import {FormattedMessage} from "react-intl"
import React from "react"
// import {getSelectedOrgUnit} from "../../../../../global/helpers/actions"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				org_unit: {
					label: <FormattedMessage id="Patient.form.patientrequest.orgunit" />,
					rules: "string"
					// value: getSelectedOrgUnit()._id
				},
				date_from: {
					label: <FormattedMessage id="Capitation.List.From" />,
					type: "date",
					value: moment()
						.subtract(70, "days")
						.startOf("day")
				},
				date_to: {
					label: <FormattedMessage id="Capitation.List.To" />,
					type: "date",
					value: moment()
						.subtract(30, "days")
						.endOf("day")
				},
				vacc_code: {
					label: <FormattedMessage id="Common.label.vaccinationCode" />
				},
				daysFromLastDose: {
					label: <FormattedMessage id="Common.label.daysFromLastDose" />
				}
			}
		}
	}
}
