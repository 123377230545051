"use strict"

// default imports for all farms
import React from "react"
import {observer} from "mobx-react"
import ReactTooltip from "react-tooltip"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import moment from "moment"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import dataBindings from "./insuranceBindings"
import {
	loadDataToFormByScheme,
	saveFormDataByScheme,
	deleteDataByScheme,
	resetForm
} from "../../../../../global/helpers/bindings"
import fields from "./insuranceFields"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import Grid from "@material-ui/core/Grid"
import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"
import GlobalStore from "../../../../../global/store/GlobalStore"
import DataStore from "../../../stores/DataStore"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import InsuranceFormStore from "../../../stores/InsuranceFormStore"
import WarningStore from "../../../../../global/store/WarningStore"
import RegistersCacheStore from "../../../../../global/store/RegistersCacheStore"

@injectIntl
@observer
export default class InsuranceTableEx extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit(form) {
				// special validation here
				if (form.$("type").value == "EU" && form.$("country").value == "SK") {
					WarningStore.open(props.intl.formatMessage({id: "Common.label.entryOtherCountryLikeSK"}))
					form.$("country").invalidate()
				} else if (isEmpty(form.$("insurer").value) || isEmpty(form.$("from").value)) {
					WarningStore.open(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
					form.$("insurer").invalidate()
				} else {
					if (isSafe(DataStore.patientDTO.get("insurances")) && DataStore.patientDTO.get("insurances").length > 0) {
						let isValid = true

						let insurancesData = DataStore.patientDTO.get("insurances")

						if (InsuranceFormStore.editable && isNotEmpty(InsuranceFormStore.editInsuranceId)) {
							insurancesData = DataStore.patientDTO
								.get("insurances")
								.filter((ins) => ins._id !== InsuranceFormStore.editInsuranceId)
						}

						if (insurancesData.length > 0) {
							insurancesData.forEach((insurance) => {
								if (isSafe(insurance.validity) && isValid) {
									let from = isNotEmpty(insurance.validity.from) ? insurance.validity.from : null
									let to = isNotEmpty(insurance.validity.to) ? insurance.validity.to : null

									if (isNotSafe(to)) {
										if (
											!moment(form.$("from").value).isBefore(moment(from)) ||
											isEmpty(form.$("to").value) ||
											(isNotEmpty(form.$("to").value) && !moment(form.$("to").value).isBefore(moment(from)))
										) {
											isValid = false
										}
									} else {
										let isAfter = moment(form.$("from").value).isAfter(moment(to))
										let isBefore =
											moment(form.$("from").value).isBefore(moment(from)) &&
											isNotEmpty(form.$("to").value) &&
											moment(form.$("to").value).isBefore(moment(from))

										if (!(isAfter || isBefore)) {
											isValid = false
										}
									}
								}
							})
						}

						if (!isValid) {
							WarningStore.open(props.intl.formatMessage({id: "Patient.insurance.form.date.intersect"}))
							form.invalidate()
						}
					}
				}
			},
			onSuccess(form) {
				if (InsuranceFormStore.editable) {
					saveFormDataByScheme(
						form,
						dataBindings,
						{number: form.$("number").value},
						() => DataStore.getPatientInsurances(props.id, props.patientInfoForm),
						false,
						"Patient.form.insuranceForm.editSuccess"
					)
					InsuranceFormStore.editable = false
				} else {
					saveFormDataByScheme(
						form,
						dataBindings,
						{clientId: props.clientId, number: form.$("number").value},
						() => DataStore.getPatientInsurances(props.id, props.patientInfoForm),
						false,
						"Patient.form.insuranceForm.savesuccess"
					)
				}

				form.reset()
				InsuranceFormStore.isAdd = false
			},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		this.initForm()
	}

	form

	initForm() {
		resetForm(this.form, dataBindings)
	}

	onHandleRowClick = (dataRow) => {
		loadDataToFormByScheme(this.form, dataBindings, {insuranceId: dataRow.delete}, (data) => {
			this.form.$("number").value = data.number
		})

		InsuranceFormStore.editInsuranceId = dataRow.delete

		InsuranceFormStore.editable = true
		InsuranceFormStore.isAdd = true
	}

	deleteInsurance = () => {
		deleteDataByScheme(
			dataBindings,
			GlobalStore.confirmationDialogParams.insuranceId,
			() => DataStore.getPatientInsurances(this.props.id, this.props.patientInfoForm),
			"Patient.form.insuranceForm.deleteMessage"
		)
		InsuranceFormStore.editable = false
		this.initForm()
	}

	render() {
		return (
			<div className="xs-insurance-table-ex">
				<div className="xs-table">
					<Grid container justify="flex-end">
						<Grid item>
							<XsButton
								className="xs-default xs-outline"
								icon={<i className="fas fa-suitcase" />}
								text={<FormattedMessage id="Common.label.checkInsurerRelation" />}
								onClick={() => {
									window.open(
										"https://www.portaludzs.sk/?fbclid=IwAR3cGK9eUKLkG1iAaoclALXaPkC5J_tdqorCcMyba8gg2yW-WBRusknMvAM",
										"_blank"
									)
								}}
							/>
						</Grid>
					</Grid>
					<XsTable
						config={{
							columnDefs: {
								insurer: {
									title: <FormattedMessage id="Common.label.insurer" />,
									type: "string",
									design: {
										width: "120px"
									},
									sortable: true
								},
								number: {
									title: <FormattedMessage id="Common.label.number" />,
									type: "string",
									design: {
										width: "120px"
									}
								},
								from: {
									title: <FormattedMessage id="Common.label.from" />,
									type: "datetime",
									design: {
										width: "120px",
										body: {
											formatter: (props) => {
												return isSafe(props) && isNotEmpty(props) ? moment(props).format("DD.MM.YYYY") : ""
											}
										}
									},
									sortable: true
								},
								to: {
									title: <FormattedMessage id="Common.label.to" />,
									type: "datetime",
									design: {
										width: "120px",
										body: {
											formatter: (props) => {
												return isSafe(props) && isNotEmpty(props) ? moment(props).format("DD.MM.YYYY") : ""
											}
										}
									},
									sortable: true
								},
								type: {
									title: <FormattedMessage id="Patient.form.patientrecord.diagnosistype" />,
									type: "string",
									design: {
										width: "250px"
									},
									sortable: true
								},
								country: {
									title: <FormattedMessage id="Patient.form.insuranceForm.country" />,
									type: "string",
									sortable: true
								},
								delete: {
									title: "",
									type: "action",
									design: {
										width: "40px",
										body: {
											renderer: (props) => {
												return (
													<XsIconButton
														className="action-delete"
														icon={
															<span>
																<i
																	className="fal fa-trash-alt fa-lg xs-greyDefault"
																	data-tip
																	data-for="removeInsurer"
																/>
																<ReactTooltip id="removeInsurer" effect="solid">
																	<FormattedMessage id="Common.label.removeInsurer" />
																</ReactTooltip>
															</span>
														}
														onClick={() =>
															GlobalStore.openConfirmationDialog("xsDeletePatientInsurance", {insuranceId: props.value})
														}
													/>
												)
											}
										}
									}
								}
							},
							options: {
								hidePager: true,
								showCursor: true,
								onRowClick: (dataRow) => {
									this.onHandleRowClick(dataRow)
								},
								mapper: (dataRow) => {
									return {
										insurer: isSafe(dataRow.insurer) ? dataRow.insurer.name_ext : "",
										insurer_code: isSafe(dataRow.insurer) ? dataRow.insurer.code : "",
										number: dataRow.number,
										from: isSafe(dataRow.validity) && isSafe(dataRow.validity.from) ? dataRow.validity.from : "",
										to: isSafe(dataRow.validity) && isSafe(dataRow.validity.to) ? dataRow.validity.to : "",
										type: isSafe(dataRow.type) ? dataRow.type.name_ext : "",
										type_code: isSafe(dataRow.type) ? dataRow.type.code : "",
										country: isSafe(dataRow.country) ? dataRow.country.name_ext : "",
										country_code: isSafe(dataRow.country) ? dataRow.country.code : "",
										delete: dataRow._id
									}
								}
							},
							dataSource: DataStore.patientDTO.get("insurances")
						}}
					/>
				</div>
				<div className="xs-form">
					{InsuranceFormStore.isAdd ? (
						<form>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={2}>
									<SearchSelect
										field={this.form.$("insurer")}
										items={RegistersCacheStore.insurersRegister}
										required
										menuPlacement="top"
									/>
								</Grid>
								<Grid item xs={2}>
									<XsInput disabled={this.form.$("type").value == "GH"} white={true} field={this.form.$("number")} />
								</Grid>
								<Grid item xs={2}>
									<XsDateTimePicker field={this.form.$("from")} />
								</Grid>
								<Grid item xs={2}>
									<XsDateTimePicker field={this.form.$("to")} />
								</Grid>
								<Grid item xs={2}>
									<SearchSelect
										field={this.form.$("type")}
										items={GlobalStore.CL["insuranceTypes"]}
										required
										menuPlacement="top"
										onChange={(value) => {
											if (value == "GH") {
												this.form.$("number").value = ""
											}

											if (value == "EU" && this.form.$("country").value == "SK") {
												WarningStore.open(this.props.intl.formatMessage({id: "Common.label.entrySpecificEUCountry"}))
											}
										}}
									/>
								</Grid>
								<Grid item xs={2}>
									<SearchSelect
										field={this.form.$("country")}
										items={GlobalStore.CL["countries"]}
										required
										menuPlacement="top"
									/>
								</Grid>
								<Grid item xs={12} className="xs-form-actions-newline">
									{InsuranceFormStore.editable ? (
										<XsButton
											className="xs-success mr-3"
											type="submit"
											onClick={this.form.onSubmit}
											text={<FormattedMessage id="Common.label.save" />}
											icon={<SaveIcon />}
										/>
									) : (
										<XsButton
											className="xs-success mr-3"
											type="submit"
											onClick={this.form.onSubmit}
											text={<FormattedMessage id="Common.label.add" />}
											icon={<SaveIcon />}
										/>
									)}
									<XsButton
										className="xs-default xs-outline"
										onClick={() => {
											this.initForm()
											InsuranceFormStore.isAdd = false
											InsuranceFormStore.editable = false
										}}
										text={<FormattedMessage id="Common.label.cancel" />}
										icon={<CancelIcon />}
									/>
								</Grid>
							</Grid>
						</form>
					) : (
						<XsButton
							className="xs-default xs-outline xs-addAnother"
							icon={<i className="fal fa-plus-circle" />}
							text={<FormattedMessage id="Common.button.addAnother" />}
							onClick={() => {
								InsuranceFormStore.isAdd = true
								InsuranceFormStore.editable = false
								this.initForm()
							}}
						/>
					)}
				</div>
				<XsConfirmationDialog
					type="danger"
					name="xsDeletePatientInsurance"
					text={<FormattedMessage id="Patient.form.insuranceForm.confirmationMessage" />}
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.btn.insurance.delete"
					}}
					onConfirmation={() => this.deleteInsurance()}
				/>
			</div>
		)
	}
}
