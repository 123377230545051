import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import renderHTML from "react-render-html"
import moment from "moment"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import MedicationDistDialogStore from "../../../stores/MedicationDistDialogStore"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import Diacritics from "../../../../../global/helpers/diacritics"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
// import {XsDateTimePicker} from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import {XsDateTimePickerDirect} from "../../../../../global/ui/xsDateTimePickerDirect/xsDateTimePickerDirect"

@injectIntl
@observer
export default class MedicationDistDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	onHandleSearchCode(value) {
		MedicationDistDialogStore.searchCode = value
	}

	onHandleSearchName(value) {
		MedicationDistDialogStore.searchName = value
	}

	onHandleSearchDate(value) {
		MedicationDistDialogStore.searchDate = value
	}

	onHandleSearchDiagnose(value) {
		MedicationDistDialogStore.searchDiagnose = value
	}

	handleFilterData(data) {
		let filteredData = data

		if (isNotEmpty(MedicationDistDialogStore.searchCode)) {
			filteredData = filteredData.filter(
				(x) => x.sukl_code.toLowerCase().indexOf(MedicationDistDialogStore.searchCode.toLowerCase()) !== -1
			)
		}
		if (isNotEmpty(MedicationDistDialogStore.searchDiagnose)) {
			filteredData = filteredData.filter((x) => x.diagnosis == MedicationDistDialogStore.searchDiagnose.value)
		}
		if (isNotEmpty(MedicationDistDialogStore.searchName)) {
			filteredData = filteredData.filter(
				(x) =>
					Diacritics.remove(x.product_name)
						.toLowerCase()
						.indexOf(Diacritics.remove(MedicationDistDialogStore.searchName).toLowerCase()) !== -1
			)
		}
		if (isNotEmpty(MedicationDistDialogStore.searchDate)) {
			filteredData = filteredData.filter((x) => MedicationDistDialogStore.searchDate.isSame(x.created_at, "day"))
		}

		return filteredData
	}

	render() {
		const dateFormat = this.props.intl.formatMessage({id: "Application.moment.dateformat"})

		const getData = () => {
			if (isSafe(MedicationDistDialogStore.dataSource) && MedicationDistDialogStore.dataSource.length > 0) {
				return this.handleFilterData(MedicationDistDialogStore.dataSource)
			} else {
				return []
			}
		}

		return (
			<Dialog
				id="xsMedicationDistDialog"
				open={MedicationDistDialogStore.isOpen}
				onClose={() => MedicationDistDialogStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
				className="xs-base-dialog"
				classes={{
					paper: "overflowSearch"
				}}
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-pills fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.drugs" />
						</div>
					</div>
				</DialogTitle>
				<div className="xs-content-filters">
					<Grid container direction="row" align="center" spacing={8}>
						<Grid item xs={1}>
							<TextField
								InputLabelProps={{shrink: true}}
								defaultValue=""
								autoComplete="off"
								onChange={(e) => this.onHandleSearchCode(e.target.value)}
								label={<FormattedMessage id="Patient.generics.dialog.code" />}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								InputLabelProps={{shrink: true}}
								defaultValue=""
								autoComplete="off"
								onChange={(e) => this.onHandleSearchName(e.target.value)}
								label={<FormattedMessage id="Common.label.name" />}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePickerDirect
								onChange={(sDateTime) => this.onHandleSearchDate(sDateTime)}
								selected={MedicationDistDialogStore.searchDate}
								label={<FormattedMessage id="Common.label.date" />}
								isClearable
							/>
						</Grid>
						<Grid item xs={6}>
							<SearchSelect
								items={MedicationDistDialogStore.diagnosisSource}
								label={<FormattedMessage id="Common.label.diagnose" />}
								getOptionLabel={(x) => x.label}
								getOptionValue={(x) => x.value}
								onChange={(val) => this.onHandleSearchDiagnose(val)}
							/>
						</Grid>
					</Grid>
				</div>
				<DialogContent className="xs-content">
					{MedicationDistDialogStore.isLoading ? (
						<XsLoading />
					) : (
						<React.Fragment>
							<XsTable
								config={{
									columnDefs: {
										check: {
											type: "action",
											design: {
												width: "5%",
												body: {
													formatter: (props) => {
														return (
															<Checkbox
																checked={MedicationDistDialogStore.medIds.includes(props.record_id)}
																onChange={(e) =>
																	MedicationDistDialogStore.onHandleCheck(props.record_id, e.target.checked)
																}
																className="xs-checkbox"
															/>
														)
													}
												}
											}
										},
										code: {
											title: <FormattedMessage id="Patient.generics.dialog.code" />,
											type: "string",
											design: {
												width: "10%"
											}
										},
										name: {
											title: <FormattedMessage id="Common.label.name" />,
											type: "string",
											design: {
												width: "25%"
											},
											sortable: true
										},
										supplement: {
											title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />,
											type: "string",
											design: {
												width: "20%"
											}
										},
										atc: {
											title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.ATC" />,
											type: "string",
											design: {
												width: "15%"
											}
										},
										transcription: {
											title: <FormattedMessage id="Patient.form.patientPrescription.quantization" />,
											type: "string",
											design: {
												width: "15%",
												body: {
													formatter: (props) => {
														return htmlToPlain(props)
													}
												}
											}
										},
										created_at: {
											title: <FormattedMessage id="Common.label.date" />,
											type: "datetime",
											design: {
												width: "10%",
												body: {
													formatter: (props) => {
														return isNotEmpty(props) ? moment(props).format(dateFormat) : ""
													}
												}
											},
											sortable: true
										}
									},
									options: {
										hidePager: true,
										showCursor: true,
										mapper: (dataRow) => {
											return {
												check: dataRow,
												code: dataRow.sukl_code,
												name: `${dataRow.product_name}`,
												supplement: renderHTML(dataRow.supplement),
												atc: dataRow.atc_code_ext + " - " + dataRow.atc_name_ext,
												transcription: dataRow.transcription,
												created_at: dataRow.created_at
											}
										}
									},
									dataSource: getData()
								}}
							/>
						</React.Fragment>
					)}
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Patient.form.patientrecord.close" />}
						onClick={() => MedicationDistDialogStore.close()}
					/>
					<XsButton
						className="xs-default xs-outline"
						text={<FormattedMessage id="Patient.form.patientPrescription.represcribe" />}
						onClick={() => MedicationDistDialogStore.onReprescribe()}
						disabled={MedicationDistDialogStore.medIds.length === 0}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
