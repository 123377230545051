"use strict"

import React from "react"
import {observer} from "mobx-react"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import dataBindings from "./employeeCardContactsBindings"
import {
	loadDataToFormByScheme,
	saveFormDataByScheme,
	deleteDataByScheme,
	resetForm
} from "../../../../../global/helpers/bindings"
import {FormattedMessage} from "react-intl"
// import UI controls here
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import Grid from "@material-ui/core/Grid"
// definitions of form fields
import fields from "./employeeCardContactsField"
import {isEmailValid, isPhoneNumberValid} from "../../../../../global/helpers/functions"
// actions
import EmployeesDetailStore from "../../../stores/EmployeesDetailStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import {injectIntl} from "react-intl"

@injectIntl
@observer
export default class EmployeeCartContacts extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			editable: false,
			isAdd: false
		}

		const hooks = {
			onSubmit(form) {
				if (isEmpty(form.$("phone").value) && isEmpty(form.$("mobile").value) && isEmpty(form.$("email").value)) {
					GlobalStore.setNotificationMessage(
						"Aspoň jeden údaj je potrebný (" +
							props.intl.formatMessage({id: "Patient.form.contactForm.mobile"}) +
							", " +
							props.intl.formatMessage({id: "Patient.form.contactForm.phone"}) +
							", " +
							props.intl.formatMessage({id: "Common.label.email"}) +
							")"
					)
					form.invalidate()
				} else {
					if (isNotEmpty(form.$("phone").value) && !isPhoneNumberValid(form.$("phone").value)) {
						GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Patient.form.contactForm.phone"}))
						form.invalidate()
					} else if (isNotEmpty(form.$("mobile").value) && !isPhoneNumberValid(form.$("mobile").value)) {
						GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Patient.form.contactForm.mobile"}))
						form.invalidate()
					} else if (isNotEmpty(form.$("email").value) && !isEmailValid(form.$("email").value)) {
						GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.label.email"}))
						form.invalidate()
					}
				}
			},
			onSuccess(form) {
				saveFormDataByScheme(
					form,
					dataBindings,
					{clientId: props.employeeId},
					(response) => {
						EmployeesDetailStore.getEmpContacts(props.entityId, props.employeeId)
						if (response) {
							form.reset()
						}
					},
					false,
					"EmployeeDetail.employeeContacts.contactSaved"
				)
			},
			onError() {}
		}

		EmployeesDetailStore.getEmpContacts(props.entityId, props.employeeId)
		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		this.initForm()
	}

	initForm() {
		resetForm(this.form, dataBindings)
	}

	form

	onHandleRowClick = (dataRow) => {
		loadDataToFormByScheme(this.form, dataBindings, {contactId: dataRow.delete})
		this.setState({
			editable: true,
			isAdd: true
		})
	}

	deleteContact = () => {
		deleteDataByScheme(dataBindings, GlobalStore.confirmationDialogParams.contactId, () =>
			EmployeesDetailStore.getEmpContacts(this.props.entityId, this.props.props.employeeId)
		)
	}

	render() {
		return (
			<Grid container direction="column">
				<Grid item className="pa-6">
					<XsTable
						config={{
							columnDefs: {
								contact_type: {
									title: <FormattedMessage id="Patient.form.patientrecord.diagnosistype" defaultMessage="Type" />,
									type: "string",
									design: {
										width: "150px",
										body: {
											className: (row) => (row.primary ? "xs-primaryContact" : null)
										}
									}
								},
								mobile: {
									title: <FormattedMessage id="Patient.form.contactForm.mobile" defaultMessage="Mobile" />,
									type: "string",
									design: {
										width: "150px",
										body: {
											className: "xs-bold"
										}
									}
								},
								email: {
									title: <FormattedMessage id="Common.label.email" defaultMessage="Email" />,
									type: "string",
									design: {
										width: "300px",
										body: {
											className: "xs-bold"
										}
									},
									sortable: true
								},

								phone: {
									title: <FormattedMessage id="Patient.form.contactForm.phone" defaultMessage="Phone" />,
									type: "string",
									design: {
										width: "150px"
									}
								},
								desciption: {
									title: <FormattedMessage id="Common.label.description" defaultMessage="Description" />,
									type: "string"
								},
								delete: {
									title: "",
									type: "action",
									design: {
										width: "42px",
										body: {
											renderer: (props) => {
												return (
													<XsIconButton
														className="action-delete"
														icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
														onClick={() =>
															GlobalStore.openConfirmationDialog("xsDeletePatientContact", {contactId: props.value})
														}
													/>
												)
											}
										}
									}
								}
							},
							options: {
								hidePager: true,
								showCursor: true,
								onRowClick: (dataRow) => {
									this.onHandleRowClick(dataRow)
								},
								mapper: (dataRow) => {
									return {
										contact_type: isSafe(dataRow.contact_type) ? dataRow.contact_type.description : "",
										contact_type_code: isSafe(dataRow.contact_type) ? dataRow.contact_type.code : "",
										mobile: dataRow.mobile,
										email: dataRow.email,
										phone: dataRow.phone,
										desciption: dataRow.description,
										delete: dataRow._id,
										primary: dataRow.primary
									}
								}
							},
							dataSource: EmployeesDetailStore.contactsTableData
						}}
					/>
				</Grid>
				<Grid item className="fomBorderTop pt-4 pb-2 pl-6 pr-6">
					{this.state.isAdd ? (
						<form>
							<Grid container direction="row" align="center" spacing={8}>
								<Grid item xs={2}>
									<SearchSelect
										field={this.form.$("type")}
										white={true}
										items={GlobalStore.CL["contactTypes"]}
										required
									/>
								</Grid>
								<Grid item xs={2}>
									<XsInput white={true} field={this.form.$("mobile")} />
								</Grid>
								<Grid item xs={2}>
									<XsInput white={true} field={this.form.$("email")} />
								</Grid>
								<Grid item xs={2}>
									<XsInput white={true} field={this.form.$("phone")} />
								</Grid>
								<Grid item xs={1}>
									<XsInput white={true} field={this.form.$("description")} />
								</Grid>
								<Grid item xs={1} className="mt-2">
									<XsCheckbox field={this.form.$("check")} />
								</Grid>
								<Grid item container justify="flex-end" alignItems="center" xs={2} spacing={8} className="mt-0">
									{this.state.editable ? (
										<Grid item>
											<XsButton
												className="xs-success"
												type="submit"
												onClick={(e) => {
													this.form.onSubmit(e)
													this.setState({
														editable: false
													})
												}}
												text={<FormattedMessage id="Common.label.save" />}
												icon={<i className="fal fa-check fa-lg" />}
											/>
										</Grid>
									) : (
										<Grid item>
											<XsButton
												className="xs-success"
												type="submit"
												onClick={this.form.onSubmit}
												text={<FormattedMessage id="Common.label.add" />}
												icon={<i className="fal fa-check fa-lg" />}
											/>
										</Grid>
									)}
									<Grid item>
										<XsButton
											className="xs-danger xs-outline"
											onClick={() => {
												this.initForm()
												this.setState({
													editable: false,
													isAdd: false
												})
											}}
											text={<FormattedMessage id="Common.label.cancel" />}
											icon={<i className="fal fa-times fa-lg"></i>}
										/>
									</Grid>
								</Grid>
							</Grid>
						</form>
					) : (
						<Grid item className="pt-2 pb-2">
							<XsButton
								className="xs-primary xs-outline"
								icon={<i className="fal fa-plus fa-lg"></i>}
								text={<FormattedMessage id="Common.button.addAnother" />}
								onClick={() => {
									this.setState({
										isAdd: true,
										editable: false
									})
								}}
							/>
						</Grid>
					)}
					<XsConfirmationDialog
						name="xsDeletePatientContact"
						text={<FormattedMessage id="Patient.form.contactForm.confirmationMessage" />}
						type="danger"
						headerConfig={{
							text: "Common.label.warning",
							icon: <i className="fal fa-trash-alt fa-2x" />
						}}
						cancelBtn={{
							icon: <i className="fal fa-times fa-lg" />,
							text: "Common.btn.not.delete"
						}}
						confirmBtn={{
							icon: <i className="fal fa-trash-alt fa-lg" />,
							text: "Common.btn.contact.delete"
						}}
						onConfirmation={() => this.deleteContact()}
					/>
				</Grid>
			</Grid>
		)
	}
}
