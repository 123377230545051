import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import Grid from "@material-ui/core/Grid"
// import Paper from "@material-ui/core/Paper"
// import XsMenu from "../../../../global/ui/xsMenu/xsMenu"
import XsTabsIcon from "../../../../global/ui/xsTabs/xsTabsIcon"
import EmployeesList from "./employeesList"
import OrgunitsList from "../orgunitsList/orgunitsList"
import UIStore from "../../stores/UIStore"

@injectIntl
@observer
export default class EmployeesListContainer extends React.Component {
	constructor(props) {
		super(props)
	}
	render() {
		return (
			<Grid container direction="column" id="xsEmployeeListContainer" className="pt-4 bgSnowWhite">
				<XsTabsIcon
					value={UIStore.listCategory}
					onChangeTab={(value) => (UIStore.listCategory = value)}
					mainClass="xs-patient-card-tab"
					background="#f0f3f8"
					scrollButtons={true}
					headerClassName="filled"
					// background="white"
					tabs={[
						{
							label: this.props.intl.formatMessage({id: "Orgunits.orgunit.employees"}),
							icon:
								UIStore.listCategory === "employees" ? (
									<i className="fas fa-users fa-2x" />
								) : (
									<i className="fal fa-users fa-2x" />
								),
							value: "employees",
							content: <EmployeesList />
						},
						{
							label: this.props.intl.formatMessage({id: "Orgunits.orgunitsName"}),
							icon:
								UIStore.listCategory === "orgunits" ? (
									<i className="fas fa-hospital fa-2x" />
								) : (
									<i className="fal fa-hospital fa-2x" />
								),
							value: "orgunits",
							content: <OrgunitsList />
						}
					]}
				/>
			</Grid>
		)
	}
}
