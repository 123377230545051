import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import moment from "moment"

// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import Grid from "@material-ui/core/Grid"

import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

import ExportEUPatientToXlsDialog from "./exportEUPatientToXlsDialog"

import GlobalStore from "../../../../../global/store/GlobalStore"
import EUPatientsReportStore from "../../../stores/EUPatientsReportStore"
import UIStore from "../../../stores/UIStore"
import TableStore from "../../../stores/TableStore"
import fields from "./euPatientsReportFields"
import {getUserPersonnelOrgUnits} from "../../../../../global/helpers/actions"

@injectIntl
@observer
export default class EUPatientsReport extends React.Component {
	constructor(props) {
		super(props)

		UIStore.isFormSaving = false

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				if (
					TableStore.tables.hasOwnProperty("euPatientsReportList") &&
					TableStore.tables["euPatientsReportList"].hasOwnProperty("index")
				) {
					delete TableStore.tables["euPatientsReportList"]["index"]
				}

				EUPatientsReportStore.loadPatientsOverview(form)
			},
			onError() {}
		}

		GlobalStore.refreshCodeLists(["countries"])

		if (isEmpty(EUPatientsReportStore.formRef)) {
			EUPatientsReportStore.formRef = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		}

		this.form = EUPatientsReportStore.formRef

		EUPatientsReportStore.loadPatientsOverview(this.form)
	}

	onHandleRowClick = (dataRow) => {
		if (dataRow.person_id) {
			GlobalStore.changeOrgUnitAndRouteToPatientDetail(this.form.$("orgunit").value, dataRow.person_id)
		}
	}

	render() {
		EUPatientsReportStore.isChangedFilter(this.form.values())
		const dateFormat = this.props.intl.formatMessage({id: "Application.moment.dateformat"})

		return (
			<Grid container className="xs-euPatients-list">
				<Grid item xs={12}>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}>
							<SearchSelect white field={this.form.$("orgunit")} items={getUserPersonnelOrgUnits()} required />
						</Grid>
						<Grid item xs={1}>
							<XsDateTimePicker white field={this.form.$("datefrom")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={1}>
							<XsDateTimePicker white field={this.form.$("dateto")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={2}>
							<XsInput white field={this.form.$("identifier")} />
						</Grid>
						<Grid item xs={2}>
							<SearchSelect white field={this.form.$("country")} items={GlobalStore.CL["countries"]} />
						</Grid>
						<Grid item xs={1} className="pb-0">
							<XsIconButton
								rect
								className="xs-default xs-outline"
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								onClick={this.form.onSubmit}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
						<Grid item xs={3}>
							<Grid container justify="flex-end" spacing={8}>
								<Grid item>
									<XsButton
										className="xs-primary"
										text={<FormattedMessage id="Common.label.export" />}
										icon={<i className="fal fa-file-excel fa-lg" />}
										onClick={() => {
											EUPatientsReportStore.open()
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{EUPatientsReportStore.isLoading ? (
						<XsLoading />
					) : EUPatientsReportStore.changedFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i className="far fa-search fa-lg mr-3 pointer" onClick={this.form.onSubmit}></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTable
							setRef={(table) => (EUPatientsReportStore.euPatientReportListRef = table)}
							config={{
								columnDefs: {
									patient: {
										title: <FormattedMessage id="Capitation.List.Patient" />,
										type: "string",
										design: {
											width: "250px",
											body: {
												className: "blueDark xs-bold"
											}
										},
										sortable: true
									},
									identifier: {
										title: <FormattedMessage id="Patient.form.patientinfo.identifier" />,
										type: "string",
										design: {
											width: "120px"
										},
										sortable: true
									},
									insurer: {
										title: <FormattedMessage id="Common.label.insurer" />,
										type: "string",
										design: {
											width: "100px"
										},
										sortable: true
									},
									country: {
										title: <FormattedMessage id="Patient.form.insuranceForm.country" />,
										type: "string",
										design: {
											width: "100px"
										},
										sortable: true
									},
									valid_from: {
										title: <FormattedMessage id="Capitation.List.From" />,
										type: "datetime",
										design: {
											width: "100px",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format(dateFormat) : ""
												}
											}
										},
										sortable: true
									},
									valid_to: {
										title: <FormattedMessage id="Capitation.List.To" />,
										type: "datetime",
										design: {
											width: "100px",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format(dateFormat) : ""
												}
											}
										},
										sortable: true
									}
								},
								options: {
									name: "euPatientsReportList",
									showCursor: true,
									// selectRow: true,
									defaultSort: {columnName: "stamp", sortDirection: "asc"},
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										//const lastInsurance = isSafe(dataRow.insurance) && dataRow.insurance.insurer.insurances.length > 0 ? dataRow.insurance.insurer.insurances.find(i => i.last) : null;
										return {
											patient: isSafe(dataRow.full_name) ? dataRow.full_name : "",
											identifier: isSafe(dataRow.identifier) ? dataRow.identifier : "",
											person_id: isSafe(dataRow.person_id) ? dataRow.person_id : null,
											//"insurer": isSafe(lastInsurance) ? `${lastInsurance.insurer.code_ext} - ${lastInsurance.insurer.name_ext}` : "",
											insurer:
												isSafe(dataRow.insurance) && isSafe(dataRow.insurance.insurer)
													? `${dataRow.insurance.insurer.code_ext} - ${dataRow.insurance.insurer.name_ext}`
													: "",
											country:
												isSafe(dataRow.insurance) && dataRow.insurance.country
													? dataRow.insurance.country.name_ext
													: "",
											valid_from:
												isSafe(dataRow.insurance) &&
												isSafe(dataRow.insurance.validity) &&
												isSafe(dataRow.insurance.validity.from)
													? dataRow.insurance.validity.from
													: "",
											valid_to:
												isSafe(dataRow.insurance) &&
												isSafe(dataRow.insurance.validity) &&
												isSafe(dataRow.insurance.validity.to)
													? dataRow.insurance.validity.to
													: ""
										}
									}
								},
								dataSource: EUPatientsReportStore.patientsOverview
							}}
						/>
					)}
				</Grid>
				<ExportEUPatientToXlsDialog />
			</Grid>
		)
	}
}
