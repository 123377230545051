export default {
	load: {
		storeKey: "employeeInfoForm",
		params: ["employeeId"],
		type: "Entity.Data.Employee",
		flags: "CDL2"
	},
	scheme: {
		entity: {
			object: {
				scheme: {
					title_before: {
						field: "title_before"
					},
					first_name: {
						field: "firstname"
					},
					last_name: {
						field: "lastname"
					},
					title_after: {
						field: "title_after"
					},
					birth_name: {
						field: "birthname"
					},
					identifier: {
						field: "identifier"
					},
					birth_date: {
						field: "dateOfBirth"
					},
					gender: {
						field: "gender"
					}
				}
			}
		}
	}
}
