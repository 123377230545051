import React from "react"
import {observer} from "mobx-react"
import OrgunitForm from "./OrgunitForm/OrgunitForm"
import OrgunitsDetailEmployees from "./orgunitsDetailEmployees/orgunitsDetailEmployeesContainer"
import OrgunitsDetailAddresses from "./orgunitsDetailAddresses/orgunitsDetailAddresses"
import OrgunitDetailContact from "./orgunitsDetailContacts/orgunitDetailContacts"
import OrgunitBankContainer from "./OrgunitBank/OrgunitBankContainer"
import OrgunitsParametersContainer from "./orgunitsParameters/orgunitsParametersContainer"
import XsTabsIcon from "../../../../global/ui/xsTabs/xsTabsIcon"
import Grid from "@material-ui/core/Grid"
import {FormattedMessage} from "react-intl"

import OrgunitDetailStore from "../../stores/OrgunitDetailStore"
import UIStore from "../../stores/UIStore"
import UIStoreAmbulance from "../../../ambulance/stores/UIStore"

import "./xs-orgunitsDetail.less"

@observer
export default class OrgunitsDetailContainer extends React.Component {
	constructor(props) {
		UIStoreAmbulance.mainMenuTab = "employees"
		super(props)
		OrgunitDetailStore.orgunitData = {}
		if (props.id != "new") {
			OrgunitDetailStore.loadOrgunitData(props.id)
			OrgunitDetailStore.loadOrgunitEmpl(props.id)
			OrgunitDetailStore.loadSuperiorOrgunits(props.id)
		}
	}

	render() {
		return (
			<div id="orgunitContainer">
				<Grid container className="pr-8 pl-8 pt-4 pb-4">
					<Grid item className="xs-title">
						<h1>{OrgunitDetailStore.orgunitData.name_ext}</h1>
					</Grid>
				</Grid>
				<div className="orgunitContainerForm">
					{isSafe(OrgunitDetailStore.orgunitData) && isNotEmpty(OrgunitDetailStore.orgunitData._id) && (
						<OrgunitForm id={OrgunitDetailStore.orgunitData._id} />
					)}
				</div>
				<XsTabsIcon
					// defaultValue="overview"
					value={UIStore.orgunitDetailIconTabs}
					onChangeTab={(value) => (UIStore.orgunitDetailIconTabs = value)}
					background="white"
					scrollButtons={true}
					ended={true}
					horizontal={true}
					tabs={[
						{
							value: "employees",
							icon:
								UIStore.orgunitDetailIconTabs === "employees" ? (
									<i className="fas fa-users fa-lg" />
								) : (
									<i className="fal fa-users fa-lg" />
								),
							label: (
								<div>
									<FormattedMessage id="Orgunits.orgunit.employees" />
								</div>
							),
							// count: this.getDataCount(patientOverviewData),
							content: <OrgunitsDetailEmployees />
						},
						{
							value: "addresses",
							icon:
								UIStore.orgunitDetailIconTabs === "addresses" ? (
									<i className="fas fa-address-card fa-lg" />
								) : (
									<i className="fal fa-address-card fa-lg" />
								),
							label: (
								<div>
									<FormattedMessage id="Orgunits.orgunit.addresses" />
								</div>
							),
							// count: this.getDataCount(patientOverviewData),
							content: <OrgunitsDetailAddresses orgunitId={this.props.id} />
						},
						{
							value: "contacts",
							icon:
								UIStore.orgunitDetailIconTabs === "contacts" ? (
									<i className="fas fa-address-book fa-lg" />
								) : (
									<i className="fal fa-address-book fa-lg" />
								),
							label: (
								<div>
									<FormattedMessage id="Orgunits.orgunit.contacts" />
								</div>
							),
							// count: this.getDataCount(patientOverviewData),
							content: <OrgunitDetailContact orgunitId={this.props.id} />
						},
						{
							value: "bank",
							icon:
								UIStore.orgunitDetailIconTabs === "bank" ? (
									<i className="fas fa-university fa-lg" />
								) : (
									<i className="fal fa-university fa-lg" />
								),
							label: (
								<div>
									<FormattedMessage id="Common.label.bankAccs" />
								</div>
							),
							content: <OrgunitBankContainer orgunitId={this.props.id} />
						},
						{
							value: "parameters",
							icon:
								UIStore.orgunitDetailIconTabs === "parameters" ? (
									<i className="fas fa-user-cog fa-lg" />
								) : (
									<i className="fal fa-user-cog fa-lg" />
								),
							label: (
								<div>
									<FormattedMessage id="Orgunits.orgunit.parameters" />
								</div>
							),
							content: <OrgunitsParametersContainer orgunitId={this.props.id} />
						}
					]}
				/>
			</div>
		)
	}
}
