import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import fields from "./hospicomDialogFields"
import api from "../../../../actions/api"
import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
import HospicomDialogStore from "../../../../stores/HospicomDialogStore"
import {XsDateTimePicker} from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import {getUserPersonnelOrgUnits, getSelectedOrgUnitID} from "../../../../../../global/helpers/actions"
import XsAutocompleteLocal from "../../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegisterCacheStore from "../../../../../../global/store/RegistersCacheStore"
import XsConfirmationDialog from "../../../../../../global/ui/xsDialog/xsConfirmationDialog"
import XsAutocomplete from "../../../../../../global/ui/xsInput/xsAutocomplete"
import XsSearchSelect from "../../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsNumberInput from "../../../../../../global/ui/xsInput/xsNumberInput"
import GlobalStore from "../../../../../../global/store/GlobalStore"
import XsButton from "../../../../../../global/ui/xsButton/xsButton"
import UIStore from "../../../../stores/UIStore"
import WarningStore from "../../../../../../global/store/WarningStore"

@injectIntl
@observer
export default class HospicomDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {
				UIStore.isFormSaving = true
			},
			onSuccess(form) {
				HospicomDialogStore.saveForm(form)
			},
			onError() {
				UIStore.isFormSaving = false
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		HospicomDialogStore.loadDeliveries()

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks})
		HospicomDialogStore.form = this.form
	}

	render() {
		return (
			<Dialog
				id="xsHospicomDialog"
				open={HospicomDialogStore.isOpen}
				onClose={() => {
					HospicomDialogStore.close(this.form)
				}}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-900"
				}}
				disableBackdropClick={true}
				scroll="paper"
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								HospicomDialogStore.close(this.form)
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-calendar-alt fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Patient.sidebar.hospicom" />
						</div>
						{isNotEmpty(this.form.$("patientInfo").value) && (
							<div className="xs-header-subtitle">{this.form.$("patientInfo").value}</div>
						)}
					</div>
				</DialogTitle>
				<DialogContent className="overflowY pa-4">
					<Grid container direction="column">
						<Grid container item spacing={8}>
							<Grid item xs={4}>
								<XsSearchSelect
									field={this.form.$("state")}
									items={GlobalStore.CL.healthCareStatus.sort((a, b) => {
										return a.item_order - b.item_order
									})}
								/>
							</Grid>

							<Grid item xs={4}>
								<XsSearchSelect field={this.form.$("zsType")} items={GlobalStore.CL["healthCareProposalType"]} />
							</Grid>

							<Grid item xs={4}>
								<XsSearchSelect field={this.form.$("plannedOrgunit")} items={getUserPersonnelOrgUnits()} />
							</Grid>

							<Grid item xs={4}>
								<XsDateTimePicker field={this.form.$("proposalDate")} showTimeSelect={false} />
							</Grid>

							<Grid item xs={4}>
								<XsDateTimePicker field={this.form.$("proposalDateZS")} showTimeSelect={true} />
							</Grid>

							<Grid item xs={4}>
								<XsAutocompleteLocal
									field={this.form.$("diagnosis")}
									minLengthForSearch="3"
									clearText
									data={RegisterCacheStore.diagnosisRegister}
									inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
									saveValue={(obj) => obj.code}
									modalConfig={(textValue, clickHandler) => {
										return {
											columnDefs: {
												code_ext: {
													title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
													type: "string",
													dbName: "code_ext",
													design: {
														width: "150px"
													},
													filter: {
														gridWidth: 3,
														defaultValue: "",
														renderElement: "input"
													}
												},
												name_ext: {
													title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
													type: "string",
													dbName: "search_column",
													filter: {
														gridWidth: 9,
														defaultValue: textValue,
														renderElement: "input"
													}
												}
											},
											options: {
												showCursor: true,
												onRowClick: (dataRow) => {
													clickHandler(dataRow)
												},
												paging: {
													rowsPerPage: 100,
													rowsPerPageOptions: [5, 10, 25, 50, 100]
												}
											}
										}
									}}
								/>
							</Grid>

							<Grid item xs={4}>
								<XsDateTimePicker
									field={this.form.$("confirmed_at")}
									showTimeSelect={false}
									onSelect={(val) => {
										if (isSafe(val)) {
											if (this.form.$("state").value == "S") {
												this.form.$("state").value = "A"
											} else {
												if (!this.form.$("confirmed_at_wasWarningOpened").value) {
													this.form.$("confirmed_at_wasWarningOpened").value = true
													WarningStore.open("Stav nie je správny, skontrolujte pole Stav!")
												}
											}
										}
									}}
								/>
							</Grid>

							<Grid item xs={4}>
								<XsDateTimePicker
									field={this.form.$("incoming_at")}
									showTimeSelect={false}
									onSelect={(val) => {
										if (isSafe(val)) {
											if (this.form.$("state").value == "A") {
												this.form.$("state").value = "E"
											} else {
												if (!this.form.$("incoming_at_wasWarningOpened").value) {
													this.form.$("incoming_at_wasWarningOpened").value = true
													WarningStore.open("Stav nie je správny, skontrolujte pole Stav!")
												}
											}
										}
									}}
								/>
							</Grid>

							<Grid item xs={4}></Grid>

							<Grid item xs={4}>
								<XsAutocomplete
									field={this.form.$("responsibleDoctor")}
									minLengthForSearch="3"
									api={api.loadPersonnelAndProvider}
									clearText
									filterValue={"personnel_expertise_id"}
									addSpecialFilter={() => {
										return [
											{
												associated_column: "has_contract",
												predicate: "=",
												values: [
													{
														id_value: true
													}
												]
											},
											{
												associated_column: "provider_id",
												predicate: "=",
												values: [
													{
														id_value: getSelectedOrgUnitID()
													}
												]
											}
										]
									}}
									inputRenderer={(obj, menuItem) =>
										(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem
											? "<b style=color:#00A787>"
											: "") +
										(obj.doctor_code ? obj.doctor_code + " / " : "") +
										obj.full_name +
										" / " +
										(obj.cl_expertise_code_ext ? obj.cl_expertise_code_ext + " " : "") +
										obj.cl_expertise_name_ext +
										(isNotEmpty(obj.provider_code) ? " / " + obj.provider_code : "") +
										(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem ? "</b>" : "")
									}
									saveValue={(obj) => obj.personnel_expertise_id}
									postAction={(obj, isLoaded) => {
										if (!isLoaded) {
											this.form.$("responsibleDoctorExpertiseId").value = ""
											this.form.$("responsibleDoctorPersonnelId").value = ""
										}
										this.form.$("responsibleDoctorExpertiseId").value = obj.cl_expertise_id
										this.form.$("responsibleDoctorPersonnelId").value = obj.personnel_id
									}}
									onClear={() => {
										this.form.$("responsibleDoctorExpertiseId").value = ""
										this.form.$("responsibleDoctorPersonnelId").value = ""
									}}
								/>
							</Grid>

							<Grid item xs={4}>
								<XsAutocomplete
									field={this.form.$("sendingDoctorPersonnelExpertiseId")}
									minLengthForSearch="3"
									api={api.loadPersonnelAndProvider}
									clearText
									filterValue={"personnel_expertise_id"}
									addSpecialFilter={() => {
										return [
											{
												associated_column: "has_contract",
												predicate: "=",
												values: [
													{
														id_value: true
													}
												]
											}
										]
									}}
									inputRenderer={(obj, menuItem) =>
										(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem
											? "<b style=color:#00A787>"
											: "") +
										(obj.doctor_code ? obj.doctor_code + " / " : "") +
										obj.full_name +
										" / " +
										(obj.cl_expertise_code_ext ? obj.cl_expertise_code_ext + " " : "") +
										obj.cl_expertise_name_ext +
										(isNotEmpty(obj.provider_code) ? " / " + obj.provider_code : "") +
										(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem ? "</b>" : "")
									}
									saveValue={(obj) => obj.personnel_expertise_id}
									postAction={(obj, isLoaded) => {
										if (!isLoaded) {
											this.form.$("sendingDoctorExpertiseId").value = ""
											this.form.$("sendingDoctorPersonnelId").value = ""
											this.form.$("sendingDoctorOUPZS").value = isNotEmpty(obj.provider_id) ? obj.provider_id : ""
										}
										this.form.$("sendingDoctorPersonnelId").value = obj.personnel_id
										this.form.$("sendingDoctorExpertiseId").value = obj.cl_expertise_id
									}}
									onClear={() => {
										this.form.$("sendingDoctorExpertiseId").value = ""
										this.form.$("sendingDoctorPersonnelId").value = ""
										this.form.$("sendingDoctorOUPZS").value = ""
									}}
								/>
							</Grid>

							<Grid item xs={4}>
								<XsAutocomplete
									field={this.form.$("sendingDoctorOUPZS")}
									minLengthForSearch="3"
									clearText
									api={api.loadProviders}
									disabled={isEmpty(this.form.$("sendingDoctorPersonnelExpertiseId").value)}
									filterValue={"provider_id"}
									inputRenderer={(obj) =>
										(obj.provider_code ? obj.provider_code + " / " : "") +
										obj.company_name +
										" / " +
										(obj.expertise_code ? obj.expertise_code + " " : "") +
										obj.expertise_name_ext
									}
									saveValue={(obj) => obj.provider_id}
								/>
							</Grid>

							<Grid item xs={4}>
								<XsSearchSelect
									field={this.form.$("delivery")}
									items={HospicomDialogStore.deliveries}
									getOptionLabel={(x) => x.code_ext + " " + x.name_ext}
									getOptionValue={(x) => x.code_ext + " " + x.name_ext}
									onChange={(deliveryId) => HospicomDialogStore.loadDeliveryPrice(this.form, deliveryId)}
									required
								/>
								{/* <XsAutocomplete
									field={this.form.$("delivery")}
									minLengthForSearch="1"
									clearText
									api={api.loadDeliveries}
									searchFilter={"code_ext"}
									loadApi={api.loadSpecificDelivery}
									renderFooterInModal={true}
									inputRenderer={(obj) => obj.code_ext + "-" + obj.name_ext}
									saveValue={(obj) => obj.code}
									modalConfig={(textValue, clickHandler) => {
										return {
											columnDefs: {
												code_ext: {
													title: <FormattedMessage id="Patient.form.patientrecord.deliveryTable.code_ext" />,
													design: {
														width: "10%"
													},
													type: "string",
													dbName: "code_ext",
													filter: {
														gridWidth: 3,
														defaultValue: "",
														renderElement: "input"
													}
												},
												name_ext: {
													title: <FormattedMessage id="Patient.form.patientrecord.deliveryTable.name_ext" />,
													type: "string",
													dbName: "search_column",
													design: {
														width: "90%"
													},
													filter: {
														gridWidth: 3,
														defaultValue: textValue,
														renderElement: "input"
													}
												}
											},
											options: {
												showCursor: true,
												onRowClick: (dataRow) => {
													clickHandler(dataRow)
												}
											}
										}
									}}
								/> */}
							</Grid>

							<Grid item xs={4}>
								<XsNumberInput field={this.form.$("expectedPrice")} type={"number"} min={"0.01"} step={"0.01"} />
							</Grid>

							<Grid item xs={4}>
								<XsSearchSelect field={this.form.$("organType")} items={GlobalStore.CL["organType"]} />
							</Grid>

							<Grid item xs={12}>
								<XsInput field={this.form.$("internalNote")} />
							</Grid>

							<Grid item xs={12}>
								<XsInput field={this.form.$("noteForHospicom")} />
							</Grid>
						</Grid>
						<Grid container item>
							<Grid item className="xs-bold pt-3 pb-2">
								Použitý liek / pomôcka
							</Grid>
						</Grid>
						<Grid container item spacing={8}>
							<Grid item xs={4}>
								<XsSearchSelect
									menuPlacement="top"
									field={this.form.$("drugsType")}
									items={[
										{name_ext: "Hromadne vyrábané lieky", _id: "EHR.CL.Medication.SK.Drug"},
										{name_ext: "Zdravotné pomôcky", _id: "EHR.CL.Medication.SK.MedicalDevice"}
									]}
									onChange={(val) => {
										this.form.$("drugsAmout").value = null
										this.form.$("drugs").value = null
										this.form.$("medEquip").value = null
										this.form.$("drugPrice").value = null

										this.form.$("drugsAmout").set("rules", "")
										this.form.$("drugs").set("rules", "")
										this.form.$("medEquip").set("rules", "")
										this.form.$("drugPrice").set("rules", "")

										if (val == "EHR.CL.Medication.SK.Drug") {
											this.form.$("drugsAmout").set("rules", "required")
											this.form.$("drugs").set("rules", "required")
											this.form.$("drugPrice").set("rules", "required")
										} else if (val == "EHR.CL.Medication.SK.MedicalDevice") {
											this.form.$("drugsAmout").set("rules", "required")
											this.form.$("medEquip").set("rules", "required")
											this.form.$("drugPrice").set("rules", "required")
										}
									}}
									onClear={() => {
										this.form.$("drugsAmout").set("rules", "")
										this.form.$("drugs").set("rules", "")
										this.form.$("medEquip").set("rules", "")
										this.form.$("drugPrice").set("rules", "")
									}}
								/>
							</Grid>
						</Grid>

						{isNotEmpty(this.form.$("drugsType").value) && (
							<Grid container item spacing={8}>
								{this.form.$("drugsType").value == "EHR.CL.Medication.SK.Drug" && (
									<Grid item xs={4}>
										<XsAutocomplete
											field={this.form.$("drugs")}
											maxWidthMenu="750"
											placementMenu="top"
											minLengthForSearch="3"
											api={api.loadDrugs}
											removePunctuation={true}
											clearText
											renderFooterInModal={true}
											postAction={(obj, loaded) => {
												if (!loaded) {
													this.form.$("drugPrice").value = obj.patient_payment
												}
											}}
											inputRenderer={(obj, menuItem) =>
												(menuItem && obj.has_limitation
													? "<span style=color:#FFB900>" + obj.code_ext + "</span>"
													: obj.code_ext) +
												" | " +
												(menuItem && obj.has_limitation
													? "<span style=color:#FFB900>" + obj.name_ext + "</span>"
													: obj.name_ext) +
												" | " +
												obj.supplement +
												" | " +
												(obj.patient_payment
													? (menuItem ? "<span style=color:#01579b>" : "") +
													  obj.patient_payment +
													  (menuItem ? "</span>" : "") +
													  " | "
													: "") +
												(obj.insurer_payment
													? (menuItem ? "<span style=color:#00A787>" : "") +
													  obj.insurer_payment +
													  (menuItem ? "</span>" : "") +
													  " | "
													: "") +
												(obj.price ? (menuItem ? "<b>" : "") + obj.price + (menuItem ? "</b>" : "") + " | " : "") +
												obj.atc +
												" | " +
												obj.active_ingredient +
												" | " +
												obj.registrator_name
											}
											saveValue={(obj) => obj.code}
											modalConfig={(textValue, clickHandler) => {
												return {
													columnDefs: {
														code_ext: {
															title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.code_ext" />,
															type: "string",
															dbName: "code_ext"
														},
														name_ext: {
															title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.name_ext" />,
															type: "string",
															dbName: "search_column",
															filterLabel: <FormattedMessage id="Common.label.search" />,
															filter: {
																gridWidth: 3,
																defaultValue: textValue,
																renderElement: "input"
															},
															sortable: true,
															design: {
																body: {
																	className: (row) => (row.has_limitation ? "xs-amberWarning" : null)
																}
															}
														},
														//Virtulany stlpec ktory sa nezobrazuje sluzi len na naviazanie filtra
														name_extFilter: {
															title: <FormattedMessage id="Delivery.list.modal.drugName" />,
															type: "string",
															dbName: "name_ext",
															filter: {
																gridWidth: 3,
																defaultValue: "",
																renderElement: "input"
															},
															design: {
																width: "0px",
																body: {
																	className: "hide"
																},
																header: {
																	className: "hide"
																}
															}
														},
														supplement: {
															title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />,
															type: "string",
															dbName: "supplement"
														},
														patient_payment: {
															title: (
																<FormattedMessage id="Patient.form.patientPrescription.drugsTable.patient_payment" />
															),
															type: "string",
															dbName: "patient_payment"
														},
														insurer_payment: {
															title: (
																<FormattedMessage id="Patient.form.patientPrescription.drugsTable.insurer_payment" />
															),
															type: "string",
															dbName: "insurer_payment"
														},
														atc: {
															title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.ATC" />,
															type: "string",
															dbName: "atc"
														},
														active_ingredient: {
															title: (
																<FormattedMessage id="Patient.form.patientPrescription.drugsTable.active_ingredient" />
															),
															type: "string",
															dbName: "active_ingredient"
														},
														registrator_name: {
															title: <FormattedMessage id="Common.label.registratorName" />,
															type: "string",
															dbName: "registrator_name"
														}
													},
													options: {
														// selectRow: true,
														showCursor: true,
														onRowClick: (dataRow) => {
															clickHandler(dataRow)
														},
														renderHeaderAsFirstRow: true,
														// row_count_full: 100,
														findSameATC: true,
														paging: {
															rowsPerPage: 100,
															rowsPerPageOptions: [5, 10, 25, 50, 100]
														}
													}
												}
											}}
										/>
									</Grid>
								)}

								{this.form.$("drugsType").value == "EHR.CL.Medication.SK.MedicalDevice" && (
									<Grid item xs={4}>
										<XsAutocomplete
											field={this.form.$("medEquip")}
											maxWidthMenu="750"
											placementMenu="top"
											minLengthForSearch="3"
											api={api.loadMedDev}
											clearText
											inputRenderer={(obj, menuItem) =>
												(menuItem && obj.has_limitation
													? "<span style=color:#FFB900>" + obj.code_ext + "</span>"
													: obj.code_ext) +
												" | " +
												(menuItem && obj.has_limitation
													? "<span style=color:#FFB900>" + obj.name_ext + "</span>"
													: obj.name_ext) +
												" | " +
												(obj.supplement ? obj.supplement + " | " : "") +
												(obj.patient_payment
													? (menuItem ? "<span style=color:#01579b>" : "") +
													  obj.patient_payment +
													  (menuItem ? "</span>" : "") +
													  " | "
													: "") +
												(obj.insurer_payment
													? (menuItem ? "<span style=color:#00A787>" : "") +
													  obj.insurer_payment +
													  (menuItem ? "</span>" : "") +
													  " | "
													: "") +
												(obj.price ? (menuItem ? "<b>" : "") + obj.price + (menuItem ? "</b>" : "") + " | " : "")
											}
											saveValue={(obj) => obj.code}
											postAction={(value, loaded) => {
												if (!loaded) {
													this.form.$("drugPrice").value = value.patient_payment
												}
											}}
											onClear={() => {
												this.form.$("drugPrice").value = null
											}}
											modalConfig={(textValue, clickHandler) => {
												return {
													columnDefs: {
														code_ext: {
															title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.code_ext" />,
															type: "string",
															dbName: "code_ext"
														},
														name_ext: {
															title: <FormattedMessage id="Common.label.medDev" />,
															type: "string",
															dbName: "search_column",
															filter: {
																gridWidth: 3,
																defaultValue: textValue,
																renderElement: "input"
															},
															sortable: true,
															design: {
																body: {
																	className: (row) => (row.has_limitation ? "xs-amberWarning" : null)
																}
															}
														},
														supplement: {
															title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />,
															type: "string",
															dbName: "supplement"
														},
														patient_payment: {
															title: (
																<FormattedMessage id="Patient.form.patientPrescription.drugsTable.patient_payment" />
															),
															type: "string",
															dbName: "patient_payment"
														},
														insurer_payment: {
															title: (
																<FormattedMessage id="Patient.form.patientPrescription.drugsTable.insurer_payment" />
															),
															type: "string",
															dbName: "insurer_payment"
														}
													},
													options: {
														// selectRow: true,
														showCursor: true,
														onRowClick: (dataRow) => {
															clickHandler(dataRow)
														},
														renderHeaderAsFirstRow: true
													}
												}
											}}
										/>
									</Grid>
								)}
								<Grid item xs={4}>
									<XsNumberInput field={this.form.$("drugsAmout")} type={"number"} min={"0.01"} step={"0.01"} />
								</Grid>

								<Grid item xs={4}>
									<XsNumberInput field={this.form.$("drugPrice")} type={"number"} min={"0.01"} step={"0.01"} />
								</Grid>
							</Grid>
						)}
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer">
					<Grid container justify="space-between">
						<Grid item>
							<XsButton
								className="xs-danger xs-outline"
								icon={<i className="fal fa-times fa-lg" />}
								text={<FormattedMessage id="Common.label.cancel" />}
								onClick={() => HospicomDialogStore.close(this.form)}
							/>
						</Grid>
						<Grid item>
							{isNotEmpty(this.form.$("recordId").value) &&
								(this.form.$("loadState").value == "N" || this.form.$("loadState").value == "P") && (
									<XsButton
										className="xs-danger"
										icon={<i className="fal fa-trash" />}
										text={<FormattedMessage id="Common.label.storno" />}
										onClick={() => {
											GlobalStore.openConfirmationDialog("stornoHospicomDialogConfirmation", {
												id: this.form.$("recordId").value
											})
										}}
									/>
								)}
							<XsButton
								className="xs-success action-confirmation-confirm ml-2"
								icon={<i className="fal fa-save" />}
								text={<FormattedMessage id="Common.label.save" />}
								onClick={(e) => {
									this.form.onSubmit(e)
								}}
							/>
						</Grid>
					</Grid>
				</DialogActions>
				<XsConfirmationDialog
					type="danger"
					name="stornoHospicomDialogConfirmation"
					text={<FormattedMessage id="Common.label.stornoHospicom" />}
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.storno"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.label.storno"
					}}
					onConfirmation={() => HospicomDialogStore.storno(GlobalStore.confirmationDialogParams.id)}
				/>
			</Dialog>
		)
	}
}
