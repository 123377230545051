import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import moment from "moment"

import {TextField, Grid} from "@material-ui/core"

import XsButton from "../../../../global/ui/xsButton/xsButton"
import SearchSelect from "../../../../global/ui/xsSearchSelect/xsSearchSelect"
import AddEventDialogStore from "../../stores/AddEventDialogStore"
import CalendarStore from "../../stores/CalendarStore"
import WarningStore from "../../../../global/store/WarningStore"
import {getUser} from "../../../../global/helpers/actions"
// import GlobalStore from "../../../../global/store/GlobalStore"

import AddEventOrders from "./addEventOrders"
import AddEventSlots from "./addEventSlots"
import AddEventHeader from "./addEventHeader"
import AddEventCalendar from "./addEventCalendar"

@injectIntl
@observer
export default class AddEvent extends React.Component {
	constructor(props) {
		super(props)
	}

	onChangeInputChange = (e) => {
		CalendarStore.inputDateTimeIsValid = e.target.value
	}

	onBlurInputChange = (e) => {
		if (
			moment(e.target.value, "DD.MM.YYYY HH:mm", true).isValid() &&
			moment(e.target.value, "DD.MM.YYYY HH:mm").isSameOrAfter(moment(), "DD.MM.YYYY HH:mm")
		) {
			CalendarStore.selectedDay = moment(e.target.value, "DD.MM.YYYY HH:mm").set(
				"minute",
				CalendarStore.getRoundMinutes(moment(e.target.value, "DD.MM.YYYY HH:mm"))
			) //moment(CalendarStore.getRoundTime(moment(e.target.value))) //moment(e.target.value, "DD.MM.YYYY HH:mm")
			CalendarStore.inputDateTimeIsValid = CalendarStore.getRoundTime(moment(e.target.value, "DD.MM.YYYY HH:mm"))
			AddEventDialogStore.setSlots()
		} else {
			CalendarStore.inputDateTimeIsValid = CalendarStore.getRoundTime(CalendarStore.selectedDay) //.format("DD.MM.YYYY HH:mm")
		}
	}

	render() {
		const user = getUser()
		const setScheduleToPastInMins =
			isSafe(user) && isSafe(user.sys_params) && isNotEmpty(user.sys_params.SetScheduleToPastInMins)
				? +user.sys_params.SetScheduleToPastInMins
				: undefined

		return (
			<div className="xs-dialog-content xs-second-step">
				<Grid container direction="column" className="xs-left" justify="space-between">
					<Grid item container direction="column" justify="space-between" className="xs-flex">
						<AddEventHeader />
						<Grid item container justify="space-between" spacing={8} className="pl-4 pr-4">
							<Grid item xs={8}>
								<SearchSelect
									items={AddEventDialogStore.serviceData.map((resource) => {
										return {label: resource.name_ext, value: resource.code}
									})}
									isValid={isSafe(AddEventDialogStore.serviceObj) && isSafe(AddEventDialogStore.serviceObj.value)}
									onChange={(obj) => AddEventDialogStore.handleChangeResource(obj)}
									value={AddEventDialogStore.serviceObj}
									hideLabel={true}
									required
								/>
							</Grid>
							<Grid item xs={4}>
								<TextField
									className="xs-textfield-white dateTimeInputTour"
									value={CalendarStore.inputDateTimeIsValid}
									onChange={(e) => this.onChangeInputChange(e)}
									onBlur={(e) => this.onBlurInputChange(e)}
								/>
							</Grid>
						</Grid>
						<AddEventCalendar setScheduleToPastInMins={setScheduleToPastInMins} />
						<Grid item className="pr-4 pl-4">
							<TextField
								value={AddEventDialogStore.note}
								className="xs-textfield-white noteTour"
								placeholder={this.props.intl.formatMessage({id: "Calendar.addEvent.dialog.note.placeholder"})}
								onChange={(e) => AddEventDialogStore.handleChangeNote(e)}
							/>
						</Grid>
						<Grid item container justify="flex-end" alignItems="center" spacing={16} className="pr-4 pl-4 pb-2">
							<Grid item>
								<XsButton
									className="xs-success xs-outline"
									text={<FormattedMessage id="Common.label.saveAndAddNewEvent" />}
									icon={<i className="fal fa-check" />}
									onClick={() => {
										if (
											moment()
												.add(isSafe(setScheduleToPastInMins) ? -setScheduleToPastInMins : -30, "minutes")
												.isAfter(
													moment(CalendarStore.selectedDay)
														.set("second", 0)
														.utc()
												)
										) {
											WarningStore.open(
												`${this.props.intl.formatMessage({id: "Calendar.addEvent.dialog.eventInPast"})}`
											)
										} else if (
											isEmpty(AddEventDialogStore.serviceObj) ||
											isEmpty(AddEventDialogStore.serviceObj.value)
										) {
											WarningStore.open(
												`${this.props.intl.formatMessage({
													id: "Common.label.createService"
												})}`
											)
										} else {
											AddEventDialogStore.saveAndAddNewEvent()
										}
									}}
								/>
							</Grid>
							{isNotSafe(AddEventDialogStore.unknowPatientName) && (
								<Grid item className="saveAndeOpenTour">
									<XsButton
										className="xs-success xs-outline"
										text={<FormattedMessage id="Common.label.saveAndOpenCard" />}
										icon={<i className="fal fa-check" />}
										onClick={() => {
											if (
												moment()
													.add(isSafe(setScheduleToPastInMins) ? -setScheduleToPastInMins : -30, "minutes")
													.isAfter(
														moment(CalendarStore.selectedDay)
															.set("second", 0)
															.utc()
													)
											) {
												WarningStore.open(
													`${this.props.intl.formatMessage({id: "Calendar.addEvent.dialog.eventInPast"})}`
												)
											} else if (
												isEmpty(AddEventDialogStore.serviceObj) ||
												isEmpty(AddEventDialogStore.serviceObj.value)
											) {
												WarningStore.open(
													`${this.props.intl.formatMessage({
														id: "Common.label.createService"
													})}`
												)
											} else {
												AddEventDialogStore.save(true)
											}
										}}
									/>
								</Grid>
							)}
							<Grid item className="saveTour">
								<XsButton
									className="xs-success"
									text={<FormattedMessage id="Common.label.save" />}
									icon={<i className="fal fa-check" />}
									onClick={() => {
										if (
											moment()
												.add(isSafe(setScheduleToPastInMins) ? -setScheduleToPastInMins : -30, "minutes")
												.isAfter(
													moment(CalendarStore.selectedDay)
														.set("second", 0)
														.utc()
												)
										) {
											WarningStore.open(
												`${this.props.intl.formatMessage({id: "Calendar.addEvent.dialog.eventInPast"})}`
											)
										} else if (
											isEmpty(AddEventDialogStore.serviceObj) ||
											isEmpty(AddEventDialogStore.serviceObj.value)
										) {
											WarningStore.open(
												`${this.props.intl.formatMessage({
													id: "Common.label.createService"
												})}`
											)
										} else {
											AddEventDialogStore.save()
										}
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item container alignItems="center" className="xs-footer bgGreyLight greyDefault pl-4 pr-4">
						<Grid item className={AddEventDialogStore.isWarningMessage ? "xs-title xs-warning" : "xs-title"}>
							{AddEventDialogStore.isWarningMessage ? (
								<span className="xs-war">
									<i className="fas fa-exclamation-triangle" /> <FormattedMessage id="Common.error.warning" />{" "}
								</span>
							) : (
								""
							)}
							<FormattedMessage id={AddEventDialogStore.headerMessage} />
						</Grid>
					</Grid>
				</Grid>
				<div className="xs-right">
					<AddEventOrders />
					<AddEventSlots />
				</div>
			</div>
		)
	}
}
