import React from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import {observer} from "mobx-react"
import moment from "moment"
import Grid from "@material-ui/core/Grid"
import RouterStore from "../../../../../global/store/RouterStore"

import api from "../../../actions/api"
import XsTableServer from "../../../../../global/ui/xsTableServer/xsTableServer"
// import {padStart} from "../../../../../global/helpers/functions"
// import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import ExportCapitationDialogStore from "../../../stores/ExportCapitationDialogStore"
// import {StorageBase} from "../../../../../global/storage/storageEx"
import CapitationListFilter from "./capitationListFilter"
import CapitationListStore from "../../../stores/CapitationListStore"
import TableStore from "../../../stores/TableStore"

@injectIntl
@observer
export default class CapitationList extends React.Component {
	constructor(props) {
		super(props)
	}

	onHandleRowClick = (dataRow) => {
		RouterStore.push(`/patient?id=${dataRow.person_id}`)
	}

	// handleStorageLoad = (storageFilters) => {
	// 	this.setState(
	// 		{
	// 			filters: {
	// 				validity_from: storageFilters.validity_from,
	// 				validity_to: storageFilters.validity_to,
	// 				insurer: storageFilters.insurer,
	// 				identifier: storageFilters.identifier
	// 			}
	// 		},
	// 		() => {
	// 			if (isSafe(this.state.filters.insurer) && this.state.filters.insurer != 0) {
	// 				this.setState({
	// 					disabledExport: false
	// 				})
	// 			}
	// 		}
	// 	)
	// 	if (isSafe(storageFilters.validity_to) && isSafe(storageFilters.validity_to.split("-"))) {
	// 		let year = +storageFilters.validity_to.split("-")[0]
	// 		let month = +storageFilters.validity_to.split("-")[1] - 1
	// 		this.periodPickerRef.refs.wrappedInstance.setValues(year, month)
	// 	}
	// }

	render() {
		return (
			<Grid container>
				<CapitationListFilter />
				<Grid item xs={12}>
					{CapitationListStore.changedFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i
								className="far fa-search fa-lg mr-3 pointer"
								onClick={() => {
									if (
										isNotEmpty("capitationList") &&
										TableStore.tables.hasOwnProperty("capitationList") &&
										TableStore.tables["capitationList"].hasOwnProperty("index")
									) {
										delete TableStore.tables["capitationList"]["index"]
									}

									CapitationListStore.getFilters(CapitationListStore.formRef.values())
								}}
							></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTableServer
							setRef={(table) => (ExportCapitationDialogStore.capitationListRef = table)}
							optimizedData={true}
							uri={`${api.loadCapitation(null, "P").getPath()}`}
							filterValue={CapitationListStore.filters}
							config={{
								columnDefs: {
									full_name: {
										title: <FormattedMessage id="Common.label.firstName" />,
										type: "string",
										design: {
											width: "40%",
											body: {
												className: "blueDark xs-bold"
											}
										},
										sortable: true,
										dbName: "full_name"
									},
									identifier: {
										title: <FormattedMessage id="Patient.form.patientinfo.identifier" />,
										type: "number",
										design: {
											width: "15%"
										},
										sortable: true,
										dbName: "identifier"
									},
									from: {
										title: <FormattedMessage id="Common.label.from" />,
										type: "string",
										design: {
											width: "15%",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format("DD.MM.YYYY") : ""
												}
											}
										},
										dbName: "from",
										sortable: true
									},
									to: {
										title: <FormattedMessage id="Common.label.to" />,
										type: "string",
										design: {
											width: "15%",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format("DD.MM.YYYY") : ""
												}
											}
										},
										dbName: "to",
										sortable: true
									},
									insurer: {
										title: <FormattedMessage id="Common.label.insurer" />,
										type: "string",
										design: {
											width: "15%"
										},
										sortable: false
									}
								},
								options: {
									name: "capitationList",
									showCursor: true,
									mapper: (dataRow) => {
										return {
											person_id: dataRow.person_id,
											full_name: dataRow.full_name,
											identifier: dataRow.identifier,
											from: dataRow.from,
											to: dataRow.to,
											insurer: isSafe(dataRow.insurer) ? dataRow.insurer.name_ext : ""
										}
									},
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									},
									paging: {
										rowsPerPageOptions: [10, 15]
									}
								}
							}}
						/>
					)}
				</Grid>
			</Grid>
		)
	}
}
