import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"

import fields from "./deadReportFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsCheckbox from "../../../../../../global/ui/xsCheckbox/xsCheckbox"
import XsAutocompleteLocal from "../../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegisterCacheStore from "../../../../../../global/store/RegistersCacheStore"

@injectIntl
@observer
export default class DeadReportTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		if (isEmpty(this.form.$("countyForm").value) && isNotEmpty(this.form.$("county").value)) {
			this.form.$("countyForm").value = this.form.$("county").value
		}
		if (isEmpty(this.form.$("cityForm").value) && isNotEmpty(this.form.$("city").value)) {
			this.form.$("cityForm").value = this.form.$("city").value
		}
		if (isEmpty(this.form.$("streetForm").value) && isNotEmpty(this.form.$("street").value)) {
			this.form.$("streetForm").value = this.form.$("street").value
		}
		if (isEmpty(this.form.$("house_numberForm").value) && isNotEmpty(this.form.$("house_number").value)) {
			this.form.$("house_numberForm").value = this.form.$("house_number").value
		}
		if (isEmpty(this.form.$("countryForm").value) && isNotEmpty(this.form.$("country").value)) {
			this.form.$("countryForm").value = this.form.$("country").value
		}
	}

	render() {
		return (
			<div className="pa-4">
				<Grid container direction="column" align="top">
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={1}>
							<XsInput field={this.form.$("rok")} maxLength={4} />
						</Grid>
						<Grid item xs={1}>
							<XsInput field={this.form.$("mesiac")} maxLength={2} />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={6}>
							<XsInput field={this.form.$("hlaseniePreSU")} maxLength={3} />
						</Grid>
						<Grid item xs={6}>
							<XsInput field={this.form.$("umrtieNaNebezpecnuChorobu")} maxLength={3} />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={6}>
							<XsInput field={this.form.$("rodnePriezvisko")} maxLength={30} />
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.form.$("datumUmrtia")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("hodinaUmrtia")} />
						</Grid>
					</Grid>

					{/* <Grid container direction="row" align="left" spacing={8}>
            <Grid item xs={6}><XsInput field={this.form.$("statOkresObecUmrtia")} maxLength={40}  /></Grid>
            <Grid item xs={6}><XsInput field={this.form.$("miestoUmrtia")} maxLength={40} labelStyle={{width: "1000px", position: "relative", top: "12px"}} /></Grid>
          </Grid> */}

					<Grid item xs={12}>
						<XsInput field={this.form.$("statOkresObecUmrtia")} maxLength={40} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("miestoUmrtia")} maxLength={40} />
					</Grid>

					<Grid item xs={12}>
						<XsInput field={this.form.$("miestoNarodenia")} maxLength={40} />
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						{/* <Grid item xs={1}><XsInput field={this.form.$("pohlavie")} /></Grid>
            <Grid item xs={2}><XsInput field={this.form.$("pohlavieSlovom")} /></Grid> */}
						<Grid item xs={4}>
							<XsInput field={this.form.$("statneObcianstvo")} maxLength={30} />
						</Grid>
						<Grid item xs={4}>
							<XsInput field={this.form.$("narodnost")} maxLength={30} />
						</Grid>
					</Grid>

					<div style={{marginTop: "40px", marginBottom: "20px", fontSize: "13px"}}>
						<FormattedMessage id="PrintTemplate.DeadReport.trvaly_pobyt" />
					</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={6}>
							<XsInput field={this.form.$("countyForm")} maxLength={40} />
						</Grid>
						<Grid item xs={6}>
							<XsInput field={this.form.$("cityForm")} maxLength={40} />
						</Grid>
					</Grid>
					<Grid container direction="row" align="left" spacing={8} style={{marginBottom: "20px"}}>
						<Grid item xs={5}>
							<XsInput field={this.form.$("streetForm")} maxLength={40} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("house_numberForm")} maxLength={40} />
						</Grid>
						<Grid item xs={5}>
							<XsInput field={this.form.$("countryForm")} maxLength={40} />
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<XsInput field={this.form.$("dosiahnuteVzdelanie")} maxLength={1} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("zamestnanie")} />
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<div style={{paddingTop: "15px"}}>
								<FormattedMessage id="PrintTemplate.DeadReport.FormLabel1" />
							</div>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("pohrebSpopolnenimPovoluje")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("pohrebSpopolnenimNepovoluje")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("pohrebSpopolnenimV")} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker field={this.form.$("pohrebSpopolnenimDatum")} showTimeSelect={false} />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<div style={{paddingTop: "15px"}}>
								<FormattedMessage id="PrintTemplate.DeadReport.FormLabel2" />
							</div>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("prevozNaPochovaniePovoluje")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("prevozNaPochovanieNepovoluje")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("prevozNaPochovanieV")} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker field={this.form.$("prevozNaPochovanieDatum")} showTimeSelect={false} />
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<XsInput field={this.form.$("stav")} maxLength={1} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("rcPozostalehoManzela")} />
					</Grid>

					<div style={{marginTop: "40px", marginBottom: "20px"}}>
						<FormattedMessage id="PrintTemplate.DeadReport.FormLabel3" />
					</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={6}>
							<XsInput field={this.form.$("detiDlzkaZivotaHodiny")} maxLength={2} labelStyle={{width: "500px"}} />
						</Grid>
						<Grid item xs={6}>
							<XsInput field={this.form.$("detiKdeNastaloUmrtie")} maxLength={1} />
						</Grid>
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={6}>
							<XsInput field={this.form.$("detiPorodnaHmotnost")} />
						</Grid>
						<Grid item xs={6}>
							<XsInput field={this.form.$("detiOtecManzelom")} maxLength={1} />
						</Grid>
					</Grid>

					<div style={{marginTop: "40px", marginBottom: "20px"}}>
						<FormattedMessage id="PrintTemplate.DeadReport.FormLabel4" />
					</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsAutocompleteLocal
								field={this.form.$("kodIDChoroba")}
								minLengthForSearch="1"
								data={RegisterCacheStore.diagnosisRegister}
								inputRenderer={(obj) => {
									this.form.$("kodChoroba").value = obj.code_ext + " " + obj.name_ext
									return obj.code_ext + " " + obj.name_ext
								}}
								saveValue={(obj) => obj.code}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<XsAutocompleteLocal
								field={this.form.$("kodIDPredchadzajucePriciny")}
								minLengthForSearch="1"
								data={RegisterCacheStore.diagnosisRegister}
								inputRenderer={(obj) => {
									this.form.$("kodPredchadzajucePriciny").value = obj.code_ext + " " + obj.name_ext
									return obj.code_ext + " " + obj.name_ext
								}}
								saveValue={(obj) => obj.code}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<XsAutocompleteLocal
								field={this.form.$("kodIDPrvotnaPricina")}
								minLengthForSearch="1"
								data={RegisterCacheStore.diagnosisRegister}
								inputRenderer={(obj) => {
									this.form.$("kodPrvotnaPricina").value = obj.code_ext + " " + obj.name_ext
									return obj.code_ext + " " + obj.name_ext
								}}
								saveValue={(obj) => obj.code}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsAutocompleteLocal
								field={this.form.$("kodIDIneChorobneStavy")}
								minLengthForSearch="1"
								data={RegisterCacheStore.diagnosisRegister}
								inputRenderer={(obj) => {
									this.form.$("kodIneChorobneStavy").value = obj.code_ext + " " + obj.name_ext
									return obj.code_ext + " " + obj.name_ext
								}}
								saveValue={(obj) => obj.code}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<div style={{paddingTop: "15px"}}>
								<FormattedMessage id="PrintTemplate.DeadReport.FormLabel5" />
							</div>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("mechanizmusSmrti1")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("mechanizmusSmrti2")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("mechanizmusSmrti3")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("mechanizmusSmrti4")} />
						</Grid>
					</Grid>

					<Grid item xs={6}>
						<XsAutocompleteLocal
							field={this.form.$("kodIDMechanizmusSmrti")}
							minLengthForSearch="1"
							data={RegisterCacheStore.diagnosisRegister}
							inputRenderer={(obj) => {
								this.form.$("kodMechanizmusSmrti").value = obj.code_ext + " " + obj.name_ext
								return obj.code_ext + " " + obj.name_ext
							}}
							saveValue={(obj) => obj.code}
							modalConfig={(textValue, clickHandler) => {
								return {
									columnDefs: {
										code_ext: {
											title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
											type: "string",
											dbName: "code_ext",
											design: {
												width: "150px"
											},
											filter: {
												gridWidth: 3,
												defaultValue: "",
												renderElement: "input"
											}
										},
										name_ext: {
											title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
											type: "string",
											dbName: "search_column",
											filter: {
												gridWidth: 9,
												defaultValue: textValue,
												renderElement: "input"
											}
										}
									},
									options: {
										showCursor: true,
										onRowClick: (dataRow) => {
											clickHandler(dataRow)
										}
									}
								}
							}}
						/>
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsInput field={this.form.$("miestoPrehliadky")} />
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.form.$("datumHodinaPrehliadky")} showTimeSelect={true} />
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<XsInput
							field={this.form.$("navrhPrehliadajucehoLekara")}
							maxLength={150}
							labelStyle={{width: "1000px", position: "relative", top: "12px"}}
						/>
					</Grid>

					<div style={{marginTop: "40px", marginBottom: "20px"}}>
						<FormattedMessage id="PrintTemplate.DeadReport.FormLabel6" />
					</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsAutocompleteLocal
								field={this.form.$("kodIDChorobaPitva")}
								minLengthForSearch="1"
								data={RegisterCacheStore.diagnosisRegister}
								inputRenderer={(obj) => {
									this.form.$("kodChorobaPitva").value = obj.code_ext + " " + obj.name_ext
									return obj.code_ext + " " + obj.name_ext
								}}
								saveValue={(obj) => obj.code}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<XsAutocompleteLocal
								field={this.form.$("kodIDPredchadzajucePricinyPitva")}
								minLengthForSearch="1"
								data={RegisterCacheStore.diagnosisRegister}
								inputRenderer={(obj) => {
									this.form.$("kodPredchadzajucePricinyPitva").value = obj.code_ext + " " + obj.name_ext
									return obj.code_ext + " " + obj.name_ext
								}}
								saveValue={(obj) => obj.code}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<XsAutocompleteLocal
								field={this.form.$("kodIDPrvotnaPricinaPitva")}
								minLengthForSearch="1"
								data={RegisterCacheStore.diagnosisRegister}
								inputRenderer={(obj) => {
									this.form.$("kodPrvotnaPricinaPitva").value = obj.code_ext + " " + obj.name_ext
									return obj.code_ext + " " + obj.name_ext
								}}
								saveValue={(obj) => obj.code}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsAutocompleteLocal
								field={this.form.$("kodIDIneChorobneStavyPitva")}
								minLengthForSearch="1"
								data={RegisterCacheStore.diagnosisRegister}
								inputRenderer={(obj) => {
									this.form.$("kodIneChorobneStavyPitva").value = obj.code_ext + " " + obj.name_ext
									return obj.code_ext + " " + obj.name_ext
								}}
								saveValue={(obj) => obj.code}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<div style={{paddingTop: "15px"}}>
								<FormattedMessage id="PrintTemplate.DeadReport.FormLabel7" />
							</div>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("mechanizmusSmrti1Pitva")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("mechanizmusSmrti2Pitva")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("mechanizmusSmrti3Pitva")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("mechanizmusSmrti4Pitva")} />
						</Grid>
					</Grid>

					<Grid item xs={6}>
						<XsAutocompleteLocal
							field={this.form.$("kodIDMechanizmusSmrtiPitva")}
							minLengthForSearch="1"
							data={RegisterCacheStore.diagnosisRegister}
							inputRenderer={(obj) => {
								this.form.$("kodMechanizmusSmrtiPitva").value = obj.code_ext + " " + obj.name_ext
								return obj.code_ext + " " + obj.name_ext
							}}
							saveValue={(obj) => obj.code}
							modalConfig={(textValue, clickHandler) => {
								return {
									columnDefs: {
										code_ext: {
											title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
											type: "string",
											dbName: "code_ext",
											design: {
												width: "150px"
											},
											filter: {
												gridWidth: 3,
												defaultValue: "",
												renderElement: "input"
											}
										},
										name_ext: {
											title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
											type: "string",
											dbName: "search_column",
											filter: {
												gridWidth: 9,
												defaultValue: textValue,
												renderElement: "input"
											}
										}
									},
									options: {
										showCursor: true,
										onRowClick: (dataRow) => {
											clickHandler(dataRow)
										}
									}
								}
							}}
						/>
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsInput field={this.form.$("miestoPitvy")} />
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.form.$("datumHodinaPitvy")} showTimeSelect={true} />
						</Grid>
					</Grid>
				</Grid>
			</div>
		)
	}
}
