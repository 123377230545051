export const insertSchemeWithRels = {
	load: {
		storeKey: "PatientConsent",
		params: ["consentId"],
		type: "CRM.Data.ConsentPackage",
		flags: "CD"
	},
	save: ["CRM.Data.ConsentPackage"],
	scheme: {
		name: {
			field: "name"
		},
		consents: {
			array: {
				load: (item) => item.active,
				descriptor: {
					_type: "CRM.Data.ConsentInPackage",
					_ref: false
				},
				scheme: {
					relships: {
						relation: "@relships"
					},
					type: {
						codeList: {_id: "type"}
					},
					validity: {
						object: {
							scheme: {
								from: {
									field: "from"
								},
								to: {
									field: "to"
								}
							}
						}
					}
				}
			}
		}
	}
}

export const defaultScheme = {
	load: {
		storeKey: "PatientConsent",
		params: ["consentId"],
		type: "CRM.Data.ConsentPackage",
		flags: "CD"
	},
	save: ["CRM.Data.ConsentPackage"],
	scheme: {
		name: {
			field: "name"
		},
		consents: {
			array: {
				load: (item) => item.active,
				descriptor: {
					_type: "CRM.Data.ConsentInPackage",
					_ref: false
				},
				scheme: {
					type: {
						codeList: {_id: "type"}
					},
					validity: {
						object: {
							scheme: {
								from: {
									field: "from"
								},
								to: {
									field: "to"
								}
							}
						}
					}
				}
			}
		}
	}
}
