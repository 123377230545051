"use strict"

import {observable, action} from "mobx"
import DataStore from "../stores/DataStore"
import {getUserDoctorInfo, printReport, getSelectedOrgUnit} from "../../../global/helpers/actions"
import moment from "moment"

class ConsentFormStore {
	@observable editable = false
	@observable isAdd = false

	@observable isOpenConsentTemplate = false
	consentId = undefined

	@action printConsent = (selectedID) => {
		const selectedConsent = DataStore.patientDTO.get("consents").find((c) => c._id === selectedID)

		if (selectedConsent) {
			const consent = selectedConsent.consents[0]
			const pacInfo = DataStore.patientDTO.get("patient")
			const doctorInfo = getUserDoctorInfo()
			const orgUnit = getSelectedOrgUnit()
			const providerCode = orgUnit.identifiers.find((i) => i.type === "PROVIDERCODE")

			let printValues = {
				name: `${pacInfo.full_name}`,
				identifier: pacInfo.identifier,
				address: pacInfo.validInfo && pacInfo.validInfo.address ? pacInfo.validInfo.address.full_address : "",
				dateFrom: moment(consent.validity.from).format("DD.MM.YYYY"),
				representationOrgunitname: isEmpty(orgUnit.name_ext) ? "" : `${orgUnit.name_ext}, v zastúpení: `,
				doctorName: doctorInfo.name,
				doctorCode: doctorInfo.code,
				ambulanceCode: providerCode ? providerCode.value : "",
				doctorPhone: doctorInfo.contact
					? doctorInfo.contact.mobile
						? doctorInfo.contact.mobile
						: doctorInfo.contact.phone
					: "",
				doctorAddress: doctorInfo.address ? doctorInfo.address.full_address : ""
			}

			//Insurer
			if (pacInfo.validInfo && pacInfo.validInfo.insurance) {
				const insurer = pacInfo.validInfo.insurance.insurer
				printValues["insurer"] = `${insurer.code_ext} - ${insurer.name_ext}`

				let icode = insurer.code_ext.toString().length === 2 ? String(insurer.code_ext + "00") : insurer.code_ext
				icode
					.toString()
					.split("")
					.map((char, index) => {
						printValues["insurer" + index] = char
					})
			}

			printReport("consent_" + consent.type._id, printValues)
		}
	}

	@action openConsentTemplate(id) {
		this.isOpenConsentTemplate = true
		this.consentId = id
	}

	@action closeConsentTemplate() {
		this.isOpenConsentTemplate = false
		this.consentId = undefined
	}
}

var singleton = new ConsentFormStore()
export default singleton
