import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				severity: {
					label: <FormattedMessage id="Patient.form.problemForm.severity" />,
					rules: "required|string"
				},
				alert: {
					label: <FormattedMessage id="PatientDetail.header.alert" />,
					rules: "required|string"
				}
			}
		}
	}
}
