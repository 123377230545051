import React from "react"
import {FormattedMessage} from "react-intl"

export default {
	load() {
		return {
			fields: {
				invoiceNumber: {
					label: <FormattedMessage id="Invoice.form.number" />,
					rules: "digits:10"
				},
				report_type: {
					label: <FormattedMessage id="Capitation.form.ReportType" />,
					rules: "required"
				},
				insurer: {
					label: <FormattedMessage id="Capitation.form.ReportType" />,
					rules: "required"
				}
			}
		}
	}
}
