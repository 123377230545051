import {observable, action} from "mobx"
import api from "../actions/api"
import WarningStore from "../../../global/store/WarningStore"

import UIStore from "./UIStore"

class WriteSpaRequestDialogStore {
	@observable isOpen = false
	@observable diagnose = null

	patient_id = null
	provider_id = null
	doctor_expertise_id = null

	errorWriteMessage = ""

	@action open(patient_id, provider_id, doctor_expertise_id) {
		this.isOpen = true
		this.patient_id = patient_id
		this.provider_id = provider_id
		this.doctor_expertise_id = doctor_expertise_id
	}

	@action onHandleChange(diagnose) {
		this.diagnose = diagnose
	}

	@action onWrite() {
		const request = {
			patient_id: this.patient_id,
			provider_id: this.provider_id,
			doctor_expertise_id: this.doctor_expertise_id,
			diagnoza: this.diagnose
		}

		UIStore.isFormSaving = true

		api
			.writeSpaRequest(request)
			.call()
			.then((data) => {
				UIStore.isFormSaving = false
				window.open(data.url, "_blank")
			})
			.catch(() => {
				UIStore.isFormSaving = false
				WarningStore.open(this.errorWriteMessage, true)
			})

		this.close()
	}

	@action close() {
		this.isOpen = false
		this.diagnose = null
		this.patient_id = null
		this.provider_id = null
		this.doctor_expertise_id = null
	}
}

var singleton = new WriteSpaRequestDialogStore()
export default singleton
