import React from "react"
import {observer} from "mobx-react"
import Grid from "@material-ui/core/Grid"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import EHGWStore from "../../../stores/EHGWStore"
import UIStore from "../../../stores/UIStore"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

@observer
export default class EHGWLabs extends React.Component {
	constructor(props) {
		super(props)
	}

	componentDidMount() {
		EHGWStore.loadLabs()
	}

	render() {
		return (
			<div className="EHGWLabs">
				{UIStore.isFormSaving && <XsLoading overlay={true} />}
				<Grid container className="xs-installFiles-grid">
					<Grid item xs={12}>
						<XsTable
							config={{
								columnDefs: {
									logType: {
										title: "logType",
										type: "string",
										design: {
											width: "20%"
										},
										sortable: true
									},
									user: {
										title: "user",
										type: "string",
										design: {
											width: "20%"
										},
										sortable: true
									},
									message: {
										title: "message",
										type: "string",
										design: {
											width: "20%"
										},
										sortable: true
									},
									description: {
										title: "description",
										type: "string",
										design: {
											width: "20%"
										},
										sortable: true
									}
								},
								options: {
									showCursor: true,
									// selectRow: true,
									// onRowClick: (dataRow) => {
									// 	this.onHandleRowClick(dataRow)
									// },
									mapper: (dataRow) => {
										return {
											logType: dataRow.LogType,
											user: dataRow.User,
											message: dataRow.message,
											description: dataRow.Description
										}
									}
								},
								dataSource: EHGWStore.labs
							}}
						/>
					</Grid>
				</Grid>
			</div>
		)
	}
}
