"use strict"

import React, {useEffect} from "react"
import {observer} from "mobx-react"
import moment from "moment"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import Grid from "@material-ui/core/Grid"
import {FormattedMessage, injectIntl} from "react-intl"
import UIStore from "../../../stores/UIStore"
import PatientSicknessAbsStore from "../../../stores/PatientSicknessAbsStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import DataStore from "../../../stores/DataStore"
import RouterStore from "../../../../../global/store/RouterStore"
import WarningStore from "../../../../../global/store/WarningStore"
import SaveIcon from "@material-ui/icons/Done"
import TrashIcon from "@material-ui/icons/DeleteForever"
import CancelIcon from "@material-ui/icons/Clear"
import AddIcon from "@material-ui/icons/Add"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import {withStyles} from "@material-ui/core/styles"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import {Chip} from "@material-ui/core"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import DeliveriesSicknessAbsForm from "./deliveriesSicknessAbsForm"
import XsAutocompleteLocal from "../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegisterCacheStore from "../../../../../global/store/RegistersCacheStore"
import settings from "../../../../../global/config/settings"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsChip from "../../../../../global/ui/xsChip/xsChip"
import XsChipForm from "../../../../../global/ui/xsChipForm/xsChipForm"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import {getDaysCodeList} from "../../../../../global/helpers/actions"

const styles = () => ({
	title: {
		marginBottom: "10px",
		fontWeight: 300,
		fontSize: "20px"
	},
	chipStyle: {
		height: "auto",
		minHeight: "32px",
		whiteSpace: "normal"
	},
	chipLabel: {
		whiteSpace: "normal"
	}
})

function PatientSicknessAbsForm(props) {
	useEffect(() => {
		// PatientSicknessAbsStore.set("PNRecordsChecked", true)
		// Prejdeme všetky PN pacienta a zistíme či sa niektoré neprekrývajú
		// Iba finálne, platné a typu OČR alebo DPN + vynecháva aktuálne otvorenú PN na editáciu
		if (props.form.$("sub_type").get("value") === "OCR" || props.form.$("sub_type").get("value") === "DPN") {
			const ehrs = DataStore.patientDTO.get("patientEHR")
			if (ehrs && ehrs.rows) {
				const pn = ehrs.rows.find(
					(r) =>
						r.version === "final" &&
						r.type_code.toUpperCase() === "PN" &&
						r.sickness_abs &&
						(r.sickness_abs.sub_type === "OCR" || r.sickness_abs.sub_type === "DPN") &&
						r.sickness_abs.validity &&
						r.sickness_abs.validity.from &&
						r.sickness_abs.validity.now &&
						r.record_id != props.form.$("id").get("value")
				)

				if (pn) {
					WarningStore.open(
						props.intl.formatMessage({id: "Patient.form.patientsicknessabs.warningExistingConfirmationMessage"})
					)
				}
			}
		}
	}, [])

	useEffect(() => {
		const handler = function sickAbs(e) {
			if (RouterStore.location.pathname === "/patient" && UIStore.patientCardTab == "sicknessabs") {
				//ulozit a zavriet request Alt + U
				if (e.altKey && e.keyCode == 85) {
					e.preventDefault()
					props.form.$("print").value = false
					props.form.onSubmit(e)
				}
			}
		}

		document.body.addEventListener("keydown", handler)

		return function cleanUp() {
			document.body.removeEventListener("keydown", handler)
		}
	}, [])

	useEffect(() => {
		if (props.form.$("sub_type").value === "DPN") {
			props.form.$("diagnosis").set("rules", "required")
		} else {
			props.form.$("diagnosis").set("rules", "")
		}
	}, [])

	const subType = props.form.$("sub_type").get("value")

	const changeDateInDeliveries = () => {
		let from = props.form.$("from").value
		let deliveries = JSON.parse(props.form.$("deliveriesChipsForm").value)

		if (isSafe(from) && isSafe(deliveries)) {
			deliveries.forEach((row) => {
				logger("ROW", row)
				if (row.itemCode == "71") {
					logger("aaa", GlobalStore.CL["deliveryExtension"])
					const delExtObj = GlobalStore.CL["deliveryExtension"].find(
						(delivery) => delivery.code == row.delivery_extension
					)
					logger(delExtObj)
					if (delExtObj.code_ext == "DPN") {
						row.delivery_date_time = moment(from).format(settings.DB_DATETIME_FORMAT)
					}
				}
			})
		}

		PatientSicknessAbsStore.deliveriesChipsForm.bindData(deliveries)
	}

	return (
		<section className="xs-patient-card-form xs-column">
			{/* {renderAutoSaveConfirmDialog()} */}
			{UIStore.isFormSaving && <XsLoading overlay={true} />}
			<div className="xs-patient-form xs-row">
				<div className="xs-patient-form-left" style={{width: "75%"}}>
					<div className="padding">
						<Grid container spacing={8}>
							<Grid item xs={4}>
								<SearchSelect
									field={props.form.$("sub_type")}
									items={GlobalStore.CL["sicknessAbsenceTypes"]}
									onChange={(val) => PatientSicknessAbsStore.changeSicknessAbsType(val, props.form, props.intl)}
									disabled={props.form.$("type_disabled").value}
								/>
							</Grid>
							{subType != "General" && subType != "MCLAIM" && (
								<Grid item xs={4}>
									<XsInput
										field={props.form.$("number")}
										autoFocus={true}
										ref={(input) => (GlobalStore.defaultFocusFields["patientSicknessAbs"] = input)}
										onChange={() => {
											props.form.$("number").value = props.form
												.$("number")
												.value.trim()
												.replace(/ /g, "")
										}}
										onBlur={() => {
											if (isNotEmpty(props.form.$("number").value)) {
												props.form.$("number").value = props.form.$("number").value.toUpperCase()
											}
										}}
										required
									/>
								</Grid>
							)}
							{subType === "MCLAIM" && (
								<Grid item xs={4}>
									<XsInput
										field={props.form.$("numberMclaim")}
										autoFocus={true}
										ref={(input) => (GlobalStore.defaultFocusFields["patientSicknessAbs"] = input)}
										onChange={() => {
											props.form.$("numberMclaim").value = props.form
												.$("numberMclaim")
												.value.trim()
												.replace(/ /g, "")
										}}
										onBlur={() => {
											if (isNotEmpty(props.form.$("numberMclaim").value)) {
												props.form.$("numberMclaim").value = props.form.$("numberMclaim").value.toUpperCase()
											}
										}}
										required
									/>
								</Grid>
							)}
							<Grid item xs={4}>
								<XsAutocompleteLocal
									field={props.form.$("diagnosis")}
									minLengthForSearch="1"
									clearText
									data={RegisterCacheStore.diagnosisRegister}
									inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
									saveValue={(obj) => obj.code}
									specialDataFilter={(data) =>
										data.filter((i) => isEmpty(i.supplementary_sign) || i.supplementary_sign == "E")
									}
									postAction={() => {
										PatientSicknessAbsStore.insertDiagnosisToDeliveries(props.form)
									}}
									modalConfig={(textValue, clickHandler) => {
										return {
											columnDefs: {
												code_ext: {
													title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
													type: "string",
													dbName: "code_ext",
													design: {
														width: "150px"
													},
													filter: {
														gridWidth: 3,
														defaultValue: "",
														renderElement: "input"
													}
												},
												name_ext: {
													title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
													type: "string",
													dbName: "search_column",
													filter: {
														gridWidth: 9,
														defaultValue: textValue,
														renderElement: "input"
													}
												}
											},
											options: {
												showCursor: true,
												onRowClick: (dataRow) => {
													clickHandler(dataRow)
												},
												paging: {
													rowsPerPage: 100,
													rowsPerPageOptions: [5, 10, 25, 50, 100]
												}
											}
										}
									}}
								/>
							</Grid>
							{subType === "OCR" && (
								<Grid item xs={4}>
									<SearchSelect
										field={props.form.$("rel_type")}
										items={
											isSafe(GlobalStore.CL["relationshipType"])
												? GlobalStore.CL["relationshipType"].filter((c) => c._id.startsWith("PERS:"))
												: []
										}
									/>
								</Grid>
							)}
							{subType != "General" && (
								<Grid item xs={4}>
									<XsDateTimePicker
										field={props.form.$("from")}
										onSelect={(value) => {
											// PatientSicknessAbsStore.insertDateToDeliveries(props.form, date)
											if (
												isNotEmpty(props.form.$("deliveriesChipsForm").value) &&
												props.form.$("deliveriesChipsForm").value != "{}" &&
												props.form.$("deliveriesChipsForm").value != "[]"
											) {
												let deliveryData = JSON.parse(props.form.$("deliveriesChipsForm").value)
												logger(props.form.$("from"))
												if (
													deliveryData.some(
														(row) =>
															isEmpty(row.delivery_date_time) ||
															!moment(row.delivery_date_time).isSame(moment(value), "day")
													)
												) {
													GlobalStore.openConfirmationDialog("xsUpdateDeliveriesDate")
												}
											}
										}}
									/>
								</Grid>
							)}
							{subType != "General" && (
								<Grid item xs={4}>
									<XsDateTimePicker field={props.form.$("to")} />
								</Grid>
							)}
							{subType === "DPN" && (
								<Grid item xs={4}>
									<XsDateTimePicker field={props.form.$("work_capable_since")} />
								</Grid>
							)}
							{subType === "MCLAIM" && (
								<Grid item xs={4}>
									<XsDateTimePicker field={props.form.$("childBirth")} />
								</Grid>
							)}
							{subType === "MCLAIM" && (
								<Grid item xs={6}>
									<XsDateTimePicker field={props.form.$("maternity_claim_from")} />
								</Grid>
							)}
							<Grid item xs={12}>
								<XsInput field={props.form.$("description")} />
							</Grid>
							{/* 11448 {subType === "OCR" && (
							<Grid item xs={4}>
								<XsInput field={props.form.$("employer_plain")} />
							</Grid>
						)} */}
						</Grid>
						{props.form.$("sub_type").value == "DPN" && (
							<Grid item xs={12}>
								<XsChipForm
									ref={(ref) => (PatientSicknessAbsStore.deliveriesAddItemChipsForm = ref)}
									field={props.form.$("walksChips")}
									label={props.intl.formatMessage({id: "Common.label.walks"})}
									configuration={{
										header: {
											chipsRenderer: (key, dataRow, clickHandler) => (
												<XsChip
													key={key}
													label={
														getDaysCodeList() && isNotEmpty(dataRow["day"]["value"])
															? getDaysCodeList().find((row) => row._id === dataRow["day"]["value"]).name_ext
															: ""
													}
													onClick={(e) => clickHandler(false, e, key)}
												/>
											),
											addButtonRenderer: (clickHandler) => (
												<XsIconButton className="xs-default xs-outline" icon={<AddIcon />} onClick={clickHandler} />
											)
										},
										columns: [
											{
												name: "day",
												label: <FormattedMessage id="Common.label.day" />,
												width: 3,
												defaultFocus: true,
												validationRule: "required",
												renderer: (field) => <XsSearchSelect field={field} items={getDaysCodeList()} />
											},
											{
												name: "_id",
												label: <FormattedMessage id="Common.label.day" />,
												width: 0,
												renderer: () => {}
											},
											{
												name: "from",
												label: <FormattedMessage id="Common.label.from" />,
												width: 3,
												validationRule: "required",
												renderer: (field) => <XsInput field={field} type={"time"} />
											},
											{
												name: "to",
												label: <FormattedMessage id="Common.label.to" />,
												width: 3,
												validationRule: "required",
												renderer: (field) => <XsInput field={field} type={"time"} />
											}
										],
										options: {
											disabled: false,
											isFormMode: false,
											showInCenter: true,
											isModalMode: true,
											onFormOpen: () => {},
											onFormClose: () => {},
											onFormSave: (vals) => {
												PatientSicknessAbsStore.deliveriesAddItemChips = vals
											}
										},
										data: PatientSicknessAbsStore.deliveriesAddItemChips
									}}
								/>
							</Grid>
						)}
					</div>
				</div>
				<div className="xs-patient-form-right">
					<div className="padding">
						{PatientSicknessAbsStore.record && (
							<div>
								<div className={props.classes.title}>
									<FormattedMessage id="Patient.form.patientrequest.parentrecord" />
								</div>
								<Chip
									label={`${moment(PatientSicknessAbsStore.record.created_at).format(
										props.intl.formatMessage({id: "Application.moment.datetimeformat"})
									)} ${PatientSicknessAbsStore.record.name}`}
									onDelete={() => PatientSicknessAbsStore.loadRecord(null)}
									color="primary"
									classes={{
										root: props.classes.chipStyle,
										label: props.classes.chipLabel
									}}
								/>
							</div>
						)}
						{(!PatientSicknessAbsStore.sicknessAbsID ||
							(PatientSicknessAbsStore.sicknessAbsLoaded && PatientSicknessAbsStore.sicknessAbsID)) && (
							<DeliveriesSicknessAbsForm
								sickForm={props.form}
								// ref={(chipform) => (deliveriesSicknessAbsFormRef = chipform)}
							/>
						)}
					</div>
				</div>
			</div>
			<div className="xs-patient-form-actions">
				<XsButton
					className="xs-success"
					text={<FormattedMessage id="Common.label.save" />}
					type="submit"
					onClick={(e) => {
						props.form.$("print").value = false
						props.form.onSubmit(e)
					}}
					icon={<SaveIcon />}
				/>
				{/* 11448 {(subType == "DPN" || subType == "OCR") && (
					<XsButton
						className="xs-success xs-outline"
						text={<FormattedMessage id="Patient.form.patientinfo.saveandprint" />}
						onClick={(e) => {
							props.form.$("print").value = true
							props.form.onSubmit(e)
						}}
						icon={<PrintIcon />}
					/>
				)} */}
				{isNotEmpty(props.form.$("id").value) && (
					<XsButton
						className="xs-danger xs-outline"
						text={<FormattedMessage id="Common.label.delete" />}
						onClick={() => {
							GlobalStore.openConfirmationDialog("xsDeleteSicknessAbs")
						}}
						icon={<TrashIcon />}
					/>
				)}
				<XsButton
					className="xs-danger xs-outline"
					text={<FormattedMessage id="Common.form.close" />}
					onClick={() => {
						PatientSicknessAbsStore.reset()
						props.options.onClose("SICKNESS", false, true)
						// deleteFromSession()
					}}
					icon={<CancelIcon />}
				/>
			</div>
			<XsConfirmationDialog
				name="xsDeleteSicknessAbs"
				title={<FormattedMessage id="Common.label.warning" />}
				text={<FormattedMessage id="Patient.form.patientsicknessabs.deleteConfirmationMessage" />}
				onConfirmation={() =>
					PatientSicknessAbsStore.deleteSicknessAbs(props.form.$("id").value, () => {
						props.options.onClose("SICKNESS", false, false)
					})
				}
			/>
			<XsConfirmationDialog
				name="xsUpdateDeliveriesDate"
				title={<FormattedMessage id="Common.label.warning" />}
				text={
					<React.Fragment>
						<FormattedMessage id="Common.label.updateSicknessDeliveriesDate" />
						<br />
						<FormattedMessage id="Common.label.checkDatesInDeliveries" />
					</React.Fragment>
				}
				onConfirmation={() => changeDateInDeliveries()}
			/>
		</section>
	)
}

export default injectIntl(withStyles(styles)(observer(PatientSicknessAbsForm)))
