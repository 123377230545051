"use strict"

import {observable, action} from "mobx"
import WarningStore from "../../../global/store/WarningStore"
import GlobalStore from "../../../global/store/GlobalStore"
import RouterStore from "../../../global/store/RouterStore"
import settings from "../../../global/config/settings"

class GWUpdatingDialogStore {
	@observable isOpen = false
	@observable updatingVersion = ""

	@observable isWaitingOpen = false
	@observable isRegisterZprWaitingOpen = false

	@action open(updatingVersion) {
		this.isOpen = true
		this.updatingVersion = updatingVersion

		setTimeout(() => {
			if (GlobalStore.isUpdatingGW) {
				// aktualizacia skapala
				this.close()
				RouterStore.push(settings.HOMEPAGE)
				WarningStore.open("Aktualizacia EHGW aplikacie sa nepodarila, kontaktujte zakaznicku podporu.", true)
			}
		}, 120000)
	}

	@action openWaiting() {
		this.isWaitingOpen = true
	}

	@action openRegisterZprWaiting() {
		this.isRegisterZprWaitingOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@action closeWaiting() {
		this.isWaitingOpen = false
	}

	@action closeRegisterZprWaiting() {
		this.isRegisterZprWaitingOpen = false
	}
}

var singleton = new GWUpdatingDialogStore()
export default singleton
