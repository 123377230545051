"use strict"

import {observable, action} from "mobx"

class WarningStore {
	@observable isOpen = false
	@observable message = ""
	@observable isError = false

	@action open(message, isError = false) {
		this.isOpen = true
		this.message = message
		this.isError = isError
	}

	@action close() {
		this.isOpen = false
	}

	generateErrorMessage(IsEhealthException, message) {
		// this.open((IsEhealthException === true ? "Chyba z NZIS! " : "Chyba z ambee! ") + message, true)
		this.open((IsEhealthException === true ? "" : "") + message, true)
	}
}

var singleton = new WarningStore()
export default singleton
