import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				print: {
					value: false,
					rules: "boolean"
				},
				diagnosis: {
					label: <FormattedMessage id="Patient.form.patientrecord.diagnosis" />,
					rules: "required|string"
				},
				diagnosis_secondary: {
					label: <FormattedMessage id="Patient.form.patientrecord.diagnosis" />,
					rules: "string"
				},
				diagnosis_code: {},
				expertise: {
					label: <FormattedMessage id="Patient.form.patientrequest.expertise" />,
					rules: "required|string"
				},
				expertise_code: {},
				diagnosis_type: {
					label: <FormattedMessage id="Patient.form.patientrecord.diagnosistype" />,
					value: "PRIMARY",
					rules: "required"
				},
				diagnosis_type_secondary: {
					label: <FormattedMessage id="Patient.form.patientrecord.diagnosistype" />,
					value: "SECONDARY",
					rules: "required"
				},
				request_type: {
					label: <FormattedMessage id="Patient.form.patientrequest.requesttype" />,
					value: "EHR.Data.Specialist.Request",
					rules: "required"
				},
				priority: {
					label: <FormattedMessage id="Patient.form.patientrecord.priority" />,
					value: "1",
					rules: "required"
				},
				clarification: {
					label: <FormattedMessage id="Patient.form.patientrecord.clarification" />,
					rules: "string"
				},
				professional_activity: {
					label: <FormattedMessage id="Common.label.section" />,
					rules: "string"
				},
				request: {
					label: <FormattedMessage id="Common.label.request" />,
					rules: "required"
				},
				patientnote: {
					label: <FormattedMessage id="Patient.form.patientrequest.patientnote" />
				},
				sedation_needed: {
					label: <FormattedMessage id="Patient.form.patientrequest.sedation" />,
					type: "checkbox"
				},
				modality: {
					label: <FormattedMessage id="Patient.form.patientrequest.modality" />,
					rules: "string"
				},
				organ_to_exam: {
					label: <FormattedMessage id="Patient.form.patientrequest.organ" />,
					rules: "string"
				},
				showSecondaryDiagnosis: {
					type: "checkbox",
					value: false
				},
				recordId: {}
			}
		}
	}
}
