import React from "react"
import {observer} from "mobx-react"

import Badge from "@material-ui/core/Badge"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import "./xsTabs.less"

@observer
export default class XsTabsIcon extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const {tabs, scrollButtons, mainClass, className, background, headerClassName, horizontal} = this.props
		let addClassName = isSafe(className) ? className : ""
		let addBackground = isSafe(background) ? {backgroundColor: background} : {}

		return (
			<div className={`xs-tabs-icon ${mainClass}`}>
				<Tabs
					value={this.props.value ? this.props.value : false}
					// onChange={this.onChange}
					onChange={(event, value) => this.props.onChangeTab(value)}
					className={headerClassName ? headerClassName : null}
					scrollable
					scrollButtons={scrollButtons ? "on" : "off"}
				>
					{isSafe(tabs) &&
						tabs.map((tab, idx) => {
							let isActive = this.props.value === tab.value ? true : false
							let activeClass = isActive ? "xs-active" : ""
							let tabBadge = tab.badge ? <div className="xs-tab-badge">{tab.badge}</div> : null
							let labelClassName = "xs-label-text"

							if (tab.labelClassName) {
								labelClassName += ` ${tab.labelClassName}`
							}

							if (!tab.hidden) {
								if (isSafe(horizontal) && horizontal) {
									return (
										<Tab
											key={idx}
											className={`xs-horizontal-tab ${activeClass} ${addClassName} tab-${tab.value}`}
											label={
												<div className="xs-horizontal-label">
													<div className="xs-label-icon">{tab.icon}</div>
													<div className="xs-label-text">{tab.label}</div>
													{tabBadge}
												</div>
											}
											value={tab.value}
											style={isActive ? {backgroundColor: background} : {}}
											disabled={tab.disabled}
										/>
									)
								} else {
									let iconClass = "xs-badge-icon"

									if (tab.success) {
										iconClass = "xs-greenSuccess"
									}
									if (tab.warning) {
										iconClass = "xs-amberWarning"
									}
									if (tab.error) {
										iconClass = "xs-redDanger"
									}
									return (
										<Tab
											key={idx}
											className={`xs-vertical-tab ${activeClass} ${addClassName} tab-${tab.value}`}
											label={<div className={labelClassName}>{tab.label}</div>}
											value={tab.value}
											disabled={tab.disabled}
											icon={
												isNotSafe(tab.count) ? (
													<span className={iconClass}>{tab.icon}</span>
												) : (
													<Badge
														className={tab.danger ? "xs-badge xs-danger" : "xs-badge"}
														badgeContent={tab.count > 0 ? tab.count : 0}
													>
														<span className={iconClass}>
															{tab.icon}
															{/* {tab.warning && <i className="fas fa-exclamation xs-badge-warning"></i>} */}
														</span>
													</Badge>
												)
											}
											style={isActive ? {backgroundColor: background} : {}}
										/>
									)
								}
							}
						})}
				</Tabs>
				{isSafe(tabs) &&
					tabs.map((tab, idx) => {
						return (
							this.props.value === tab.value && (
								<div style={addBackground} className="xs-tab-content" key={idx}>
									{tab.content}
								</div>
							)
						)
					})}
			</div>
		)
	}
}
