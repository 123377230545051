"use strict"

import {observable, action} from "mobx"

class RecordRePrintDialogStore {
	@observable isOpen = false

	config = {}

	@action open(config) {
		this.isOpen = true
		this.config = config
	}

	@action close() {
		this.isOpen = false
	}
}

var singleton = new RecordRePrintDialogStore()
export default singleton
