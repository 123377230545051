/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				from: {
					label: <FormattedMessage id="Common.label.from" />,
					rules: "required",
					type: "date",
					value: moment()
				},
				to: {
					label: <FormattedMessage id="Common.label.to" />,
					type: "date"
				},
				end_reason: {
					label: <FormattedMessage id="Capitation.List.EndReason" />,
					type: "string"
				}
			}
		}
	}
}
