import * as types from "../config/constants"
import api from "../../ui/authentification/actions"
import {ApiCall} from "./api"
import {StorageBase} from "../storage/storageEx"
import config from "../config/settings"
import {ixsIsObject} from "./functions"
import moment from "moment"
// import {load} from "recaptcha-v3"
//import type {State as AppState} from "app/flowTypes";
// import {KEY_AUTH_TOKEN}` from "auth/constants";
import {USER_DATA, DZPDATE} from "../config/constants"

import GlobalStore from "../store/GlobalStore"
import DataStore from "../../modules/ambulance/stores/DataStore"
import RouterStore from "../store/RouterStore"
import CheckMedicineCardDialogStore from "../../modules/ambulance/stores/CheckMedicineCardDialogStore"
import RecordRePrintDialogStore from "../../modules/ambulance/stores/RecordRePrintDialogStore"
import NotificationCenterStore from "../../modules/ambulance/components/notificationCenter/NotificationCenterStore"
import UIStore from "../../modules/ambulance/stores/UIStore"

import {useState, useEffect, useRef} from "react"
import settings from "../config/settings"

//Získa objekt aktuálne prihláseného používateľa
// export const getUser = () => Storage.getItem(USER_DATA)
export const getUser = () => StorageBase.getObjectByKey(USER_DATA)

export const login = (username, password) => {
	try {
		// load(config.SITE_KEY, {autoHideBadge: true}).then((recaptcha) => {
		// 	recaptcha.execute("security_login").then((recaptchaToken) => {
		// .login(username, password, recaptchaToken)
		api
			.login(username, password)
			.call((statusCode) => {
				if (statusCode != 200) {
					GlobalStore.authentificationFailed = true
					GlobalStore.authentificationInProgress = false
				}
			})
			.then((user) => {
				if (typeof user == "undefined") {
					GlobalStore.authentificationFailed = true
					GlobalStore.authentificationInProgress = false
					throw new Error()
				}
				//Storage.clear([types.USER_SETTINGS, types.LANGUAGE])
				StorageBase.deleteByKeyQuery(
					(x) =>
						!// isSafe(x.type) ||
						(
							x == types.USER_SETTINGS ||
							x == types.LANGUAGE ||
							x == types.DZPDATE ||
							x == types.RESOURCES ||
							x == types.TEXTAREASIZES ||
							x == types.MEDICATIONDEFAULTS
						)
				)

				const days40 = 40 * 24 * 60 * 60 * 1000
				StorageBase.deleteByValueQuery((x) => isSafe(x.info) && x.info.LastModified < Date.now() - days40)

				StorageBase.updateByKey(USER_DATA, user)

				let dzpDate = StorageBase.getByKey(DZPDATE)

				if (user.chat_enabled == "1") {
					NotificationCenterStore.startConnection(user.user._id)
				}

				// MAROS: getStatus sa zacne az po registracii ZPr (akokolvek sa skonci)
				// GlobalStore.checkGWApp()
				//setInterval(GlobalStore.checkGWApp, 15000)
				GlobalStore.Autenticate()

				// dialog sa zobrazuje len pre specificke expertises EMA-14965
				const expertises = ["008", "020", "009"]
				if (
					expertises.includes(GlobalStore.orgunitExpertiseCodeExt) &&
					(isEmpty(dzpDate) || (isSafe(dzpDate) && !moment(dzpDate).isSame(moment().format("YYYY-MM-DD"))))
				) {
					CheckMedicineCardDialogStore.open()
				}
			})
		// 	})
		// })
	} catch (err) {
		logger(err)
	}
}

export const logout = async (withoutCallingLogout = false) => {
	try {
		RouterStore.push("/login")

		if (withoutCallingLogout == false) {
			await api.logout().call()
		}

		StorageBase.deleteByKeyQuery(
			(x) =>
				!(
					isSafe(x.type) ||
					x == types.USER_SETTINGS ||
					x == types.LANGUAGE ||
					x == types.DZPDATE ||
					x == types.RESOURCES ||
					x == types.TEXTAREASIZES ||
					x == types.MEDICATIONDEFAULTS
				)
		)
		GlobalStore.InitEhealthStates()
		location.reload(true)
	} catch (err) {
		logger(err)
	}
}

export const openWindowWithPost = (url, data) => {
	let form = document.createElement("form")
	form.target = "_blank"
	form.method = "POST"
	form.action = url
	form.style.display = "none"

	for (let key in data) {
		const value = data[key]

		if (ixsIsObject(value)) {
			for (let k in value) {
				let input = createHiddenInput(k, value[k])
				form.appendChild(input)
			}
		} else {
			let input = createHiddenInput(key, value)
			form.appendChild(input)
		}
	}
	document.body.appendChild(form)
	form.submit()
	document.body.removeChild(form)
}

export const createHiddenInput = (key, value) => {
	let input = document.createElement("input")
	input.type = "hidden"
	input.name = key
	input.value = value
	return input
}

//Zavolá mPDF API a pošle HTML alebo názov template
export const printReport = (templateName = null, values, directHtml, streamCallback, format = null) => {
	// const company = getUserCompanyInfo()
	const userInfo = getUserDoctorInfo() || {}
	const user = getUser() || {}
	const printIdentifier = {
		client_id: DataStore.patientDTO.get("patientID"),
		doctor_id: userInfo.id,
		org_unit_id: GlobalStore.orgunitsId,
		template: templateName
	}
	const customIdentifier = null //company && company.identifier ? parseInt(company.identifier) : null
	const requestData = {
		_html: directHtml,
		_template: templateName,
		_customIdentifier: customIdentifier,
		_format: format,
		_stream: streamCallback ? true : false,
		_doctorId: printIdentifier.doctor_id,
		_orgUnitId: printIdentifier.org_unit_id,
		_clientId: printIdentifier.client_id,
		_token: `IXS ${user.ixstoken || ""}`,
		_apiBaseUri: config.API_BASE_URL,
		_paddingTop: 0,
		values: values
	}

	if (templateName === "record") {
		RecordRePrintDialogStore.open({streamCallback, requestData, printIdentifier})
	} else {
		printMPDF(streamCallback, requestData)
	}

	// if (templateName === "record_plain") {
	// 	ambulanceApi
	// 		.getPrintPosition(printIdentifier)
	// 		.call()
	// 		.then((data) => {
	// 			requestData["_reprint"] = true
	// 			requestData["_paddingTop"] = (data || {}).print_position_approved
	// 			printMPDF(streamCallback, requestData)
	// 			setTimeout(() => {
	// 				ambulanceApi.aprovePrintPosition(printIdentifier).call()
	// 			}, 20000)
	// 		})
	// } else {
	// 	printMPDF(streamCallback, requestData)
	// }
}

//Zavolá mPDF API na hromadnú tlač
export const printMultiReport = (multiValues, streamCallback, splitMultiplePages = false, format = null) => {
	// const company = getUserCompanyInfo()
	const customIdentifier = null // company && company.identifier ? parseInt(company.identifier) : null

	let cnt = 0
	let cntMultiple = 0
	let resValues = {}
	let resValuesMultiple = {}

	multiValues.map((mv) => {
		const template = mv.template

		//Zisžujeme či rozdeľujeme na viacstranové a jednostranové
		if (!splitMultiplePages || !mv.multiplePages) {
			cnt++
			for (let v in mv.values) {
				resValues[`${cnt}$${template}$${v}$${customIdentifier}`] = mv.values[v]
			}
		} else {
			cntMultiple++
			for (let v in mv.values) {
				resValuesMultiple[`${cntMultiple}$${template}$${v}$${customIdentifier}`] = mv.values[v]
			}
		}
	})

	if (Object.keys(resValues).length !== 0) {
		const requestData = {
			_multi: true,
			_stream: streamCallback ? true : false,
			_format: format,
			values: resValues
		}
		printMPDF(streamCallback, requestData)
	}

	//Viacstranové na samostaný tab
	if (Object.keys(resValuesMultiple).length !== 0) {
		const requestDataMulti = {
			_multi: true,
			_stream: streamCallback ? true : false,
			_format: format,
			values: resValuesMultiple
		}
		printMPDF(streamCallback, requestDataMulti)
	}
}

export const printMPDF = (streamCallback, requestData) => {
	if (streamCallback) {
		fetch(config.MPDF_API, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(requestData)
		})
			.then((response) => response.blob())
			.then((blob) => streamCallback(blob))
	} else {
		openWindowWithPost(config.MPDF_API, requestData)
	}
}

//Získa ID defaultnej služby
export const getDefaultProvidedServiceID = () => {
	const selectedOrgUnit = getSelectedOrgUnit()
	return selectedOrgUnit ? selectedOrgUnit.default_provided_service_id : null
}

//Získa ID personnel aktuálne prihláseného používateľa
export const getUserPersonnelID = () => {
	const currentUser = getUser()
	if (currentUser) {
		const personnel = currentUser.relships.find((relship) => {
			return relship._type === "EHR.Data.Entity.Personnel"
		})
		return personnel ? personnel._id : null
	}
	return null
}

//Získa oddelenia prihláseného používateľa
export const getUserPersonnelOrgUnits = () => {
	if (GlobalStore.orgunits) {
		return GlobalStore.orgunits
	}
}

//Získa aktuálne zvolené oddelenie prihláseného používateľa (inak nazvané PROVIDER)
export const getSelectedOrgUnit = () => {
	if (GlobalStore.orgunits) {
		return GlobalStore.orgunits.find((ou) => ou._id === GlobalStore.orgunitsId)
	}
}

//Získa ID aktuálne zvoleného oddelenie používateľa (inak nazvané PROVIDER ID)
export const getSelectedOrgUnitID = () => {
	if (GlobalStore.orgunitsId) {
		return GlobalStore.orgunitsId
	}
}

//Získa zdroj aktuálne zvoleného oddelenia prihláseného používateľa
export const getSelectedOrgUnitResourceID = () => {
	if (GlobalStore.orgunitsResourceId) {
		return GlobalStore.orgunitsResourceId
	}
}

export const getKeyInfo = (type) => {
	const userInfo = getUserDoctorInfo()
	let keyInfo = null
	if (isSafe(userInfo)) {
		keyInfo = {
			type,
			doctorId: userInfo.id,
			patientId: DataStore.patientDTO.get("personId"),
			orgUnitId: GlobalStore.orgunitsId
		}
	}
	return keyInfo
}

//Vráti objekt s informáciami o doktorovi
export const getUserDoctorInfo = () => {
	const currentUser = getUser()
	const selectedOrgUnit = getSelectedOrgUnit()

	if (isSafe(currentUser) && isSafe(selectedOrgUnit)) {
		const personnel = currentUser.relships.find(
			(relship) => relship._type === "EHR.Data.Entity.Personnel" && relship._id == selectedOrgUnit.personnel_id
		)

		if (personnel) {
			let code = null
			let expertise = null
			let name = null
			let first_name = null
			let last_name = null
			let loginemail = null
			let selectedContact = null
			let selectedAddress = null

			// selectedExpertise = personnel.expertises ? personnel.expertises.find(ex => ex._id === selectedOrgUnit.personnel_expertise_id) : null;
			selectedContact = selectedOrgUnit.contacts.find(
				(c) => c.contact_type._id === "business" && c.contact_type.active == true && c.contact_type.primary == true
			)

			if (isNotSafe(selectedContact) || (isEmpty(selectedContact.mobile) && isEmpty(selectedContact.phone))) {
				selectedContact = selectedOrgUnit.contacts.find((c) => c.contact_type._id === "business")
			}
			//Primárne doťahujeme adresu sídla, ak ju nemáme tak doťahujeme adresu označenú ako primárnu
			selectedAddress = selectedOrgUnit.addresses.find((a) => a.type && a.type._id === "OA")
			if (!selectedAddress) {
				selectedAddress = selectedOrgUnit.addresses.find((a) => a.primary)
			}
			code = selectedOrgUnit.doctor_code

			expertise = {
				id: selectedOrgUnit.personnel_expertise_id,
				cl_id: selectedOrgUnit.personnel_expertise_cl_id,
				code: selectedOrgUnit.personnel_expertise_cl_code,
				code_ext: selectedOrgUnit.personnel_expertise_cl_code_ext,
				name_ext: selectedOrgUnit.personnel_expertise_cl_name_ext,
				jruz_id: selectedOrgUnit.personnel_expertise_jruz_id
			}

			//Zmenilo sa API pre login a v objekte personnel.expertises uz tieto informacie niesu
			// expertise = (selectedExpertise && selectedExpertise.expertise) ? {
			//   code: selectedExpertise.expertise.code_ext,
			//   name: selectedExpertise.expertise.name_ext,
			//   fullname: `${selectedExpertise.expertise.code_ext} - ${selectedExpertise.expertise.name_ext}`,
			//   id: selectedExpertise.expertise._id
			// } : null;

			if (currentUser.person) {
				name = currentUser.person.full_name
				first_name = currentUser.person.first_name
				last_name = currentUser.person.last_name
			}
			loginemail = currentUser.user.email

			return {
				id: personnel._id,
				code: code,
				expertise: expertise,
				name: name,
				first_name: first_name,
				last_name: last_name,
				loginemail: loginemail,
				address: selectedAddress,
				contact: selectedContact
			}
		}
	}
	return null
}

// Vráti objekt s informáciami o poskytovateľovi
export const getUserCompanyInfo = () => {
	const company = getUser().company
	return company ? {...company, id: company._id} : null
}

//Vráti objekt s informáciami o zmluvach s poistovnami
export const getUserMedInsurers = () => {
	const currentOrg = getSelectedOrgUnit()
	if (currentOrg) {
		return currentOrg["insurer_contracts"]
	}
	return null
}

// Hash objektu do stringu
export const hashCode = (obj) => {
	// let result = ""
	// if (Array.isArray(str)) {
	// 	result = str.join("#")
	// }
	// return str.split("").reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0)
	let hashString = ""
	if (ixsIsObject(obj)) {
		hashString = JSON.stringify(obj)
	}
	return hashString
}

// Uloží si dočasne nejaké veci na server
export const savePayloadServer = (identifier, payload) => {
	if (identifier && payload) {
		const saveRequest = {
			identifier: identifier,
			object: {}
		}

		Object.assign(saveRequest.object, payload)

		//Uložíme na server
		api.saveSession(saveRequest).call()
	}
}

// Overí či sú nejaké veci na servery uložené
export const loadPayloadServer = (identifier) => {
	if (identifier) {
		return api.loadSession(identifier).call()
	}
}

//Získa zoznam pristupov uzivatela
export const getUserAccessList = () => {
	const user = getUser()
	if (isSafe(user)) return user.sec_access
	else return []
}

//Získa riadok z pristupov pouzivatela podla mena a typu
export const getUserAccessRights = (name, type = "") => {
	const sec_access = getUserAccessList()
	if (isSafe(sec_access) && isSafe(sec_access.resources))
		return sec_access.resources.find((res) => res.name.includes(name) && res.name.includes(type))
	else return null
}

export const getGWServerRole = () => {
	return settings.GWSERVERROLE
}

// Vlozi pomocku na miesto kde je kurzor
export const handleInsertHelpersIntoTextArea = (form, areaName, cursor, dataToInsert) => {
	const srcVal = form
		.$(areaName)
		.value.toString()
		.replace(/&[^;]*;/g, " ")
	let pastePosition = 0

	const textCursor = cursor

	let modifSrcVal = srcVal
	let removedLength = 0
	while (modifSrcVal.indexOf("<") < textCursor) {
		const actualSrcLength = modifSrcVal.length
		if (modifSrcVal.indexOf("<") == modifSrcVal.indexOf("<p><br></p>")) {
			modifSrcVal = modifSrcVal.replace("<p><br></p>", " ")
		} else if (modifSrcVal.indexOf("<") == modifSrcVal.indexOf("</p>") && modifSrcVal.indexOf("</p>") < textCursor) {
			modifSrcVal = modifSrcVal.replace(/<[^>]*>/, " ")
		} else {
			modifSrcVal = modifSrcVal.replace(/<[^>]*>/, "")
		}

		removedLength += actualSrcLength - modifSrcVal.length
	}

	pastePosition = removedLength + textCursor

	form.$(areaName).value =
		form.$(areaName).value.slice(0, pastePosition) + dataToInsert + form.$(areaName).value.slice(pastePosition)
}

// custom hook for using timer
export const useInterval = (callback, delay) => {
	const savedCallback = useRef()

	useEffect(() => {
		savedCallback.current = callback
	}, [callback])

	useEffect(() => {
		function tick() {
			savedCallback.current()
		}
		if (delay !== null) {
			let id = setInterval(tick, delay)
			return () => clearInterval(id)
		}
	}, [delay])
}

export const mergeObj = (target, source) => {
	for (const key of Object.keys(source)) {
		if (source[key] instanceof Object && key in target) Object.assign(source[key], mergeObj(target[key], source[key]))
	}
	Object.assign(target || {}, source)
	return target
}

export const useMergeState = (initialState) => {
	const [state, setState] = useState(initialState)
	const setMergedState = (newState) => setState((prevState) => mergeObj(prevState, newState))
	return [state, setMergedState]
}

export const getFieldIdentifier = (field, additional = "") => {
	let customClassName = additional
	if (field) {
		const fieldName = field.name || field.key
		customClassName = customClassName === "" ? fieldName : `${customClassName} ${fieldName}`
	}
	return customClassName
}

const unZip = (data) => {
	// FIXME nefunguje pole v poli
	let cache = {}

	const cacheObj = (obj) => {
		const {_type, _id} = obj
		if (_type && _id && Object.keys(obj).length === 2 && cache[_type] && cache[_type][_id]) {
			return cache[_type][_id]
		} else {
			if (_type && _id && Object.keys(obj).length > 2) {
				if (!cache[_type]) {
					cache[_type] = {}
				}
				cache[_type][_id] = obj
			}
			return obj
		}
	}

	const traverse = (obj) => {
		let stack = [{path: [], obj}]
		let result = {}

		while (stack.length) {
			const stackObj = stack[0].obj
			if (typeof stackObj === "object" && stackObj !== null) {
				for (var prop in stackObj) {
					var ref = stack[0].path.reduce((acc, v) => acc[v], result)
					const stackObjProp = stackObj[prop]
					if (Array.isArray(stackObjProp)) {
						ref[prop] = stackObjProp.map(traverse)
					} else if (typeof stackObjProp === "object" && stackObjProp !== null) {
						ref[prop] = {}
						const obj = cacheObj(stackObjProp)
						stack.push({path: stack[0].path.concat(prop), obj})
					} else {
						ref[prop] = stackObjProp
					}
				}
			} else {
				result = stackObj
			}
			stack.shift()
		}

		return result
	}

	return data.map(traverse)
}

export const getFullDataFromOptimized = (data) => {
	return unZip(data)
}

export const exportToExcel = (req, action) => {
	if (isEmpty(req.row_count_show)) {
		req.row_count_show = 100000
	}

	if (isEmpty(req.row_offset)) {
		req.row_offset = 1
	}

	if (isEmpty(req.row_count_full)) {
		req.row_count_full = 100000
	}

	new ApiCall("/object/export", "POST", req)
		.call()
		.then((res) => {
			if (res.ok == true) {
				let fileName = isSafe(res.headers.get("content-disposition"))
					? res.headers.get("content-disposition").split("filename=")[1]
					: "export"
				res.blob().then((blob) => {
					let FileSaver = require("file-saver") // eslint-disable-line no-undef
					FileSaver.saveAs(blob, fileName)
				})
			}
			if (typeof action === "function") {
				action()
			}
			UIStore.isFormSaving = false
		})
		.catch(() => {
			UIStore.isFormSaving = false
		})
}

export const getDaysCodeList = () => {
	return [
		{
			_id: 1,
			code: 1,
			code_ext: 1,
			name_ext: "Pondelok"
		},
		{
			_id: 2,
			code: 2,
			name_ext: "Utorok"
		},
		{
			_id: 3,
			code: 3,
			name_ext: "Streda"
		},
		{
			_id: 4,
			code: 4,
			name_ext: "Štvrtok"
		},
		{
			_id: 5,
			code: 5,
			name_ext: "Piatok"
		},
		{
			_id: 6,
			code: 6,
			name_ext: "Sobota"
		},
		{
			_id: 0,
			code: 0,
			name_ext: "Nedeľa"
		}
	]
}

export const getHTMLFromTableText = (value) => {
	const formatedValue = value
		.replace(/</g, "&lt;")
		.replace(/>/g, "&gt;")
		.replace(/(\r\n|\n|\r)/gm, "<br />")
		.replace(/(\t)/gm, "&nbsp;&nbsp;&nbsp;&nbsp;")
	const isTable = value != formatedValue

	return {isTable: isTable, value: formatedValue}
}
