import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"

import fields from "./angiologieQuestionnaireFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsTextArea from "../../../../../../global/ui/xsTextArea/xsTextArea"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"

@injectIntl
@observer
export default class AngiologieQuestionnaireTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}
	}

	render() {
		return (
			<div className="pa-4">
				<Grid container direction="column" align="top">
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={6}>
							<XsInput field={this.form.$("hmotnost")} />
						</Grid>
						<Grid item xs={6}>
							<XsInput field={this.form.$("vyska")} />
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<XsTextArea field={this.form.$("otazka1")} hideToolbar={true} className="xs-height-100" />
					</Grid>
					<Grid item xs={12}>
						<XsTextArea field={this.form.$("otazka2")} hideToolbar={true} className="xs-height-100" />
					</Grid>
					<Grid item xs={12}>
						<XsTextArea field={this.form.$("otazka3")} hideToolbar={true} className="xs-height-100" />
					</Grid>
					<Grid item xs={12}>
						<XsTextArea field={this.form.$("otazka4")} hideToolbar={true} className="xs-height-100" />
					</Grid>
					<Grid item xs={12}>
						<XsTextArea field={this.form.$("otazka5")} hideToolbar={true} className="xs-height-100" />
					</Grid>
					<Grid item xs={12}>
						<XsTextArea field={this.form.$("otazka6")} hideToolbar={true} className="xs-height-100" />
					</Grid>
					<Grid item xs={12}>
						<XsTextArea field={this.form.$("otazka7")} hideToolbar={true} className="xs-height-100" />
					</Grid>
					<Grid item xs={12}>
						<XsTextArea field={this.form.$("otazka8")} hideToolbar={true} className="xs-height-100" />
					</Grid>
					<Grid item xs={12}>
						<XsTextArea field={this.form.$("otazka9")} hideToolbar={true} className="xs-height-100" />
					</Grid>
					<Grid item xs={12}>
						<XsTextArea field={this.form.$("otazka10")} hideToolbar={true} className="xs-height-100" />
					</Grid>
				</Grid>
			</div>
		)
	}
}
