import React from "react"
import ReactTooltip from "react-tooltip"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import "./xsExpansionPanel.less"

@observer
export class XsExpansionPanel extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isOpen: false,
			isGlobalOpen: false
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (!nextProps.lab) {
			if (nextProps.glOpen) {
				this.setState({isGlobalOpen: true, isOpen: true})
			} else {
				this.setState({isGlobalOpen: false, isOpen: false})
			}
			if (nextProps.forceOpen === true) {
				this.setState({isOpen: true})
			}
		}
	}

	handleArrowClick = () => {
		this.setState({isOpen: !this.state.isOpen})
	}

	render() {
		const {attachments, maxHeight} = this.props

		const expandedClass = this.state.isOpen ? "expanded" : "collapsed"

		return (
			<div className={`xs-expansion-panel ${expandedClass}`}>
				{this.state.isOpen && this.props.children}
				{!this.state.isOpen && isSafe(maxHeight) && (
					<div style={{height: `${maxHeight}px`, overflow: "hidden"}}>{this.props.children}</div>
				)}
				<div className="xs-row">
					{this.state.isOpen && (
						<div
							className="arrow greyLight mr-2 pointer"
							onClick={() => this.handleArrowClick()}
							data-tip
							data-for="hideRecordDetail"
						>
							<span className="black">...</span>
							<ReactTooltip id="hideRecordDetail">
								<FormattedMessage id="Common.label.hideRecordDetail" />
							</ReactTooltip>
						</div>
					)}
					{!this.state.isOpen && (
						<div
							className="arrow greyLight mr-2 pointer"
							onClick={() => this.handleArrowClick()}
							data-tip
							data-for="showRecordDetail"
						>
							<span className="black">...</span>

							<ReactTooltip id="showRecordDetail">
								<FormattedMessage id="Common.label.showRecordDetail" />
							</ReactTooltip>
						</div>
					)}
					{isSafe(attachments) && attachments.length > 0 && (
						<div onClick={() => this.handleArrowClick()} className="arrow xs-snowWhite blueInfo fontSize12 pointer">
							{this.state.isOpen && (
								<span data-tip data-for="hideAttachments">
									<i className="fal fa-paperclip"></i>
									<span className="xs-bold pl2">{attachments.length}</span>

									<ReactTooltip id="hideAttachments">
										<FormattedMessage id="Common.label.hideAttachments" />
									</ReactTooltip>
								</span>
							)}
							{!this.state.isOpen && (
								<span data-tip data-for="showAttachments">
									<i className="fal fa-paperclip"></i>
									<span className="xs-bold pl2">{attachments.length}</span>

									<ReactTooltip id="showAttachments">
										<FormattedMessage id="Common.label.showAttachments" />
									</ReactTooltip>
								</span>
							)}
						</div>
					)}
				</div>
			</div>
		)
	}
}

export default XsExpansionPanel
