"use strict"

import React from "react"
import {observer} from "mobx-react"
import ReactTooltip from "react-tooltip"
import MobxReactForm from "mobx-react-form"
import {FormattedMessage} from "react-intl"
import validatorjs from "validatorjs"
import Moment from "moment"
import XsDateTimePicker from "../../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsIconButton from "../../../../../../../global/ui/xsButton/xsIconButton"
import XsTable from "../../../../../../../global/ui/xsTable/xsTable"
import XsButton from "../../../../../../../global/ui/xsButton/xsButton"
import SearchSelect from "../../../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsConfirmationDialog from "../../../../../../../global/ui/xsDialog/xsConfirmationDialog"
import bindings from "../../../../../../../global/ui/globalUISchemeBindings"
import dataBindings from "./priceListBindings"
import {
	loadDataToFormByScheme,
	saveFormDataByScheme,
	insertFormDataByScheme,
	deleteDataByScheme,
	resetForm
} from "../../../../../../../global/helpers/bindings"
import Grid from "@material-ui/core/Grid"
import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"
import fields from "./priceListFields"
import GlobalStore from "../../../../../../../global/store/GlobalStore"
import PriceListFormStore from "../../../../../stores/PriceListFormStore"
import CodeListStore from "../../../../../stores/CodeListStore"
import XsNumberInput from "../../../../../../../global/ui/xsInput/xsNumberInput"

@observer
export default class PriceListTableEx extends React.Component {
	constructor(props) {
		super(props)
		GlobalStore.refreshCodeListsSync(["currencyUnit"])
		CodeListStore.loadProvidedServicePriceList(props.providedServiceID)

		const hooks = {
			onSubmit(/*form*/) {
				// special validation here
			},
			onSuccess(form) {
				if (PriceListFormStore.editable) {
					saveFormDataByScheme(
						form,
						dataBindings,
						{
							psID: props.providedServiceID
						},
						() => CodeListStore.loadProvidedServicePriceList(props.providedServiceID),
						false,
						"Admin.codelist.providedservices.pricelist.editSuccess"
					)
					PriceListFormStore.editable = false
				} else {
					insertFormDataByScheme(
						form,
						dataBindings,
						{
							psID: props.providedServiceID,
							priceID: null
						},
						() => CodeListStore.loadProvidedServicePriceList(props.providedServiceID),
						false,
						"Admin.codelist.providedservices.pricelist.saveSuccess"
					)
				}

				form.reset()
				PriceListFormStore.isAdd = false
			},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		this.initForm()
	}

	form

	initForm() {
		resetForm(this.form, dataBindings)
	}

	onHandleRowClick = (dataRow) => {
		loadDataToFormByScheme(this.form, dataBindings, {priceID: dataRow.delete})

		PriceListFormStore.editable = true
		PriceListFormStore.isAdd = true
	}

	deletePrice = () => {
		deleteDataByScheme(
			dataBindings,
			GlobalStore.confirmationDialogParams.priceID,
			() => CodeListStore.loadProvidedServicePriceList(this.props.providedServiceID),
			"Admin.codelist.providedservices.pricelist.deleteMessage"
		)
		PriceListFormStore.editable = false
		this.initForm()
	}

	render() {
		return (
			<div className="xs-pricelist-table-ex">
				<div className="xs-table">
					<XsTable
						config={{
							columnDefs: {
								price: {
									title: <FormattedMessage id="Delivery.List.Price" />,
									type: "string"
								},
								currency_unit: {
									title: <FormattedMessage id="Invoice.form.currency" />,
									type: "string"
								},
								valid_from: {
									title: <FormattedMessage id="Common.label.validFrom" />,
									type: "datetime",
									design: {
										width: "200px",
										body: {
											formatter: (props) => {
												return isSafe(props) && isNotEmpty(props) ? Moment(props).format("DD.MM.YYYY") : ""
											}
										}
									},
									sortable: true
								},
								valid_to: {
									title: <FormattedMessage id="Common.label.validTo" />,
									type: "datetime",
									design: {
										width: "200px",
										body: {
											formatter: (props) => {
												return isSafe(props) && isNotEmpty(props) ? Moment(props).format("DD.MM.YYYY") : ""
											}
										}
									},
									sortable: true
								},
								delete: {
									title: "",
									type: "action",
									design: {
										width: "5%",
										body: {
											renderer: (props) => {
												return (
													<XsIconButton
														className="action-delete"
														icon={
															<span>
																<i className="fal fa-trash-alt fa-lg xs-greyDefault" data-tip data-for="removePrice" />
																<ReactTooltip id="removePrice" effect="solid">
																	<FormattedMessage id="Common.label.removePrice" />
																</ReactTooltip>
															</span>
														}
														onClick={() =>
															GlobalStore.openConfirmationDialog("xsDeletePrice", {
																priceID: props.value
															})
														}
													/>
												)
											}
										}
									}
								}
							},
							options: {
								hidePager: true,
								showCursor: true,
								onRowClick: (dataRow) => {
									this.onHandleRowClick(dataRow)
								},
								mapper: (dataRow) => {
									return {
										valid_from: dataRow.valid_from,
										valid_to: dataRow.valid_to,
										price: dataRow.price,
										currency_unit: dataRow.currency_unit ? dataRow.currency_unit._id : null,
										delete: dataRow._id
									}
								}
							},
							dataSource: CodeListStore.providedServicePrices
						}}
					/>
				</div>
				<div className="xs-form">
					{PriceListFormStore.isAdd ? (
						<form>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={2}>
									<XsNumberInput field={this.form.$("price")}  step={"0.01"} min={"0.01"}/>
								</Grid>
								<Grid item xs={3}>
									<SearchSelect
										field={this.form.$("currency_unit")}
										items={GlobalStore.CL["currencyUnit"]}
										menuPlacement="top"
									/>
								</Grid>
								<Grid item xs={2}>
									<XsDateTimePicker field={this.form.$("valid_from")} showTimeSelect={false} />
								</Grid>
								<Grid item xs={2}>
									<XsDateTimePicker field={this.form.$("valid_to")} showTimeSelect={false} />
								</Grid>
								{/* <Grid item xs={3}><XsInput white={true} field={this.form.$("end_reason")} /></Grid> */}
								<Grid item xs={3} className="xs-form-actions">
									{PriceListFormStore.editable ? (
										<XsButton
											className="xs-success mr-3"
											type="submit"
											onClick={this.form.onSubmit}
											text={<FormattedMessage id="Common.label.save" />}
											icon={<SaveIcon />}
										/>
									) : (
										<XsButton
											className="xs-success mr-3"
											type="submit"
											onClick={this.form.onSubmit}
											text={<FormattedMessage id="Common.label.add" />}
											icon={<SaveIcon />}
										/>
									)}
									<XsButton
										className="xs-default xs-outline"
										onClick={() => {
											this.initForm()
											PriceListFormStore.isAdd = false
											PriceListFormStore.editable = false
										}}
										text={<FormattedMessage id="Common.label.cancel" />}
										icon={<CancelIcon />}
									/>
								</Grid>
							</Grid>
						</form>
					) : (
						<XsButton
							className="xs-default xs-outline xs-addAnother"
							icon={<i className="fal fa-plus-circle" />}
							text={<FormattedMessage id="Common.button.addAnother2" />}
							onClick={() => {
								PriceListFormStore.isAdd = true
								PriceListFormStore.editable = false
								this.initForm()
							}}
						/>
					)}
				</div>
				<XsConfirmationDialog
					type="danger"
					name="xsDeletePrice"
					text={<FormattedMessage id="Admin.codelist.providedservices.pricelist.confirmationMessage" />}
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.btn.pricelist.delete"
					}}
					onConfirmation={() => this.deletePrice()}
				/>
			</div>
		)
	}
}
