/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"
import {getSelectedOrgUnit} from "../../../../../global/helpers/actions"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				orgunit: {
					label: <FormattedMessage id="Patient.form.patientrequest.orgunit" />,
					rules: "string",
					value: getSelectedOrgUnit()._id
				},
				deliveries: {
					label: <FormattedMessage id="Patient.form.patientrecord.deliveries" />,
					rules: "required",
				},
				datefrom: {
					label: <FormattedMessage id="Capitation.List.From" />,
					type: "date",
					value: moment().startOf("month")
				},
				dateto: {
					label: <FormattedMessage id="Capitation.List.To" />,
					type: "date",
					value: moment().endOf("month")
				}
			}
		}
	}
}
