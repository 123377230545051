import React from "react"
import {FormattedMessage} from "react-intl"

import moment from "moment"

export default {
	load() {
		return {
			fields: {
				time_from: {
					value: moment()
						.startOf("month")
						.format("YYYY-MM-DD")
				},
				time_to: {
					value: moment()
						.endOf("month")
						.format("YYYY-MM-DD")
				},
				from: {
					label: <FormattedMessage id="Common.label.from" />,
					type: "date"
				},
				to: {
					label: <FormattedMessage id="Common.label.to" />,
					type: "date"
				},
				payerType: {
					label: <FormattedMessage id="Common.label.payerType" />,
					rules: "required",
					value: "P"
				},
				person_identifier: {
					label: <FormattedMessage id="Patient.labels.IDNumber" />
				},
				diagnosis: {
					label: <FormattedMessage id="Patient.labels.Diagnosis" />
				},
				deliveries: {
					label: <FormattedMessage id="Delivery.list.modal.deliveryCode" />,
					placeholder: "napr. C10,1"
				},
				insurer: {
					label: <FormattedMessage id="Common.label.insurer" />
				},
				insurence_type: {
					label: <FormattedMessage id="Agreement.Container.Detail.Attribute.InsuranceType" />
				}
			}
		}
	}
}
