import {FormattedMessage} from "react-intl"
import React from "react"
import {getSelectedOrgUnit} from "../../../../../global/helpers/actions"

export default {
	load() {
		return {
			fields: {
				orgunit: {
					label: <FormattedMessage id="Patient.form.patientrequest.orgunit" />,
					rules: "string",
					value: getSelectedOrgUnit()._id
				},
				// next_date_from: {
				// 	label: <FormattedMessage id="Common.label.nextDateFromPP" />,
				// 	type: "date"
				// },
				// next_date_to: {
				// 	label: <FormattedMessage id="Common.label.nextDateToPP" />,
				// 	type: "date"
				// },
				last_date_from: {
					label: <FormattedMessage id="Common.label.lastDateFromPP" />,
					type: "date"
				},
				last_date_to: {
					label: <FormattedMessage id="Common.label.lastDateToPP" />,
					type: "date"
				},
				insurer: {
					label: <FormattedMessage id="Common.label.insurer" />,
					type: "string"
				},
				identifier: {
					label: <FormattedMessage id="Patient.labels.IDNumber" />,
					type: "string"
				}
			}
		}
	}
}
