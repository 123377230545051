import React from "react"
import "./xsSearchdropdown.less"
import {TextField} from "@material-ui/core"

const SearchFieldNoForm = ({value, onChange, placeholder}) => {
	// const clear = () => {
	//   onChange("")
	//   if (onClear) {
	//     onClear()
	//   }
	// }

	const handleOnChange = (e) => {
		if (typeof onChange == "function") onChange(e.target.value)
	}

	return (
		<div className="searchContainer">
			<div className="searchField">
				<div className="xs-icon-search">
					<i className="fal fa-search" />
				</div>
				{/* <XsInput
          id={id}
          underlineStyle={{ display: "none" }}
          value={value}
          onChange={handleOnChange}
          placeholder={placeholder}
          fullWidth={false}
          {...other} /> */}
				<TextField
					InputLabelProps={{
						shrink: false
					}}
					value={value}
					autoComplete="off"
					onChange={handleOnChange}
					placeholder={placeholder}
				/>
			</div>
		</div>
	)
}

export default SearchFieldNoForm
