import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import {Grid, TextField} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsInputSearchFilter from "../../../../../global/ui/xsInput/xsInputSearchFilter"
// import XsDropdownList from "../../../../../global/ui/xsDropdown/xsDropdownList"

import api from "../../../actions/api"
import AgreementAttributeStore from "../../../stores/AgreementAttributeStore"
import WarningStore from "../../../../../global/store/WarningStore"
// import GlobalStore from "../../../../../global/store/GlobalStore"

@injectIntl
@observer
export default class PricePerAttributeItemForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isSaving: false
		}
	}

	enableSave = () => {
		this.setState({isSaving: false})
	}

	hasDuplicityDelivery = () => {
		let hasDuplicity = false

		if (
			isSafe(AgreementAttributeStore.attributeDataJSON) &&
			AgreementAttributeStore.attributeDataJSON.hasOwnProperty(AgreementAttributeStore.typeFilter)
		) {
			Object.keys(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]).some((attrKey) => {
				let existDeliveryItemId =
					AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][attrKey].delivery_item._id

				if (
					`${AgreementAttributeStore.pricePerAttributeItemFormDeliveryId}`.trim() === `${existDeliveryItemId}`.trim()
				) {
					hasDuplicity = true
					return true
				}
			})
		}

		return hasDuplicity
	}

	render() {
		return (
			<Grid container direction="row" spacing={8} alignItems="flex-end">
				<Grid item xs={3}>
					<XsInputSearchFilter
						api={api.getDeliveryItems}
						disabled={AgreementAttributeStore.isEditAttributeGrid}
						label={
							<span className="xs-bold">
								<FormattedMessage id="Delivery.List.AddItem" />*
							</span>
						}
						isValid={
							isEmpty(AgreementAttributeStore.pricePerAttributeItemFormDelivery) &&
							!AgreementAttributeStore.isEditAttributeGrid
						}
						minLengthForSearch={3}
						onButtonClick={(value) => delayedCallback(500, () => AgreementAttributeStore.searchDeliveriesItem(value))}
						data={AgreementAttributeStore.deliveriesItem}
						onChange={(value) => (AgreementAttributeStore.pricePerAttributeItemFormDelivery = value)}
						onSearchClose={() => (AgreementAttributeStore.deliveriesItem = [])}
						// defaultValue={AgreementAttributeStore.pricePerPointFormDeliveryId}
						value={AgreementAttributeStore.pricePerAttributeItemFormDelivery}
						inputRenderer={(dataRow) => {
							return dataRow.name_ext
						}}
						valueRenderer={(dataRow) => {
							return dataRow.name_ext
						}}
						chooseItem={(value) => {
							AgreementAttributeStore.pricePerAttributeItemFormDelivery = value.name_ext
							AgreementAttributeStore.pricePerAttributeItemFormDeliveryId = value._id
						}}
						modalTitle={<FormattedMessage id="Delivery.List.AddItems" />}
						modalConfig={(textValue, clickHandler) => {
							return {
								columnDefs: {
									name_ext: {
										title: <FormattedMessage id="Common.label.name" />,
										type: "string",
										dbName: "search_column",
										filter: {
											gridWidth: 9,
											defaultValue: textValue,
											renderElement: "input"
										}
										// sortable: true
									}
								},
								options: {
									// selectRow: true,
									showCursor: true,
									onRowClick: (dataRow) => {
										clickHandler(dataRow)
									}
								}
							}
						}}
					/>
				</Grid>
				<Grid item xs={2}>
					<TextField
						value={AgreementAttributeStore.agreementAttributeCurrency}
						disabled={AgreementAttributeStore.isEditAttributeGrid}
						onChange={(e) => (AgreementAttributeStore.agreementAttributeCurrency = e.target.value)}
						label={
							<span className="xs-bold">
								<FormattedMessage id="Agreement.Container.Detail.Attribute.Price" />*
							</span>
						}
						InputLabelProps={{
							shrink: true
						}}
						InputProps={{
							classes: {
								root:
									!isPrice(AgreementAttributeStore.agreementAttributeCurrency) &&
									!AgreementAttributeStore.isEditAttributeGrid
										? "xs-input-error"
										: ""
							}
						}}
					/>
				</Grid>
				<Grid item xs={7} className="xs-form-actions">
					<XsButton
						className={AgreementAttributeStore.isEditAttributeGrid ? "xs-default xs-outline" : "xs-success xs-outline"}
						disabled={this.state.isSaving || AgreementAttributeStore.isEditAttributeGrid}
						text={<FormattedMessage id="Common.label.add" />}
						onClick={() => {
							if (
								isEmpty(AgreementAttributeStore.agreementAttributeCurrency) ||
								!isPrice(AgreementAttributeStore.agreementAttributeCurrency) ||
								isEmpty(AgreementAttributeStore.pricePerAttributeItemFormDeliveryId)
							) {
								WarningStore.open(
									`${this.props.intl.formatMessage({id: "Patient.form.patientPrescription.invalidForm"})}`
								)
							} else if (this.hasDuplicityDelivery()) {
								WarningStore.open(
									`${this.props.intl.formatMessage({
										id: "Agreement.Container.Detail.Attribute.pricePerDelivery.duplicityDelivery"
									})}`
								)
							} else {
								this.setState({isSaving: true}, AgreementAttributeStore.saveAttributes(this.enableSave.bind()))
							}
						}}
					/>
				</Grid>
			</Grid>
		)
	}
}
