import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"

import fields from "./medicalFindingsInParkingCardFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"

import {getSelectedOrgUnitID} from "../../../../../../global/helpers/actions"
import DataStore from "../../../../stores/DataStore"

import api from "../../../../actions/api"

@injectIntl
@observer
export default class MedicalFindingsInParkingCardTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		api
			.loadAnnamnesis(getSelectedOrgUnitID(), DataStore.patientDTO.get("patientID"))
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.data) && res.data.length > 0) {
					const personAna = res.data.find((x) => x.type._id == "ANA")

					if (isSafe(personAna) && isNotEmpty(personAna.content)) {
						this.form.$("anamnesis").value = htmlToPlain(personAna.content)
					}
				}
			})
	}

	render() {
		return (
			<div className="pa-4">
				<Grid container direction="column" align="top">
					<div style={{marginBottom: "20px", marginTop: "20px", fontWeight: "bold"}}>I. Anamnéza</div>
					<Grid item xs={12}>
						<XsInput field={this.form.$("anamnesis")} multiple rows={3} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("subjectiveDifficulties")} multiple rows={3} />
					</Grid>

					<div style={{marginBottom: "20px", marginTop: "20px", fontWeight: "bold"}}>II. Objektívny nález</div>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={2}>
							<XsInput field={this.form.$("height")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("weight")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("bmi")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("tk")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("pulse")} />
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<XsInput field={this.form.$("habitus")} multiple rows={3} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("orientation")} multiple rows={3} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("position")} multiple rows={3} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("posture")} multiple rows={3} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("walk")} multiple rows={3} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("continenceDisorder")} multiple rows={3} />
					</Grid>

					<div style={{marginBottom: "20px", marginTop: "20px", fontWeight: "bold"}}>II. A</div>
					<div style={{marginBottom: "20px", fontWeight: "bold"}}>
						Pri interných ochoreniach uviesť fyzikálny nález a doplniť výsledky odborných vyšetrení, ak nie sú uvedené v
						priloženom náleze
					</div>
					<Grid item xs={12}>
						<XsInput field={this.form.$("iiA")} multiple rows={3} />
					</Grid>

					<div style={{marginBottom: "20px", marginTop: "20px", fontWeight: "bold"}}>II. B</div>
					<div style={{marginBottom: "20px", fontWeight: "bold"}}>
						Pri ortopedických ochoreniach, neurologických ochoreniach a poúrazových stavoch
					</div>
					<Grid item xs={12}>
						<XsInput field={this.form.$("iiB")} multiple rows={3} />
					</Grid>

					<div style={{marginBottom: "20px", marginTop: "20px", fontWeight: "bold"}}>
						III. Diagnostický záver (podľa Medzinárodnej klasifikácie chorôb s funkčným vyjadrením)
					</div>
					<Grid item xs={12}>
						<XsInput field={this.form.$("iii")} multiple rows={3} />
					</Grid>

					<Grid container direction="row" align="left" spacing={8} style={{marginTop: "20px"}}>
						<Grid item xs={4}>
							<XsInput field={this.form.$("in")} />
						</Grid>
						<Grid item xs={4}>
							<XsDateTimePicker field={this.form.$("date")} showTimeSelect={false} />
						</Grid>
					</Grid>
				</Grid>
			</div>
		)
	}
}
