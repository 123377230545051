import React from "react"
import {observer} from "mobx-react"
import MedicationList from "./medicationList"
import VaccList from "./vaccList"
import VaccinatedList from "./vaccinatedList"
import XsTabsIcon from "../../../../../global/ui/xsTabs/xsTabsIcon"
import VaccinationListStore from "../../../stores/VaccinationListStore"
import {injectIntl} from "react-intl"
import {Grid} from "@material-ui/core"

function vaccReportsContainer(props) {
	return (
		<Grid container className="xs-drugReport-list">
			<Grid item xs={12}>
				<XsTabsIcon
					value={VaccinationListStore.tabCard}
					onChangeTab={(value) => {
						VaccinationListStore.tabCard = value
					}}
					mainClass="xs-patient-card-tab"
					background="white"
					tabs={[
						//ockovany
						{
							label: props.intl.formatMessage({id: "Common.label.vaccination"}),
							value: "vacc",
							icon:
								VaccinationListStore.tabCard === "vacc" ? (
									<i className="fas fa-syringe fa-2x" />
								) : (
									<i className="fal fa-syringe fa-2x" />
								),
							content: <VaccinatedList />
						},
						//cakajuci na dalsiu davku
						{
							label: props.intl.formatMessage({id: "Common.label.vaccWaiting"}),
							value: "vaccWaiting",
							icon:
								VaccinationListStore.tabCard === "vaccWaiting" ? (
									<i className="fas fa-sort-amount-down fa-2x" />
								) : (
									<i className="fal fa-sort-amount-down fa-2x" />
								),
							content: <VaccList />
						},
						{
							label: props.intl.formatMessage({id: "Common.label.medication"}),
							value: "medications",
							icon:
								VaccinationListStore.tabCard === "medications" ? (
									<i className="fas fa-notes-medical fa-2x" />
								) : (
									<i className="fal fa-notes-medical fa-2x" />
								),
							content: <MedicationList />
						}
					]}
				/>
			</Grid>
		</Grid>
	)
}

export default injectIntl(observer(vaccReportsContainer))
