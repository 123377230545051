"use strict"

import {observable, action} from "mobx"
import moment from "moment"
import DataStore from "./DataStore"
import HospicomReportStore from "./HospicomReportStore"
import api from "../actions/api"
import UIStore from "./UIStore"
import RouterStore from "../../../global/store/RouterStore"
import RegistersCacheStore from "../../../global/store/RegistersCacheStore"
import DDaction from "../actions/patientCard"
import {getSelectedOrgUnitID} from "../../../global/helpers/actions"
import {getFilters} from "../../../global/helpers/api"

class HospicomDialogStore {
	@observable isOpen = false
	@observable deliveries = []
	form = null

	@action open() {
		this.isOpen = true
	}

	@action close(form) {
		this.isOpen = false
		if (isSafe(form)) {
			form.reset()
		}
	}

	@action saveForm(form) {
		const values = form.values()

		const patientId = isNotEmpty(values.patientId) ? values.patientId : DataStore.patientDTO.get("patientID")

		let data = {
			type_code: "NZS",
			doctor_id: isNotEmpty(values.responsibleDoctorPersonnelId) ? values.responsibleDoctorPersonnelId : null,
			cl_doctor_expertise: isNotEmpty(values.responsibleDoctorExpertiseId) ? values.responsibleDoctorExpertiseId : null,
			estimated_price: isNotEmpty(values.expectedPrice) ? values.expectedPrice : null,
			external_note: values.noteForHospicom,
			internal_note: values.internalNote,
			patient_id: patientId,
			planned_at: values.proposalDateZS,
			proposed_at: values.proposalDate,
			proposal_type: values.zsType,
			organ_type_id: isNotEmpty(values.organType) ? values.organType : null,
			proposal_status: values.state ? values.state : "N",
			provider_id: values.plannedOrgunit,
			confirmed_at: values.confirmed_at,
			incoming_at: values.incoming_at,
			record_diagnoses: [
				{
					active: true,
					diagnosis_type: {_id: "PRIMARY", _type: "EHR.CL.Record.DiagnosisType"},
					item: {_type: "EHR.CL.Record.Diagnosis", _id: values.diagnosis},
					item_order: 1,
					life_threatening: false,
					type: "dgn",
					_ref: false,
					_type: "EHR.Data.Record.Diagnosis"
				}
			],
			sending_doctor_expertise_id: isNotEmpty(values.sendingDoctorExpertiseId) ? values.sendingDoctorExpertiseId : null,
			sending_doctor_id: isNotEmpty(values.sendingDoctorPersonnelId) ? values.sendingDoctorPersonnelId : null,
			sending_provider_id: isNotEmpty(values.sendingDoctorOUPZS) ? values.sendingDoctorOUPZS : null,
			version: "final"
		}

		if (isNotEmpty(values.drugsType)) {
			data.record_products = [
				{
					_ref: false,
					active: true,
					item: {
						/* Liek z číselníka */
						_id: values.drugsType == "EHR.CL.Medication.SK.Drug" ? values.drugs : values.medEquip,
						_type: values.drugsType
					},
					quantity: values.drugsAmout /* Množstvo */,
					type: "pdt",
					unit_price: values.drugPrice /* Cena */
				}
			]
		}

		if (isNotEmpty(values.recordId)) {
			data.record_id = values.recordId
		}

		api
			.savePrescription(data)
			.call()
			.then((response) => {
				if (isNotEmpty(values.delivery)) {
					const req = {
						_ref: false,
						_type: "EHR.Data.Record.SK.Delivery",
						item: {_id: values.delivery, _type: "EHR.CL.Bill.SK.Delivery"},
						count: 1,
						type: "dlv",
						payer_type: "Z",
						insuree_type: null,
						refund_type: null,
						delivery_extension: null,
						delivery_date_time: values.proposalDateZS,
						sender_type: null,
						request_date: null,
						diagnosis: {_id: values.diagnosis, _type: "EHR.CL.Record.Diagnosis"}
					}
					api
						.saveDeliveries(req, response._id)
						.call()
						.then(() => {
							UIStore.isFormSaving = false
							if (RouterStore.location.pathname === "/patient") {
								if (isSafe(UIStore.patientCardContainerOPPRefresh)) {
									UIStore.patientCardContainerOPPRefresh(Date.now())
								}
								DDaction.loadPatientOverview(getSelectedOrgUnitID(), DataStore.patientDTO.get("patientID")).then(
									(overview) => {
										DataStore.setPatientEHR(overview)
									}
								)
							} else {
								HospicomReportStore.loadHospicomReport(HospicomReportStore.formRef)
							}
							this.close(form)
						})
				} else {
					UIStore.isFormSaving = false
					if (RouterStore.location.pathname === "/patient") {
						DDaction.loadPatientOverview(getSelectedOrgUnitID(), DataStore.patientDTO.get("patientID")).then(
							(overview) => {
								DataStore.setPatientEHR(overview)
							}
						)
					} else {
						HospicomReportStore.loadHospicomReport(HospicomReportStore.formRef)
					}
					this.close(form)
				}
			})
	}

	@action loadDeliveries() {
		// api
		// 	.loadDeliveries()
		// 	.call()
		// 	.then((res) => {
		// 		if (isSafe(res) && isSafe(res.rows) && res.rows.length > 0) {
		// 			this.deliveries = res.rows.filter((x) => ["8539", "8539a", "8573", "8567"].includes(x.code_ext))
		// 		} else {
		// 			this.deliveries = []
		// 		}
		// 	})
			this.deliveries = []
	}

	@action async loadDeliveryPrice(form, deliveryId) {
		let insurerId = DataStore.patientDTO.get("insurerId")

		if (isNotEmpty(form.$("insurer_code_ext").value)) {
			const ins = RegistersCacheStore.insurersRegister.find((x) => x.code_ext == form.$("insurer_code_ext").value)

			if (isSafe(ins)) {
				insurerId = ins._id
			}
		}

		let price = await api
			.getDeliveryPrice(deliveryId, {
				org_unit_id: getSelectedOrgUnitID(),
				insurer_id: insurerId,
				in_capitation: false,
				date: moment().format("YYYY-MM-DD")
			})
			.call()

		if (isSafe(price)) {
			form.$("expectedPrice").value = price.price_in_currency
		}
	}

	@action load(data) {
		if (isNotEmpty(data) && isNotEmpty(data.record_id)) {
			this.form.reset()

			api
				.loadSpecificHospicomReport(data.record_id)
				.call()
				.then(async (report) => {
					if (isSafe(this.form)) {
						this.form.$(
							"patientInfo"
						).value = `${data.full_name.fullName}, ${data.identifier} (${data.insurer_code_ext})`
						this.form.$("recordId").value = report.record_id
						this.form.$("insurer_code_ext").value = data.insurer_code_ext
						this.form.$("patientId").value = isSafe(data.patient_id) ? data.patient_id : null
						this.form.$("state").value = report.proposal_status
						this.form.$("loadState").value = report.proposal_status
						this.form.$("plannedOrgunit").value = report.provider_id
						this.form.$("sendingDoctorOUPZS").value = report.sending_provider_id
						if (isSafe(report.doctor_card)) {
							let filters = getFilters([
								`expertise_id=${report.doctor_card.expertise_id}`,
								`personnel_id=${report.doctor_id}`,
								`provider_id=${report.provider_id}`
							])
							let response = await api.loadPersonnel(filters).call()
							if (isSafe(response) && isSafe(response.rows) && response.rows.length) {
								this.form.$("responsibleDoctor").set("value", response.rows[0].personnel_expertise_id)
								this.form.$("responsibleDoctorPersonnelId").set("value", response.rows[0].personnel_id)
							}
						}

						this.form.$("zsType").value = report.proposal_type
						this.form.$("expectedPrice").value = report.estimated_price
						this.form.$("noteForHospicom").value = report.external_note
						this.form.$("internalNote").value = report.internal_note
						this.form.$("proposalDateZS").value = report.planned_at
						this.form.$("proposalDate").value = report.proposed_at
						this.form.$("proposalDate").value = report.proposed_at
						this.form.$("organType").value = report.organ_type_id
						this.form.$("diagnosis").value = isSafe(report.diagnosis) ? report.diagnosis : ""
						this.form.$("sendingDoctorPersonnelExpertiseId").value = report.sending_personnel_expertise_id
						this.form.$("confirmed_at").value = report.confirmed_at
						this.form.$("incoming_at").value = report.incoming_at

						this.form.$("drugsAmout").set("rules", "")
						this.form.$("drugs").set("rules", "")
						this.form.$("medEquip").set("rules", "")
						this.form.$("drugPrice").set("rules", "")

						if (
							isSafe(report.record_products) &&
							isSafe(report.record_products[0]) &&
							isSafe(report.record_products[0].item)
						) {
							const product = report.record_products[0]
							this.form.$("drugsType").value = product.item._type

							if (this.form.$("drugsType").value == "EHR.CL.Medication.SK.Drug") {
								this.form.$("drugs").value = product.item._id
								this.form.$("drugsAmout").set("rules", "required")
								this.form.$("drugs").set("rules", "required")
								this.form.$("drugPrice").set("rules", "required")
							} else if (this.form.$("drugsType").value == "EHR.CL.Medication.SK.MedicalDevice") {
								this.form.$("medEquip").value = product.item._id
								this.form.$("drugsAmout").set("rules", "required")
								this.form.$("medEquip").set("rules", "required")
								this.form.$("drugPrice").set("rules", "required")
							}
							this.form.$("drugPrice").value = product.unit_price
							this.form.$("drugsAmout").value = product.quantity
						}

						if (isSafe(report.deliveries) && report.deliveries.length > 0) {
							const delivery = report.deliveries[0]

							if (isSafe(delivery.item)) {
								this.form.$("delivery").value = delivery.item._id
							}
						}
					}
					this.open()
				})
		}
	}

	@action storno(id) {
		UIStore.isFormSaving = true
		const request = {
			_id: id,
			_type: "EHR.Data.HealthCareProposal",
			reason: "Storno",
			active: false
		}

		api
			.stornoRecords(request)
			.call()
			.then(() => {
				if (RouterStore.location.pathname === "/patient") {
					DDaction.loadPatientOverview(
						getSelectedOrgUnitID(),
						DataStore.patientDTO.get("patientID"),
						null,
						null,
						true
					).then((overview) => {
						DataStore.setPatientEHR(overview)
						UIStore.isFormSaving = false
					})
				} else {
					HospicomReportStore.loadHospicomReport(HospicomReportStore.formRef)
				}
				this.close(this.form)
			})
	}
}

var singleton = new HospicomDialogStore()
export default singleton
