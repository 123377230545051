"use strict"

import {observable, action} from "mobx"

class UIStore {
	@observable employeeDetailTabs = "employeeCard"
	@observable openSnackBar = null
	@observable orgunitDetailIconTabs = "employees"
	@observable listCategory = "employees"

	@action setSnackbar(value) {
		this.openSnackBar = value
	}
}
var singleton = new UIStore()
export default singleton
