import {observable, action} from "mobx"
import api from "../actions/api"

// import PatientPrescriptionContainerStore from "./PatientPrescriptionContainerStore"
import UIStore from "../stores/UIStore"
import GlobalStore from "../../../global/store/GlobalStore"

class MedicationDistDialogStore {
	@observable isOpen = false
	@observable isLoading = false
	@observable dataSource = []
	@observable diagnosisSource = []
	providerId = null
	patientId = null

	@observable searchCode = ""
	@observable searchName = ""
	@observable searchDate = null
	@observable searchDiagnose = ""

	@observable medIds = []

	callback = null

	@action open(providerId, patient_id, callback) {
		this.isLoading = true
		this.isOpen = true
		this.providerId = providerId
		this.patientId = patient_id
		this.callback = callback

		this.findEHRs()
	}

	@action onHandleCheck(medicationId, checked) {
		if (checked) {
			this.medIds.push(medicationId)
		} else {
			const idx = this.medIds.indexOf(medicationId)
			this.medIds.splice(idx, 1)
		}
	}

	findEHRs() {
		const filters = [
			{
				associated_column: "patient_id",
				values: [{id_value: this.patientId}]
			},
			{
				associated_column: "provider_id",
				values: [{id_value: this.providerId}]
			},
			{
				associated_column: "distinct_mode",
				values: [{id_value: "EHR.Data.Medication:RECIPE"}]
			},
			{
				associated_column: "type_id",
				values: [{id_value: "EHR.Data.Medication:RECIPE"}]
			},
			{
				associated_column: "version",
				predicate: "=",
				values: [{id_value: "final"}]
			}
		]

		api
			.loadFilteredEHROverview(filters)
			.call()
			.then((data) => {
				this.dataSource = data.rows.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
				this.isLoading = false

				let distDiagnosis = []
				this.dataSource.forEach((x) => {
					if (distDiagnosis.some((d) => d.value == x.diagnosis)) {
						// eslint-disable-line
					} else {
						distDiagnosis.push({
							label: `${x.diagnosis_code_ext} ${x.diagnosis_name_ext}`,
							value: x.diagnosis
						})
					}
				})

				this.diagnosisSource = distDiagnosis.sort((a, b) => a.value - b.value)
			})
			.catch(() => {
				this.isLoading = false
			})
	}

	@action onReprescribe() {
		if (UIStore.patientCardTab === "prescription") {
			GlobalStore.handleClickPrescriptionFocus("0")
		}

		let recs = this.medIds.map((x) => {
			return {
				record_id: x
			}
		})
		// recs.push(this.medIds[this.medIds.length - 1])
		this.callback(recs)

		this.close()
	}

	@action close() {
		this.isOpen = false
		this.dataSource = []
		this.diagnosisSource = []
		this.medIds = []
		this.providerId = null
		this.patientId = null
		this.searchCode = ""
		this.searchName = ""
		this.searchDate = null
		this.searchDiagnose = ""
	}
}

var singleton = new MedicationDistDialogStore()
export default singleton
