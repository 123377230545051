import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import {Grid, TextField} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsDropdownList from "../../../../../global/ui/xsDropdown/xsDropdownList"
import XsInputSearchFilter from "../../../../../global/ui/xsInput/xsInputSearchFilter"
import AgreementAttributeStore from "../../../stores/AgreementAttributeStore"
import WarningStore from "../../../../../global/store/WarningStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

import api from "../../../actions/api"

@injectIntl
@observer
export default class PricePerPointByGroupDeliveryForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isSaving: false
		}
	}

	enableSave = () => {
		this.setState({isSaving: false})
	}

	hasDuplicityDelivery = () => {
		let hasDuplicity = false

		if (
			isSafe(AgreementAttributeStore.attributeDataJSON) &&
			AgreementAttributeStore.attributeDataJSON.hasOwnProperty(AgreementAttributeStore.typeFilter)
		) {
			Object.keys(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]).some((attrKey) => {
				const attribute = AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][attrKey]

				const existDeliveryGroup = attribute.delivery_group._id
				const existClExpertiseId = attribute.cl_expertise._id

				if (
					`${AgreementAttributeStore.pricePerPointByDeliveryGroupFormDeliveryGroup}`.trim() ===
						`${existDeliveryGroup}`.trim() &&
					`${AgreementAttributeStore.pricePerPointByDeliveryGroupFormExpertiseId}`.trim() ===
						`${existClExpertiseId}`.trim()
				) {
					hasDuplicity = true
					return true
				}
			})
		}

		return hasDuplicity
	}

	render() {
		return (
			<Grid container direction="row" spacing={8} alignItems="flex-end">
				<Grid item xs={3}>
					<XsDropdownList
						emptyValue
						disabled={AgreementAttributeStore.isEditAttributeGrid}
						label={
							<span className="xs-bold">
								<FormattedMessage id="Common.label.deliveryGroup" />*
							</span>
						}
						items={GlobalStore.CL["deliveryGroup"]}
						className={
							isEmpty(
								AgreementAttributeStore.pricePerPointByDeliveryGroupFormDeliveryGroup &&
									!AgreementAttributeStore.isEditAttributeGrid
							)
								? "xs-input-error"
								: ""
						}
						renderer={(item) => {
							return item.name_ext
						}}
						column="_id"
						value={AgreementAttributeStore.pricePerPointByDeliveryGroupFormDeliveryGroup}
						chooseItem={(item) => {
							AgreementAttributeStore.pricePerPointByDeliveryGroupFormDeliveryGroup = item._id
						}}
					/>
				</Grid>
				<Grid item xs={3}>
					<XsInputSearchFilter
						api={api.loadExpertisePr}
						disabled={AgreementAttributeStore.isEditAttributeGrid}
						label={
							<span className="xs-bold">
								<FormattedMessage id="Patient.form.patientrequest.expertise" />*
							</span>
						}
						isValid={
							isEmpty(AgreementAttributeStore.pricePerPointByDeliveryGroupFormExpertise) &&
							!AgreementAttributeStore.isEditAttributeGrid
						}
						minLengthForSearch={3}
						onButtonClick={(value) => delayedCallback(500, () => AgreementAttributeStore.searchExpertise(value))}
						data={AgreementAttributeStore.clExpertise}
						onChange={(value) => (AgreementAttributeStore.pricePerPointByDeliveryGroupFormExpertise = value)}
						onSearchClose={() => (AgreementAttributeStore.clExpertise = [])}
						value={AgreementAttributeStore.pricePerPointByDeliveryGroupFormExpertise}
						inputRenderer={(dataRow) => {
							return dataRow.code_ext + " " + dataRow.name_ext
						}}
						valueRenderer={(dataRow) => {
							return dataRow.code_ext + " " + dataRow.name_ext
						}}
						chooseItem={(value) => {
							AgreementAttributeStore.pricePerPointByDeliveryGroupFormExpertise = value.code_ext + " " + value.name_ext
							AgreementAttributeStore.pricePerPointByDeliveryGroupFormExpertiseId = value._id
						}}
						onBlur={(e) => {
							if (isEmpty(e.target.value)) {
								AgreementAttributeStore.pricePerPointByDeliveryGroupFormExpertiseId = ""
							}
						}}
						modalTitle={<FormattedMessage id="Patient.form.patientrequest.expertise" />}
						modalConfig={(textValue, clickHandler) => {
							return {
								columnDefs: {
									code_ext: {
										title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
										type: "string",
										dbName: "code_ext",
										design: {
											width: "150px"
										},
										filter: {
											gridWidth: 3,
											defaultValue: "",
											renderElement: "input"
										}
									},
									name_ext: {
										title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
										type: "string",
										dbName: "search_column",
										filter: {
											gridWidth: 9,
											defaultValue: textValue,
											renderElement: "input"
										}
									}
								},
								options: {
									showCursor: true,
									onRowClick: (dataRow) => {
										clickHandler(dataRow)
									}
								}
							}
						}}
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						value={AgreementAttributeStore.agreementAttributeCurrency}
						disabled={AgreementAttributeStore.isEditAttributeGrid}
						onChange={(e) => (AgreementAttributeStore.agreementAttributeCurrency = e.target.value)}
						label={
							<span className="xs-bold">
								<FormattedMessage id="Agreement.Container.Detail.Attribute.Price" />*
							</span>
						}
						InputLabelProps={{
							shrink: true
						}}
						InputProps={{
							classes: {
								root:
									!isPrice(AgreementAttributeStore.agreementAttributeCurrency) &&
									!AgreementAttributeStore.isEditAttributeGrid
										? "xs-input-error"
										: ""
							}
						}}
					/>
				</Grid>
				<Grid item xs={3} className="xs-form-actions">
					<XsButton
						className={AgreementAttributeStore.isEditAttributeGrid ? "xs-default xs-outline" : "xs-success xs-outline"}
						disabled={this.state.isSaving || AgreementAttributeStore.isEditAttributeGrid}
						text={<FormattedMessage id="Common.label.add" />}
						onClick={() => {
							if (!isPrice(AgreementAttributeStore.agreementAttributeCurrency)) {
								WarningStore.open(
									`${this.props.intl.formatMessage({id: "Patient.form.patientPrescription.invalidForm"})}`
								)
							} else if (this.hasDuplicityDelivery()) {
								WarningStore.open(
									`${this.props.intl.formatMessage({
										id: "Agreement.Container.Detail.Attribute.pricePerDelivery.duplicityDelivery"
									})}`
								)
							} else {
								this.setState({isSaving: true}, AgreementAttributeStore.saveAttributes(this.enableSave.bind()))
							}
						}}
					/>
				</Grid>
			</Grid>
		)
	}
}
