"use strict"

import {observable, action} from "mobx"
import moment from "moment"
import api from "../actions/api"
import VaccinationStore from "./VaccinationListStore"
import {exportToExcel, getUserCompanyInfo} from "../../../global/helpers/actions"
import settings from "../../../global/config/settings"

class ExportVaccinatedListToXlsDialogStore {
	@observable isOpen = false

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@action ExportToXls(form) {
		let filters = []

		filters.push({
			associated_column: "company_id",
			values: [
				{
					id_value: getUserCompanyInfo()._id
				}
			]
		})

		if (isNotEmpty(form.values().exportType)) {
			filters.push({
				associated_column: "exportType",
				values: [
					{
						id_value: form.values().exportType
					}
				]
			})
		}

		if (isNotEmpty(VaccinationStore.vaccinatedFormRef.$("org_unit").value)) {
			filters.push({
				associated_column: "provider_id",
				values: [{id_value: VaccinationStore.vaccinatedFormRef.$("org_unit").value}]
			})
		}

		if (isNotEmpty(VaccinationStore.vaccinatedFormRef.$("doseNumber").value)) {
			filters.push({
				associated_column: "vacc_order",
				values: [{id_value: VaccinationStore.vaccinatedFormRef.$("doseNumber").value}]
			})
		}

		let req = {
			filters: filters,
			uri: api.loadVaccinatedReport().getPath()
		}

		if (isNotEmpty(VaccinationStore.vaccinatedFormRef.$("date_from").value)) {
			req["time_from"] = moment(VaccinationStore.vaccinatedFormRef.$("date_from").value).format(settings.DB_DATETIME_FORMAT)
		}

		if (isNotEmpty(VaccinationStore.vaccinatedFormRef.$("date_to").value)) {
			req["time_to"] = moment(VaccinationStore.vaccinatedFormRef.$("date_to").value).format(settings.DB_DATETIME_FORMAT)
		}

		if (isSafe(VaccinationStore.vaccinatedReportListRef)) {
			req.orders = [
				{
					associated_column: "created_at",
					asc: false
				}
			]
		}

		if (form.values().exportType == "now") {
			if (isSafe(VaccinationStore.vaccinatedReportListRef)) {
				req.row_count_show = VaccinationStore.vaccinatedReportListRef.state.rowsPerPage
				req.row_offset =
					VaccinationStore.vaccinatedReportListRef.state.page *
						VaccinationStore.vaccinatedReportListRef.state.rowsPerPage +
					1
			} else {
				req.row_offset = 1
				req.row_count_show = 10
			}
		}

		exportToExcel(req, () => {
			this.close(), form.reset()
		})
	}
}

var singleton = new ExportVaccinatedListToXlsDialogStore()
export default singleton
