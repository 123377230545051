export default {
	load: {
		storeKey: "OrgunitContact",
		params: ["contactId"],
		type: "Entity.Data.Contact",
		flags: "CD"
	},
	scheme: {
		contact_type: {
			codeList: {_id: "type"}
		},
		email: {
			field: "email"
		},
		mobile: {
			field: "mobile"
		},
		phone: {
			field: "phone"
		},
		web_page: {
			field: "web_page"
		},
		description: {
			field: "description"
		},
		primary: {
			field: "check"
		},
		specific_rel: {
			relation: "@specrel"
		}
		// specific_rel: {
		//   relation: {
		//     _type: "Entity.Data.Client",
		//     _id: "#clientId"
		//   }
		// }
	}
}
