"use strict"

import React, {useState} from "react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
// import GlobalStore from "../../../../../global/store/GlobalStore"
import SicknessPrintDatesStore from "../../../stores/SicknessPrintDatesStore"
// import UIStore from "../../../stores/UIStore"
import moment from "moment"

function PatientRecordDropzoneModal() {
	const fields = {
		date: {
			label: <FormattedMessage id="Patient.labels.period" />,
			rules: "required",
			value: moment().format("YYYYMM")
		}
	}

	const hooks = {
		onSubmit() {},
		onSuccess() {},
		onError() {}
	}

	const [form] = useState(new MobxReactForm({fields: fields}, {plugins: {dvr: validatorjs}, hooks}))

	return (
		<Dialog
			id="xsSicknessPrintDatesModal"
			open={SicknessPrintDatesStore.isOpen}
			onClose={() => {
				form.reset()
				SicknessPrintDatesStore.close()
			}}
			className="xs-base-dialog"
			classes={{
				paper: "xs-paper-dialog xs-width-paper-600"
			}}
			disableBackdropClick={true}
			maxWidth="md"
		>
			<DialogTitle className="xs-info">
				<div className="xs-header">
					<div
						className="xs-absolute xs-close-btn"
						onClick={() => {
							form.reset()
							SicknessPrintDatesStore.close()
						}}
					>
						<i className="fal fa-times fa-lg" />
					</div>
					<div className="xs-header-icon">
						<i className="fal fa-calendar fa-2x" />
					</div>
					<div className="xs-header-title">Zadajte obdobie, ku ktorému chcete tlačiť TDPN (tvar: RRRRMM)</div>
				</div>
			</DialogTitle>
			<DialogContent className="xs-overflow-visible">
				<Grid container direction="column">
					<Grid item xs={12} className="pa-4">
						<XsInput field={form.$("date")} />
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions className="xs-footer">
				<Grid item xs={12} className="xs-action xs-row xs-justify-between">
					<XsButton
						className="xs-danger xs-outline"
						onClick={() => {
							form.reset()
							SicknessPrintDatesStore.close()
						}}
						text={<FormattedMessage id="Common.label.cancel" />}
						icon={<i className="fal fa-times fa-lg" />}
					/>

					<XsButton
						className="xs-success"
						type="submit"
						onClick={() => {
							SicknessPrintDatesStore.checkIfCorrectDate(form)
						}}
						text={<FormattedMessage id="Common.label.print" />}
						icon={<i className="fal fa-plus fa-lg" />}
					/>
				</Grid>
			</DialogActions>
			<XsConfirmationDialog
				name="xsDeleteAttachmentFromDropzoneModal"
				title={<FormattedMessage id="Common.label.warning" />}
				text={<FormattedMessage id="Patient.form.patientrecord.deleteAttachment" />}
				// onConfirmation={() => handleDropzoneDelete(GlobalStore.confirmationDialogParams.fileIndex)}
			/>
		</Dialog>
	)
}

export default injectIntl(observer(PatientRecordDropzoneModal))
