import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import EHGWStore from "../../../stores/EHGWStore"
import UIStore from "../../../stores/UIStore"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import MobxReactForm from "mobx-react-form"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import validatorjs from "validatorjs"

@injectIntl
@observer
export default class EHGWLogs extends React.Component {
	constructor(props) {
		super(props)
		const filterHooks = {
			onSubmit() {},
			onSuccess(form) {
				EHGWStore.loadLogs(form)
			},
			onError() {}
		}

		this.filterForm = new MobxReactForm(
			{
				fields: {
					description: {
						label: <FormattedMessage id="Common.label.description" />
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks: filterHooks, bindings}
		)
		EHGWStore.loadLogs(this.filterForm)
	}

	render() {
		EHGWStore.isChangedFilterLogs(this.filterForm.values())
		return (
			<div className="EHGWLogs">
				<Grid container>
					<Grid item container alignItems="center" spacing={8}>
						<Grid item xs={2}>
							<XsInput white field={this.filterForm.$("description")} />
						</Grid>
						<Grid item xs className="pb-0">
							<XsIconButton
								rect
								className="xs-default xs-outline"
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								onClick={(e) => {
									this.filterForm.onSubmit(e)
								}}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						{UIStore.isFormSaving ? (
							<XsLoading />
						) : EHGWStore.changedFiltersLogs ? (
							<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
								<i className="far fa-search fa-lg mr-3 pointer" onClick={this.filterForm.onSubmit}></i>
								<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
							</div>
						) : (
							<Grid container className="xs-installFiles-grid">
								<Grid item xs={12}>
									<XsTable
										config={{
											columnDefs: {
												logType: {
													title: "logType",
													type: "string",
													design: {
														width: "50%"
													},
													sortable: true
												},
												user: {
													title: "user",
													type: "string",
													design: {
														width: "50%"
													},
													sortable: true
												},
												description: {
													title: "description ",
													type: "string",
													design: {
														width: "50%"
													},
													sortable: true
												}
											},
											options: {
												showCursor: true,
												// selectRow: true,
												// onRowClick: (dataRow) => {
												// 	this.onHandleRowClick(dataRow)
												// },
												mapper: (dataRow) => {
													return {
														logType: dataRow.LogType,
														user: dataRow.User,
														description: dataRow.Description
													}
												}
											},
											dataSource: EHGWStore.logs
										}}
									/>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			</div>
		)
	}
}
