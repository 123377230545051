import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import {TableHead, Table, TableBody, TableCell, TableRow} from "@material-ui/core"

import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"

import PricePerCapitationAdditionalForm from "./pricePerCapitationAdditionalForm"

import AgreementAttributeStore from "../../../stores/AgreementAttributeStore"
import {TextField} from "@material-ui/core"

@observer
export default class PricePerCapitationAdditionalGrid extends React.Component {
	constructor(props) {
		super(props)
	}

	tableBodyRows
	tableBody() {
		this.tableBodyRows = []
		if (
			isSafe(AgreementAttributeStore.attributeDataJSON) &&
			AgreementAttributeStore.attributeDataJSON.hasOwnProperty(AgreementAttributeStore.typeFilter) &&
			Object.keys(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]).length > 0
		) {
			// dataSource.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).forEach((currRow, idx) => {
			Object.keys(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]).forEach(
				(rowId, idx) => {
					if (AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].active) {
						this.tableBodyRows.push(
							<TableRow key={idx}>
								<TableCell className="xs-text-right xs-col-12">
									{AgreementAttributeStore.isEditAttributeGrid ? (
										<TextField
											value={
												AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
													.price_in_currency
											}
											onChange={(e) =>
												AgreementAttributeStore.changedAttribute(
													AgreementAttributeStore.typeFilter,
													rowId,
													"price_in_currency",
													e.target.value
												)
											}
											InputProps={{
												classes: {
													root: !isPrice(
														AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
															.price_in_currency
													)
														? "xs-input-error xs-input"
														: "xs-input"
												}
											}}
										/>
									) : (
										priceFormat(
											AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
												.price_in_currency
										)
									)}
								</TableCell>
								{AgreementAttributeStore.isEditAttributeGrid && (
									<TableCell className="xs-text-center xs-delete-action">
										<XsIconButton
											icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
											onClick={
												() =>
													AgreementAttributeStore.changedAttribute(
														AgreementAttributeStore.typeFilter,
														rowId,
														"active",
														false
													) /*GlobalStore.openConfirmationDialog("xsDeleteAgreementAttribute", { attributeId: AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].id })*/
											}
										/>
									</TableCell>
								)}
							</TableRow>
						)
					}
				}
			)
		} else {
			this.tableBodyRows.push(
				<TableRow key={1}>
					<TableCell className="xs-table-no-data" colSpan={4}>
						<FormattedMessage id="Table.NoData" />
					</TableCell>
				</TableRow>
			)
		}
	}

	render() {
		this.tableBody()

		return (
			<div className="xs-table-ex">
				<div className="xs-table">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell className="xs-text-right">
									<FormattedMessage id="Agreement.Container.Detail.Attribute.Currency" />
								</TableCell>
								{AgreementAttributeStore.isEditAttributeGrid && <TableCell />}
							</TableRow>
						</TableHead>
						<TableBody>{this.tableBodyRows}</TableBody>
					</Table>
				</div>
				<div className="xs-attribute-form">
					<PricePerCapitationAdditionalForm />
				</div>
			</div>
		)
	}
}
