import React from "react"
import {observer} from "mobx-react"
import {/*FormattedMessage, */ injectIntl} from "react-intl"
import {Grid, List, Divider, Drawer, ListItem, ListItemText, ListItemIcon, Tooltip} from "@material-ui/core"
import UIStore from "../../stores/UIStore"
import {withStyles} from "@material-ui/core/styles"
import classnames from "classnames"

import Messaging from "./messaging/messaging"

const drawerWidth = 250

const styles = (theme) => ({
	drawer: {
		height: "100%"
	},
	drawerPaper: {
		position: "relative",
		overflow: "hidden",
		whiteSpace: "nowrap",
		height: "100%",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerPaperClose: {
		overflowX: "hidden",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		width: theme.spacing.unit * 7,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing.unit * 9
		}
	},
	drawerIcon: {
		fontSize: "1.6rem !important",
		width: "15px"
	}
})

@withStyles(styles)
@injectIntl
@observer
export default class SettingContainer extends React.Component {
	constructor(props) {
		super(props)

		if (UIStore.settingDetailTab == "messaging") {
			UIStore.settingName = "Správy" //<FormattedMessage id="Common.label.draftReportList" />
		}

		this.state = {
			open: false
		}
	}

	handleDrawerOpen = () => {
		this.setState({open: true})
	}

	handleDrawerClose = () => {
		this.setState({open: false})
	}

	handleChange = (event, newValue) => {
		UIStore.settingDetailTab = newValue
	}

	drawerData = [
		{
			id: "messaging",
			label: "Správy", //<FormattedMessage id="Common.label.draftReportList" />,
			icon: <i className={classnames("fal fa-file-signature", this.props.classes.drawerIcon)} />
		}
	]

	render() {
		return (
			<Grid container className="maxHeight">
				<Grid item className="maxHeight">
					<Drawer
						variant="permanent"
						className={this.props.classes.drawer}
						classes={{
							paper: classnames(this.props.classes.drawerPaper, !this.state.open && this.props.classes.drawerPaperClose)
						}}
						open={this.state.open}
					>
						<Divider />
						<List>
							<ListItem button onClick={() => (this.state.open ? this.handleDrawerClose() : this.handleDrawerOpen())}>
								{this.state.open ? (
									<ListItemText className="textRight">
										<i className={classnames("fal fa-chevron-left", this.props.classes.drawerIcon)} />
									</ListItemText>
								) : (
									<ListItemText className="textRight">
										<i className={classnames("fal fa-chevron-right", this.props.classes.drawerIcon)} />
									</ListItemText>
								)}
							</ListItem>
						</List>
						<Divider />
						<List className="overflowY overflowXHidden">
							{this.drawerData.map((data, key) => {
								return (
									<ListItem
										key={key}
										button
										onClick={() => {
											UIStore.settingDetailTab = data.id
											UIStore.settingName = data.label
										}}
										className={classnames({bgGreyLight: UIStore.settingDetailTab == data.id})}
									>
										<Tooltip title={data.label} placement="right">
											<ListItemIcon>{data.icon}</ListItemIcon>
										</Tooltip>
										<ListItemText>{data.label}</ListItemText>
									</ListItem>
								)
							})}
						</List>
					</Drawer>
				</Grid>
				<Grid item className="xs-flex pt-4 pb-4 pr-8 pl-8 maxHeight overflowY">
					<Grid container>
						<h2 className="pb-6">{UIStore.settingName}</h2>
						{UIStore.settingDetailTab === "messaging" && <Messaging />}
					</Grid>
				</Grid>
			</Grid>
		)
	}
}
