import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"
import settings from "../../../../../../global/config/settings"
import {getUserCompanyInfo, getSelectedOrgUnit} from "../../../../../../global/helpers/actions"

export default {
	load(invoiceID) {
		let retFields = {
			print: {
				value: false,
				rules: "boolean"
			},
			_id: {},
			customer: {
				label: <FormattedMessage id="Invoice.form.customer" />
			},
			type: {
				label: <FormattedMessage id="Patient.form.patientrecord.diagnosistype" />,
				rules: "required",
				value: "invoice"
			},
			currency_unit: {
				label: <FormattedMessage id="Invoice.form.currency" />,
				value: "EUR",
				rules: "required"
			},
			invoice_number: {
				label: <FormattedMessage id="Invoice.form.number" />
			},
			total_cost: {
				rules: "required",
				type: "numeric",
				value: 0
			},
			issue_date: {
				label: <FormattedMessage id="Invoice.form.issuedate" />,
				rules: "required",
				value: moment()
			},
			due_date: {
				label: <FormattedMessage id="Invoice.form.duedate" />,
				rules: "required",
				value: moment().add(settings.INVOICE_SETTINGS.due_date_days, "days")
			},
			delivery_date: {
				label: <FormattedMessage id="Invoice.form.deliverydate" />
			},
			variable_symbol: {
				label: <FormattedMessage id="Invoice.form.variablesymbol" />,
				rules: "required"
			},
			constant_symbol: {
				label: <FormattedMessage id="Invoice.form.constantsymbol" />,
				value: settings.INVOICE_SETTINGS.default_constant_symbol
			},
			specific_symbol: {
				label: <FormattedMessage id="Invoice.form.specificsymbol" />
			},
			description: {
				label: <FormattedMessage id="Common.label.description" />
			},
			period: {
				label: <FormattedMessage id="Patient.labels.period" />
			},
			customer_dataName: {
				label: <FormattedMessage id="Invoice.form.customer_data.name" />,
				rules: "required"
			},
			customer_dataIdentifier: {
				label: <FormattedMessage id="Invoice.form.customer_data.identifier" />,
				rules: "required"
			},
			customer_dataTax_identification_number: {
				label: <FormattedMessage id="Invoice.form.customer_data.tax_identification_number" />
			},
			customer_dataVat_identification_number: {
				label: <FormattedMessage id="Invoice.form.customer_data.vat_identification_number" />
			},
			customer_addressStreet_plain: {
				label: <FormattedMessage id="Common.label.street" />
			},
			customer_addressHouse_number: {
				label: <FormattedMessage id="Common.label.number" />
			},
			customer_addressZip_plain: {
				label: <FormattedMessage id="Common.label.zip" />,
				rules: "required"
			},
			customer_addressCity_plain: {
				label: <FormattedMessage id="Common.label.city" />,
				rules: "required"
			},
			customer_dataBank_account_plain: {
				label: <FormattedMessage id="Invoice.form.bankAccount" />
			},
			customer_dataCompany_description: {
				label: <FormattedMessage id="Common.label.description" />
			},
			supplier_dataName: {
				label: <FormattedMessage id="Invoice.form.customer_data.name" />,
				rules: "required"
			},
			supplier_dataIdentifier: {
				label: <FormattedMessage id="Invoice.form.customer_data.identifier" />,
				rules: "required"
			},
			supplier_dataTax_identification_number: {
				label: <FormattedMessage id="Invoice.form.customer_data.tax_identification_number" />
			},
			supplier_dataVat_identification_number: {
				label: <FormattedMessage id="Invoice.form.customer_data.vat_identification_number" />
			},
			supplier_addressStreet_plain: {
				label: <FormattedMessage id="Common.label.street" />
			},
			supplier_addressHouse_number: {
				label: <FormattedMessage id="Common.label.number" />
			},
			supplier_addressZip_plain: {
				label: <FormattedMessage id="Common.label.zip" />
			},
			supplier_addressCity_plain: {
				label: <FormattedMessage id="Common.label.city" />
			},
			supplier_dataBank_account_plain: {
				label: <FormattedMessage id="Invoice.form.bankAccount" />,
				rules: "required"
			},
			supplier_dataCompany_description: {
				label: <FormattedMessage id="Common.label.description" />
			},
			endCustomer_dataName: {
				label: <FormattedMessage id="Invoice.form.customer_data.name" />
			},
			endCustomer_addressStreet_plain: {
				label: <FormattedMessage id="Common.label.street" />
			},
			endCustomer_addressHouse_number: {
				label: <FormattedMessage id="Common.label.number" />
			},
			endCustomer_addressZip_plain: {
				label: <FormattedMessage id="Common.label.zip" />
			},
			endCustomer_addressCity_plain: {
				label: <FormattedMessage id="Common.label.city" />
			},
			enslaved_invoices: {},
			attachmentIds: {}
		}
		//Predplníme suppliera (Dodávateľa) ak ide o novú faktúru
		if (!invoiceID) {
			const companyInfo = getUserCompanyInfo()
			const selectedOrgUnit = getSelectedOrgUnit()

			//Údaje o spoločnosti
			if (companyInfo) {
				retFields.supplier_dataName["value"] = companyInfo.name
				retFields.supplier_dataBank_account_plain["value"] = companyInfo.bank_account_plain
				retFields.supplier_dataCompany_description["value"] = companyInfo.company_description
				retFields.supplier_dataIdentifier["value"] = companyInfo.identifier
				retFields.supplier_dataTax_identification_number["value"] = companyInfo.tax_identification_number
				retFields.supplier_dataVat_identification_number["value"] = companyInfo.vat_identification_number
			}

			//Údaje o zvolenej org unit (adresa, bankový účet a popis spoločnosti)
			if (selectedOrgUnit) {
				if (selectedOrgUnit.addresses) {
					const primarySupAddress = selectedOrgUnit.addresses.find((a) => a.primary)
					if (primarySupAddress) {
						retFields.supplier_addressStreet_plain["value"] = primarySupAddress.street
						retFields.supplier_addressHouse_number["value"] = primarySupAddress.house_number
						retFields.supplier_addressZip_plain["value"] = primarySupAddress.zip
						retFields.supplier_addressCity_plain["value"] = primarySupAddress.city
					}
				}
				if (selectedOrgUnit.accounts) {
					const bankAccount = selectedOrgUnit.accounts.find((a) => a.active && a.primary)
					if (bankAccount) {
						retFields.supplier_dataBank_account_plain["value"] = bankAccount.number
					}
				}
				if (selectedOrgUnit.entity) {
					retFields.supplier_dataCompany_description["value"] = selectedOrgUnit.entity.description
				}
			}
		}
		// if (!invoiceID) {
		// 	retFields.period.value = moment()
		// 		.subtract(1, "month")
		// 		.format("YYYY/MM")
		// }
		return {
			fields: retFields
		}
	}
}
