export default {
	load: {
		storeKey: "employeeInfoSaveForm",
		params: ["employeeId"],
		type: "Entity.Data.Person"
	},
	scheme: {
		_id: {
			relation: "@entityId"
		},
		title_before: {
			object: {
				descriptor: {
					_ref: true,
					_type: "Entity.CL.Title"
				},
				scheme: {
					_id: {
						field: "title_before"
					}
				}
			}
		},
		first_name: {
			field: "firstname"
		},
		last_name: {
			field: "lastname"
		},
		title_after: {
			object: {
				descriptor: {
					_ref: true,
					_type: "Entity.CL.Title"
				},
				scheme: {
					_id: {
						field: "title_after"
					}
				}
			}
		},
		birth_name: {
			field: "birthname"
		},
		identifier: {
			field: "identifier"
		},
		birth_date: {
			field: "dateOfBirth"
		},
		gender: {
			object: {
				descriptor: {
					_ref: true,
					_type: "Entity.CL.Gender"
				},
				scheme: {
					_id: {
						field: "gender"
					}
				}
			}
		},
		specific_rels: {
			array: {
				load: (item) => item._type === "Entity.Data.Employee",
				descriptor: {
					_type: "Entity.Data.Employee",
					_ref: false,
					active: true
				},
				scheme: {
					personal_id: {
						field: "personalNumber"
					},
					validity: {
						object: {
							descriptor: {
								_ref: false
							},
							scheme: {
								from: {
									field: "arrivalDate"
								},
								to: {
									field: "departureDate"
								}
							}
						}
					},
					category: {
						object: {
							descriptor: {
								_type: "Entity.CL.EmployeeCategory",
								_ref: true
							},
							scheme: {
								_id: {
									field: "employeeCategory"
								}
							}
						}
					},
					identifiers: {
						array: {
							load: (item) => item._type === "Entity.Data.Employee" && item.type === "CARD",
							descriptor: {
								_type: "IXS.Data.Object.Identifier",
								type: "CARD",
								system: "IXS",
								_ref: false,
								active: true
							},
							scheme: {
								value: {
									field: "cardNumber"
								}
							}
						}
					},
					company: {
						relation: "@companyId"
					}
				}
			}
		}
	}
}
