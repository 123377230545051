"use strict"

import {observable, action} from "mobx"
import moment from "moment"
import renderHTML from "react-render-html"

import {getSelectedOrgUnitID, getUserPersonnelID} from "../../../global/helpers/actions"

import DataStore from "../stores/DataStore"
import GlobalStore from "../../../global/store/GlobalStore"
import PatientDeliveryDialogStore from "./PatientDeliveryDialogStore"
import OtherDeliveryStore from "./OtherDeliveriesStore"
import SocInsDeliveryStore from "./SocInsDeliveryStore"
import DeliveryStore from "./DeliveryStore"
import UIStore from "../stores/UIStore"

import api from "../actions/api"

class AddDeliveryStore {
	@observable isOpen = false

	reloadList = ""
	formRef = null
	formPatientDetailRef = null

	@action open(reloadList, formVal) {
		this.isOpen = true

		if (isSafe(formVal)) {
			if (reloadList == "patientDelivery") {
				this.formPatientDetailRef.$("payer_type").value = formVal.payerType
				this.formPatientDetailRef.$("date").value = formVal.date
				this.formPatientDetailRef.$("diagnosis").value = formVal.diagnosis
			} else {
				this.formRef.$("payer_type").value = formVal.payerType
				this.formRef.$("date").value = formVal.date
				this.formRef.$("diagnosis").value = formVal.diagnosis
				this.formRef.$("patientId").set("value", formVal.patientId)
			}
		}

		this.reloadList = reloadList
	}

	@action saveDelivery(form) {
		const formValues = form.values()

		const patientId =
			this.reloadList == "patientDelivery" ? DataStore.patientDTO.get("patientID") : formValues.patientId

		let reqOverview = {
			filters: [
				{
					associated_column: "patient_id",
					values: [
						{
							id_value: patientId
						}
					]
				},
				{
					associated_column: "provider_id",
					values: [
						{
							id_value: getSelectedOrgUnitID()
						}
					]
				},
				{
					associated_column: "type_id",
					values: [
						{
							id_value: "EHR.Data.DlvRecord"
						}
					]
				}
			],
			time_from: moment().startOf("month"),
			time_to: moment().endOf("month")
		}

		api
			.loadEHROverview(reqOverview)
			.call()
			.then((rec) => {
				if (isSafe(rec) && isSafe(rec.rows) && rec.rows.length > 0) {
					let request = {
						_ref: false,
						_type: "EHR.Data.Record.SK.Delivery",
						item: {
							_id: formValues.delivery,
							_type: "EHR.CL.Bill.SK.Delivery"
						},
						diagnosis: {
							_id: formValues.diagnosis,
							_type: "EHR.CL.Record.Diagnosis"
						},
						count: formValues.number,
						type: "dlv",
						payer_type: isNotEmpty(formValues.payer_type) ? formValues.payer_type : null,
						insuree_type: isNotEmpty(formValues.insuree_type) ? formValues.insuree_type : null,
						refund_type: isNotEmpty(formValues.refund_type) ? formValues.refund_type : null,
						delivery_extension: isNotEmpty(formValues.delivery_extension) ? formValues.delivery_extension : null,
						movement: formValues.movement,
						delivery_date_time: formValues.date,
						sender_type: isNotEmpty(formValues.sender_type) ? formValues.sender_type : null
					}

					api
						.saveDeliveriesAdd(request, rec.rows[0].record_id)
						.call()
						.then((res) => {
							if (isSafe(res) && isNotEmpty(res._id)) {
								GlobalStore.setNotificationMessage("Common.label.saveDelivery")
								switch (this.reloadList) {
									case "patientDelivery":
										PatientDeliveryDialogStore.loadPatientDelivery()
										break
									case "otherDelivery":
										OtherDeliveryStore.getFilters()
										break
									case "delivery":
										DeliveryStore.getFilters()
										break
									case "socDelivery":
										SocInsDeliveryStore.getFilters()
										break
								}
								form.reset()
								this.close()
							}
							UIStore.isFormSaving = false
						})
				} else {
					let request = {
						_ref: false,
						_type: "EHR.Data.DlvRecord",
						version: {
							_type: "EHR.CL.Record.Version",
							_id: "final"
						},
						specific_rels: [
							{
								_ref: false,
								_type: "EHR.Data.Record.SpecificRel",
								specific_rel: {
									_id: patientId,
									_type: "Entity.Data.Client"
								},
								type: "pat"
							},
							{
								_ref: false,
								_type: "EHR.Data.Record.SpecificRel",
								specific_rel: {
									_id: getUserPersonnelID(),
									_type: "EHR.Data.Entity.Personnel"
								},
								type: "phy"
							},
							{
								_ref: false,
								_type: "EHR.Data.Record.SpecificRel",
								specific_rel: {
									_id: getSelectedOrgUnitID(),
									_type: "Entity.Data.OrgUnit"
								},
								type: "ou"
							}
						],
						recorded_events: [
							{
								_ref: false,
								_type: "EHR.Data.Record.Event",
								active: true,
								recorded_at: moment(),
								type: {
									_id: "WRT",
									_type: "EHR.CL.Record.EventType"
								}
							}
						],
						general_items: [
							{
								_ref: false,
								_type: "EHR.Data.Record.SK.Delivery",
								item: {
									_id: formValues.delivery,
									_type: "EHR.CL.Bill.SK.Delivery"
								},
								diagnosis: {
									_id: formValues.diagnosis,
									_type: "EHR.CL.Record.Diagnosis"
								},
								count: formValues.number,
								type: "dlv",
								payer_type: isNotEmpty(formValues.payer_type) ? formValues.payer_type : null,
								insuree_type: isNotEmpty(formValues.insuree_type) ? formValues.insuree_type : null,
								refund_type: isNotEmpty(formValues.refund_type) ? formValues.refund_type : null,
								delivery_extension: isNotEmpty(formValues.delivery_extension) ? formValues.delivery_extension : null,
								movement: formValues.movement,
								delivery_date_time: formValues.date,
								sender_type: isNotEmpty(formValues.sender_type) ? formValues.sender_type : null
							}
						]
					}

					api
						.saveRecord(request, patientId)
						.call()
						.then((res) => {
							if (isSafe(res) && isNotEmpty(res._id)) {
								GlobalStore.setNotificationMessage("Common.label.saveDelivery")
								switch (this.reloadList) {
									case "patientDelivery":
										PatientDeliveryDialogStore.loadPatientDelivery()
										break
									case "otherDelivery":
										OtherDeliveryStore.getFilters()
										break
									case "delivery":
										DeliveryStore.getFilters()
										break
									case "socDelivery":
										SocInsDeliveryStore.getFilters()
										break
								}
								form.reset()
								this.close()
							}
							UIStore.isFormSaving = false
						})
				}
			})
	}

	@action checkDeliverysForConstraints(form) {
		const values = form.values()

		const patientId = this.reloadList == "patientDelivery" ? DataStore.patientDTO.get("patientID") : values.patientId
		const insurerId = this.reloadList == "patientDelivery" ? DataStore.patientDTO.get("insurerId") : values.insurer_id

		let req = {
			patient_id: patientId,
			insurer_id: insurerId,
			provider_id: getSelectedOrgUnitID(),
			deliveries: [
				{
					item_id: values.delivery,
					delivery_date_time: moment(values.date).endOf("day")
				}
			]
		}

		api
			.getDeliveryConflicts(req)
			.call()
			.then((response) => {
				if (response.entries.length) {
					let periodicityError = ""
					let combinationError = ""
					response.entries.forEach((entry) => {
						if (isSafe(entry.constraints) && Array.isArray(entry.constraints) && entry.constraints.length > 0) {
							entry.constraints.forEach((constrain) => {
								if (constrain._type == "EHR.CL.Bill.SK.DeliveryPeriod") {
									let periodicityObj = GlobalStore.CL.medicationRepeats.find(
										(per) => per._id == constrain.allowed_period_unit._id
									)
									periodicityError += `${entry.item.code_ext}-${entry.item.name_ext} ${
										constrain.allowed_amount_in_period
									} za ${constrain.allowed_period_amount} ${periodicityObj.name_ext.toLowerCase()} <br/>`
								} else if (constrain._type == "EHR.CL.Bill.SK.DeliveryNoCombination") {
									if (isSafe(constrain.no_item)) {
										combinationError += `${constrain.no_item.code_ext}-${constrain.no_item.name_ext} <br/>`
									}
								}
							})
						}
					})
					if (isNotEmpty(periodicityError) || isNotEmpty(combinationError)) {
						let text = ""
						if (isNotEmpty(periodicityError)) {
							text += "<b>Obmedzená periodicita vykázania:</b> <br />" + periodicityError
						}
						if (isNotEmpty(combinationError)) {
							text += "<b>Zakázaná kombinácia:</b> <br />" + combinationError
						}
						GlobalStore.openConfirmationDialog("xsDeliveryConflictsAddDeliveryDialog", {
							dialogText: renderHTML(text),
							confirmAction: () => this.saveDelivery(form)
						})
					} else {
						this.saveDelivery(form)
					}
				} else {
					this.saveDelivery(form)
				}
			})
	}

	@action close() {
		this.isOpen = false

		this.reloadList = ""
	}
}

var singleton = new AddDeliveryStore()
export default singleton
