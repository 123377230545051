import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import XsButton from "../../../../../../../global/ui/xsButton/xsButton"
import SearchSelect from "../../../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsDateTimePicker from "../../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsTable from "../../../../../../../global/ui/xsTable/xsTable"
import XsIconButton from "../../../../../../../global/ui/xsButton/xsIconButton"
import XsConfirmationDialog from "../../../../../../../global/ui/xsDialog/xsConfirmationDialog"

import Grid from "@material-ui/core/Grid"

import fields from "./expertiseFormContractsFields"
import {FormattedMessage, injectIntl} from "react-intl"
import EmployeesDetailStore from "../../../../../stores/EmployeesDetailStore"
import GlobalStore from "../../../../../../../global/store/GlobalStore"

@injectIntl
@observer
export default class ExpertiseFormContrats extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				EmployeesDetailStore.saveExpertiseContractsForm(
					form,
					props.personnelId,
					props.expertiseIdField.get("value"),
					props.personnelExperiseId.get("value")
				)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks})
	}

	handleTableRowClick = (dataRow) => {
		this.form.$("personnelExpertiseContract").set(dataRow.contractId)
		this.form.$("contract").set(dataRow.contractOrg_unitId)
		this.form.$("from").set(dataRow.validFrom)
		this.form.$("to").set(dataRow.validTo)
		EmployeesDetailStore.addEmpExpertiseContract = true
	}

	render() {
		return (
			<Grid container direction="column">
				<Grid item className="pt-6 pr-6 pl-6">
					<h4 className="xs-bold">
						<FormattedMessage id="Common.label.contracts" />
					</h4>
				</Grid>
				<Grid item className="pr-6 pl-6 pb-6">
					<XsTable
						config={{
							columnDefs: {
								name_ext: {
									title: "Názov",
									design: {
										width: "100%"
									},
									sortable: true
								},
								delete: {
									title: "",
									type: "action",
									design: {
										body: {
											renderer: (props) => {
												return (
													<XsIconButton
														className="action-delete"
														icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
														onClick={() =>
															GlobalStore.openConfirmationDialog("xsDeleteEmpExpertiseContract", {
																expertiseContractId: props.value
															})
														}
													/>
												)
											}
										}
									}
								}
							},
							options: {
								hidePager: true,
								showCursor: true,
								// selectRow: true,
								onRowClick: (dataRow) => {
									this.handleTableRowClick(dataRow)
								},
								mapper: (dataRow) => {
									return {
										name_ext: dataRow.employment_contract.org_unit.name_ext,
										contractOrg_unitId: isSafe(
											dataRow.personnel_expertise_unique && isSafe(dataRow.personnel_expertise_unique.split("@"))
										)
											? dataRow.personnel_expertise_unique.split("@")[1]
											: "",
										contractId: dataRow._id,
										personnel_expertiseId: dataRow.personnel_expertise,
										validFrom: dataRow.validity.from,
										validTo: dataRow.validity.to,
										delete: dataRow._id
									}
								}
							},
							dataSource: EmployeesDetailStore.empExpertises
						}}
					/>
				</Grid>
				<Grid item className="fomBorderTop pt-4 pb-2 pl-6 pr-6">
					{EmployeesDetailStore.addEmpExpertiseContract ? (
						<form className="xs-expertiseContract-form">
							<Grid container direction="row" spacing={8}>
								<Grid item xs={3}>
									<SearchSelect
										field={this.form.$("contract")}
										items={EmployeesDetailStore.employeeContratsEdited}
										white={true}
										required
									/>
								</Grid>
								<Grid item xs={1}>
									<XsDateTimePicker field={this.form.$("from")} />
								</Grid>
								<Grid item xs={1}>
									<XsDateTimePicker field={this.form.$("to")} />
								</Grid>
								<Grid item container justify="flex-end" alignItems="center" xs={7} spacing={8} className="mt-0">
									{isNotEmpty(this.form.$("personnelExpertiseContract").get("value")) ? (
										<Grid item>
											<XsButton
												className="xs-success"
												type="submit"
												onClick={this.form.onSubmit}
												text={<FormattedMessage id="Common.label.save" />}
												icon={<i className="fal fa-check fa-lg" />}
											/>
										</Grid>
									) : (
										<Grid item>
											<XsButton
												className="xs-success"
												type="submit"
												onClick={this.form.onSubmit}
												text={<FormattedMessage id="Common.label.add" />}
												icon={<i className="fal fa-check fa-lg" />}
											/>
										</Grid>
									)}
									<Grid item>
										<XsButton
											className="xs-default xs-outline"
											onClick={() => {
												// this.initForm()
												this.form.reset()
												EmployeesDetailStore.addEmpExpertiseContract = false
											}}
											text={<FormattedMessage id="Common.label.cancel" />}
											icon={<i className="fal fa-times fa-lg"></i>}
										/>
									</Grid>
								</Grid>
							</Grid>
						</form>
					) : (
						<Grid item className="pt-2 pb-2">
							<XsButton
								className="xs-default xs-outline"
								icon={<i className="fal fa-plus fa-lg" />}
								text={<FormattedMessage id="Common.button.addAnother" />}
								onClick={() => {
									EmployeesDetailStore.addEmpExpertiseContract = true
								}}
							/>
						</Grid>
					)}
				</Grid>

				<XsConfirmationDialog
					type="danger"
					name="xsDeleteEmpExpertiseContract"
					text={<FormattedMessage id="Employee.form.medicalWorkerExpForm.Deleteexpertise" />}
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.btn.expertise.delete"
					}}
					onConfirmation={() =>
						EmployeesDetailStore.deleteExpertiseContract(
							GlobalStore.confirmationDialogParams.expertiseContractId,
							this.props.expertiseIdField.get("value"),
							this.props.personnelId
						)
					}
				/>
			</Grid>
		)
	}
}
