"use strict"

import React from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import Tooltip from "@material-ui/core/Tooltip"
import {observer} from "mobx-react"
import {Grid} from "@material-ui/core"
import DataStore from "../../../stores/DataStore"
import UIStore from "../../../stores/UIStore"
import WarningStore from "../../../../../global/store/WarningStore"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import MedicineCardDialogStore from "../../../stores/MedicineCardDialogStore"
import OverPrescriptionDialogStore from "../../../stores/OverPrescriptionDialogStore"
import OverPrescriptionDialog from "../patientPrescriptionForm/overPrescriptionDialog"
import HealthCardDialog from "./healthCardDialog"
import SmSNotificationStore from "../../../stores/SmsNotificationStore"
import SmsNotificationDialog from "../cardFromDovera/smsNotificationDialog"
import WriteSpaRequestDialogStore from "../../../stores/WriteSpaRequestDialogStore"
import WriteSpaRequestDialog from "./writeSpaRequestDialog"
import RequestsSVLZInsurerStore from "../../../stores/RequestsSVLZInsurerStore"
import {getSelectedOrgUnitID, getSelectedOrgUnit, getUserAccessRights} from "../../../../../global/helpers/actions"
import api from "../../../actions/api"
import DoveraIcon from "../../../../../global/ui/icons/DoveraIcon"
import GlobalStore from "../../../../../global/store/GlobalStore"

@injectIntl
@observer
export default class PatientInsuranceServices extends React.Component {
	constructor(props) {
		super(props)
	}

	onCardFromInsuranceHandleClick = () => {
		let patient = undefined
		if (
			isSafe(DataStore.patientDTO.get("patient")) &&
			isSafe(DataStore.patientDTO.get("patient").validInfo) &&
			isSafe(DataStore.patientDTO.get("patient").validInfo.client)
		) {
			patient = DataStore.patientDTO.get("patient").validInfo.client

			let request = {
				patient_id: patient._id,
				provider_id: getSelectedOrgUnitID()
			}

			UIStore.isFormSaving = true

			api
				.dajKodPreZdravotnuKartu(request)
				.call()
				.then((response) => {
					if (isSafe(response) && response.no_sms_code_needed) {
						const request = {
							patient_id: patient._id,
							provider_id: getSelectedOrgUnitID(),
							sms_kod: ""
						}

						api
							.dajzdravotnukartu(request)
							.call()
							.then((response) => {
								if (isSafe(response) && isSafe(response.html)) {
									UIStore.patientDetailTab = "InsuranceServices"
									SmSNotificationStore.openHealthCard(response.html)
									UIStore.isFormSaving = false
								} else {
									SmSNotificationStore.htmlContent = undefined
									UIStore.isFormSaving = false
								}
							})
							.catch(() => {
								SmSNotificationStore.htmlContent = undefined
								UIStore.isFormSaving = false
							})
					} else {
						if (isSafe(response) && isSafe(response.odoslana) && response.odoslana) {
							SmSNotificationStore.open(patient._id)
						} else {
							if (isSafe(response) && isSafe(response.warning) && isNotEmpty(response.warning.text)) {
								WarningStore.open(response.warning.text)
							}
						}
						UIStore.isFormSaving = false
					}
				})
		}
	}

	onLoadEPZP() {
		const selectedOrgUnit = getSelectedOrgUnit()
		UIStore.isFormSaving = true

		const request = {
			provider_id: getSelectedOrgUnitID(),
			doctor_expertise_id: selectedOrgUnit.personnel_expertise_id
		}

		api
			.loadEp(request)
			.call()
			.then((data) => {
				UIStore.isFormSaving = false
				window.open(data.url, "_blank")
			})
			.catch(() => {
				UIStore.isFormSaving = false
				WarningStore.open(this.props.intl.formatMessage({id: "Api.unknown.error"}), true)
			})
	}

	onLoadSpaRequest() {
		if (
			isSafe(DataStore.patientDTO.get("patient")) &&
			isSafe(DataStore.patientDTO.get("patient").validInfo) &&
			isSafe(DataStore.patientDTO.get("patient").validInfo.client)
		) {
			const patient = DataStore.patientDTO.get("patient").validInfo.client

			const request = {
				patient_id: patient._id,
				provider_id: getSelectedOrgUnitID()
			}

			UIStore.isFormSaving = true

			api
				.loadSpaRequests(request)
				.call()
				.then((data) => {
					UIStore.isFormSaving = false
					window.open(data.url, "_blank")
				})
				.catch(() => {
					UIStore.isFormSaving = false
					GlobalStore.setNotificationMessage("Common.label.loadSpaRequestsError")
				})
		} else {
			GlobalStore.setNotificationMessage("Common.label.loadSpaRequestsError")
		}
	}

	render() {
		// let insurerCodeExt = undefined
		let patient = undefined
		const provider_id = getSelectedOrgUnitID()
		const selectedOrgUnit = getSelectedOrgUnit()
		const personnel_expertise_id = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_id : null
		if (DataStore.patientDTO.get("patient")) {
			// let pacInfo = DataStore.patientDTO.get("patient")

			// if (isSafe(pacInfo.specific_rels)) {
			// 	const client = pacInfo.specific_rels.find((x) => x.active && x._type === "Entity.Data.Client")
			// if (isSafe(client) && isSafe(client.insurances)) {
			// const insurance = client.insurances.find((x) => x.active && x.validity.now)
			// if (isSafe(insurance) && isSafe(insurance.insurer) && insurance.insurer.active) {
			// 	insurerCodeExt = `${insurance.insurer.code_ext}`
			// } else {
			// 	insurerCodeExt = undefined
			// }
			// }
			// }

			if (
				isSafe(DataStore.patientDTO.get("patient").validInfo) &&
				isSafe(DataStore.patientDTO.get("patient").validInfo.client)
			) {
				patient = DataStore.patientDTO.get("patient").validInfo.client
			}
		}

		return (
			<React.Fragment>
				<Grid container direction="column" spacing={16}>
					<Grid item container spacing={8}>
						<Grid item>
							<XsButton
								className="xs-default xs-outline"
								text={<FormattedMessage id="Patient.medicalCard.dialog.mediactionCard" />}
								onClick={() => MedicineCardDialogStore.drugBook()}
							/>
						</Grid>
						<Grid item>
							<XsButton
								className="xs-default xs-outline"
								text={<FormattedMessage id="Patient.medicalCard.dialog.interactionCard" />}
								onClick={() => MedicineCardDialogStore.pacientInteractions()}
							/>
						</Grid>
						<Grid item>
							<XsButton
								className="xs-default xs-outline"
								text={<FormattedMessage id="Calendar.prescription.overPrescription" />}
								onClick={() => OverPrescriptionDialogStore.open()}
							/>
						</Grid>
					</Grid>
					{/* {isSafe(insurerCodeExt) && insurerCodeExt.toLowerCase() == 24 && ( */}
					{DataStore.patientDTO.get("insurerCodeExt") == "24" && (
						<React.Fragment>
							<Grid item container spacing={8}>
								<Grid item>
									<XsButton
										className="xs-default xs-outline"
										icon={<DoveraIcon />}
										text={<FormattedMessage id="PatientDetail.cards.patientInsuranceCard" />}
										onClick={() => this.onCardFromInsuranceHandleClick()}
									/>
								</Grid>
								<Grid item>
									<XsButton
										className="xs-default xs-outline"
										icon={<DoveraIcon />}
										text={<FormattedMessage id="Common.label.writeSpaRequest" />}
										onClick={() => WriteSpaRequestDialogStore.open(patient._id, provider_id, personnel_expertise_id)}
									/>
								</Grid>
								<Grid item>
									<XsButton
										className="xs-default xs-outline"
										icon={<DoveraIcon />}
										text={<FormattedMessage id="Common.label.loadSpaRequests" />}
										onClick={() => this.onLoadSpaRequest()}
									/>
								</Grid>
							</Grid>
							<Grid item container spacing={8}>
								<Grid item>
									<XsButton
										className="xs-default xs-outline"
										text={<FormattedMessage id="Common.label.eLabDZP" />}
										icon={<DoveraIcon />}
										// onClick={() => RequestsSVLZInsurerStore.open()}
										onClick={() => RequestsSVLZInsurerStore.dajZiadankySVLZPoistenca(this.props.patientId)}
									/>
								</Grid>

								<Grid item>
									{isSafe(getUserAccessRights("/amb/ehealth/ep", "RESTP")) && (
										<Tooltip title={<FormattedMessage id="Common.label.epzpTooltip" />} enterDelay={500}>
											<span>
												<XsButton
													className="xs-default xs-outline"
													icon={<DoveraIcon />}
													text={<FormattedMessage id="Common.label.epzp" />}
													onClick={() => this.onLoadEPZP()}
												/>
											</span>
										</Tooltip>
									)}
								</Grid>
							</Grid>
						</React.Fragment>
					)}
				</Grid>
				<SmsNotificationDialog />
				<OverPrescriptionDialog />
				<WriteSpaRequestDialog />
				<HealthCardDialog />
				{/* <RequestsSVLZInsurerDialog patientId={patient._id} />
				<RequestsSVLZInsurerViewerDialog patientId={patient._id} />
				<AcceptOfTheTermDialog patientId={patient._id} />
				<RequestsSVLZDialog />
				<ShowPDFDialog /> */}
			</React.Fragment>
		)
	}
}
