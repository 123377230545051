"use strict"

import {observable, action} from "mobx"

import {getSelectedOrgUnitID, getSelectedOrgUnit} from "../../../global/helpers/actions"

import DataStore from "./DataStore"
import UIStore from "./UIStore"

import api from "../actions/api"

class MovePatientsToAnotherOrgunitStore {
	@observable isOpen = false

	@action open() {
		this.isOpen = true
	}

	@action close(form) {
		form.reset()
		this.isOpen = false
		DataStore.patientChecked = []
	}

	@action movePatients(form) {
		const formValues = form.values()
		UIStore.isFormSaving = true

		let req = {
			remove_from_portfolio: true,
			source_personnel_expertise_id: getSelectedOrgUnit().personnel_expertise_id,
			target_org_unit_id: formValues.orgunitId,
			target_personnel_expertise_id: formValues.personnelExpertiseId,
			include_capitation: formValues.moveCapitation,
			source_patients: DataStore.patientChecked.map((id) => {
				return {_id: id, _type: "Entity.Data.Client"}
			})
		}

		api
			.patientsTransfer(req, getSelectedOrgUnitID())
			.call()
			.then((res) => {
				if (isSafe(res)) {
					this.close(form)
					DataStore.patientChecked = []
					DataStore.patientListTableRef.state.checkboxValues = []
					DataStore.patientListTableRef.refreshData()
				}
				UIStore.isFormSaving = false
			})
	}
}

var singleton = new MovePatientsToAnotherOrgunitStore()
export default singleton
