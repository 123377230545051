import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {FormattedMessage, injectIntl} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import TextField from "@material-ui/core/TextField"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import fields from "./eHealthSearchDialogFormFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import EHealthSearchDialogStore from "../../../stores/EHealthSearchDialogStore"
import {Grid, FormControlLabel, Checkbox, RadioGroup, Radio} from "@material-ui/core"
import {XsDateTimePicker} from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import SearchDropdownNoForm from "../../../../../global/ui/xsSearchdropdown/xsSearchdropdownNoForm"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import api from "../../../actions/api"

@injectIntl
@observer
export default class EHealthSearchDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
    }
    
    EHealthSearchDialogStore.warningText = this.props.intl.formatMessage({id: "Common.label.infoNoCriteria"})

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	handleSearchClick() {
		Object.entries(this.form.values()).forEach((obj) => {
			EHealthSearchDialogStore.handleChange(obj[0], obj[1])
		})

		EHealthSearchDialogStore.search()
		this.form.reset()
	}

	render() {
		const examinationType = [
			"ZAZNAM_O_ODBORNOM_VYSETRENI",
			"ZAZNAM_O_ZOBRAZOVACOM_VYSETRENI",
			"ZAZNAM_PREPUSTACIA_SPRAVA"
		]

		const checkedTypes =
			isSafe(EHealthSearchDialogStore.examinationTypes) && EHealthSearchDialogStore.examinationTypes.length > 0
				? EHealthSearchDialogStore.examinationTypes.map((x) => x)
				: null

		const examinationTypeValues = examinationType.map((et, idx) => {
			return {
				id: idx,
				search_string: et,
				// primaryText: et,
				secondaryText: et,
				checkValue: isSafe(checkedTypes) && checkedTypes.includes(et)
			}
		})

		return (
			<Dialog
				id="xsEHealthSearchDialog"
				open={EHealthSearchDialogStore.isOpen}
				onClose={() => EHealthSearchDialogStore.close()}
				disableBackdropClick={true}
				classes={{
					paper: "xs-paper-dialog"
				}}
				maxWidth="md"
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-base">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-search fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="PatientDetail.overview.eHealth.searching" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<Grid container direction="column">
						<Grid item xs={12}>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={6}>
									<XsDateTimePicker
										field={this.form.$("dateFrom")}
										onChange={(sDateTime) => EHealthSearchDialogStore.handleChange("dateFrom", sDateTime)}
										locale="sk-SK"
										dateFormat="DD.MM.YYYY"
									/>
								</Grid>
								<Grid item xs={6}>
									<XsDateTimePicker
										field={this.form.$("dateTo")}
										onChange={(sDateTime) => EHealthSearchDialogStore.handleChange("dateTo", sDateTime)}
										locale="sk-SK"
										dateFormat="DD.MM.YYYY"
									/>
								</Grid>
							</Grid>

							<Grid container direction="row" spacing={8} className="xs-examination">
								<Grid item xs={6}>
									<TextField
										InputLabelProps={{shrink: true}}
										label={<FormattedMessage id="PatientDetail.overview.eHealth.examinationName" />}
										autoFocus={false}
										defaultValue=""
										onChange={(e) => EHealthSearchDialogStore.handleChange("name", e.target.value)}
									/>
								</Grid>
								<Grid item xs={6}>
									<SearchDropdownNoForm
										className="eHealth_searchDropdown"
										items={examinationTypeValues}
										multiSelect={true}
										id="eHealthSearch_examinationType"
										onClose={(data) => {
											const values = data.map((x) => x.secondaryText)
											EHealthSearchDialogStore.handleChange("examinationTypes", values)
										}}
										label={<FormattedMessage id="PatientDetail.overview.eHealth.examinationType" />}
									/>
								</Grid>
							</Grid>

							<Grid container direction="row" spacing={8}>
								<Grid item xs={6}>
									<XsAutocomplete
										field={this.form.$("professionalFocus")}
										minLengthForSearch="1"
										api={api.loadExpertise}
										apiCallType="Pr"
										inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
										saveValue={(obj) => obj._id}
										postAction={(value) => {
											EHealthSearchDialogStore.searchExpertise(value._id)
											// EHealthSearchDialogStore.handleChange("professionalFocus", value._id)
										}}
										modalConfig={(textValue, clickHandler) => {
											return {
												columnDefs: {
													code_ext: {
														title: (
															<FormattedMessage id="Patient.form.patientrequest.expertise.autoCompleteModal.CodeColumn" />
														),
														type: "string",
														dbName: "code_ext",
														design: {
															width: "150px",
															header: {
																className: "xs-autoCompleteModalColumnHeader"
															}
														}
													},
													name_ext: {
														title: (
															<FormattedMessage id="Common.label.name" />
														),
														type: "string",
														dbName: "search_column",
														design: {
															header: {
																className: "xs-autoCompleteModalColumnHeader"
															}
														},
														filter: {
															gridWidth: 3,
															defaultValue: textValue,
															renderElement: "input"
														},
														sortable: true
													}
												},
												options: {
													// selectRow: true,
													showCursor: true,
													onRowClick: (dataRow) => {
														clickHandler(dataRow)
													}
												}
											}
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<XsAutocomplete
										field={this.form.$("technicalDepartment")}
										minLengthForSearch="3"
										api={api.loadProviders}
										filterValue={"provider_id"}
										inputRenderer={(obj) =>
											(obj.provider_code ? obj.provider_code + " / " : "") +
											obj.company_name +
											" / " +
											(obj.expertise_code ? obj.expertise_code + " " : "") +
											obj.expertise_name_ext
										}
										saveValue={(obj) => obj.provider_id}
										postAction={(value) => {
											EHealthSearchDialogStore.searchOrgUnit(value.provider_id)
											// EHealthSearchDialogStore.handleChange("technicalDepartment", value.provider_id)
										}}
									/>
								</Grid>
							</Grid>

							<Grid container direction="row" spacing={8}>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Checkbox
												value="OnlyOwnedRecords"
												checked={EHealthSearchDialogStore.onlyOwnedRecords}
												onChange={(e) => EHealthSearchDialogStore.handleChange("onlyOwnedRecords", e.target.checked)}
											/>
										}
										label={<FormattedMessage id="PatientDetail.overview.eHealth.onlyOwnedRecords" />}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Checkbox
												value="IsFullyDescribed"
												checked={EHealthSearchDialogStore.isFullyDescribed}
												onChange={(e) => EHealthSearchDialogStore.handleChange("isFullyDescribed", e.target.checked)}
											/>
										}
										label={<FormattedMessage id="PatientDetail.overview.eHealth.isFullyDescribed" />}
									/>
								</Grid>
							</Grid>

							<Grid container direction="row">
								<Grid item xs={12}>
									<RadioGroup
										className="xs-radio-buttons-sensitivity"
										onChange={(e) => EHealthSearchDialogStore.handleChange("sensitivity", e.target.value)}
										value={EHealthSearchDialogStore.sensitivity}
									>
										<FormControlLabel
											className="xs-control"
											value="3"
											control={<Radio />}
											label={<FormattedMessage id="PatientDetail.overview.eHealth.sensitivityMedium" />}
										/>
										<FormControlLabel
											className="xs-control"
											value="5"
											control={<Radio />}
											label={<FormattedMessage id="PatientDetail.overview.eHealth.sensitivityHigh" />}
										/>
									</RadioGroup>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => (EHealthSearchDialogStore.close(), this.form.reset())}
					/>
					<XsButton
						disabled={!this.form.isValid}
						className={!this.form.isValid ? "xs-default" : "xs-success"}
						icon={<i className="fal fa-search" />}
						text={<FormattedMessage id="Common.list.search" />}
						onClick={() => this.handleSearchClick()}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
