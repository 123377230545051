import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import Grid from "@material-ui/core/Grid"
import moment from "moment"

// special import for Material-UI binding
import api from "../../../actions/api"
import XsTableServer from "../../../../../global/ui/xsTableServer/xsTableServer"
import VaccinationListStore from "../../../stores/VaccinationListStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

import VaccFilter from "./vaccFilter"
import ExportVaccListToXlsDialog from "./exportVaccListToXlsDialog"
import UIStore from "../../../stores/UIStore"
import TableStore from "../../../stores/TableStore"

@injectIntl
@observer
export default class VaccList extends React.Component {
	constructor(props) {
		super(props)
		UIStore.isFormSaving = false
	}

	onHandleRowClick = (dataRow) => {
		if (isNotEmpty(dataRow.person_id) && isNotEmpty(dataRow.provider_id)) {
			GlobalStore.changeOrgUnitAndRouteToPatientDetail(dataRow.provider_id, dataRow.person_id)
		}
	}

	render() {
		const applyFilters = VaccinationListStore.vaccFilters

		return (
			<Grid container className="xs-vaccination-list">
				<VaccFilter />
				<Grid item xs={12}>
					{VaccinationListStore.changedFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i
								className="far fa-search fa-lg mr-3 pointer"
								onClick={() => {
									if (
										TableStore.tables.hasOwnProperty("vaccinationList") &&
										TableStore.tables["vaccinationList"].hasOwnProperty("index")
									) {
										delete TableStore.tables["vaccinationList"]["index"]
									}

									VaccinationListStore.getFiltersVacc(VaccinationListStore.vaccFormRef)
								}}
							></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTableServer
							setRef={(table) => (VaccinationListStore.vaccReportListRef = table)}
							uri={api.loadVaccinationReport().getPath()}
							filterValue={applyFilters}
							white={true}
							orderValue={[
								{
									asc: false,
									associated_column: "created_at"
								}
							]}
							config={{
								columnDefs: {
									person_identifier: {
										title: <FormattedMessage id="Patient.labels.IDNumber" />,
										type: "string",
										design: {
											width: "100px",
											body: {
												renderer: (props) => {
													return <div className="blueDark xs-bold">{props.value}</div>
												}
											}
										}
									},
									last_vaccinated_at: {
										title: "Dátum očkovania",
										design: {
											width: "100px",
											body: {
												formatter: (props) => {
													return isNotEmpty(props) ? moment(props).format("DD.MM.YYYY") : ""
												}
											}
										},
										type: "string"
									},
									vaccine_code_ext: {
										title: "Očk. látka",
										type: "action",
										design: {
											width: "75px"
										}
									},
									max_repeats: {
										title: "Celk. počet dávok",
										design: {
											width: "50px"
										},
										// type: "action"
										// sortable: true
									},
									max_med_order: {
										title: "Posledné poradie dávky",
										design: {
											width: "50px"
										},
										type: "string"
									},
									waiting_days: {
										title: "Počet dní od posledného očkovania",
										design: {
											width: "100px"
										},
										type: "string"
									}
								},
								options: {
									name: "vaccinationList",
									showCursor: true,
									mapper: (dataRow) => {
										return {
											person_identifier: isSafe(dataRow.person_identifier) ? dataRow.person_identifier : "",
											last_vaccinated_at: isSafe(dataRow.last_vaccinated_at) ? dataRow.last_vaccinated_at : "",
											vaccine_code_ext: isSafe(dataRow.vaccine_code_ext) ? dataRow.vaccine_code_ext : "",
											max_repeats: isSafe(dataRow.max_repeats) ? dataRow.max_repeats : "",
											max_med_order: isSafe(dataRow.max_med_order) ? dataRow.max_med_order : "",
											waiting_days: isSafe(dataRow.waiting_days) ? dataRow.waiting_days : "",
											person_id: dataRow.person_id,
											provider_id: dataRow.provider_id
										}
									},
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									}
								}
							}}
						/>
					)}
				</Grid>
				<ExportVaccListToXlsDialog />
			</Grid>
		)
	}
}
