"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"
import UIstore from "./UIStore"
import GlobalStore from "../../../global/store/GlobalStore"
import {getFilters} from "../../../global/helpers/api"

class QueueContainerStore {
	@observable listData = []
	@observable employeesList = []
	@observable editDialogOpen = false
	@observable queueId = null

	@action loadListData() {
		api
			.loadQueueList()
			.call()
			.then((response) => {
				this.listData = response.rows
			})
	}

	@action loadEmployees() {
		let filters = getFilters([`category_id=${"QueueManager"}`])
		api
			.getEmployeesForCompany(filters)
			.call()
			.then((response) => {
				if (isSafe(response.rows)) {
					this.employeesList = response.rows.map((row) => {
						return {
							code: row.relationship,
							_id: row.relationship,
							name_ext: row.full_name
						}
					})
				}
			})
	}

	@action openEditDialog(id) {
		this.editDialogOpen = true
		this.queueId = id
	}

	@action closeEditDialog(form) {
		this.editDialogOpen = false
		this.queueId = null
		if (isSafe(form)) {
			form.reset()
		}
	}

	@action loadEditForm(form) {
		const dataRow = this.listData.find((row) => row._id == this.queueId)
		form.$("name").value = dataRow.name
		form.$("count").value = dataRow.waiting
		form.$("capacity").value = dataRow.capacity
		form.$("employee").value = isSafe(dataRow.primary_manager) ? dataRow.primary_manager._id : ""
	}

	@action editQueue(form) {
		UIstore.isFormSaving = true
		const formVals = form.values()

		let req = {
			_ref: false,
			_type: "EZdravie.Data.MeZ.WorkQueue.Queue",
			active: true,
			capacity: formVals.capacity,
			primary_manager: {
				_type: "Entity.Data.Employee",
				_id: formVals.employee /* fronta pre tohto manazera */
			},
			name: formVals.name,
			waiting: formVals.count /* pocet cakajucich poloziek */
		}

		if (isNotEmpty(this.queueId)) {
			req["_id"] = this.queueId
		}

		const afterSave = () => {
			GlobalStore.setNotificationMessage(
				isNotEmpty(this.queueId) ? "Úprava prebehla úspešne" : "Uloženie prebehlo úspešne",
				"",
				"success"
			)
			UIstore.isFormSaving = false
			this.loadListData()
			this.closeEditDialog(form)
		}

		api
			.updateQueueInfo("PUT", req)
			.call()
			.then(() => {
				afterSave()
			})
	}
}

var singleton = new QueueContainerStore()
export default singleton
