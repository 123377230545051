import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "medical_opinion_4versions"
				},
				paperTemplateId: {
					value: "doctors_opinion_A"
				},

				version: {
					label: "Vzor",
					rules: "required",
					value: "a"
				},

				orgunitname: {},
				orgunitaddress: {},
				companyIdentifier: {},

				paperNumber: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.paperNumber" />
				},

				employerInv: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.employerInv" />,
					type: "checkbox",
					options: {
						html: "visibility"
					},
					value: false
				},

				employerName: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.employerName" />
				},
				employerSite: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.employerSite" />
				},
				employerId: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.employerId" />
				},

				employerPersonInv: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.employerPersonInv" />,
					type: "checkbox",
					options: {
						html: "visibility"
					},
					value: false
				},

				employerPersonName: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.name" />
				},
				employerPersonPlace: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.employerPersonPlace" />
				},
				employerPersonId: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.employerId" />
				},

				name: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.name" />
				},
				birthdate: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.birthdate" />,
					type: "date"
				},
				address: {},
				addressForm: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.address" />
				},
				personalNumberInv: {
					type: "checkbox",
					options: {
						html: "visibility"
					},
					value: false
				},
				personalNumber: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.personalNumber" />
				},
				employerWorkPlace: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.employerPersonPlace" />
				},
				workPlace: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.workPlace" />
				},

				workType: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.workType" />
				},
				workFactors: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.workFactors" />
				},
				workCategory: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.workCategory" />
				},
				workCustomRegulationInv: {
					type: "checkbox",
					options: {
						html: "visibility"
					},
					value: false
				},
				workCustomRegulation: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.workCustomRegulation" />
				},

				resultAInv: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.resultA" />,
					type: "checkbox",
					options: {
						html: "visibility"
					},
					value: false
				},

				resultBInv: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.resultB" />,
					type: "checkbox",
					options: {
						html: "visibility"
					},
					value: false
				},
				resultBDetail: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.resultBDetail" />
				},

				resultCInv: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinion.resultC" />,
					type: "checkbox",
					options: {
						html: "visibility"
					},
					value: false
				},

				now: {}
			}
		}
	}
}
