import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "transport"
				},
				variableDate: {
					label: <FormattedMessage id="PrintTemplate.Transport.datumCasPristaveniaVozidla" />,
					value: "",
					type: "dateTime"
				},
				casMiestoPristaveniaVozidla: {
					label: <FormattedMessage id="PrintTemplate.Transport.casMiestoPristaveniaVozidla" />,
					value: "",
					type: "text"
				},
				kod: {
					value: ""
				},
				kodID: {
					label: <FormattedMessage id="PrintTemplate.Transport.kodID" />,
					value: ""
				},
				odovodnenie: {
					label: <FormattedMessage id="PrintTemplate.Transport.odovodnenie" />,
					value: "",
					type: "text"
				},
				menoSprievodcu: {
					label: <FormattedMessage id="PrintTemplate.Transport.menoSprievodcu" />,
					value: "",
					type: "text"
				},
				dopravaZ: {
					label: <FormattedMessage id="PrintTemplate.Transport.dopravaZ" />,
					value: "",
					type: "text"
				},
				dopravaDo: {
					label: <FormattedMessage id="PrintTemplate.Transport.dopravaDo" />,
					value: "",
					type: "text"
				},
				zDovodu: {
					label: <FormattedMessage id="PrintTemplate.Transport.zDovodu" />,
					value: "",
					type: "text"
				},
				datum: {
					label: <FormattedMessage id="Common.label.date" />,
					value: moment(),
					type: "date"
				},

				osobaChodiacaInv: {
					label: <FormattedMessage id="PrintTemplate.Transport.osobaChodiacaInv" />,
					type: "checkbox",
					options: {
						html: "visibility"
					},
					value: false
				},
				osobaSediacaInv: {
					label: <FormattedMessage id="PrintTemplate.Transport.osobaSediacaInv" />,
					type: "checkbox",
					options: {
						html: "visibility"
					},
					value: false
				},
				osobaLeziacaInv: {
					label: <FormattedMessage id="PrintTemplate.Transport.osobaLeziacaInv" />,
					type: "checkbox",
					options: {
						html: "visibility"
					},
					value: false
				},
				osobaSoSprievodcomInv: {
					label: <FormattedMessage id="PrintTemplate.Transport.osobaSoSprievodcomInv" />,
					type: "checkbox",
					options: {
						html: "visibility"
					},
					value: false
				},

				vozidloRLPInv: {
					label: <FormattedMessage id="PrintTemplate.Transport.vozidloRLPInv" />,
					type: "checkbox",
					options: {
						html: "visibility"
					},
					value: false
				},
				vozidloRZPInv: {
					label: <FormattedMessage id="PrintTemplate.Transport.vozidloRZPInv" />,
					type: "checkbox",
					options: {
						html: "visibility"
					},
					value: false
				},
				vozidloDZSInv: {
					label: <FormattedMessage id="PrintTemplate.Transport.vozidloDZSInv" />,
					type: "checkbox",
					options: {
						html: "visibility"
					},
					value: false
				},
				vozidloInfekcneInv: {
					label: <FormattedMessage id="PrintTemplate.Transport.vozidloInfekcneInv" />,
					type: "checkbox",
					options: {
						html: "visibility"
					},
					value: false
				},

				preddefinovanyDovod: {
					label: <FormattedMessage id="PrintTemplate.Transport.preddefinovanyDovod" />,
					value: ""
				},

				opakovatKazdy: {
					label: "",
					value: ""
				},
				opakovatDo: {
					label: <FormattedMessage id="PrintTemplate.Transport.opakovatDo" />,
					type: "date",
					value: ""
				}
			}
		}
	}
}
