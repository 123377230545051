"use strict"

import {observable} from "mobx"

class CapitationFormStore {
	@observable editable = false
	@observable isAdd = false
}

var singleton = new CapitationFormStore()
export default singleton
