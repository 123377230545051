"use strict"

import {observable, action} from "mobx"
import moment from "moment"

import {getSelectedOrgUnitID, getSelectedOrgUnit} from "../../../global/helpers/actions"

import WarningStore from "../../../global/store/WarningStore"
import UIStore from "./UIStore"

import api from "../actions/api"

class ProblemListStore {
	@observable problemData = []

	formRef = null

	@observable changedFilters = false
	currFilters = {}

	@observable loading = false

	@action isChangedFilter(formValues) {
		let isChangeDateFrom = compareMomentDate(moment(this.currFilters.validity_from), moment(formValues.validity_from))
		let isChangeDateTo = compareMomentDate(moment(this.currFilters.validity_to), moment(formValues.validity_to))

		if (
			isChangeDateFrom ||
			isChangeDateTo ||
			this.currFilters.identifier != formValues.identifier ||
			this.currFilters.insurer != formValues.insurer
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action getFilters(form) {
		let formValues = form.values()
		this.currFilters = form.values()

		let filters = [
			// {
			// 	associated_column: "personnel_id",
			// 	values: [{id_value: getUserPersonnelID()}]
			// },
			{
				associated_column: "provider_id",
				values: [{id_value: getSelectedOrgUnitID()}]
			},
			{
				associated_column: "problem_type",
				values: [{id_value: "DISPENSARY"}]
			},
			{
				associated_column: "validity_from",
				values: [{id_value: formValues.validity_from}]
			},
			{
				associated_column: "validity_to",
				values: [{id_value: formValues.validity_to}]
			}
		]

		if (isNotEmpty(formValues.identifier)) {
			filters.push({
				associated_column: "identifier",
				values: [{id_value: formValues.identifier.trim()}]
			})
		}

		if (isNotEmpty(formValues.insurer)) {
			filters.push({
				associated_column: "insurer",
				values: [{id_value: formValues.insurer}]
			})
		}

		const req = {
			filters: filters
		}

		this.getProblemDispensary(req)
	}

	@action getProblemDispensary(req) {
		this.loading = true
		this.changedFilters = false

		api
			.loadProblems(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows) && res.rows.length > 0) {
					this.problemData = res.rows
				} else {
					this.problemData = []
				}

				this.loading = false
			})
			.catch(() => {
				this.loading = false
			})
	}

	@action downloadDispensarisation() {
		const formValues = this.formRef.values()

		UIStore.isFormSaving = true

		api
			.downloadDispensarisation({
				doctor_expertise_id: getSelectedOrgUnit().personnel_expertise_id,
				insurer_id: formValues.insurer,
				provider_id: getSelectedOrgUnitID(),
				date: formValues.validity_from
			})
			.call()
			.then((response) => {
				if (response.ok == true) {
					let fileName = isSafe(response.headers.get("content-disposition"))
						? response.headers.get("content-disposition").split("filename=")[1]
						: "dispensar.xml"
					response.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})

					UIStore.isFormSaving = false
				} else {
					WarningStore.open(response.statusText)
					UIStore.isFormSaving = false
				}
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}
}

var singleton = new ProblemListStore()
export default singleton
