import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../../global/ui/xsButton/xsButton"
// import SearchSelect from "../../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsTable from "../../../../../../global/ui/xsTable/xsTable"
import XsIconButton from "../../../../../../global/ui/xsButton/xsIconButton"
import XsConfirmationDialog from "../../../../../../global/ui/xsDialog/xsConfirmationDialog"
import XsAutocomplete from "../../../../../../global/ui/xsInput/xsAutocomplete"

import Grid from "@material-ui/core/Grid"

import fields from "./employeeMedWorkExpFormFields"
import dataBindings from "./employeeMedWorkExpContainerBindings"
import {insertFormDataByScheme} from "../../../../../../global/helpers/bindings"
import {FormattedMessage, injectIntl} from "react-intl"

import EmployeesDetailStore from "../../../../stores/EmployeesDetailStore"
import GlobalStore from "../../../../../../global/store/GlobalStore"

import api from "../../../../../ambulance/actions/api"
import ExpertiseFormContracts from "../employeeMedicalWorkerForm/expertiseFormContrats/expertiseFormContracts"

@injectIntl
@observer
export default class EmployeeMedWorkExpContainer extends React.Component {
	constructor(props) {
		super(props)
		// this.state = {
		//   showTable: true
		// }

		const hooks = {
			onSubmit(form) {
				if (isEmpty(form.$("expertiseId").value) || isEmpty(form.$("from").value)) {
					GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
				}
			},
			onSuccess(form) {
				let identifiers = []
				if (isNotEmpty(form.$("doctorCode").value)) {
					identifiers.push({
						type: "DOCTORCODE",
						_ref: false,
						_type: "IXS.Data.Object.Identifier",
						value: form.$("doctorCode").value
					})
				}
				insertFormDataByScheme(
					form,
					dataBindings,
					{personnelId: props.personnelId, identifiers: identifiers},
					() => {
						EmployeesDetailStore.loadWorkerExpertise(props.personnelId)
						EmployeesDetailStore.isAdd = true
						form.reset()
					},
					false,
					"Common.notification.save"
				)
			},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks})
		EmployeesDetailStore.isAdd = false
	}

	handleTableRowClick = (dataRow) => {
		EmployeesDetailStore.loadEmployeeMedWorkExpForm(this.form, this.props.personnelId, dataRow.personnelExperiseId)
		EmployeesDetailStore.empExpertises = dataRow.employment_contracts
		EmployeesDetailStore.editable = true
		EmployeesDetailStore.isAdd = true
		// this.form.$("expertise").set(isSafe(dataRow.formData) && isSafe(dataRow.formData.expertise)?dataRow.formData.expertise.name_ext:"")
		// this.form.$("expertiseId").set(isSafe(dataRow.formData) && isSafe(dataRow.formData.expertise)?dataRow.formData.expertise._id:"")
		// this.form.$("from").set(isSafe(dataRow.formData) && isSafe(dataRow.formData.validity)?"":"") //FIX moment!!!
		// this.form.$("to").set(isSafe(dataRow.formData) && isSafe(dataRow.formData.validity)?"":"") //FIX moment!!!
	}

	render() {
		return (
			<Grid container direction="column">
				<Grid item className="pa-6">
					<XsTable
						config={{
							columnDefs: {
								doctorCode: {
									title: <FormattedMessage id="Employee.form.medicalWorkerExpForm.doctor_code" />,
									type: "string",
									design: {
										width: "20%"
									},
									sortable: true
								},
								expertiseCode: {
									title: <FormattedMessage id="Employee.form.medicalWorkerExpForm.code_ext" />,
									type: "string",
									design: {
										width: "20%"
									},
									sortable: true
								},
								expertiseName: {
									title: <FormattedMessage id="Employee.form.medicalWorkerExpForm.name_ext" />,
									type: "string",
									design: {
										width: "60%"
									},
									sortable: true
								},
								delete: {
									title: "",
									type: "action",
									design: {
										body: {
											renderer: (props) => {
												return (
													<XsIconButton
														className="action-delete"
														icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
														onClick={() =>
															GlobalStore.openConfirmationDialog("xsDeleteEmpExpertise", {expertiseId: props.value})
														}
													/>
												)
											}
										}
									}
								}
							},
							options: {
								hidePager: true,
								showCursor: true,
								// selectRow: true,
								onRowClick: (dataRow) => {
									this.handleTableRowClick(dataRow)
								},
								mapper: (dataRow) => {
									return {
										doctorCode:
											isSafe(dataRow.personnel_expertise) &&
											isSafe(dataRow.personnel_expertise.identifiers) &&
											isSafe(dataRow.personnel_expertise.identifiers.find((i) => i.type == "DOCTORCODE"))
												? dataRow.personnel_expertise.identifiers.find((i) => i.type == "DOCTORCODE").value
												: "",
										expertiseCode:
											isSafe(dataRow.personnel_expertise) && isSafe(dataRow.personnel_expertise.expertise)
												? dataRow.personnel_expertise.expertise.code_ext
												: "",
										expertiseName:
											isSafe(dataRow.personnel_expertise) && isSafe(dataRow.personnel_expertise.expertise)
												? dataRow.personnel_expertise.expertise.name_ext
												: "",
										expertiseId:
											isSafe(dataRow.personnel_expertise) && isSafe(dataRow.personnel_expertise.expertise)
												? dataRow.personnel_expertise.expertise._id
												: "",
										delete: isSafe(dataRow.personnel_expertise) ? dataRow.personnel_expertise._id : "",
										personnelExperiseId: isSafe(dataRow.personnel_expertise) ? dataRow.personnel_expertise._id : "",
										employment_contracts:
											isSafe(dataRow.personnel_expertise) && isSafe(dataRow.personnel_expertise.employment_contracts)
												? dataRow.personnel_expertise.employment_contracts
												: []
									}
								}
							},
							dataSource: EmployeesDetailStore.workerExpertiseList
						}}
					/>
				</Grid>
				<Grid item className="fomBorderTop pt-4 pb-2 pl-6 pr-6">
					{EmployeesDetailStore.isAdd ? (
						<form className="xs-medWork-form">
							<Grid container direction="row" spacing={8}>
								<Grid item xs={2}>
									<XsAutocomplete
										api={api.loadExpertise}
										apiCallType="PE"
										white={true}
										field={this.form.$("expertiseId")}
										minLengthForSearch="3"
										inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
										saveValue={(obj) => obj._id}
										modalConfig={(textValue, clickHandler) => {
											return {
												columnDefs: {
													code_ext: {
														title: <FormattedMessage id="Employee.form.medicalWorkerExpForm.code_ext" />,
														design: {
															width: "15%"
														},
														type: "string",
														dbName: "code_ext",
														filter: {
															gridWidth: 3,
															defaultValue: "",
															renderElement: "input"
														}
													},
													name_ext: {
														title: <FormattedMessage id="Employee.form.medicalWorkerExpForm.name_ext" />,
														type: "string",
														dbName: "search_column",
														design: {
															width: "85%"
														},
														filter: {
															gridWidth: 3,
															defaultValue: textValue,
															renderElement: "input"
														}
													}
												},
												options: {
													showCursor: true,
													onRowClick: (dataRow) => {
														clickHandler(dataRow)
													}
												}
											}
										}}
									/>
								</Grid>
								<Grid item xs={1}>
									<XsDateTimePicker field={this.form.$("from")} />
								</Grid>
								<Grid item xs={1}>
									<XsDateTimePicker field={this.form.$("to")} />
								</Grid>
								<Grid item xs={2}>
									<XsInput field={this.form.$("doctorCode")} white={true} />
								</Grid>
								<Grid item xs={2}>
									<XsAutocomplete
										api={api.loadAssesingPhysicians}
										field={this.form.$("assesing_physician")}
										minLengthForSearch="3"
										white={true}
										inputRenderer={(obj) => obj.code + " " + obj.full_name}
										saveValue={(obj) => obj.assesing_physician_id}
										filterValue="assesing_physician_id"
										modalConfig={(textValue, clickHandler) => {
											return {
												columnDefs: {
													code: {
														title: <FormattedMessage id="Employee.form.medicalWorkerExpForm.doctor_code" />,
														design: {
															width: "15%"
														},
														type: "string",
														dbName: "code",
														filter: {
															gridWidth: 3,
															defaultValue: "",
															renderElement: "input"
														}
													},
													branch_code: {
														title: <FormattedMessage id="Employee.form.medicalWorkerExpForm.department_code" />,
														type: "string",
														dbName: "branch_code",
														design: {
															width: "15%"
														}
													},
													full_name: {
														title: <FormattedMessage id="Employee.form.medicalWorkerExpForm.doctor_name" />,
														type: "string",
														dbName: "search_column",
														design: {
															width: "70%"
														},
														filter: {
															gridWidth: 3,
															defaultValue: textValue,
															renderElement: "input"
														}
													}
												},
												options: {
													showCursor: true,
													onRowClick: (dataRow) => {
														clickHandler(dataRow)
													}
												}
											}
										}}
									/>
								</Grid>
								<Grid item container justify="flex-end" alignItems="center" xs={4} spacing={8} className="mt-0">
									{EmployeesDetailStore.editable ? (
										<Grid item>
											<XsButton
												className="xs-success"
												type="submit"
												onClick={this.form.onSubmit}
												text={<FormattedMessage id="Common.label.save" />}
												icon={<i className="fal fa-check fa-lg" />}
											/>
										</Grid>
									) : (
										<Grid item>
											<XsButton
												className="xs-success"
												type="submit"
												onClick={this.form.onSubmit}
												text={<FormattedMessage id="Common.label.add" />}
												icon={<i className="fal fa-check fa-lg" />}
											/>
										</Grid>
									)}
									<Grid item>
										<XsButton
											className="xs-danger xs-outline"
											onClick={() => {
												// this.initForm()
												this.form.reset()
												EmployeesDetailStore.isAdd = false
												EmployeesDetailStore.editable = false
											}}
											text={<FormattedMessage id="Common.label.cancel" />}
											icon={<i className="fal fa-times fa-lg"></i>}
										/>
									</Grid>
								</Grid>
							</Grid>
						</form>
					) : (
						<XsButton
							className="xs-primary xs-outline"
							icon={<i className="fal fa-plus fa-lg" />}
							text={<FormattedMessage id="Common.button.addAnother" />}
							onClick={() => {
								EmployeesDetailStore.isAdd = true
								EmployeesDetailStore.editable = false
							}}
						/>
					)}
				</Grid>
				{EmployeesDetailStore.editable && (
					<ExpertiseFormContracts
						personnelId={this.props.personnelId}
						expertiseIdField={this.form.$("expertiseId")}
						personnelExperiseId={this.form.$("personnelExpertisesId")}
					/>
				)}
				<XsConfirmationDialog
					type="danger"
					name="xsDeleteEmpExpertise"
					text={<FormattedMessage id="Employee.form.medicalWorkerExpForm.DeleteContract" />}
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.btn.expertise.deleteContract"
					}}
					onConfirmation={() =>
						EmployeesDetailStore.deleteEmpWorkExpertise(
							this.props.personnelId,
							GlobalStore.confirmationDialogParams.expertiseId
						)
					}
				/>
			</Grid>
		)
	}
}
