import React from "react"
import {observer} from "mobx-react"
import {injectIntl /*, FormattedMessage*/} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import {Grid, InputAdornment} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"

import "./printTemplatesStyle.less"

// import GlobalStore from "../../../../../../global/store/GlobalStore"
import fields from "./releaseReportFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsTextArea from "../../../../../../global/ui/xsTextArea/xsTextArea"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import api from "../../../../actions/api"
// import XsCheckbox from "../../../../../../global/ui/xsCheckbox/xsCheckbox"
// import SearchSelect from "../../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsAutocompleteLocal from "../../../../../../global/ui/xsInput/xsAutocompleteLocal"
import XsAutocomplete from "../../../../../../global/ui/xsInput/xsAutocomplete"
import XsSimpleTextArea from "../../../../../../global/ui/xsTextArea/xsSimpleTextArea"
import XsSearchDropDownAction from "../../../../../../global/ui/xsDropDownAction/xsSearchDropDownAction"
import XsIconButton from "../../../../../../global/ui/xsButton/xsIconButton"
import RegisterCacheStore from "../../../../../../global/store/RegistersCacheStore"
import {getUserCompanyInfo /*, handleInsertHelpersIntoTextArea */} from "../../../../../../global/helpers/actions"
import DataStore from "../../../../stores/DataStore"
import TemplateForm from "../../patientRecordForm/templateForm"

@injectIntl
@observer
export default class ReleaseReport extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		if (isEmpty(this.form.$("insurerCodeForm").value) && isNotEmpty(this.form.$("insurerCode").value)) {
			this.form.$("insurerCodeForm").value = this.form.$("insurerCode").value
		}

		if (isEmpty(this.form.$("identifierForm").value) && isNotEmpty(this.form.$("identifier").value)) {
			this.form.$("identifierForm").value = this.form.$("identifier").value
		}

		if (isEmpty(this.form.$("nameForm").value) && isNotEmpty(this.form.$("name").value)) {
			this.form.$("nameForm").value = this.form.$("name").value
		}

		if (isEmpty(this.form.$("addressForm").value) && isNotEmpty(this.form.$("address").value)) {
			this.form.$("addressForm").value = this.form.$("address").value
		}

		this.recommTemplates = null
		this.recommendationCursor = null
		this.templateName = "TMPL:RECOMM"
	}

	uncheckValue = (fieldName) => {
		this.form.$(fieldName).value = false
	}

	componentDidMount() {
		this.loadTextareaTemplates()
	}

	loadTextareaTemplates = async () => {
		await DataStore.loadTemplatesSync(this.templateName)
		this.refreshTextareaTemplates()
	}

	refreshTextareaTemplates = () => {
		this.recommTemplates =
			isSafe(DataStore.templatesAll) &&
			isSafe(DataStore.templatesAll[this.templateName]) &&
			isSafe(DataStore.templatesAll[this.templateName].records) &&
			DataStore.templatesAll[this.templateName].records.length > 0
				? DataStore.templatesAll[this.templateName].records
						.sort((a, b) => {
							if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
							if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
							return 0
						})
						.map((r) => {
							return {
								label: r.name,
								itemData: r.template_stream.replace(/\\\"/g, '"'), // eslint-disable-line
								action: (e) => DataStore.editTemplate(e.currentTarget, r)
							}
						})
				: []
	}

	render() {
		this.refreshTextareaTemplates()

		return (
			<div className="pa-4">
				<Grid container spacing={8}>
					<Grid item xs={6}>
						<XsInput field={this.form.$("nameForm")} />
					</Grid>
					<Grid item xs={3}>
						<XsInput field={this.form.$("identifierForm")} />
					</Grid>
					<Grid item xs={3}>
						<XsInput field={this.form.$("insurerCodeForm")} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsInput field={this.form.$("addressForm")} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={3}>
						<XsInput
							field={this.form.$("patientRecievedTime")}
							InputProps={{
								endAdornment: (
									<InputAdornment className="xs-endUnit" position="end">
										hod.
									</InputAdornment>
								)
							}}
						/>
					</Grid>
					<Grid item xs={9}>
						<XsInput field={this.form.$("patientRecieved")} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={6}>
						<XsAutocompleteLocal
							field={this.form.$("diagnosisWhenRecieved")}
							minLengthForSearch="3"
							filterValue="code_ext"
							clearText
							data={RegisterCacheStore.diagnosisRegister}
							inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
							postAction={(obj) => {
								this.form.$("diagnosisWhenRecieved_name").value = obj.code_ext + " " + obj.name_ext
							}}
							saveValue={(obj) => obj.code_ext}
						/>
					</Grid>
					<Grid item xs={6}>
						<XsAutocomplete
							field={this.form.$("operationProtocolDoneBy")}
							minLengthForSearch="3"
							api={api.loadPersonnelAndProvider}
							clearText
							filterValue={"personnel_expertise_id"}
							addSpecialFilter={() => {
								return [
									{
										associated_column: "company_id",
										predicate: "=",
										values: [
											{
												id_value: getUserCompanyInfo().id
											}
										]
									}
								]
							}}
							inputRenderer={(obj, menuItem) =>
								(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem
									? "<b style=color:#00A787>"
									: "") +
								(obj.doctor_code ? obj.doctor_code + " / " : "") +
								obj.full_name +
								" / " +
								(obj.cl_expertise_code_ext ? obj.cl_expertise_code_ext + " " : "") +
								obj.cl_expertise_name_ext +
								(isNotEmpty(obj.provider_code) ? " / " + obj.provider_code : "") +
								(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem ? "</b>" : "")
							}
							saveValue={(obj) => obj.personnel_expertise_id}
							postAction={(obj) => {
								this.form.$("operationProtocolDoneBy_name").value = obj.full_name
							}}
							onClear={() => {}}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12} className="fontSize12">
						Informovaný súhlas rodičmi podpísaný.
					</Grid>
				</Grid>

				<Grid container spacing={8} className="mt-2">
					<Grid item className="xs-bold">
						OPERÁCIA
					</Grid>
				</Grid>

				<Grid container spacing={8}>
					<Grid item xs={3}>
						<XsInput
							field={this.form.$("operationDeliveryTime")}
							InputProps={{
								endAdornment: (
									<InputAdornment className="xs-endUnit" position="end">
										hod.
									</InputAdornment>
								)
							}}
						/>
					</Grid>
					<Grid item xs={9}>
						<XsInput field={this.form.$("operationDelivery")} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={6}>
						<XsAutocomplete
							field={this.form.$("operator")}
							minLengthForSearch="3"
							api={api.loadPersonnelAndProvider}
							clearText
							filterValue={"personnel_expertise_id"}
							addSpecialFilter={() => {
								return [
									{
										associated_column: "company_id",
										predicate: "=",
										values: [
											{
												id_value: getUserCompanyInfo().id
											}
										]
									}
								]
							}}
							inputRenderer={(obj, menuItem) =>
								(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem
									? "<b style=color:#00A787>"
									: "") +
								(obj.doctor_code ? obj.doctor_code + " / " : "") +
								obj.full_name +
								" / " +
								(obj.cl_expertise_code_ext ? obj.cl_expertise_code_ext + " " : "") +
								obj.cl_expertise_name_ext +
								(isNotEmpty(obj.provider_code) ? " / " + obj.provider_code : "") +
								(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem ? "</b>" : "")
							}
							saveValue={(obj) => obj.personnel_expertise_id}
							postAction={(obj) => {
								this.form.$("operator_name").value = obj.full_name
							}}
							onClear={() => {}}
						/>
					</Grid>
					<Grid item xs={6}>
						<XsInput field={this.form.$("anestesis")} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsInput field={this.form.$("operationDeliveryText")} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsSimpleTextArea field={this.form.$("complicationsDuringOperation")} rows={3} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsInput field={this.form.$("materialForHisto")} />
					</Grid>
				</Grid>

				<Grid container spacing={8}>
					<Grid item className="xs-bold">
						POOPERAČNÝ PRIEBEH
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsInput field={this.form.$("medicamentTreatment")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("bleedingAfterOperation")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("otherComplications")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("vitalSings")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("poRecieved")} />
					</Grid>

					<Grid item xs={12}>
						<XsAutocompleteLocal
							field={this.form.$("releasedWithDiagnosis")}
							minLengthForSearch="3"
							filterValue="code_ext"
							clearText
							data={RegisterCacheStore.diagnosisRegister}
							inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
							saveValue={(obj) => obj.code_ext}
							postAction={(obj) => {
								this.form.$("releasedWithDiagnosis_name").value = obj.code_ext + " " + obj.name_ext
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<XsTextArea
							field={this.form.$("recommendation")}
							customToolbarHelpers={
								<div className="xs-textarea-helpers">
									<XsSearchDropDownAction
										placeholder={"Pomôcky"}
										items={this.recommTemplates}
										actionIconClassName="fal fa-pencil-alt"
										className="xs-textarea-dropdown"
										onChange={(data) => {
											if (isSafe(data)) {
												// if (isNotEmpty(this.form.$("recommendation").value) && this.recommendationCursor) {
												// 	handleInsertHelpersIntoTextArea(
												// 		this.form,
												// 		"recommendation",
												// 		this.recommendationCursor.cursor,
												// 		data.itemData
												// 	)
												// } else {
													this.form.$("recommendation").value += data.itemData
												// }
												this.recommendationCursor = null
											}
										}}
									/>
								</div>
							}
							customToolbar={
								<div className="xs-textarea-helpers-other">
									<XsIconButton
										className="xs-default xs-outline xs-quil-helper-btn"
										icon={<AddIcon />}
										onClick={(e) => {
											DataStore.templateAnchorEl = e.currentTarget
											DataStore.openTemplateForm()
										}}
									/>
								</div>
							}
							onFocus={(data) => {
								if (data && (isNotSafe(this.recommendationCursor) || this.recommendationCursor.cursor != data.cursor)) {
									this.recommendationCursor = {
										cursor: data.cursor
									}
								}
							}}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={8}>
					<Grid item xs={6}>
						<XsInput field={this.form.$("doctorname")} />
					</Grid>

					<Grid item xs={3}>
						<XsDateTimePicker field={this.form.$("releaseDate")} />
					</Grid>
				</Grid>
				<TemplateForm type={this.templateName} />
			</div>
		)
	}
}
