"use strict"

import {observable, action} from "mobx"
import config from "../../../global/config/settings"

import WarningStore from "../../../global/store/WarningStore"
import DDaction from "../actions/patientCard"
import DataStore from "../stores/DataStore"
import UIStore from "../stores/UIStore"
import {getSelectedOrgUnitID, getSelectedOrgUnit, getUser, getUserPersonnelID} from "../../../global/helpers/actions"
import {GWApiCall} from "../../../global/helpers/api"
import GlobalStore from "../../../global/store/GlobalStore"

class EHealthGetRequestsDialogStore {
	@observable isOpen = false
	@observable numberRequestId = ""
	warningText = ""

	@action open() {
		this.isOpen = true
	}

	@action search() {
		const selectedOrgUnit = getSelectedOrgUnit()
		const user = getUser()
		const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
		const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
		const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
		const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
		const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

		const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
			? DataStore.patientDTO.get("patient").validInfo.client
			: null
		const JRUZIDObj = isSafe(patientClientInfo.identifiers)
			? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
			: null

		let request = {
			ClassName: "VyhladajZaznamyOVysetreniPreZiadatela_v5",
			ExtId: this.numberRequestId,
			AmbeePatientId: DataStore.patientDTO.get("patientID"),
			PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
			PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
			AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
			AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
			AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
			AmbeePersonnelId: getUserPersonnelID(),
			OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
			PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
			PersonnelExpertiseJRUZVersion: specVer,
			PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
		}

		// api.searchExaminationRecords(request).call()
		const providerID = getSelectedOrgUnitID()

		GWApiCall({
			method: "POST",
			uri: `${config.GW_BASE_URL}/SearchExchangeTickets`,
			body: JSON.stringify(request)
		})
			.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
			.then((data) => {
				if (isSafe(data) && isNotEmpty(data.ErrorMessage)) {
					GlobalStore.checkEHGWError(data.ErrorCode, data.ErrorMessage, data.IsEhealthException)
					// WarningStore.generateErrorMessage(data.IsEhealthException, data.ErrorMessage)
				} else {
					if (data.Count == 0) {
						WarningStore.open(this.warningText)
					}
				}
				DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
					DataStore.setPatientEHR(overview)
					UIStore.isFormSaving = false
				})
			})
			.catch((err) => {
				logger(err)
				DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
					DataStore.setPatientEHR(overview)
					UIStore.isFormSaving = false
				})
			})

		this.close()
	}

	@action close() {
		this.isOpen = false

		this.numberRequestId = ""
	}
}

var singleton = new EHealthGetRequestsDialogStore()
export default singleton
