import React from "react"
import {observer} from "mobx-react"
// import moment from "moment"

import settings from "../../config/settings"

import "./xsFooter.less"

//TODO Vytiahnúť to wrappera pre App, podmieniť podľa settings aby sa vôbec nerenderovalo

@observer
class XsFooter extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			version: null
		}
		if (settings.DEBUG == true) {
			// fetch("./config/version.json")
			// 	.then((res) => (res.json().status !== 404 ? res.json() : null))
			// 	.then((data) => {
			// 		this.setState({version: data})
			// 	})
		}
	}

	render() {
		// const {version} = this.state
		// let footerText = ""

		// if (settings.DEBUG == true && version) {
		// 	footerText = `branch: ${version.version}, build: ${version.commit}${
		// 		version.buildTime ? `, buildTime: ${moment(version.buildTime).format(settings.DB_DATETIME_FORMAT)}` : ""
		// 	}`
		// 	return <footer id="xsFooter">{footerText}</footer>
		// }
		return null
	}
}

export default XsFooter
