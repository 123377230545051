/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				insurer: {
					label: <FormattedMessage id="Common.label.insurer" />,
					rules: "required|string"
				},
				number: {
					label: <FormattedMessage id="Common.label.number" />,
					rules: "string"
				},
				from: {
					label: <FormattedMessage id="Common.label.from" />,
					rules: "required",
					type: "date",
					value: moment().startOf("year")
				},
				to: {
					label: <FormattedMessage id="Common.label.to" />,
					type: "date"
				},
				type: {
					label: <FormattedMessage id="Patient.form.patientrecord.diagnosistype" />,
					rules: "required|string",
					value: "GH"
				},
				country: {
					label: <FormattedMessage id="Patient.form.insuranceForm.country" />,
					rules: "required|string",
					value: "SK"
				}
			}
		}
	}
}
