"use strict"

import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import {
	Grid,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	TextField,
	Checkbox,
	IconButton,
	InputAdornment,
	FormControlLabel
} from "@material-ui/core"
import XsButton from "../../../../global/ui/xsButton/xsButton"
import DeletePatientDialogStore from "../../stores/DeletePatientDialogStore"
import DataStore from "../../stores/DataStore"
import UIStore from "../../stores/UIStore"
import XsConfirmationDialog from "../../../../global/ui/xsDialog/xsConfirmationDialog"
// import api from "../../../actions/api"

function deletePatientDialog(props) {
	const handleRoute = () => {
		let data = DataStore.patientListTableRef.getLoadedData()
		if (isSafe(data)) {
			props.routeToPatient(data.find((row) => row.client_id == DeletePatientDialogStore.patientId))
			DeletePatientDialogStore.close()
		}
	}

	return (
		<div>
			<Dialog
				id="xsDeletePatientDialog"
				open={DeletePatientDialogStore.isOpen}
				onClose={() => {
					DeletePatientDialogStore.close()
				}}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-900"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								DeletePatientDialogStore.close()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-user-slash fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.removePatient" />
						</div>
						<div className="xs-header-subtitle">
							<FormattedMessage id="Common.label.removePatientText" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-overflow-visible">
					<Grid item xs={12} className="pa-4">
						<div className="xs-search">
							<TextField
								placeholder={props.intl.formatMessage({id: "Calendar.addEvent.dialog.searchPlaceholder"})}
								autoFocus={true}
								value={DeletePatientDialogStore.searchValue}
								onChange={(e) => {
									DeletePatientDialogStore.searchValue = e.target.value
									DeletePatientDialogStore.searchPatient()
								}}
								InputProps={{
									className: "xs-search-input",
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												disabled={isEmpty(DeletePatientDialogStore.searchValue) ? true : false}
												onClick={() => {
													DeletePatientDialogStore.onHandleUnknowPatient()
												}}
											>
												<i className="xs-plus-btn fal fa-plus-square" />
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</div>
						{isSafe(DeletePatientDialogStore.patientList) && DeletePatientDialogStore.patientList.length > 0 && (
							<div className="xs-patient-list-scrollbar">
								<div className="xs-patient-list">
									{DeletePatientDialogStore.patientList.map((patient, idx) => {
										let lastName = isNotEmpty(patient.profile.last_name) ? patient.profile.last_name.toUpperCase() : ""

										return (
											<div key={idx} className="xs-patient">
												<div className="xs-cover">
													<div className="xs-checkbox-before-name">
														<FormControlLabel
															control={
																<Checkbox
																	className="xs-orgunit-checkbox"
																	checked={DeletePatientDialogStore.checked === idx}
																	onChange={() => {
																		DeletePatientDialogStore.checked =
																			DeletePatientDialogStore.checked == idx ? null : idx
																	}}
																/>
															}
														/>
													</div>
													<div className="xs-patient-info">
														<div className="xs-full-name">
															{isEmpty(patient.profile.last_name) && isEmpty(patient.profile.first_name)
																? patient.client_text
																: lastName + " " + patient.profile.first_name}
														</div>
														<div className="xs-gender">
															{patient.profile.gender_name}, {patient.profile.age}{" "}
															<FormattedMessage id="Common.label.years" />
															{isNotEmpty(patient.primary_address) && ` (${patient.primary_address})`}
														</div>
													</div>
												</div>
												<div className="xs-insurer">
													<div className="xs-identifier">{patient.identifier}</div>
													<div className="xs-insurer-name">{patient.insurer_short_name}</div>
													{/* <div className="xs-insurer-name">{patient.insurer_name}</div> */}
												</div>
											</div>
										)
									})}
								</div>
							</div>
						)}
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer">
					<Grid item xs={12} className="xs-action xs-row xs-justify-between">
						<XsButton
							className="xs-danger xs-outline"
							onClick={() => {
								DeletePatientDialogStore.close()
							}}
							text={<FormattedMessage id="Common.label.cancel" />}
							icon={<i className="fal fa-times fa-lg" />}
						/>

						<XsButton
							className="xs-success"
							type="submit"
							onClick={() => {
								UIStore.isFormSaving = true
								if (isEmpty(DeletePatientDialogStore.checked)) {
									DeletePatientDialogStore.checkAndDelete()
								} else {
									DeletePatientDialogStore.movePatient()
								}
							}}
							text={
								<FormattedMessage
									id={
										isEmpty(DeletePatientDialogStore.checked)
											? "Common.label.closeAndDelete"
											: "Common.label.closeAndMove"
									}
								/>
							}
							icon={<i className="fal fa-trash fa-lg" />}
						/>
					</Grid>
				</DialogActions>
			</Dialog>
			<XsConfirmationDialog
				name="xsDeletePatientConfirmation"
				type="danger"
				title={<FormattedMessage id="Common.label.warning" />}
				text={
					<div className="xsDeletePatientConfirmation">
						{props.intl.formatMessage({id: "Common.label.moveIncomplete"}) + " "}
						<span
							className="link"
							onClick={() => {
								handleRoute()
							}}
						>
							tu.
						</span>
						<div>{props.intl.formatMessage({id: "Common.label.forceDeletePatient"})}</div>
					</div>
				}
				onConfirmation={() => DeletePatientDialogStore.deletePatient()}
			/>
		</div>
	)
}

export default injectIntl(observer(deletePatientDialog))
