import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
// import moment from "moment"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import {FormattedMessage} from "react-intl"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import Grid from "@material-ui/core/Grid"
import fields from "./employeeCardFormField"
import saveBindings from "./employeeCardFormSaveBindings"
import {insertFormDataByScheme} from "../../../../../global/helpers/bindings"
import EmployeesDetailStore from "../../../stores/EmployeesDetailStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

@observer
export default class EmployeeCardForm extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit(/*form*/) {
				// special validation here
			},
			onSuccess(form) {
				let identifiers = []
				if (isNotEmpty(form.$("cardNumber").value)) {
					identifiers.push({
						_type: "IXS.Data.Object.Identifier",
						type: "CARD",
						system: "IXS",
						_ref: false,
						active: true,
						value: form.$("cardNumber").value
					})
				}
				// form.$("capacityTransformed").value = +form.$("capacity").value / 100
				// // form.$("org_unit").set(72781)
				insertFormDataByScheme(
					form,
					saveBindings,
					{employeeId: props.employeeId, identifiers: identifiers},
					() => (EmployeesDetailStore.editCardForm = false),
					false,
					"EmployeeDetail.employeeInfoForm.saved"
				)

				// set(form, DataStore.patientDTO.get("patient"), dataBindings)
			},
			onError() {}
		}

		GlobalStore.refreshCodeLists(["employeeCategory"])
		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		EmployeesDetailStore.setCardForm(this.form, props.employeeId)
	}

	render() {
		return (
			<Grid container spacing={8} className="mb-6">
				<Grid item xs={2}>
					<SearchSelect
						disabled={!EmployeesDetailStore.editCardForm}
						field={this.form.$("employeeCategory")}
						items={GlobalStore.CL["employeeCategory"]}
						required
					/>
				</Grid>
				<Grid item xs={2}>
					<XsInput disabled={!EmployeesDetailStore.editCardForm} field={this.form.$("personalNumber")} />
				</Grid>
				<Grid item xs={2}>
					<XsInput disabled={!EmployeesDetailStore.editCardForm} field={this.form.$("cardNumber")} />
				</Grid>
				<Grid item xs={2}>
					<XsDateTimePicker disabled={!EmployeesDetailStore.editCardForm} field={this.form.$("arrivalDate")} />
				</Grid>
				<Grid item xs={2}>
					<XsDateTimePicker disabled={!EmployeesDetailStore.editCardForm} field={this.form.$("departureDate")} />
				</Grid>
				<Grid item xs={2} container alignItems="center" justify="flex-end" spacing={8} className="mt-0">
					{EmployeesDetailStore.editCardForm ? (
						<React.Fragment>
							<Grid item>
								<XsButton
									className="xs-success"
									text={<FormattedMessage id="Common.label.save" />}
									type="submit"
									onClick={this.form.onSubmit}
									icon={<i className="fal fa-check fa-lg" />}
								/>
							</Grid>
							<Grid item>
								<XsButton
									className="xs-danger xs-outline"
									onClick={() => {
										EmployeesDetailStore.editCardForm = false
									}}
									text={<FormattedMessage id="Common.label.cancel" />}
									icon={<i className="fal fa-times fa-lg" />}
								/>
							</Grid>
						</React.Fragment>
					) : (
						<Grid>
							<XsButton
								className="xs-primary xs-outline"
								text={<FormattedMessage id="Common.label.edit" />}
								type="submit"
								onClick={() => (EmployeesDetailStore.editCardForm = true)}
								icon={<i className="fal fa-pencil-alt fa-lg" />}
							/>
						</Grid>
					)}
				</Grid>
			</Grid>
		)
	}
}
