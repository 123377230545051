import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import fields from "./enumDetailFields"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"

import XsButton from "../../../../../../global/ui/xsButton/xsButton"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"

import EnumDetailDialogStore from "../../../../stores/EnumDetailDialogStore"
import EnumeratorListStore from "../../../../stores/EnumeratorListStore"

@injectIntl
@observer
export default class EnumDetailDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {
				EnumDetailDialogStore.save(EnumeratorListStore.enumId, props.intl.formatMessage({id: "Common.label.editEnum"}))
			},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		EnumDetailDialogStore.formDialogRef = this.form
	}

	render() {
		return (
			<Dialog
				id="xsEnumDetailDialog"
				open={EnumDetailDialogStore.isOpen}
				onClose={() => EnumDetailDialogStore.close()}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-500"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-absolute xs-close-btn" onClick={() => EnumDetailDialogStore.close()}>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-info-circle fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.recordDetail" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<Grid container direction="column" spacing={8}>
						<Grid item xs={12}>
							<XsInput field={this.form.$("code")} />
						</Grid>
						<Grid item xs={12}>
							<XsInput field={this.form.$("code_ext")} />
						</Grid>
						<Grid item xs={12}>
							<XsInput field={this.form.$("name_ext")} />
						</Grid>
						<Grid item xs={12}>
							<XsInput field={this.form.$("description")} />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-danger xs-outline"
						onClick={() => {
							EnumDetailDialogStore.close()
						}}
						text={<FormattedMessage id="Common.label.cancel" />}
						icon={<i className="fal fa-times fa-lg" />}
					/>
					<XsButton
						className="xs-success"
						type="submit"
						onClick={(e) => {
							this.form.onSubmit(e)
						}}
						text={<FormattedMessage id="Common.label.save" />}
						icon={<i className="fal fa-file-excel fa-lg" />}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
