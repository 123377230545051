"use strict"
import api from "../actions/api"
import {observable, action} from "mobx"
import {getFilters} from "../../../global/helpers/api"
import {getUserCompanyInfo} from "../../../global/helpers/actions"

class EmployeesListStore {
	@observable employees = []
	@observable orgunits = []

	@action loadEmployees() {
		let company = getUserCompanyInfo()
		api
			.loadEmployees(company.id)
			.call()
			.then((response) => {
				this.employees = response.rows
			})
	}

	@action loadOrgunits(companyId) {
		let company = getUserCompanyInfo()
		const searchRequest = getFilters([`company_id=${isNotEmpty(companyId) ? companyId : company.id}`])
		api
			.loadOrgUnits(searchRequest,"L3")
			.call()
			.then((response) => {
				this.orgunits = response.rows
			})
	}

	@action loadEntityOrgunits(companyId) {
		let company = getUserCompanyInfo()
		const searchRequest = getFilters([`company_id=${companyId}`, `object_owner=${company.object_owner}`])
		api
			.loadEntityOrgUnits(searchRequest)
			.call()
			.then((response) => {
				this.orgunits = response.rows
			})
	}
}
var singleton = new EmployeesListStore()
export default singleton
