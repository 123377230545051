import React from "react"
import {observer} from "mobx-react"
import classnames from "classnames"

import {Button, ClickAwayListener, Grow, Paper, MenuItem, MenuList, Grid} from "@material-ui/core"
import XsButton from "./xsButton"

import "./xsButton.less"

@observer
export default class XsMultiButton extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			open: false
		}
	}

	handleToggle = () => {
		this.setState((state) => ({open: !state.open}))
	}

	handleClose = () => {
		this.setState({open: false})
	}

	render() {
		const {open} = this.state
		const {buttons, className, menuWidth = 175} = this.props

		return (
			isSafe(buttons) &&
			buttons.length > 0 && (
				<React.Fragment>
					{buttons.length == 1 ? (
						<XsButton
							className={classnames(className)}
							onClick={() => buttons[0].onButtonClick()}
							text={buttons[0].label}
							// className={[classes.button, classes.buttonOne].join(" ")}
						/>
					) : (
						<React.Fragment>
							<Grid container wrap="nowrap" alignItems="center" className={classnames("multiButton")}>
								<Grid item className="defaultBtn">
									<XsButton
										onClick={(e) => buttons[0].onButtonClick(e)}
										disabled={isSafe(buttons[0].disabled) ? buttons[0].disabled : false}
										className={
											isSafe(buttons[0].disabled) && buttons[0].disabled
												? "xs-default xs-outline"
												: classnames(className)
										}
										text={buttons[0].label}
										icon={isSafe(buttons[0].icon) ? buttons[0].icon : null}
									/>
								</Grid>
								<Grid item className="xs-relative">
									<Button
										className={
											isSafe(buttons[0].disabled) && buttons[0].disabled
												? "selectBtn xs-default xs-outline"
												: classnames(`selectBtn ${className}`)
										}
										onClick={() => this.handleToggle()}
									>
										<i className={classnames("fas fa-sort-down")}></i>
									</Button>
									{open && (
										<Grow
											in={open}
											style={{width: +menuWidth, position: "absolute", zIndex: 10, top: 36, right: 0}}
											{...(open ? {timeout: 500} : {})}
											// {...TransitionProps}
											// id="menu-list-grow"
											// style={{transformOrigin: placement === "bottom" ? "center bottom" : "center bottom"}}
										>
											<Paper>
												<ClickAwayListener onClickAway={this.handleClose}>
													<MenuList className="xs-multi-list">
														{buttons.map(
															(item, key) =>
																key > 0 && (
																	<MenuItem
																		key={key}
																		className={classnames({
																			"xs-disabled": isSafe(item.disabled) && item.disabled
																		})}
																		onClick={(e) => {
																			if (isNotSafe(item.disabled) || (isSafe(item.disabled) && !item.disabled)) {
																				item.onButtonClick(e), this.setState({open: false})
																			}
																		}}
																	>
																		<div
																			className={classnames("multiMenuItem", {
																				"xs-disabled": isSafe(item.disabled) && item.disabled
																			})}
																			style={{width: +menuWidth - 25}}
																		>
																			{isSafe(item.icon) && <span className="pr-2">{item.icon}</span>}
																			{item.label}
																		</div>
																	</MenuItem>
																)
														)}
													</MenuList>
												</ClickAwayListener>
											</Paper>
										</Grow>
									)}
								</Grid>
							</Grid>
							{/* <Popper
								open={open}
								anchorEl={this.anchorEl}
								transition
								// id="menu-list-grow"
								// placement="bottom-end"
								// disablePortal
								// modifiers={{
								// 	preventOverflow: {
								// 		enabled: true,
								// 		boundariesElement: "disabled"
								// 	}
								// }}
							> */}
							{/* )} */}
							{/* </Popper> */}
						</React.Fragment>
					)}
				</React.Fragment>
			)
		)
	}
}
