import {observable, action} from "mobx"
import moment from "moment"
import api from "../actions/api"

import {getUserCompanyInfo} from "../../../global/helpers/actions"

class MedicalRecordStore {
	@observable medicalDGNData = []

	@observable isFetching = false
	@observable changedFilters = false
	currFilters = {}

	formRef = null

	@action isChangedFilter(formValues) {
		let isChangeDateFrom = compareMomentDate(this.currFilters.date_from, formValues.date_from)
		let isChangeDateTo = compareMomentDate(this.currFilters.date_to, formValues.date_to)

		if (
			isChangeDateFrom ||
			isChangeDateTo ||
			this.currFilters.orgunit != formValues.orgunit ||
			JSON.stringify(this.currFilters.dgn) != JSON.stringify(formValues.dgn)
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action loadMedicalDGN(form) {
		const values = form.values()
		this.currFilters = form.values()

		let req = {
			filters: [
				{
					associated_column: "company_id",
					values: [
						{
							id_value: getUserCompanyInfo().id
						}
					]
				},
				{
					associated_column: "date_from",
					values: [
						{
							id_value: moment(values.date_from).format("YYYY-MM-DD")
						}
					]
				},
				{
					associated_column: "date_to",
					values: [
						{
							id_value: moment(values.date_to).format("YYYY-MM-DD")
						}
					]
				}
			]
		}

		if (isNotEmpty(values.orgunit)) {
			req.filters.push({
				associated_column: "provider_id",
				values: [
					{
						id_value: values.orgunit
					}
				]
			})
		}

		if (isSafe(values.dgn) && values.dgn.length) {
			let dgnValues = []

			values.dgn.forEach((id) => {
				dgnValues.push({
					id_value: id
				})
			})

			req.filters.push({
				associated_column: "diagnosis_id",
				values: dgnValues
			})
		}

		this.isFetching = true

		api
			.loadMedicalDGNReport(req)
			.call()
			.then((res) => {
				if (isSafe(res)) {
					this.medicalDGNData = res.rows
				}
				this.isFetching = false
			})
			.catch(() => {
				this.isFetching = false
			})
	}
}

var singleton = new MedicalRecordStore()
export default singleton
