export default {
	load: {
		storeKey: "employeeInfoSaveForm",
		params: ["employeeId"],
		type: "Entity.Data.Person"
	},
	scheme: {
		_id: {
			relation: "@entityId"
		},
		title_before: {
			object: {
				descriptor: {
					_ref: true,
					_type: "Entity.CL.Title"
				},
				scheme: {
					_id: {
						field: "title_before"
					}
				}
			}
		},
		first_name: {
			field: "firstname"
		},
		last_name: {
			field: "lastname"
		},
		title_after: {
			object: {
				descriptor: {
					_ref: true,
					_type: "Entity.CL.Title"
				},
				scheme: {
					_id: {
						field: "title_after"
					}
				}
			}
		},
		birth_name: {
			field: "birthname"
		},
		identifier: {
			field: "identifier"
		},
		birth_date: {
			field: "dateOfBirth"
		},
		gender: {
			object: {
				descriptor: {
					// _ref: false,
					_type: "Entity.CL.Gender"
				},
				scheme: {
					_id: {
						field: "gender"
					}
				}
			}
		}
	}
}
