import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import {withStyles} from "@material-ui/core/styles"
import {TextField} from "@material-ui/core"
import fields from "./companiesFormFields"
import validatorjs from "validatorjs"
import MobxReactForm from "mobx-react-form"
import InputAdornment from "@material-ui/core/InputAdornment"
import XsTable from "../../../../../../global/ui/xsTable/xsTable"
import CodeListStore from "../../../../stores/CodeListStore"
import UIStore from "../../../../stores/UIStore"
import AddIcon from "@material-ui/icons/Add"
import XsButton from "../../../../../../global/ui/xsButton/xsButton"
import CompaniesForm from "./companiesForm"
import XsLoading from "../../../../../../global/ui/xsLoading/xsLoading"

const styles = () => ({
	searchIcon: {
		marginTop: "14px"
	}
})

@withStyles(styles)
@injectIntl
@observer
export default class CompaniesContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			searchValue: "",
			searchValueICO: ""
		}
		this.formRef = null

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				CodeListStore.saveCompany(form)
			},
			onError() {
				UIStore.isFormSaving = false
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks})
		CodeListStore.loadCompanies()
	}

	onHandleRowClick = (dataRow) => {
		CodeListStore.companiesShowForm = true
		CodeListStore.companiesFormCompanyId = dataRow.companyId
	}

	onHandleNewButtonClick = () => {
		this.form.reset()
		CodeListStore.companiesShowForm = true
	}

	render() {
		return (
			<div className="xs-companies-container">
				{UIStore.isFormSaving && <XsLoading overlay={true} />}
				{!CodeListStore.companiesShowForm && (
					<div>
						<Grid container direction="row" spacing={8}>
							<Grid item xs={2}>
								<TextField
									id="PatientListSearchInput"
									placeholder={this.props.intl.formatMessage({id: "Common.searchDropdown.search"})}
									className="xs-search-component"
									inputProps={{
										className: "xs-search-input"
									}}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start" className="xs-search-adornment">
												<i className="fal fa-search" />
											</InputAdornment>
										)
									}}
									value={this.state.searchValue}
									onChange={(e) =>
										this.setState({searchValue: e.target.value}, () => {
											CodeListStore.loadCompanies(this.state.searchValue, this.state.searchValueICO)
										})
									}
									autoComplete="off"
								/>
							</Grid>
							<Grid item xs={2}>
								<TextField
									id="PatientListSearchInputICO"
									placeholder={this.props.intl.formatMessage({id: "Common.label.ico"})}
									className="xs-search-component"
									inputProps={{
										className: "xs-search-input"
									}}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start" className="xs-search-adornment">
												<i className="fal fa-search" />
											</InputAdornment>
										)
									}}
									value={this.state.searchValueICO}
									onChange={(e) =>
										this.setState({searchValueICO: e.target.value}, () => {
											CodeListStore.loadCompanies(this.state.searchValue, this.state.searchValueICO)
										})
									}
									autoComplete="off"
								/>
							</Grid>
							<Grid item xs={8} className="xs-actions">
								<XsButton
									className="xs-success"
									text={<FormattedMessage id="Common.label.newCompany" />}
									icon={<AddIcon />}
									onClick={this.onHandleNewButtonClick}
								/>
							</Grid>
							<XsTable
								config={{
									columnDefs: {
										ico: {
											title: <FormattedMessage id="Common.label.ico" />,
											type: "number",
											design: {
												width: "150px"
											},
											sortable: true
										},
										name: {
											title: <FormattedMessage id="Common.label.name" />,
											type: "string",
											design: {
												width: "150px"
											},
											sortable: true
										},
										short_name: {
											title: <FormattedMessage id="Common.label.shortName" />,
											type: "string",
											design: {
												width: "150px"
											},
											sortable: true
										},
										DIC: {
											title: <FormattedMessage id="Common.label.dic" />,
											type: "string",
											design: {
												width: "150px"
											},
											sortable: true
										},
										ICDPH: {
											title: <FormattedMessage id="Common.label.icdph" />,
											type: "string",
											sortable: true
										}
									},
									options: {
										showCursor: true,
										selectRow: true,
										defaultSort: {columnName: "item_order", sortDirection: "asc"},
										onRowClick: (dataRow) => {
											this.onHandleRowClick(dataRow)
										},
										mapper: (dataRow) => {
											return {
												companyId: dataRow.entity._id,
												ico: dataRow.entity.identifier,
												name: dataRow.entity.name,
												short_name: dataRow.entity.short_name,
												DIC: dataRow.entity.tax_identification_number,
												ICDPH: dataRow.entity.vat_identification_number
											}
										}
									},
									dataSource: CodeListStore.companies
								}}
							/>
						</Grid>
					</div>
				)}
				{CodeListStore.companiesShowForm && (
					<div>
						<CompaniesForm form={this.form} />
					</div>
				)}
			</div>
		)
	}
}
