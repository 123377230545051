import React from "react"
import {observer} from "mobx-react"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import "./xsTabs.less"

@observer
export default class XsTabs extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const {tabs, scrollButtons, className, background, mainClass, headerClassName} = this.props

		let addClassName = isSafe(className) ? className : ""
		let addBackground = isSafe(background) ? {backgroundColor: background} : {}

		return (
			<div className={`xs-tab-base ${mainClass}`}>
				<Tabs
					value={this.props.value ? this.props.value : false}
					onChange={(event, value) => this.props.onChangeTab(value)}
					className={headerClassName ? headerClassName : null}
					scrollable
					scrollButtons={scrollButtons ? "on" : "off"}
				>
					{isSafe(tabs) &&
						tabs.map((tab, idx) => {
							if (isSafe(tab)) {
								let isActive =
									this.props.value === tab.value ? {backgroundColor: background, borderBottomColor: background} : {}
								let activeClass = this.props.value === tab.value ? "xs-active" : ""
								return (
									<Tab
										key={idx}
										className={`xs-tab ${addClassName} ${activeClass}`}
										color="primary"
										label={tab.label}
										value={tab.value}
										style={isActive}
									/>
								)
							}
						})}
				</Tabs>
				{isSafe(tabs) &&
					tabs.map((tab, idx) => {
						if (isSafe(tab)) {
							return (
								this.props.value === tab.value && (
									<div style={addBackground} className="xs-tab-content" key={idx}>
										{tab.content}
									</div>
								)
							)
						}
					})}
			</div>
		)
	}
}
