import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "preventive_examination_adults"
				},
				datumPoslednejPP: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.datumPoslednejPP" />,
					value: moment(),
					type: "date"
				},

				anamPopisTazkosti: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.anamPopisTazkosti" />,
					value: ""
				},
				anamPrekonaneChoroby: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.anamPrekonaneChoroby" />,
					value: ""
				},
				anamSkodliveNavyky: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.anamSkodliveNavyky" />,
					value: ""
				},
				anamKontrolaOckovania: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.anamKontrolaOckovania" />,
					value: ""
				},
				anamRA: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.anamRA" />,
					value: ""
				},

				ovHabitus: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovHabitus" />,
					value: ""
				},
				ovHlava: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovHlava" />,
					value: ""
				},
				ovKrk: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovKrk" />,
					value: ""
				},
				ovHrudnik: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovHrudnik" />,
					value: ""
				},
				ovPulmo: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovPulmo" />,
					value: ""
				},
				ovCor: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovCor" />,
					value: ""
				},
				ovAbdomen: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovAbdomen" />,
					value: ""
				},
				ovUrogenitalnyAparat: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovUrogenitalnyAparat" />,
					value: ""
				},
				ovPohybovyAparat: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovPohybovyAparat" />,
					value: ""
				},
				ovVyska: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovVyska" />,
					value: ""
				},
				ovHmotnost: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovHmotnost" />,
					value: ""
				},
				ovTeplota: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovTeplota" />,
					value: ""
				},
				ovFW: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovFW" />,
					value: ""
				},
				ovTK: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovTK" />,
					value: ""
				},
				ovPF: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovPF" />,
					value: ""
				},
				ovMocChem: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovMocChem" />,
					value: ""
				},
				ovEKG: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovEKG" />,
					value: ""
				},
				ovCelkovyCholesterol: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovCelkovyCholesterol" />,
					value: ""
				},
				ovTriaglyceridy: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovTriaglyceridy" />,
					value: ""
				},
				ovVysetrenieStolice: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovVysetrenieStolice" />,
					value: ""
				},
				ovInePotrebneVysetrenie: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovInePotrebneVysetrenie" />,
					value: ""
				},

				zaverPP: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.ovInePotrebneVysetrenie" />,
					value: ""
				},
				odporucenie: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.odporucenie" />,
					value: ""
				},

				miesto: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.miesto" />,
					value: ""
				},
				datum: {
					label: "dňa",
					value: moment(),
					type: "date"
				}
			}
		}
	}
}
