import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {withStyles} from "@material-ui/core/styles"

// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import DailyReportListStore from "../../../stores/DailyReportListStore"
import WarningStore from "../../../../../global/store/WarningStore"
import TableStore from "../../../stores/TableStore"

import ExportDailyReportToXlsDialog from "./exportDailyReportToXlsDialog"

// definitions of form fields
import fields from "./dailyReportListFields"

import moment from "moment"
import {getUserCompanyInfo, getUserPersonnelOrgUnits} from "../../../../../global/helpers/actions"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import RegistersCacheStore from "../../../../../global/store/RegistersCacheStore"
import DiagnosesSearch from "./DiagnosesFilter"
import GlobalStore from "../../../../../global/store/GlobalStore"
import api from "../../../actions/api"

const styles = () => ({
	searchIcon: {
		marginTop: "14px"
	}
})

@withStyles(styles)
@injectIntl
@observer
export default class DailyReportList extends React.Component {
	constructor(props) {
		super(props)
		DailyReportListStore.isFetching = false
		// DailyReportListStore.loadPatientsOverview()

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				if (form.values().datefrom || form.values().dateto) {
					if (
						TableStore.tables.hasOwnProperty("dailyReportList") &&
						TableStore.tables["dailyReportList"].hasOwnProperty("index")
					) {
						delete TableStore.tables["dailyReportList"]["index"]
					}

					DailyReportListStore.loadPatientsOverview(form)
				} else {
					WarningStore.open(props.intl.formatMessage({id: "Common.label.warningDateFromDateTo"}))
				}
			},
			onError() {}
		}

		if (isEmpty(DailyReportListStore.formRef)) {
			DailyReportListStore.formRef = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		}

		this.form = DailyReportListStore.formRef

		// DailyReportListStore.diagnosisFilter = []
		// DailyReportListStore.diagnoses_patientListFilter = null
		// DailyReportListStore.currDiagnosis = DailyReportListStore.diagnosisFilter

		DailyReportListStore.loadPatientsOverview(this.form)
	}

	componentWillUnmount() {
		DailyReportListStore.patientsOverview = []
	}

	onHandleRowClick = (dataRow) => {
		GlobalStore.changeOrgUnitAndRouteToPatientDetail(this.form.$("orgunit").value, dataRow.person_id)
	}

	render() {
		DailyReportListStore.isChangedFilter(this.form.values())
		const dateTimeFormat = this.props.intl.formatMessage({id: "Application.moment.dateformat"})

		return (
			<Grid container className="xs-dailyReport-list">
				<Grid item xs={12}>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}>
							<SearchSelect white field={this.form.$("orgunit")} items={getUserPersonnelOrgUnits()} required />
						</Grid>
						<Grid item xs={1}>
							<XsDateTimePicker white field={this.form.$("datefrom")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={1}>
							<XsDateTimePicker white field={this.form.$("dateto")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={1}>
							<SearchSelect white field={this.form.$("insurer")} items={RegistersCacheStore.insurersRegister} />
						</Grid>
						<Grid item xs={2} className="pb-0">
							<DiagnosesSearch />
						</Grid>
						<Grid item xs={1}>
							<SearchSelect white field={this.form.$("recordTypes")} items={GlobalStore.CL["recordTypes"]} />
						</Grid>
						<Grid item xs={2}>
							<XsAutocomplete
								white
								field={this.form.$("doctor")}
								minLengthForSearch="3"
								api={api.loadPersonnelAndProvider}
								clearText
								addSpecialFilter={() => {
									return [
										{
											associated_column: "has_contract",
											predicate: "=",
											values: [
												{
													id_value: true
												}
											]
										},
										{
											associated_column: "company_id",
											predicate: "=",
											values: [
												{
													id_value: getUserCompanyInfo().id
												}
											]
										}
									]
								}}
								filterValue="personnel_id"
								inputRenderer={(obj, menuItem) =>
									(isNotEmpty(obj.preferred_provider_code) && obj.preferred_provider_code.charAt(9) == "2" && menuItem
										? "<b style=color:#00A787>"
										: "") +
									(obj.doctor_code ? obj.doctor_code + " / " : "") +
									obj.full_name +
									" / " +
									(obj.cl_expertise_code_ext ? obj.cl_expertise_code_ext + " " : "") +
									obj.cl_expertise_name_ext +
									(isNotEmpty(obj.provider_code) ? " / " + obj.provider_code : "") +
									(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem ? "</b>" : "")
								}
								saveValue={(obj) => obj.personnel_id}
								// postAction={(obj) => {
								// this.form.$("expertise_id").value = obj.cl_expertise_id
								// this.form.$("personnel_id").value = obj.personnel_id
								// this.form.$("provider_id").value = obj.provider_id
								// }}
							/>
						</Grid>

						<Grid item xs={1} className="pb-0">
							<XsIconButton
								rect
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								className="xs-default xs-outline"
								onClick={this.form.onSubmit}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
						<Grid item xs={1}>
							<Grid container justify="flex-end" spacing={8}>
								<Grid item>
									<XsButton
										disabled={
											isEmpty(this.form.$("datefrom").value) && isEmpty(this.form.$("dateto").value) ? true : false
										}
										className={
											isEmpty(this.form.$("datefrom").value) && isEmpty(this.form.$("dateto").value)
												? "xs-default xs-outline"
												: "xs-primary"
										}
										text={<FormattedMessage id="Common.label.export" />}
										icon={<i className="fal fa-file-excel fa-lg" />}
										onClick={() => {
											DailyReportListStore.open()
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{DailyReportListStore.isFetching ? (
						<XsLoading />
					) : DailyReportListStore.changedFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i className="far fa-search fa-lg mr-3 pointer" onClick={this.form.onSubmit}></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTable
							setRef={(table) => (DailyReportListStore.dailyReportListRef = table)}
							config={{
								columnDefs: {
									stamp: {
										title: <FormattedMessage id="Common.label.date" />,
										type: "datetime",
										design: {
											width: "150px",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format(dateTimeFormat) : ""
												}
											}
										},
										sortable: true
									},
									full_name: {
										title: <FormattedMessage id="Capitation.List.Patient" />,
										type: "string",
										design: {
											width: "250px",
											body: {
												className: "blueDark xs-bold"
											}
										},
										sortable: true
									},
									identifier: {
										title: <FormattedMessage id="Patient.form.patientinfo.identifier" />,
										type: "string",
										design: {
											width: "120px"
										},
										sortable: true
									},
									insurer: {
										title: <FormattedMessage id="Common.label.insurer" />,
										type: "string",
										design: {
											width: "100px"
										},
										sortable: true
									},
									dg: {
										title: <FormattedMessage id="Patient.form.patientrecord.diagnosis" />,
										type: "string",
										design: {
											width: "250px"
										},
										sortable: true
									},
									sending_doctor: {
										title: <FormattedMessage id="Common.label.doctor" />,
										design: {
											width: "250px"
										},
										type: "string"
									},
									record_type: {
										title: <FormattedMessage id="Common.list.footer.countText" />,
										// design: {
										// 	width: "100px"
										// },
										type: "string"
									},
									bill_count: {
										title: <FormattedMessage id="Common.label.deliveriesCount" />,
										// design: {
										// 	width: "50px"
										// },
										type: "string"
									}
								},
								options: {
									showCursor: true,
									name: "dailyReportList",
									// selectRow: true,
									defaultSort: {columnName: "stamp", sortDirection: "asc"},
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										return {
											stamp: isSafe(dataRow.date) ? dataRow.date : "",
											full_name: isSafe(dataRow.person_full_name) ? dataRow.person_full_name : "",
											identifier: isSafe(dataRow.person_identifier) ? dataRow.person_identifier : "",
											insurer: isSafe(dataRow.insurer_code_ext)
												? `${dataRow.insurer_code_ext} - ${dataRow.insurer_name_ext}`
												: "",
											dg: isSafe(dataRow.diagnosis_codes) ? dataRow.diagnosis_codes : "",
											sending_doctor: isSafe(dataRow.doc_full_names) ? dataRow.doc_full_names : "",
											person_id: dataRow.person_id,
											record_type: dataRow.record_types,
											bill_count: dataRow.bill_count
										}
									}
								},
								dataSource: DailyReportListStore.patientsOverview
							}}
						/>
					)}
				</Grid>
				<ExportDailyReportToXlsDialog />
			</Grid>
		)
	}
}
