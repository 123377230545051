"use strict"

import {observable, action} from "mobx"
// import config from "../../../global/config/settings"
// import WarningStore from "../../../global/store/WarningStore"

class EHealthProgressDialogStore {
	@observable isOpen = false
	@observable isFetching = false
	@observable processes = null

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}
}

var singleton = new EHealthProgressDialogStore()
export default singleton
