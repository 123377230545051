//@flow
import React from "react"
import {observer} from "mobx-react"

import PatientPrescriptionStore from "../../../stores/PatientPrescriptionStore"
import XsExpansionPanel from "../../../../../global/ui/xsExpansionPanel/xsExpansionPanel"
import renderHTML from "react-render-html"
import UIStore from "../../../stores/UIStore"

@observer
export default class PrescriptionLimitations extends React.Component {
	constructor(props) {
		super(props)

		PatientPrescriptionStore.prescriptionLimitations[this.props.formKey] = []
	}

	render() {
		let limitations =
			isSafe(PatientPrescriptionStore.prescriptionLimitations) &&
			isSafe(PatientPrescriptionStore.prescriptionLimitations[this.props.formKey])
				? PatientPrescriptionStore.prescriptionLimitations[this.props.formKey]
				: []

		return (
			limitations.length > 0 && (
				<div className="prescriptionLimitations pl-4 pb-4">
					{limitations.length == 1 && isSafe(limitations[0]) && limitations[0].length <= 350 ? (
						<div className="no-expansion">
							<div className="content">
								{renderHTML(
									`<b>Indikačné obmedzenia:</b> ${
										isNotEmpty(limitations[0]) ? limitations[0].replace(/<br\s*\/?>/gm, " ") : ""
									}`
								)}
							</div>
						</div>
					) : (
						<XsExpansionPanel maxHeight={20} glOpen={UIStore.isOpenAllRecordsInEHROverview}>
							{limitations.map((text, index) => {
								let replaceText = isNotEmpty(text) ? text.replace(/<br\s*\/?>/gm, " ") : ""

								return (
									<div key={index} className="expansion">
										<div className="content">{renderHTML(`<b>Indikačné obmedzenia:</b> ${replaceText}`)}</div>
									</div>
								)
							})}
						</XsExpansionPanel>
					)}
				</div>
			)
		)
	}
}
