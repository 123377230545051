import api from "../../../modules/ambulance/actions/api"
import {
	getSelectedOrgUnitID,
	getSelectedOrgUnit,
	printReport,
	getUserDoctorInfo,
	getUserCompanyInfo,
	getUserPersonnelID,
	getUser,
	getGWServerRole
} from "../../../global/helpers/actions"
import {observable, action} from "mobx"
import DataStore from "../stores/DataStore"
import DDaction from "../actions/patientCard"
import moment from "moment"
import {loadDataToFormBySchemeSync} from "../../../global/helpers/bindings"
import bindings from "../components/patientDetail/patientMedicationForm/patientMedicationBindings"
import settings from "../../../global/config/settings"
import {MEDICATIONDEFAULTS} from "../../../global/config/constants"
import StorageEx from "../../../global/storage/storageEx"
import WarningStore from "../../../global/store/WarningStore"
import GlobalStore from "../../../global/store/GlobalStore"

class PatientMedicationStore {
	// @observable medicationID = null
	// @observable recordLoaded = false
	@observable represcribeID = null
	// @observable represcribeLoaded = false

	@observable validationSnackbar = null

	//flags
	@observable represcribeFlag = false

	fieldsWithChangingDefaults = [
		"drugs",
		"productCode",
		"diagnosis",
		"unit",
		"packs",
		"medicationRoute",
		"expiration",
		"expirationPeriod",
		"typeOfVaccination",
		"numberOfDoses",
		"ordinanceOrder",
		"medicationLocation",
		"batchNumber",
		"vaccinationType"
	]

	medicationRef = null
	ids = []

	@action represcribe(medications) {
		this.ids = medications.map((i) => {
			return i.record_id
		})
		//znovupredpisat pri uz otvorenom formulari
		if (isSafe(this.medicationRef)) {
			let ids = this.ids.slice()
			this.medicationRef.clearAllReqeust(() => {
				this.medicationRef.addForm(medications.length - 1, ids)
			})
			this.ids = []
		}
		DataStore.clearCheckedEHR()
	}

	//znovupredpisat pri uz zatvorenom formulari teda caka kym sa otvori ziska ref a naplni formulare
	@action represcribeIdsToForms() {
		if (isSafe(this.ids) && this.ids.length) {
			let ids = this.ids.slice()
			this.medicationRef.clearAllReqeust(() => {
				this.medicationRef.addForm(ids.length - 1, ids)
			})
		}
		this.ids = []
	}

	@action isGoingToReprescribe() {
		if (isSafe(this.ids) && this.ids.length) {
			return true
		} else {
			return false
		}
	}

	@action storno(id, reason) {
		let requestJSON = {
			_id: id,
			active: false,
			reason: reason,
			_type: "EHR.Data.Medication"
		}

		api
			.stornoRecords(requestJSON)
			.call()
			.then((res) => {
				if (
					isSafe(res) &&
					isSafe(res.ixs_signer_data) &&
					isSafe(res.ixs_signer_data.payload) &&
					isNotEmpty(res.ixs_signer_data.payload.text)
				) {
					const selectedOrgUnit = getSelectedOrgUnit()
					const user = getUser()
					const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
					const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
					const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
					const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
					const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

					const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
						? DataStore.patientDTO.get("patient").validInfo.client
						: null
					const JRUZIDObj = isSafe(patientClientInfo.identifiers)
						? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
						: null

					fetch(`${settings.GW_BASE_URL}/WriteEHRExtract`, {
						method: "POST",
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json"
						},
						body: JSON.stringify({
							ClassName: res.ixs_signer_data.eh_class,
							ExtId: res.record_external_id,
							EHRExtract: res.ixs_signer_data.payload.text,
							Environment: getGWServerRole(),
							TimeCommitted: res.actual_time,
							AmbeePatientId: DataStore.patientDTO.get("patientID"),
							PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
							PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
							AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
							AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
							AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
							AmbeePersonnelId: getUserPersonnelID(),
							OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
							PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
							PersonnelExpertiseJRUZVersion: specVer,
							PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
							RecordSignerData: isSafe(res.record_signer_data)
								? res.record_signer_data.map((x) => {
										return {
											Ref: x._ref,
											Type: x._type,
											RecordClass: x.record_class,
											RecordExternalId: x.record_external_id,
											RecordId: x.record_id,
											QueueId: x.queue_id
										}
								  })
								: null
						})
					})
						.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
						.then((res) => {
							// api.setSent(res.ixs_signer_data.ext_id).call()
							if (isSafe(res) && isNotEmpty(res.ErrorMessage)) {
								WarningStore.open(res.ErrorMessage)
							}
						})
						.catch(() => {})
				}

				const providerID = getSelectedOrgUnitID()
				DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
					DataStore.setPatientEHR(overview)

					let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
					if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
						DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
					}
				})
			})
	}

	@action async loadDefaultDiagnosis(form) {
		const providerId = getSelectedOrgUnitID()
		const patientId = DataStore.patientDTO.get("patientID")
		const filter = {
			filters: [
				{
					associated_column: "provider_id",
					values: [
						{
							id_value: providerId
						}
					]
				},
				{
					associated_column: "patient_id",
					values: [
						{
							id_value: patientId
						}
					]
				},
				{
					associated_column: "type_id",
					values: [
						{
							id_value: "EHR.Data.Ambulance"
						}
					]
				}
			],
			time_from: moment
				.utc()
				.startOf("day")
				.format(),
			time_to: moment
				.utc()
				.endOf("day")
				.format()
		}

		const response = await api.loadEHROverview(filter).call()
		if (isSafe(response) && isSafe(response.rows) && isSafe(response.rows[0]) && isSafe(response.rows[0].diagnosis)) {
			form.$("diagnosis").value = response.rows[0].diagnosis
		}
	}

	@action async loadFormData(form, id) {
		await loadDataToFormBySchemeSync(form, bindings, {prescriptionId: id}, (data) => {
			form.$("medicationType").set("value", data.medication_type)
			form.$("drugs").set("value", data.product_id)
			form.$("unit").set("value", data.dose_unit)
			form.$("medicationRoute").set("value", data.route)
			form.$("batchNumber").set("value", "")
			form.$("expiration").set("value", "")
			form.$("barcode").set("value", "")
			form
				.$("vaccinationType")
				.set(
					"value",
					isSafe(data.vaccination_type) && isSafe(data.vaccination_type._id) ? data.vaccination_type._id : ""
				)
			form
				.$("medicationLocation")
				.set("value", isSafe(data.location) && isSafe(data.location._id) ? data.location._id : "")
			// form.$("expiration").set("rules", form.$("medicationType").value === "ADMVACC" ? "required" : "")
			form.$("typeOfVaccination").set("value", isSafe(data.vaccination_malady) ? data.vaccination_malady._id : "")
			form.$("numberOfDoses").set("value", data.repeats_number)
			form.$("note").set("value", data.note)
		})
	}

	@action printVaccination(medId) {
		api
			.loadPrescription(medId)
			.call()
			.then((med) => {
				const pacInfo = DataStore.patientDTO.get("patient")
				const companyInfo = getUserCompanyInfo()
				const doctorInfo = getUserDoctorInfo()
				const orgUnit = getSelectedOrgUnit()

				const pacAddress = pacInfo.validInfo.address
				let printAddress = ""

				if (pacInfo.validInfo && pacAddress) {
					printAddress += `${pacAddress.city ? `${pacAddress.city}, ` : ""}`
					printAddress += `${
						pacAddress.street
							? `${pacAddress.street}${pacAddress.house_number ? ` ${pacAddress.house_number}` : ""},`
							: ""
					}`
					printAddress += ` ${pacAddress.zip}`
				}

				let printValues = {
					name: `${pacInfo.full_name}`,
					identifier: pacInfo.identifier,
					address: pacInfo.validInfo && pacAddress ? printAddress : "",
					providertitle: "",
					doctortitle: ""
				}

				//tlaci sa nase interne tlacivo
				printValues["print_internal"] = "initial"
				printValues["print_mzsr"] = "none"
				printValues["breakpage"] = "none"
				if (isSafe(orgUnit.params) && orgUnit.params.length) {
					const ext = orgUnit.params.find((x) => x.param_type == "EHR_VACC_PRINT_TYPE")

					if (isSafe(ext)) {
						if (ext.value == "both") {
							printValues["print_internal"] = "initial"
							printValues["print_mzsr"] = "initial"
							printValues["breakpage"] = "initial"
						}
						if (ext.value == "ext") {
							printValues["print_internal"] = "none"
							printValues["print_mzsr"] = "initial"
						}
					}
				}

				if (doctorInfo) {
					printValues.doctortitle = doctorInfo.name
					printValues["doctor_name"] = doctorInfo.name
					printValues.doctortitle += `, ${doctorInfo.code}`
					printValues["doctor_code"] = doctorInfo.code
				}

				if (companyInfo) {
					printValues.providertitle = companyInfo.name
					printValues["company_name"] = companyInfo.name
				}

				//OrgUnit
				if (orgUnit) {
					const primaryAddress = doctorInfo.address
					const businessContact = doctorInfo.contact
					const providerCode = orgUnit.identifiers.find((i) => i.type === "PROVIDERCODE")
					printValues.providertitle += `, ${orgUnit.name_ext}`
					printValues["orgunit_name"] = orgUnit.name_ext
					const pAddress =
						isSafe(primaryAddress) && isNotEmpty(primaryAddress.full_address) ? primaryAddress.full_address : ""
					// printValues.providertitle += `, ${pAddress}`
					printValues["p_address"] = pAddress

					printValues["print_both"] = "inherit" //tlaci oba tlaciva
					if (isSafe(orgUnit.params) && orgUnit.params.length) {
						const ext = orgUnit.params.find((x) => x.param_type == "EHR_VACC_PRINT_TYPE" && x.value == "ext")
						if (isSafe(ext)) {
							printValues["print_both"] = "none" //iba mzsr sa tlaci
						}
					}

					if (isSafe(orgUnit.addresses) && orgUnit.addresses.length) {
						const contactAddress = orgUnit.addresses.find((x) => x.type._id == "CA")

						if (isSafe(contactAddress)) {
							printValues["contactAddress"] = `<p>${isSafe(contactAddress.street) ? contactAddress.street : ""} ${
								isSafe(contactAddress.house_number) ? contactAddress.house_number : ""
							}</p><p>${isSafe(contactAddress.zip) ? contactAddress.zip : ""} ${
								isSafe(contactAddress.city) ? contactAddress.city : ""
							}</p>`
						}
					}

					if (businessContact) {
						// printValues.providertitle +=
						// 	isSafe(businessContact) && isNotEmpty(businessContact.email) ? `, ${businessContact.email}` : ""
						printValues["bussiness_email"] =
							isSafe(businessContact) && isNotEmpty(businessContact.email) ? `<b>E:</b> ${businessContact.email}` : ""
						printValues["web_page"] = isSafe(businessContact.web_page) ? `<b>W:</b> ${businessContact.web_page}` : ""
						printValues["orgunitcontact"] = `${businessContact.mobile ? businessContact.mobile : ""} ${
							businessContact.phone ? businessContact.phone : ""
						}`
						printValues["bussiness_mobile"] =
							isSafe(businessContact) && isNotEmpty(businessContact.mobile) ? `<b>T:</b> ${businessContact.mobile}` : ""
					}
					const codeP = isSafe(providerCode) && isNotEmpty(providerCode.value) ? providerCode.value : ""
					printValues.doctortitle += `, ${codeP}`
					printValues["code_p"] = codeP
				}

				//Insurer
				if (pacInfo.validInfo && pacInfo.validInfo.insurance) {
					printValues[
						"insurer"
					] = `${pacInfo.validInfo.insurance.insurer.code_ext} - ${pacInfo.validInfo.insurance.insurer.name_ext}`
				}

				printValues["diagnosis"] = `${med.diagnosis_code_ext} ${med.diagnosis_name_ext}`
				printValues["registrator_name_ext"] = isSafe(med.registrator_name_ext) ? med.registrator_name_ext : ""
				printValues["productcode"] = isSafe(med.product_code) ? med.product_code : ""
				printValues["productname"] = isSafe(med.product_name) ? med.product_name : ""

				printValues["batchnumber"] = isSafe(med.batch_number) ? med.batch_number : ""
				printValues["administered_at"] = isNotEmpty(med.administered_at)
					? moment(med.administered_at).format("DD.MM.YYYY HH:mm")
					: ""

				if (isNotEmpty(med.dose_amount) && isNotEmpty(med.route_name)) {
					const unit = isSafe(med.dose_unit) ? med.dose_unit : ""
					const amount = `${med.dose_amount}`.includes(".") ? `${med.dose_amount}`.replace(".", ",") : med.dose_amount
					printValues["amount_and_route_vaccine"] = `${amount} ${unit} - ${med.route_name}`
				}

				printValues["ordinance_order"] = isSafe(med.ordinance_order)
					? `${med.ordinance_order}/${med.repeats_number}`
					: ""

				printValues["show_SMS_sentence"] = med.ordinance_order == med.repeats_number ? "none" : "initial"

				printValues["qr_code_text"] = `Meno (Name): ${pacInfo.full_name} \n`
				printValues["qr_code_text"] += `Dátum narodenia (Date of Birth): ${
					isNotEmpty(pacInfo.birth_date) ? moment(pacInfo.birth_date, "YYYY-MM-DD").format("DD.MM.YYYY") : ""
				} \n`
				printValues["birth_date"] = isNotEmpty(pacInfo.birth_date)
					? `, ${moment(pacInfo.birth_date, "YYYY-MM-DD").format("DD.MM.YYYY")}`
					: ""

				printValues["qr_code_text"] += `\n`
				printValues["qr_code_text"] += `Druh očkovania (Vaccine type): ${med.vaccination_malady_name_ext} \n`
				printValues["qr_code_text"] += `\n`
				printValues["qr_code_text"] += `Dávka (Dose): ${med.ordinance_order} / ${med.repeats_number}  \n`
				printValues["qr_code_text"] += `ŠUKL kód (Vaccine code): ${med.product_code} \n`
				printValues["qr_code_text"] += `Názov (Vaccine name): ${med.product_name} \n`
				printValues["qr_code_text"] += `Šarža (Batch no.): ${med.batch_number} \n`
				printValues["qr_code_text"] += `Dátum (Date): ${
					isNotEmpty(med.administered_at) ? moment(med.administered_at).format("DD.MM.YYYY HH:mm") : ""
				} \n`
				printValues["qr_code_text"] += `Štát (Country): Slovakia`

				printReport("vaccination_record", printValues)
				// printReport("vaccination_document", printValues)
			})
	}

	@action setFormDefaults(form) {
		const formVals = form.values()
		let newDefaults = {}
		this.fieldsWithChangingDefaults.forEach((fieldName) => {
			if (fieldName == "expiration") {
				newDefaults[fieldName] = isNotEmpty(formVals[fieldName]) ? moment(formVals[fieldName]).format() : ""
			} else {
				newDefaults[fieldName] = formVals[fieldName]
			}
		})

		StorageEx.updateByKey(MEDICATIONDEFAULTS, newDefaults)
	}

	@action loadFormDefaultsFromStorage(form) {
		const storageDefData = StorageEx.getByKey(MEDICATIONDEFAULTS)
		if (isNotEmpty(storageDefData)) {
			const defData = JSON.parse(storageDefData)
			if (isSafe(defData)) {
				this.fieldsWithChangingDefaults.forEach((fieldName) => {
					form.$(fieldName).value = defData[fieldName]
				})
				this.checkLastDose(form)
			}
		}
	}

	checkLastDose = (form) => {
		if (form.$("numberOfDoses").value == form.$("ordinanceOrder").value) {
			form.$("lastDose").value = true
		} else {
			form.$("lastDose").value = false
		}
	}

	checkModernaThirdDose = (form) => {
		const formVals = form.values()
		if (
			formVals.drugCodeExt == "6261D" &&
			formVals.numberOfDoses == 3 &&
			formVals.ordinanceOrder == 3 &&
			formVals.vaccinationType == "PREOC"
		) {
			form.$("packs").value = 0.25
		} else if (formVals.drugCodeExt == "6261D") {
			form.$("packs").value = 0.5
		}
	}

	@action async loadVaccDefaults(form) {
		if (isNotEmpty(DataStore.patientDTO.get("patientID"))) {
			let req = {
				clientId: DataStore.patientDTO.get("patientID"),
				providerId: getSelectedOrgUnitID()
			}

			const eventIdFromCalendar = DataStore.patientDTO.get("eventIdFromCalendar")

			if (isNotEmpty(eventIdFromCalendar)) {
				req["eventId"] = eventIdFromCalendar
			}

			let response
			try {
				response = await api.loadMedicationVaccDefaults(req).call()
			} catch (e) {
				response = {}
			}

			if (isNotEmpty(response["medication_id"])) {
				form.$("drugs").value = response["medication_id"]
			}

			if (isNotEmpty(response["mnozstvo_podanej_latky"])) {
				form.$("packs").value = response["mnozstvo_podanej_latky"]
			}

			if (isNotEmpty(response["jednotka_podanej_latky"])) {
				form.$("unit").value = response["jednotka_podanej_latky"]
			}

			if (isSafe(response["ordinance_final"])) {
				form.$("lastDose").value =
					isSafe(response["ordinance_final"]) && response["ordinance_final"] == true ? true : false
			}

			if (isNotEmpty(response["pocet_davok"])) {
				form.$("numberOfDoses").value = response["pocet_davok"]
			}

			if (isNotEmpty(response["poradie_davky"])) {
				form.$("ordinanceOrder").value = response["poradie_davky"]
			}

			if (isNotEmpty(response["typ_ockovania"])) {
				form.$("vaccinationType").value = response["typ_ockovania"]
			}

			if (isNotEmpty(response["druh_ockovania"])) {
				if (isSafe(GlobalStore.CL["vaccinationMalady"])) {
					const vaccType = GlobalStore.CL["vaccinationMalady"].find((row) => row.code_ext == response["druh_ockovania"])
					form.$("typeOfVaccination").value = vaccType.code
				}
			}

			if (isNotEmpty(response["warning_text"])) {
				setTimeout(() => {
					WarningStore.open(response["warning_text"])
				}, 0)
			}
		}
	}
}

var singleton = new PatientMedicationStore()
export default singleton