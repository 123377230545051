import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import XsTable from "../../../../global/ui/xsTable/xsTable"
import XsButton from "../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../global/ui/xsButton/xsIconButton"
import EmployeesListStore from "../../stores/EmployeesListStore"
import RouterStore from "../../../../global/store/RouterStore"
import EmployeesDetailStore from "../../stores/EmployeesDetailStore"
import GlobalStore from "../../../../global/store/GlobalStore"
import XsConfirmationDialog from "../../../../global/ui/xsDialog/xsConfirmationDialog"

import AddEmployeeDialog from "./addEmployeeDialog"
import AddEmployeeDialogStore from "../../stores/AddEmployeeDialogStore"

@observer
export default class EmployeesList extends React.Component {
	constructor(props) {
		super(props)
		EmployeesListStore.loadEmployees()
		EmployeesListStore.loadOrgunits()
	}

	onHandleRowClick = (dataRow) => {
		RouterStore.push(`/employee?id=${dataRow.relationship}&personId=${dataRow.personId}`)
	}

	// handleAddClick = () => {
	// 	RouterStore.push(`/employee?id=new`)
	// }

	render() {
		return (
			<Grid container className="xs-employee-grid">
				<Grid item container justify="flex-end" xs={12}>
					<Grid item>
						<XsButton
							className="xs-primary"
							icon={<i className="fal fa-user-plus fa-lg" />}
							text={<FormattedMessage id="EmployeeDetail.employeenew.newemployee" />}
							type="submit"
							onClick={() => AddEmployeeDialogStore.open()}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<XsTable
						config={{
							columnDefs: {
								pID: {
									title: <FormattedMessage id="AttendeeListContainer.pIDCol" />,
									type: "string",
									design: {
										width: "20%"
									},
									sortable: true
								},
								full_name: {
									title: <FormattedMessage id="AttendeeListContainer.nameCol" />,
									type: "string",
									design: {
										width: "25%"
									},
									sortable: true
								},
								phone: {
									title: <FormattedMessage id="AttendeeListContainer.phonedCol" />,
									type: "string",
									design: {
										width: "15%"
									}
								},
								email: {
									title: <FormattedMessage id="Common.label.email" />,
									type: "string",
									design: {
										width: "25%"
									}
								},
								card: {
									title: <FormattedMessage id="AttendeeListContainer.cardCol" />,
									type: "string",
									design: {
										width: "20%"
									},
									sortable: true
								},
								delete: {
									title: "",
									type: "action",
									design: {
										width: "50px",
										body: {
											renderer: (props) => {
												return (
													<XsIconButton
														className="action-delete"
														icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault"></i>}
														onClick={() => {
															EmployeesDetailStore.fullName = props.value.fullName
															GlobalStore.openConfirmationDialog("xsDeleteEmployee", {
																employeeId: props.value.id
															})
														}}
													/>
												)
											}
										}
									}
								}
							},
							options: {
								showCursor: true,
								// selectRow: true,
								onRowClick: (dataRow) => {
									this.onHandleRowClick(dataRow)
								},
								mapper: (dataRow) => {
									return {
										// _id: dataRow._id,
										pID: isSafe(dataRow.personal_number) ? dataRow.personal_number : "",
										full_name: isSafe(dataRow.full_name) ? dataRow.full_name : "",
										card: isSafe(dataRow.card_id) ? dataRow.card_id : "",
										phone:
											isSafe(dataRow.business_contact) && isSafe(dataRow.business_contact.mobile)
												? dataRow.business_contact.mobile
												: "",
										email:
											isSafe(dataRow.business_contact) && isSafe(dataRow.business_contact.email)
												? dataRow.business_contact.email
												: "",
										personId: dataRow.person_id,
										relationship: dataRow.relationship,
										delete: {id: dataRow.relationship, fullName: dataRow.full_name}
									}
								}
							},
							dataSource: EmployeesListStore.employees
						}}
					/>
				</Grid>
				<AddEmployeeDialog />
				<XsConfirmationDialog
					type="danger"
					name="xsDeleteEmployee"
					text={
						<span className="xs-greyDefault fontSize13">
							<FormattedMessage id="Delete.employee.text" />
						</span>
					}
					subtext={<span className="xs-bold fontSize15">{EmployeesDetailStore.fullName}</span>}
					headerConfig={{
						text: "Delete.employee.header.text",
						subtext: "Delete.employee.header.subtext",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.label.delete"
					}}
					onConfirmation={() => EmployeesDetailStore.deleteEmployee()}
				/>
			</Grid>
		)
	}
}
