import React from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import XsBarcode from "../../../../../../global/ui/xsBarcode/xsBarcode"

import "./externalRow.less"

const ExternalRow = ({item}) => {
	let barcodeText = null
	if (item.external && item.external.request_extension) {
		const barcodeTextApp = item.external.request_extension.slice(-6)
		barcodeText = `${item.external.request_extension.slice(0, -6)} / ${barcodeTextApp}`
	}
	return (
		<div className="externalRow">
			{item.external && item.external.version === "draft" && (
				<div>
					<b className="title draft">
						<FormattedMessage id="Patient.form.patientrecord.nzisexternalDraft" />
					</b>
				</div>
			)}
			{item.external && item.external.version === "canceled" && (
				<div>
					<b className="title draft">
						<FormattedMessage id="Patient.form.patientrecord.nzisexternalCanceled" />
					</b>
				</div>
			)}
			{item.doctor_card && item.doctor_card.personnel_code && (
				<div>
					<span className="title">
						<FormattedMessage id="Calendar.overPrescription.dialog.doctorCode" />:
					</span>{" "}
					{item.doctor_card.personnel_code}
				</div>
			)}
			{item.diagnosis_code_ext && (
				<div>
					<span className="title">
						<FormattedMessage id="Patient.form.patientrecord.diagnosis" />:
					</span>{" "}
					<b>{item.diagnosis_code_ext}</b> - {item.diagnosis_name_ext}
				</div>
			)}
			{item.doctor_card && item.doctor_card.expertise_code && (
				<div>
					<span className="title">
						<FormattedMessage id="Orgunits.orgunit.form.expertise" />:
					</span>{" "}
					{`${item.doctor_card.expertise_code} - ${item.doctor_card.expertise}`}
				</div>
			)}
			{item.provider_card && item.provider_card.provider_code && (
				<div>
					<span className="title">
						<FormattedMessage id="Calendar.overPrescription.dialog.oupzsCode" />:
					</span>{" "}
					{`${item.provider_card.company} - ${item.provider_card.provider_code}`}
				</div>
			)}
			{item.ehealth_id && (
				<div>
					<span className="title">
						<FormattedMessage id="Patient.form.patientrecord.nzisexternalID" />:
					</span>{" "}
					{item.ehealth_id}
				</div>
			)}
			{barcodeText && (
				<div className="pr-barcode">
					<XsBarcode
						value={item.external.request_extension}
						format="CODE128"
						ean128={true}
						displayValue={true}
						text={barcodeText}
						height={50}
					/>
				</div>
			)}
		</div>
	)
}

export default injectIntl(ExternalRow)
