import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import {Grid, Paper} from "@material-ui/core"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import TemplateDialog from "./templateDialog"
import UniversalTemplateStore from "../../../stores/UniversalTemplateStore"

@injectIntl
@observer
export default class UniversalTemplateList extends React.Component {
	constructor(props) {
		super(props)

		// const hooks = {
		// 	onSubmit() {},
		// 	onSuccess(form) {
		// 		NotifyStore.getNotification(form)
		// 	},
		// 	onError() {}
		// }

		// this.form = new MobxReactForm(
		// 	{
		// 		fields: {
		// 			time_from: {
		// 				label: <FormattedMessage id="Common.label.from" />,
		// 				type: "date",
		// 				value: moment()
		// 					.add(-1, "month")
		// 					.startOf("month")
		// 			},
		// 			time_to: {
		// 				label: <FormattedMessage id="Common.label.to" />,
		// 				type: "date",
		// 				value: moment()
		// 			}
		// 		}
		// 	},
		// 	{plugins: {dvr: validatorjs}, hooks, bindings}
		// )

		// NotifyStore.formRef = this.form

		UniversalTemplateStore.getList()
	}

	onHandleRowClick = (datarow) => {
		UniversalTemplateStore.openTemplateDialog(datarow._id)
	}

	render() {
		return (
			<Grid container direction="column" id="notifyContainer">
				<Grid item>
					<Paper square={true} elevation={1} className="pr-8 pl-8 header">
						<div className="title">
							<FormattedMessage id="Common.label.universalTemplates" />
						</div>
					</Paper>
				</Grid>
				<Grid item className="pl-8 pr-8 pt-4">
					<Grid container justify="space-between">
						<Grid item xs={10} container alignItems="center" spacing={8}></Grid>
						<Grid item xs={2} container alignItems="center" justify="flex-end">
							<Grid item>
								<XsButton
									className="xs-primary"
									onClick={() => UniversalTemplateStore.openTemplateDialog()}
									text={<FormattedMessage id="Common.form.new" />}
									icon={<i className="fal fa-file-invoice fa-lg" />}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item className="pl-8 pr-8">
					<XsTable
						config={{
							columnDefs: {
								name: {
									title: <FormattedMessage id="Common.label.name" />,
									type: "string",
									design: {
										width: "200px"
									}
								}
							},
							options: {
								showCursor: true,
								mapper: (dataRow) => {
									return {
										_id: dataRow._id,
										name: dataRow.name
									}
								},
								onRowClick: (dataRow) => {
									this.onHandleRowClick(dataRow)
								}
							},
							dataSource: UniversalTemplateStore.templates
						}}
					/>
				</Grid>
				<TemplateDialog />
				{/* {NotifyStore.isOpenSendNotification && <SendNotificationDialog />} */}
			</Grid>
		)
	}
}
