// const xxx = {
// 	storeKey: "PatientInsurances",
// 	dataEntities: {
// 		insurance: {
// 			entity: "EHR.Data.Patient.Insurance",
// 			params: ["InsuranceId"],
// 			flags: "CD",

// 			lookups: {
// 				insurer: (obj) => {
// 					if (isSafe(obj) && isSafe(obj.insurer)) {
// 						return obj.insurer
// 					}
// 					return {
// 						insurer: {_type: "EHR.Data.Entity.Insurer", _ref: false, $new$: "$values$"}
// 					}
// 				},
// 				validity: (obj) => {
// 					if (isSafe(obj) && isSafe(obj.validity)) {
// 						return obj.validity
// 					}
// 					return {
// 						validity: {_type: "IXS.Entity.Data.Validity", _ref: false, $new$: "$values$"}
// 					}
// 				}
// 			}
// 		}
// 	},

// 	formFields: {
// 		insurer: "insurer._id", //"insurer.code_ext",
// 		number: "insurance.number",
// 		type: "insurance.type",
// 		from: "validity.from",
// 		to: "validity.to",
// 		country: "insurance.country"
// 	},

// 	parentRelation: (parentId) => {
// 		return {specific_rel: {_type: "Entity.Data.Client", _id: parentId}}
// 	},

// 	enumFields: ["country", "type"]
// }

export default {
	load: {
		storeKey: "PatientInsurance",
		params: ["insuranceId"],
		type: "EHR.Data.Patient.Insurance",
		flags: "CD"
	},
	scheme: {
		number: {
			relation: "@number"
		},
		type: {
			codeList: {_id: "type"}
		},
		country: {
			codeList: {_id: "country"}
		},
		insurer: {
			object: {
				scheme: {
					_id: {
						field: "insurer"
					}
				}
			}
		},
		validity: {
			object: {
				scheme: {
					from: {
						field: "from"
					},
					to: {
						field: "to"
					}
				}
			}
		},
		specific_rel: {
			relation: {
				_type: "Entity.Data.Client",
				_id: "#clientId"
			}
		}
	}
}
