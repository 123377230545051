import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
// import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"

import SettingStore from "../../../stores/SettingStore"

@injectIntl
@observer
export default class Messaging extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					nick_name: {
						label: <FormattedMessage id="Common.label.nickname" />
					},
					messaging_enable: {
						label: <FormattedMessage id="Common.label.messagingEnable" />,
						type: "checkbox"
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)

		SettingStore.loadMessiging(this.form)
	}

	render() {
		return (
			<Grid container className="xs-messaging">
				<Grid item xs={12}>
					<Grid container direction="column">
						<Grid item container alignItems="center" spacing={8}>
							<Grid item xs={3}>
								<XsInput white field={this.form.$("nick_name")} />
							</Grid>
							<Grid item xs={1} className="pt-2">
								<XsIconButton
									rect
									className="xs-default xs-outline"
									tooltip={this.props.intl.formatMessage({id: "Common.label.sendNickname"})}
									onClick={() => SettingStore.setSetting("NickName", this.form.$("nick_name").value)}
									icon={<i className="far fa-check fa-lg xs-greenSuccess"></i>}
								/>
							</Grid>
						</Grid>
						<Grid item container alignItems="center" spacing={8}>
							<Grid item xs={3}>
								<XsCheckbox
									field={this.form.$("messaging_enable")} /*onChange={() => AddEventDialogStore.searchPatient()}*/
								/>
							</Grid>
							<Grid item xs={1}>
								<XsIconButton
									rect
									className="xs-default xs-outline"
									tooltip={this.props.intl.formatMessage({id: "Common.label.sendMessagingEnbale"})}
									onClick={() =>
										SettingStore.setSetting("MessagingEnabled", this.form.$("messaging_enable").value ? 1 : 0)
									}
									icon={<i className="far fa-check fa-lg xs-greenSuccess"></i>}
								/>
							</Grid>
						</Grid>
						{/* <Grid item xs={1} className="pb-0">
							<XsIconButton
								rect
								className="xs-default xs-outline"
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								onClick={this.form.onSubmit}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid> */}
						{/* <Grid item xs={5}>
							<Grid container justify="flex-end" spacing={8}>
								<Grid item>
									<XsButton
										className="xs-primary"
										text={<FormattedMessage id="Common.label.export" />}
										icon={<i className="fal fa-file-excel fa-lg" />}
										onClick={() => {
											SicknessAbsReportListStore.open()
										}}
									/>
								</Grid>
							</Grid>
						</Grid> */}
					</Grid>
				</Grid>
			</Grid>
		)
	}
}
