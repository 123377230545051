import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

// import DataStore from "../../../stores/DataStore"

export default {
	load() {
		// let dgvalue = null

		// //Ak máme novú medikáciu predplníme diagnózu z dnešhého dekurzu
		// if (DataStore.patientDTO) {
		// 	const lastRecord = DataStore.patientDTO.get("lastRecord")
		// 	if (lastRecord && moment(lastRecord.created_at).isSame(moment(), "day")) {
		// 		dgvalue = lastRecord.diagnosis
		// 	}
		// }

		return {
			fields: {
				print: {
					value: false,
					rules: "boolean"
				},
				medicationType: {
					label: <FormattedMessage id="Patient.Form.Medication.MedicationType" defaultMessage="Prescription type" />,
					value: "ADMVACC",
					rules: "required"
				},
				vaccinationType: {
					label: <FormattedMessage id="Common.label.vaccinationType" />
				},
				medicationLocation: {
					label: <FormattedMessage id="Common.label.medicationLocation" />
				},
				drugs: {
					label: <FormattedMessage id="Common.label.drugs" />,
					rules: "required"
				},
				diagnosis: {
					label: <FormattedMessage id="Patient.Form.Medication.Diagnosis" />,
					rules: "required"
					// value: dgvalue
				},
				packs: {
					label: <FormattedMessage id="Patient.Form.Medication.Packs" />,
					rules: "required|min:0.01",
					value: 0.5
				},
				unit: {
					label: <FormattedMessage id="Common.label.unit" />,
					rules: "required",
					value: "ml"
				},
				medicationRoute: {
					label: <FormattedMessage id="Patient.Form.Medication.MedicationRoute" />,
					rules: "required",
					value: "IM"
				},

				batchNumber: {
					label: <FormattedMessage id="Patient.Form.Medication.BatchNumber" />
					// rules: "required"
				},
				expiration: {
					label: <FormattedMessage id="Patient.Form.Medication.Expiration" />
					//required_if: ["medicationType", "ADMVACC"]
					// rules: "required"
				},
				expirationPeriod: {
					label: <FormattedMessage id="Common.label.expirationPeriod" />
				},
				barcode: {
					label: <FormattedMessage id="Patient.Form.Medication.Barcode" />
				},

				type_code: {
					value: "POD"
				},

				version: {},
				productCode: {},
				drugCodeExt:{},

				doctor_id: {},
				personnel_id: {},
				provider_id: {},

				dosage_type: {},
				dateOfDelivery: {
					label: <FormattedMessage id="Common.label.AdministeredAtDateTime" />,
					rules: "required",
					type: "date",
					value: moment()
				},
				typeOfVaccination: {
					label: <FormattedMessage id="Common.label.typeOfVaccination" />
				},
				note: {
					label: <FormattedMessage id="Common.label.note" />
				},
				numberOfDoses: {
					label: <FormattedMessage id="Common.label.numberOfDoses" />,
					value: 2,
					rules: "required_if:medicationType,ADMVACC"
				},
				ordinanceOrder: {
					label: <FormattedMessage id="Common.label.ordinanceOrder" />,
					value: 1
				},
				lastDose: {
					label: <FormattedMessage id="Common.label.lastDose" />,
					type: "checkbox",
					value: false
				},
				specialVaccNote: {
					label: <FormattedMessage id="Common.label.vaccNote" />
				},
				qr_code_image: {}
			}
		}
	}
}
