"use strict"

import {observable, action} from "mobx"

class InfoDialogStore {
  @observable isOpen = false
	@observable infoObj = ""

	@action open(infoObj) {
		this.isOpen = true
		this.infoObj = infoObj
	}

	@action close() {
		this.isOpen = false
	}
}

var singleton = new InfoDialogStore()
export default singleton
