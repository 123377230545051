import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
// import PatientRecordForm from "../patientRecordForm/patientRecordForm"

import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import ShowSelectedDekurzDialogStore from "../../../stores/ShowSelectedDekurzDialogStore"
import PatientRecordForm from "../patientRecordForm/patientRecordForm"
import Recordfields from "../patientRecordForm/patientRecordFields"

@injectIntl
@observer
export default class ShowSelectedDekurzDialog extends React.Component {
	constructor(props) {
		super(props)

		const emptyHooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}
		this.form = new MobxReactForm(Recordfields.load(), {plugins: {dvr: validatorjs}, hooks: emptyHooks})
		ShowSelectedDekurzDialogStore.form = this.form
	}

	render() {
		return (
			<Dialog
				id="xsShowSelectedDekurzDialog"
				open={ShowSelectedDekurzDialogStore.isOpen}
				onClose={() => {
					ShowSelectedDekurzDialogStore.close()
				}}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								ShowSelectedDekurzDialogStore.close()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-tint fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.dekurz" />
						</div>
						<div className="xs-header-subtitle">
							<FormattedMessage id="Common.label.dekurzOverview" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent /*className="xs-content"*/ style={{padding: "20px"}}>
					<Grid container spacing={8} className="xs-form">
						{/* <PatientRecordFormOld showInDialog={true} />*/}
						<PatientRecordForm
							form={ShowSelectedDekurzDialogStore.form}
							showInDialog={true}
							keyVal={this.props.keyVal}
						/>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer">
					<XsButton
						className="xs-danger xs-outline"
						type="submit"
						onClick={() => {
							ShowSelectedDekurzDialogStore.close()
						}}
						text={<FormattedMessage id="Common.form.close" />}
						icon={<i className="fal fa-trash-alt fa-lg" />}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
