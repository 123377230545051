import React from "react"
import moment from "moment"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import {Grid} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
// import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import CopyAgreementDialog from "./copyAgreementDialog"

import fields from "./agreementFormFields"

import AgreementsStore from "../../../stores/AgreementsStore"
// import GlobalStore from "../../../../../global/store/GlobalStore"
import WarningStore from "../../../../../global/store/WarningStore"
import {getUserAccessRights} from "../../../../../global/helpers/actions"

@injectIntl
@observer
export default class AgreementForm extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit(form) {
				if (AgreementsStore.isNewAgreement) {
					if (
						isEmpty(form.$("agreement_number").value) ||
						isEmpty(form.$("from").value) ||
						isEmpty(form.$("insurer").value)
					) {
						WarningStore.open(`${props.intl.formatMessage({id: "Patient.form.patientPrescription.invalidForm"})}`)
					} else if (
						isNotEmpty(form.$("to").value) &&
						moment(form.$("from").value).isAfter(moment(form.$("to").value))
					) {
						WarningStore.open(`${props.intl.formatMessage({id: "Patient.form.patientPrescription.interval"})}`)
						form.invalidate()
					}
				}

				if (!AgreementsStore.isNewAgreement) {
					if (
						isEmpty(form.$("agreement_number").value) ||
						isEmpty(form.$("from").value) ||
						isEmpty(form.$("insurer").value)
					) {
						WarningStore.open(`${props.intl.formatMessage({id: "Patient.form.patientPrescription.invalidForm"})}`)
					}
				}
			},
			onSuccess(form) {
				if (AgreementsStore.isNewAgreement) {
					AgreementsStore.saveAgreement(true, form)
				}

				if (!AgreementsStore.isNewAgreement) {
					AgreementsStore.saveAgreement(false, form)
				}
			},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		AgreementsStore.agreementFormRef = this.form
	}

	render() {
		return (
			<div className="xs-agreement-form">
				<div className="xs-action">
					{AgreementsStore.isNewAgreement ? (
						<Grid container direction="row" justify="flex-end">
							<Grid item>
								<XsButton
									className="xs-success"
									text={<FormattedMessage id="Common.label.add" />}
									onClick={this.form.onSubmit}
								/>
							</Grid>
						</Grid>
					) : (
						<Grid container direction="row" justify="space-between">
							<Grid item>
								{getUserAccessRights("SRVZO.API.REST.MAIN./contract/contract-copy") && (
									<XsButton
										className="xs-outline xs-success"
										text={<FormattedMessage id="Common.label.copy" />}
										onClick={() => AgreementsStore.openCopyAgreement()}
									/>
								)}
							</Grid>
							<Grid item>
								<XsButton
									className="xs-success"
									text={<FormattedMessage id="Common.label.save" />}
									onClick={this.form.onSubmit}
								/>
							</Grid>
						</Grid>
					)}
				</div>
				<div className="xs-form">
					<Grid container direction="row" spacing={8}>
						<Grid item xs={3}>
							<XsInput field={this.form.$("agreement_number")} />
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.form.$("from")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.form.$("to")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={3}>
							<XsSearchSelect field={this.form.$("insurer")} items={AgreementsStore.insurers} required />
						</Grid>
					</Grid>
					<XsInput field={this.form.$("agreement_subject_matter")} multiple rows={2} />
				</div>
				{/* <XsConfirmationDialog
					type="info"
					name="xsUpdateAgreementConfirmation"
					text={<FormattedMessage id="Agreement.Container.Detail.Update.confirmationMessage" />}
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-pencil-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.update"
					}}
					confirmBtn={{
						icon: <i className="fal fa-pencil-alt fa-lg" />,
						text: "Common.btn.agreementForm.updateAgreement"
					}}
					onConfirmation={() =>
						this.setState({isSaving: true}, AgreementsStore.saveAgreement(false, this.enableSave.bind()))
					}
				/> */}
				{AgreementsStore.isOpenCopyAgreement && <CopyAgreementDialog />}
			</div>
		)
	}
}
