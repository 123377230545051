"use strict"
import api from "../actions/api"
import GlobalStore from "../../../global/store/GlobalStore"
import InvoiceFormStore from "./InvoiceFormStore"
import {observable, action} from "mobx"

class InvoiceListStore {
	@observable showForm = false
	@observable selectedInvoiceID = null

	tableRef = null
	@observable checkedInvoces = []

	@action set(name, value) {
		this[name] = value
	}

	@action reset() {
		this.showForm = false
		this.selectedInvoiceID = null
	}

	@action deleteInvoice(invoiceID) {
		const updateRequest = {
			_id: invoiceID,
			_type: "Fin.Data.Invoice",
			_ref: false,
			active: false
		}
		api
			.updateInvoice(updateRequest)
			.call()
			.then((response) => {
				if (response) {
					this.set("selectedInvoiceID", null)
					this.set("showForm", false)
					InvoiceFormStore.reset()
					GlobalStore.setNotificationMessage("Invoice.form.deleteSuccess")
				}
			})
	}
}
var singleton = new InvoiceListStore()
export default singleton
