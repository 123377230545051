export default {
	load: {
		storeKey: "personnelForm",
		params: ["entityId"],
		type: "EHR.Data.Entity.Personnel"
	},
	descriptor: {
		_ref: false,
		active: true
	},
	scheme: {
		validity: {
			object: {
				scheme: {
					from: {
						field: "from"
					},
					to: {
						field: "to"
					}
				}
			}
		},
		category: {
			object: {
				descriptor: {
					_ref: false,
					_type: "EHR.CL.Entity.PersonnelCategory"
				},
				scheme: {
					_id: {
						field: "category"
					}
				}
			}
		},
		entity: {
			relation: {
				_type: "Entity.Data.Person",
				_id: "#entityId"
			}
		},
		identifiers: {
			relation: "@identifiers"
		},
		_id: {
			relation: "#personnelId"
		}
	}
}
