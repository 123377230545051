import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				personnelExpertiseContract: {},
				contract: {
					label: <FormattedMessage id="Employee.form.medicalWorkerExpForm.contract" />
				},
				from: {
					label: <FormattedMessage id="Employee.form.medicalWorker.from" />,
					rules: "required",
					type: "date"
				},
				to: {
					label: <FormattedMessage id="Employee.form.medicalWorker.to" />,
					type: "date"
				}
			}
		}
	}
}
