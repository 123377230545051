import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import {Grid, TextField} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import AgreementAttributeStore from "../../../stores/AgreementAttributeStore"
import WarningStore from "../../../../../global/store/WarningStore"
// import AgreementPartDepartmentsStore from "../../../stores/AgreementPartDepartmentsStore"

@injectIntl
@observer
export default class PricePerCapitationForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isSaving: false
		}
	}

	enableSave = () => {
		this.setState({isSaving: false})
	}

	hasIntersect = () => {
		let isValid = false

		let from = +AgreementAttributeStore.agreementAttributeAgeFrom
		let to = +AgreementAttributeStore.agreementAttributeAgeTo

		if (
			isSafe(AgreementAttributeStore.attributeDataJSON) &&
			AgreementAttributeStore.attributeDataJSON.hasOwnProperty(AgreementAttributeStore.typeFilter)
		) {
			Object.keys(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]).some((attrKey) => {
				let a = +AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][attrKey].age_from
				let b = +AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][attrKey].age_to

				if ((a <= from && from <= b) || (a <= to && to <= b) || (from <= a && a <= to) || (from <= b && b <= to)) {
					isValid = true
					return true
				}
			})
		}

		return isValid
	}

	render() {
		return (
			<Grid container direction="column">
				<Grid item xs={12}>
					<Grid container direction="row" spacing={8} alignItems="flex-end">
						<Grid item xs={3}>
							<TextField
								value={AgreementAttributeStore.agreementAttributeAgeFrom}
								disabled={AgreementAttributeStore.isEditAttributeGrid}
								onChange={(e) => (AgreementAttributeStore.agreementAttributeAgeFrom = e.target.value)}
								label={<FormattedMessage id="Agreement.Container.Detail.Attribute.AgeFrom" />}
								InputLabelProps={{
									shrink: true
								}}
								// InputProps={{
								//   classes: {
								//     root: (isEmpty(AgreementAttributeStore.agreementAttributeAgeFrom) || !Number.isInteger(+AgreementAttributeStore.agreementAttributeAgeFrom)) ? "xs-input-error" : ""
								//   }
								// }}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								value={AgreementAttributeStore.agreementAttributeAgeTo}
								disabled={AgreementAttributeStore.isEditAttributeGrid}
								onChange={(e) => (AgreementAttributeStore.agreementAttributeAgeTo = e.target.value)}
								label={<FormattedMessage id="Agreement.Container.Detail.Attribute.AgeTo" />}
								InputLabelProps={{
									shrink: true
								}}
								// InputProps={{
								//   classes: {
								//     root: (isEmpty(AgreementAttributeStore.agreementAttributeAgeTo) || !Number.isInteger(+AgreementAttributeStore.agreementAttributeAgeTo)) ? "xs-input-error" : ""
								//   }
								// }}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								value={AgreementAttributeStore.agreementAttributeCurrency}
								disabled={AgreementAttributeStore.isEditAttributeGrid}
								onChange={(e) => (AgreementAttributeStore.agreementAttributeCurrency = e.target.value)}
								label={
									<span className="xs-bold">
										<FormattedMessage id="Agreement.Container.Detail.Attribute.Currency" />*
									</span>
								}
								InputLabelProps={{
									shrink: true
								}}
								InputProps={{
									classes: {
										root:
											!isPrice(AgreementAttributeStore.agreementAttributeCurrency) &&
											!AgreementAttributeStore.isEditAttributeGrid
												? "xs-input-error"
												: ""
									}
								}}
							/>
						</Grid>
						<Grid item xs={3} className="xs-form-actions">
							<XsButton
								className={
									AgreementAttributeStore.isEditAttributeGrid ? "xs-default xs-outline" : "xs-success xs-outline"
								}
								disabled={this.state.isSaving || AgreementAttributeStore.isEditAttributeGrid}
								text={<FormattedMessage id="Common.label.add" />}
								onClick={() => {
									if (!isPrice(AgreementAttributeStore.agreementAttributeCurrency)) {
										WarningStore.open(
											`${this.props.intl.formatMessage({id: "Patient.form.patientPrescription.invalidForm"})}`
										)
									} else if (
										isNotEmpty(AgreementAttributeStore.agreementAttributeAgeFrom) &&
										isNotEmpty(AgreementAttributeStore.agreementAttributeAgeTo) &&
										+AgreementAttributeStore.agreementAttributeAgeFrom >
											+AgreementAttributeStore.agreementAttributeAgeTo
									) {
										WarningStore.open(
											`${this.props.intl.formatMessage({
												id: "Common.label.ageFromIsGraterThanAgeTo"
											})}`
										)
									} else if (this.hasIntersect()) {
										WarningStore.open(
											`${this.props.intl.formatMessage({id: "Patient.form.patientPrescription.intersect"})}`
										)
									} else {
										// AgreementAttributeStore.saveAttributes()
										this.setState({isSaving: true}, AgreementAttributeStore.saveAttributes(this.enableSave.bind()))
									}
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		)
	}
}
