import React from "react"
import {FormattedMessage} from "react-intl"

export default {
	load() {
		return {
			fields: {
				agreement_number: {
					label: <FormattedMessage id="Agreement.Container.Detail.Number" />,
					rules: "required"
				},
				from: {
					label: <FormattedMessage id="Agreement.Container.Detail.From" />,
					type: "date",
					rules: "required"
				},
				to: {
					label: <FormattedMessage id="Agreement.Container.Detail.To" />,
					type: "date"
				},
				insurer: {
					label: <FormattedMessage id="Common.label.insurer" />,
					rules: "required"
				},
				agreement_subject_matter: {
					label: <FormattedMessage id="Agreement.Container.Detail.SubjectMatter" />
				}
			}
		}
	}
}
