import React from "react"
import moment from "moment"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import {Grid} from "@material-ui/core"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import NxTable from "../../../../../global/ui/xsTable/xsTable"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

import AgreementsStore from "../../../stores/AgreementsStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

@observer
export default class AgreementList extends React.Component {
	constructor(props) {
		super(props)
		AgreementsStore.resetDataAgreementForm()
	}

	render() {
		return (
			<div className="xs-agreement-list">
				<Grid container direction="column">
					<Grid item xs={12} className="xs-agreement-list-actions">
						<Grid container direction="row" justify="flex-end">
							<Grid item>
								<XsButton
									className="xs-primary"
									text={<FormattedMessage id="Common.form.new" />}
									onClick={() => {
										AgreementsStore.showDetail()
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<NxTable
							config={{
								columnDefs: {
									number: {
										title: <FormattedMessage id="Agreement.Container.Detail.Number" />,
										type: "string",
										design: {
											width: "150px"
										},
										sortable: true
									},
									from: {
										title: <FormattedMessage id="Agreement.Container.Detail.From" />,
										type: "datetime",
										design: {
											width: "120px",
											body: {
												formatter: (props) => {
													return isNotEmpty(props) ? moment(props).format("DD.MM.YYYY") : ""
												}
											}
										}
										// sortable: true
									},
									to: {
										title: <FormattedMessage id="Agreement.Container.Detail.To" />,
										type: "datetime",
										design: {
											width: "120px",
											body: {
												formatter: (props) => {
													return isNotEmpty(props) ? moment(props).format("DD.MM.YYYY") : ""
												}
											}
										}
										// sortable: true
									},
									subject_matter: {
										title: <FormattedMessage id="Agreement.Container.Detail.SubjectMatter" />,
										type: "string",
										sortable: true
									},
									delete: {
										title: "",
										type: "action",
										design: {
											width: "50px",
											body: {
												renderer: (props) => {
													return (
														<XsIconButton
															className="action-delete"
															icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault"></i>}
															onClick={() =>
																GlobalStore.openConfirmationDialog("xsDeleteAgreement", {agreementId: props.value})
															}
														/>
													)
												}
											}
										}
									}
								},
								options: {
									showCursor: true,
									onRowClick: (dataRow) => {
										AgreementsStore.showDetail(dataRow.id)
									},
									paging: {
										rowsPerPageOptions: [10, 15]
									},
									mapper: (dataRow) => {
										return {
											id: dataRow._id,
											number: dataRow.number,
											from: isSafe(dataRow.validity) && isSafe(dataRow.validity.from) ? dataRow.validity.from : "",
											to: isSafe(dataRow.validity) && isSafe(dataRow.validity.to) ? dataRow.validity.to : "",
											subject_matter: dataRow.subject_matter,
											delete: dataRow._id
										}
									}
								},
								dataSource: AgreementsStore.agreementListData //DataStore.patientDTO.get("capitationsDTO") //this.getData()
							}}
						/>
					</Grid>
				</Grid>
				<XsConfirmationDialog
					name="xsDeleteAgreement"
					text={<FormattedMessage id="Agreement.Part.RemoveAgreement.Message" />}
					type="danger"
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x"></i>
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg"></i>,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg"></i>,
						text: "Common.btn.agreement.delete"
					}}
					onConfirmation={() => AgreementsStore.removeAgreement()}
				/>
			</div>
		)
	}
}
