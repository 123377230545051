import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "protocol_enteral_nutrition"
				},

				name: {},
				birthdate: {},
				identifier: {},
				address: {},
				insurerCode: {},

				///

				// nechceny pokles hmotnosti
				ana_weight_yes: {
					label: "ÁNO - 1",
					type: "checkbox",
					value: false
				},
				ana_weight_no: {
					label: "NIE - 0",
					type: "checkbox",
					value: false
				},

				// znizeny prijem o 35% hmotnosti
				ana_income_yes: {
					label: "ÁNO - 1",
					type: "checkbox",
					value: false
				},
				ana_income_no: {
					label: "NIE - 0",
					type: "checkbox",
					value: false
				},

				// chodiaci pacienti - muzi
				antro_walk_man_yes: {
					label: "ÁNO - 1",
					type: "checkbox",
					value: false
				},
				antro_walk_man_no: {
					label: "NIE - 0",
					type: "checkbox",
					value: false
				},

				// chodiaci pacienti - zeny
				antro_walk_woman_yes: {
					label: "ÁNO - 1",
					type: "checkbox",
					value: false
				},
				antro_walk_woman_no: {
					label: "NIE - 0",
					type: "checkbox",
					value: false
				},

				// leziaci pacienti - muzi
				antro_lying_man_yes: {
					label: "ÁNO - 1",
					type: "checkbox",
					value: false
				},
				antro_lying_man_no: {
					label: "NIE - 0",
					type: "checkbox",
					value: false
				},

				// leziaci pacienti - zeny
				antro_lying_woman_yes: {
					label: "ÁNO - 1",
					type: "checkbox",
					value: false
				},
				antro_lying_woman_no: {
					label: "NIE - 0",
					type: "checkbox",
					value: false
				},

				// dialyzovani pacienti - muzi
				antro_dial_man_yes: {
					label: "ÁNO - 1",
					type: "checkbox",
					value: false
				},
				antro_dial_man_no: {
					label: "NIE - 0",
					type: "checkbox",
					value: false
				},

				// dialyzovani pacienti - zeny
				antro_dial_woman_yes: {
					label: "ÁNO - 1",
					type: "checkbox",
					value: false
				},
				antro_dial_woman_no: {
					label: "NIE - 0",
					type: "checkbox",
					value: false
				},

				clinicalEvaluationState: {
					label: <FormattedMessage id="PrintTemplate.ProtocolEnteralNutrition.clinicalEvaluationState" />
				},

				diagnosis: {
					label: <FormattedMessage id="PrintTemplate.ProtocolEnteralNutrition.diagnosis" />
				},
				diagnosisWord: {},
				diagnosisCode: {},

				//1
				amb1_yes: {
					label: "ÁNO - 1",
					type: "checkbox",
					value: false
				},
				amb1_no: {
					label: "NIE - 0",
					type: "checkbox",
					value: false
				},

				//2
				amb2_yes: {
					label: "ÁNO - 1",
					type: "checkbox",
					value: false
				},
				amb2_no: {
					label: "NIE - 0",
					type: "checkbox",
					value: false
				},

				//3
				amb3_yes: {
					label: "ÁNO - 1",
					type: "checkbox",
					value: false
				},
				amb3_no: {
					label: "NIE - 0",
					type: "checkbox",
					value: false
				},

				//4
				amb4_yes: {
					label: "ÁNO - 1",
					type: "checkbox",
					value: false
				},
				amb4_no: {
					label: "NIE - 0",
					type: "checkbox",
					value: false
				},

				//5
				amb5_yes: {
					label: "ÁNO - 1",
					type: "checkbox",
					value: false
				},
				amb5_no: {
					label: "NIE - 0",
					type: "checkbox",
					value: false
				},

				//6
				amb6_yes: {
					label: "ÁNO - 1",
					type: "checkbox",
					value: false
				},
				amb6_no: {
					label: "NIE - 0",
					type: "checkbox",
					value: false
				},

				//nutricna podpora
				nutritionSupport_yes: {
					label: "Áno",
					type: "checkbox",
					value: false
				},
				nutritionSupport_no: {
					label: "Nie",
					type: "checkbox",
					value: false
				},

				//plna vyziva
				fullNutrition_yes: {
					label: "Áno",
					type: "checkbox",
					value: false
				},
				fullNutrition_no: {
					label: "Nie",
					type: "checkbox",
					value: false
				},

				foodCode: {},
				foodName: {},
				foodCodeInput: {
					label: <FormattedMessage id="PrintTemplate.ProtocolEnteralNutrition.foodCodeInput" />
				},

				dailyDosage: {
					label: <FormattedMessage id="PrintTemplate.ProtocolEnteralNutrition.dailyDosage" />
				},
				packageNumber: {
					label: <FormattedMessage id="PrintTemplate.ProtocolEnteralNutrition.packageNumber" />
				},

				date: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.date" />,
					value: moment(),
					type: "date"
				}
			}
		}
	}
}
