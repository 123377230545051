"use strict"

import {observable, action} from "mobx"
import {getUserPersonnelID, getSelectedOrgUnit, getUser, getGWServerRole} from "../../../global/helpers/actions"
import settings from "../../../global/config/settings"

import DataStore from "./DataStore"
import WarningStore from "../../../global/store/WarningStore"

class CovidCertificatesDialogStore {
	@observable isOpen = false
	@observable certificates = []

	@observable isOpenPDF = false

	@observable covidCertPDF = null
	@observable filterVal = {}

	@observable loading = false
	@observable loadingPDF = false

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@action openPDF() {
		this.isOpenPDF = true
	}

	@action closePDF() {
		this.isOpenPDF = false
		this.covidCertPDF = null
	}

	@action searchCovidCertificates() {
		this.loading = true

		const user = getUser()
		const orgunit = getSelectedOrgUnit()
		const OUPZS = isSafe(orgunit) ? orgunit.identifiers.find((i) => i.type === "JRUZID") : null
		const spec = isSafe(orgunit) ? orgunit.personnel_expertise_jruz_id : null
		const specVer = isSafe(orgunit) ? orgunit.personnel_expertise_jruz_version : null
		const personnelJRUZId = isSafe(orgunit) ? orgunit.personnel_jruz_id : null
		const personnelExpClId = isSafe(orgunit) ? orgunit.personnel_expertise_cl_id : null

		const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
			? DataStore.patientDTO.get("patient").validInfo.client
			: null
		const JRUZIDObj = isSafe(patientClientInfo.identifiers)
			? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
			: null

		const req = {
			ClassName: "VyhladajCovidCertifikaty",
			AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
			AmbeePersonnelId: getUserPersonnelID(),
			PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
			AmbeeOrgUnitId: isSafe(orgunit) ? orgunit._id : null,
			OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
			PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
			PersonnelExpertiseJRUZVersion: specVer,
			AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
			AmbeePatientId: DataStore.patientDTO.get("patientID"),
			PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
			PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
			PPCToken: null,
			CertificateType: null,
			Environment: getGWServerRole()
		}

		if (isSafe(this.filterVal)) {
			req["CertificateType"] = this.filterVal._id
		}

		fetch(`${settings.GW_BASE_URL}/SearchCovidCertificates`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json"
			},
			body: JSON.stringify(req)
		})
			.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
			.then((res) => {
				if (res && res.rows && res.rows.length) {
					this.certificates = res.rows
				} else {
					this.certificates = []
				}
				logger("res", res)

				if (isNotEmpty(res.ErrorMessage) && isNotEmpty(res.ErrorCode)) {
					WarningStore.open("Nastala chyba: " + res.ErrorCode + ": " + res.ErrorMessage)
				}

				this.loading = false
			})
			.catch(() => (this.loading = false))
	}

	@action getCovidCertificate(certId, certType) {
		this.loadingPDF = true

		this.openPDF()
		const user = getUser()
		const orgunit = getSelectedOrgUnit()
		const OUPZS = isSafe(orgunit) ? orgunit.identifiers.find((i) => i.type === "JRUZID") : null
		const spec = isSafe(orgunit) ? orgunit.personnel_expertise_jruz_id : null
		const specVer = isSafe(orgunit) ? orgunit.personnel_expertise_jruz_version : null
		const personnelJRUZId = isSafe(orgunit) ? orgunit.personnel_jruz_id : null
		const personnelExpClId = isSafe(orgunit) ? orgunit.personnel_expertise_cl_id : null

		const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
			? DataStore.patientDTO.get("patient").validInfo.client
			: null
		const JRUZIDObj = isSafe(patientClientInfo.identifiers)
			? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
			: null

		const req = {
			ClassName: "DajCovidCertifikat",
			AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
			AmbeePersonnelId: getUserPersonnelID(),
			PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
			AmbeeOrgUnitId: isSafe(orgunit) ? orgunit._id : null,
			OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
			PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
			PersonnelExpertiseJRUZVersion: specVer,
			AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
			AmbeePatientId: DataStore.patientDTO.get("patientID"),
			PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
			PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
			PPCToken: null,
			CertificateId: certId,
			CertificateType: certType,
			Environment: getGWServerRole()
		}

		fetch(`${settings.GW_BASE_URL}/GetCovidCertificate`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json"
			},
			body: JSON.stringify(req)
		})
			.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
			.then((res) => {
				logger("res", res)
				if (res && isNotEmpty(res.certificate_base64)) {
					this.covidCertPDF = res.certificate_base64
				} else {
					this.covidCertPDF = null
				}

				if (isNotEmpty(res.ErrorMessage) && isNotEmpty(res.ErrorCode)) {
					WarningStore.open("Nastala chyba: " + res.ErrorCode + ": " + res.ErrorMessage)
				}

				this.loadingPDF = false
			})
			.catch(() => (this.loadingPDF = false))
	}
}

var singleton = new CovidCertificatesDialogStore()
export default singleton

