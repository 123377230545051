import React, {Component} from "react"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
// import TableFooter from "@material-ui/core/TableFooter"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import {TableHead, TableSortLabel, Checkbox} from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"
import XsLoading from "../xsLoading/xsLoading"

import "./xsTable.less"
import {FormattedMessage, injectIntl} from "react-intl"
import TablePaginationActions from "../xsTableServer/xsTablePaginationActions"

import TableStore from "../../../modules/ambulance/stores/TableStore"

@injectIntl
class XsTable extends Component {
	constructor(props) {
		super(props)

		this.reloadGrid = true

		const {options} = props.config

		let page = 0
		let rowsPerPage = 10

		if (isNotEmpty(options.name) && TableStore.tables.hasOwnProperty(options.name)) {
			const tableByNameData = TableStore.tables[options.name]

			let currRows = []

			if (isSafe(props.config.dataSource)) {
				if (typeof props.config.dataSource === "function") {
					currRows = props.config.dataSource()
				} else {
					currRows = props.config.dataSource.slice()
				}
			}

			const previousRowsCount = tableByNameData.rows
			const currRowsCount = currRows.length

			if (previousRowsCount == currRowsCount) {
				page = tableByNameData.page
			} else {
				page = 0
			}

			rowsPerPage = tableByNameData.rowsPerPage
		} else {
			page = isSafe(options.paging) && isSafe(options.paging.page) ? options.paging.page : 0
			rowsPerPage = isSafe(options.paging) && isSafe(options.paging.rowsPerPage) ? options.paging.rowsPerPage : 10
		}
		this.state = {
			order: false, //sortDirection: [asc, desc, false]
			orderBy: null,
			page: page,
			rowsPerPage: rowsPerPage,
			selectedRow: false,
			checkboxValues: [],
			checkAll: false
		}

		if (isSafe(props.setRef) && typeof props.setRef == "function") {
			props.setRef(this)
		}
	}

	data = []

	componentDidUpdate() {
		this.reloadGrid = false
	}

	UNSAFE_componentWillReceiveProps() {
		const {options} = this.props.config
		this.reloadGrid = true

		let page = 0

		if (isNotEmpty(options.name) && TableStore.tables.hasOwnProperty(options.name)) {
			const tableByNameData = TableStore.tables[options.name]

			if (tableByNameData.rows == this.data.length) {
				page = tableByNameData.page
			} else {
				page = 0
			}
		}

		this.setState({
			page: page
		})
	}

	createSortHandler = (property) => (event) => {
		this.handleRequestSort(event, property)
	}

	handleRequestSort = (event, property) => {
		const {options} = this.props.config

		if (
			isNotEmpty(options.name) &&
			TableStore.tables.hasOwnProperty(options.name) &&
			TableStore.tables[options.name].hasOwnProperty("index")
		) {
			delete TableStore.tables[options.name]["index"]
		}

		this.reloadGrid = true
		const orderBy = property
		let order = this.state.order

		switch (order) {
			case "asc":
				order = false
				break
			case false:
				order = "desc"
				break
			default:
				order = "asc"
		}

		this.setState({order, orderBy})
	}

	handleChangePage = (event, page) => {
		const {options} = this.props.config

		if (
			isNotEmpty(options.name) &&
			TableStore.tables.hasOwnProperty(options.name) &&
			TableStore.tables[options.name].hasOwnProperty("index")
		) {
			delete TableStore.tables[options.name]["index"]
		}

		this.setState({
			page
		})
	}

	handleChangeRowsPerPage = (event) => {
		const {options} = this.props.config

		if (
			isNotEmpty(options.name) &&
			TableStore.tables.hasOwnProperty(options.name) &&
			TableStore.tables[options.name].hasOwnProperty("index")
		) {
			delete TableStore.tables[options.name]["index"]
		}

		this.setState({
			rowsPerPage: event.target.value
		})
	}

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value
		})
	}

	selectRow = (idx) => {
		this.setState({
			selectedRow: idx //this.state.selectedRow === idx ? undefined : idx
		})
	}

	// getSorting(order, orderBy) {
	//   return order === "desc"
	//     ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
	//     : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1)
	// }

	stringComparer = (a, b, direction) => {
		let result
		switch (direction) {
			case "asc":
				result = (isSafe(a) ? a : "").localeCompare(isSafe(b) ? b : "")
				break
			case "desc":
				result = (isSafe(b) ? b : "").localeCompare(isSafe(a) ? a : "")
				break
			default:
				result = 0
		}
		return result
	}

	numberComparer = (a, b, direction) => {
		let result
		switch (direction) {
			case "asc":
				result = (isNotEmpty(a) ? +a : Number.MAX_VALUE) - (isNotEmpty(b) ? +b : Number.MAX_VALUE)
				break
			case "desc":
				result = (isNotEmpty(b) ? +b : Number.MAX_VALUE) - (isNotEmpty(a) ? +a : Number.MAX_VALUE)
				break
			default:
				result = 0
				break
		}
		return result
	}

	dateComparer = (a, b, direction) => {
		// cez moment je to strasne pomale
		//const result = direction === "descending" ? Moment(b) - Moment(a) : Moment(a) - Moment(b)
		let result
		switch (direction) {
			case "asc":
				result = new Date(a) - new Date(b)
				break
			case "desc":
				result = new Date(b) - new Date(a)
				break
			default:
				result = 0
				break
		}
		return result
	}

	tableHeaderRow
	tableHeaderJSX
	tableHeader() {
		const {columnDefs, options} = this.props.config
		const {order, orderBy} = this.state

		this.tableHeaderRow = []
		this.tableHeaderJSX = null

		if (!options.hideHeader) {
			let columnName = isSafe(orderBy) ? orderBy : null
			let sortDirection = isSafe(order) ? order : null

			if (
				!isSafe(columnName) &&
				isSafe(options) &&
				isSafe(options.defaultSort) &&
				isSafe(options.defaultSort.columnName)
			) {
				columnName = options.defaultSort.columnName
				sortDirection = options.defaultSort.sortDirection === "desc" ? "desc" : "asc"
			}

			if (options.checkboxes) {
				this.tableHeaderRow.push(
					<TableCell
						key="checkbox_header"
						className="xs-header-checkbox"
						// width={width}
					>
						<Checkbox
							checked={this.state.checkAll}
							onChange={(e) => {
								let value = e.target.checked

								if (value) {
									this.setState(
										{
											checkAll: true,
											checkboxValues: this.data.map(
												(obj) =>
													obj[
														isNotEmpty(options.checkAllDataColumn) ? options.checkAllDataColumn : options.checkboxColumn
													]
											)
										},
										() => options.onClickCheckbox(this.state.checkboxValues)
									)
								} else {
									this.setState({checkAll: false, checkboxValues: []}, () =>
										options.onClickCheckbox(this.state.checkboxValues)
									)
								}
							}}
						/>
					</TableCell>
				)
			}

			// const defaultWidth = 100 / +Object.keys(columnDefs).length
			Object.keys(columnDefs).forEach((column, idx) => {
				const columnDef = columnDefs[column]

				const tooltip =
					isSafe(columnDef.design) && isSafe(columnDef.design.header) && isSafe(columnDef.design.header.tooltip)
						? columnDef.design.header.tooltip()
						: null
				const sortable = isSafe(columnDef.sortable) && columnDef.sortable
				const className =
					isSafe(columnDef.design) && isSafe(columnDef.design.header) && isSafe(columnDef.design.header.className)
						? columnDef.design.header.className
						: null
				const width = isSafe(columnDef.design) && isSafe(columnDef.design.width) ? columnDef.design.width : null //`${defaultWidth}%`

				if (isSafe(tooltip) && sortable) {
					this.tableHeaderRow.push(
						<Tooltip key={idx} title={tooltip} enterDelay={750} placement="bottom-start">
							<TableCell
								sortDirection={columnName === column ? sortDirection : false}
								key={column}
								onClick={this.createSortHandler(column)}
								className={className ? `${className} sortable` : "sortable"}
								width={width}
							>
								{(sortDirection === "asc" || sortDirection === "desc") && (
									<TableSortLabel active={columnName === column} direction={sortDirection}>
										{columnDef.title}
									</TableSortLabel>
								)}
								{!sortDirection && columnDef.title}
							</TableCell>
						</Tooltip>
					)
				} else if (isSafe(tooltip)) {
					this.tableHeaderRow.push(
						<Tooltip key={idx} title={tooltip} enterDelay={750} placement="bottom-start">
							<TableCell
								sortDirection={columnName === column ? sortDirection : false}
								key={column}
								className={className}
								width={width}
							>
								{columnDef.title}
							</TableCell>
						</Tooltip>
					)
				} else if (sortable) {
					this.tableHeaderRow.push(
						<TableCell
							sortDirection={columnName === column ? sortDirection : false}
							key={column}
							onClick={this.createSortHandler(column)}
							className={className ? `${className} sortable` : "sortable"}
							width={width}
						>
							{(sortDirection === "asc" || sortDirection === "desc") && (
								<TableSortLabel active={columnName === column} direction={sortDirection}>
									{columnDef.title}
								</TableSortLabel>
							)}
							{!sortDirection && columnDef.title}
						</TableCell>
					)
				} else {
					this.tableHeaderRow.push(
						<TableCell
							sortDirection={columnName === column ? sortDirection : false}
							key={column}
							className={className}
							width={width}
						>
							{columnDef.title}
						</TableCell>
					)
				}
			})

			this.tableHeaderJSX = (
				<TableHead>
					<TableRow>{this.tableHeaderRow}</TableRow>
				</TableHead>
			)
		}
	}

	tableBodyRows
	tableBody() {
		const {columnDefs, options} = this.props.config
		const {order, orderBy, rowsPerPage, page} = this.state

		let dataSource = isSafe(this.data) ? this.data : []

		let columnName = isSafe(orderBy) ? orderBy : null
		let sortDirection = isSafe(order) ? order : null

		if (this.reloadGrid) {
			if (
				!isSafe(columnName) &&
				isSafe(options) &&
				isSafe(options.defaultSort) &&
				isSafe(options.defaultSort.columnName)
			) {
				columnName = options.defaultSort.columnName
				if (isSafe(options.defaultSort.sortDirection)) {
					switch (options.defaultSort.sortDirection) {
						case "asc":
							sortDirection = "asc"
							break
						case "desc":
							sortDirection = "desc"
							break
					}
				} else {
					sortDirection = false
				}
			}

			if (isSafe(columnName) && isSafe(columnDefs[columnName]) && isSafe(columnDefs[columnName].type)) {
				switch (columnDefs[columnName].type) {
					case "string":
						dataSource.sort((a, b) => this.stringComparer(a[columnName], b[columnName], sortDirection))
						break
					case "number":
						dataSource.sort((a, b) => this.numberComparer(a[columnName], b[columnName], sortDirection))
						break
					case "datetime":
						dataSource.sort((a, b) => this.dateComparer(a[columnName], b[columnName], sortDirection))
						break
					// case "object":
					//   dataSource.sort((a, b) => this.stringComparer(a[sortedColumn], b[sortedColumn], sortDirection))
					//   break
				}
			}
		}

		this.tableBodyRows = []
		if (isSafe(this.props.loading) && this.props.loading) {
			this.tableBodyRows.push(
				<TableRow key={0}>
					<TableCell className="nx-grid-loading">
						<XsLoading />
					</TableCell>
				</TableRow>
			)
		} else if (isSafe(this.props.filterChange) && this.props.filterChange) {
			this.tableBodyRows.push(
				<TableRow key={1}>
					<TableCell className={`xs-table-no-data ${isNotEmpty(options.reverseColor) ? "bgGrey" : ""}`}>
						<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
					</TableCell>
				</TableRow>
			)
		} else if (dataSource.length > 0) {
			if (!options.hidePager) {
				dataSource = dataSource.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
			}
			// dataSource.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).forEach((row, idx) => {
			dataSource.forEach((row, idx) => {
				if (isSafe(options) && isSafe(options.mapper)) {
					row = options.mapper(row)
				}

				this.tableBodyRows.push(
					<TableRow key={idx} hover={isSafe(options) && options.showCursor ? true : false}>
						{options.checkboxes && (
							<TableCell
								className="xs-body-checkbox"
								// width={width}
								// style={cursorStyle}
								key={`${row[options.checkboxColumn]}`}
								// onClick={() => (
								// 	isRowClick ? options.onRowClick(row) : null,
								// 	columnDef.type !== "action" && isSafe(options) && isSafe(options.selectRow) && options.selectRow
								// 		? this.selectRow(idx)
								// 		: null
								// )}
							>
								<Checkbox
									checked={this.state.checkboxValues.includes(row[options.checkboxColumn])}
									classes={{
										root: "checkboxInTable"
									}}
									className="checkboxInTable"
									onChange={(e) => {
										let value = e.target.checked
										let checkboxValues = this.state.checkboxValues

										if (value) {
											checkboxValues.push(row[options.checkboxColumn])
											this.setState(
												{checkboxValues: checkboxValues, checkAll: this.data.length == checkboxValues.length},
												() => options.onClickCheckbox(this.state.checkboxValues)
											)
										} else {
											this.setState(
												{
													checkboxValues: this.state.checkboxValues.filter((val) => val != row[options.checkboxColumn]),
													checkAll: false
												},
												() => options.onClickCheckbox(this.state.checkboxValues)
											)
										}
									}}
								/>
							</TableCell>
						)}

						{Object.keys(columnDefs).map((column, i) => {
							const columnDef = columnDefs[column]

							const CellTemplate =
								isSafe(columnDef.design) && isSafe(columnDef.design.body) && isSafe(columnDef.design.body.renderer)
									? columnDef.design.body.renderer
									: null
							let value =
								isSafe(columnDef.design) && isSafe(columnDef.design.body) && isSafe(columnDef.design.body.formatter)
									? columnDef.design.body.formatter(row[column])
									: row[column]
							const tooltip =
								isSafe(columnDef.design) && isSafe(columnDef.design.body) && isSafe(columnDef.design.body.tooltip)
									? columnDef.design.body.tooltip(row)
									: null
							let className =
								isSafe(columnDef.design) && isSafe(columnDef.design.body) && isSafe(columnDef.design.body.className)
									? typeof columnDef.design.body.className === "function"
										? columnDef.design.body.className(row)
										: columnDef.design.body.className
									: ""
							const width = isSafe(columnDef.design) && isSafe(columnDef.design.width) ? columnDef.design.width : null //`${defaultWidth}%`
							const cellStyle =
								isSafe(columnDef.design) && isSafe(columnDef.design.cellStyle) ? columnDef.design.cellStyle : {}
							const isRowClick =
								columnDef.type !== "action" &&
								isSafe(options) &&
								isSafe(options.onRowClick) &&
								typeof options.onRowClick === "function"
									? true
									: false
							const isRowClickSelect =
								isSafe(options) && isSafe(options.onRowClick) && typeof options.onRowClick === "function" ? true : false
							const cursorStyle = isRowClick ? {...cellStyle, cursor: "pointer"} : {...cellStyle}

							const selectRowClassName =
								isSafe(options) &&
								isSafe(options.selectRow) &&
								options.selectRow &&
								isSafe(this.state.selectedRow) &&
								this.state.selectedRow === idx
									? "xs-selected-row"
									: ""

							const isActionCell = columnDef.type == "action" ? "xs-action-cell" : ""

							if (
								isNotEmpty(options.name) &&
								TableStore.tables.hasOwnProperty(options.name) &&
								isNotEmpty(TableStore.tables[options.name].index) &&
								idx == TableStore.tables[options.name].index
							) {
								className += " lastSelectedRow"
							}

							if (isSafe(tooltip)) {
								return (
									<Tooltip key={`${idx}_${i}`} title={tooltip} enterDelay={750} placement="bottom-start">
										<TableCell
											className={`${className} ${selectRowClassName} ${isActionCell} ${
												isNotEmpty(options.reverseColor) ? "bgGrey" : ""
											}`}
											width={width}
											style={cursorStyle}
											onClick={() => (
												isRowClick ? options.onRowClick(row) : null,
												columnDef.type !== "action" && isSafe(options) && isSafe(options.selectRow) && options.selectRow
													? this.selectRow(idx)
													: null,
												isRowClickSelect && isNotEmpty(options.name)
													? (TableStore.tables[options.name] = {
															index: idx,
															rowsPerPage: rowsPerPage,
															page: page,
															rows: this.data.length
													  })
													: null
											)}
										>
											{isSafe(CellTemplate) ? <CellTemplate value={value} /> : value}
										</TableCell>
									</Tooltip>
								)
							} else {
								return (
									<TableCell
										className={`${className} ${selectRowClassName} ${isActionCell} ${
											isNotEmpty(options.reverseColor) ? "bgGrey" : ""
										}`}
										width={width}
										style={cursorStyle}
										key={`${idx}_${i}`}
										onClick={() => (
											isRowClick ? options.onRowClick(row) : null,
											columnDef.type !== "action" && isSafe(options) && isSafe(options.selectRow) && options.selectRow
												? this.selectRow(idx)
												: null,
											isRowClickSelect && isNotEmpty(options.name)
												? (TableStore.tables[options.name] = {
														index: idx,
														rowsPerPage: rowsPerPage,
														page: page,
														rows: this.data.length
												  })
												: null
										)}
									>
										{isSafe(CellTemplate) ? <CellTemplate value={value} /> : value}
									</TableCell>
								)
							}
						})}
					</TableRow>
				)
			})
		} else {
			this.tableBodyRows.push(
				<TableRow key={1}>
					<TableCell className={`xs-table-no-data ${isNotEmpty(options.reverseColor) ? "bgGrey" : ""}`}>
						<i className="far fa-lg fa-empty-set mr-3"></i>
						<FormattedMessage id="Table.NoData" />
					</TableCell>
				</TableRow>
			)
		}
	}

	tableFooterJSX
	tableFooter() {
		const {options} = this.props.config
		const {rowsPerPage, page} = this.state
		this.tableFooterJSX = null

		if (!options.hidePager /* && this.data.length > rowsPerPage*/) {
			this.tableFooterJSX = (
				<div className="xs-grid-footer">
					<TablePagination
						component="div"
						className="xs-table-pagination"
						count={this.data.length}
						rowsPerPage={rowsPerPage}
						rowsPerPageOptions={
							isSafe(options) && isSafe(options.paging) && isSafe(options.paging.rowsPerPageOptions)
								? options.paging.rowsPerPageOptions
								: [5, 10, 25, 50]
						}
						page={page}
						labelRowsPerPage={<FormattedMessage id="Table.Pagination.RowPerPage" />}
						labelDisplayedRows={(pager) =>
							`${pager.from}-${pager.to} ${this.props.intl.formatMessage({id: "Table.Pagination.Of"})} ${pager.count}`
						}
						onChangePage={this.handleChangePage}
						onChangeRowsPerPage={this.handleChangeRowsPerPage}
						ActionsComponent={TablePaginationActions}
					/>
				</div>
			)
		}
	}

	render() {
		if (this.reloadGrid) {
			if (isSafe(this.props.config.dataSource)) {
				if (typeof this.props.config.dataSource === "function") {
					this.data = this.props.config.dataSource()
				} else {
					this.data = this.props.config.dataSource.slice()
				}
			} else {
				this.data = []
			}
		}

		this.tableHeader()

		this.tableBody()

		this.tableFooter()

		return (
			<div className="xs-grid">
				{this.props.config.options.pagerOnTop === true && this.tableFooterJSX}
				{!this.props.config.options.hideHeader && <Table className="xs-grid-header">{this.tableHeaderJSX}</Table>}
				<div className="xs-grid-body">
					<Table>
						<TableBody>{this.tableBodyRows}</TableBody>
					</Table>
				</div>
				{this.props.config.options.pagerOnBottom !== false && this.tableFooterJSX}
			</div>
		)
	}
}

export default XsTable
