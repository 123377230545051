import React from "react"
import moment from "moment"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import {Grid} from "@material-ui/core"

import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

import AgreementPartCopyDialog from "./agreementPartCopyDialog"

import AgreementsStore from "../../../stores/AgreementsStore"
import AgreementPartCopyStore from "../../../stores/AgreementPartCopyStore"
import AgreementAttributeStore from "../../../stores/AgreementAttributeStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

@observer
export default class AgreementPartList extends React.Component {
	constructor(props) {
		super(props)

		// if (AgreementAttributeStore.providers.length < 1) {
		//   AgreementAttributeStore.loadDepartments()
		// }
	}

	render() {
		return (
			<Grid container direction="column">
				<Grid item xs={12} className="xs-subtitle">
					<Grid container direction="row" justify="flex-end">
						<Grid item>
							<XsButton
								className="xs-primary"
								text={<FormattedMessage id="Common.form.newy" />}
								onClick={() => AgreementAttributeStore.showAttributes()}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<XsTable
						config={{
							columnDefs: {
								number: {
									title: <FormattedMessage id="Agreement.Container.Detail.PartNumber" />,
									type: "string",
									design: {
										width: "150px"
										// header: {
										//   className: "xs-text-align-right"
										// },
										// body: {
										//   className: "xs-text-align-right"
										// }
									},
									sortable: true
								},
								from: {
									title: <FormattedMessage id="Agreement.Container.Detail.From" />,
									type: "datetime",
									design: {
										width: "120px",
										body: {
											formatter: (props) => {
												return isNotEmpty(props) ? moment(props).format("DD.MM.YYYY") : ""
											}
										}
									}
									// sortable: true
								},
								to: {
									title: <FormattedMessage id="Agreement.Container.Detail.To" />,
									type: "datetime",
									design: {
										width: "120px",
										body: {
											formatter: (props) => {
												return isNotEmpty(props) ? moment(props).format("DD.MM.YYYY") : ""
											}
										}
									}
									// sortable: true
								},
								subject_matter: {
									title: <FormattedMessage id="Agreement.Container.Detail.PartSubjectMatter" />,
									type: "string",
									sortable: true
								},
								sufficient: {
									title: <FormattedMessage id="Agreement.Container.Detail.Sufficient" />,
									type: "boolean",
									design: {
										width: "100px",
										header: {
											className: "xs-text-align"
										},
										body: {
											className: "xs-text-align",
											formatter: (props) => {
												if (props) {
													return <i className="far fa-check-square fa-lg" />
												} else {
													return <i className="far fa-square fa-lg" />
												}
											}
										}
									}
								},
								clone: {
									title: <FormattedMessage id="Agreement.Container.Detail.Copy" />,
									type: "action",
									design: {
										width: "100px",
										header: {
											className: "xs-text-align"
										},
										body: {
											className: "xs-text-align",
											renderer: (props) => {
												return (
													<XsIconButton
														className="xs-dialog-header"
														icon={<i className="far fa-copy fa-lg" />}
														onClick={() => AgreementPartCopyStore.open(props.value)}
													/>
												)
											}
										}
									}
								},
								delete: {
									title: "",
									type: "action",
									design: {
										width: "50px",
										header: {
											className: "xs-text-align"
										},
										body: {
											className: "xs-text-align",
											renderer: (props) => {
												return (
													<XsIconButton
														className="action-delete"
														icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
														onClick={() =>
															GlobalStore.openConfirmationDialog("xsDeleteAgreementPart", {partId: props.value})
														}
													/>
												)
											}
										}
									}
								}
							},
							options: {
								selectRow: true,
								showCursor: true,
								onRowClick: (dataRow) => {
									AgreementAttributeStore.showAttributes(dataRow.id)
								},
								paging: {
									rowsPerPage: 5,
									rowsPerPageOptions: [5, 10]
								},
								mapper: (dataRow) => {
									return {
										number: dataRow.number,
										from: isSafe(dataRow.validity) && isSafe(dataRow.validity.from) ? dataRow.validity.from : "",
										to: isSafe(dataRow.validity) && isSafe(dataRow.validity.to) ? dataRow.validity.to : "",
										subject_matter: dataRow.subject_matter,
										sufficient: dataRow.sufficient,
										attributes: dataRow.attributes,
										id: dataRow._id,
										clone: dataRow,
										delete: dataRow._id
									}
								}
							},
							dataSource: AgreementsStore.agreementParts //DataStore.patientDTO.get("capitationsDTO") //this.getData()
						}}
					/>
				</Grid>
				<AgreementPartCopyDialog />
				<XsConfirmationDialog
					name="xsDeleteAgreementPart"
					text={<FormattedMessage id="Agreement.Part.RemovePart.Message" />}
					type="danger"
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.btn.agreementPart.delete"
					}}
					onConfirmation={() => AgreementAttributeStore.removePart()}
				/>
			</Grid>
		)
	}
}
