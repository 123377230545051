import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import {Grid, InputAdornment} from "@material-ui/core"
import {withStyles} from "@material-ui/core/styles"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"

// special import for Material-UI binding
import XsTable from "../../../../../../global/ui/xsTable/xsTable"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import CodeListStore from "../../../../stores/CodeListStore"
import AddIcon from "@material-ui/icons/Add"
import CheckIcon from "@material-ui/icons/Check"

import moment from "moment"
import XsButton from "../../../../../../global/ui/xsButton/xsButton"
import ProvidedServicesForm from "./providedServicesForm/providedServicesForm"
import CopyServicesDialogStore from "../../../../stores/CopyServicesDialogStore"
import CopyServicesDialog from "./copyServicesDialog"
import {getUserAccessRights} from "../../../../../../global/helpers/actions"

const styles = () => ({
	searchIcon: {
		marginTop: "14px"
	}
})

@withStyles(styles)
@injectIntl
@observer
export default class ProvidedServicesList extends React.Component {
	constructor(props) {
		super(props)
		CodeListStore.set("showProvidedServicesForm", false)
		CodeListStore.loadCompanyServices()

		this.tablePage = null
		this.tableRowsPerPage = null
		this.tableRef = null
		CopyServicesDialogStore.checkedResourcesIds = []

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					name_ext: {
						label: " "
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)
	}

	onHandleRowClick = (dataRow) => {
		CodeListStore.set("selectedProvidedServiceID", dataRow.id)
		CodeListStore.set("selectedServiceID", dataRow.service_id)
		CodeListStore.set("showProvidedServicesForm", true)
	}

	onHandleNewButtonClick = () => {
		CodeListStore.set("selectedProvidedServiceID", null)
		CodeListStore.set("selectedServiceID", null)
		CodeListStore.set("showProvidedServicesForm", true)
	}

	render() {
		const dateFormat = this.props.intl.formatMessage({id: "Application.moment.dateformat"})

		return (
			<div className="xs-providedservices-list">
				<Grid container>
					{!CodeListStore.showProvidedServicesForm && (
						<Grid container justify="space-between">
							<Grid item container xs={5}>
								<Grid item xs={7} className="search">
									<XsInput
										white
										clearText={() => CodeListStore.filterProvidedServices("")}
										field={this.form.$("name_ext")}
										placeholder={"Vyhľadávanie..."}
										onChange={(val) =>
											delayedCallback(350, () => {
												CodeListStore.filterProvidedServices(val)
											})
										}
										startAdornment={
											<InputAdornment position="start" className="xs-search-adornment">
												<i className="fal fa-search pt-1 pl-2" />
											</InputAdornment>
										}
									/>
								</Grid>
							</Grid>
							<Grid item container xs={7} justify="flex-end" spacing={8}>
								<Grid item>
									<XsButton
										className="xs-success"
										text={<FormattedMessage id="Admin.codelist.providedservicesList.new" />}
										icon={<AddIcon />}
										onClick={this.onHandleNewButtonClick}
									/>
								</Grid>
								<Grid item>
									{getUserAccessRights("SRVZO.API.REST.MAIN./service/providedservices-copy") && (
										<XsButton
											className="xs-success"
											disabled={!CopyServicesDialogStore.checkedResourcesIds.length}
											text={<FormattedMessage id="Common.label.copy" />}
											icon={<i className="fas fa-copy"></i>}
											onClick={() => CopyServicesDialogStore.open()}
										/>
									)}
								</Grid>
							</Grid>
						</Grid>
					)}
					{CodeListStore.showProvidedServicesForm && <ProvidedServicesForm />}
					{!CodeListStore.showProvidedServicesForm && (
						<XsTable
							setRef={(r) => {
								this.tableRef = r
							}}
							config={{
								columnDefs: {
									item_order: {
										title: <FormattedMessage id="Food.FoodContainer.order" />,
										type: "number",
										design: {
											width: "10%"
										},
										sortable: true
									},
									service_name_ext: {
										title: <FormattedMessage id="Calendar.addEvent.dialog.service" />,
										type: "string",
										design: {
											width: "40%"
										},
										sortable: true
									},
									duration: {
										title: <FormattedMessage id="Admin.codelist.providedservicesList.duration" />,
										type: "number",
										design: {
											width: "15%",
											body: {
												formatter: (props) => {
													return props / 60
												}
											}
										},
										sortable: true
									},
									valid_from: {
										title: <FormattedMessage id="Patient.form.patientPrescription.exeptionValidityFrom" />,
										type: "datetime",
										design: {
											width: "10%",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format(dateFormat) : ""
												}
											}
										},
										sortable: true
									},
									valid_to: {
										title: <FormattedMessage id="Patient.form.patientPrescription.validityTo" />,
										type: "datetime",
										design: {
											width: "10%",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format(dateFormat) : ""
												}
											}
										},
										sortable: true
									},
									default: {
										title: <FormattedMessage id="Admin.codelist.providedservicesList.defaultServices" />,
										type: "boolean",
										design: {
											cellStyle: {textAlign: "center"},
											width: "15%",
											body: {
												formatter: (props) => {
													return props ? <CheckIcon /> : ""
												}
											}
										},
										sortable: true
									}
								},
								options: {
									showCursor: true,
									selectRow: true,
									defaultSort: {columnName: "item_order", sortDirection: "asc"},
									paging: {
										page: isSafe(this.tablePage) ? this.tablePage : undefined,
										rowsPerPage: isSafe(this.tableRowsPerPage) ? this.tableRowsPerPage : 50
									},
									checkboxes: true,
									checkboxColumn: "id",
									checkAllDataColumn: "provided_service_id",
									onClickCheckbox: (data) => {
										CopyServicesDialogStore.checkedResourcesIds = data
									},
									onRowClick: (dataRow) => {
										this.tablePage = this.tableRef.state.page
										this.tableRowsPerPage = this.tableRef.state.rowsPerPage
										this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										const ps = dataRow.provided_service
										return {
											id: ps._id,
											item_order: ps.item_order,
											service_id: isSafe(ps.service) ? ps.service._id : null,
											service_name_ext: isSafe(ps.service) ? ps.service.name_ext : "",
											duration: ps.duration,
											valid_from: ps.valid_from,
											valid_to: ps.valid_to,
											default: ps.default,
											resource_id: isSafe(ps.provider) ? ps.provider._id : null
										}
									}
								},
								dataSource: CodeListStore.providedServices
							}}
						/>
					)}
				</Grid>
				<CopyServicesDialog />
			</div>
		)
	}
}
