import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import {TableHead, Table, TableBody, TableCell, TableRow} from "@material-ui/core"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"

import PricePerCapitationForm from "./pricePerCapitationForm"

import AgreementAttributeStore from "../../../stores/AgreementAttributeStore"
import {TextField} from "@material-ui/core"

@observer
export default class PricePerCapitationGrid extends React.Component {
	constructor(props) {
		super(props)
	}

	sortable = []

	tableBodyRows
	tableBody() {
		this.tableBodyRows = []
		if (
			isSafe(AgreementAttributeStore.attributeDataJSON) &&
			AgreementAttributeStore.attributeDataJSON.hasOwnProperty(AgreementAttributeStore.typeFilter) &&
			Object.keys(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]).length > 0
		) {
			if (!AgreementAttributeStore.isEditAttributeGrid) {
				this.sortable = []

				for (let key in AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]) {
					this.sortable.push([key, AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][key]])
				}

				this.sortable.sort(function(a, b) {
					if (+a[1].age_from < +b[1].age_from) {
						return -1
					}
					if (+a[1].age_from > +b[1].age_from) {
						return 1
					}
					return 0
				})
			}

			this.sortable.forEach((row, idx) => {
				let rowId = row[0]
				if (AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].active) {
					this.tableBodyRows.push(
						<TableRow key={idx}>
							<TableCell className="xs-text-right xs-col-3">
								{AgreementAttributeStore.isEditAttributeGrid ? (
									<TextField
										value={
											AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].age_from
										}
										onChange={(e) =>
											AgreementAttributeStore.changedAttribute(
												AgreementAttributeStore.typeFilter,
												rowId,
												"age_from",
												e.target.value
											)
										}
										InputProps={{
											className: "xs-input"
										}}
									/>
								) : (
									AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].age_from
								)}
							</TableCell>
							<TableCell className="xs-text-right xs-col-3">
								{AgreementAttributeStore.isEditAttributeGrid ? (
									<TextField
										value={AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].age_to}
										onChange={(e) =>
											AgreementAttributeStore.changedAttribute(
												AgreementAttributeStore.typeFilter,
												rowId,
												"age_to",
												e.target.value
											)
										}
										InputProps={{
											className: "xs-input"
										}}
									/>
								) : (
									AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].age_to
								)}
							</TableCell>
							<TableCell className="xs-col-3">
								{AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].age_unit}
							</TableCell>
							<TableCell className="xs-text-right xs-col-3">
								{AgreementAttributeStore.isEditAttributeGrid ? (
									<TextField
										value={
											AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
												.price_in_currency
										}
										onChange={(e) =>
											AgreementAttributeStore.changedAttribute(
												AgreementAttributeStore.typeFilter,
												rowId,
												"price_in_currency",
												e.target.value
											)
										}
										InputProps={{
											classes: {
												root: !isPrice(
													AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
														.price_in_currency
												)
													? "xs-input-error xs-input"
													: "xs-input"
											}
										}}
									/>
								) : (
									priceFormat(
										AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
											.price_in_currency
									)
								)}
							</TableCell>
							{AgreementAttributeStore.isEditAttributeGrid && (
								<TableCell className="xs-text-center xs-delete-action">
									<XsIconButton
										icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
										onClick={
											() =>
												AgreementAttributeStore.changedAttribute(
													AgreementAttributeStore.typeFilter,
													rowId,
													"active",
													false
												) /*GlobalStore.openConfirmationDialog("xsDeleteAgreementAttribute", { attributeId: AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].id })*/
										}
									/>
								</TableCell>
							)}
						</TableRow>
					)
				}
			})
		} else {
			this.tableBodyRows.push(
				<TableRow key={1}>
					<TableCell className="xs-table-no-data" colSpan={4}>
						<FormattedMessage id="Table.NoData" />
					</TableCell>
				</TableRow>
			)
		}
	}

	render() {
		this.tableBody()

		return (
			<div className="xs-table-ex">
				<div className="xs-table">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell className="xs-text-right">
									<FormattedMessage id="Agreement.Container.Detail.Attribute.AgeFrom" />
								</TableCell>
								<TableCell className="xs-text-right">
									<FormattedMessage id="Agreement.Container.Detail.Attribute.AgeTo" />
								</TableCell>
								<TableCell>
									<FormattedMessage id="Common.label.unit" />
								</TableCell>
								<TableCell className="xs-text-right">
									<FormattedMessage id="Agreement.Container.Detail.Attribute.Currency" />
								</TableCell>
								{AgreementAttributeStore.isEditAttributeGrid && <TableCell />}
							</TableRow>
						</TableHead>
						<TableBody>{this.tableBodyRows}</TableBody>
					</Table>
				</div>
				<div className="xs-attribute-form">
					<PricePerCapitationForm />
				</div>
			</div>
		)
	}
}
