import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import {FormattedMessage, injectIntl} from "react-intl"

import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../../global/ui/xsButton/xsButton"
import SearchSelect from "../../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"

import Grid from "@material-ui/core/Grid"

import fields from "./employeeMedicalWorkerFormFields"
import dataBindings from "./employeeMedicalWorkerFormBindings"
import {insertFormDataByScheme} from "../../../../../../global/helpers/bindings"

import EmployeesDetailStore from "../../../../stores/EmployeesDetailStore"
import GlobalStore from "../../../../../../global/store/GlobalStore"

@injectIntl
@observer
export default class EmployeeMedicalWorkerForm extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit(/*form*/) {
				// special validation here
			},
			onSuccess(form) {
				let identifiers = []
				if (isNotEmpty(form.$("JRUZid").value)) {
					identifiers.push({
						_type: "IXS.Data.Object.Identifier",
						type: "JRUZID",
						_ref: false,
						active: true,
						value: form.$("JRUZid").value
					})
				}
				insertFormDataByScheme(
					form,
					dataBindings,
					{personnelId: props.personnelId, entityId: props.entityId, identifiers: identifiers},
					() => {
						if (isEmpty(props.personnelId)) {
							EmployeesDetailStore.loadEmployeeDetail(props.entityId)
						}
					},
					false,
					"Common.notification.save"
				)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		// const hooksJURZid = {
		//   onSubmit(/*form*/) {
		//     // special validation here
		//   },
		//   onSuccess(form) {
		//     saveFormDataByScheme(form, dataBindingsJURZid, { personnelId: props.personnelId, entityId: props.entityId }, () => { }, false, "Common.notification.save")
		//   },
		//   onError(form) {
		//   }
		// }

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks})
		// this.formJURZid = new MobxReactForm(fieldsJURZid.load(), { plugins: { dvr: validatorjs }, hooksJURZid })
		GlobalStore.refreshCodeLists(["personnelCategory"])
		EmployeesDetailStore.insertDataToEmployeeWorker(this.form, /*this.formJURZid,*/ props.personnelId)
		// loadDataToFormByScheme(this.formJURZid, dataBindingsJURZid, { personnelId: props.personnelId, entityId: props.entityId })
	}

	render() {
		return (
			<Grid container spacing={8} className="mb-6">
				<Grid item xs={2}>
					<SearchSelect
						disabled={!EmployeesDetailStore.editMedicalWorkerForm}
						field={this.form.$("category")}
						items={GlobalStore.CL["personnelCategory"]}
						required
					/>
				</Grid>
				<Grid item xs={1}>
					<XsDateTimePicker disabled={!EmployeesDetailStore.editMedicalWorkerForm} field={this.form.$("from")} />
				</Grid>
				<Grid item xs={1}>
					<XsDateTimePicker disabled={!EmployeesDetailStore.editMedicalWorkerForm} field={this.form.$("to")} />
				</Grid>
				<Grid item xs={2}>
					<XsInput disabled={!EmployeesDetailStore.editMedicalWorkerForm} field={this.form.$("JRUZid")} />
				</Grid>
				<Grid item xs={1} className="mt-3">
					<XsButton
						className="xs-fullWidth xs-default xs-outline"
						text={<FormattedMessage id="Common.dialog.load" />}
						onClick={() => EmployeesDetailStore.GetZprId(this.form)}
					/>
				</Grid>
				<Grid item xs={5} container alignItems="center" justify="flex-end" spacing={8} className="mt-0">
					{EmployeesDetailStore.editMedicalWorkerForm ? (
						<React.Fragment>
							<Grid item>
								<XsButton
									className="xs-success"
									text={<FormattedMessage id="Common.label.save" />}
									type="submit"
									onClick={(e) => {
										this.form.onSubmit(e)
									}}
									icon={<i className="fal fa-check fa-lg" />}
								/>
							</Grid>
							<Grid item>
								<XsButton
									className="xs-danger xs-outline"
									onClick={() => {
										EmployeesDetailStore.editMedicalWorkerForm = false
									}}
									text={<FormattedMessage id="Common.label.cancel" />}
									icon={<i className="fal fa-times fa-lg" />}
								/>
							</Grid>
						</React.Fragment>
					) : (
						<Grid>
							<XsButton
								className="xs-primary xs-outline"
								text={<FormattedMessage id="Common.label.edit" />}
								type="submit"
								onClick={() => (EmployeesDetailStore.editMedicalWorkerForm = true)}
								icon={<i className="fal fa-pencil-alt fa-lg" />}
							/>
						</Grid>
					)}
				</Grid>
			</Grid>
		)
	}
}
