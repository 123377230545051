import React from "react"
import {observer} from "mobx-react"
import Grid from "@material-ui/core/Grid"
import UIStore from "../../../stores/UIStore"

@observer
export default class NCZIReportList extends React.Component {
	constructor(props) {
		UIStore.isFormSaving = false
		super(props)
	}

	render() {
		return (
			<Grid container direction="column" className="xs-ncziReport-list">
				<Grid item xs={12}>
					<a href="https://iszi.nczisk.sk/iszi/login?redirectTo=%2Finbox" rel="noopener noreferrer" target="_blank">
						ISZI Prehľad (Výkazy, Hlásenia, Registre...)
					</a>
				</Grid>
			</Grid>
		)
	}
}
