"use strict"

import React from "react"
import ReactTooltip from "react-tooltip"
import {FormattedMessage} from "react-intl"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsButton from "../../../../../global/ui/xsButton/xsButton"

import {
	patientContactPersonBinding,
	contactPersonPortfolioBinding,
	contactPersonPortfolioDeleteBinding,
	contactPersonPPhyAddressBinding
} from "./patientInfoBindings"
import {
	loadDataToFormByScheme,
	saveFormDataByScheme,
	deleteDataByScheme,
	insertFormDataByScheme
} from "../../../../../global/helpers/bindings"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"

import Grid from "@material-ui/core/Grid"
import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"

import fields from "./contactPersonFields"
import UIStore from "../../../stores/UIStore"
import DataStore from "../../../stores/DataStore"
import ContactPersonFormStore from "../../../stores/ContactPersonFormStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
// import WarningStore from "../../../../../global/store/WarningStore"
import config from "../../../../../global/config/settings"

import {ixsIsEmpty} from "../../../../../global/helpers/functions"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"

import api from "../../../actions/api"
import {GWApiCall} from "../../../../../global/helpers/api"
import {getUserPersonnelID, getSelectedOrgUnit, getUser} from "../../../../../global/helpers/actions"

@observer
export default class ContactPersonTableEx extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {
				UIStore.isFormSaving = true
			},
			onSuccess(form) {
				const typeID = form.$("type").get("value")
				if (typeID === "PERS:PPHY") {
					//Nový záznam preferovaného zdravotného pracovníka
					if (ixsIsEmpty(ContactPersonFormStore.contactPersonID)) {
						insertFormDataByScheme(
							form,
							contactPersonPortfolioBinding,
							{
								patientID: DataStore.patientDTO.get("patientID"),
								contactPersonID: form.$("doctorPersonnelId").get("value"),
								typeID: typeID,
								specRelType: "EHR.Data.Entity.Personnel"
							},
							() => {
								hooks.updateAddress(form, contactPersonPPhyAddressBinding)
							},
							false,
							"Patient.form.patientcontactperson.savesuccess"
						)
					} else {
						hooks.updateAddress(form, contactPersonPPhyAddressBinding)
					}
				} else {
					//Nový záznam
					if (ixsIsEmpty(ContactPersonFormStore.contactPersonID)) {
						insertFormDataByScheme(form, patientContactPersonBinding, {}, (r) => {
							insertFormDataByScheme(
								form,
								contactPersonPortfolioBinding,
								{
									patientID: DataStore.patientDTO.get("patientID"),
									contactPersonID: r.specific_rels[0]._id,
									typeID: typeID,
									specRelType: "Entity.Data.SpecificRelRelated"
								},
								() => {
									DataStore.getPatientContactPersons()
									UIStore.isFormSaving = false
									ContactPersonFormStore.reset()
									ContactPersonFormStore.isAdd = false
								},
								false,
								"Patient.form.patientcontactperson.savesuccess"
							)
						})
					}
					//Update existujúceho záznamu
					else {
						saveFormDataByScheme(
							form,
							patientContactPersonBinding,
							{},
							() => {
								DataStore.getPatientContactPersons()
								UIStore.isFormSaving = false
								ContactPersonFormStore.reset()
								ContactPersonFormStore.isAdd = false
							},
							false,
							"Patient.form.patientcontactperson.editsuccess"
						)
					}
				}
			},
			updateAddress(form, binding) {
				insertFormDataByScheme(form, binding, {personnelID: form.$("doctorPersonnelId").get("value")}, () => {
					DataStore.getPatientContactPersons()
					UIStore.isFormSaving = false
					ContactPersonFormStore.reset()
					ContactPersonFormStore.isAdd = false
				})
			},
			onError() {
				UIStore.isFormSaving = false
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	componentWillUnmount() {
		ContactPersonFormStore.reset()
	}

	deleteContactPerson = () => {
		UIStore.isFormSaving = true
		let identifier = null
		let personData = DataStore.patientDTO
			.get("contactpersons")
			.find((obj) => obj._id == GlobalStore.confirmationDialogParams.contactAddressId)
		if (isSafe(personData)) {
			identifier = personData.identifiers.find((ident) => ident.type == "NCZIRECIDENT")
		}
		deleteDataByScheme(
			contactPersonPortfolioDeleteBinding,
			GlobalStore.confirmationDialogParams.contactAddressId /*ContactPersonFormStore.contactPersonSpecRelRelationID*/,
			(res) => {
				if (isSafe(identifier) && isNotEmpty(identifier.value)) {
					const selectedOrgUnit = getSelectedOrgUnit()
					const user = getUser()
					const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
					const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
					const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
					const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
					const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

					const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
						? DataStore.patientDTO.get("patient").validInfo.client
						: null
					const JRUZIDObj = isSafe(patientClientInfo.identifiers)
						? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
						: null

					GWApiCall({
						method: "POST",
						uri: `${config.GW_BASE_URL}/CancelPatientSummaryContactInformation`,
						body: JSON.stringify({
							ClassName: "ZrusKontaktneUdajePacientskehoSumaru_v3",
							ExtRecordIds: ["" + identifier.value],
							AmbeePatientId: DataStore.patientDTO.get("patientID"),
							PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
							PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
							AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
							AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
							AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
							AmbeePersonnelId: getUserPersonnelID(),
							OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
							PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
							PersonnelExpertiseJRUZVersion: specVer,
							PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
							RecordSignerData: isSafe(res.record_signer_data)
								? res.record_signer_data.map((x) => {
										return {
											Ref: x._ref,
											Type: x._type,
											RecordClass: x.record_class,
											RecordExternalId: x.record_external_id,
											RecordId: x.record_id
										}
								  })
								: null
						})
					})
						.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
						.then((data) => {
							if (isSafe(data) && isNotEmpty(data.ErrorMessage)) {
								GlobalStore.checkEHGWError(data.ErrorCode, data.ErrorMessage, data.IsEhealthException)
								// WarningStore.generateErrorMessage(data.IsEhealthException, data.ErrorMessage)
							}
						})
						.catch()
				}
				DataStore.getPatientContactPersons()
				ContactPersonFormStore.reset()
				ContactPersonFormStore.isAdd = false
				ContactPersonFormStore.editable = false
				UIStore.isFormSaving = false
			},
			"Patient.form.patientcontactperson.deletesuccess"
		)
	}

	handleTypeChange = (value) => {
		ContactPersonFormStore.showPreferredPhy = value === "PERS:PPHY"
	}

	getDoctorAutocompleteLabel = (obj) => {
		return (
			(obj.identifier ? obj.identifier + " / " : "") +
			obj.full_name +
			" / " +
			(obj.expertise_code ? obj.expertise_code + " " : "") +
			obj.expertise
		)
	}

	onHandleRowClick = (dataRow) => {
		this.form.reset()
		ContactPersonFormStore.reset()
		ContactPersonFormStore.contactPersonID = dataRow ? dataRow.id : null
		ContactPersonFormStore.contactPersonSpecRelRelationID = dataRow ? dataRow.spec_rel_relation_id : null
		const cpTypeID = dataRow ? dataRow.type_id : null

		if (!ixsIsEmpty(ContactPersonFormStore.contactPersonID)) {
			loadDataToFormByScheme(
				this.form,
				patientContactPersonBinding,
				{contactPersonID: ContactPersonFormStore.contactPersonID},
				(r) => {
					this.form.$("type").set("value", cpTypeID)
					this.handleTypeChange(cpTypeID)
					ContactPersonFormStore.isLoaded = true
					if (ContactPersonFormStore.showPreferredPhy) {
						const pphypersonnel = r.specific_rels.find((sr) => sr._type === "EHR.Data.Entity.Personnel" && sr.active)

						if (pphypersonnel) {
							const pphexpertise = pphypersonnel.expertises.find(
								(e) => e._type === "EHR.Data.Entity.PersonnelExpertise" && e.active
							)
							if (pphexpertise) {
								this.form.$("doctorPersonnelExpertiseId").set("value", pphexpertise._id)
							}
						}
					}
					setTimeout(() => GlobalStore.handleClickFocus("contactPersonTableEx"), 500)
				}
			)
		}

		ContactPersonFormStore.isAdd = true
		ContactPersonFormStore.editable = true
	}

	render() {
		const cpType = GlobalStore.CL["relationshipType"]

		return (
			<div className="xs-contact-person-table-ex">
				<div className="xs-table">
					<XsTable
						config={{
							columnDefs: {
								type: {
									title: <FormattedMessage id="Patient.form.patientrecord.diagnosistype" />,
									type: "string",
									design: {
										width: "210px"
									},
									sortable: true
								},
								identifier: {
									title: <FormattedMessage id="Patient.labels.identifier" />,
									type: "string",
									design: {
										width: "120px"
									},
									sortable: true
								},
								name: {
									title: <FormattedMessage id="AttendeeListContainer.nameCol" />,
									type: "string",
									design: {
										width: "250px"
									},
									sortable: true
								},
								mobile: {
									title: <FormattedMessage id="Patient.form.contactForm.mobile" />,
									type: "string",
									design: {
										width: "160px"
									}
								},
								email: {
									title: <FormattedMessage id="Common.label.email" />,
									type: "string",
									design: {
										width: "250px"
									},
									sortable: true
								},
								phone: {
									title: <FormattedMessage id="Patient.form.contactForm.phone" />,
									type: "string",
									design: {
										width: "160px"
									}
								},
								address: {
									title: <FormattedMessage id="Patient.form.contactForm.address" />,
									type: "string"
								},
								action: {
									title: "",
									type: "action",
									design: {
										width: "42px",
										body: {
											renderer: (props) => {
												return (
													<XsIconButton
														className="action-delete"
														icon={
															<span>
																<i
																	className="fal fa-trash-alt fa-lg xs-greyDefault"
																	data-tip
																	data-for="removeClosePerson"
																/>
																<ReactTooltip id="removeClosePerson" effect="solid">
																	<FormattedMessage id="Common.label.removeClosePerson" />
																</ReactTooltip>
															</span>
														}
														onClick={() =>
															GlobalStore.openConfirmationDialog("xsDeletePatientContactAddress", {
																contactAddressId: props.value
															})
														}
													/>
												)
											}
										}
									}
								}
							},
							options: {
								hidePager: true,
								showCursor: true,
								onRowClick: (dataRow) => {
									this.onHandleRowClick(dataRow)
								},
								mapper: (dataRow) => {
									const cp = dataRow.specific_rel_2
									const cpContact = cp && cp.contacts ? cp.contacts.find((c) => c.primary && c.active) : null
									const cpAddress = cp && cp.addresses ? cp.addresses.find((a) => a.primary && a.active) : null
									return {
										action: dataRow._id,
										id: cp.entity ? cp.entity._id : null,
										type_id: isSafe(dataRow.rel_type) ? dataRow.rel_type._id : "",
										type: isSafe(dataRow.rel_type) ? dataRow.rel_type.name_ext : "",
										identifier: cp.entity ? cp.entity.identifier : "",
										name: cp.entity ? cp.entity.full_name : "",
										mobile: cpContact ? cpContact.mobile : "",
										email: cpContact ? cpContact.email : "",
										phone: cpContact ? cpContact.phone : "",
										address: cpAddress ? cpAddress.full_address : ""
									}
								}
							},
							dataSource: DataStore.patientDTO.get("contactpersons")
						}}
					/>
				</div>
				<div className="xs-form">
					{ContactPersonFormStore.isAdd ? (
						<div>
							{(!ContactPersonFormStore.contactPersonID && cpType) ||
							(cpType && ContactPersonFormStore.contactPersonID && ContactPersonFormStore.isLoaded) ? (
								<div>
									{UIStore.isFormSaving && <XsLoading overlay={true} />}
									<Grid container direction="row" spacing={8}>
										<Grid item xs={3}>
											<SearchSelect
												field={this.form.$("type")}
												items={cpType.filter((rt) => rt.code.startsWith("PERS:"))}
												onChange={this.handleTypeChange}
												disabled={ContactPersonFormStore.contactPersonID ? true : false}
											/>
										</Grid>
										{ContactPersonFormStore.showPreferredPhy && (
											<Grid item xs={9}>
												<XsAutocomplete
													field={this.form.$("doctorPersonnelExpertiseId")}
													minLengthForSearch="3"
													api={api.loadPersonnel}
													disabled={ContactPersonFormStore.contactPersonID ? true : false}
													filterValue={"personnel_expertise_id"}
													inputRenderer={this.getDoctorAutocompleteLabel}
													saveValue={(obj) => obj.personnel_expertise_id}
													postAction={(obj) => {
														this.form.$("doctorPersonnelId").value = obj.personnel_id
														this.form.$("doctorExpertiseId").value = obj.expertise_id

														//Load a nastavenie adresy
														if (obj && obj.personnel_id) {
															api
																.loadSpecificRel(obj.personnel_id, "L3")
																.call()
																.then((r) => {
																	const address = r.addresses.find((a) => a.active && a.type && a.type._id === "CA")
																	if (address) {
																		this.form.$("street").value = address.street_plain
																		this.form.$("houseNumber").value = address.house_number
																		this.form.$("city").value = address.city_plain
																		this.form.$("zip").value = address.zip_plain
																	}
																})
														}
													}}
													onClear={() => {
														this.form.$("doctorExpertiseId").value = ""
														this.form.$("doctorPersonnelId").value = ""
													}}
												/>
											</Grid>
										)}
										{!ContactPersonFormStore.showPreferredPhy && (
											<React.Fragment>
												<Grid item xs={3}>
													<XsInput white={true} field={this.form.$("identifier")} />
												</Grid>
												<Grid item xs={3}>
													<XsDateTimePicker
														className="xs-white-datetimepicker"
														field={this.form.$("dateOfBirth")}
														showTimeSelect={false}
													/>
												</Grid>
												<Grid item xs={3}>
													<SearchSelect
														inputWhite={true}
														field={this.form.$("gender")}
														items={GlobalStore.CL["genders"]}
														required
													/>
												</Grid>
											</React.Fragment>
										)}
									</Grid>
									{!ContactPersonFormStore.showPreferredPhy && (
										<Grid container direction="row" spacing={8}>
											<Grid item xs={3}>
												<SearchSelect
													field={this.form.$("title_before")}
													items={
														isSafe(GlobalStore.CL["titles"]) ? GlobalStore.CL["titles"].filter((obj) => obj.before) : []
													}
													getOptionLabel={(row) => row.code_ext}
												/>
											</Grid>
											<Grid item xs={3}>
												<XsInput white={true} field={this.form.$("firstname")} />
											</Grid>
											<Grid item xs={3}>
												<XsInput white={true} field={this.form.$("lastname")} />
											</Grid>
											<Grid item xs={3}>
												<SearchSelect
													field={this.form.$("title_after")}
													items={
														isSafe(GlobalStore.CL["titles"])
															? GlobalStore.CL["titles"].filter((obj) => !obj.before)
															: []
													}
													getOptionLabel={(row) => row.code_ext}
												/>
											</Grid>
										</Grid>
									)}
									<Grid container direction="row" spacing={8}>
										<Grid item xs={3}>
											<XsInput
												white={true}
												field={this.form.$("street")}
												autoFocus={true}
												ref={(input) => (GlobalStore.defaultFocusFields["contactPersonTableEx"] = input)}
											/>
										</Grid>
										<Grid item xs={1}>
											<XsInput white={true} field={this.form.$("houseNumber")} />
										</Grid>
										<Grid item xs={3}>
											<XsInput white={true} field={this.form.$("city")} />
										</Grid>
										<Grid item xs={2}>
											<XsInput white={true} field={this.form.$("zip")} />
										</Grid>
									</Grid>
									{!ContactPersonFormStore.showPreferredPhy && (
										<Grid container direction="row" spacing={8}>
											<Grid item xs={3}>
												<XsInput white={true} field={this.form.$("mobile")} />
											</Grid>
											<Grid item xs={3}>
												<XsInput white={true} field={this.form.$("email")} />
											</Grid>
											<Grid item xs={3}>
												<XsInput white={true} field={this.form.$("phone")} />
											</Grid>
											<Grid item xs={3}>
												<XsInput white={true} field={this.form.$("description")} />
											</Grid>
										</Grid>
									)}
									<Grid container direction="row" spacing={8} className="xs-action-btn">
										{!ContactPersonFormStore.editable ? (
											<XsButton
												className="xs-success mr-3"
												onClick={this.form.onSubmit}
												text={<FormattedMessage id="Common.label.add" />}
												icon={<SaveIcon />}
											/>
										) : (
											<XsButton
												className="xs-success mr-3"
												onClick={this.form.onSubmit}
												text={<FormattedMessage id="Common.label.save" />}
												icon={<SaveIcon />}
											/>
										)}
										<XsButton
											className="xs-default xs-outline"
											onClick={() => {
												this.form.reset()
												ContactPersonFormStore.reset()
												ContactPersonFormStore.isAdd = false
												ContactPersonFormStore.editable = false
											}}
											text={<FormattedMessage id="Common.label.cancel" />}
											icon={<CancelIcon />}
										/>
									</Grid>
								</div>
							) : (
								<XsLoading />
							)}
						</div>
					) : (
						<XsButton
							className="xs-default xs-outline xs-addAnother"
							icon={<i className="fal fa-plus-circle" />}
							text={<FormattedMessage id="Common.button.addAnother" />}
							onClick={() => {
								ContactPersonFormStore.isAdd = true
								ContactPersonFormStore.editable = false
								this.form.reset()
							}}
						/>
					)}
				</div>
				<XsConfirmationDialog
					name="xsDeletePatientContactAddress"
					text={<FormattedMessage id="Patient.form.patientcontactperson.deleteMessage" />}
					type="danger"
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.btn.contactPerson.delete"
					}}
					onConfirmation={() => this.deleteContactPerson()}
				/>
			</div>
		)
	}
}
