"use strict"

// default imports for all farms
import React, {useState, useEffect} from "react"
import {observer} from "mobx-react"
import api from "../../../actions/api"

// import UI controls here
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsChipForm from "../../../../../global/ui/xsChipForm/xsChipForm"
import XsChip from "../../../../../global/ui/xsChip/xsChip"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import XsTextArea from "../../../../../global/ui/xsTextArea/xsTextArea"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import AddIcon from "@material-ui/icons/Add"
import Grid from "@material-ui/core/Grid"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import Dropzone from "react-dropzone"
// import { saveAs } from "file-saver";

// definitions of form fields
// import fields from "./patientRecordFields"

import {FormattedMessage, injectIntl} from "react-intl"
import DataStore from "../../../stores/DataStore"
import UIStore from "../../../stores/UIStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import PatientRecordStore from "../../../stores/PatientRecordStore"
import RouterStore from "../../../../../global/store/RouterStore"
import {BMIForm} from "./BMIForm"

import TrashIcon from "@material-ui/icons/DeleteForever"
import SaveIcon from "@material-ui/icons/Done"
import EditIcon from "@material-ui/icons/Edit"
import CancelIcon from "@material-ui/icons/Clear"
// import PrintIcon from "@material-ui/icons/Print"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import moment from "moment"

import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import TemplateForm from "./templateForm"
import DeliveriesForm from "./deliveriesForm"
// import {SaveableForm} from "../saveableForm/saveableForm"
// import XsDropDownAction from "../../../../../global/ui/xsDropDownAction/xsDropDownAction"
import XsSearchDropDownAction from "../../../../../global/ui/xsDropDownAction/xsSearchDropDownAction"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsAutocompleteLocal from "../../../../../global/ui/xsInput/xsAutocompleteLocal"
import DoctorOUPZwarningDialogStore from "../../../stores/DoctorOUPZwarningDialogStore"
import DoctorOUPZwarningDialog from "../patientPrescriptionForm/doctorOUPZwarningDialog"
import {
	getSelectedOrgUnitID
	// getUserDoctorInfo,
	// handleInsertHelpersIntoTextArea
} from "../../../../../global/helpers/actions"
import {Chip, withStyles} from "@material-ui/core"
import ClinicalSetsDialog from "./clinicalSetsDialog"
import settings from "../../../../../global/config/settings"
import RegisterCacheStore from "../../../../../global/store/RegistersCacheStore"
import WarningStore from "../../../../../global/store/WarningStore"

const styles = () => ({
	title: {
		marginBottom: "10px",
		fontWeight: 300,
		fontSize: "20px"
	},
	chipStyle: {
		height: "auto",
		minHeight: "32px",
		whiteSpace: "normal"
	},
	chipLabel: {
		whiteSpace: "normal"
	}
})

// @observer
function PatientRecordForm(props) {
	const [disableLifeThreat, setDisableLifeThreat] = useState({})
	let [, forceRefresh] = useState()
	PatientRecordStore.forceComponentRefresh = () => {
		forceRefresh({})
	}

	/*
	"<p>Objektivny nález *</p>", type: "OF"}
	"<p>Subjektívne ťažkosti *</p>", type: "SA"}
	"<p>Odporúčanie</p>", type: "Recomm"}
	"<p>Diagnostický záver</p>", type: "DC"}
	*/

	// let dropdownRecordTemplatesAMB =
	// 	isSafe(DataStore.templatesAll) &&
	// 	isSafe(DataStore.templatesAll["RECORD"]) &&
	// 	isSafe(DataStore.templatesAll["RECORD"].records) &&
	// 	DataStore.templatesAll["RECORD"].records.length > 0
	// 		? DataStore.templatesAll["RECORD"].records
	// 				.sort((a, b) => {
	// 					if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
	// 					if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
	// 					return 0
	// 				})
	// 				.filter((val) => val.type_id == "SA" || val.type_id == "AMB")
	// 				.map((r) => {
	// 					return {
	// 						label: r.name,
	// 						itemData: r.template_stream.replace(/\\\"/g, '"'), // eslint-disable-line
	// 						action: (e) => DataStore.editTemplate(e.currentTarget, r, "SA")
	// 					}
	// 				})
	// 		: []

	let dropdownRecordTemplatesOF =
		isSafe(DataStore.templatesAll) &&
		isSafe(DataStore.templatesAll["RECORD"]) &&
		isSafe(DataStore.templatesAll["RECORD"].records) &&
		DataStore.templatesAll["RECORD"].records.length > 0
			? DataStore.templatesAll["RECORD"].records
					.sort((a, b) => {
						if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
						if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
						return 0
					})
					.filter((val) => val.type_id == "OF" || val.type_id == "AMB")
					.map((r) => {
						return {
							label: r.name,
							itemData: r.template_stream.replace(/\\\"/g, '"'), // eslint-disable-line
							action: (e) => DataStore.editTemplate(e.currentTarget, r, "OF")
						}
					})
			: []

	let dropdownRecordTemplatesIexam =
		isSafe(DataStore.templatesAll) &&
		isSafe(DataStore.templatesAll["RECORD"]) &&
		isSafe(DataStore.templatesAll["RECORD"].records) &&
		DataStore.templatesAll["RECORD"].records.length > 0
			? DataStore.templatesAll["RECORD"].records
					.sort((a, b) => {
						if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
						if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
						return 0
					})
					.filter((val) => val.type_id == "IEXAM" || val.type_id == "AMB")
					.map((r) => {
						return {
							label: r.name,
							itemData: r.template_stream.replace(/\\\"/g, '"'), // eslint-disable-line
							action: (e) => DataStore.editTemplate(e.currentTarget, r, "IEXAM")
						}
					})
			: []

	let dropdownRecordTemplatesRecomm =
		isSafe(DataStore.templatesAll) &&
		isSafe(DataStore.templatesAll["RECORD"]) &&
		isSafe(DataStore.templatesAll["RECORD"].records) &&
		DataStore.templatesAll["RECORD"].records.length > 0
			? DataStore.templatesAll["RECORD"].records
					.sort((a, b) => {
						if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
						if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
						return 0
					})
					.filter((val) => val.type_id == "Recomm" || val.type_id == "AMB")
					.map((r) => {
						return {
							label: r.name,
							itemData: r.template_stream.replace(/\\\"/g, '"'), // eslint-disable-line
							action: (e) => DataStore.editTemplate(e.currentTarget, r, "Recomm")
						}
					})
			: []

	let dropdownRecordTemplatesDC =
		isSafe(DataStore.templatesAll) &&
		isSafe(DataStore.templatesAll["RECORD"]) &&
		isSafe(DataStore.templatesAll["RECORD"].records) &&
		DataStore.templatesAll["RECORD"].records.length > 0
			? DataStore.templatesAll["RECORD"].records
					.sort((a, b) => {
						if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
						if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
						return 0
					})
					.filter((val) => val.type_id == "DC" || val.type_id == "AMB")
					.map((r) => {
						return {
							label: r.name,
							itemData: r.template_stream.replace(/\\\"/g, '"'), // eslint-disable-line
							action: (e) => DataStore.editTemplate(e.currentTarget, r, "DC")
						}
					})
			: []

	let lastBMI
	const isDraft = props.form && props.form.$("version").get("value") === "draft"

	useEffect(() => {
		const handler = function record(e) {
			if (RouterStore.location.pathname === "/patient" && UIStore.patientCardTab == "record") {
				//ulozit a zavriet record Alt + U
				if (e.altKey && e.keyCode == 85) {
					e.preventDefault()
					props.form.$("print").value = false
					PatientRecordStore.resolveChipFormOnSave()
					props.form.onSubmit(e)
				}
				//ulozit rozpisany record Alt + R
				if (e.altKey && e.keyCode == 82) {
					e.preventDefault()
					UIStore.isFormSaving = true
					props.form.$("print").value = false
					PatientRecordStore.resolveChipFormOnSave()
					const draftID = props.form.$("version").get("value") === "draft" ? props.form.$("recordId").value : null
					PatientRecordStore.checkDeliverysAndSaveForm(
						props.form,
						(response) => {
							PatientRecordStore.clearProvidedServices(RouterStore.location.search.replace(/([^0-9])+/, ""))
							props.form.$("recordId").value = response._id
							props.form.$("version").value = "draft"
						},
						true,
						draftID
					)
				}
				//ulozit a vytlacit record Alt + P
				if (e.altKey && e.keyCode == 80) {
					e.preventDefault()
					props.form.$("print").value = true
					PatientRecordStore.resolveChipFormOnSave()
					props.form.onSubmit(e)
				}
			}
		}

		document.body.addEventListener("keydown", handler)

		return function cleanUp() {
			document.body.removeEventListener("keydown", handler)
		}
	}, [])

	useEffect(() => {
		if (
			isNotEmpty(props.form.$("clinicalValuesChipsForm").value) &&
			props.form.$("clinicalValuesChipsForm").value != "{}" &&
			props.form.$("clinicalValuesChipsForm").value != "[]"
		) {
			setTimeout(() => {
				PatientRecordStore.clinicalValuesChipsForm.showForm()
			}, 0)
		}
	}, [])

	useEffect(() => {
		if (
			isSafe(props.options) &&
			isSafe(props.options.checkRecordForToday) &&
			typeof props.options.checkRecordForToday == "function" &&
			props.form.$("version").value != "draft" &&
			!PatientRecordStore.readonlyform
		) {
			props.options.checkRecordForToday()
		}
	}, [])

	const handleDeliveryChange = (form, name, life_threatening, index) => {
		let values = form.get("value")
		let stateData = disableLifeThreat
		if (isSafe(name)) {
			values.col1NameExt = name
		}
		if (values.col4 == false) {
			values.col4 = life_threatening
		}
		stateData[index] = life_threatening
		setDisableLifeThreat(stateData)
		// setState({disableLifeThreat: stateData})
		form.set(values)
	}

	const checkDisabled = (index) => {
		return disableLifeThreat[index]
	}

	const saveBMI = (clinicalValues, onlySetText = false) => {
		let color = ""
		if (clinicalValues.BMI < 18.5) {
			color = "#FFC107"
		} else if (clinicalValues.BMI >= 18.5 && clinicalValues.BMI < 25) {
			color = "#8BC34A"
		} else if (clinicalValues.BMI >= 25 && clinicalValues.BMI < 30) {
			color = "#FFC107"
		} else if (clinicalValues.BMI > 30) {
			color = "#F44336"
		}
		props.form.$(
			"OF"
		).value += `BMI = <b>${clinicalValues.BMI}</b><span style="color:${color}"> (${clinicalValues.BMILabel})</span> (${clinicalValues.height}/${clinicalValues.weight})`
		if (!onlySetText) {
			PatientRecordStore.bmiData = clinicalValues
		}
	}

	const handleClinicalValuesMeasureChange = (form) => {
		let values = form.get("value")
		let measureData =
			isSafe(GlobalStore.CL["recordMeasures"]) && isNotEmpty(GlobalStore.CL["recordMeasures"])
				? GlobalStore.CL["recordMeasures"].find((i) => i.code === values.col1)
				: null
		if (isSafe(measureData)) {
			values.col1NameExt = measureData.name_ext
			values.col3 = measureData.default_unit_id
		}
		form.set(values)
	}

	const handleDrop = (files) => {
		PatientRecordStore.dropedFiles.push(...files)
		// formChanged = true
	}

	const handleDropzoneDelete = (index) => {
		if (isSafe(PatientRecordStore.dropedFiles[index]._id)) {
			// formChanged = true
			PatientRecordStore.deleteLoadedAttachemnt(PatientRecordStore.dropedFiles[index]._id, index)
		} else {
			PatientRecordStore.dropedFiles.splice(index, 1)
			// formChanged = true
		}
	}

	const handleDropzonePreview = (index) => {
		// if(isSafe(PatientRecordStore.dropedFiles[index]) && isNotEmpty(PatientRecordStore.dropedFiles[index].api_url)){
		//   api.downloadFile(PatientRecordStore.dropedFiles[index].api_url)
		// }else{
		var FileSaver = require("file-saver") // eslint-disable-line no-undef
		FileSaver.saveAs(PatientRecordStore.dropedFiles[index], PatientRecordStore.dropedFiles[index].name)
		// }
	}

	const handleDiagnosisClear = (row, index) => {
		let data = row.value
		if (isSafe(data)) {
			data.col1NameExt = ""
			data.col4 = false
			row.set("value", data)
		}

		let stateData = disableLifeThreat
		stateData[index] = false
		setDisableLifeThreat(stateData)
		// setState({disableLifeThreat: })
	}

	const handleValidationRules = () => {
		if (isNotEmpty(props.form.$("doctorPersonnelExpertiseId").value) || isNotEmpty(props.form.$("doctorOUPZS").value)) {
			props.form.$("doctorPersonnelExpertiseId").set("rules", "required")
			props.form.$("doctorOUPZS").set("rules", "required")
			props.form.$("requestdate").set("rules", "required")
		} else {
			props.form.$("doctorPersonnelExpertiseId").set("rules", "")
			props.form.$("doctorOUPZS").set("rules", "")
			props.form.$("requestdate").set("rules", "")
		}
		if (
			isNotEmpty(props.form.$("substitutingPersonnelExpertiseId").value) ||
			isNotEmpty(props.form.$("substitutingDoctorOUPZS").value)
		) {
			props.form.$("substitutingPersonnelExpertiseId").set("rules", "required")
			props.form.$("substitutingDoctorOUPZS").set("rules", "required")
		} else {
			props.form.$("substitutingPersonnelExpertiseId").set("rules", "")
			props.form.$("substitutingDoctorOUPZS").set("rules", "")
		}
		forceRefresh({})
	}

	const generateBMIFromClinicalValues = (row) => {
		let rowData = row.value
		if (
			isEmpty(props.form.$("version").value) &&
			isSafe(rowData) &&
			(rowData.col1 === "weight" || rowData.col1 === "height")
		) {
			let formValues = PatientRecordStore.clinicalValuesChipsForm.getData() //form.get("value")
			let weight = null
			let height = null

			let dataArr = []
			Object.keys(formValues).forEach((field) => {
				dataArr[+field] = formValues[field]
			})

			if (isSafe(dataArr) && dataArr.length) {
				let weightRow = dataArr.find((row) => {
					if (isSafe(row)) {
						return row.col1 == "weight"
					}
				})
				if (isSafe(weightRow) && isNotEmpty(weightRow.col2)) {
					weight = weightRow.col2
				}

				let heightRow = dataArr.find((row) => {
					if (isSafe(row)) {
						return row.col1 == "height"
					}
				})
				if (isSafe(heightRow) && isNotEmpty(heightRow.col2)) {
					height = heightRow.col2
				}
			}

			if (isNotEmpty(weight) && isNotEmpty(height)) {
				let hm = height / 100
				const BMIresult = Math.round((weight / (hm * hm)) * 10) / 10
				if (lastBMI != BMIresult) {
					lastBMI = BMIresult
					let BMIclass = ""
					let BMIstring = ""
					if (BMIresult < 18.5) {
						BMIclass = "under"
					} else if (BMIresult >= 18.5 && BMIresult < 25) {
						BMIclass = "normal"
					} else if (BMIresult >= 25 && BMIresult < 30) {
						BMIclass = "over"
					} else if (BMIresult > 30) {
						BMIclass = "obese"
					}
					BMIstring = props.intl.formatMessage({id: "Patient.form.bmiform." + BMIclass})
					saveBMI({BMILabel: BMIstring, BMI: BMIresult, height: height, weight: weight}, true)
				}
			}
		}
	}

	const changeDateInDeliveries = () => {
		let examdate = props.form.$("examdate").value
		let deliveries = JSON.parse(props.form.$("deliveriesChipsForm").value)

		if (isSafe(examdate) && isSafe(deliveries)) {
			deliveries.forEach((row) => {
				row.col7 = moment(examdate).format(settings.DB_DATETIME_FORMAT)
			})
		}

		PatientRecordStore.deliveriesChipsForm.bindData(deliveries)
	}

	useEffect(() => {
		handleValidationRules()
	}, [])

	let pacInfo = DataStore.patientDTO.get("patient")

	const isMan = isSafe(pacInfo.gender) && pacInfo.gender._id == "M" ? true : false
	props.form.$("isMan").value = !isMan

	return (
		<section className="xs-patient-card-form xs-column">
			{UIStore.isFormSaving && <XsLoading overlay={true} />}
			<div className="xs-patient-form-actions xs-top forceButtonRight">
				{/* {!PatientRecordStore.readonlyform && (
					<XsButton
						className="xs-success button-record-save-and-print"
						text={<FormattedMessage id="Patient.form.patientinfo.saveandprint" />}
						tabindex={7}
						onClick={(e) => {
							props.form.$("print").value = true
							PatientRecordStore.resolveChipFormOnSave()
							props.form.onSubmit(e)
						}}
						icon={<PrintIcon />}
					/>
				)} */}
				{!PatientRecordStore.readonlyform && (
					<XsButton
						className="xs-success xs-outline button-record-save"
						text={<FormattedMessage id="Patient.form.patientPrescription.SaveClose" />}
						type="submit"
						tabindex={6}
						onClick={(e) => {
							props.form.$("print").value = false
							PatientRecordStore.resolveChipFormOnSave()
							props.form.onSubmit(e)
						}}
						icon={<SaveIcon />}
					/>
				)}
				{/* TLacidlo sa nezobrazuje nikdy na VACC ho netreba */}
				{!PatientRecordStore.readonlyform && 1 > 2 && (
					<XsButton
						className="xs-success xs-outline button-record-save-draft"
						text={<FormattedMessage id="Common.form.savedraft" />}
						type="submit"
						tabindex={8}
						onClick={() => {
							UIStore.isFormSaving = true
							props.form.$("print").value = false
							PatientRecordStore.resolveChipFormOnSave()
							const draftID = props.form.$("version").get("value") === "draft" ? props.form.$("recordId").value : null
							PatientRecordStore.checkDeliverysAndSaveForm(
								props.form,
								(response) => {
									PatientRecordStore.clearProvidedServices(RouterStore.location.search.replace(/([^0-9])+/, ""))
									props.form.$("recordId").value = response._id
									if (isSafe(response.specific_rels)) {
										const personel = response.specific_rels.find(
											(row) => row.specific_rel._type == "EHR.Data.Entity.Personnel"
										)
										const orgunit = response.specific_rels.find(
											(row) => row.specific_rel._type == "Entity.Data.OrgUnit"
										)
										const client = response.specific_rels.find((row) => row.specific_rel._type == "Entity.Data.Client")

										if (isSafe(personel)) {
											props.form.$("personnelSpecificRelId").value = personel._id
										}
										if (isSafe(orgunit)) {
											props.form.$("orgunitSpecificRelId").value = orgunit._id
										}
										if (isSafe(client)) {
											props.form.$("clientSpecificRelId").value = client._id
										}
									}
									props.form.$("version").value = "draft"
									//setovanie version a recordId nieje synchronne preto timeout
									setTimeout(() => {
										props.options.deleteOnlyFromStorage("RECORD")
									}, 100)
								},
								true,
								draftID
							)
						}}
						icon={<EditIcon />}
					/>
				)}
				{/* {!PatientRecordStore.readonlyform && (
					<XsButton
						className="xs-success xs-outline button-record-save-draft-and-print"
						text={<FormattedMessage id="Common.form.savedraftPrint" />}
						type="submit"
						tabindex={9}
						onClick={() => {
							props.form.$("print").value = true
							PatientRecordStore.resolveChipFormOnSave()
							const draftID = props.form.$("version").get("value") === "draft" ? props.form.$("recordId").value : null
							PatientRecordStore.checkDeliverysAndSaveForm(
								props.form,
								(response) => {
									PatientRecordStore.clearProvidedServices(RouterStore.location.search.replace(/([^0-9])+/, ""))
									props.form.$("recordId").value = response._id
									if (isSafe(response.specific_rels)) {
										const personel = response.specific_rels.find(
											(row) => row.specific_rel._type == "EHR.Data.Entity.Personnel"
										)
										const orgunit = response.specific_rels.find(
											(row) => row.specific_rel._type == "Entity.Data.OrgUnit"
										)
										const client = response.specific_rels.find((row) => row.specific_rel._type == "Entity.Data.Client")

										if (isSafe(personel)) {
											props.form.$("personnelSpecificRelId").value = personel._id
										}
										if (isSafe(orgunit)) {
											props.form.$("orgunitSpecificRelId").value = orgunit._id
										}
										if (isSafe(client)) {
											props.form.$("clientSpecificRelId").value = client._id
										}
									}
									props.form.$("version").value = "draft"
									//setovanie version a recordId nieje synchronne preto timeout
									setTimeout(() => {
										props.options.deleteOnlyFromStorage("RECORD")
									}, 100)
								},
								true,
								draftID
							)
						}}
						icon={<PrintIcon />}
					/>
				)} */}
				{isDraft && (
					<XsButton
						className="xs-danger xs-outline button-record-delete"
						text={<FormattedMessage id="Common.label.delete" />}
						type="submit"
						tabindex={10}
						onClick={() => {
							GlobalStore.openConfirmationDialog("xsDeleteRecord", {recordID: props.form.$("recordId").value})
						}}
						icon={<TrashIcon />}
					/>
				)}
				{!props.showInDialog && (
					<XsButton
						className="xs-danger xs-outline button-record-cancel"
						text={
							<FormattedMessage
								id={!PatientRecordStore.readonlyform ? "Common.form.close" : "Patient.form.patientrecord.close"}
							/>
						}
						tabindex={11}
						onClick={() => {
							props.options.onClose("RECORD", false, !PatientRecordStore.readonlyform, () => {
								window.scrollTo(0, 0)
								if (PatientRecordStore.readonlyform) {
									PatientRecordStore.closeScoring()
								}
								PatientRecordStore.reset()
								PatientRecordStore.clearProvidedServices(RouterStore.location.search.replace(/([^0-9])+/, ""))
							})
						}}
						icon={<CancelIcon />}
					/>
				)}
			</div>
			<div className="xs-patient-form xs-row">
				<div className="xs-patient-form-left" style={{width: "75%"}}>
					<div className="padding">
						<Grid container spacing={8} data={DataStore.templatesAll["RECORD"]}>
							<Grid item xs={12}>
								<XsChipForm
									ref={(chipForm) => (PatientRecordStore.diagnosisChipsForm = chipForm)}
									field={props.form.$("diagnosisChipsForm")}
									label={
										props.intl.formatMessage({id: "Patient.form.patientrecord.diagnosis"}) +
										'<span class="xs-required">*</span>' // eslint-disable-line
									}
									configuration={{
										header: {
											chipsRenderer: (key, dataRow, clickHandler) => (
												<XsChip
													key={key}
													label={isSafe(dataRow["col1NameExt"]) ? dataRow["col1NameExt"]["value"] : ""}
													onClick={(e) => clickHandler(false, e, key)}
												/>
											),
											addButtonRenderer: (clickHandler) => (
												<XsIconButton
													className="xs-success xs-outline"
													icon={<AddIcon />}
													onClick={clickHandler}
													id={"diagnosis"}
												/>
											)
										},
										columns: [
											{
												name: "col1",
												label: <FormattedMessage id="Patient.form.patientrecord.diagnosis" />,
												width: 3,
												validationRule: "required|string",
												renderer: (field, index, fieldKey, onChangeHandler) => (
													<XsAutocompleteLocal
														field={field}
														clearText
														tabindex={1}
														minLengthForSearch="3"
														ref={(input) => (GlobalStore.defaultFocusFields["patientRecordForm"] = input)}
														autoFocus={true}
														data={RegisterCacheStore.diagnosisRegister}
														inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
														saveValue={(obj) => obj.code}
														disabled={PatientRecordStore.readonlyform}
														specialDataFilter={(data) => {
															if (isSafe(index) && +index > 0) {
																return data
															} else {
																return data.filter((i) => isEmpty(i.supplementary_sign) || i.supplementary_sign == "E")
															}
														}}
														postAction={(value) => {
															PatientRecordStore.setDeliveryDiagnosis(value.code, onChangeHandler(fieldKey), props.form)
															if (+index == 0) {
																if (isSafe(props.options) && isSafe(props.options.insertDefaultDiagnosisToForms)) {
																	props.options.insertDefaultDiagnosisToForms(value)
																}
															}
															handleDeliveryChange(
																onChangeHandler(fieldKey),
																value.code_ext + "-" + value.name_ext,
																value.life_threatening,
																index
															)
															PatientRecordStore.insertDispensaryToDeliveries(props.form, value)
														}}
														onClear={() => {
															handleDiagnosisClear(onChangeHandler(fieldKey), index)
															props.form.$("hasDispensaryDiagnosis").value = false
															props.form.$("dispensarisation").value = false
														}}
														modalConfig={(textValue, clickHandler) => {
															return {
																columnDefs: {
																	code_ext: {
																		title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
																		type: "string",
																		dbName: "code_ext",
																		design: {
																			width: "150px"
																		},
																		filter: {
																			gridWidth: 3,
																			defaultValue: "",
																			renderElement: "input"
																		}
																	},
																	name_ext: {
																		title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
																		type: "string",
																		dbName: "search_column",
																		filter: {
																			gridWidth: 9,
																			defaultValue: textValue,
																			renderElement: "input"
																		}
																	}
																},
																options: {
																	showCursor: true,
																	onRowClick: (dataRow) => {
																		clickHandler(dataRow)
																	},
																	paging: {
																		rowsPerPage: 100,
																		rowsPerPageOptions: [5, 10, 25, 50, 100]
																	}
																}
															}
														}}
													/>
												)
											},
											{
												name: "col1NameExt",
												renderer: () => {},
												width: 0
											},
											{
												name: "col2",
												label: <FormattedMessage id="Patient.form.patientrecord.diagnosistype" />,
												width: 2,
												validationRule: "required|string",
												renderer: (field) => (
													<SearchSelect
														field={field}
														items={
															isSafe(GlobalStore.CL["diagnoseTypes"]) && field.path.split(".")[1] != 0
																? GlobalStore.CL["diagnoseTypes"].filter((i) => i.code !== "PRIMARY")
																: GlobalStore.CL["diagnoseTypes"]
														}
														required
														disabled={true}
													/>
												),
												defaultValue: (col, idx) => {
													if (isSafe(idx) && +idx > 0) {
														return "SECONDARY"
													} else {
														return "PRIMARY"
													}
												}
											},
											{
												name: "col3",
												label: <FormattedMessage id="Patient.form.patientrecord.clarification" />,
												width: 3,
												validationRule: "string",
												renderer: (field) => <XsInput field={field} disabled={PatientRecordStore.readonlyform} />
											},
											{
												name: "col4",
												label: <FormattedMessage id="Patient.form.patientrecord.lifethreatening" />,
												width: 2,
												validationRule: "boolean",
												type: "checkbox",
												renderer: (field, index) => (
													<XsCheckbox
														field={field}
														disabled={checkDisabled(index) || PatientRecordStore.readonlyform}
													/>
												)
											}
										],
										options: {
											disabled: PatientRecordStore.readonlyform,
											disableFirstRowDelete: true,
											isFormMode: true,
											isModalMode: false,
											hideButtons: true,
											startOpen: true,
											onFormOpen: () => {},
											onFormClose: () => {},
											onFormSave: (values) => {
												PatientRecordStore.diagnosisChips = values
											}
										},
										data: PatientRecordStore.diagnosisChips
									}}
								/>
							</Grid>
							<Grid item xs={4}>
								<XsAutocomplete
									field={props.form.$("doctorPersonnelExpertiseId")}
									minLengthForSearch="3"
									api={api.loadPersonnelAndProvider}
									clearText
									filterValue={"personnel_expertise_id"}
									addSpecialFilter={() => {
										return [
											{
												associated_column: "has_contract",
												predicate: "=",
												values: [
													{
														id_value: true
													}
												]
											}
										]
									}}
									inputRenderer={(obj, menuItem) =>
										(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem
											? "<b style=color:#00A787>"
											: "") +
										(obj.doctor_code ? obj.doctor_code + " / " : "") +
										obj.full_name +
										" / " +
										(obj.cl_expertise_code_ext ? obj.cl_expertise_code_ext + " " : "") +
										obj.cl_expertise_name_ext +
										(isNotEmpty(obj.provider_code) ? " / " + obj.provider_code : "") +
										(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem ? "</b>" : "")
									}
									saveValue={(obj) => obj.personnel_expertise_id}
									disabled={PatientRecordStore.readonlyform}
									postAction={(obj, isLoaded) => {
										if (!isLoaded) {
											props.form.$("doctorExpertiseId").value = ""
											props.form.$("doctorPersonnelId").value = ""
											PatientRecordStore.oupzsItems = []
											props.form.$("doctorOUPZS").value = isNotEmpty(obj.provider_id) ? obj.provider_id : ""
										}
										props.form.$("doctorPersonnelId").value = obj.personnel_id
										props.form.$("doctorExpertiseId").value = obj.cl_expertise_id
										PatientRecordStore.setSendingType(props.form)
										handleValidationRules()
									}}
									onClear={() => {
										props.form.$("doctorExpertiseId").value = ""
										props.form.$("doctorPersonnelId").value = ""
										props.form.$("doctorOUPZS").value = ""
										PatientRecordStore.oupzsItems = []
										handleValidationRules()
										//Ak ma vykony dialog ci ma zmenit typ odsielatela
										let data = PatientRecordStore.deliveriesChipsForm.getData()
										if (Object.keys(data).length && Object.keys(data).some((i) => data[i].colSendingType == "S")) {
											GlobalStore.openConfirmationDialog("xsOverwriteDeliveriesToDefaultSender")
										}
									}}
								/>
							</Grid>
							<Grid item xs={4}>
								{isSafe(PatientRecordStore.oupzsItems) && PatientRecordStore.oupzsItems.length > 0 ? (
									<SearchSelect
										disabled={
											isEmpty(props.form.$("doctorPersonnelExpertiseId").value) || PatientRecordStore.readonlyform
										}
										field={props.form.$("doctorOUPZS")}
										items={PatientRecordStore.oupzsItems}
										onChange={() => {
											PatientRecordStore.setSendingType(props.form)
											handleValidationRules()
										}}
										required
									/>
								) : (
									<XsAutocomplete
										field={props.form.$("doctorOUPZS")}
										minLengthForSearch="3"
										clearText
										api={api.loadProviders}
										disabled={
											isEmpty(props.form.$("doctorPersonnelExpertiseId").value) || PatientRecordStore.readonlyform
										}
										filterValue={"provider_id"}
										inputRenderer={(obj) =>
											(obj.provider_code ? obj.provider_code + " / " : "") +
											obj.company_name +
											" / " +
											(obj.expertise_code ? obj.expertise_code + " " : "") +
											obj.expertise_name_ext
										}
										saveValue={(obj) => obj.provider_id}
										postAction={() => {
											PatientRecordStore.setSendingType(props.form)
											handleValidationRules()
										}}
									/>
								)}
							</Grid>
							<Grid item xs={4}>
								<XsDateTimePicker
									field={props.form.$("requestdate")}
									showTimeSelect={false}
									disabled={PatientRecordStore.readonlyform}
									onSelect={(val) => {
										if (isSafe(val) && moment.isMoment(val) && val.isAfter(moment(), "day")) {
											WarningStore.open(
												props.intl.formatMessage({
													id: "Common.label.dateInFuture"
												})
											)
										}
									}}
								/>
							</Grid>
							<Grid item xs={4}>
								<XsAutocomplete
									field={props.form.$("substitutingPersonnelExpertiseId")}
									minLengthForSearch="3"
									clearText
									api={api.loadPersonnelAndProvider}
									filterValue={"personnel_expertise_id"}
									addSpecialFilter={() => {
										return [
											{
												associated_column: "has_contract",
												predicate: "=",
												values: [
													{
														id_value: true
													}
												]
											}
										]
									}}
									inputRenderer={(obj, menuItem) =>
										(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem
											? "<b style=color:#00A787>"
											: "") +
										(obj.doctor_code ? obj.doctor_code + " / " : "") +
										obj.full_name +
										" / " +
										(obj.cl_expertise_code_ext ? obj.cl_expertise_code_ext + " " : "") +
										obj.cl_expertise_name_ext +
										(isNotEmpty(obj.provider_code) ? " / " + obj.provider_code : "") +
										(isNotEmpty(obj.provider_code) && obj.provider_code.charAt(9) == "2" && menuItem ? "</b>" : "")
									}
									saveValue={(obj) => obj.personnel_expertise_id}
									disabled={PatientRecordStore.readonlyform}
									postAction={(obj, isLoaded) => {
										if (!isLoaded) {
											props.form.$("substitutingDoctor").value = ""
											props.form.$("substitutingExpertiseId").value = ""
											PatientRecordStore.substitutingDoctorOUPZSItems = []
											props.form.$("substitutingDoctorOUPZS").value = isNotEmpty(obj.provider_id) ? obj.provider_id : ""
										}
										props.form.$("substitutingDoctor").value = obj.personnel_id
										props.form.$("substitutingExpertiseId").value = obj.cl_expertise_id
										handleValidationRules()
									}}
									onClear={() => {
										props.form.$("substitutingDoctor").value = ""
										props.form.$("substitutingExpertiseId").value = ""
										props.form.$("substitutingDoctorOUPZS").value = ""
										PatientRecordStore.substitutingDoctorOUPZSItems = []
										handleValidationRules()
									}}
								/>
							</Grid>
							<Grid item xs={4}>
								{isSafe(PatientRecordStore.substitutingDoctorOUPZSItems) &&
								isSafe(PatientRecordStore.substitutingDoctorOUPZSItems) &&
								PatientRecordStore.substitutingDoctorOUPZSItems.length > 0 ? (
									<SearchSelect
										field={props.form.$("substitutingDoctorOUPZS")}
										disabled={
											isEmpty(props.form.$("substitutingPersonnelExpertiseId").value) || PatientRecordStore.readonlyform
										}
										items={PatientRecordStore.substitutingDoctorOUPZSItems}
										onChange={(val) => {
											if (
												isNotEmpty(props.form.$("substitutingDoctorOUPZS")) &&
												isNotEmpty(props.form.$("substitutingPersonnelExpertiseId")) &&
												isNotEmpty(val)
											) {
												DoctorOUPZwarningDialogStore.checkDoctor(
													DataStore.patientDTO.get("patientID"),
													getSelectedOrgUnitID(),
													props.form.$("substitutingPersonnelExpertiseId").value,
													val
												)
											}
											handleValidationRules()
											props.options.changePrescriptionDefaultDoctor(
												props.form.$("substitutingPersonnelExpertiseId").value,
												props.form.$("substitutingDoctor").value,
												props.form.$("substitutingExpertiseId").value,
												props.form.$("substitutingDoctorOUPZS").value
											)
										}}
										required
									/>
								) : (
									<XsAutocomplete
										field={props.form.$("substitutingDoctorOUPZS")}
										minLengthForSearch="3"
										clearText
										api={api.loadProviders}
										disabled={
											isEmpty(props.form.$("substitutingPersonnelExpertiseId").value) || PatientRecordStore.readonlyform
										}
										filterValue={"provider_id"}
										inputRenderer={(obj) =>
											(obj.provider_code ? obj.provider_code + " / " : "") +
											obj.company_name +
											" / " +
											(obj.expertise_code ? obj.expertise_code + " " : "") +
											obj.expertise_name_ext
										}
										saveValue={(obj) => obj.provider_id}
										postAction={(obj) => {
											if (
												isNotEmpty(props.form.$("substitutingDoctorOUPZS")) &&
												isNotEmpty(props.form.$("substitutingPersonnelExpertiseId"))
											) {
												DoctorOUPZwarningDialogStore.checkDoctor(
													DataStore.patientDTO.get("patientID"),
													getSelectedOrgUnitID(),
													props.form.$("substitutingPersonnelExpertiseId").value,
													obj.provider_id
												)
											}
											handleValidationRules()
										}}
									/>
								)}
							</Grid>
							<Grid item xs={4}>
								<XsDateTimePicker
									field={props.form.$("examdate")}
									showTimeSelect={true}
									disabled={PatientRecordStore.readonlyform}
									onClickOutside={() => {
										if (
											isNotEmpty(props.form.$("deliveriesChipsForm").value) &&
											props.form.$("deliveriesChipsForm").value != "{}" &&
											props.form.$("deliveriesChipsForm").value != "[]"
										) {
											GlobalStore.openConfirmationDialog("xsUpdateDeliveriesDate")
										}
										let val = props.form.$("examdate").value
										if (isSafe(val) && moment.isMoment(val) && val.isAfter(moment(), "day")) {
											WarningStore.open(
												props.intl.formatMessage({
													id: "Common.label.dateInFuture"
												})
											)
										}
									}}
								/>
							</Grid>
							<Grid item container justify="space-between" alignItems="flex-end">
								<Grid item xs={5} className="xs-bold fontSize21 pb-3">
									{props.intl.formatMessage({
										id: "Patient.form.patientrecord.clinicalvalues"
									})}
								</Grid>
								<Grid item container xs={7} spacing={8} alignItems="flex-end" justify="flex-end">
									<Grid item xs={6}>
										<SearchSelect
											field={props.form.$("clinicalValuesSets")}
											items={RegisterCacheStore.measureSetRegister}
											getOptionLabel={(obj) => obj.name}
											onChange={(obj) => {
												PatientRecordStore.setToClinivalVal(props.form, obj)
											}}
										/>
									</Grid>
									<Grid item className="pb-3">
										<XsIconButton
											rect
											className="xs-success xs-outline"
											onClick={() => {
												PatientRecordStore.clinicalSetsDialogOpen = true
											}}
											icon={<i className="fal fa-plus fa-lg"></i>}
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<XsChipForm
									ref={(chipForm) => (PatientRecordStore.clinicalValuesChipsForm = chipForm)}
									field={props.form.$("clinicalValuesChipsForm")}
									// label={`<span class="xs-bold">${props.intl.formatMessage({
									// 	// eslint-disable-line
									// 	id: "Patient.form.patientrecord.clinicalvalues"
									// })}</span>`} // eslint-disable-line
									configuration={{
										header: {
											chipsRenderer: (key, dataRow, clickHandler) => (
												<XsChip
													key={key}
													label={dataRow["col1NameExt"]["value"]}
													onClick={(e) => clickHandler(false, e, key)}
												/>
											),
											addButtonRenderer: (clickHandler) => (
												<XsIconButton
													className="xs-success xs-outline"
													icon={<AddIcon />}
													onClick={(e) => {
														if (
															isSafe(PatientRecordStore.clinicalValuesChipsForm) &&
															Object.keys(PatientRecordStore.clinicalValuesChipsForm.getData()).length === 0 &&
															PatientRecordStore.clinicalValuesChipsForm.getData().constructor === Object
														) {
															PatientRecordStore.loadClinicalValuesFromDiagnosis()
														}
														clickHandler(e)
													}}
													id={"clinical"}
												/>
											)
										},
										columns: [
											{
												name: "col1",
												label: <FormattedMessage id="Patient.form.patientrecord.measuretype" />,
												width: 3,
												validationRule: "required|string",
												renderer: (field, idx, fieldKey, onChangeHandler) => (
													<SearchSelect
														field={field}
														items={GlobalStore.CL["recordMeasures"]}
														onChange={() => handleClinicalValuesMeasureChange(onChangeHandler(fieldKey))}
														required
													/>
												)
											},
											{
												name: "col1NameExt",
												renderer: () => {},
												width: 0
											},
											{
												name: "col2",
												label: <FormattedMessage id="Patient.form.patientrecord.value" />,
												width: 2,
												validationRule: "required|string",
												defaultFocus: true,
												renderer: (field, idx, fieldKey, onChangeHandler) => (
													<XsInput
														field={field}
														ref={(input) => (PatientRecordStore.clinicalValuesChipsFormFocus = input)}
														onBlur={() => generateBMIFromClinicalValues(onChangeHandler(fieldKey))}
														tabindex={2}
													/>
												)
											},
											{
												name: "col3",
												label: <FormattedMessage id="Common.label.unit" />,
												width: 2,
												// validationRule: "required|string",
												renderer: (field) => <SearchSelect field={field} items={GlobalStore.CL["units"]} />
											},
											{
												name: "note",
												label: <FormattedMessage id="Common.label.note" />,
												width: 2,
												validationRule: "string",
												renderer: (field) => <XsInput field={field} />
											},
											{
												name: "date",
												label: <FormattedMessage id="Common.label.date" />,
												width: 2,
												validationRule: "string",
												renderer: (field) => <XsDateTimePicker field={field} showTimeSelect={true} />
											}
										],
										options: {
											disabled: false,
											isFormMode: false,
											isModalMode: false,
											hideButtons: true,
											showCancelButton: true,
											plusButtonTabindex: () => {
												return 3
											},
											onFormOpen: () => {},
											onFormClose: () => {},
											onFormSave: (values) => {
												PatientRecordStore.clinicalValuesChips = values
											}
										},
										data: PatientRecordStore.clinicalValuesChips
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<XsIconButton
									className="xs-default xs-outline xs-quil-helper-btn"
									tooltip={<FormattedMessage id="Common.label.copyCVtoRecord" />}
									icon={<i className="fas fa-file-import"></i>}
									onClick={() => {
										PatientRecordStore.generateStringToRecordTextArea(props.form)
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<XsInput clearText field={props.form.$("examinationName")} disabled={PatientRecordStore.readonlyform} />
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={8} className="pr-4" alignItems="baseline">
									<Grid item xs={12} className="xs-bold">
										Anamnestický dotazník
									</Grid>
									<Grid item xs={8}>
										Boli ste už očkovaný/á proti ochoreniu COVID-19?
									</Grid>
									<Grid item xs={2} className="pl-8">
										<XsCheckbox
											field={props.form.$("question_9_yes")}
											onChange={() => {
												props.form.$("question_9_no").value = false
												props.form.$("question_9_yes").value = true
												props.options.insertVaccDosageToMedication()
											}}
										/>
									</Grid>
									<Grid item xs={2}>
										<XsCheckbox
											field={props.form.$("question_9_no")}
											onChange={() => {
												props.form.$("question_9_yes").value = false
												props.form.$("question_9_no").value = true
												props.options.resetVaccDosageToMedication()
											}}
										/>
									</Grid>
									<Grid item xs={8}>
										Máte príznaky akútneho ochorenia (teplota nad 37 °C, kašeľ, bolesť hrdla, slabosť, malátnosť, bolesť
										kĺbov, herpes...)?
									</Grid>
									<Grid item xs={2} className="pl-8">
										<XsCheckbox
											field={props.form.$("question_1_yes")}
											onChange={() => {
												props.form.$("question_1_no").value = false
												props.form.$("question_1_yes").value = true
											}}
										/>
									</Grid>
									<Grid item xs={2}>
										<XsCheckbox
											field={props.form.$("question_1_no")}
											onChange={() => {
												props.form.$("question_1_yes").value = false
												props.form.$("question_1_no").value = true
											}}
										/>
									</Grid>
									<Grid item xs={8}>
										Trpíte závažným ochorením, ktoré bolo v minulosti posudzované ako prekážka očkovania?
									</Grid>
									<Grid item xs={2} className="pl-8">
										<XsCheckbox
											field={props.form.$("question_2_yes")}
											onChange={() => {
												props.form.$("question_2_no").value = false
												props.form.$("question_2_yes").value = true
											}}
										/>
									</Grid>
									<Grid item xs={2}>
										<XsCheckbox
											field={props.form.$("question_2_no")}
											onChange={() => {
												props.form.$("question_2_yes").value = false
												props.form.$("question_2_no").value = true
											}}
										/>
									</Grid>
									<Grid item xs={8}>
										Mali ste v minulosti závažnú alergickú reakciu – napr. anafylaktický šok?
									</Grid>
									<Grid item xs={2} className="pl-8">
										<XsCheckbox
											field={props.form.$("question_3_yes")}
											onChange={() => {
												props.form.$("question_3_no").value = false
												props.form.$("question_3_yes").value = true
											}}
										/>
									</Grid>
									<Grid item xs={2}>
										<XsCheckbox
											field={props.form.$("question_3_no")}
											onChange={() => {
												props.form.$("question_3_yes").value = false
												props.form.$("question_3_no").value = true
											}}
										/>
									</Grid>
									<Grid item xs={8}>
										Mali ste niekedy v minulosti závažné vedľajšie účinky po podaní vakcíny?
									</Grid>
									<Grid item xs={2} className="pl-8">
										<XsCheckbox
											field={props.form.$("question_4_yes")}
											onChange={() => {
												props.form.$("question_4_no").value = false
												props.form.$("question_4_yes").value = true
											}}
										/>
									</Grid>
									<Grid item xs={2}>
										<XsCheckbox
											field={props.form.$("question_4_no")}
											onChange={() => {
												props.form.$("question_4_yes").value = false
												props.form.$("question_4_no").value = true
											}}
										/>
									</Grid>
									<Grid item xs={8}>
										Máte vážnu poruchu zrážanlivosti krvi alebo beriete lieky na riedenie krvi?
									</Grid>
									<Grid item xs={2} className="pl-8">
										<XsCheckbox
											field={props.form.$("question_5_yes")}
											onChange={() => {
												props.form.$("question_5_no").value = false
												props.form.$("question_5_yes").value = true
											}}
										/>
									</Grid>
									<Grid item xs={2}>
										<XsCheckbox
											field={props.form.$("question_5_no")}
											onChange={() => {
												props.form.$("question_5_yes").value = false
												props.form.$("question_5_no").value = true
											}}
										/>
									</Grid>
									<Grid item xs={8}>
										Máte závažnú poruchu imunity?
									</Grid>
									<Grid item xs={2} className="pl-8">
										<XsCheckbox
											field={props.form.$("question_6_yes")}
											onChange={() => {
												props.form.$("question_6_no").value = false
												props.form.$("question_6_yes").value = true
											}}
										/>
									</Grid>
									<Grid item xs={2}>
										<XsCheckbox
											field={props.form.$("question_6_no")}
											onChange={() => {
												props.form.$("question_6_yes").value = false
												props.form.$("question_6_no").value = true
											}}
										/>
									</Grid>
									<Grid item xs={8}>
										Absolvovali ste v posledných dvoch týždňoch nejaké iné očkovanie?
									</Grid>
									<Grid item xs={2} className="pl-8">
										<XsCheckbox
											field={props.form.$("question_8_yes")}
											onChange={() => {
												props.form.$("question_8_no").value = false
												props.form.$("question_8_yes").value = true
											}}
										/>
									</Grid>
									<Grid item xs={2}>
										<XsCheckbox
											field={props.form.$("question_8_no")}
											onChange={() => {
												props.form.$("question_8_yes").value = false
												props.form.$("question_8_no").value = true
											}}
										/>
									</Grid>
									{!isMan && (
										<React.Fragment>
											<Grid item xs={8}>
												Ste tehotná alebo dojčíte?
											</Grid>
											<Grid item xs={2} className="pl-8">
												<XsCheckbox
													field={props.form.$("question_7_yes")}
													onChange={() => {
														props.form.$("question_7_no").value = false
														props.form.$("question_7_yes").value = true
													}}
												/>
											</Grid>
											<Grid item xs={2}>
												<XsCheckbox
													field={props.form.$("question_7_no")}
													onChange={() => {
														props.form.$("question_7_yes").value = false
														props.form.$("question_7_no").value = true
													}}
												/>
											</Grid>
										</React.Fragment>
									)}
								</Grid>
								{/* <XsTextArea
									keyVal={isNotEmpty(props.keyVal) ? props.keyVal : 1}
									field={props.form.$("record")}
									tabindex={5}
									rows={10}
									rowsId={"dekurzRecord"}
									maxLength={1000}
									disabled={PatientRecordStore.readonlyform}
									customToolbarHelpers={
										!PatientRecordStore.readonlyform && (
											<div className="xs-textarea-helpers">
												<XsSearchDropDownAction
													placeholder={"Pomôcky"}
													items={dropdownRecordTemplatesAMB}
													actionIconClassName="fal fa-pencil-alt"
													className="xs-textarea-dropdown"
													onChange={(data) => {
														if (isSafe(data)) {
															props.form.$("record").value += `<div>${data.itemData}<div>`
															delete PatientRecordStore.textAreaCursors.record
														}
													}}
												/>
											</div>
										)
									}
									customToolbar={
										!PatientRecordStore.readonlyform && (
											<div className="xs-textarea-helpers-other">
												<XsIconButton
													className="xs-default xs-outline xs-quil-helper-btn"
													icon={<AddIcon />}
													onClick={(e) => {
														DataStore.templateAnchorEl = e.currentTarget
														DataStore.openTemplateForm("SA")
													}}
												/>
											</div>
										)
									}
								/> */}
							</Grid>
							<Grid item xs={12}>
								<XsTextArea
									keyVal={isNotEmpty(props.keyVal) ? props.keyVal : 1}
									// ref={(area) => (this.OFArea = area)}
									maxLength={85000}
									field={props.form.$("OF")}
									rows={1}
									rowsId={"dekurzOF"}
									tabindex={4}
									customToolbarBmi={
										!PatientRecordStore.readonlyform && (
											<div className="xs-textarea-helpers-bmi">
												<BMIForm onSave={(clinicalValues) => saveBMI(clinicalValues)} />
											</div>
										)
									}
									customToolbarHelpers={
										!PatientRecordStore.readonlyform && (
											<div className="xs-textarea-helpers">
												<XsSearchDropDownAction
													placeholder={"Pomôcky"}
													items={dropdownRecordTemplatesOF}
													actionIconClassName="fal fa-pencil-alt"
													className="xs-textarea-dropdown"
													onChange={(data) => {
														if (isSafe(data)) {
															// if (isNotEmpty(props.form.$("OF").value) && PatientRecordStore.textAreaCursors.OF) {
															// 	handleInsertHelpersIntoTextArea(
															// 		props.form,
															// 		"OF",
															// 		PatientRecordStore.textAreaCursors.OF.cursor,
															// 		`<div>${data.itemData}<div>`
															// 	)
															// } else {
															props.form.$("OF").value += `<div>${data.itemData}<div>`
															// }
															delete PatientRecordStore.textAreaCursors.OF
														}
													}}
												/>
											</div>
										)
									}
									customToolbar={
										!PatientRecordStore.readonlyform && (
											<div className="xs-textarea-helpers-other">
												<XsIconButton
													className="xs-default xs-outline xs-quil-helper-btn"
													icon={<AddIcon />}
													onClick={(e) => {
														DataStore.templateAnchorEl = e.currentTarget
														DataStore.openTemplateForm("OF")
													}}
												/>
											</div>
										)
									}
									// shrinkMode
									// shrinkModeClass="xs-height-50"
									disabled={PatientRecordStore.readonlyform}
									onFocus={(data) => {
										if (
											data &&
											(isNotSafe(PatientRecordStore.textAreaCursors.OF) ||
												PatientRecordStore.textAreaCursors.OF.cursor != data.cursor)
										) {
											PatientRecordStore.textAreaCursors.OF = {
												cursor: data.cursor
											}
										}
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<XsTextArea
									keyVal={isNotEmpty(props.keyVal) ? props.keyVal : 1}
									// ref={(area) => (this.diagnosisSummary = area)}
									rows={5}
									rowsId={"dekurzIeaxam"}
									field={props.form.$("iexam")}
									disabled={PatientRecordStore.readonlyform}
									maxLength={5000}
									customToolbarHelpers={
										!PatientRecordStore.readonlyform && (
											<div className="xs-textarea-helpers">
												<XsSearchDropDownAction
													placeholder={"Pomôcky"}
													items={dropdownRecordTemplatesIexam}
													actionIconClassName="fal fa-pencil-alt"
													className="xs-textarea-dropdown"
													onChange={(data) => {
														if (isSafe(data)) {
															// if (isNotEmpty(props.form.$("iexam").value) && PatientRecordStore.textAreaCursors.iexam) {
															// 	handleInsertHelpersIntoTextArea(
															// 		props.form,
															// 		"iexam",
															// 		PatientRecordStore.textAreaCursors.iexam.cursor,
															// 		`<div>${data.itemData}<div>`
															// 	)
															// } else {
															props.form.$("iexam").value += `<div>${data.itemData}<div>`
															// }
															delete PatientRecordStore.textAreaCursors.iexam
														}
													}}
												/>
											</div>
										)
									}
									customToolbar={
										!PatientRecordStore.readonlyform && (
											<div className="xs-textarea-helpers-other">
												<XsIconButton
													className="xs-default xs-outline xs-quil-helper-btn"
													icon={<AddIcon />}
													onClick={(e) => {
														DataStore.templateAnchorEl = e.currentTarget
														DataStore.openTemplateForm("IEXAM")
													}}
												/>
											</div>
										)
									}
									onFocus={(data) => {
										if (
											data &&
											(isNotSafe(PatientRecordStore.textAreaCursors.iexam) ||
												PatientRecordStore.textAreaCursors.iexam.cursor != data.cursor)
										) {
											PatientRecordStore.textAreaCursors.iexam = {
												cursor: data.cursor
											}
										}
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<XsTextArea
									keyVal={isNotEmpty(props.keyVal) ? props.keyVal : 1}
									// ref={(area) => (this.diagnosisSummary = area)}
									field={props.form.$("diagnosisSummary")}
									rows={10}
									rowsId={"dekurzDiagnosisSummary"}
									maxLength={5000}
									disabled={PatientRecordStore.readonlyform}
									customToolbarHelpers={
										!PatientRecordStore.readonlyform && (
											<div className="xs-textarea-helpers">
												<XsSearchDropDownAction
													placeholder={"Pomôcky"}
													items={dropdownRecordTemplatesDC}
													actionIconClassName="fal fa-pencil-alt"
													className="xs-textarea-dropdown"
													onChange={(data) => {
														if (isSafe(data)) {
															// if (
															// 	isNotEmpty(props.form.$("diagnosisSummary").value) &&
															// 	PatientRecordStore.textAreaCursors.diagnosisSummary
															// ) {
															// 	handleInsertHelpersIntoTextArea(
															// 		props.form,
															// 		"diagnosisSummary",
															// 		PatientRecordStore.textAreaCursors.diagnosisSummary.cursor,
															// 		`<div>${data.itemData}<div>`
															// 	)
															// } else {
															props.form.$("diagnosisSummary").value += `<div>${data.itemData}<div>`
															// }
															delete PatientRecordStore.textAreaCursors.diagnosisSummary
														}
													}}
												/>
											</div>
										)
									}
									customToolbar={
										!PatientRecordStore.readonlyform && (
											<div className="xs-textarea-helpers-other">
												<XsIconButton
													className="xs-default xs-outline xs-quil-helper-btn"
													icon={<AddIcon />}
													onClick={(e) => {
														DataStore.templateAnchorEl = e.currentTarget
														DataStore.openTemplateForm("DC")
													}}
												/>
											</div>
										)
									}
								/>
							</Grid>
							<Grid item xs={12}>
								<XsTextArea
									keyVal={isNotEmpty(props.keyVal) ? props.keyVal : 1}
									// ref={(area) => (this.recommArea = area)}
									field={props.form.$("Recomm")}
									disabled={PatientRecordStore.readonlyform}
									maxLength={2000}
									rows={3}
									rowsId={"dekurzRecomm"}
									customToolbarHelpers={
										!PatientRecordStore.readonlyform && (
											<div className="xs-textarea-helpers">
												<XsSearchDropDownAction
													placeholder={"Pomôcky"}
													items={dropdownRecordTemplatesRecomm}
													actionIconClassName="fal fa-pencil-alt"
													className="xs-textarea-dropdown"
													onChange={(data) => {
														if (isSafe(data)) {
															// if (isNotEmpty(props.form.$("Recomm").value) && PatientRecordStore.textAreaCursors.Recomm) {
															// 	handleInsertHelpersIntoTextArea(
															// 		props.form,
															// 		"Recomm",
															// 		PatientRecordStore.textAreaCursors.Recomm.cursor,
															// 		`<div>${data.itemData}<div>`
															// 	)
															// } else {
															props.form.$("Recomm").value += `<div>${data.itemData}<div>`
															// }
															delete PatientRecordStore.textAreaCursors.Recomm
														}
													}}
												/>
											</div>
										)
									}
									customToolbar={
										!PatientRecordStore.readonlyform && (
											<div className="xs-textarea-helpers-other">
												<XsIconButton
													className="xs-default xs-outline xs-quil-helper-btn"
													icon={<AddIcon />}
													onClick={(e) => {
														DataStore.templateAnchorEl = e.currentTarget
														DataStore.openTemplateForm("Recomm")
													}}
												/>
											</div>
										)
									}
								/>
							</Grid>
							<Grid item xs={12}>
								{/* <XsInput field={props.form.$("note")} disabled={PatientRecordStore.readonlyform} /> */}
								<XsTextArea
									keyVal={isNotEmpty(props.keyVal) ? props.keyVal : 1}
									field={props.form.$("note")}
									maxLength={6000}
									disabled={PatientRecordStore.readonlyform}
									rows={2}
									rowsId={"dekurzNote"}
								/>
							</Grid>
							<Grid item xs={4}>
								<SearchSelect
									field={props.form.$("recordPriority")}
									items={GlobalStore.CL["priorities"]}
									onChange={(val) => {
										if (isSafe(props.options) && isSafe(props.options.insertRecordPriorityToRequest)) {
											props.options.insertRecordPriorityToRequest(val)
										}
									}}
									disabled={PatientRecordStore.readonlyform}
								/>
							</Grid>
							<Grid item xs={4}>
								<SearchSelect
									field={props.form.$("confidentialityLevel")}
									items={GlobalStore.CL["confidentialityLevel"]}
									disabled={PatientRecordStore.readonlyform || props.form.$("confidentialityLevelDisable").value}
									getOptionLabel={(obj) => obj.name_ext}
								/>
							</Grid>
							<Grid item xs={4}>
								<XsCheckbox field={props.form.$("dispensarisation")} disabled={PatientRecordStore.readonlyform} />
							</Grid>
							<Grid item xs={12}>
								<Dropzone onDrop={handleDrop} id="dropzone">
									{isSafe(PatientRecordStore.dropedFiles) && PatientRecordStore.dropedFiles.length ? (
										PatientRecordStore.dropedFiles.map((file, index) => (
											<div key={index} className={"dropedFile"}>
												<i
													className="fal fa-file icon"
													onClick={(e) => {
														e.stopPropagation()
														handleDropzonePreview(index)
													}}
												/>
												<i
													className="far fa-trash-alt deleteIcon"
													onClick={(e) => {
														e.stopPropagation()
														GlobalStore.openConfirmationDialog("xsDeleteAttachmentFromDropzone", {fileIndex: index})
													}}
												/>
												<br />
												<div
													className={"mac_ellipsis"}
													data-content-start={file.name.substr(0, Math.floor(file.name.length / 2))}
													data-content-end={"\u200E" + file.name.substr(Math.floor(file.name.length / 2)) + "\u200E"}
												/>
											</div>
										))
									) : (
										<div className={"dropzonePlaceholder"}>
											<FormattedMessage id="Common.form.attachment" />
										</div>
									)}
								</Dropzone>
							</Grid>
						</Grid>
					</div>
				</div>
				{!props.showInDialog && (
					<div className="xs-patient-form-right">
						<div className="padding">
							{PatientRecordStore.masterRecord && (
								<React.Fragment>
									<div className={props.classes.title}>
										<FormattedMessage id="Patient.form.patientrequest.parentrecord" />
									</div>
									<Chip
										label={`${moment(PatientRecordStore.masterRecord.created_at).format(
											props.intl.formatMessage({id: "Application.moment.datetimeformat"})
										)} ${PatientRecordStore.masterRecord.name}`}
										onDelete={() => (PatientRecordStore.masterRecord = null)}
										color="primary"
										classes={{
											root: props.classes.chipStyle,
											label: props.classes.chipLabel
										}}
									/>
								</React.Fragment>
							)}
							<TemplateForm
								type="AMB"
								specialLoadType={["AMB", "OF", "Recomm", "DC", "IEXAM", "SA"]}
								specialName="RECORD"
							/>
							<DeliveriesForm id={props.id} form={props.form} chipFormField={props.form.$("deliveriesChipsForm")} />
						</div>
					</div>
				)}
			</div>
			<div className="xs-patient-form-actions forceButtonRight">
				{/* {!PatientRecordStore.readonlyform && (
					<XsButton
						className="xs-success button-record-save-and-print"
						text={<FormattedMessage id="Patient.form.patientinfo.saveandprint" />}
						tabindex={7}
						onClick={(e) => {
							props.form.$("print").value = true
							PatientRecordStore.resolveChipFormOnSave()
							props.form.onSubmit(e)
						}}
						icon={<PrintIcon />}
					/>
				)} */}
				{!PatientRecordStore.readonlyform && (
					<XsButton
						className="xs-success xs-outline button-record-save"
						text={<FormattedMessage id="Patient.form.patientPrescription.SaveClose" />}
						type="submit"
						tabindex={6}
						onClick={(e) => {
							props.form.$("print").value = false
							PatientRecordStore.resolveChipFormOnSave()
							props.form.onSubmit(e)
						}}
						icon={<SaveIcon />}
					/>
				)}
				{/* TLacidlo sa nezobrazuje nikdy na VACC ho netreba */}
				{!PatientRecordStore.readonlyform && 1 > 2 && (
					<XsButton
						className="xs-success xs-outline button-record-save-draft"
						text={<FormattedMessage id="Common.form.savedraft" />}
						type="submit"
						tabindex={8}
						onClick={() => {
							UIStore.isFormSaving = true
							props.form.$("print").value = false
							PatientRecordStore.resolveChipFormOnSave()
							const draftID = props.form.$("version").get("value") === "draft" ? props.form.$("recordId").value : null
							PatientRecordStore.checkDeliverysAndSaveForm(
								props.form,
								(response) => {
									PatientRecordStore.clearProvidedServices(RouterStore.location.search.replace(/([^0-9])+/, ""))
									props.form.$("recordId").value = response._id
									if (isSafe(response.specific_rels)) {
										const personel = response.specific_rels.find(
											(row) => row.specific_rel._type == "EHR.Data.Entity.Personnel"
										)
										const orgunit = response.specific_rels.find(
											(row) => row.specific_rel._type == "Entity.Data.OrgUnit"
										)
										const client = response.specific_rels.find((row) => row.specific_rel._type == "Entity.Data.Client")

										if (isSafe(personel)) {
											props.form.$("personnelSpecificRelId").value = personel._id
										}
										if (isSafe(orgunit)) {
											props.form.$("orgunitSpecificRelId").value = orgunit._id
										}
										if (isSafe(client)) {
											props.form.$("clientSpecificRelId").value = client._id
										}
									}
									props.form.$("version").value = "draft"
									//setovanie version a recordId nieje synchronne preto timeout
									setTimeout(() => {
										props.options.deleteOnlyFromStorage("RECORD")
									}, 100)
								},
								true,
								draftID
							)
						}}
						icon={<EditIcon />}
					/>
				)}
				{/* {!PatientRecordStore.readonlyform && (
					<XsButton
						className="xs-success xs-outline button-record-save-draft-and-print"
						text={<FormattedMessage id="Common.form.savedraftPrint" />}
						type="submit"
						tabindex={9}
						onClick={() => {
							props.form.$("print").value = true
							PatientRecordStore.resolveChipFormOnSave()
							const draftID = props.form.$("version").get("value") === "draft" ? props.form.$("recordId").value : null
							PatientRecordStore.checkDeliverysAndSaveForm(
								props.form,
								(response) => {
									PatientRecordStore.clearProvidedServices(RouterStore.location.search.replace(/([^0-9])+/, ""))
									props.form.$("recordId").value = response._id
									if (isSafe(response.specific_rels)) {
										const personel = response.specific_rels.find(
											(row) => row.specific_rel._type == "EHR.Data.Entity.Personnel"
										)
										const orgunit = response.specific_rels.find(
											(row) => row.specific_rel._type == "Entity.Data.OrgUnit"
										)
										const client = response.specific_rels.find((row) => row.specific_rel._type == "Entity.Data.Client")

										if (isSafe(personel)) {
											props.form.$("personnelSpecificRelId").value = personel._id
										}
										if (isSafe(orgunit)) {
											props.form.$("orgunitSpecificRelId").value = orgunit._id
										}
										if (isSafe(client)) {
											props.form.$("clientSpecificRelId").value = client._id
										}
									}
									props.form.$("version").value = "draft"
									//setovanie version a recordId nieje synchronne preto timeout
									setTimeout(() => {
										props.options.deleteOnlyFromStorage("RECORD")
									}, 100)
								},
								true,
								draftID
							)
						}}
						icon={<PrintIcon />}
					/>
				)} */}
				{isDraft && (
					<XsButton
						className="xs-danger xs-outline button-record-delete"
						text={<FormattedMessage id="Common.label.delete" />}
						type="submit"
						tabindex={10}
						onClick={() => {
							GlobalStore.openConfirmationDialog("xsDeleteRecord", {recordID: props.form.$("recordId").value})
						}}
						icon={<TrashIcon />}
					/>
				)}
				{!props.showInDialog && (
					<XsButton
						className="xs-danger xs-outline button-record-cancel"
						text={
							<FormattedMessage
								id={!PatientRecordStore.readonlyform ? "Common.form.close" : "Patient.form.patientrecord.close"}
							/>
						}
						tabindex={11}
						onClick={() => {
							props.options.onClose("RECORD", false, !PatientRecordStore.readonlyform, () => {
								window.scrollTo(0, 0)
								if (PatientRecordStore.readonlyform) {
									PatientRecordStore.closeScoring()
								}
								PatientRecordStore.reset()
								PatientRecordStore.clearProvidedServices(RouterStore.location.search.replace(/([^0-9])+/, ""))
							})
						}}
						icon={<CancelIcon />}
					/>
				)}
			</div>
			<DoctorOUPZwarningDialog />
			<XsConfirmationDialog
				name="xsDeleteAttachmentFromDropzone"
				title={<FormattedMessage id="Common.label.warning" />}
				text={<FormattedMessage id="Patient.form.patientrecord.deleteAttachment" />}
				onConfirmation={() => handleDropzoneDelete(GlobalStore.confirmationDialogParams.fileIndex)}
			/>
			<XsConfirmationDialog
				name="xsDeleteRecord"
				title={<FormattedMessage id="Common.label.warning" />}
				text={<FormattedMessage id="Patient.form.patientrecord.deleteConfirmationMessage" />}
				onConfirmation={() => {
					PatientRecordStore.deleteRecord(props.form.$("recordId").value)
					props.options.onClose("RECORD", false, false, () => {
						window.scrollTo(0, 0)
						if (PatientRecordStore.readonlyform) {
							PatientRecordStore.closeScoring()
						}
						PatientRecordStore.reset()
						PatientRecordStore.clearProvidedServices(RouterStore.location.search.replace(/([^0-9])+/, ""))
					})
					// DataStore.setFormDataState(formName, formDataStateCL.EMPTY)
				}}
			/>
			<XsConfirmationDialog
				name="xsDeliveriesValidationFailed"
				title={<FormattedMessage id="Common.label.warning" />}
				text={<FormattedMessage id="Patient.form.patientrecord.DeliveriesValidationFailedModal" />}
				onConfirmation={() => GlobalStore.confirmationDialogParams.deliveriesValidatinFailedCallback()}
			/>
			<XsConfirmationDialog
				name="xsUpdateDeliveriesDate"
				title={<FormattedMessage id="Common.label.warning" />}
				text={<FormattedMessage id="Common.label.updateDeliveriesDate" />}
				onConfirmation={() => changeDateInDeliveries()}
			/>
			{GlobalStore.confirmationDialogOpened == "xsUpdateDeliveriesDiagnosis" && (
				<XsConfirmationDialog
					name="xsUpdateDeliveriesDiagnosis"
					title={<FormattedMessage id="Common.label.warning" />}
					text={<FormattedMessage id="Common.label.insertDiagnosisIntoDeliveries" />}
					onConfirmation={() => GlobalStore.confirmationDialogParams.insertDiagnosis()}
				/>
			)}
			{GlobalStore.confirmationDialogOpened == "xsDeliveryConflictsDialog" && (
				<XsConfirmationDialog
					name="xsDeliveryConflictsDialog"
					type="warning"
					headerConfig={{
						icon: <i className="fal fa-exclamation-triangle fa-2x"></i>,
						text: "Boli zistené nasledovné obmedzenia pri vykazovaní výkonov:",
						type: "warning"
					}}
					text={
						isSafe(GlobalStore.confirmationDialogParams) && isSafe(GlobalStore.confirmationDialogParams.dialogText)
							? GlobalStore.confirmationDialogParams.dialogText
							: ""
					}
					onConfirmation={() => GlobalStore.confirmationDialogParams.confirmAction()}
					onCancel={() => {
						UIStore.isFormSaving = false
					}}
					confirmBtn={{
						text: "Common.label.proceed"
					}}
					cancelBtn={{
						text: "Common.label.back"
					}}
				/>
			)}

			{GlobalStore.confirmationDialogOpened == "xsOverwriteDeliveriesSender" && (
				<XsConfirmationDialog
					name="xsOverwriteDeliveriesSender"
					title={<FormattedMessage id="Common.label.warning" />}
					text={<FormattedMessage id="Common.label.overwriteSenderTypeDialog" />}
					onConfirmation={() => PatientRecordStore.overwriteSendingType(props.form)}
				/>
			)}

			{GlobalStore.confirmationDialogOpened == "xsOverwriteDeliveriesToDefaultSender" && (
				<XsConfirmationDialog
					name="xsOverwriteDeliveriesToDefaultSender"
					title={<FormattedMessage id="Common.label.warning" />}
					text={<FormattedMessage id="Common.label.overwriteSenderTypeDialog" />}
					onConfirmation={() => PatientRecordStore.overwriteToDefaultSendingType(props.form)}
				/>
			)}

			<ClinicalSetsDialog />
		</section>
	)
}
// }

export default injectIntl(withStyles(styles)(observer(PatientRecordForm)))
