"use strict"
import api from "../actions/api"
// import moment from "moment"
import {observable, action} from "mobx"
import {getFilters} from "../../../global/helpers/api"
import settings from "../../../global/config/settings"

class NotAllowedDeliveriesListStore {
	@observable patientsOverview = []
	@observable isDownloading = false
	@observable isLoading = false
	@observable isOpen = false

	formRef = undefined

	sicknessAbsReportListRef = null

	@observable changedFilters = false
	currFilters = {}

	@action isChangedFilter(formValues) {
		let isChangeDateFrom = compareMomentDate(this.currFilters.datefrom, formValues.datefrom)
		let isChangeDateTo = compareMomentDate(this.currFilters.dateto, formValues.dateto)


		if (isChangeDateFrom || isChangeDateTo || this.currFilters.orgunit != formValues.orgunit || this.currFilters.deliveries != formValues.deliveries) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}

	}

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@action loadListData(form) {
		this.currFilters = form.values()

		let filters = [`provider_id=${form.values().orgunit}`, `dlvs_code_ext=${form.values().deliveries}`]

		if (isSafe(form) && isSafe(form.values())) {
			let formValues = form.values()
			if (isNotEmpty(formValues.number)) {
				filters.push(`number=${formValues.number}`)
			}
		}

		let searchRequest = getFilters(filters)

		if (isSafe(form) && isSafe(form.values())) {
			let formValues = form.values()
			if (formValues.datefrom) {
				searchRequest.time_from = formValues.datefrom.startOf("day").format(settings.DB_DATETIME_FORMAT)
			}
			if (formValues.dateto) {
				searchRequest.time_to = formValues.dateto.endOf("day").format(settings.DB_DATETIME_FORMAT)
			}
		}





		this.isLoading = true
		api
			.loadCombinationsByDay(searchRequest)
			.call()
			.then((response) => {
				this.listData = response.rows
				this.isLoading = false
			})
			.catch(() => {
				this.isLoading = false
			})
	}
}
var singleton = new NotAllowedDeliveriesListStore()
export default singleton
