import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				paramId: {},
				paramType: {
					label: <FormattedMessage id="Orgunits.orgunit.TypeOfParam" />
				},
				value: {
					label: <FormattedMessage id="Common.label.value" />,
					rules: "required"
				}
			}
		}
	}
}
