//@flow
import React from "react"
import MobxReactForm from "mobx-react-form"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import validatorjs from "validatorjs"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Grid from "@material-ui/core/Grid"
import DialogActions from "@material-ui/core/DialogActions"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

import GenerateInvoiceDialogStore from "../../../stores/GenerateInvoiceDialogStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import UIStore from "../../../stores/UIStore"

import fields from "./generateInvoiceFields"
import {getSelectedOrgUnitID} from "../../../../../global/helpers/actions"

@injectIntl
@observer
export default class GenerateInvoiceDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}
		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks})
	}

	componentDidMount() {
		GlobalStore.orgunits.forEach((org, idx) => {
			this.form.$("capitationsChecboxes").add(
				{
					key: idx,
					label: `${this.props.intl.formatMessage({id: "Deliveries.generateInvoice.capitations"})}`,
					type: "checkbox"
				},
				true
			)
			let deliveriesIndex = idx + 1
			this.form.$("deliveriesChecboxes").add(
				{
					key: deliveriesIndex.toString() + "1",
					label: `${this.props.intl.formatMessage({id: "Deliveries.generateInvoice.SKinsurers"})}`,
					type: "checkbox"
				},
				true
			)
			this.form.$("deliveriesChecboxes").add(
				{
					key: deliveriesIndex.toString() + "2",
					label: `${this.props.intl.formatMessage({id: "Deliveries.generateInvoice.EUinsurers"})}`,
					type: "checkbox"
				},
				true
			)
			this.form.$("deliveriesChecboxes").add(
				{
					key: deliveriesIndex.toString() + "3",
					label: `${this.props.intl.formatMessage({id: "Deliveries.generateInvoice.FRorHMLSinsures"})}`,
					type: "checkbox"
				},
				true
			)
		})
	}

	render() {
		return (
			<Dialog
				id="xsGenerateInvoiceDialog"
				open={GenerateInvoiceDialogStore.isOpen}
				onClose={() => GenerateInvoiceDialogStore.close()}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-900"
				}}
				disableBackdropClick={true}
				maxWidth="md"
				onEnter={() => {
					this.form.reset()
					let selectedOrg = getSelectedOrgUnitID()
					let orgIndex = GlobalStore.orgunits.map((org) => org._id).indexOf(selectedOrg) + 1
					//Predplnenie "Výkony SK poistenci" podla prave selectnutej orgunit
					this.form
						.$("deliveriesChecboxes")
						.fields.get(`${orgIndex}1`)
						.set("value", UIStore.reportingZSDetailTab === "Deliveries")
					//Predplnenie "Kapitacie" podla prave selectnutej orgunit a tabu Kapitations
					this.form
						.$("capitationsChecboxes")
						.fields.get(`${orgIndex - 1}`)
						.set("value", UIStore.reportingZSDetailTab === "Capitations")
				}}
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-absolute xs-close-btn" onClick={() => GenerateInvoiceDialogStore.close()}>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-question fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Deliveries.generateInvoice.title" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="content">
					{UIStore.isFormSaving && <XsLoading overlay={true} />}
					<Grid container direction="column">
						<Grid item xs={12}>
							{GlobalStore.orgunits.map((org, idx) => {
								let deliveriesIndex = idx + 1
								return (
									<div key={idx}>
										<h4>
											{org.name + " "}(<b>{org.provider_code}</b>)
										</h4>
										<div className="form">
											<XsCheckbox field={this.form.$("capitationsChecboxes").fields.get(idx.toString())} />
											<XsCheckbox
												field={this.form.$("deliveriesChecboxes").fields.get(deliveriesIndex.toString() + "1")}
											/>
											<XsCheckbox
												field={this.form.$("deliveriesChecboxes").fields.get(deliveriesIndex.toString() + "2")}
											/>
											<XsCheckbox
												field={this.form.$("deliveriesChecboxes").fields.get(deliveriesIndex.toString() + "3")}
											/>
										</div>
									</div>
								)
							})}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-danger xs-outline"
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => GenerateInvoiceDialogStore.close()}
						icon={<i className="fal fa-times fa-lg" />}
					/>
					<XsButton
						className="xs-success"
						text={<FormattedMessage id="Deliveries.generateInvoice.generate" />}
						onClick={() => {
							GenerateInvoiceDialogStore.generateInvoiceAndOpen(this.form, 0)
						}}
						icon={<i className="fal fa-question fa-lg" />}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
