import React from "react"
import ReactTooltip from "react-tooltip"

import UIStore from "../../../../stores/UIStore"
import "./timelineFilters.less"
import XsDropdownList from "../../../../../../global/ui/xsDropdown/xsDropdownList"
import SearchDropdownNoForm from "../../../../../../global/ui/xsSearchdropdown/xsSearchdropdownNoForm"
import {FormattedMessage, injectIntl} from "react-intl"
import {FormControlLabel, Checkbox, Tooltip} from "@material-ui/core"
import XsButton from "../../../../../../global/ui/xsButton/xsButton"
import DataStore from "../../../../stores/DataStore"
import MedicationDistDialogStore from "../../../../stores/MedicationDistDialogStore"
import {getSelectedOrgUnitID} from "../../../../../../global/helpers/actions"
import GlobalStore from "../../../../../../global/store/GlobalStore"
import LabResultsStore from "../../../../stores/LabResultsStore"
import MedicineCardDialogStore from "../../../../stores/MedicineCardDialogStore"
import {observer} from "mobx-react"
import XsIconButton from "../../../../../../global/ui/xsButton/xsIconButton"

@injectIntl
@observer
export class TimelineFilters extends React.Component {
	constructor(props) {
		super(props)

		GlobalStore.refreshCodeLists(["recordVersion"])

		// UIStore.currPatientOverviewFilterValue =
		// 	isSafe(UIStore.patientOverviewFilter) && isSafe(UIStore.patientOverviewFilter.time)
		// 		? UIStore.patientOverviewFilter.time.search_string
		// 		: this.props.timeFilterValues[2].search_string

		if (isSafe(UIStore.patientOverviewFilter) && isSafe(UIStore.patientOverviewFilter.time)) {
			UIStore.currPatientOverviewFilterValue = UIStore.patientOverviewFilter.time.search_string
		} else {
			UIStore.currPatientOverviewFilterValue = this.props.timeFilterValues[2].search_string
			UIStore.patientOverviewFilter = Object.assign({}, UIStore.patientOverviewFilter, {
				time: {
					name: this.props.timeFilterValues[2].name,
					search_string: this.props.timeFilterValues[2].search_string
				}
			})
		}
	}

	checkedIds =
		isSafe(UIStore.patientOverviewFilter) &&
		isSafe(UIStore.patientOverviewFilter.diagnoses) &&
		UIStore.patientOverviewFilter.diagnoses.length > 0
			? UIStore.patientOverviewFilter.diagnoses.map((x) => x.id_value)
			: null

	render() {
		const provider_id = getSelectedOrgUnitID()
		const patient_id = DataStore.patientDTO.get("patientID")

		const diagnosisFilterValues = isSafe(DataStore.patientDTO.get("diagnosis"))
			? DataStore.patientDTO.get("diagnosis").map((dg) => {
					return {
						id: dg.id_value,
						search_string: dg.search_string,
						primaryText: dg.text_value
							.split(" ")
							.slice(1)
							.join(" "),
						secondaryText: dg.text_value.split(" ")[0],
						checkValue: isSafe(this.checkedIds) && this.checkedIds.includes(dg.id_value) //false,
					}
			  })
			: []

		return (
			<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
				<div className="refreshDiv">
					<XsIconButton
						icon={
							<span>
								<i className="fal fa-sync-alt fa-lg" data-tip data-for="reloadRecords" />
								<ReactTooltip id="reloadRecords">
									<FormattedMessage id="Common.label.reloadRecords" />
								</ReactTooltip>
							</span>
						}
						onClick={() => this.props.reloadOverview(UIStore.patientOverviewFilter)}
					/>
				</div>

				<div className="filters">
					{UIStore.overviewTab !== "laboratories" && (
						<div className="pr-4">
							<XsIconButton
								tooltip={
									UIStore.isOpenAllRecordsInEHROverview
										? this.props.intl.formatMessage({
												id: "Common.label.hideAllRecordDetail"
										  })
										: this.props.intl.formatMessage({
												id: "Common.label.showAllRecordDetail"
										  })
								}
								rect
								className={`xs-outline ${UIStore.isOpenAllRecordsInEHROverview ? "xs-success" : "xs-default"}`}
								icon={<i className="fas fa-ellipsis-h"></i>}
								onClick={() => (UIStore.isOpenAllRecordsInEHROverview = !UIStore.isOpenAllRecordsInEHROverview)}
							/>
						</div>
					)}
					{UIStore.overviewTab === "medication" && (
						<React.Fragment>
							<XsButton
								className="xs-default xs-outline"
								text={<FormattedMessage id="Common.label.mediactionCardZP" />}
								onClick={() => MedicineCardDialogStore.drugBookEHR()}
							/>
							<Tooltip
								title={this.props.intl.formatMessage({
									id: "Common.label.listOfPrescription"
								})}
							>
								<div className="ml-4 mr-4">
									<XsButton
										className="xs-default xs-outline"
										text={<FormattedMessage id="Patient.Overview.DrugsDisctictList" />}
										onClick={() =>
											MedicationDistDialogStore.open(
												provider_id,
												patient_id,
												this.props.actions.prescription.represcribe
											)
										}
									/>
								</div>
							</Tooltip>
						</React.Fragment>
					)}
					{UIStore.overviewTab === "laboratories" && (
						<React.Fragment>
							<XsIconButton
								tooltip={this.props.intl.formatMessage({id: "Common.label.table"})}
								rect
								className={`xs-default ${LabResultsStore.fullTable ? "" : "xs-outline"}`}
								icon={<i className="fal fa-table"></i>}
								onClick={() => LabResultsStore.openTable()}
							/>
							<XsIconButton
								tooltip={this.props.intl.formatMessage({id: "Common.label.onlyValue"})}
								rect
								className={`${LabResultsStore.fullTable ? "xs-outline" : ""} xs-default mr-3`}
								icon={<i className="fal fa-list"></i>}
								onClick={() => LabResultsStore.closeTable()}
							/>
						</React.Fragment>
					)}
					<FormControlLabel
						control={
							<Checkbox
								className="xs-orgunit-checkbox"
								checked={UIStore.patientOverviewFilterCheckBox}
								onChange={() => {
									UIStore.patientOverviewFilterCheckBox = !UIStore.patientOverviewFilterCheckBox
									this.props.reloadOverview(UIStore.patientOverviewFilter)
								}}
							/>
						}
						label={
							<span className="xs-orgunit-label" data-tip data-for="currentDepartment">
								<FormattedMessage id="Common.label.currentDepartment" />
								<ReactTooltip id="currentDepartment">
									<FormattedMessage id="PatientDetail.filters.orgunit" />
								</ReactTooltip>
							</span>
						}
					/>
					<div className="xs-dropdown-ehr-filter">
						<XsDropdownList
							items={GlobalStore.CL.recordVersion}
							renderer={(item) => <span>{item.name_ext}</span>}
							column="name_ext"
							value={UIStore.EHRversionFilterText}
							label={this.props.intl.formatMessage({id: "Common.label.StateOfRecord"})}
							labelAsPlaceholder={true}
							chooseItem={(item) => {
								//hack kvoli tomu ze v komponente sa mi odpaluje chooseItem uz pri zobrazeni items
								if (UIStore.EHRversionFilterText != item.name_ext) {
									UIStore.EHRversionFilterText = item.name_ext
									UIStore.EHRversionFilterValue = isNotEmpty(item.code_ext) ? item.code_ext : ""
									this.props.reloadOverview(UIStore.patientOverviewFilter)
								}
							}}
							emptyValue
						/>
					</div>
					{
						// UIStore.overviewTab === "overview" &&
						<div className="xs-dropdown-ehr-filter">
							<XsDropdownList
								items={[
									{value: "overview", name_ext: this.props.intl.formatMessage({id: "PatientDetail.cards.overview"})},
									{
										value: "noDeliveries",
										name_ext: this.props.intl.formatMessage({id: "PatientDetail.cards.noDeliveries"})
									},
									{value: "anamnesis", name_ext: this.props.intl.formatMessage({id: "PatientDetail.cards.anamnesis"})},
									{
										value: "medication",
										name_ext: this.props.intl.formatMessage({id: "Common.label.drugs"})
									},
									{value: "ambulance", name_ext: this.props.intl.formatMessage({id: "PatientDetail.cards.ambulance"})},
									{value: "laboratories", name_ext: this.props.intl.formatMessage({id: "Common.label.labs"})},
									{value: "requests", name_ext: this.props.intl.formatMessage({id: "Common.label.requests"})},
									{
										value: "sicknessabs",
										name_ext: this.props.intl.formatMessage({id: "Common.label.patientSicknessAbs"})
									},
									{
										value: "operation_protocol",
										name_ext: this.props.intl.formatMessage({id: "PatientDetail.cards.opProtocol"})
									},
									{
										value: "clinicalvalues",
										name_ext: this.props.intl.formatMessage({id: "Patient.form.patientrecord.clinicalvalues"})
									},
									{
										value: "hospitalization",
										name_ext: this.props.intl.formatMessage({id: "PatientDetail.cards.hospitalization"})
									},
									{
										value: "nczivalues",
										name_ext: this.props.intl.formatMessage({id: "Patient.form.patientrecord.nzisexternal"})
									},
									{
										value: "medicationAMB",
										name_ext: this.props.intl.formatMessage({id: "PatientDetail.cards.medication"})
									},
									{
										value: "printTemplates",
										name_ext: this.props.intl.formatMessage({id: "Common.label.templates"})
									},
									{
										value: "scoring",
										name_ext: this.props.intl.formatMessage({id: "PatientDetail.cards.patientScoring"})
									},
									{
										value: "printNZS",
										name_ext: this.props.intl.formatMessage({id: "Common.label.planHospitalization"})
									}
								]}
								renderer={(item) => {
									return <span>{item.name_ext}</span>
								}}
								column="value"
								value={UIStore.EHRfilterValue}
								// label={<span className="xs-bold"><FormattedMessage id="Agreement.Container.Detail.Insurer" />*</span>}
								chooseItem={(item) => {
									UIStore.EHRfilterValue = item.value
									if (
										item.value === "noDeliveries" ||
										item.value === "sicknessabs" ||
										item.value === "medication" ||
										item.value === "ambulance" ||
										item.value === "requests" ||
										item.value === "clinicalvalues" ||
										item.value === "laboratories"
									) {
										UIStore.overviewTab = item.value
									} else {
										UIStore.overviewTab = "overview"
									}
								}}
							/>
						</div>
					}
					<div className="xs-dropdown-ehr-filter">
						<XsDropdownList
							items={this.props.timeFilterValues.map((item) => {
								return {
									name: item.primaryText,
									search_string: item.search_string
								}
							})}
							renderer={(item) => {
								return <span>{item.name}</span>
							}}
							column="search_string"
							value={
								isSafe(UIStore.patientOverviewFilter) && isSafe(UIStore.patientOverviewFilter.time)
									? UIStore.patientOverviewFilter.time.search_string
									: this.props.timeFilterValues[2].search_string
							}
							// label={<span className="xs-bold"><FormattedMessage id="Common.label.insurer" />*</span>}
							chooseItem={(dataRow) => {
								if (dataRow.search_string !== UIStore.currPatientOverviewFilterValue) {
									const filters = Object.assign({}, UIStore.patientOverviewFilter, {
										time: {
											name: dataRow.name,
											search_string: dataRow.search_string
										}
									})
									UIStore.patientOverviewFilter = filters
									this.props.reloadOverview(filters)
									UIStore.currPatientOverviewFilterValue = dataRow.search_string
								}
							}}
						/>
					</div>
					{/* <XsMenu
          className="xs-timefilter-menu"
          // default={isSafe(UIStore.patientOverviewFilter) && isSafe(UIStore.patientOverviewFilter.time)
          //   ? UIStore.patientOverviewFilter.time.name
          //   : this.props.timeFilterValues[0].primaryText}
          // items={this.props.timeFilterValues.map(item => {
          //   return {
          //     name: item.primaryText,
          //     search_string: item.search_string
          //   }
          // })}
          onClick={dataRow => {
            const filters = Object.assign({}, UIStore.patientOverviewFilter, {
              time: {
                name: dataRow.name,
                search_string: dataRow.search_string
              }
            })
            UIStore.patientOverviewFilter = filters
            this.props.reloadOverview(filters)
          }}
        /> */}

					<SearchDropdownNoForm
						items={diagnosisFilterValues}
						multiSelect={true}
						id="EHR_overview_diagnoses"
						onClose={(data) => {
							const selectedIds = data.map((x) => x.id)
							const filteredDiagnoses =
								isSafe(UIStore.patientOverviewFilter) && isSafe(UIStore.patientOverviewFilter.diagnoses)
									? UIStore.patientOverviewFilter.diagnoses
									: []

							if (
								filteredDiagnoses.length != selectedIds.length ||
								filteredDiagnoses.every((x) => selectedIds.includes(x.id_value)) == false
							) {
								const filters = Object.assign({}, UIStore.patientOverviewFilter, {
									diagnoses:
										data.length > 0
											? data.map((x) => {
													return {id_value: x.id}
											  })
											: null
								})
								UIStore.patientOverviewFilter = filters
								this.props.reloadOverview(filters)
							}
						}}
						// hintText="Diagnozy"
						label={<FormattedMessage id="Common.label.diagnosis" />}
					/>
				</div>
				{/* <div className="refreshDiv">
					<XsIconButton
						icon={<i className="fal fa-sync-alt fa-lg" />}
						onClick={() => this.props.reloadOverview(UIStore.patientOverviewFilter)}
					/>
				</div> */}
			</div>
		)
	}
}

export default TimelineFilters
