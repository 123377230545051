"use strict"

import {observable, action} from "mobx"

class PatientTagsDialogStore {
	@observable isOpen = false
	@observable isEditable = false
	@observable tagObj = undefined
	@observable nameOfDeleteTag = ""

	tagId = undefined

	@action open(tag = undefined) {
		this.isOpen = true
		this.tagObj = tag
	}

	@action insertFormData(form) {
		let tag = this.tagObj

		this.isEditable = true
		this.tagId = tag._id

		form.$("tagType").set("value", tag.tagType)

		this.tagObj = undefined
	}

	@action close() {
		this.isOpen = false
		this.nameOfDeleteTag = ""
	}
}

var singleton = new PatientTagsDialogStore()
export default singleton
