import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import {Grid, Paper} from "@material-ui/core"

import XsTabs from "../../../../../global/ui/xsTabs/xsTabs"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

import AgreementForm from "../agreementList/agreementForm"
import AgreementPartList from "./agreementPartList"

import AgreementsStore from "../../../stores/AgreementsStore"
import UIStore from "../../../stores/UIStore"

@injectIntl
@observer
export default class AgreementDetail extends React.Component {
	constructor(props) {
		super(props)
		UIStore.mainMenuTab = "agreements"
		AgreementsStore.loadInsurences()

		if (isNotEmpty(this.props.id)) {
			AgreementsStore.loadAgreement(this.props.id)
			AgreementsStore.loadParts(this.props.id)
		} else {
			AgreementsStore.isNewAgreement = true
		}
	}

	render() {
		return (
			<React.Fragment>
				{UIStore.isFormSaving && <XsLoading overlay={true} />}
				<Grid container direction="column" className="xs-agreement-detail-container">
					<Grid item xs={12}>
						<Paper className="xs-header" square={true} elevation={1}>
							<div className="xs-title">
								<FormattedMessage id="Agreement.Container.Agreement" />
							</div>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Grid container className="xs-agreement-part-container">
							<Grid item xs={12}>
								<AgreementForm />
							</Grid>
							<Grid item xs={12}>
								{!AgreementsStore.isNewAgreement && (
									<XsTabs
										background="white"
										value="parts"
										tabs={[
											{
												label: (
													<span className="xs-part-tab">
														{this.props.intl.formatMessage({id: "Agreement.Container.Detail.PartsSubMat"})}
													</span>
												),
												value: "parts",
												content: <AgreementPartList />
											}
										]}
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</React.Fragment>
		)
	}
}
