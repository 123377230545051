import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import {Grid} from "@material-ui/core"
import XsTextArea from "../../../../../../global/ui/xsTextArea/xsTextArea"
import XsSearchSelect from "../../../../../../global/ui/xsSearchSelect/xsSearchSelect"

import "./printTemplatesStyle.less"

import fields from "./generalFormFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import TemplateForm from "../../patientRecordForm/templateForm"
import UniversalTemplateStore from "../../../../stores/UniversalTemplateStore"

@injectIntl
@observer
export default class RequestExaminationRiadiofarmakom extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		UniversalTemplateStore.generalForm = this.form
		UniversalTemplateStore.getListForSearch()
	}

	// componentDidMount() {}

	render() {
		return (
			<React.Fragment>
				<Grid container direction="column">
					<Grid item>
						<XsSearchSelect
							white
							field={this.form.$("templates")}
							items={UniversalTemplateStore.templatesForSearch}
							onChange={(value) => {
								UniversalTemplateStore.loadGeneralForm(value)
							}}
						/>
					</Grid>
					<Grid item>
						<XsTextArea
							field={this.form.$("templateText")}
							rows={9}
							white
							maxLength={5000}
							// onFocus={(data) => {
							//     if (data && (isNotSafe(this.textareaCursor) || this.textareaCursor.cursor != data.cursor)) {
							//         this.textareaCursor = {
							//             cursor: data.cursor
							//         }
							//     }
							// }}
						/>
					</Grid>
				</Grid>
				<TemplateForm type={this.templateName} />
			</React.Fragment>
		)
	}
}
