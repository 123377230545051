"use strict"

import {observable, action} from "mobx"
import {getSelectedOrgUnitID, getUserPersonnelID} from "../../../global/helpers/actions"
import UIStore from "./UIStore"
import moment from "moment"
import api from "../actions/api"
// import WarningStore from "../../../global/store/WarningStore"
// import GlobalStore from "../../../global/store/GlobalStore"
// import RouterStore from "../../../global/store/RouterStore"
// import settings from "../../../global/config/settings"

class CommMsgQueueDialogStore {
	@observable isOpen = false

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@action checkMsgs() {
		if (isEmpty(getSelectedOrgUnitID()) || isEmpty(getUserPersonnelID())) {
			return
		}
		let filters = {
			filters: [
				{
					associated_column: "provider",
					values: [
						{
							id_value: getSelectedOrgUnitID()
						}
					]
				},
				{
					associated_column: "personnel",
					values: [
						{
							id_value: getUserPersonnelID()
						}
					]
				}
			],
			time_from: moment()
				.subtract(6, "days")
				.utc()
				.format(),
			time_to: moment()
				.subtract(5, "minutes")
				.utc()
				.format()
		}

		api
			.loadEhealthQueue(filters)
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.rows)) {
					response.rows.forEach((element) => {
						const key = `prescription#${element.identifier._id};`
						UIStore.commMsgQueue.set(key, {
							timestamp: moment(element.created_at).valueOf(),
							text: element.message_text,
							state: element.state,
							needApprove: element.needApprove,
							errorCode: element.error_code,
							stateText: element.state_text,
							navigationIdentifier:
								isSafe(element.navigation_identifier) &&
								element.navigation_identifier._type == "Entity.Data.Person" &&
								isNotEmpty(element.navigation_identifier._id)
									? {patientId: element.navigation_identifier._id}
									: null,
							identifier: element.identifier
						})
					})
				}
			})
	}
}

var singleton = new CommMsgQueueDialogStore()
export default singleton
