import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import {Paper} from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsAutocomplete from "../../../../../../global/ui/xsInput/xsAutocomplete"
import SearchSelect from "../../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import CodeListStore from "../../../../stores/CodeListStore"
import AddIcon from "@material-ui/icons/Add"
import XsButton from "../../../../../../global/ui/xsButton/xsButton"
import OrgunitsList from "../../../../../attendance/components/orgunitsList/orgunitsList"
import ambulanceApi from "../../../../../ambulance/actions/api"
import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"
import UIStore from "../../../../stores/UIStore"

@injectIntl
@observer
export default class CompaniesForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			addOrgunit: false
		}
		this.form = props.form
		if (isNotEmpty(CodeListStore.companiesFormCompanyId)) {
			CodeListStore.loadSpecificCompany(CodeListStore.companiesFormCompanyId, this.form)
		}
		this.handleValidation()
	}

	handleValidation = () => {
		if (this.state.addOrgunit) {
			this.form.$("code").set("rules", "required")
			this.form.$("org_name").set("rules", "required")
			this.form.$("expertise").set("rules", "required")
		} else {
			this.form.$("code").set("rules", "")
			this.form.$("org_name").set("rules", "")
			this.form.$("expertise").set("rules", "")
		}
	}

	render() {
		return (
			<div>
				<Paper className="companies-form-paper">
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsInput field={this.form.$("ico")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("name")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("short_name")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("DIC")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("ICDPH")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("description")} />
						</Grid>
					</Grid>
					{isEmpty(CodeListStore.companiesFormCompanyId) && (
						<Grid container direction="row" align="left" spacing={8}>
							<XsButton
								className="xs-info"
								text={<FormattedMessage id="Orgunits.orgunit.addOrgunit" />}
								icon={<AddIcon />}
								onClick={() => {
									this.setState({addOrgunit: !this.state.addOrgunit}, () => {
										this.handleValidation()
									})
								}}
							/>
						</Grid>
					)}
					{this.state.addOrgunit && (
						<Grid container direction="row" align="left" spacing={8} className="company-add-orgunit">
							<Grid item xs={12}>
								<FormattedMessage id="Orgunits.orgunit.form.new" />
							</Grid>
							<Grid item xs={3}>
								<XsInput field={this.form.$("code")} />
							</Grid>
							<Grid item xs={3}>
								<XsInput field={this.form.$("org_name")} />
							</Grid>
							<Grid item xs={3}>
								<SearchSelect field={this.form.$("superiorOJ")} items={[]} />
							</Grid>
							{/* <Grid item xs={3}><XsDropdown field={this.form.$("company")} items={[]} /></Grid> */}
							<Grid item xs={3}>
								<XsInput field={this.form.$("OUPZScode")} />
							</Grid>
							<Grid item xs={3}>
								<XsInput field={this.form.$("JRUZid")} />
							</Grid>
							<Grid item xs={3}>
								{/* <XsInputDBfilter
                field={this.form.$("expertise")} minLengthForSearch="1"
                onButtonClick={(value) => OrgunitDetailStore.searchExpertiseOrg(value)}
                data={OrgunitDetailStore.expertiseData}
                selector={(obj) => obj.code_ext + " " + obj.name_ext}
                onSearchClose={() => { OrgunitDetailStore.clearData("expertiseData") }}
                postAction={data => {
                  this.form.$("expertiseId").value = data._id
                }}
              /> */}
								<XsAutocomplete
									field={this.form.$("expertise")}
									minLengthForSearch="3"
									api={ambulanceApi.loadExpertise}
									apiCallType="Pr"
									inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
									saveValue={(obj) => obj._id}
									modalConfig={(textValue, clickHandler) => {
										return {
											columnDefs: {
												code_ext: {
													title: (
														<FormattedMessage id="Patient.form.patientrequest.expertise.autoCompleteModal.CodeColumn" />
													),
													type: "string",
													dbName: "code_ext",
													design: {
														width: "150px",
														header: {
															className: "xs-autoCompleteModalColumnHeader"
														}
													}
												},
												name_ext: {
													title: <FormattedMessage id="Common.label.name" />,
													type: "string",
													dbName: "search_column",
													design: {
														header: {
															className: "xs-autoCompleteModalColumnHeader"
														}
													},
													filter: {
														gridWidth: 3,
														defaultValue: textValue,
														renderElement: "input"
													},
													sortable: true
												}
											},
											options: {
												// selectRow: true,
												showCursor: true,
												onRowClick: (dataRow) => {
													clickHandler(dataRow)
												}
											}
										}
									}}
								/>
							</Grid>
						</Grid>
					)}
					<Grid container direction="row-reverse" align="right" spacing={8}>
						<Grid item xs={1}>
							<XsButton
								className="xs-danger"
								text={<FormattedMessage id="Common.label.cancel" />}
								icon={<CancelIcon />}
								onClick={() => {
									CodeListStore.companiesShowForm = false
									CodeListStore.companiesFormCompanyId = ""
									this.form.reset()
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsButton
								className="xs-success"
								text={<FormattedMessage id="Common.label.save" />}
								icon={<SaveIcon />}
								onClick={(e) => {
									UIStore.isFormSaving = true
									this.form.onSubmit(e)
									// CodeListStore.companiesShowForm = false
									// CodeListStore.companiesFormCompanyId = ""
								}}
							/>
						</Grid>
					</Grid>
				</Paper>
				{isNotEmpty(this.form.$("companyId").value) && (
					<div className="orgunitList">
						<OrgunitsList companyId={this.form.$("companyId").value} />
					</div>
				)}
			</div>
		)
	}
}
