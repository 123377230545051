import api from "../actions/api"
import {observable, action} from "mobx"
import {getUserCompanyInfo, getUserPersonnelID} from "../../../global/helpers/actions"
import moment from "moment"

import UIStore from "../stores/UIStore"

class ExportBatchDialogStore {
	@observable isOpen = false
	@observable insurer = null
	@observable onlyCapitations = true

	@action open() {
		this.isOpen = true
	}

	@action close(form) {
		form.reset()
		this.isOpen = false
	}

	@action exportBatchToXls(form) {
		const values = form.values()

		UIStore.isFormSaving = true

		let filters = [
			{
				associated_column: "company_id",
				values: [
					{
						id_value: getUserCompanyInfo().id
					}
				]
			},
			{
				associated_column: "only_capitations",
				values: [
					{
						id_value: values.only_capitation ? "1" : "0"
					}
				]
			},
			{
				associated_column: "personnel_id",
				values: [
					{
						id_value: getUserPersonnelID()
					}
				]
			}
		]

		if (isNotEmpty(values.insurer_id)) {
			filters.push({
				associated_column: "insurer_id",
				values: [
					{
						id_value: values.insurer_id
					}
				]
			})
		}

		if (isNotEmpty(values.records_from)) {
			filters.push({
				associated_column: "records_from",
				values: [
					{
						id_value: moment(values.records_from)
							.startOf("day")
							.utc()
							.format()
					}
				]
			})
		}

		if (isNotEmpty(values.records_to)) {
			filters.push({
				associated_column: "records_to",
				values: [
					{
						id_value: moment(values.records_to)
							.endOf("day")
							.utc()
							.format()
					}
				]
			})
		}

		let req = {
			filters: filters
		}

		req.row_count_show = 100000
		req.row_offset = 1

		api
			.exportBatch(req)
			.call()
			.then((response) => {
				this.close(form)
				if (response.ok == true) {
					let fileName = isSafe(response.headers.get("content-disposition"))
						? response.headers.get("content-disposition").split("filename=")[1]
						: "export"
					response.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})
					UIStore.isFormSaving = false
				} else {
					UIStore.isFormSaving = false
				}
			})
	}
}
var singleton = new ExportBatchDialogStore()
export default singleton
