import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import bindings from "../../../../global/ui/globalUISchemeBindings"
import fields from "./addEmployeeFields"
import employeeBindings from "./addEmployeeBindings"

import {getUserCompanyInfo} from "../../../../global/helpers/actions"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
import {insertFormDataByScheme} from "../../../../global/helpers/bindings"

import SearchSelect from "../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsInput from "../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../global/ui/xsButton/xsButton"
import XsDateTimePicker from "../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import {padStart} from "../../../../global/helpers/functions"

import DuplicityPersonModalStore from "../../stores/DuplicityPersonModalStore"
import WarningStore from "../../../../global/store/WarningStore"

import GlobalStore from "../../../../global/store/GlobalStore"
import AddEmployeeDialogStore from "../../stores/AddEmployeeDialogStore"
import EmployeesDetailStore from "../../stores/EmployeesDetailStore"

import ambulanceApi from "../../../ambulance/actions/api"

@injectIntl
@observer
export default class AddEmployeeDialog extends React.Component {
	constructor(props) {
		super(props)

		GlobalStore.refreshCodeLists([
			"genders",
			"titles",
			"countries",
			"consentTypes",
			"contactTypes",
			"insuranceTypes",
			"employeeCategory"
		])

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				form.$("title_before").value = form.$("title_before").value === "" ? null : form.$("title_before").value
				form.$("title_after").value = form.$("title_after").value === "" ? null : form.$("title_after").value

				let company = getUserCompanyInfo()
				insertFormDataByScheme(
					form,
					employeeBindings,
					{entityId: isNotEmpty(form.$("entityId").value) ? form.$("entityId").value : "", companyId: company.id},
					(res) => {
						EmployeesDetailStore.redirToSpecificEmployee(res._id), AddEmployeeDialogStore.close()
					},
					false,
					"EmployeeDetail.employeeInfoForm.saved"
				)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(fields.load(), {
			plugins: {dvr: validatorjs},
			hooks,
			bindings
		})

		// this.loadedData = initFormData(this.form, this.props.id)
	}

	form

	changeIdentifier(field) {
		const identifier = field.value
		if (!this.stopRefresh && field.changed && !this.form.$("dateOfBirth").changed) {
			if (isFinite(identifier)) {
				const idLength = identifier.length
				let isInvalidIdentifier = false

				if (idLength === 9 || idLength === 10) {
					if (idLength === 10 && +identifier % 11 !== 0) {
						isInvalidIdentifier = true
					}

					const idfYear = +identifier.substr(0, 2)
					const actualYear = new Date()
						.getFullYear()
						.toString()
						.substr(2, 2)

					const year = +idfYear + (+idfYear <= +actualYear && +idfYear < 54 && idLength === 10 ? 2000 : 1900)
					let month = +identifier.substr(2, 2) - (+identifier.substr(2, 2) > 12 ? 50 : 0)
					let day = +identifier.substr(4, 2)
					const gender = +identifier.substr(2, 2) > 12 ? "F" : "M"

					month = padStart(month, 2, "0")
					day = padStart(day, 2, "0")

					if (idLength === 9 && year > 1953) {
						isInvalidIdentifier = true
					}

					const testDate = new Date(`${year}-${month}-${day}`)

					if (testDate instanceof Date == false || isNaN(testDate) || testDate.getDate() !== +day) {
						WarningStore.open(
							`${this.props.intl.formatMessage({id: "Patient.form.patientinfo.invalidIdentifierWarning"})}`
						)
					} else {
						this.form.$("dateOfBirth").set("value", `${year}-${month}-${day}`)
						this.form.$("gender").set("value", gender)

						// aj keby nebolo rodne cislo validne, aj tak urob kontrolu diplicity pacientov, lebo uz moze existovat
						let request = {
							filters: [
								{
									associated_column: "identifier",
									predicate: "=",
									values: [{id_value: identifier}]
								}
							]
						}

						ambulanceApi
							.getDuplicityPerson(request)
							.call()
							.then((response) => {
								if (isSafe(response) && isSafe(response.rows) && response.rows.length > 0) {
									DuplicityPersonModalStore.patientData = response.rows
									DuplicityPersonModalStore.open(this.form)
								}
							})
							.then(() => {
								if (isInvalidIdentifier)
									WarningStore.open(
										`${this.props.intl.formatMessage({id: "Patient.form.patientinfo.invalidIdentifierWarning"})}`
									)
							})
							.catch(() => {
								if (isInvalidIdentifier)
									WarningStore.open(
										`${this.props.intl.formatMessage({id: "Patient.form.patientinfo.invalidIdentifierWarning"})}`
									)
							})
					}
				}
			}
		}
	}

	render() {
		return (
			<Dialog
				id="xsAddEmployeeDialog"
				open={AddEmployeeDialogStore.isOpen}
				onClose={() => {
					this.form.reset()
					AddEmployeeDialogStore.close()
				}}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-900"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								this.form.reset()
								AddEmployeeDialogStore.close()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-user-plus fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="EmployeeDetail.employeenew.newemployee" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-overflow-visible">
					<Grid container spacing={8} className="pa-4">
						<Grid item xs={3}>
							<XsInput field={this.form.$("identifier")} onBlur={(field) => this.changeIdentifier(field)} />
						</Grid>
						<Grid item xs={3}>
							<SearchSelect
								field={this.form.$("title_before")}
								items={isSafe(GlobalStore.CL["titles"]) ? GlobalStore.CL["titles"].filter((obj) => obj.before) : []}
								getOptionLabel={(row) => row.code_ext}
							/>
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("firstname")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("lastname")} />
						</Grid>

						<Grid item xs={3}>
							<SearchSelect
								field={this.form.$("title_after")}
								items={isSafe(GlobalStore.CL["titles"]) ? GlobalStore.CL["titles"].filter((obj) => !obj.before) : []}
								getOptionLabel={(row) => row.code_ext}
							/>
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("birthname")} />
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.form.$("dateOfBirth")} />
						</Grid>
						<Grid item xs={3}>
							<SearchSelect
								inputWhite={true}
								field={this.form.$("gender")}
								items={GlobalStore.CL["genders"]}
								required
							/>
						</Grid>
						<Grid item xs={2}>
							<SearchSelect
								field={this.form.$("employeeCategory")}
								items={GlobalStore.CL["employeeCategory"]}
								required
							/>
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("personalNumber")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("cardNumber")} />
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.form.$("arrivalDate")} />
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.form.$("departureDate")} />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-danger xs-outline"
						icon={<i className="fal fa-times fa-lg" />}
						text={<FormattedMessage id="Common.form.close" />}
						onClick={() => {
							this.form.reset()
							AddEmployeeDialogStore.close()
						}}
					/>
					<XsButton
						className="xs-success"
						icon={<i className="fal fa-user-plus fa-lg" />}
						text={<FormattedMessage id="Common.label.addEmployee" />}
						onClick={this.form.onSubmit}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
