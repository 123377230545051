import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import renderHTML from "react-render-html"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import EHealthRecordDialogStore from "../../../stores/EHealthRecordDialogStore"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

@observer
export default class EHealthRecordDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsEHealthRecordDialog"
				open={EHealthRecordDialogStore.isOpen}
				onClose={() => EHealthRecordDialogStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
				className="xs-base-dialog"
			>
				<DialogTitle>
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-file-import fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Patient.form.patientrecord.nzisexternal.showTitle" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					{EHealthRecordDialogStore.isFetching ? (
						<XsLoading />
					) : (
						<div>
							{isNotEmpty(EHealthRecordDialogStore.htmlData) ? renderHTML(EHealthRecordDialogStore.htmlData) : ""}
						</div>
					)}
				</DialogContent>
				<DialogActions className="xs-footer">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => EHealthRecordDialogStore.close()}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
