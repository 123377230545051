export default {
	load: {
		storeKey: "RecordTemplates",
		type: "EHR.Data.Template.Summary"
	},
	descriptor: {
		_ref: false,
		// "active": true,
		content_type: "text/html"
	},
	scheme: {
		_id: {
			relation: "@id"
		},
		active: {
			relation: "@active"
		},
		name: {
			field: "name"
		},
		type_id: {
			relation: "@typeID"
		},
		org_unit_id: {
			relation: "@orgUnitId"
		},
		scope: {
			relation: "@scope"
		},
		template_stream: {
			field: "templateStream"
		},
		validity: {
			object: {
				scheme: {
					from: {
						field: "validityFrom"
					},
					to: {
						field: "validityTo"
					}
				}
			}
		}
	}
}
