import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"

import fields from "./generateInvoiceFromServiceFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import DataStore from "../../../stores/DataStore"
import UIStore from "../../../stores/UIStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

import GenerateInvoiceFromServiceDialogStore from "../../../stores/GenerateInvoiceFromServiceDialogStore"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

@injectIntl
@observer
export default class GenerateInvoiceFromServiceDialog extends React.Component {
	constructor(props) {
		super(props)
		DataStore.setServices()

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				const noEventsText = props.intl.formatMessage({
					id: "Invoice.form.generateFromServiceNoEvents"
				})
				GenerateInvoiceFromServiceDialogStore.generateInvoiceAndOpen(form, noEventsText)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}
		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		GlobalStore.refreshCodeLists(["eventStatus"])
	}

	render() {
		return (
			<div>
				{UIStore.isFormSaving && <XsLoading overlay={true} />}
				<Dialog
					id="xsGenerateInvoiceDialog"
					open={GenerateInvoiceFromServiceDialogStore.isOpen}
					onClose={() => (this.form.reset(), GenerateInvoiceFromServiceDialogStore.close())}
					className="xs-base-dialog"
					classes={{
						paper: "xs-paper-dialog xs-width-paper-900"
					}}
					disableBackdropClick={true}
					maxWidth="md"
				>
					<DialogTitle className="xs-info">
						<div className="xs-header">
							<div className="xs-absolute xs-close-btn" onClick={() => GenerateInvoiceFromServiceDialogStore.close()}>
								<i className="fal fa-times fa-lg" />
							</div>
							<div className="xs-header-icon">
								<i className="fal fa-save fa-2x" />
							</div>
							<div className="xs-header-title">
								<FormattedMessage id="Invoice.form.generateFromService" />
							</div>
						</div>
					</DialogTitle>
					<DialogContent className="xs-content">
						<Grid container spacing={8}>
							<Grid item xs={6}>
								<XsDateTimePicker field={this.form.$("from")} showTimeSelect={false} />
							</Grid>
							<Grid item xs={6}>
								<XsDateTimePicker field={this.form.$("to")} showTimeSelect={false} />
							</Grid>
							<Grid item xs={6}>
								<XsSearchSelect field={this.form.$("provided_service")} items={DataStore.resourcesData} />
							</Grid>
							<Grid item xs={6}>
								<XsSearchSelect field={this.form.$("status_id")} items={GlobalStore.CL["eventStatus"]} />
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions className="xs-footer xs-space-between">
						<XsButton
							className="xs-danger xs-outline"
							onClick={() => {
								this.form.reset()
								GenerateInvoiceFromServiceDialogStore.close()
							}}
							text={<FormattedMessage id="Common.label.cancel" />}
							icon={<i className="fal fa-times fa-lg" />}
						/>
						<XsButton
							className="xs-success"
							type="submit"
							onClick={(e) => {
								this.form.onSubmit(e)
							}}
							text={<FormattedMessage id="Common.label.ok" />}
							icon={<i className="fal fa-save fa-lg" />}
						/>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}
