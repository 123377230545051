"use strict"

import {observable, action} from "mobx"
// import moment from "moment"

import api from "../actions/api"
import NotificationCenterStore from "../components/notificationCenter/NotificationCenterStore"
import GlobalStore from "../../../global/store/GlobalStore"

class NotifyStore {
	@observable notificationData = []
	// @observable userData = []
	@observable notifyUsers = []
	formRef = null
	// allUsers = []

	@observable checkedUserIds = []

	@observable isOpenSendNotification = false

	@action openSendNotification() {
		this.isOpenSendNotification = true
	}

	@action closeSendNotification(form) {
		this.isOpenSendNotification = false

		this.checkedUserIds = []
		form.reset()
	}

	// @action getUsers() {
	// 	api
	// 		.getUsers()
	// 		.call()
	// 		.then((res) => {
	// 			if (isSafe(res) && isSafe(res.rows)) {
	// 				this.allUsers = res.rows
	// 				this.userData = res.rows
	// 			} else {
	// 				this.allUsers = []
	// 				this.userData = []
	// 			}
	// 		})
	// }

	@action getNotifyUsers() {
		api
			.getNotifyUsers()
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows)) {
					this.notifyUsers = res.rows
				} else {
					this.notifyUsers = []
				}
			})
	}

	@action getNotification(form) {
		const formValues = form.values()
		let req = {
			filters: [
				{
					associated_column: "message_type",
					values: [{id_value: "notify"}]
				},
				{
					associated_column: "time_from",
					values: [
						{
							id_value: formValues.time_from.format("YYYY-MM-DD")
						}
					]
				},
				{
					associated_column: "time_to",
					values: [{id_value: formValues.time_to.format("YYYY-MM-DD")}]
				}
			]
		}

		api
			.getMessages(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows) && res.rows.length > 0) {
					this.notificationData = res.rows.filter((x) => x.message.folder == "outbox")
				} else {
					this.notificationData = []
				}
			})
	}

	@action sendNotification(form) {
		if (this.checkedUserIds.length > 0) {
			NotificationCenterStore.sendNotification(this.checkedUserIds, form)
			this.closeSendNotification(form)
		} else {
			GlobalStore.setNotificationMessage("Common.label.checkedOnlyOneUser")
		}
	}
}

var singleton = new NotifyStore()
export default singleton
