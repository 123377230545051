import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "spa"
				},
				paperTemplateId: {
					value: "spa_with_template"
				},

				sprievodcaMeno: {
					label: <FormattedMessage id="PrintTemplate.Spa.sprievodcaMeno" />,
					value: ""
				},
				sprievodcaRodneCislo: {
					label: <FormattedMessage id="PrintTemplate.Spa.sprievodcaRodneCislo" />,
					value: ""
				},
				sprievodcaTelefon: {
					label: <FormattedMessage id="PrintTemplate.Spa.sprievodcaTelefon" />,
					value: ""
				},
				sprievodcaAdresa: {
					label: <FormattedMessage id="PrintTemplate.Spa.sprievodcaAdresa" />,
					value: ""
				},
				sprievodcaPSC: {
					label: <FormattedMessage id="PrintTemplate.Spa.sprievodcaPSC" />,
					value: ""
				},
				sprievodcaZamestnavatel: {
					label: <FormattedMessage id="PrintTemplate.Spa.sprievodcaZamestnavatel" />,
					value: ""
				},
				pacientZamestnavatel: {
					label: <FormattedMessage id="PrintTemplate.Spa.pacientZamestnavatel" />,
					value: ""
				},

				indikacnaSkupina: {
					label: <FormattedMessage id="PrintTemplate.Spa.FormLabel1" />
				},
				identifikacnaSkupina1: {
					label: <FormattedMessage id="PrintTemplate.Spa.identifikacnaSkupina1" />,
					value: ""
				},
				identifikacnaSkupina2: {
					label: <FormattedMessage id="PrintTemplate.Spa.identifikacnaSkupina2" />,
					value: ""
				},
				kod: {
					value: ""
				},
				kod1: {},
				kod2: {},
				kod3: {},
				diagnoza: {
					value: ""
				},
				kodID: {
					label: <FormattedMessage id="PrintTemplate.Spa.kodID" />,
					value: ""
				},
				vedlajsieDiagnozy: {
					label: <FormattedMessage id="PrintTemplate.Spa.vedlajsieDiagnozy" />,
					value: ""
				},

				urcenaKupelnaLiecebna: {
					label: <FormattedMessage id="PrintTemplate.Spa.urcenaKupelnaLiecebna" />,
					value: ""
				},
				zdovodnenieZamietnutia: {
					label: <FormattedMessage id="PrintTemplate.Spa.zdovodnenieZamietnutia" />,
					value: ""
				},

				adresaKupelnehoZariadenia: {
					label: <FormattedMessage id="PrintTemplate.Spa.adresaKupelnehoZariadenia" />,
					value: ""
				},
				kupelneZariadenieICO: {
					label: <FormattedMessage id="PrintTemplate.Spa.kupelneZariadenieICO" />,
					value: ""
				},
				nastupDna: {
					label: <FormattedMessage id="PrintTemplate.Spa.nastupDna" />,
					// value: moment(),
					type: "date"
				},

				lsVyska: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsVyska" />,
					value: ""
				},
				lsHmotnost: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsHmotnost" />,
					value: ""
				},
				lsTK1: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsTK1" />,
					value: ""
				},
				lsTK2: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsTK2" />,
					value: ""
				},
				lsP: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsP" />,
					value: ""
				},
				lsAnamneza: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsAnamneza" />,
					value: ""
				},
				lsDatum: {
					label: <FormattedMessage id="Common.label.date" />,
					value: moment(),
					type: "date"
				},
				lsFW1: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsFW1" />,
					value: ""
				},
				lsFW2: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsFW2" />,
					value: ""
				},
				lsMoc: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsMoc" />,
					value: ""
				},
				lsKO: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsKO" />,
					value: ""
				},
				lsRtgHrudnika: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsRtgHrudnika" />,
					value: ""
				},
				lsEKG: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsEKG" />,
					value: ""
				},
				lsIne: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsIne" />,
					value: ""
				},
				lsObjektivnyNalez: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsObjektivnyNalez" />,
					value: ""
				},
				lsZdovodnenieSprievodcu: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsZdovodnenieSprievodcu" />,
					value: ""
				},

				datum: {
					label: <FormattedMessage id="Common.label.date" />,
					value: moment(),
					type: "date"
				},

				kupelnaStarostlivostUstavnaCheckbox: {
					label: <FormattedMessage id="PrintTemplate.Spa.kupelnaStarostlivostUstavnaCheckbox" />,
					type: "checkbox",
					value: false
				},
				kupelnaStarostlivostAmbulantnaCheckbox: {
					label: <FormattedMessage id="PrintTemplate.Spa.kupelnaStarostlivostAmbulantnaCheckbox" />,
					type: "checkbox",
					value: false
				},
				kupelnaStarostlivostDetskaCheckbox: {
					label: <FormattedMessage id="PrintTemplate.Spa.kupelnaStarostlivostDetskaCheckbox" />,
					type: "checkbox",
					value: false
				},
				stupenNaliehavosti1Checkbox: {
					label: <FormattedMessage id="PrintTemplate.Spa.stupenNaliehavosti1Checkbox" />,
					type: "checkbox",
					value: false
				},
				stupenNaliehavosti2Checkbox: {
					label: <FormattedMessage id="PrintTemplate.Spa.stupenNaliehavosti2Checkbox" />,
					type: "checkbox",
					value: false
				},
				sprievodcaPrePobytAnoCheckbox: {
					label: <FormattedMessage id="PrintTemplate.Spa.sprievodcaPrePobytAnoCheckbox" />,
					type: "checkbox",
					value: false
				},
				sprievodcaPrePobytNieCheckbox: {
					label: <FormattedMessage id="PrintTemplate.Spa.sprievodcaPrePobytNieCheckbox" />,
					type: "checkbox",
					value: false
				},

				skkNavrhSchvalenyCheckbox: {
					label: <FormattedMessage id="PrintTemplate.Spa.skkNavrhSchvalenyCheckbox" />,
					type: "checkbox",
					value: false
				},
				skkStupenNaliehavosti1Checkbox: {
					label: <FormattedMessage id="PrintTemplate.Spa.skkStupenNaliehavosti1Checkbox" />,
					type: "checkbox",
					value: false
				},
				skkStupenNaliehavosti2Checkbox: {
					label: <FormattedMessage id="PrintTemplate.Spa.skkStupenNaliehavosti2Checkbox" />,
					type: "checkbox",
					value: false
				},
				skkSprievodcaPrePobytAnoCheckbox: {
					label: <FormattedMessage id="PrintTemplate.Spa.skkSprievodcaPrePobytAnoCheckbox" />,
					type: "checkbox",
					value: false
				},
				skkSprievodcaPrePobytNieCheckbox: {
					label: <FormattedMessage id="PrintTemplate.Spa.skkSprievodcaPrePobytNieCheckbox" />,
					type: "checkbox",
					value: false
				},
				skkNavrhZamietnutyCheckbox: {
					label: <FormattedMessage id="PrintTemplate.Spa.skkNavrhZamietnutyCheckbox" />,
					type: "checkbox",
					value: false
				},

				lsPacientChodiaciCheckbox: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsPacientChodiaciCheckbox" />,
					type: "checkbox",
					value: false
				},
				lsPacientBarlyCheckbox: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsPacientBarlyCheckbox" />,
					type: "checkbox",
					value: false
				},
				lsPacientVozikCheckbox: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsPacientVozikCheckbox" />,
					type: "checkbox",
					value: false
				},

				navrhol: {
					label: <FormattedMessage id="PrintTemplate.Spa.navrhol" />,
					value: ""
				},
				overil: {
					label: <FormattedMessage id="PrintTemplate.Spa.overil" />,
					value: ""
				},
				datumOverenia: {
					label: <FormattedMessage id="PrintTemplate.Spa.datumOverenia" />,
					value: moment(),
					type: "date"
				}
			}
		}
	}
}
