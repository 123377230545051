import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import bindings from "../../../../../global/ui/globalUISchemeBindings"
import {FormattedMessage} from "react-intl"

import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"

import Grid from "@material-ui/core/Grid"

import fields from "./employeeInfoFormField"
import loadBindings from "./employeeInfoFormBindings"
import saveBindings from "./employeeInfoFormSaveBindings"
import {injectIntl} from "react-intl"
import {loadDataToFormByScheme, insertFormDataByScheme} from "../../../../../global/helpers/bindings"
// import {getUserCompanyInfo} from "../../../../../global/helpers/actions"
import {padStart} from "../../../../../global/helpers/functions"
import GlobalStore from "../../../../../global/store/GlobalStore"
// import RouterStore from "../../../../../global/store/RouterStore"
import EmployeesDetailStore from "../../../stores/EmployeesDetailStore"
import DuplicityPersonModal from "./duplicityPersonModal"
import DuplicityPersonModalStore from "../../../stores/DuplicityPersonModalStore"
import WarningStore from "../../../../../global/store/WarningStore"
import ambulanceApi from "../../../../ambulance/actions/api"

const initFormData = (form, id) => {
	if (isSafe(id)) {
		loadDataToFormByScheme(form, loadBindings, {employeeId: id})
	}
}

@injectIntl
@observer
export default class EmployeeInfoForm extends React.Component {
	constructor(props) {
		super(props)

		this.loadedData = false

		const hooks = {
			onSubmit(/*form*/) {
				// special validation here
			},
			onSuccess(form) {
				form.$("title_before").value = form.$("title_before").value === "" ? null : form.$("title_before").value
				form.$("title_after").value = form.$("title_after").value === "" ? null : form.$("title_after").value

				insertFormDataByScheme(
					form,
					saveBindings,
					{entityId: props.entityId},
					() => {
						loadDataToFormByScheme(form, loadBindings, {employeeId: props.employeeId}),
							(EmployeesDetailStore.editEmployee = false)
					},
					false,
					"EmployeeDetail.employeeInfoForm.saved"
				)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}
		GlobalStore.refreshCodeLists([
			"genders",
			"titles",
			"countries",
			"consentTypes",
			"contactTypes",
			"insuranceTypes",
			"employeeCategory"
		])
		this.form = new MobxReactForm(fields.load(), {
			plugins: {dvr: validatorjs},
			hooks,
			bindings
		})
		if (isSafe(GlobalStore.BLConfiguration)) {
			initFormData(this.form, props.employeeId)
			this.loadedData = true
		}
	}

	changeIdentifier(field) {
		const identifier = field.value
		if (!this.stopRefresh && field.changed && !this.form.$("dateOfBirth").changed) {
			if (isFinite(identifier)) {
				const idLength = identifier.length
				let isInvalidIdentifier = false

				if (idLength === 9 || idLength === 10) {
					if (idLength === 10 && +identifier % 11 !== 0) {
						isInvalidIdentifier = true
					}

					const idfYear = +identifier.substr(0, 2)
					const actualYear = new Date()
						.getFullYear()
						.toString()
						.substr(2, 2)

					const year = +idfYear + (+idfYear <= +actualYear && +idfYear < 54 && idLength === 10 ? 2000 : 1900)
					let month = +identifier.substr(2, 2) - (+identifier.substr(2, 2) > 12 ? 50 : 0)
					let day = +identifier.substr(4, 2)
					const gender = +identifier.substr(2, 2) > 12 ? "F" : "M"

					month = padStart(month, 2, "0")
					day = padStart(day, 2, "0")

					if (idLength === 9 && year > 1953) {
						isInvalidIdentifier = true
					}

					const testDate = new Date(`${year}-${month}-${day}`)

					if (testDate instanceof Date == false || isNaN(testDate) || testDate.getDate() !== +day) {
						WarningStore.open(
							`${this.props.intl.formatMessage({id: "Patient.form.patientinfo.invalidIdentifierWarning"})}`
						)
					} else {
						this.form.$("dateOfBirth").set("value", `${year}-${month}-${day}`)
						this.form.$("gender").set("value", gender)

						// aj keby nebolo rodne cislo validne, aj tak urob kontrolu diplicity pacientov, lebo uz moze existovat
						let request = {
							filters: [
								{
									associated_column: "identifier",
									predicate: "=",
									values: [{id_value: identifier}]
								}
							]
						}

						ambulanceApi
							.getDuplicityPerson(request)
							.call()
							.then((response) => {
								if (isSafe(response) && isSafe(response.rows) && response.rows.length > 0) {
									DuplicityPersonModalStore.patientData = response.rows
									DuplicityPersonModalStore.open(this.form)
								}
							})
							.then(() => {
								if (isInvalidIdentifier)
									WarningStore.open(
										`${this.props.intl.formatMessage({id: "Patient.form.patientinfo.invalidIdentifierWarning"})}`
									)
							})
							.catch(() => {
								if (isInvalidIdentifier)
									WarningStore.open(
										`${this.props.intl.formatMessage({id: "Patient.form.patientinfo.invalidIdentifierWarning"})}`
									)
							})
					}
				}
			}
		}
	}

	render() {
		if (isSafe(GlobalStore.BLConfiguration) && !this.loadedData) {
			initFormData(this.form, this.props.employeeId)
			this.loadedData = true
		}
		return (
			<Grid container className="pr-8 pl-8">
				<Grid container direction="column" className="bgSnowWhite borderRadius-2 mb-6">
					<Grid item container spacing={8} className="pt-4 pr-4 pl-4">
						<Grid item xs={3}>
							<XsInput
								field={this.form.$("identifier")}
								onBlur={(field) => this.changeIdentifier(field)}
								disabled={!EmployeesDetailStore.editEmployee}
							/>
						</Grid>
						<Grid item xs={3}>
							<SearchSelect
								field={this.form.$("title_before")}
								items={isSafe(GlobalStore.CL["titles"]) ? GlobalStore.CL["titles"].filter((obj) => obj.before) : []}
								getOptionLabel={(row) => row.code_ext}
								disabled={!EmployeesDetailStore.editEmployee}
							/>
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("firstname")} disabled={!EmployeesDetailStore.editEmployee} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("lastname")} disabled={!EmployeesDetailStore.editEmployee} />
						</Grid>
					</Grid>
					<Grid item container spacing={8} className="pr-4 pl-4 pb-4">
						<Grid item xs={3}>
							<SearchSelect
								field={this.form.$("title_after")}
								items={isSafe(GlobalStore.CL["titles"]) ? GlobalStore.CL["titles"].filter((obj) => !obj.before) : []}
								getOptionLabel={(row) => row.code_ext}
								disabled={!EmployeesDetailStore.editEmployee}
							/>
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("birthname")} disabled={!EmployeesDetailStore.editEmployee} />
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.form.$("dateOfBirth")} disabled={!EmployeesDetailStore.editEmployee} />
						</Grid>
						<Grid item xs={3}>
							<SearchSelect
								inputWhite={true}
								field={this.form.$("gender")}
								items={GlobalStore.CL["genders"]}
								disabled={!EmployeesDetailStore.editEmployee}
								required
							/>
						</Grid>
					</Grid>
					<Grid item container className="pt-2 pr-4 pb-2 pl-4 xsformFooter">
						<Grid container spacing={8}>
							{EmployeesDetailStore.editEmployee ? (
								<React.Fragment>
									<Grid item>
										<XsButton
											className="xs-success"
											text={<FormattedMessage id="Common.label.save" />}
											type="submit"
											onClick={this.form.onSubmit}
											icon={<i className="fal fa-check fa-lg"></i>}
										/>
									</Grid>
									<Grid item>
										<XsButton
											className="xs-danger xs-outline"
											text={<FormattedMessage id="Common.label.cancel" />}
											type="submit"
											onClick={() => {
												EmployeesDetailStore.editEmployee = false
											}}
											icon={<i className="fal fa-times fa-lg" />}
										/>
									</Grid>
								</React.Fragment>
							) : (
								<Grid item>
									<XsButton
										className="xs-primary xs-outline"
										text={<FormattedMessage id="Common.label.edit" />}
										type="submit"
										onClick={() => (EmployeesDetailStore.editEmployee = true)}
										icon={<i className="fal fa-pencil-alt fa-lg"></i>}
									/>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
				<DuplicityPersonModal />
			</Grid>
		)
	}
}
