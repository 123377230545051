import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				login: {
					label: <FormattedMessage id="Employee.form.user.login" />,
					rules: "required"
				},
				password: {
					label: <FormattedMessage id="Employee.form.user.password" />,
					rules: "required"
				},
				password2: {
					label: <FormattedMessage id="Employee.form.user.password2" />,
					rules: "required"
				},
				system: {
					label: <FormattedMessage id="EmployeeDetail.employeeUser.system" />,
					rules: "required"
				},
				sub_identifier: {
					label: <FormattedMessage id="Common.label.orgunit" />,
					// rules: "required"
				},
				externalId: {}
			}
		}
	}
}
