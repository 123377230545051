import React from "react"
import {observer} from "mobx-react"
import EmployeeMedicalWorkerForm from "./employeeMedicalWorkerForm/employeeMedicalWorkerForm"
import EmployeeMedWorkExpContainer from "./employeeMedicalWorkerExpertise/employeeMedWorkExpContainer"
import XsTabsIcon from "../../../../../global/ui/xsTabs/xsTabsIcon"
import Grid from "@material-ui/core/Grid"
import {FormattedMessage} from "react-intl"
import EmployeesDetailStore from "../../../stores/EmployeesDetailStore"

@observer
export default class EmployeeMedicalWorkerContainer extends React.Component {
	constructor(props) {
		super(props)
		EmployeesDetailStore.employeeContrats = []
		EmployeesDetailStore.employeeContratsEdited = []
		EmployeesDetailStore.workerExpertiseList = []
		EmployeesDetailStore.loadWorkerExpertise(props.personnelId)
		EmployeesDetailStore.loadEmployeeContrats(props.employeeId)
	}

	render() {
		return (
			<Grid container direction="column" className="pa-8">
				<EmployeeMedicalWorkerForm
					entityId={this.props.entityId}
					employeeId={this.props.employeeId}
					personnelId={this.props.personnelId}
				/>
				{isNotEmpty(this.props.personnelId) && (
					<Grid item xs={12}>
						<XsTabsIcon
							value="expertise"
							onChangeTab={(value) => value}
							scrollButtons={false}
							horizontal={true}
							mainClass="xs-patient-info-tab"
							background="#f0f3f8"
							headerClassName="filled"
							//defaultValue="contacts"
							tabs={[
								{
									label: (
										<div>
											<FormattedMessage id="PatientDetail.cards.expertise" />
										</div>
									),
									value: "expertise",
									icon: <i className="fas fa-user-md fa-lg"></i>,
									content: <EmployeeMedWorkExpContainer personnelId={this.props.personnelId} />
								}
							]}
						/>
					</Grid>
				)}
			</Grid>
		)
	}
}
