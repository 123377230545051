import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "header"
				},
				customTemplateName: {
					label: <FormattedMessage id="Patient.form.patientrecord.clarification" />
				},
				date: {
					label: <FormattedMessage id="Common.lable.datetime" />,
					value: moment(),
					type: "date"
				},
				dateTime: {
					label: <FormattedMessage id="Common.lable.datetime" />,
					value: moment().format("DD.MM.YYYY HH:mm")
				},
				dekurz: {
					label: <FormattedMessage id="PrintTemplate.Header.dekurz" />,
					value: ""
				}
			}
		}
	}
}
