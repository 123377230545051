import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {FormattedMessage, injectIntl} from "react-intl"

import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import {insertFormDataByScheme} from "../../../../../global/helpers/bindings"
import {getUserCompanyInfo} from "../../../../../global/helpers/actions"

import Grid from "@material-ui/core/Grid"

import fields from "./OrgunitFormFields"
import {defaultSaveBindings, companySaveBindings} from "./OrgunitFormBindings"

import OrgunitDetailStore from "../../../stores/OrgunitDetailStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import ambulanceApi from "../../../../ambulance/actions/api"

@injectIntl
@observer
export default class OrgunitForm extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit(/*form*/) {},
			onSuccess(form) {
				let company = getUserCompanyInfo()

				form.$("superiorOJ").value = isNotEmpty(form.$("superiorOJ").value) ? form.$("superiorOJ").value : null
				let idsObj = []
				if (isNotEmpty(form.$("OUPZScode").value)) {
					idsObj.push({
						_ref: false,
						_type: "IXS.Data.Object.Identifier",
						value: form.$("OUPZScode").value,
						type: "PROVIDERCODE"
					})
				}
				if (isNotEmpty(form.$("JRUZid").value)) {
					idsObj.push({
						_ref: false,
						_type: "IXS.Data.Object.Identifier",
						value: form.$("JRUZid").value,
						type: "JRUZID"
					})
				}
				insertFormDataByScheme(
					form,
					isEmpty(props.companyId) ? defaultSaveBindings : companySaveBindings,
					{
						idsObj: idsObj,
						orgunitId: props.id,
						companyId: isEmpty(form.$("company").value)
							? isNotEmpty(props.companyId)
								? props.companyId
								: company.id
							: form.$("company").value,
						owner: isNotEmpty(props.companyId) ? company.object_owner : ""
					},
					() => {
						OrgunitDetailStore.loadOrgunitData(props.id)
						OrgunitDetailStore.editOrgunit = false
					},
					false,
					"Common.notification.save"
				)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks})
		OrgunitDetailStore.loadFormData(this.form)
	}

	render() {
		return (
			<Grid container className="pr-8 pl-8">
				<Grid container direction="column" className="bgSnowWhite borderRadius-2 mb-6">
					<Grid item container spacing={8} className="pa-4">
						<Grid item xs={3}>
							<XsInput disabled={!OrgunitDetailStore.editOrgunit} field={this.form.$("code")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput disabled={!OrgunitDetailStore.editOrgunit} field={this.form.$("name")} />
						</Grid>
						<Grid item xs={3}>
							<SearchSelect
								disabled={!OrgunitDetailStore.editOrgunit}
								field={this.form.$("superiorOJ")}
								items={OrgunitDetailStore.companyOrgunits}
							/>
						</Grid>
						<Grid item xs={3}>
							<XsInput disabled={!OrgunitDetailStore.editOrgunit} field={this.form.$("OUPZScode")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput disabled={!OrgunitDetailStore.editOrgunit} field={this.form.$("JRUZid")} />
						</Grid>
						<Grid item xs={3}>
							<XsAutocomplete
								disabled={!OrgunitDetailStore.editOrgunit}
								field={this.form.$("expertise")}
								minLengthForSearch="3"
								api={ambulanceApi.loadExpertise}
								apiCallType="Pr"
								inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
								saveValue={(obj) => obj._id}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: (
													<FormattedMessage id="Patient.form.patientrequest.expertise.autoCompleteModal.CodeColumn" />
												),
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px",
													header: {
														className: "xs-autoCompleteModalColumnHeader"
													}
												}
											},
											name_ext: {
												title: <FormattedMessage id="Common.label.name" />,
												type: "string",
												dbName: "search_column",
												design: {
													header: {
														className: "xs-autoCompleteModalColumnHeader"
													}
												},
												filter: {
													gridWidth: 3,
													defaultValue: textValue,
													renderElement: "input"
												},
												sortable: true
											}
										},
										options: {
											// selectRow: true,
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
					</Grid>
					<Grid item container className="pt-2 pr-4 pb-2 pl-4 xsformFooter">
						<Grid container spacing={8}>
							{OrgunitDetailStore.editOrgunit ? (
								<React.Fragment>
									<Grid item>
										<XsButton
											className="xs-success"
											text={<FormattedMessage id="Common.label.save" />}
											type="submit"
											onClick={this.form.onSubmit}
											icon={<i className="fal fa-check fa-lg" />}
										/>
									</Grid>
									<Grid item>
										<XsButton
											className="xs-danger xs-outline"
											text={<FormattedMessage id="Common.label.cancel" />}
											type="submit"
											onClick={() => {
												OrgunitDetailStore.editOrgunit = false
											}}
											icon={<i className="fal fa-times fa-lg" />}
										/>
									</Grid>
								</React.Fragment>
							) : (
								<Grid item>
									<XsButton
										className="xs-primary xs-outline"
										text={<FormattedMessage id="Common.label.edit" />}
										type="submit"
										onClick={() => (OrgunitDetailStore.editOrgunit = true)}
										icon={<i className="fal fa-pencil-alt fa-lg"></i>}
									/>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		)
	}
}
