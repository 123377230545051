import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import EmployeeUserForm from "./employeeUserForm"
import XsTabsIcon from "../../../../../global/ui/xsTabs/xsTabsIcon"
import EmployeeUserExternalCredentials from "./externalCredentials/employeeUserExternalCredentials"
import EmployeesDetailStore from "../../../stores/EmployeesDetailStore"

@observer
export default class EmployeeUserContainer extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Grid container direction="column" className="pa-8">
				<EmployeeUserForm entityId={this.props.entityId} />
				{isNotEmpty(EmployeesDetailStore.employeeUserId) && (
					<Grid item xs={12}>
						<XsTabsIcon
							background="#f0f3f8"
							headerClassName="filled"
							scrollButtons={false}
							horizontal={true}
							value={"external"}
							onChangeTab={(value) => value}
							mainClass="xs-patient-info-tab"
							tabs={[
								{
									label: (
										<div>
											<FormattedMessage id="EmployeeDetail.employeeUser.externalCredentials" />
										</div>
									),
									value: "external",
									icon: <i className="fas fa-external-link-alt fa-lg"></i>,
									content: <EmployeeUserExternalCredentials entityId={this.props.entityId} />
								}
							]}
						/>
					</Grid>
				)}
			</Grid>
		)
	}
}
