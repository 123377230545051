import api from "../actions/api"
import {observable, action} from "mobx"

import {getUserPersonnelID, getSelectedOrgUnitID, exportToExcel} from "../../../global/helpers/actions"
import moment from "moment"
// import moment from "moment"
// import settings from "../../../global/config/settings"

class PatientsWithoutPPStore {
	@observable isOpen = false

	totalCount = 0
	@observable infoMessage = "Table.NoData"
	@observable patientWithoutData = []

	refreshInterval = null

	formRef = undefined

	page = 0
	rowsPerPage = 10

	@observable changedFilters = false
	currFilters = {}

	@action isChangedFilter(formValues) {
		if (
			this.currFilters.insurer != formValues.insurer ||
			this.currFilters.identifier != formValues.identifier ||
			this.currFilters.orgunit != formValues.orgunit
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@action prepareReport(state) {
		api
			.prepareReport("SKPC0", {
				sync: false,
				provider_id: getSelectedOrgUnitID(),
				date: moment().format("YYYY-MM-DD"),
				accepted_stamp: moment().add(-4, "hours"),
				generate: true,
				force: false
			})
			.call(null, null, null, 300000)
			.then((res) => {
				if (isSafe(res) && isSafe(res._id)) {
					if (res.status == "R") {
						api
							.listReport(res._id, {
								row_offset: state.page == 0 ? 1 : state.page * state.rowsPerPage + 1,
								row_count_show: state.rowsPerPage,
								filters: this.filters()
							})
							.call()
							.then((list) => {
								this.isChangedFilter(this.formRef.values())

								this.infoMessage = "Table.NoData"

								this.totalCount = list.row_count_full
								this.patientWithoutData = list.rows

								this.page = state.page
								this.rowsPerPage = state.rowsPerPage

								if (isSafe(this.refreshInterval)) {
									clearInterval(this.refreshInterval)
								}
							})
					} else {
						this.isChangedFilter(this.formRef.values())

						if (res.status == "C" || res.status == "N") {
							this.infoMessage = "Common.label.processingReport"
						} else {
							this.infoMessage = "Common.label.processingReportError"
						}

						this.patientWithoutData = []
						this.totalCount = 0
					}
				}
			})
	}

	@action getFilters(form, state) {
		this.currFilters = form.values()
		this.formRef = form

		this.refreshInterval = setInterval(() => {
			this.prepareReport(state)
		}, 30000)

		this.prepareReport(state)
	}

	filters = () => {
		const form = this.formRef

		let filters = [
			{
				associated_column: "personnel_id",
				values: [
					{
						id_value: getUserPersonnelID()
					}
				]
			}
		]

		if (isNotEmpty(form.$("orgunit").value)) {
			filters.push({associated_column: "provider_id", values: [{id_value: form.$("orgunit").value}]})
		}

		if (isNotEmpty(form.$("insurer").value)) {
			filters.push({associated_column: "insurer", values: [{id_value: form.$("insurer").value}]})
		}

		if (isNotEmpty(form.$("identifier").value)) {
			filters.push({associated_column: "identifier", values: [{id_value: form.$("identifier").value}]})
		}

		return filters
	}

	@action exportToXls(form) {
		let filters = []

		if (isNotEmpty(form.values().exportType)) {
			filters.push({
				associated_column: "exportType",
				values: [
					{
						id_value: form.values().exportType
					}
				]
			})
		}

		if (isSafe(this.formRef && isSafe(this.formRef.values()))) {
			let refFormValues = this.formRef.values()

			if (isNotEmpty(refFormValues.orgunit)) {
				filters.push({
					associated_column: "provider_id",
					values: [{id_value: refFormValues.orgunit}]
				})
			}

			if (isNotEmpty(refFormValues.insurer)) {
				filters.push({
					associated_column: "insurer",
					values: [{id_value: refFormValues.insurer}]
				})
			}

			if (isNotEmpty(refFormValues.identifier)) {
				filters.push({
					associated_column: "identifier",
					values: [{id_value: refFormValues.identifier}]
				})
			}
		}

		let req = {
			filters: filters,
			uri: api.loadPatientWithoutPP().getPath()
		}

		if (form.values().exportType == "now") {
			req.row_count_show = this.rowsPerPage
			// req.row_count_full = this.rowsPerPage
			req.row_offset = this.page * this.rowsPerPage + 1
		} else {
			req.row_offset = 1
			req.row_count_show = 100000
			// req.row_count_full = 100000
		}

		exportToExcel(req, () => {
			this.close(), form.reset()
		})
	}
}
var singleton = new PatientsWithoutPPStore()
export default singleton
