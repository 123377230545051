import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import XsInputSearchFilter from "../../../../../global/ui/xsInput/xsInputSearchFilter"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
// import XsDropdownList from "../../../../../global/ui/xsDropdown/xsDropdownList"

import {TableHead, Table, TableBody, TableCell, TableRow} from "@material-ui/core"

import PricePerAttributeItemForm from "./pricePerAttributeItemForm"

import api from "../../../actions/api"
import AgreementAttributeStore from "../../../stores/AgreementAttributeStore"
// import GlobalStore from "../../../../../global/store/GlobalStore"
import {TextField} from "@material-ui/core"

@injectIntl
@observer
export default class PricePerAttributeItemGrid extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			order: false,
			orderBy: null,
			sortType: ""
		}
	}

	sortable = []

	tableBodyRows
	tableBody() {
		this.tableBodyRows = []

		if (
			isSafe(AgreementAttributeStore.attributeDataJSON) &&
			AgreementAttributeStore.attributeDataJSON.hasOwnProperty(AgreementAttributeStore.typeFilter) &&
			Object.keys(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]).length > 0
		) {
			if (!AgreementAttributeStore.isEditAttributeGrid) {
				this.sortable = []
				for (let key in AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]) {
					this.sortable.push([key, AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][key]])
				}

				this.sortable.sort(function(a, b) {
					if (a[1].delivery_item.code_ext < b[1].delivery_item.code_ext) {
						return -1
					}
					if (a[1].delivery_item.code_ext > b[1].delivery_item.code_ext) {
						return 1
					}
					return 0
				})
			}

			this.sortable.forEach((row, idx) => {
				// Object.keys(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]).forEach(
				// (rowId, idx) => {
				let rowId = row[0]
				if (AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].active) {
					this.tableBodyRows.push(
						<TableRow key={idx}>
							<TableCell>
								{AgreementAttributeStore.isEditAttributeGrid ? (
									<XsInputSearchFilter
										api={api.deliveryItem}
										minLengthForSearch={3}
										// isValid={isEmpty(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].delivery)}
										onButtonClick={(value) =>
											delayedCallback(500, () => AgreementAttributeStore.searchDeliveriesItem(value, rowId))
										}
										data={AgreementAttributeStore.deliveriesItemGrid[rowId]}
										onSearchClose={() => (AgreementAttributeStore.deliveriesItemGrid[rowId] = [])}
										defaultValue={AgreementAttributeStore.deliveryItemsGridJSON[rowId]}
										className="xs-input"
										inputRenderer={(dataRow) => {
											return dataRow.name_ext
										}}
										valueRenderer={(dataRow) => {
											return dataRow.name_ext
										}}
										chooseItem={(value) => {
											AgreementAttributeStore.changedAttribute(
												AgreementAttributeStore.typeFilter,
												rowId,
												"delivery_item",
												value._id
											)
										}}
										modalTitle={<FormattedMessage id="Delivery.List.AddItems" />}
										modalConfig={(textValue, clickHandler) => {
											return {
												columnDefs: {
													name_ext: {
														title: <FormattedMessage id="Common.label.code" />,
														type: "string",
														dbName: "search_column",
														filter: {
															gridWidth: 9,
															defaultValue: textValue,
															renderElement: "input"
														}
														// sortable: true
													}
												},
												options: {
													// selectRow: true,
													showCursor: true,
													onRowClick: (dataRow) => {
														clickHandler(dataRow)
													}
												}
											}
										}}
									/>
								) : (
									AgreementAttributeStore.deliveryItemsGridJSON[rowId]
								)}
							</TableCell>
							<TableCell className="xs-width-150 xs-text-right">
								{AgreementAttributeStore.isEditAttributeGrid ? (
									<TextField
										value={
											AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
												.price_in_currency
										}
										onChange={(e) =>
											AgreementAttributeStore.changedAttribute(
												AgreementAttributeStore.typeFilter,
												rowId,
												"price_in_currency",
												e.target.value
											)
										}
										InputProps={{
											className: "xs-input",
											classes: {
												root: !isPrice(
													AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
														.price_in_currency
												)
													? "xs-input-error"
													: ""
											}
										}}
									/>
								) : (
									priceFormat(
										AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
											.price_in_currency
									)
								)}
							</TableCell>
							{AgreementAttributeStore.isEditAttributeGrid && (
								<TableCell className="xs-text-center xs-delete-action">
									<XsIconButton
										icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
										onClick={
											() =>
												AgreementAttributeStore.changedAttribute(
													AgreementAttributeStore.typeFilter,
													rowId,
													"active",
													false
												) /*GlobalStore.openConfirmationDialog("xsDeleteAgreementAttribute", { attributeId: AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].id })*/
										}
									/>
								</TableCell>
							)}
						</TableRow>
					)
				}
			})
		} else {
			this.tableBodyRows.push(
				<TableRow key={1}>
					<TableCell className="xs-table-no-data" colSpan={4}>
						<FormattedMessage id="Table.NoData" />
					</TableCell>
				</TableRow>
			)
		}
	}

	render() {
		this.tableBody()

		return (
			<div className="xs-table-ex">
				<div className="xs-table">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<FormattedMessage id="Agreement.Container.Detail.Attribute.Deliveries" />
								</TableCell>
								<TableCell className="xs-text-right">
									<FormattedMessage id="Agreement.Container.Detail.Attribute.Price" />
								</TableCell>
								{AgreementAttributeStore.isEditAttributeGrid && <TableCell />}
							</TableRow>
						</TableHead>
						<TableBody>{this.tableBodyRows}</TableBody>
					</Table>
				</div>
				<div className="xs-attribute-form">
					<PricePerAttributeItemForm />
				</div>
			</div>
		)
	}
}
