import {ApiCall} from "../../global/helpers/api"

export default {
	getCurrentUser() {
		return new ApiCall("/user/current").call()
	},

	// login(login, password, recaptchaToken) {
	// 	return new ApiCall({
	// 		path: `/sec/login?g-recaptcha-response=${recaptchaToken}`,
	// 		method: "POST",
	// 		body: {
	// 			login,
	// 			password
	// 		},
	// 		includeToken: false
	// 	})
	// },

	login(login, password) {
		return new ApiCall({
			path: `/sec/login`,
			method: "POST",
			body: {
				login,
				password
			},
			includeToken: false
		})
	},

	logout() {
		return new ApiCall({
			path: "/sec/logout",
			method: "POST"
		})
	},

	loadSession(identifier) {
		return new ApiCall(`/session/store/${identifier}`, "GET")
	},

	deleteSession(identifier) {
		return new ApiCall(`/session/store/${identifier}`, "DELETE")
	},

	saveSession(saveRequest) {
		return new ApiCall("/session/store", "POST", saveRequest)
	}
}
