import React from "react"

export default class Reprint extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			originYPosition: 0,
			dragMode: false,
			startYPosition: this.props.suggestPosition,
			positionY: 0
		}
	}

	svg
	point

	componentDidMount() {
		this.svg = document.getElementById("printPreviewSvgRootElement")
		this.point = this.svg.createSVGPoint()
	}

	cursorPoint = (e) => {
		this.point.x = e.clientX
		this.point.y = e.clientY

		return this.point.matrixTransform(this.svg.getScreenCTM().inverse())
	}

	mouseHandlers = {
		onMouseDown: (e) => this.setState({originYPosition: this.cursorPoint(e).y, dragMode: true}),
		onMouseUp: () =>
			this.setState({
				startYPosition: Math.round(this.state.startYPosition + this.state.positionY),
				positionY: 0,
				dragMode: false
			}),
		onMouseLeave: () =>
			this.setState({
				startYPosition: Math.round(this.state.startYPosition + this.state.positionY),
				positionY: 0,
				dragMode: false
			}),
		onMouseMove: (e) => {
			const y = this.cursorPoint(e).y

			if (
				this.state.dragMode === true &&
				Math.round(this.state.startYPosition + this.state.positionY) >= 0 &&
				Math.round(this.state.startYPosition + this.state.positionY) <= this.props.maxPrintHeight
			) {
				const posY = y - this.state.originYPosition
				if (
					!(
						Math.round(this.state.startYPosition + posY) < 0 ||
						Math.round(this.state.startYPosition + posY) > this.props.maxPrintHeight
					)
				) {
					this.setState({positionY: posY})
					this.props.onChange(Math.round(this.state.startYPosition + posY))
				}
			}
		},
		mouseFFClick: () => {
			this.setState({
				startYPosition: 0,
				positionY: 0,
				dragMode: false
			})
			this.props.onChange(0)
		}
	}

	render() {
		const {startYPosition, positionY, dragMode} = this.state
		const {paper, suggestPosition, width, height} = this.props
		const {margins} = paper

		const textPaddings = {left: 15, top: 15}

		const printPosition = Math.round(startYPosition + positionY)

		const li =
			"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur "
		let lix = ""
		for (let index = 0; index < 10; index++) {
			lix += li
		}

		const sliderColor = dragMode === true ? "darkgray" : "gray"
		const sliderStroke = dragMode === true ? "blue" : "gray"

		return (
			<svg width={width} height={height}>
				<svg
					id="printPreviewSvgRootElement" //width={paper.width + 2 * margins.left + 100} height={paper.height + 2 * margins.top}
					viewBox={`0 0 ${paper.width + 2 * margins.left} ${paper.height + 2 * margins.top}`}
					{...this.mouseHandlers}
					onMouseDown={null}
				>
					{/* shadow */}
					<rect x={margins.left + 3} y={margins.top - 3} width={paper.width} height={paper.height} />
					{/* paper */}
					<rect
						x={margins.left}
						y={margins.top}
						width={paper.width}
						height={paper.height}
						style={{fill: "white", stroke: "black"}}
					/>
					{/* lorem ipsum */}
					<foreignObject
						x={margins.left + textPaddings.left}
						y={margins.top + textPaddings.top}
						width={paper.width - 2 * textPaddings.left}
						height={paper.height - 2 * textPaddings.top}
					>
						<p style={{fontSize: 3, color: "darkgray", userSelect: "none"}}>{lix}</p>
					</foreignObject>
					{/* clean paper */}
					<rect
						x={margins.left + textPaddings.left / 2}
						y={margins.top + suggestPosition}
						width={paper.width - textPaddings.left}
						height={paper.height - suggestPosition - textPaddings.top / 2}
						style={{fill: "white"}}
					/>
					{/* new data area */}
					<rect
						x={margins.left + textPaddings.left / 2}
						y="0"
						width={paper.width - textPaddings.left}
						height={paper.height - printPosition - textPaddings.top / 2 - 10}
						style={{fill: printPosition < suggestPosition ? "red" : "green", fillOpacity: "0.3"}}
						transform={`translate(0, ${margins.top + printPosition + 10})`}
					/>
					{/* new data */}
					{/* <foreignObject
                        x={margins.left + textPaddings.left} y={margins.top + printPosition + 10 + textPaddings.top}
                        width={paper.width - 2 * textPaddings.left} height={paper.height - printPosition - 2 * textPaddings.top - 10}>
                        <p style={{ fontSize: 3, color: "black", userSelect: "none" }}>{lix}</p>
                    </foreignObject> */}
					{/* slider */}
					<rect
						x={margins.left}
						y="0"
						width={paper.width}
						height="20"
						style={{fill: sliderColor, stroke: sliderStroke, fillOpacity: "0.5"}}
						transform={`translate(0, ${margins.top + printPosition})`}
						onMouseDown={this.mouseHandlers.onMouseDown}
					/>
					{/* arrows */}
					<g transform={`translate(${paper.width + 2 * margins.left}, ${margins.top + printPosition}) scale(2)`}>
						<path d={`M0,0 L-2,3 L2,3 L0,0 L0,10 L-2,7 L2,7 L0,10 Z`} fill="black" stroke="black" strokeWidth="0.3" />
					</g>
					{/* fast forward */}
					<rect
						transform={`translate(${paper.width + 2 * margins.left}, ${margins.top + printPosition}) scale(2)`}
						onClick={this.mouseHandlers.mouseFFClick}
						width="12"
						height="20"
						x="0"
						y="-6"
						fillOpacity="0"
						style={{cursor: "pointer"}}
					/>
					<g
						transform={`translate(${paper.width + 2 * margins.left}, ${margins.top + printPosition}) scale(2)`}
						onClick={this.mouseHandlers.mouseFFClick}
					>
						<path
							d={`M5,0 L3,3 L7,3 L5,0 M3,0 L7,0 L5,-3 L3,0 Z`}
							fill="black"
							stroke="black"
							strokeWidth="0.3"
							style={{cursor: "pointer"}}
						/>
					</g>

					{/* position in mm */}
					<text
						x={margins.left - 5}
						y={margins.top + printPosition + 10}
						textAnchor="end"
						style={{userSelect: "none"}}
					>{`${printPosition} mm`}</text>
				</svg>
			</svg>
		)
	}
}
