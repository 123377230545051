import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {withStyles} from "@material-ui/core/styles"

// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import SmsReportStore from "../../../stores/SmsReportStore"
import WarningStore from "../../../../../global/store/WarningStore"
import TableStore from "../../../stores/TableStore"

// definitions of form fields
import fields from "./smsReportFilterFields"

import moment from "moment"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

const styles = () => ({
	searchIcon: {
		marginTop: "14px"
	}
})

@withStyles(styles)
@injectIntl
@observer
export default class DailyReportList extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				if (form.values().datefrom || form.values().dateto) {
					if (
						TableStore.tables.hasOwnProperty("smsReportList") &&
						TableStore.tables["smsReportList"].hasOwnProperty("index")
					) {
						delete TableStore.tables["smsReportList"]["index"]
					}

					SmsReportStore.loadSMS(form)
				} else {
					WarningStore.open(props.intl.formatMessage({id: "Common.label.warningDateFromDateTo"}))
				}
			},
			onError() {}
		}

		if (isEmpty(SmsReportStore.formRef)) {
			SmsReportStore.formRef = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		}

		this.form = SmsReportStore.formRef

		SmsReportStore.loadSMS(this.form)
	}

	// componentWillUnmount() {
	// 	SmsReportStore.smsReportData = []
	// }

	// onHandleRowClick = (dataRow) => {
	// 	GlobalStore.changeOrgUnitAndRouteToPatientDetail(this.form.$("orgunit").value, dataRow.person_id)
	// }

	render() {
		SmsReportStore.isChangedFilter(this.form.values())

		return (
			<Grid container className="xs-smsReport-list">
				<Grid item xs={12}>
					<Grid container spacing={8} justify="space-between">
						<Grid item xs={7} container spacing={8} alignItems="center">
							<Grid item xs={3}>
								<XsDateTimePicker white field={this.form.$("datefrom")} showTimeSelect={false} />
							</Grid>
							<Grid item xs={3}>
								<XsDateTimePicker white field={this.form.$("dateto")} showTimeSelect={false} />
							</Grid>
							<Grid item xs={3}>
								<XsInput white field={this.form.$("mobile")} />
							</Grid>
							<Grid item xs={2} className="pb-0">
								<XsIconButton
									rect
									tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
									className="xs-default xs-outline"
									onClick={this.form.onSubmit}
									icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
								/>
							</Grid>
						</Grid>
						<Grid item xs={5} container justify="flex-end">
							<Grid item>
								<XsButton
									disabled={SmsReportStore.changedFilters}
									className={SmsReportStore.changedFilters ? "xs-default xs-outline" : "xs-primary"}
									icon={<i className="fal fa-file-excel fa-lg" />}
									text={<FormattedMessage id="Common.label.export" />}
									onClick={() => SmsReportStore.exportToExcel(this.form)}
								/>
							</Grid>
						</Grid>
					</Grid>
					{SmsReportStore.isFetching ? (
						<XsLoading />
					) : SmsReportStore.changedFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i className="far fa-search fa-lg mr-3 pointer" onClick={this.form.onSubmit}></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTable
							config={{
								columnDefs: {
									sent_at: {
										title: <FormattedMessage id="Common.lable.sendDatetime" />,
										type: "datetime",
										design: {
											width: "175px",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format("DD.MM.YYYY HH:mm") : ""
												}
											}
										}
									},
									phone: {
										title: <FormattedMessage id="Patient.form.contactForm.mobile" />,
										type: "string",
										design: {
											width: "250px"
										}
									},
									message: {
										title: <FormattedMessage id="Common.label.textSMS" />,
										type: "string"
									},
									sms_count: {
										title: "Reálny počet SMS",
										type: "integer",
										design: {
											width: "125px"
										}
									}
								},
								options: {
									showCursor: true,
									name: "smsReportList"
									// selectRow: true,
									// defaultSort: {columnName: "stamp", sortDirection: "asc"},
									// onRowClick: (dataRow) => {
									// 	this.onHandleRowClick(dataRow)
									// }
									// mapper: (dataRow) => {
									// 	return {
									// 		stamp: isSafe(dataRow.date) ? dataRow.date : "",
									// 		full_name: isSafe(dataRow.person_full_name) ? dataRow.person_full_name : "",
									// 		identifier: isSafe(dataRow.person_identifier) ? dataRow.person_identifier : "",
									// 		person_id: dataRow.person_id,
									// 		record_type: dataRow.record_types,
									// 		bill_count: dataRow.bill_count
									// 	}
									// }
								},
								dataSource: SmsReportStore.smsReportData
							}}
						/>
					)}
				</Grid>
			</Grid>
		)
	}
}
