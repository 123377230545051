//@flow
import React from "react"
import moment from "moment"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import {Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import DuplicityPersonStore from "../../../stores/DuplicityPersonModalStore"
import NewPatientDialogStore from "../../../stores/NewPatientDialogStore"
import {getSelectedOrgUnitID} from "../../../../../global/helpers/actions"
// import RouterStore from "../../../../../global/store/RouterStore"

import DataStore from "../../../stores/DataStore"
import api from "../../../actions/api"

@observer
export default class DuplicityPersonModal extends React.Component {
	constructor(props) {
		super(props)
	}

	createClientRel = (patient) => {
		const clientRequest = {
			_id: "",
			_ref: false,
			_type: "Entity.Data.Client",
			active: true,
			entity: {
				_type: "Entity.Data.Person",
				_id: patient._id
			}
		}

		api
			.saveSpecificRel(clientRequest)
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response._id)) {
					this.createPortfolio(patient._id, response._id, patient)
				}
			})
	}

	initNewPatientForm = (patient) => {
		NewPatientDialogStore.newPatientFormRef.$("dateOfBirth").value = moment(patient.birth_date)
		NewPatientDialogStore.newPatientFormRef.$("gender").value = isSafe(patient.gender) ? patient.gender._id : ""
		NewPatientDialogStore.newPatientFormRef.$("title_before").value = isSafe(patient.title_before)
			? patient.title_before._id
			: null
		NewPatientDialogStore.newPatientFormRef.$("firstname").value = patient.first_name
		NewPatientDialogStore.newPatientFormRef.$("lastname").value = patient.last_name
		NewPatientDialogStore.newPatientFormRef.$("birth_name").value = patient.birth_name
		NewPatientDialogStore.newPatientFormRef.$("title_after").value = isSafe(patient.title_after)
			? patient.title_after._id
			: null

		if (isSafe(patient.citizenships) && patient.citizenships.length > 0) {
			const citizenship = patient.citizenships.find(
				(x) => isSafe(x.type) && isSafe(x.validity) && x.validity.to == null
			)

			if (isSafe(citizenship) && isSafe(citizenship.country)) {
				NewPatientDialogStore.newPatientFormRef.$("country").value = citizenship.country._id
			} else {
				NewPatientDialogStore.newPatientFormRef.$("country").value = null
			}
		}

		if (isSafe(patient.specific_rels) && patient.specific_rels.length > 0) {
			const specRels = patient.specific_rels[0]

			if (isSafe(specRels)) {
				if (isSafe(specRels.addresses) && specRels.addresses.length > 0) {
					const paAddress = specRels.addresses.find(
						(address) => address.active && isSafe(address.type) && address.type._id == "PA"
					)

					if (isSafe(paAddress)) {
						NewPatientDialogStore.newPatientFormRef.$("street").value = paAddress.street
						NewPatientDialogStore.newPatientFormRef.$("houseNumber").value = paAddress.house_number
						if (isNotEmpty(paAddress.obj_city) && isNotEmpty(paAddress.obj_city._id)) {
							NewPatientDialogStore.newPatientFormRef.$("city").value = isSafe(paAddress.obj_city)
								? paAddress.obj_city._id
								: null
						} else {
							NewPatientDialogStore.newPatientFormRef.$("cityText").value = paAddress.city_plain
						}
						if (isNotEmpty(paAddress.obj_zip) && isNotEmpty(paAddress.obj_zip._id)) {
							NewPatientDialogStore.newPatientFormRef.$("zip").value = isSafe(paAddress.obj_zip)
								? paAddress.obj_zip._id
								: ""
						} else {
							NewPatientDialogStore.newPatientFormRef.$("zipText").value = paAddress.zip_plain
						}
						NewPatientDialogStore.newPatientFormRef.$("region").value = isSafe(paAddress.region)
							? paAddress.region._id
							: null
						NewPatientDialogStore.newPatientFormRef.$("county").value ? paAddress.county._id : null
						this.addressId = paAddress._id
					}
				}

				if (isSafe(specRels.contacts) && specRels.contacts.length > 0) {
					const contact = specRels.contacts.find((x) => x.active)

					if (isSafe(contact)) {
						NewPatientDialogStore.newPatientFormRef.$("mobile").value = contact.mobile
						NewPatientDialogStore.newPatientFormRef.$("email").value = contact.email
						NewPatientDialogStore.newPatientFormRef.$("phone").value = contact.phone
						NewPatientDialogStore.newPatientFormRef.$("description").value = contact.description
					}
				}

				if (isSafe(specRels.insurances) && specRels.insurances.length > 0) {
					const insurance = specRels.insurances.find((ins) => ins.active && isSafe(ins.validity) && ins.validity.now)

					if (isSafe(insurance)) {
						NewPatientDialogStore.newPatientFormRef.$("insurer").value = isSafe(insurance.insurer)
							? insurance.insurer._id
							: null
						NewPatientDialogStore.newPatientFormRef.$("insurerCode").value = isSafe(insurance.insurer)
							? insurance.insurer.code_ext
							: null
					}
				}
			}
		}
	}

	createPortfolio = (patientId, clientId, patient) => {
		const portRequest = {
			filters: [
				{
					associated_column: "client_id",
					values: [
						{
							id_value: clientId
						}
					]
				}
			]
		}

		api
			.checkPortfolio(portRequest, getSelectedOrgUnitID())
			.call()
			.then((response) => {
				if (isNotSafe(response) || isNotSafe(response.rows) || response.rows.length === 0) {
					const request = {
						_id: "",
						_ref: false,
						_tags: [
							{
								_id: "",
								_ref: false,
								_type: "IXS.Data.Tag",
								public: true,
								type: {
									_type: "IXS.CL.Data.TagType",
									_id: "VIP"
								},
								validity: {
									_ref: false,
									_type: "IXS.Entity.Data.ValidityInterval",
									from: "",
									to: ""
								},
								value: "1"
							}
						],
						_type: "Entity.Data.SpecificRelRelationship",
						rel_type: {
							_type: "Entity.CL.RelationshipType",
							_id: "General"
						},
						specific_rel_1: {
							_type: "Entity.Data.OrgUnit",
							_id: getSelectedOrgUnitID()
						},
						specific_rel_2: {
							_type: "Entity.Data.Client",
							_id: clientId
						},
						validity: {
							_ref: false,
							_type: "IXS.Entity.Data.Validity",
							from: "",
							to: "",
							zone: "L"
						}
					}

					api
						.saveSpecRelToSpecRel(request)
						.call()
						.then((response) => {
							if (isSafe(response) && isSafe(response._id)) {
								// RouterStore.push(`/patient?id=${patientId}`)
								this.initNewPatientForm(patient)
								DataStore.isCreatedFromDuplicityPerson_clientId = clientId
								DataStore.isCreatedFromDuplicityPerson_personId = patientId
							}
						})
				} else {
					this.initNewPatientForm(patient)
					DataStore.isCreatedFromDuplicityPerson_clientId = clientId
					DataStore.isCreatedFromDuplicityPerson_personId = patientId
					// RouterStore.push(`/patient?id=${patientId}`)
				}
			})
	}

	actualizePortfolioAndRedirect = (patient) => {
		if (isSafe(patient.specific_rels) && patient.specific_rels.length > 0) {
			const clients = patient.specific_rels.filter((x) => x._type === "Entity.Data.Client")
			if (isSafe(clients) && clients.length > 0) {
				this.createPortfolio(patient._id, clients[0]._id, patient)
			} else {
				this.createClientRel(patient)
			}
		} else {
			this.createClientRel(patient)
		}
	}

	render() {
		return (
			<Dialog
				id="xsDuplicityPersonDialog"
				open={DuplicityPersonStore.isOpen}
				onClose={() => DuplicityPersonStore.close()}
				onEscapeKeyDown={() => DuplicityPersonStore.close()}
				disableBackdropClick={true}
				scroll="body"
				aria-labelledby="scroll-dialog-title"
				maxWidth="sm"
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-base">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-copy fa-2x"></i>
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Patient.modal.duplicity.header" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					{isSafe(DuplicityPersonStore.patientData) &&
						DuplicityPersonStore.patientData.length > 0 &&
						DuplicityPersonStore.patientData.map((patient, idx) => {
							let genderClassName =
								isSafe(patient.entity.gender) && patient.entity.gender._id === "M" ? "xs-woman" : "xs-woman"
							return (
								<div
									key={idx}
									className="xs-patient-item"
									onClick={() => {
										DuplicityPersonStore.close()
										this.actualizePortfolioAndRedirect(patient.entity)
										// NewPatientDialogStore.close(),
										/*, RouterStore.push(`/patient?id=${patient.entity._id}`)*/
									}}
								>
									<div className="xs-patientInfo">
										<div className="xs-avatar"></div>
										<div className="xs-personalInfo">
											<div className="xs-fullName">{patient.entity.full_name}</div>
											<div className="xs-gender">
												{isSafe(patient.entity.gender) && isSafe(patient.entity.gender.name_ext)
													? patient.entity.gender.name_ext
													: ""}
												, {patient.entity.age} rokov
											</div>
										</div>
									</div>
									<div className={`${genderClassName} xs-insurerId`}>
										<div className="xs-label">
											<FormattedMessage id="Patient.labels.insurerID" defaultMessage="Insurer ID" />
										</div>
										<div className="xs-identifier">{patient.entity.identifier}</div>
									</div>
								</div>
							)
						})}
				</DialogContent>
				<DialogActions className="xs-footer">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times"></i>}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => DuplicityPersonStore.close()}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
