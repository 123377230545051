export default {
	load: {
		storeKey: "PatientProblem",
		type: "EHR.Data.Problem"
	},
	descriptor: {
		_ref: false,
		confidentiality_level: "STANDARD"
	},
	scheme: {
		_id: {
			relation: "@_id"
		},
		active: {
			relation: "@active"
		},
		description: {
			field: "name"
		},
		general_items: {
			array: {
				descriptor: {
					_ref: false,
					type: "dgn",
					_type: "EHR.Data.Record.Diagnosis",
					diagnosis_type: "PRIMARY"
				},
				scheme: {
					item: {
						object: {
							descriptor: {
								type: "EHR.CL.Record.Diagnosis"
							},
							scheme: {
								_id: {
									field: "diagnoses"
								}
							}
						}
					},
					life_threatening: {
						field: "life_threatening"
					},
					description: {
						field: "diagnosis_desc"
					}
				}
			}
		},
		biohazard: {
			field: "biohazard"
		},
		problem_type: {
			field: "problem_type"
		},
		specific_rels: {
			relation: "@specRels"
		},
		reexamination_every_amount: {
			field: "reexamination_every_amount"
		},
		reexamination_every_unit: {
			field: "reexamination_every_unit"
		},
		disease: {
			field: "disease"
		},
		expected_for_amount: {
			field: "expected_for_amount"
		},
		end_reason: {
			field: "end_reason"
		},
		expected_for_unit: {
			field: "expected_for_unit"
		},
		// specific_rels: {
		// 	array: {
		// 		load: (item) => item._type === "EHR.Data.Record.SpecificRel",
		// 		descriptor: {
		// 			type: "pat",
		// 			_ref: false,
		// 			_type: "EHR.Data.Record.SpecificRel"
		// 		},
		// 		scheme: {
		// 			specific_rel: {
		// 				object: {
		// 					descriptor: {
		// 						_type: "Entity.Data.Client"
		// 					},
		// 					scheme: {
		// 						_id: {
		// 							relation: "@patientId"
		// 						}
		// 					}
		// 				}
		// 			}
		// 		}
		// 	}
		// },
		validity: {
			object: {
				scheme: {
					from: {
						field: "startDate"
					},
					to: {
						field: "endDate"
					}
				}
			}
		}
	}
}
