"use strict"

import {observable, action, reaction} from "mobx"
import RouterStore from "./RouterStore"
import DataStore from "../../modules/ambulance/stores/DataStore"
// import CalendarStore from "../../modules/ambulance/stores/CalendarStore"
import {codeListsConfig} from "../config/codeLists"
import api from "../config/api"
import config from "../config/settings"
// import moment from "moment"

// import UIStore from "../../modules/ambulance/stores/UIStore"
import WarningStore from "../../global/store/WarningStore"
import {
	getUser,
	getSelectedOrgUnit,
	getUserPersonnelID,
	logout,
	getSelectedOrgUnitID,
	getGWServerRole
} from "../helpers/actions"
import {ixsIsUndefined, ixsIsNull} from "../helpers/functions"
import settings from "../config/settings"
import {StorageBase} from "../storage/storageEx"
import {USER_SETTINGS} from "../config/constants"
import GWUpdatingDialogStore from "../../modules/ambulance/stores/GWUpdatingDialogStore"
import RegistersCacheStore from "./RegistersCacheStore"

// configure({ isolateGlobalState: true })

class GlobalStore {
	constructor() {
		reaction(
			() => this.forms,
			() => {
				//if (this.forms % 7 === 0)
				this.force = Date.now()
			}
		)
	}
	@observable CL = {}

	@observable isAuthenticated = false
	@observable authentificationFailed = false
	@observable authentificationInProgress = false

	@observable GWAppRunningState = 0 // 0 - not checked yet, 1 - success, 2 - failure, 3 - checking
	@observable ZprRegisteredInEhealthState = 0 // 0 - not checked yet, 1 - success, 2 - failure, 3 - checking
	@observable PrZSRegisteredInEhealthState = 0 // 0 - not checked yet, 1 - success, 2 - failure, 3 - checking

	EHGWErrorStack = []

	@observable force = Date.now()

	@observable forms = 0

	isUpdatingGW = false
	updatingTimeout = null
	updatingTimeoutPassed = false
	isEHGWOutdated = false

	registerZprTimeout = null
	registerZprTimeoutPassed = false

	// getStateColorByType = (type) => {
	// 	let val = undefined
	// 	if (type === "GWApp") {
	// 		val = this.GWAppRunningState
	// 	} else if (type === "Zpr") {
	// 		val = this.ZprRegisteredInEhealthState
	// 	} else if (type === "PrZS") {
	// 		val = this.PrZSRegisteredInEhealthState
	// 	} else {
	// 		val = null
	// 	}
	// 	const colors = ["grey", "green", "red", "yellow"]
	// 	return colors[val]
	// }

	// @computed get GWAppColorState() {
	// 	return this.getStateColorByType("GWApp")
	// }
	// @computed get ZprColorState() {
	// 	return this.getStateColorByType("Zpr")
	// }
	// @computed get PrZSColorState() {
	// 	return this.getStateColorByType("PrZS")
	// }

	@action InitEhealthStates() {
		this.GWAppRunningState = 0
		this.ZprRegisteredInEhealthState = 0
		this.PrZSRegisteredInEhealthState = 0
	}

	@action increaseForms() {
		this.forms = this.forms + 1
	}

	@observable orgunits = []
	@observable orgunitsId = undefined
	@observable orgunitsResourceId = undefined
	@observable orgunitExpertiseCodeExt = undefined
	@observable currentOrgName = ""
	@observable userName = ""
	@observable refresh = Date.now()
	@observable confirmationDialogOpened = undefined

	allowOrgUnitExpertiesCodeExt = ["008", "009", "020", "007", "022"]

	@observable routes = null
	confirmationDialogParams = {}

	openingHour = {}

	@observable notification = {message: "", data: "", variant: "default"}

	@observable gwApp = observable.map({
		Me: "red",
		Zpr: "grey",
		PrZS: "grey",
		Actions: []
	})

	BLConfiguration = undefined
	BLConfigurationLoading = false
	@observable BLConfigurationLoaded = false

	defaultFocusFields = {}
	defaultPrescriptionFields = {}

	@action handleClickFocus(formName, isTextArea = false) {
		if (isTextArea) {
			if (isSafe(this.defaultFocusFields[formName]) && isSafe(this.defaultFocusFields[formName].reactQuillRef)) {
				this.defaultFocusFields[formName].reactQuillRef.focus()
			}
		} else {
			if (isSafe(this.defaultFocusFields[formName])) {
				this.defaultFocusFields[formName].focus()
			}
		}
	}

	@action handleClickPrescriptionFocus(key, isTextArea = false) {
		if (key) {
			if (isTextArea) {
				if (isSafe(this.defaultPrescriptionFields[key]) && isSafe(this.defaultPrescriptionFields[key].reactQuillRef)) {
					this.defaultPrescriptionFields[key.toString()].reactQuillRef.focus()
				}
			} else {
				if (isSafe(this.defaultPrescriptionFields[key])) {
					this.defaultPrescriptionFields[key.toString()].focus()
				}
			}
		}
	}

	@action Autenticate() {
		this.authentificationFailed = false
		this.authentificationInProgress = false
		this.isAuthenticated = true

		//Ciselniky sa loaduju v RegistersCacheStore
		// this.loadCodeLists()

		this.setUserData()
		this.GetBLConfiguration()

		RegistersCacheStore.checkRegisters()

		if (config.GW_BASE_URL) {
			this.GWLogin()
		} else {
			RouterStore.push(settings.HOMEPAGE)
		}
		// this.RegisterZpr()

		// MAROS: toto som zakomentoval a pridal to do then a catch v this.RegisterZpr()
		// RouterStore.push(settings.HOMEPAGE)
	}

	@action GetBLConfiguration() {
		if (isNotSafe(this.BLConfiguration)) {
			api.loadTypeRoutes().then((c) => ((this.BLConfiguration = c), (this.BLConfigurationLoaded = true)))
		}
	}
	@action setUserData() {
		const cookies = getUser()

		if (isSafe(cookies) && ixsIsNull(getSelectedOrgUnit())) {
			let userSettings = StorageBase.getObjectByKey(USER_SETTINGS)
			const defaultOrgUnit = cookies.orgunits.find((o) => o.primary)

			let selectedOrgUnit = null
			if (userSettings && userSettings[cookies.user._id]) {
				selectedOrgUnit = userSettings[cookies.user._id].selectedOrgUnit

				if (isEmpty(cookies.orgunits.find((x) => x._id == selectedOrgUnit._id))) {
					selectedOrgUnit = defaultOrgUnit ? defaultOrgUnit : cookies.orgunits[0]

					delete userSettings[cookies.user._id]

					StorageBase.updateByKey(USER_SETTINGS, userSettings)
				}
			} else {
				selectedOrgUnit = defaultOrgUnit ? defaultOrgUnit : cookies.orgunits[0]
			}

			this.orgunits = cookies.orgunits
			this.orgunitsId = selectedOrgUnit._id
			this.orgunitsResourceId = selectedOrgUnit.resource_id
			this.orgunitExpertiseCodeExt = defaultOrgUnit
				? defaultOrgUnit.expertises[0].expertise.code_ext
				: cookies.orgunits[0].expertises[0].expertise.code_ext

			this.setOpenningHour(selectedOrgUnit)

			this.currentOrgName = selectedOrgUnit.name
			this.userName = isSafe(cookies) && isSafe(cookies.person) ? cookies.person.full_name : ""
		}
	}

	//Zmena oddelenia cez hlavičku - xsHeader

	@action setOpenningHour(orgunit) {
		if (isSafe(orgunit.opening_hours)) {
			let tempOpenningHour = {}

			orgunit.opening_hours.forEach((day) => {
				if (isSafe(day.opened)) {
					if (day.opened.length === 1) {
						let amFrom = +day.opened[0].from.substring(0, 2)
						let pmTo = +day.opened[0].to.substring(0, 2)

						tempOpenningHour[day.day] = {from: amFrom, to: pmTo}
					}

					if (day.opened.length === 2) {
						let amFrom = +day.opened[0].from.substring(0, 2)
						let pmTo = +day.opened[1].to.substring(0, 2)

						tempOpenningHour[day.day] = {from: amFrom, to: pmTo}
					}
				}
			})

			this.openingHour = tempOpenningHour
		}
	}

	@action changeOrgUnitAndRouteToPatientDetail(orgunitId, personId) {
		if (getSelectedOrgUnitID() != orgunitId) {
			const selectedOrgunit = this.orgunits.find((x) => x._id == orgunitId)

			if (isSafe(selectedOrgunit)) {
				this.changeOrgUnit(selectedOrgunit, () => RouterStore.push(`/patient?id=${personId}`))
			} else {
				this.setNotificationMessage("Na toto oddelenie nemáte prístup!")
			}
		} else {
			RouterStore.push(`/patient?id=${personId}`)
		}
	}

	@action changeOrgUnit(selectedOrgUnit, fnRoute = undefined) {
		this.currentOrgName = selectedOrgUnit.name
		this.orgunitsId = selectedOrgUnit._id
		this.orgunitsResourceId = selectedOrgUnit.resource_id
		this.orgunitExpertiseCodeExt = selectedOrgUnit.expertises[0].expertise.code_ext

		this.setOpenningHour(selectedOrgUnit)

		if (getUser() && getUser().user._id) {
			let userSettings = {}
			userSettings[getUser().user._id] = {
				selectedOrgUnit: {
					_id: selectedOrgUnit._id,
					name: selectedOrgUnit.name,
					resource_id: selectedOrgUnit.resource_id,
					opening_hours: selectedOrgUnit.opening_hours
				}
			}

			StorageBase.updateByKey(USER_SETTINGS, userSettings)
		}
		DataStore.onSelectedOrgUnitChange()
		this.RegisterZpr(fnRoute)

		RegistersCacheStore.refreshCacheAfterOrgunitChange()
	}

	@action GWLogin() {
		const user = getUser()
		if (isSafe(user)) {
			// nastavenie timeoutu pre odpoved od EHGW
			this.updatingTimeout = setTimeout(() => {
				this.updatingTimeoutPassed = true
				//ak uplynul timeout, warning a zavriet loading a pokracovat na RegisterZpr
				GWUpdatingDialogStore.closeWaiting()
				this.RegisterZpr()
				WarningStore.open("Aktualizácia EHGW aplikácie sa nepodarila, kontaktujte zákaznícku podporu.", true)
			}, 60000)

			GWUpdatingDialogStore.openWaiting()

			const selectedOrgUnit = getSelectedOrgUnit()
			const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
			const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
			const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
			const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
			const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

			fetch(`${config.GW_BASE_URL}/login`, {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					AmbeeAuthorizationToken: `IXS ${user.ixstoken}`,
					Environment: getGWServerRole(),
					AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
					AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
					AmbeePersonnelId: getUserPersonnelID(),
					OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
					PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
					PersonnelExpertiseJRUZVersion: specVer,
					PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
				})
			})
				.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
				.then((data) => {
					GWUpdatingDialogStore.closeWaiting()

					// ak prisla odpoved pred timeout-om
					if (this.updatingTimeoutPassed === false) {
						// zrusim timeout
						clearTimeout(this.updatingTimeout)

						if (isNotEmpty(data.UpdatingToVersion)) {
							this.isUpdatingGW = true
							GWUpdatingDialogStore.open(data.UpdatingToVersion)
						} else {
							this.RegisterZpr()
						}

						if (data.UpdatingFailed) {
							WarningStore.open("Aktualizácia EHGW aplikácie sa nepodarila, kontaktujte zákaznícku podporu.", true)
						}
					}
					this.checkGWAppPost()
				})
				.catch(() => {
					// zrusim timeout
					clearTimeout(this.updatingTimeout)

					GWUpdatingDialogStore.closeWaiting()

					logger("Bud mas staru GW appku, alebo nie je zapnuta...")
					this.RegisterZpr()
					this.checkGWAppPost()
				})
		} else {
			WarningStore.open("Chyba pri kontrole aktualizácie EHGW aplikácie", true)
			this.RegisterZpr()
			this.checkGWAppPost()
		}
	}

	@action checkEHGWError(errorCode, errorMessage, isEhealthException) {
		if (isEmpty(errorMessage)) {
			return
		}

		if (this.gwApp.get("Zpr") == "green") {
			this.EHGWErrorStack = []
			WarningStore.generateErrorMessage(isEhealthException, errorMessage)
		} else {
			if (isNotSafe(errorCode)) {
				errorCode = -1
			}
			if (!this.EHGWErrorStack.some((x) => x == errorCode)) {
				this.EHGWErrorStack.push(errorCode)
				WarningStore.generateErrorMessage(isEhealthException, errorMessage)
			}
		}
	}

	@action RegisterZpr(fnRoute) {
		// nastavenie timeoutu pre RegisterZpr
		this.registerZprTimeout = setTimeout(() => {
			this.registerZprTimeoutPassed = true
			//ak uplynul timeout, warning a vstup do aplikacie
			RouterStore.push(settings.HOMEPAGE)
			GWUpdatingDialogStore.closeRegisterZprWaiting()
			WarningStore.open("Registrácia zdravotníckeho pracovníka sa nepodarila.", true)
		}, 30000)

		GWUpdatingDialogStore.openRegisterZprWaiting()

		this.ZprRegisteredInEhealthState = 3

		const user = getUser()
		const selectedOrgUnit = getSelectedOrgUnit()
		const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
		const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
		const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
		const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
		const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

		fetch(`${config.GW_BASE_URL}/RegisterZpr`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				Force: true,
				AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
				Environment: getGWServerRole(),
				AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
				AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
				AmbeePersonnelId: getUserPersonnelID(),
				OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
				PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
				PersonnelExpertiseJRUZVersion: specVer,
				PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
				// backup pre stare EHGW:
				OUPZS: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
				Specialization: isSafe(spec) ? spec : null,
				SpecializationVersion: specVer
			})
		})
			.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
			.then((data) => {
				GWUpdatingDialogStore.closeRegisterZprWaiting()

				// ak prisla odpoved pred timeout-om
				if (this.registerZprTimeoutPassed === false) {
					// zrusim timeout
					clearTimeout(this.registerZprTimeout)

					isSafe(fnRoute) ? fnRoute() : RouterStore.push(settings.HOMEPAGE)
				} else {
					// RouterStore.push(settings.HOMEPAGE)
				}

				this.ZprRegisteredInEhealthState = isEmpty(data.ErrorMessage) && isNotEmpty(data.Id) ? 1 : 2
				this.checkEHGWError(data.ErrorCode, data.ErrorMessage, data.IsEhealthException)
			})
			.catch(() => {
				// zrusim timeout
				clearTimeout(this.registerZprTimeout)
				GWUpdatingDialogStore.closeRegisterZprWaiting()

				this.ZprRegisteredInEhealthState = 2
				isSafe(fnRoute) ? fnRoute() : RouterStore.push(settings.HOMEPAGE)

				WarningStore.open("Registrácia zdravotníckeho pracovníka sa nepodarila.", true)
			})
	}

	@action checkVersion() {
		const minVersion = config.MIN_GW_VERSION

		fetch(`${config.GW_BASE_URL}/GetVersion`, {
			method: "GET"
		})
			.then((response) => response.text().then((text) => (text ? JSON.parse(text) : true)))
			.then((data) => {
				const version = data.version

				let isLowerThanMinimum = false
				if (version.major < minVersion.major) {
					isLowerThanMinimum = true
				} else if (version.minor < minVersion.minor) {
					isLowerThanMinimum = true
				} else if (version.build < minVersion.build) {
					isLowerThanMinimum = true
				} else if (version.revision < minVersion.revision) {
					isLowerThanMinimum = true
				}
				if (isLowerThanMinimum) {
					WarningStore.open("EHGW aplikácia je zastaralá", true)

					//zastavit fetching na GW
					this.isEHGWOutdated = true
				}
			})
			.catch(() => {
				// asi sa nic nerobi lebo appka je offline
			})
	}

	@action checkGWApp() {
		this.GWAppRunningState = 3

		const user = getUser()
		const selectedOrgUnit = getSelectedOrgUnit()
		const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null

		const id = isSafe(personnelJRUZId) ? personnelJRUZId : null
		const auth = isSafe(user) ? encodeURI(`IXS ${user.ixstoken}`) : null
		const pid = getUserPersonnelID()
		const ouid = isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null

		fetch(`${config.GW_BASE_URL}/GetStatus?id=${id}&auth=${auth}&pid=${pid}&ouid=${ouid}`, {
			method: "GET"
		})
			.then((response) => response.text().then((text) => (text ? JSON.parse(text) : true)))
			.then((data) => {
				if (this.isUpdatingGW) {
					this.isUpdatingGW = false
					GWUpdatingDialogStore.close()
					logout()
				}

				//Ked nepride v objekte Me tak potom je to stara verzia apky (optimalizacia pre 1.5.1 verziu)
				if (isNotSafe(data.Me)) {
					// NEW
					if (data.ZprRegistered) {
						this.EHGWErrorStack = []
						if (data.ErrorMessage) {
							WarningStore.generateErrorMessage(true, data.ErrorMessage)
						}
					} else {
						if (data.ErrorMessage) {
							const errorCode = -1

							if (!this.EHGWErrorStack.some((x) => x == errorCode)) {
								this.EHGWErrorStack.push(errorCode)
								WarningStore.generateErrorMessage(true, data.ErrorMessage)
							}
						}
					}

					let colors = {
						Me: "green",
						Zpr: data.ZprRegistered ? "green" : "red",
						PrZS: data.PrZSRegistered ? "green" : "red",
						Actions: []
					}
					this.gwApp.merge(colors)
				} else {
					// NEW
					if (data.Zpr == "green") {
						this.EHGWErrorStack = []
						if (data.ErrorMessage) {
							WarningStore.generateErrorMessage(data.IsEhealthException, data.ErrorMessage)
						}
					} else {
						if (data.ErrorMessage) {
							if (isNotSafe(data.ErrorCode)) {
								data.ErrorCode = -1
							}

							if (!this.EHGWErrorStack.some((x) => x == data.ErrorCode)) {
								this.EHGWErrorStack.push(data.ErrorCode)
								WarningStore.generateErrorMessage(data.IsEhealthException, data.ErrorMessage)
							}
						}
					}

					this.gwApp.merge(data)
				}

				// ak bezi GET, POST uz nepojde
				setTimeout(this.checkGWApp.bind(this), 3000)
			})
			.catch(() => {
				let notConnected = {
					Me: "red",
					Zpr: "grey",
					PrZS: "grey",
					Actions: []
				}
				this.gwApp.merge(notConnected)

				// ak bezi GET, POST uz nepojde
				setTimeout(this.checkGWApp.bind(this), 3000)
			})
	}

	@action checkGWAppPost() {
		this.GWAppRunningState = 3

		const user = getUser()
		const selectedOrgUnit = getSelectedOrgUnit()
		const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
		const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
		const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
		const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
		const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

		fetch(`${config.GW_BASE_URL}/GetStatusPost`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
				Environment: getGWServerRole(),
				AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
				AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
				AmbeePersonnelId: getUserPersonnelID(),
				OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
				PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
				PersonnelExpertiseJRUZVersion: specVer,
				PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
			})
		})
			.then((response) => response.text().then((text) => (text ? JSON.parse(text) : true)))
			.then((data) => {
				if (this.isUpdatingGW) {
					this.isUpdatingGW = false
					GWUpdatingDialogStore.close()
					logout()
				}

				// NEW
				if (data.Zpr == "green") {
					this.EHGWErrorStack = []
					if (data.ErrorMessage) {
						WarningStore.generateErrorMessage(data.IsEhealthException, data.ErrorMessage)
					}
				} else {
					if (data.ErrorMessage) {
						if (isNotSafe(data.ErrorCode)) {
							data.ErrorCode = -1
						}

						if (!this.EHGWErrorStack.some((x) => x == data.ErrorCode)) {
							this.EHGWErrorStack.push(data.ErrorCode)
							WarningStore.generateErrorMessage(data.IsEhealthException, data.ErrorMessage)
						}
					}
				}

				this.gwApp.merge(data)

				setTimeout(this.checkGWAppPost.bind(this), 3000)
			})
			.catch(() => {
				let notConnected = {
					Me: "red",
					Zpr: "grey",
					PrZS: "grey",
					Actions: []
				}
				this.gwApp.merge(notConnected)

				// ak nezbehne POST, fallback na GET
				setTimeout(this.checkGWApp.bind(this), 3000)
			})
	}

	async logoutGWApp() {
		const user = getUser()
		const selectedOrgUnit = getSelectedOrgUnit()
		const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
		const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
		const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
		const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
		const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

		try {
			await fetch(`${config.GW_BASE_URL}/Logout`, {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
					Environment: getGWServerRole(),
					AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
					AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
					AmbeePersonnelId: getUserPersonnelID(),
					OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
					PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
					PersonnelExpertiseJRUZVersion: specVer,
					PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
				})
			})
		} catch (err) {
			logger(err)
		}
	}

	@action openConfirmationDialog(dialogName, params) {
		this.confirmationDialogParams = params
		this.confirmationDialogOpened = dialogName
	}

	@action closeConfirmationDialog() {
		this.confirmationDialogParams = {}
		this.confirmationDialogOpened = undefined
	}

	@action refreshCodeLists(codeListNames, onSuccessLoad) {
		codeListNames.forEach((codeListName) => {
			const codeListParams = codeListsConfig.filter((x) => x.name === codeListName)[0]
			if (ixsIsUndefined(this.CL[codeListParams.name])) {
				api.loadCodeList(codeListParams.uri).then((val) => {
					if (
						codeListName == "allergens" ||
						codeListName == "symptoms" ||
						codeListName == "vaccinationSymptoms" ||
						codeListName == "vaccinationTypes" ||
						codeListName == "patientLocations"
					) {
						this.CL[codeListParams.name] = val.rows.sort((a, b) =>
							(isSafe(a.name_ext) ? a.name_ext : "").localeCompare(isSafe(b.name_ext) ? b.name_ext : "")
						)
					} else {
						this.CL[codeListParams.name] = val.rows
					}
					if (onSuccessLoad) {
						onSuccessLoad()
					}
				})
			} else {
				if (onSuccessLoad) {
					onSuccessLoad()
				}
			}
		})
	}

	@action async refreshCodeListsSync(codeListNames) {
		const mapCL = []
		const promises = codeListNames
			.map((codeListName) => {
				const codeListParams = codeListsConfig.find((x) => x.name === codeListName)
				if (isNotSafe(this.CL[codeListParams.name])) {
					mapCL.push({codeListName, codeListParams})
					return api.loadCodeList(codeListParams.uri)
				} else {
					return null
				}
			})
			.filter(isSafe)

		const results = await Promise.all(promises)

		results.forEach((val, idx) => {
			const {codeListName, codeListParams} = mapCL[idx]
			if (
				codeListName == "allergens" ||
				codeListName == "symptoms" ||
				codeListName == "vaccinationSymptoms" ||
				codeListName == "vaccinationTypes" ||
				codeListName == "patientLocations"
			) {
				this.CL[codeListParams.name] = val.rows.sort((a, b) =>
					(isSafe(a.name_ext) ? a.name_ext : "").localeCompare(isSafe(b.name_ext) ? b.name_ext : "")
				)
			} else {
				this.CL[codeListParams.name] = val.rows
			}
		})
	}

	@action loadCodeLists() {
		// codeListsConfig.map((cl) => {
		// 	api.loadCodeList(cl.uri).then((val) => {
		// 		if (cl.name == "allergens") {
		// 			this.CL[cl.name] = val.rows.sort((a, b) =>
		// 				(isSafe(a.name_ext) ? a.name_ext : "").localeCompare(isSafe(b.name_ext) ? b.name_ext : "")
		// 			)
		// 		} else {
		// 			this.CL[cl.name] = val.rows
		// 		}
		// 	})
		// })
	}

	setNotificationMessage(message, data = "", variant) {
		if (isNotEmpty(message)) {
			this.notification = {
				message: message,
				data: data,
				variant: variant
			}
		}
	}
}

var singleton = new GlobalStore()
export default singleton
