import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
// import {getSelectedOrgUnitID} from "../../../../../global/helpers/actions"

import fields from "./exportDeliveriesToXlsDialogFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
// import api from "../../../actions/api"

import UIStore from "../../../stores/UIStore"
import ExportToXlsDialogStore from "../../../stores/ExportToXlsDialogStore"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

@injectIntl
@observer
export default class ExportDeliveriesToXlsDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				if (isSafe(props.onSuccess) && typeof props.onSuccess == "function") {
					UIStore.isFormSaving = true

					props.onSuccess("all", form.$("batchType").value, form.$("disposition").value)
				}
			},
			onError() {}
		}
		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		ExportToXlsDialogStore.formRef = this.form

		// api
		// 	.getDefaultBatchType({org_unit: getSelectedOrgUnitID()})
		// 	.call()
		// 	.then((res) => {
		// 		this.form.$("batchType").set("value", res.code_ext)
		// 	})
	}

	handleRows = () => {
		this.form.update({rows: []})
		ExportToXlsDialogStore.insurers.forEach((ins) => {
			ExportToXlsDialogStore.disposition.forEach((disp) => {
				this.form.$("rows").add(
					{
						key: ins.id + disp.id,
						label: `${this.props.intl.formatMessage({id: "Invoice.form.number"})} ${ins.secondaryText} ${
							disp.primaryText
						}`,
						type: "text",
						rules: "required"
					},
					true
				)
			})
		})
	}

	render() {
		return (
			<div>
				{UIStore.isFormSaving && <XsLoading overlay={true} />}
				<Dialog
					id="xsExportDeliveriesToXlsDialog"
					open={ExportToXlsDialogStore.isOpen}
					onClose={() => (this.form.reset(), ExportToXlsDialogStore.close())}
					className="xs-base-dialog"
					classes={{
						paper: "xs-paper-dialog"
					}}
					disableBackdropClick={true}
					maxWidth="md"
				>
					<DialogTitle className="xs-info">
						<div className="xs-header">
							<div
								className="xs-absolute xs-close-btn"
								onClick={() => (this.form.reset(), ExportToXlsDialogStore.close())}
							>
								<i className="fal fa-times fa-lg" />
							</div>
							<div className="xs-header-icon">
								<i className="fal fa-file-excel fa-2x" />
							</div>
							<div className="xs-header-title">
								<FormattedMessage id="Common.label.export" />
							</div>
						</div>
					</DialogTitle>
					<DialogContent className="xs-content">
						<Grid container>
							<Grid item xs={12}>
								<SearchSelect
									field={this.form.$("batchType")}
									items={[{name_ext: "751a", _id: "751a"}, {name_ext: "753a", _id: "753a"}]}
								/>
							</Grid>
							<Grid item xs={12}>
								<XsSearchSelect field={this.form.$("disposition")} items={ExportToXlsDialogStore.dispositionData} />
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions className="xs-footer xs-space-between">
						<XsButton
							className="xs-success mr-3"
							type="submit"
							onClick={(e) => {
								this.form.onSubmit(e)
							}}
							text={<FormattedMessage id="Common.label.exportingToExcel" />}
							icon={<i className="fal fa-file-excel fa-lg" />}
						/>
						<XsButton
							className="xs-danger xs-outline"
							onClick={() => {
								this.form.reset()
								ExportToXlsDialogStore.close()
							}}
							text={<FormattedMessage id="Common.label.cancel" />}
							icon={<i className="fal fa-times fa-lg" />}
						/>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}
