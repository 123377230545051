import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import fields from "./agreementPartFormFields"

import {Grid} from "@material-ui/core"

import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"

import AgreementAttributeStore from "../../../stores/AgreementAttributeStore"
import AgreementPartDepartmentsStore from "../../../stores/AgreementPartDepartmentsStore"
import WarningStore from "../../../../../global/store/WarningStore"

@injectIntl
@observer
export default class AgreementPartForm extends React.Component {
	constructor(props) {
		super(props)

		if (AgreementAttributeStore.providers.length < 1) {
			AgreementAttributeStore.loadDepartments()
		}

		const hooks = {
			onSubmit(form) {
				if (isEmpty(form.$("agreement_part_number").value) || isEmpty(form.$("from").value)) {
					WarningStore.open(`${props.intl.formatMessage({id: "Patient.form.patientPrescription.invalidForm"})}`)
				}
			},
			onSuccess(form) {
				if (AgreementAttributeStore.isNewPart) {
					AgreementAttributeStore.savePart(form)
				} else {
					AgreementAttributeStore.updatePart(form)
				}
			},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		AgreementAttributeStore.agreementPartFormRef = this.form
	}

	render() {
		return (
			<div className="xs-agreement-form">
				<div className="xs-action">
					<Grid container direction="row" spacing={8} justify="flex-end">
						<Grid item>
							<XsButton
								className="xs-success"
								text={
									<FormattedMessage id={AgreementAttributeStore.isNewPart ? "Common.label.add" : "Common.label.save"} />
								}
								onClick={this.form.onSubmit}
							/>
						</Grid>
					</Grid>
				</div>
				<div className="xs-form">
					<Grid container direction="column">
						<Grid item xs={12}>
							<Grid container direction="row" spacing={8} alignItems="flex-end">
								<Grid item xs={3}>
									<XsInput field={this.form.$("agreement_part_number")} />
								</Grid>
								<Grid item xs={3}>
									<XsDateTimePicker field={this.form.$("from")} showTimeSelect={false} />
								</Grid>
								<Grid item xs={3}>
									<XsDateTimePicker field={this.form.$("to")} showTimeSelect={false} />
								</Grid>
								<Grid item xs={3}>
									<XsCheckbox field={this.form.$("sufficient")} />
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container direction="row" spacing={8} alignItems="center">
								<Grid item xs={9}>
									<XsInput field={this.form.$("subject_matter")} multiline rows={2} />
								</Grid>
								<Grid item xs={3}>
									<XsButton
										className="xs-default xs-outline"
										text={<FormattedMessage id="Agreement.Container.Detail.Departments" />}
										onClick={() => AgreementPartDepartmentsStore.open()}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</div>
		)
	}
}
