"use strict"

import React from "react"
import {observer} from "mobx-react"
// import {FormattedMessage} from "react-intl"
import classNames from "classnames"

import Joyride, {STATUS} from "react-joyride"
import {Grid} from "@material-ui/core"
import TourStore from "../../../../global/store/TourStore"

@observer
export default class AddNewEventTour extends React.Component {
	constructor(props) {
		super(props)
	}

	handleJoyrideCallback = (data) => {
		const {status} = data

		const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]

		if (finishedStatuses.includes(status)) {
			TourStore.runSaveNewEvent_tour = false
			TourStore.stepSaveNewEventIndex = 0
		}

		if (data.action == "close") {
			TourStore.runSaveNewEvent_tour = false
			TourStore.stepSaveNewEventIndex = 0
		}
	}

	getHelpers = (helpers) => {
		this.helpers = helpers
	}

	footerTour = () => (
		<Grid item container spacing={8} justify="center" alignItems="center" className="mt-3">
			<Grid
				item
				onClick={() =>
					TourStore.stepSaveNewEventIndex == 1 || TourStore.stepSaveNewEventIndex == 3
						? null
						: TourStore.handlePreviousSaveNewEventTour()
				}
				className={classNames("mr-1", TourStore.stepSaveNewEventIndex != 0 && "pointer")}
			>
				<i
					className={classNames(
						"far fa-chevron-left fa-lg greyDark mr-1",
						TourStore.stepSaveNewEventIndex == 1 && "greyLightImportant",
						TourStore.stepSaveNewEventIndex == 3 && "greyLightImportant"
					)}
				></i>
			</Grid>
			{[0, 1, 2, 3, 4, 5, 6, 7, 8].map((i, idx) => (
				<Grid item key={idx}>
					{TourStore.stepSaveNewEventIndex == i ? (
						<i
							className="fas fa-circle pointer greyDark fontSize12"
							onClick={() => TourStore.handleCircleSaveNewEventTour(i)}
						></i>
					) : (
						<i
							className={classNames(
								"far fa-circle pointer greyDark fontSize12",
								i == 0 && "greyLightImportant",
								TourStore.searchPatient && "greyLightImportant",
								TourStore.stepSaveNewEventIndex > 0 &&
									TourStore.stepSaveNewEventIndex < 3 &&
									i > 2 &&
									"greyLightImportant",
								TourStore.stepSaveNewEventIndex > 2 && i < 3 && "greyLightImportant"
							)}
							onClick={() =>
								i == 0 ||
								(TourStore.stepSaveNewEventIndex > 0 && TourStore.stepSaveNewEventIndex < 3 && i > 2) ||
								TourStore.searchPatient ||
								(TourStore.stepSaveNewEventIndex > 2 && i < 3)
									? null
									: TourStore.handleCircleSaveNewEventTour(i)
							}
						></i>
					)}
				</Grid>
			))}
			<Grid
				item
				onClick={() => (TourStore.searchPatient ? null : TourStore.handleNextSaveNewEventTour())}
				className={classNames("ml-1 pointer")}
			>
				<i
					className={classNames("far fa-chevron-right fa-lg greyDark", TourStore.searchPatient && "greyLightImportant")}
				></i>
			</Grid>
		</Grid>
	)

	render() {
		let steps = [
			{
				content: "Ak chcete pacientovi vytvoriť objednávku do kalendára, kliknite na toto tlačidlo.",
				locale: {skip: <strong aria-label="skip">S-K-I-P</strong>},
				disableBeacon: true,
				disableOverlayClose: true,
				hideCloseButton: true,
				hideFooter: true,
				placement: "left",
				spotlightClicks: true,
				spotlightPadding: 5,
				target: ".tourAddEvent"
			},
			{
				content: (
					<Grid container direction="column">
						<Grid item>
							Ak chcete do kalendára pridať objednávku pacientovi, ktorého ešte nemáte v evidencii, napíšte meno
							pacienta.
						</Grid>
						{this.footerTour()}
					</Grid>
				),
				spotlightPadding: 5,
				placement: "bottom",
				hideFooter: true,
				target: ".tourSearchPatient"
			},
			{
				content: <div>Kliknite na toto tlačidlo</div>,
				spotlightClicks: true,
				spotlightPadding: 5,
				placement: "bottom",
				hideFooter: true,
				target: ".tourSearchPlus"
			},
			{
				content: (
					<Grid container direction="column">
						<Grid item>Tu vyberte poskytovanú službu, na ktorú chcete pacienta objednať.</Grid>
						{this.footerTour()}
					</Grid>
				),
				spotlightClicks: true,
				spotlightPadding: 5,
				placement: "bottom",
				hideFooter: true,
				target: ".xs-searchSelect-input"
			},
			{
				content: (
					<Grid container direction="column">
						<Grid item>Ak chcete nastaviť čas objednania, kliknite do tohto poľa a nastavte dátum a čas.</Grid>
						{this.footerTour()}
					</Grid>
				),
				spotlightClicks: true,
				spotlightPadding: 5,
				placement: "bottom",
				hideFooter: true,
				target: ".dateTimeInputTour"
			},
			{
				content: (
					<Grid container direction="column">
						<Grid item>Dátum a čas objednania, viete nastaviť aj pomocou tohto kalendára.</Grid>
						{this.footerTour()}
					</Grid>
				),
				spotlightPadding: 0,
				placement: "bottom",
				hideFooter: true,
				target: ".xs-datetime-picker"
			},
			{
				content: (
					<Grid container direction="column">
						<Grid item>K udalosti v kalendári môžete zapísať ľubovoľnú poznámku. Urobíte tak tu.</Grid>
						{this.footerTour()}
					</Grid>
				),
				placement: "bottom",
				hideFooter: true,
				target: ".noteTour",
				spotlightPadding: 5
			},
			{
				content: (
					<Grid container direction="column">
						<Grid item>
							Pri objednávaní môžete využiť ponúkané voľné termíny v kalendári. Ak chcete zvoliť niektorý z ponúkaných
							termínov, kliknite priamo na ponúkaný dátum a čas.
						</Grid>
						{this.footerTour()}
					</Grid>
				),
				placement: "bottom",
				hideFooter: true,
				target: ".xs-slots-scrollbar",
				spotlightPadding: 0
			},
			{
				content: (
					<Grid container direction="column">
						<Grid item>Ak chcete udalosť uložiť do kalendára, kliknite na toto tlačidlo.</Grid>
						{this.footerTour()}
					</Grid>
				),
				placement: "bottom",
				hideFooter: true,
				target: ".saveTour",
				spotlightPadding: 0
			}
		]

		return (
			<Joyride
				callback={this.handleJoyrideCallback}
				continuous={true}
				getHelpers={this.getHelpers}
				run={TourStore.runSaveNewEvent_tour}
				stepIndex={TourStore.stepSaveNewEventIndex}
				hideBackButton={true}
				disableScrolling={true}
				disableOverlayClose={true}
				showProgress={true}
				steps={steps}
				styles={{
					options: {
						zIndex: 10000
					}
				}}
			/>
		)
	}
}
