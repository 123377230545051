/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				weight: {
					label: <FormattedMessage id="Patient.form.bmiform.weight" />,
					rules: "required|numeric|min:1"
				},
				height: {
					label: <FormattedMessage id="Patient.form.bmiform.height" />,
					rules: "required|numeric|min:1"
				}
			}
		}
	}
}
