"use strict"

import {action} from "mobx"
import moment from "moment"
import api from "../actions/api"
import UIStore from "./UIStore"
import DataStore from "./DataStore"
import RouterStore from "../../../global/store/RouterStore"
import WarningStore from "../../../global/store/WarningStore"
import config from "../../../global/config/settings"
import {GWApiCall} from "../../../global/helpers/api"
import {getUserPersonnelID, getSelectedOrgUnit, getUser} from "../../../global/helpers/actions"
import GlobalStore from "../../../global/store/GlobalStore"

class PatientInfoFormStore {
	warningText = ""

	formRef = null
	citizenshipId = null
	addressId = null

	@action loadDataFromEhealth(id) {
		UIStore.isFormSaving = true
		const selectedOrgUnit = getSelectedOrgUnit()
		const user = getUser()
		const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
		const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
		const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
		const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
		const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

		const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
			? DataStore.patientDTO.get("patient").validInfo.client
			: null
		const JRUZIDObj = isSafe(patientClientInfo.identifiers)
			? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
			: null

		GWApiCall({
			method: "POST",
			uri: `${config.GW_BASE_URL}/GetPatientSummaryContactInformation`,
			body: JSON.stringify({
				ClassName: "DajPacientskySumarKontaktneUdaje_v3",
				AmbeePatientId: DataStore.patientDTO.get("patientID"),
				PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
				PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
				AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
				AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
				AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
				AmbeePersonnelId: getUserPersonnelID(),
				OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
				PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
				PersonnelExpertiseJRUZVersion: specVer,
				PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
			})
		})
			.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res.ErrorMessage)) {
					// WarningStore.open(res.ErrorMessage, true)
					GlobalStore.checkEHGWError(res.ErrorCode, res.ErrorMessage, res.IsEhealthException)
				} else {
					if (res.Count1 == 0 && res.Count2 == 0) {
						WarningStore.open(this.warningText)
					}
				}
				UIStore.isFormSaving = false
				RouterStore.push(`/patient?id=${id}`)
				UIStore.patientDetailTab = "PersonalInfo"
			})
			.catch(() => {
				UIStore.isFormSaving = false
				RouterStore.push(`/patient?id=${id}`)
				setTimeout(() => {
					UIStore.patientDetailTab = "PersonalInfo"
				}, 0)
			})
	}

	@action updateDataInEhealth() {
		UIStore.isFormSaving = true
		const patientId = DataStore.patientDTO.get("patientID")
		const personalIdNumber = DataStore.patientDTO.get("patient").identifier
		if (isNotEmpty(patientId)) {
			api
				.loadEhealthPatientContacts(patientId)
				.call()
				.then((response) => {
					UIStore.isFormSaving = false
					if (isNotEmpty(response.xml_data_block) && isNotEmpty(response.xml_envelope)) {
						const selectedOrgUnit = getSelectedOrgUnit()
						const user = getUser()
						const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
						const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
						const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
						const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
						const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

						const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
							? DataStore.patientDTO.get("patient").validInfo.client
							: null
						const JRUZIDObj = isSafe(patientClientInfo.identifiers)
							? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
							: null

						GWApiCall({
							method: "POST",
							uri: `${config.GW_BASE_URL}/UpdatePatientSummaryContactInformation`,
							body: JSON.stringify({
								ClassName: "ZapisPacientskehoSumaruKontaktneUdaje_v3",
								XMLEnvelope: response.xml_envelope,
								XMLData: response.xml_data_block,
								AmbeePatientId: patientId,
								PatientIdentificationNumber: personalIdNumber,
								PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
								AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
								AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
								AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
								AmbeePersonnelId: getUserPersonnelID(),
								OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
								PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
								PersonnelExpertiseJRUZVersion: specVer,
								PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
								RecordSignerData: isSafe(response.record_signer_data)
									? response.record_signer_data.map((x) => {
											return {
												Ref: x._ref,
												Type: x._type,
												RecordClass: x.record_class,
												RecordExternalId: x.record_external_id,
												RecordId: x.record_id
											}
									  })
									: null
							})
						})
							.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
							.then((res) => {
								if (isSafe(res)) {
									if (isSafe(res.ErrorMessage)) {
										// WarningStore.generateErrorMessage(res.IsEhealthException, res.ErrorMessage)
										GlobalStore.checkEHGWError(res.ErrorCode, res.ErrorMessage, res.IsEhealthException)
									} else {
										GWApiCall({
											method: "POST",
											uri: `${config.GW_BASE_URL}/GetPatientSummaryContactInformation`,
											body: JSON.stringify({
												ClassName: "DajPacientskySumarKontaktneUdaje_v3",
												AmbeePatientId: patientId,
												PatientIdentificationNumber: personalIdNumber,
												PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
												AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
												AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
												AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
												AmbeePersonnelId: getUserPersonnelID(),
												OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
												PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
												PersonnelExpertiseJRUZVersion: specVer,
												PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
											})
										})
											.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
											.then((resGet) => {
												if (isSafe(resGet)) {
													if (isSafe(resGet.ErrorMessage)) {
														GlobalStore.checkEHGWError(resGet.ErrorCode, resGet.ErrorMessage, resGet.IsEhealthException)
														// WarningStore.generateErrorMessage(resGet.IsEhealthException, resGet.ErrorMessage)
													} else {
														if (resGet.Count1 == 0 && resGet.Count2 == 0) {
															WarningStore.open(this.warningText)
														}
													}
												}
											})
											.catch((err) => logger(err))
									}
								}
							})
							.catch((err) => logger(err))
					}
				})
				.catch(() => {
					UIStore.isFormSaving = false
				})
		}
	}

	@action initFormData() {
		// this.formRef.reset()
		this.addressId = null
		this.citizenshipId = null
		UIStore.patientInfoFormDisabled = true

		let patient = DataStore.patientDTO.get("patient")

		this.formRef.$("identifier").value = patient.identifier
		this.formRef.$("identifierOld").value = patient.identifier
		this.formRef.$("dateOfBirth").value = patient.birth_date
		this.formRef.$("gender").value = isSafe(patient.gender) ? patient.gender._id : null
		// this.formRef.$("insurer").value = patient.identifier
		this.formRef.$("firstname").value = patient.first_name
		this.formRef.$("title_before").value = isSafe(patient.title_before) ? patient.title_before._id : null
		this.formRef.$("firstname").value = patient.first_name
		this.formRef.$("lastname").value = patient.last_name
		this.formRef.$("birth_name").value = patient.birth_name
		this.formRef.$("title_after").value = isSafe(patient.title_after) ? patient.title_after._id : null
		this.formRef.$("deathDate").value = patient.death_date

		if (isSafe(patient.citizenships) && patient.citizenships.length > 0) {
			const citizenship = patient.citizenships.find(
				(x) => isSafe(x.type) && isSafe(x.validity) && x.validity.to == null
			)

			if (isSafe(citizenship) && isSafe(citizenship.country)) {
				this.formRef.$("country").value = citizenship.country._id
				this.citizenshipId = citizenship._id
			} else {
				this.formRef.$("country").value = null
				this.citizenshipId = null
			}
		}

		if (isSafe(patient.specific_rels) && patient.specific_rels.length > 0) {
			const specRels = patient.specific_rels[0]

			if (isSafe(specRels)) {
				if (isSafe(specRels.addresses) && specRels.addresses.length > 0) {
					const paAddress = specRels.addresses.find(
						(address) => address.active && isSafe(address.type) && address.type._id == "PA"
					)

					if (isSafe(paAddress)) {
						this.formRef.$("street").value = paAddress.street
						this.formRef.$("houseNumber").value = paAddress.house_number
						if (isNotEmpty(paAddress.obj_city) && isNotEmpty(paAddress.obj_city._id)) {
							this.formRef.$("city").value = isSafe(paAddress.obj_city) ? paAddress.obj_city._id : null
						} else {
							this.formRef.$("cityText").value = paAddress.city_plain
						}
						if (isNotEmpty(paAddress.obj_zip) && isNotEmpty(paAddress.obj_zip._id)) {
							this.formRef.$("zip").value = isSafe(paAddress.obj_zip) ? paAddress.obj_zip._id : null
						} else {
							this.formRef.$("zipText").value = paAddress.zip_plain
						}
						this.formRef.$("region").value = isSafe(paAddress.region) ? paAddress.region._id : null
						this.formRef.$("county").value = isSafe(paAddress.county) ? paAddress.county._id : null
						this.addressId = paAddress._id
					}
				}

				// if (isSafe(specRels.insurances) && specRels.insurances.length > 0) {
				// 	const insurance = specRels.insurances.find((ins) => ins.active && isSafe(ins.validity) && ins.validity.now)

				// 	if (isSafe(insurance)) {
				// 		this.formRef.$("insurer").value = isSafe(insurance.insurer) ? insurance.insurer._id : null
				// 		this.formRef.$("insurerCode").value = isSafe(insurance.insurer) ? insurance.insurer.code_ext : null
				// 	}
				// }
			}
		}

		if (
			isSafe(patient.validInfo) &&
			isSafe(patient.validInfo.insurance) &&
			isSafe(patient.validInfo.insurance.insurer)
		) {
			this.formRef.$("insurer").value = patient.validInfo.insurance.insurer._id
			this.formRef.$("insurerCode").value = patient.validInfo.insurance.insurer.code_ext
		} else {
			this.formRef.$("insurer").value = null
			this.formRef.$("insurerCode").value = null
		}
	}

	@action async updatePatientInfo(patientId, clientId) {
		try {
			let citizenshipReq = {
				_type: "Entity.Data.Citizenship",
				_ref: false,
				country: {
					_id: this.formRef.$("country").value
				},
				_id: this.citizenshipId
			}

			await api.saveCitizenship(citizenshipReq).call()

			let addressReq = {
				_type: "Entity.Data.Address",
				_ref: false,
				type: "PA",
				specific_rel: clientId,

				region: isNotEmpty(this.formRef.$("region").value)
					? {
							_id: this.formRef.$("region").value
					  }
					: null,
				county: isNotEmpty(this.formRef.$("county").value)
					? {
							_id: this.formRef.$("county").value
					  }
					: null,
				street_plain: this.formRef.$("street").value,
				house_number: this.formRef.$("houseNumber").value,
				_id: this.addressId
			}

			if (isNotEmpty(this.formRef.$("city").value)) {
				addressReq["obj_city"] = this.formRef.$("city").value
				addressReq["city_plain"] = null
			} else {
				addressReq["obj_city"] = null
				addressReq["city_plain"] = this.formRef.$("cityText").value
			}

			if (isNotEmpty(this.formRef.$("zip").value)) {
				addressReq["obj_zip"] = this.formRef.$("zip").value
				addressReq["zip_plain"] = null
			} else {
				addressReq["obj_zip"] = null
				addressReq["zip_plain"] = this.formRef.$("zipText").value
			}

			await api.saveAddress(addressReq).call()

			let entityReq = {
				_type: "Entity.Data.Person",
				identifier: this.formRef.$("identifier").value,
				title_before: isNotEmpty(this.formRef.$("title_before").value)
					? {
							_id: this.formRef.$("title_before").value
					  }
					: null,
				title_after: isNotEmpty(this.formRef.$("title_after").value)
					? {
							_id: this.formRef.$("title_after").value
					  }
					: null,
				birth_date: isNotEmpty(this.formRef.$("dateOfBirth").value)
					? moment(this.formRef.$("dateOfBirth").value).format("YYYY-MM-DD")
					: null,
				gender: isNotEmpty(this.formRef.$("gender").value)
					? {
							_id: this.formRef.$("gender").value
					  }
					: null,
				death_date: isNotEmpty(this.formRef.$("deathDate").value)
					? moment(this.formRef.$("deathDate").value).format("YYYY-MM-DD")
					: null,
				first_name: this.formRef.$("firstname").value,
				last_name: this.formRef.$("lastname").value,
				birth_name: this.formRef.$("birth_name").value,
				_id: patientId
			}

			await api.updateEntityInfo(entityReq).call()

			DataStore.loadEntity(patientId)
		} catch (err) {
			GlobalStore.setNotificationMessage("Api.unknown.error")
		}
	}
}

var singleton = new PatientInfoFormStore()
export default singleton
