import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import {TableHead, Table, TableBody, TableCell, TableRow} from "@material-ui/core"
import XsDropdownList from "../../../../../global/ui/xsDropdown/xsDropdownList"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"

import PriceLimitPerHCTypeForm from "./priceLimitPerHCTypeForm"

import AgreementAttributeStore from "../../../stores/AgreementAttributeStore"
import {TextField} from "@material-ui/core"

@injectIntl
@observer
export default class PriceLimitPerHCTypeGrid extends React.Component {
	constructor(props) {
		super(props)
	}

	tableBodyRows
	tableBody() {
		this.tableBodyRows = []
		if (
			isSafe(AgreementAttributeStore.attributeDataJSON) &&
			AgreementAttributeStore.attributeDataJSON.hasOwnProperty(AgreementAttributeStore.typeFilter) &&
			Object.keys(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]).length > 0
		) {
			// dataSource.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).forEach((currRow, idx) => {
			Object.keys(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]).forEach(
				(rowId, idx) => {
					if (AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].active) {
						this.tableBodyRows.push(
							<TableRow key={idx}>
								<TableCell>
									{AgreementAttributeStore.isEditAttributeGrid ? (
										<XsDropdownList
											className={
												isEmpty(
													AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].h_c_type
												)
													? "xs-input-error xs-dropdown"
													: "xs-dropdown"
											}
											items={AgreementAttributeStore.healthTypes}
											renderer={(item) => {
												return <span>{item.code_ext + " " + item.name_ext}</span>
											}}
											column="_id"
											value={
												AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].h_c_type
											}
											chooseItem={(item) => {
												{
													AgreementAttributeStore.changedAttribute(
														AgreementAttributeStore.typeFilter,
														rowId,
														"h_c_type",
														item._id
													)
													AgreementAttributeStore.updatedHealthTypesPriceLimitPerHC[rowId] = item._id
												}
											}}
										/>
									) : (
										AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].h_c_type +
										" " +
										AgreementAttributeStore.healthTypesJSON[
											AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].h_c_type
										]
									)}
								</TableCell>
								<TableCell className="xs-text-right xs-width-150">
									{AgreementAttributeStore.isEditAttributeGrid ? (
										<TextField
											value={
												AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
													.price_in_currency
											}
											onChange={(e) =>
												AgreementAttributeStore.changedAttribute(
													AgreementAttributeStore.typeFilter,
													rowId,
													"price_in_currency",
													e.target.value
												)
											}
											InputProps={{
												className: "xs-input",
												classes: {
													root: !isPrice(
														AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
															.price_in_currency
													)
														? "xs-input-error"
														: ""
												}
											}}
										/>
									) : (
										priceFormat(
											AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
												.price_in_currency
										)
									)}
								</TableCell>
								{AgreementAttributeStore.isEditAttributeGrid && (
									<TableCell className="xs-text-center xs-delete-action">
										<XsIconButton
											icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
											onClick={
												() =>
													AgreementAttributeStore.changedAttribute(
														AgreementAttributeStore.typeFilter,
														rowId,
														"active",
														false
													) /*GlobalStore.openConfirmationDialog("xsDeleteAgreementAttribute", { attributeId: AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].id })*/
											}
										/>
									</TableCell>
								)}
							</TableRow>
						)
					}
				}
			)
		} else {
			this.tableBodyRows.push(
				<TableRow key={1}>
					<TableCell className="xs-table-no-data" colSpan={2}>
						<FormattedMessage id="Table.NoData" />
					</TableCell>
				</TableRow>
			)
		}
	}

	render() {
		this.tableBody()

		return (
			<div className="xs-table-ex">
				<div className="xs-table">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<FormattedMessage id="Agreement.Container.Detail.Attribute.TypeZS" />
								</TableCell>
								<TableCell className="xs-text-right">
									<FormattedMessage id="Agreement.Container.Detail.Attribute.Limit" />
								</TableCell>
								{AgreementAttributeStore.isEditAttributeGrid && <TableCell />}
							</TableRow>
						</TableHead>
						<TableBody>{this.tableBodyRows}</TableBody>
					</Table>
				</div>
				<div className="xs-attribute-form">
					<PriceLimitPerHCTypeForm />
				</div>
			</div>
		)
	}
}
