export const defaultSaveBindings = {
	load: {
		storeKey: "OrgunitFormBidings",
		// params: ["orgunit"],
		type: "Entity.Data.OrgUnit"
	},
	descriptor: {
		_ref: false,
		active: true
	},
	scheme: {
		_id: {
			relation: "#orgunitId"
		},
		code: {
			field: "code"
		},
		code_ext: {
			field: "code"
		},
		name_ext: {
			field: "name"
		},
		name: {
			field: "name"
		},
		parent: {
			field: "superiorOJ"
		},
		identifiers: {
			relation: "@idsObj"
		},
		expertises: {
			array: {
				load: (item) => item._type === "EHR.Data.Entity.ProviderExpertise",
				descriptor: {
					_type: "EHR.Data.Entity.ProviderExpertise",
					_ref: false
				},
				scheme: {
					expertise: {
						object: {
							scheme: {
								_id: {
									field: "expertise"
								}
							}
						}
					}
				}
			}
		},
		entity: {
			object: {
				descriptor: {
					_type: "Entity.Data.Company",
					_ref: false
				},
				scheme: {
					_id: {
						relation: "#companyId"
					}
				}
			}
		}
	}
}

export const companySaveBindings = {
	load: {
		storeKey: "OrgunitFormBidings",
		// params: ["orgunit"],
		type: "Entity.Data.OrgUnit"
	},
	descriptor: {
		_ref: false,
		active: true
	},
	scheme: {
		_id: {
			relation: "#orgunitId"
		},
		code: {
			field: "code"
		},
		code_ext: {
			field: "code"
		},
		name_ext: {
			field: "name"
		},
		name: {
			field: "name"
		},
		parent: {
			field: "superiorOJ"
		},
		identifiers: {
			relation: "@idsObj"
		},
		object_owner: {
			relation: "@owner"
		},
		expertises: {
			array: {
				load: (item) => item._type === "EHR.Data.Entity.ProviderExpertise",
				descriptor: {
					_type: "EHR.Data.Entity.ProviderExpertise",
					_ref: false
				},
				scheme: {
					expertise: {
						object: {
							scheme: {
								_id: {
									field: "expertise"
								}
							}
						}
					}
				}
			}
		},
		entity: {
			object: {
				descriptor: {
					_type: "Entity.Data.Company",
					_ref: false
				},
				scheme: {
					_id: {
						relation: "#companyId"
					}
				}
			}
		}
	}
}
