//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import CancelIcon from "@material-ui/icons/Clear"
import Grid from "@material-ui/core/Grid"

import PrescriptionTemplateDialogStore from "../../../stores/PrescriptionTemplateDialogStore"
import RegistersCacheStore from "../../../../../global/store/RegistersCacheStore"

import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"

import api from "../../../actions/api"

@observer
export default class PrescriptionTemplateDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				PrescriptionTemplateDialogStore.saveCallback(form.$("private").value)
				PrescriptionTemplateDialogStore.close()
			},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					private: {
						label: <FormattedMessage id="Common.label.private" />,
						type: "checkbox",
						value: true
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks}
		)
	}

	onHandleRowClick = (dataRow) => {
		this.props.load(dataRow, PrescriptionTemplateDialogStore.formKey)
		PrescriptionTemplateDialogStore.close()
	}

	handleDelete = (data) => {
		const templateId = data.value
		if (isNotEmpty(templateId)) {
			api
				.deletePrescriptionTemplate(templateId)
				.call()
				.then(() => {
					const deleteIndex = PrescriptionTemplateDialogStore.templates.findIndex((row) => row.templateId == templateId)
					PrescriptionTemplateDialogStore.templates.splice(deleteIndex, 1)
					this.forceUpdate()
				})
		}
	}

	render() {
		return (
			<Dialog
				id="xsPrescriptionTemplateDialog"
				open={PrescriptionTemplateDialogStore.isOpen}
				onClose={() => PrescriptionTemplateDialogStore.onClose()}
				fullWidth={true}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-450"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle>
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								PrescriptionTemplateDialogStore.close()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-file-alt fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.saveAsTemplate" />
						</div>
						{/* <div className="xs-header-subtitle">
							<FormattedMessage id="Patient.sidebar.bloodType.subtitle" />
						</div> */}
					</div>
				</DialogTitle>
				<DialogContent className="xs-overflow-visible">
					<Grid container className="pa-8">
						{PrescriptionTemplateDialogStore.showSaveForm ? (
							<Grid item xs={12}>
								<XsCheckbox field={this.form.$("private")} />
							</Grid>
						) : (
							<Grid item xs={12}>
								<XsTable
									config={{
										columnDefs: {
											diagnosisCodeExt: {
												title: <FormattedMessage id="Patient.labels.Diagnosis" />,
												type: "string",
												design: {
													width: "15%"
												}
											},
											templateDesc: {
												title: <FormattedMessage id="Common.label.description" />,
												type: "string",
												design: {
													width: "80%"
												}
											},
											delete: {
												title: "",
												type: "action",
												design: {
													width: "5%",
													body: {
														renderer: (props) => {
															return (
																<XsIconButton
																	className="action-delete"
																	icon={
																		<span>
																			<i
																				className="fal fa-trash-alt fa-lg xs-greyDefault" /*data-tip data-for="removePrice"*/
																			/>
																			{/* <ReactTooltip id="removePrice" effect="solid">
																				<FormattedMessage id="Common.label.removePrice" />
																			</ReactTooltip> */}
																		</span>
																	}
																	onClick={() => this.handleDelete(props)}
																/>
															)
														}
													}
												}
											}
										},
										options: {
											reverseColor: true,
											hidePager: true,
											showCursor: true,
											onRowClick: (dataRow) => {
												this.onHandleRowClick(dataRow)
											},
											mapper: (dataRow) => {
												return Object.assign(dataRow, {
													diagnosisCodeExt:
														isSafe(dataRow.record_diagnoses) &&
														isSafe(dataRow.record_diagnoses[0]) &&
														isSafe(dataRow.record_diagnoses[0].item) &&
														isSafe(dataRow.record_diagnoses[0].item._id) &&
														isSafe(
															RegistersCacheStore.diagnosisRegister.find(
																(row) => row._id == dataRow.record_diagnoses[0].item._id
															)
														)
															? RegistersCacheStore.diagnosisRegister.find(
																	(row) => row._id == dataRow.record_diagnoses[0].item._id
															  ).code_ext
															: "",
													templateDesc: htmlToPlain(dataRow.transcription),
													delete: dataRow.template_id
												})
											}
										},
										dataSource: PrescriptionTemplateDialogStore.templates
									}}
								/>
							</Grid>
						)}
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-danger xs-outline"
						icon={<CancelIcon />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => PrescriptionTemplateDialogStore.close()}
					/>
					{PrescriptionTemplateDialogStore.showSaveForm && (
						<XsButton
							className="xs-success mr-3"
							type="submit"
							onClick={this.form.onSubmit}
							text={<FormattedMessage id="Common.label.save" />}
							icon={<i className="fal fa-plus fa-lg" />}
						/>
					)}
				</DialogActions>
			</Dialog>
		)
	}
}
