import React, {Component} from "react"
import {Switch, Route, withRouter} from "react-router-dom"
import LoginForm from "./authentification/LoginForm"

import {inject, observer} from "mobx-react"
import {getUserAccessRights, getSelectedOrgUnitID} from "../global/helpers/actions"

import PatientListContainer from "../modules/ambulance/components/patientList/patientListContainer"
import PatientDetailContainer from "../modules/ambulance/components/patientDetail/patientDetailContainer"
import DeliveriesContainer from "../modules/ambulance/components/deliveries/deliveriesContainer"
import ReportContainer from "../modules/ambulance/components/report/reportContainer"
import SettingContainer from "../modules/ambulance/components/settings/settingContainer"
import AdminContainer from "../modules/ambulance/components/admin/adminContainer"
import CalendarContainer from "../modules/ambulance/components/calendar/calendarContainer"
import EmployeesListContainer from "../modules/attendance/components/employeesList/employeesListContainer"
import EmployeesDetail from "../modules/attendance/components/employeesDetail/employeesDetail"
import OrgunitsDetailContainer from "../modules/attendance/components/orgunitsDetail/orgunitsDetailContainer"
import UserContainer from "../modules/ambulance/components/admin/users/userContainer"
import AgreementsContainer from "../modules/ambulance/components/agreements/agreementsContainer"
import AgreementDetail from "../modules/ambulance/components/agreements/agreementDetail/agreementDetail"
import AgreementAttributeList from "../modules/ambulance/components/agreements/attributeList/agreementAttributeList"

import "../global/styles/global.less"
import "../modules/attendance/styles/global.less"
import XsHeader from "../global/ui/xsHeader/xsHeader"
import XsFooter from "../global/ui/xsFooter/xsFooter"

import XsSnackbar from "../global/ui/xsSnackbar/xsSnackbar"
import XsErrorDialog from "../global/ui/xsDialog/xsErrorDialog"
import XsWarningDialog from "../global/ui/xsDialog/xsWarningDialog"
import XsInfoDialog from "../global/ui/xsDialog/xsInfoDialog"
import XsInformationDialog from "../global/ui/xsDialog/xsInformationDialog"
import EHealthProgressDialog from "../modules/ambulance/eHealthProgressDialog"
import GWUpdatingDialog from "../modules/ambulance/gwUpdatingDialog"
import GWWaitingDialog from "../modules/ambulance/gwWaitingDialog"
import CommMsgQueueDialog from "../modules/ambulance/CommMsgQueueDialog"
import NotificationCenter from "../modules/ambulance/components/notificationCenter/notificationCenter"
import QueueContainer from "../modules/ambulance/components/admin/queue/queueContainer"

import GlobalStore from "../global/store/GlobalStore"
import StorageManagementDialog from "../modules/ambulance/StorageManagementDialog"
import GWRegisterZprWaitingDialog from "../modules/ambulance/gwRegisterZprWaitingDialog"
import NotificationCenterStore from "../modules/ambulance/components/notificationCenter/NotificationCenterStore"

import RegistersCacheStore from "../global/store/RegistersCacheStore"
import NotificationDialog from "../modules/ambulance/components/notificationCenter/notificationDialog"
import XsLoading from "../global/ui/xsLoading/xsLoading"

import {getUser} from "../global/helpers/actions"

import {StorageBase} from "../global/storage/storageEx"
import {LANGUAGE} from "../global/config/constants"
import {FormattedMessage} from "react-intl"

@inject("routing")
@withRouter
@observer
export default class App extends Component {
	componentDidMount() {
		GlobalStore.checkVersion()
		const user = getUser()
		if (
			isSafe(user) &&
			this.props.routing.location.pathname != "/" &&
			this.props.routing.location.pathname != "/login"
		) {
			GlobalStore.GetBLConfiguration()
			if (user.chat_enabled == "1" && NotificationCenterStore.connection.state == "Disconnected") {
				NotificationCenterStore.startConnection()
			}
		}
	}

	render() {
		document.documentElement.lang = isNotEmpty(StorageBase.getByKey(LANGUAGE)) ? StorageBase.getByKey(LANGUAGE) : "sk"
		const id = new URLSearchParams(this.props.routing.location.search).get("id")
		const personId = new URLSearchParams(this.props.routing.location.search).get("personId")

		const user = getUser()

		if (this.props.routing.location.pathname != "/" && this.props.routing.location.pathname != "/login") {
			GlobalStore.setUserData()

			if (isNotEmpty(getSelectedOrgUnitID())) {
				RegistersCacheStore.checkRegisters(this.props.routing.location.pathname)
			}
		}

		return (
			<React.Fragment>
				{isSafe(user) ? (
					<React.Fragment>
						{RegistersCacheStore.registersLoaded ? (
							<Switch>
								<Route exact path="/" render={(props) => <LoginForm {...props} />} />
								<Route exact path="/login" render={(props) => <LoginForm {...props} />} />
								<Route
									exact
									path="/patients"
									render={(props) => (
										<div id="xsContainer" className="pb-4">
											<XsHeader {...props} />
											<CalendarContainer {...props} />
											<PatientListContainer id={id} {...props} />
											<XsFooter {...props} />
											{user.chat_enabled == "1" && <NotificationCenter {...props} />}
										</div>
									)}
								/>
								<Route
									exact
									path="/patient"
									render={(props) => (
										<React.Fragment>
											<XsHeader isPatientDetail={true} {...props} />
											<PatientDetailContainer id={id} {...props} />
											<XsFooter {...props} />
											{user.chat_enabled == "1" && <NotificationCenter {...props} />}
										</React.Fragment>
									)}
								/>
								{isSafe(getUserAccessRights("menu_insurance_billing")) && (
									<Route
										exact
										path="/deliveries"
										render={(props) => (
											<div className="report-cover">
												<XsHeader {...props} />
												<DeliveriesContainer {...props} />
												{user.chat_enabled == "1" && <NotificationCenter {...props} />}
												{/* <XsFooter /> */}
											</div>
										)}
									/>
								)}
								<Route
									exact
									path="/agreements"
									render={(props) => (
										<div id="xsContainer">
											<XsHeader {...props} />
											<AgreementsContainer {...props} />
											<XsFooter {...props} />
											{user.chat_enabled == "1" && <NotificationCenter {...props} />}
										</div>
									)}
								/>
								<Route
									exact
									path="/agreement"
									render={(props) => (
										<div id="xsContainer">
											<XsHeader {...props} />
											<AgreementDetail id={id} {...props} />
											<XsFooter {...props} />
											{user.chat_enabled == "1" && <NotificationCenter {...props} />}
										</div>
									)}
								/>
								<Route
									exact
									path="/part"
									render={(props) => (
										<div id="xsContainer">
											<XsHeader {...props} />
											<AgreementAttributeList id={id} {...props} />
											<XsFooter {...props} />
											{user.chat_enabled == "1" && <NotificationCenter {...props} />}
										</div>
									)}
								/>
								<Route
									exact
									path="/reports"
									render={(props) => (
										<div className="report-cover">
											<XsHeader {...props} />
											<ReportContainer {...props} />
											{user.chat_enabled == "1" && <NotificationCenter {...props} />}
										</div>
									)}
								/>
								<Route
									exact
									path="/settings"
									render={(props) => (
										<div className="report-cover">
											<XsHeader {...props} />
											<SettingContainer {...props} />
											{user.chat_enabled == "1" && <NotificationCenter {...props} />}
										</div>
									)}
								/>
								<Route
									exact
									path="/admin-cl"
									render={(props) => (
										<div id="xsContainer">
											<XsHeader {...props} />
											<AdminContainer view={"codelist"} {...props} />
											<XsFooter />
											{user.chat_enabled == "1" && <NotificationCenter {...props} />}
										</div>
									)}
								/>
								<Route
									exact
									path="/routes"
									render={(props) => (
										<div id="xsContainer">
											<XsHeader {...props} />
											<AdminContainer view={"routes"} {...props} />
											<XsFooter {...props} />
											{user.chat_enabled == "1" && <NotificationCenter {...props} />}
										</div>
									)}
								/>
								<Route
									exact
									path="/templates"
									render={(props) => (
										<div id="xsContainer">
											<XsHeader {...props} />
											<AdminContainer view={"universalTemplate"} {...props} />
											<XsFooter {...props} />
											{user.chat_enabled == "1" && <NotificationCenter {...props} />}
										</div>
									)}
								/>
									<Route
									exact
									path="/queue"
									render={(props) => (
										<div id="xsContainer">
											<XsHeader {...props} />
											<QueueContainer {...props} />
											<XsFooter {...props} />
											{user.chat_enabled == "1" && <NotificationCenter {...props} />}
										</div>
									)}
								/>
								{isSafe(getUserAccessRights("SRVZO.API.REST.MAIN./session/sec-resource")) && (
									<Route
										exact
										path="/users"
										render={(props) => (
											<div className="report-cover">
												<XsHeader {...props} />
												<AdminContainer view={"users"} {...props} />
												<XsFooter {...props} />
												{user.chat_enabled == "1" && <NotificationCenter {...props} />}
											</div>
										)}
									/>
								)}
								<Route
									exact
									path="/user"
									render={(props) => (
										<div id="xsContainer">
											<XsHeader {...props} />
											<UserContainer id={id} {...props} />
											<XsFooter {...props} />
											{user.chat_enabled == "1" && <NotificationCenter {...props} />}
										</div>
									)}
								/>
								{isSafe(getUserAccessRights("/entity/entities/:1/employees")) && (
									<Route
										exact
										path="/employees"
										render={(props) => (
											<div id="xsContainer">
												<XsHeader {...props} />
												<EmployeesListContainer {...props} />
												<XsFooter {...props} />
												{user.chat_enabled == "1" && <NotificationCenter {...props} />}
											</div>
										)}
									/>
								)}
								<Route
									exact
									path="/employee"
									render={(props) => (
										<div id="xsContainer">
											<XsHeader {...props} />
											<EmployeesDetail personId={personId} id={id} {...props} />
											<XsFooter {...props} />
											{user.chat_enabled == "1" && <NotificationCenter {...props} />}
										</div>
									)}
								/>
								<Route
									exact
									path="/orgunit"
									render={(props) => (
										<div id="xsContainer">
											<XsHeader {...props} />
											<OrgunitsDetailContainer
												id={id}
												companyId={new URLSearchParams(this.props.routing.location.search).get("companyId")}
												{...props}
											/>
											<XsFooter {...props} />
											{user.chat_enabled == "1" && <NotificationCenter {...props} />}
										</div>
									)}
								/>
								<Route
									exact
									path="/translation"
									render={(props) => (
										<div id="xsContainer">
											<XsHeader {...props} />
											<AdminContainer view={"translation"} {...props} />
											<XsFooter {...props} />
											{user.chat_enabled == "1" && <NotificationCenter {...props} />}
										</div>
									)}
								/>
								<Route
									exact
									path="/ehgw"
									render={(props) => (
										<div className="report-cover">
											<XsHeader {...props} />
											<AdminContainer view={"ehgw"} {...props} />
											<XsFooter {...props} />
											{user.chat_enabled == "1" && <NotificationCenter {...props} />}
										</div>
									)}
								/>
								<Route
									exact
									path="/notify"
									render={(props) => (
										<div className="report-cover">
											<XsHeader {...props} />
											<AdminContainer view={"notify"} {...props} />
											<XsFooter {...props} />
											{user.chat_enabled == "1" && <NotificationCenter {...props} />}
										</div>
									)}
								/>
							</Switch>
						) : (
							<React.Fragment>
								<XsLoading message={<FormattedMessage id="Common.label.loadingCodeLists" />} darkText />
							</React.Fragment>
						)}
					</React.Fragment>
				) : (
					<Switch>
						<Route exact path="/" render={(props) => <LoginForm {...props} />} />
						<Route exact path="/login" render={(props) => <LoginForm {...props} />} />
					</Switch>
				)}

				<XsSnackbar />
				<XsErrorDialog />
				<XsWarningDialog />
				<XsInfoDialog />
				<XsInformationDialog />
				<EHealthProgressDialog />
				<GWUpdatingDialog />
				<GWWaitingDialog />
				<GWRegisterZprWaitingDialog />
				<CommMsgQueueDialog />
				<StorageManagementDialog />
				<NotificationDialog />
			</React.Fragment>
		)
	}
}
