"use strict"

import React from "react"
import moment from "moment"
import {withStyles} from "@material-ui/core/styles"

const styles = () => ({
	chartTooltip: {
		backgroundColor: "#E5E7EA",
		padding: "10px",
		opacity: 0.6
	}
})

export class PatientClinicalValuesTooltip extends React.Component {
	render() {
		const {active, payload, label, classes, dateFormat} = this.props

		if (active && payload) {
			return (
				<div className={classes.chartTooltip}>
					<p className="label">
						{`${moment(label).format(dateFormat)}:`} <b>{`${payload[0].value} ${payload[0].payload.unit_code_ext}`}</b>
					</p>
				</div>
			)
		}

		return null
	}
}

export default withStyles(styles)(PatientClinicalValuesTooltip)
