"use strict"

import {observable, action} from "mobx"

import {getUserCompanyInfo, getSelectedOrgUnit} from "../../global/helpers/actions"

import api from "../../modules/ambulance/actions/api"
import RouterStore from "./RouterStore"

import AddEventDialogStore from "../../modules/ambulance/stores/AddEventDialogStore"

class TourStore {
	@observable isOpen = false

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@observable runSaveEvent_tour = false
	@observable searchPatient = false
	@observable stepSaveEventIndex = 0

	@observable runSaveNewEvent_tour = false
	@observable stepSaveNewEventIndex = 0

	//Kalendar tour
	@observable runCalendar_tour = false
	@observable stepCalendarIndex = 0

	tourList = [
		{
			label: "Uloženie udalosti do kalendára",
			openTour: () => {
				this.close()
				if (RouterStore.location.pathname !== "/patients") {
					RouterStore.push("/patients")
					setTimeout(() => {
						this.runSaveEvent_tour = true
					}, 1000)
				} else {
					this.runSaveEvent_tour = true
				}
			}
		},
		{
			label: "Vytvorenie objednávky pacienta, ktorý nie je v evidencii",
			openTour: () => {
				this.close()
				if (RouterStore.location.pathname !== "/patients") {
					RouterStore.push("/patients")
					setTimeout(() => {
						this.runSaveNewEvent_tour = true
					}, 1000)
				} else {
					this.runSaveNewEvent_tour = true
				}
			}
		},
		{
			label: "Kalendár a jeho funkcie",
			openTour: () => {
				this.close()
				if (RouterStore.location.pathname !== "/patients") {
					RouterStore.push("/patients")
					setTimeout(() => {
						this.runCalendar_tour = true
					}, 1000)
				} else {
					this.runCalendar_tour = true
				}
			}
		}
	]

	i = 0

	@action simulateType(val) {
		if (this.i < val.length) {
			this.i++
			AddEventDialogStore.searchFormRef.$("search").value = val.slice(0, this.i)
			// AddEventDialogStore.searchValue = val.slice(0, this.i)
			setTimeout(() => this.simulateType(val), 250)
		}
	}

	//Vytvorenie objednavky pacienta
	@action handleNextSaveEventTour() {
		if (this.stepSaveEventIndex == 0) {
			setTimeout(() => {
				this.stepSaveEventIndex = 1
				api
					.loadCompanyPatients(getUserCompanyInfo().id, {
						row_count_show: 10,
						row_offset: 1,
						filters: [
							{
								associated_column: "provider_id",
								values: [{id_value: getSelectedOrgUnit()._id}]
							}
						]
					})
					.call()
					.then((res) => {
						if (isSafe(res) && isSafe(res.rows) && res.rows.length > 0) {
							const lastName = res.rows[0].last_name

							this.searchPatient = true

							this.simulateType(lastName)

							setTimeout(() => {
								AddEventDialogStore.searchPatient()
								this.searchPatient = false
								this.i = 0
							}, lastName.length * 275)
						}
					})
			}, 400)
		} else if (this.stepSaveEventIndex == 1) {
			setTimeout(() => {
				this.stepSaveEventIndex = 2
			}, 400)
		} else if (this.stepSaveEventIndex == 2) {
			setTimeout(() => {
				this.runSaveEvent_tour = true
				this.stepSaveEventIndex = 3
			}, 400)
		} else {
			this.stepSaveEventIndex = this.stepSaveEventIndex + 1
		}
	}

	@action handleCircleSaveEventTour(index) {
		this.stepSaveEventIndex = index
	}

	@action handlePreviousSaveEventTour() {
		if (this.stepSaveEventIndex > 0) {
			this.stepSaveEventIndex = this.stepSaveEventIndex - 1
		}
	}

	//Vytvorenie objednavky pacienta, ktory nie je v evidencii
	@action handleNextSaveNewEventTour() {
		if (this.stepSaveNewEventIndex == 0) {
			setTimeout(() => {
				this.stepSaveNewEventIndex = 1

				const name = "Nový pacient"

				this.searchPatient = true

				this.simulateType(name)

				setTimeout(() => {
					AddEventDialogStore.searchPatient()
					this.searchPatient = false
					this.i = 0
				}, name.length * 275)
			}, 400)
		} else if (this.stepSaveNewEventIndex == 1) {
			setTimeout(() => {
				this.stepSaveNewEventIndex = 2
			}, 400)
		} else if (this.stepSaveNewEventIndex == 2) {
			setTimeout(() => {
				this.stepSaveNewEventIndex = 3
			}, 400)
		} else {
			this.stepSaveNewEventIndex = this.stepSaveNewEventIndex + 1
		}
	}

	@action handleCircleSaveNewEventTour(index) {
		this.stepSaveNewEventIndex = index
	}

	@action handlePreviousSaveNewEventTour() {
		if (this.stepSaveNewEventIndex > 0) {
			this.stepSaveNewEventIndex = this.stepSaveNewEventIndex - 1
		}
	}

	//Kalendar a jeho funkcie
	@action handleNextCalendarTour() {
		this.stepCalendarIndex = this.stepCalendarIndex + 1
	}

	@action handleCircleCalendarTour(index) {
		this.stepCalendarIndex = index
	}

	@action handlePreviousCalendarTour() {
		if (this.stepCalendarIndex > 0) {
			this.stepCalendarIndex = this.stepCalendarIndex - 1
		}
	}
}

var singleton = new TourStore()
export default singleton
