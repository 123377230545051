import React, {Component} from "react"
import {observer} from "mobx-react"

import UIStore from "../../../modules/ambulance/stores/UIStore"

import "./xsTableServerWithFilters.less"

@observer
export default class XsFooterJSX extends Component {
	constructor(props) {
		super(props)
	}

	render() {
		if (this.props.display == true && isSafe(UIStore.JSXTableFooter)) {
			return <div className="xs-footer-at-custom-place">{UIStore.JSXTableFooter}</div>
		} else return null
	}
}
