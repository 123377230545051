import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "request_CT"
				},

				isCT: {
					label: "CT",
					type: "checkbox",
					value: false
				},
				idCTKardio: {
					label: "CT KARDIO",
					type: "checkbox",
					value: false
				},

				idHospPrip: {
					label: "Číslo hospitalizačného prípadu"
				},

				name: {},
				identifier: {},
				insurerCode: {},

				address: {},
				addressForm: {
					label: <FormattedMessage id="Patient.form.contactForm.address" />
				},

				employment: {
					label: "Zamestnanie"
				},

				doctorname: {},
				orgunitname: {},
				orgunitaddress: {},
				orgunitcontact: {},

				doctorcode: {},
				orgunitcode: {},

				odporPersonnelExpertiseId: {
					label: "Odporúčajúci lekár"
				},
				odporDoctorId: {},
				odporDoctorOUPZS: {
					label: "Odporúčajúci OUPZS"
				},
				odporACode: {},
				odporPCode: {},
				//

				phone: {},
				dateNotifPatient: {
					label: "Termín vyšetrenia oznámiť na adresu pacienta(tel)"
				},
				dateNotifDoctor: {
					label: "lekára(tel)"
				},

				newDate: {
					label: "Objednaný na Deň, Hodina",
					// value: moment(),
					type: "date"
				},
				orderDateDay: {},
				orderDateHour: {},

				prevCTExam: {
					label: "Doterajšie CT vyšetrenia pacienta (Kde / Kedy?)"
				},
				areaExam: {
					label: "Ktorý orgán (oblasť) sa má vyšetriť ?"
				},
				questionCT: {
					label: "Ktorá otázka sa má CT vyšetrením zodpovedať ?"
				},
				anestesis: {
					label: "Požiadavka na celkovú anestézu"
				},
				alergAnamnesis: {
					label: "Alergická anamnéza, event. spôsob prípravy pacienta (napr. premedikácia u nekľudných pacientov)"
				},
				epikriza: {
					label: "Epikríza"
				},
				prevLabExam: {
					label: "Výsledky predchádzajúcich rádiodiagnostických vyšetrení"
				},

				diagClinical: {
					label: "Klinická diagnóza"
				},
				diagKod: {},
				diagName: {},

				statisticsCode: {
					label: "Štatistický kód"
				},

				date: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.date" />,
					value: moment(),
					type: "date"
				}
			}
		}
	}
}
