"use strict"
import api from "../actions/api"
import {observable, action} from "mobx"
import moment from "moment"
import {getSelectedOrgUnitResourceID, getSelectedOrgUnitID /*, getUser*/} from "../../../global/helpers/actions"
import {getFilters} from "../../../global/helpers/api"
import UIStore from "./UIStore"
import GlobalStore from "../../../global/store/GlobalStore"
import settings from "../../../global/config/settings"
import {StorageBase} from "../../../global/storage/storageEx"
import {USER_DATA, USER_SETTINGS} from "../../../global/config/constants"

class AdminStore {
	@observable users = []
	@observable openingHours = []
	@observable openingDays = []
	@observable changedFilters = false
	currFilters = {}
	@observable openingDaysDTO = observable.map({
		dialogOpen: false,
		openingDays: null,
		selectedDay: null,
		startTime: null,
		endTime: null
	})

	@action clearData(name) {
		this[name] = {}
		this.openingDaysDTO.clear()
	}

	@action loadUsers(form) {
		UIStore.isFormSaving = true
		let formVals = isSafe(form) ? form.values() : {}
		this.currFilters = formVals
		this.changedFilters = false
		const filter = getFilters([`login=${isNotEmpty(formVals.login) ? formVals.login : ""}`])
		// let user = getUser()
		// if (isSafe(user) && isSafe(user.person)) {
		// let usersFilter = getFilters([`entity=${user.person._id}`])
		api
			.loadAllUsers(filter)
			.call()
			.then((res) => {
				this.users = res.rows
				UIStore.isFormSaving = false
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
		// }
	}

	@action loadOpeningDays() {
		const resourceID = getSelectedOrgUnitResourceID()
		api
			.loadOpeningDays(resourceID)
			.call()
			.then((od) => {
				this.openingDaysDTO.set("openingDays", od)
			})
	}

	@action saveOpeningDay(form) {
		const resourceID = getSelectedOrgUnitResourceID()
		const selectedDayM = this.openingDaysDTO.get("selectedDay")
		const items = form.values()
		const remove = items.remove
		const selectedDay = moment(selectedDayM).format(settings.DB_DATE_FORMAT)
		const openingDays = this.openingDaysDTO.get("openingDays")
		let openedFromSelectedDay = null

		if (!remove && openingDays) {
			const otherHours = openingDays.filter((od) => od.date === selectedDay)
			if (otherHours && otherHours.opened) {
				openedFromSelectedDay = otherHours.opened
			}
		}

		const saveRequest = [
			{
				date: selectedDay,
				opened: openedFromSelectedDay
			}
		]
		api
			.saveOpeningDays(resourceID, saveRequest)
			.call()
			.then(() => {
				UIStore.isFormSaving = false
				this.openingDaysDTO.clear()
				this.loadOpeningDays()
			})
	}

	@action loadOpeningHours() {
		const resourceID = getSelectedOrgUnitResourceID()
		api
			.loadOpeningHours(resourceID)
			.call()
			.then((oh) => {
				this.openingHours = oh
			})
	}

	@action isChangedFilter(formVals) {
		this.changedFilters = this.currFilters.login != formVals.login
	}

	@action saveOpeningHours(form) {
		const resourceID = getSelectedOrgUnitResourceID()
		const weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
		const items = form.values()
		let saveRequest = []
		weekdays.map((d, ix) => {
			const openedFrom = items[`${d}from`] ? items[`${d}from`] : "00:00:00"
			const openedTo = items[`${d}to`] ? items[`${d}to`] : "00:00:00"
			const closedFrom = items[`${d}closedfrom`] ? items[`${d}closedfrom`] : "00:00:00"
			const closedTo = items[`${d}closedto`] ? items[`${d}closedto`] : "00:00:00"

			const dayObj = {
				day: ix,
				opened: [
					{
						from: openedFrom.length === 5 ? openedFrom + ":00" : openedFrom,
						to: openedTo.length === 5 ? openedTo + ":00" : openedTo
					}
				],
				closed: [
					{
						from: closedFrom.length === 5 ? closedFrom + ":00" : closedFrom,
						to: closedTo.length === 5 ? closedTo + ":00" : closedTo
					}
				]
			}

			saveRequest.push(dayObj)
		})

		if (saveRequest) {
			api
				.saveOpeningHours(saveRequest, resourceID)
				.call()
				.then(() => {
					GlobalStore.setNotificationMessage("Admin.codelist.openinghours.savesuccess")

					// let userData = Storage.getItem(USER_DATA)
					// let userSettings = Storage.getItem(USER_SETTINGS)
					let userData = StorageBase.getObjectByKey(USER_DATA)
					let userSettings = StorageBase.getObjectByKey(USER_SETTINGS)

					if (isSafe(userData)) {
						let index = userData.orgunits.findIndex((orgunit) => orgunit._id == getSelectedOrgUnitID())

						let tempSaveRequest = saveRequest.map((day) => {
							return {
								day: day.day,
								opened: [
									{from: day.opened[0].from, to: day.closed[0].from},
									{from: day.closed[0].to, to: day.opened[0].to}
								]
							}
						})

						let currOrgunit = GlobalStore.orgunits.filter((orgunit) => orgunit._id == getSelectedOrgUnitID())
						if (isSafe(currOrgunit) && isSafe(currOrgunit[0])) {
							currOrgunit[0].opening_hours = tempSaveRequest
						}

						userData.orgunits[index].opening_hours = tempSaveRequest
						// Storage.setItem(USER_DATA, userData)
						StorageBase.updateByKey(USER_DATA, userData)

						if (isSafe(userSettings) && isSafe(userSettings[userData.user._id])) {
							userSettings[userData.user._id].selectedOrgUnit.opening_hours = tempSaveRequest
							// Storage.setItem(USER_SETTINGS, userSettings)
							StorageBase.updateByKey(USER_SETTINGS, userSettings)
						}
					}

					let tempOpenningHour = {}

					saveRequest.forEach((day) => {
						if (isSafe(day.opened) && isSafe(day.opened[0])) {
							let amFrom = +day.opened[0].from.substring(0, 2)
							let pmTo = +day.opened[0].to.substring(0, 2)

							tempOpenningHour[day.day] = {from: amFrom, to: pmTo}
						}
					})

					GlobalStore.openingHour = tempOpenningHour

					this.openingHours = saveRequest
					UIStore.isFormSaving = false
				})
		}
	}
}
var singleton = new AdminStore()
export default singleton
