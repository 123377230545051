import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				showForm: {
					value: false,
					rules: "boolean"
				},
				anchorEl: {
					value: null
				},
				itemorder: {
					label: <FormattedMessage id="Food.FoodContainer.order" />
				},
				code: {
					label: <FormattedMessage id="Orgunits.orgunit.form.code" />,
					rules: "string|required"
				},
				name: {
					label: <FormattedMessage id="Common.label.name" />,
					rules: "string|required"
				},
				description: {
					label: <FormattedMessage id="Common.label.description" />
				}
			}
		}
	}
}
