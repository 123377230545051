import {FormattedMessage} from "react-intl"
import React from "react"
import {getSelectedOrgUnit} from "../../../../../global/helpers/actions"

export default {
	load() {
		return {
			fields: {
				orgunit: {
					label: <FormattedMessage id="Patient.form.patientrequest.orgunit" />,
					rules: "string",
					value: getSelectedOrgUnit()._id
				},
				insurer: {
					label: <FormattedMessage id="Common.label.insurer" />,
					type: "string"
				},
				identifier: {
					label: <FormattedMessage id="Patient.labels.IDNumber" />,
					type: "string"
				}
			}
		}
	}
}
