import React from "react"
import {observer} from "mobx-react"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import Grid from "@material-ui/core/Grid"
import {FormattedMessage, injectIntl} from "react-intl"
import OrgunitDetailParametersStore from "../../../stores/OrgunitDetailParametersStore"
import OrgunitDetailStore from "../../../stores/OrgunitDetailStore"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import fields from "./orgunitsParametersFields"
import GlobalStore from "../../../../../global/store/GlobalStore"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import {insertFormDataByScheme} from "../../../../../global/helpers/bindings"
import bindings from "./orgunitsParametersBindings"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

@injectIntl
@observer
export default class OrgunitsParametersContainer extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				insertFormDataByScheme(
					form,
					bindings,
					{
						orgunitId: props.orgunitId,
						paramId: isNotEmpty(form.$("paramId").value) ? form.$("paramId").value : null
					},
					() => {
						OrgunitDetailParametersStore.editable = false
						OrgunitDetailStore.loadOrgUnitParams(props.orgunitId)
						form.reset()
					},
					false,
					"Common.notification.save"
				)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		OrgunitDetailStore.loadOrgUnitParams(props.orgunitId)
		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks})
		GlobalStore.refreshCodeLists(["orgUnitParamType"])
	}

	onHandleRowClick = (data) => {
		this.form.$("paramType").set("value", data.param_type)
		this.form.$("value").set("value", data.value)
		this.form.$("paramId").set("value", data.delete)

		OrgunitDetailParametersStore.editable = true
		OrgunitDetailParametersStore.isAdd = true
	}

	render() {
		return (
			<React.Fragment>
				<Grid container className="pa-8">
					<Grid item xs={12}>
						<XsTable
							config={{
								columnDefs: {
									param_name_ext: {
										title: <FormattedMessage id="Orgunits.orgunit.TypeOfParam" />,
										type: "string",
										design: {
											width: "50%"
										}
									},
									value: {
										title: <FormattedMessage id="Common.label.value" />,
										type: "string",
										design: {
											width: "40%"
										}
									},
									delete: {
										title: "",
										type: "action",
										design: {
											width: "10%",
											body: {
												renderer: (props) => {
													return (
														<XsIconButton
															className="action-delete"
															icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
															onClick={() =>
																GlobalStore.openConfirmationDialog("xsDeleteOrgunitParam", {paramId: props.value})
															}
														/>
													)
												}
											}
										}
									}
								},
								options: {
									reverseColor: true,
									hidePager: true,
									showCursor: true,
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										return {
											delete: dataRow._id,
											param_type: dataRow.param_type._id,
											param_name_ext: dataRow.param_type.name_ext,
											value: dataRow.value
										}
									}
								},
								dataSource: OrgunitDetailStore.orgParamsList
							}}
						/>
					</Grid>
					<Grid item xs={12} className="pt-6">
						{OrgunitDetailParametersStore.isAdd ? (
							<Grid container direction="row" alignItems="center" spacing={8}>
								<Grid item xs={3}>
									<XsSearchSelect field={this.form.$("paramType")} items={GlobalStore.CL["orgUnitParamType"]} />
								</Grid>
								<Grid item xs={2}>
									<XsInput field={this.form.$("value")} />
								</Grid>
								<Grid item container justify="flex-end" alignItems="center" xs={7} spacing={8} className="mt-0">
									<Grid item>
										<XsButton
											className="xs-success"
											icon={<i className="fal fa-check fa-lg" />}
											text={
												<FormattedMessage
													id={isNotEmpty(this.form.$("paramId").value) ? "Common.label.edit" : "Common.label.save"}
												/>
											}
											onClick={(e) => {
												this.form.onSubmit(e)
											}}
										/>
									</Grid>
									<Grid item>
										<XsButton
											className="xs-danger xs-outline"
											icon={<i className="fal fa-times fa-lg" />}
											text={<FormattedMessage id="Common.label.cancel" />}
											onClick={() => {
												OrgunitDetailParametersStore.editable = false
												OrgunitDetailParametersStore.isAdd = false
												this.form.reset()
											}}
										/>
									</Grid>
								</Grid>
							</Grid>
						) : (
							<Grid item className="pb-2 pt-2">
								<XsButton
									className="xs-primary xs-outline"
									icon={<i className="fal fa-plus fa-lg" />}
									text={<FormattedMessage id="Common.button.addAnother" />}
									onClick={() => {
										OrgunitDetailParametersStore.isAdd = true
										OrgunitDetailParametersStore.editable = false
									}}
								/>
							</Grid>
						)}
					</Grid>
				</Grid>
				<XsConfirmationDialog
					name="xsDeleteOrgunitParam"
					text={<FormattedMessage id="Orgunits.orgunit.deleteParam" />}
					type="danger"
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Orgunits.orgunit.deleteParamButton"
					}}
					onConfirmation={() => {
						OrgunitDetailStore.deleteParam(GlobalStore.confirmationDialogParams.paramId, this.props.orgunitId)
					}}
				/>
			</React.Fragment>
		)
	}
}
