import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"

import fields from "./requestMRFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsAutocomplete from "../../../../../../global/ui/xsInput/xsAutocomplete"
import SearchSelect from "../../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import api from "../../../../actions/api"
import {getFilters} from "../../../../../../global/helpers/api"
import XsAutocompleteLocal from "../../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegisterCacheStore from "../../../../../../global/store/RegistersCacheStore"

@injectIntl
@observer
export default class RequestMRTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.state = {
			doctorOUPZSItems: []
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		if (isEmpty(this.form.$("addressForm").value) && isNotEmpty(this.form.$("address").value)) {
			this.form.$("addressForm").value = this.form.$("address").value
		}
		if (isEmpty(this.form.$("phoneForm").value) && isNotEmpty(this.form.$("phone").value)) {
			this.form.$("phoneForm").value = this.form.$("phone").value
		}
	}

	render() {
		return (
			<div className="pa-4">
				<Grid container direction="column" align="top">
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsInput field={this.form.$("idHospPrip")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("employment")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("phoneForm")} />
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<XsInput field={this.form.$("addressForm")} />
					</Grid>

					<Grid container direction="row" align="left" spacing={8} className="mt-4">
						<Grid item xs={8} className="xs-autocomplete-element">
							<XsAutocomplete
								field={this.form.$("odporPersonnelExpertiseId")}
								minLengthForSearch="3"
								clearText
								api={api.loadPersonnel}
								filterValue={"personnel_expertise_id"}
								inputRenderer={(obj, menuItem = false) =>
									(isNotEmpty(obj.preferred_provider_code) && obj.preferred_provider_code.charAt(9) == "2" && menuItem
										? "<b style=color:#00A787>"
										: "") +
									(obj.identifier ? obj.identifier + " / " : "") +
									obj.full_name +
									" / " +
									(obj.expertise_code ? obj.expertise_code + " " : "") +
									obj.expertise +
									(isNotEmpty(obj.preferred_provider_code) ? " / " + obj.preferred_provider_code : "") +
									(isNotEmpty(obj.preferred_provider_code) && obj.preferred_provider_code.charAt(9) == "2" && menuItem
										? "</b>"
										: "")
								}
								saveValue={(obj) => obj.personnel_expertise_id}
								postAction={(obj, firstLoad) => {
									if (!firstLoad) {
										this.form.$("odporDoctorId").value = ""
										this.form.$("odporACode").value = ""
										this.form.$("odporDoctorOUPZS").value = ""
										this.setState({doctorOUPZSItems: []})
									}

									this.form.$("odporDoctorId").value = obj.personnel_id
									this.form.$("odporACode").value = obj.identifier

									if (isNotEmpty(obj.personnel_expertise_id)) {
										const searchRequest = getFilters([`personnel_expertise_id=${obj.personnel_expertise_id}`])
										api
											.loadProviders(searchRequest)
											.call()
											.then((response) => {
												if (isSafe(response) && isSafe(response.rows)) {
													let items = []
													response.rows.forEach((item) => {
														items.push({_id: item.provider_id, name_ext: item.provider_code})
													})
													this.setState({doctorOUPZSItems: items})
													let doctorOUPZS
													let preferredOUPZS = null
													if (isSafe(response.rows) && response.rows.length > 1) {
														preferredOUPZS = response.rows.find((row) => {
															return row.code_ext === obj.preferred_provider_code
														})
													}
													if (response.rows.length === 1 || isSafe(preferredOUPZS)) {
														if (response.rows.length === 1) {
															doctorOUPZS =
																isEmpty(response.rows[0].provider_id) === "" ? null : response.rows[0].provider_id
														} else {
															doctorOUPZS = preferredOUPZS.provider_id
														}
														if (!firstLoad) {
															this.form.$("odporDoctorOUPZS").value = doctorOUPZS
															const codeItem = items.find((x) => x._id == doctorOUPZS)
															if (isSafe(codeItem)) {
																this.form.$("odporPCode").value = codeItem.name_ext
															}
														}
													}
												}
											})
									}
								}}
								onClear={() => {
									this.form.$("odporDoctorId").value = ""
									this.form.$("odporACode").value = ""
									this.form.$("odporDoctorOUPZS").value = ""
									this.setState({doctorOUPZSItems: []})
								}}
							/>
						</Grid>
						<Grid item xs={4} className="xs-autocomplete-element">
							{isSafe(this.state.doctorOUPZSItems) && this.state.doctorOUPZSItems.length > 0 ? (
								<SearchSelect
									field={this.form.$("odporDoctorOUPZS")}
									disabled={isEmpty(this.form.$("odporDoctorId").value)}
									items={this.state.doctorOUPZSItems}
									onChange={(val) => {
										const code = this.state.doctorOUPZSItems.find((x) => x._id == val).name_ext
										this.form.$("oldDoctorPCode").value = code
									}}
								/>
							) : (
								<XsAutocomplete
									field={this.form.$("odporDoctorOUPZS")}
									minLengthForSearch="3"
									api={api.loadProviders}
									clearText
									disabled={isEmpty(this.form.$("odporDoctorId").value)}
									filterValue={"provider_id"}
									inputRenderer={(obj) =>
										(obj.provider_code ? obj.provider_code + " / " : "") +
										obj.company_name +
										" / " +
										(obj.expertise_code ? obj.expertise_code + " " : "") +
										obj.expertise_name_ext
									}
									saveValue={(obj) => obj.provider_id}
									postAction={(obj) => {
										this.form.$("odporPCode").value = obj.provider_code
									}}
								/>
							)}
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8} className="mt-4">
						<Grid item xs={3}>
							<XsDateTimePicker
								field={this.form.$("newDate")}
								showTimeSelect={true}
								onChange={(value) => {
									this.form.$("orderDateDay").value = value.format("DD.MM.YYYY")
									this.form.$("orderDateHour").value = value.format("HH:mm")
								}}
							/>
						</Grid>
						<Grid item xs={5}>
							<XsInput field={this.form.$("dateNotifPatient")} />
						</Grid>
					</Grid>

					<Grid item xs={12} className="mt-4">
						<XsInput field={this.form.$("prevMRExam")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("areaExam")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("questionMR")} multiple rows={3} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("anamnesis")} multiple rows={3} />
					</Grid>

					<Grid item xs={12}>
						<XsInput field={this.form.$("alergAnamnesis")} multiple rows={3} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("epikriza")} multiple rows={3} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("prevLabExam")} multiple rows={3} />
					</Grid>

					<Grid item xs={12} className="xs-autocomplete-element mt-4">
						<XsAutocompleteLocal
							field={this.form.$("diagClinical")}
							minLengthForSearch="1"
							data={RegisterCacheStore.diagnosisRegister}
							inputRenderer={(obj) => {
								this.form.$("diagKod").value = obj.code_ext
								this.form.$("diagName").value = obj.name_ext
								return obj.code_ext + " " + obj.name_ext
							}}
							saveValue={(obj) => obj.code}
							modalConfig={(textValue, clickHandler) => {
								return {
									columnDefs: {
										code_ext: {
											title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
											type: "string",
											dbName: "code_ext",
											design: {
												width: "150px"
											},
											filter: {
												gridWidth: 3,
												defaultValue: "",
												renderElement: "input"
											}
										},
										name_ext: {
											title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
											type: "string",
											dbName: "search_column",
											filter: {
												gridWidth: 9,
												defaultValue: textValue,
												renderElement: "input"
											}
										}
									},
									options: {
										showCursor: true,
										onRowClick: (dataRow) => {
											clickHandler(dataRow)
										}
									}
								}
							}}
						/>
					</Grid>

					<Grid container direction="row" align="left" spacing={8} className="mt-2">
						<Grid item xs={6}>
							<XsInput field={this.form.$("statisticsCode")} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker field={this.form.$("date")} showTimeSelect={false} />
						</Grid>
					</Grid>
				</Grid>
			</div>
		)
	}
}
