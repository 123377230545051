import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "angiologie_questionnaire"
				},

				hmotnost: {
					label: <FormattedMessage id="PrintTemplate.AngiologieQuestionnaire.hmotnost" />,
					value: ""
				},
				vyska: {
					label: <FormattedMessage id="PrintTemplate.AngiologieQuestionnaire.vyska" />,
					value: ""
				},

				otazka1: {
					label: <FormattedMessage id="PrintTemplate.AngiologieQuestionnaire.otazka1" />,
					value: ""
				},
				otazka2: {
					label: <FormattedMessage id="PrintTemplate.AngiologieQuestionnaire.otazka2" />,
					value: ""
				},
				otazka3: {
					label: <FormattedMessage id="PrintTemplate.AngiologieQuestionnaire.otazka3" />,
					value: ""
				},
				otazka4: {
					label: <FormattedMessage id="PrintTemplate.AngiologieQuestionnaire.otazka4" />,
					value: ""
				},
				otazka5: {
					label: <FormattedMessage id="PrintTemplate.AngiologieQuestionnaire.otazka5" />,
					value: ""
				},
				otazka6: {
					label: <FormattedMessage id="PrintTemplate.AngiologieQuestionnaire.otazka6" />,
					value: ""
				},
				otazka7: {
					label: <FormattedMessage id="PrintTemplate.AngiologieQuestionnaire.otazka7" />,
					value: ""
				},
				otazka8: {
					label: <FormattedMessage id="PrintTemplate.AngiologieQuestionnaire.otazka8" />,
					value: ""
				},
				otazka9: {
					label: <FormattedMessage id="PrintTemplate.AngiologieQuestionnaire.otazka9" />,
					value: ""
				},
				otazka10: {
					label: <FormattedMessage id="PrintTemplate.AngiologieQuestionnaire.otazka10" />,
					value: ""
				}
			}
		}
	}
}
