import React from "react"
import { observer } from "mobx-react"
import { FormattedMessage } from "react-intl"
import XsBarcode from "../../../../../../global/ui/xsBarcode/xsBarcode"
import XsExpansionPanel from "../../../../../../global/ui/xsExpansionPanel/xsExpansionPanel"

import UIStore from "../../../../stores/UIStore"

// import mime from "mime-types"
// import FileIcon, { defaultStyles } from "react-file-icon"
// import config from "../../../../../../global/config/settings"

import "./requestRow.less"

const RequestRow = observer(({ summaries, expertiseCode, expertiseName, barCode, request }) => {
	const sumReq = summaries.find((s) => s.type._id === "REQ")
	const sumInfo = summaries.find((s) => s.type._id === "INFO")

	let barcodeText = null
	if (isNotEmpty(barCode)) {
		const barcodeTextApp = barCode.slice(-6)
		barcodeText = `${barCode.slice(0, -6)} / ${barcodeTextApp}`
	}

	return (
		<XsExpansionPanel maxHeight={40} glOpen={UIStore.isOpenAllRecordsInEHROverview}>
			<div className="requestRow">
				{expertiseCode && (
					<div>
						<span className="title">
							<FormattedMessage id="Patient.form.patientrequest.expertise" />:
						</span>{" "}
						<b>
							{expertiseCode} - {expertiseName}
						</b>
					</div>
				)}
				{request && request.diagnosis_code_ext && (
					<div>
						<span className="title">
							<FormattedMessage id="Patient.form.patientrecord.diagnosis" />:
							</span>{" "}
						<b>
							{request.diagnosis_code_ext} - {request.diagnosis_name_ext} {request.diagnosis_desc}
						</b>
					</div>
				)}
				{sumReq && <div dangerouslySetInnerHTML={{ __html: sumReq.content }} />}
				{sumInfo && (
					<div>
						<span className="title">
							<FormattedMessage id="Patient.form.patientrequest.patientnote" />:
						</span>
						<b>
							<div dangerouslySetInnerHTML={{ __html: sumInfo.content }} />
						</b>
					</div>
				)}
				{/* <div className="xs-overview-attachments">
        {isSafe(attachments) && attachments.length > 0 && attachments.map((attach, idx) => (
          <a className="xs-attachment" key={idx} href={`${config.API_BASE_URL}${attach.api_url}`} download>
            <div><FileIcon size={40} extension={mime.extension(attach.content_type)} /></div>
            <div>{attach.file_name} ({isSafe(attach.size) && isFinite(attach.size) ? +attach.size / 1000 + " kB" : ""})</div>
          </a>
        ))}
      </div> */}
				{barCode && (
					<div className="req-barcode">
						<XsBarcode
							value={barCode}
							text={barcodeText}
							format="CODE128"
							ean128={true}
							displayValue={true}
							height={50}
						/>
					</div>
				)}
			</div>
		</XsExpansionPanel>
	)
})

export default RequestRow
