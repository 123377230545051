import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

import GWUpdatingDialogStore from "./stores/GWUpdatingDialogStore"
import XsLoading from "../../global/ui/xsLoading/xsLoading"

@injectIntl
@observer
export default class GWRegisterZprWaitingDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsUpdatingDialog"
				open={GWUpdatingDialogStore.isRegisterZprWaitingOpen}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle>
					<div className="xs-eHealthProgress-header">
						<div className="xs-eHealthProgress-title">
							{this.props.intl.formatMessage({id: "Common.label.EHGWRegisterZprWaiting"})}
						</div>
					</div>
				</DialogTitle>
				<DialogContent>
					<div className="xs-body">
						<XsLoading />
					</div>
				</DialogContent>
			</Dialog>
		)
	}
}
