import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import bindings from "../../../../../global/ui/globalUISchemeBindings"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsSimpleTextArea from "../../../../../global/ui/xsTextArea/xsSimpleTextArea"

import {getUserCompanyInfo} from "../../../../../global/helpers/actions"
import {isPhoneNumberValid} from "../../../../../global/helpers/functions"

import ContactFormStore from "../../../stores/ContactFormStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

@injectIntl
@observer
export default class ContactSendSMSToPatientDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit(form) {
				if (isEmpty(form.$("sender").value) || isEmpty(form.$("recipient").value) || isEmpty(form.$("message").value)) {
					GlobalStore.setNotificationMessage("Common.warning.message.emptyform")
				} else if (isNotEmpty(form.$("recipient").value) && !isPhoneNumberValid(form.$("recipient").value)) {
					GlobalStore.setNotificationMessage("Patient.form.contactForm.mobileFormat")
					form.$("recipient").invalidate()
				}
			},
			onSuccess(form) {
				ContactFormStore.sendSMS(form)
			},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					sender: {
						label: <FormattedMessage id="Common.label.sendingDoctor" />,
						rules: "string|required",
						value: getUserCompanyInfo().name
					},
					recipient: {
						label: <FormattedMessage id="Common.label.recipient" />,
						rules: "string|required",
						placeholder: "+421999000000"
					},
					message: {
						label: <FormattedMessage id="Common.label.textMessage" />,
						rules: "string|required|max:450"
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)

		ContactFormStore.smsFormRef = this.form
	}

	close = () => {
		this.form.reset()
		ContactFormStore.closeSendSMSDialog()
	}

	render() {
		return (
			<Dialog
				id="xsContactSendSMSToPatientDialog"
				open={ContactFormStore.isOpenSendSMSDialog}
				onClose={() => this.close()}
				className="xs-base-dialog dialog-newpatient"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-600"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-absolute xs-close-btn" onClick={() => this.close()}>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-comment-lines fa-2x" />
						</div>
						<div className="xs-header-title">
							{isSafe(ContactFormStore.resData) ? (
								<FormattedMessage id="Common.label.SMSsended" />
							) : (
								<FormattedMessage id="Common.label.sendSMS" />
							)}
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-overflow-visible">
					<Grid container direction="column">
						<Grid item xs={12} className="xs-form">
							{isSafe(ContactFormStore.resData) ? (
								<React.Fragment>
									{isSafe(ContactFormStore.resData.to_success) && ContactFormStore.resData.to_success.length > 0 && (
										<Grid container direction="column" spacing={8} className="pb-4">
											<Grid item className="greenDark">
												SMS bola odoslaná nasledovným príjemcom:
											</Grid>
											{isSafe(ContactFormStore.resData.to_success) &&
												ContactFormStore.resData.to_success.length &&
												ContactFormStore.resData.to_success.map((phoneNumber, idx) => {
													return (
														<Grid item key={idx} className="greenDark">
															{phoneNumber}
														</Grid>
													)
												})}
										</Grid>
									)}
									{isSafe(ContactFormStore.resData.to_failed) && ContactFormStore.resData.to_failed.length > 0 && (
										<Grid container direction="column" spacing={8}>
											<Grid item className="redDanger">
												SMS nebola odoslaná nasledovným príjemcom:
											</Grid>
											{isSafe(ContactFormStore.resData.to_failed) &&
												ContactFormStore.resData.to_failed.length &&
												ContactFormStore.resData.to_failed.map((phoneNumber, idx) => {
													return (
														<Grid item key={idx} className="redDanger">
															{phoneNumber}
														</Grid>
													)
												})}
										</Grid>
									)}
								</React.Fragment>
							) : (
								<Grid container spacing={8}>
									<Grid item xs={6}>
										<XsInput field={this.form.$("sender")} />
									</Grid>
									<Grid item xs={6}>
										<XsInput field={this.form.$("recipient")} />
									</Grid>
									<Grid item xs={12}>
										<XsSimpleTextArea
											field={this.form.$("message")}
											rows={4}
											onChange={(val) => {
												if (val.length > 450) {
													this.form.$("message").value = val.slice(0, 450)
												}
											}}
										/>
										<div className="textRight greyDark xs-bold fontSize12">
											{this.form.$("message").value.length}/450
										</div>
										{/* <XsInput field={this.form.$("message")} /> */}
									</Grid>
								</Grid>
							)}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer">
					<Grid container justify="space-between">
						<Grid item xs={5} className="amberDark">
							<FormattedMessage id="Common.label.serviceIsCharged" />
						</Grid>
						<Grid item container xs={7} justify="flex-end" spacing={8}>
							{isSafe(ContactFormStore.resData) ? (
								<Grid item>
									<XsButton
										className="xs-success xs-outline"
										icon={<i className="fal fa-check" />}
										text={<FormattedMessage id="Common.label.ok" />}
										onClick={() => this.close()}
									/>
								</Grid>
							) : (
								<React.Fragment>
									<Grid item>
										<XsButton
											className="xs-default xs-outline"
											icon={<i className="fal fa-times" />}
											text={<FormattedMessage id="Common.form.close" />}
											onClick={() => this.close()}
										/>
									</Grid>
									<Grid item>
										<XsButton
											className="xs-success"
											icon={<i className="fal fa-comment-lines"></i>}
											text={<FormattedMessage id="Common.label.sendSMS" />}
											onClick={this.form.onSubmit}
										/>
									</Grid>
								</React.Fragment>
							)}
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}
