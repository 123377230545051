// class CookieStorageProvider {
// 	cookiePrefix = constants.cookiePrefix
// 	storedKeys = new Set()

// 	setItem(key, content) {
// 		this.storedKeys.add(key)
// 		Cookies.set(this.cookiePrefix + key, content)
// 	}

// 	getItem(key) {
// 		Cookies.get(this.cookiePrefix + key)
// 	}

// 	removeItem(key) {
// 		this.storedKeys.delete(key)
// 		Cookies.remove(this.cookiePrefix + key)
// 	}

// 	clear() {
// 		this.storedKeys.forEach((key) => Cookies.remove(this.cookiePrefix + key))
// 	}
// }

const defaultStorageProvider = window.localStorage

const Storage = () => {
	const storageProvider = /*bowser.safari ? new CookieStorageProvider() : */ defaultStorageProvider

	const setItem = (key, content) => {
		storageProvider.setItem(key, JSON.stringify(content))
	}

	const getItem = (key) => {
		return JSON.parse(storageProvider.getItem(key))
	}

	const removeItem = (key) => {
		storageProvider.removeItem(key)
	}

	const pushItem = (key, content) => {
		const arrayItem = getItem(key) || []
		arrayItem.push(content)
		setItem(key, arrayItem)
	}

	// Preserve array of item which are not going to be deleted

	const clear = (preserveArray) => {
		for (let key in storageProvider) {
			if (!preserveArray.includes(key)) {
				storageProvider.removeItem(key)
			}
		}
	}

	const getAll = () => {
		return storageProvider
	}

	return {
		setItem,
		getItem,
		pushItem,
		removeItem,
		clear,
		getAll
	}
}

export default Storage()
