import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import GlobalStore from "../../../../../global/store/GlobalStore"
import {Grid, Dialog, DialogContent, DialogActions, DialogTitle} from "@material-ui/core"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
// import XsSimpleTextArea from "../../../../../global/ui/xsTextArea/xsSimpleTextArea"
import XsTextArea from "../../../../../global/ui/xsTextArea/xsTextArea"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"

import UniversalTemplateStore from "../../../stores/UniversalTemplateStore"
// import {handleInsertHelpersIntoTextArea} from "../../../../../global/helpers/actions"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import {getPlaceholderFunctions} from "../../../../../global/helpers/universalTemplatesFunctions"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

// import "./notify.less"

@observer
export default class SendNotificationDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				UniversalTemplateStore.saveDialog(form)
			},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					id: {},
					name: {label: <FormattedMessage id="Common.label.name" />, rules: "required"},
					private: {label: <FormattedMessage id="Common.label.private" />, type: "checkbox", rules: "required"},
					templateText: {label: "Text", rules: "required"},
					templateTextFull: {label: "Zobrazenie textu"},
					priority: {label: <FormattedMessage id="Common.label.placeholders" />}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)

		UniversalTemplateStore.form = this.form
		this.textareaCursor = null
	}

	generateFullText = () => {
		let templateText = this.form.$("templateText").value
		let fullText = templateText
		const functions = getPlaceholderFunctions()
		UniversalTemplateStore.mappings.forEach((placeholder) => {
			const placeholderObj = functions.find((row) => row._id == placeholder)
			fullText = fullText.replace(placeholderObj._id, placeholderObj.function())
		})
		this.form.$("templateTextFull").value = fullText
	}

	render() {
		return (
			<Dialog
				id="createUniversalTemplate"
				open={UniversalTemplateStore.isDialogOpen}
				onClose={() => UniversalTemplateStore.closeTemplateDialog(this.form)}
				className="xs-base-dialog dialog-newpatient"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-900"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								UniversalTemplateStore.closeTemplateDialog(this.form)
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-file-invoice fa-2x" />
						</div>
						<div className="xs-header-title">Pridať šablónu</div>
					</div>
				</DialogTitle>
				<DialogContent className="pa-2">
					<Grid container direction="column">
						<Grid item xs={12} className="xs-form">
							<Grid container direction="row" spacing={8}>
								<Grid item xs={12}>
									<XsInput field={this.form.$("name")} />
								</Grid>
								<Grid item xs={12}>
									<XsCheckbox field={this.form.$("private")} />
								</Grid>
								<Grid item xs={6}>
									<XsSearchSelect
										white
										field={this.form.$("priority")}
										items={getPlaceholderFunctions()}
										onChange={(data) => {
											if (isSafe(data)) {
												// if (isNotEmpty(this.form.$("templateText").value) && this.textareaCursor) {
												// 	handleInsertHelpersIntoTextArea(this.form, "templateText", this.textareaCursor.cursor, data)
												// } else {
													this.form.$("templateText").value += data
												// }
												this.textareaCursor = null
											}

											UniversalTemplateStore.mappings.push(data)
										}}
									/>
								</Grid>
								<Grid item xs={12} className="pt-3 pb-1">
									<XsTextArea
										field={this.form.$("templateText")}
										rows={9}
										white
										maxLength={5000}
										onFocus={(data) => {
											if (data && (isNotSafe(this.textareaCursor) || this.textareaCursor.cursor != data.cursor)) {
												this.textareaCursor = {
													cursor: data.cursor
												}
											}
										}}
									/>
								</Grid>
								{UniversalTemplateStore.showFullText && (
									<Grid item xs={12} className="pt-3 pb-1">
										<XsTextArea
											field={this.form.$("templateTextFull")}
											rows={9}
											white
											maxLength={5000}
											disabled={true}
											onFocus={(data) => {
												if (data && (isNotSafe(this.textareaCursor) || this.textareaCursor.cursor != data.cursor)) {
													this.textareaCursor = {
														cursor: data.cursor
													}
												}
											}}
										/>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid item direction="row-reverse" container spacing={8}>
						<Grid item>
							<XsButton
								className="xs-success"
								icon={<i className="fal fa-check" />}
								text={<FormattedMessage id="Common.label.save" />}
								onClick={(e) => this.form.onSubmit(e)}
							/>
						</Grid>
						<Grid item>
							<XsButton
								className="xs-info"
								icon={<i className="fal fa-file-alt" />}
								text={<FormattedMessage id="Common.label.loadPlaceholders" />}
								onClick={() => {
									this.generateFullText()
									UniversalTemplateStore.showFullText = true
								}}
							/>
						</Grid>
						<Grid item>
							<XsButton
								className="xs-danger xs-outline"
								icon={<i className="fal fa-times" />}
								text={<FormattedMessage id="Common.label.cancel" />}
								onClick={() => {
									UniversalTemplateStore.closeTemplateDialog()
								}}
							/>
						</Grid>
						{isNotEmpty(this.form.$("id").value) && (
							<Grid item>
								<XsButton
									className="xs-danger"
									icon={<i className="fal fa-trash" />}
									text={<FormattedMessage id="Common.label.delete" />}
									onClick={() => GlobalStore.openConfirmationDialog("xsDeleteUniversalTemplate")}
								/>
							</Grid>
						)}
					</Grid>
				</DialogActions>
				<XsConfirmationDialog
					type="danger"
					name="xsDeleteUniversalTemplate"
					text={<FormattedMessage id="Common.label.deleteUniversalTemplateQ" />}
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.label.deleteUniversalTemplateQ"
					}}
					onConfirmation={() => UniversalTemplateStore.delete(this.form)}
				/>
			</Dialog>
		)
	}
}
