import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import {TextField} from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment"
import XsTableServer from "../../../../../../global/ui/xsTableServer/xsTableServer"
import UIStore from "../../../../stores/UIStore"
import XsLoading from "../../../../../../global/ui/xsLoading/xsLoading"
import api from "../../../../actions/api"
// import {getFilters} from "../../../../../global/helpers/api"

@injectIntl
@observer
export default class DiagnosesContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			searchValue: ""
		}
	}

	render() {
		const filterValue = []

		filterValue.push({
			associated_column: "search_column",
			values: [{id_value: this.state.searchValue}]
		})

		return (
			<div className="xs-drugs-container">
				{UIStore.isFormSaving && <XsLoading overlay={true} />}
				<Grid container spacing={8}>
					<Grid item xs={3}>
						<TextField
							placeholder={this.props.intl.formatMessage({id: "Common.searchDropdown.search"})}
							className="xs-search-component"
							inputProps={{
								className: "xs-search-input"
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start" className="xs-search-adornment">
										<i className="fal fa-search" />
									</InputAdornment>
								)
							}}
							value={this.state.searchValue}
							onChange={(e) => this.setState({searchValue: e.target.value})}
							autoComplete="off"
						/>
					</Grid>
				</Grid>
				<Grid container direction="row" spacing={8}>
					<XsTableServer
						uri={`${api.loadDiagnosisCl().getPath()}`}
						filterValue={filterValue}
						config={{
							columnDefs: {
								code_ext: {
									title: <FormattedMessage id="Common.label.diagnosesCode" />,
									type: "string",
									design: {
										width: "5%"
									},
									sortable: true,
									dbName: "code_ext"
								},
								name_ext: {
									title: <FormattedMessage id="Common.label.diagnosesName" />,
									type: "string",
									design: {
										width: "25%"
									},
									sortable: true,
									dbName: "name_ext"
								}
							},
							options: {
								showCursor: true,
								mapper: (dataRow) => {
									return {
										code_ext: isSafe(dataRow.code_ext) ? dataRow.code_ext : "",
										name_ext: isSafe(dataRow.name_ext) ? dataRow.name_ext : ""
									}
								}
							}
						}}
					/>
				</Grid>
			</div>
		)
	}
}
