"use strict"

import {observable, action} from "mobx"

import DataStore from "./DataStore"
import GlobalStore from "../../../global/store/GlobalStore"
import {getSelectedOrgUnit} from "../../../global/helpers/actions"

import api from "../actions/api"

class InvitePatientDialogStore {
	@observable isOpen = false

	email = ""

	@action open(email) {
		this.isOpen = true
		this.email = email
	}

	@action sendInvitation() {
		const patientId = DataStore.patientDTO.get("patientID")
		const orgunit = getSelectedOrgUnit()

		let request = {
			personnel_id: orgunit.personnel_id,
			expertise_id: orgunit.personnel_expertise_id,
			provider_id: orgunit._id
		}

		api
			.getInvitationToken(patientId, request)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res.registration)) {
					let req = {
						email: this.email,
						token: res.registration
					}

					api
						.registerMail(patientId, req)
						.call()
						.then(() => {
							GlobalStore.setNotificationMessage("Common.label.sendedInvitation")
							this.close()
						})
				} else {
					GlobalStore.setNotificationMessage("Common.label.generateTokenError")
					this.close()
				}
			})
	}

	@action close() {
		this.isOpen = false
	}
}

var singleton = new InvitePatientDialogStore()
export default singleton
