"use strict"

import React from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import {observer} from "mobx-react"
import moment from "moment"
import classnames from "classnames"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsInputSearchFilter from "../../../../../global/ui/xsInput/xsInputSearchFilter"
import XsDropdownList from "../../../../../global/ui/xsDropdown/xsDropdownList"

import {
	Tooltip,
	Checkbox,
	TextField,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TablePagination,
	TableHead,
	TableSortLabel,
	InputAdornment
} from "@material-ui/core"

import Grid from "@material-ui/core/Grid"

import api from "../../../actions/api"
import OtherDeliveriesStore from "../../../stores/OtherDeliveriesStore"
import RouterStore from "../../../../../global/store/RouterStore"
import {getSelectedOrgUnitID} from "../../../../../global/helpers/actions"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import TablePaginationActions from "../../../../../global/ui/xsTableServer/xsTablePaginationActions"
// import {StorageBase} from "../../../../../global/storage/storageEx"

import OtherDeliveryListFilter from "./otherDeliverylistFilter"
import AddDeliveryDialog from "../../patientDetail/patientDelivery/addDeliveryDialog"
import GlobalStore from "../../../../../global/store/GlobalStore"

import ExportRepairBatchDialog from "../deliveryList/exportRepairBatchDialog"
import RegistersCacheStore from "../../../../../global/store/RegistersCacheStore"
import AddDeliveryStore from "../../../stores/AddDeliveryStore"

import TableStore from "../../../stores/TableStore"

@injectIntl
@observer
export default class DeliveryList extends React.Component {
	constructor(props) {
		super(props)

		this.reloadGrid = true

		if (
			isSafe(Object.keys(OtherDeliveriesStore.insureeJSON)) &&
			Object.keys(OtherDeliveriesStore.insureeJSON).length === 0
		) {
			OtherDeliveriesStore.loadInsureeTypes()
		}

		if (
			isSafe(Object.keys(OtherDeliveriesStore.senderJSON)) &&
			Object.keys(OtherDeliveriesStore.senderJSON).length === 0
		) {
			OtherDeliveriesStore.loadSenderType()
		}

		if (
			isSafe(Object.keys(OtherDeliveriesStore.refundJSON)) &&
			Object.keys(OtherDeliveriesStore.refundJSON).length === 0
		) {
			OtherDeliveriesStore.loadRefundTypes()
		}

		if (
			isSafe(Object.keys(OtherDeliveriesStore.payerJSON)) &&
			Object.keys(OtherDeliveriesStore.payerJSON).length === 0
		) {
			OtherDeliveriesStore.loadPayerTypes()
		}

		let page = 0
		let rowsPerPage = 10

		if (isNotEmpty("otherDeliveryList") && TableStore.tables.hasOwnProperty("otherDeliveryList")) {
			page = +TableStore.tables["otherDeliveryList"].page
			rowsPerPage = +TableStore.tables["otherDeliveryList"].rowsPerPage
		}

		OtherDeliveriesStore.page = page
		OtherDeliveriesStore.rowsPerPage = rowsPerPage

		this.state = {
			order: false,
			orderBy: null,
			sortType: ""
		}
	}

	componentDidUpdate() {
		this.reloadGrid = false
	}

	componentDidMount() {
		OtherDeliveriesStore.resetGrid()
	}

	UNSAFE_componentWillReceiveProps() {
		this.reloadGrid = true
		// OtherDeliveriesStore.page = 0
	}

	handleChangePage = (event, page) => {
		if (!OtherDeliveriesStore.isEditable) {
			if (
				isNotEmpty("otherDeliveryList") &&
				TableStore.tables.hasOwnProperty("otherDeliveryList") &&
				TableStore.tables["otherDeliveryList"].hasOwnProperty("index")
			) {
				delete TableStore.tables["otherDeliveryList"]["index"]
			}

			OtherDeliveriesStore.page = page
			OtherDeliveriesStore.getFilters()
		}
	}

	handleChangeRowsPerPage = (event) => {
		if (!OtherDeliveriesStore.isEditable) {
			if (
				isNotEmpty("otherDeliveryList") &&
				TableStore.tables.hasOwnProperty("otherDeliveryList") &&
				TableStore.tables["otherDeliveryList"].hasOwnProperty("index")
			) {
				delete TableStore.tables["otherDeliveryList"]["index"]
			}

			OtherDeliveriesStore.rowsPerPage = event.target.value
			OtherDeliveriesStore.getFilters()
		}
	}

	createSortHandler = (property) => (event) => {
		this.handleRequestSort(event, property.columnName, property.type)
	}

	handleRequestSort = (event, property, type) => {
		this.reloadGrid = true
		const orderBy = property
		let order = this.state.order

		switch (order) {
			case "asc":
				order = false
				break
			case false:
				order = "desc"
				break
			default:
				order = "asc"
		}

		if (
			isNotEmpty("otherDeliveryList") &&
			TableStore.tables.hasOwnProperty("otherDeliveryList") &&
			TableStore.tables["otherDeliveryList"].hasOwnProperty("index")
		) {
			delete TableStore.tables["otherDeliveryList"]["index"]
		}

		this.setState({order, orderBy, sortType: type})
	}

	stringComparer = (a, b, direction) => {
		let result
		switch (direction) {
			case "asc":
				result = (isSafe(a) ? a : "").localeCompare(isSafe(b) ? b : "")
				break
			case "desc":
				result = (isSafe(b) ? b : "").localeCompare(isSafe(a) ? a : "")
				break
			default:
				result = 0
		}
		return result
	}

	getInsuranceTooltip = (insurance_type_id) => {
		if (insurance_type_id == "GH") {
			return "Common.label.insuranceVZP"
		}
		if (insurance_type_id == "EU") {
			return "Common.label.insuranceEU"
		}
		if (insurance_type_id == "FRGN") {
			return "Common.label.foreigner"
		}
	}

	getInsuranceCodeExt = (insuranceTypeId) => {
		let insType = GlobalStore.CL["insuranceTypes"].filter((x) => x.code == insuranceTypeId)

		if (isSafe(insType) && insType.length > 0) {
			return ` (${insType[0].code_ext})`
		} else {
			return ""
		}
	}

	tableBodyRows
	tableBody() {
		const {order, orderBy, sortType} = this.state
		let dataSource = OtherDeliveriesStore.otherDeliveryList

		if (isSafe(orderBy)) {
			switch (sortType) {
				case "string":
					dataSource = dataSource.slice().sort((a, b) => this.stringComparer(a[orderBy], b[orderBy], order))
					break
				case "number":
					dataSource = dataSource.slice().sort((a, b) => this.numberComparer(a[orderBy], b[orderBy], order))
					break
				case "datetime":
					dataSource = dataSource.slice().sort((a, b) => this.dateComparer(a[orderBy], b[orderBy], order))
					break
			}
		}

		OtherDeliveriesStore.isValidGrid = true

		this.tableBodyRows = []
		{
			if (isSafe(dataSource) && dataSource.length > 0) {
				dataSource.forEach((currRow, idx) => {
					if (!OtherDeliveriesStore.removeDeliveryIds.includes(currRow._id)) {
						let row = currRow
						if (OtherDeliveriesStore.changedData[currRow._id]) {
							row = Object.assign({}, currRow, OtherDeliveriesStore.changedData[row._id])
						}
						let countSubItemsPerRow = 0
						let colorSubItemsPerRow = "#E0E5EF"

						if (OtherDeliveriesStore.changedKeysTest.hasOwnProperty(row._id)) {
							colorSubItemsPerRow = "#8BC34A"
							let isValid = true

							if (!isNumber(row.count) || isEmpty(row.diagnosis_code) || isEmpty(row.item_code_ext)) {
								isValid = false
							}
							Object.keys(OtherDeliveriesStore.changedKeysTest[row._id]).forEach((ppId) => {
								if (
									!isPrice(OtherDeliveriesStore.subItems[row._id][ppId].unit_price) ||
									!isNumber(OtherDeliveriesStore.subItems[row._id][ppId].quantity)
								) {
									isValid = false
								}

								if (
									isSafe(OtherDeliveriesStore.subItems[row._id][ppId].sub_type) &&
									(OtherDeliveriesStore.subItems[row._id][ppId].sub_type === "adidrug" ||
										OtherDeliveriesStore.subItems[row._id][ppId].sub_type === "adidev" ||
										OtherDeliveriesStore.subItems[row._id][ppId].sub_type === "adicntr")
								) {
									if (isEmpty(OtherDeliveriesStore.subItems[row._id][ppId].drugs)) {
										isValid = false
									}
								} else {
									if (isEmpty(OtherDeliveriesStore.subItems[row._id][ppId].subItemType)) {
										isValid = false
									}
								}
							})
							if (!isValid) {
								colorSubItemsPerRow = "#E91E63"
								OtherDeliveriesStore.isValidGrid = false
							}
							if (OtherDeliveriesStore.subItems.hasOwnProperty(row._id)) {
								countSubItemsPerRow = Object.keys(OtherDeliveriesStore.subItems[row._id]).length
							}
						} else {
							countSubItemsPerRow = row.sub_items.length
						}

						let selectedRow = false

						if (
							TableStore.tables.hasOwnProperty("otherDeliveryList") &&
							isNotEmpty(TableStore.tables["otherDeliveryList"].index) &&
							idx == TableStore.tables["otherDeliveryList"].index
						) {
							selectedRow = true
						}

						// }
						// mapper flat napisat, osetrit nulovanie pri cancel a save a pri  search dat flag na renderovanie kvoli novemu riadku
						this.tableBodyRows.push(
							<TableRow key={idx}>
								{!OtherDeliveriesStore.isEditable && (
									<TableCell width="50px" className={classnames({bgBlueLight: selectedRow})}>
										<Checkbox
											checked={OtherDeliveriesStore.selectedIds.includes(row._id)}
											// classes={{
											// 	root: "checkboxInTable"
											// }}
											className="checkboxInTable"
											onChange={(e) => {
												if (e.target.checked) {
													OtherDeliveriesStore.selectedIds.push(row._id)
												} else {
													OtherDeliveriesStore.selectedIds = OtherDeliveriesStore.selectedIds.filter(
														(x) => x != row._id
													)
												}
											}}
										/>
									</TableCell>
								)}
								<TableCell
									width="100px"
									className={classnames({bgBlueLight: selectedRow && !OtherDeliveriesStore.isEditable})}
								>
									{isSafe(row.created_at) ? moment(row.created_at).format("DD.MM.YYYY") : ""}
								</TableCell>
								{OtherDeliveriesStore.isEditable ? (
									<TableCell>
										<div>{row.person_name + " " + this.getInsuranceCodeExt(row.insurance_type_id)}</div>
										<div>{row.person_identifier}</div>
									</TableCell>
								) : (
									<TableCell
										className={classnames("blueDark xs-bold pointer", {bgBlueLight: selectedRow})}
										onClick={() => {
											TableStore.tables["otherDeliveryList"] = {
												index: idx,
												rowsPerPage: OtherDeliveriesStore.rowsPerPage,
												page: OtherDeliveriesStore.page
											}
											RouterStore.push(`/patient?id=${row.person_id}`)
										}}
									>
										<div>
											{row.person_name}
											{row.insurance_type_id == "GH" ||
											row.insurance_type_id == "EU" ||
											row.insurance_type_id == "FRGN" ? (
												<Tooltip
													title={this.props.intl.formatMessage({
														id: this.getInsuranceTooltip(row.insurance_type_id)
													})}
												>
													<span className="xs-insurer"> {this.getInsuranceCodeExt(row.insurance_type_id)}</span>
												</Tooltip>
											) : (
												<span className="xs-insurer">{this.getInsuranceCodeExt(row.insurance_type_id)}</span>
											)}
										</div>
										<div>{row.person_identifier}</div>
									</TableCell>
								)}
								<TableCell className={classnames({bgBlueLight: selectedRow && !OtherDeliveriesStore.isEditable})}>
									{row.person_age}
								</TableCell>
								<TableCell className={classnames({bgBlueLight: selectedRow && !OtherDeliveriesStore.isEditable})}>
									{row.insurer_name_ext}
								</TableCell>

								{OtherDeliveriesStore.isEditable ? (
									<TableCell>
										<XsInputSearchFilter
											clearText
											api={api.loadDiagnosisCl}
											minLengthForSearch={3}
											className={isEmpty(row.item_code_ext) ? "xs-input-error xs-input" : "xs-input"}
											onButtonClick={(value) =>
												delayedCallback(500, () => OtherDeliveriesStore.searchDiagnosis(value, idx))
											}
											data={OtherDeliveriesStore.diagnosis[idx]}
											onSearchClose={() => (OtherDeliveriesStore.diagnosis[idx] = [])}
											defaultValue={row.diagnosis_code + " " + row.diagnosis_name_ext}
											inputRenderer={(dataRow) => {
												return dataRow.code_ext + " " + dataRow.name_ext
											}}
											valueRenderer={(dataRow) => {
												return dataRow.code_ext + " " + dataRow.name_ext
											}}
											chooseItem={(value) =>
												OtherDeliveriesStore.changeDelivery("diagnosis_name_ext", row._id, value._id)
											}
											modalTitle={<FormattedMessage id="Common.label.diagnosis" />}
											modalConfig={(textValue, clickHandler) => {
												return {
													columnDefs: {
														code_ext: {
															title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
															type: "string",
															dbName: "code_ext",
															design: {
																width: "150px"
															},
															filter: {
																gridWidth: 3,
																defaultValue: "",
																renderElement: "input"
															}
														},
														name_ext: {
															title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
															type: "string",
															dbName: "search_column",
															filter: {
																gridWidth: 9,
																defaultValue: textValue,
																renderElement: "input"
															}
															// sortable: true
														}
													},
													options: {
														// selectRow: true,
														showCursor: true,
														onRowClick: (dataRow) => {
															clickHandler(dataRow)
														}
													}
												}
											}}
										/>
									</TableCell>
								) : (
									<TableCell className={classnames({bgBlueLight: selectedRow})}>
										{row.diagnosis_code + " " + row.diagnosis_name_ext}
									</TableCell>
								) //row.diagnosis_name_ext
								}
								{OtherDeliveriesStore.isEditable ? (
									<TableCell>
										<XsInputSearchFilter
											clearText
											api={api.loadDeliveries}
											minLengthForSearch={1}
											className={isEmpty(row.item_code_ext) ? "xs-input-error xs-input" : "xs-input"}
											onButtonClick={(value) =>
												delayedCallback(500, () => OtherDeliveriesStore.searchDeliveries(value, idx))
											}
											data={OtherDeliveriesStore.deliveries[idx]}
											onSearchClose={() => (OtherDeliveriesStore.deliveries[idx] = [])}
											defaultValue={row.item_code_ext + " " + row.item_name_ext}
											inputRenderer={(dataRow) => {
												return dataRow.code_ext + " " + dataRow.name_ext
											}}
											valueRenderer={(dataRow) => {
												return dataRow.name_ext
											}}
											chooseItem={(value) => OtherDeliveriesStore.changeDelivery("item_code_ext", row._id, value._id)}
											modalTitle={<FormattedMessage id="Delivery.list.modal.delivery.title" />}
											modalConfig={(textValue, clickHandler) => {
												return {
													columnDefs: {
														code_ext: {
															title: <FormattedMessage id="Delivery.list.modal.deliveryCode" />,
															type: "string",
															dbName: "code_ext",
															design: {
																width: "150px"
															},
															filter: {
																gridWidth: 3,
																defaultValue: "",
																renderElement: "input"
															}
														},
														name_ext: {
															title: <FormattedMessage id="Delivery.list.modal.deliveryName" />,
															type: "string",
															dbName: "search_column",
															filter: {
																gridWidth: 9,
																defaultValue: textValue,
																renderElement: "input"
															}
															// sortable: true
														}
													},
													options: {
														// selectRow: true,
														showCursor: true,
														onRowClick: (dataRow) => {
															clickHandler(dataRow)
														}
													}
												}
											}}
										/>
									</TableCell>
								) : (
									<TableCell className={classnames({bgBlueLight: selectedRow})}>
										{row.item_code_ext + " " + row.item_name_ext}
									</TableCell>
								)}

								{OtherDeliveriesStore.isEditable ? (
									<TableCell>
										<XsDropdownList
											emptyValue
											className="xs-dropdown"
											items={GlobalStore.CL["senderType"]}
											renderer={(item) => {
												return <span>{item.name_ext}</span>
											}}
											column="_id"
											value={OtherDeliveriesStore.senderTypesJSON[row._id]}
											chooseItem={(item) => {
												{
													OtherDeliveriesStore.changeDelivery("sender_type", row._id, item._id)
													OtherDeliveriesStore.senderTypesJSON[row._id] = item._id
												}
											}}
										/>
									</TableCell>
								) : (
									<TableCell className={classnames("xs-no-wrap", {bgBlueLight: selectedRow})}>
										{OtherDeliveriesStore.senderJSON[row.sender_type]}
									</TableCell>
								)}
								{OtherDeliveriesStore.isEditable ? (
									<TableCell width="75px">
										<TextField
											className="xs-count"
											InputProps={{
												classes: {
													root: !isNumber(row.count) ? "xs-input-error xs-input" : "xs-input"
												}
											}}
											value={row.count}
											onChange={(e) => OtherDeliveriesStore.changeDelivery("count", row._id, e.target.value)}
										/>
									</TableCell>
								) : (
									<TableCell width="75px" className={classnames({bgBlueLight: selectedRow})}>
										{row.count}
									</TableCell>
								)}
								{OtherDeliveriesStore.isEditable ? (
									<TableCell>
										<XsDropdownList
											emptyValue
											className="xs-dropdown"
											items={GlobalStore.CL["payerType"]}
											renderer={(item) => {
												return <span>{item.name_ext}</span>
											}}
											column="_id"
											value={OtherDeliveriesStore.payerTypesJSON[row._id]}
											chooseItem={(item) => {
												{
													OtherDeliveriesStore.changeDelivery("payer_type", row._id, item._id)
													OtherDeliveriesStore.payerTypesJSON[row._id] = item._id
												}
											}}
										/>
									</TableCell>
								) : (
									<TableCell className={classnames({bgBlueLight: selectedRow})}>
										{OtherDeliveriesStore.payerJSON[row.payer_type]}
									</TableCell>
								)}
								<TableCell
									width="100px"
									className={classnames({bgBlueLight: selectedRow && !OtherDeliveriesStore.isEditable})}
								>
									{row.price_in_points}
								</TableCell>
								<TableCell
									width="100px"
									className={classnames({bgBlueLight: selectedRow && !OtherDeliveriesStore.isEditable})}
								>
									{row.price_in_currency}
								</TableCell>

								{OtherDeliveriesStore.isEditable ? (
									<TableCell>
										<XsDropdownList
											emptyValue
											className="xs-dropdown"
											items={GlobalStore.CL["insureeType"]}
											renderer={(item) => {
												return <span>{item.name_ext}</span>
											}}
											column="_id"
											value={OtherDeliveriesStore.insureeTypesJSON[row._id]}
											chooseItem={(item) => {
												{
													OtherDeliveriesStore.changeDelivery("insuree_type", row._id, item._id)
													OtherDeliveriesStore.insureeTypesJSON[row._id] = item._id
												}
											}}
										/>
									</TableCell>
								) : (
									<TableCell className={classnames("xs-no-wrap", {bgBlueLight: selectedRow})}>
										{OtherDeliveriesStore.insureeJSON[row.insuree_type]}
									</TableCell>
								)}
								{OtherDeliveriesStore.isEditable ? (
									<TableCell>
										<XsDropdownList
											emptyValue
											className="xs-dropdown"
											items={GlobalStore.CL["refundType"]}
											renderer={(item) => {
												return <span>{item.name_ext}</span>
											}}
											column="_id"
											value={OtherDeliveriesStore.refundTypesJSON[row._id]}
											chooseItem={(item) => {
												{
													OtherDeliveriesStore.changeDelivery("refund_type", row._id, item._id)
													OtherDeliveriesStore.refundTypesJSON[row._id] = item._id
												}
											}}
										/>
									</TableCell>
								) : (
									<TableCell className={classnames("xs-no-wrap", {bgBlueLight: selectedRow})}>
										{OtherDeliveriesStore.refundJSON[row.refund_type]}
									</TableCell>
								)}
								{OtherDeliveriesStore.isEditable ? (
									<TableCell>
										<TextField
											className="xs-movement"
											InputProps={{
												classes: {
													root: "xs-input"
												}
											}}
											value={row.movement}
											onChange={(e) => OtherDeliveriesStore.changeDelivery("movement", row._id, e.target.value)}
										/>
									</TableCell>
								) : (
									<TableCell className={classnames("xs-no-wrap", {bgBlueLight: selectedRow})}>{row.movement}</TableCell>
								)}
								{OtherDeliveriesStore.isEditable && (
									<TableCell width="40px" className="xs-align-text">
										<Tooltip
											title={this.props.intl.formatMessage({
												id:
													countSubItemsPerRow > 0
														? "Common.label.removeDeliveryContainsPP"
														: "Common.label.removeDelivery"
											})}
										>
											<i
												className={`fal fa-trash-alt fa-lg ${countSubItemsPerRow == 0 && "xs-redDanger"}`}
												onClick={() => (countSubItemsPerRow > 0 ? null : OtherDeliveriesStore.removeDelivery(row._id))}
											/>
										</Tooltip>
									</TableCell>
								)}
								<TableCell
									width="40px"
									className={classnames("xs-align-text", {
										bgBlueLight: selectedRow && !OtherDeliveriesStore.isEditable
									})}
								>
									<XsIconButton
										className="xs-default xs-outline"
										style={{borderColor: colorSubItemsPerRow, backgroundColor: colorSubItemsPerRow, color: "#21212F"}}
										icon={countSubItemsPerRow}
										onClick={() => {
											OtherDeliveriesStore.createSubItemPP(row)
										}}
									/>
								</TableCell>
								{!OtherDeliveriesStore.isEditable && (
									<TableCell width="40px" className={classnames("xs-align-text", {bgBlueLight: selectedRow})}>
										<XsIconButton
											className="xs-success xs-outline"
											icon={<i className="fal fa-plus fa-lg greenSuccess" />}
											onClick={() => {
												AddDeliveryStore.open("otherDelivery", {
													date: row.created_at,
													payerType: OtherDeliveriesStore.payerTypesJSON[row._id],
													diagnosis: row.diagnosis_id,
													patientId: row.patient_id
												})
											}}
										/>
									</TableCell>
								)}
							</TableRow>
						)
						// pridavanie riadku do gridy vo vynimocnych pripadoch!!!!, ak sa vyberie vykon ktoremu treba doplnujuce udaje
						if (
							OtherDeliveriesStore.idSubItemsRow === row._id &&
							isSafe(OtherDeliveriesStore.subItems) &&
							isSafe(OtherDeliveriesStore.subItems[row._id])
						) {
							const countSubItems = Object.keys(OtherDeliveriesStore.subItems[row._id]).length
							this.tableBodyRows.push(
								<TableRow key={idx + 10000}>
									<TableCell colSpan={12}>
										<Grid container direction="column">
											{Object.keys(OtherDeliveriesStore.subItems[row._id]).map((key, index) => {
												return (
													<Grid item xs={12} key={`${idx}_${index}`}>
														<Grid container direction="row" spacing={8} alignItems="center">
															<Grid item xs={2}>
																<XsDropdownList
																	disabled={!OtherDeliveriesStore.isEditable}
																	label={
																		<span className="xs-bold">
																			<FormattedMessage id="Delivery.List.Type" />*
																		</span>
																	}
																	items={GlobalStore.CL["generalSubItemType"]}
																	renderer={(item) => {
																		return <span>{item.name_ext}</span>
																	}}
																	column="_id"
																	value={
																		isSafe(OtherDeliveriesStore.subItems[row._id][key].sub_type)
																			? OtherDeliveriesStore.subItems[row._id][key].sub_type
																			: "adigen"
																	}
																	chooseItem={(item) => {
																		{
																			OtherDeliveriesStore.changeSubItem("sub_type", row._id, key, item._id)
																		}
																	}}
																	onChange={() => {
																		if (isSafe(OtherDeliveriesStore.subItems[row._id][key].unit_price)) {
																			OtherDeliveriesStore.subItems[row._id][key].unit_price = ""
																		}
																		if (isSafe(OtherDeliveriesStore.subItems[row._id][key].quantity)) {
																			OtherDeliveriesStore.subItems[row._id][key].quantity = 1
																		}
																		if (isSafe(OtherDeliveriesStore.subItems[row._id][key].drugs)) {
																			OtherDeliveriesStore.subItems[row._id][key].drugs = ""
																		}
																		if (isSafe(OtherDeliveriesStore.subItems[row._id][key].drugsName)) {
																			OtherDeliveriesStore.subItems[row._id][key].drugsName = ""
																		}
																		if (isSafe(OtherDeliveriesStore.subItems[row._id][key].description)) {
																			OtherDeliveriesStore.subItems[row._id][key].description = ""
																		}
																		if (isSafe(OtherDeliveriesStore.subItems[row._id][key].subItemType)) {
																			OtherDeliveriesStore.subItems[row._id][key].subItemType = ""
																		}
																	}}
																/>
															</Grid>
															{isSafe(OtherDeliveriesStore.subItems[row._id][key].sub_type) &&
																OtherDeliveriesStore.subItems[row._id][key].sub_type === "adidrug" && (
																	<Grid item xs={2}>
																		<XsInputSearchFilter
																			api={api.loadDrug}
																			disabled={!OtherDeliveriesStore.isEditable}
																			isValid={isEmpty(OtherDeliveriesStore.subItems[row._id][key].drugs)}
																			label={
																				<span className="xs-bold">
																					<FormattedMessage id="Delivery.List.Item" />*
																				</span>
																			}
																			minLengthForSearch={3}
																			onButtonClick={(value) =>
																				delayedCallback(500, () => OtherDeliveriesStore.searchDrugs(value, key))
																			}
																			data={OtherDeliveriesStore.drugs[key]}
																			onSearchClose={() => (OtherDeliveriesStore.drugs[key] = [])}
																			defaultValue={
																				isSafe(OtherDeliveriesStore.subItems[row._id][key].drugs)
																					? OtherDeliveriesStore.subItems[row._id][key].drugsName
																					: ""
																			}
																			className="xs-search-grid"
																			// inputRenderer={(dataRow) => { return dataRow.code_ext + " " + dataRow.name_ext }}
																			inputRenderer={(obj) =>
																				obj.code_ext +
																				" | " +
																				obj.name_ext +
																				" | " +
																				obj.supplement +
																				" | " +
																				(obj.patient_payment ? obj.patient_payment + " | " : "") +
																				(obj.insurer_payment ? obj.insurer_payment + " | " : "") +
																				obj.atc +
																				" | " +
																				obj.active_ingredient
																			}
																			// valueRenderer={(dataRow) => { return dataRow.code_ext + " " + dataRow.name_ext }}
																			valueRenderer={(obj) =>
																				obj.code_ext +
																				" | " +
																				obj.name_ext +
																				" | " +
																				obj.supplement +
																				" | " +
																				(obj.patient_payment ? obj.patient_payment + " | " : "") +
																				(obj.insurer_payment ? obj.insurer_payment + " | " : "") +
																				obj.atc +
																				" | " +
																				obj.active_ingredient
																			}
																			chooseItem={(value) => {
																				OtherDeliveriesStore.changeSubItem("drugs", row._id, key, value._id)
																				if (isNotEmpty(value.insurer_bs_unit_price)) {
																					OtherDeliveriesStore.subItems[row._id][key].unit_price =
																						value.insurer_bs_unit_price
																					OtherDeliveriesStore.subItems[row._id][key].adorment = value.bs_unit
																				} else {
																					OtherDeliveriesStore.subItems[row._id][key].unit_price = ""
																				}
																			}}
																			onClear={() => {
																				OtherDeliveriesStore.subItems[row._id][key].unit_price = ""
																				OtherDeliveriesStore.subItems[row._id][key].drugsName = ""
																				OtherDeliveriesStore.subItems[row._id][key].drugs = ""
																			}}
																			modalTitle={<FormattedMessage id="Common.label.drugs" />}
																			modalConfig={(textValue, clickHandler) => {
																				return {
																					columnDefs: {
																						code_ext: {
																							title: <FormattedMessage id="Delivery.list.modal.drugCode" />,
																							type: "string",
																							dbName: "code_ext",
																							design: {
																								width: "90px"
																							},
																							filter: {
																								gridWidth: 3,
																								defaultValue: "",
																								renderElement: "input"
																							}
																						},
																						name_ext: {
																							title: <FormattedMessage id="Delivery.list.modal.drugName" />,
																							type: "string",
																							dbName: "search_column",
																							filter: {
																								gridWidth: 9,
																								defaultValue: textValue,
																								renderElement: "input"
																							}
																						},
																						supplement: {
																							title: (
																								<FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />
																							),
																							type: "string",
																							dbName: "supplement",
																							design: {
																								width: "150px"
																							}
																						},
																						patient_payment: {
																							title: (
																								<FormattedMessage id="Patient.form.patientPrescription.drugsTable.patient_payment" />
																							),
																							type: "string",
																							dbName: "patient_payment",
																							design: {
																								width: "80px"
																							}
																						},
																						insurer_payment: {
																							title: (
																								<FormattedMessage id="Patient.form.patientPrescription.drugsTable.insurer_payment" />
																							),
																							type: "string",
																							dbName: "insurer_payment",
																							design: {
																								width: "80px"
																							}
																						},
																						atc: {
																							title: (
																								<FormattedMessage id="Patient.form.patientPrescription.drugsTable.ATC" />
																							),
																							type: "string",
																							dbName: "atc",
																							design: {
																								width: "90px"
																							}
																						}
																					},
																					filterValue: [
																						{
																							associated_column: "only_bsu",
																							predicate: "=",
																							values: [
																								{
																									id_value: 1
																								}
																							]
																						}
																					],
																					options: {
																						// selectRow: true,
																						showCursor: true,
																						onRowClick: (dataRow) => {
																							clickHandler(dataRow)
																						},
																						renderHeaderAsFirstRow: true
																					}
																				}
																			}}
																		/>
																	</Grid>
																)}
															{isSafe(OtherDeliveriesStore.subItems[row._id][key].sub_type) &&
																OtherDeliveriesStore.subItems[row._id][key].sub_type === "adidev" && (
																	<Grid item xs={2}>
																		<XsInputSearchFilter
																			api={api.loadMedDev}
																			disabled={!OtherDeliveriesStore.isEditable}
																			isValid={isEmpty(OtherDeliveriesStore.subItems[row._id][key].drugs)}
																			label={
																				<span className="xs-bold">
																					<FormattedMessage id="Delivery.List.Item" />*
																				</span>
																			}
																			minLengthForSearch={3}
																			onButtonClick={(value) =>
																				delayedCallback(500, () =>
																					OtherDeliveriesStore.searchMedicalDevices(value, key)
																				)
																			}
																			data={OtherDeliveriesStore.medicalDevices[key]}
																			onSearchClose={() => (OtherDeliveriesStore.medicalDevices[key] = [])}
																			defaultValue={
																				isSafe(OtherDeliveriesStore.subItems[row._id][key].drugs)
																					? OtherDeliveriesStore.subItems[row._id][key].drugsName
																					: ""
																			}
																			className="xs-search-grid"
																			inputRenderer={(obj) => {
																				return obj.code_ext + " | " + obj.name_ext + " | " + obj.supplement
																			}}
																			valueRenderer={(obj) => {
																				return obj.code_ext + " | " + obj.name_ext + " | " + obj.supplement
																			}}
																			chooseItem={(value) => {
																				OtherDeliveriesStore.changeSubItem("drugs", row._id, key, value._id)
																			}}
																			onClear={() => {
																				OtherDeliveriesStore.subItems[row._id][key].drugsName = ""
																				OtherDeliveriesStore.subItems[row._id][key].drugs = ""
																			}}
																			modalTitle={<FormattedMessage id="Delivery.list.modal.help.title" />}
																			modalConfig={(textValue, clickHandler) => {
																				return {
																					columnDefs: {
																						code_ext: {
																							title: <FormattedMessage id="Delivery.list.modal.helpCode" />,
																							type: "string",
																							dbName: "code_ext",
																							design: {
																								width: "120px"
																							},
																							filter: {
																								gridWidth: 3,
																								defaultValue: "",
																								renderElement: "input"
																							}
																						},
																						name_ext: {
																							title: <FormattedMessage id="Delivery.list.modal.helpName" />,
																							type: "string",
																							dbName: "search_column",
																							filter: {
																								gridWidth: 9,
																								defaultValue: textValue,
																								renderElement: "input"
																							}
																						},
																						supplement: {
																							title: (
																								<FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />
																							),
																							type: "string",
																							dbName: "supplement",
																							design: {
																								width: "225px"
																							}
																						}
																					},
																					options: {
																						// selectRow: true,
																						showCursor: true,
																						onRowClick: (dataRow) => {
																							clickHandler(dataRow)
																						}
																					}
																				}
																			}}
																		/>
																	</Grid>
																)}
															{isSafe(OtherDeliveriesStore.subItems[row._id][key].sub_type) &&
																OtherDeliveriesStore.subItems[row._id][key].sub_type === "adicntr" && (
																	<Grid item xs={2}>
																		<XsInputSearchFilter
																			api={api.loadCustomItem}
																			disabled={!OtherDeliveriesStore.isEditable}
																			isValid={isEmpty(OtherDeliveriesStore.subItems[row._id][key].drugs)}
																			label={
																				<span className="xs-bold">
																					<FormattedMessage id="Delivery.List.Item" />*
																				</span>
																			}
																			minLengthForSearch={3}
																			onButtonClick={(value) =>
																				delayedCallback(500, () => OtherDeliveriesStore.searchCustomItems(value, key))
																			}
																			data={OtherDeliveriesStore.customItems[key]}
																			onSearchClose={() => (OtherDeliveriesStore.customItems[key] = [])}
																			defaultValue={
																				isSafe(OtherDeliveriesStore.subItems[row._id][key].drugs)
																					? OtherDeliveriesStore.subItems[row._id][key].drugsName
																					: ""
																			}
																			className="xs-search-grid"
																			inputRenderer={(obj) => {
																				return obj.code_ext + " | " + obj.name_ext
																			}}
																			valueRenderer={(obj) => {
																				return obj.code_ext + " | " + obj.name_ext
																			}}
																			chooseItem={(value) => {
																				OtherDeliveriesStore.changeSubItem("drugs", row._id, key, value._id)
																				if (isNotEmpty(row.insurerId)) {
																					api
																						.getDeliveryItemPrice(value._id, {
																							org_unit_id: getSelectedOrgUnitID(),
																							insurer_id: row.insurerId,
																							date: isNotEmpty(row.delivery_date_time)
																								? moment(row.delivery_date_time).format("YYYY-MM-DD")
																								: moment().format("YYYY-MM-DD")
																						})
																						.call()
																						.then((res) => {
																							if (isSafe(res) && isNotEmpty(res.price_in_currency)) {
																								OtherDeliveriesStore.subItems[row._id][key].unit_price =
																									res.price_in_currency
																							} else {
																								if (isNotEmpty(value.price_in_currency)) {
																									OtherDeliveriesStore.subItems[row._id][key].unit_price =
																										value.price_in_currency
																								} else {
																									OtherDeliveriesStore.subItems[row._id][key].unit_price = ""
																								}
																							}
																						})
																				}
																			}}
																			onClear={() => {
																				OtherDeliveriesStore.subItems[row._id][key].unit_price = ""
																				OtherDeliveriesStore.subItems[row._id][key].drugsName = ""
																				OtherDeliveriesStore.subItems[row._id][key].drugs = ""
																			}}
																			modalTitle={<FormattedMessage id="Common.label.addItems" />}
																			modalConfig={(textValue, clickHandler) => {
																				return {
																					columnDefs: {
																						code_ext: {
																							title: <FormattedMessage id="Common.label.code" />,
																							type: "string",
																							dbName: "code_ext",
																							design: {
																								width: "120px"
																							},
																							filter: {
																								gridWidth: 3,
																								defaultValue: "",
																								renderElement: "input"
																							}
																						},
																						name_ext: {
																							title: <FormattedMessage id="Common.label.name" />,
																							type: "string",
																							dbName: "search_column",
																							filter: {
																								gridWidth: 9,
																								defaultValue: textValue,
																								renderElement: "input"
																							}
																						}
																					},
																					options: {
																						// selectRow: true,
																						showCursor: true,
																						onRowClick: (dataRow) => {
																							clickHandler(dataRow)
																						}
																					}
																				}
																			}}
																		/>
																	</Grid>
																)}
															{((isSafe(OtherDeliveriesStore.subItems[row._id][key].sub_type) &&
																(OtherDeliveriesStore.subItems[row._id][key].sub_type === "adigen" ||
																	OtherDeliveriesStore.subItems[row._id][key].sub_type === "drg")) ||
																isEmpty(OtherDeliveriesStore.subItems[row._id][key].sub_type)) && (
																<Grid item xs={2}>
																	<TextField
																		disabled={!OtherDeliveriesStore.isEditable}
																		label={
																			<span className="xs-bold">
																				<FormattedMessage id="Delivery.List.Item" />*
																			</span>
																		}
																		value={
																			isSafe(OtherDeliveriesStore.subItems[row._id][key].subItemType)
																				? OtherDeliveriesStore.subItems[row._id][key].subItemType
																				: ""
																		}
																		onChange={(e) => {
																			OtherDeliveriesStore.changeSubItem("subItemType", row._id, key, e.target.value)
																			// OtherDeliveriesStore.changeSubItem("sub_type", row._id, key, "adigen") //TODO: davat bacha, teraz to je jedno lebo adigen a drg su tie iste podmienky, ale ked bude ine, treba upravit!
																		}}
																		InputLabelProps={{
																			shrink: true
																		}}
																		InputProps={{
																			classes: {
																				root: isEmpty(OtherDeliveriesStore.subItems[row._id][key].subItemType)
																					? "xs-input-error"
																					: ""
																			}
																		}}
																	/>
																</Grid>
															)}
															<Grid item xs={1}>
																<TextField
																	disabled={!OtherDeliveriesStore.isEditable}
																	label={
																		<span className="xs-bold">
																			<FormattedMessage id="Delivery.List.Quantity" />*
																		</span>
																	}
																	value={
																		isSafe(OtherDeliveriesStore.subItems[row._id][key].quantity)
																			? OtherDeliveriesStore.subItems[row._id][key].quantity
																			: ""
																	}
																	onChange={(e) =>
																		OtherDeliveriesStore.changeSubItem("quantity", row._id, key, e.target.value)
																	}
																	InputLabelProps={{
																		shrink: true
																	}}
																	InputProps={{
																		classes: {
																			root: !isNumber(OtherDeliveriesStore.subItems[row._id][key].quantity)
																				? "xs-input-error"
																				: ""
																		}
																	}}
																/>
															</Grid>
															<Grid item xs={2}>
																<TextField
																	disabled={!OtherDeliveriesStore.isEditable}
																	label={
																		<span className="xs-bold">
																			<FormattedMessage id="Delivery.List.UnitPrice" />*
																		</span>
																	}
																	value={
																		isSafe(OtherDeliveriesStore.subItems[row._id][key].unit_price)
																			? OtherDeliveriesStore.subItems[row._id][key].unit_price
																			: ""
																	}
																	onChange={(e) =>
																		OtherDeliveriesStore.changeSubItem("unit_price", row._id, key, e.target.value)
																	}
																	InputLabelProps={{
																		shrink: true
																	}}
																	InputProps={{
																		classes: {
																			root: !isPrice(OtherDeliveriesStore.subItems[row._id][key].unit_price)
																				? "xs-input-error"
																				: ""
																		},
																		endAdornment:
																			isNotEmpty(OtherDeliveriesStore.subItems[row._id][key].unit_price) &&
																			isNotEmpty(OtherDeliveriesStore.subItems[row._id][key].adorment) ? (
																				<InputAdornment
																					position="end"
																					variant="filled"
																					className="xs-unit-price-adorment"
																				>
																					{OtherDeliveriesStore.subItems[row._id][key].adorment}
																				</InputAdornment>
																			) : null
																	}}
																/>
															</Grid>
															<Grid item xs={4}>
																{!(
																	(isSafe(OtherDeliveriesStore.subItems[row._id][key].sub_type) &&
																		(OtherDeliveriesStore.subItems[row._id][key].sub_type === "adigen" ||
																			OtherDeliveriesStore.subItems[row._id][key].sub_type === "drg")) ||
																	isEmpty(OtherDeliveriesStore.subItems[row._id][key].sub_type)
																) && (
																	<TextField
																		disabled={!OtherDeliveriesStore.isEditable}
																		label={<FormattedMessage id="Common.label.description" />}
																		defaultValue={
																			isSafe(OtherDeliveriesStore.subItems[row._id][key].description)
																				? OtherDeliveriesStore.subItems[row._id][key].description
																				: ""
																		}
																		onChange={(e) =>
																			OtherDeliveriesStore.changeSubItem("description", row._id, key, e.target.value)
																		}
																		InputLabelProps={{
																			shrink: true
																		}}
																	/>
																)}
															</Grid>
															{OtherDeliveriesStore.isEditable && (
																<Grid item xs={1}>
																	<Grid container direction="row" justify="flex-end">
																		<Grid item>
																			{countSubItems === index + 1 && (
																				<XsIconButton
																					className="xs-success xs-outline"
																					icon={<i className="fal fa-plus" />}
																					onClick={() => OtherDeliveriesStore.addSubItem(row._id)}
																				/>
																			)}
																			<XsIconButton
																				className="xs-danger xs-outline"
																				icon={<i className="fal fa-times" />}
																				onClick={() => OtherDeliveriesStore.deleteSubItem(row._id, key)}
																			/>
																		</Grid>
																	</Grid>
																</Grid>
															)}
														</Grid>
													</Grid>
												)
											})}
										</Grid>
									</TableCell>
								</TableRow>
							)
						}
					}
				})
			} else {
				this.tableBodyRows.push(
					<TableRow key={1}>
						<TableCell className="textCenter xs-bold" colSpan={15}>
							<FormattedMessage id="Table.NoData" />
						</TableCell>
					</TableRow>
				)
			}
		}
	}

	render() {
		const {order, orderBy} = this.state

		if (this.reloadGrid) {
			this.data = OtherDeliveriesStore.otherDeliveryList
		}

		this.tableBody()

		return (
			<React.Fragment>
				<Grid container direction="column" spacing={16}>
					<OtherDeliveryListFilter />
					{OtherDeliveriesStore.isLoadingData ? (
						<XsLoading />
					) : OtherDeliveriesStore.changedFilters ? (
						<Grid item className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i
								className="far fa-search fa-lg mr-3 pointer"
								onClick={() => {
									if (
										isNotEmpty("otherDeliveryList") &&
										TableStore.tables.hasOwnProperty("otherDeliveryList") &&
										TableStore.tables["otherDeliveryList"].hasOwnProperty("index")
									) {
										delete TableStore.tables["otherDeliveryList"]["index"]
									}

									OtherDeliveriesStore.getFilters(false, false, true)
									OtherDeliveriesStore.page = 0
								}}
							></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</Grid>
					) : (
						<Grid container item>
							<Grid item xs={12}>
								<Table className="xs-customTable">
									<TableHead>
										<TableRow>
											{!OtherDeliveriesStore.isEditable && <TableCell></TableCell>}
											<TableCell>
												<FormattedMessage id="Common.label.date" />
											</TableCell>
											<TableCell
												sortDirection={order}
												onClick={
													!OtherDeliveriesStore.isEditable
														? this.createSortHandler({columnName: "person_name", type: "string"})
														: null
												}
											>
												{order === "asc" || order === "desc" ? (
													<TableSortLabel active={orderBy === "person_name"} direction={order}>
														<FormattedMessage id="Delivery.List.Person" />
													</TableSortLabel>
												) : (
													<FormattedMessage id="Delivery.List.Person" />
												)}
											</TableCell>
											<TableCell>
												<FormattedMessage id="Delivery.List.Age" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Common.label.insurer" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Delivery.List.Diagnosis" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Common.label.delivery" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Delivery.List.SenderType" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Delivery.List.Count" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Common.label.payerType" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Delivery.List.Points" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Delivery.List.Currency" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Delivery.List.InsureeType" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Delivery.List.RefundType" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Delivery.List.Movement" />
											</TableCell>
											{OtherDeliveriesStore.isEditable && (
												<TableCell width="40px" className="xs-align-text"></TableCell>
											)}
											<TableCell className="xs-align-text">
												<FormattedMessage id="Delivery.List.PP" />
											</TableCell>
											{!OtherDeliveriesStore.isEditable && <TableCell className="xs-align-text"></TableCell>}
										</TableRow>
									</TableHead>
									<TableBody>{this.tableBodyRows}</TableBody>
								</Table>
							</Grid>
							<Grid item xs={12}>
								<div className="xs-grid-footer">
									{!OtherDeliveriesStore.isEditable && (
										<TablePagination
											component="div"
											className="xs-table-pagination"
											// count={isSafe(this.data) ? this.data.length : 0}
											count={OtherDeliveriesStore.rowCountFull}
											rowsPerPage={OtherDeliveriesStore.rowsPerPage}
											rowsPerPageOptions={[5, 10, 25, 50]}
											labelRowsPerPage={<FormattedMessage id="Table.Pagination.RowPerPage" />}
											labelDisplayedRows={(pager) =>
												`${pager.from}-${pager.to} ${this.props.intl.formatMessage({id: "Table.Pagination.Of"})} ${
													pager.count
												}`
											}
											page={OtherDeliveriesStore.page}
											onChangePage={this.handleChangePage}
											onChangeRowsPerPage={this.handleChangeRowsPerPage}
											ActionsComponent={TablePaginationActions}
										/>
									)}
								</div>
							</Grid>
						</Grid>
					)}
				</Grid>
				<AddDeliveryDialog />
				<ExportRepairBatchDialog insurers={RegistersCacheStore.insurersRegister} />
			</React.Fragment>
		)
	}
}
