"use strict"

import {observable, action} from "mobx"
import DDaction from "../actions/patientCard"
import UIStore from "../stores/UIStore"
import api from "../actions/api"
import {
	getSelectedOrgUnitID,
	getUserDoctorInfo,
	getUserPersonnelID,
	getSelectedOrgUnit,
	getUser,
	getGWServerRole
} from "../../../global/helpers/actions"
import DataStore from "../stores/DataStore"
import WarningStore from "../../../global/store/WarningStore"
import settings from "../../../global/config/settings"
import moment from "moment"

// import WarningStore from "../../../global/store/WarningStore"

class VaccAllergyDialogStore {
	@observable isOpen = false
	@observable recordId = null
	@observable rcid = null
	@observable vaccAllergy = null
	defaultVacc = []

	@action open(recordId, rcid, symptoms) {
		this.isOpen = true
		this.recordId = recordId
		this.rcid = rcid
		this.vaccAllergy = isSafe(symptoms) && symptoms.length > 0 ? symptoms.map((x) => x.type) : []
	}

	@action onHandleChange(value) {
		this.vaccAllergy = value
	}

	@action save(form) {
		UIStore.isFormSaving = true

		let formVals = form.values()

		// let postObj = {
		// 	_ref: false,
		// 	symptoms: []
		// }

		// let symptom = {
		// 	_ref: false,
		// 	_type: "EHR.Data.Medication.Symptom",
		// 	active: true,
		// 	severity: "Medium",
		// 	type: {
		// 		_id: formVals.symptom,
		// 		_type: "EHR.CL.Medication.Vaccination.Symptom",
		// 		_ref: true
		// 	}
		// }

		// if (isNotEmpty(formVals.allergyType)) {
		// 	symptom["allergy_type"] = formVals.allergyType
		// }
		// if (isNotEmpty(formVals.atcCode)) {
		// 	symptom["atc"] = formVals.atcCode
		// }
		// if (isNotEmpty(formVals.allergenCode)) {
		// 	symptom["allergen"] = formVals.allergenCode
		// }
		// if (isNotEmpty(formVals.dateOfSymptoms)) {
		// 	symptom["validity"] = {
		// 		from: moment(formVals.dateOfSymptoms)
		// 			.startOf("day")
		// 			.format(settings.DB_DATETIME_FORMAT)
		// 	}
		// }
		// if (isNotEmpty(formVals.note)) {
		// 	symptom["note"] = formVals.note
		// }

		// postObj.symptoms.push(symptom)
		//_______________________________________________________________________________

		let postObj = {
			_type: "EHR.API.JSON.Proxy.Medication",
			type_code: "POD",
			medication_type: "ADMVACC",
			patient_id: {
				_type: "Entity.Data.SpecificRel",
				_id: DataStore.patientDTO.get("patientID")
			},
			version: {
				_id: "final",
				_type: "EHR.CL.Record.Version"
			},
			doctor_id: getUserDoctorInfo().id,
			provider_id: getSelectedOrgUnitID(),
			personnel_id: getUserPersonnelID(),
			written_at: moment().format(settings.DB_DATETIME_FORMAT),
			symptoms: [],
			cl_doctor_expertise: getUserDoctorInfo().expertise.cl_id
		}

		if (isNotEmpty(this.recordId)) {
			postObj["parent_record_id"] = this.recordId
		}

		if (isNotEmpty(this.rcid)) {
			postObj["vaccination_ext_id"] = this.rcid
		}

		let symptom = {
			_ref: false,
			_type: "EHR.Data.Medication.Symptom",
			active: true,
			severity: "Medium"
			// type: formVals.symptom
		}

		if (isNotEmpty(formVals.allergyType)) {
			symptom["allergy_type"] = formVals.allergyType
		}
		if (isNotEmpty(formVals.allergyReaction)) {
			symptom["reaction"] = formVals.allergyReaction
		}
		if (isNotEmpty(formVals.atcCode)) {
			symptom["atc"] = formVals.atcCode
		}
		if (isNotEmpty(formVals.allergenCode)) {
			symptom["allergen"] = formVals.allergenCode
		}
		if (isNotEmpty(formVals.dateOfSymptoms)) {
			symptom["validity"] = {
				from: moment(formVals.dateOfSymptoms)
					.startOf("day")
					.format(settings.DB_DATETIME_FORMAT)
			}
		}
		if (isNotEmpty(formVals.note)) {
			symptom["note"] = formVals.note
		}

		if (isNotEmpty(formVals.diagnosis)) {
			postObj["record_diagnoses"] = [
				{
					_ref: false,
					_type: "EHR.Data.Record.Diagnosis",
					active: true,
					item_order: 1,
					life_threatening: false,
					type: "dgn",
					diagnosis_type: {
						_id: "PRIMARY",
						_type: "EHR.CL.Record.DiagnosisType"
					},
					item: {_type: "EHR.CL.Record.Diagnosis", _id: formVals.diagnosis}
				}
			]
		}

		postObj.symptoms.push(symptom)

		api
			.savePrescription([postObj])
			.call()
			.then((response) => {
				let providerID = getSelectedOrgUnitID()

				if (isSafe(response) && response.length > 0) {
					response.forEach((item) => {
						if (
							isSafe(item) &&
							isSafe(item.ixs_signer_data) &&
							isSafe(item.ixs_signer_data.payload) &&
							isSafe(item.ixs_signer_data.payload.text)
						) {
							const selectedOrgUnit = getSelectedOrgUnit()
							const user = getUser()
							const OUPZS = isSafe(selectedOrgUnit)
								? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID")
								: null
							const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
							const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
							const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
							const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

							const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
								? DataStore.patientDTO.get("patient").validInfo.client
								: null
							const JRUZIDObj = isSafe(patientClientInfo.identifiers)
								? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
								: null

							fetch(`${settings.GW_BASE_URL}/WriteEHRExtract`, {
								method: "POST",
								headers: {
									Accept: "application/json",
									"Content-Type": "application/json"
								},
								body: JSON.stringify({
									ClassName: "ZapisReakcieNaOckovanie",
									ExtId: item.record_external_id,
									EHRExtract: item.ixs_signer_data.payload.text,
									TimeCommitted: item.actual_time,
									Environment: getGWServerRole(),
									AmbeePatientId: DataStore.patientDTO.get("patientID"),
									PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
									PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
									AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
									AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
									AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
									AmbeePersonnelId: getUserPersonnelID(),
									OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
									PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
									PersonnelExpertiseJRUZVersion: specVer,
									PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
									RecordSignerData: isSafe(item.record_signer_data)
										? item.record_signer_data.map((x) => {
												return {
													Ref: x._ref,
													Type: x._type,
													RecordClass: x.record_class,
													RecordExternalId: x.record_external_id,
													RecordId: x.record_id,
													QueueId: x.queue_id
												}
										  })
										: null
								})
							})
								.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
								.then((res) => {
									if (isSafe(res) && isNotEmpty(res.ErrorMessage)) {
										if (isSafe(res) && isNotEmpty(res.ErrorMessage)) {
											WarningStore.open("<b>Vytlačte papierový záznam!</b></br>" + res.ErrorMessage)
										}
									}
								})
								.catch(() => {})
						}
					})
				}

				DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
					DataStore.setPatientEHR(overview)
					UIStore.isFormSaving = false
				})
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})

		this.close(form)
	}

	@action close(form) {
		this.isOpen = false
		this.recordId = null
		this.rcid = null
		this.vaccAllergy = null
		if (isSafe(form)) {
			form.reset()
		}
	}
}

var singleton = new VaccAllergyDialogStore()
export default singleton

