import UIStore from "../../../stores/UIStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import PatientAnamnesisStore from "../../../stores/PatientAnamnesisStore"
import DataStore from "../../../stores/DataStore"
import {getSelectedOrgUnitID, getUserPersonnelID} from "../../../../../global/helpers/actions"

export default {
	load(props, onSave) {
		return {
			onSubmit() {
				// special validation here
				UIStore.isFormSaving = true
			},
			onSuccess(form) {
				PatientAnamnesisStore.saveForm(
					form,
					DataStore.patientDTO.get("patientID"),
					getSelectedOrgUnitID(),
					getUserPersonnelID(),
					"EHR.Data.Anamnesis",
					"ANA",
					() => {
						onSave("ANAMNESIS", !form.$("closeForm").value)
					}
				)
				UIStore.overviewTab = "overview" //"anamnesis" - presmerovanie na prehlad
			},
			onError() {
				UIStore.isFormSaving = false
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}
	}
}
