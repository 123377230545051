import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import {Grid /*, InputAdornment*/} from "@material-ui/core"

import "./printTemplatesStyle.less"

import fields from "./anesthesiologicalRecordFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsAutocompleteLocal from "../../../../../../global/ui/xsInput/xsAutocompleteLocal"
import XsCheckbox from "../../../../../../global/ui/xsCheckbox/xsCheckbox"
import RegisterCacheStore from "../../../../../../global/store/RegistersCacheStore"
import XsTextArea from "../../../../../../global/ui/xsTextArea/xsTextArea"

import DataStore from "../../../../stores/DataStore"
import TemplateForm from "../../patientRecordForm/templateForm"
// import {handleInsertHelpersIntoTextArea} from "../../../../../../global/helpers/actions"
import XsSearchDropDownAction from "../../../../../../global/ui/xsDropDownAction/xsSearchDropDownAction"
import XsIconButton from "../../../../../../global/ui/xsButton/xsIconButton"
import AddIcon from "@material-ui/icons/Add"

// import api from "../../../../actions/api"
// import {getSelectedOrgUnitID} from "../../../../../../global/helpers/actions"
// import DataStore from "../../../../stores/DataStore"
// import XsButton from "../../../../../../global/ui/xsButton/xsButton"
// import Button from "@material-ui/core/Button"

@injectIntl
@observer
export default class AnesthesiologicalRecord extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		if (isEmpty(this.form.$("insurerCodeForm").value) && isNotEmpty(this.form.$("insurerCode").value)) {
			this.form.$("insurerCodeForm").value = this.form.$("insurerCode").value
		}

		if (isEmpty(this.form.$("identifierForm").value) && isNotEmpty(this.form.$("identifier").value)) {
			this.form.$("identifierForm").value = this.form.$("identifier").value
		}

		if (isEmpty(this.form.$("nameForm").value) && isNotEmpty(this.form.$("name").value)) {
			this.form.$("nameForm").value = this.form.$("name").value
		}

		if (isEmpty(this.form.$("doctornameForm").value) && isNotEmpty(this.form.$("doctorname").value)) {
			this.form.$("doctornameForm").value = this.form.$("doctorname").value
		}

		if (isEmpty(this.form.$("addressForm").value) && isNotEmpty(this.form.$("address").value)) {
			this.form.$("addressForm").value = this.form.$("address").value
		}

		if (isEmpty(this.form.$("phoneForm").value) && isNotEmpty(this.form.$("phone").value)) {
			this.form.$("phoneForm").value = this.form.$("phone").value
		}

		this.ordinanceTemplates = null
		this.ordinanceCursor = null
		this.templateName = "TMPL:NURSEINSTR"
	}

	componentDidMount() {
		this.loadTextareaTemplates()
	}

	loadTextareaTemplates = async () => {
		await DataStore.loadTemplatesSync(this.templateName)
		this.refreshTextareaTemplates()
	}

	refreshTextareaTemplates = () => {
		this.ordinanceTemplates =
			isSafe(DataStore.templatesAll) &&
			isSafe(DataStore.templatesAll[this.templateName]) &&
			isSafe(DataStore.templatesAll[this.templateName].records) &&
			DataStore.templatesAll[this.templateName].records.length > 0
				? DataStore.templatesAll[this.templateName].records
						.sort((a, b) => {
							if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
							if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
							return 0
						})
						.map((r) => {
							return {
								label: r.name,
								itemData: r.template_stream.replace(/\\\"/g, '"'), // eslint-disable-line
								action: (e) => DataStore.editTemplate(e.currentTarget, r)
							}
						})
				: []
	}

	render() {
		this.refreshTextareaTemplates()

		return (
			<div className="pa-4">
				<Grid container direction="column" align="top">
					<Grid container spacing={8}>
						<Grid item xs={5}>
							<XsInput field={this.form.$("nameForm")} />
						</Grid>
						<Grid item xs={5}>
							<XsInput field={this.form.$("doctornameForm")} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker field={this.form.$("date")} showTimeSelect={false} />
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={2}>
							<XsInput field={this.form.$("identifierForm")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("insurerCodeForm")} />
						</Grid>
						<Grid item xs={8}>
							<XsInput field={this.form.$("addressForm")} />
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={8}>
							<XsInput field={this.form.$("contact_person")} />
						</Grid>
						<Grid item xs={4}>
							<XsInput field={this.form.$("phoneForm")} />
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={2}>
							<XsAutocompleteLocal
								field={this.form.$("diagnosis")}
								minLengthForSearch="3"
								filterValue="code_ext"
								clearText
								data={RegisterCacheStore.diagnosisRegister}
								inputRenderer={(obj, menuItem) => {
									return obj.code_ext + (menuItem ? " " + obj.name_ext : "")
								}}
								saveValue={(obj) => obj.code_ext}
							/>
						</Grid>
						<Grid item xs={6}>
							<XsInput field={this.form.$("delivery")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("time_from")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("time_to")} />
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={2}>
							<XsInput field={this.form.$("pending_delivery")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("ASA")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("SpO")} />
						</Grid>
						<Grid item xs={6}>
							<XsInput field={this.form.$("other")} />
						</Grid>
					</Grid>
					<Grid container spacing={8}>
						<Grid item container xs alignItems="center">
							<Grid item className="pr-2">
								Alergie:
							</Grid>
							<XsCheckbox
								field={this.form.$("alergy_yes")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("alergy_no").value = false
									}
									this.form.$("alergy_yes").value = true
								}}
							/>
							<XsCheckbox
								field={this.form.$("alergy_no")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("alergy_yes").value = false
									}
									this.form.$("alergy_no").value = true
								}}
							/>
						</Grid>
						<Grid item container xs alignItems="center">
							<Grid item className="pr-2">
								Anestezovaný:
							</Grid>
							<XsCheckbox
								field={this.form.$("anesthesiological_before_yes")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("anesthesiological_before_no").value = false
									}
									this.form.$("anesthesiological_before_yes").value = true
								}}
							/>
							<XsCheckbox
								field={this.form.$("anesthesiological_before_no")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("anesthesiological_before_yes").value = false
									}
									this.form.$("anesthesiological_before_no").value = true
								}}
							/>
						</Grid>
						<Grid item container xs alignItems="center">
							<Grid item className="pr-2">
								Komplikácie:
							</Grid>
							<XsCheckbox
								field={this.form.$("complication_yes")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("complication_no").value = false
									}
									this.form.$("complication_yes").value = true
								}}
							/>
							<XsCheckbox
								field={this.form.$("complication_no")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("complication_yes").value = false
									}
									this.form.$("complication_no").value = true
								}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2} className="xs-bold">
							Premedikácia:
						</Grid>
						<Grid item xs={5} container alignItems="center" className="xs-input-with-checkbox">
							<Grid item xs={2}>
								<XsCheckbox field={this.form.$("seduxen")} />
							</Grid>
							<Grid item xs={8}>
								<XsInput
									field={this.form.$("seduxen_quantity")}
									disabled={!this.form.$("seduxen").value}
									// InputProps={{
									// 	endAdornment: (
									// 		<InputAdornment className="xs-endUnit" position="end">
									// 			mg
									// 		</InputAdornment>
									// 	)
									// }}
								/>
							</Grid>
						</Grid>
						<Grid item xs={5} container alignItems="center" className="xs-input-with-checkbox">
							<Grid item xs={2}>
								<XsCheckbox field={this.form.$("dolsin")} />
							</Grid>
							<Grid item xs={8}>
								<XsInput
									field={this.form.$("dolsin_quantity")}
									disabled={!this.form.$("dolsin").value}
									// InputProps={{
									// 	endAdornment: (
									// 		<InputAdornment className="xs-endUnit" position="end">
									// 			mg
									// 		</InputAdornment>
									// 	)
									// }}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}></Grid>
						<Grid item xs={5} container alignItems="center" className="xs-input-with-checkbox">
							<Grid item xs={2}>
								<XsCheckbox field={this.form.$("fentanyl")} />
							</Grid>
							<Grid item xs={8}>
								<XsInput
									field={this.form.$("fentanyl_quantity")}
									disabled={!this.form.$("fentanyl").value}
									// InputProps={{
									// 	endAdornment: (
									// 		<InputAdornment className="xs-endUnit" position="end">
									// 			ml
									// 		</InputAdornment>
									// 	)
									// }}
								/>
							</Grid>
						</Grid>
						<Grid item xs={5} container alignItems="center" className="xs-input-with-checkbox">
							<Grid item xs={2}>
								<XsCheckbox field={this.form.$("fraxiparine")} />
							</Grid>
							<Grid item xs={8}>
								<XsInput
									field={this.form.$("fraxiparine_quantity")}
									disabled={!this.form.$("fraxiparine").value}
									// InputProps={{
									// 	endAdornment: (
									// 		<InputAdornment className="xs-endUnit" position="end">
									// 			s.c.
									// 		</InputAdornment>
									// 	)
									// }}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}></Grid>
						<Grid item xs={5} container alignItems="center" className="xs-input-with-checkbox">
							<Grid item xs={2}>
								<XsCheckbox field={this.form.$("atropin")} />
							</Grid>
							<Grid item xs={8}>
								<XsInput
									field={this.form.$("atropin_quantity")}
									disabled={!this.form.$("atropin").value}
									// InputProps={{
									// 	endAdornment: (
									// 		<InputAdornment className="xs-endUnit" position="end">
									// 			mg
									// 		</InputAdornment>
									// 	)
									// }}
								/>
							</Grid>
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox field={this.form.$("i_m")} />
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox field={this.form.$("i_v")} />
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox field={this.form.$("p_o")} />
						</Grid>
						<Grid item xs={1}>
							<XsCheckbox field={this.form.$("ina")} />
						</Grid>
					</Grid>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2} className="xs-bold">
							Anestézia:
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("anesthesia_together")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("anesthesia_region")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("anesthesia_anelgesic")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("anesthesia_other")} />
						</Grid>
					</Grid>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}>
							Spôsob:
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("anesthesia_method_iv")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("anesthesia_method_mask")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("anesthesia_method_et")} />
						</Grid>
						<Grid item xs={4}>
							<XsCheckbox field={this.form.$("anesthesia_method_subarachnoid")} />
						</Grid>
						<Grid item xs={2}></Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("anesthesia_method_epidural")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("anesthesia_method_tranqal")} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("anesthesia_method_other")} />
						</Grid>
					</Grid>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}></Grid>
						<Grid item xs={5} container alignItems="center" className="xs-input-with-checkbox">
							<Grid item xs={2}>
								<XsCheckbox field={this.form.$("midazolam")} />
							</Grid>
							<Grid item xs={8}>
								<XsInput
									field={this.form.$("midazolam_quantity")}
									disabled={!this.form.$("midazolam").value}
									// InputProps={{
									// 	endAdornment: (
									// 		<InputAdornment className="xs-endUnit" position="end">
									// 			mg
									// 		</InputAdornment>
									// 	)
									// }}
								/>
							</Grid>
						</Grid>
						<Grid item xs={5} container alignItems="center" className="xs-input-with-checkbox">
							<Grid item xs={2}>
								<XsCheckbox field={this.form.$("propofol")} />
							</Grid>
							<Grid item xs={8}>
								<XsInput
									field={this.form.$("propofol_quantity")}
									disabled={!this.form.$("propofol").value}
									// InputProps={{
									// 	endAdornment: (
									// 		<InputAdornment className="xs-endUnit" position="end">
									// 			mg
									// 		</InputAdornment>
									// 	)
									// }}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}></Grid>
						<Grid item xs={5} container alignItems="center" className="xs-input-with-checkbox">
							<Grid item xs={2}>
								<XsCheckbox field={this.form.$("hct")} />
							</Grid>
							<Grid item xs={8}>
								<XsInput
									field={this.form.$("hct_quantity")}
									disabled={!this.form.$("hct").value}
									// InputProps={{
									// 	endAdornment: (
									// 		<InputAdornment className="xs-endUnit" position="end">
									// 			mg
									// 		</InputAdornment>
									// 	)
									// }}
								/>
							</Grid>
						</Grid>
						<Grid item xs={5} container alignItems="center" className="xs-input-with-checkbox">
							<Grid item xs={2}>
								<XsCheckbox field={this.form.$("sufetna")} />
							</Grid>
							<Grid item xs={8}>
								<XsInput
									field={this.form.$("sufetna_quantity")}
									disabled={!this.form.$("sufetna").value}
									// InputProps={{
									// 	endAdornment: (
									// 		<InputAdornment className="xs-endUnit" position="end">
									// 			m
									// 		</InputAdornment>
									// 	)
									// }}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}></Grid>
						<Grid item xs={5} container alignItems="center" className="xs-input-with-checkbox">
							<Grid item xs={2}>
								<XsCheckbox field={this.form.$("soverane")} />
							</Grid>
							<Grid item xs={8}>
								<XsInput
									field={this.form.$("soverane_quantity")}
									disabled={!this.form.$("soverane").value}
									// InputProps={{
									// 	endAdornment: (
									// 		<InputAdornment className="xs-endUnit" position="end">
									// 			mg
									// 		</InputAdornment>
									// 	)
									// }}
								/>
							</Grid>
						</Grid>
						<Grid item xs={5} container alignItems="center" className="xs-input-with-checkbox">
							<Grid item xs={2}>
								<XsCheckbox field={this.form.$("novalgin")} />
							</Grid>
							<Grid item xs={8}>
								<XsInput
									field={this.form.$("novalgin_quantity")}
									disabled={!this.form.$("novalgin").value}
									// InputProps={{
									// 	endAdornment: (
									// 		<InputAdornment className="xs-endUnit" position="end">
									// 			i.v.
									// 		</InputAdornment>
									// 	)
									// }}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}></Grid>
						<Grid item xs={5} container alignItems="center" className="xs-input-with-checkbox">
							<Grid item xs={2}>
								<XsCheckbox field={this.form.$("rocuronium")} />
							</Grid>
							<Grid item xs={8}>
								<XsInput
									field={this.form.$("rocuronium_quantity")}
									disabled={!this.form.$("rocuronium").value}
									// InputProps={{
									// 	endAdornment: (
									// 		<InputAdornment className="xs-endUnit" position="end">
									// 			mg
									// 		</InputAdornment>
									// 	)
									// }}
								/>
							</Grid>
						</Grid>
						<Grid item xs={5} container alignItems="center" className="xs-input-with-checkbox">
							<Grid item xs={2}>
								<XsCheckbox field={this.form.$("mgso")} />
							</Grid>
							<Grid item xs={8}>
								<XsInput
									field={this.form.$("mgso_quantity")}
									disabled={!this.form.$("mgso").value}
									// InputProps={{
									// 	endAdornment: (
									// 		<InputAdornment className="xs-endUnit" position="end">
									// 			m
									// 		</InputAdornment>
									// 	)
									// }}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}></Grid>
						<Grid item xs={5} container alignItems="center" className="xs-input-with-checkbox">
							<Grid item xs={2}>
								<XsCheckbox field={this.form.$("ephedrin")} />
							</Grid>
							<Grid item xs={8}>
								<XsInput
									field={this.form.$("ephedrin_quantity")}
									disabled={!this.form.$("ephedrin").value}
									// InputProps={{
									// 	endAdornment: (
									// 		<InputAdornment className="xs-endUnit" position="end">
									// 			mg
									// 		</InputAdornment>
									// 	)
									// }}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid container spacing={8} alignItems="center">
						<Grid item className="pr-2">
							Priebeh anestézie:
						</Grid>
						<XsCheckbox
							field={this.form.$("course_anesthesia_calm")}
							onChange={(target) => {
								if (target.value) {
									this.form.$("course_anesthesia_other").value = false
								}
								this.form.$("course_anesthesia_calm").value = true
							}}
						/>
						<XsCheckbox
							field={this.form.$("course_anesthesia_other")}
							onChange={(target) => {
								if (target.value) {
									this.form.$("course_anesthesia_calm").value = false
								}
								this.form.$("course_anesthesia_other").value = true
							}}
						/>
					</Grid>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={12} className="pr-2 xs-bold">
							Stav pri prepustení z operačnej sály (zákrok. miestností):
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox
								field={this.form.$("wakes_up")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("unconscious").value = false
										this.form.$("ii").value = false
										this.form.$("iii1").value = false
										this.form.$("iii2").value = false
										this.form.$("iii3").value = false
									}

									this.form.$("wakes_up").value = true
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox
								field={this.form.$("unconscious")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("wakes_up").value = false
										this.form.$("ii").value = false
										this.form.$("iii1").value = false
										this.form.$("iii2").value = false
										this.form.$("iii3").value = false
									}

									this.form.$("unconscious").value = true
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox
								field={this.form.$("ii")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("wakes_up").value = false
										this.form.$("unconscious").value = false
										this.form.$("iii1").value = false
										this.form.$("iii2").value = false
										this.form.$("iii3").value = false
									}

									this.form.$("ii").value = true
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox
								field={this.form.$("iii1")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("wakes_up").value = false
										this.form.$("unconscious").value = false
										this.form.$("ii").value = false
										this.form.$("iii2").value = false
										this.form.$("iii3").value = false
									}

									this.form.$("iii1").value = true
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox
								field={this.form.$("iii2")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("wakes_up").value = false
										this.form.$("unconscious").value = false
										this.form.$("ii").value = false
										this.form.$("iii1").value = false
										this.form.$("iii3").value = false
									}

									this.form.$("iii2").value = true
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox
								field={this.form.$("iii3")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("wakes_up").value = false
										this.form.$("unconscious").value = false
										this.form.$("ii").value = false
										this.form.$("iii1").value = false
										this.form.$("iii2").value = false
									}

									this.form.$("iii3").value = true
								}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}>
							Celkový stav:
						</Grid>
						<Grid item className="width125">
							Dýchanie
						</Grid>
						<Grid item>
							<XsCheckbox
								field={this.form.$("breath_without_defect")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("breath_defect").value = false
									}

									this.form.$("breath_without_defect").value = true
								}}
							/>
						</Grid>
						<Grid item>
							<XsCheckbox
								field={this.form.$("breath_defect")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("breath_without_defect").value = false
									}

									this.form.$("breath_defect").value = true
								}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}></Grid>
						<Grid item className="width125">
							Krvný obeh
						</Grid>
						<Grid item>
							<XsCheckbox
								field={this.form.$("blood_without_defect")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("blood_defect").value = false
									}

									this.form.$("blood_without_defect").value = true
								}}
							/>
						</Grid>
						<Grid item>
							<XsCheckbox
								field={this.form.$("blood_defect")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("blood_without_defect").value = false
									}

									this.form.$("blood_defect").value = true
								}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}></Grid>
						<Grid item className="width125">
							Vzduchovod
						</Grid>
						<Grid item>
							<XsCheckbox
								field={this.form.$("air_duct_oral")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("air_duct_nose").value = false
										this.form.$("air_duct_not_implement").value = false
									}

									this.form.$("air_duct_oral").value = true
								}}
							/>
						</Grid>
						<Grid item>
							<XsCheckbox
								field={this.form.$("air_duct_nose")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("air_duct_oral").value = false
										this.form.$("air_duct_not_implement").value = false
									}

									this.form.$("air_duct_nose").value = true
								}}
							/>
						</Grid>
						<Grid item>
							<XsCheckbox
								field={this.form.$("air_duct_not_implement")}
								onChange={(target) => {
									if (target.value) {
										this.form.$("air_duct_nose").value = false
										this.form.$("air_duct_oral").value = false
									}

									this.form.$("air_duct_not_implement").value = true
								}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={8} alignItems="center">
						<Grid item>Počas zákroku dostal i.v.:</Grid>
						<Grid item>
							<XsCheckbox field={this.form.$("get_i_v")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput
								field={this.form.$("get_i_v_quantity")}
								// InputProps={{
								//     endAdornment: (
								//         <InputAdornment className="xs-endUnit" position="end">
								//             m
								//         </InputAdornment>
								//     )
								// }}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={12} className="xs-bold">
							Pokyny pre sestru:
						</Grid>
						<Grid item xs={12}>
							<XsTextArea
								field={this.form.$("instruction_nurse")}
								customToolbarHelpers={
									<div className="xs-textarea-helpers">
										<XsSearchDropDownAction
											placeholder={"Pomôcky"}
											items={this.ordinanceTemplates}
											actionIconClassName="fal fa-pencil-alt"
											className="xs-textarea-dropdown"
											onChange={(data) => {
												if (isSafe(data)) {
													// if (isNotEmpty(this.form.$("instruction_nurse").value) && this.ordinanceCursor) {
													// 	handleInsertHelpersIntoTextArea(
													// 		this.form,
													// 		"instruction_nurse",
													// 		this.ordinanceCursor.cursor,
													// 		data.itemData
													// 	)
													// } else {
														this.form.$("instruction_nurse").value += data.itemData
													// }
													this.ordinanceCursor = null
												}
											}}
										/>
									</div>
								}
								customToolbar={
									<div className="xs-textarea-helpers-other">
										<XsIconButton
											className="xs-default xs-outline xs-quil-helper-btn"
											icon={<AddIcon />}
											onClick={(e) => {
												DataStore.templateAnchorEl = e.currentTarget
												DataStore.openTemplateForm()
											}}
										/>
									</div>
								}
								onFocus={(data) => {
									if (data && (isNotSafe(this.ordinanceCursor) || this.ordinanceCursor.cursor != data.cursor)) {
										this.ordinanceCursor = {
											cursor: data.cursor
										}
									}
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
				<TemplateForm type={this.templateName} />
			</div>
		)
	}
}
