import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import renderHTML from "react-render-html"
import "./xsDialog.less"

import XsButton from "../xsButton/xsButton"

import WarningStore from "../../store/WarningStore"

@observer
export default class XsWarningDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				aria-describedby="alert-dialog-description"
				open={WarningStore.isOpen}
				onEscapeKeyDown={() => WarningStore.close()}
				maxWidth="md"
				className="xs-warning-dialog"
			>
				<DialogTitle className={WarningStore.isError ? "xs-danger" : "xs-warning"}>
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-exclamation-triangle fa-2x"></i>
						</div>
						<div className="xs-header-title">{<FormattedMessage id="Common.label.warning" />}</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					{isEmpty(WarningStore.message) ? "" : renderHTML(WarningStore.message)}
				</DialogContent>
				<DialogActions className="xs-footer">
					<XsButton
						className={WarningStore.isError ? "xs-danger" : "xs-warning"}
						text={<FormattedMessage id="Common.label.ok" />}
						onClick={() => WarningStore.close()}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
