import React, {Component} from "react"
import {observer} from "mobx-react"
import {TextField, InputAdornment} from "@material-ui/core"
import XsIconButton from "../xsButton/xsIconButton"
import DotsIcon from "@material-ui/icons/MoreHoriz"
import Paper from "@material-ui/core/Paper"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import MenuItem from "@material-ui/core/MenuItem"
import AutocompleteModal from "./autocompleModal"

import "./xsInput.less"

@observer
export default class XsInputSearchFilter extends Component {
	constructor(props) {
		super(props)
		this.state = {
			modalIsOpen: false
		}
		this.inputText = ""
	}

	textFieldInputReference

	handleClick = () => {
		this.setState({modalIsOpen: true})
	}

	handleChoose = (value) => {
		const {chooseItem, onSearchClose, valueRenderer} = this.props
		chooseItem(value)
		if (isSafe(valueRenderer) && typeof valueRenderer === "function") {
			this.textFieldInputReference.value = valueRenderer(value)
		} else {
			this.textFieldInputReference.value = value[this.props.value]
		}
		this.inputText = ""
		onSearchClose()
	}

	handleClickAway = () => {
		this.inputText = this.textFieldInputReference.value
		this.props.onSearchClose()
	}

	onChange = (value) => {
		const {onButtonClick, onChange, onSearchClose, minLengthForSearch = 3} = this.props

		this.inputText = value

		if (isSafe(value) && value.length >= minLengthForSearch && typeof onButtonClick === "function") {
			onButtonClick(value)
		} else {
			onSearchClose()
		}

		if (typeof onChange === "function") {
			onChange(value)
		}
	}

	onClear = (value) => {
		if (value == "") {
			if (isSafe(this.props.onClear) && typeof this.props.onClear === "function") {
				this.props.onClear()
			}
		}
	}

	handleModalClose = () => {
		this.setState({modalIsOpen: false})
	}

	handleModalRowClick = (dataRow) => {
		this.handleChoose(dataRow)
		this.handleModalClose()
	}

	clearText = () => {
		if (!this.props.disabled) {
			this.inputText = ""

			if (isSafe(this.textFieldInputReference)) {
				this.textFieldInputReference.value = ""
			}

			if (typeof this.props.onClear === "function") this.props.onClear()
		}
	}

	render() {
		const {
			data,
			defaultValue = "",
			inputRenderer,
			className = "",
			label,
			value,
			isValid = false,
			disabled,
			onBlur
		} = this.props

		// const value = ""
		return (
			<div className="xs-inputFilter-cover">
				<TextField
					label={label}
					InputLabelProps={{
						shrink: true
					}}
					disabled={disabled}
					className={isSafe(this.props.clearText) ? "xs-input-search-filter with-clear" : "xs-input-search-filter"}
					defaultValue={defaultValue}
					value={value}
					inputProps={{
						ref: (input) => (this.textFieldInputReference = input)
					}}
					InputProps={{
						classes: {
							root: isValid ? `${className} xs-input-error` : className
						},
						endAdornment: (
							<InputAdornment position="end" variant="filled" className="xs-align endAbdornment">
								{isSafe(this.props.clearText) && (
									<XsIconButton
										className={isEmpty(label) ? "xs-clear-icon" : "xs-clear-icon-with-label"}
										icon={<i className="fal fa-times fa-lg"></i>}
										onClick={() => this.clearText()}
									/>
								)}
								{isSafe(this.props.modalConfig) && typeof this.props.modalConfig === "function" && (
									<XsIconButton
										className={isEmpty(label) ? "xs-menu-icon" : "xs-menu-icon-with-label"}
										icon={<DotsIcon />}
										disabled={disabled}
										onClick={() => this.handleClick(value)}
									/>
								)}
							</InputAdornment>
						)
					}}
					onBlur={(e) => {
						if (typeof onBlur === "function") {
							onBlur(e)
						}
					}}
					onChange={(e) => {
						this.onClear(e.target.value), this.onChange(e.target.value)
					}}
				/>
				{/* <XsIconButton
					className={isEmpty(label) ? "xs-menu-icon" : "xs-menu-icon-with-label"}
					icon={<DotsIcon />}
					disabled={disabled}
					onClick={() => this.handleClick(value)}
				/> */}
				{isSafe(this.textFieldInputReference) &&
				isSafe(
					this.textFieldInputReference.value
				) /*&& this.textFieldInputReference.value.length >= minLengthForSearch*/ &&
					isSafe(data) &&
					data.length > 0 && (
						<ClickAwayListener onClickAway={this.handleClickAway}>
							<Paper elevation={10} className="xs-filter-menu xs-input-search-fitContent">
								{data.map((option, idx) => (
									<MenuItem key={idx} component="div" onClick={() => this.handleChoose(option)}>
										{isSafe(inputRenderer) && typeof inputRenderer === "function" && inputRenderer(option)}
									</MenuItem>
								))}
							</Paper>
						</ClickAwayListener>
					)}
				{isSafe(this.props.modalConfig) && typeof this.props.modalConfig === "function" && (
					<AutocompleteModal
						isOpen={this.state.modalIsOpen}
						onClose={this.handleModalClose}
						modalTitle={this.props.modalTitle}
						uri={this.props.api("", this.apiCallType).getPath()}
						searchValue={value} //{this.inputText}
						config={this.props.modalConfig(this.inputText, this.handleModalRowClick)} //{this.props.modalConfig(this.inputText, this.handleModalRowClick)}
					/>
				)}
			</div>
		)
	}
}
