"use strict"

import {observable} from "mobx"

class TableStore {
	@observable tables = {}
}

var singleton = new TableStore()
export default singleton
