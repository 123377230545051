import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import renderHTML from "react-render-html"

import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import {Dialog, DialogContent, DialogTitle, DialogActions, Checkbox, FormControlLabel} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import GetPatientSummaryEDSStore from "../../../stores/GetPatientSummaryEDSStore"

@observer
export default class GetPatientSummaryEDSDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsGetPatientSummaryEDSDialog"
				open={GetPatientSummaryEDSStore.isOpen}
				onClose={() => GetPatientSummaryEDSStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
				className="xs-base-dialog"
			>
				<DialogTitle className={isNotEmpty(GetPatientSummaryEDSStore.errorMessage) ? "xs-danger" : ""}>
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-reply fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Patient.dialog.summaryEDS.title" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					{GetPatientSummaryEDSStore.step === 1 ? (
						<div className="xs-row xs-justify">
							<FormControlLabel
								control={
									<Checkbox
										// className="xs-orgunit-checkbox"
										checked={GetPatientSummaryEDSStore.isChecked}
										onChange={() => (GetPatientSummaryEDSStore.isChecked = !GetPatientSummaryEDSStore.isChecked)}
									/>
								}
								label={<FormattedMessage id="Common.label.cancelRecord" />}
							/>
						</div>
					) : GetPatientSummaryEDSStore.isFetching ? (
						<XsLoading />
					) : (
						<div>
							{isNotEmpty(GetPatientSummaryEDSStore.errorMessage) ? (
								GetPatientSummaryEDSStore.errorMessage
							) : GetPatientSummaryEDSStore.notResponse ? (
								<div>
									<FormattedMessage id="Common.not.find.response" />
								</div>
							) : (
								<div>
									{isNotEmpty(GetPatientSummaryEDSStore.htmlData) ? renderHTML(GetPatientSummaryEDSStore.htmlData) : ""}
								</div>
							)}
						</div>
					)}
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className={isNotEmpty(GetPatientSummaryEDSStore.errorMessage) ? "xs-danger" : "xs-default xs-outline"}
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => GetPatientSummaryEDSStore.close()}
					/>
					{isEmpty(GetPatientSummaryEDSStore.errorMessage) && (
						<XsButton
							className="xs-success"
							icon={<i className="fal fa-reply" />}
							text={<FormattedMessage id="Common.label.ok" />}
							onClick={() => GetPatientSummaryEDSStore.search()}
						/>
					)}
				</DialogActions>
			</Dialog>
		)
	}
}
