import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import "./invoiceSettings.less"
import fields from "./invoiceSettingsDetailFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

import GlobalStore from "../../../../../global/store/GlobalStore"
import UIStore from "../../../stores/UIStore"
import InvoiceSettingsStore from "../../../stores/InvoiceSettingsStore"
import {Grid} from "@material-ui/core"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"

@injectIntl
@observer
export default class InvoiceSettingsDetailDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {
				InvoiceSettingsStore.isDetailSaving = true
			},
			onSuccess(form) {
				InvoiceSettingsStore.saveDetail(form.values(), form)
			},
			onError() {
				UIStore.isFormSaving = false
				InvoiceSettingsStore.isDetailSaving = false
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	form

	render() {
		if (isNotEmpty(InvoiceSettingsStore.detail_id)) {
			this.form.$("detail_name").value = InvoiceSettingsStore.detail_name
			this.form.$("detail_customer").value = InvoiceSettingsStore.detail_customer
			this.form.$("detail_constant_symbol").value = InvoiceSettingsStore.detail_constant_symbol
			this.form.$("detail_specific_symbol").value = InvoiceSettingsStore.detail_specific_symbol
			this.form.$("detail_description").value = InvoiceSettingsStore.detail_description
		}

		return (
			<Dialog
				id="xsInvoiceSettingsDetailDialog"
				open={InvoiceSettingsStore.isDetailOpen}
				onClose={() => InvoiceSettingsStore.close()}
				disableBackdropClick={true}
				classes={{
					paper: "xs-paper-dialog"
				}}
				maxWidth="md"
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-base">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-search fa-2x" />
						</div>
						<div className="xs-header-title">
							Detail
							{/* <FormattedMessage id="Capitation.form.ExportBatch" /> */}
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					{InvoiceSettingsStore.isDetailLoading ? (
						<XsLoading />
					) : (
						<Grid container direction="column">
							<Grid item xs={12}>
								<Grid container direction="row" spacing={8}>
									<Grid item xs={6}>
										<XsInput field={this.form.$("detail_name")} />
									</Grid>
									<Grid item xs={6}>
										<SearchSelect field={this.form.$("detail_customer")} items={InvoiceSettingsStore.customers} />
									</Grid>
								</Grid>
								<Grid container direction="row" spacing={8}>
									<Grid item xs={6}>
										<XsInput field={this.form.$("detail_constant_symbol")} />
									</Grid>
									<Grid item xs={6}>
										<XsInput field={this.form.$("detail_specific_symbol")} />
									</Grid>
								</Grid>
								<Grid container direction="row">
									<Grid item xs={12}>
										<XsInput field={this.form.$("detail_description")} />
									</Grid>
									<Grid />
								</Grid>
							</Grid>
						</Grid>
					)}
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Patient.form.patientrecord.close" />}
						onClick={() => InvoiceSettingsStore.close(this.form)}
					/>
					<XsButton
						className={"xs-success"}
						icon={<i className="fal fa-save" />}
						text={<FormattedMessage id="Common.label.save" />}
						onClick={(e) => {
							this.form.validate().then(({isValid}) => {
								if (isValid) {
									this.form.onSubmit(e)
								} else {
									GlobalStore.setNotificationMessage("Patient.form.patientPrescription.invalidForm")
								}
							})
						}}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
