import React, {useState} from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import settings from "../../../../../../global/config/settings"
import moment from "moment"
import Chip from "@material-ui/core/Chip"
import api from "../../../../actions/api"
import "./sicknessAbsRow.less"
import XsExpansionPanel from "../../../../../../global/ui/xsExpansionPanel/xsExpansionPanel"
import ReactTooltip from "react-tooltip"

import UIStore from "../../../../stores/UIStore"

function SicknessAbsRow(props) {
	const {item, loadDeliveries, forceUpdate, intl} = props

	const dateFrom =
		item.sickness_abs && item.sickness_abs.validity && item.sickness_abs.validity.from
			? moment(item.sickness_abs.validity.from, settings.DB_DATETIME_FORMAT).format(
					intl.formatMessage({id: "Application.moment.dateformat"})
			  )
			: ""
	const dateTo =
		item.sickness_abs && item.sickness_abs.validity && item.sickness_abs.validity.to
			? moment(item.sickness_abs.validity.to, settings.DB_DATETIME_FORMAT).format(
					intl.formatMessage({id: "Application.moment.dateformat"})
			  )
			: ""

	let [forceOpenExp, setForceOpenExp] = useState(false)
	let [deliveriesLoaded, setDeliveriesLoaded] = useState(false)
	let [deliveries, setDeliveries] = useState()

	if (loadDeliveries && deliveriesLoaded === false) {
		setDeliveriesLoaded(true)
		api
			.loadRecord(item.record_id, "L3")
			.call()
			.then((response) => {
				let deliveries = response.general_items.filter(
					(i) => isSafe(i.item) && i.item._type === "EHR.CL.Bill.SK.Delivery"
				)

				setDeliveries(
					deliveries.map((val, key) => {
						return (
							<React.Fragment key={key}>
								<Chip
									key={key}
									label={val.item.code_ext}
									className="mr-1"
									data-tip
									data-for={`deliveryTip${item.record_id}${key}`}
								/>
								<ReactTooltip id={`deliveryTip${item.record_id}${key}`}>{val.item.name_ext}</ReactTooltip>
							</React.Fragment>
						)
					})
				)

				setForceOpenExp(true)
				setForceOpenExp(false)
			})
	} else if (loadDeliveries && deliveriesLoaded === true && forceOpenExp === false) {
		setForceOpenExp(true)
	}

	return (
		<XsExpansionPanel glOpen={UIStore.isOpenAllRecordsInEHROverview} forceOpen={forceOpenExp} forceUpdate={forceUpdate}>
			<div className="sickessAbsRow">
				{item.sickness_abs && item.sickness_abs.validity && (
					<div>{`${dateFrom} ${dateFrom && dateTo ? "-" : ""} ${dateTo}`}</div>
				)}
				{item.sickness_abs && item.sickness_abs.number && (
					<div>
						<span className="title">
							<FormattedMessage id="Common.label.number" />:
						</span>{" "}
						<b>{item.sickness_abs.number}</b>
					</div>
				)}
				{item.sickness_abs && item.sickness_abs.work_capable_since && item.sickness_abs.sub_type == "MCLAIM" && (
					<div>
						<span className="title">
							<FormattedMessage id="Patient.form.patientsicknessabs.childBirth" />:
						</span>{" "}
						<b>
							{moment(item.sickness_abs.work_capable_since, settings.DB_DATE_FORMAT).format(
								intl.formatMessage({id: "Application.moment.dateformat"})
							)}
						</b>
					</div>
				)}
				{item.diagnosis && (
					<div>
						<span className="title">
							<FormattedMessage id="Patient.form.patientrecord.diagnosis" />:
						</span>{" "}
						<b>{`${item.diagnosis_code_ext} - ${item.diagnosis_name_ext}`}</b>
					</div>
				)}
				{item.sickness_abs && item.sickness_abs.description_text && (
					<div>
						<span className="title">
							<FormattedMessage id="Patient.form.patientPrescription.note" />:
						</span>{" "}
						<b>{item.sickness_abs.description_text}</b>
					</div>
				)}
				{isSafe(deliveries) && (
					<div>
						<div>
							<FormattedMessage id="Patient.form.patientrecord.deliveries" />:
						</div>
						{deliveries}
					</div>
				)}
			</div>
		</XsExpansionPanel>
	)
}

export default injectIntl(observer(SicknessAbsRow))
