export const saveBinding = {
	load: {
		storeKey: "PatientContract",
		params: ["contractId"],
		type: "Entity.Data.EmploymentContract"
	},
	scheme: {
		validity: {
			object: {
				scheme: {
					from: {
						field: "from"
					},
					to: {
						field: "to"
					}
				}
			}
		},
		employee: {
			object: {
				descriptor: {
					_type: "Entity.Data.Employee"
				},
				scheme: {
					_id: {
						relation: "@employeeId"
					}
				}
			}
		},
		org_unit: {
			object: {
				descriptor: {
					_type: "EHR.Data.Entity.Provider"
				},
				scheme: {
					_id: {
						field: "org_unit"
					}
				}
			}
		},
		_id: {
			relation: "@contractId"
		}
	}
}

export const updateBinding = {
	load: {
		storeKey: "PatientContract",
		params: ["contractId"],
		type: "Entity.Data.EmploymentContract"
	},
	scheme: {
		validity: {
			object: {
				scheme: {
					from: {
						field: "from"
					},
					to: {
						field: "to"
					}
				}
			}
		},
		employee: {
			object: {
				descriptor: {
					_ref: false,
					_type: "Entity.Data.Employee"
				},
				scheme: {
					_id: {
						relation: "@employeeId"
					},
					company: {
						object: {
							descriptor: {
								_ref: true,
								_type: "Entity.Data.Company"
							},
							scheme: {
								_id: {
									relation: "@companyId"
								}
							}
						}
					}
				}
			}
		},
		org_unit: {
			object: {
				descriptor: {
					_type: "EHR.Data.Entity.Provider"
				},
				scheme: {
					_id: {
						field: "org_unit"
					}
				}
			}
		},
		_id: {
			relation: "@contractId"
		}
	}
}
