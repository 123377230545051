import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				code: {
					label: <FormattedMessage id="Orgunits.orgunit.form.code" />,
					rules: "required"
				},
				name: {
					label: <FormattedMessage id="Common.label.name" />,
					rules: "required"
				},
				superiorOJ: {
					label: <FormattedMessage id="Orgunits.orgunit.form.superiorOJ" />
				},
				company: {},
				OUPZScode: {
					label: <FormattedMessage id="Orgunits.orgunit.form.OUPZScode" />
				},
				JRUZid: {
					label: <FormattedMessage id="Orgunits.orgunit.form.JRUZid" />
				},
				expertise: {
					label: <FormattedMessage id="Orgunits.orgunit.form.expertise" />,
					rules: "required"
				},
				expertiseId: {}
			}
		}
	}
}
