import React from "react"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import {
	getHour,
	getMinute,
	newDate,
	setHour,
	setMinute,
	getStartOfDay,
	addMinutes,
	addHours,
	cloneDate,
	formatDate,
	isTimeInDisabledRange,
	isTimeDisabled,
	getStartOfHour,
	getDate,
	getMonth,
	getYear
} from "./helpers"
import {observer} from "mobx-react"

@observer
export default class Time extends React.Component {
	hoursTop = 0
	minutesTop = 0
	renderedTime = null

	static get defaultProps() {
		return {
			intervals: 30,
			onTimeChange: () => {},
			todayButton: null,
			timeCaption: "Time"
		}
	}

	constructor(props) {
		super(props)
		this.scrollToTime(this.props.selected)
	}

	// shouldComponentUpdate(nextProps) {
	// 	if (nextProps.selected !== this.props.selected) {
	// 		const time = nextProps.selected
	// 		const amountH = getHour(time)
	// 		const amountM = this.roundMinutes(getMinute(time))
	// 		const currentItemH = this.hoursTop / this.itemHeight
	// 		const currentItemM = (this.minutesTop * this.props.intervals) / this.itemHeight

	// 		this.stepTime(null, {
	// 			hour: amountH - currentItemH,
	// 			minute: amountM - currentItemM
	// 		})
	// 	}
	// 	return true
	// }

	// componentDidUpdate(prevProps) {
	//   if (this.props.selected !== prevProps.selected) {
	//     const time = this.props.selected;
	//     const amountH = getHour(time);
	//     const amountM = getMinute(time);
	//     const currentItemH = this.hoursTop / this.itemHeight;
	//     const currentItemM = this.minutesTop / this.itemHeight;

	//     this.stepTime(null, {
	//       hour: amountH - currentItemH,
	//       minute: amountM - currentItemM
	//     });
	//   }

	// }

	itemHeight = 34
	mtopDefault = 2006
	htopDefault = 782

	getOffset = (el) => {
		el = el.getBoundingClientRect()
		return {
			left: el.left + window.scrollX,
			top: el.top + window.scrollY
		}
	}

	handleClick = (time, hoursList) => {
		if (
			((this.props.minTime || this.props.maxTime) && isTimeInDisabledRange(time, this.props)) ||
			(this.props.excludeTimes && isTimeDisabled(time, this.props.excludeTimes)) ||
			(this.props.includeTimes && !isTimeDisabled(time, this.props.includeTimes))
		) {
			return
		}

		const amount = hoursList ? getHour(time) : getMinute(time) / this.props.intervals
		const currentItem = (hoursList ? this.hoursTop : this.minutesTop) / this.itemHeight
		this.stepTime(hoursList, amount - currentItem)
		this.props.onChange(time, hoursList)
		this.props.onTimeClick(
			time
				.set("date", getDate(this.props.selected))
				.set("month", getMonth(this.props.selected))
				.set("year", getYear(this.props.selected)),
			hoursList
		)
	}

	handleScroll = (e, hoursList) => {
		if (hoursList) {
			//this.listhours.scrollTo(0, 0);
			this.listhours.scrollTop = 0
		} else {
			//this.listminutes.scrollTo(0, 0);
			this.listminutes.scrollTop = 0
		}
	}

	handleWheel = (e, hoursList, amount) => {
		const delta = e ? (e.wheelDelta ? e.wheelDelta : -1 * e.deltaY) : null
		const amountStep = amount ? amount : delta < 0 ? 1 : -1
		const newTops = this.stepTime(hoursList, amountStep)
		const currentItem = parseInt((hoursList ? newTops.hoursTop : newTops.minutesTop) / this.itemHeight)
		let time = newDate()
		time = hoursList ? setHour(time, currentItem) : setMinute(time, currentItem * this.props.intervals)
		this.props.onChange(time, hoursList)
		this.props.onTimeClick(
			time
				.set("date", getDate(this.props.selected))
				.set("month", getMonth(this.props.selected))
				.set("year", getYear(this.props.selected)),
			hoursList
		)
	}

	stepTime = (hoursList, amount, setTime = false) => {
		const {hoursTop, minutesTop} = this
		let htop = hoursTop ? hoursTop : 0
		let mtop = minutesTop ? minutesTop : 0

		//If setTime start from the top
		if (setTime) {
			htop = mtop = 0
		}

		if (hoursList === null) {
			htop += amount.hour * this.itemHeight
			mtop += (amount.minute / this.props.intervals) * this.itemHeight
		} else if (hoursList) {
			htop += amount * this.itemHeight
		} else if (!hoursList) {
			mtop += amount * this.itemHeight
		}

		//MIN-MAX Height
		mtop = mtop > this.mtopDefault / this.props.intervals ? 0 : mtop
		htop = htop > this.htopDefault ? 0 : htop
		mtop = mtop < 0 ? this.mtopDefault / this.props.intervals : mtop
		htop = htop < 0 ? this.htopDefault : htop

		const newTops = {
			minutesTop: mtop,
			hoursTop: htop
		}

		//this.setState(newTops);

		this.minutesTop = mtop
		this.hoursTop = htop
		return newTops
	}

	roundMinutes = (min) => {
		const nextNumber = Math.ceil(min / this.props.intervals) * this.props.intervals
		return nextNumber >= 60 ? 60 - this.props.intervals : nextNumber
	}

	scrollToTime = (selected) => {
		const currH = selected ? getHour(selected) : getHour(newDate())
		let currM = selected ? getMinute(selected) : getMinute(newDate())

		currM = this.roundMinutes(currM)

		this.stepTime(
			null,
			{
				hour: currH,
				minute: currM
			},
			true
		)
	}

	liClasses = (time, currH, currM) => {
		let classes = ["react-datepicker__time-list-item"]

		if (currH === getHour(time) || currM === getMinute(time)) {
			classes.push("react-datepicker__time-list-item--selected")
		}
		if (
			((this.props.minTime || this.props.maxTime) && isTimeInDisabledRange(time, this.props)) ||
			(this.props.excludeTimes && isTimeDisabled(time, this.props.excludeTimes)) ||
			(this.props.includeTimes && !isTimeDisabled(time, this.props.includeTimes))
		) {
			classes.push("react-datepicker__time-list-item--disabled")
		}

		return classes.join(" ")
	}

	/*renderTimes = () => {
        let times = [];
        const format = this.props.format ? this.props.format : "hh:mm A";
        const intervals = this.props.intervals;
        const activeTime = this.props.selected ? this.props.selected : newDate();
        const currH = getHour(activeTime);
        const currM = getMinute(activeTime);
        let base = getStartOfDay(newDate());
        const multiplier = 1440 / intervals;
        for (let i = 0; i < multiplier; i++) {
          times.push(addMinutes(cloneDate(base), i * intervals));
        }
        
        return times.map((time, i) => (
          <li
          key={i}
          onClick={this.handleClick.bind(this, time)}
          className={this.liClasses(time, currH, currM)}>
          {formatDate(time, format)}
          </li>
          ));
        };*/

	renderHours = (selected) => {
		let times = []
		const format = this.props.formatHours ? this.props.formatHours : "H"
		const activeTime = this.props.selected ? this.props.selected : newDate()
		const currH = getHour(activeTime)
		let base = getStartOfDay(newDate())

		for (let i = 0; i < 24; i++) {
			times.push(addHours(cloneDate(base), i))
		}

		return times.map((time, i) => {
			let bold = selected === i ? " bold" : ""
			return (
				<div key={i} onClick={() => this.handleClick(time, true)} className={this.liClasses(time, currH) + bold}>
					{formatDate(time, format)}
				</div>
			)
		})
	}

	renderMinutes = (selected) => {
		let times = []
		const format = this.props.formatMinutes ? this.props.formatMinutes : "mm"
		const intervals = this.props.intervals ? this.props.intervals : 1
		const activeTime = this.props.selected ? this.props.selected : newDate()
		const currM = getMinute(activeTime)
		let base = getStartOfHour(newDate())
		const multiplier = 60 / intervals
		for (let i = 0; i < multiplier; i++) {
			times.push(addMinutes(cloneDate(base), i * intervals))
		}

		return times.map((time, i) => {
			let bold = selected === i ? " bold" : ""

			return (
				<div key={i} onClick={() => this.handleClick(time, false)} className={this.liClasses(time, null, currM) + bold}>
					{formatDate(time, format)}
				</div>
			)
		})
	}

	render() {
		if (
			this.renderedTime &&
			this.props.selected &&
			this.props.selected.isValid() &&
			!this.renderedTime.isSame(this.props.selected)
		) {
			this.scrollToTime(this.props.selected)
		}

		this.renderedTime = this.props.selected

		const {minutesTop, hoursTop} = this
		const smtop = minutesTop ? minutesTop : 0
		const shtop = hoursTop ? hoursTop : 0
		const minItem = smtop / this.itemHeight
		const hourItem = shtop / this.itemHeight
		const mtop = 0 - smtop + "px"
		const htop = 0 - shtop + "px"

		const minStyle = {
			transform: `translate3d(0, ${mtop}, 0)`
		}

		const hourStyle = {
			transform: `translate3d(0, ${htop}, 0)`
		}

		return (
			<div
				className={`react-datepicker__time-container ${
					this.props.todayButton ? "react-datepicker__time-container--with-today-button" : ""
				}`}
			>
				<div className="react-datepicker__header react-datepicker__header--time">
					<div className="react-datepicker-time__header">{this.props.timeCaption}</div>
				</div>
				<div className="react-datepicker__time">
					<div className="react-datepicker__header_selector">
						<div className="top" />
						<div className="time-row">
							<span>:</span>
						</div>
						<div className="bottom" />
					</div>
					<div className="react-datepicker__time-hour-buttons">
						<div className="buttons">
							<ChevronLeft
								onClick={() => {
									this.handleWheel(null, true, -1)
								}}
								style={{width: 30, height: 30}}
							/>
							<ChevronRight
								onClick={() => {
									this.handleWheel(null, true, 1)
								}}
								style={{width: 30, height: 30}}
							/>
						</div>
					</div>
					<div className="react-datepicker__time-box">
						<div
							className="react-datepicker__time-list"
							ref={(r) => {
								this.listhours = r
							}}
							onScroll={(e) => this.handleScroll(e, true)}
							onWheel={(e) => this.handleWheel(e, true)}
						>
							<div className="react-datepicker__time-hours-items" style={hourStyle}>
								{this.renderHours(hourItem)}
							</div>
						</div>
						<div
							className="react-datepicker__time-list minutes"
							ref={(r) => {
								this.listminutes = r
							}}
							onScroll={(e) => this.handleScroll(e, false)}
							onWheel={(e) => this.handleWheel(e, false)}
						>
							<div className="react-datepicker__time-minutes-items" style={minStyle}>
								{this.renderMinutes(minItem)}
							</div>
						</div>
					</div>
					<div className="react-datepicker__time-minute-buttons">
						<div className="buttons">
							<ChevronLeft
								onClick={() => {
									this.handleWheel(null, false, -1)
								}}
								style={{width: 30, height: 30}}
							/>
							<ChevronRight
								onClick={() => {
									this.handleWheel(null, false, 1)
								}}
								style={{width: 30, height: 30}}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
