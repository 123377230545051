export default {
	load: {
		storeKey: "employeeContractForm",
		params: ["employeeId"],
		type: "Entity.Data.EmploymentContract"
	},
	scheme: {
		_id: {
			relation: "@employmentContractId"
		},
		org_unit: {
			object: {
				descriptor: {
					_type: "Entity.Data.OrgUnit"
				},
				scheme: {
					_id: {
						field: "org_unit"
					}
				}
			}
		},
		capacity: {
			field: "capacityTransformed"
		},
		validity: {
			object: {
				descriptor: {
					_ref: false
				},
				scheme: {
					from: {
						field: "validFrom"
					},
					to: {
						field: "validTo"
					}
				}
			}
		},
		employee: {
			object: {
				descriptor: {
					_type: "Entity.Data.Employee"
				},
				scheme: {
					_id: {
						relation: "@employeeId"
					}
				}
			}
		}
	}
}
