import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "anesthesiological_record"
				},
				name: {},
				nameForm: {
					label: <FormattedMessage id="Common.label.fullName" />
				},
				doctorname: {},
				doctornameForm: {
					label: "Odd./Amb."
				},
				date: {
					label: <FormattedMessage id="Common.label.date" />,
					value: moment(),
					type: "date"
				},
				identifier: {},
				identifierForm: {
					label: <FormattedMessage id="Patient.labels.IDNumber" />
				},
				insurerCode: {},
				insurerCodeForm: {
					label: "Poisť."
				},
				address: {},
				addressForm: {
					label: "Bydlisko"
				},
				contact_person: {
					label: "Kontaktná osoba"
				},
				phone: {},
				phoneForm: {
					label: "č. tel."
				},
				diagnosis: {
					label: "Dg"
				},
				delivery: {
					label: <FormattedMessage id="Common.label.delivery" />
				},
				time_from: {
					label: "Zač. výk.",
					value: "00:00"
				},
				time_to: {
					label: "Koniec",
					value: "00:00"
				},
				pending_delivery: {
					label: "Trvanie výkonu"
				},
				ASA: {
					label: "ASA"
				},
				SpO: {
					label: "SpO"
				},
				other: {
					label: "Iné"
				},

				alergy_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				alergy_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: true
				},

				anesthesiological_before_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				anesthesiological_before_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: true
				},

				complication_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				complication_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: true
				},

				seduxen: {
					label: " ",
					type: "checkbox",
					value: false
				},
				seduxen_quantity: {
					label: " ",
					value: "Seduxen 0 mg"
				},

				atropin: {
					label: " ",
					type: "checkbox",
					value: false
				},
				atropin_quantity: {
					label: " ",
					value: "Atropin 0 mg"
				},

				dolsin: {
					label: " ",
					type: "checkbox",
					value: false
				},
				dolsin_quantity: {
					label: " ",
					value: "Dolsin 0 ml"
				},

				fentanyl: {
					label: " ",
					type: "checkbox",
					value: false
				},
				fentanyl_quantity: {
					label: " ",
					value: "Fentanyl 0 ml"
				},

				fraxiparine: {
					label: " ",
					type: "checkbox",
					value: false
				},
				fraxiparine_quantity: {
					label: " ",
					value: "Fraxiparine 0 s.c."
				},

				i_m: {
					label: "i.m.",
					type: "checkbox",
					value: false
				},
				i_v: {
					label: "i.v.",
					type: "checkbox",
					value: false
				},
				p_o: {
					label: "p.o.",
					type: "checkbox",
					value: false
				},
				ina: {
					label: "iná",
					type: "checkbox",
					value: false
				},

				anesthesia_together: {
					label: "celková",
					type: "checkbox",
					value: false
				},
				anesthesia_region: {
					label: "regionálna",
					type: "checkbox",
					value: false
				},
				anesthesia_anelgesic: {
					label: "analgosedácia",
					type: "checkbox",
					value: false
				},
				anesthesia_other: {
					label: "iná",
					type: "checkbox",
					value: true
				},
				anesthesia_method_iv: {
					label: "i.v.",
					type: "checkbox",
					value: false
				},
				anesthesia_method_mask: {
					label: "maska",
					type: "checkbox",
					value: false
				},
				anesthesia_method_et: {
					label: "epidurálna",
					type: "checkbox",
					value: false
				},
				anesthesia_method_epidural: {
					label: "epidurálna",
					type: "checkbox",
					value: false
				},
				anesthesia_method_subarachnoid: {
					label: "subarachnidálna",
					type: "checkbox",
					value: false
				},
				anesthesia_method_tranqal: {
					label: "tranqalna",
					type: "checkbox",
					value: false
				},
				anesthesia_method_other: {
					label: "iná",
					type: "checkbox",
					value: true
				},

				midazolam: {
					label: " ",
					type: "checkbox",
					value: false
				},
				midazolam_quantity: {
					label: " ",
					value: "Midazolam 2 mg"
				},

				propofol: {
					label: " ",
					type: "checkbox",
					value: false
				},
				propofol_quantity: {
					label: " ",
					value: "Propofol 200 mg"
				},

				hct: {
					label: " ",
					type: "checkbox",
					value: false
				},
				hct_quantity: {
					label: " ",
					value: "HCT 100 mg"
				},

				sufetna: {
					label: " ",
					type: "checkbox",
					value: false
				},
				sufetna_quantity: {
					label: " ",
					value: "Sufenta 2 m"
				},

				soverane: {
					label: " ",
					type: "checkbox",
					value: false
				},
				soverane_quantity: {
					label: " ",
					value: "O2, AIR, soverane 0"
				},

				novalgin: {
					label: " ",
					type: "checkbox",
					value: false
				},
				novalgin_quantity: {
					label: " ",
					value: "Novalgin 2 i.v."
				},

				rocuronium: {
					label: " ",
					type: "checkbox",
					value: false
				},
				rocuronium_quantity: {
					label: " ",
					value: "Rocuronium 0 mg"
				},

				mgso: {
					label: " ",
					type: "checkbox",
					value: false
				},
				mgso_quantity: {
					label: " ",
					value: "MgSO4 1000 m"
				},

				ephedrin: {
					label: " ",
					type: "checkbox",
					value: false
				},
				ephedrin_quantity: {
					label: " ",
					value: "Ephedrin 10 mg"
				},

				course_anesthesia_calm: {
					label: "kľudný",
					type: "checkbox",
					value: true
				},
				course_anesthesia_other: {
					label: "iný",
					type: "checkbox",
					value: false
				},

				wakes_up: {
					label: "zobúdza sa",
					type: "checkbox",
					value: true
				},
				unconscious: {
					label: "v bezvedomí",
					type: "checkbox",
					value: false
				},
				ii: {
					label: "II",
					type: "checkbox",
					value: false
				},
				iii1: {
					label: "III/1",
					type: "checkbox",
					value: false
				},
				iii2: {
					label: "III/2",
					type: "checkbox",
					value: false
				},
				iii3: {
					label: "III/3",
					type: "checkbox",
					value: false
				},

				breath_without_defect: {
					label: "bez porúch",
					type: "checkbox",
					value: true
				},
				breath_defect: {
					label: "narušený",
					type: "checkbox",
					value: false
				},

				blood_without_defect: {
					label: "bez porúch",
					type: "checkbox",
					value: true
				},
				blood_defect: {
					label: "narušený",
					type: "checkbox",
					value: false
				},

				air_duct_oral: {
					label: "zavedený, ústny",
					type: "checkbox",
					value: false
				},
				air_duct_nose: {
					label: "zavedený, nosný",
					type: "checkbox",
					value: false
				},
				air_duct_not_implement: {
					label: "nezavedený",
					type: "checkbox",
					value: true
				},

				get_i_v: {
					label: " ",
					type: "checkbox",
					value: true
				},
				get_i_v_quantity: {
					label: " ",
					value: "F 1/1 500ml"
				},

				instruction_nurse: {
					label: "starostlivosť o dýchanie a krvný obeh do prebudenia"
				}
			}
		}
	}
}
