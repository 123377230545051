import React from "react"
import {observer} from "mobx-react"

import DailyReportListStore from "../../../stores/DailyReportListStore"
// import SearchDropdownNoForm from "../../../../global/ui/xsSearchdropdown/xsSearchdropdownNoForm"
import {FormattedMessage} from "react-intl"
import SearchDropdownCache from "../../../../../global/ui/xsSearchdropdown/xsSearchdropdownCache"
import RegistersCacheStore from "../../../../../global/store/RegistersCacheStore"

export default observer(() => {
	const selectedDiagnosisIds =
		isSafe(DailyReportListStore.diagnosisFilter) &&
		isSafe(DailyReportListStore.diagnosisFilter.diagnoses) &&
		DailyReportListStore.diagnosisFilter.diagnoses.length > 0
			? DailyReportListStore.diagnosisFilter.diagnoses.map((x) => x.id_value)
			: []

	//#region old
	// const diagnosesFilterValues = isSafe(DataStore.patientsDiagnoses)
	// 	? DataStore.patientsDiagnoses.map((dg) => {
	// 			return {
	// 				id: dg.id_value,
	// 				search_string: dg.search_string,
	// 				primaryText: dg.text_value
	// 					.split(" ")
	// 					.slice(1)
	// 					.join(" "),
	// 				secondaryText: dg.text_value.split(" ")[0],
	// 				checkValue: isSafe(diagnosesCheckedIds) && diagnosesCheckedIds.includes(dg.id_value)
	// 			}
	// 	  })
	// 		: []
	//#endregion

	return (
		<SearchDropdownCache
			selectedItems={selectedDiagnosisIds}
			sourceItems={RegistersCacheStore.diagnosisRegister}
			multiSelect={true}
			id="patientListContainer_diagnoses"
			label={<FormattedMessage id="Common.label.diagnosis" />}
			onClose={(ids) => {
				if (ids.length == 0) {
					DailyReportListStore.diagnosisFilter = {diagnoses: null}
					DailyReportListStore.diagnoses_patientListFilter = null
					DailyReportListStore.isChangedFilter(DailyReportListStore.formRef.values())
				} else {
					const filteredDiagnoses =
						isSafe(DailyReportListStore.diagnosisFilter) && isSafe(DailyReportListStore.diagnosisFilter.diagnoses)
							? DailyReportListStore.diagnosisFilter.diagnoses
							: []

					if (
						filteredDiagnoses.length != ids.length ||
						filteredDiagnoses.every((x) => ids.includes(x.id_value)) == false
					) {
						const filters = {
							diagnoses: ids.map((x) => {
								return {id_value: x}
							})
						}

						DailyReportListStore.diagnosisFilter = filters
						DailyReportListStore.diagnoses_patientListFilter = filters.diagnoses
						DailyReportListStore.isChangedFilter(DailyReportListStore.formRef.values())
					}
				}
			}}
		/>

		//#region old

		// <SearchDropdownNoForm
		// 	items={diagnosesFilterValues}
		// 	multiSelect={true}
		// 	id="patientListContainer_diagnoses"
		// 	label={<FormattedMessage id="Common.label.diagnosis" />}
		// 	onClose={(data) => {
		// 		const selectedIds = data.map((x) => x.id)
		// 		const filteredDiagnoses =
		// 			isSafe(DailyReportListStore.diagnosisFilter) && isSafe(DailyReportListStore.diagnosisFilter.diagnoses)
		// 				? DailyReportListStore.diagnosisFilter.diagnoses
		// 				: []

		// 		if (
		// 			filteredDiagnoses.length != selectedIds.length ||
		// 			filteredDiagnoses.every((x) => selectedIds.includes(x.id_value)) == false
		// 		) {
		// 			const filters = {
		// 				diagnoses:
		// 					data.length > 0
		// 						? data.map((x) => {
		// 								return {id_value: x.id}
		// 						  })
		// 						: null
		// 			}

		// 			const postObj = data.length > 0 ? filters.diagnoses : null

		// 			// const postObj = data.length > 0 ?
		// 			//   {
		// 			//     filters: [{
		// 			//       associated_column: "diagnoses",
		// 			//       values: filters.diagnoses
		// 			//     }]
		// 			//   }
		// 			//   : null

		// 			DailyReportListStore.diagnosisFilter = filters
		// 			DailyReportListStore.diagnoses_patientListFilter = postObj
		// 		}
		// 	}}
		// />

		//#endregion
	)
})
