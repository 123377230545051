import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "request_MR"
				},

				idHospPrip: {
					label: "Číslo hospitalizačného prípadu"
				},

				name: {},
				identifier: {},
				insurerCode: {},

				address: {},
				addressForm: {
					label: "Bydlisko" //<FormattedMessage id="Patient.form.contactForm.address" />
				},

				phone: {},
				phoneForm: {
					label: <FormattedMessage id="Patient.form.contactForm.phone" />
				},

				employment: {
					label: "Zamestnanie"
				},

				doctorname: {},
				orgunitname: {},
				orgunitaddress: {},
				orgunitcontact: {},

				doctorcode: {},
				orgunitcode: {},

				odporPersonnelExpertiseId: {
					label: "Odporúčajúci lekár"
				},
				odporDoctorId: {},
				odporDoctorOUPZS: {
					label: "Odporúčajúci OUPZS"
				},
				odporACode: {},
				odporPCode: {},
				//

				dateNotifPatient: {
					label: "Termín vyšetrenia oznámiť na adresu"
				},

				newDate: {
					label: "Pacient objednaný na deň, hodina",
					// value: moment(),
					type: "date"
				},
				orderDateDay: {},
				orderDateHour: {},

				prevMRExam: {
					label: "Bol už pacient na MR vyšetrení Kedy? Kde?"
				},
				areaExam: {
					label: "Ktorý orgán (oblasť) má byť vyšetrený?"
				},
				questionMR: {
					label: "Ktorá otázka má byť MR vyšetrením zodpovedaná?"
				},
				anamnesis: {
					label: "Anamnéza"
				},
				alergAnamnesis: {
					label: "Alergia v anamnéze"
				},
				epikriza: {
					label: "Epikríza"
				},
				prevLabExam: {
					label: "Výsledky predchádzajúcich rádiodiagnostických vyšetrení"
				},

				diagClinical: {
					label: "Klinická diagnóza"
				},
				diagKod: {},
				diagName: {},

				statisticsCode: {
					label: "Štatistický kód"
				},

				date: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.date" />,
					value: moment(),
					type: "date"
				}
			}
		}
	}
}
