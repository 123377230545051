"use strict"

import {observable} from "mobx"

class ContactAddressFormStore {
	@observable editable = false
	@observable isAdd = false
}

var singleton = new ContactAddressFormStore()
export default singleton
