"use strict"

import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import GlobalStore from "../../../../../global/store/GlobalStore"
import PatientRecordDropzoneModalStore from "../../../stores/PatientRecordDropzoneModalStore"
import UIStore from "../../../stores/UIStore"
import Dropzone from "react-dropzone"

// import api from "../../../actions/api"

function PatientRecordDropzoneModal() {
	const handleDropzonePreview = (index) => {
		var FileSaver = require("file-saver") // eslint-disable-line no-undef
		FileSaver.saveAs(
			PatientRecordDropzoneModalStore.dropedFiles[index],
			PatientRecordDropzoneModalStore.dropedFiles[index].name
		)
	}

	const handleDropzoneDelete = (index) => {
		if (isSafe(PatientRecordDropzoneModalStore.dropedFiles[index]._id)) {
			PatientRecordDropzoneModalStore.deleteLoadedAttachemnt(
				PatientRecordDropzoneModalStore.dropedFiles[index]._id,
				index
			)
		} else {
			PatientRecordDropzoneModalStore.dropedFiles.splice(index, 1)
		}
	}

	const handleDrop = (files) => {
		PatientRecordDropzoneModalStore.dropedFiles.push(...files)
	}

	return (
		<Dialog
			id="xsPatientRecordDropzoneModal"
			open={PatientRecordDropzoneModalStore.isOpen}
			onClose={() => {
				PatientRecordDropzoneModalStore.close()
			}}
			className="xs-base-dialog"
			classes={{
				paper: "xs-paper-dialog xs-width-paper-600"
			}}
			disableBackdropClick={true}
			maxWidth="md"
		>
			<DialogTitle className="xs-info">
				<div className="xs-header">
					<div
						className="xs-absolute xs-close-btn"
						onClick={() => {
							PatientRecordDropzoneModalStore.close()
						}}
					>
						<i className="fal fa-times fa-lg" />
					</div>
					<div className="xs-header-icon">
						<i className="fal fa-paperclip fa-2x" />
					</div>
					<div className="xs-header-title">
						<FormattedMessage id="Common.label.addAttachemnt" />
					</div>
				</div>
			</DialogTitle>
			<DialogContent className="xs-overflow-visible">
				<Grid item xs={12} className="pa-3">
					<Dropzone onDrop={handleDrop} id="dropzoneModal">
						{isSafe(PatientRecordDropzoneModalStore.dropedFiles) &&
						PatientRecordDropzoneModalStore.dropedFiles.length ? (
							PatientRecordDropzoneModalStore.dropedFiles.map((file, index) => (
								<div key={index} className={"dropedFile"}>
									<i
										className="fal fa-file icon"
										onClick={(e) => {
											e.stopPropagation()
											handleDropzonePreview(index)
										}}
									/>
									<i
										className="far fa-trash-alt deleteIcon"
										onClick={(e) => {
											e.stopPropagation()
											GlobalStore.openConfirmationDialog("xsDeleteAttachmentFromDropzoneModal", {fileIndex: index})
										}}
									/>
									<br />
									<div
										className={"mac_ellipsis"}
										data-content-start={file.name.substr(0, Math.floor(file.name.length / 2))}
										data-content-end={"\u200E" + file.name.substr(Math.floor(file.name.length / 2)) + "\u200E"}
									/>
								</div>
							))
						) : (
							<div className={"dropzonePlaceholder"}>
								<FormattedMessage id="Common.form.attachment" />
							</div>
						)}
					</Dropzone>
				</Grid>
			</DialogContent>
			<DialogActions className="xs-footer">
				<Grid item xs={12} className="xs-action xs-row xs-justify-between">
					<XsButton
						className="xs-danger xs-outline"
						onClick={() => {
							PatientRecordDropzoneModalStore.close()
						}}
						text={<FormattedMessage id="Common.label.cancel" />}
						icon={<i className="fal fa-times fa-lg" />}
					/>

					<XsButton
						className="xs-success"
						type="submit"
						onClick={() => {
							UIStore.isFormSaving = true
							PatientRecordDropzoneModalStore.save()
						}}
						text={<FormattedMessage id="Common.label.add" />}
						icon={<i className="fal fa-plus fa-lg" />}
					/>
				</Grid>
			</DialogActions>
			<XsConfirmationDialog
				name="xsDeleteAttachmentFromDropzoneModal"
				title={<FormattedMessage id="Common.label.warning" />}
				text={<FormattedMessage id="Patient.form.patientrecord.deleteAttachment" />}
				onConfirmation={() => handleDropzoneDelete(GlobalStore.confirmationDialogParams.fileIndex)}
			/>
		</Dialog>
	)
}

export default injectIntl(observer(PatientRecordDropzoneModal))
