/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"
// import moment from "moment"

export default {
	load() {
		return {
			fields: {
				name: {
					label: <FormattedMessage id="Common.label.name" />,
					rules: "required|string"
				},
				description: {
					label: <FormattedMessage id="Common.label.description" />,
					rules: "string"
				},
				roleId: {}
			}
		}
	}
}
