import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import Grid from "@material-ui/core/Grid"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import UsersStore from "../../../stores/UsersStore"
import validatorjs from "validatorjs"
import UIStore from "../../../../ambulance/stores/UIStore.js"
// import fields from "./rolesDetailFields"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"
import AddIcon from "@material-ui/icons/Add"
// import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import GlobalStore from "../../../../../global/store/GlobalStore"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
// import XsDropDownButton from "../../../../../global/ui/xsDropDownButton/xsDropDownButton"
import XsMultiButton from "../../../../../global/ui/xsButton/xsMultiButton"
import XsTabsIcon from "../../../../../global/ui/xsTabs/xsTabsIcon"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
// import api from "../../../../attendance/actions/api"

@injectIntl
@observer
export default class ResourcesForRoleContainer extends React.Component {
	constructor(props) {
		super(props)
		UsersStore.showResourceForRoleForm = false
		const fields = {
			fields: {
				name: {
					label: <FormattedMessage id="Common.label.name" />,
					rules: "string"
				}
			}
		}

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				!UsersStore.showResourceForRoleForm
					? UsersStore.loadResourcesForRole(props.roleId, form)
					: UsersStore.loadResources(form)
			},
			onError() {}
		}

		this.filterForm = new MobxReactForm(fields, {plugins: {dvr: validatorjs}, hooks})
		UsersStore.loadResources(this.filterForm)
		this.checkedResourcesIds = []
	}

	tabContent = () => {
		const actions = [
			{
				label: <FormattedMessage id="Common.label.addResources" />,
				icon: <AddIcon />,
				onButtonClick: () => {
					this.filterForm.reset()
					UsersStore.resetCurrFilters()
					UsersStore.loadResources(this.filterForm)
					UsersStore.showResourceForRoleForm = true
				}
			},
			{
				label: <FormattedMessage id="Common.label.removeResources" />,
				icon: <AddIcon />,
				onButtonClick: () => {
					this.filterForm.reset()
					UsersStore.resetCurrFilters()
					UsersStore.unassingResource(this.checkedResourcesIds, this.props.roleId)
				}
			}
		]

		UsersStore.isChangedResourcesForRolesFilter(this.filterForm.values())

		return (
			<React.Fragment>
				{!UsersStore.showResourceForRoleForm && (
					<Grid container justify="flex-end">
						<Grid item>
							<XsMultiButton buttons={actions} className="xs-outline xs-info" menuWidth={200} />
						</Grid>
					</Grid>
				)}

				<Grid container spacing={8} alignItems="center">
					<Grid item xs={2}>
						<XsInput white field={this.filterForm.$("name")} />
					</Grid>
					<Grid item xs={1} className="pb-0">
						<XsIconButton
							rect
							className="xs-default xs-outline"
							tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
							onClick={(e) => {
								this.filterForm.onSubmit(e)
							}}
							icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
						/>
					</Grid>
				</Grid>
				{!UsersStore.showResourceForRoleForm ? (
					UIStore.isFormSaving ? (
						<XsLoading />
					) : UsersStore.changedResourcesForRoleFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i className="far fa-search fa-lg mr-3 pointer" onClick={this.filterForm.onSubmit}></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTable
							config={{
								columnDefs: {
									name_ext: {
										title: <FormattedMessage id="Common.label.name" />,
										type: "string",
										sortable: true,
										design: {
											width: "50%"
										}
									},
									description: {
										title: <FormattedMessage id="Common.label.description" />,
										type: "string",
										sortable: true,
										design: {
											width: "50%"
										}
									}
									// action: {
									// 	title: "",
									// 	type: "action",
									// 	design: {
									// 		width: "42px",
									// 		body: {
									// 			renderer: (props) => {
									// 				return (
									// 					<XsIconButton
									// 						icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
									// 						onClick={() =>
									// 							GlobalStore.openConfirmationDialog("xsDeleteResourcesAssignedToRole", {
									// 								resourceName: props.value
									// 							})
									// 						}
									// 					/>
									// 				)
									// 			}
									// 		}
									// 	}
									// }
								},
								options: {
									// showCursor: true,
									// selectRow: true,
									checkboxes: true,
									checkboxColumn: "_id",
									checkAllDataColumn: "resource_id",
									onClickCheckbox: (data) => {
										this.checkedResourcesIds = data
									},
									onRowClick: () => {
										// this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										return {
											name_ext: dataRow.name,
											description: dataRow.description,
											action: dataRow.name,
											_id: dataRow.resource_id
										}
									}
								},
								dataSource: UsersStore.resourcesForRole
							}}
						/>
					)
				) : (
					<Grid container direction="row" spacing={8}>
						<Grid item xs={12}>
							<XsButton
								className="xs-success pullRight"
								text={<FormattedMessage id="Common.label.save" />}
								icon={<SaveIcon />}
								onClick={() => {
									UIStore.isFormSaving = true
									UsersStore.assignResourceToRole(this.checkedResourcesIds, this.props.roleId)
								}}
							/>
							<XsButton
								className="xs-danger pullRight mr-2"
								text={<FormattedMessage id="Common.label.cancel" />}
								icon={<CancelIcon />}
								onClick={() => {
									UsersStore.showResourceForRoleForm = false
									UsersStore.loadResourcesForRole(this.props.roleId, this.filterForm)
									// this.form.reset()
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							{UIStore.isFormSaving ? (
								<XsLoading />
							) : UsersStore.changedResourcesForRoleFilters ? (
								<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
									<i className="far fa-search fa-lg mr-3 pointer" onClick={this.filterForm.onSubmit}></i>
									<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
								</div>
							) : (
								<XsTable
									config={{
										columnDefs: {
											name_ext: {
												title: <FormattedMessage id="Common.label.name" />,
												type: "string",
												sortable: true,
												design: {
													width: "50%"
												}
											},
											description: {
												title: <FormattedMessage id="Common.label.description" />,
												type: "string",
												sortable: true,
												design: {
													width: "50%"
												}
											}
										},
										options: {
											// showCursor: true,
											checkboxes: true,
											checkboxColumn: "_id",
											checkAllDataColumn: "resource_id",
											onClickCheckbox: (data) => {
												this.checkedResourcesIds = data
											},
											// selectRow: true,
											// onRowClick: (dataRow) => {},
											mapper: (dataRow) => {
												return {
													name_ext: dataRow.name,
													description: dataRow.description,
													_id: dataRow.resource_id
												}
											}
										},
										dataSource: UsersStore.resourcesList
									}}
								/>
							)}
						</Grid>
						{/* <Grid item xs={3}>
							<XsAutocomplete
								minLengthForSearch="3"
								field={this.form.$("resource")}
								api={api.loadResources}
								inputRenderer={(obj) => obj.name}
								saveValue={(obj) => obj.resource_id}
							/>
						</Grid>
						<Grid item xs={7}></Grid>
						<Grid item xs={1}>
							<XsButton
								className="xs-danger"
								text={<FormattedMessage id="Common.label.cancel" />}
								icon={<CancelIcon />}
								onClick={() => {
									UsersStore.showResourceForRoleForm = false
									this.form.reset()
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsButton
								className="xs-success"
								text={<FormattedMessage id="Common.label.save" />}
								icon={<SaveIcon />}
								onClick={(e) => {
									UIStore.isFormSaving = true
									this.form.onSubmit(e)
								}}
							/>
						</Grid> */}
					</Grid>
				)}
			</React.Fragment>
		)
	}

	render() {
		return (
			<React.Fragment>
				{/* <Grid container direction="row" align="left" spacing={8}>
					<Grid item xs={12}>
						<h3 className="detailHeading">
							<FormattedMessage id="Common.label.listOfResourcesForRole" />
						</h3>
					</Grid>
				</Grid> */}
				<Grid container direction="row" align="left" spacing={8}>
					<Grid item xs={12}>
						<XsTabsIcon
							value={"resourcesForRoles"}
							onChangeTab={() => {}}
							mainClass="xs-patient-card-tab"
							background="white"
							tabs={[
								{
									label: this.props.intl.formatMessage({id: "Common.label.listOfResourcesForRole"}),
									icon: <i className="fas fa-users fa-2x" />,
									value: "resourcesForRoles",
									content: this.tabContent()
								}
							]}
						/>
					</Grid>
				</Grid>

				<XsConfirmationDialog
					name="xsDeleteResourcesAssignedToRole"
					text={<FormattedMessage id="Common.label.unassingResourceFromRole" />}
					type="danger"
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.label.unassingResource"
					}}
					onConfirmation={() =>
						UsersStore.unassingResource(GlobalStore.confirmationDialogParams.resourceName, this.props.roleId)
					}
				/>
			</React.Fragment>
		)
	}
}
