"use strict"

// default imports for all farms
import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
// import UI controls here
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import Grid from "@material-ui/core/Grid"
// definitions of form fields
import fields from "./BMIFormFields"
import {FormattedMessage} from "react-intl"
import Popover from "@material-ui/core/Popover"
import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import {injectIntl} from "react-intl"

@injectIntl
@observer
export class BMIForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			showBMIForm: false,
			clinicalValues: {}
		}

		const hooks = {
			onSubmit() {
				// special validation here
			},
			onSuccess() {
				// UIStore.clinicalValuesFormExtended = false
			},
			onError() {
				// special error notification here
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		this.form.$("weight").focus()
	}

	handleChange = (name, value) => {
		this.setState({
			[name]: value
		})
	}

	handleBMIClick = (open, event) => {
		const anchorEl = event ? event.currentTarget : null
		this.setState({
			showBMIForm: !open ? false : true,
			anchorEl: anchorEl
		})
	}

	calculateBMI = () => {
		const w = parseInt(this.form.$("weight").value)
		let h = parseInt(this.form.$("height").value)
		let hm = h / 100
		if (w && hm) {
			const BMIresult = Math.round((w / (hm * hm)) * 10) / 10
			if (BMIresult) {
				this.setState(
					{
						showBMIForm: false,
						clinicalValues: {
							weight: w,
							height: h,
							BMI: BMIresult,
							BMILabel: this.getBMILabel(BMIresult)
						}
					},
					() => {
						this.props.onSave(this.state.clinicalValues)
					}
				)
			}
		}
	}

	getBMILabel = (BMIresult, classOnly = false) => {
		let BMIclass = ""
		if (BMIresult < 18.5) {
			BMIclass = "under"
		} else if (BMIresult >= 18.5 && BMIresult < 25) {
			BMIclass = "normal"
		} else if (BMIresult >= 25 && BMIresult < 30) {
			BMIclass = "over"
		} else if (BMIresult > 30) {
			BMIclass = "obese"
		}
		if (classOnly) {
			return BMIclass
		}
		// return this.context.intl.formatMessage({
		//   id: "Patient.form.bmiform." + BMIclass
		// });
		return this.props.intl.formatMessage({id: "Patient.form.bmiform." + BMIclass})
		// return BMIclass
	}

	render() {
		const {clinicalValues} = this.state
		let BMIclass = ""
		let BMIlabel = ""
		let BMIresult = clinicalValues.BMI
		if (BMIresult) {
			BMIclass = this.getBMILabel(BMIresult, true)
			BMIlabel = this.getBMILabel(BMIresult)
			BMIresult += ": "
		} else {
			BMIlabel = ""
		}
		return (
			<div>
				<button type="button" className="ct-label" onClick={(e) => this.handleBMIClick(!this.state.showBMIForm, e)}>
					BMI
				</button>
				<Popover
					open={this.state.showBMIForm}
					anchorEl={this.state.anchorEl}
					anchorOrigin={{vertical: "bottom", horizontal: "right"}}
					transformOrigin={{vertical: "top", horizontal: "left"}}
					onClose={() => this.handleChange("showBMIForm", false)}
					className="xs-bmi-form"
				>
					<div className={"bmiForm"}>
						<Grid container direction="row" align="right" spacing={8}>
							<Grid item xs={6}>
								<XsInput field={this.form.$("weight")} type={"number"} min={1} />
							</Grid>
							{/* //onBlur={() => this.calculateBMI()} /></Grid> */}
							<Grid item xs={6}>
								<XsInput field={this.form.$("height")} type={"number"} min={1} />
							</Grid>
							{/* //onBlur={() => this.calculateBMI()} /></Grid> */}
						</Grid>
						<Grid container direction="row" align="left" spacing={8}>
							<Grid item xs={9}>
								<XsButton
									className="xs-danger xs-outline"
									text={<FormattedMessage id="Common.label.cancel" />}
									onClick={() => {
										this.setState({showBMIForm: false})
									}}
									icon={<CancelIcon />}
								/>
								<XsButton
									className="xs-success"
									text={<FormattedMessage id="Common.label.save" />}
									type="submit"
									onClick={() => this.calculateBMI()}
									icon={<SaveIcon />}
								/>
							</Grid>
							<Grid item xs={3}>
								<div className="bmiResult">
									<span className="bold">{BMIresult}</span>
									<span className={BMIclass}>{BMIlabel}</span>
								</div>
							</Grid>
						</Grid>
					</div>
				</Popover>
			</div>
		)
	}
}
