import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import bindings from "../../../../../../global/ui/globalUISchemeBindings"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions, InputAdornment} from "@material-ui/core"
import XsButton from "../../../../../../global/ui/xsButton/xsButton"
import XsTable from "../../../../../../global/ui/xsTable/xsTable"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
// import CopyServiceDialogStore from "../../../stores/CopyServiceDialogStore"
import CopyServiceDialogStore from "../../../../stores/CopyServicesDialogStore"
import GlobalStore from "../../../../../../global/store/GlobalStore"

@injectIntl
@observer
export default class copyServicesDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				CopyServiceDialogStore.copyAgreement(form)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					endDate: {label: <FormattedMessage id="Common.label.copyServiceValidityDate" />},
					search_column: {label: <FormattedMessage id="Common.label.orgunit" />}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)

		CopyServiceDialogStore.loadOrgunits()
	}

	render() {
		return (
			<Dialog
				id="xscopyServicesDialog"
				open={CopyServiceDialogStore.isOpen}
				onClose={() => {
					this.form.reset()
					CopyServiceDialogStore.close()
				}}
				className="xs-base-dialog dialog-newpatient"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-1050"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								this.form.reset()
								CopyServiceDialogStore.close()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-copy fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.copy" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent>
					<Grid container direction="column">
						<Grid item xs={12} className="pa-4">
							<FormattedMessage id="Common.label.copyServiceValidityDateInfo" />
						</Grid>
					</Grid>
					<Grid container justify="space-between">
						<Grid item xs={3} className="pa-4">
							<XsDateTimePicker field={this.form.$("endDate")} />
						</Grid>
						<Grid item xs={5} className="pa-4">
							<XsInput
								white
								clearText={() => CopyServiceDialogStore.agreementFilter("")}
								field={this.form.$("search_column")}
								placeholder={"Vyhľadávanie..."}
								onChange={(val) => CopyServiceDialogStore.agreementFilter(val)}
								startAdornment={
									<InputAdornment position="start" className="xs-search-adornment">
										<i className="fal fa-search pt-1 pl-2" />
									</InputAdornment>
								}
							/>
						</Grid>
						<Grid item className="pl-4 pr-4 pb-4">
							<XsTable
								config={{
									columnDefs: {
										code: {
											title: <FormattedMessage id="Orgunits.orgunit.form.code" />,
											type: "string",
											design: {
												width: "20%"
											},
											sortable: true
										},
										name: {
											title: <FormattedMessage id="Common.label.name" />,
											type: "string",
											design: {
												width: "20%"
											},
											sortable: true
										},
										OUPZS_code: {
											title: <FormattedMessage id="Orgunits.orgunit.form.OUPZScode" />,
											type: "string",
											design: {
												width: "20%"
											},
											sortable: true
										},
										expertise: {
											title: <FormattedMessage id="Orgunits.orgunit.form.expertise" />,
											type: "string",
											design: {
												width: "20%"
											},
											sortable: true
										},
										JRUZ_id: {
											title: <FormattedMessage id="Orgunits.orgunit.form.JRUZid" />,
											type: "string",
											design: {
												width: "20%"
											},
											sortable: true
										}
									},
									options: {
										// hideHeader: true,
										showCursor: true,
										checkboxes: true,
										checkboxColumn: "resource_id",
										checkAllDataColumn: "resource_id",
										onClickCheckbox: (data) => {
											CopyServiceDialogStore.checkedOrgunitIds = data
										},
										// selectRow: true,
										// onRowClick: (dataRow) => {
										// 	this.onHandleRowClick(dataRow)
										// },
										mapper: (dataRow) => {
											return {
												name: isSafe(dataRow.name_ext) ? dataRow.name_ext : "",
												code: isSafe(dataRow.code_ext) ? dataRow.code_ext : "",
												OUPZS_code:
													isSafe(dataRow.identifiers) &&
													isSafe(dataRow.identifiers.find((i) => i.type === "PROVIDERCODE"))
														? dataRow.identifiers.find((i) => i.type === "PROVIDERCODE").value
														: "",
												expertise: isSafe(dataRow.cl_expertise)
													? dataRow.cl_expertise.code_ext + " " + dataRow.cl_expertise.name_ext
													: "",
												JRUZ_id:
													isSafe(dataRow.identifiers) && isSafe(dataRow.identifiers.find((i) => i.type === "JRUZID"))
														? dataRow.identifiers.find((i) => i.type === "JRUZID").value
														: "",
												org_unit_id: dataRow._id,
												resource_id: dataRow.resource_id
											}
										}
									},
									dataSource: CopyServiceDialogStore.orgunits
								}}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer">
					<Grid container justify="space-between">
						<Grid item>
							<XsButton
								className="xs-default xs-outline"
								icon={<i className="fal fa-times" />}
								text={<FormattedMessage id="Common.form.close" />}
								onClick={() => {
									this.form.reset()
									CopyServiceDialogStore.close()
								}}
							/>
						</Grid>
						<Grid>
							<XsButton
								className="xs-success ml-3"
								icon={<i className="fal fa-copy fa-lg" />}
								text={<FormattedMessage id="Common.label.copy" />}
								onClick={this.form.onSubmit}
							/>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}
