import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import ReactTooltip from "react-tooltip"
import {Grid, Dialog, DialogContent, DialogTitle} from "@material-ui/core"
import fields from "./clinicalSetsDialogFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import GlobalStore from "../../../../../global/store/GlobalStore"
import PatientRecordStore from "../../../stores/PatientRecordStore"
import UIStore from "../../../stores/UIStore"
import XsChipForm from "../../../../../global/ui/xsChipForm/xsChipForm"
import XsChip from "../../../../../global/ui/xsChip/xsChip"
import AddIcon from "@material-ui/icons/Add"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import RegistersCacheStore from "../../../../../global/store/RegistersCacheStore"

@injectIntl
@observer
export default class ClinicalSetsDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {
				UIStore.isFormSaving = true
			},
			onSuccess(form) {
				PatientRecordStore.saveClinicalValsToSet(form)
			},
			onError() {
				UIStore.isFormSaving = false
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	form

	onRowClick = (clinSet) => {
		if (isSafe(clinSet)) {
			let chipData = clinSet.items.map((item) => {
				return {
					col1: item.measure._id,
					col1NameExt: item.measure.name_ext
				}
			})
			this.form.$("chipData").set("value", JSON.stringify(chipData))
			this.form.$("name").set("value", clinSet.name)
			this.form.$("_id").set("value", clinSet._id)
			this.forceUpdate()
		}
	}

	handleClinicalValuesMeasureChange = (form) => {
		let values = form.get("value")
		let measureData =
			isSafe(GlobalStore.CL["recordMeasures"]) && isNotEmpty(GlobalStore.CL["recordMeasures"])
				? GlobalStore.CL["recordMeasures"].find((i) => i.code === values.col1)
				: null
		if (isSafe(measureData)) {
			values.col1NameExt = measureData.name_ext
		}
		form.set(values)
	}

	render() {
		return (
			<Dialog
				id="xsPatientClinicalSetsDialog"
				open={PatientRecordStore.clinicalSetsDialogOpen}
				onClose={() => {
					PatientRecordStore.clinicalSetsDialogClose()
					this.form.reset()
				}}
				className="xs-base-dialog dialog-patientallergies"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-600"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								PatientRecordStore.clinicalSetsDialogClose()
								this.form.reset()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-list-ul fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.clinicalSetTitle" />
						</div>
						<div className="xs-header-subtitle">
							<FormattedMessage id="Common.label.clinicalSetSubTitle" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-overflow-visible xs-content-clinicalSets">
					<Grid container direction="column">
						<Grid item xs={12} className="xs-form">
							<Grid container spacing={8}>
								<Grid item xs={12}>
									<XsInput field={this.form.$("name")} />
								</Grid>
								<Grid item xs={12}>
									<XsChipForm
										ref={(chipForm) => (PatientRecordStore.clinicalSetDialogChipsForm = chipForm)}
										field={this.form.$("chipData")}
										label={`<span class="xs-bold">${this.props.intl.formatMessage({
											// eslint-disable-line
											id: "Patient.form.patientrecord.clinicalvalues"
										})}</span>`} // eslint-disable-line
										configuration={{
											header: {
												chipsRenderer: (key, dataRow, clickHandler) => (
													<XsChip
														key={key}
														label={dataRow["col1NameExt"]["value"]}
														onClick={(e) => clickHandler(false, e, key)}
													/>
												),
												addButtonRenderer: (clickHandler) => (
													<XsIconButton
														className="xs-success xs-outline"
														icon={<AddIcon />}
														onClick={(e) => {
															clickHandler(e)
														}}
														id={"clinical"}
													/>
												)
											},
											columns: [
												{
													name: "col1",
													label: <FormattedMessage id="Patient.form.patientrecord.measuretype" />,
													width: 10,
													validationRule: "required|string",
													renderer: (field, idx, fieldKey, onChangeHandler) => (
														<SearchSelect
															field={field}
															items={GlobalStore.CL["recordMeasures"]}
															onChange={() => this.handleClinicalValuesMeasureChange(onChangeHandler(fieldKey))}
															required
														/>
													)
												},
												{
													name: "col1NameExt",
													renderer: () => {},
													width: 0
												}
											],
											options: {
												disabled: false,
												isFormMode: false,
												isModalMode: false,
												hideButtons: true,
												showCancelButton: true,
												plusButtonTabindex: (index) => {
													return +index + 1
												},
												onFormOpen: () => {},
												onFormClose: () => {},
												onFormSave: (values) => {
													PatientRecordStore.clinicalValuesChips = values
												}
											},
											data: PatientRecordStore.clinicalSetDialogChipsFormDaata
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
						{isNotEmpty(this.form.$("_id").value) ? (
							<Grid item xs={12} className="xs-action xs-row xs-justify-between">
								<XsButton
									className="xs-success"
									type="submit"
									onClick={this.form.onSubmit}
									text={<FormattedMessage id="Common.label.editClincalSet" />}
									icon={<i className="fal fa-plus fa-lg" />}
								/>
								<XsButton
									className="xs-danger xs-outline"
									onClick={() => {
										this.form.reset()
									}}
									text={<FormattedMessage id="Common.label.cancel" />}
									icon={<i className="fal fa-times fa-lg" />}
								/>
							</Grid>
						) : (
							<Grid item xs={12} className="xs-action">
								<XsButton
									className="xs-success"
									type="submit"
									onClick={this.form.onSubmit}
									text={<FormattedMessage id="Common.label.addClincalSet" />}
									icon={<i className="fal fa-plus fa-lg" />}
								/>
							</Grid>
						)}
						<Grid item xs={12} className="xs-items-scrollbar">
							<div className="xs-bold xs-items-title xs-row">
								<FormattedMessage id="Common.label.allClincalSet" />
								<div className="xs-count-items">
									{isSafe(RegistersCacheStore.measureSetRegister) && RegistersCacheStore.measureSetRegister.length > 0
										? RegistersCacheStore.measureSetRegister.length
										: 0}
								</div>
							</div>
							<div className="xs-items">
								{isSafe(RegistersCacheStore.measureSetRegister) &&
									RegistersCacheStore.measureSetRegister.map((clinSet, idx) => {
										return (
											<div key={idx} className="xs-item xs-row xs-flex">
												<div className="xs-item-content" onClick={() => this.onRowClick(clinSet)}>
													<div className="xs-column xs-flex xs-justify info">
														<div className="xs-primary-info xs-blueInfo">{clinSet.name}</div>
													</div>
												</div>
												<div
													className="xs-trash"
													data-tip
													data-for="deleteClinSet"
													onClick={() => {
														GlobalStore.openConfirmationDialog("xsDeletePatientClinicalSet", {setId: clinSet._id})
													}}
												>
													<i className="fal fa-trash-alt fa-lg" />
												</div>
												<ReactTooltip id="deleteClinSet">
													<FormattedMessage id="Common.label.deleteClinSet" />
												</ReactTooltip>
											</div>
										)
									})}
							</div>
						</Grid>
						{GlobalStore.confirmationDialogOpened === "xsDeletePatientClinicalSet" && (
							<XsConfirmationDialog
								type="danger"
								name="xsDeletePatientClinicalSet"
								headerConfig={{
									text: "Common.label.deleteClinicalSetQuestion",
									subtext: "Common.label.deleteClinicalSetQuestionSubText",
									icon: <i className="fal fa-trash-alt fa-2x" />
								}}
								subtext={
									<div>
										<div className="pb-2">
											<span className="xs-greyDefault fontSize13">
												<FormattedMessage id="Common.label.description" />:{" "}
											</span>
											<span className="xs-bold fontSize15">
												{isSafe(
													RegistersCacheStore.measureSetRegister.find(
														(obj) => obj._id == GlobalStore.confirmationDialogParams.setId
													)
												)
													? RegistersCacheStore.measureSetRegister.find(
															(obj) => obj._id == GlobalStore.confirmationDialogParams.setId
													  ).name
													: ""}
											</span>
										</div>
									</div>
								}
								cancelBtn={{
									icon: <i className="fal fa-times fa-lg" />,
									text: "Common.btn.not.delete"
								}}
								confirmBtn={{
									icon: <i className="fal fa-trash-alt fa-lg" />,
									text: "Common.label.deleteClinSet"
								}}
								onConfirmation={() =>
									PatientRecordStore.deleteClinicalSet(this.form, GlobalStore.confirmationDialogParams.setId)
								}
							/>
						)}
					</Grid>
				</DialogContent>
			</Dialog>
		)
	}
}
