import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {withStyles} from "@material-ui/core/styles"

// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import LabResultReportStore from "../../../stores/LabResultReportStore"
import WarningStore from "../../../../../global/store/WarningStore"
import TableStore from "../../../stores/TableStore"

// definitions of form fields
import fields from "./labResultFields"

import moment from "moment"
import {getUserPersonnelOrgUnits} from "../../../../../global/helpers/actions"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import GlobalStore from "../../../../../global/store/GlobalStore"
import UIStore from "../../../stores/UIStore"

const styles = () => ({
	searchIcon: {
		marginTop: "14px"
	}
})

@withStyles(styles)
@injectIntl
@observer
export default class LabResult extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				if (form.values().datefrom || form.values().dateto) {
					if (
						TableStore.tables.hasOwnProperty("labResultReportList") &&
						TableStore.tables["labResultReportList"].hasOwnProperty("index")
					) {
						delete TableStore.tables["labResultReportList"]["index"]
					}

					LabResultReportStore.loadLabResult(form)
				} else {
					WarningStore.open(props.intl.formatMessage({id: "Common.label.warningDateFromDateTo"}))
				}
			},
			onError() {}
		}

		if (isEmpty(LabResultReportStore.formRef)) {
			LabResultReportStore.formRef = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		}

		this.form = LabResultReportStore.formRef

		LabResultReportStore.loadLabResult(this.form)
	}

	componentWillUnmount() {
		LabResultReportStore.labResultData = []
	}

	onHandleRowClick = (dataRow) => {
		if (isNotEmpty(dataRow.person_id) && isNotEmpty(dataRow.provider_id)) {
			GlobalStore.changeOrgUnitAndRouteToPatientDetail(dataRow.provider_id, dataRow.person_id)
			UIStore.EHRfilterValue = "laboratories"
			UIStore.overviewTab = "laboratories"
		}
	}

	render() {
		LabResultReportStore.isChangedFilter(this.form.values())

		return (
			<Grid container className="xs-dailyReport-list">
				<Grid item xs={12}>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}>
							<XsDateTimePicker white field={this.form.$("datefrom")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker white field={this.form.$("dateto")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={2}>
							<SearchSelect white field={this.form.$("orgunit")} items={getUserPersonnelOrgUnits()} />
						</Grid>
						<Grid item xs={2}>
							<XsInput white field={this.form.$("identifier")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={1} className="pb-0">
							<XsIconButton
								rect
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								className="xs-default xs-outline"
								onClick={this.form.onSubmit}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
					</Grid>
					{LabResultReportStore.isFetching ? (
						<XsLoading />
					) : LabResultReportStore.changedFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i className="far fa-search fa-lg mr-3 pointer" onClick={this.form.onSubmit}></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTable
							setRef={(table) => (LabResultReportStore.labResultReportListRef = table)}
							config={{
								columnDefs: {
									full_name: {
										title: <FormattedMessage id="Capitation.List.Patient" />,
										type: "string",
										design: {
											width: "350px",
											body: {
												className: "blueDark xs-bold"
											}
										},
										sortable: true
									},
									identifier: {
										title: <FormattedMessage id="Patient.form.patientinfo.identifier" />,
										type: "string",
										design: {
											width: "120px"
										},
										sortable: true
									},
									recorded_at: {
										title: <FormattedMessage id="Common.label.date" />,
										type: "datetime",
										design: {
											width: "150px",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format("DD.MM.YYYY") : ""
												}
											}
										},
										sortable: true
									},
									provider_code_ext: {
										title: <FormattedMessage id="Common.label.provider" />,
										type: "string",
										sortable: true
									},
									laboratory_system_short_name: {
										title: <FormattedMessage id="Common.label.laboratory" />,
										type: "string",
										sortable: true
									}
								},
								options: {
									showCursor: true,
									name: "labResultReportList",
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									}
								},
								dataSource: LabResultReportStore.labResultData
							}}
						/>
					)}
				</Grid>
			</Grid>
		)
	}
}
