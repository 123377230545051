//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import {Document, Page} from "react-pdf"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import DeleteIcon from "@material-ui/icons/Clear"

import ScoringStore from "../../../stores/ScoringStore"
import UIStore from "../../../stores/UIStore"
import {Grid} from "@material-ui/core"

@injectIntl
@observer
export default class ShowDocumentsDialog extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			numPages: null,
			pageNumber: 1
		}
	}

	onDocumentLoadSuccess = ({numPages}) => {
		this.setState({numPages})
	}

	render() {
		return (
			<Dialog
				id="xsShowDocumentsDialog"
				open={ScoringStore.isOpenShowDocument}
				onClose={() => ScoringStore.closeShowDocument()}
				disableBackdropClick={true}
				maxWidth="md"
				fullWidth={true}
				className="xs-base-dialog"
				classes={{
					paper: "overflowSearch xs-width-paper-1050"
				}}
			>
				<DialogTitle>
					<div className="nx-insurer-header">
						<XsIconButton
							className="xs-dialog-header"
							icon={<DeleteIcon onClick={() => ScoringStore.closeShowDocument()} />}
						/>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<Grid container direction="column">
						<Document file={`${UIStore.documentContent}`} onLoadSuccess={this.onDocumentLoadSuccess}>
							<Page width={990} pageNumber={this.state.pageNumber} />
						</Document>
					</Grid>
				</DialogContent>

				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-danger xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.form.close" />}
						onClick={() => {
							ScoringStore.closeShowDocument()
						}}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
