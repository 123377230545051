import React from "react"
import {observer} from "mobx-react"

// import OutlinedInput from "@material-ui/core/OutlinedInput"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"

import "./xsDropdownList.less"

@observer
export default class XsDropdownList extends React.Component {
	constructor(props) {
		super(props)
		// this.state = {
		//   value: isNotEmpty(this.props.defaultValue) ? this.props.defaultValue : "",
		// }
	}

	// handleChange = event => {
	//   this.setState({ value: event.target.value });
	// };

	render() {
		const {
			disabled,
			label,
			column,
			value,
			items,
			chooseItem,
			renderer,
			onChange,
			className = "",
			emptyValue,
			labelAsPlaceholder
		} = this.props

		return (
			<FormControl disabled={disabled}>
				{isEmpty(value) && labelAsPlaceholder && (
					<InputLabel
						shrink
						htmlFor="age-helper"
						className={labelAsPlaceholder ? "xs-placeholder-for-dropdownlist" : ""}
					>
						{label}
					</InputLabel>
				)}
				{!labelAsPlaceholder && (
					<InputLabel shrink htmlFor="age-helper">
						{label}
					</InputLabel>
				)}
				<Select
					value={isSafe(value) ? value : []}
					SelectDisplayProps={{
						className: "xs-select"
					}}
					onChange={(e) => (isSafe(onChange) && typeof onChange === "function" ? onChange(e.target.value) : null)} // fake kvoli nulovaniu v PP v delivery liste...
					className={`xs-dropdown-list ${className}`}
				>
					{isSafe(emptyValue) && (
						<MenuItem key={"-1"} value="">
							<div
								className="xs-menu-item"
								onClick={() =>
									isSafe(chooseItem) && typeof chooseItem === "function" ? chooseItem({[column]: ""}) : null
								}
							>
								...
							</div>
						</MenuItem>
					)}
					{isSafe(items) &&
						items.length > 0 &&
						items.map((item, idx) => {
							return (
								<MenuItem key={idx} value={item[column]}>
									<div
										className="xs-menu-item"
										onClick={() => (isSafe(chooseItem) && typeof chooseItem === "function" ? chooseItem(item) : null)}
									>
										{isSafe(renderer) && typeof renderer === "function" && renderer(item)}
									</div>
								</MenuItem>
							)
						})}
				</Select>
			</FormControl>
		)
	}
}
