"use strict"

import {observable, action} from "mobx"
import api from "../../../../modules/ambulance/actions/api"
import GlobalStore from "../../../store/GlobalStore"
import {getUserCompanyInfo, getUserDoctorInfo, getSelectedOrgUnitID} from "../../../helpers/actions"
import config from "../../../config/settings"
import moment from "moment"
import UIStore from "../../../../modules/ambulance/stores/UIStore"
// import WarningStore from "../../../../global/store/WarningStore"
import InformationStore from "../../../../global/store/InformationStore"
import CalendarStore from "../../../../modules/ambulance/stores/CalendarStore"

class ImportVaccStore {
	@observable isOpen = false
	@observable dropedFiles = []
	@observable vaccPlaces = []

	@observable customWarningisOpen = false
	@observable customWarningErrorText = ""
	@observable customWarningWarningText = ""
	@observable customWarningSuccessRowCount = ""

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
		this.dropedFiles = []
	}

	@action customWarningOpen() {
		this.customWarningisOpen = true
	}

	@action customWarningClose() {
		this.customWarningisOpen = false
		this.customWarningWarningText = ""
		this.customWarningErrorText = ""
		this.customWarningSuccessRowCount = ""
	}

	//Load idciek miest do selectu v exporte
	@action loadVaccPlaces(form) {
		if (
			isNotEmpty(form.$("dateFrom").value) &&
			isNotEmpty(form.$("dateTo").value) &&
			isNotEmpty(form.$("orgunit").value)
		) {
			const formValues = form.values()
			const req = {
				time_from: moment(formValues.dateFrom)
					.startOf("day")
					.format(config.DB_DATETIME_FORMAT),
				time_to: moment(formValues.dateTo)
					.endOf("day")
					.format(config.DB_DATETIME_FORMAT),
				filters: [
					{
						associated_column: "company_id",
						values: [
							{
								id_value: getUserCompanyInfo().id
							}
						]
					},
					{
						associated_column: "provider_id",
						values: [
							{
								id_value: formValues.orgunit
							}
						]
					}
				]
			}

			api
				.gettVaccinationPlaces(req)
				.call()
				.then((response) => {
					if (isSafe(response) && Array.isArray(response)) {
						//Map idciek do selectu
						this.vaccPlaces = response.map((id) => {
							return {
								_id: id,
								code_ext: id,
								code: id,
								name_ext: id
							}
						})
						//Defaultne ma byt prva vybrana
						form.$("vaccPlace").value = response[0]
					}
				})
		}
	}

	@action loadDefaultDelivery(form) {
		const filter = {
			filters: [{associated_column: "code_ext", predicate: "=", values: [{id_value: "252K"}]}],
			row_count_full: 200
		}

		api
			.loadDeliveries(filter)
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.rows)) {
					const delivery = response.rows.find((row) => row.code_ext === "252K")
					form.$("delivery").value = delivery._id
				}
			})
	}

	@action handleErrorText(response) {
		if (
			(isSafe(response.errors) && Array.isArray(response.errors) && response.errors.length > 0) ||
			(isSafe(response.warnings) && Array.isArray(response.warnings) && response.warnings.length > 0)
		) {
			let errorText = ""
			let warningText = ""
			if (isSafe(response.errors)) {
				response.errors.map((error) => {
					errorText += `<p>${error}</p>`
				})
			}
			this.customWarningErrorText = errorText
			if (isSafe(response.warnings)) {
				response.warnings.map((warning) => {
					warningText += `<p>${warning}</p>`
				})
			}

			this.customWarningWarningText = warningText
			this.customWarningSuccessRowCount = response.row_count_success
			if (isNotEmpty(errorText) || isNotEmpty(warningText)) {
				this.customWarningOpen()
			}
		} else {
			if (isNotEmpty(response.row_count_success)) {
				InformationStore.open(`Úspešne naimportovaných riadkov: ${response.row_count_success}`)
			}
		}
	}

	@action importData(form) {
		if (isSafe(this.dropedFiles) && this.dropedFiles.length) {
			const formValues = form.values()
			UIStore.isFormSaving = true
			// let req = []
			let files = this.dropedFiles
			let binaryDataArr = []
			const onClose = () => {
				this.close()
			}
			const handleErrorText = this.handleErrorText.bind(this)
			let getdata = function(index) {
				let data = files[index]
				let binaryData
				var reader = new FileReader()
				reader.readAsText(data)
				reader.onloadend = async function() {
					binaryData = reader.result
					binaryDataArr.push(binaryData)
					if (files.length > index + 1) getdata(index + 1)
					else {
						binaryDataArr.forEach((row) => {
							let urlParamsString = ""
							urlParamsString += `&send_sms=${formValues.sendSMS ? "1" : "0"}`
							urlParamsString += `&send_email=${formValues.sendEmail ? "1" : "0"}`
							urlParamsString += `&check_udzs=${formValues.checkUdzs ? "1" : "0"}`
							urlParamsString += `&force_overwrite_vacc=${formValues.forceSaveDrug ? "1" : "0"}`
							urlParamsString += `&vaccine_id=${formValues.productCode}`
							urlParamsString += `&delivery_id=${formValues.delivery}`

							if (isNotEmpty(formValues.forceSendSMS)) {
								urlParamsString += `&force_sms=${formValues.forceSendSMS ? "1" : "0"}`
							}

							//vaccName nepovinne
							if (isNotEmpty(formValues.vaccName)) {
								urlParamsString += `&vacc_name=${formValues.vaccName}`
							}

							//vaccPlaceName, vaccPlaceAdress, vaccSender POVINNE
							urlParamsString += `&vacc_place_name=${formValues.vaccPlaceName}`
							urlParamsString += `&vacc_place_address=${formValues.vaccPlaceAdress}`
							urlParamsString += `&sender=${formValues.vaccSender}`

							api
								.importEhealthCsv(row, urlParamsString, getSelectedOrgUnitID())
								.call(null, null, null, 300000)
								.then((response) => {
									UIStore.isFormSaving = false
									GlobalStore.setNotificationMessage("Import prebehol úspešne", "", "success")
									//Ak su nejake errory nerefreshovat kalendar
									// if (!(isSafe(response.errors) && Array.isArray(response.errors) && response.errors.length > 0)) {
									CalendarStore.getEvents()
									// }
									handleErrorText(response)
									onClose()
								})
								.catch(() => {
									UIStore.isFormSaving = false
									GlobalStore.setNotificationMessage("Import sa nepodaril", "", "error")
								})
						})
					}
				}
			}
			getdata(0)
		}
	}

	@action exportData(form) {
		const formValues = form.values()

		let req = {
			time_from: moment(formValues.dateFrom)
				.startOf("day")
				.format(config.DB_DATETIME_FORMAT),
			time_to: moment(formValues.dateTo)
				.endOf("day")
				.format(config.DB_DATETIME_FORMAT),
			doctor_expertise: getUserDoctorInfo().expertise.id,
			vaccPlace_id: formValues.vaccPlace,
			filters: [
				{
					associated_column: "company_id",
					values: [
						{
							id_value: getUserCompanyInfo().id
						}
					]
				}
			]
		}

		if (isNotEmpty(formValues.orgunit)) {
			req.filters.push({
				associated_column: "provider_id",
				values: [
					{
						id_value: formValues.orgunit
					}
				]
			})
		}

		UIStore.isFormSaving = true
		api
			.downloadEhealthCsv(req)
			.call(null, null, null, 300000)
			.then(async (response) => {
				let fileName = isNotEmpty(response.file_name) ? response.file_name : "export.csv"

				const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
					const byteCharacters = atob(b64Data)
					const byteArrays = []

					for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
						const slice = byteCharacters.slice(offset, offset + sliceSize)

						const byteNumbers = new Array(slice.length)
						for (let i = 0; i < slice.length; i++) {
							byteNumbers[i] = slice.charCodeAt(i)
						}

						const byteArray = new Uint8Array(byteNumbers)
						byteArrays.push(byteArray)
					}

					const blob = new Blob(byteArrays, {type: contentType})
					return blob
				}

				const blob = b64toBlob(response.file, "text/csv")

				let FileSaver = require("file-saver") // eslint-disable-line no-undef
				FileSaver.saveAs(blob, fileName)
				this.handleErrorText(response)
				UIStore.isFormSaving = false
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}
}

var singleton = new ImportVaccStore()
export default singleton
