import React from "react"
import {observer} from "mobx-react"
import reactCSS from "reactcss"
import {SketchPicker} from "react-color"

import "./xsColorPicker.less"

@observer
export default class XsColorPicker extends React.Component {
	state = {
		displayColorPicker: false
	}

	constructor(props) {
		super(props)
		props.field.set("bindings", "MaterialSelectField")
	}

	handleClick = () => {
		this.setState({displayColorPicker: !this.state.displayColorPicker})
	}

	handleClose = () => {
		this.setState({displayColorPicker: false})
	}

	handleChange = (field, color) => {
		field.set("value", color.hex)
	}

	render() {
		const {field} = this.props
		const disableAlpha = !this.props.enableAlpha
		const selectedColor = field.get("value")

		const styles = reactCSS({
			default: {
				color: {
					width: "36px",
					height: "14px",
					borderRadius: "2px",
					background: `${selectedColor}`
				},
				swatch: {
					padding: "5px",
					background: "#fff",
					borderRadius: "1px",
					boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
					display: "inline-block",
					cursor: "pointer"
				},
				popover: {
					position: "absolute",
					zIndex: "2"
				},
				cover: {
					position: "fixed",
					top: "0px",
					right: "0px",
					bottom: "0px",
					left: "0px"
				}
			}
		})

		return (
			<div className="xsColorPicker">
				<div className="xs-label">{field.label}</div>
				<div style={styles.swatch} onClick={this.handleClick}>
					<div style={styles.color} />
				</div>
				{this.state.displayColorPicker ? (
					<div style={styles.popover}>
						<div style={styles.cover} onClick={this.handleClose} />
						<SketchPicker
							{...field.bind()}
							color={selectedColor}
							disableAlpha={disableAlpha}
							onChange={(color) => this.handleChange(field, color)}
						/>
					</div>
				) : null}
			</div>
		)
	}
}
