"use strict"

import {observable, action} from "mobx"

class InformationStore {
	@observable isOpen = false
	@observable message = ""

	@action open(message) {
		this.isOpen = true
		this.message = message
	}

	@action close() {
		this.isOpen = false
	}
}

var singleton = new InformationStore()
export default singleton
