import React from "react"
import {observer} from "mobx-react"
import "./clinicalValueRow.less"
import XsExpansionPanel from "../../../../../../global/ui/xsExpansionPanel/xsExpansionPanel"

import UIStore from "../../../../stores/UIStore"

const ClinicalValueRow = observer(({data}) => {
	return (
		<XsExpansionPanel maxHeight={20} glOpen={UIStore.isOpenAllRecordsInEHROverview}>
			<div className="clinicalValueRow">
				{data &&
					data.map((cv, index) => {
						return (
							<div key={index}>
								{cv.measure_name_ext}:{" "}
								<b>
									{cv.value} {cv.unit_code_ext}
								</b>
								<span> {cv.note_value}</span>
							</div>
						)
					})}
			</div>
		</XsExpansionPanel>
	)
})

export default ClinicalValueRow
