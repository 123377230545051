import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import validatorjs from "validatorjs"
import MobxReactForm from "mobx-react-form"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"

import ExportInvoicesPDFDialogStore from "../../../stores/ExportInvoicesPDFDialogStore"
import InvoiceListStore from "../../../stores/InvoiceListStore"

@injectIntl
@observer
export default class ExportInvoicesPDFDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				ExportInvoicesPDFDialogStore.exportPDF(form, InvoiceListStore.checkedInvoces)
			},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					password_zip: {label: "Heslo pre ZIP"}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)
	}

	render() {
		return (
			<Dialog
				id="xsExportInvoicesPDFDialog"
				open={ExportInvoicesPDFDialogStore.isOpen}
				onClose={() => ExportInvoicesPDFDialogStore.close()}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-500"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-absolute xs-close-btn" onClick={() => ExportInvoicesPDFDialogStore.close()}>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-file-export fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.exportToPDF" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content overflowY">
					<Grid item container>
						<Grid item xs={12}>
							<XsInput field={this.form.$("password_zip")} />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer">
					<Grid container justify="space-between" alignItems="center">
						<Grid item>
							<XsButton
								className="xs-danger xs-outline"
								onClick={() => {
									ExportInvoicesPDFDialogStore.close()
								}}
								text={<FormattedMessage id="Common.label.cancel" />}
								icon={<i className="fal fa-times fa-lg" />}
							/>
						</Grid>
						<Grid item>
							<XsButton
								className="xs-success"
								onClick={this.form.onSubmit}
								text={<FormattedMessage id="Common.label.exportToPDF" />}
								icon={<i className="fal fa-file-export fa-lg" />}
							/>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}
