"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"
// import PatientPrescriptionContainerStore from "./PatientPrescriptionContainerStore"

class GenerikaDialogStore {
	@observable isOpen = false
	@observable generika = undefined

	drugSelectCallback = null
	form

	@action open(generika) {
		this.generika = generika
		this.isOpen = true
	}

	@action close() {
		this.form = null
		this.isOpen = false
		this.drugSelectCallback = null
	}

	@action selectDrug(code) {
		api
			.loadDrugFromDrugCode(code)
			.call()
			.then((res) => {
				if (isSafe(res)) {
					this.form.$("onlyCategorisedDrugs").set("value", res.categorized)
					this.form.$("drugs").set("value", res._id)
					if (isSafe(this.drugSelectCallback) && typeof this.drugSelectCallback == "function") {
						this.drugSelectCallback()
					}
				}
				this.close()
			})
		// PatientPrescriptionStore.searchDrug(code)
	}

	@action findGenerics(patientId, providerId, productCode, form, callback) {
		api
			.findGenerics(patientId, providerId, productCode)
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.generika) && response.generika.length > 0) {
					this.form = form
					this.open(response.generika)
					this.drugSelectCallback = callback
				}
			})
	}
}

var singleton = new GenerikaDialogStore()
export default singleton
