"use strict"

import {observable, action} from "mobx"

import DataStore from "./DataStore"

class DuplicityPersonModalStore {
	@observable isOpen = false
	@observable patientData = undefined
	form = undefined

	@action open(form) {
		DataStore.isCreatedFromDuplicityPerson_personId = undefined
		DataStore.isCreatedFromDuplicityPerson_clientId = undefined
		this.isOpen = true
		this.form = form
	}

	@action close() {
		this.isOpen = false
	}
}

var singleton = new DuplicityPersonModalStore()
export default singleton
