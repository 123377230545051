"use strict"

import {observable, action} from "mobx"
import UIStore from "../stores/UIStore"
import {getUserPersonnelID, getSelectedOrgUnit, getUser} from "../../../global/helpers/actions"
import config from "../../../global/config/settings"
import {GWApiCall} from "../../../global/helpers/api"
import DataStore from "../stores/DataStore"
import moment from "moment"

class VaccAllergyListDialogStore {
	@observable isOpen = false
	@observable allergyList = []

	@action open() {
		this.isOpen = true
	}

	@action loadListData(form, callback) {
		UIStore.isFormSaving = true
		const formVals = form.values()
		const selectedOrgUnit = getSelectedOrgUnit()
		const user = getUser()
		const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
		const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
		const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
		const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
		const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

		const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
			? DataStore.patientDTO.get("patient").validInfo.client
			: null
		const JRUZIDObj = isSafe(patientClientInfo.identifiers)
			? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
			: null

		GWApiCall({
			method: "POST",
			uri: `${config.GW_BASE_URL}/GetVaccination`,
			body: JSON.stringify({
				ClassName: "VyhladajOckovaniaPacienta",
				AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
				AmbeePersonnelId: getUserPersonnelID(),
				PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
				AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
				OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
				PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
				PersonnelExpertiseJRUZVersion: specVer,
				AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
				AmbeePatientId: DataStore.patientDTO.get("patientID"),
				PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
				PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
				PPCToken: null,
				DatumVykonaniaOd: moment(formVals.dateFrom)
					.startOf("day")
					.format(config.DB_DATETIME_FORMAT),
				DatumVykonaniaDo: moment(formVals.dateTo)
					.subtract(10, "minutes")
					.format(config.DB_DATETIME_FORMAT)
			})
		})
			.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
			.then((response) => {
				this.allergyList = response.rows
				if (isSafe(callback) && typeof callback == "function") {
					callback()
				}
				UIStore.isFormSaving = false
			})
			.catch(() => {
				callback()
				UIStore.isFormSaving = false
			})
	}

	@action close(form, callback) {
		this.isOpen = false
		this.allergyList = []
		if (isSafe(form)) {
			form.reset()
		}
		if (isSafe(callback) && typeof callback == "function") {
			callback()
		}
	}
}

var singleton = new VaccAllergyListDialogStore()
export default singleton
