import React from "react"
// import moment from "moment"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import fields from "./enumeratorListFields"
import Grid from "@material-ui/core/Grid"
import XsTable from "../../../../../../global/ui/xsTable/xsTable"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import EnumList from "./enumList"

import EnumeratorListStore from "../../../../stores/EnumeratorListStore"

@observer
export default class EnumeratorList extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		EnumeratorListStore.loadEnumerator(this.form)
	}

	applyFilters = () => {
		delayedCallback(500, () => EnumeratorListStore.loadEnumerator(this.form))
	}

	render() {
		return (
			<div className="xs-enumerators-list">
				{EnumeratorListStore.isOpen ? (
					<EnumList />
				) : (
					<Grid container direction="column">
						<Grid item container spacing={8}>
							<Grid item xs={2}>
								<XsInput field={this.form.$("search_column")} onChange={() => this.applyFilters()} />
							</Grid>
							<Grid item xs={2}>
								<XsInput field={this.form.$("code_ext")} onChange={() => this.applyFilters()} />
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<XsTable
								config={{
									columnDefs: {
										name_ext: {
											title: <FormattedMessage id="Common.label.name" />,
											design: {
												width: "40%"
											},
											type: "string"
										},
										description: {
											title: <FormattedMessage id="Common.label.description" />,
											design: {
												width: "60%"
											},
											type: "string"
										}
									},
									options: {
										showCursor: true,
										selectRow: true,
										onRowClick: (dataRow) => {
											EnumeratorListStore.openEnumList(dataRow)
										},
										mapper: (dataRow) => {
											return {
												_id: dataRow._id,
												name_ext: dataRow.name_ext,
												description: dataRow.description
											}
										}
									},
									dataSource: EnumeratorListStore.enumeratorData
								}}
							/>
						</Grid>
					</Grid>
				)}
			</div>
		)
	}
}
