import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"

import fields from "./medicalOpinionFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsCheckbox from "../../../../../../global/ui/xsCheckbox/xsCheckbox"

@injectIntl
@observer
export default class MedicalOpinionTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		if (isEmpty(this.form.$("addressForm").value) && isNotEmpty(this.form.$("address").value)) {
			this.form.$("addressForm").value = this.form.$("address").value
		}
	}

	render() {
		return (
			<div className="pa-4">
				<Grid container direction="column" align="top">
					<Grid item xs={3}>
						<XsInput field={this.form.$("paperNumber")} maxLength={40} />
					</Grid>

					<div style={{marginBottom: "20px", marginTop: "20px"}}>
						<FormattedMessage id="PrintTemplate.MedicalOpinion.FormLabel1" />
					</div>

					<div>
						<XsCheckbox field={this.form.$("employerInv")} />
					</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsInput field={this.form.$("employerName")} maxLength={30} />
						</Grid>
						<Grid item xs={4}>
							<XsInput field={this.form.$("employerSite")} maxLength={80} />
						</Grid>
						<Grid item xs={4}>
							<XsInput field={this.form.$("employerId")} maxLength={20} />
						</Grid>
					</Grid>
					<div>
						<XsCheckbox field={this.form.$("employerPersonInv")} />
					</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsInput field={this.form.$("employerPersonName")} maxLength={30} />
						</Grid>
						<Grid item xs={4}>
							<XsInput field={this.form.$("employerPersonPlace")} maxLength={80} />
						</Grid>
						<Grid item xs={4}>
							<XsInput field={this.form.$("employerPersonId")} maxLength={20} />
						</Grid>
					</Grid>

					<div style={{marginTop: "40px", marginBottom: "20px"}}>
						<FormattedMessage id="PrintTemplate.MedicalOpinion.FormLabel2" />
					</div>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsInput field={this.form.$("name")} maxLength={80} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker field={this.form.$("birthdate")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={6}>
							<XsInput field={this.form.$("addressForm")} maxLength={80} />
						</Grid>
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={2}>
							<XsInput
								field={this.form.$("personalNumber")}
								onChange={(value) => {
									if (isSafe(value) && isNotEmpty(value.trim())) {
										this.form.$("personalNumberInv").value = true
									} else {
										this.form.$("personalNumberInv").value = false
									}
								}}
							/>
						</Grid>
						<Grid item xs={10}>
							<XsInput field={this.form.$("workPlace")} />
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("workType")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("workFactors")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("workCategory")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput
							field={this.form.$("workCustomRegulation")}
							onChange={(value) => {
								if (isSafe(value) && isNotEmpty(value.trim())) {
									this.form.$("workCustomRegulationInv").value = true
								} else {
									this.form.$("workCustomRegulationInv").value = false
								}
							}}
						/>
					</Grid>
					<div style={{marginTop: "40px", marginBottom: "20px"}}>
						<FormattedMessage id="PrintTemplate.MedicalOpinion.FormLabel3" />
					</div>
					<Grid item xs={12}>
						<XsCheckbox field={this.form.$("resultAInv")} />
					</Grid>
					<Grid item xs={12}>
						<XsCheckbox field={this.form.$("resultBInv")} />
					</Grid>
					<Grid item xs={12} style={{marginTop: "5px"}}>
						<XsInput field={this.form.$("resultBDetail")} labelStyle={{top: "-10px"}} />
					</Grid>
					<Grid item xs={12}>
						<XsCheckbox field={this.form.$("resultCInv")} />
					</Grid>

					<div style={{marginTop: "40px"}}>
						<FormattedMessage id="PrintTemplate.MedicalOpinion.FormLabel4" />
					</div>
					<div style={{marginTop: "10px"}}>
						<FormattedMessage id="PrintTemplate.MedicalOpinion.FormLabel5" />
					</div>
				</Grid>
			</div>
		)
	}
}
