//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import TextField from "@material-ui/core/TextField"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import StornoRequestDialogStore from "../../../stores/StornoRequestDialogStore"

@observer
export default class StornoRequestDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsStornoRequestDialog"
				open={StornoRequestDialogStore.isOpen}
				onClose={() => StornoRequestDialogStore.close()}
				//onEscapeKeyDown={() => DrugInteractionsDialogStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-danger">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-trash-alt fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="PatientDetail.overview.recordStornoTitle" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<TextField
						label={<FormattedMessage id="PatientDetail.overview.recordStornoTitle" />}
						className="dialog-cancel-input"
						value={StornoRequestDialogStore.reason}
						onChange={(e) => StornoRequestDialogStore.onHandleChange(e.target.value)}
						InputLabelProps={{
							shrink: true
						}}
						fullWidth
						autoFocus
					/>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline action-close"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.btn.not.storno" />}
						onClick={() => StornoRequestDialogStore.close()}
					/>
					<XsButton
						className="xs-danger action-cancel"
						icon={<i className="fal fa-trash-alt" />}
						text={<FormattedMessage id="Common.label.storno" />}
						onClick={() => StornoRequestDialogStore.storno()}
						disabled={StornoRequestDialogStore.reason.length == 0}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
