"use strict"

import {observable, action} from "mobx"
import config from "../../../global/config/settings"

class UIStore {
	@observable showHeaderInPatientDetail = false

	@observable patientInfoFormDisabled = true
	@observable clinicalValuesFormExtended = false
	@observable patientAlertsExtended = false
	@observable patientAllergiesExtended = false
	@observable isFormSaving = false
	@observable isFormSavingMessage = ""

	@observable calendarTimePeriod = "today"
	@observable weekPlusWeekend = false

	@observable reportingZSDetailTab = "Capitations"
	@observable reportZSName = ""
	@observable agreementsDetailTab = "Agreements"
	@observable reportDetailTab = "vaccByRegion"
	@observable reportName = ""
	@observable settingDetailTab = "messaging"
	@observable settingName = ""
	@observable adminclDetailTab = "ProvidedServices"
	@observable patientDetailTab = "EHR"
	@observable patientCardTab = ""
	@observable overviewTab = "overview"
	@observable EHRfilterValue = "overview"
	@observable patientInfoTab = "contacts"
	@observable mainMenuTab = "mainmenu"
	@observable previousReportingZSDetailTab = ""
	@observable previousReportZSName = ""
	@observable patientOverviewFilterCheckBox = true
	patientOverviewFilter = null
	@observable insuranceMessage = undefined
	@observable EHRversionFilterValue = ""
	@observable EHRversionFilterText = ""
	@observable usersSettings = "users"
	@observable usersSettingsName = ""

	@observable isOpenAllRecordsInEHROverview = false

	@observable usersTabs = "users"

	currPatientOverviewFilterValue = null

	@observable openSnackBar = false

	@observable documentContent = null

	overviewAllowedCheckbox = ["RX", "DEK", "ZV", "OV", "OOP", "POD"] //["ANA", "RX", "DEK", "ZV", "OV", "PN", "OOP", "POD"]

	overviewTabCounts = {
		overview: config.OVERVIEW_PAGE_SIZE,
		medication: config.OVERVIEW_PAGE_SIZE,
		ambulance: config.OVERVIEW_PAGE_SIZE,
		requests: config.OVERVIEW_PAGE_SIZE,
		clinicalvalues: config.OVERVIEW_PAGE_SIZE,
		noDeliveries: config.OVERVIEW_PAGE_SIZE,
		sicknessabs: config.OVERVIEW_PAGE_SIZE
	}

	@observable isOverviewTemplateLoading = false

	@observable JSXTableFooter = null

	@observable commMsgQueue = observable.map({})

	//Ref na divko ktore obsahuje formualre a scrollbar v karte pacienta
	patientCardDivRef = null

	//Ref na na funkciu pre refresh OPP formularu
	patientCardContainerOPPRefresh = null

	//funkcia na force-rerender overview
	patientCardRerenderOverview = null

	/*
	commMsgQueue.set(key, { //key-unikatny kluc v preskripcii poskladany z "prescription" a idcka oddelene ';'
		timestamp: Date.now(), //DATUM
		text: "Podpisujem recept(y) a posielame ich do poistovne", //NADPIS
		state: 0, // 0-prebieha , 1-uspesne, 2-s chybou
		needApprove: true, //musi byt potvrdene inak sa rovno vymaze a do schanky ani nejde
		stateText: null // detailnejsi popis chyby
	})
*/

	@action
	onHandleTabs(value) {
		this.patientCardTab = value
		this.patientDetailTab = "EHR"
	}
}

var singleton = new UIStore()
export default singleton
