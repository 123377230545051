import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				ico: {
					label: <FormattedMessage id="Common.label.ico" />
				},
				name: {
					label: <FormattedMessage id="Common.label.name" />
				},
				codeNZ: {
					label: <FormattedMessage id="Common.label.codeNZ" />
				},
				doctorCode: {
					label: <FormattedMessage id="Calendar.overPrescription.dialog.doctorCode" />
				},
				doctorName: {
					label: <FormattedMessage id="Employee.form.medicalWorkerExpForm.doctor_name" />
				},
				address: {
					label: <FormattedMessage id="Patient.form.contactForm.address" />
				},
				mobile: {
					label: <FormattedMessage id="Patient.form.contactForm.mobile" />,
					rules: "required"
				},
				email: {
					label: <FormattedMessage id="Common.label.email" />,
					rules: "required"
				}
			}
		}
	}
}
