import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import {Grid, List, Divider, Drawer, ListItem, ListItemText, ListItemIcon, Tooltip} from "@material-ui/core"
import UIStore from "../../stores/UIStore"
import DailyReportList from "./dailyReport/dailyReportList"
import SicknessAbsReportList from "./sicknessAbsReport/sicknessAbsReportList"
import NCZIReportList from "./ncziReport/ncziReportList"
import PatientWithoutPPList from "./patientsWithoutPP/patientsWithoutPPList"
import EUPatients from "./euPatientsReport/euPatientsReport"
import PatientWithPPList from "./patientsWithPP/patientsWithPPList"
import VaccReportsContainer from "./vaccinationReport/vaccReportsContainer"
// import DrugReportList from "./drugsReport/drugReportList"
import NcziA01ReportList from "./ncziA01Report/ncziA01ReportList"
import DraftReportList from "./draftReport/draftReportList"
import HospicomReportList from "./hospicom/hospicomList"
import LabResult from "./labResult/labResult"
import MedicalRecordList from "./medicalRecordReport/medicalRecordList"
import DeliveriesByCodeList from "./deliveriesByCodeReport/deliveriesByCodeList"
import RecordsWithoutDeliveriesList from "./recordsWithoutDeliveries/recordsWithoutDeliveriesList"
import {withStyles} from "@material-ui/core/styles"
import classnames from "classnames"
import NotAllowedDeliveriesList from "./notAllowedDeliveries/notAllowedDeliveriesList"
import DrugReportContainer from "./drugsReport/drugReportContainer"
import VaccByRegionReport from "./vaccByRegionReport/vaccByRegionReport"
import SmsReportList from "./smsReport/smsReportList"
import VaccinationCountByDeliveryList from "./vaccinationCountByDelivery/VaccinationCountByDeliveryList"
import NcziU01ReportList from "./ncziU01Report/ncziU01ReportList"

const drawerWidth = 250

const styles = (theme) => ({
	drawer: {
		height: "100%"
	},
	drawerPaper: {
		position: "relative",
		overflow: "hidden",
		whiteSpace: "nowrap",
		height: "100%",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerPaperClose: {
		overflowX: "hidden",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		width: theme.spacing.unit * 7,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing.unit * 9
		}
	},
	drawerIcon: {
		fontSize: "1.6rem !important",
		width: "15px"
	}
})

@withStyles(styles)
@injectIntl
@observer
export default class ReportContainer extends React.Component {
	constructor(props) {
		super(props)
		UIStore.mainMenuTab = "reports"

		if (UIStore.reportDetailTab == "vaccByRegion") {
			UIStore.reportName = <FormattedMessage id="Common.label.vaccByRegion" />
		}

		this.state = {
			open: false
		}
	}

	handleDrawerOpen = () => {
		this.setState({open: true})
	}

	handleDrawerClose = () => {
		this.setState({open: false})
	}

	handleChange = (event, newValue) => {
		UIStore.reportDetailTab = newValue
	}

	drawerData = [
		{
			id: "vaccByRegion",
			label: <FormattedMessage id="Common.label.vaccByRegion" />,
			icon: <i className={classnames("fal fa-file-medical", this.props.classes.drawerIcon)} />
		},
		{
			id: "draftReport",
			label: <FormattedMessage id="Common.label.draftReportList" />,
			icon: <i className={classnames("fal fa-file-signature", this.props.classes.drawerIcon)} />
		},
		{
			id: "recordsWithoutDeliveries",
			label: <FormattedMessage id="Common.label.recordsWithoutDeliveries" />,
			icon: <i className={classnames("fal fa-file-invoice-dollar", this.props.classes.drawerIcon)} />
		},
		{
			id: "DailyReport",
			label: <FormattedMessage id="Report.DailyReportList" />,
			icon: <i className={classnames("fal fa-file-alt", this.props.classes.drawerIcon)} />
		},
		{
			id: "SicknessAbs",
			label: <FormattedMessage id="Common.label.patientSicknessAbs" />,
			icon: <i className={classnames("fal fa-id-badge", this.props.classes.drawerIcon)} />
		},
		{
			id: "NCZI",
			label: <FormattedMessage id="PatientDetail.cards.nczi" />,
			icon: <i className={classnames("fal fa-hospital", this.props.classes.drawerIcon)} />
		},
		{
			id: "NCZIA01",
			label: <FormattedMessage id="Common.label.NcziA01" />,
			icon: <i className={classnames("fal fa-hospital", this.props.classes.drawerIcon)} />
		},
		{
			id: "NCZIU01",
			label: <FormattedMessage id="Common.label.NcziU01" />,
			icon: <i className={classnames("fal fa-hospital", this.props.classes.drawerIcon)} />
		},
		{
			id: "EUPatients",
			label: <FormattedMessage id="PatientDetail.cards.euPatients" />,
			icon: <i className={classnames("fal fa-globe-stand", this.props.classes.drawerIcon)} />
		},
		{
			id: "PatientWithoutPP",
			label: <FormattedMessage id="Common.label.patientsWithoutPP" />,
			icon: <i className={classnames("fal fa-user-times", this.props.classes.drawerIcon)} />
		},
		{
			id: "PatientWithPP",
			label: <FormattedMessage id="Common.label.patientsWithPP" />,
			icon: <i className={classnames("fal fa-user-clock", this.props.classes.drawerIcon)} />
		},
		{
			id: "Vaccination",
			label: (
				<React.Fragment>
					<FormattedMessage id="Common.label.vaccination" /> / <FormattedMessage id="Common.label.medication" />
				</React.Fragment>
			),
			icon: <i className={classnames("fal fa-syringe", this.props.classes.drawerIcon)} />
		},
		{
			id: "drugReport",
			label: <FormattedMessage id="Common.label.drugReportList" />,
			icon: <i className={classnames("fal fa-pills", this.props.classes.drawerIcon)} />
		},
		{
			id: "hospicomReportList",
			label: <FormattedMessage id="Patient.sidebar.hospicom" />,
			icon: <i className={classnames("fal fa-calendar-alt", this.props.classes.drawerIcon)} />
		},
		{
			id: "labResultReport",
			label: <FormattedMessage id="Common.label.labResult" />,
			icon: <i className={classnames("fal fa-flask", this.props.classes.drawerIcon)} />
		},
		{
			id: "notAllowedDeliveries",
			label: <FormattedMessage id="Common.label.deliveriesCombinations" />,
			icon: <i className={classnames("fal fa-ban", this.props.classes.drawerIcon)} />
		},
		{
			id: "medicalRecordWithDGN",
			label: <FormattedMessage id="Common.label.medicalRecordWithDGN" />,
			icon: <i className={classnames("fal fa-diagnoses", this.props.classes.drawerIcon)} />
		},
		{
			id: "deliveriesByCode",
			label: <FormattedMessage id="Common.label.deliveriesByCode" />,
			icon: <i className={classnames("fal fa-box-usd", this.props.classes.drawerIcon)} />
		},
		{
			id: "sms",
			label: "SMS",
			icon: <i className={classnames("fal fa-comments", this.props.classes.drawerIcon)} />
		},
		{
			id: "vaccinationCountByDelivery",
			label: <FormattedMessage id="Common.label.vaccinationCountByDelivery" />,
			icon: <i className={classnames("fal fa-list-ol", this.props.classes.drawerIcon)} />
		}
	]

	render() {
		return (
			<Grid container className="maxHeight">
				<Grid item className="maxHeight">
					<Drawer
						variant="permanent"
						className={this.props.classes.drawer}
						classes={{
							paper: classnames(this.props.classes.drawerPaper, !this.state.open && this.props.classes.drawerPaperClose)
						}}
						open={this.state.open}
					>
						<Divider />
						<List>
							<ListItem button onClick={() => (this.state.open ? this.handleDrawerClose() : this.handleDrawerOpen())}>
								{this.state.open ? (
									<ListItemText className="textRight">
										<i className={classnames("fal fa-chevron-left", this.props.classes.drawerIcon)} />
									</ListItemText>
								) : (
									<ListItemText className="textRight">
										<i className={classnames("fal fa-chevron-right", this.props.classes.drawerIcon)} />
									</ListItemText>
								)}
							</ListItem>
						</List>
						<Divider />
						<List className="overflowY overflowXHidden">
							{this.drawerData.map((data, key) => {
								return (
									<ListItem
										key={key}
										button
										onClick={() => {
											UIStore.reportDetailTab = data.id
											UIStore.reportName = data.label
										}}
										className={classnames({bgGreyLight: UIStore.reportDetailTab == data.id})}
									>
										<Tooltip title={data.label} placement="right">
											<ListItemIcon>{data.icon}</ListItemIcon>
										</Tooltip>
										<ListItemText>{data.label}</ListItemText>
									</ListItem>
								)
							})}
						</List>
					</Drawer>
				</Grid>
				<Grid item className="xs-flex pt-4 pb-4 pr-8 pl-8 maxHeight overflowY">
					<Grid container>
						<h2 className="pb-6">{UIStore.reportName}</h2>
						{UIStore.reportDetailTab === "vaccByRegion" && <VaccByRegionReport />}
						{UIStore.reportDetailTab === "draftReport" && <DraftReportList />}
						{UIStore.reportDetailTab === "DailyReport" && <DailyReportList />}
						{UIStore.reportDetailTab === "SicknessAbs" && <SicknessAbsReportList />}
						{UIStore.reportDetailTab === "NCZI" && <NCZIReportList />}
						{UIStore.reportDetailTab === "NCZIA01" && <NcziA01ReportList />}
						{UIStore.reportDetailTab === "NCZIU01" && <NcziU01ReportList />}
						{UIStore.reportDetailTab === "EUPatients" && <EUPatients />}
						{UIStore.reportDetailTab === "PatientWithoutPP" && <PatientWithoutPPList />}
						{UIStore.reportDetailTab === "PatientWithPP" && <PatientWithPPList />}
						{UIStore.reportDetailTab === "Vaccination" && <VaccReportsContainer />}
						{UIStore.reportDetailTab === "drugReport" && <DrugReportContainer />}
						{UIStore.reportDetailTab === "recordsWithoutDeliveries" && <RecordsWithoutDeliveriesList />}
						{UIStore.reportDetailTab === "hospicomReportList" && <HospicomReportList />}
						{UIStore.reportDetailTab === "labResultReport" && <LabResult />}
						{UIStore.reportDetailTab === "notAllowedDeliveries" && <NotAllowedDeliveriesList />}
						{UIStore.reportDetailTab === "medicalRecordWithDGN" && <MedicalRecordList />}
						{UIStore.reportDetailTab === "deliveriesByCode" && <DeliveriesByCodeList />}
						{UIStore.reportDetailTab === "sms" && <SmsReportList />}
						{UIStore.reportDetailTab === "vaccinationCountByDelivery" && <VaccinationCountByDeliveryList />}
					</Grid>
				</Grid>
			</Grid>
		)
	}
}
