import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "medical_findings_parkingcard"
				},

				name: {},
				birthdate: {},
				address: {},

				///
				anamnesis: {
					label: <FormattedMessage id="Common.label.anamnes" />
				},

				subjectiveDifficulties: {
					label: <FormattedMessage id="Common.label.subjectiveDifficulties" />
				},

				height: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsVyska" />
				},

				weight: {
					label: <FormattedMessage id="PrintTemplate.Spa.lsHmotnost" />
				},

				bmi: {
					label: "BMI"
				},

				tk: {
					label: "TK"
				},

				pulse: {
					label: "P"
				},

				habitus: {
					label: "Habitus"
				},

				orientation: {
					label: <FormattedMessage id="Common.label.orientation" />
				},

				position: {
					label: <FormattedMessage id="Common.label.position" />
				},

				posture: {
					label: <FormattedMessage id="Common.label.posture" />
				},

				walk: {
					label: <FormattedMessage id="Common.label.walk" />
				},

				continenceDisorder: {
					label: <FormattedMessage id="Common.label.continenceDisorder" />
				},

				iiA: {
					label: "II. A"
				},

				iiB: {
					label: "II. B"
				},

				iii: {
					label: "III. Diagnostický záver"
				},

				in: {
					label: <FormattedMessage id="PrintTemplate.PreventiveExaminationAdults.miesto" />
				},

				date: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.date" />,
					value: moment(),
					type: "date"
				}
			}
		}
	}
}
