"use strict"

import {observable} from "mobx"

class InsuranceFormStore {
	@observable editable = false
	@observable isAdd = false

	editInsuranceId = undefined
}

var singleton = new InsuranceFormStore()
export default singleton
