import React from "react"
import {FormattedMessage} from "react-intl"

export default {
	load() {
		return {
			fields: {
				// invoiceNumber: {
				//   label: <FormattedMessage id="Invoice.form.number" />,
				//   rules: "digits:10"
				// },
				batchType: {
					label: <FormattedMessage id="Delivery.List.BatchType" />,
					rules: "required",
					value: "751a"
				},
				payerType: {
					label: <FormattedMessage id="Common.label.payerType" />
				},
				// disposition: {
				//   label: <FormattedMessage id="Delivery.List.Disposition" />,
				//   rules: "required"
				// },
				// insurers:{
				//   label: <FormattedMessage id="Delivery.List.Disposition" />,
				//   rules: "required"
				// },
				rows: {}
			}
		}
	}
}
