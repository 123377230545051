// import api from "../actions/api"
// import moment from "moment"
import {observable, action} from "mobx"
import moment from "moment"
import settings from "../../../global/config/settings"
import {getUserCompanyInfo} from "../../../global/helpers/actions"

// import settings from "../../../global/config/settings"

class VaccinationListStore {
	@observable insurersData = []
	@observable filters = []
	@observable vaccFilters = []
	@observable vaccinatedFilters = []
	@observable tabCard = "vacc"

	formRef = undefined
	vaccFormRef = undefined
	vaccinatedFormRef = undefined

	medicationReportListRef = null
	vaccReportListRef = null
	vaccinatedReportListRef = null

	@observable changedFilters = false
	currFilters = {}

	@action isChangedFilterMedication(formValues) {
		let isChangeDateFrom = compareMomentDate(this.currFilters.date_from, formValues.date_from)
		let isChangeDateTo = compareMomentDate(this.currFilters.date_to, formValues.date_to)

		if (
			isChangeDateFrom ||
			isChangeDateTo ||
			this.currFilters.sub_type != formValues.sub_type ||
			this.currFilters.identifier != formValues.identifier ||
			this.currFilters.org_unit != formValues.org_unit
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action getFiltersMedication(form) {
		this.currFilters = form.values()

		let filters = []

		if (isNotEmpty(form.$("org_unit").value)) {
			filters.push({associated_column: "provider_id", values: [{id_value: form.$("org_unit").value}]})
		}

		if (isNotEmpty(form.$("identifier").value)) {
			filters.push({associated_column: "identifier", values: [{id_value: form.$("identifier").value}]})
		}

		if (isNotEmpty(form.$("date_from").value)) {
			filters.push({
				associated_column: "date_from",
				values: [{id_value: moment(form.$("date_from").value).format("YYYY-MM-DD")}]
			})
		}

		if (isNotEmpty(form.$("date_to").value)) {
			filters.push({
				associated_column: "date_to",
				values: [{id_value: moment(form.$("date_to").value).format("YYYY-MM-DD")}]
			})
		}

		if (isNotEmpty(form.$("sub_type").value)) {
			filters.push({associated_column: "sub_type_id", values: [{id_value: form.$("sub_type").value}]})
		}

		this.filters = filters

		this.isChangedFilterMedication(form.values())
	}

	@action isChangedFilterVacc(formValues) {
		let isChangeDateFrom = compareMomentDate(this.currFilters.date_from, formValues.date_from)
		let isChangeDateTo = compareMomentDate(this.currFilters.date_to, formValues.date_to)

		if (
			isChangeDateFrom ||
			isChangeDateTo ||
			this.currFilters.org_unit != formValues.org_unit ||
			this.currFilters.vacc_code != formValues.vacc_code ||
			this.currFilters.daysFromLastDose != formValues.daysFromLastDose
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action getFiltersVacc(form) {
		this.currFilters = form.values()

		let filters = []

		filters.push({associated_column: "company_id", values: [{id_value: getUserCompanyInfo()._id}]})

		if (isNotEmpty(form.$("org_unit").value)) {
			filters.push({associated_column: "provider_id", values: [{id_value: form.$("org_unit").value}]})
		}

		if (isNotEmpty(form.$("vacc_code").value)) {
			filters.push({associated_column: "vaccine_code_ext", values: [{id_value: form.$("vacc_code").value}]})
		}

		if (isNotEmpty(form.$("daysFromLastDose").value)) {
			filters.push({associated_column: "range_days", values: [{id_value: form.$("daysFromLastDose").value}]})
		}

		if (isNotEmpty(form.$("date_from").value)) {
			filters.push({
				rootObject: true,
				associated_column: "time_from",
				values: moment(form.$("date_from").value).format(settings.DB_DATETIME_FORMAT)
			})
		}

		if (isNotEmpty(form.$("date_to").value)) {
			filters.push({
				rootObject: true,
				associated_column: "time_to",
				values: moment(form.$("date_to").value).format(settings.DB_DATETIME_FORMAT)
			})
		}

		this.vaccFilters = filters

		this.isChangedFilterVacc(form.values())
	}

	@action isChangedFilterVaccinated(formValues) {
		let isChangeDateFrom = compareMomentDate(this.currFilters.date_from, formValues.date_from)
		let isChangeDateTo = compareMomentDate(this.currFilters.date_to, formValues.date_to)

		if (
			isChangeDateFrom ||
			isChangeDateTo ||
			this.currFilters.org_unit != formValues.org_unit ||
			this.currFilters.doseNumber != formValues.doseNumber ||
			this.currFilters.user != formValues.user
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action getFiltersVaccinated(form) {
		this.currFilters = form.values()

		let filters = []

		filters.push({associated_column: "company_id", values: [{id_value: getUserCompanyInfo()._id}]})

		if (isNotEmpty(form.$("org_unit").value)) {
			filters.push({associated_column: "provider_id", values: [{id_value: form.$("org_unit").value}]})
		}

		// if (isNotEmpty(form.$("vacc_code").value)) {
		// 	filters.push({associated_column: "vaccine_code_ext", values: [{id_value: form.$("vacc_code").value}]})
		// }

		if (isNotEmpty(form.$("doseNumber").value)) {
			filters.push({associated_column: "vacc_order", values: [{id_value: form.$("doseNumber").value}]})
		}

		if (isNotEmpty(form.$("user").value)) {
			filters.push({associated_column: "user_login", values: [{id_value: form.$("user").value}]})
		}

		if (isNotEmpty(form.$("date_from").value)) {
			filters.push({
				rootObject: true,
				associated_column: "time_from",
				values: moment(form.$("date_from").value).format(settings.DB_DATETIME_FORMAT)
			})
		}

		if (isNotEmpty(form.$("date_to").value)) {
			filters.push({
				rootObject: true,
				associated_column: "time_to",
				values: moment(form.$("date_to").value).format(settings.DB_DATETIME_FORMAT)
			})
		}

		this.vaccFilters = filters

		this.isChangedFilterVaccinated(form.values())
	}
}
var singleton = new VaccinationListStore()
export default singleton
