import React from "react"
import {observer} from "mobx-react"
import moment from "moment"
import ReactTooltip from "react-tooltip"
import {FormattedMessage} from "react-intl"

import EditEventDialogStore from "../../stores/EditEventDialogStore"
// import DataStore from "../../stores/DataStore"
import CalendarStore from "../../stores/CalendarStore"
import OtherDayDialogStore from "../../stores/OtherDayDialogStore"
import AddEventDialogStore from "../../stores/AddEventDialogStore"
import {isInThePast} from "../../../../global/ui/xsDateTimePicker/helpers"

@observer
export default class MonthlyView extends React.Component {
	constructor(props) {
		super(props)
	}

	getDayClass = (date) => {
		let dayClass = moment(date).isSame(new Date(), "date") ? "xs-day xs-active" : "xs-day"
		if (!moment(date).isSame(CalendarStore.activeMonth, "month")) {
			dayClass += " xs-otherMonth"
		}

		if (
			moment(date)
				.add(1, "day")
				.isBefore(moment())
		) {
			dayClass += " xs-dayInPast"
		}

		if (moment(date).day() === 0 || moment(date).day() === 6) {
			dayClass += " xs-weekend"
		}

		return dayClass
	}

	formatDayNumber = (date) => {
		const format = +moment(date).format("D") === 1 ? "D.MMMM" : "D"
		return moment(date).format(format)
	}

	openAddEventDialog = (day, month, year) => {
		if (EditEventDialogStore.isClickedEdit) {
			EditEventDialogStore.isClickedEdit = false
		} else {
			if (
				moment()
					.set("year", year)
					.set("month", month)
					.set("date", day)
					.isSameOrAfter(moment())
			) {
				CalendarStore.selectedDay = moment()
					.set("year", year)
					.set("month", month)
					.set("date", day)
					.set("hour", 0)
					.set("minute", 0)
					.set("second", 0)
				CalendarStore.inputDateTimeIsValid = moment()
					.set("year", year)
					.set("month", month)
					.set("date", day)
					.set("hour", 0)
					.set("minute", 0)
					.set("second", 0)
					.format("DD.MM.YYYY HH:mm")
				AddEventDialogStore.open()
			}
		}
	}

	render() {
		const expandTooltip = CalendarStore.calendarToggledFull ? "hideCalendar" : "showAllCalendar"

		return (
			<div className="xs-month-view">
				<div className="xs-month-header">
					<div className="xs-days">
						{moment.weekdays(true).map((weekend, idx) => {
							return (
								<div key={idx} className="xs-weekday">
									{weekend}
								</div>
							)
						})}
					</div>
				</div>
				<div className={`xs-month-body ${CalendarStore.calendarToggledFull ? "" : "xs-fix"}`}>
					{isSafe(CalendarStore.monthlyEvents) &&
						isSafe(CalendarStore.eventsData) &&
						CalendarStore.monthlyEvents.map((date, i) => {
							let inThePastClass = moment()
								.add(-1, "days")
								.isAfter(date)
								? "xs-in-the-past"
								: ""

							let countEvents = 0
							const day = moment(date).date()
							const month = moment(date).month()
							const year = moment(date).year()

							return (
								<div
									key={i}
									className={this.getDayClass(date)}
									onClick={() => this.openAddEventDialog(day, month, year)}
								>
									<div className="xs-date-number">
										<span>{this.formatDayNumber(date)}</span>
									</div>
									{isSafe(CalendarStore.eventsData[month]) &&
										isSafe(CalendarStore.eventsData[month][day]) &&
										Array.isArray(CalendarStore.eventsData[month][day]) &&
										CalendarStore.eventsData[month][day].map((event, idx) => {
											countEvents++
											if (countEvents <= 4) {
												const {services, status, client, interval_from, event_note_text} = event
												const service =
													isSafe(services) && services.length > 0 && isSafe(services[0]) ? services[0] : null
												let bgColor = isSafe(service) && isNotEmpty(service.back_color) ? service.back_color : "#808080"
												let fontColor = isNotEmpty(service.fore_color) ? service.fore_color : undefined
												let isEventEnd = isSafe(status) && status._id === "F" ? true : false

												let inThePast = isSafe(service) ? isInThePast(date, service.duration) : false
												let colors = CalendarStore.invertColor(bgColor, fontColor, isEventEnd, inThePast)

												let lastName = isNotEmpty(client.last_name) ? client.last_name.toUpperCase() : ""

												return (
													<div
														key={`${i}_${idx}`}
														className={`xs-event-item ${inThePastClass}`}
														onClick={() => {
															EditEventDialogStore.isClickedEdit = true
															EditEventDialogStore.open(event)
														}}
														style={inThePastClass ? {} : {backgroundColor: colors.backgroundColor, color: colors.color}}
													>
														<div className="xs-event-info">
															<div className="xs-full-name">
																{isEmpty(client.last_name) && isEmpty(client.first_name) ? (
																	client.client_text
																) : (
																	<span>
																		<span className="xs-lastname">{lastName}</span>
																		{" " + client.first_name} ({moment(interval_from).format("HH:mm")})
																	</span>
																)}
															</div>
															<div className="xs-sub-info">{client.identifier + " " + service.service_name_ext}</div>
															{isNotEmpty(event_note_text) && <div>{event_note_text}</div>}
														</div>
														{inThePast && isEventEnd && (
															<div className="xs-state-icon">
																<i className="fas fa-user-check fa-lg greenSuccess" data-tip data-for="eventEnd" />
																<ReactTooltip id="eventEnd">
																	<FormattedMessage id="Common.label.eventEnd" />
																</ReactTooltip>
															</div>
														)}
													</div>
												)
											}
										})}
									{countEvents > 4 && (
										<div
											data-tip
											data-for="showAllOrderPatient"
											className="xs-more-events"
											onClick={() => {
												EditEventDialogStore.isClickedEdit = true
												OtherDayDialogStore.loadSelectedDay(moment(date))
												window.scrollTo(0, 0)
											}}
										>
											+ {countEvents - 4}
											<ReactTooltip id="showAllOrderPatient">
												<FormattedMessage id="Common.label.showAllOrderPatient" />
											</ReactTooltip>
										</div>
									)}
								</div>
							)
						})}
				</div>
				<div
					data-tip
					data-for={expandTooltip}
					className="xs-full-screen"
					onClick={() => (CalendarStore.calendarToggledFull = !CalendarStore.calendarToggledFull)}
				>
					<i className={`fal fa-chevron-${CalendarStore.calendarToggledFull ? "up" : "down"} fa-lg"`} />
				</div>
				<ReactTooltip id={expandTooltip}>
					<FormattedMessage id={`Common.label.${expandTooltip}`} />
				</ReactTooltip>
			</div>
		)
	}
}
