import React from "react"
import {observer} from "mobx-react"

import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
// import moment from "moment"

import {FormattedMessage, injectIntl} from "react-intl"

import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
// import XsTable from "../../../../../global/ui/xsTable/xsTable"

import Grid from "@material-ui/core/Grid"

import fields from "./employeeUserFormFields"
import bindings from "./employeeUserFormBinding"
import {insertFormDataByScheme} from "../../../../../global/helpers/bindings"

import EmployeesDetailStore from "../../../stores/EmployeesDetailStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

@injectIntl
@observer
export default class EmployeeUserForm extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit(/*form*/) {
				// special validation here
			},
			onSuccess(form) {
				insertFormDataByScheme(
					form,
					bindings,
					{entityId: props.entityId},
					() => {
						EmployeesDetailStore.loadSpecificUser(form, props.entityId), (EmployeesDetailStore.editUserForm = false)
					},
					false,
					"EmployeeDetail.employeeInfoForm.saved"
				)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		EmployeesDetailStore.employeeUserId = null
		EmployeesDetailStore.externalCredentials = []
		EmployeesDetailStore.loadSpecificUser(this.form, this.props.entityId)
		// loadDataToFormByScheme(this.form, bindings, { userId: props.entityId })
	}

	render() {
		return (
			<Grid container spacing={8} className="mb-6">
				<Grid item xs={2}>
					<XsInput disabled={!EmployeesDetailStore.editUserForm} field={this.form.$("login")} />
				</Grid>
				<Grid item xs={2}>
					<XsInput disabled={!EmployeesDetailStore.editUserForm} field={this.form.$("password")} type="password" />
				</Grid>
				<Grid item xs={2}>
					<XsInput disabled={!EmployeesDetailStore.editUserForm} field={this.form.$("password2")} type="password" />
				</Grid>
				<Grid item xs={2}>
					<XsInput disabled={!EmployeesDetailStore.editUserForm} field={this.form.$("email")} />
				</Grid>
				<Grid item xs={1} className="mt-2">
					<XsCheckbox disabled={!EmployeesDetailStore.editUserForm} field={this.form.$("enabled")} />
				</Grid>
				<Grid item xs={1} className="mt-2">
					<XsCheckbox disabled={!EmployeesDetailStore.editUserForm} field={this.form.$("confirmed")} />
				</Grid>
				<Grid item xs={2} container alignItems="center" justify="flex-end" spacing={8} className="mt-0">
					{EmployeesDetailStore.editUserForm ? (
						<React.Fragment>
							<Grid item>
								<XsButton
									className="xs-success"
									text={<FormattedMessage id="Common.label.save" />}
									type="submit"
									onClick={this.form.onSubmit}
									icon={<i className="fal fa-check fa-lg" />}
								/>
							</Grid>
							<Grid item>
								<XsButton
									className="xs-danger xs-outline"
									onClick={() => {
										EmployeesDetailStore.editUserForm = false
									}}
									text={<FormattedMessage id="Common.label.cancel" />}
									icon={<i className="fal fa-times fa-lg" />}
								/>
							</Grid>
						</React.Fragment>
					) : (
						<Grid>
							<XsButton
								className="xs-primary xs-outline"
								text={<FormattedMessage id="Common.label.edit" />}
								type="submit"
								onClick={() => (EmployeesDetailStore.editUserForm = true)}
								icon={<i className="fal fa-pencil-alt fa-lg" />}
							/>
						</Grid>
					)}
				</Grid>
			</Grid>
		)
	}
}
