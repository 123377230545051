"use strict"
import api from "../actions/api"
import {observable, action} from "mobx"
import OrgunitDetailStore from "./OrgunitDetailStore"
import GlobalStore from "../../../global/store/GlobalStore"

class OrgunitBankStore {
	@observable editable = false
	@observable isAdd = false
	@observable banks = []

	@action loadBankTypes() {
		api
			.loadBankTypes()
			.call()
			.then((response) => {
				this.banks = response
			})
	}

	@action save(form, orgunitId, saveMessage) {
		let req = {
			_id: isNotEmpty(form.$("bankAccId").value) ? form.$("bankAccId").value : "",
			active: true,
			_ref: false,
			_type: "Fin.Data.BankAccount",
			treasurer: form.$("bank").value,
			type: form.$("type").value,
			prefix: form.$("prefix").value,
			number: form.$("number").value,
			primary: form.$("check").value,
			specific_rel: {
				_type: "Entity.Data.Client",
				_id: orgunitId
			}
		}

		api
			.saveBankAcc(orgunitId, req)
			.call()
			.then(() => {
				OrgunitDetailStore.loadOrgunitBankAcc(orgunitId)
				GlobalStore.setNotificationMessage(saveMessage)
				form.reset()
				this.editable = false
			})
	}

	@action delete(bankAccId, orgunitId) {
		let req = {
			_id: bankAccId,
			active: false,
			_ref: false,
			specific_rel: {
				_type: "Entity.Data.Client",
				_id: orgunitId
			}
		}

		api
			.saveBankAcc(orgunitId, req)
			.call()
			.then(() => OrgunitDetailStore.loadOrgunitBankAcc(orgunitId))
	}
}

var singleton = new OrgunitBankStore()
export default singleton
