import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import {FormattedMessage, injectIntl} from "react-intl"
import validatorjs from "validatorjs"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import "./exportDeliveries.less"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import SearchSelectNoForm from "../../../../../global/ui/xsSearchdropdown/xsSearchdropdownNoForm"

import fields from "./exportDeliveriesFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import ExportRepairBatchStore from "../../../stores/ExportRepairBatchStore"
import {Grid} from "@material-ui/core"

@injectIntl
@observer
export default class ExportRepairBatchDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {
				// special validation here
			},
			onSuccess(form) {
				ExportRepairBatchStore.downloadMultipleDeliveries(form)
			},
			onError() {}
		}
		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		ExportRepairBatchStore.formRef = this.form

		if (props.isOtherDeliveries) {
			this.form.$("payerType").set("rules", "required")
		}

		this.insurerSelectRef = null
	}

	onSubmitClick = () => {
		Object.entries(this.form.values()).forEach((obj) => {
			ExportRepairBatchStore.handleChange(obj[0], obj[1])
		})

		ExportRepairBatchStore.getSummaryDeliveries()
	}

	getInsurersForDropdown = () => {
		if (isSafe(this.props.insurers) && !ExportRepairBatchStore.insurersData.length) {
			this.props.insurers.forEach((row) => {
				ExportRepairBatchStore.insurersData.push({
					id: row._id,
					secondaryText: isNotEmpty(row.company_short_name) ? row.company_short_name : "  ",
					primaryText: row.description,
					search_string: row.search_column,
					checkValue: false
				})
			})
		}
		return ExportRepairBatchStore.insurersData
	}

	handleRows = () => {
		this.form.update({rows: []})
		ExportRepairBatchStore.insurers.forEach((ins) => {
			ExportRepairBatchStore.disposition.forEach((disp) => {
				this.form.$("rows").add(
					{
						key: ins.id + disp.id,
						label: `${this.props.intl.formatMessage({id: "Invoice.form.number"})} ${ins.secondaryText} ${
							disp.primaryText
						}`,
						type: "text",
						rules: "required"
					},
					true
				)
			})
		})
	}

	componentDidMount() {
		ExportRepairBatchStore.formRef = this.form
	}

	insertDefaultInsurer = () => {
		if (isNotEmpty(this.props.defaultInsurer)) {
			const insurers = this.getInsurersForDropdown()
			if (isSafe(insurers)) {
				const defaultData = insurers.find((row) => row.id == this.props.defaultInsurer)
				if (isSafe(defaultData)) {
					ExportRepairBatchStore.insurers = [defaultData]
					this.handleRows()
					if (
						isSafe(this.insurerSelectRef) &&
						isSafe(this.insurerSelectRef.state) &&
						isSafe(this.insurerSelectRef.state.items)
					) {
						let items = this.insurerSelectRef.state.items
						items.forEach((item) => {
							if (item.id == this.props.defaultInsurer) {
								item.checkValue = true
							}
						})

						this.insurerSelectRef.setState({items: items})
					}
				}
			}
		}
	}

	render() {
		return (
			<Dialog
				id="xsExportDeliveries"
				open={ExportRepairBatchStore.isOpen}
				onClose={() => ExportRepairBatchStore.close()}
				onEntered={() => {
					this.insertDefaultInsurer()
				}}
				disableBackdropClick={true}
				classes={{
					paper: "xs-paper-dialog"
				}}
				maxWidth="md"
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-base">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-save fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Capitation.form.ExportBatch" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<Grid container direction="column">
						<Grid item xs={12}>
							<Grid container direction="row">
								<Grid item xs={12}>
									<SearchSelect
										field={this.form.$("batchType")}
										items={[{name_ext: "751a", _id: "751a"}, {name_ext: "753a", _id: "753a"}]}
									/>
								</Grid>
							</Grid>
							{this.props.isOtherDeliveries && (
								<Grid container direction="row">
									<Grid item xs={12}>
										<SearchSelect
											field={this.form.$("payerType")}
											items={isSafe(this.props.payers) ? this.props.payers : []}
										/>
									</Grid>
								</Grid>
							)}
							<Grid container direction="row">
								<Grid item xs={12}>
									<div className="searchDropdownDiv">
										<div className="searchDropdownLabel">{<FormattedMessage id="Capitation.form.insurers" />}*</div>
										<SearchSelectNoForm
											refValue={(ref) => (this.insurerSelectRef = ref)}
											id="insurers"
											// field={this.form.$("insurers")}
											items={this.getInsurersForDropdown()}
											multiSelect={true}
											onClose={(data) => {
												// this.form.$("insurers").set(data)
												ExportRepairBatchStore.insurers = data
												this.handleRows()
											}}
										/>
									</div>
								</Grid>
							</Grid>
							<Grid container direction="row">
								<Grid item xs={12}>
									<div className="searchDropdownDiv">
										<div className="searchDropdownLabel">{<FormattedMessage id="Capitation.form.ReportType" />}*</div>
										<SearchSelectNoForm
											id="ReportType"
											items={ExportRepairBatchStore.dispositionData}
											multiSelect={true}
											onClose={(data) => {
												ExportRepairBatchStore.disposition = data
												this.handleRows()
											}}
										/>
									</div>
								</Grid>
							</Grid>
							<Grid container direction="row">
								<Grid item xs={12}>
									{this.form.$("rows").map((fieldRow) => {
										return <XsInput field={fieldRow} key={fieldRow.key} />
									})}
									{/* <XsInput field={this.form.$("invoiceNumber")}
                    placeholder={this.props.intl.formatMessage({ id: "Invoice.form.optionalInvoiceNumber" })}
                  /> */}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => (ExportRepairBatchStore.close(), this.form.reset())}
					/>
					<XsButton
						className={
							ExportRepairBatchStore.insurers.length == 0 ||
							ExportRepairBatchStore.disposition.length == 0 ||
							!this.form.isValid
								? "xs-default"
								: "xs-success"
						}
						disabled={
							ExportRepairBatchStore.insurers.length == 0 ||
							ExportRepairBatchStore.disposition.length == 0 ||
							!this.form.isValid
						}
						icon={<i className="fal fa-save" />}
						text={<FormattedMessage id="Capitation.form.ExportBatch" />}
						onClick={(e) => this.form.onSubmit(e)}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
