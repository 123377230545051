import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
// import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import fields from "./agreementPartCopyDialogFields"

import {Dialog, DialogContent, DialogTitle, DialogActions, Grid} from "@material-ui/core"

import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import XsButton from "../../../../../global/ui/xsButton/xsButton"

import AgreementPartCopyStore from "../../../stores/AgreementPartCopyStore"

@observer
export default class AgreementPartCopyDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				AgreementPartCopyStore.copyPart(form)
			},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		AgreementPartCopyStore.agreementPartCopyFormRef = this.form
	}

	render() {
		return (
			<Dialog
				id="xsAgreementPartCopy"
				open={AgreementPartCopyStore.isOpen}
				onClose={() => AgreementPartCopyStore.close()}
				className="xs-base-dialog"
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-base">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-copy fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Agreement.CopyPart.dialog.headerMessage" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent>
					<div className="xs-content xs-agreementPart-copy">
						<Grid container direction="column">
							<Grid item xs={12} className="xs-question-text">
								<FormattedMessage id="Agreement.CopyPart.dialog.Message" />
							</Grid>
							<Grid item xs={12}>
								<Grid container direction="row" alignItems="center">
									<Grid item xs={9}>
										<FormattedMessage id="Agreement.CopyPart.dialog.dateFrom" />:
									</Grid>
									<Grid item xs={3}>
										<XsDateTimePicker field={this.form.$("from")} />
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid container direction="row" className="xs-align">
									<Grid item xs={9}>
										<FormattedMessage id="Agreement.CopyPart.dialog.endOfLastPart" />
									</Grid>
									<Grid item xs={3}>
										<XsCheckbox field={this.form.$("end_part")} />
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</div>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times fa-lg" />}
						text={<FormattedMessage id="Common.btn.not.copy" />}
						onClick={() => AgreementPartCopyStore.close()}
					/>
					<XsButton
						className="xs-success"
						text={<FormattedMessage id="Common.btn.agreement.copyAgreementPart" />}
						icon={<i className="fal fa-copy fa-lg" />}
						onClick={this.form.onSubmit}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
