import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"

import fields from "./agreementHealthCareFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
// import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsAutocomplete from "../../../../../../global/ui/xsInput/xsAutocomplete"
import SearchSelect from "../../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import api from "../../../../actions/api"
import {getFilters} from "../../../../../../global/helpers/api"

@injectIntl
@observer
export default class AgreementHealthCareTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.state = {
			doctorOUPZSItems: []
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		if (isEmpty(this.form.$("first_nameForm").value) && isNotEmpty(this.form.$("first_name").value)) {
			this.form.$("first_nameForm").value = this.form.$("first_name").value
		}
		if (isEmpty(this.form.$("last_nameForm").value) && isNotEmpty(this.form.$("last_name").value)) {
			this.form.$("last_nameForm").value = this.form.$("last_name").value
		}
		if (isEmpty(this.form.$("addressForm").value) && isNotEmpty(this.form.$("address").value)) {
			this.form.$("addressForm").value = this.form.$("address").value
		}
		if (isEmpty(this.form.$("phoneForm").value) && isNotEmpty(this.form.$("phone").value)) {
			this.form.$("phoneForm").value = this.form.$("phone").value
		}
		if (isEmpty(this.form.$("emailForm").value) && isNotEmpty(this.form.$("email").value)) {
			this.form.$("emailForm").value = this.form.$("email").value
		}
	}

	render() {
		return (
			<div className="pa-4">
				<Grid container direction="column" align="top">
					<div style={{marginBottom: "20px", fontWeight: "bold"}}>
						Časť - Dohoda o poskytovaní zdravotnej starostlivosti
					</div>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsInput field={this.form.$("first_nameForm")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("last_nameForm")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("phoneForm")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("emailForm")} />
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("addressForm")} />
					</Grid>

					<div style={{marginBottom: "20px", marginTop: "20px", fontWeight: "bold"}}>
						Časť - Odstúpenie od dohody o poskytovaní zdravotnej starostlivosti
					</div>

					<div style={{marginBottom: "20px", marginTop: "20px"}}>Údaje pôvodného lekára:</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={12}>
							<XsInput field={this.form.$("oldDoctor")} />
						</Grid>
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={6} className="xs-autocomplete-element">
							<XsAutocomplete
								field={this.form.$("oldDoctorExpertiseId")}
								minLengthForSearch="3"
								clearText
								api={api.loadPersonnel}
								filterValue={"personnel_expertise_id"}
								inputRenderer={(obj, menuItem = false) =>
									(isNotEmpty(obj.preferred_provider_code) && obj.preferred_provider_code.charAt(9) == "2" && menuItem
										? "<b style=color:#00A787>"
										: "") +
									(obj.identifier ? obj.identifier + " / " : "") +
									obj.full_name +
									" / " +
									(obj.expertise_code ? obj.expertise_code + " " : "") +
									obj.expertise +
									(isNotEmpty(obj.preferred_provider_code) ? " / " + obj.preferred_provider_code : "") +
									(isNotEmpty(obj.preferred_provider_code) && obj.preferred_provider_code.charAt(9) == "2" && menuItem
										? "</b>"
										: "")
								}
								saveValue={(obj) => obj.personnel_expertise_id}
								postAction={(obj, firstLoad) => {
									if (!firstLoad) {
										this.form.$("oldDoctorId").value = ""
										this.form.$("oldDoctorACode").value = ""
										this.form.$("oldDoctorOUPZS").value = ""
										this.form.$("oldDoctorFullName").value = ""
										this.setState({doctorOUPZSItems: []})
									}
									this.form.$("oldDoctorId").value = obj.personnel_id
									this.form.$("oldDoctorACode").value = obj.identifier
									this.form.$("oldDoctorFullName").value = obj.full_name

									if (isNotEmpty(obj.personnel_expertise_id)) {
										const searchRequest = getFilters([`personnel_expertise_id=${obj.personnel_expertise_id}`])
										api
											.loadProviders(searchRequest)
											.call()
											.then((response) => {
												if (isSafe(response) && isSafe(response.rows)) {
													let items = []
													response.rows.forEach((item) => {
														items.push({_id: item.provider_id, name_ext: item.provider_code})
													})
													this.setState({doctorOUPZSItems: items})
													let doctorOUPZS
													let preferredOUPZS = null
													if (isSafe(response.rows) && response.rows.length > 1) {
														preferredOUPZS = response.rows.find((row) => {
															return row.code_ext === obj.preferred_provider_code
														})
													}
													if (response.rows.length === 1 || isSafe(preferredOUPZS)) {
														if (response.rows.length === 1) {
															doctorOUPZS =
																isEmpty(response.rows[0].provider_id) === "" ? null : response.rows[0].provider_id
														} else {
															doctorOUPZS = preferredOUPZS.provider_id
														}
														if (!firstLoad) {
															this.form.$("oldDoctorOUPZS").value = doctorOUPZS
															const codeItem = items.find((x) => x._id == doctorOUPZS)
															if (isSafe(codeItem)) {
																this.form.$("oldDoctorPCode").value = codeItem.name_ext
															}
														}
													}
												}
											})
									}
								}}
								onClear={() => {
									this.form.$("oldDoctorId").value = ""
									this.form.$("oldDoctorACode").value = ""
									this.form.$("oldDoctorOUPZS").value = ""
									this.form.$("oldDoctorFullName").value = ""
									this.setState({doctorOUPZSItems: []})
								}}
							/>
						</Grid>
						<Grid item xs={6} className="xs-autocomplete-element">
							{isSafe(this.state.doctorOUPZSItems) && this.state.doctorOUPZSItems.length > 0 ? (
								<SearchSelect
									field={this.form.$("oldDoctorOUPZS")}
									disabled={isEmpty(this.form.$("oldDoctorId").value)}
									items={this.state.doctorOUPZSItems}
									onChange={(val) => {
										const code = this.state.doctorOUPZSItems.find((x) => x._id == val).name_ext
										this.form.$("oldDoctorPCode").value = code
									}}
								/>
							) : (
								<XsAutocomplete
									field={this.form.$("oldDoctorOUPZS")}
									minLengthForSearch="3"
									api={api.loadProviders}
									clearText
									disabled={isEmpty(this.form.$("oldDoctorId").value)}
									filterValue={"provider_id"}
									inputRenderer={(obj) =>
										(obj.provider_code ? obj.provider_code + " / " : "") +
										obj.company_name +
										" / " +
										(obj.expertise_code ? obj.expertise_code + " " : "") +
										obj.expertise_name_ext
									}
									saveValue={(obj) => obj.provider_id}
									postAction={(obj) => {
										this.form.$("oldDoctorPCode").value = obj.provider_code
									}}
								/>
							)}
						</Grid>
					</Grid>

					{/* <div style={{marginBottom: "20px", marginTop: "20px", fontWeight: "bold"}}>
						Časť - Žiadosť o odovzdanie zdravotnej dokumentácie
					</div>

					<Grid item xs={4}>
						<XsDateTimePicker field={this.form.$("newDate")} showTimeSelect={false} />
					</Grid>

					<Grid container direction="row" align="left" spacing={8} style={{marginTop: "20px"}}>
						<Grid item xs={4}>
							<XsDateTimePicker field={this.form.$("date")} showTimeSelect={false} />
						</Grid>
					</Grid> */}
				</Grid>
			</div>
		)
	}
}
