// import React from "react"
// import {FormattedMessage} from "react-intl"

export default {
	load() {
		return {
			fields: {
				from: {
					label: " ",
					type: "date"
				},
				end_part: {
					label: " ",
					type: "checkbox"
				}
			}
		}
	}
}
