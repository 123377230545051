import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import "./xsDialog.less"

import XsButton from "../xsButton/xsButton"

import GlobalStore from "../../store/GlobalStore"

export default observer(
	({
		name,
		title,
		text,
		subtext,
		type,
		onConfirmation,
		confirmBtn,
		cancelBtn,
		headerConfig,
		onCancel,
		titleColor,
		specialContent,
		hideCancelButton = false
	}) => {
		const handleClose = () => {
			if (isSafe(onCancel) && typeof onCancel === "function") {
				onCancel()
			}
			GlobalStore.closeConfirmationDialog()
		}

		const handleDecline = () => {
			if (isSafe(onCancel) && typeof onCancel === "function") {
				onCancel()
			}
			GlobalStore.closeConfirmationDialog()
		}

		const handleConfirm = (e) => {
			if (isSafe(onConfirmation) && typeof onConfirmation === "function") {
				onConfirmation(e)
			}
			GlobalStore.closeConfirmationDialog()
		}

		const dialog =
			name === GlobalStore.confirmationDialogOpened ? (
				<Dialog
					// aria-labeledby="alert-dialog-title"
					// aria-describedby="alert-dialog-description"
					open={true}
					onEscapeKeyDown={() => handleClose()}
					maxWidth="md"
					className={`xs-confirmation-dialog ${name}`}
				>
					{isSafe(headerConfig) && isSafe(headerConfig.text) ? (
						<DialogTitle className={isNotEmpty(type) ? `xs-${type}` : ""}>
							<div className="xs-header">
								{isSafe(headerConfig.icon) && <div className="xs-header-icon">{headerConfig.icon}</div>}
								<div className="xs-header-title">
									{isSafe(headerConfig.values) ? (
										<FormattedMessage id={headerConfig.text} values={headerConfig.values} />
									) : (
										<FormattedMessage id={headerConfig.text} />
									)}
								</div>
								{isSafe(headerConfig.subtext) && (
									<div className="xs-header-subtitle">
										<FormattedMessage id={headerConfig.subtext} />
									</div>
								)}
							</div>
						</DialogTitle>
					) : (
						<DialogTitle>
							<div className="xs-header">
								<div className={`xs-header-title ${titleColor}`}>{title}</div>
							</div>
						</DialogTitle>
					)}
					<DialogContent className="xs-content">
						<div>{text}</div>
						<div className="xs-subtext">{subtext}</div>
						<div>{specialContent}</div>
					</DialogContent>
					<DialogActions className="xs-footer">
						{hideCancelButton === true ? (
							<div></div>
						) : (
							<React.Fragment>
								{isSafe(cancelBtn) && isSafe(cancelBtn.text) ? (
									<XsButton
										className={
											isNotEmpty(cancelBtn.type)
												? `xs-${cancelBtn.type} xs-outline action-confirmation-cancel mr-2`
												: "xs-default xs-outline action-confirmation-cancel mr-2"
										}
										icon={isSafe(cancelBtn.icon) ? cancelBtn.icon : null}
										text={<FormattedMessage id={cancelBtn.text} />}
										onClick={() => handleDecline()}
									/>
								) : (
									<XsButton
										className="xs-default xs-outline action-confirmation-decline mr-2"
										text={<FormattedMessage id="Common.dialog.No" />}
										onClick={() => handleDecline()}
									/>
								)}
							</React.Fragment>
						)}
						{isSafe(confirmBtn) && isSafe(confirmBtn.text) ? (
							<XsButton
								className={
									isNotEmpty(confirmBtn.type)
										? `xs-${confirmBtn.type} action-confirmation-confirm`
										: isNotEmpty(type)
										? `xs-${type} action-confirmation-confirm`
										: "xs-success action-confirmation-confirm"
								}
								icon={isSafe(confirmBtn.icon) ? confirmBtn.icon : null}
								text={<FormattedMessage id={confirmBtn.text} />}
								onClick={(e) => handleConfirm(e)}
							/>
						) : (
							<XsButton
								className="xs-success action-confirmation-confirm"
								text={<FormattedMessage id="Common.dialog.Yes" />}
								onClick={() => handleConfirm()}
							/>
						)}
					</DialogActions>
				</Dialog>
			) : null

		return dialog
	}
)
