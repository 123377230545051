import React from "react"
import {observer} from "mobx-react"

import UserForm from "./userForm"
import AdminStore from "../../../stores/AdminStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

@observer
export default class UserContainer extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<div className="userContainer">
				<h1>{isSafe(AdminStore.userInfo) && AdminStore.userInfo.full_name}</h1>
				{GlobalStore.BLConfigurationLoaded && <UserForm id={this.props.id} />}
			</div>
		)
	}
}
