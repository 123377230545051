import React from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import {observer} from "mobx-react"

import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import fields from "./problemListFilterFields"

import XsPeriodPicker from "../../../../../global/ui/xsPeriodPicker/xsPeriodPicker"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import ProblemListStore from "../../../stores/ProblemListStore"
import RegistersCacheStore from "../../../../../global/store/RegistersCacheStore"
import TableStore from "../../../stores/TableStore"

@injectIntl
@observer
export default class ProblemListFilter extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				if (
					isNotEmpty("problemList") &&
					TableStore.tables.hasOwnProperty("problemList") &&
					TableStore.tables["problemList"].hasOwnProperty("index")
				) {
					delete TableStore.tables["problemList"]["index"]
				}

				ProblemListStore.getFilters(form)
			},
			onError() {}
		}

		if (isEmpty(ProblemListStore.formRef)) {
			ProblemListStore.formRef = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		}

		this.form = ProblemListStore.formRef

		ProblemListStore.getFilters(this.form)
	}

	render() {
		ProblemListStore.isChangedFilter(this.form.values())

		return (
			<Grid item container justify="space-between" alignItems="center">
				<Grid item xs container spacing={8} alignItems="center">
					<Grid item xs={4}>
						<XsPeriodPicker
							white
							defaultValue={this.form.$("validity_from").value}
							onChange={(val) => {
								this.form.$("validity_from").value = val.from
								this.form.$("validity_to").value = val.to
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						<XsSearchSelect white field={this.form.$("insurer")} items={RegistersCacheStore.insurersRegister} />
					</Grid>
					<Grid item xs={3}>
						<XsInput white field={this.form.$("identifier")} />
					</Grid>
					<Grid item className="pb-0">
						<XsIconButton
							rect
							tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
							className="xs-default xs-outline"
							onClick={this.form.onSubmit}
							icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
						/>
					</Grid>
				</Grid>
				<Grid item xs container justify="flex-end" spacing={8}>
					<XsButton
						className={
							ProblemListStore.changedFilters || isEmpty(this.form.$("insurer").value)
								? "xs-default xs-outline"
								: "xs-primary"
						}
						disabled={ProblemListStore.changedFilters || isEmpty(this.form.$("insurer").value)}
						text={<FormattedMessage id="Capitation.form.ExportBatch" />}
						icon={<i className="fal fa-save fa-lg" />}
						onClick={() => ProblemListStore.downloadDispensarisation()}
					/>
				</Grid>
			</Grid>
		)
	}
}
