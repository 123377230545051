import {observable, action} from "mobx"
import api from "../actions/api"
import {ApiCall} from "../../../global/helpers/api"

class EnumeratorListStore {
	@observable enumeratorData = []
	@observable enumData = []

	@observable isOpen = false
	@observable enumId = undefined
	@observable description = ""

	formEnumFilterRef = undefined

	@action loadEnumerator(form) {
		this.isOpen = false

		let filters = []

		if (isNotEmpty(form.$("search_column").value)) {
			filters.push({associated_column: "search_column", values: [{id_value: form.$("search_column").value}]})
		}

		if (isNotEmpty(form.$("code_ext").value)) {
			filters.push({associated_column: "code_ext", values: [{id_value: form.$("code_ext").value}]})
		}

		let request = {
			filters: filters,
			row_count_show: 10000,
			row_offset: 1
		}

		api
			.loadAllEnumerators(request)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows)) {
					this.enumeratorData = res.rows
				} else {
					this.enumeratorData = []
				}
			})
	}

	@action openEnumList(dataRow) {
		this.enumId = dataRow._id
		this.description = dataRow.description

		this.loadCodeList()
	}

	@action loadCodeList(form = undefined) {
		let filters = []

		if (isSafe(form)) {
			if (isNotEmpty(form.$("search_column").value)) {
				filters.push({associated_column: "search_column", values: [{id_value: form.$("search_column").value}]})
			}

			if (isNotEmpty(form.$("code_ext").value)) {
				filters.push({associated_column: "code_ext", values: [{id_value: form.$("code_ext").value}]})
			}
		}

		let request = {
			filters: filters,
			row_count_show: 10000,
			row_offset: 1
		}

		let getEnumApi = new ApiCall(`/cl/${this.enumId}`, "POST", request)
		getEnumApi.call().then((res) => {
			this.isOpen = true
			if (isSafe(res) && isSafe(res.rows)) {
				this.enumData = res.rows
			} else {
				this.enumData = []
			}
		})
	}
}
var singleton = new EnumeratorListStore()
export default singleton
