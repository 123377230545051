"use strict"

import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import {FormattedMessage, injectIntl} from "react-intl"
import {withStyles} from "@material-ui/core/styles"

import XsButton from "../../../../../../global/ui/xsButton/xsButton"
import Grid from "@material-ui/core/Grid"
import fields from "./openingHoursFormFields"
import UIStore from "../../../../stores/UIStore"
import AdminStore from "../../../../stores/AdminStore"

import SaveIcon from "@material-ui/icons/Done"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsLoading from "../../../../../../global/ui/xsLoading/xsLoading"

const styles = () => ({
	centerTitle: {
		textAlign: "center"
	},
	weekName: {
		textAlign: "center",
		marginTop: "20px"
	},
	containerPadding: {
		margin: "14px 0 14px 0"
	},
	actionButtons: {
		marginTop: "20px"
	}
})

@observer
export class OpeningHoursForm extends React.Component {
	constructor(props) {
		AdminStore.loadOpeningHours()

		super(props)

		const hooks = {
			onSuccess() {
				UIStore.isFormSaving = true
			},
			onSubmit(form) {
				AdminStore.saveOpeningHours(form)
			},
			onError() {
				UIStore.isFormSaving = false
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]

	renderWeek = (weekOnly = false) => {
		const weekstart = this.props.intl.formatMessage({id: "Application.moment.weekStart"})
		const labelStyle = {display: "none"}
		this.setOpeningHours()

		const weekRows = this.weekdays.map((d) => {
			if ((weekOnly && d !== "saturday" && d !== "sunday") || !weekOnly) {
				return (
					<Grid container key={d} direction="row" spacing={8}>
						<Grid item xs={1} className={this.props.classes.weekName}>
							<FormattedMessage id={`Application.cl.${d}`} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$(`${d}from`)} labelStyle={labelStyle} type={"time"} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$(`${d}to`)} labelStyle={labelStyle} type={"time"} />
						</Grid>
						<Grid item xs={1} />
						<Grid item xs={2}>
							<XsInput field={this.form.$(`${d}closedfrom`)} labelStyle={labelStyle} type={"time"} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$(`${d}closedto`)} labelStyle={labelStyle} type={"time"} />
						</Grid>
					</Grid>
				)
			}
		})

		//Ak začína týždeň pondelok tak posunieme
		if (weekstart === "monday" && !weekOnly) {
			const shiftDay = weekRows.shift()
			weekRows.push(shiftDay)
		}
		return weekRows
	}

	setOpeningHours = () => {
		if (AdminStore.openingHours) {
			const oh = AdminStore.openingHours
			oh.map((d) => {
				const dayName = this.weekdays[d.day]
				let openedFrom = d.opened && d.opened.length > 0 ? d.opened[0].from : null
				let openedTo = d.opened && d.opened.length > 0 ? d.opened[0].to : null
				let closedFrom = d.closed && d.closed.length > 0 ? d.closed[0].from : null
				let closedTo = d.closed && d.closed.length > 0 ? d.closed[0].to : null

				if (openedFrom === "00:00:00" && openedTo === "00:00:00") {
					openedFrom = ""
					openedTo = ""
				}

				if (closedFrom === "00:00:00" && closedTo === "00:00:00") {
					closedFrom = ""
					closedTo = ""
				}

				this.form.$(`${dayName}from`).set("value", openedFrom)
				this.form.$(`${dayName}to`).set("value", openedTo)
				this.form.$(`${dayName}closedfrom`).set("value", closedFrom)
				this.form.$(`${dayName}closedto`).set("value", closedTo)
			})
		}
	}

	render() {
		return (
			<div className={`xs-openingHours ${this.props.classes.containerPadding}`}>
				{UIStore.isFormSaving && <XsLoading overlay={true} />}
				<div className="xs-opening-hours-form">
					<Grid container direction="row" spacing={8}>
						<Grid item xs={1} />
						<Grid item xs={4} className={this.props.classes.centerTitle}>
							<FormattedMessage id="Admin.codelist.openinghours" />
						</Grid>
						<Grid item xs={1} />
						<Grid item xs={4} className={this.props.classes.centerTitle}>
							<FormattedMessage id="Admin.codelist.openinghoursbreak" />
						</Grid>
					</Grid>
					{this.renderWeek()}
					<Grid container direction="row" spacing={8} className={this.props.classes.actionButtons}>
						<Grid item xs={9}>
							<XsButton
								className="xs-success"
								text={<FormattedMessage id="Common.label.save" />}
								type="submit"
								onClick={(e) => this.form.onSubmit(e)}
								icon={<SaveIcon />}
							/>
						</Grid>
					</Grid>
				</div>
			</div>
		)
	}
}

export default injectIntl(withStyles(styles)(OpeningHoursForm))
