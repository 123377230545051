"use strict"

import {observable, action} from "mobx"
// import moment = require("moment");

class PatientBloodTypeDialogStore {
	@observable isOpen = false
	@observable nameOfDeleteBloodType = ""
	recordId = null
	form = null

	@action open(id, bloodTypeID, blodTypeName, writtenAt) {
		this.isOpen = true
		this.nameOfDeleteBloodType = blodTypeName
		this.recordId = id
		if (isSafe(this.form)) {
			this.form.$("bloodType").value = bloodTypeID
			if (isNotEmpty(writtenAt)) {
				this.form.$("dateOfExam").value = writtenAt
			}
		}
	}

	@action close() {
		this.isOpen = false
		this.recordId = null
		this.nameOfDeleteBloodType = ""
	}
}

var singleton = new PatientBloodTypeDialogStore()
export default singleton
