import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../global/ui/globalUISchemeBindings"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"

import XsButton from "../../../../global/ui/xsButton/xsButton"
import XsCheckbox from "../../../../global/ui/xsCheckbox/xsCheckbox"
import XsSearchSelect from "../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsDateTimePicker from "../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import ExportBatchDialogStore from "../../stores/ExportBatchDialogStore"
import RegistersCacheStore from "../../../../global/store/RegistersCacheStore"
import GlobalStore from "../../../../global/store/GlobalStore"

@injectIntl
@observer
export default class ExportBatchDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				ExportBatchDialogStore.exportBatchToXls(form)
			},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					insurer_id: {
						label: <FormattedMessage id="Common.label.insurer" />
					},
					only_capitation: {
						label: <FormattedMessage id="Common.label.onlyCapitation" />,
						type: "checkbox",
						value: true
					},
					records_from: {
						label: <FormattedMessage id="Common.label.recordsFrom" />
					},
					records_to: {
						label: <FormattedMessage id="Common.label.recordsTo" />
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)
	}

	render() {
		return (
			<Dialog
				id="xsExportBatchDialog"
				open={ExportBatchDialogStore.isOpen}
				onClose={() => ExportBatchDialogStore.close(this.form)}
				className="xs-base-dialog dialog-newpatient"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-450"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-absolute xs-close-btn" onClick={() => ExportBatchDialogStore.close(this.form)}>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-file-excel fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.export515" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-overflow-visible">
					<Grid container direction="column" className="xs-form">
						<Grid item xs={12}>
							<XsCheckbox field={this.form.$("only_capitation")} />
						</Grid>
						<Grid item xs={12}>
							<XsSearchSelect white field={this.form.$("insurer_id")} items={RegistersCacheStore.insurersRegister} />
						</Grid>
						<Grid item xs={12}>
							<XsDateTimePicker field={this.form.$("records_from")} white />
						</Grid>
						<Grid item xs={12}>
							<XsDateTimePicker field={this.form.$("records_to")} white />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.form.close" />}
						onClick={() => ExportBatchDialogStore.close(this.form)}
					/>
					<XsButton
						className="xs-success"
						icon={<i className="fal fa-file-excel" />}
						text={<FormattedMessage id="Common.label.exportBatch" />}
						onClick={() => {
							const values = this.form.values()
							if (
								(isNotEmpty(values.records_from) || isNotEmpty(values.records_to)) &&
								(isEmpty(values.records_from) || isEmpty(values.records_to))
							) {
								GlobalStore.setNotificationMessage(
									"Musí byť vyplnený dátum od aj datum do, alebo ani jeden!",
									"",
									"warning"
								)
							} else {
								this.form.submit()
							}
						}}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
