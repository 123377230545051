"use strict"

import {observable, action} from "mobx"
import StorageEx from "../../../global/storage/storageEx"
import {getKeyInfo} from "../../../global/helpers/actions"

class StorageManagementDialogStore {
	@observable isOpen = false
	@observable unsavedData = []

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@action checkUnsavedData() {
		const data = StorageEx.getAllWithInfo(getKeyInfo())
		if (data.length !== this.unsavedData.length) {
			this.unsavedData = data.map((entry) => {
				return {
					type: entry[0].type,
					patientId: entry[0].patientId,
					patientFullName: entry[1].info.ClientFullName,
					lastModified: entry[1].info.LastModified
				}
			})
		}
	}
}

var singleton = new StorageManagementDialogStore()
export default singleton
