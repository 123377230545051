export default {
	load: {
		storeKey: "userLogin",
		params: ["entityId"],
		type: "Entity.Data.EntityUser"
		// flags: "CD"
	},
	scheme: {
		entity: {
			object: {
				scheme: {
					_id: {
						relation: "@entityId"
					}
				}
			}
		},
		user: {
			object: {
				descriptor: {
					_ref: false,
					active: true
				},
				scheme: {
					confirmed: {
						field: "confirmed"
					},
					email: {
						field: "email"
					},
					enabled: {
						field: "enabled"
					},
					login: {
						field: "login"
					},
					password_plain: {
						field: "password"
					},
					_id: {
						field: "userId"
					}
				}
			}
		},
		_id: {
			field: "entityUserId"
		}
	}
}
