"use strict"

import {observable, action} from "mobx"
import moment from "moment"

import settings from "../../../global/config/settings"
import {getUserCompanyInfo} from "../../../global/helpers/actions"
import api from "../actions/api"

class LabResultReportStore {
	@observable labResultData = []
	@observable changedFilters = false
	@observable isFetching = false

	currFilters = {}

	labResultReportListRef = null

	@action loadLabResult(form) {
		let formValues = form.values()
		this.currFilters = form.values()

		this.isChangedFilter(form.values())

		let filters = [{associated_column: "company_id", values: [{id_value: getUserCompanyInfo().id}]}]

		const req = {
			filters: filters
		}

		if (isNotEmpty(formValues.datefrom)) {
			req["time_from"] = moment(formValues.datefrom)
				.add(-1, "days")
				.endOf("day")
				.format(settings.DB_DATETIME_FORMAT)
		}

		if (isNotEmpty(formValues.dateto)) {
			req["time_to"] = moment(formValues.dateto)
				.endOf("day")
				.format(settings.DB_DATETIME_FORMAT)
		}

		if (isNotEmpty(formValues.orgunit)) {
			filters.push({associated_column: "provider_id", values: [{id_value: formValues.orgunit}]})
		}

		if (isNotEmpty(formValues.identifier)) {
			filters.push({associated_column: "identifier", values: [{id_value: formValues.identifier}]})
		}

		this.isFetching = true
		api
			.getLabResult(req)
			.call()
			.then((response) => {
				this.labResultData = response.rows
				this.isFetching = false
			})
			.catch(() => {
				this.isFetching = false
			})
	}

	@action isChangedFilter(formValues) {
		let isChangeDateFrom = compareMomentDate(this.currFilters.datefrom, formValues.datefrom)
		let isChangeDateTo = compareMomentDate(this.currFilters.dateto, formValues.dateto)

		if (
			isChangeDateFrom ||
			isChangeDateTo ||
			this.currFilters.orgunit != formValues.orgunit ||
			this.currFilters.identifier != formValues.identifier
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}
}

var singleton = new LabResultReportStore()
export default singleton
