import {observable, action} from "mobx"
import moment from "moment"

import {exportToExcel} from "../../../global/helpers/actions"
import {DB_DATETIME_FORMAT} from "../../../global/config/settings"

import api from "../actions/api"

class VaccByRegionReportStore {
	@observable filters = []

	formRef = undefined

	vaccListRef = null

	@observable changedFilters = false
	currFilters = {}

	@action isChangedFilter(formValues) {
		let isChangeDateFrom = compareMomentDate(this.currFilters.date_from, formValues.date_from)
		let isChangeDateTo = compareMomentDate(this.currFilters.date_to, formValues.date_to)

		if (isChangeDateFrom || isChangeDateTo || this.currFilters.place_ids != formValues.place_ids) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action getFilters(form) {
		this.currFilters = form.values()

		let filters = []

		if (isNotEmpty(form.$("date_from").value)) {
			filters.push({
				associated_column: "date_from",
				values: [{id_value: moment(form.$("date_from").value).format(DB_DATETIME_FORMAT)}]
			})
		}

		if (isNotEmpty(form.$("date_to").value)) {
			filters.push({
				associated_column: "date_to",
				values: [{id_value: moment(form.$("date_to").value).format(DB_DATETIME_FORMAT)}]
			})
		}

		if (isNotEmpty(form.$("place_ids").value)) {
			let values = [{id_value: form.$("place_ids").value.trim()}]

			let ids = null

			if (form.$("place_ids").value.includes(",")) {
				ids = form.$("place_ids").value.split(",")
			}

			if (isSafe(ids)) {
				let tempValues = []

				ids.forEach((id) => {
					if (isNotEmpty(id)) {
						tempValues.push({id_value: id.trim()})
					}
				})

				values = tempValues
			}

			filters.push({
				associated_column: "vaccPlace_id",
				values: values
			})
		}

		this.filters = filters

		this.isChangedFilter(form.values())
	}

	@action exportToExcel() {
		let filters = []

		const formVal = this.formRef.values()

		if (isNotEmpty(formVal.date_from)) {
			filters.push({
				associated_column: "date_from",
				values: [{id_value: moment(formVal.date_from).format("YYYY-MM-DD")}]
			})
		}

		if (isNotEmpty(formVal.date_to)) {
			filters.push({
				associated_column: "date_to",
				values: [{id_value: moment(formVal.date_to).format("YYYY-MM-DD")}]
			})
		}

		if (isNotEmpty(formVal.place_ids)) {
			let values = [{id_value: formVal.place_ids.trim()}]

			let ids = null

			if (formVal.place_ids.includes(",")) {
				ids = formVal.place_ids.split(",")
			}

			if (isSafe(ids)) {
				let tempValues = []

				ids.forEach((id) => {
					if (isNotEmpty(id)) {
						tempValues.push({id_value: id.trim()})
					}
				})

				values = tempValues
			}

			filters.push({
				associated_column: "vaccPlace_id",
				values: values
			})
		}

		logger("export to excel")
		let req = {
			uri: api.loadVaccReport().getPath(),
			filters: filters
		}

		req.row_offset = 1
		req.row_count_show = 100000

		exportToExcel(req)
	}
}
var singleton = new VaccByRegionReportStore()
export default singleton
