import React, {useState} from "react"
import {observer} from "mobx-react"
import XsExpansionPanel from "../../../../../../global/ui/xsExpansionPanel/xsExpansionPanel"
import {FormattedMessage, injectIntl} from "react-intl"
import Chip from "@material-ui/core/Chip"
import api from "../../../../actions/api"
import ReactTooltip from "react-tooltip"

import UIStore from "../../../../stores/UIStore"

import "./operationRow.less"

function OperationRow(props) {
	const {item, loadDeliveries, forceUpdate} = props

	let [forceOpenExp, setForceOpenExp] = useState(false)
	let [deliveriesLoaded, setDeliveriesLoaded] = useState(false)
	let [deliveries, setDeliveries] = useState()

	if (loadDeliveries && deliveriesLoaded === false) {
		setDeliveriesLoaded(true)
		api
			.loadRecord(item.record_id, "L3")
			.call()
			.then((response) => {
				let deliveries = response.general_items.filter(
					(i) => isSafe(i.item) && i.item._type === "EHR.CL.Bill.SK.Delivery"
				)

				setDeliveries(
					deliveries.map((val, key) => {
						return (
							<React.Fragment key={key}>
								<Chip
									key={key}
									label={val.item.code_ext}
									className="mr-1"
									data-tip
									data-for={`deliveryTip${item.record_id}${key}`}
								/>
								<ReactTooltip id={`deliveryTip${item.record_id}${key}`}>{val.item.name_ext}</ReactTooltip>
							</React.Fragment>
						)
					})
				)

				setForceOpenExp(true)
				setForceOpenExp(false)
			})
	} else if (loadDeliveries && deliveriesLoaded === true && forceOpenExp === false) {
		setForceOpenExp(true)
	}

	return (
		<XsExpansionPanel
			maxHeight={20}
			glOpen={UIStore.isOpenAllRecordsInEHROverview}
			forceOpen={forceOpenExp}
			forceUpdate={forceUpdate}
		>
			<div className="operationRow">
				<div dangerouslySetInnerHTML={{__html: item.data ? item.data.content : ""}} />
				{isSafe(deliveries) && (
					<div>
						<br />
						<div>
							<FormattedMessage id="Patient.form.patientrecord.deliveries" />:
						</div>
						{deliveries}
					</div>
				)}
			</div>
		</XsExpansionPanel>
	)
}

export default injectIntl(observer(OperationRow))
