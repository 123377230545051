"use strict"

import {observable, action} from "mobx"
import moment from "moment"

import {getUserPersonnelID, getSelectedOrgUnitID} from "../../../global/helpers/actions"

class CapitationListStore {
	@observable filters = []

	@observable exportFilters = {}

	@observable changedFilters = false
	currFilters = {}

	formRef = null

	@action isChangedFilter(formValues) {
		let isChangeDateFrom = compareMomentDate(moment(this.currFilters.validity_from), moment(formValues.validity_from))
		let isChangeDateTo = compareMomentDate(moment(this.currFilters.validity_to), moment(formValues.validity_to))

		if (
			isChangeDateFrom ||
			isChangeDateTo ||
			this.currFilters.identifier != formValues.identifier ||
			this.currFilters.insurer != formValues.insurer
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action getFilters() {
		let formValues = this.formRef.values()
		this.currFilters = this.formRef.values()

		let expFilter = {
			validity_from: formValues.validity_from,
			validity_to: formValues.validity_to
		}

		let filters = [
			{
				associated_column: "personnel_id",
				values: [{id_value: getUserPersonnelID()}]
			},
			{
				associated_column: "provider_id",
				values: [{id_value: getSelectedOrgUnitID()}]
			},
			{
				associated_column: "validity_from",
				values: [{id_value: formValues.validity_from}]
			},
			{
				associated_column: "validity_to",
				values: [{id_value: formValues.validity_to}]
			}
		]

		if (isNotEmpty(formValues.identifier)) {
			filters.push({
				associated_column: "identifier",
				values: [{id_value: formValues.identifier.trim()}]
			})
			expFilter["identifier"] = formValues.identifier.trim()
		}

		if (isNotEmpty(formValues.insurer)) {
			filters.push({
				associated_column: "insurer",
				values: [{id_value: formValues.insurer}]
			})
			expFilter["insurer"] = formValues.insurer
		}

		this.exportFilters = expFilter

		this.filters = filters
	}
}

var singleton = new CapitationListStore()
export default singleton
