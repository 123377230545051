export default {
	load: {
		storeKey: "PatientContactAddress",
		params: ["contactAddressId"],
		type: "Entity.Data.Address",
		flags: "CD"
	},
	scheme: {
		_id: {
			relation: "@addressId"
		},
		region: {
			codeList: {_id: "region"}
		},
		county: {
			codeList: {_id: "county"}
		},
		city_plain: {
			field: "cityText"
		},
		obj_city: {
			relation: "@city"
		},
		street_plain: {
			field: "street"
		},
		house_number: {
			field: "house_number"
		},
		primary: {
			field: "check"
		},
		obj_zip: {
			relation: "@zip"
		},
		zip_plain: {
			field: "zipText"
		},
		specific_rel: {
			relation: "@specrel"
		},
		// type: {
		//   field: "type"
		// },
		type: {
			object: {
				descriptor: {
					_id: "CA"
				}
			}
		}
		// type: "CA"
		// type: {
		//   codeList: { _id: "@type" }
		// }
		// county: {
		//   field: "county"
		// },
		// specific_rel: {
		//   relation: {
		//     _type: "Entity.Data.Client",
		//     _id: "#clientId"
		//   }
		// }
	}
}
