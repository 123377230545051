"use strict"

import {observable, action} from "mobx"

import GlobalStore from "../../../global/store/GlobalStore"

class PatientAllergiesDialogStore {
	@observable isOpen = false
	@observable isEditable = false
	@observable allergyObj = undefined
	@observable nameOfDeleteAllergy = ""

	allergyId

	@action open(allergyObj = undefined) {
		this.isOpen = true
		this.allergyObj = allergyObj
	}

	@action insertFormData(form) {
		const allergy = this.allergyObj

		this.isEditable = true
		this.allergyId = allergy._id

		const symIds = allergy.symptoms.map((x) => x.type._id)
		const symptomValues = GlobalStore.CL["symptoms"].filter((x) => symIds.includes(x._id)).map((x) => x._id)

		form.$("allergy").set("value", allergy.allergy)
		form.$("severity").set("value", allergy.code)
		form.$("allergenCode").set("value", allergy.allergenCode)
		form.$("atcCode").set("value", allergy.atcCode)
		form.$("symptoms").set("value", symptomValues)

		this.allergyObj = undefined
	}

	@action close() {
		this.isOpen = false
		this.nameOfDeleteAllergy = ""
	}
}

var singleton = new PatientAllergiesDialogStore()
export default singleton
