"use strict"

import {observable} from "mobx"

class ContractFormStore {
	@observable editable = false
	@observable isAdd = false
}

var singleton = new ContractFormStore()
export default singleton
