import React from "react"
import {observer} from "mobx-react"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import Grid from "@material-ui/core/Grid"
import {FormattedMessage} from "react-intl"

import OrgunitDetalStore from "../../../stores/OrgunitDetailStore"
import RouterStore from "../../../../../global/store/RouterStore"

@observer
export default class OrgunitsDetailEmployees extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Grid container className="pa-8">
				<Grid item xs={12}>
					<XsTable
						config={{
							columnDefs: {
								pID: {
									title: <FormattedMessage id="AttendeeListContainer.pIDCol" />,
									type: "string",
									design: {
										width: "175px"
									},
									sortable: true
								},
								full_name: {
									title: <FormattedMessage id="AttendeeListContainer.nameCol" />,
									type: "string",
									design: {
										width: "175px"
									},
									sortable: true
								},
								phone: {
									title: <FormattedMessage id="AttendeeListContainer.phonedCol" />,
									type: "string",
									design: {
										width: "175px"
									}
								},
								email: {
									title: <FormattedMessage id="Common.label.email" />,
									type: "string",
									design: {
										width: "175px"
									}
								}
							},
							options: {
								reverseColor: true,
								showCursor: true,
								hidePager: true,
								onRowClick: (dataRow) => {
									RouterStore.push(`/employee?id=${dataRow.relationship}&personId=${dataRow.personId}`)
								},
								mapper: (dataRow) => {
									return {
										pID: isSafe(dataRow.personal_number) ? dataRow.personal_number : "",
										full_name: isSafe(dataRow.full_name) ? dataRow.full_name : "",
										card: isSafe(dataRow.card_id) ? dataRow.card_id : "",
										phone:
											isSafe(dataRow.business_contact) && isSafe(dataRow.business_contact.mobile)
												? dataRow.business_contact.mobile
												: "",
										email:
											isSafe(dataRow.business_contact) && isSafe(dataRow.business_contact.email)
												? dataRow.business_contact.email
												: "",
										personId: dataRow.person_id,
										relationship: dataRow.relationship
									}
								}
							},
							dataSource: OrgunitDetalStore.orgunitDetailEmpl
						}}
					/>
				</Grid>
			</Grid>
		)
	}
}
