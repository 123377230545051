"use strict"

import {observable, action} from "mobx"
import {getSelectedOrgUnitID} from "../../../global/helpers/actions"
import moment from "moment"

// import UIStore from "../stores/UIStore"
import DataStore from "../stores/DataStore"
import GlobalStore from "../../../global/store/GlobalStore"

import api from "../actions/api"

class PatientPayDebtDialogStore {
	@observable isOpen = false
	@observable htmlContent = undefined
	@observable dlhCelkom = undefined

	@observable symbols = undefined
	email = ""
	phone = ""

	@observable message = undefined
	@observable steps = 1

	@action open() {
		this.steps = 1
		this.isOpen = true
	}

	@action testuj() {
		const request = {
			patient_id: DataStore.patientDTO.get("patientID"),
			provider_id: getSelectedOrgUnitID(),
			aj_detailny_rozpis: true
		}

		api
			.dajsumudlhu(request)
			.call()
			.then((response) => {
				if (isSafe(response)) {
					let byteCharacters = atob(response.pdf)
					let byteNumbers = new Array(byteCharacters.length)

					for (let i = 0; i < byteCharacters.length; i++) {
						byteNumbers[i] = byteCharacters.charCodeAt(i)
					}

					let byteArray = new Uint8Array(byteNumbers)
					let blob = new Blob([byteArray], {type: "application/pdf"})

					let FileSaver = require("file-saver") // eslint-disable-line no-undef
					FileSaver.saveAs(blob, "priloha")
				}

				this.steps = 2
			})
	}

	@action dajsumudlhu(ajDetailnyRozpis) {
		const request = {
			patient_id: DataStore.patientDTO.get("patientID"),
			provider_id: getSelectedOrgUnitID(),
			aj_detailny_rozpis: ajDetailnyRozpis
		}

		api
			.dajsumudlhu(request)
			.call()
			.then((response) => {
				if (isSafe(response)) {
					// let htmlContent = atob(response.pdf) //b64DecodeUnicode(response.pdf)
					this.dlhCelkom = response.dlh_celkom

					if (!ajDetailnyRozpis) {
						this.open()
					}
				}
			})
	}

	@action dajsymbolypreuhradu() {
		const request = {
			patient_id: DataStore.patientDTO.get("patientID"),
			provider_id: getSelectedOrgUnitID()
		}

		api
			.dajsymbolypreuhradu(request)
			.call()
			.then((response) => {
				this.symbols = response
				this.steps = 3
			})
	}

	// @action printReport() {
	//   printReport(null, null, this.htmlContent)
	// }

	@action zaevidujUhradu() {
		if (isEmpty(this.symbols.datum_uhrady)) {
			this.symbols.datum_uhrady = moment().toISOString()
		}

		let request = Object.assign({}, this.symbols, {
			patient_id: DataStore.patientDTO.get("patientID"),
			provider_id: getSelectedOrgUnitID()
		})

		if (isNotEmpty(this.phone) || isNotEmpty(this.email)) {
			let aktUdajeRequest = {
				patient_id: DataStore.patientDTO.get("patientID"),
				provider_id: getSelectedOrgUnitID(),
				mobile_phone: this.phone,
				email: this.email
			}

			api
				.aktualizujkontaktneudajepoistenca(aktUdajeRequest)
				.call()
				.then((response) => {
					if (isSafe(response) && isSafe(response.aktualizovane) && response.aktualizovane) {
						GlobalStore.setNotificationMessage("Patient.payDept.dialog.confirmed.Message")

						api
							.zaevidujuhradu(request)
							.call()
							.then((response) => {
								this.steps = 4

								if (isSafe(response) && isSafe(response.potvrdenie) && response.potvrdenie) {
									this.message = "Patient.payDept.dialog.paymentSuccess"
									DataStore.nonPayer = ""
								} else {
									this.message = "Patient.payDept.dialog.paymentDenied"
								}
							})
					} else {
						GlobalStore.setNotificationMessage("Patient.payDept.dialog.unconfirmed.Message")
					}
				})
		} else {
			api
				.zaevidujuhradu(request)
				.call()
				.then((response) => {
					this.steps = 4

					if (isSafe(response) && isSafe(response.potvrdenie) && response.potvrdenie) {
						this.message = "Patient.payDept.dialog.paymentSuccess"
						DataStore.nonPayer = ""
					} else {
						this.message = "Patient.payDept.dialog.paymentDenied"
					}
				})
		}
	}

	@action stornoSymbolovPreUhradu() {
		let request = Object.assign({}, this.symbols, {
			patient_id: DataStore.patientDTO.get("patientID"),
			provider_id: getSelectedOrgUnitID()
		})

		this.steps = 4
		api
			.stornosymbolovpreuhradu(request)
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.stornovanie) && response.stornovanie) {
					this.message = "Patient.payDept.dialog.stornoSuccess"
				} else {
					this.message = "Patient.payDept.dialog.stornoDenied"
				}
			})
	}

	@action close() {
		this.isOpen = false
		this.dlhCelkom = undefined
		this.htmlContent = undefined
		this.message = undefined
		this.email = ""
		this.phone = ""
	}
}

var singleton = new PatientPayDebtDialogStore()
export default singleton
