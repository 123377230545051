"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"

import InvoiceListStore from "../stores/InvoiceListStore"
import UIStore from "../stores/UIStore"

class ExportInvoicesPDFDialogStore {
	@observable isOpen = false

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@action exportPDF(form, invoiceIds) {
		const req = {
			invoices: invoiceIds,
			password: isNotEmpty(form.$("password_zip").value) ? form.$("password_zip").value : null
		}

		UIStore.isFormSaving = true

		api
			.exportInvoices(req)
			.call()
			.then((response) => {
				if (response.ok == true) {
					let fileName = isSafe(response.headers.get("content-disposition"))
						? response.headers.get("content-disposition").split("filename=")[1]
						: "davky"
					response.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})
				}

				UIStore.isFormSaving = false
				form.reset()
				this.close()
				InvoiceListStore.tableRef.state.checkboxValues = []
				InvoiceListStore.tableRef.refreshData()
				InvoiceListStore.checkedInvoces = []
			})
	}
}

var singleton = new ExportInvoicesPDFDialogStore()
export default singleton
