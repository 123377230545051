"use strict"
import api from "../../ambulance/actions/api"
import attendanceApi from "../actions/api"
import {observable, action} from "mobx"
import {getFilters, GWApiCall} from "../../../global/helpers/api"
import {getUserCompanyInfo, getSelectedOrgUnit, getUser, getUserPersonnelID} from "../../../global/helpers/actions"
import config from "../../../global/config/settings"
import moment from "moment"
import RouterStore from "../../../global/store/RouterStore"
import GlobalStore from "../../../global/store/GlobalStore"
import settings from "../../../global/config/settings"

class EmployeesDetailStore {
	@observable employeeData
	@observable employeeContrats = []
	@observable employeeContratsEdited = []
	@observable orgunits = []
	@observable employeeContratseditingId = ""
	@observable employeeCategories = []
	@observable contactsTableData = []
	@observable workerExpertiseList = []
	@observable expertiseData = []
	@observable isAdd = false
	@observable editable = false
	@observable externalCredentials = []
	@observable employeeUserId = null
	@observable empExpertises
	@observable addEmpExpertiseContract = false
	@observable externalCredentialsEditing = false
	@observable externalCredentialsAdd = false

	@observable editEmployee = false
	@observable editCardForm = false
	@observable editUserForm = false
	@observable editMedicalWorkerForm = false
	@observable fullName = ""

	updatedSubIdentifier = null
	updatedSystem = null

	entitySpecRels

	@action loadEmployeeDetail(id) {
		if (isNotEmpty(id)) {
			api
				.loadEntityInfo(id, "L3")
				.call()
				.then((response) => {
					this.employeeData = response
				})
		}
	}

	@action clearData(name) {
		this[name] = {}
	}

	@action loadEmployeeContrats(id) {
		const searchRequest = getFilters([`employee_id=${id}`])
		attendanceApi
			.loadEmployeeContrats(searchRequest)
			.call()
			.then((response) => {
				this.employeeContrats = response.rows
				this.employeeContratsEdited = response.rows.map((i) => {
					return {
						// "key":idx,
						// "code_ext":idx,
						code: i.employment_contract_id,
						_id: i.employment_contract_id,
						name_ext: i.org_unit_name_ext
					}
				})
			})
	}

	@action loadEmployeeOrgunits() {
		let company = getUserCompanyInfo()
		const searchRequest = getFilters([`company_id=${company.id}`])
		attendanceApi
			.loadOrgUnits(searchRequest)
			.call()
			.then((response) => {
				this.orgunits = response.rows.map((i) => {
					return {
						name_ext: i.name_ext,
						code_ext: i.org_unit_id,
						code: i.org_unit_id,
						_id: i.org_unit_id
					}
				})
			})
	}

	@action setCardForm(form, entityId) {
		api
			.loadSpecificRel(entityId, "L2")
			.call()
			.then((response) => {
				this.entitySpecRels = response
				let cardnumber = ""
				if (this.entitySpecRels.identifiers && this.entitySpecRels.identifiers.length > 0) {
					const icard = this.entitySpecRels.identifiers.filter((identifier) => {
						return identifier.type === "CARD" && identifier.active
					})
					if (icard.length > 0) {
						cardnumber = icard[0].value
					}
				}

				form.$("personalNumber").set(this.entitySpecRels.personal_id)
				form.$("cardNumber").set(cardnumber)
				form.$("employeeCategory").set(isSafe(this.entitySpecRels.category) ? this.entitySpecRels.category._id : "")
				form.$("arrivalDate").set(isSafe(this.entitySpecRels.validity) ? this.entitySpecRels.validity.from : "")
				form.$("departureDate").set(isSafe(this.entitySpecRels.validity) ? this.entitySpecRels.validity.to : "")
			})
	}

	@action getEmpContacts(patientId, employee_id) {
		api
			.loadEntityInfo(patientId)
			.call()
			.then((info) => {
				const patient = info.specific_rels.find((sr) => {
					return sr._type === "Entity.Data.Employee" && sr._id == employee_id
				})
				if (isSafe(patient) && isSafe(patient.contacts)) {
					this.contactsTableData = patient.contacts
				}
			})
	}

	@action insertDataToEmployeeWorker(form, /* formJURZid,*/ id) {
		if (isEmpty(id)) return
		attendanceApi
			.loadWorkerCard(id, "L2")
			.call()
			.then((res) => {
				form.$("category").set(isSafe(res.category) ? res.category._id : "")
				form.$("from").set(isNotEmpty(res.validity.from) ? res.validity.from : "")
				form.$("to").set(isNotEmpty(res.validity.to) ? res.validity.to : "")
				form
					.$("JRUZid")
					.set(
						isSafe(res.identifiers) && isSafe(res.identifiers.find((i) => i.type === "JRUZID"))
							? res.identifiers.find((i) => i.type === "JRUZID").value
							: ""
					)
			})
	}

	@action loadWorkerExpertise(id, callback) {
		if (isEmpty(id)) return
		attendanceApi
			.loadPersonnelExpertise(id)
			.call()
			.then((response) => {
				this.workerExpertiseList = response.rows
				if (isSafe(callback) && typeof callback === "function") {
					callback(response)
				}
			})
	}

	@action searchExpertise(value) {
		const searchRequest = getFilters([`search_column=${value}`])
		api
			.loadExpertise(searchRequest, "PE")
			.call()
			.then((response) => {
				this.expertiseData = response.rows
			})
	}

	@action loadEmployeeMedWorkExpForm(form, personnel, expertise) {
		attendanceApi
			.loadPersonnelExpertiseSpecific(personnel, expertise, "L3")
			.call()
			.then((res) => {
				form.$("personnelExpertisesId").set(expertise)
				form.$("expertiseId").set(isSafe(res.expertise) ? res.expertise._id : "")
				// form.$("expertise").set(isSafe(res.expertise) ? res.expertise.code_ext + " " + res.expertise.name_ext : "")
				form.$("from").set(isSafe(res.validity) && isNotEmpty(res.validity.from) ? res.validity.from : "")
				form.$("assesing_physician").set(isSafe(res.assesing_physician) ? res.assesing_physician._id : "")
				form.$("to").set(isSafe(res.validity) && isNotEmpty(res.validity.to) ? res.validity.to : "")
				form.$("contract").set(isSafe(res.employment_contract) ? res.employment_contract._id : "")
				form
					.$("doctorCode")
					.set(
						isSafe(res.identifiers) && isSafe(res.identifiers.find((i) => i.type === "DOCTORCODE"))
							? res.identifiers.find((i) => i.type === "DOCTORCODE").value
							: ""
					)
			})
	}

	@action deleteEmpWorkExpertise(personnelId, expertiseId) {
		attendanceApi
			.deleteEmpWorkExpertise(personnelId, expertiseId)
			.call()
			.then(() => {
				this.loadWorkerExpertise(personnelId)
			})
	}

	@action deleteEmpContract(id, callback) {
		attendanceApi
			.deleteEmpContract(id)
			.call()
			.then(() => {
				if (typeof callback === "function") callback()
			})
	}

	@action loadSpecificUser(form, entityId) {
		const searchRequest = getFilters([`entity=${entityId}`])
		api
			.loadSpecificUser(searchRequest)
			.call()
			.then((res) => {
				const resEntity = res.rows[0]
				const userId = isSafe(resEntity) && isSafe(resEntity.user) ? resEntity.user._id : null
				if (isSafe(resEntity) && isSafe(resEntity.user)) {
					form.$("login").set("value", resEntity.user.login)
					form.$("email").set("value", resEntity.user.email)
					form.$("enabled").set("value", resEntity.user.enabled)
					form.$("confirmed").set("value", resEntity.user.confirmed)
					form.$("userId").set("value", userId)
					form.$("entityUserId").set("value", resEntity._id)
				}
				if (isNotEmpty(userId)) {
					this.employeeUserId = userId
					this.loadExternalCredentials(userId)
				}
			})
	}

	@action loadExternalCredentials(id) {
		api
			.loadExternalCredentials(id)
			.call()
			.then((external) => {
				this.externalCredentials = external
			})
	}

	@action GetZprId(form) {
		const selectedOrgUnit = getSelectedOrgUnit()
		const user = getUser()
		const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
		const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
		const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
		const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
		const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

		GWApiCall({
			method: "POST",
			uri: `${config.GW_BASE_URL}/GetZprId`,
			body: JSON.stringify({
				Force: true,
				AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
				AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
				AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
				AmbeePersonnelId: getUserPersonnelID(),
				OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
				PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
				PersonnelExpertiseJRUZVersion: specVer,
				PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
			})
		})
			.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
			.then((data) => {
				GlobalStore.checkEHGWError(data.ErrorCode, data.ErrorMessage, data.IsEhealthException)
				if (isSafe(data) && isSafe(data.Id)) {
					form.$("JRUZid").set(data.Id)
				}
			})
			.catch((err) => logger(err))
	}

	@action redirToSpecificEmployee(personId) {
		let company = getUserCompanyInfo()
		let filter = {
			filters: [
				{
					associated_column: "validity_from",
					predicate: "=",
					values: [
						{
							id_value: "2010-01-01"
						}
					]
				},
				{
					associated_column: "validity_to",
					values: [
						{
							id_value: "2100-12-31"
						}
					]
				},
				{
					associated_column: "person_id",
					values: [
						{
							id_value: personId
						}
					]
				}
			]
		}

		attendanceApi
			.getSpecificEmployee(company._id, filter)
			.call()
			.then((res) => {
				if (isSafe(res.rows) && res.rows.length) {
					RouterStore.push(`/employee?id=${res.rows[0].relationship}&personId=${personId}`)
				}
			})
	}

	@action saveExpertiseContractsForm(form, personnelId, expertiseId, personnelExperiseId) {
		let formVals = form.values()
		let req = {
			_ref: false,
			active: true,

			employment_contract: {
				_id: formVals.contract,
				_ref: true,
				_type: "Entity.Data.EmploymentContract"
			},
			personnel_expertise: personnelExperiseId,
			validity: {
				from: isSafe(formVals.from) ? moment(formVals.from).format(settings.DB_DATE_FORMAT) : null,
				to: isSafe(formVals.to) ? moment(formVals.to).format(settings.DB_DATE_FORMAT) : null
			},
			type: "EHR.Data.Entity.PersonnelExpertiseContract"
		}

		if (isNotEmpty(formVals.personnelExpertiseContract)) {
			req._id = formVals.personnelExpertiseContract
		}

		api
			.saveExpertiseContract(personnelId, expertiseId, req)
			.call()
			.then(() => {
				this.loadWorkerExpertise(personnelId, (response) => {
					if (isSafe(response) && isSafe(response.rows)) {
						let empData = response.rows.find((row) => {
							if (isSafe(row) && isSafe(row.personnel_expertise) && isSafe(row.personnel_expertise.expertise))
								return row.personnel_expertise.expertise._id == expertiseId
						}).personnel_expertise

						if (isSafe(empData)) {
							this.empExpertises = empData.employment_contracts
						}
					}
				})
				this.addEmpExpertiseContract = false
				form.reset()
			})
	}

	@action deleteExpertiseContract(contractId, expertiseId, personnelId) {
		let req = {
			_ref: false,
			active: false,
			_id: contractId
		}
		api
			.saveExpertiseContract(personnelId, expertiseId, req)
			.call()
			.then(() => {
				this.loadWorkerExpertise(personnelId, (response) => {
					if (isSafe(response) && isSafe(response.rows)) {
						let empData = response.rows.find((row) => {
							if (isSafe(row) && isSafe(row.personnel_expertise) && isSafe(row.personnel_expertise.expertise))
								return row.personnel_expertise.expertise._id == expertiseId
						}).personnel_expertise
						if (isSafe(empData)) {
							this.empExpertises = empData.employment_contracts
						}
					}
				})
				this.addEmpExpertiseContract = false
			})
	}

	@action deleteEmployee() {
		const req = {
			_id: GlobalStore.confirmationDialogParams.employeeId,
			_ref: false,
			_type: "Entity.Data.Employee",
			active: false
		}
		api
			.saveSpecificRel(req)
			.call()
			.then(() => {
				this.editEmployee = false
				this.fullName = ""
				RouterStore.push(`/employees`)
			})
	}

	@action saveExternalCredentials(form, editing) {
		let req = {
			password: form.$("password").value,
			system: form.$("system").value,
			username: form.$("login").value,
			sub_identifier: isNotEmpty(form.$("sub_identifier").value) ? "OU" + form.$("sub_identifier").value : ""
		}

		if (editing) {
			let deletePrevious = {
				username: "",
				password: "",
				system: this.updatedSystem,
				sub_identifier: isNotEmpty(this.updatedSubIdentifier) ? "OU" + this.updatedSubIdentifier : ""
				// sub_identifier: isNotEmpty(form.$("sub_identifier").value) ? "OU" + form.$("sub_identifier").value : ""
			}

			api
				.saveExternalCredentials(this.employeeUserId, deletePrevious)
				.call()
				.then(() => {
					api
						.saveExternalCredentials(this.employeeUserId, req)
						.call()
						.then(() => {
							this.loadExternalCredentials(this.employeeUserId)
							form.reset()
						})
				})
		} else {
			api
				.saveExternalCredentials(this.employeeUserId, req)
				.call()
				.then(() => {
					this.loadExternalCredentials(this.employeeUserId)
					form.reset()
				})
		}
	}

	@action deleteExternalCredentials(data) {
		let req = {
			password: "",
			system: data.system,
			username: "",
			sub_identifier: data.sub_identifier
		}

		api
			.saveExternalCredentials(this.employeeUserId, req)
			.call()
			.then(() => {
				this.loadExternalCredentials(this.employeeUserId)
			})
	}
}
var singleton = new EmployeesDetailStore()
export default singleton
