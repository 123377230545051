import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {withStyles} from "@material-ui/core/styles"

// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
// import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import NotAllowedDeliveriesListStore from "../../../stores/NotAllowedDeliveriesListStore"
import WarningStore from "../../../../../global/store/WarningStore"
import UIStore from "../../../stores/UIStore"
// import ExportDailyReportToXlsDialog from "./exportDailyReportToXlsDialog"
// definitions of form fields
import fields from "./notAllowedDeliveriesFields"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import moment from "moment"
import {getUserPersonnelOrgUnits} from "../../../../../global/helpers/actions"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import TableStore from "../../../stores/TableStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

const styles = () => ({
	searchIcon: {
		marginTop: "14px"
	}
})

@withStyles(styles)
@injectIntl
@observer
export default class NotAllowedDeliveriesList extends React.Component {
	constructor(props) {
		super(props)
		UIStore.isFormSaving = false

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				if (form.values().datefrom || form.values().dateto) {
					if (
						TableStore.tables.hasOwnProperty("recordsWithoutDeliveriesList") &&
						TableStore.tables["recordsWithoutDeliveriesList"].hasOwnProperty("index")
					) {
						delete TableStore.tables["recordsWithoutDeliveriesList"]["index"]
					}

					NotAllowedDeliveriesListStore.loadListData(form)
				} else {
					WarningStore.open(props.intl.formatMessage({id: "Common.label.warningDateFromDateTo"}))
				}
			},
			onError() {}
		}

		if (isEmpty(NotAllowedDeliveriesListStore.formRef)) {
			NotAllowedDeliveriesListStore.formRef = new MobxReactForm(fields.load(), {
				plugins: {dvr: validatorjs},
				hooks,
				bindings
			})
		}
		this.form = NotAllowedDeliveriesListStore.formRef
		if (this.form.$("datefrom").value || this.form.$("dateto").value) {
			NotAllowedDeliveriesListStore.loadListData(this.form)
		}
	}

	componentWillUnmount() {
		NotAllowedDeliveriesListStore.listData = []
	}

	onHandleRowClick = (dataRow) => {
		GlobalStore.changeOrgUnitAndRouteToPatientDetail(this.form.$("orgunit").value, dataRow.person_id)
	}

	render() {
		NotAllowedDeliveriesListStore.isChangedFilter(this.form.values())
		const dateTimeFormat = this.props.intl.formatMessage({id: "Application.moment.datetimeformat"})

		return (
			<Grid container className="xs-notAllowedDeliveriesList-list">
				<Grid item xs={12} className="mb-3">
					<FormattedMessage id="Common.label.deliveriesCombinationsHeader" />
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}>
							<SearchSelect white field={this.form.$("orgunit")} items={getUserPersonnelOrgUnits()} required />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker white field={this.form.$("datefrom")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker white field={this.form.$("dateto")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={2}>
							<XsInput white field={this.form.$("deliveries")} />
						</Grid>
						<Grid item xs={3} className="pb-0">
							<XsIconButton
								rect
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								className="xs-default xs-outline"
								onClick={this.form.onSubmit}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
						{/* <Grid item xs={5}>
							<Grid container justify="flex-end" spacing={8}>
								<Grid item>
									<XsButton
										disabled={
											isEmpty(this.form.$("datefrom").value) && isEmpty(this.form.$("dateto").value) ? true : false
										}
										className={
											isEmpty(this.form.$("datefrom").value) && isEmpty(this.form.$("dateto").value)
												? "xs-default xs-outline"
												: "xs-primary"
										}
										text={<FormattedMessage id="Common.label.export" />}
										icon={<i className="fal fa-file-excel fa-lg" />}
										onClick={() => {
											NotAllowedDeliveriesListStore.open()
										}}
									/>
								</Grid>
							</Grid>
						</Grid> */}
					</Grid>
					{NotAllowedDeliveriesListStore.isLoading ? (
						<XsLoading />
					) : NotAllowedDeliveriesListStore.changedFilters ? (
						isEmpty(this.form.$("deliveries").value) ? (
							<React.Fragment>
								<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
									<i className="far fa-exclamation fa-lg mr-3 pointer"></i>
									Filter Výkony je povinný
								</div>
							</React.Fragment>
						) : (
							<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
								<i className="far fa-search fa-lg mr-3 pointer" onClick={this.form.onSubmit}></i>
								<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
							</div>
						)
					) : isEmpty(this.form.$("deliveries").value) ? (
						<React.Fragment>
							<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
								<i className="far fa-exclamation fa-lg mr-3 pointer"></i>
								Filter Výkony je povinný
							</div>
						</React.Fragment>
					) : (
						<XsTable
							setRef={(table) => (NotAllowedDeliveriesListStore.dailyReportListRef = table)}
							config={{
								columnDefs: {
									provider_code_ext: {
										title: <FormattedMessage id="Patient.form.patientrequest.orgunit" />,
										design: {
											width: "150px"
										},
										sortable: true
									},
									identifier: {
										title: <FormattedMessage id="Patient.form.patientinfo.identifier" />,
										type: "string",
										design: {
											width: "120px"
										},
										sortable: true
									},
									full_name: {
										title: <FormattedMessage id="Capitation.List.Patient" />,
										type: "string",
										design: {
											width: "250px",
											body: {
												className: "blueDark xs-bold"
											}
										},
										sortable: true
									},
									dlv_date: {
										title: <FormattedMessage id="Common.label.date" />,
										type: "datetime",
										design: {
											width: "150px",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format(dateTimeFormat) : ""
												}
											}
										},
										sortable: true
									},
									dlvs_code_ext: {
										title: <FormattedMessage id="Common.label.deliveriesInCombinations" />,
										type: "string",
										design: {
											width: "120px"
										},
										sortable: true
									},
									dlv_count: {
										title: <FormattedMessage id="Common.label.deliveriesCount" />,
										type: "string",
										design: {
											width: "120px"
										},
										sortable: true
									}
								},
								options: {
									name: "recordsWithoutDeliveriesList",
									showCursor: true,
									// selectRow: true,
									defaultSort: {columnName: "stamp", sortDirection: "asc"},
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										return dataRow
									}
								},
								dataSource: NotAllowedDeliveriesListStore.listData
							}}
						/>
					)}
				</Grid>
				{/* <ExportDailyReportToXlsDialog /> */}
			</Grid>
		)
	}
}
