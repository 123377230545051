import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				org_unit: {
					label: <FormattedMessage id="Patient.form.contractForm.contract" />,
					rules: "required"
				},
				company_id: {
					label: ""
				},
				contract_id: {},
				employee_id: {},
				from: {
					label: <FormattedMessage id="Common.label.from" />,
					// rules: "required",
					type: "date"
				},
				to: {
					label: <FormattedMessage id="Common.label.to" />,
					type: "date"
				}
			}
		}
	}
}
