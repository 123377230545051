export default {
	load: {
		storeKey: "OrgunitsParametersForm",
		type: "Entity.Data.OrgUnitParam"
	},
	descriptor: {
		_ref: false
	},
	scheme: {
		_id:{
			relation: "@paramId"
		},
		param_type: {
			field: "paramType"
		},
		value: {
			field: "value"
    },
    org_unit:{
      relation : "@orgunitId"
    }
	}
}
