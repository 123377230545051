/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				type: {
					//definujeme len staticke props ako su label, placeholder, ktore sa nemenia za behu...
					label: <FormattedMessage id="Patient.form.patientrecord.diagnosistype" defaultMessage="Type" />,
					rules: "string",
					value: "home"
				},
				mobile: {
					label: <FormattedMessage id="Patient.form.contactForm.mobile" defaultMessage="Mobile" />,
					placeholder: "+421999000000",
					rules: "string"
				},
				email: {
					label: <FormattedMessage id="Common.label.email" defaultMessage="Email" />,
					rules: "string"
				},
				phone: {
					label: <FormattedMessage id="Patient.form.contactForm.phone" defaultMessage="Phone" />,
					placeholder: "+421999000000",
					rules: "string"
				},
				description: {
					label: <FormattedMessage id="Common.label.description" defaultMessage="Description" />,
					rules: "string"
				},
				check: {
					type: "checkbox",
					label: <FormattedMessage id="Patient.form.contactForm.primary" defaultMessage="Primary" />
				}
			}
		}
	}
}
