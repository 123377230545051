import React from "react"
import {observer} from "mobx-react"
import Grid from "@material-ui/core/Grid"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import GlobalStore from "../../../../../global/store/GlobalStore"
import EHGWStore from "../../../stores/EHGWStore"
import EHGWUploadDialogStore from "../../../stores/EHGWUploadDialogStore"
import EHGWUploadDialog from "./EHGWUploadDialog"
import UIStore from "../../../stores/UIStore"

import config from "../../../../../global/config/settings"

import {GWApiCall} from "../../../../../global/helpers/api"

import {FormattedMessage} from "react-intl"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import api from "../../../actions/api"

@observer
export default class EHGWFilesList extends React.Component {
	constructor(props) {
		super(props)
	}

	componentDidMount() {
		EHGWStore.loadInstallFiles()
	}

	handleAddClick = () => {
		EHGWUploadDialogStore.open()
	}

	handleDownloadFile = (id) => {
		api
			.downloadInstallFile(id)
			.call()
			.then((response) => {
				const fileName = response.headers.get("content-disposition").split("filename=")[1]
				response.blob().then((blob) => {
					var FileSaver = require("file-saver") // eslint-disable-line no-undef
					FileSaver.saveAs(blob, fileName)
				})
			})
			.catch(() => {})
	}

	handleDownloadInstallator = () => {
		UIStore.isFormSaving = true
		api
			.downloadEHGWInstallator()
			.call()
			.then((response) => {
				const fileName = response.headers.get("content-disposition").split("filename=")[1]
				if (isNotEmpty(response)) {
					response.blob().then((blob) => {
						var FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
						UIStore.isFormSaving = false
					})
				} else {
					UIStore.isFormSaving = false
				}
			})
			.catch(() => (UIStore.isFormSaving = false))
	}

	handleDownloadEZdravieInstallator = () => {
		api
			.downloadEZdravieInstallator()
			.call()
			.then((response) => {
				const fileName = response.headers.get("content-disposition").split("filename=")[1]
				response.blob().then((blob) => {
					var FileSaver = require("file-saver") // eslint-disable-line no-undef
					FileSaver.saveAs(blob, fileName)
				})
			})
			.catch(() => {})
	}

	render() {
		return (
			<div className="EHGWFilesList">
				{UIStore.isFormSaving && <XsLoading overlay={true} />}
				<Grid container className="xs-installFiles-grid">
					<Grid item xs={12}>
						<XsButton
							className="xs-primary pullRight"
							text={<FormattedMessage id="Common.label.uploadNewEHGW" />}
							type="submit"
							onClick={this.handleAddClick}
						/>
						<XsButton
							className="xs-primary leftSide"
							text={<FormattedMessage id="Common.label.downloadEHGWInstallator" />}
							type="submit"
							onClick={this.handleDownloadInstallator}
						/>
						<XsButton
							className="xs-primary leftSide"
							icon={<img src="/public/images/eZdravie.jpg" width="24px" height="24px" />}
							text={<FormattedMessage id="Common.label.downloadeZdravieInstallator" />}
							type="submit"
							onClick={() => {
								GWApiCall({
									method: "GET",
									uri: `${config.GW_BASE_URL}/InstallNzisPrerequisites?auto=true`
								})
							}}
						/>
						<XsButton
							className="xs-primary leftSide"
							icon={<img src="/public/images/teamviewer.jpg" width="24px" height="24px" />}
							text={<FormattedMessage id="Common.label.downloadTeamViewerInstallator" />}
							type="submit"
							onClick={() => window.open("https://get.teamviewer.com/6irq9rp", "_blank")}
						/>
					</Grid>
					<Grid item xs={12}>
						<XsTable
							config={{
								columnDefs: {
									file_name: {
										title: "FileName",
										type: "string",
										design: {
											width: "50%"
										},
										sortable: true
									},
									version: {
										title: "Version",
										type: "string",
										design: {
											width: "20%"
										}
									},
									// sub_version_1: {
									// 	title: "SubVersion1",
									// 	type: "string"
									// },
									// sub_version_2: {
									// 	title: "SubVersion2",
									// 	type: "string"
									// },
									// sub_version_3: {
									// 	title: "SubVersion3",
									// 	type: "string"
									// },
									os: {
										title: "OS",
										type: "string"
									},
									download: {
										title: "",
										type: "action",
										design: {
											width: "20px",
											body: {
												renderer: (props) => {
													return (
														<XsIconButton
															className="xs-dialog-header"
															icon={<i className="far fa-download fa-lg" />}
															onClick={() => {
																this.handleDownloadFile(props.value)
															}}
														/>
													)
												}
											}
										}
									},
									delete: {
										title: "",
										type: "action",
										design: {
											width: "20px",
											body: {
												renderer: (props) => {
													return (
														<XsIconButton
															className="xs-dialog-header"
															icon={<i className="far fa-trash-alt fa-lg" />}
															onClick={() => {
																GlobalStore.openConfirmationDialog("xsDeleteEHGWFile", {
																	id: props.value
																})
															}}
														/>
													)
												}
											}
										}
									}
								},
								options: {
									showCursor: true,
									// selectRow: true,
									// onRowClick: (dataRow) => {
									// 	this.onHandleRowClick(dataRow)
									// },
									mapper: (dataRow) => {
										return {
											file_name: dataRow.install_file.file_name,
											version: `${dataRow.install_file.version}.${dataRow.install_file.sub_version_1}.${dataRow.install_file.sub_version_2}.${dataRow.install_file.sub_version_3}`,
											// version: dataRow.install_file.version,
											sub_version_1: dataRow.install_file.sub_version_1,
											sub_version_2: dataRow.install_file.sub_version_2,
											sub_version_3: dataRow.install_file.sub_version_3,
											os: dataRow.install_file.os,
											download: dataRow.install_file._id,
											delete: dataRow.install_file._id
										}
									}
								},
								dataSource: EHGWStore.installFiles
							}}
						/>
					</Grid>
				</Grid>
				<EHGWUploadDialog />
				<XsConfirmationDialog
					name="xsDeleteEHGWFile"
					title={<FormattedMessage id="Common.label.warning" />}
					text={<FormattedMessage id="Patient.form.patientrecord.deleteAttachment" />}
					onConfirmation={() => EHGWStore.deleteInstallFile(GlobalStore.confirmationDialogParams.id)}
				/>
			</div>
		)
	}
}
