"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"
import moment from "moment"

import DataStore from "../stores/DataStore"
import GlobalStore from "../../../global/store/GlobalStore"
import config from "../../../global/config/settings"
// import WarningStore from "../../../global/store/WarningStore"
import {GWApiCall} from "../../../global/helpers/api"
import {getUserPersonnelID, getSelectedOrgUnit, getUser /*, getUserCompanyInfo*/} from "../../../global/helpers/actions"
// import {getFilters} from "../../../global/helpers/api"

class ProblemsStore {
	@observable problemsDTO = null
	@observable isOpenDeleteDialog = false

	@observable problemsDTO = null
	@observable isOpenDeleteDialog = false
	@observable symptomsChips = []
	// symptomsHeaderWidth = undefined

	// @action setSymptomsRef(symptomsRef) {
	//   if (isSafe(symptomsRef) && isSafe(symptomsRef.getBoundingClientRect()) && isFinite(symptomsRef.getBoundingClientRect().width)) {
	//     if (symptomsRef.getBoundingClientRect().width != this.symptomsHeaderWidth) {
	//       this.symptomsHeaderWidth = symptomsRef.getBoundingClientRect().width
	//     }
	//   }
	// }

	@action loadPatientProblems() {
		// const filters = getFilters([`company_id=${getUserCompanyInfo()._id}`])

		// api
		// 	.loadPatientProblems(DataStore.patientDTO.get("patientID"), filters)
		// 	.call()
		// 	.then((problems) => {
		// 		if (isSafe(problems) && isSafe(problems.rows)) {
		// 			this.problemsDTO = []
		// 			this.problemsDTO = problems.rows
		// 		}
		// 	})

		this.problemsDTO = []
	}

	@action formEditData(form, data) {
		form.$("name").set(data.name)
		// this.forms[index].form.$("startDate").set(data.interval_from)
		// this.forms[index].form.$("endDate").set(data.interval_to)
		isNotEmpty(data.interval_from) ? form.$("startDate").set(moment(data.interval_from)) : form.$("startDate").set("")
		isSafe(data.interval_to) ? form.$("endDate").set(moment(data.interval_to)) : form.$("endDate").set("")
		form.$("_id").set(data.record_id)
		form.$("biohazard").set(data.biohazard)

		if (isSafe(data.problem_type) && isSafe(data.problem_type._id) && data.problem_type._id == "DISPENSARY") {
			form.$("problem_type").set(true)
		} else {
			form.$("problem_type").set(false)
		}

		form.$("expected_for_amount").set(isSafe(data.expected_for_amount) ? data.expected_for_amount : "")
		form
			.$("reexamination_every_amount")
			.set(isSafe(data.reexamination_every_amount) ? data.reexamination_every_amount : "")
		form
			.$("reexamination_every_unit")
			.set(
				isSafe(data.reexamination_every_unit) && isNotEmpty(data.reexamination_every_unit._id)
					? data.reexamination_every_unit._id
					: ""
			)
		form.$("end_reason").set(isSafe(data.end_reason) && isNotEmpty(data.end_reason._id) ? data.end_reason._id : "")

		form.$("diagnoses").set(data.diagnosis)
		form.$("diagnosis_desc").set(data.diagnosis_desc)
		if (isSafe(data.record_diagnoses) && data.record_diagnoses.length > 0) {
			const diagnosis = data.record_diagnoses.find((x) => x.active)

			if (isSafe(diagnosis)) {
				form.$("life_threatening").set(diagnosis.life_threatening)
			} else {
				form.$("life_threatening").set(false)
			}
		}

		if (isSafe(data.disease) && isNotEmpty(data.disease._id)) {
			form.$("disease").set(data.disease._id)
		}

		//Prida symptomi ku problemu
		this.symptomsChips = data.symptoms.map((sym) => {
			return {
				text: sym.description,
				severity: isSafe(sym.severity) ? +sym.severity.code_ext : 3
			}
		})
	}

	@action saveSymptoms(data, id) {
		let req = {_ref: false}
		req.symptoms = data.map((i) => {
			let sev =
				isSafe(GlobalStore.CL["severities"]) &&
				isSafe(GlobalStore.CL["severities"].find((obj) => +obj.code_ext == i.severity))
					? GlobalStore.CL["severities"].find((obj) => +obj.code_ext == i.severity)
					: {code: "Medium"}
			return {
				_ref: false,
				_type: "EHR.Data.Problem.Symptom",
				active: true,
				description: i.text,
				severity: sev.code
			}
		})

		api
			.updateProblem(req, id)
			.call()
			.then(() => {
				this.symptomsChips = []
				this.loadPatientProblems()
			})
	}

	@action deleteProblem(form, id) {
		let request = {
			_id: id,
			_ref: false,
			active: false,
			_type: "EHR.Data.Problem"
		}

		api
			.updateRecord(request)
			.call()
			.then((res) => {
				this.loadPatientProblems()
				form.reset()
				this.symptomsChips = []

				if (isSafe(res.ixs_signer_data)) {
					const selectedOrgUnit = getSelectedOrgUnit()
					const user = getUser()
					const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
					const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
					const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
					const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
					const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

					const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
						? DataStore.patientDTO.get("patient").validInfo.client
						: null
					const JRUZIDObj = isSafe(patientClientInfo.identifiers)
						? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
						: null

					GWApiCall({
						method: "POST",
						uri: `${config.GW_BASE_URL}/CancelEHRExtract`,
						body: JSON.stringify({
							ClassName: "ZrusZapisZPacientskehoSumaru_v2",
							ExtId: res.ixs_signer_data.ext_id,
							PreviousExtId: res.ixs_signer_data.previous_ext_id,
							ArchetypeId: res.ixs_signer_data.archetype_id,
							RcIdOID: res.ixs_signer_data.rcid_oid,
							AmbeePatientId: DataStore.patientDTO.get("patientID"),
							PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
							PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
							AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
							AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
							AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
							AmbeePersonnelId: getUserPersonnelID(),
							OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
							PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
							PersonnelExpertiseJRUZVersion: specVer,
							PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
							RecordSignerData: isSafe(res.record_signer_data)
								? res.record_signer_data.map((x) => {
										return {
											Ref: x._ref,
											Type: x._type,
											RecordClass: x.record_class,
											RecordExternalId: x.record_external_id,
											RecordId: x.record_id,
											QueueId: x.queue_id
										}
								  })
								: null
						})
					})
						.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
						.then((data) => {
							// api.setSent(res.ixs_signer_data.ext_id).call()
							if (isSafe(data) && isNotEmpty(data.ErrorMessage)) {
								GlobalStore.checkEHGWError(data.ErrorCode, data.ErrorMessage, data.IsEhealthException)
								// WarningStore.generateErrorMessage(data.IsEhealthException, data.ErrorMessage)
							}
						})
						.catch()
				}
			})
	}

	@action writeEHR(res) {
		const selectedOrgUnit = getSelectedOrgUnit()
		const user = getUser()
		const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
		const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
		const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
		const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
		const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

		const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
			? DataStore.patientDTO.get("patient").validInfo.client
			: null
		const JRUZIDObj = isSafe(patientClientInfo.identifiers)
			? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
			: null

		GWApiCall({
			method: "POST",
			uri: `${config.GW_BASE_URL}/${
				res.ixs_signer_data.operation === "invalidate" ? "InvalidateEHRExtract" : "WriteEHRExtract"
			}`,
			body: JSON.stringify({
				ClassName:
					res.ixs_signer_data.operation === "invalidate"
						? "ZrusZapisZPacientskehoSumaru_v2"
						: "ZapisPacientskehoSumaruZdravotneProblemy_v2",
				ExtId: res.ixs_signer_data.ext_id,
				ArchetypeId: res.ixs_signer_data.archetype_id,
				EHRExtract: res.ixs_signer_data.payload.text,
				PreviousExtId: res.ixs_signer_data.previous_ext_id,
				TimeCommitted: res.actual_time,
				RcIdOID: res.ixs_signer_data.rcid_oid,
				AmbeePatientId: DataStore.patientDTO.get("patientID"),
				PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
				PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
				AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
				AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
				AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
				AmbeePersonnelId: getUserPersonnelID(),
				OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
				PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
				PersonnelExpertiseJRUZVersion: specVer,
				PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
				RecordSignerData: isSafe(res.record_signer_data)
					? res.record_signer_data.map((x) => {
							return {
								Ref: x._ref,
								Type: x._type,
								RecordClass: x.record_class,
								RecordExternalId: x.record_external_id,
								RecordId: x.record_id,
								QueueId: x.queue_id
							}
					  })
					: null
			})
		})
			.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
			.then((data) => {
				// api.setSent(res.ixs_signer_data.ext_id).call()
				if (isSafe(data) && isNotEmpty(data.ErrorMessage)) {
					GlobalStore.checkEHGWError(data.ErrorCode, data.ErrorMessage, data.IsEhealthException)
					// WarningStore.generateErrorMessage(data.IsEhealthException, data.ErrorMessage)
				}
			})
			.catch()
	}
}

var singleton = new ProblemsStore()
export default singleton
