"use strict"

import {observable, action} from "mobx"
import {getSelectedOrgUnitID} from "../../../global/helpers/actions"
import DataStore from "./DataStore"
import api from "../actions/api"

import UIStore from "./UIStore"

class OverPrescriptionDialogStore {
	@observable isOpen = false
	@observable isCheck = false
	@observable data = undefined
	@observable barcode = ""

	@action open(barcode) {
		if (isSafe(barcode) && isNotEmpty(barcode)) {
			this.barcode = barcode
			this.check()
		} else {
			this.barcode = ""
		}

		this.isCheck = false
		this.isOpen = true
	}

	@action onHandleChange(value) {
		this.barcode = value
	}

	@action check() {
		UIStore.isFormSaving = true
		api
			.loadEhealthPrescriptionEntry(this.barcode, getSelectedOrgUnitID(), DataStore.patientDTO.get("patientID"))
			.call()
			.then((response) => {
				if (isSafe(response)) {
					UIStore.isFormSaving = false
					this.data = response
					this.isCheck = true
				} else {
					UIStore.isFormSaving = false
				}
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}

	@action close() {
		this.isOpen = false
	}
}

var singleton = new OverPrescriptionDialogStore()
export default singleton
