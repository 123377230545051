"use strict"
import api from "../actions/api"
import moment from "moment"
import {observable, action} from "mobx"
import settings from "../../../global/config/settings"
import {getUserCompanyInfo, exportToExcel} from "../../../global/helpers/actions"
import UIStore from "./UIStore"

class SmsReportStore {
	@observable smsReportData = []
	@observable isFetching = false
	@observable isOpen = false
	@observable diagnosisFilter = []
	@observable diagnoses_patientListFilter = null

	formRef = undefined

	@observable changedFilters = false
	currFilters = {}
	currDiagnosis = []

	@action isChangedFilter(formValues) {
		let isChangeDateFrom = compareMomentDate(this.currFilters.datefrom, formValues.datefrom)
		let isChangeDateTo = compareMomentDate(this.currFilters.dateto, formValues.dateto)

		if (isChangeDateFrom || isChangeDateTo || this.currFilters.mobile != formValues.mobile) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	getFilters = (form) => {
		let formValues = form.values()

		let req = {
			filters: [{associated_column: "company_id", values: [{id_value: getUserCompanyInfo().id}]}]
		}

		if (isNotEmpty(formValues.datefrom)) {
			req["time_from"] = moment(formValues.datefrom)
				.add(-1, "days")
				.endOf("day")
				.format(settings.DB_DATETIME_FORMAT)
		}

		if (isNotEmpty(formValues.dateto)) {
			req["time_to"] = moment(formValues.dateto)
				.endOf("day")
				.format(settings.DB_DATETIME_FORMAT)
		}

		if (isNotEmpty(formValues.mobile)) {
			req.filters.push({associated_column: "phone", values: [{id_value: formValues.mobile}]})
		}

		return req
	}

	@action loadSMS(form) {
		this.currFilters = form.values()

		const req = this.getFilters(form)

		this.isFetching = true
		api
			.loadSMSReport(req)
			.call()
			.then((response) => {
				this.smsReportData = response.rows
				this.isFetching = false
			})
			.catch(() => {
				this.isFetching = false
			})
	}

	@action exportToExcel(form) {
		UIStore.isFormSaving = false

		let req = this.getFilters(form)

		req["uri"] = api.loadSMSReport().getPath()

		exportToExcel(req)
	}
}
var singleton = new SmsReportStore()
export default singleton
