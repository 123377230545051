export default {
	load() {
		return {
			fields: {
				mondayfrom: {},
				mondayto: {},
				mondayclosedfrom: {},
				mondayclosedto: {},
				tuesdayfrom: {},
				tuesdayto: {},
				tuesdayclosedfrom: {},
				tuesdayclosedto: {},
				wednesdayfrom: {},
				wednesdayto: {},
				wednesdayclosedfrom: {},
				wednesdayclosedto: {},
				thursdayfrom: {},
				thursdayto: {},
				thursdayclosedfrom: {},
				thursdayclosedto: {},
				fridayfrom: {},
				fridayto: {},
				fridayclosedfrom: {},
				fridayclosedto: {},
				saturdayfrom: {},
				saturdayto: {},
				saturdayclosedfrom: {},
				saturdayclosedto: {},
				sundayfrom: {},
				sundayto: {},
				sundayclosedfrom: {},
				sundayclosedto: {}
			}
		}
	}
}
