/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				loginName: {
					label: <FormattedMessage id="Login.label.name" />,
					rules: "string|between:5,30"
				},
				password: {
					label: <FormattedMessage id="Login.label.pass" />,
					rules: "string|between:5,20"
				}
			}
		}
	}
}
