export default {
	load: {
		storeKey: "ProvidedServicesPriceList",
		params: ["priceID"],
		type: "Service.Data.PriceList"
	},
	scheme: {
		_id: {
			relation: "@priceID"
		},
		valid_from: {
			field: "valid_from"
		},
		valid_to: {
			field: "valid_to"
		},
		currency_unit: {
			field: "currency_unit"
		},
		price: {
			field: "price"
		},
		provided_service: {relation: "@psID"}
	}
}
