import React from "react"
import {observer} from "mobx-react"
import Grid from "@material-ui/core/Grid"
import localeDataEN from "../../../../global/i18n/dictionary.en.json"
import localeDataSK from "../../../../global/i18n/dictionary.sk.json"
import ContentCopyIcon from "@material-ui/icons/ContentCopy"
import {TableCell, TableBody, Table, TableRow, Typography, Tooltip} from "@material-ui/core"
import {FormattedMessage} from "react-intl"

@observer
export default class TranslationList extends React.Component {
	notTranslated = []
	stats = {}

	constructor(props) {
		super(props)
		if (localeDataEN && localeDataSK) {
			let ntCount = 0
			let enCount = 0
			let totalTextCopy = ""

			Object.keys(localeDataEN).forEach((t) => {
				enCount++
				if (localeDataEN[t] === "NEPRELOZENE") {
					this.notTranslated.push({code: t, sk: localeDataSK[t]})
					const textCopy = `${JSON.stringify(t)}:${JSON.stringify(localeDataSK[t])},\r`
					totalTextCopy += textCopy
					ntCount++
				}
			})
			this.stats = {total: enCount, notTranslated: ntCount, jsonText: totalTextCopy}
		}
		this.state = {textAreaValue: ""}
	}

	copyToClipboard = (textAreaText) => {
		this.setState({textAreaValue: textAreaText}, () => {
			this.textArea.select()
			document.execCommand("copy")
		})
	}

	render() {
		return (
			<Grid container>
				<textarea
					ref={(textarea) => (this.textArea = textarea)}
					value={this.state.textAreaValue}
					style={{position: "absolute", left: "-9999px"}}
				/>
				<div style={{padding: "50px"}}>
					<Typography variant="title" id="tableTitle">
						<FormattedMessage id="Admin.translation.missingEN" /> ({this.stats.notTranslated}/{this.stats.total})
						<Tooltip title={<FormattedMessage id="Common.dialog.CopyAll" />}>
							<ContentCopyIcon style={{marginLeft: "20px"}} onClick={() => this.copyToClipboard(this.stats.jsonText)} />
						</Tooltip>
					</Typography>
					<Table>
						<TableBody>
							{this.notTranslated.map((t) => {
								const textCopy = `${JSON.stringify(t.code)}:${JSON.stringify(t.sk)},\r`
								return (
									<TableRow key={t.code}>
										<TableCell component="th" scope="row">
											{t.code}
										</TableCell>
										<TableCell>{t.sk}</TableCell>
										<TableCell>
											<Tooltip title={<FormattedMessage id="Common.dialog.Copy" />}>
												<ContentCopyIcon onClick={() => this.copyToClipboard(textCopy)} />
											</Tooltip>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</div>
			</Grid>
		)
	}
}
