import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import Grid from "@material-ui/core/Grid"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import Dialog from "@material-ui/core/Dialog"

import PatientDetailActionsStore from "../../../stores/PatientDetailActionsStore"
import UIStore from "../../../stores/UIStore"
import DataStore from "../../../stores/DataStore"
import PatientProblemsDialogStore from "../../../stores/PatientProblemsDialogStore"
import PatientAlertsDialogStore from "../../../stores/PatientAlertsDialogStore"
import PatientTagsDialogStore from "../../../stores/PatientTagsDialogStore"
import PatientAllergiesDialogStore from "../../../stores/PatientAllergiesDialogStore"
import PatientImplantDialogStore from "../../../stores/PatientImplantDialogStore"
import PatientBloodTypeDialogStore from "../../../stores/PatientBloodTypeDialogStore"
import PatientTemplatesDialogStore from "../../../stores/PatientTemplatesDialogStore"
import HospicomDialogStore from "../../../stores/HospicomDialogStore"

import "./patientActions.less"

@observer
export default class PatientDetailActionsContainer extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				fullScreen
				open={PatientDetailActionsStore.isOpen}
				onClose={() => PatientDetailActionsStore.close()}
				disableBackdropClick={true}
				className="xs-actions-modal"
				PaperProps={{
					style: {backgroundColor: "transparent", border: "none"}
				}}
			>
				<div className="xs-actions-body xs-column xs-flex">
					<header className="xs-row xs-justify-end height">
						<XsButton
							className="xs-close-btn"
							icon={<i className="fal fa-times fa-lg" />}
							text={<FormattedMessage id="Common.form.closeWindow" />}
							onClick={() => PatientDetailActionsStore.close()}
						/>
					</header>
					<section>
						<Grid container spacing={8}>
							<Grid item xs={6} md={4} lg={3}>
								<XsButton
									className="xs-action"
									text={<FormattedMessage id="Patient.detail.actions.prescriptionDrug" />}
									icon={<i className="fal fa-pills fa-lg" />}
									onClick={() => {
										UIStore.onHandleTabs("prescription")
										PatientDetailActionsStore.close()
									}}
								/>
							</Grid>
							<Grid item xs={6} md={4} lg={3}>
								<XsButton
									className="xs-action"
									text={<FormattedMessage id="Common.label.dekurz" />}
									icon={<i className="fal fa-notes-medical fa-lg" />}
									onClick={() => {
										UIStore.onHandleTabs("record")
										PatientDetailActionsStore.close()
									}}
								/>
							</Grid>
							<Grid item xs={6} md={4} lg={3}>
								<XsButton
									className="xs-action"
									text={<FormattedMessage id="Common.label.anamnes" />}
									icon={<i className="fal fa-stethoscope fa-lg" />}
									onClick={() => {
										UIStore.onHandleTabs("anamnesis")
										PatientDetailActionsStore.close()
									}}
								/>
							</Grid>
							<Grid item xs={6} md={4} lg={3}>
								<XsButton
									className="xs-action"
									text={<FormattedMessage id="Common.label.request" />}
									icon={<i className="fal fa-file-contract fa-lg" />}
									onClick={() => {
										UIStore.onHandleTabs("request")
										PatientDetailActionsStore.close()
									}}
								/>
							</Grid>
							<Grid item xs={6} md={4} lg={3}>
								<XsButton
									className="xs-action"
									text={<FormattedMessage id="Common.label.patientSicknessAbs" />}
									icon={<i className="fal fa-id-badge fa-lg" />}
									onClick={() => {
										UIStore.onHandleTabs("sicknessabs")
										PatientDetailActionsStore.close()
									}}
								/>
							</Grid>
							<Grid item xs={6} md={4} lg={3}>
								<XsButton
									className="xs-action"
									text={<FormattedMessage id="PatientDetail.cards.opProtocol" />}
									icon={<i className="fal fa-file-medical-alt fa-lg" />}
									onClick={() => {
										UIStore.onHandleTabs("operation")
										PatientDetailActionsStore.close()
									}}
								/>
							</Grid>
							<Grid item xs={6} md={4} lg={3}>
								<XsButton
									className="xs-action"
									text={<FormattedMessage id="PatientDetail.cards.medication" />}
									icon={<i className="fal fa-syringe fa-lg" />}
									onClick={() => {
										UIStore.onHandleTabs("medication")
										PatientDetailActionsStore.close()
									}}
								/>
							</Grid>
							<Grid item xs={6} md={4} lg={3}>
								<XsButton
									className="xs-action"
									text={<FormattedMessage id="Common.label.templates" />}
									icon={<i className="fal fa-file-alt fa-lg" />}
									onClick={() => {
										// UIStore.onHandleTabs("templates")
										PatientDetailActionsStore.close()
										PatientTemplatesDialogStore.open()
									}}
								/>
							</Grid>
							<Grid item xs={6} md={4} lg={3}>
								<XsButton
									className="xs-action"
									text={<FormattedMessage id="PatientDetail.cards.patientScoring" />}
									icon={<i className="fal fa-ballot-check fa-lg" />}
									onClick={() => {
										UIStore.onHandleTabs("scoring")
										PatientDetailActionsStore.close()
									}}
								/>
							</Grid>
							<Grid item xs={6} md={4} lg={3}>
								<XsButton
									className="xs-action"
									text={<FormattedMessage id="PatientDetail.cards.problem" />}
									icon={<i className="fal fa-file-alt fa-lg" />}
									onClick={() => {
										PatientDetailActionsStore.close()
										PatientProblemsDialogStore.open()
									}}
								/>
							</Grid>
							<Grid item xs={6} md={4} lg={3}>
								<XsButton
									className="xs-action"
									text={<FormattedMessage id="Patient.sidebar.alert" />}
									icon={<i className="fal fa-exclamation-triangle fa-lg" />}
									onClick={() => {
										PatientDetailActionsStore.close()
										PatientAlertsDialogStore.open()
									}}
								/>
							</Grid>
							<Grid item xs={6} md={4} lg={3}>
								<XsButton
									className="xs-action"
									text={<FormattedMessage id="Patient.sidebar.tag" />}
									icon={<i className="fal fa-tags fa-lg" />}
									onClick={() => {
										PatientDetailActionsStore.close()
										PatientTagsDialogStore.open()
									}}
								/>
							</Grid>
							<Grid item xs={6} md={4} lg={3}>
								<XsButton
									className="xs-action"
									text={<FormattedMessage id="PatientDetail.header.allergy" />}
									icon={<i className="fal fa-allergies fa-lg" />}
									onClick={() => {
										PatientDetailActionsStore.close()
										PatientAllergiesDialogStore.open()
									}}
								/>
							</Grid>
							<Grid item xs={6} md={4} lg={3}>
								<XsButton
									className="xs-action"
									text={<FormattedMessage id="Patient.sidebar.implant" />}
									icon={<i className="fal fa-scalpel-path fa-lg" />}
									onClick={() => {
										PatientDetailActionsStore.close()
										PatientImplantDialogStore.open()
									}}
								/>
							</Grid>
							<Grid item xs={6} md={4} lg={3}>
								<XsButton
									className="xs-action"
									text={<FormattedMessage id="Patient.sidebar.bloodType" />}
									icon={<i className="fal fa-tint fa-lg" />}
									onClick={() => {
										PatientDetailActionsStore.close()
										if (
											isSafe(DataStore.patientDTO) &&
											isSafe(DataStore.patientDTO.get("bloodType")) &&
											isSafe(DataStore.patientDTO.get("bloodType")._id) &&
											isSafe(DataStore.patientDTO.get("bloodType").type._id) &&
											isSafe(DataStore.patientDTO.get("bloodType").type.name_ext)
										) {
											PatientBloodTypeDialogStore.open(
												DataStore.patientDTO.get("bloodType")._id,
												DataStore.patientDTO.get("bloodType").type._id,
												DataStore.patientDTO.get("bloodType").type.name_ext,
												DataStore.patientDTO.get("bloodType").written_at
											)
										} else {
											PatientBloodTypeDialogStore.open()
										}
									}}
								/>
							</Grid>
							<Grid item xs={6} md={4} lg={3}>
								<XsButton
									className="xs-action"
									text={<FormattedMessage id="Patient.sidebar.hospicom" />}
									icon={<i className="fal fa-calendar fa-lg" />}
									onClick={() => {
										PatientDetailActionsStore.close()
										HospicomDialogStore.open()
									}}
								/>
							</Grid>
						</Grid>
					</section>
				</div>
			</Dialog>
		)
	}
}
