"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"
import moment from "moment"
import {getUserCompanyInfo} from "../../../global/helpers/actions"

import GlobalStore from "../../../global/store/GlobalStore"
import UIStore from "../stores/UIStore"

class ExportInvoicesDialogStore {
	@observable isOpen = false
	@observable invoicesCount = 0
	@observable invoices = []

	@observable password = ""
	@observable includeDavku = false
	@observable inputs = {}
	dateFrom = moment()
		.startOf("month")
		.format("YYYY-MM-DD")
	@observable warningMessage = ""

	@action open() {
		this.isOpen = true

		this.getDrafts()
	}

	@action getDrafts() {
		UIStore.isFormSaving = true

		let request = {
			filters: [
				{
					associated_column: "company_id",
					values: [{id_value: getUserCompanyInfo()._id}]
				}
			],
			time_from: moment(this.dateFrom).startOf("month"),
			time_to: moment(this.dateFrom).endOf("month")
		}

		api
			.loadDraftReport(request)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows) && res.rows.length > 0) {
					this.warningMessage = "Common.label.showItemizedRecord"
				} else {
					this.warningMessage = ""
				}

				this.getInvoicesCount()
			})
	}

	@action getInvoicesCount() {
		api
			.getInvoiceConfig(getUserCompanyInfo().id)
			.call()
			.then((res) => {
				const req = {
					company_id: getUserCompanyInfo()._id,
					date: moment(this.dateFrom).format("YYYY-MM-DD") //moment().format("YYYY-MM-DD")
				}

				api
					.checkBatch(req)
					.call()
					.then((batch) => {
						if (isSafe(res) && isSafe(res.insurers) && res.insurers.length > 0) {
							let sortedInsurers = []

							const showInv = batch.filter((y) => y.invoice == 1)

							if (isSafe(showInv) && showInv.length) {
								showInv.forEach((x) => {
									const findIns = res.insurers.find((ins) => ins._id == x.config_insurer_id)

									if (findIns) {
										sortedInsurers.push(findIns)
									}
								})
							}

							sortedInsurers.sort(function(a, b) {
								if (a.item_order < b.item_order) {
									return -1
								}
								if (a.item_order > b.item_order) {
									return 1
								}
								return 0
							})

							this.invoicesCount = sortedInsurers.length
							this.invoices = sortedInsurers
						} else {
							this.invoicesCount = 0
							this.invoices = []
						}

						UIStore.isFormSaving = false
					})
			})
	}

	@action exportInvoices() {
		UIStore.isFormSaving = true
		if (Object.keys(this.inputs).length > 0 && Object.keys(this.inputs).length == this.invoicesCount) {
			let isNotValid = Object.values(this.inputs).some((invoice) => isEmpty(invoice))

			if (isNotValid) {
				GlobalStore.setNotificationMessage("Patient.form.patientPrescription.invalidForm")
				UIStore.isFormSaving = false
			} else {
				let request = {
					company_id: getUserCompanyInfo().id,
					date: this.dateFrom,
					password: this.password,
					// invoice_numbers: Object.values(this.inputs)
					// 	.map((x) => x)
					// 	.join(",")
					invoice_numbers: this.invoices.map((x) => `${x.item_order}^${this.inputs[x.item_order].trim()}`).join(","),
					include_soc_insurance: this.includeDavku ? 1 : 0
				}

				api
					.generalAll(request)
					.call(null, null, null, 600000)
					.then((response) => {
						if (response.ok == true) {
							let fileName = isSafe(response.headers.get("content-disposition"))
								? response.headers.get("content-disposition").split("filename=")[1]
								: "davky"
							response.blob().then((blob) => {
								let FileSaver = require("file-saver") // eslint-disable-line no-undef
								FileSaver.saveAs(blob, fileName)
							})
							UIStore.isFormSaving = false
							this.close()
						}
					})
			}
		} else {
			UIStore.isFormSaving = false
			GlobalStore.setNotificationMessage("Patient.form.patientPrescription.invalidForm")
		}
	}

	@action close() {
		this.isOpen = false

		this.password = ""
		this.includeDavku = false
		this.inputs = {}
		this.dateFrom = moment()
			.startOf("month")
			.format("YYYY-MM-DD")
		this.warningMessage = ""
	}
}

var singleton = new ExportInvoicesDialogStore()
export default singleton
