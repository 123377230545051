import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "request_dispensary_care"
				},

				orgunitname: {},
				doctorcode: {},
				orgunitaddress: {},
				insurer: {
					label: "Zdravotná poisťovňa"
				},
				insurerName: {},
				insurerCode: {},
				insurerId: "",
				insuranceTypeCode: {},
				socInsOffice: {
					label: "Pobočka"
				},

				name: {},
				nameForm: {
					label: "Meno a priezvisko"
				},
				identifier: {},
				identifierForm: {
					label: "Číslo poistenca (rodné číslo)"
				},
				address: {},
				addressForm: {
					label: "Bydlisko"
				},

				eu_name: {
					label: "Meno a priezvisko"
				},
				sex_verbal: {},
				eu_sex_verbal: {
					label: "Pohlavie"
				},
				birthdate: {},
				eu_birthdate: {
					label: "Dátum narodenia",
					type: "date"
				},
				eu_patientId: {
					label: "Identifikačné číslo"
				},
				citizenshipCode: {},
				eu_state: {
					label: "Štát"
				},

				diagClinical: {
					label: "Diagnóza"
				},
				diagnosisName: {},
				diagnosisCode: {},
				proposalDate: {
					label: "Dátum návrhu",
					type: "date",
					value: moment()
				},
				lengthFrom: {
					label: "Navrhovaná dĺžka dispenzarizácie od",
					type: "date"
				},
				lengthTo: {
					label: "Navrhovaná dĺžka dispenzarizácie do",
					type: "date"
				},
				frequency: {
					label: "Frekvencia kontrol"
				},
				postponeReason: {
					label: "Dôvod predĺženia"
				},

				date: {
					label: "Dátum",
					type: "date",
					value: moment()
				}
			}
		}
	}
}
