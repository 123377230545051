// import React from "react"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "general_form"
				},
				id: {},
				name: {},
				templates: {
					label: "Názov tlačiva"
				},
				templateText: {
					label: "Text"
				}
			}
		}
	}
}
