"use strict"

import React, {Component} from "react"
import JsBarcode from "jsbarcode"
import PropTypes from "prop-types"

var getDOMNode //eslint-disable-line
// Super naive semver detection but it"s good enough. We support 0.12, 0.13
// which both have getDOMNode on the ref. 0.14 and 15 make the DOM node the ref.
var version = React.version.split(/[.-]/)
if ((version[0] === "0" && version[1] === "13") || version[1] === "12") {
	getDOMNode = (ref) => ref.getDOMNode()
} else {
	getDOMNode = (ref) => ref
}

export default class xsBarcode extends Component {
	constructor(props) {
		super(props)
		this.update = this.update.bind(this)
		this.renderElement = React.createRef()
	}

	shouldComponentUpdate(nextProps) {
		return Object.keys(xsBarcode.propTypes).some((k) => this.props[k] !== nextProps[k])
	}

	componentDidMount() {
		this.update()
	}

	componentDidUpdate() {
		this.update()
	}

	update() {
		try {
			new JsBarcode(this.renderElement.current, this.props.value, Object.assign({}, this.props))
		} catch (e) {
			window.console.error(e)
		}
	}

	render() {
		if (this.props.renderer === "svg") {
			return <svg ref={this.renderElement} />
		} else if (this.props.renderer === "canvas") {
			return <canvas ref={this.renderElement} />
		}
	}
}

xsBarcode.propTypes = {
	value: PropTypes.string.isRequired,
	renderer: PropTypes.string,
	format: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	displayValue: PropTypes.bool,
	fontOptions: PropTypes.string,
	font: PropTypes.string,
	textAlign: PropTypes.string,
	textPosition: PropTypes.string,
	textMargin: PropTypes.number,
	fontSize: PropTypes.number,
	background: PropTypes.string,
	lineColor: PropTypes.string,
	margin: PropTypes.number,
	marginTop: PropTypes.number,
	marginBottom: PropTypes.number,
	marginLeft: PropTypes.number,
	marginRight: PropTypes.number
}

xsBarcode.defaultProps = {
	format: "CODE128",
	renderer: "svg",
	width: 2,
	height: 100,
	displayValue: true,
	fontOptions: "",
	font: "monospace",
	textAlign: "center",
	textPosition: "bottom",
	textMargin: 2,
	fontSize: 20,
	background: "#ffffff",
	lineColor: "#000000",
	margin: 10
}
