// import {FormattedMessage} from "react-intl"
// import React from "react"

export default {
	load() {
		return {
			fields: {
				detail_name: {
					label: "Nazov",
					rules: "required"
				},
				detail_customer: {
					label: "Pre odberateľa", // {<FormattedMessage id="Capitation.form.insurers" />}
					rules: "required"
				},
				detail_constant_symbol: {
					label: "Konštantný symbol"
					// rules: "required|integer"
				},
				detail_specific_symbol: {
					label: "Špecifický symbol"
					// rules: "required|integer"
				},
				detail_description: {
					label: "Popis"
				}
			}
		}
	}
}
