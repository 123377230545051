import React, {useState} from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import PatientRequestForm from "../patientRequestForm/patientRequestForm"
import PatientRequestStore from "../../../stores/PatientRequestStore"
import PatientRequestSaveButtons from "../patientRequestForm/patientRequestSaveButtons"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import CancelIcon from "@material-ui/icons/Clear"
import GlobalStore from "../../../../../global/store/GlobalStore"

function PatientRequestContainer(props) {
	const [maxFormKey, setMaxFormKey] = useState(Math.max(...Object.keys(props.forms)))

	const addForm = () => {
		const firstForm = props.forms[Math.min(...Object.keys(props.forms))]
		props.forms[+maxFormKey + 1] = props.options.createFormFunction()
		props.forms[+maxFormKey + 1].$("diagnosis").value = firstForm.$("diagnosis").value
		props.forms[+maxFormKey + 1].$("professional_activity").value = PatientRequestStore.paAmbDefaultID
		setMaxFormKey(+maxFormKey + 1)
	}

	const renderMultipleRequest = () => {
		const requestForms = []
		if (isSafe(props.forms)) {
			Object.keys(props.forms).forEach((formKey, idx) => {
				requestForms.push(
					<PatientRequestForm key={idx} keyVal={idx} form={props.forms[formKey]} options={props.options} />
				)
				if (Object.keys(props.forms).length > 1) {
					requestForms.push(
						<div className="xs-cancel-btn">
							<XsButton
								className="xs-danger xs-outline pullLeft"
								text={<FormattedMessage id="Common.label.cancel" />}
								onClick={() => {
									const handler = () => {
										delete props.forms[formKey]
										setMaxFormKey(maxFormKey + 1)
									}
									GlobalStore.openConfirmationDialog("patientCardAction", {
										onConfirm: handler,
										text: "Common.label.cancelForm"
									})
								}}
								icon={<CancelIcon />}
							/>
						</div>
					)
				}
			})
		}
		return requestForms
	}

	return (
		<div>
			<PatientRequestSaveButtons
				top
				form={props.forms}
				options={props.options}
				addForm={() => {
					addForm()
				}}
				clearAllReqeust={() => {}}
			/>
			{renderMultipleRequest()}
			<PatientRequestSaveButtons
				form={props.forms}
				options={props.options}
				addForm={() => {
					addForm()
				}}
				clearAllReqeust={() => {}}
			/>
		</div>
	)
}

export default injectIntl(observer(PatientRequestContainer))
