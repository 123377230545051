import React from "react"
import {FormattedMessage} from "react-intl"

export default {
	load() {
		return {
			fields: {
				search_column: {
					label: <FormattedMessage id="Common.label.find" />,
					type: "string"
				},
				code_ext: {
					label: <FormattedMessage id="Common.label.code" />,
					type: "string"
				}
			}
		}
	}
}
