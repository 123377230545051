import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import XsButton from "../../global/ui/xsButton/xsButton"
import XsIconButton from "../../global/ui/xsButton/xsIconButton"
import DeleteIcon from "@material-ui/icons/Clear"

import EHealthProgressDialogStore from "./stores/EHealthProgressDialogStore"
import GlobalStore from "../../global/store/GlobalStore"
import XsLoading from "../../global/ui/xsLoading/xsLoading"
import moment from "moment"

@injectIntl
@observer
export default class EHealthProgressDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	calculateProcessDuration(created) {
		const duration = moment.duration(moment.utc().diff(moment.utc(created)))
		const totalSeconds = Math.round(duration.asSeconds())
		if (totalSeconds > 59) {
			return `${Math.round(duration.minutes())} m ${Math.round(duration.seconds())} s`
		} else {
			return `${totalSeconds} s`
		}
	}

	render() {
		return (
			<Dialog
				id="xsEHealthProgress"
				open={EHealthProgressDialogStore.isOpen}
				onClose={() => EHealthProgressDialogStore.close()}
				//onEscapeKeyDown={() => DrugInteractionsDialogStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle>
					<div className="xs-eHealthProgress-header">
						<div className="xs-eHealthProgress-title">
							<FormattedMessage id="Common.eHealthStatus.header.text" />
						</div>
						<XsIconButton
							className="xs-dialog-header"
							icon={<DeleteIcon onClick={() => EHealthProgressDialogStore.close()} />}
						/>
					</div>
				</DialogTitle>
				<DialogContent>
					<div className="xs-body">
						{EHealthProgressDialogStore.isFetching ? (
							<XsLoading />
						) : (
							<div>
								{isSafe(GlobalStore.gwApp.get("Actions")) && GlobalStore.gwApp.get("Actions").length > 0
									? GlobalStore.gwApp.get("Actions").map((row, idx) => {
											return (
												<div className="processRow" key={idx}>
													<span className="processName">{row.Action}</span>
													<span className="processDuration">{row.Duration}</span>
													{/* this.calculateProcessDuration(row.Created) */}
												</div>
											)
									  })
									: this.props.intl.formatMessage({id: "Common.eHealthStatus.noProcessess"})}
							</div>
						)}
					</div>
				</DialogContent>
				<DialogActions className="xs-button">
					<div>
						<XsButton
							className="xs-default xs-outline"
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => EHealthProgressDialogStore.close()}
						/>
					</div>
				</DialogActions>
			</Dialog>
		)
	}
}
