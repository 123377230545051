import React from "react"
import {FormattedMessage} from "react-intl"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				time_from: {
					label: <FormattedMessage id="Capitation.List.From" />,
					type: "date",
					value: moment().startOf("day")
				},
				time_to: {
					label: <FormattedMessage id="Capitation.List.To" />,
					type: "date",
					value: moment().endOf("day")
				},
				orgunit: {
					label: <FormattedMessage id="Patient.form.patientrequest.orgunit" />
				},
				insurer: {
					label: <FormattedMessage id="Common.label.insurer" />,
					type: "string"
				},
				identifier: {
					label: <FormattedMessage id="Patient.labels.IDNumber" />,
					rules: "string"
				},
				insurence_type: {
					label: <FormattedMessage id="Agreement.Container.Detail.Attribute.InsuranceType" />
				}
			}
		}
	}
}
