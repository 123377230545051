"use strict"

import UIStore from "../../../stores/UIStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import PatientRecordStore from "../../../stores/PatientRecordStore"
import RouterStore from "../../../../../global/store/RouterStore"

export default {
	load(props, onSave) {
		return {
			onSubmit(form) {
				UIStore.isFormSaving = true
				PatientRecordStore.clinicalValuesChips.forEach((i) => {
					if (isEmpty(i.col1) || isEmpty(i.col2)) {
						if (isSafe(PatientRecordStore.clinicalValuesChipsFormFocus)) {
							PatientRecordStore.clinicalValuesChipsFormFocus.focus()
						} else {
							PatientRecordStore.clinicalValuesChipsForm.showForm()
							setTimeout(() => {
								if (isSafe(PatientRecordStore.clinicalValuesChipsFormFocus)) {
									PatientRecordStore.clinicalValuesChipsFormFocus.focus()
								}
							}, 0)
						}
						form.$("OF").invalidate()
					}
				})
				if (PatientRecordStore.diagnosisChips.length == 0) {
					document.getElementById("diagnosis").click()
					form.$("OF").invalidate()
				}
				PatientRecordStore.diagnosisChips.forEach((i) => {
					if (isSafe(i) && (isEmpty(i.col1) || isEmpty(i.col2))) {
						GlobalStore.defaultFocusFields["patientRecordForm"].focus()
						form.$("OF").invalidate()
					}
				})
				if (isNotEmpty(form.$("doctorPersonnelExpertiseId").value)) {
					if (isEmpty(form.$("doctorPersonnelExpertiseId").value) || isEmpty(form.$("doctorOUPZS").value)) {
						setTimeout(() => {
							window.scrollTo(0, 0)
						}, 0)
						form.$("doctorPersonnelExpertiseId").invalidate()
					}
				}

				if (isNotEmpty(form.$("substitutingPersonnelExpertiseId").value)) {
					if (
						isEmpty(form.$("substitutingPersonnelExpertiseId").value) ||
						isEmpty(form.$("substitutingDoctorOUPZS").value)
					) {
						form.$("substitutingPersonnelExpertiseId").invalidate()
						setTimeout(() => {
							window.scrollTo(0, 0)
						}, 0)
					}
				}

				// if (isEmpty(htmlToPlain(form.$("record").value)) /*|| isEmpty(htmlToPlain(form.$("OF").value))*/) {
				// 	form.$("record").invalidate()
				// }
			},
			onSuccess(form) {
				const draftID = form.$("version").get("value") === "draft" ? form.$("recordId").value : null //Skontroluje  vykony  a  ak  su  v  poriadku  ulozi
				PatientRecordStore.checkDeliverysForConstraints(() => {
					PatientRecordStore.checkDeliverysAndSaveForm(
						form,
						() => {
							onSave("RECORD", false, false)
							afterRecordFormSave()
						},
						false,
						draftID
					)
				}, form.$("recordId").value)
			},
			onError() {
				UIStore.isFormSaving = false
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}
	}
}

const afterRecordFormSave = () => {
	//  deleteFromSession()
	// PatientRecordForm.clinicalValuesChips = []
	// PatientRecordForm.diagnosisChips = []
	UIStore.overviewTab = "overview"
	PatientRecordStore.clearProvidedServices(RouterStore.location.search.replace(/([^0-9])+/, ""))
	//  DataStore.setFormDataState(formName,  formDataStateCL.EMPTY)  uz  asi  netreba????
}
