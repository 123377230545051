export default {
	load: {
		storeKey: "PatientCapitation",
		params: ["capitationId"],
		type: "EHR.Data.Patient.Capitation"
	},
	scheme: {
		validity: {
			object: {
				scheme: {
					from: {
						field: "from"
					},
					to: {
						field: "to"
					}
				}
			}
		},
		end_reason: {
			object: {
				scheme: {
					_id: {
						field: "end_reason"
					}
				}
			}
		},
		doctor: {
			relation: {
				_type: "EHR.Data.Entity.Personnel",
				_id: "#personnelId"
			}
		},
		patient: {
			relation: {
				_type: "Entity.Data.SpecificRel",
				_id: "#patientId"
			}
		},
		provider: {
			relation: {
				_type: "EHR.Data.Entity.Provider",
				_id: "#providerId"
			}
		}
	}
}
