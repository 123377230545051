import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "dead_report"
				},

				// ikf: {
				//   label: "IKF",
				//   value: "",
				// },
				rok: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.rok" />,
					value: "",
					type: "split"
				},
				mesiac: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.mesiac" />,
					value: "",
					type: "split"
				},
				hlaseniePreSU: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.hlaseniePreSU" />,
					value: ""
				},
				umrtieNaNebezpecnuChorobu: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.umrtieNaNebezpecnuChorobu" />,
					value: ""
				},
				rodnePriezvisko: {
					label: <FormattedMessage id="Common.label.birthName" />,
					value: ""
				},
				datumUmrtia: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.datumUmrtia" />,
					type: "dateSplit"
				},
				hodinaUmrtia: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.hodinaUmrtia" />,
					value: ""
				},
				statOkresObecUmrtia: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.statOkresObecUmrtia" />,
					value: ""
				},
				miestoUmrtia: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.miestoUmrtia" />,
					value: ""
				},
				miestoNarodenia: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.miestoNarodenia" />,
					value: ""
				},
				// pohlavie: {
				//   label: "Pohlavie",
				//   value: "",
				// },
				// pohlavieSlovom: {
				//   label: "Pohlavie slovom",
				//   value: "",
				// },
				statneObcianstvo: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.statneObcianstvo" />,
					value: ""
				},
				dosiahnuteVzdelanie: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.dosiahnuteVzdelanie" />,
					value: ""
				},
				zamestnanie: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.zamestnanie" />,
					value: ""
				},
				narodnost: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.narodnost" />,
					value: ""
				},
				county: {},
				countyForm: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.okres" />,
					value: ""
				},
				city: {},
				cityForm: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.obec" />,
					value: ""
				},
				street: {},
				streetForm: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.ulica" />,
					value: ""
				},
				house_number: {},
				house_numberForm: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.cislo" />,
					value: ""
				},
				country: {},
				countryForm: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.stat" />,
					value: ""
				},

				pohrebSpopolnenimPovoluje: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.pohrebSpopolnenimPovoluje" />,
					type: "checkbox",
					value: false
				},
				pohrebSpopolnenimNepovoluje: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.pohrebSpopolnenimNepovoluje" />,
					type: "checkbox",
					value: false
				},
				pohrebSpopolnenimV: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.pohrebSpopolnenimV" />,
					value: ""
				},
				pohrebSpopolnenimDatum: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.pohrebSpopolnenimDatum" />,
					value: "",
					type: "date"
				},

				prevozNaPochovaniePovoluje: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.prevozNaPochovaniePovoluje" />,
					type: "checkbox",
					value: false
				},
				prevozNaPochovanieNepovoluje: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.prevozNaPochovanieNepovoluje" />,
					type: "checkbox",
					value: false
				},
				prevozNaPochovanieV: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.prevozNaPochovanieV" />,
					value: ""
				},
				prevozNaPochovanieDatum: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.prevozNaPochovanieDatum" />,
					value: "",
					type: "date"
				},
				stav: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.stav" />,
					value: ""
				},
				rcPozostalehoManzela: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.rcPozostalehoManzela" />,
					value: "",
					type: "split"
				},
				detiDlzkaZivotaHodiny: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.detiDlzkaZivotaHodiny" />,
					value: "",
					type: "split"
				},
				detiKdeNastaloUmrtie: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.detiKdeNastaloUmrtie" />,
					value: ""
				},
				detiPorodnaHmotnost: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.detiPorodnaHmotnost" />,
					value: "",
					type: "split"
				},
				detiOtecManzelom: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.detiOtecManzelom" />,
					value: ""
				},

				kodChoroba: {
					value: ""
				},
				kodIDChoroba: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.kodIDChoroba" />,
					value: ""
				},
				kodPredchadzajucePriciny: {
					value: ""
				},
				kodIDPredchadzajucePriciny: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.kodIDPredchadzajucePriciny" />,
					value: ""
				},
				kodPrvotnaPricina: {
					value: ""
				},
				kodIDPrvotnaPricina: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.kodIDPrvotnaPricina" />,
					value: ""
				},
				kodIneChorobneStavy: {
					value: ""
				},
				kodIDIneChorobneStavy: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.kodIDIneChorobneStavy" />,
					value: ""
				},

				mechanizmusSmrti1: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.mechanizmusSmrti1" />,
					type: "checkbox",
					value: false
				},
				mechanizmusSmrti2: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.mechanizmusSmrti2" />,
					type: "checkbox",
					value: false
				},
				mechanizmusSmrti3: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.mechanizmusSmrti3" />,
					type: "checkbox",
					value: false
				},
				mechanizmusSmrti4: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.mechanizmusSmrti4" />,
					type: "checkbox",
					value: false
				},
				kodMechanizmusSmrti: {
					value: ""
				},
				kodIDMechanizmusSmrti: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.kodIDMechanizmusSmrti" />,
					value: ""
				},

				miestoPrehliadky: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.miestoPrehliadky" />,
					value: ""
				},
				datumHodinaPrehliadky: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.datumHodinaPrehliadky" />,
					value: "",
					type: "dateTime"
				},
				navrhPrehliadajucehoLekara: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.navrhPrehliadajucehoLekara" />,
					value: ""
				},

				kodChorobaPitva: {
					value: ""
				},
				kodIDChorobaPitva: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.kodIDChorobaPitva" />,
					value: ""
				},
				kodPredchadzajucePricinyPitva: {
					value: ""
				},
				kodIDPredchadzajucePricinyPitva: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.kodIDPredchadzajucePricinyPitva" />,
					value: ""
				},
				kodPrvotnaPricinaPitva: {
					value: ""
				},
				kodIDPrvotnaPricinaPitva: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.kodIDPrvotnaPricinaPitva" />,
					value: ""
				},
				kodIneChorobneStavyPitva: {
					value: ""
				},
				kodIDIneChorobneStavyPitva: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.kodIDIneChorobneStavyPitva" />,
					value: ""
				},

				mechanizmusSmrti1Pitva: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.mechanizmusSmrti1Pitva" />,
					type: "checkbox",
					value: false
				},
				mechanizmusSmrti2Pitva: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.mechanizmusSmrti2Pitva" />,
					type: "checkbox",
					value: false
				},
				mechanizmusSmrti3Pitva: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.mechanizmusSmrti3Pitva" />,
					type: "checkbox",
					value: false
				},
				mechanizmusSmrti4Pitva: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.mechanizmusSmrti4Pitva" />,
					type: "checkbox",
					value: false
				},
				kodMechanizmusSmrtiPitva: {
					value: ""
				},
				kodIDMechanizmusSmrtiPitva: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.kodIDMechanizmusSmrtiPitva" />,
					value: ""
				},

				miestoPitvy: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.miestoPitvy" />,
					value: ""
				},
				datumHodinaPitvy: {
					label: <FormattedMessage id="PrintTemplate.DeadReport.datumHodinaPitvy" />,
					value: "",
					type: "dateTime"
				}
			}
		}
	}
}
