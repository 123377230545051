//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import GenerikaDialogStore from "../../../stores/GenerikaDialogStore"

@observer
export default class GenerikaDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			isSafe(GenerikaDialogStore.generika) && (
				<Dialog
					id="xsGenerikaDialog"
					open={GenerikaDialogStore.isOpen}
					onClose={() => GenerikaDialogStore.close()}
					//onEscapeKeyDown={() => DrugInteractionsDialogStore.close()}
					disableBackdropClick={true}
					maxWidth="md"
					className="xs-base-dialog"
				>
					<DialogTitle>
						<div className="xs-header">
							<div className="xs-header-icon">
								<i className="fal fa-pills fa-2x"></i>
							</div>
							<div className="xs-header-title">
								<FormattedMessage id="Patient.generics.dialog.headerMessage" />
							</div>
						</div>
					</DialogTitle>
					<DialogContent className="xs-content">
						<table>
							<thead>
								<tr>
									<th className="xs-width-50">
										<FormattedMessage id="Patient.generics.dialog.code" />
									</th>
									<th>
										<FormattedMessage id="Common.label.name" />
									</th>
									<th>
										<FormattedMessage id="Patient.generics.dialog.nameAddendum" />
									</th>
									<th className="xs-width-75">
										<FormattedMessage id="Patient.generics.dialog.codeATC" />
									</th>
									<th className="xs-width-90">
										<FormattedMessage id="Patient.generics.dialog.nameATC" />
									</th>
									<th>
										<FormattedMessage id="Patient.generics.dialog.nameATCAddendum" />
									</th>
									<th className="xs-width-50">
										<FormattedMessage id="Patient.generics.dialog.price" />
									</th>
									<th>
										<FormattedMessage id="Patient.generics.dialog.payPatient" />
									</th>
								</tr>
							</thead>
							<tbody>
								{GenerikaDialogStore.generika.map((gen, idx) => {
									return (
										<tr key={idx} onClick={() => GenerikaDialogStore.selectDrug(gen.kod)}>
											<td className="xs-generika-cell">{gen.kod}</td>
											<td className="xs-generika-cell">{gen.nazov}</td>
											<td className="xs-generika-cell">{gen.doplnok_nazvu}</td>
											<td className="xs-generika-cell">{gen.kod_atc}</td>
											<td className="xs-generika-cell">{gen.nazov_atc}</td>
											<td className="xs-generika-cell">{gen.doplnok_nazvu_atc}</td>
											<td className="xs-generika-cell">{gen.cena}</td>
											<td className="xs-generika-cell">{gen.doplatok_pacienta}</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</DialogContent>
					<DialogActions className="xs-footer">
						<XsButton
							className="xs-default xs-outline"
							icon={<i className="fal fa-times"></i>}
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => GenerikaDialogStore.close()}
						/>
					</DialogActions>
				</Dialog>
			)
		)
	}
}
