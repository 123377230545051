//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import CancelIcon from "@material-ui/icons/Clear"

import XsButton from "../xsButton/xsButton"
import XsIconButton from "../xsButton/xsIconButton"
import DeleteIcon from "@material-ui/icons/Clear"

// import XsTableServerWithFilters from "../xsTableServerWithFilters/xsTableServerWithFilters"
import XsTable from "../xsTable/xsTable"
import Diacritics from "../../helpers/diacritics"
// import "./xsTableServerFilters.less"
import {Grid, TextField} from "@material-ui/core"

@observer
export default class AutocompleteModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			filters: {},
			filteredData:
				isSafe(props.specFilter) && typeof props.specFilter === "function"
					? props.specFilter(props.data)
					: props.data
		}
	}

	handleFilterChange = (filterObj) => {
		delayedCallback(250, () => {
			this.setState({filters: Object.assign(this.state.filters, filterObj)}, () => {
				let filteredData = this.props.data
				Object.keys(this.state.filters).forEach((filterKey) => {
					filteredData = isNotEmpty(this.state.filters[filterKey])
						? filteredData.filter((row) =>
								row[filterKey].toString().toLowerCase().includes(this.state.filters[filterKey].toString().toLowerCase())
						  )
						: filteredData
				})

				filteredData =
					isSafe(this.props.specFilter) && typeof this.props.specFilter === "function"
						? this.props.specFilter(filteredData)
						: filteredData

				this.setState({filteredData: filteredData})
			})
		})
	}

	tableFilters() {
		const {columnDefs} = this.props.config

		this.tableFilterItems = []

		Object.keys(columnDefs).map((column) => {
			const columnDef = columnDefs[column]
			const dbColumnName = columnDef.dbName

			if (isSafe(columnDef.filter) && isSafe(columnDef.filter.renderElement)) {
				this.tableFilterItems.push(
					<Grid item xs={columnDef.filter.gridWidth} key={column}>
						{// INPUT
						columnDef.filter.renderElement === "input" && (
							<TextField
								InputLabelProps={{
									shrink: true
								}}
								className={`filter-${dbColumnName}`}
								autoComplete="off"
								autoFocus={false}
								label={isSafe(columnDef.filterLabel) ? columnDef.filterLabel : columnDef.title}
								onChange={(e) => {
									this.handleFilterChange({[dbColumnName]: Diacritics.remove(e.target.value)})
								}}
							/>
						)}
					</Grid>
				)
			}
		})

		return (
			<Grid container direction="row" spacing={8}>
				{this.tableFilterItems}
			</Grid>
		)
	}

	handleClose = () => {
		this.setState({filters: {}, filteredData: this.props.data})
		this.props.onClose()
	}

	render() {
		const config = Object.assign(this.props.config, {mapper: (dataRow) => dataRow, dataSource: this.state.filteredData})

		return (
			<Dialog
				id="xsAutocompleteLocalModal"
				open={this.props.isOpen}
				onClose={() => this.handleClose()}
				//onEscapeKeyDown={() => DrugInteractionsDialogStore.close()}
				fullWidth={true}
				className="nx-modal-base"
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle>
					<div className="nx-header">
						<div className="nx-title">
							{/* <FormattedMessage id="Patient.generics.dialog.headerMessage" />*/}
							{this.props.modalTitle}
						</div>
						<XsIconButton className="xs-close-btn" icon={<DeleteIcon onClick={() => this.handleClose()} />} />
					</div>
				</DialogTitle>
				<DialogContent>
					<div className="xs-modal-with-filters">{this.tableFilters()}</div>
					<div className="xs-modal-grid">
						<XsTable config={config} />
					</div>
				</DialogContent>
				<DialogActions>
					<div className="xs-button">
						<XsButton
							className="xs-danger xs-outline"
							icon={<CancelIcon />}
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => this.handleClose()}
						/>
					</div>
				</DialogActions>
			</Dialog>
		)
	}
}
