"use strict"

import {observable, action} from "mobx"
import Copy from "copy-html-to-clipboard"
import UIStore from "./UIStore"
import DataStore from "./DataStore"
import api from "../actions/api"
import {getUserDoctorInfo, printReport, getSelectedOrgUnit, getHTMLFromTableText} from "../../../global/helpers/actions"
import moment from "moment"

class LabResultsStore {
	@observable fullTable = true
	@observable isOpenTextDialog = false

	fullText = ""
	selectedLabResults = {}

	@action copySelectedResult() {
		let copyHtml = ""

		//kazdy 5 zaznam sa odriadkuje pomocou br
		let index = 0
		Object.values(this.selectedLabResults)
			.sort((firstObj, secondObj) => firstObj.indexNumber - secondObj.indexNumber) //Zoradi podla indexov v zozname EMA-14362
			.forEach((row) => {
				let val = this.getValue(row)
				// let formatedValue = value.replace(/(\r\n|\n|\r)/gm, "<br />").replace(/(\t)/gm, "&nbsp;&nbsp;&nbsp;&nbsp;")
				// const isTable = value != formatedValue // pri tabulke treba dat hlavicku do noveho riadku
				const {isTable, value} = getHTMLFromTableText(val)
				const addBr = index % 5 == 0 && index != 0
				logger("value", value)
				copyHtml += `<b>${row.test}:</b>  ${isTable ? "<br />" : ""} ${value} ${
					isNotEmpty(row.unit) ? `[${row.unit}]` : ""
				} , ${addBr ? "<br/>" : ""}`
				index++
			})

		Copy(`<p>${copyHtml}</p>`, {
			asHtml: true
		})
	}

	@action getValue = (row) => {
		let tempValue = ""
		if (row.value_is_numeric) {
			if (isNotEmpty(row.value)) {
				let status = this.getStatus(row)
				let color = "#60606f"

				switch (status) {
					case "ok":
						color = "#00a787"
						break
					case "low":
						color = "#c6184e"
						break
					case "high":
						color = "#880e4f"
						break
				}

				if (row.value.toString().includes(".")) {
					tempValue = `<span style="color:${color}"><b>${row.value.toString().replace(".", ",")}</b></span>`
				} else {
					tempValue = `<span style="color:${color}"><b>${row.value}</b></span>`
				}
			}
		} else {
			tempValue = isNotEmpty(row.value_text) ? row.value_text : ""
		}
		return tempValue
	}

	@action getStatus = (row) => {
		const {value_is_numeric, range_low, range_high, value} = row

		if (value_is_numeric) {
			if (isSafe(range_low) && isSafe(range_high)) {
				if (range_low <= value && value <= range_high) {
					return "ok" //"greenSuccess"
				}
				if (value < range_low) {
					return "low" //"redDark"
				}
				if (value > range_high) {
					return "high" //"redDanger"
				}
			} else {
				if (isSafe(range_low)) {
					if (value < range_low) {
						return "low"
					}
					return "ok"
				} else if (isSafe(range_high)) {
					if (range_high < value) {
						return "high"
					}
					return "ok"
				} else {
					return "noValue"
				}
			}
		} else {
			return "text"
		}
	}

	@action openTable() {
		this.fullTable = true
	}

	@action closeTable() {
		this.fullTable = false
	}

	@action openTextDialog(value) {
		this.fullText = value
		this.isOpenTextDialog = true
	}

	@action closeTextDialog() {
		this.fullText = ""
		this.isOpenTextDialog = false
	}

	printLabResults(recordID) {
		UIStore.isFormSaving = true

		api
			.loadRecord(recordID, "L3")
			.call()
			.then((labData) => {
				const pacInfo = DataStore.patientDTO.get("patient")
				const doctorInfo = getUserDoctorInfo()
				const orgUnit = getSelectedOrgUnit()

				const pacAddress = pacInfo.validInfo.address
				let printAddress = ""
				if (pacInfo.validInfo && pacAddress) {
					printAddress += `${pacAddress.city ? `, ${pacAddress.city}, ` : ""}`
					printAddress += `${
						pacAddress.street
							? `${pacAddress.street}${pacAddress.house_number ? ` ${pacAddress.house_number}` : ""},`
							: ""
					}`
					printAddress += pacAddress.zip
				}

				let printValues = {
					lab_name:
						isSafe(labData.laboratory_system) && isSafe(labData.laboratory_system.name)
							? `Výsledky z laboratória (${labData.laboratory_system.name})`
							: "Výsledky z laboratória",
					name: `${pacInfo.full_name}`,
					results: "",
					identifier: pacInfo.identifier,
					address: pacInfo.validInfo && pacAddress ? printAddress : "",
					doctorcode: doctorInfo ? doctorInfo.code : "",
					doctorname: doctorInfo ? doctorInfo.name : "",
					date: labData.written_at ? moment(labData.written_at).format("DD.MM.YYYY") : ""
				}

				//OrgUnit
				if (orgUnit) {
					const primaryAddress = doctorInfo.address
					const businessContact = doctorInfo.contact
					const providerCode = orgUnit.identifiers.find((i) => i.type === "PROVIDERCODE")
					printValues["orgunitname"] = orgUnit.name_ext
					printValues["orgunitaddress"] = primaryAddress ? primaryAddress.full_address : ""
					if (businessContact) {
						printValues["orgunitemail"] = businessContact.email ? businessContact.email : ""
						printValues["orgunitcontact"] = `${businessContact.mobile ? businessContact.mobile : ""} ${
							businessContact.phone ? businessContact.phone : ""
						}`
					}
					printValues["orgunitcode"] = providerCode ? providerCode.value : ""
				}

				//Insurer
				if (pacInfo.validInfo && pacInfo.validInfo.insurance) {
					printValues[
						"insurer"
					] = `${pacInfo.validInfo.insurance.insurer.code_ext} - ${pacInfo.validInfo.insurance.insurer.name_ext}`
				}

				//Clinical values ResultItem
				const resultValues =
					labData.clinical_values &&
					labData.clinical_values
						.filter((cv) => cv.active && cv._type === "EHR.Data.Laboratory.ResultItem")
						.sort((a, b) => a.item_order - b.item_order)

				if (resultValues) {
					resultValues.map((result) => {
						const ranges = result.ranges
						const resultValue = result.value
						let bold = ""
						let rangesText = ""

						//Hraničné hodnoty workaround
						//Record vracia 2 ranges s rovnakými min a max values
						//Musíme ich porovnať (min_value) a zistiť aké sú min a max
						if (ranges && ranges.length === 2) {
							let minRange = null
							let maxRange = null
							const rangeA = ranges[0].min_value
							const rangeB = ranges[1].min_value
							if (rangeA < rangeB) {
								minRange = rangeA
								maxRange = rangeB
							} else {
								minRange = rangeB
								maxRange = rangeA
							}
							rangesText = `${minRange}${maxRange ? ` - ${maxRange}` : ""}`

							if (resultValue < minRange || resultValue > maxRange) {
								bold = " bold"
							}
						}

						//Kód
						printValues.results += `<tr><td class="tab">${result.test ? result.test : ""}</td>`

						//Názov
						printValues.results += `<td class="tab">${result.measure_text}${
							result.unit_text ? ` [${result.unit_text}]` : ""
						}</td>`

						//Výsledok
						printValues.results += `<td class="tab${bold}">${resultValue}</td>`

						//Hraničné hodnoty
						printValues.results += `<td class="tab">${rangesText}</td></tr>`
					})
				}

				printReport("lab_results", printValues)

				UIStore.isFormSaving = false
			})
	}
}
var singleton = new LabResultsStore()
export default singleton
