"use strict"

// default imports for all farms
import React from "react"
import {observer} from "mobx-react"

// import AddIcon from "@material-ui/icons/Add"

import {FormattedMessage, injectIntl} from "react-intl"

import dataBindings from "./patientMedicationBindings"
// import PatientRequestStore from "../../../stores/PatientRequestStore"
import RouterStore from "../../../../../global/store/RouterStore"
import DataStore from "../../../stores/DataStore"
import DDaction from "../../../actions/patientCard"
// import GlobalStore from "../../../../../global/store/GlobalStore"
import api from "../../../../ambulance/actions/api"
import {resetForm, insertFormDataByScheme} from "../../../../../global/helpers/bindings"
import {
	getSelectedOrgUnitID,
	getUserPersonnelID,
	getSelectedOrgUnit,
	getUserDoctorInfo,
	getUser,
	getGWServerRole
} from "../../../../../global/helpers/actions"
import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"
import PrintIcon from "@material-ui/icons/Print"
import AddIcon from "@material-ui/icons/Add"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import UIStore from "../../../stores/UIStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import {formDataStateCL} from "../../../../../global/config/codeLists"
import WarningStore from "../../../../../global/store/WarningStore"
import settings from "../../../../../global/config/settings"
import PatientMedicationStore from "../../../stores/PatientMedicationStore"

@injectIntl
@observer
export default class PatientMedicationSaveButtons extends React.Component {
	constructor(props) {
		super(props)
	}

	medicationShortcuts = (e) => {
		if (RouterStore.location.pathname === "/patient" && UIStore.patientCardTab == "medication") {
			//ulozit medication Alt + U
			if (e.altKey && e.keyCode == 85) {
				e.preventDefault()
				this.validateForms(Object.keys(this.props.form), e)
			}

			//pridat novu medikaciu Alt + A
			if (e.altKey && e.keyCode == 65) {
				e.preventDefault()
				this.props.addForm()
			}
		}
	}

	componentDidMount() {
		document.body.addEventListener("keydown", this.medicationShortcuts)
	}

	componentWillUnmount() {
		document.body.removeEventListener("keydown", this.medicationShortcuts)
	}

	validateForms(keys, e, print) {
		let index = keys[0]
		const firstForm = this.props.form[index]

		this.props.form[index].validate().then(({isValid}) => {
			UIStore.isFormSaving = true
			if (isValid && keys.length > 1) {
				keys.shift()
				this.validateForms(keys, e, print)
			} else if (isValid && keys.length == 1) {
				let medication = []

				Object.keys(this.props.form).forEach((key) => {
					let obj = this.props.form[key]
					obj.$("print").value = print
					medication.push(this.getJsonFromForm(obj))
				})

				this.saveMedication(
					medication,
					() => {
						PatientMedicationStore.setFormDefaults(firstForm)
					},
					print
				)
			} else {
				GlobalStore.setNotificationMessage("Patient.form.patientPrescription.invalidForm")
				UIStore.isFormSaving = false
				return false
			}
		})
	}

	validateForm(print = false) {
		this.form.validate().then(({isValid}) => {
			if (isValid) {
				UIStore.isFormSaving = true

				this.form.$("print").value = print

				let medicationJson = this.getJsonFromForm(this.form)

				this.saveMedication(medicationJson)
			} else {
				GlobalStore.setNotificationMessage("Patient.form.patientPrescription.invalidForm")
				UIStore.isFormSaving = false
				this.invalidForm()
				return false
			}
		})
	}

	getJsonFromForm(form) {
		form.$("doctor_id").value = getUserPersonnelID()
		form.$("personnel_id").value = getUserPersonnelID()
		form.$("provider_id").value = getSelectedOrgUnitID()
		form.$("unit").value = form.$("unit").value ? form.$("unit").value : null
		form.$("medicationRoute").value = form.$("medicationRoute").value ? form.$("medicationRoute").value : "OTH"
		form.$("diagnosis").value =
			form.$("diagnosis").value == "" || isEmpty(form.$("diagnosis").value) ? null : form.$("diagnosis").value

		const medicationJson = insertFormDataByScheme(
			form,
			dataBindings,
			{
				patientId: DataStore.patientDTO.get("patientID"),
				print: form.$("print").value
			},
			() => resetForm(form, dataBindings),
			true
		)

		medicationJson["print"] = form.$("print").value
		medicationJson.version = {
			_id: "final",
			_type: "EHR.CL.Record.Version"
		}

		medicationJson["repeats_type"] = "1"
		medicationJson["instructions_for_preparation"] = null
		medicationJson["instructions_for_use"] = null
		medicationJson["supplement"] = null
		medicationJson["dosage_type"] = "FIX"
		medicationJson["dosage"] = null
		medicationJson["dose_periodicity"] = "1"
		medicationJson["duration_mins"] = null
		medicationJson["note"] = null
		medicationJson["transcription"] = null
		medicationJson["flags"] = null
		medicationJson["doctor_id"] = form.$("doctor_id").value
		medicationJson["provider_id"] = form.$("provider_id").value
		medicationJson["personnel_id"] = form.$("personnel_id").value
		medicationJson["repeats_number"] = null
		medicationJson["sending_doctor_id"] = null
		medicationJson["sent_at"] = null
		medicationJson["sending_provider_id"] = null
		medicationJson["substituting_doctor_id"] = null
		medicationJson["administered_at"] = form.$("dateOfDelivery").value
		medicationJson["written_at"] = form.$("dateOfDelivery").value
		medicationJson["vaccination_malady"] = isNotEmpty(form.$("typeOfVaccination").value)
			? form.$("typeOfVaccination").value
			: null
		medicationJson["repeats_number"] = isNotEmpty(form.$("numberOfDoses").value) ? form.$("numberOfDoses").value : null
		medicationJson["note"] = isNotEmpty(form.$("note").value) ? form.$("note").value : null
		medicationJson["cl_doctor_expertise"] = getUserDoctorInfo().expertise.cl_id

		const eventIdFromCalendar = DataStore.patientDTO.get("eventIdFromCalendar")
		if (isNotEmpty(eventIdFromCalendar)) {
			const predecessor = {
				rel_class_name: "Service.Data.Event",
				rel_object_id: eventIdFromCalendar,
				type: "predecessor",
				_type: "EHR.Data.Record.Related",
				_ref: false
			}

			if (isSafe(medicationJson["relateds"]) && Array.isArray(medicationJson["relateds"])) {
				medicationJson["relateds"].push(predecessor)
			} else {
				medicationJson["relateds"] = [predecessor]
			}
		}

		const canvas = document.getElementById("qrcode")

		if (isSafe(canvas)) {
			const base64 = canvas.toDataURL("image/png")
			medicationJson["qr_code_image"] = base64.replace("data:image/png;base64,", "")
		}

		return medicationJson
	}

	saveMedication(medications, callback, saveRecord = false) {
		api
			.savePrescription(medications)
			.call()
			.then((response) => {
				GlobalStore.setNotificationMessage("Common.label.medicationSaveSuccess")
				this.props.options.onClose("MEDICATION")

				//Samotna medikacia sa uz neda printovat pri save vzdy len s DEKURZOM
				// const printForms = () => {
				// 	if (
				// 		medications &&
				// 		medications.length > 0 &&
				// 		medications[0].print &&
				// 		!this.props.options.isRecordInStorage()
				// 	) {
				// 		Object.keys(response).forEach((obj) => {
				// 			PatientMedicationStore.printVaccination(response[obj].record_id)
				// 		})
				// 	}
				// }

				const medicationID = response[0]._id

				const afterSave = () => {
					UIStore.patientCardTab = ""
					UIStore.overviewTab = "overview"
					UIStore.isFormSaving = true
					const providerID = getSelectedOrgUnitID()
					DataStore.setFormDataState("PatientRequestForm", formDataStateCL.EMPTY)
					DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID"), null, null, true).then(
						(overview) => {
							DataStore.setPatientEHR(overview)
							window.scrollTo(0, 0)
							UIStore.isFormSaving = true
							if (saveRecord) {
								this.props.options.saveWithRecord(medicationID)
							} else {
								UIStore.isFormSaving = false
							}
							let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
							if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
								DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
							}
						}
					)
				}

				if (isSafe(callback) && typeof callback == "function") {
					callback()
				}

				if (isSafe(response) && response.length > 0) {
					response.forEach((item) => {
						if (
							isSafe(item) &&
							isSafe(item.ixs_signer_data) &&
							isSafe(item.ixs_signer_data.payload) &&
							isSafe(item.ixs_signer_data.payload.text)
						) {
							const selectedOrgUnit = getSelectedOrgUnit()
							const user = getUser()
							const OUPZS = isSafe(selectedOrgUnit)
								? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID")
								: null
							const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
							const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
							const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
							const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

							const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
								? DataStore.patientDTO.get("patient").validInfo.client
								: null
							const JRUZIDObj = isSafe(patientClientInfo.identifiers)
								? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
								: null

							fetch(`${settings.GW_BASE_URL}/WriteEHRExtract`, {
								method: "POST",
								headers: {
									Accept: "application/json",
									"Content-Type": "application/json"
								},
								body: JSON.stringify({
									ClassName: "ZapisOckovanie",
									ExtId: item.record_external_id,
									EHRExtract: item.ixs_signer_data.payload.text,
									Environment: getGWServerRole(),
									TimeCommitted: item.actual_time,
									AmbeePatientId: DataStore.patientDTO.get("patientID"),
									PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
									PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
									AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
									AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
									AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
									AmbeePersonnelId: getUserPersonnelID(),
									OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
									PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
									PersonnelExpertiseJRUZVersion: specVer,
									PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
									RecordSignerData: isSafe(item.record_signer_data)
										? item.record_signer_data.map((x) => {
												return {
													Ref: x._ref,
													Type: x._type,
													RecordClass: x.record_class,
													RecordExternalId: x.record_external_id,
													RecordId: x.record_id,
													QueueId: x.queue_id
												}
										  })
										: null
								})
							})
								.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
								.then((res) => {
									if (isSafe(res) && isNotEmpty(res.ErrorMessage)) {
										if (isSafe(res) && isNotEmpty(res.ErrorMessage)) {
											WarningStore.open("<b>Vytlačte papierový záznam!</b></br>" + res.ErrorMessage)
										}
									}
									// printForms()
									afterSave()
								})
								.catch(() => {
									// printForms()
									afterSave()
								})
						}
					})
				}
			})
	}

	checkMedicationVersion = () => {
		let tmp = false

		if (isSafe(this.form) && this.form.$("version").get("value") == "draft") tmp = true

		return tmp
	}

	render() {
		return (
			<div className="xs-patient-form-actions" style={this.props.top ? {borderTop: "none"} : {}}>
				<XsButton
					className="xs-success"
					text={<FormattedMessage id="Patient.form.patientinfo.saveandprintWithRecord" />}
					type="submit"
					onClick={(e) => {
						// this.validateForm()
						this.validateForms(Object.keys(this.props.form), e, true)
					}}
					icon={<PrintIcon />}
				/>
				<XsButton
					className="xs-success xs-outline"
					text={<FormattedMessage id="Patient.form.patientPrescription.SaveClose" />}
					type="submit"
					onClick={(e) => {
						// this.validateForm()
						this.validateForms(Object.keys(this.props.form), e, false)
					}}
					icon={<SaveIcon />}
				/>
				<XsButton
					className="xs-success xs-outline"
					text={<FormattedMessage id="Common.label.addMedication" />}
					onClick={() => {
						this.props.addForm()
					}}
					icon={<AddIcon />}
				/>
				<XsButton
					className="xs-danger xs-outline"
					text={<FormattedMessage id="Common.form.close" />}
					onClick={() => {
						this.props.options.onClose("MEDICATION", false, true)
						/*this.props.clearAllReqeust()
						UIStore.patientCardTab = ""*/
					}}
					icon={<CancelIcon />}
				/>
			</div>
		)
	}
}

