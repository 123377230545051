/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import moment from "moment"
import React from "react"
// import moment from "moment"

export default {
	load() {
		return {
			fields: {
				name: {
					label: <FormattedMessage id="Patient.form.problemForm.problem" />,
					rules: "string|required"
				},
				startDate: {
					label: <FormattedMessage id="Patient.form.problemForm.startOfProblem" />,
					rules: "required",
					type: "date",
					value: moment()
				},
				problem_type: {
					label: <FormattedMessage id="Common.label.dispensarisation" />,
					type: "checkbox"
				},
				diagnoses: {
					label: <FormattedMessage id="Patient.labels.Diagnosis" />,
					rules: "required"
				},
				diagnosis_desc: {
					label: <FormattedMessage id="Patient.form.patientrecord.clarification" />
				},
				life_threatening: {
					label: <FormattedMessage id="Patient.form.patientrecord.lifethreatening" />,
					type: "checkbox"
				},
				biohazard: {
					label: <FormattedMessage id="Common.label.biohazard" />,
					type: "checkbox"
				},
				endDate: {
					label: <FormattedMessage id="Common.label.endDate" />,
					type: "date"
				},
				reexamination_every_amount: {
					label: <FormattedMessage id="Common.label.controlMultiplicity" />
				},
				reexamination_every_unit: {
					label: <FormattedMessage id="Common.label.controlUnit" />
				},
				disease: {
					label: <FormattedMessage id="Common.label.disease" />
				},
				expected_for_unit: {
					value: "mm"
				},
				expected_for_amount: {
					label: <FormattedMessage id="Common.label.expectedDuration" />
				},
				end_reason: {
					label: <FormattedMessage id="Common.label.reason" />
				},
				symptom: {
					label: <FormattedMessage id="Patient.form.problemForm.symptoms" />
				},
				_id: {}
			}
		}
	}
}
