//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import DeleteIcon from "@material-ui/icons/Clear"
import MedicineCardDialogStore from "../../../stores/MedicineCardDialogStore"
import renderHTML from "react-render-html"

@observer
export default class MedicineCardDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			isNotEmpty(MedicineCardDialogStore.htmlContent) && (
				<Dialog
					id="xsMedicineCard"
					open={MedicineCardDialogStore.isOpen}
					onClose={() => MedicineCardDialogStore.close()}
					disableBackdropClick={true}
					maxWidth={false}
				>
					<DialogTitle>
						<div className="nx-insurer-header">
							<div className="nx-header-title">
								{MedicineCardDialogStore.setName ? (
									<FormattedMessage id="Patient.medicalCard.dialog.interactionCard" />
								) : (
									<FormattedMessage id="Patient.medicalCard.dialog.mediactionCard" />
								)}
							</div>
							<XsIconButton
								className="xs-dialog-header"
								icon={<DeleteIcon onClick={() => MedicineCardDialogStore.close()} />}
							/>
						</div>
					</DialogTitle>
					<DialogContent>
						<div className="nx-body">
							<div className="xs-html-content">
								{isEmpty(MedicineCardDialogStore.htmlContent) ? "" : renderHTML(MedicineCardDialogStore.htmlContent)}
							</div>
						</div>
					</DialogContent>
					<DialogActions>
						<div className="xs-button">
							<XsButton
								className="xs-default xs-outline"
								text={<FormattedMessage id="Common.label.ok" />}
								onClick={() => MedicineCardDialogStore.close()}
							/>
						</div>
					</DialogActions>
				</Dialog>
			)
		)
	}
}
