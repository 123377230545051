// import {FormattedMessage} from "react-intl"
// import React from "react"

export default {
	load() {
		return {
			fields: {
				file_name: {
					rules: "string"
				},
				version: {
					label: "Verzia",
					rules: "required|integer"
				},
				sub_version_1: {
					label: "Sub verzia 1",
					rules: "required|integer"
				},
				sub_version_2: {
					label: "Sub verzia 2",
					rules: "required|integer"
				},
				sub_version_3: {
					label: "Sub verzia 3",
					rules: "required|integer"
				}
			}
		}
	}
}
