import React from "react"
import {observer} from "mobx-react"
import Grid from "@material-ui/core/Grid"
import api from "../../actions/api"
import GlobalStore from "../../../../global/store/GlobalStore"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import settings from "../../../../global/config/settings"

@observer
export default class RoutesList extends React.Component {
	constructor(props) {
		super(props)
		api
			.loadRoutes()
			.call()
			.then((data) => {
				GlobalStore.routes = data[0].routes
			})
	}

	render() {
		const APIBASE = settings.API_BASE_URL
		return (
			<Grid container>
				{GlobalStore.routes &&
					GlobalStore.routes.map((r, ix) => {
						return (
							<Grid item xs={12} key={ix}>
								<Grid container>
									<ExpansionPanel style={{width: "100%", margin: "20px"}}>
										<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
											<span style={{fontSize: "18px"}}>{r.path}</span> ({r.method})
										</ExpansionPanelSummary>
										<ExpansionPanelDetails>
											<Grid container>
												<Grid item xs={12}>
													<p>
														<b>URI</b>: {APIBASE}
														{r.path}
													</p>
												</Grid>
												<Grid item xs={12}>
													<p>
														<b>Metóda</b>: {r.method}
													</p>
												</Grid>
												<Grid item xs={12}>
													<p style={{whiteSpace: "pre"}} dangerouslySetInnerHTML={{__html: r.description}}></p>
												</Grid>
											</Grid>
										</ExpansionPanelDetails>
									</ExpansionPanel>
								</Grid>
							</Grid>
						)
					})}
			</Grid>
		)
	}
}
