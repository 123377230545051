import React from "react"
import {observer} from "mobx-react"
import DrugReportList from "./drugReportList"
import MedEquipReport from "./medEquipReport"
import XsTabsIcon from "../../../../../global/ui/xsTabs/xsTabsIcon"
import DrugReportStore from "../../../stores/DrugReportStore"
import {injectIntl} from "react-intl"
import {Grid} from "@material-ui/core"

function drugReportContainer(props) {
	return (
		<Grid container className="xs-drugReport-list">
			<Grid item xs={12}>
				<XsTabsIcon
					value={DrugReportStore.tabCard}
					onChangeTab={(value) => {
						DrugReportStore.tabCard = value
					}} //  className="overviewTabs" //  horizontal={true}
					mainClass="xs-patient-card-tab"
					background="white" //  background="white" //  //  headerClassName="xs-horizontal-filled" //  //  scrollButtons={true} //  value={UIStore.patientCardTab} //  onChangeTab={(value)  =>  UIStore.patientCardTab  =  value} //  horizontal={true} //  mainClass="xs-patient-card-tab"
					tabs={[
						{
							label: props.intl.formatMessage({id: "Common.label.drugs"}),
							// labelClassName: "button-anamnesis-form",
							value: "drugs", //  hidden:  UIStore.patientCardTab  !==  "anamnesis"  &&  UIStore.patientCardTab  !==  "record",
							// badge: renderWarningBadge("PatientAnamnesisForm"),
							// warning: StorageEx.exists(getKeyInfo("ANAMNESIS")),
							icon:
								// <Tooltip
								// 	title={
								// 		StorageEx.exists(getKeyInfo("ANAMNESIS")) ? <FormattedMessage id="Common.label.conceptRecord" /> : ""
								// 	}
								// 	placement="top"
								// >
								DrugReportStore.tabCard === "drug" ? (
									<i className="fas   fa-pills fa-2x" />
								) : (
									<i className="fal   fa-pills  fa-2x" />
								),
							// </Tooltip>
							content: <DrugReportList />
						},
						{
							label: props.intl.formatMessage({id: "Patient.form.template.template"}),
							// labelClassName: "button-anamnesis-form",
							value: "medEquip", //  hidden:  UIStore.patientCardTab  !==  "anamnesis"  &&  UIStore.patientCardTab  !==  "record",
							// badge: renderWarningBadge("PatientAnamnesisForm"),
							// warning: StorageEx.exists(getKeyInfo("ANAMNESIS")),
							icon:
								// <Tooltip
								// 	title={
								// 		StorageEx.exists(getKeyInfo("ANAMNESIS")) ? <FormattedMessage id="Common.label.conceptRecord" /> : ""
								// 	}
								// 	placement="top"
								// >
								DrugReportStore.tabCard === "medEquip" ? (
									<i className="fas fa-first-aid fa-2x" />
								) : (
									<i className="fal fa-first-aid fa-2x" />
								),
							// </Tooltip>
							content: <MedEquipReport />
						}
					]}
				/>
			</Grid>
		</Grid>
	)
}

export default injectIntl(observer(drugReportContainer))
