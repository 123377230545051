import React from "react"
import {FormattedMessage} from "react-intl"

export default {
	load() {
		return {
			fields: {
				code: {
					label: <FormattedMessage id="Common.label.code" />,
					type: "string"
				},
				code_ext: {
					label: <FormattedMessage id="Common.label.extCode" />,
					type: "string"
				},
				name_ext: {
					label: <FormattedMessage id="Common.label.name" />,
					type: "string"
				},
				description: {
					label: <FormattedMessage id="Common.label.description" />,
					type: "string"
				}
			}
		}
	}
}
