//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import renderHTML from "react-render-html"
import ConfirmInteracationDialogStore from "../../../stores/ConfirmInteractionDialogStore"

@observer
export default class ConfirmInteracationDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsConfirmInteractionDialog"
				open={ConfirmInteracationDialogStore.isOpen}
				onClose={() => ConfirmInteracationDialogStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-warning">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-exclamation-triangle fa-2x"></i>
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.warning" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					{isEmpty(ConfirmInteracationDialogStore.warning) ? "" : renderHTML(ConfirmInteracationDialogStore.warning)}
				</DialogContent>
				<DialogActions className="xs-footer">
					<div>
						{ConfirmInteracationDialogStore.confirm && (
							<XsButton
								className="xs-success xs-outline"
								text={<FormattedMessage id="Common.label.ok" />}
								onClick={() => ConfirmInteracationDialogStore.close()}
							/>
						)}
						{ConfirmInteracationDialogStore.confirm && (
							<XsButton
								className="xs-danger xs-outline"
								text={<FormattedMessage id="Common.label.cancel" />}
								onClick={() => ConfirmInteracationDialogStore.cancel()}
							/>
						)}
						{!ConfirmInteracationDialogStore.confirm && (
							<XsButton
								className="xs-warning"
								text={<FormattedMessage id="Common.label.ok" />}
								onClick={() => ConfirmInteracationDialogStore.close()}
							/>
						)}
					</div>
				</DialogActions>
			</Dialog>
		)
	}
}
