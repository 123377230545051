import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				allergy: {
					label: <FormattedMessage id="Common.label.description" />,
					rules: "string"
				},
				atcCode: {
					label: <FormattedMessage id="Common.label.drug" />,
					rules: "string"
				},
				allergenCode: {
					label: <FormattedMessage id="Common.label.substance" />,
					rules: "string"
				},
				severity: {
					label: <FormattedMessage id="Patient.form.problemForm.severity" />,
					rules: "required|string"
				},
				symptoms: {
					label: <FormattedMessage id="Patient.form.problemForm.symptoms" />,
					rules: "array",
					options: {
						isMulti: true
					},
					value: []
				}
			}
		}
	}
}
