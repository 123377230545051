"use strict"

import {observable, action} from "mobx"
import UIStore from "./UIStore"
import api from "../actions/api"
import {getSelectedOrgUnitID} from "../../../global/helpers/actions"
// import WarningStore from "../../../global/store/WarningStore"
// import InvoiceFormStore from "../../ambulance/stores/InvoiceFormStore"
// import GlobalStore from "../../../global/store/GlobalStore"

class ExportToXlsDialogStore {
	@observable isOpen = false
	formRef = null

	insurers = []

	dispositionData = [
		{
			secondaryText: "N",
			primaryText: "nová (SK poistenci)",
			name_ext: "N - nová (SK poistenci)",
			_id: "N",
			code: "N",
			checkValue: true
		},
		{
			secondaryText: "E",
			primaryText: "nová (EU poistenci)",
			name_ext: "E - nová (EU poistenci)",
			_id: "E",
			code: "E",
			checkValue: true
		},
		{
			secondaryText: "I",
			primaryText: "nová (Cudzinci a bezdomovci)",
			name_ext: "I - nová (Cudzinci a bezdomovci)",
			_id: "I",
			code: "I",
			checkValue: true
		}
	]

	@action open() {
		this.isOpen = true

		if (isSafe(this.formRef) && this.formRef.has("batchType")) {
			api
				.getDefaultBatchType({org_unit: getSelectedOrgUnitID()})
				.call()
				.then((res) => {
					this.formRef.$("batchType").set("value", res.code_ext)
				})
		}
	}

	@action close() {
		this.isOpen = false
		UIStore.isFormSaving = false
		if (isSafe(this.formRef)) {
			this.formRef.reset()
		}
	}
}

var singleton = new ExportToXlsDialogStore()
export default singleton
