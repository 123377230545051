"use strict"

import {observable, action} from "mobx"
import {getSelectedOrgUnitID} from "../../../global/helpers/actions"
import {pascalCaseToSentance} from "../../../global/helpers/functions"
import api from "../actions/api"

class PatientCheckInsurerStore {
	@observable isOpen = false
	@observable checkInsurerData = undefined

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@action checkInsurance(ZPcode, identifier) {
		api
			.checkInsurance(ZPcode, identifier, getSelectedOrgUnitID())
			.call()
			.then((response) => {
				if (isSafe(response)) {
					this.checkInsurerData = {
						je_neplatic: isSafe(response.je_neplatic) ? (response.je_neplatic ? "Áno" : "Nie") : "-",
						ma_narok_na_odkladnu_zs: isSafe(response.ma_narok_na_odkladnu_zs)
							? response.ma_narok_na_odkladnu_zs
								? "Áno"
								: "Nie"
							: "-",
						poistny_vztah_poistenca: pascalCaseToSentance(response.poistny_vztah_poistenca),
						zaciatok_eu_poistenia: isSafe(response.zaciatok_eu_poistenia) ? response.zaciatok_eu_poistenia : "-"
					}
					this.isOpen = true
				}
			})
	}
}

var singleton = new PatientCheckInsurerStore()
export default singleton
