"use strict"
import api from "../actions/api"
import {observable, action} from "mobx"

import DataStore from "./DataStore"

class CovidResultStore {
	@observable isOpen = false
	@observable covidResult = undefined

	@observable loading = false

	@action open() {
		this.loading = true

		let patient_phone = ""

		const patientContacts = DataStore.patientDTO.get("contacts")

		if (isSafe(patientContacts) && patientContacts.length > 0) {
			const primaryContact = patientContacts.find((x) => x.primary && (isNotEmpty(x.mobile) || isNotEmpty(x.phone)))
			if (isSafe(primaryContact)) {
				const phone = isNotEmpty(primaryContact.mobile) ? primaryContact.mobile : primaryContact.phone
				patient_phone = phone
			} else {
				const contact = patientContacts.find((x) => isNotEmpty(x.mobile) || isNotEmpty(x.phone))
				if (isSafe(contact)) {
					const phone = isNotEmpty(contact.mobile) ? contact.mobile : contact.phone
					patient_phone = phone
				}
			}
		}

		const req = {
			patient_id: DataStore.patientDTO.get("patientID"),
			patient_phone: patient_phone
		}

		api
			.covidResults(req)
			.call()
			.then((res) => {
				if (isNotEmpty(res)) {
					this.covidResult = res
				}
				this.loading = false
			})
			.catch(() => {
				this.loading = false
			})

		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
		this.loading = false
		this.covidResult = undefined
	}
}
var singleton = new CovidResultStore()
export default singleton
