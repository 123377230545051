import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "sickness_abs_temp_template"
				},

				cisloPotvrdenia: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.cisloPotvrdenia" />,
					value: ""
				},
				praceneschopnyKuDnu: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.praceneschopnyKuDnu" />,
					value: moment().endOf("month"),
					type: "date"
				},
				nazovSidloLiecebnehoZariadenia: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.nazovSidloLiecebnehoZariadenia" />,
					value: ""
				},
				chorobaCheckbox: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.chorobaCheckbox" />,
					type: "checkbox",
					value: false
				},
				karantenneOpatrenieCheckbox: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.karantenneOpatrenieCheckbox" />,
					type: "checkbox",
					value: false
				},
				urazCheckbox: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.urazCheckbox" />,
					type: "checkbox",
					value: false
				},
				chorobaZPovolaniaCheckbox: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.chorobaZPovolaniaCheckbox" />,
					type: "checkbox",
					value: false
				},
				pracovnyUrazCheckbox: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.pracovnyUrazCheckbox" />,
					type: "checkbox",
					value: false
				},
				urazZavinenyInouOsobouCheckbox: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.urazZavinenyInouOsobouCheckbox" />,
					type: "checkbox",
					value: false
				},
				zmenaDiagnozyOdDatum: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.zmenaDiagnozyOdDatum" />,
					type: "date"
				},

				datum1Datum: {
					label: <FormattedMessage id="Common.label.date" />,
					type: "date"
				},
				datum2Datum: {
					label: <FormattedMessage id="Common.label.date" />,
					type: "date"
				},

				dennyVymeriavaciZaklad: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.dennyVymeriavaciZaklad" />,
					value: ""
				},
				zaciatokDocasnejPracovnejNeschopnostiDatum: {
					label: (
						<FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.zaciatokDocasnejPracovnejNeschopnostiDatum" />
					),
					type: "date"
				},
				znizenieSumyUrazovehoPriplatkuAnoCheckbox: {
					label: (
						<FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.znizenieSumyUrazovehoPriplatkuAnoCheckbox" />
					),
					type: "checkbox",
					value: false
				},
				znizenieSumyUrazovehoPriplatkuNieCheckbox: {
					label: (
						<FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.znizenieSumyUrazovehoPriplatkuNieCheckbox" />
					),
					type: "checkbox",
					value: false
				},
				poznamka: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.poznamka" />,
					value: ""
				},

				_55PercentOdDo: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate._55PercentOdDo" />,
					value: ""
				},
				_55PercentPocetDniDPN: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate._55PercentPocetDniDPN" />,
					value: ""
				},
				_55PercentSumaUrazovehoPriplatku: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate._55PercentSumaUrazovehoPriplatku" />,
					value: ""
				},

				_25PercentOdDo: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate._25PercentOdDo" />,
					value: ""
				},
				_25PercentPocetDniDPN: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate._25PercentPocetDniDPN" />,
					value: ""
				},
				_25PercentSumaUrazovehoPriplatku: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate._25PercentSumaUrazovehoPriplatku" />,
					value: ""
				},

				spoluSumaUrazovehoPriplatku: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.spoluSumaUrazovehoPriplatku" />,
					value: ""
				},
				znizenaSumaUrazovehoPriplatku: {
					label: <FormattedMessage id="PrintTemplate.SicknessAbsTempTemplate.znizenaSumaUrazovehoPriplatku" />,
					value: ""
				},
				datum3Datum: {
					label: <FormattedMessage id="Common.label.date" />,
					type: "date"
				}
			}
		}
	}
}
