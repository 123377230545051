import {observer} from "mobx-react"
import React from "react"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import YearDropdownOptions from "./YearDropdownOptions"
import {withStyles} from "@material-ui/core/styles"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import {getYear, isMoment} from "./helpers"
import ArrowDropDown from "@material-ui/icons/ArrowDropDown"
import {ClickAwayListener} from "@material-ui/core"

import moment from "moment"

const WrappedYearDropdownOptions = YearDropdownOptions

const styles = () => ({
	transparent: {
		background: "transparent",
		minWidth: "10px",
		padding: "0px",
		margin: "0px",
		color: "#9ca0a6"
	},
	icon: {
		fontSize: "18px",
		width: "0.8em"
	}
})

@observer
class YearDropdown extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			anchorEl: null
		}
	}

	renderSelectOptions = () => {
		const minDate = this.props.minDate
		const maxDate = this.props.maxDate
		const minYear = minDate ? getYear(isMoment(minDate) ? minDate : moment(minDate)) : 1900
		const maxYear = maxDate ? getYear(isMoment(maxDate) ? maxDate : moment(maxDate)) : 2050

		const options = []
		for (let i = maxYear; i >= minYear; i--) {
			options.push(
				<MenuItem key={i} onClick={this.onChange.bind(this, i)}>
					{i}
				</MenuItem>
			)
		}
		return options
	}

	onSelectChange = (e, key, value) => {
		this.onChange(value)
	}

	renderSelectMode = () => (
		<div>
			<Button onClick={this.toggleDropdown} className={classNames(this.props.classes.transparent)}>
				<ArrowDropDown className={classNames(this.props.classes.icon)} />
			</Button>
			<Menu
				id="dropdownButton"
				style={{zIndex: 2001}}
				open={Boolean(this.state.anchorEl)}
				anchorEl={this.state.anchorEl}
				//onClose={this.toggleDropdown}
			>
				<ClickAwayListener onClickAway={() => this.toggleDropdown(null)}>
					<React.Fragment>{this.renderSelectOptions()}</React.Fragment>
				</ClickAwayListener>
			</Menu>
		</div>
	)

	renderReadView = (visible) => (
		<div
			key="read"
			style={{visibility: visible ? "visible" : "hidden"}}
			className="react-datepicker__year-read-view"
			onClick={(event) => this.toggleDropdown(event)}
		>
			<span className="react-datepicker__year-read-view--down-arrow" />
			<span className="react-datepicker__year-read-view--selected-year">{this.props.year}</span>
		</div>
	)

	renderDropdown = () => (
		<WrappedYearDropdownOptions
			key="dropdown"
			//ref="options"
			year={this.props.year}
			onChange={this.onChange}
			onCancel={this.toggleDropdown}
			minDate={this.props.minDate}
			maxDate={this.props.maxDate}
			scrollableYearDropdown={this.props.scrollableYearDropdown}
			yearDropdownItemNumber={this.props.yearDropdownItemNumber}
		/>
	)

	renderScrollMode = () => {
		let result = [this.renderReadView(!this.dropdownVisible)]
		if (this.dropdownVisible) {
			result.unshift(this.renderDropdown())
		}
		return result
	}

	onChange = (year) => {
		this.toggleDropdown()
		if (year === this.props.year) {
			return
		}
		this.props.onChange(year)
	}

	toggleDropdown = (event) => {
		this.setState({
			anchorEl: event ? event.currentTarget : null
		})
	}

	handleYearChange = (date, event) => {
		this.onSelect(date, event)
		this.setOpen()
	}

	onSelect = (date, event) => {
		if (this.props.onSelect) {
			this.props.onSelect(date, event)
		}
	}

	setOpen = () => {
		if (this.props.setOpen) {
			this.props.setOpen(true)
		}
	}

	render() {
		let renderedDropdown
		switch (this.props.dropdownMode) {
			case "scroll":
				//renderedDropdown = this.renderScrollMode();
				break
			case "select":
				renderedDropdown = this.renderSelectMode()
				break
		}

		return (
			<div
				className={`react-datepicker__year-dropdown-container react-datepicker__year-dropdown-container--${this.props.dropdownMode}`}
			>
				{renderedDropdown}
			</div>
		)
	}
}

export default withStyles(styles)(YearDropdown)
