"use strict"

import {action} from "mobx"
import moment from "moment"

import {getUserDoctorInfo, getUserCompanyInfo, printReport, getSelectedOrgUnit} from "../../../global/helpers/actions"
import {ixsIsEmpty, ixsIsUndefined} from "../../../global/helpers/functions"
import settings from "../../../global/config/settings"

import GlobalStore from "../../../global/store/GlobalStore"
import DataStore from "../stores/DataStore"
import UIStore from "../stores/UIStore"

import api from "../actions/api"

class PrintMedicationRecordStore {
	@action async print(medicationId, recordId) {
		UIStore.isFormSaving = true
		await GlobalStore.refreshCodeListsSync(["sicknessAbsenceTypes"])

		api
			.loadPrescription(medicationId)
			.call()
			.then((med) => {
				const pacInfo = DataStore.patientDTO.get("patient")
				const companyInfo = getUserCompanyInfo()
				const doctorInfo = getUserDoctorInfo()
				const orgUnit = getSelectedOrgUnit()

				const pacAddress = pacInfo.validInfo.address
				let printAddress = ""

				if (pacInfo.validInfo && pacAddress) {
					printAddress += `${pacAddress.city ? `${pacAddress.city}, ` : ""}`
					printAddress += `${
						pacAddress.street
							? `${pacAddress.street}${pacAddress.house_number ? ` ${pacAddress.house_number}` : ""},`
							: ""
					}`
					printAddress += ` ${pacAddress.zip}`
				}

				let printValues = {
					name: `${pacInfo.full_name}`,
					identifier: pacInfo.identifier,
					address: pacInfo.validInfo && pacAddress ? printAddress : "",
					providertitle: "",
					doctortitle: "",
					isSputnik: "none",
					pocetDavokText: "Podanie očkovacej látky je v dvoch dávkach."
				}

				if (isSafe(med) && isSafe(med.product_code)) {
					if (med.product_code == "MD672") {
						printValues.isSputnik = "initial"
					}

					if (med.product_code == "6807D" || med.product_code == "7422D") {
						printValues.pocetDavokText = "Podanie očkovacej látky je v jednej dávke."
					}
				}

				printValues["isAdolescent"] = isNotEmpty(pacInfo.age) && +pacInfo.age < 18 ? "initial" : "none"

				const pacContact = pacInfo.validInfo.contact

				if (isSafe(pacInfo.validInfo.contact)) {
					printValues["patient_mobile"] = pacContact.mobile
				}

				if (doctorInfo) {
					printValues.doctortitle = doctorInfo.name
					printValues["doctor_name"] = doctorInfo.name
					printValues.doctortitle += `, ${doctorInfo.code}`
					printValues["doctor_code"] = doctorInfo.code
				}

				if (companyInfo) {
					printValues.providertitle = companyInfo.name
					printValues["company_name"] = companyInfo.name
				}

				//OrgUnit
				if (orgUnit) {
					const primaryAddress = doctorInfo.address
					const businessContact = doctorInfo.contact
					const providerCode = orgUnit.identifiers.find((i) => i.type === "PROVIDERCODE")
					printValues.providertitle += `, ${orgUnit.name_ext}`
					printValues["orgunit_name"] = orgUnit.name_ext
					const pAddress =
						isSafe(primaryAddress) && isNotEmpty(primaryAddress.full_address) ? `${primaryAddress.full_address}` : ""
					printValues.providertitle += pAddress
					printValues["p_address"] = pAddress

					const caAddress = orgUnit.addresses.find((x) => x.type._id == "CA")
					printValues["orgunit_city"] = "......................................."

					if (caAddress) {
						printValues["orgunit_city"] = caAddress.city
					} else {
						const asAddress = orgUnit.addresses.find((x) => x.type._id == "OA")

						if (asAddress) {
							printValues["orgunit_city"] = asAddress.city
						}
					}

					if (isSafe(orgUnit.addresses) && orgUnit.addresses.length) {
						const contactAddress = orgUnit.addresses.find((x) => x.type._id == "CA")

						if (isSafe(contactAddress)) {
							printValues["contactAddress"] = `<p class="medication">${
								isSafe(contactAddress.street) ? contactAddress.street : ""
							} ${isSafe(contactAddress.house_number) ? contactAddress.house_number : ""}</p><p class="medication">${
								isSafe(contactAddress.zip) ? contactAddress.zip : ""
							} ${isSafe(contactAddress.city) ? contactAddress.city : ""}</p>`
						}
					}

					if (businessContact) {
						const bussinessEmail =
							isSafe(businessContact) && isNotEmpty(businessContact.email) ? `<b>E:</b> ${businessContact.email}` : ""
						// printValues.providertitle += bussinessContact
						printValues["bussiness_email"] = bussinessEmail
						printValues["web_page"] = isSafe(businessContact.web_page) ? `<b>W:</b> ${businessContact.web_page}` : ""
						printValues["bussiness_mobile"] =
							isSafe(businessContact) && isNotEmpty(businessContact.mobile) ? `<b>T:</b> ${businessContact.mobile}` : ""
						// const mobile = isSafe(businessContact) && isNotEmpty(businessContact.mobile) ? `, ${businessContact.mobile}` : ""
						// printValues.providertitle += isNotEmpty(mobile) ? mobile : isSafe(businessContact) && isNotEmpty(businessContact.phone) ? `, ${businessContact.phone}` : ""
					}

					const codeP = isSafe(providerCode) && isNotEmpty(providerCode.value) ? providerCode.value : ""
					printValues.doctortitle += `, ${codeP}`
					printValues["code_p"] = codeP
				}

				//Insurer
				if (pacInfo.validInfo && pacInfo.validInfo.insurance) {
					printValues[
						"insurer"
					] = `${pacInfo.validInfo.insurance.insurer.code_ext} - ${pacInfo.validInfo.insurance.insurer.name_ext}`
				}

				printValues["diagnosis"] = `${med.diagnosis_code_ext} ${med.diagnosis_name_ext}`

				printValues["productcode"] = isSafe(med.product_code) ? med.product_code : ""
				printValues["productname"] = isSafe(med.product_name) ? med.product_name : ""
				printValues["registrator_name_ext"] = isSafe(med.registrator_name_ext) ? med.registrator_name_ext : ""
				printValues["birth_date"] = isNotEmpty(pacInfo.birth_date)
					? `, ${moment(pacInfo.birth_date, "YYYY-MM-DD").format("DD.MM.YYYY")}`
					: ""

				printValues["batchnumber"] = isSafe(med.batch_number) ? med.batch_number : ""
				printValues["administered_at"] = isNotEmpty(med.administered_at)
					? moment(med.administered_at).format("DD.MM.YYYY HH:mm")
					: ""

				printValues["ordinance_order"] = isSafe(med.ordinance_order)
					? `${med.ordinance_order}/${med.repeats_number}`
					: ""

				printValues["show_SMS_sentence"] = med.ordinance_order == med.repeats_number ? "none" : "initial"

				if (isNotEmpty(med.dose_amount) && isNotEmpty(med.route_name)) {
					const unit = isSafe(med.dose_unit) ? med.dose_unit : ""
					const amount = `${med.dose_amount}`.includes(".") ? `${med.dose_amount}`.replace(".", ",") : med.dose_amount
					printValues["amount_and_route_vaccine"] = `${amount} ${unit} - ${med.route_name}`
				}

				printValues["qr_code_text"] = `Meno (Name): ${pacInfo.full_name} \n`
				printValues["qr_code_text"] += `Dátum narodenia (Date of Birth): ${
					isNotEmpty(pacInfo.birth_date) ? moment(pacInfo.birth_date, "YYYY-MM-DD").format("DD.MM.YYYY") : ""
				} \n`
				printValues["qr_code_text"] += `\n`
				printValues["qr_code_text"] += `Druh očkovania (Vaccine type): ${med.vaccination_malady_name_ext} \n`
				printValues["qr_code_text"] += `\n`
				printValues["qr_code_text"] += `Dávka (Dose): ${med.ordinance_order} / ${med.repeats_number}  \n`
				printValues["qr_code_text"] += `ŠUKL kód (Vaccine code): ${med.product_code} \n`
				printValues["qr_code_text"] += `Názov (Vaccine name): ${med.product_name} \n`
				printValues["qr_code_text"] += `Šarža (Batch no.): ${med.batch_number} \n`
				printValues["qr_code_text"] += `Dátum (Date): ${
					isNotEmpty(med.administered_at) ? moment(med.administered_at).format("DD.MM.YYYY HH:mm") : ""
				} \n`
				printValues["qr_code_text"] += `Štát (Country): Slovakia`

				api
					.loadRecord(recordId, "L4")
					.call()
					.then(async (recordData) => {
						const pacEvents = await api
							.loadClientAgenda(
								DataStore.patientDTO.get("patientID"),
								moment()
									.endOf("day")
									.utc(),
								moment()
									.add(12, "months")
									.utc()
							)
							.call()

						let nextVisit = ""

						if (isSafe(pacEvents) && pacEvents.length) {
							nextVisit = "<br/><b>Najbližšia návšteva:</b>"

							pacEvents.forEach((event) => {
								nextVisit += `<div style="font-size: 12px; font-weight: normal;">${moment(event.interval.from).format(
									"DD.MM.YYYY, HH:mm"
								)} ${event.service_names}</div>`
							})
						}

						const companyInfo = getUserCompanyInfo()
						const orgUnit = getSelectedOrgUnit()
						let skipRecord = []

						const pacAddress = pacInfo.validInfo.address
						let printAddress = ""
						if (pacInfo.validInfo && pacAddress) {
							printAddress += `${pacAddress.city ? `${pacAddress.city}, ` : ""}`
							printAddress += `${
								pacAddress.street
									? `${pacAddress.street}${pacAddress.house_number ? ` ${pacAddress.house_number}` : ""},`
									: ""
							}`
							printAddress += ` ${pacAddress.zip}`
						}

						printValues["name"] = `${pacInfo.full_name}`
						printValues["identifier"] = pacInfo.identifier
						printValues["address"] = pacInfo.validInfo && pacAddress ? printAddress : ""
						// printValues["doctorcode"] = doctorInfo ? doctorInfo.code : ""
						// printValues["doctorname"] = doctorInfo ? doctorInfo.name : ""
						printValues["dgs"] = ""
						printValues["dgs_plain"] = "<b>Diagnóza =</b> "
						printValues["cvs"] = ""
						printValues["nextVisit"] = nextVisit
						printValues["anamnesis"] = ""
						printValues["medication"] = ""
						printValues["medication_plain"] = ""
						printValues["prescription"] = ""
						printValues["prescription_plain"] = ""
						printValues["summaries_plain"] = ""
						printValues["request"] = ""
						printValues["request_plain"] = ""
						printValues["cvshidden"] = "hidden"
						printValues["score"] = ""

						let onlyDgnDesc = undefined

						//OrgUnit
						if (orgUnit) {
							if (isSafe(orgUnit.params)) {
								onlyDgnDesc = orgUnit.params.find((x) => x.param_type == "EHR_PRINT_ONLY_DGN_DESC" && x.value == 1)
							}
						}

						//tlaci sa nase interne tlacivo
						printValues["print_internal"] = "initial"
						printValues["print_mzsr"] = "none"
						if (isSafe(orgUnit.params) && orgUnit.params.length) {
							const ext = orgUnit.params.find((x) => x.param_type == "EHR_VACC_PRINT_TYPE")
							logger("ext", ext)
							if (isSafe(ext)) {
								if (ext.value == "both") {
									printValues["print_internal"] = "initial"
									printValues["print_mzsr"] = "initial"
								}
								if (ext.value == "ext") {
									printValues["print_internal"] = "none"
									printValues["print_mzsr"] = "initial"
								}
							}
						}

						//Insurer
						if (pacInfo.validInfo && pacInfo.validInfo.insurance) {
							printValues[
								"insurer"
							] = `${pacInfo.validInfo.insurance.insurer.code_ext} - ${pacInfo.validInfo.insurance.insurer.name_ext}`
						}

						//Date and Time
						const dateAndTime = recordData.recorded_events.find((re) => re.type === "WRT")
						if (dateAndTime) {
							printValues.dateTime = moment(dateAndTime.recorded_at).format("DD.MM.YYYY, HH:mm")
							printValues.wrtDate = moment(dateAndTime.recorded_at).format("DD.MM.YYYY")
							printValues.wrtTime = moment(dateAndTime.recorded_at).format("HH:mm")
						}

						//Logo
						if (isNotEmpty(companyInfo.company_logo_file_name)) {
							printValues["logo_path"] = `${settings.API_IMAGE_URL}/${companyInfo.company_logo_file_name}`
						}

						//Dg
						//Primary
						const primaryDg = recordData.general_items.find(
							(dg) =>
								dg._type === "EHR.Data.Record.Diagnosis" && dg.diagnosis_type && dg.diagnosis_type._id === "PRIMARY"
						)

						if (primaryDg) {
							if (isNotEmpty(primaryDg.description)) {
								if (isSafe(onlyDgnDesc)) {
									printValues.dgs += `<p class="record">${primaryDg.item.code_ext} - ${escape(
										primaryDg.description
									)}</p>`
								} else {
									printValues.dgs += `<p class="record">${primaryDg.item.code_ext} - ${
										primaryDg.item.name_ext
									} (${escape(primaryDg.description)})</p>`
								}
							} else {
								printValues.dgs += `<p class="record">${primaryDg.item.code_ext} - ${primaryDg.item.name_ext}</p>`
							}

							printValues.dgs_plain += `${primaryDg.item.code_ext} - ${primaryDg.item.name_ext}`
						}

						//Secondary and others
						recordData.general_items
							.filter(
								(dg) =>
									dg._type === "EHR.Data.Record.Diagnosis" && dg.diagnosis_type && dg.diagnosis_type._id !== "PRIMARY"
							)
							.sort((a, b) => a.item_order - b.item_order)
							.map((dg) => {
								if (isNotEmpty(dg.description)) {
									if (isSafe(onlyDgnDesc)) {
										printValues.dgs += `<p class="record">${dg.item.code_ext} - ${escape(dg.description)}</p>`
									} else {
										printValues.dgs += `<p class="record">${dg.item.code_ext} - ${dg.item.name_ext} (${escape(
											dg.description
										)})</p>`
									}
								} else {
									printValues.dgs += `<p class="record">${dg.item.code_ext} - ${dg.item.name_ext}</p>`
								}

								printValues.dgs_plain += `, ${dg.item.code_ext} - ${dg.item.name_ext}`
							})

						//Klinické hodnoty
						if (recordData.clinical_values && recordData.clinical_values.length > 0) {
							printValues.cvshidden = ""
							recordData.clinical_values
								.sort((a, b) => a.item_order - b.item_order)
								.map((cv, idx) => {
									const unit = cv.unit ? cv.unit.code_ext : ""
									const notes = cv._notes && cv._notes.length > 0 ? cv._notes.map((n) => n.value).join(", ") : ""
									const measured_at = cv.measured_at ? `(${moment(measured_at).format("DD.MM.YYYY")})` : ""
									if (idx !== 0) {
										printValues.cvs += ",   "
									}
									printValues.cvs += `${cv.measure.name_ext} - ${
										typeof cv.value === "number" ? cv.value.toString().replace(".", ",") : cv.value.replace(".", ",")
									} ${unit} ${notes} ${measured_at}`
								})
						}

						const ehrs = DataStore.patientDTO.get("patientEHR")
						// Pridružené anamnézy, preskripcia, PN začína alebo končí príp. žiadanka v rámci rovnakého dňa
						if (ehrs && ehrs.rows) {
							//Loadovanie anamnezy z API
							const anamnesisString = DataStore.patientDTO.get("anamnesis")
							if (isNotEmpty(anamnesisString)) {
								const anam = JSON.parse(anamnesisString)
								if (anam.data) {
									anam.data.map((sum) => {
										if (sum.content) {
											printValues[
												"anamnesis"
											] += `<span class="twelve"><b>${sum.type.name_ext}:</b><p class="record">${sum.content}</p></span><br/>`
										}
									})
								}
							}

							//Soc. poist. - všetky ak majú vyplnenú platnosť OD
							const pns = ehrs.rows.filter(
								(r) =>
									r.sickness_abs.validity &&
									r.sickness_abs.sub_type &&
									r.sickness_abs.validity.from &&
									((moment(recordData.created_at).isAfter(moment(r.sickness_abs.validity.from), "day") &&
										r.sickness_abs.validity.to === null) ||
										moment(recordData.created_at).isBetween(
											r.sickness_abs.validity.from,
											r.sickness_abs.validity.to,
											"day",
											"[]"
										))
							)

							if (pns && pns.length > 0) {
								printValues["sicknessabs"] = ""
								printValues["sicknessabs_plain"] = ""
								pns.map((pn) => {
									const subtype = pn.sickness_abs.sub_type
										? GlobalStore.CL["sicknessAbsenceTypes"].find((sat) => sat._id === pn.sickness_abs.sub_type)
										: null
									if (subtype) {
										const dateFrom = pn.sickness_abs.validity.from
											? moment(pn.sickness_abs.validity.from, settings.DB_DATETIME_FORMAT).format("DD.MM.YYYY")
											: ""
										const dateTo = pn.sickness_abs.validity.to
											? moment(pn.sickness_abs.validity.to, settings.DB_DATETIME_FORMAT).format("DD.MM.YYYY")
											: ""
										printValues["sicknessabs"] += `<br/><p class="record"><b>${subtype.name_ext}</b> od ${dateFrom} ${
											dateTo ? `do ${dateTo}` : ""
										} ${pn.sickness_abs.number}</p>`

										printValues["sicknessabs_plain"] += ` <b>${subtype.code_ext}</b> od ${dateFrom} ${
											dateTo ? `do ${dateTo}` : ""
										} ${pn.sickness_abs.number}`
									}
								})
							}

							//Žiadanka - Všetky ak boli vytvorené v rovnaký deň ako dekurz
							const reqs = ehrs.rows.filter(
								(r) =>
									(r.type_code.toUpperCase() === "OV" || r.type_code.toUpperCase() === "ZV") &&
									moment(r.created_at).isSame(moment(recordData.created_at), "day")
							)

							if (reqs && reqs.length > 0) {
								printValues.request = ""
								reqs.map((req) => {
									skipRecord.push(req.record_id)

									if (req.request) {
										let pactivity = ""
										if (req.request.professional_activity_name_ext) {
											pactivity = `, ${req.request.professional_activity_name_ext}`
										}
										printValues.request += `<br/><p class="record"><b>${req.type_name}:</b></p>
            <p class="record">Odbornosť: ${req.request.receiving_expertise_code_ext} - ${req.request.receiving_expertise_name_ext}${pactivity}</p>`

										printValues.request_plain += `<b>${req.type_name}:</b> Odbornosť: ${req.request.receiving_expertise_code_ext} - ${req.request.receiving_expertise_name_ext}${pactivity} `
									}
								})
							}

							//Prescripcia - všetká finálna v rovnaký deň ako dekurz
							const rxs = ehrs.rows.filter(
								(r) =>
									r.type_code.toUpperCase() === "RX" &&
									r.version === "final" &&
									moment(r.created_at).isSame(moment(recordData.created_at), "day")
							)
							if (rxs && rxs.length > 0) {
								printValues["prescription"] += `<p class="record"><b>Preskripcia:</b></p>`
								printValues["prescription_plain"] += ` <b>Preskripcia: </b>`
								rxs.map((r) => {
									// const prescriptionName = htmlToPlain(r.name)
									const prescriptionName = htmlToPlain(r.name_for_print)
									printValues["prescription"] += `<p class="record">${prescriptionName}</p>`
									printValues["prescription_plain"] += `${prescriptionName} `
								})
							}

							//Medikácia - v rovnaký deň ako dekurz
							const meds = ehrs.rows.filter(
								(r) =>
									r.type_code.toUpperCase() === "POD" &&
									moment(r.created_at).isSame(moment(recordData.created_at), "day")
							)
							if (meds && meds.length > 0) {
								printValues["medication"] += `<br/><b>Medikácia / očkovanie:</b>`
								printValues["medication_plain"] += ` <b>Medikácia / očkovanie: </b>`
								meds.map((med) => {
									const m = med.medication
									let expdate = ""
									if (m.expiration_date) {
										expdate = moment(m.expiration_date).format("M/Y")
									}

									if (isNotEmpty(m.product_printable_name)) {
										const locationName = isNotEmpty(m.location_name_ext) ? ` (${m.location_name_ext})` : ""
										const medicationText = `
                            ${m.product_printable_name}${isNotEmpty(m.batch_number) ? ", " + m.batch_number : ""}${
											isNotEmpty(expdate) ? ", Exp: " + expdate : ""
										}${locationName}${isNotEmpty(m.ordinance_order) ? ", Poradie dávky: " + m.ordinance_order : ""}${
											isNotEmpty(m.dose_amount) && isNotEmpty(m.route_name)
												? ", Množstvo a spôsob podania vakcíny: " +
												  m.dose_amount +
												  " " +
												  m.dose_unit +
												  " - " +
												  m.route_name
												: ""
										}`

										printValues["medication"] += `<br/>${medicationText}`
										printValues["medication_plain"] += `${medicationText} `
									}
								})
							}
						}

						//Score
						const scores = ehrs.rows.filter(
							(r) => r.type_code.toUpperCase() === "SCR" && moment(r.crt_at).isSame(moment(recordData.crt_at), "day")
						)

						if (scores && scores.length > 0) {
							printValues.score = `<br/><b>Skóre:</b>`
							scores.map((scr) => {
								printValues.score += `<br/>${scr.name}`
							})
						}

						//Odporúčajúci lekár
						const recomDoctorSR = recordData.specific_rels.find((sr) => sr.type === "sndphy")
						const recomDoctor = recomDoctorSR
							? `<br/><b>Odosielajúci lekár:</b> ${recomDoctorSR.specific_rel.entity.full_name}`
							: ""

						//Zastupovaný lekár
						const subDoctorSR = recordData.specific_rels.find((sr) => sr.type === "subphy")
						const subDoctor = subDoctorSR
							? `<br/><b>Zastupovaný lekár:</b> ${subDoctorSR.specific_rel.entity.full_name}`
							: ""

						//Naviazané výkony v riadku
						let deliveriesText = ""
						let dcnt = 0
						const deliveries = recordData.general_items.filter((gi) => gi._type === "EHR.Data.Record.SK.Delivery")
						deliveries.length > 0 &&
							deliveries.map((d) => {
								if (d.item) {
									dcnt++
									deliveriesText += `${dcnt === 1 ? "" : ", "}${d.item.code_ext}`
								}
							})

						//Summaries
						const sumAMB = recordData.summaries.find((sum) => {
							return sum.type._id === "SA"
						})
						const sumRecomm = recordData.summaries.find((sum) => {
							return sum.type._id === "Recomm"
						})
						const sumOF = recordData.summaries.find((sum) => {
							return sum.type._id === "OF"
						})
						const sumDC = recordData.summaries.find((sum) => {
							return sum.type._id === "DC"
						})
						const sumIEXAM = recordData.summaries.find((sum) => {
							return sum.type._id === "IEXAM"
						})

						const recordName = recordData.summaries.find((sum) => {
							return sum.type._id === "SEARCHNAME"
						})

						const questions = recordData.summaries.find((sum) => sum.type._id === "JSONHEADER")

						if (isSafe(questions) && isNotEmpty(questions.content)) {
							let contentJSON = JSON.parse(base64DecodeUnicode(questions.content))

							//kvoli tomu ze niekedy otazka 9 neexistovala, a ked sa doplnila k starym zaznamom sa plnily obidva moznosti
							printValues["question_9_yes"] = "none"
							printValues["question_9_no"] = "none"

							Object.keys(contentJSON).forEach((key) => {
								printValues[key] = contentJSON[key] ? "initial" : "none"
							})
						}

						const sumAMBText = sumAMB && sumAMB.content && !ixsIsEmpty(sumAMB.content) ? `${sumAMB.content}` : null
						const sumOFText = sumOF && sumOF.content && !ixsIsEmpty(sumOF.content) ? `${sumOF.content}` : null
						const sumDCText = sumDC && sumDC.content && !ixsIsEmpty(sumDC.content) ? `${sumDC.content}` : null
						const sumIEXAMText =
							sumIEXAM && sumIEXAM.content && !ixsIsEmpty(sumIEXAM.content) ? `${sumIEXAM.content}` : null
						const sumRecommText =
							sumRecomm && sumRecomm.content && !ixsIsEmpty(sumRecomm.content) ? `${sumRecomm.content}` : null

						printValues["recordName"] =
							recordName && recordName.content && !isEmpty(recordName.content) ? `${recordName.content}` : null

						printValues["summaries_plain"] += sumAMBText
							? `<b>Subjektívne ťažkosti:</b> ${htmlToPlain(sumAMBText)} `
							: ""
						printValues["summaries_plain"] += sumOFText ? `<b>Objektívny nález:</b> ${htmlToPlain(sumOFText)} ` : ""
						printValues["summaries_plain"] += sumIEXAMText
							? `<b>Zobrazovacie vyšetrenie:</b> ${htmlToPlain(sumIEXAMText)} `
							: ""
						printValues["summaries_plain"] += sumRecommText ? `<b>Odporúčanie:</b> ${htmlToPlain(sumRecommText)} ` : ""
						printValues["summaries_plain"] += sumDCText ? `<b>Diagnostický záver:</b> ${htmlToPlain(sumDCText)} ` : ""

						printValues["summaryAMB"] = sumOFText
							? `<b>Objektívny nález:</b><p class="record">${unescapeString(sumOFText)}</p>`
							: ""
						printValues["sumRecomm"] = sumRecommText
							? `<b>Odporúčanie:</b><p class="record">${unescapeString(sumRecommText)}</p>`
							: ""
						printValues["sumOF"] = sumAMBText
							? `<b>Subjektívne ťažkosti:</b><p class="record">${unescapeString(sumAMBText)}</p>`
							: ""
						printValues["sumDC"] = sumDCText
							? `<b>Diagnostický záver:</b><p class="record">${unescapeString(sumDCText)}</p>`
							: ""
						printValues["sumIEXAM"] = sumIEXAMText
							? `<b>Zobrazovacie vyšetrenie:</b><p class="record">${unescapeString(sumIEXAMText)}</p>`
							: ""
						printValues["doctorrecom"] = recomDoctor
						printValues["doctorsub"] = subDoctor
						printValues["deliveries"] = !ixsIsEmpty(deliveriesText) ? `<br/><b>Výkony:</b> ${deliveriesText}` : ""

						printValues["priority"] = recordData.priority
							? `<b>Urgentnosť záznamu:</b> ${recordData.priority.name_ext}`
							: ""

						//Escape stringov
						// printValues["dgs"] = isNotEmpty(printValues["dgs"]) ? escape(printValues["dgs"]) : printValues["dgs"]
						printValues["recordName"] = isNotEmpty(printValues["recordName"])
							? escape(printValues["recordName"])
							: printValues["recordName"]
						logger("printValues", printValues)
						//Naviazané EHR na dekurz
						if (recordData.enslaved_records) {
							//Žiadanky a PN
							const slaveData = recordData.enslaved_records.filter(
								(er) =>
									er.active &&
									er.slave.active &&
									!skipRecord.includes(er.slave._id) &&
									(er.slave._type === "EHR.Data.Specialist.Request" ||
										er.slave._type === "EHR.Data.Radiology.Request" ||
										er.slave._type === "EHR.Data.SicknessAbsence")
							)
							if (slaveData && slaveData.length > 0) {
								let sItemCnt = 0
								slaveData.map((s) => {
									//PN
									if (s.slave._type === "EHR.Data.SicknessAbsence" && ixsIsUndefined(printValues.sicknessabs)) {
										const dateFrom =
											s.slave && s.slave.validity && s.slave.validity.from
												? moment(s.slave.validity.from, settings.DB_DATETIME_FORMAT).format("DD.MM.YYYY")
												: ""
										const dateTo =
											s.slave && s.slave.validity && s.slave.validity.to
												? moment(s.slave.validity.to, settings.DB_DATETIME_FORMAT).format("DD.MM.YYYY")
												: ""
										// const subtype = s.slave && s.slave.sub_type ? `${s.slave.sub_type.name_ext} ` : ""
										let subtypeName = ""
										let subtypeCodeExt = ""
										if (s.slave && s.slave.sub_type && GlobalStore.CL["sicknessAbsenceTypes"]) {
											const subtype = GlobalStore.CL["sicknessAbsenceTypes"].find((sat) => sat._id === s.slave.sub_type)
											if (subtype) {
												subtypeName = subtype.name_ext
												subtypeCodeExt = subtype.code_ext
											}
										}
										printValues["sicknessabs"] = `<br/><p class="record"><b>${subtypeName}</b> od ${dateFrom} ${
											dateTo ? `do ${dateTo}` : ""
										} ${s.slave.number}</p>`

										printValues["sicknessabs_plain"] = `<b>${subtypeCodeExt}</b> od ${dateFrom} ${
											dateTo ? `do ${dateTo}` : ""
										} ${s.slave.number}`
									}

									if (
										s.slave._type === "EHR.Data.Specialist.Request" ||
										s.slave._type === "EHR.Data.Radiology.Request"
									) {
										printValues["request"] += "<br/><b>Žiadanka:</b>"
									}

									api
										.loadRecord(s.slave._id, "L4")
										.call()
										.then((requestData) => {
											sItemCnt++
											const expertise = requestData.general_items.find(
												(gi) => gi._type === "EHR.Data.Record.Expertise" && gi.type === "recvouexp"
											)

											const pactivity = requestData.general_items.find(
												(gi) => gi._type === "EHR.Data.Record.ProfessionalActivity"
											)
											if (expertise) {
												printValues[
													"request"
												] += `<br/>Odbornosť: ${expertise.item.code_ext} - ${expertise.item.name_ext}`
											}

											if (pactivity) {
												printValues["request"] += `, ${pactivity.item.name_ext}`
											}
											if (sItemCnt === slaveData.length) {
												UIStore.isFormSaving = false
												printReport("medication_with_record", printValues)
											}
										})
								})
							} else {
								UIStore.isFormSaving = false
								printReport("medication_with_record", printValues)
							}
						} else {
							UIStore.isFormSaving = false
							printReport("medication_with_record", printValues)
						}
					})
			})
	}
}

var singleton = new PrintMedicationRecordStore()
export default singleton
