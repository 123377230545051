import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import {Grid, Paper} from "@material-ui/core"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import QueueContainerStore from "../../../stores/QueueContainerStore"
import EditQueueDialog from "./EditQueueDialog"

@injectIntl
@observer
export default class QueueContainer extends React.Component {
	constructor(props) {
		super(props)
		QueueContainerStore.loadListData()
		QueueContainerStore.loadEmployees()
	}

	onHandleRowClick = () => {}

	getQueueStatus = (capacity, waiting) => {
		if (capacity > waiting) {
			return <i className="fas fa-circle greenSuccess" />
		} else if (capacity == waiting) {
			return <i className="fas fa-circle amberDark" />
		} else {
			return <i className="fas fa-circle redDanger" />
		}
	}

	render() {
		return (
			<Grid container direction="column" id="notifyContainer">
				<Grid item>
					<Paper square={true} elevation={1} className="pr-8 pl-8 header">
						<div className="title">Fronty</div>
					</Paper>
				</Grid>
				<Grid item className="pl-8 pr-8 pt-4">
					<Grid container justify="space-between">
						<Grid item xs={8} container alignItems="center" spacing={8}></Grid>
						<Grid item xs={4} container alignItems="center" justify="flex-end" spacing={8}>
							<Grid item>
								<XsIconButton
									rect
									tooltip="Refresh"
									className="xs-default xs-outline"
									onClick={() => {
										QueueContainerStore.loadListData()
									}}
									icon={<i className="fal fa-sync fa-lg" />}
								/>
							</Grid>
							<Grid item>
								<XsButton
									className="xs-primary"
									onClick={() => {
										QueueContainerStore.openEditDialog(null)
									}}
									text={"Nová fronta"}
									icon={<i className="fal fa-plus fa-lg" />}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item className="pl-8 pr-8">
					<XsTable
						config={{
							columnDefs: {
								state: {
									type: "action",
									design: {
										width: "50px",
										body: {
											renderer: (props) => {
												return this.getQueueStatus(props.value.capacity, props.value.waiting)
											}
										}
									}
								},
								name: {
									title: <FormattedMessage id="Common.label.name" />,
									type: "string",
									design: {
										width: "200px"
									}
								},
								managment: {
									title: "Manažér fronty",
									type: "string",
									design: {
										width: "200px"
									}
								},
								capacity: {
									title: "Kapacita",
									type: "string",
									design: {
										width: "200px"
									}
								},
								count: {
									title: "Počet objektov vo fronte",
									type: "string",
									design: {
										width: "200px"
									}
								},
								action: {
									title: "",
									type: "action",
									design: {
										width: "10%",
										body: {
											renderer: (props) => {
												return (
													<React.Fragment>
														<XsIconButton
															icon={<i className="fal fa-edit fa-lg xs-greyDefault" />}
															tooltip={<FormattedMessage id="Common.label.edit" />}
															onClick={() => {
																QueueContainerStore.openEditDialog(props.value)
															}}
														/>
													</React.Fragment>
												)
											}
										}
									}
								}
							},
							options: {
								showCursor: true,
								mapper: (dataRow) => {
									return {
										state: {waiting: dataRow.waiting, capacity: dataRow.capacity},
										name: dataRow.name,
										managment: isSafe(dataRow.primary_manager_name) ? dataRow.primary_manager_name : "",
										count: dataRow.waiting,
										action: dataRow._id,
										capacity: dataRow.capacity
									}
								}
								// onRowClick: (dataRow) => {
								// 	this.onHandleRowClick(dataRow)
								// }
							},
							dataSource: QueueContainerStore.listData
						}}
					/>
				</Grid>
				{QueueContainerStore.editDialogOpen && <EditQueueDialog />}
			</Grid>
		)
	}
}
