"use strict"

import {observable, action} from "mobx"
import api from "../../attendance/actions/api"
import {getFilters} from "../../../global/helpers/api"
import UIStore from "../stores/UIStore"

class UsersStore {
	@observable rolesList = []
	@observable resourcesList = []
	@observable resourcesForRole = []
	@observable showRoleDetail = false
	@observable showResourceForRoleForm = false
	@observable userRoles = []
	@observable changedResourcesFilters = false
	@observable changedRolesFilters = false
	@observable changedResourcesForRoleFilters = false
	currResourcesFilters = {}
	currRolesFilters = {}
	currResourcesForRoleFilters = {}

	@action loadResources(form) {
		UIStore.isFormSaving = true
		let formVals = isSafe(form) ? form.values() : {}
		const filter = getFilters([`name=${isNotEmpty(formVals.name) ? formVals.name : ""}`])

		this.currResourcesFilters = formVals
		this.currResourcesForRoleFilters = formVals
		this.changedResourcesFilters = false
		this.changedResourcesForRoleFilters = false

		api
			.loadResources(filter)
			.call()
			.then((response) => {
				this.resourcesList = response.rows
				UIStore.isFormSaving = false
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}

	@action isChangedResourcesFilter(formVals) {
		this.changedResourcesFilters = this.currResourcesFilters.name != formVals.name
	}

	@action isChangedRolesFilter(formVals) {
		this.changedRolesFilters = this.currRolesFilters.name != formVals.name
	}

	@action isChangedResourcesForRolesFilter(formVals) {
		this.changedResourcesForRoleFilters = this.currResourcesForRoleFilters.name != formVals.name
	}

	@action loadRoles(form) {
		UIStore.isFormSaving = true
		let formVals = isSafe(form) ? form.values() : {}

		this.currRolesFilters = formVals
		this.changedRolesFilters = false

		const filter = getFilters([`name=${isNotEmpty(formVals.name) ? formVals.name : ""}`])
		api
			.loadRoles(filter)
			.call()
			.then((response) => {
				this.rolesList = response.rows
				UIStore.isFormSaving = false
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}

	@action loadResourcesForRole(id, form) {
		UIStore.isFormSaving = true
		let formVals = isSafe(form) ? form.values() : {}
		if (isEmpty(formVals.name)) {
			formVals.name = ""
		}
		const filter = getFilters([`role_id=${id}`, `name=${isNotEmpty(formVals.name) ? formVals.name : ""}`])

		this.currResourcesForRoleFilters = formVals
		this.changedResourcesForRoleFilters = false

		api
			.loadResources(filter)
			.call()
			.then((response) => {
				response.rows.forEach((element) => {
					element.isChecked = false
				})
				this.resourcesForRole = response.rows
				UIStore.isFormSaving = false
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}

	@action saveRole(form) {
		let formData = form.values()
		let req = {
			// _id: "4",
			_ref: false,
			_type: "IXS.Security.Data.Role",
			active: true,
			description: formData.description,
			display_name: formData.name,
			name: formData.name
		}

		if (isNotEmpty(formData.roleId)) {
			req._id = formData.roleId
		}

		api
			.saveRole(req)
			.call()
			.then(() => {
				form.reset()
				this.showRoleDetail = false
				this.loadRoles()
				UIStore.isFormSaving = false
			})
	}

	@action assignResourceToRole(resources, roleId) {
		let req = resources.map((val) => {
			return {
				resource: {_id: val},
				role: {_id: roleId},
				right: "CR",
				action: "R+"
			}
		})

		api
			.assignToRole(req)
			.call()
			.then(() => {
				this.showResourceForRoleForm = false
				this.loadResourcesForRole(roleId)
			})
	}

	@action unassingResource(ids, roleId) {
		let req = ids.map((id) => {
			let role = this.resourcesForRole.find((role) => role.resource_id == id)
			return {
				resource: {name: role.name},
				role: {_id: roleId},
				right: "",
				action: "R-"
			}
		})
		api
			.assignToRole(req)
			.call()
			.then(() => {
				this.loadResourcesForRole(roleId)
			})
	}

	@action loadRolesForUser(userId) {
		if (isNotEmpty(userId)) {
			api
				.loadUserRoles(userId)
				.call()
				.then((response) => {
					this.userRoles = response.roles
				})
		}
	}

	@action assignRoleToUser(roles, userId, deleteFromRole = false) {
		let req = roles.map((val) => {
			return {
				user_id: userId,
				role: {_id: val},
				action: deleteFromRole ? "U-" : "U+"
			}
		})

		api
			.assignToRole(req)
			.call()
			.then(() => {
				this.showResourceForRoleForm = false
				this.loadRolesForUser(userId)
			})
	}

	@action resetCurrFilters() {
		this.changedResourcesFilters = false
		this.changedRolesFilters = false
		this.changedResourcesForRoleFilters = false
		this.currResourcesFilters = {}
		this.currRolesFilters = {}
		this.currResourcesForRoleFilters = {}
	}
}

var singleton = new UsersStore()
export default singleton
