//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import {withStyles} from "@material-ui/core/styles"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import SaveIcon from "@material-ui/icons/Done"
import PrintIcon from "@material-ui/icons/Print"
import EvaluateIcon from "@material-ui/icons/Assessment"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import DeleteIcon from "@material-ui/icons/Clear"

import DataStore from "../../../stores/DataStore"
import ScoringStore from "../../../stores/ScoringStore"
import {Grid} from "@material-ui/core"
import XsRadioButtonGroup from "../../../../../global/ui/xsRadioButtonGroup/xsRadioButtonGroup"
import WarningStore from "../../../../../global/store/WarningStore"

const styles = () => ({
	bold: {
		fontWeight: "bold"
	},
	question: {
		padding: "24px"
	},
	even: {
		backgroundColor: "#F1F1F1"
	},
	odd: {
		backgroundColor: "#FFF"
	},
	dialog: {
		overflow: "hidden"
	},
	saveAndPrintButton: {
		marginRight: "15px"
	}
})

@injectIntl
@observer
export class ScoringDialog extends React.Component {
	form
	content

	constructor(props) {
		super(props)
		//Create form and content
		this.form = this.createForm(ScoringStore.scoringTemplate)
		this.content = this.getContent(ScoringStore.scoringTemplate)
	}

	createForm(survey) {
		const invalidMsg = this.props.intl.formatMessage({id: "Patient.form.scoring.invalidForm"})
		const hooks = {
			onSubmit(form) {
				if (!form.isValid) {
					WarningStore.open(invalidMsg)
					form.invalidate()
				}
			},
			onSuccess(form) {
				ScoringStore.evalute(form)
			},
			onError() {}
		}

		//Loop through question to create required fields
		let fields = {
			eval: {
				value: true,
				rules: "boolean"
			}
		}
		survey.questions
			.slice()
			.sort((a, b) => a.item_order < b.item_order)
			.map((q) => (fields[`question-${q._id}`] = {rules: "required"}))
		return new MobxReactForm({fields: fields}, {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	//Get dialog content
	getContent(survey) {
		let content = ""
		const classes = this.props.classes

		if (survey) {
			//Questions - sorted with answers
			if (survey.questions) {
				content = survey.questions
					.slice()
					.sort((a, b) => a.item_order - b.item_order)
					.map((q, idx) => {
						const questionID = `question-${q._id}`
						const rowColor = idx % 2 === 0 ? classes.even : classes.odd
						this.setDefaultAnswer(q)
						return (
							<Grid
								container
								direction="row"
								spacing={8}
								justify="space-between"
								key={idx}
								className={[classes.question, rowColor].join(" ")}
							>
								<Grid item xs={1}>
									<div className={["question-order", classes.bold].join(" ")}>{q.item_order}.</div>
								</Grid>
								<Grid item xs={7}>
									<div className="question-desc">{q.description}</div>
								</Grid>
								<Grid item xs={4}>
									<div className="question-answers">
										{
											<XsRadioButtonGroup
												name={questionID}
												field={this.form.$(questionID)}
												radioButtons={q.answers.map((a) => {
													return {
														value: a._id.toString(),
														label: a.description
													}
												})}
											/>
										}
									</div>
								</Grid>
							</Grid>
						)
					})
			}
		}
		ScoringStore.scoringFormLoaded = true
		return content
	}

	/**
	 * Prednastaví odpovede pre známe otázky na základe markera
	 * Marker: age, gender
	 */

	setDefaultAnswer(question) {
		const knownMarkers = ["age", "gender"]
		if (
			question &&
			question.marker &&
			knownMarkers.includes(question.marker) &&
			question.answers &&
			question.answers.length > 0
		) {
			let setValue = null
			const patientDTO = DataStore.patientDTO.get("patient")

			//Vek pacienta
			if (question.marker === "age") {
				setValue = patientDTO ? patientDTO.age : null
			}

			//Pohlavie pacienta
			if (question.marker === "gender") {
				setValue = patientDTO && patientDTO.gender ? patientDTO.gender.code : null
			}

			question.answers.map((a) => {
				//Odpoveď má marker, rozlišujeme či nejde o rozsah
				if (a.marker) {
					//Overíme či nejde o rozsah
					if (a.marker.indexOf(":") !== -1) {
						//Ide o rozsah tak skontrolujeme obe či sa nachádzame v rozsahu
						const valRange = a.marker.split(":")
						if (setValue >= valRange[0] && setValue <= valRange[1]) {
							this.form.$(`question-${question._id}`).set("value", a._id)
						}
					} else {
						//Zistíme či marker neobsahuje priamo hodnotu
						if (a.marker === setValue) {
							this.form.$(`question-${question._id}`).set("value", a._id)
						}
					}
				}
			})
		}
		return null
	}

	render() {
		const title = ScoringStore.scoringTemplate ? ScoringStore.scoringTemplate.name_ext : null
		let result = null

		if (ScoringStore.scoringResult && ScoringStore.scoringResult.clinical_values) {
			const cvResult = ScoringStore.scoringResult.clinical_values.find(
				(cv) => cv.active && cv._type === "EHR.Data.Record.ClinicalValue"
			)
			if (cvResult) {
				result = cvResult.value
			}
		}

		return (
			<Dialog
				id="xsOverPrescription"
				open={ScoringStore.isOpen}
				onClose={() => ScoringStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
				fullWidth={true}
				className="xs-base-dialog"
			>
				<DialogTitle>
					<div className="nx-insurer-header">
						<div className="nx-header-title">{title}</div>
						<XsIconButton className="xs-dialog-header" icon={<DeleteIcon onClick={() => ScoringStore.close()} />} />
					</div>
				</DialogTitle>

				<DialogContent className="xs-content">{this.content}</DialogContent>

				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-success"
						text={<FormattedMessage id="Patient.form.scoring.evaluate" />}
						onClick={(e) => {
							this.form.$("eval").set("value", true)
							this.form.onSubmit(e)
						}}
						icon={<EvaluateIcon />}
					/>

					{result != null && (
						<div className="result">
							<FormattedMessage id="Patient.form.scoring.result" />: {result}
						</div>
					)}

					<div>
						<XsButton
							className={[
								"xs-success" + (!ScoringStore.scoringResult ? " xs-outline" : ""),
								this.props.classes.saveAndPrintButton
							]}
							text={<FormattedMessage id="Common.label.save" />}
							disabled={!ScoringStore.scoringResult}
							onClick={() => {
								ScoringStore.save(this.props.options.insertScoringToRecord, false)
							}}
							icon={<SaveIcon />}
						/>
						<XsButton
							className={"xs-success" + (!ScoringStore.scoringResult ? " xs-outline" : "")}
							text={<FormattedMessage id="Common.dialog.SaveAndPrint" />}
							disabled={!ScoringStore.scoringResult}
							onClick={() => {
								ScoringStore.save(this.props.options.insertScoringToRecord, true)
							}}
							icon={<PrintIcon />}
						/>
					</div>
				</DialogActions>
			</Dialog>
		)
	}
}
export default withStyles(styles)(ScoringDialog)
