export default {
	load: {
		storeKey: "employeeCardForm",
		params: ["employeeId"],
		type: "Entity.Data.Employee"
	},
	scheme: {
		_id: {
			relation: "@employeeId"
		},
		personal_id: {
			field: "personalNumber"
		},
		validity: {
			object: {
				descriptor: {
					_ref: false
				},
				scheme: {
					from: {
						field: "arrivalDate"
					},
					to: {
						field: "departureDate"
					}
				}
			}
		},
		category: {
			object: {
				descriptor: {
					_type: "Entity.CL.EmployeeCategory",
					_ref: false
				},
				scheme: {
					_id: {
						field: "employeeCategory"
					}
				}
			}
		},
		identifiers: {
			relation: "@identifiers"
		}
		// identifiers:{
		//   array:{
		//     load: (item) => item._type === "Entity.Data.Employee" && item.type === "CARD",
		//     descriptor:{
		//       "_type": "IXS.Data.Object.Identifier",
		//       "type": "CARD",
		//       "system": "IXS",
		//       "_ref": false,
		//       "active": true,
		//     },
		//     scheme:{
		//       value: {
		//         field: "cardNumber"
		//       }
		//     }
		//   }
		// }
	}
}

// {
//   "_id": "72667",
//   "_type": "Entity.Data.Employee",
//   "_ref": false,
//   "personal_id": "1222222",
//   "validity": {
//       "from": "2018-09-03",
//       "to": "2018-09-25"
//   },
//   "category": {
//       "_id": "Nurse",
//       "_type": "Entity.CL.EmployeeCategory",
//       "_ref": false
//   },
//   "identifiers": [
//       {
//           "_id": "175583",
//           "_type": "IXS.Data.Object.Identifier",
//           "_ref": true
//       },
//       {
//           "_type": "IXS.Data.Object.Identifier",
//           "type": "CARD",
//           "system": "IXS",
//           "_ref": false,
//           "_id": "175583",
//           "active": true,
//           "value": "0839023991" // card
//       }
//   ]
// }
