import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
// import moment from "moment"
// import api from "../../../actions/api"
// import moment from "moment"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"

import XsButton from "../../../../global/ui/xsButton/xsButton"
// import XsInput from "../../../../global/ui/xsInput/xsInput"
import XsSearchSelect from "../../../../global/ui/xsSearchSelect/xsSearchSelect"

import fields from "./exportToXlsDialogFields"
import bindings from "../../../../global/ui/globalUISchemeBindings"

// import DataStore from "../../../stores/DataStore"
import UIStore from "../../stores/UIStore"
// import GlobalStore from "../../../../../global/store/GlobalStore"
import ExportToXlsDialogStore from "../../stores/ExportToXlsDialogStore"
import XsLoading from "../../../../global/ui/xsLoading/xsLoading"

@injectIntl
@observer
export default class ExportToXlsDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				if (isSafe(props.onSuccess) && typeof props.onSuccess == "function") {
					UIStore.isFormSaving = true
					props.onSuccess(form.$("exportType").value)
				}
			},
			onError() {}
		}
		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		ExportToXlsDialogStore.formRef = this.form
	}

	render() {
		const exportOptions = [
			{
				_id: "now",
				code: "now",
				name_ext: <FormattedMessage id="Common.label.currentSelection" />
			},
			{
				_id: "all",
				code: "all",
				name_ext: <FormattedMessage id="Common.label.allRecords" />
			}
		]
		return (
			<div>
				{UIStore.isFormSaving && <XsLoading overlay={true} />}
				<Dialog
					id="xsExportToXlsDialog"
					open={ExportToXlsDialogStore.isOpen}
					onClose={() => (this.form.reset(), ExportToXlsDialogStore.close())}
					className="xs-base-dialog"
					classes={{
						paper: "xs-paper-dialog"
					}}
					disableBackdropClick={true}
					maxWidth="md"
				>
					<DialogTitle className="xs-info">
						<div className="xs-header">
							<div
								className="xs-absolute xs-close-btn"
								onClick={() => (this.form.reset(), ExportToXlsDialogStore.close())}
							>
								<i className="fal fa-times fa-lg" />
							</div>
							<div className="xs-header-icon">
								<i className="fal fa-file-excel fa-2x" />
							</div>
							<div className="xs-header-title">
								<FormattedMessage id="Common.label.export" />
							</div>
						</div>
					</DialogTitle>
					<DialogContent className="xs-content">
						<Grid container>
							<Grid item xs={12}>
								<XsSearchSelect field={this.form.$("exportType")} items={exportOptions} />
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions className="xs-footer xs-space-between">
						<XsButton
							className="xs-success mr-3"
							type="submit"
							onClick={(e) => {
								this.form.onSubmit(e)
							}}
							text={<FormattedMessage id="Common.label.exportingToExcel" />}
							icon={<i className="fal fa-file-excel fa-lg" />}
						/>
						<XsButton
							className="xs-danger xs-outline"
							onClick={() => {
								this.form.reset()
								ExportToXlsDialogStore.close()
							}}
							text={<FormattedMessage id="Common.label.cancel" />}
							icon={<i className="fal fa-times fa-lg" />}
						/>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}
