import React from "react"
import {FormattedMessage} from "react-intl"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "patientDischargeForHomeTreatment"
				},
				name: {},
				nameForm: {
					label: "Meno a priezvisko"
				},
				identifier: {},
				insurerCode: {},

				address: {},
				addressForm: {
					label: <FormattedMessage id="Patient.form.contactForm.address" />
				},
				phone: {},
				phoneForm: {
					label: "Tel. číslo"
				},
				email: {},
				emailForm: {
					label: "E-mail"
				},
				deliveryCode: {
					label: "Kód výkonu"
				},
				deliveryDate: {
					label: "Výkon dňa",
					value: moment(),
					type: "date"
				},
				isConscious_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				isConscious_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				isConscious_inv: {
					type: "checkbox",
					value: true
				},
				isOriented_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				isOriented_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				isOriented_inv: {
					type: "checkbox",
					value: true
				},
				isBreathing_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				isBreathing_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				isBreathing_inv: {
					type: "checkbox",
					value: true
				},
				isEupnoe_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				isEupnoe_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				isEupnoe_inv: {
					type: "checkbox",
					value: true
				},
				hasPulse_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				hasPulse_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				hasPulse_inv: {
					type: "checkbox",
					value: true
				},
				hasPulseWhole_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				hasPulseWhole_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				hasPulseWhole_inv: {
					type: "checkbox",
					value: true
				},
				pulseValue: {
					label: " "
				},
				hasCapilarReturn_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				hasCapilarReturn_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				hasCapilarReturn_inv: {
					type: "checkbox",
					value: true
				},
				tk_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				tk_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				tk_inv: {
					type: "checkbox",
					value: true
				},
				tkValue: {
					label: " "
				},
				wound_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				wound_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				wound_inv: {
					type: "checkbox",
					value: true
				},
				bandage_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				bandage_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				bandage_inv: {
					type: "checkbox",
					value: true
				},
				analgezia_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				analgezia_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				analgezia_inv: {
					type: "checkbox",
					value: true
				},
				patient_can_drink_liquid_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				patient_can_drink_liquid_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				patient_can_drink_liquid_inv: {
					type: "checkbox",
					value: true
				},
				patient_can_piss_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				patient_can_piss_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				patient_can_piss_inv: {
					type: "checkbox",
					value: true
				},
				patient_can_walk_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				patient_can_walk_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				patient_can_walk_inv: {
					type: "checkbox",
					value: true
				},
				patient_use_crutches_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				patient_use_crutches_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				patient_use_crutches_inv: {
					type: "checkbox",
					value: true
				},
				hasInstructions_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				hasInstructions_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				hasInstructions_inv: {
					type: "checkbox",
					value: true
				},
				oam_ended_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				oam_ended_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				oam_ended_inv: {
					type: "checkbox",
					value: true
				},
				next_checkup_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				next_checkup_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				next_checkup_inv: {
					type: "checkbox",
					value: true
				},
				after_operation_yes: {
					label: <FormattedMessage id="Common.label.yes" />,
					type: "checkbox",
					value: false
				},
				after_operation_no: {
					label: <FormattedMessage id="Common.label.no" />,
					type: "checkbox",
					value: false
				},
				after_operation_inv: {
					type: "checkbox",
					value: true
				},
				ordinations: {
					label: "Ordinácie"
				},
				entry_time: {
					label: "Hodina príchodu"
				},
				leave_time: {
					label: "Hodina prepustenia"
				},
				inDate: {
					label: "V Košiciach dňa",
					value: moment(),
					type: "date"
				}
			}
		}
	}
}
