"use strict"

import {observable, action} from "mobx"
import moment from "moment"
import {getUserCompanyInfo, exportToExcel} from "../../../global/helpers/actions"

import GlobalStore from "../../../global/store/GlobalStore"
import UIStore from "./UIStore"

import api from "../actions/api"

class DeliveryPerPatientStore {
	@observable deliveryPerPatientData = []
	@observable isFetching = false

	rowPerPage = 100
	@observable showData_count = this.rowPerPage

	filterRef = null

	@observable changedFilters = false
	currFilters = {}

	@action isChangedFilter(formValues) {
		const isChangeTimeFrom = compareMomentDate(this.currFilters.time_from, formValues.time_from)
		const isChangeTimeTo = compareMomentDate(this.currFilters.time_to, formValues.time_to)

		if (
			isChangeTimeFrom ||
			isChangeTimeTo ||
			this.currFilters.orgunit != formValues.orgunit ||
			this.currFilters.insurer != formValues.insurer ||
			this.currFilters.identifier != formValues.identifier ||
			this.currFilters.insurence_type != formValues.insurence_type
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action getFilters(exportToExcel = false) {
		const formValues = this.filterRef.values()

		if (moment(formValues.time_from).isAfter(formValues.time_to)) {
			GlobalStore.setNotificationMessage("Dátum od musí byť menší ale rovný dátumu do!")
		} else {
			this.currFilters = this.filterRef.values()
			this.showData_count = this.rowPerPage
			this.changedFilters = false

			const req = {
				time_from: moment(formValues.time_from).startOf("day"),
				time_to: moment(formValues.time_to).endOf("day"),
				filters: [
					{
						associated_column: "company_id",
						values: [{id_value: getUserCompanyInfo().id}]
					},
					{
						associated_column: "payer_type_id",
						values: [{id_value: "Z"}]
					}
				]
			}

			if (isNotEmpty(formValues.orgunit)) {
				req.filters.push({
					associated_column: "provider_id",
					values: [{id_value: formValues.orgunit}]
				})
			}

			if (isNotEmpty(formValues.insurer)) {
				req.filters.push({
					associated_column: "insurer_id",
					values: [{id_value: formValues.insurer}]
				})
			}

			if (isNotEmpty(formValues.identifier)) {
				req.filters.push({
					associated_column: "person_identifier",
					values: [{id_value: formValues.identifier}]
				})
			}

			if (isNotEmpty(formValues.insurence_type)) {
				req.filters.push({
					associated_column: "insurance_type",
					values: [{id_value: formValues.insurence_type}]
				})
			}

			if (exportToExcel) {
				this.exportToExcel(req)
			} else {
				this.loadDeliveriesPerPatients(req)
			}
		}
	}

	@action exportToExcel(req) {
		UIStore.isFormSaving = true

		req["uri"] = api.loadDeliveriesPerPatient(getUserCompanyInfo().id).getPath()

		exportToExcel(req)
	}

	@action loadDeliveriesPerPatients(req) {
		this.isFetching = true
		api
			.loadDeliveriesPerPatient(getUserCompanyInfo().id, req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows) && res.rows.length) {
					this.deliveryPerPatientData = res.rows
				} else {
					this.deliveryPerPatientData = []
				}
				this.isFetching = false
			})
			.catch(() => {
				this.isFetching = false
			})
	}
}
var singleton = new DeliveryPerPatientStore()
export default singleton
