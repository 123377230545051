"use strict"
import {observable, action} from "mobx"

class ContactPersonFormStore {
	@observable editable = false
	@observable isAdd = false
	@observable isLoaded = false
	@observable showPreferredPhy = false

	contactPersonID = null
	contactPersonSpecRelRelationID = null

	@action reset() {
		this.contactPersonID = null
		this.contactPersonSpecRelRelationID = null
		this.showPreferredPhy = false
		this.isAdd = false
		this.isLoaded = false
		this.editable = false
	}
}

var singleton = new ContactPersonFormStore()
export default singleton
