"use strict"

import {observable, action} from "mobx"
import moment from "moment"

import {getUserPersonnelID, getUserCompanyInfo, exportToExcel} from "../../../global/helpers/actions"

import ExportToXlsDialogStore from "./ExportToXlsDialogStore"

import api from "../actions/api"

class NonCapitationStore {
	@observable isOpen = false
	@observable filters = []

	formRef = null
	nonCapitationListRef = null

	@observable changedFilters = false
	currFilters = {}

	@action isChangedFilter(formValues) {
		let isChangeDateFrom = compareMomentDate(moment(this.currFilters.validity_from), moment(formValues.validity_from))
		let isChangeDateTo = compareMomentDate(moment(this.currFilters.validity_to), moment(formValues.validity_to))

		if (
			isChangeDateFrom ||
			isChangeDateTo ||
			this.currFilters.identifier != formValues.identifier ||
			this.currFilters.provider_id != formValues.provider_id
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action getFilters(form) {
		this.currFilters = form.values()

		this.isChangedFilter(form.values())
		this.filters = this.reqFilters(form)
	}

	reqFilters = (form) => {
		const formValues = form.values()

		let filters = [
			{
				associated_column: "company_id",
				values: [{id_value: getUserCompanyInfo().id}]
			},
			{
				associated_column: "personnel_id",
				values: [{id_value: getUserPersonnelID()}]
			},
			{
				associated_column: "validity_from",
				values: [{id_value: formValues.validity_from}]
			},
			{
				associated_column: "validity_to",
				values: [{id_value: formValues.validity_to}]
			}
		]

		if (isNotEmpty(formValues.provider_id)) {
			filters.push({
				associated_column: "provider_id",
				values: [{id_value: formValues.provider_id}]
			})
		}

		if (isNotEmpty(formValues.identifier)) {
			filters.push({
				associated_column: "identifier",
				values: [{id_value: formValues.identifier}]
			})
		}

		return filters
	}

	@action exportToXls(type, form) {
		const req = {
			uri: `${api.loadNonCapitation().getPath()}?level_flag=CD`,
			filters: this.reqFilters(form)
		}

		if (type == "now") {
			if (isSafe(this.nonCapitationListRef)) {
				req.row_count_show = this.nonCapitationListRef.state.rowsPerPage
				req.row_offset = this.nonCapitationListRef.state.page * this.nonCapitationListRef.state.rowsPerPage + 1
			} else {
				req.row_offset = 1
				req.row_count_show = 10
			}
		}

		exportToExcel(req, () => ExportToXlsDialogStore.close())
	}
}

var singleton = new NonCapitationStore()
export default singleton
