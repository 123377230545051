import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				sms_code: {
					label: <FormattedMessage id="Common.label.smsCode" />
				},
				period_from: {
					label: <FormattedMessage id="Common.label.periodFrom" />,
					placeholder: "napr. 202001 (rok, mesiac)"
				},
				period_to: {
					label: <FormattedMessage id="Common.label.periodTo" />,
					placeholder: "napr. 202011 (rok, mesiac)"
				},
				period_type: {
					label: <FormattedMessage id="Common.label.periodType" />,
					value: "P"
				},
				examination_type: {
					label: <FormattedMessage id="Common.label.examinationType" />,
					value: "LAB",
					rules: "required"
				},
				diagnosis: {
					label: <FormattedMessage id="Common.label.diagnose" />
				},
				examination_tag: {
					label: <FormattedMessage id="Common.label.examinationTag" />
				},
				search: {
					label: <FormattedMessage id="Common.label.find" />
				}
			}
		}
	}
}
