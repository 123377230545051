/**
  Fields Bindings
*/
export default {
	MaterialTextField: {
		//treba definovat vsetky ktore sa nachadzaju v API Documentacii + ak treba tak vlastne vid. priklad okraje: "margin"
		id: "id",
		name: "name",
		type: "type",
		value: "value",
		label: "label",
		placeholder: "placeholder",
		disabled: "disabled",
		rules: "rules",

		// rly dont know how error visualize
		error: "helperText",

		onChange: "onChange",
		onBlur: "onBlur",
		onFocus: "onFocus",
		autoFocus: "autoFocus"

		// only for true testing :)
		//okraje: "margin"
	},
	MaterialSelectField: {
		// autoWidth: "autoWidth",
		// children: "children",
		// classes: "classes",
		// displayEmpty: "displayEmpty",
		// IconComponent: "IconComponent",
		// input: "input",
		// inputProps: "inputProps",
		// MenuProps: "MenuProps",
		// multiple: "multiple",
		// native: "native",
		// onChange: "onChange", // ~~~~~~
		// onClose: "onClose",
		// onOpen: "onOpen",
		// open: "open",
		// renderValue: "renderValue",
		// SelectDisplayProps: "SelectDisplayProps",
		// value: "value" // ~~~~~~
	},
	// MaterialSnackbar: {
	// action: "action",
	// anchorOrigin: "anchorOrigin",
	// autoHideDuration: "autoHideDuration",
	// children: "children",
	// classes : "classes",
	// ContentProps: "ContentProps",
	// disableWindowBlurListener: "disableWindowBlurListener",
	// key: "key",
	// message: "message",
	// onClose: "onClose",
	// onEnter: "onEnter",
	// onEntered: "onEntered",
	// onEntering: "onEntering",
	// onExit: "onExit",
	// onExited: "onExited",
	// onExiting: "onExiting",
	// open: "open",
	// resumeHideDuration: "resumeHideDuration",
	// TransitionComponent: "TransitionComponent",
	// transitionDuration: "transitionDuration",
	// TransitionProps: "TransitionProps"
	// }

	MaterialCheckbox: {
		// checked: "checked",
		// // checkedIcon: "checkedIcon",
		// classes: "classes",
		// color: "color",
		// disabled: "disabled",
		// disableRipple: "disableRipple",
		// //icon: "icon",
		// id: "id",
		// // indeterminate: "indeterminate",
		// // indeterminateIcon: "indeterminateIcon",
		// // inputProps: "inputProps",
		// // inputRef: "inputRef",
		// onChange: "onChange",
		// // type: "type",
		// value: "checked",
		// onChange: "onChange",
	},
	MaterialSwitch: {
		// checked: "checked",
		// checkedIcon: "checkedIcon",
		// classes: "classes",
		// color: "color",
		// disabled: "disabled",
		// disableRipple: "disableRipple",
		// icon: "icon",
		// id: "id",
		// inputProps: "inputProps",
		// inputRef: "inputRef",
		// onChange: "onChange",
		// type: "type",
		// value: "value"
	},
	QuillTextArea: {
		// id: "id",
		// className: "className",
		// defaultValue: "defaultValue",
		// readOnly: "readOnly",
		// placeholder: "placeholder",
		// modules: "modules",
		// formats: "formats",
		// style: "style",
		// theme: "theme",
		// tabIndex: "tabIndex",
		// bounds: "bounds",
		// children: "children",
		// onChange: "onChange",
		// onChangeSelection: "onChangeSelection",
		// onFocus: "onFocus",
		// onBlur: "onBlur",
		// onKeyPress: "onKeyPress",
		// onKeyDown: "onKeyDown",
		// onKeyUp: "onKeyUp"
	}
}
