"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"
import WarningStore from "../../../global/store/WarningStore"
import InvoiceFormStore from "../../ambulance/stores/InvoiceFormStore"
import GlobalStore from "../../../global/store/GlobalStore"
import UIStore from "./UIStore"
import {getUserCompanyInfo, getSelectedOrgUnitID, exportToExcel} from "../../../global/helpers/actions"

class ExportSocInsDeliveryDialogStore {
	@observable isOpen = false

	date_from = undefined
	date_to = undefined
	provider_id = undefined
	company_id = undefined

	translatedExportBatchError = undefined
	translatedGetSummaryDeliveriesError = undefined
	translatedExportNoItems = undefined

	invoiceNumber = ""
	invoiceData = {}
	allToDelivery = false
	selectedOrgUnitsIds = []

	@action open(obj) {
		this.date_from = obj.date_from
		this.date_to = obj.date_to
		this.provider_id = obj.provider_id
		this.company_id = obj.company_id

		this.isOpen = true
		this.translatedExportBatchError = obj.translatedExportBatchError
		this.translatedGetSummaryDeliveriesError = obj.translatedGetSummaryDeliveriesError
		this.translatedExportNoItems = obj.translatedExportNoItems
	}

	@action handleChange(key, value) {
		this[key] = value
	}

	@action exportRecordToXls(from, to, personIdentifier) {
		UIStore.isFormSaving = true
		let req = {
			uri: api.loadCompanyDeliveries(getUserCompanyInfo().id, null, "L2").getPath(), //`amb/entity/companies/${getUserCompanyInfo().id}/deliveries?level_flag=CDL2`,
			filters: [
				{
					associated_column: "provider_id",
					values: [
						{
							id_value: getSelectedOrgUnitID()
						}
					]
				},
				{
					associated_column: "payer_type",
					values: [
						{
							id_value: "S"
						}
					]
				},
				{
					associated_column: "time_from",
					values: [
						{
							id_value: from
						}
					]
				},
				{
					associated_column: "time_to",
					values: [
						{
							id_value: to
						}
					]
				}
			]
		}

		if (isNotEmpty(personIdentifier)) {
			req.filters.push({
				associated_column: "person_identifier",
				values: [
					{
						id_value: personIdentifier.trim()
					}
				]
			})
		}

		exportToExcel(req)
	}

	@action getSocSummaryDeliveries(orgUnits, totalLength) {
		UIStore.isFormSaving = true
		let generateWithName = totalLength > 1
		if (orgUnits.length == 0) {
			WarningStore.open(this.translatedExportNoItems)
			this.close()
			return
		}
		let thisOrgunit = orgUnits.shift()
		api
			.loadSocSummaryDeliveries({
				from: this.date_from,
				to: this.date_to,
				org_unit_id: thisOrgunit
			})
			.call()
			.then((data) => {
				// if (isNotEmpty(this.invoiceNumber)) {
				// 	this.invoiceData["invoice_number"] = this.invoiceNumber
				// }

				if (isNotSafe(this.invoiceData["items"])) {
					this.invoiceData["items"] = []
				}
				if (isSafe(data.rows) && data.rows.length > 0) {
					let tmp = data.rows.map((row) => {
						return {
							itemName:
								(generateWithName ? GlobalStore.orgunits.find((row) => row._id == thisOrgunit).code_ext + " - " : "") +
								row.item_name_ext,
							itemUnitPrice: row.unit_price,
							itemQuantity: row.count,
							itemPrice: row.price
						}
					})
					this.invoiceData["items"].push(...tmp)
					if (orgUnits.length >= 1) {
						this.getSocSummaryDeliveries(orgUnits, totalLength)
					} else {
						InvoiceFormStore.setNewInvoiceData(this.invoiceData, this.callbackFn)
					}
				} else {
					if (orgUnits.length >= 1) {
						this.getSocSummaryDeliveries(orgUnits, totalLength)
					} else if (this.invoiceData["items"].length == 0) {
						WarningStore.open(this.translatedExportNoItems)
						this.close()
					} else {
						InvoiceFormStore.setNewInvoiceData(this.invoiceData, this.callbackFn)
					}
				}
				UIStore.isFormSaving = false
			})
			.catch(() => {
				WarningStore.open(this.translatedGetSummaryDeliveriesError)
				UIStore.isFormSaving = false
			})

		this.isOpen = false
	}

	callbackFn = (formData) => {
		const invoiceNumberFromInvoice = isSafe(formData) ? formData.$("invoice_number").get("value") : null
		this.download(invoiceNumberFromInvoice)
	}

	@action download(invoiceNumber) {
		UIStore.isFormSaving = true
		let filter = {}
		if (this.allToDelivery) {
			filter = {
				company_id: this.company_id,
				date: this.date_from,
				invoice_number: isSafe(invoiceNumber) ? invoiceNumber : this.invoiceNumber
			}
		} else {
			filter = {
				provider_ids: this.selectedOrgUnitsIds,
				company_id: this.company_id,
				date: this.date_from,
				invoice_number: isSafe(invoiceNumber) ? invoiceNumber : this.invoiceNumber
			}
		}

		api
			.downloadSocialInsuranceDeliveries(filter)
			.call()
			.then((response) => {
				if (response.ok == true) {
					let fileName = isSafe(response.headers.get("content-disposition"))
						? response.headers.get("content-disposition").split("filename=")[1]
						: "davka.xml"
					response.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})
					UIStore.isFormSaving = false
				} else {
					WarningStore.open(response.statusText)
					UIStore.isFormSaving = false
				}
			})
			.catch(() => {
				WarningStore.open(this.translatedExportBatchError)
				UIStore.isFormSaving = false
			})

		this.close()
	}

	@action close() {
		this.date_from = undefined
		this.date_to = undefined
		this.provider_id = undefined
		this.company_id = undefined

		this.invoiceNumber = ""
		this.invoiceData = {}
		this.allToDelivery = ""
		this.selectedOrgUnitsIds = []

		this.isOpen = false

		this.invoiceNumber = ""
	}
}

var singleton = new ExportSocInsDeliveryDialogStore()
export default singleton
