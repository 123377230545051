import {observable, action} from "mobx"
import {getSelectedOrgUnitID, getSelectedOrgUnit, getUser, getUserPersonnelID} from "../../../global/helpers/actions"
import DDaction from "../actions/patientCard"
import api from "../actions/api"
import {GWApiCall} from "../../../global/helpers/api"
import config from "../../../global/config/settings"

import GlobalStore from "../../../global/store/GlobalStore"
import DataStore from "./DataStore"
import UIStore from "./UIStore"
import StornoPrescriptionDialogStore from "./StornoPrescriptionDialogStore"
import ConfirmStornoPrescriptionDialogStore from "./ConfirmStornoPrescriptionDialogStore"

class MultiStornoDialogStore {
	@observable isOpen = false
	@observable recordIds = null
	@observable reason = ""

	@action open(ids) {
		this.isOpen = true
		this.recordIds = ids
	}

	@action onHandleChange(value) {
		this.reason = value
	}

	@action storno() {
		UIStore.isFormSaving = true

		let request = []
		let requestWithoutBarcode = []

		this.recordIds.forEach((rec) => {
			if (isNotEmpty(rec.barcode)) {
				request.push({
					_id: rec.record_id,
					reason: this.reason,
					_type: "EHR.Data.Medication"
				})
			} else {
				requestWithoutBarcode.push({
					_id: rec.record_id,
					reason: this.reason,
					_type: "EHR.Data.Medication"
				})
			}
		})

		if (requestWithoutBarcode.length) {
			api
				.deletePrescriptions(requestWithoutBarcode)
				.call()
				.then(() => {
					//Zrušíme označenie záznamov EHR
					requestWithoutBarcode.forEach((r) => DataStore.removeFromCheckedEHR({record_id: r._id}))

					const providerID = getSelectedOrgUnitID()
					DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
						DataStore.setPatientEHR(overview)
						UIStore.isFormSaving = false
					})
					this.close()
				})
				.catch(() => {
					this.close()
				})
		}

		if (request.length) {
			UIStore.isFormSaving = true
			api
				.loadPrescriptionDeleteInfo(request)
				.call()
				.then((response) => {
					//Zrušíme označenie záznamov EHR
					request.forEach((r) => DataStore.removeFromCheckedEHR({record_id: r._id}))

					let xmlB64Data = []
					let cardNotInserted = false
					let signatureData = []
					response.forEach((res) => {
						xmlB64Data.push({
							RecordId: res._id,
							XmlData:
								isSafe(res.ixs_signer_data) &&
								isSafe(res.ixs_signer_data.payload) &&
								isSafe(res.ixs_signer_data.payload.text)
									? res.ixs_signer_data.payload.text
									: ""
						})
					})

					const selectedOrgUnit = getSelectedOrgUnit()
					const user = getUser()
					const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
					const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
					const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
					const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
					const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

					const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
						? DataStore.patientDTO.get("patient").validInfo.client
						: null
					const JRUZIDObj = isSafe(patientClientInfo.identifiers)
						? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
						: null

					GWApiCall({
						method: "POST",
						uri: `${config.GW_BASE_URL}/SignXmlData`,
						body: JSON.stringify({
							XmlDataItems: xmlB64Data,
							AmbeePatientId: DataStore.patientDTO.get("patientID"),
							PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
							PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
							AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
							AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
							AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
							AmbeePersonnelId: getUserPersonnelID(),
							OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
							PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
							PersonnelExpertiseJRUZVersion: specVer,
							PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
						})
					})
						.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
						.then((data) => {
							GlobalStore.checkEHGWError(data.ErrorCode, data.ErrorMessage, data.IsEhealthException)
							if (isSafe(data) && isSafe(data.XmlDataItems) && data.XmlDataItems.length) {
								Object.keys(data.XmlDataItems).forEach((obj) => {
									if (isSafe(data.XmlDataItems[obj]) && isSafe(data.XmlDataItems)) {
										signatureData.push({
											record_id: data.XmlDataItems[obj].RecordId,
											signature: data.XmlDataItems[obj].XmlData
										})
									}
								})
							} else {
								cardNotInserted = true
								Object.keys(response).forEach((obj) => {
									signatureData.push({record_id: response[obj].record_id, signature: ""})
								})
							}

							if (cardNotInserted) {
								const errorCard =
									"Záznam nebolo možné podpísať, skontrolujte, či máte vloženú kartu v čítačke. Chcete napriek tomu pokračovať?"

								ConfirmStornoPrescriptionDialogStore.open(errorCard, true, request.map((x) => x._id), signatureData)
								UIStore.isFormSaving = false
								UIStore.isFormSavingMessage = ""
								this.close()
							} else {
								api
									.deleteSignatureMultiplePrescriptions(signatureData)
									.call()
									.then(async (response) => {
										UIStore.isFormSavingMessage = ""
										let text = ""
										let confirm = false
										let ids = []
										Object.keys(response).forEach((obj, index) => {
											if (isSafe(response[obj])) {
												if (isSafe(response[obj].warning)) {
													text +=
														response[obj].warning.text +
														(Object.keys(response).length != index || response[obj].warning.text != "" ? "" : "<br/>")
													if (!confirm) confirm = response[obj].warning.confirm
												}
												if (isSafe(response[obj].warning) && response[obj].warning.confirm == false) {
													ids.push(response[obj].record_id)
												}
											}
										})

										let request = []
										ids.forEach((rec) => {
											request.push({
												_id: rec,
												active: false,
												reason: this.reason,
												_type: "EHR.Data.Medication"
											})
										})

										api
											.stornoRecords(request)
											.call()
											.then(() => {
												if (!confirm) {
													if (isNotEmpty(text)) {
														// warning storna
														ConfirmStornoPrescriptionDialogStore.open(text, false, null, null, true)
													} else {
														StornoPrescriptionDialogStore.open("Operácia prebehla úspešne", false)
													}
												} else {
													ConfirmStornoPrescriptionDialogStore.open(text, false, null, null, true)
												}

												const providerID = getSelectedOrgUnitID()
												DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then(
													(overview) => {
														DataStore.setPatientEHR(overview)
														UIStore.isFormSaving = false
														UIStore.isFormSavingMessage = ""

														let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
														if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
															DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
														}
													}
												)
												this.close()
											})
											.catch(() => {
												const providerID = getSelectedOrgUnitID()
												DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then(
													(overview) => {
														DataStore.setPatientEHR(overview)
														UIStore.isFormSaving = false
														UIStore.isFormSavingMessage = ""

														let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
														if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
															DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
														}
													}
												)
												this.close()
											})
									})
									.catch(async () => {
										const providerID = getSelectedOrgUnitID()
										DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) =>
											DataStore.setPatientEHR(overview)
										)
									})
								this.close()
							}
						})
						.catch(() => {
							request.forEach((rec) => {
								signatureData.push({
									record_id: rec._id
								})
							})

							const errorCard =
								"Záznam nebolo možné podpísať, skontrolujte, či máte vloženú kartu v čítačke. Chcete napriek tomu pokračovať?"

							ConfirmStornoPrescriptionDialogStore.open(errorCard, true, request.map((x) => x._id), signatureData)

							UIStore.isFormSaving = false
							UIStore.isFormSavingMessage = ""
							this.close()
						})
				})
		}
	}

	@action close() {
		this.isOpen = false
		this.recordIds = null
		this.reason = ""
	}
}

var singleton = new MultiStornoDialogStore()
export default singleton
