"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"
// import UIStore from "./UIStore"
// import {getSelectedOrgUnitID} from "../../../global/helpers/actions"
// import actionCard from "../actions/patientCard"
// import DataStore from "./DataStore"
import GlobalStore from "../../../global/store/GlobalStore"
import moment from "moment"
import settings from "../../../global/config/settings"
import PatientSicknessAbsStore from "./PatientSicknessAbsStore"

class SicknessPrintDatesStore {
	@observable isOpen = false
	@observable id = ""

	@action open(id) {
		this.isOpen = true
		this.id = id
	}

	@action close() {
		this.isOpen = false
		this.id = ""
	}

	@action checkIfCorrectDate(form) {
		api
			.loadRecord(this.id, "L4")
			.call()
			.then((recordData) => {
				if (isSafe(recordData.general_items) && recordData.general_items.length) {
					const deliveries = recordData.general_items.filter((x) => x._type == "EHR.Data.Record.SK.Delivery")
					if (isSafe(deliveries)) {
						if (
							deliveries.some(
								(row) =>
									moment(row.delivery_date_time).format("YYYYMM") == form.$("date").value &&
									isSafe(row.item) &&
									row.item.code_ext == "71" &&
									isSafe(row.delivery_extension) &&
									row.delivery_extension.code_ext == "TDPN"
							)
						) {
							this.printAndClose(moment(form.$("date").value, "YYYYMM").endOf("month"))
						} else {
							GlobalStore.openConfirmationDialog("patientSicknessAddDelivery", {
								dateValue: moment(form.$("date").value, "YYYYMM")
									.endOf("month")
									.format("DD.MM.YYYY")
							})
						}
					}
				}
			})
	}

	@action generateDeliveryToPN(date) {
		if (isNotEmpty(this.id)) {
			api
				.loadRecord(this.id, "L4")
				.call()
				.then(async (recordData) => {
					let deliveryObj = await api
						.loadDeliveries({
							filters: [{associated_column: "code_ext", predicate: "=", values: [{id_value: "71"}]}],
							row_count_full: 200
						})
						.call()

					let delivery = deliveryObj.rows[0]
					const diagnosisObj = recordData.general_items.find((x) => x._type == "EHR.Data.Record.Diagnosis")
					const extension = GlobalStore.CL["deliveryExtension"].find((row) => row.name_ext == "TDPN")
					let deliveries = [
						{
							_ref: false,
							_type: "EHR.Data.Record.SK.Delivery",
							delivery_extension: extension.code,
							item: {
								_id: delivery._id,
								_type: "EHR.CL.Bill.SK.Delivery"
							},
							diagnosis: {
								_id: diagnosisObj.item._id,
								_type: "EHR.CL.Record.Diagnosis"
							},
							count: 1,
							payer_type: "S",
							type: "dlv",
							delivery_date_time: moment(date, "DD.MM.YYYY").format(settings.DB_DATETIME_FORMAT)
						}
					]
					await api.saveDeliveriesAdd(deliveries, this.id).call()
					this.printAndClose(moment(date, "DD.MM.YYYY"))
				})
		}
	}

	@action printAndClose(date) {
		GlobalStore.closeConfirmationDialog()
		PatientSicknessAbsStore.printSicknessAbsTDPN(this.id, date)
		this.close()
	}
}

var singleton = new SicknessPrintDatesStore()
export default singleton
