/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				employeeCategory: {
					label: <FormattedMessage id="Employee.form.employeeinfo.employeecategory" />,
					rules: "required"
				},
				personalNumber: {
					label: <FormattedMessage id="Employee.form.employeeinfo.personalnumber" />
				},
				cardNumber: {
					label: <FormattedMessage id="Employee.form.employeeinfo.cardnumber" />
					// rules: "required"
				},
				arrivalDate: {
					label: <FormattedMessage id="Employee.form.employeeinfo.arrivaldate" />,
					type: "date"
				},
				departureDate: {
					label: <FormattedMessage id="Employee.form.employeeinfo.departuredate" />,
					type: "date"
				}
			}
		}
	}
}
