import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import renderHTML from "react-render-html"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import SearchExchangeTicketsStore from "../../../stores/SearchExchangeTicketsStore"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

@observer
export default class SearchExchangeTicketsDialogs extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsSearchExchangeTicket"
				open={SearchExchangeTicketsStore.isOpen}
				onClose={() => SearchExchangeTicketsStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
				className="xs-base-dialog"
			>
				<DialogTitle className={isNotEmpty(SearchExchangeTicketsStore.errorMessage) ? "xs-danger" : ""}>
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-reply fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Patient.form.patientPrescription.getResponse.dialog.title" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					{SearchExchangeTicketsStore.isFetching ? (
						<XsLoading />
					) : (
						<div>
							{isNotEmpty(SearchExchangeTicketsStore.errorMessage) ? (
								SearchExchangeTicketsStore.errorMessage
							) : SearchExchangeTicketsStore.notResponse ? (
								<div>
									<FormattedMessage id="Common.not.find.response" />
								</div>
							) : (
								<div>
									{isNotEmpty(SearchExchangeTicketsStore.htmlData)
										? renderHTML(SearchExchangeTicketsStore.htmlData)
										: ""}
								</div>
							)}
						</div>
					)}
				</DialogContent>
				<DialogActions className="xs-footer">
					<XsButton
						className={isNotEmpty(SearchExchangeTicketsStore.errorMessage) ? "xs-danger" : "xs-default xs-outline"}
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => SearchExchangeTicketsStore.close()}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
