/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				mobile: {
					label: <FormattedMessage id="Patient.form.contactForm.mobile" />
				},
				datefrom: {
					label: <FormattedMessage id="Capitation.List.From" />,
					type: "date",
					rules: "required",
					value: moment().startOf("day")
				},
				dateto: {
					label: <FormattedMessage id="Capitation.List.To" />,
					rules: "required",
					type: "date",
					value: moment().endOf("day")
				}
			}
		}
	}
}
