/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				addressId: {},
				region: {
					//definujeme len staticke props ako su label, placeholder, ktore sa nemenia za behu...
					label: <FormattedMessage id="Common.label.region" defaultMessage="Region" />,
					rules: "string"
				},
				county: {
					label: <FormattedMessage id="Common.label.county" defaultMessage="County" />,
					rules: "string"
				},
				street: {
					label: <FormattedMessage id="Common.label.street" defaultMessage="Street" />,
					rules: "string"
				},
				house_number: {
					label: <FormattedMessage id="Common.label.number" defaultMessage="House number" />,
					rules: "string"
				},
				city: {
					label: <FormattedMessage id="Common.label.city" defaultMessage="City" />,
					rules: "string|required"
				},
				zip: {
					label: <FormattedMessage id="Common.label.zip" defaultMessage="ZIP" />,
					rules: "string"
				},
				check: {
					type: "checkbox",
					label: <FormattedMessage id="Patient.form.contactForm.primary" defaultMessage="Primary" />
				},
				zipText: {},
				cityText: {}
				// type: {
				//   label: "",
				//   rules: "string",
				// },
			}
		}
	}
}
