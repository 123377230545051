"use strict"

import {observable, action} from "mobx"

class PatientAlertsDialogStore {
	@observable isOpen = false
	@observable isEditable = false
	@observable alertObj = undefined
	@observable nameOfDeleteAlert = ""

	alertId = undefined

	@action open(alert = undefined) {
		this.isOpen = true
		this.alertObj = alert
	}

	@action insertFormData(form) {
		let alert = this.alertObj

		this.isEditable = true
		this.alertId = alert._id

		form.$("alert").set("value", alert.alert)
		form.$("severity").set("value", alert.code)

		this.alertObj = undefined
	}

	@action close() {
		this.isOpen = false
		this.nameOfDeleteAlert = ""
	}
}

var singleton = new PatientAlertsDialogStore()
export default singleton
