import React from "react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import moment from "moment"
import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import VaccAllergyListDialogStore from "../../../stores/VaccAllergyListDialogStore"
import WarningStore from "../../../../../global/store/WarningStore"
import UIStore from "../../../stores/UIStore"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import VaccAllergyDialogStore from "../../../stores/VaccAllergyDialogStore"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"

@observer
export default class VaccAllergyListDialog extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			showTable: false
		}

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		const fields = {
			dateFrom: {
				label: "Dátum vykonania od",
				rules: "required",
				value: moment().subtract(6, "months")
			},
			dateTo: {
				label: "Dátum vykonania do",
				rules: "required",
				value: moment()
			}
		}

		this.form = new MobxReactForm({fields: fields}, {plugins: {dvr: validatorjs}, hooks})
	}

	render() {
		return (
			<Dialog
				id="xsVaccAllergyListDialog"
				open={VaccAllergyListDialogStore.isOpen}
				onClose={() =>
					VaccAllergyListDialogStore.close(this.form, () => {
						this.setState({showTable: false})
					})
				}
				disableBackdropClick={true}
				maxWidth={this.state.showTable == false ? "md" : "lg"}
				fullWidth={this.state.showTable}
				classes={{
					paper: "xs-paper-dialog"
				}}
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-syringe fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.patientVaccList" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content" style={{overflow: "scroll"}}>
					{UIStore.isFormSaving ? (
						<XsLoading />
					) : this.state.showTable ? (
						<Grid container>
							{isSafe(VaccAllergyListDialogStore.allergyList) && VaccAllergyListDialogStore.allergyList.length > 0 ? (
								<Grid item xs={12}>
									<XsTable
										config={{
											columnDefs: {
												malady: {
													title: "Druh očkovania",
													type: "string",
													sortable: true,
													design: {
														width: "10%"
													}
												},
												note: {
													title: <FormattedMessage id="Common.label.note" />,
													type: "string",
													sortable: true,
													design: {
														width: "20%"
													}
												},
												vaccination_type: {
													title: <FormattedMessage id="Common.label.vaccinationType" />,
													type: "string",
													sortable: true,
													design: {
														width: "10%"
													}
												},
												dose_count: {
													title: " Počet dávok",
													design: {
														width: "5%"
													}
												},
												dose_order: {
													title: "Poradie dávky",
													design: {
														width: "5%"
													}
												},
												last_dose: {
													title: "Posledná dávka",
													design: {
														width: "5%"
													}
												},
												datetime_executed: {
													title: "Dátum a čas vykonania očkovania",
													type: "datetime",
													design: {
														width: "10%",
														body: {
															formatter: (props) => {
																return isSafe(props) && isNotEmpty(props)
																	? moment(props).format("DD.MM.YYYY HH:mm:ss")
																	: ""
															}
														}
													}
												},
												vaccine: {
													title: "Očkovacia látka",
													design: {
														width: "20%"
													}
												},
												batch_number: {
													title: "Šarža",
													design: {
														width: "5%"
													}
												},
												amount: {
													title: <FormattedMessage id="Patient.form.patientPrescription.packs" />,
													design: {
														width: "5%"
													}
												},
												action: {
													title: "",
													type: "action",
													design: {
														width: "10%",
														body: {
															renderer: (props) => {
																const symptoms = props.value.symptoms
																const rcid = props.value.rcid
																return (
																	<React.Fragment>
																		{isSafe(symptoms) && symptoms.length > 0 && (
																			<XsIconButton
																				icon={<i className="fal fa-allergies fa-lg xs-greyDefault" />}
																				tooltip={"Reakcia na očkovanie"}
																				onClick={() => {
																					let reactionText = ""
																					if (isSafe(symptoms)) {
																						reactionText = symptoms.map(
																							(row) =>
																								`<p>${
																									isNotEmpty(row.date_occured)
																										? moment(row.date_occured).format("DD.MM.YYYY")
																										: ""
																								} | ${isNotEmpty(row.note) ? row.note : ""} | ${
																									isNotEmpty(row.reaction_type) ? row.reaction_type : ""
																								} | ${isNotEmpty(row.allergen) ? row.allergen : ""} | ${
																									isNotEmpty(row.allergy_type) ? row.allergy_type : ""
																								} </p>`
																						)
																					}

																					WarningStore.open(
																						`<span><h4>Reakcia na očkovanie</h4> ${
																							isNotEmpty(reactionText) ? reactionText.join("") : ""
																						}</span>`
																					)
																				}}
																			/>
																		)}
																		{isSafe(rcid) && (
																			<XsIconButton
																				icon={<i className="fal fa-syringe fa-lg xs-greyDefault" />}
																				tooltip={<FormattedMessage id="Common.label.vaccAllergy" />}
																				onClick={() => {
																					VaccAllergyDialogStore.open(null, rcid, null)
																				}}
																			/>
																		)}
																		{/* <XsIconButton
																icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
																onClick={() =>
																	GlobalStore.openConfirmationDialog("xsDeleteResourcesAssignedToRole", {
																		resourceName: props.value
																	})
																}
															/> */}
																	</React.Fragment>
																)
															}
														}
													}
												}
											},
											options: {
												onRowClick: () => {},
												mapper: (dataRow) => {
													return {
														...dataRow,
														action: {symptoms: dataRow.vaccination_reaction, rcid: dataRow.rc_id}
													}
												}
											},
											dataSource: VaccAllergyListDialogStore.allergyList
										}}
									/>
								</Grid>
							) : (
								<Grid container className="mt-2" justify="center">
									<Grid item className="xs-bold pt-2 pb-2">
										<FormattedMessage id="Table.NoData" />
									</Grid>
								</Grid>
							)}
						</Grid>
					) : (
						<Grid container spacing={8}>
							<Grid item xs={4}>
								<XsDateTimePicker field={this.form.$("dateFrom")} />
							</Grid>
							<Grid item xs={4}>
								<XsDateTimePicker field={this.form.$("dateTo")} />
							</Grid>
							<Grid item xs={4}>
								<XsButton
									className="xs-success mt-3"
									icon={<i className="fal fa-search" />}
									text={<FormattedMessage id="Common.list.search" />}
									onClick={() =>
										VaccAllergyListDialogStore.loadListData(this.form, () => {
											this.setState({showTable: true})
										})
									}
								/>
							</Grid>
						</Grid>
					)}
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.form.close" />}
						onClick={() =>
							VaccAllergyListDialogStore.close(this.form, () => {
								this.setState({showTable: false})
							})
						}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
