import {createMuiTheme} from "@material-ui/core/styles"

/* eslint-disable */
const blueInfo = "#39B0DD" //"#03A9F4"
const blueLight = "#E1F5FE"
const blueDark = "#01579b"
const greenSuccess = "#00A787" //"#8BC34A"
const greenLight = "#F1F8E9"
const greenDark = "#33691E"
const amberWarning = "#FFB900" // "#FFC107"
const amberLight = "#FFF8E1"
const amberDark = "#FF8F00"
const redDanger = "#C6184E" //"#E91E63"
const redLight = "#FCE4EC"
const redDark = "#880E4F"
const greyLight = "#E5E7EA"
const greyDefault = "#78849E" //"#A0A5AF"
const greyDark = "#60606F" //"#21212A"
const greyOutline = "#E0E5EF"
const white = "#F0F3F8"
const snowWhite = "#FFFFFF"
const black = "#22232C" //"#20252F"

const inputBackground = "rgb(241, 243, 248)"
const border = "rgb(192, 197, 207)"

const smallfontSize = "13px" //"11pt"
const mediumFontSize = "15px" //"13pt"
const normal = 400 //300
const bold = 500
/* eslint-enable */

export const muiTheme = createMuiTheme({
	overrides: {
		MuiFormControl: {
			root: {
				width: "100%"
			}
		},
		MuiButton: {
			root: {
				// backgroundColor: snowWhite,
				borderRadius: 5,
				fontSize: mediumFontSize,
				minHeight: "36px",
				height: "36px",
				minWidth: "75px",
				padding: "0px 15px",
				textTransform: "none",
				whiteSpace: "nowrap",
				fontWeight: normal,
				"&.xs-add-dot": {
					border: `1px dotted ${greyLight}`,
					color: "lightgray"
				},
				"&.xs-primary": {
					background: blueDark,
					border: `1px solid ${blueDark}`,
					color: snowWhite,

					"&.xs-outline": {
						background: snowWhite,
						border: `1px solid ${blueDark}`,
						color: blueDark
					}
				},
				"&.xs-info": {
					background: blueInfo,
					border: `1px solid ${blueInfo}`,
					color: snowWhite,

					"&.xs-outline": {
						background: snowWhite,
						border: `1px solid ${blueInfo}`,
						color: blueInfo
					}
				},
				"&.xs-default": {
					background: greyDefault,
					border: `1px solid ${greyDefault}`,
					color: snowWhite,

					"&.xs-outline": {
						background: snowWhite,
						border: `1px solid ${greyOutline}`,
						color: greyDefault
					}
				},
				"&.xs-success": {
					background: greenSuccess,
					border: `1px solid ${greenSuccess}`,
					color: snowWhite,

					"&.xs-outline": {
						background: snowWhite,
						border: `1px solid ${greyOutline}`,
						color: greenSuccess
					}
				},
				"&.xs-warning": {
					background: amberWarning,
					border: `1px solid ${amberWarning}`,
					color: snowWhite,

					"&.xs-outline": {
						background: snowWhite,
						border: `1px solid ${greyOutline}`,
						color: amberWarning
					}
				},
				"&.xs-danger": {
					background: redDanger,
					border: `1px solid ${redDanger}`,
					color: snowWhite,

					"&.xs-outline": {
						background: snowWhite,
						border: `1px solid ${greyOutline}`,
						color: redDanger
					}
				},
				"&.xs-action": {
					background: snowWhite,
					border: `1px solid ${snowWhite}`,
					color: black,
					fontSize: "21px",
					height: "60px",
					width: "100%",
					overflow: "hidden",
					justifyContent: "start"
				},
				"&.xs-dialog-header": {
					minHeight: "24px !important",
					height: "24px",
					minWidth: "24px !important",
					color: greyDefault
				},
				// "&.xs-dialog-header-close-icon": {
				//   minHeight: "24px !important",
				//   height: "24px",
				//   minWidth: "24px !important",
				//   color: greyDefault,

				//   position: "absolute",
				//   top: "10px",
				//   right: "10px",
				// },
				"&.xs-circle": {
					borderRadius: "24px"
				},
				"&.xs-icon": {
					minHeight: "36px",
					minWidth: "36px",
					padding: 0,
					borderWidth: "1px"
				},
				"&:hover": {
					backgroundColor: snowWhite
				}
			},
			// disabled: {
			// 	backgroundColor: `${snowWhite} !important`,
			// 	color: `${greyDefault} !important`,
			// 	border: `1px solid ${greyOutline} !important`
			// },
			contained: {
				backgroundColor: snowWhite,
				"&:hover": {
					backgroundColor: snowWhite
				}
			}
		},
		MuiInput: {
			root: {
				backgroundColor: inputBackground,
				border: `1px solid ${border}`,
				borderRadius: 5,
				color: greyDark,
				height: 34,
				lineHeight: "none",
				marginBottom: 10,
				position: "initial",
				textAlign: "left",
				fontSize: mediumFontSize
			},
			underline: {
				"&::before, &::after": {
					border: "none !important"
				}
			},
			input: {
				borderRadius: "inherit",
				padding: "0 10px"
			},
			inputType: {
				height: "34px"
			},
			disabled: {
				backgroundColor: inputBackground,
				border: "1px solid rgba(192, 197, 207, 0.4)",
				color: `${black} !important`,
				boxShadow: "none !important",
				opacity: "1" //"0.4"
			},
			inputMultiline: {
				padding: "0px 10px"
			}
		},
		MuiInputLabel: {
			root: {
				color: greyDark, //greyDefault, //"rgba(0, 0, 0, 0.3)",
				fontSize: "16px",
				lineHeight: "15px", //"17px",
				padding: "0 10px",
				whiteSpace: "nowrap"
			}
		},
		MuiCheckbox: {
			colorSecondary: {
				color: `${greyDefault} !important`
			}
		},
		MuiTable: {
			root: {
				height: 30,
				borderCollapse: "separate",
				borderSpacing: "0px 5px"
			}
		},
		MuiTableRow: {
			// body: {
			//   height: "50px"
			// },
			head: {
				height: "44px"
			}
		},
		MuiTableCell: {
			root: {
				"&:first-child": {
					borderTopLeftRadius: "5px",
					borderBottomLeftRadius: "5px"
				},
				"&:last-child": {
					borderTopRightRadius: "5px",
					borderBottomRightRadius: "5px",
					paddingRight: "15px"
				}
			},
			body: {
				backgroundColor: snowWhite,
				color: greyDark,
				fontSize: mediumFontSize,
				padding: "15px",
				fontWeight: normal,

				"&.bgGrey": {
					backgroundColor: white
				}
			},
			head: {
				fontSize: smallfontSize,
				color: greyDark,
				borderBottom: "none",
				borderTop: "none",
				padding: "10px 15px",
				fontWeight: normal
			}
		},
		MuiTablePagination: {
			input: {
				margin: "0 15px"
			},
			select: {
				paddingRight: 30,
				textAlign: "center",
				padding: "0 4px"
			},
			selectRoot: {
				display: "flex",
				alignItems: "center",
				marginRight: 0,
				marginLeft: 0
			},
			toolbar: {
				height: 40,
				minHeight: 40
			},
			caption: {
				fontSize: mediumFontSize,
				color: greyDark,
				fontWeight: normal
			},
			actions: {
				color: greyDark
			}
		},
		MuiDialog: {
			paper: {
				border: `2px solid ${greyLight}`
			}
		},
		MuiDialogTitle: {
			root: {
				padding: "17px 20px",
				backgroundColor: white,

				"&.xs-error-header": {
					backgroundColor: redDanger
				},
				"&.xs-primary": {
					backgroundColor: blueDark,
					color: white
				},
				"&.xs-info": {
					backgroundColor: blueInfo,
					color: white
				},
				"&.xs-default": {
					backgroundColor: greyDefault,
					color: white
				},
				"&.xs-success": {
					backgroundColor: greenDark,
					color: white
				},
				"&.xs-warning": {
					backgroundColor: amberWarning,
					color: white
				},
				"&.xs-danger": {
					backgroundColor: redDanger,
					color: white
				},
				"&.xs-base": {
					backgroundColor: white,
					color: greyDark,
					borderTopRightRadius: "5px",
					borderTopLeftRadius: "5px"
				}
			}
		},
		MuiDialogContent: {
			root: {
				padding: "0px"
			}
		},
		MuiDialogActions: {
			action: {
				margin: "0px"
			}
		},
		MuiTab: {
			root: {
				textTransform: "none",
				minWidth: "75px !important",
				minHeight: "42px",
				marginRight: "5px"
			},
			selected: {
				borderTopLeftRadius: "5px",
				borderTopRightRadius: "5px",
				"&.xs-tab": {
					borderTop: `1px solid ${greyLight}`, //"1px solid #ccd1d9",
					borderRight: `1px solid ${greyLight}`,
					borderLeft: `1px solid ${greyLight}`,
					borderBottom: `1px solid ${snowWhite}`
				}
			},
			label: {
				fontSize: `${mediumFontSize} !important`,
				fontWeight: normal
			},
			textColorInherit: {
				opacity: 1
			},
			labelContainer: {
				paddingLeft: "10px !important",
				paddingRight: "10px !important",
				paddingBottom: "3px !important",
				paddingTop: "3px !important"
			}
		},
		MuiTabs: {
			root: {
				// backgroundColor: "white",
				minHeight: "36px",
				"&.filled": {
					backgroundColor: snowWhite
				}
			},
			indicator: {
				display: "none"
			}
		},
		// MuiGrid: {
		//   item: {
		//     padding: "0 5px"
		//   }
		// },
		MuiSelect: {
			root: {
				cursor: "pointer"
			},
			selectMenu: {
				lineHeight: "34px",
				borderRadius: "5px"
			},
			select: {
				"&:focus": {
					borderRadius: "5px"
				}
			}
		},
		MuiMenuItem: {
			root: {
				height: "20px",
				paddingTop: "5px",
				paddingBottom: "5px",
				fontSize: mediumFontSize
			}
		},
		MuiListItem: {
			default: {
				paddingTop: "8px",
				paddingBottom: "8px"
			}
		},
		MuiChip: {
			root: {
				height: "34px",
				minWidth: "75px",
				fontSize: smallfontSize,
				margin: "2px 5px",
				backgroundColor: greyLight,
				color: black
			}
		},
		MuiBadge: {
			badge: {
				zIndex: 0
			}
		},
		MuiPaper: {
			root: {
				outline: "none"
			}
		},
		MuiTooltip: {
			tooltip: {
				backgroundColor: black,
				color: snowWhite,
				fontSize: smallfontSize,
				padding: "7px 20px"
			}
		}
	}
})

export default muiTheme
