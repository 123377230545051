"use strict"

import {observable, action} from "mobx"
import config from "../../../global/config/settings"
import DataStore from "./DataStore"
import UIStore from "./UIStore"
import {
	/*getSelectedOrgUnitID,*/ getUserPersonnelID,
	getSelectedOrgUnit,
	getUser
} from "../../../global/helpers/actions"
import {GWApiCall} from "../../../global/helpers/api"
import GlobalStore from "../../../global/store/GlobalStore"
import WarningStore from "../../../global/store/WarningStore"

class EhealthConsentDialogStore {
	@observable isOpen = false
	@observable sensitivity = "3"
	@observable hasAgreement = false
	@observable oau = false
	@observable oau = false
	@observable oup = false
	@observable phr = false
	@observable liek = false
	@observable rec = false
	@observable disp = false
	@observable psu = false
	@observable dps = false
	@observable lbz = false
	@observable lbv = false
	@observable vvp = false
	@observable vla = false
	@observable vlu = false

	warningText = ""

	@action open() {
		this.isOpen = true
	}

	@action handleChange(key, value) {
		this[key] = value
	}

	@action requestConsent(form) {
		UIStore.isFormSaving = true
		const formVals = form.values()

		const selectedOrgUnit = getSelectedOrgUnit()
		const user = getUser()
		const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
		const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
		const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
		const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
		const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

		const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
			? DataStore.patientDTO.get("patient").validInfo.client
			: null
		const JRUZIDObj = isSafe(patientClientInfo.identifiers)
			? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
			: null

		let accessTypes = []
		const varNames = ["vvp", "oau", "oup", "phr", "liek", "rec", "disp", "psu", "dps", "lbz", "lbv", "vla", "vlu"]

		varNames.forEach((name) => {
			if (this[name]) {
				accessTypes.push(name.toUpperCase())
			}
		})

		let request = {
			ClassName: "ZapisSuhlasOsobyPrePZS",
			PresenceConfirmationLevel: formVals.auth,
			Sensitivity: +this.sensitivity,
			SignedAgreement: this.hasAgreement,
			AccessTypes: accessTypes,
			AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
			AmbeePersonnelId: getUserPersonnelID(),
			PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
			AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
			OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
			PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
			PersonnelExpertiseJRUZVersion: specVer,
			AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
			AmbeePatientId: DataStore.patientDTO.get("patientID"),
			PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
			PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier
		}

		// const providerID = getSelectedOrgUnitID()

		if (formVals.auth == "ChallengeResponse") {
			WarningStore.open("Sledujte displej čítačky a stlačte OK")
		}

		GWApiCall({
			method: "POST",
			uri: `${config.GW_BASE_URL}/WriteConsent`,
			body: JSON.stringify(request)
		})
			.then((resp) => resp.json())
			.then((data) => {
				if (isNotEmpty(data.ErrorCode) || isNotEmpty(data.ErrorMessage)) {
					UIStore.isFormSaving = false
					WarningStore.open(`Nepodarilo sa zapísať súhlas! <br/> ${data.ErrorCode} <br/> ${data.ErrorMessage}`)
				} else {
					form.reset()
					this.close()
					WarningStore.close()
					UIStore.isFormSaving = false
					GlobalStore.setNotificationMessage("Common.label.writeConsentSucc")
				}
			})
			.catch(() => {
				UIStore.isFormSaving = false
				WarningStore.open("Nepodarilo sa zapísať súhlas!")
			})
	}

	@action close() {
		this.isOpen = false

		this.sensitivity = "3"
		this.hasAgreement = false
		this.oau = false
		this.oau = false
		this.oup = false
		this.phr = false
		this.liek = false
		this.rec = false
		this.disp = false
		this.psu = false
		this.dps = false
		this.lbz = false
		this.lbv = false
		this.vvp = false
		this.vla = false
		this.vlu = false

		// this.dateFrom = ""
		// this.dateTo = ""
		// this.examinationTypes = []
		// this.onlyOwnedRecords = false
		// this.isFullyDescribed = true
		// this.sensitivity = "3"
		// this.name = ""
		// this.professionalFocus = ""
		// this.technicalDepartment = ""
	}
}

var singleton = new EhealthConsentDialogStore()
export default singleton
