"use strict"

import {action} from "mobx"
import DataStore from "../stores/DataStore"

class PatientPrescriptionContainerStore {
	cardRef = null
	ids = []

	@action represcribe(recipes) {
		this.ids = recipes.map((i) => {
			return i.record_id
		})
		//znovupredpisat pri uz otvorenom formulari
		if (isSafe(this.cardRef)) {
			let ids = this.ids.slice()
			this.cardRef.clearAllPrescriptions(() => {
				this.cardRef.addForm(recipes.length - 1, ids)
			})
			this.ids = []
		}
		DataStore.clearCheckedEHR()
	}

	//znovupredpisat pri uz zatvorenom formulari teda caka kym sa otvori ziska ref a naplni formulare
	@action represcribeIdsToForms() {
		if (isSafe(this.ids) && this.ids.length) {
			let ids = this.ids.slice()
			this.cardRef.clearAllPrescriptions(() => {
				this.cardRef.addForm(ids.length - 1, ids)
			})
		}
		this.ids = []
	}

	@action clearPrescriptions() {
		if (isSafe(this.cardRef)) {
			this.cardRef.clearAllPrescriptions()
		}
	}

	@action setFormIds(ids) {
		Object.keys(this.cardRef.formRefs).forEach((key, idx) => {
			this.cardRef.formRefs[key].$("recordId").set("value", ids[+idx])
		})
		this.fixFormRepeatType()
	}

	@action fixFormRepeatType() {
		Object.keys(this.cardRef.formRefs).forEach((key) => {
			let form = this.cardRef.formRefs[key]
			if (form.$("repeatType").value != 1) {
				form.$("repeatTypeSecondary").value = form.$("repeatType").value
				form.$("repeatType").set("value", "0")
			}
		})
	}

	@action isGoingToReprescribe() {
		if (isSafe(this.ids) && this.ids.length) {
			return true
		} else {
			return false
		}
	}
}

var singleton = new PatientPrescriptionContainerStore()
export default singleton
