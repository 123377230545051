import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"

import fields from "./dispensaryCareFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import SearchSelect from "../../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsAutocompleteLocal from "../../../../../../global/ui/xsInput/xsAutocompleteLocal"

import GlobalStore from "../../../../../../global/store/GlobalStore"
import RegisterCacheStore from "../../../../../../global/store/RegistersCacheStore"

@injectIntl
@observer
export default class DispensaryCareTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		this.sortedCountries = isSafe(GlobalStore.CL["countries"])
			? GlobalStore.CL["countries"].slice().sort((a, b) => a.item_order - b.item_order)
			: []

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		if (isSafe(this.form.$("insuranceTypeCode").value) && this.form.$("insuranceTypeCode").value == "VZP") {
			if (isEmpty(this.form.$("nameForm").value) && isNotEmpty(this.form.$("name").value)) {
				this.form.$("nameForm").value = this.form.$("name").value
			}
			if (isEmpty(this.form.$("identifierForm").value) && isNotEmpty(this.form.$("identifier").value)) {
				this.form.$("identifierForm").value = this.form.$("identifier").value
			}
			if (isEmpty(this.form.$("addressForm").value) && isNotEmpty(this.form.$("address").value)) {
				this.form.$("addressForm").value = this.form.$("address").value
			}
		}

		if (isSafe(this.form.$("insuranceTypeCode").value) && this.form.$("insuranceTypeCode").value == "CUDZ") {
			if (isEmpty(this.form.$("eu_name").value) && isNotEmpty(this.form.$("name").value)) {
				this.form.$("eu_name").value = this.form.$("name").value
			}
			if (isEmpty(this.form.$("eu_sex_verbal").value) && isNotEmpty(this.form.$("sex_verbal").value)) {
				this.form.$("eu_sex_verbal").value = this.form.$("sex_verbal").value
			}
			if (isEmpty(this.form.$("eu_birthdate").value) && isNotEmpty(this.form.$("birthdate").value)) {
				this.form.$("eu_birthdate").value = this.form.$("birthdate").value
			}
			if (isEmpty(this.form.$("eu_state").value) && isNotEmpty(this.form.$("citizenshipCode").value)) {
				this.form.$("eu_state").value = this.form.$("citizenshipCode").value
			}
		}
		if (isSafe(this.form.$("insurerId").value)) {
			this.form.$("insurer").value = this.form.$("insurerId").value
		}
	}

	render() {
		return (
			<div className="pa-4">
				<Grid container direction="column" align="top">
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<SearchSelect
								field={this.form.$("insurer")}
								items={RegisterCacheStore.insurersRegister}
								onChange={(val) => {
									const item = RegisterCacheStore.insurersRegister.find((x) => x._id == val)
									this.form.$("insurerId").value = item._id
									this.form.$("insurerCode").value = item.code_ext
									this.form.$("insurerName").value = item.description
								}}
							/>
						</Grid>
						<Grid item xs={5}>
							<XsInput field={this.form.$("socInsOffice")} />
						</Grid>
					</Grid>

					<div style={{marginTop: "20px", marginBottom: "10px", fontWeight: "bold"}}>OSOBNÉ ÚDAJE POISTENCA</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsInput field={this.form.$("nameForm")} />
						</Grid>
						<Grid item xs={3}>
							<XsInput field={this.form.$("identifierForm")} />
						</Grid>
						<Grid item xs={6}>
							<XsInput field={this.form.$("addressForm")} />
						</Grid>
					</Grid>

					<div style={{marginTop: "20px", marginBottom: "10px", fontWeight: "bold"}}>
						OSOBNÉ ÚDAJE POISTENCA (EURÓPSKA ÚNIA)
					</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsInput field={this.form.$("eu_name")} />
						</Grid>
						<Grid item xs={1}>
							<XsInput field={this.form.$("eu_sex_verbal")} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker field={this.form.$("eu_birthdate")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("eu_patientId")} />
						</Grid>
						<Grid item xs={4}>
							<SearchSelect field={this.form.$("eu_state")} items={this.sortedCountries} />
						</Grid>
					</Grid>

					<div style={{marginTop: "20px", marginBottom: "10px", fontWeight: "bold"}}>DISPENZÁRNA STAROSTLIVOSŤ</div>
					<Grid item xs={12} className="xs-autocomplete-element">
						<XsAutocompleteLocal
							field={this.form.$("diagClinical")}
							minLengthForSearch="1"
							data={RegisterCacheStore.diagnosisRegister}
							inputRenderer={(obj) => {
								this.form.$("diagnosisCode").value = obj.code_ext
								this.form.$("diagnosisName").value = obj.name_ext
								return obj.code_ext + " " + obj.name_ext
							}}
							saveValue={(obj) => obj.code}
							modalConfig={(textValue, clickHandler) => {
								return {
									columnDefs: {
										code_ext: {
											title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
											type: "string",
											dbName: "code_ext",
											design: {
												width: "150px"
											},
											filter: {
												gridWidth: 3,
												defaultValue: "",
												renderElement: "input"
											}
										},
										name_ext: {
											title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
											type: "string",
											dbName: "search_column",
											filter: {
												gridWidth: 9,
												defaultValue: textValue,
												renderElement: "input"
											}
										}
									},
									options: {
										showCursor: true,
										onRowClick: (dataRow) => {
											clickHandler(dataRow)
										}
									}
								}
							}}
						/>
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={2}>
							<XsDateTimePicker field={this.form.$("proposalDate")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={4}>
							<XsDateTimePicker field={this.form.$("lengthFrom")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={4}>
							<XsDateTimePicker field={this.form.$("lengthTo")} showTimeSelect={false} />
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("frequency")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={this.form.$("postponeReason")} />
					</Grid>

					<Grid item xs={2} style={{marginTop: "20px"}}>
						<XsDateTimePicker field={this.form.$("date")} showTimeSelect={false} />
					</Grid>
				</Grid>
			</div>
		)
	}
}
