import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import {Grid, TextField, Checkbox, FormControlLabel} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsInputSearchFilter from "../../../../../global/ui/xsInput/xsInputSearchFilter"
import XsDropdownList from "../../../../../global/ui/xsDropdown/xsDropdownList"

import api from "../../../actions/api"
import AgreementAttributeStore from "../../../stores/AgreementAttributeStore"
import WarningStore from "../../../../../global/store/WarningStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

@injectIntl
@observer
export default class PricePerDeliveryForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isSaving: false
		}
	}

	enableSave = () => {
		this.setState({isSaving: false})
	}

	hasDuplicityDelivery = () => {
		let hasDuplicity = false

		if (
			isSafe(AgreementAttributeStore.attributeDataJSON) &&
			AgreementAttributeStore.attributeDataJSON.hasOwnProperty(AgreementAttributeStore.typeFilter)
		) {
			Object.keys(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]).some((attrKey) => {
				let attribute = AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][attrKey]
				let existDeliveryCode = attribute.delivery._id
				let existDiagnosisCode = isSafe(attribute.diagnosis) ? attribute.diagnosis._id : ""
				let insuranceType = isSafe(attribute.insurance_type) ? attribute.insurance_type : ""

				if (
					`${AgreementAttributeStore.pricePerDeliveryFormDeliveryId}`.trim() === `${existDeliveryCode}`.trim() &&
					`${AgreementAttributeStore.pricePerDeliveryFormDiagnosisId}`.trim() === `${existDiagnosisCode}`.trim() &&
					`${AgreementAttributeStore.agreementAttributeInsuranceType}`.trim() === `${insuranceType}`.trim() &&
					AgreementAttributeStore.agreementAttributeCapitationChecked === attribute.in_capitation
				) {
					hasDuplicity = true
					return true
				}
			})
		}

		return hasDuplicity
	}

	render() {
		return (
			<Grid container direction="row" spacing={8} alignItems="flex-end">
				<Grid item xs={3}>
					<XsInputSearchFilter
						api={api.loadDeliveries}
						disabled={AgreementAttributeStore.isEditAttributeGrid}
						label={
							<span className="xs-bold">
								<FormattedMessage id="Common.label.delivery" />*
							</span>
						}
						isValid={
							isEmpty(AgreementAttributeStore.pricePerDeliveryFormDelivery) &&
							!AgreementAttributeStore.isEditAttributeGrid
						}
						minLengthForSearch={1}
						onButtonClick={(value) => delayedCallback(500, () => AgreementAttributeStore.searchDeliveries(value))}
						data={AgreementAttributeStore.deliveries}
						onChange={(value) => (AgreementAttributeStore.pricePerDeliveryFormDelivery = value)}
						onSearchClose={() => (AgreementAttributeStore.deliveries = [])}
						// defaultValue={AgreementAttributeStore.pricePerPointFormDeliveryId}
						value={AgreementAttributeStore.pricePerDeliveryFormDelivery}
						inputRenderer={(dataRow) => {
							return dataRow.code_ext + " " + dataRow.name_ext
						}}
						valueRenderer={(dataRow) => {
							return dataRow.code_ext + " " + dataRow.name_ext
						}}
						chooseItem={(value) => {
							AgreementAttributeStore.pricePerDeliveryFormDelivery = value.code_ext + " " + value.name_ext
							AgreementAttributeStore.pricePerDeliveryFormDeliveryId = value._id
						}}
						modalTitle={<FormattedMessage id="Agreement.Container.Detail.Attribute.Deliveries" />}
						modalConfig={(textValue, clickHandler) => {
							return {
								columnDefs: {
									code_ext: {
										title: <FormattedMessage id="Agreement.Container.Detail.Attribute.DeliveryCode" />,
										type: "string",
										dbName: "code_ext",
										design: {
											width: "150px"
										},
										filter: {
											gridWidth: 3,
											defaultValue: "",
											renderElement: "input"
										}
									},
									name_ext: {
										title: <FormattedMessage id="Agreement.Container.Detail.Attribute.DeliveryName" />,
										type: "string",
										dbName: "search_column",
										filter: {
											gridWidth: 9,
											defaultValue: textValue,
											renderElement: "input"
										}
										// sortable: true
									}
								},
								options: {
									// selectRow: true,
									showCursor: true,
									onRowClick: (dataRow) => {
										clickHandler(dataRow)
									}
								}
							}
						}}
					/>
				</Grid>
				<Grid item xs={3}>
					<XsInputSearchFilter
						api={api.loadDiagnosisCl}
						disabled={AgreementAttributeStore.isEditAttributeGrid}
						label={
							<span>
								<FormattedMessage id="Agreement.Container.Detail.Attribute.Diagnosis" />
							</span>
						}
						// isValid={isEmpty(AgreementAttributeStore.pricePerDeliveryFormDiagnosis)}
						minLengthForSearch={3}
						onButtonClick={(value) => delayedCallback(500, () => AgreementAttributeStore.searchDiagnosis(value))}
						data={AgreementAttributeStore.diagnosis}
						onChange={(value) => (AgreementAttributeStore.pricePerDeliveryFormDiagnosis = value)}
						onSearchClose={() => (AgreementAttributeStore.diagnosis = [])}
						value={AgreementAttributeStore.pricePerDeliveryFormDiagnosis}
						inputRenderer={(dataRow) => {
							return dataRow.code_ext + " " + dataRow.name_ext
						}}
						valueRenderer={(dataRow) => {
							return dataRow.code_ext + " " + dataRow.name_ext
						}}
						chooseItem={(value) => {
							AgreementAttributeStore.pricePerDeliveryFormDiagnosis = value.code_ext + " " + value.name_ext
							AgreementAttributeStore.pricePerDeliveryFormDiagnosisId = value._id
						}}
						onBlur={(e) => {
							if (isEmpty(e.target.value)) {
								AgreementAttributeStore.pricePerDeliveryFormDiagnosisId = ""
							}
						}}
						modalTitle={<FormattedMessage id="Common.label.diagnosis" />}
						modalConfig={(textValue, clickHandler) => {
							return {
								columnDefs: {
									code_ext: {
										title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
										type: "string",
										dbName: "code_ext",
										design: {
											width: "150px"
										},
										filter: {
											gridWidth: 3,
											defaultValue: "",
											renderElement: "input"
										}
									},
									name_ext: {
										title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
										type: "string",
										dbName: "search_column",
										filter: {
											gridWidth: 9,
											defaultValue: textValue,
											renderElement: "input"
										}
									}
								},
								options: {
									showCursor: true,
									onRowClick: (dataRow) => {
										clickHandler(dataRow)
									}
								}
							}
						}}
					/>
				</Grid>
				<Grid item xs={2}>
					<XsDropdownList
						emptyValue
						disabled={AgreementAttributeStore.isEditAttributeGrid}
						label={
							<span>
								<FormattedMessage id="Agreement.Container.Detail.Attribute.InsuranceType" />
							</span>
						}
						items={GlobalStore.CL["insuranceTypes"]}
						// className={isEmpty(AgreementAttributeStore.agreementAttributeInsuranceType) ? "xs-input-error" : ""}
						renderer={(item) => {
							return item.name_ext
						}}
						column="_id"
						value={AgreementAttributeStore.agreementAttributeInsuranceType}
						chooseItem={(item) => {
							AgreementAttributeStore.agreementAttributeInsuranceType = item._id
						}}
					/>
				</Grid>
				<Grid item xs={2}>
					<TextField
						value={AgreementAttributeStore.agreementAttributeCurrency}
						disabled={AgreementAttributeStore.isEditAttributeGrid}
						onChange={(e) => (AgreementAttributeStore.agreementAttributeCurrency = e.target.value)}
						label={
							<span className="xs-bold">
								<FormattedMessage id="Agreement.Container.Detail.Attribute.Price" />*
							</span>
						}
						InputLabelProps={{
							shrink: true
						}}
						InputProps={{
							classes: {
								root:
									!isPrice(AgreementAttributeStore.agreementAttributeCurrency) &&
									!AgreementAttributeStore.isEditAttributeGrid
										? "xs-input-error"
										: ""
							}
						}}
					/>
				</Grid>
				<Grid item xs={1}>
					<FormControlLabel
						control={
							<Checkbox
								disabled={AgreementAttributeStore.isEditAttributeGrid}
								checked={AgreementAttributeStore.agreementAttributeCapitationChecked}
								onChange={(e) => (AgreementAttributeStore.agreementAttributeCapitationChecked = e.target.checked)}
							/>
						}
						label={<FormattedMessage id="Agreement.Container.Detail.Attribute.Capitation" />}
					/>
				</Grid>
				<Grid item xs={1} className="xs-form-actions">
					<XsButton
						className={AgreementAttributeStore.isEditAttributeGrid ? "xs-default xs-outline" : "xs-success xs-outline"}
						disabled={this.state.isSaving || AgreementAttributeStore.isEditAttributeGrid}
						text={<FormattedMessage id="Common.label.add" />}
						onClick={() => {
							if (
								isEmpty(AgreementAttributeStore.pricePerDeliveryFormDelivery) ||
								!isPrice(
									AgreementAttributeStore.agreementAttributeCurrency
								) /*|| isEmpty(AgreementAttributeStore.pricePerDeliveryFormDiagnosis) || isEmpty(AgreementAttributeStore.agreementAttributeInsuranceType)*/
							) {
								WarningStore.open(
									`${this.props.intl.formatMessage({id: "Patient.form.patientPrescription.invalidForm"})}`
								)
							} else if (this.hasDuplicityDelivery()) {
								WarningStore.open(
									`${this.props.intl.formatMessage({
										id: "Agreement.Container.Detail.Attribute.pricePerDelivery.duplicityDelivery"
									})}`
								)
							} else {
								this.setState({isSaving: true}, AgreementAttributeStore.saveAttributes(this.enableSave.bind()))
							}
						}}
					/>
				</Grid>
			</Grid>
		)
	}
}
