import React from "react"
import {observer} from "mobx-react"
import EmployeeCardForm from "./employeeCardForm"
import EmployeeCardContacts from "./employeeCardContacts"
import XsTabsIcon from "../../../../../global/ui/xsTabs/xsTabsIcon"
import Grid from "@material-ui/core/Grid"
import {FormattedMessage} from "react-intl"

@observer
export default class EmployeeCardContainer extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<div className="pa-8">
				<EmployeeCardForm entityId={this.props.entityId} employeeId={this.props.employeeId} />
				<Grid item xs={12}>
					<XsTabsIcon
						value="contacts"
						onChangeTab={(value) => value}
						scrollButtons={false}
						horizontal={true}
						mainClass="xs-patient-info-tab"
						background="#f0f3f8"
						headerClassName="filled"
						tabs={[
							{
								label: (
									<div>
										<FormattedMessage id="PatientDetail.cards.contacts" />
									</div>
								),
								value: "contacts",
								icon: <i className="fas fa-address-book fa-lg"></i>, //<ContactsIcon />,
								content: (
									<div>
										<EmployeeCardContacts entityId={this.props.entityId} employeeId={this.props.employeeId} />
									</div>
								)
							}
						]}
					/>
				</Grid>
			</div>
		)
	}
}
