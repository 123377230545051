"use strict"

import React from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import {observer} from "mobx-react"
import moment from "moment"
import ReactTooltip from "react-tooltip"
import {Grid, InputAdornment, Hidden} from "@material-ui/core"
import classNames from "classnames"
import {getUserPersonnelOrgUnits} from "../../../../global/helpers/actions"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../global/ui/globalUISchemeBindings"

import XsButton from "../../../../global/ui/xsButton/xsButton"
import XsInput from "../../../../global/ui/xsInput/xsInput"
import XsButtonGroup from "../../../../global/ui/xsButton/xsButtonGroup"

import UIStore from "../../stores/UIStore"
import GlobalStore from "../../../../global/store/GlobalStore"
import CalendarStore from "../../stores/CalendarStore"
import AddEventDialogStore from "../../stores/AddEventDialogStore"
import OtherDayDialogStore from "../../stores/OtherDayDialogStore"
import ExportEventsToXlsDialogStore from "../../stores/ExportEventsToXlsDialogStore"
import TourStore from "../../../../global/store/TourStore"
import ExportEventsToXlsDialog from "./exportEventsToXlsDialog"

import "./xs-calendar.less"

@injectIntl
@observer
export default class CalendarHeader extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					search_column: {
						label: " "
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)
	}

	getGroupBtnLabel = () => {
		let label = ""
		switch (UIStore.calendarTimePeriod) {
			case "today":
				label = this.props.intl.formatMessage({
					id: "Common.label.today"
				})
				break
			case "week":
				label = this.props.intl.formatMessage({
					id: "Calendar.tabs.week"
				})
				break
			case "month":
				label = this.props.intl.formatMessage({
					id: "Calendar.tabs.month"
				})
				break
			case "resources":
				label = "Multi"
				break
			case "resourcesPlus":
				label = "Multi+"
				break
		}

		return label
	}

	render() {
		const {hideCalendarView, hideAddEvent, hideCounter} = this.props

		const addBtnDisabled =
			(UIStore.calendarTimePeriod == "resources" || UIStore.calendarTimePeriod == "resourcesPlus") &&
			CalendarStore.disabledMultiBtn

		const previouDataFor =
			UIStore.calendarTimePeriod === "month"
				? "previousMonth"
				: UIStore.calendarTimePeriod === "week"
				? "previousWeek"
				: "previousDay"
		const nextDataFor =
			UIStore.calendarTimePeriod === "month"
				? "nextMonth"
				: UIStore.calendarTimePeriod === "week"
				? "nextWeek"
				: "nextDay"

		let groupActions = [
			{
				label: <FormattedMessage id="Common.label.today" defaultMessage="Today" />,
				onClick: () => CalendarStore.setCalendarRange("today", true)
			},
			{
				label: <FormattedMessage id="Calendar.tabs.week" defaultMessage="Week" />,
				onClick: () => {
					CalendarStore.setCalendarRange("week")
					UIStore.weekPlusWeekend = false
				}
			},
			{
				label: <FormattedMessage id="Calendar.tabs.month" defaultMessage="Month" />,
				onClick: () => CalendarStore.setCalendarRange("month")
			}
		]

		if (getUserPersonnelOrgUnits().length > 1) {
			groupActions.push(
				{
					label: "Multi",
					onClick: () => CalendarStore.setCalendarRange("resources")
				},
				{
					label: "Multi+",
					onClick: () => CalendarStore.setCalendarRange("resourcesPlus")
				}
			)
		}

		return (
			<div className="xs-calendar-header-new xs-justify-between">
				<div className="xs-left-side">
					<div className="xs-departments">
						<XsButtonGroup
							items={
								isSafe(GlobalStore.orgunits) && GlobalStore.orgunits.length > 0
									? GlobalStore.orgunits.map((orgunit) => ({
											label: orgunit.name,
											onClick: () => GlobalStore.changeOrgUnit(orgunit)
									  }))
									: []
							}
							value={GlobalStore.currentOrgName}
							bgClass="bgBlueDark"
							textClass="snowWhite"
						/>
					</div>
					<div className="xs-select-day">
						<div
							data-tip
							data-for={previouDataFor}
							className="xs-previous-day"
							onClick={() => {
								if (UIStore.calendarTimePeriod === "month") {
									CalendarStore.onLoadPeviousMonth()
								} else if (UIStore.calendarTimePeriod === "week") {
									CalendarStore.onLoadPeviousWeek()
								} else {
									OtherDayDialogStore.handleDateTimePickerChange(moment(CalendarStore.selectedDay).add(-1, "days"))
								}
							}}
						>
							<i className="fal fa-chevron-left"></i>
						</div>
						<div className="xs-other-day" onClick={() => OtherDayDialogStore.open()}>
							<span className="xs-day-title">{moment(CalendarStore.selectedDay).format("dddd")},</span>
							<span>{moment(CalendarStore.selectedDay).format("MMM DD, YYYY")}</span>
							<i className="xs-calendar-icon fal fa-calendar-alt" data-tip data-for="showCalendar"></i>
							{!hideCounter && (
								<div className="xs-badge" data-tip data-for="countOrderPatient">
									{CalendarStore.countEvents}
								</div>
							)}
						</div>
						<div
							data-tip
							data-for={nextDataFor}
							className="xs-next-day"
							onClick={() => {
								if (UIStore.calendarTimePeriod === "month") {
									CalendarStore.onLoadNextMonth()
								} else if (UIStore.calendarTimePeriod === "week") {
									CalendarStore.onLoadNextWeek()
								} else {
									OtherDayDialogStore.handleDateTimePickerChange(moment(CalendarStore.selectedDay).add(1, "days"))
								}
							}}
						>
							<i className="fal fa-chevron-right"></i>
						</div>
					</div>
				</div>
				<Hidden only={["lg", "xl", "md"]}>
					<div className="xs-right-side">
						<XsButtonGroup
							items={groupActions}
							value={this.getGroupBtnLabel()}
							bgClass="bgBlueDark"
							textClass="snowWhite"
						/>
						{!hideAddEvent && (
							<div className="xs-add-event tourAddEvent ml-3" data-tip data-for="disabledAddEvent">
								<XsButton
									className={classNames({"xs-primary": !addBtnDisabled, "xs-default xs-outline": addBtnDisabled})}
									icon={<i className="fal fa-plus fa-lg" />}
									text={<FormattedMessage id="Calendar.header.addEvent" />}
									disabled={addBtnDisabled}
									onClick={() => {
										AddEventDialogStore.open(true)
										if (TourStore.runSaveEvent_tour) {
											TourStore.handleNextSaveEventTour()
										}
										if (TourStore.runSaveNewEvent_tour) {
											TourStore.handleNextSaveNewEventTour()
										}
									}}
								/>
							</div>
						)}
					</div>
				</Hidden>

				<Hidden only={["xs", "sm"]}>
					<div className="xs-event-filter">
						{(UIStore.calendarTimePeriod == "today" ||
							UIStore.calendarTimePeriod == "tomorrow" ||
							UIStore.calendarTimePeriod == "otherDay" ||
							UIStore.calendarTimePeriod == "resources" ||
							UIStore.calendarTimePeriod == "resourcesPlus") && (
							<Grid container wrap="nowrap">
								<Grid item>
									<XsInput
										white
										clearText={() => CalendarStore.eventFilter("")}
										field={this.form.$("search_column")}
										placeholder={"Vyhľadávanie..."}
										onChange={(val) => CalendarStore.eventFilter(val)}
										startAdornment={
											<InputAdornment position="start" className="xs-search-adornment">
												<i className="fal fa-search pt-1 pl-2" />
											</InputAdornment>
										}
									/>
								</Grid>
							</Grid>
						)}
					</div>
					<div className="xs-right-side">
						<div className="xs-export-to-excel mr-3">
							<XsButton
								className="xs-primary"
								icon={<i className="fal fa-file-excel fa-lg" />}
								text={<FormattedMessage id="Common.label.export" />}
								onClick={() => ExportEventsToXlsDialogStore.open()}
							/>
						</div>
						{!hideCalendarView && (
							<div className="xs-calendar-view">
								<div
									className={classNames("today", UIStore.calendarTimePeriod === "today" && "xs-active")}
									onClick={() => CalendarStore.setCalendarRange("today", true)}
								>
									<FormattedMessage id="Common.label.today" defaultMessage="Today" />
								</div>
								{/* <div
									className={classNames("week", UIStore.calendarTimePeriod === "week" && "xs-active")}
									onClick={() => {
										CalendarStore.setCalendarRange("week")
										UIStore.weekPlusWeekend = false
									}}
								>
									<FormattedMessage id="Calendar.tabs.weekShortcut" defaultMessage="Week" />
								</div>
								<Tooltip
									title={this.props.intl.formatMessage({
										id: "Common.label.weekPlusWeekend"
									})}
								>
									<div
										style={UIStore.weekPlusWeekend ? {borderLeft: "1px solid #e5e7ea"} : {}}
										className={classNames(
											"week nowrap weekend",
											UIStore.calendarTimePeriod === "week" && UIStore.weekPlusWeekend && "xs-active"
										)}
										onClick={() => {
											CalendarStore.setCalendarRange("week")
											UIStore.weekPlusWeekend = true
										}}
									>
										<FormattedMessage id="Calendar.tabs.weekPlusWeekend" defaultMessage="Week" />
									</div>
								</Tooltip> */}
								{/* <div
									className={classNames("month", UIStore.calendarTimePeriod === "month" && "xs-active")}
									onClick={() => CalendarStore.setCalendarRange("month")}
								>
									<FormattedMessage id="Calendar.tabs.monthShortcut" defaultMessage="Month" />
								</div> */}
								{/* {getUserPersonnelOrgUnits().length > 1 && (
									<div
										className={classNames("resources", UIStore.calendarTimePeriod === "resources" && "xs-active")}
										onClick={() => CalendarStore.setCalendarRange("resources")}
									>
										Multi
									</div>
								)} */}
								{/* {getUserPersonnelOrgUnits().length > 1 && (
									<div
										className={classNames(
											"resourcesPlus",
											UIStore.calendarTimePeriod === "resourcesPlus" && "xs-active"
										)}
										onClick={() => CalendarStore.setCalendarRange("resourcesPlus")}
									>
										Multi+
									</div>
								)} */}
							</div>
						)}
						{!hideAddEvent && (
							<div className="xs-add-event tourAddEvent" data-tip data-for="disabledAddEvent">
								<XsButton
									className={classNames({"xs-primary": !addBtnDisabled, "xs-default xs-outline": addBtnDisabled})}
									icon={<i className="fal fa-plus fa-lg" />}
									text={<FormattedMessage id="Calendar.header.addEvent" />}
									disabled={addBtnDisabled}
									onClick={() => {
										AddEventDialogStore.open(true)
										if (TourStore.runSaveEvent_tour) {
											TourStore.handleNextSaveEventTour()
										}
										if (TourStore.runSaveNewEvent_tour) {
											TourStore.handleNextSaveNewEventTour()
										}
									}}
								/>
							</div>
						)}
					</div>
				</Hidden>
				{addBtnDisabled && (
					<ReactTooltip id="disabledAddEvent">
						<FormattedMessage id="Common.label.disabledAddEventTooltip" />
					</ReactTooltip>
				)}
				<ReactTooltip id={nextDataFor}>
					<FormattedMessage id={`Common.label.${nextDataFor}`} />
				</ReactTooltip>
				<ReactTooltip id={previouDataFor}>
					<FormattedMessage id={`Common.label.${previouDataFor}`} />
				</ReactTooltip>
				<ReactTooltip id="showCalendar">
					<FormattedMessage id="Common.label.showCalendar" />
				</ReactTooltip>
				<ReactTooltip id="countOrderPatient">
					<FormattedMessage id="Common.label.countOrderPatient" />
				</ReactTooltip>
				{ExportEventsToXlsDialogStore.isOpen && <ExportEventsToXlsDialog />}
			</div>
		)
	}
}
