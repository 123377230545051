//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../global/ui/globalUISchemeBindings"
import {StorageBase} from "../../../../global/storage/storageEx"
import {RESOURCES} from "../../../../global/config/constants"
import {getUser} from "../../../../global/helpers/actions"

import {Dialog, DialogContent, Grid} from "@material-ui/core"

import XsSearchSelect from "../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsButton from "../../../../global/ui/xsButton/xsButton"

import AddEventDialogStore from "../../stores/AddEventDialogStore"

@observer
export default class AddEventSelectOrgunitDialog extends React.Component {
	constructor(props) {
		super(props)
		const resourcesObj = StorageBase.getObjectByKey(RESOURCES)
		this.resources =
			isSafe(resourcesObj) && isSafe(resourcesObj[getUser().user._id]) ? resourcesObj[getUser().user._id] : null

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				AddEventDialogStore.changeOrgunitAddEvent(form)
			},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					orgunitId: {
						label: <FormattedMessage id="Common.label.selectOrgunitWhereYouWantToOrderPatient" />,
						value: isSafe(this.resources) && this.resources.length > 0 ? this.resources[0]._id : null
					},
					providerId: {}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)
	}

	render() {
		return (
			<Dialog
				id="xsAddEventSelectOrgunitDialog"
				open={AddEventDialogStore.isOpenSelectOrgunit}
				onClose={() => AddEventDialogStore.closeSelectOrgunit()}
				onEscapeKeyDown={() => AddEventDialogStore.closeSelectOrgunit()}
				// disableBackdropClick={true}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-500"
				}}
				maxWidth="md"
			>
				<DialogContent className="overflowYInherit">
					<Grid container alignItems="center" spacing={8} className="pb-2 pr-4 pl-3">
						<Grid item xs={10}>
							<XsSearchSelect
								white
								required
								// hideLabel={true}
								field={this.form.$("orgunitId")}
								items={isSafe(this.resources) ? this.resources : []}
								onChange={(val) => {
									const orgunit = this.resources.find((x) => x._id == val)
									if (isSafe(orgunit)) {
										this.form.$("providerId").value = orgunit.resource_id
									}
								}}
							/>
						</Grid>
						<Grid item xs={2} className="pt-2">
							<XsButton
								className="xs-success"
								text={<FormattedMessage id="Common.label.ok" />}
								icon={<i className="fal fa-check" />}
								onClick={this.form.onSubmit}
							/>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		)
	}
}
