/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				identifier: {
					label: <FormattedMessage id="Patient.labels.identifier" />
				},
				dateOfBirth: {
					label: <FormattedMessage id="Common.label.dateOfBirth" />,
					type: "date"
				},
				gender: {
					label: <FormattedMessage id="Common.label.gender" />,
					rules: "string|required_unless:type,PERS:PPHY"
				},
				title_before: {
					label: <FormattedMessage id="Common.label.titleBefore" />,
					rules: "string"
				},
				firstname: {
					label: <FormattedMessage id="Common.label.firstName" />,
					rules: "string|required_unless:type,PERS:PPHY"
				},
				lastname: {
					label: <FormattedMessage id="Common.label.lastName" />,
					rules: "string|required_unless:type,PERS:PPHY"
				},
				title_after: {
					label: <FormattedMessage id="Common.label.titleAfter" />,
					rules: "string"
				},
				street: {
					label: <FormattedMessage id="Common.label.street" />,
					rules: "string"
				},
				houseNumber: {
					label: <FormattedMessage id="Common.label.number" />,
					rules: "string"
				},
				city: {
					label: <FormattedMessage id="Common.label.city" />,
					rules: "string"
				},
				zip: {
					label: <FormattedMessage id="Common.label.zip" />,
					rules: "string"
				},
				mobile: {
					label: <FormattedMessage id="Patient.form.contactForm.mobile" defaultMessage="Mobile" />,
					placeholder: "+421999000000",
					rules: "string"
				},
				email: {
					label: <FormattedMessage id="Common.label.email" defaultMessage="Email" />,
					rules: "string"
				},
				phone: {
					label: <FormattedMessage id="Patient.form.contactForm.phone" defaultMessage="Phone" />,
					placeholder: "+421999000000",
					rules: "string"
				},
				description: {
					label: <FormattedMessage id="Common.label.description" defaultMessage="Description" />,
					rules: "string"
				},
				type: {
					label: <FormattedMessage id="Patient.form.patientrecord.diagnosistype" defaultMessage="Type" />,
					rules: "string|required"
					// value: "ICE"
				},
				doctorPersonnelExpertiseId: {
					label: <FormattedMessage id="Patient.form.patientcontactperson.type.PPhy" />,
					rules: "string|required_if:type,PERS:PPHY"
				},
				specRelID: {},
				doctorPersonnelId: {},
				doctorExpertiseId: {}
			}
		}
	}
}
