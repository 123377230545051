"use strict"

import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {FormattedMessage} from "react-intl"

import bindings from "../../../../../global/ui/globalUISchemeBindings"

import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsTextArea from "../../../../../global/ui/xsTextArea/xsTextArea"
import Grid from "@material-ui/core/Grid"
import Popover from "@material-ui/core/Popover"

import fields from "./templateFormFields"
import dataBindings from "./templateBinding"

import {getSelectedOrgUnitID} from "../../../../../global/helpers/actions"

import DataStore from "../../../stores/DataStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import {insertFormDataByScheme} from "../../../../../global/helpers/bindings"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

@observer
export default class TemplateForm extends React.Component {
	constructor(props) {
		super(props)

		let paramState = this

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				DataStore.templateIsOpen = false

				let typeID
				if (isNotEmpty(DataStore.templateSpecialType)) {
					typeID = DataStore.templateSpecialType
				} else if (isNotEmpty(props.type)) {
					typeID = props.type
				} else {
					typeID = "AMB"
				}

				insertFormDataByScheme(
					form,
					dataBindings,
					{
						scope: form.$("scope").value ? "Pr" : "Pu",
						orgUnitId: getSelectedOrgUnitID(),
						typeID: typeID,
						id: isSafe(DataStore.templateData) ? DataStore.templateData._id : "",
						active: !DataStore.deletingTemplate
					},
					() => {
						DataStore.templateData = null
						DataStore.templateDataLoaded = false
						DataStore.deletingTemplate = false
						form.reset()
						DataStore.loadTemplatesSync(
							isSafe(props.specialLoadType) ? props.specialLoadType : props.type,
							isNotEmpty(props.specialName) ? props.specialName : null
						)
					}
				)
				paramState.setState({
					isFormSaving: false
				})
			},
			onError() {
				paramState.setState({
					isFormSaving: false
				})

				// FIX ME ERROR MESSAGE
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		this.state = {
			isFormSaving: false
		}
	}

	componentDidMount() {
		DataStore.templateIsMounted = true
	}

	componentWillUnmount() {
		DataStore.templateIsMounted = false
	}

	deleteTemplate = () => {
		this.form.onSubmit(GlobalStore.confirmationDialogParams.form)
	}

	delaySubmit = (e) => {
		delayedCallback(500, () => {
			this.form.onSubmit(e)
		})
	}

	render() {
		if (
			isSafe(this.props.keyVal) &&
			isSafe(DataStore.templateDataKeyVal) &&
			this.props.keyVal != DataStore.templateDataKeyVal
		) {
			return null
		}
		if (isSafe(DataStore.templateData) && !DataStore.templateDataLoaded) {
			DataStore.insertDataToTemplateForm(this.form)
		}
		if (isNotEmpty(DataStore.templateSourceText)) {
			if (this.props.type == "RINDIVID") {
				this.form.$("templateStream").set(htmlToPlain(DataStore.templateSourceText))
			} else {
				this.form.$("templateStream").set(DataStore.templateSourceText.replace(/\\\"/g, '"')) // eslint-disable-line
			}
		}
		return (
			<div>
				<Popover
					open={DataStore.templateIsOpen}
					anchorEl={DataStore.templateAnchorEl}
					onClose={() => (
						(DataStore.templateIsOpen = false),
						this.form.reset(),
						(DataStore.templateDataLoaded = false),
						(DataStore.templateData = null),
						(DataStore.deletingTemplate = false),
						(DataStore.templateSourceText = null),
						(DataStore.templateDataKeyVal = null)
					)}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left"
					}}
				>
					<div style={{padding: "10px 20px"}}>
						<form>
							<Grid container direction="column">
								<Grid item xs={12}>
									<Grid container direction="row" alignItems="baseline">
										<Grid item xs={8}>
											<XsInput field={this.form.$("name")} />
										</Grid>
										<Grid item xs={4} style={{paddingLeft: "15px", textAlign: "right"}}>
											<XsCheckbox field={this.form.$("scope")} />
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<XsTextArea
										keyVal={this.props.keyVal}
										field={this.form.$("templateStream")}
										className="xs-height-200"
										maxLength={this.props.type == "AMB" ? 85000 : 1000}
										hideToolbar={this.props.type == "RINDIVID"}
									/>
								</Grid>
							</Grid>
							<Grid container direction="row" justify="space-between">
								<Grid item>
									<XsButton
										disabled={this.state.isFormSaving == true}
										className={this.state.isFormSaving == true ? "xs-default" : "xs-success"}
										text={<FormattedMessage id="Common.label.save" />}
										onClick={(e) => {
											DataStore.deletingTemplate = false
											this.setState(
												{
													isFormSaving: true
												},
												this.delaySubmit(e)
											)
										}}
									/>
								</Grid>
								<Grid item>
									<XsButton
										className="xs-danger xs-outline"
										text={<FormattedMessage id="Common.label.cancel" />}
										onClick={() => {
											DataStore.deletingTemplate = false
											this.form.reset()
											DataStore.closeTemplateForm()
											DataStore.templateDataLoaded = false
											DataStore.templateData = null
											DataStore.deletingTemplate = false
											DataStore.templateSourceText = null
										}}
									/>

									{DataStore.templateDataLoaded && (
										<XsButton
											className="xs-danger ml-4"
											text={<FormattedMessage id="Common.label.delete" />}
											onClick={(e) => {
												DataStore.deletingTemplate = true
												GlobalStore.openConfirmationDialog("xsDeleteTemplate", {form: e})
											}}
										/>
									)}
								</Grid>
							</Grid>
						</form>
					</div>
				</Popover>
				<XsConfirmationDialog
					name="xsDeleteTemplate"
					title={<FormattedMessage id="Common.label.warning" />}
					text={<FormattedMessage id="Patient.form.template.deleteTemplateMessage" />}
					onConfirmation={() => this.deleteTemplate()}
				/>
			</div>
		)
	}
}
