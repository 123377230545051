import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import {Grid, TextField} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsDropdownList from "../../../../../global/ui/xsDropdown/xsDropdownList"

import AgreementAttributeStore from "../../../stores/AgreementAttributeStore"
import WarningStore from "../../../../../global/store/WarningStore"

@injectIntl
@observer
export default class PriceLimitPerHCTypeForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isSaving: false
		}
	}

	enableSave = () => {
		this.setState({isSaving: false})
	}

	render() {
		return (
			<Grid container>
				<Grid item xs={12}>
					<Grid container direction="row" spacing={8} alignItems="flex-end">
						<Grid item xs={3}>
							<XsDropdownList
								disabled={AgreementAttributeStore.isEditAttributeGrid}
								label={
									<span className="xs-bold">
										<FormattedMessage id="Agreement.Container.Detail.Attribute.TypeZS" />*
									</span>
								}
								items={AgreementAttributeStore.healthTypes}
								className={
									isEmpty(AgreementAttributeStore.agreementAttributePricePerLimitType) &&
									!AgreementAttributeStore.isEditAttributeGrid
										? "xs-input-error"
										: ""
								}
								renderer={(item) => {
									return <span>{item.code_ext + " " + item.name_ext}</span>
								}}
								column="_id"
								value={AgreementAttributeStore.agreementAttributePricePerLimitType}
								chooseItem={(item) => {
									AgreementAttributeStore.agreementAttributePricePerLimitType = item._id
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								disabled={AgreementAttributeStore.isEditAttributeGrid}
								value={AgreementAttributeStore.agreementAttributeCurrency}
								onChange={(e) => (AgreementAttributeStore.agreementAttributeCurrency = e.target.value)}
								label={
									<span className="xs-bold">
										<FormattedMessage id="Agreement.Container.Detail.Attribute.Currency" />*
									</span>
								}
								InputLabelProps={{
									shrink: true
								}}
								InputProps={{
									classes: {
										root:
											!isPrice(AgreementAttributeStore.agreementAttributeCurrency) &&
											!AgreementAttributeStore.isEditAttributeGrid
												? "xs-input-error"
												: ""
									}
								}}
							/>
						</Grid>
						<Grid item xs={6} className="xs-form-actions">
							<XsButton
								className={
									AgreementAttributeStore.isEditAttributeGrid ? "xs-default xs-outline" : "xs-success xs-outline"
								}
								disabled={this.state.isSaving || AgreementAttributeStore.isEditAttributeGrid}
								text={<FormattedMessage id="Common.label.add" />}
								onClick={() => {
									if (
										isEmpty(AgreementAttributeStore.agreementAttributePricePerLimitType) ||
										!isPrice(AgreementAttributeStore.agreementAttributeCurrency)
									) {
										WarningStore.open(
											`${this.props.intl.formatMessage({id: "Patient.form.patientPrescription.invalidForm"})}`
										)
									} else {
										this.setState({isSaving: true}, AgreementAttributeStore.saveAttributes(this.enableSave.bind()))
									}
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		)
	}
}
