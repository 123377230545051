"use strict"

import React from "react"
import {observer} from "mobx-react"

import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsChipForm from "../../../../../global/ui/xsChipForm/xsChipForm"
import XsChip from "../../../../../global/ui/xsChip/xsChip"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"

import PatientOperationStore from "../../../stores/PatientOperationStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import api from "../../../actions/api"

import AddIcon from "@material-ui/icons/Add"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import {FormattedMessage, injectIntl} from "react-intl"
import {getSelectedOrgUnitID} from "../../../../../global/helpers/actions"
import moment from "moment"
import WarningStore from "../../../../../global/store/WarningStore"
import DataStore from "../../../stores/DataStore"

@injectIntl
@observer
export default class DeliveriesFormAddItems extends React.Component {
	constructor(props) {
		super(props)
	}

	insertDrugPrice = (form, drug) => {
		let tmpData = form.get("value")
		tmpData.unit = isSafe(drug.insurer_bs_unit_price) ? +drug.insurer_bs_unit_price : ""
		tmpData.unitEndText = isSafe(drug.bs_unit) ? drug.bs_unit : ""
		form.set(tmpData)
	}

	insertDeliveryPrice = async (form, priceFromCl) => {
		let tmpData = form.get("value")
		if (isNotEmpty(tmpData.product)) {
			let price = await api
				.getDeliveryItemPrice(tmpData.product, {
					org_unit_id: getSelectedOrgUnitID(),
					insurer_id: DataStore.patientDTO.get("insurerId"),
					date: moment().format("YYYY-MM-DD")
				})
				.call()
			if (isSafe(price)) {
				tmpData.unit = price.price_in_currency
			} else {
				tmpData.unit = priceFromCl
			}
		}
		if (isEmpty(tmpData.quantity)) {
			tmpData.quantity = "1"
		}
		form.set(tmpData)
	}

	handleTypeChange = (form, val) => {
		let tmpData = form.get("value")
		tmpData.product = ""
		tmpData.quantity = ""
		tmpData.unit = ""
		tmpData.unitEndText = ""
		tmpData.typeNameExt =
			isSafe(GlobalStore.CL["generalSubItemType"]) &&
			isSafe(GlobalStore.CL["generalSubItemType"].find((i) => i._id == val))
				? GlobalStore.CL["generalSubItemType"].find((i) => i._id == val).name_ext
				: ""
		form.set(tmpData)
	}

	clearFormOnFieldClear = (form) => {
		let tmpData = form.get("value")
		tmpData.product = ""
		tmpData.quantity = ""
		tmpData.unit = ""
		tmpData.unitEndText = ""
		form.set(tmpData)
	}

	handleInputChange = (form) => {
		let tmpData = form.get("value")
		if (isSafe(tmpData) && tmpData.product == "") {
			tmpData.quantity = ""
			tmpData.unit = ""
			form.set(tmpData)
		}
	}

	handleOtherPPBlur = (inputVal) => {
		const {index} = this.props
		if (isNotEmpty(inputVal) && inputVal.includes("SKOR")) {
			let deliveryChips = JSON.parse(this.props.deliveryFormField.get("value"))
			if (isSafe(deliveryChips) && isSafe(deliveryChips[index])) {
				let row = deliveryChips[index]
				if (row.col1CodeExt != "H0006") {
					WarningStore.open(this.props.intl.formatMessage({id: "Common.label.scoreWrongPositionWarning"}))
				}
			}
		}
	}

	handleDefaultType = (idx) => {
		if (idx == 0 && isSafe(PatientOperationStore.deliveriesChipsForm.returnFormData(this.props.fieldKey))) {
			let formData = PatientOperationStore.deliveriesChipsForm.returnFormData(this.props.fieldKey).get("value")
			if (isSafe(formData) && formData.col1 === "81461") {
				return "adigen"
			}
		}
		return "adidrug"
	}

	handleDefaultTypeNameExt = (idx) => {
		if (idx == 0 && isSafe(PatientOperationStore.deliveriesChipsForm.returnFormData(this.props.fieldKey))) {
			let formData = PatientOperationStore.deliveriesChipsForm.returnFormData(this.props.fieldKey).get("value")
			if (isSafe(formData) && formData.col1 === "81461") {
				return "Iná pripočitateľná položka"
			}
		}
		return "Liek"
	}

	handleDefaultTypeNameExt = (idx) => {
		if (idx == 0 && isSafe(PatientOperationStore.deliveriesChipsForm.returnFormData(this.props.fieldKey))) {
			let formData = PatientOperationStore.deliveriesChipsForm.returnFormData(this.props.fieldKey).get("value")
			if (isSafe(formData) && formData.col1 === "81461") {
				return "Iná pripočítateľná položka"
			}
		}
		return "Liek"
	}

	handleDefaultProduct = (idx) => {
		if (idx == 0 && isSafe(PatientOperationStore.deliveriesChipsForm.returnFormData(this.props.fieldKey))) {
			let formData = PatientOperationStore.deliveriesChipsForm.returnFormData(this.props.fieldKey).get("value")
			if (isSafe(formData) && formData.col1 === "81461") {
				return "SKOR"
			}
		}
		return ""
	}

	handleDefaultQuantity = (idx) => {
		if (idx == 0 && isSafe(PatientOperationStore.deliveriesChipsForm.returnFormData(this.props.fieldKey))) {
			let formData = PatientOperationStore.deliveriesChipsForm.returnFormData(this.props.fieldKey).get("value")
			if (isSafe(formData) && formData.col1 === "81461") {
				return 1
			}
		}
		return ""
	}

	handleDefaultUnit = (idx) => {
		if (idx == 0 && isSafe(PatientOperationStore.deliveriesChipsForm.returnFormData(this.props.fieldKey))) {
			let formData = PatientOperationStore.deliveriesChipsForm.returnFormData(this.props.fieldKey).get("value")
			if (isSafe(formData) && formData.col1 === "81461") {
				return 0
			}
		}
		return ""
	}

	getEndText = (form) => {
		let data = form.get("value")
		if (isSafe(data) && isSafe(data.unitEndText)) {
			return data.unitEndText
		} else {
			return ""
		}
	}

	renderProductCol = (field, index, fieldKey, handleChange, returnFormData) => {
		if (returnFormData(fieldKey).get("value").type == "adidrug") {
			return (
				<XsAutocomplete
					field={field}
					api={api.loadDrugs}
					addSpecialFilter={() => [
						{
							associated_column: "only_bsu",
							predicate: "=",
							values: [
								{
									id_value: 1
								}
							]
						}
					]}
					inputRenderer={(obj) =>
						obj.code_ext +
						" | " +
						obj.name_ext +
						" | " +
						obj.supplement +
						" | " +
						(obj.patient_payment ? obj.patient_payment + " | " : "") +
						(obj.insurer_payment ? obj.insurer_payment + " | " : "") +
						obj.atc +
						" | " +
						obj.active_ingredient
					}
					saveValue={(obj) => obj._id}
					postAction={(obj, loaded) => {
						if (!loaded) {
							this.insertDrugPrice(handleChange(fieldKey), obj)
						}
					}}
					onClear={() => {
						this.clearFormOnFieldClear(handleChange(fieldKey), {price: ""})
					}}
					modalConfig={(textValue, clickHandler) => {
						return {
							columnDefs: {
								code_ext: {
									title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.code_ext" />,
									type: "string",
									dbName: "code_ext"
								},
								name_ext: {
									title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.name_ext" />,
									type: "string",
									dbName: "search_column",
									filterLabel: <FormattedMessage id="Common.label.search" />,
									filter: {
										gridWidth: 3,
										defaultValue: textValue,
										renderElement: "input"
									},
									sortable: true
								},
								//Virtulany stlpec ktory sa nezobrazuje sluzi len na naviazanie filtra
								name_extFilter: {
									title: <FormattedMessage id="Delivery.list.modal.drugName" />,
									type: "string",
									dbName: "name_ext",
									filter: {
										gridWidth: 3,
										defaultValue: "",
										renderElement: "input"
									},
									design: {
										width: "0px",
										body: {
											className: "hide"
										},
										header: {
											className: "hide"
										}
									}
								},
								supplement: {
									title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />,
									type: "string",
									dbName: "supplement"
								},
								patient_payment: {
									title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.patient_payment" />,
									type: "string",
									dbName: "patient_payment"
								},
								insurer_payment: {
									title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.insurer_payment" />,
									type: "string",
									dbName: "insurer_payment"
								},
								atc: {
									title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.ATC" />,
									type: "string",
									dbName: "atc"
								},
								active_ingredient: {
									title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.active_ingredient" />,
									type: "string",
									dbName: "active_ingredient"
								}
							},
							options: {
								// selectRow: true,
								showCursor: true,
								onRowClick: (dataRow) => {
									clickHandler(dataRow)
								},
								renderHeaderAsFirstRow: true,
								row_count_full: 100
							},
							filterValue: [
								{
									associated_column: "only_bsu",
									predicate: "=",
									values: [
										{
											id_value: 1
										}
									]
								}
							]
						}
					}}
				/>
			)
		} else if (returnFormData(fieldKey).get("value").type == "adicntr") {
			return (
				<XsAutocomplete
					field={field}
					api={api.loadDeliveryItemsWithPrice}
					filterValue="id"
					// addSpecialFilter={() => [
					// 	{
					// 		associated_column: "only_bsu",
					// 		predicate: "=",
					// 		values: [
					// 			{
					// 				id_value: 1
					// 			}
					// 		]
					// 	}
					// ]}
					inputRenderer={
						(obj) => obj.code_ext
						// +
						// " | " +
						// obj.name_ext +
						// " | " +
						// obj.supplement +
						// " | " +
						// (obj.patient_payment ? obj.patient_payment + " | " : "") +
						// (obj.insurer_payment ? obj.insurer_payment + " | " : "") +
						// obj.atc +
						// " | " +
						// obj.active_ingredient
					}
					saveValue={(obj) => {
						return obj._id
					}}
					postAction={(obj, loaded) => {
						if (!loaded) {
							this.insertDeliveryPrice(handleChange(fieldKey), obj.price_in_currency)
						}
					}}
					onClear={() => {
						this.clearFormOnFieldClear(handleChange(fieldKey), {price: ""})
					}}
					modalConfig={(textValue, clickHandler) => {
						return {
							columnDefs: {
								code_ext: {
									title: <FormattedMessage id="Patient.form.patientrecord.deliveryTable.code_ext" />,
									design: {
										width: "10%"
									},
									type: "string",
									dbName: "code_ext",
									filter: {
										gridWidth: 3,
										defaultValue: "",
										renderElement: "input"
									}
								},
								name_ext: {
									title: <FormattedMessage id="Common.label.name" />,
									type: "string",
									dbName: "search_column",
									design: {
										width: "90%"
									},
									filter: {
										gridWidth: 3,
										defaultValue: textValue,
										renderElement: "input"
									}
								}
							},
							options: {
								showCursor: true,
								onRowClick: (dataRow) => {
									clickHandler(dataRow)
								}
							}
						}
					}}
				/>
			)
		} else if (returnFormData(fieldKey).get("value").type == "adidev") {
			return (
				<XsAutocomplete
					field={field}
					api={api.loadMedDev}
					inputRenderer={(obj) => obj.code_ext + " | " + obj.name_ext + " | " + obj.supplement}
					saveValue={(obj) => obj._id}
					postAction={(obj, loaded) => {
						if (!loaded) {
							this.insertDrugPrice(handleChange(fieldKey), obj)
						}
					}}
					onClear={() => {
						this.clearFormOnFieldClear(handleChange(fieldKey), {price: ""})
					}}
					modalConfig={(textValue, clickHandler) => {
						return {
							columnDefs: {
								code_ext: {
									title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.code_ext" />,
									type: "string",
									dbName: "code_ext"
								},
								name_ext: {
									title: <FormattedMessage id="Common.label.medDev" />,
									type: "string",
									dbName: "search_column",
									filter: {
										gridWidth: 3,
										defaultValue: textValue,
										renderElement: "input"
									},
									sortable: true
								},
								supplement: {
									title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />,
									type: "string",
									dbName: "supplement"
								},
								patient_payment: {
									title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.patient_payment" />,
									type: "string",
									dbName: "patient_payment"
								},
								insurer_payment: {
									title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.insurer_payment" />,
									type: "string",
									dbName: "insurer_payment"
								}
							},
							options: {
								// selectRow: true,
								showCursor: true,
								onRowClick: (dataRow) => {
									clickHandler(dataRow)
								},
								renderHeaderAsFirstRow: true
							}
						}
					}}
				/>
			)
		} else {
			return (
				<XsInput
					field={field}
					onChange={() => {
						this.handleInputChange(returnFormData(fieldKey))
					}}
					onBlur={(val) => {
						this.handleOtherPPBlur(val.value)
					}}
				/>
			)
		}
	}

	render() {
		const itemsText = this.props.intl.formatMessage({id: "Delivery.List.AddItems"})
		return (
			<XsChipForm
				ref={(ref) => (PatientOperationStore.deliveriesAddItemChipsForm[this.props.fieldKey] = ref)}
				label={itemsText}
				configuration={{
					header: {
						chipsRenderer: (key, dataRow, clickHandler) => (
							<XsChip key={key} label={dataRow["typeNameExt"]["value"]} onClick={(e) => clickHandler(false, e, key)} />
						),
						addButtonRenderer: (clickHandler) => (
							<XsIconButton className="xs-default xs-outline" icon={<AddIcon />} onClick={clickHandler} />
						)
					},
					columns: [
						{
							name: "type",
							label: <FormattedMessage id="Delivery.List.Type" />,
							width: 3,
							defaultFocus: true,
							validationRule: "required|string",
							renderer: (field, index, fieldKey, handleChange) => (
								<SearchSelect
									field={field}
									items={GlobalStore.CL["generalSubItemType"]}
									onChange={(e) => {
										this.handleTypeChange(handleChange(fieldKey), e)
									}}
									required
								/>
							),
							defaultValue: (col, idx) => {
								return this.handleDefaultType(idx)
							}
						},
						{
							name: "typeNameExt",
							renderer: () => {},
							width: 0,
							defaultValue: (col, idx) => {
								return this.handleDefaultTypeNameExt(idx)
							}
						},
						{
							name: "product",
							label: <FormattedMessage id="Delivery.List.AddItem" />,
							width: 3,
							validationRule: "required",
							defaultValue: (col, idx) => {
								return this.handleDefaultProduct(idx)
							},
							renderer: (field, index, fieldKey, handleChange, returnFormData) =>
								this.renderProductCol(field, index, fieldKey, handleChange, returnFormData)
						},
						{
							name: "quantity",
							label: <FormattedMessage id="Delivery.List.Quantity" />,
							width: 1,
							// defaultValue: (col, idx) => { return this.handleDefaultQuantity(idx) },
							defaultValue: 1,
							validationRule: "required",
							renderer: (field) => <XsInput field={field} type="number" required />
						},
						{
							name: "unitEndText",
							renderer: () => {},
							width: 0
						},
						{
							name: "unit",
							label: <FormattedMessage id="Delivery.List.UnitPrice" />,
							width: 3,
							validationRule: "required|numeric|min:0",
							defaultValue: (col, idx) => {
								return this.handleDefaultUnit(idx)
							},
							renderer: (field, index, fieldKey, handleChange, returnFormData) => (
								<XsInput
									field={field}
									type={"number"}
									step={0.000001}
									endText={this.getEndText(returnFormData(fieldKey))}
									shortEnd={true}
									required
								/>
							)
						}
					],
					options: {
						disabled: false,
						isFormMode: false,
						showInCenter: true,
						isModalMode: true,
						onFormOpen: () => {},
						onFormClose: () => {},
						onFormSave: (vals) => {
							PatientOperationStore.deliveriesAddItemChips[this.props.fieldKey] = vals
						}
					},
					data: PatientOperationStore.deliveriesAddItemChips[this.props.fieldKey]
				}}
			/>
		)
	}
}
