"use strict"
import api from "../actions/api"
import {observable, action} from "mobx"
import config from "../../../global/config/settings"
import {GWApiCall, getFilters} from "../../../global/helpers/api"
import UIStore from "./UIStore"

class EHGWStore {
	@observable ehgwName = ""
	@observable ehgwMenu = ""
	@observable installFiles = []
	@observable logs = []
	@observable labs = []

	changedFiltersLogs = {}

	@action loadInstallFiles() {
		api
			.loadInstallFiles()
			.call()
			.then((res) => {
				this.installFiles = res.rows
			})
			.catch((err) => {
				logger("loadInstallFiles failed:", err)
			})
	}

	@action deleteInstallFile(id) {
		api
			.deleteInstallFile(id)
			.call()
			.then(() => {
				this.loadInstallFiles()
			})
			.catch(() => {
				this.loadInstallFiles()
			})
	}

	@action isChangedFilterLogs(formVals) {
		this.changedFiltersLogs = this.currFilters.description != formVals.description
	}

	@action loadLogs(form) {
		UIStore.isFormSaving = true
		let formVals = isSafe(form) ? form.values() : {}
		this.currFilters = formVals
		this.changedFiltersLogs = false
		const filter = getFilters([`Description=${isNotEmpty(formVals.login) ? formVals.description : ""}`])

		GWApiCall({
			method: "POST",
			uri: `${config.GW_BASE_URL}/GetNzisLog`,
			body: JSON.stringify(filter)
		})
			.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
			.then((data) => {
				this.logs = data.rows
				UIStore.isFormSaving = false
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}

	@action loadLabs() {
		UIStore.isFormSaving = true
		GWApiCall({
			method: "POST",
			uri: `${config.GW_BASE_URL}/GetLabLog`
		})
			.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
			.then((data) => {
				this.labs = data.rows
				UIStore.isFormSaving = false
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}
}
var singleton = new EHGWStore()
export default singleton
