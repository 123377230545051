import GlobalStore from "../store/GlobalStore"
import UIStore from "../../modules/ambulance/stores/UIStore"
import {ApiCall} from "../helpers/api"
import {isMoment} from "moment"

const parseScheme = (scheme, selector) => {
	let formFieldBindings = {}
	let descriptorIds = {}
	if (isSafe(selector) && typeof selector === "function") {
		Object.keys(scheme).forEach((attr) => {
			if (typeof scheme[attr] === "object") {
				// process FIELD attr
				if (isSafe(scheme[attr].field) && typeof scheme[attr].field === "string") {
					formFieldBindings[scheme[attr].field] = () =>
						isSafe(selector()) && isSafe(selector()[attr]) ? selector()[attr] : ""
				}
				// process CODELIST attr
				else if (
					isSafe(scheme[attr].codeList) &&
					typeof scheme[attr].codeList === "object" &&
					isSafe(scheme[attr].codeList._id) &&
					typeof scheme[attr].codeList._id === "string"
				) {
					formFieldBindings[scheme[attr].codeList._id] = () =>
						isSafe(selector()) && isSafe(selector()[attr]) ? selector()[attr]._id : ""
				}
				// process ARRAY attr
				else if (
					isSafe(scheme[attr].array) &&
					typeof scheme[attr].array === "object" &&
					isSafe(scheme[attr].array.load) &&
					typeof scheme[attr].array.load === "function" &&
					isSafe(scheme[attr].array.scheme) &&
					typeof scheme[attr].array.scheme === "object"
				) {
					const parsedSchemesFunctions = parseScheme(scheme[attr].array.scheme, () =>
						isSafe(selector()) && isSafe(selector()[attr]) ? selector()[attr].find(scheme[attr].array.load) : undefined
					)
					formFieldBindings = Object.assign({}, formFieldBindings, parsedSchemesFunctions.fields)
					if (
						isSafe(scheme[attr].array.descriptor) &&
						typeof scheme[attr].array.descriptor === "object" &&
						isSafe(scheme[attr].array.descriptor._type) &&
						isSafe(selector()) &&
						isSafe(selector()[attr])
					) {
						const objectFound = selector()[attr].find(scheme[attr].array.load)
						if (isSafe(objectFound)) {
							descriptorIds[scheme[attr].array.descriptor._type] = () => objectFound._id
							descriptorIds = Object.assign({}, parsedSchemesFunctions.ids, descriptorIds)
						}
					}
				}
				// process OBJECT attr
				else if (isSafe(scheme[attr].object && typeof scheme[attr].object === "object")) {
					let parsedSchemesFunctions = null
					if (isSafe(scheme[attr].object.scheme) && typeof scheme[attr].object.scheme === "object") {
						parsedSchemesFunctions = parseScheme(scheme[attr].object.scheme, () =>
							isSafe(selector()) ? selector()[attr] : ""
						)
						formFieldBindings = Object.assign({}, formFieldBindings, parsedSchemesFunctions.fields)
					} else {
						console.warn("[parseScheme] skip attribute scheme is not present:", attr)
					}
					if (
						isSafe(parsedSchemesFunctions) &&
						isSafe(scheme[attr].object.descriptor) &&
						typeof scheme[attr].object.descriptor === "object" &&
						isSafe(scheme[attr].object.descriptor._type) &&
						isSafe(selector()) &&
						isSafe(selector()[attr])
					) {
						descriptorIds[scheme[attr].object.descriptor._type] = () => selector()[attr]._id
						descriptorIds = Object.assign({}, parsedSchemesFunctions.ids, descriptorIds)
					} else {
						console.warn("[parseScheme] skip attribute descriptor is not present or scheme is missing:", attr)
					}
				}
				// process RELATION attr as OBJECT with #params
				else if (
					isSafe(scheme[attr].relation) &&
					typeof scheme[attr].relation === "object" &&
					isSafe(scheme[attr].relation._type) &&
					isSafe(scheme[attr].relation._id) &&
					isSafe(selector()) &&
					isSafe(selector()[attr])
				) {
					descriptorIds[scheme[attr].relation._type] = () => selector()[attr]._id
				}
				// process RELATION attr as STRING with @param contains OBJECT
				else if (
					isSafe(scheme[attr].relation) &&
					typeof scheme[attr].relation === "string" &&
					isSafe(selector()) &&
					selector()[attr] !== undefined
				) {
					if (!scheme[attr].relation.startsWith("@")) {
						console.warn("[parseScheme] skip attribute relation, must start as parameter with @ character:", attr)
					}
				} else {
					console.warn(
						"[parseScheme] attribute must be declared inside with one of [field, codeList, array, object, relation]:",
						attr
					)
				}
			} else {
				console.warn("[parseScheme] attribute in scheme must be object:", attr)
			}
		})
	} else {
		console.warn("[parseScheme] selector parameter is required and must be function:", selector)
	}

	return {fields: formFieldBindings, ids: descriptorIds}
}

export const putDataToFormByScheme = (form, bindings, data) => {
	if (isSafe(bindings) && isSafe(bindings.load) && isSafe(bindings.load.type)) {
		// async call for getting data and fill form fields
		if (isSafe(data)) {
			let formFieldBindings = {}
			let descriptorIds = {[bindings.load.type]: () => data._id}

			if (isSafe(bindings.scheme)) {
				const parsedSchemesFunctions = parseScheme(bindings.scheme, () => data)
				formFieldBindings = parsedSchemesFunctions.fields
				descriptorIds = Object.assign({}, descriptorIds, parsedSchemesFunctions.ids)
			} else {
				console.warn("[loadDataToFormByScheme] scheme is not defined")
			}
			Object.keys(formFieldBindings).forEach((formField) => {
				form.$(formField).reset()
				form.$(formField).value = formFieldBindings[formField]()
			})
			let ids = {}
			Object.keys(descriptorIds).forEach((descriptorType) => {
				ids[descriptorType] = descriptorIds[descriptorType]()
			})

			if (isSafe(bindings.load) && isSafe(bindings.load.storeKey) && typeof bindings.load.storeKey === "string") {
				GlobalStore[bindings.load.storeKey] = JSON.stringify(ids)
			}
		}
	}
}

export const loadDataToFormByScheme = (form, bindings, params, onLoad) => {
	const _bindings = deepCopy(bindings)

	if (isSafe(_bindings) && isSafe(_bindings.load) && isSafe(_bindings.load.type)) {
		// load api configuration
		let path = null
		let cfg = null
		if (isSafe(GlobalStore.BLConfiguration)) {
			cfg = GlobalStore.BLConfiguration.find(
				(cfg) => cfg.type === _bindings.load.type && cfg.method === "GET" && isNotEmpty(cfg.path)
			)
			if (isSafe(cfg)) {
				path = cfg.path
			} else {
				console.warn(
					"[loadDataToFormByScheme] can't load GET bussiness logic configuration for type:",
					_bindings.load.type
				)
			}
		}

		// process parameters if exists
		if (isSafe(path) && isSafe(_bindings.load.params) && isSafe(params)) {
			_bindings.load.params.forEach((p, i) => {
				if (isSafe(params[p])) {
					path = path.replace(":" + (i + 1), params[p])
				} else {
					console.warn("[loadDataToFormByScheme] can't find parameter:", p, "in input params:", params)
				}
			})
			if (isSafe(_bindings.load.flags)) {
				path += "?level_flag=" + _bindings.load.flags
			}
		}

		// async call for getting data and fill form fields
		if (isSafe(path) && isSafe(cfg)) {
			new ApiCall({path: path, method: cfg.method, transform: true}).call().then((data) => {
				let formFieldBindings = {}
				let descriptorIds = {[_bindings.load.type]: () => data._id}

				if (isSafe(_bindings.scheme)) {
					const parsedSchemesFunctions = parseScheme(_bindings.scheme, () => data)
					formFieldBindings = parsedSchemesFunctions.fields
					descriptorIds = Object.assign({}, descriptorIds, parsedSchemesFunctions.ids)
				} else {
					console.warn("[loadDataToFormByScheme] scheme is not defined")
				}
				Object.keys(formFieldBindings).forEach((formField) => {
					form.$(formField).reset()
					form.$(formField).value = formFieldBindings[formField]()
				})
				let ids = {}
				Object.keys(descriptorIds).forEach((descriptorType) => {
					ids[descriptorType] = descriptorIds[descriptorType]()
				})

				if (isSafe(onLoad)) {
					onLoad(data)
				}

				if (isSafe(_bindings.load) && isSafe(_bindings.load.storeKey) && typeof _bindings.load.storeKey === "string") {
					GlobalStore[_bindings.load.storeKey] = JSON.stringify(ids)
				}

				//form.add({ name: "__descriptor_ids", value:  })
			})
		}
	}
}

const placeToObject = (obj, attr, value, selector) => {
	if (typeof selector === "function") {
		selector(obj)[attr] = isNotSafe(value) || isEmpty(value) ? null : value
	} else {
		obj[attr] = isNotSafe(value) || isEmpty(value) ? null : value
	}
	return obj
}

const createNewPutRequestObject = (request, scheme, descriptor, form, selector, selectorAtribute) => {
	//let request = {}
	if (isSafe(scheme)) {
		Object.keys(scheme).forEach((attr) => {
			if (typeof scheme[attr] === "object") {
				// process FIELD attr
				if (
					isSafe(scheme[attr].field) &&
					typeof scheme[attr].field === "string" &&
					isSafe(form.$(scheme[attr].field))
				) {
					const formField = form.$(scheme[attr].field)
					//request[`${isSafe(parent) ? `${parent}.` : ""}${attr}`] = { value: formField.value, changed: formField.changed }

					// Ak ide o dátum (moment) prevedieme čas normálne
					let reqValue = formField.value
					if (isMoment(reqValue)) {
						reqValue = reqValue.format()
					}

					const value = reqValue
					if ((isNotSafe(value) || isEmpty(value)) && isSafe(selectorAtribute)) {
						if (Object.keys(selector(request)).length === 0 && selector(request).constructor === Object) {
							selector(request)["active"] = false
						}
					}
					request = placeToObject(request, attr, value, selector)
				}
				// process CODELIST attr
				else if (
					isSafe(scheme[attr].codeList) &&
					typeof scheme[attr].codeList === "object" &&
					isSafe(scheme[attr].codeList._id) &&
					typeof scheme[attr].codeList._id === "string" &&
					isSafe(form.$(scheme[attr].codeList._id))
				) {
					const formValue = form.$(scheme[attr].codeList._id).value
					const value = isEmpty(formValue) ? null : {_id: formValue}
					request = placeToObject(request, attr, value, selector)
				}
				// process ARRAY attr
				else if (
					isSafe(scheme[attr].array) &&
					typeof scheme[attr].array === "object" &&
					isSafe(scheme[attr].array.descriptor) &&
					typeof scheme[attr].array.descriptor === "object" &&
					isSafe(scheme[attr].array.scheme) &&
					typeof scheme[attr].array.scheme === "object"
				) {
					const value = [scheme[attr].array.descriptor]
					request = placeToObject(request, attr, value, selector)
					request = createNewPutRequestObject(
						request,
						scheme[attr].array.scheme,
						scheme[attr].array.descriptor,
						form,
						(obj) => selector(obj)[attr][0]
					)
					//createPutRequestObjects(requests, scheme[attr].array.scheme, scheme[attr].array.descriptor, form)
				}
				// process OBJECT attr
				else if (isSafe(scheme[attr].object) && typeof scheme[attr].object === "object") {
					// TODO ten parrent descriptor je nutne este otestovat
					const descr = isSafe(scheme[attr].object.descriptor) ? scheme[attr].object.descriptor : descriptor
					const value = isSafe(scheme[attr].object.descriptor) ? descr : {}
					request = placeToObject(request, attr, value, selector)
					request = createNewPutRequestObject(
						request,
						scheme[attr].object.scheme,
						descr,
						form,
						(obj) => selector(obj)[attr],
						attr
					)
				}
				// process RELATION attr
				else if (isSafe(scheme[attr].relation) /*&& typeof scheme[attr].relation === "object"*/) {
					const value = scheme[attr].relation
					request = placeToObject(request, attr, value, selector)
				}
			}
		})
	}
	return request
}

const createPutRequestObjects = (requests, scheme, descriptor, form, parent) => {
	let request = {}
	if (isSafe(scheme)) {
		Object.keys(scheme).forEach((attr) => {
			if (typeof scheme[attr] === "object") {
				// process FIELD attr
				if (
					isSafe(scheme[attr].field) &&
					typeof scheme[attr].field === "string" &&
					isSafe(form.$(scheme[attr].field))
				) {
					const formField = form.$(scheme[attr].field)
					// FIXME zatial len taky HACK pre ukladanie datumov, berieme ze stale je zmeneny, lebo XsDatetimePicker my znemoznuje zistit zmenu, kedze sama meni field
					// FIXME zatial len taky HACK pre ukladanie checkboxov, berieme ze stale je zmeneny, lebo Checkbox my znemoznuje zistit zmenu, kedze sama meni field, konkretne v ContactTableEx
					const formFieldChanged =
						form.$(scheme[attr].field).type === "date" || form.$(scheme[attr].field).type === "checkbox"
							? true
							: form.$(scheme[attr].field).changed

					// Ak ide o dátum (moment) prevedieme čas normálne
					let reqValue = formField.value
					if (isMoment(reqValue)) {
						reqValue = reqValue.format()
					}

					request[`${isSafe(parent) ? `${parent}.` : ""}${attr}`] = {value: reqValue, changed: formFieldChanged}
				}
				// process CODELIST attr
				else if (
					isSafe(scheme[attr].codeList) &&
					typeof scheme[attr].codeList === "object" &&
					isSafe(scheme[attr].codeList._id) &&
					typeof scheme[attr].codeList._id === "string" &&
					isSafe(form.$(scheme[attr].codeList._id))
				) {
					// FIXME podkovu konvenciu vid. FIELD attr
					const value = form.$(scheme[attr].codeList._id).value
					request[attr] = isEmpty(value)
						? {value: null, changed: form.$(scheme[attr].codeList._id).changed}
						: {value: {_id: value}, changed: form.$(scheme[attr].codeList._id).changed}
				}
				// process ARRAY attr
				else if (
					isSafe(scheme[attr].array) &&
					typeof scheme[attr].array === "object" &&
					isSafe(scheme[attr].array.descriptor) &&
					typeof scheme[attr].array.descriptor === "object" &&
					isSafe(scheme[attr].array.scheme) &&
					typeof scheme[attr].array.scheme === "object"
				) {
					// FIXME podkovu konvenciu vid. FIELD attr ? mozno
					createPutRequestObjects(requests, scheme[attr].array.scheme, scheme[attr].array.descriptor, form)
				}
				// process OBJECT attr
				else if (isSafe(scheme[attr].object) && typeof scheme[attr].object === "object") {
					const descr = isSafe(scheme[attr].object.descriptor) ? scheme[attr].object.descriptor : descriptor
					requests = createPutRequestObjects(requests, scheme[attr].object.scheme, descr, form, attr)
				}
				// process RELATION attr
				else if (isSafe(scheme[attr].relation)) {
					request[attr] = JSON.parse(JSON.stringify(scheme[attr].relation))
					// TEST what if relation is @param
				}
			}
		})
	}

	if (
		isSafe(descriptor) &&
		isSafe(descriptor._type) &&
		typeof descriptor._type === "string" /*&& isNotSafe(requests[descriptor._type])*/
	) {
		const data = isSafe(requests[descriptor._type]) ? requests[descriptor._type].data : {}
		requests[descriptor._type] = {descriptor: descriptor, data: Object.assign({}, data, request)}
	}

	return requests
}

// FIXME na viac bodiek napr. x.y.z
const expandObject = (obj) => {
	let newObj = {}
	Object.keys(obj).forEach((attr) => {
		if (attr.includes(".")) {
			const firstAttr = attr.split(".")[0]
			const firstDotIndex = attr.indexOf(".")
			const restAttr = attr.substr(firstDotIndex + 1)

			newObj[firstAttr] = Object.assign({}, newObj[firstAttr], {[restAttr]: obj[attr]})
		} else {
			newObj[attr] = obj[attr]
		}
	})

	return newObj
}

export const insertFormDataByScheme = (form, bindings, params, onSave, onlyReturnRequest = false, message) => {
	const _bindings = deepCopy(bindings)
	// load api configuration
	let cfg = null
	let returnValue = {}
	if (isSafe(GlobalStore.BLConfiguration) && isSafe(_bindings) && isSafe(_bindings.scheme) && isSafe(form)) {
		//let reqs = createPutRequestObjects([], bindings.scheme, { _type: bindings.load.type }, form)
		const descriptor = Object.assign({_type: _bindings.load.type}, _bindings.descriptor)
		returnValue = createNewPutRequestObject(descriptor, _bindings.scheme, descriptor, form, (obj) => obj)

		// FIXME hack
		if (
			isSafe(returnValue.specific_rels) &&
			returnValue.specific_rels.length > 0 &&
			isSafe(returnValue.specific_rels[0].insurances)
		) {
			returnValue.specific_rels[0].insurances[0].insurer = {_id: form.$("insurer").value}
		}

		//nahradit params # TODO pre @params
		let reqInStr = JSON.stringify(returnValue)
		Object.keys(params).forEach((p) => {
			if (reqInStr.includes(`#${p}`)) {
				reqInStr = reqInStr.replace(`#${p}`, params[p])
			} else if (reqInStr.includes(`@${p}`)) {
				reqInStr = reqInStr.replace(`"@${p}"`, JSON.stringify(params[p]))
			}
		})

		returnValue = JSON.parse(reqInStr)

		cfg = GlobalStore.BLConfiguration.find(
			(cfg) => cfg.type === _bindings.load.type && cfg.method === "PUT" && isNotEmpty(cfg.path)
		)
		if (isSafe(cfg)) {
			let path = cfg.path
			if (isSafe(_bindings.load.flags)) {
				path += "?level_flag=" + _bindings.load.flags
			}
			if (!onlyReturnRequest) {
				new ApiCall(path, "PUT", returnValue)
					.call((statusCode) => {
						if (isSafe(statusCode) && statusCode >= 200 && statusCode < 300 && isNotEmpty(message)) {
							GlobalStore.setNotificationMessage(message)
						}
					})
					.then((response) => {
						if (isSafe(onSave) && typeof onSave === "function") {
							onSave(response)
						}

						UIStore.isFormSaving = false
					})
			}
		} else {
			console.warn(
				"[insertFormDataByScheme] can't load PUT bussiness logic configuration for type:",
				_bindings.load.type
			)
		}
	} else {
		console.warn("[insertFormDataByScheme] missing binding scheme or form object")
	}

	if (onlyReturnRequest) {
		return returnValue
	}
}

export const updateFormDataByScheme = (form, bindings, params, onSave, onlyReturnRequest = false, message) => {
	// load api configuration
	let cfg = null
	let returnValue = {}
	if (isSafe(GlobalStore.BLConfiguration) && isSafe(bindings) && isSafe(bindings.scheme) && isSafe(form)) {
		//let reqs = createPutRequestObjects([], bindings.scheme, { _type: bindings.load.type }, form)
		returnValue = createNewPutRequestObject(
			{_type: bindings.load.type},
			bindings.scheme,
			{_type: bindings.load.type},
			form,
			(obj) => obj
		)

		// FIXME hack
		if (
			isSafe(returnValue.specific_rels) &&
			returnValue.specific_rels.length > 0 &&
			isSafe(returnValue.specific_rels[0].insurances)
		) {
			returnValue.specific_rels[0].insurances[0].insurer = {_id: form.$("insurer").value}
		}

		//nahradit params # TODO pre @params
		let reqInStr = JSON.stringify(returnValue)
		Object.keys(params).forEach((p) => {
			if (reqInStr.includes(`#${p}`)) {
				reqInStr = reqInStr.replace(`#${p}`, params[p])
			} else if (reqInStr.includes(`@${p}`)) {
				reqInStr = reqInStr.replace(`"@${p}"`, JSON.stringify(params[p]))
			}
		})
		returnValue = JSON.parse(reqInStr)

		cfg = GlobalStore.BLConfiguration.find(
			(cfg) => cfg.type === bindings.load.type && cfg.method === "PUT" && isNotEmpty(cfg.path)
		)
		if (isSafe(cfg)) {
			let path = cfg.path
			if (isSafe(bindings.load.flags)) {
				path += "?level_flag=" + bindings.load.flags
			}
			if (!onlyReturnRequest) {
				new ApiCall(path, "PUT", returnValue)
					.call((statusCode) => {
						if (isSafe(statusCode) && statusCode >= 200 && statusCode < 300 && isNotEmpty(message)) {
							GlobalStore.setNotificationMessage(message)
						}
					})
					.then((response) => {
						if (isSafe(onSave) && typeof onSave === "function") {
							onSave(response)
						}
					})
			}
		} else {
			console.warn(
				"[updateFormDataByScheme] can't load PUT bussiness logic configuration for type:",
				bindings.load.type
			)
		}
	} else {
		console.warn("[updateFormDataByScheme] missing binding scheme or form object")
	}

	if (onlyReturnRequest) {
		return returnValue
	}
}

export const saveFormDataByScheme = (form, bindings, params, onSave, onlyReturnRequest = false, message) => {
	const _bindings = deepCopy(bindings)
	// load api configuration
	let cfg = null
	let returnValue = {}
	if (isSafe(GlobalStore.BLConfiguration) && isSafe(_bindings) && isSafe(_bindings.scheme) && isSafe(form)) {
		let reqs = createPutRequestObjects([], _bindings.scheme, {_type: _bindings.load.type}, form)
		Object.keys(reqs).forEach((requestType) => {
			let id = undefined
			if (
				isSafe(_bindings.load) &&
				isSafe(_bindings.load.storeKey) &&
				typeof _bindings.load.storeKey === "string" &&
				isSafe(GlobalStore[_bindings.load.storeKey])
			) {
				const idObj = JSON.parse(GlobalStore[_bindings.load.storeKey])
				if (isSafe(idObj[requestType])) {
					id = {_id: idObj[requestType]}
				}
			}
			// const id = form.has("__descriptor_ids") && isSafe(form.$("__descriptor_ids")) && isSafe(form.$("__descriptor_ids").value)
			//   && isSafe(form.$("__descriptor_ids").value[requestType]) ? { _id: form.$("__descriptor_ids").value[requestType] } : undefined
			let entity = {}
			let entityChanged = false
			Object.keys(reqs[requestType].data).forEach((attr) => {
				if (reqs[requestType].data[attr].changed || (isSafe(reqs[requestType].data[attr].changed) && isNotSafe(id))) {
					const value = reqs[requestType].data[attr].value
					if (isNotSafe(value) || isEmpty(value)) {
						//ak je napr id prazdny string "" vlozim tam null miesto ""
						entity[attr] = null
						//ziskam si objekt do ktoreho sa hodnota doplna (o bodku vyssie)
						let attrArray = attr.split(".")
						let tmpArray = attrArray.slice(0, attrArray.length - 1)
						let tmpRoute = tmpArray.join("")
						if (isSafe(tmpRoute)) {
							//Ak je v objekte napriklad len prazdne _id vlozim tam active=false inak tam nedavam nic napriklad validity ma 2 hodnoty ale 1 moze byt null
							let numberOfoccurrences = 0
							Object.keys(entity).forEach((row) => {
								if (row.includes(tmpRoute)) {
									numberOfoccurrences++
								}
							})
							if (numberOfoccurrences <= 1) {
								tmpRoute += ".active"
								entity[tmpRoute] = false
							}
						}
					} else {
						//Normalne vkladanie ak hodnota je Safe
						entity[attr] = reqs[requestType].data[attr].value
					}
					entityChanged = true
				}
				// process relations
				else if (isNotSafe(reqs[requestType].data[attr].changed)) {
					// zatial len ID params
					if (
						isSafe(reqs[requestType].data[attr]._id) &&
						reqs[requestType].data[attr]._id.startsWith("#") &&
						isSafe(reqs[requestType].data[attr]._type)
					) {
						const param = reqs[requestType].data[attr]._id.substr(1)
						if (isSafe(params) && isSafe(params[param])) {
							reqs[requestType].data[attr]._id = reqs[requestType].data[attr]._id.replace(`#${param}`, params[param])
						} else {
							const relType = reqs[requestType].data[attr]._type
							let relId = undefined
							if (
								isSafe(_bindings.load) &&
								isSafe(_bindings.load.storeKey) &&
								typeof _bindings.load.storeKey === "string" &&
								isSafe(GlobalStore[_bindings.load.storeKey])
							) {
								const relIdObj = JSON.parse(GlobalStore[_bindings.load.storeKey])
								if (isSafe(relIdObj)) {
									relId = relIdObj[relType]
								}
							}
							// const relId = form.has("__descriptor_ids") && isSafe(form.$("__descriptor_ids")) && isSafe(form.$("__descriptor_ids").value)
							//   && isSafe(form.$("__descriptor_ids").value[relType]) ? form.$("__descriptor_ids").value[relType] : undefined
							if (isSafe(relId)) {
								reqs[requestType].data[attr]._id = reqs[requestType].data[attr]._id.replace(`#${param}`, relId)
							}
						}
					}
					// replace whole object as param by @
					else if (typeof reqs[requestType].data[attr] === "string" && reqs[requestType].data[attr].startsWith("@")) {
						const param = reqs[requestType].data[attr].substr(1)
						if (isSafe(params) && isSafe(params[param])) {
							reqs[requestType].data[attr] = params[param]
							entityChanged = true
						} else {
							delete reqs[requestType].data[attr]
							entityChanged = true
						}
					}
					entity[attr] = reqs[requestType].data[attr]
				}
			})

			if (entityChanged && !(Object.keys(entity).length === 0 && entity.constructor === Object)) {
				cfg = GlobalStore.BLConfiguration.find(
					(cfg) => cfg.type === requestType && cfg.method === "PUT" && isNotEmpty(cfg.path)
				)
				if (isSafe(cfg)) {
					entity = Object.assign({}, reqs[requestType].descriptor, entity, isSafe(id) ? id : {})
					entity = expandObject(entity)
					if (!onlyReturnRequest) {
						const cfgPathParamsCount = cfg.path.split(":").length - 1
						let replacedPath = cfg.path
						if (
							isSafe(_bindings.save) &&
							_bindings.save.constructor === Array &&
							_bindings.save.length === cfgPathParamsCount &&
							isSafe(GlobalStore[_bindings.load.storeKey])
						) {
							for (let paramIdx = 1; paramIdx <= cfgPathParamsCount; paramIdx++) {
								replacedPath = replacedPath.replace(
									`:${paramIdx}`,
									JSON.parse(GlobalStore[_bindings.load.storeKey])[_bindings.save[paramIdx - 1]]
								)
							}
						}
						if (isSafe(_bindings.load.flags)) {
							replacedPath += "?level_flag=" + _bindings.load.flags
						}
						new ApiCall(replacedPath, "PUT", entity)
							.call((statusCode) => {
								if (isSafe(statusCode) && statusCode >= 200 && statusCode < 300 && isNotEmpty(message)) {
									GlobalStore.setNotificationMessage(message)
								}
							})
							.then((response) => {
								if (isSafe(onSave) && typeof onSave === "function") {
									// FIXME toto sa odpali viackrat podla toho kolko entit afektuje zmenu
									onSave(response)
								}

								UIStore.isFormSaving = false
							})
					} else {
						returnValue = entity
					}
				} else {
					console.warn("[saveFormDataByScheme] can't load PUT bussiness logic configuration for type:", requestType)
				}
			} else {
				console.warn("[saveFormDataByScheme] nothing for save")
			}
		})
	} else {
		console.warn("[saveFormDataByScheme] missing binding scheme or form object")
	}

	// clear loaded system ids
	if (
		isSafe(_bindings) &&
		isSafe(_bindings.load) &&
		isSafe(_bindings.load.storeKey) &&
		typeof _bindings.load.storeKey === "string" &&
		isSafe(GlobalStore[_bindings.load.storeKey])
	) {
		GlobalStore[_bindings.load.storeKey] = null
	}

	if (onlyReturnRequest) {
		return returnValue
	}
}

export const deleteDataByScheme = (bindings, id, onDelete, message) => {
	if (isSafe(GlobalStore.BLConfiguration) && isSafe(bindings) && isSafe(bindings.load) && isSafe(bindings.load.type)) {
		const cfg = GlobalStore.BLConfiguration.find(
			(cfg) => cfg.type === bindings.load.type && cfg.method === "PUT" && isNotEmpty(cfg.path)
		)

		if (isSafe(cfg)) {
			const request = {
				active: false,
				_id: id,
				_ref: false,
				_type: bindings.load.type
			}

			new ApiCall(cfg.path, "PUT", request)
				.call((statusCode) => {
					if (isSafe(statusCode) && statusCode >= 200 && statusCode < 300 && isNotEmpty(message)) {
						GlobalStore.setNotificationMessage(message)
					}
				})
				.then((res) => {
					if (isSafe(onDelete) && typeof onDelete === "function") {
						onDelete(res)
					}
				})
		} else {
			console.warn("[deleteDataByScheme] can't load PUT bussiness logic configuration for type:", bindings.load.type)
		}
	} else {
		console.warn("[deleteDataByScheme] missing binding load params or BL configuration object")
	}
}

export const resetForm = (form, bindings) => {
	// clear loaded system ids
	if (
		isSafe(bindings) &&
		isSafe(bindings.load) &&
		isSafe(bindings.load.storeKey) &&
		typeof bindings.load.storeKey === "string" &&
		isSafe(GlobalStore[bindings.load.storeKey])
	) {
		GlobalStore[bindings.load.storeKey] = null
	}
	form.reset()
}

export const loadDataToFormBySchemeSync = async (form, bindings, params, onLoad) => {
	const _bindings = deepCopy(bindings)

	if (isSafe(_bindings) && isSafe(_bindings.load) && isSafe(_bindings.load.type)) {
		// load api configuration
		let path = null
		let cfg = null
		if (isSafe(GlobalStore.BLConfiguration)) {
			cfg = GlobalStore.BLConfiguration.find(
				(cfg) => cfg.type === _bindings.load.type && cfg.method === "GET" && isNotEmpty(cfg.path)
			)
			if (isSafe(cfg)) {
				path = cfg.path
			} else {
				console.warn(
					"[loadDataToFormByScheme] can't load GET bussiness logic configuration for type:",
					_bindings.load.type
				)
			}
		}

		// process parameters if exists
		if (isSafe(path) && isSafe(_bindings.load.params) && isSafe(params)) {
			_bindings.load.params.forEach((p, i) => {
				if (isSafe(params[p])) {
					path = path.replace(":" + (i + 1), params[p])
				} else {
					console.warn("[loadDataToFormByScheme] can't find parameter:", p, "in input params:", params)
				}
			})
			if (isSafe(_bindings.load.flags)) {
				path += "?level_flag=" + _bindings.load.flags
			}
		}

		// async call for getting data and fill form fields
		if (isSafe(path) && isSafe(cfg)) {
			const data = await new ApiCall({path: path, method: cfg.method, transform: true}).call()
			let formFieldBindings = {}
			let descriptorIds = {[_bindings.load.type]: () => data._id}

			if (isSafe(_bindings.scheme)) {
				const parsedSchemesFunctions = parseScheme(_bindings.scheme, () => data)
				formFieldBindings = parsedSchemesFunctions.fields
				descriptorIds = Object.assign({}, descriptorIds, parsedSchemesFunctions.ids)
			} else {
				console.warn("[loadDataToFormByScheme] scheme is not defined")
			}
			Object.keys(formFieldBindings).forEach((formField) => {
				form.$(formField).reset()
				form.$(formField).value = formFieldBindings[formField]()
			})
			let ids = {}
			Object.keys(descriptorIds).forEach((descriptorType) => {
				ids[descriptorType] = descriptorIds[descriptorType]()
			})

			if (isSafe(onLoad)) {
				await onLoad(data)
			}

			if (isSafe(_bindings.load) && isSafe(_bindings.load.storeKey) && typeof _bindings.load.storeKey === "string") {
				GlobalStore[_bindings.load.storeKey] = JSON.stringify(ids)
			}
			//form.add({ name: "__descriptor_ids", value:  })
		}
	}
}

export const loadDataToFormBySchemeSyncWithUri = async (form, dataToInsert, bindings, params, onLoad) => {
	const _bindings = deepCopy(bindings)

	if (isSafe(_bindings) && isSafe(_bindings.load) && isSafe(_bindings.load.type)) {
		// load api configuration
		let data = dataToInsert
		let formFieldBindings = {}
		let descriptorIds = {[_bindings.load.type]: () => data._id}

		if (isSafe(_bindings.scheme)) {
			const parsedSchemesFunctions = parseScheme(_bindings.scheme, () => data)
			formFieldBindings = parsedSchemesFunctions.fields
			descriptorIds = Object.assign({}, descriptorIds, parsedSchemesFunctions.ids)
		} else {
			console.warn("[loadDataToFormByScheme] scheme is not defined")
		}
		Object.keys(formFieldBindings).forEach((formField) => {
			form.$(formField).reset()
			form.$(formField).value = formFieldBindings[formField]()
		})
		let ids = {}
		Object.keys(descriptorIds).forEach((descriptorType) => {
			ids[descriptorType] = descriptorIds[descriptorType]()
		})

		if (isSafe(onLoad)) {
			await onLoad(data)
		}

		if (isSafe(_bindings.load) && isSafe(_bindings.load.storeKey) && typeof _bindings.load.storeKey === "string") {
			GlobalStore[_bindings.load.storeKey] = JSON.stringify(ids)
		}
		//form.add({ name: "__descriptor_ids", value:  })
	}
}
