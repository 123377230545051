import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import {Grid, Dialog, DialogContent} from "@material-ui/core"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
// import XsSimpleTextArea from "../../../../../global/ui/xsTextArea/xsSimpleTextArea"
import XsTextArea from "../../../../../global/ui/xsTextArea/xsTextArea"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"

import NotifyStore from "../../../stores/NotifyStore"

import "./notify.less"

@observer
export default class SendNotificationDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					message: {label: <FormattedMessage id="Common.label.message" />, rules: "required"},
					valid_from: {label: <FormattedMessage id="Common.label.from" />, rules: "required"},
					valid_to: {label: <FormattedMessage id="Common.label.to" />, rules: "required"},
					priority: {label: <FormattedMessage id="Common.label.priority" />, rules: "required"}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)

		NotifyStore.getNotifyUsers()
	}

	render() {
		return (
			<Dialog
				id="sendNotificationDialog"
				open={NotifyStore.isOpenSendNotification}
				onClose={() => NotifyStore.closeSendNotification(this.form)}
				onEscapeKeyDown={() => NotifyStore.closeSendNotification(this.form)}
				disableBackdropClick={true}
				PaperProps={{
					className: "notification-dialog"
				}}
			>
				<DialogContent className="content-dialog">
					<div className="xs-row hidden">
						<div className="left mr-4 bgWhite xs-col-7 borderRadius-1">
							<i
								className="fal fa-times fa-lg greyDefault closeBtn pointer"
								onClick={() => NotifyStore.closeSendNotification(this.form)}
							/>
							<Grid container className="pa-4">
								<Grid item container direction="column" alignItems="center" spacing={8}>
									<Grid item>
										<i className="fal fa-bell fa-3x" />
									</Grid>
									<Grid item className="xs-bold">
										<FormattedMessage id="Common.label.notification" />
									</Grid>
								</Grid>
								<Grid item xs={12} className="pt-3 pb-1">
									{/* <XsSimpleTextArea field={this.form.$("message")} rows={13} white /> */}
									<XsTextArea field={this.form.$("message")} /*hideToolbar={true}*/ rows={9} white maxLength={5000} />
								</Grid>
								<Grid item container spacing={8}>
									<Grid item xs={6}>
										<XsDateTimePicker field={this.form.$("valid_from")} white showTimeSelect={true} />
									</Grid>
									<Grid item xs={6}>
										<XsDateTimePicker field={this.form.$("valid_to")} white showTimeSelect={true} />
									</Grid>
								</Grid>
								<Grid item xs={6}>
									<XsSearchSelect
										white
										field={this.form.$("priority")}
										items={[{_id: "1", code: "1", name_ext: "Vysoká"}, {_id: "2", code: "2", name_ext: "Nízka"}]}
									/>
								</Grid>
								<Grid item container justify="flex-end" className="mt-7">
									<Grid item>
										<XsButton
											className="xs-success"
											icon={<i className="fal fa-bell" />}
											text={<FormattedMessage id="Common.label.sendNotifications" />}
											onClick={() => NotifyStore.sendNotification(this.form)}
										/>
									</Grid>
								</Grid>
							</Grid>
						</div>
						<div className="right bgWhite pb-4 pl-4 pr-4 xs-col-5 borderRadius-1">
							<Grid container className="pt-4">
								<Grid item>
									<FormattedMessage id="Common.label.selectUsers" />:
								</Grid>
							</Grid>
							<XsTable
								// loading={NotifyStore.loadingUser}
								config={{
									columnDefs: {
										nick_name: {
											title: <FormattedMessage id="Common.label.fullName" />,
											type: "string",
											design: {
												width: "100%"
											}
										}
									},
									options: {
										hidePager: true,
										showCursor: true,
										checkboxes: true,
										checkboxColumn: "user_id",
										checkAllDataColumn: "user_id",
										onClickCheckbox: (data) => {
											NotifyStore.checkedUserIds = data
										}
									},
									dataSource: NotifyStore.notifyUsers
								}}
							/>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		)
	}
}
