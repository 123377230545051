//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../global/ui/globalUISchemeBindings"

import {getUserPersonnelOrgUnits, getSelectedOrgUnitID} from "../../../../global/helpers/actions"

import {Dialog, DialogContent, Grid, DialogTitle, DialogActions} from "@material-ui/core"

import XsSearchSelect from "../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsCheckbox from "../../../../global/ui/xsCheckbox/xsCheckbox"
import XsInput from "../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../global/ui/xsButton/xsButton"

import MovePatientsToAnotherOrgunitStore from "../../stores/MovePatientsToAnotherOrgunitStore"

@observer
export default class MovePatientsToAnotherOrgunitDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				MovePatientsToAnotherOrgunitStore.movePatients(form)
			},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					orgunitId: {
						label: <FormattedMessage id="Common.label.selectOrgunitWhereYouWantToMovePatients" />,
						rules: "required"
					},
					doctorCode: {
						label: <FormattedMessage id="Common.label.moveToDoctor" />
					},
					personnelExpertiseId: {},
					moveCapitation: {
						label: <FormattedMessage id="Common.label.moveCapitation" />,
						type: "checkbox"
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)
	}

	render() {
		return (
			<Dialog
				id="xsAddEventSelectOrgunitDialog"
				open={MovePatientsToAnotherOrgunitStore.isOpen}
				onClose={() => MovePatientsToAnotherOrgunitStore.close(this.form)}
				onEscapeKeyDown={() => MovePatientsToAnotherOrgunitStore.close(this.form)}
				// disableBackdropClick={true}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-500"
				}}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								MovePatientsToAnotherOrgunitStore.close(this.form)
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-share-square fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.moveToAnotherOrgunit" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="overflowYInherit">
					<Grid container alignItems="center" spacing={8} className="pb-2 pr-4 pl-3 pt-4">
						<Grid item xs={12}>
							<XsSearchSelect
								white
								required
								field={this.form.$("orgunitId")}
								items={getUserPersonnelOrgUnits().filter((x) => x._id != getSelectedOrgUnitID())}
								onChange={(val) => {
									const selectedOrgunit = getUserPersonnelOrgUnits().find((x) => x._id == val)

									if (isSafe(selectedOrgunit)) {
										this.form.$("doctorCode").value = isSafe(selectedOrgunit.doctor_code)
											? selectedOrgunit.doctor_code
											: ""
										this.form.$("personnelExpertiseId").value = isSafe(selectedOrgunit.personnel_expertise_id)
											? selectedOrgunit.personnel_expertise_id
											: ""
									}
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<XsInput field={this.form.$("doctorCode")} disabled={true} />
						</Grid>
						<Grid item xs={12}>
							<XsCheckbox field={this.form.$("moveCapitation")} />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer">
					<Grid container justify="space-between">
						<Grid item>
							<XsButton
								className="xs-default xs-outline"
								icon={<i className="fal fa-times" />}
								text={<FormattedMessage id="Common.form.close" />}
								onClick={() => {
									MovePatientsToAnotherOrgunitStore.close(this.form)
								}}
							/>
						</Grid>
						<Grid item>
							<XsButton
								className="xs-success"
								text={<FormattedMessage id="Common.label.move" />}
								icon={<i className="fal fa-share-square" />}
								onClick={this.form.onSubmit}
							/>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}
