import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import moment from "moment"
import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsAutocompleteLocal from "../../../../../global/ui/xsInput/xsAutocompleteLocal"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import GlobalStore from "../../../../../global/store/GlobalStore"
import DataStore from "../../../stores/DataStore"
import DDaction from "../../../actions/patientCard"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import VaccAllergyDialogStore from "../../../stores/VaccAllergyDialogStore"
import {getSelectedOrgUnitID} from "../../../../../global/helpers/actions"
import api from "../../../actions/api"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import RegisterCacheStore from "../../../../../global/store/RegistersCacheStore"

@observer
export default class VaccAllergyDialog extends React.Component {
	constructor(props) {
		super(props)

		GlobalStore.refreshCodeLists(["vaccinationSymptoms"])
		const fields = {
			symptom: {
				label: <FormattedMessage id="Common.label.vaccAllergy" />
			},
			allergyType: {
				label: <FormattedMessage id="Common.label.allergyType" />
			},
			allergyReaction: {
				label: "Prejav nežiadúcej reakcie (Typ alergie)"
			},
			atcCode: {
				label: <FormattedMessage id="Common.label.drug" />
			},
			allergenCode: {
				label: <FormattedMessage id="Common.label.substance" />
			},
			showAlergenCl: {
				label: <FormattedMessage id="Common.label.type" />,
				value: "liecivo"
			},
			dateOfSymptoms: {
				label: <FormattedMessage id="Common.label.dateOfFristSymptoms" />,
				type: "date",
				value: moment()
			},
			note: {
				label: <FormattedMessage id="Common.label.note" />
			},
			diagnosis: {
				label: <FormattedMessage id="Patient.form.patientrecord.diagnosis" />
			}
		}

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm({fields: fields}, {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	render() {
		const providerID = getSelectedOrgUnitID()

		const showAlergenCl = [
			{
				_id: "latka",
				code_ex: "Látka",
				name_ext: "Látka"
			},
			{
				_id: "liecivo",
				code_ex: "Liečivo",
				name_ext: "Liečivo"
			}
		]

		return (
			<Dialog
				id="xsVaccAllergyDialog"
				open={VaccAllergyDialogStore.isOpen}
				onClose={() => VaccAllergyDialogStore.close(this.form)}
				disableBackdropClick={true}
				maxWidth="md"
				classes={{
					paper: "xs-paper-dialog"
				}}
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-danger">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-syringe fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.vaccAllergy" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content" style={{maxWidth: 450}}>
					<Grid container>
						{/* <Grid item xs={12}>
							<SearchSelect
								field={this.form.$("symptom")}
								items={GlobalStore.CL["vaccinationSymptoms"]}
								getOptionLabel={(obj) => obj.name_ext}
								getOptionValue={(obj) => obj._id}
							/>
						</Grid> */}
						<Grid item xs={12}>
							<XsDateTimePicker field={this.form.$("dateOfSymptoms")} maxDate={moment()}/>
						</Grid>
						<Grid item xs={12}>
							<XsInput field={this.form.$("note")} maxLength={2000} />
						</Grid>
						<Grid item xs={12}>
							<SearchSelect field={this.form.$("allergyType")} items={GlobalStore.CL["allergyType"]} />
						</Grid>
						<Grid item xs={12}>
							<SearchSelect
								field={this.form.$("showAlergenCl")}
								onChange={() => {
									if(this.form.$("showAlergenCl").value == "latka"){
										this.form.$("atcCode").value = ""
									}else{
										this.form.$("allergenCode").value = ""
									}
								}}
								items={showAlergenCl}
								required
							/>
						</Grid>

						{this.form.$("showAlergenCl").value == "latka" ? (
							<Grid item xs={12}>
								<SearchSelect field={this.form.$("allergenCode")} items={GlobalStore.CL["allergens"]} />
							</Grid>
						) : (
							<Grid item xs={12}>
								<XsAutocomplete
									field={this.form.$("atcCode")}
									minLengthForSearch="3"
									api={api.loadClassification}
									inputRenderer={(obj) => obj.name_ext}
									saveValue={(obj) => obj.code}
									modalConfig={(textValue, clickHandler) => {
										return {
											columnDefs: {
												code_ext: {
													title: (
														<FormattedMessage id="Patient.form.patientrequest.expertise.autoCompleteModal.CodeColumn" />
													),
													type: "string",
													dbName: "code_ext",
													design: {
														width: "150px",
														header: {
															className: "xs-autoCompleteModalColumnHeader"
														}
													}
												},
												name_ext: {
													title: <FormattedMessage id="Common.label.name" />,
													type: "string",
													dbName: "search_column",
													design: {
														header: {
															className: "xs-autoCompleteModalColumnHeader"
														}
													},
													filter: {
														gridWidth: 3,
														defaultValue: textValue,
														renderElement: "input"
													},
													sortable: true
												}
											},
											options: {
												// selectRow: true,
												showCursor: true,
												onRowClick: (dataRow) => {
													clickHandler(dataRow)
												}
											}
										}
									}}
								/>
							</Grid>
						)}
						<Grid item xs={12}>
							<SearchSelect field={this.form.$("allergyReaction")} items={GlobalStore.CL["allergyReaction"]} />
						</Grid>
						<Grid item xs={12}>
							<XsAutocompleteLocal
								field={this.form.$("diagnosis")}
								minLengthForSearch="3"
								clearText
								data={RegisterCacheStore.diagnosisRegister}
								inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
								saveValue={(obj) => obj.code}
								specialDataFilter={(data) =>
									data.filter((i) => isEmpty(i.supplementary_sign) || i.supplementary_sign == "E")
								}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											},
											paging: {
												rowsPerPage: 100,
												rowsPerPageOptions: [5, 10, 25, 50, 100]
											}
										}
									}
								}}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => VaccAllergyDialogStore.close(this.form)}
					/>
					<XsButton
						className="xs-info"
						icon={<i className="fal fa-syringe" />}
						text={<FormattedMessage id="Common.label.save" />}
						onClick={() => {
							VaccAllergyDialogStore.save(this.form)
							DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
								DataStore.setPatientEHR(overview)
							})
						}}
						disabled={isEmpty(this.form.$("allergyType").value)}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
