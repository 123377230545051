"use strict"

import api from "../../../modules/ambulance/actions/api"
import {
	printReport,
	getSelectedOrgUnitID,
	getUserDoctorInfo,
	getUserPersonnelID,
	getUser,
	getSelectedOrgUnit,
	getUserMedInsurers
} from "../../../global/helpers/actions"
import {getFilters, GWApiCall} from "../../../global/helpers/api"
import {observable, action} from "mobx"
import moment from "moment"

import DataStore from "../stores/DataStore"
import UIStore from "../stores/UIStore"
import WarningStore from "../../../global/store/WarningStore"
import StornoPrescriptionDialogStore from "../stores/StornoPrescriptionDialogStore"
import config from "../../../global/config/settings"
import PatientPrescriptionContainerStore from "./PatientPrescriptionContainerStore"
import GlobalStore from "../../../global/store/GlobalStore"

import DDaction from "../actions/patientCard"
import {loadDataToFormBySchemeSync, loadDataToFormBySchemeSyncWithUri} from "../../../global/helpers/bindings"
import dataBindings from "../components/patientDetail/patientPrescriptionForm/patientPrescriptionBindings"

class PatientPrescriptionStore {
	@observable drugs = []
	@observable diagnosis = []
	@observable personel = []
	@observable substitutingDoctor = []
	@observable medDevices = []
	@observable medIngredients = {}
	@observable ingredientsChips = {}
	ingredientsChipsForm = {}
	prohibitedChipsForm = {}
	@observable medFood = []
	@observable represcribeID = null
	@observable represcribeLoaded = false
	@observable providers = []
	@observable providers2 = []
	@observable prohibitedChips = {}
	@observable prohibitedDrugsId = {}
	@observable prohibitedDrugs = {}
	// @observable validationSnackbar = null
	@observable doctorOUPZSItems = {}
	@observable substitutingDoctorOUPZSItems = {}
	@observable prescriptionLimitations = {}
	@observable drugPrices = observable.map({})
	@observable drugPricesFull = observable.map({})
	@observable patientPresence = null

	//FLAGS
	@observable doctorLoadedFlag = false
	@observable represcribeFlag = false

	ingredientId = {}

	@action clearData(name) {
		this[name] = {}
	}

	@action printPrescription(recordID, pacInfo, directPrint = true) {
		return api
			.loadPrescription(recordID)
			.call()
			.then((prescriptionData) => {
				let multiplePages = false

				let barcode = prescriptionData.barcode ? prescriptionData.barcode.replace("(", "") : ""
				barcode = prescriptionData.barcode ? barcode.replace(")", "") : ""

				const doctorInfo = getUserDoctorInfo()

				let printValues = {
					name: `${pacInfo.last_name} ${pacInfo.first_name}${
						pacInfo.title_before ? ", " + pacInfo.title_before.code_ext : ""
					}${pacInfo.title_after ? ", " + pacInfo.title_after.code_ext : ""}`,
					identifier: pacInfo.identifier,
					//drug: `${prescriptionData.name} ${translationText.pkg}<br/>${prescriptionData.supplement}${prescriptionData.transcription ? "<br/>D.S.: " + prescriptionData.transcription : ""}`,
					drug: `${prescriptionData.product_printable_name}${
						prescriptionData.transcription ? "<br/>D.S.: " + htmlToPlain(prescriptionData.transcription) : ""
					}`,
					barcode: barcode,
					product_supplement: prescriptionData.product_supplement,
					pay:
						prescriptionData.patient_payment_reason &&
						(prescriptionData.patient_payment_reason === "NEZML" || prescriptionData.patient_payment_reason === "JASAM")
							? "HRADÍ PACIENT"
							: "",
					barcodetext1: prescriptionData.barcode ? prescriptionData.barcode.substr(0, 5) : "",
					barcodetext2: prescriptionData.barcode ? prescriptionData.barcode.substr(5, 10) : "",
					barcodetext3: prescriptionData.barcode ? prescriptionData.barcode.substr(15) : "",
					doctorcode: doctorInfo && doctorInfo.code ? doctorInfo.code : "",
					insurance_company_note: prescriptionData.insurance_company_note
						? prescriptionData.insurance_company_note
						: "",
					providercode: prescriptionData.provider_card ? "PZS: " + prescriptionData.provider_card.provider_code : null,
					sending_doctor: "",
					nezml_doctor: "",
					created_date: prescriptionData.created_at ? moment(prescriptionData.created_at).format("DD.MM.YYYY") : "",
					prescription_reason: prescriptionData.prescription_reason
						? htmlToPlain(prescriptionData.prescription_reason)
						: ""
				}

				//Adresa
				if (pacInfo.validInfo && pacInfo.validInfo.address) {
					const street = pacInfo.validInfo.address.street ? pacInfo.validInfo.address.street : ""
					const house_number = pacInfo.validInfo.address.house_number ? pacInfo.validInfo.address.house_number : ""
					const city = pacInfo.validInfo.address.city ? pacInfo.validInfo.address.city : ""
					const zipcode = pacInfo.validInfo.address.zip ? pacInfo.validInfo.address.zip : ""
					printValues["address"] = `${street} ${house_number} ${city} ${zipcode}`
				}
				//Insurer
				if (pacInfo.validInfo && pacInfo.validInfo.insurance) {
					pacInfo.validInfo.insurance.insurer.code_ext
						.toString()
						.split("")
						.map((char, index) => {
							printValues["insurer" + index] = char
						})
				}

				//Dg
				prescriptionData.diagnosis_code_ext
					.toString()
					.split("")
					.map((char, index) => {
						printValues["dg" + index] = char
					})

				//Druhá strana receptu
				if (
					prescriptionData.substituting_doctor_card ||
					prescriptionData.sending_doctor_card ||
					prescriptionData.replacement_prohibited ||
					prescriptionData.patient_payment_reason === "NEZML" ||
					prescriptionData.flags.includes("N")
				) {
					const sentAt = prescriptionData.sent_at ? moment(prescriptionData.sent_at).format("DD.MM.YYYY") : ""
					printValues["second_page"] = "<pagebreak>"
					multiplePages = true

					//Nezmluvný lekár
					if (prescriptionData.patient_payment_reason && prescriptionData.patient_payment_reason === "NEZML") {
						printValues["nezml_doctor"] += "NEZMLUVNÝ LEKÁR<br/>"
					}

					//Neodkladná zdravotná starostlivosť
					if (prescriptionData.flags && prescriptionData.flags.includes("N")) {
						printValues["nezml_doctor"] += "NEODKLADNÁ ZDRAVOTNÁ STAROSTLIVOSŤ<br/>"
					}

					//Odporúčajúci lekár
					if (prescriptionData.sending_doctor_card) {
						const sdc = prescriptionData.sending_doctor_card
						printValues["sending_doctor"] = `Na odporúčanie odborného lekára <br/>
          ${sdc.personnel_code} <br />
          ${sdc.full_name} ${sdc.expertise}
          ${sentAt ? "<br/>Odporučil dňa: " + sentAt : ""}`
					}

					if (prescriptionData.sending_provider_card) {
						const spc = prescriptionData.sending_provider_card
						printValues["sending_doctor"] +=
							spc.company || spc.provider_code ? `<br/>${spc.company} ${spc.provider_code}` : ""
						printValues["sending_doctor"] += spc.address ? `<br />${spc.address}` : ""
					}

					//Zastupovaný lekár
					if (prescriptionData.substituting_doctor_card) {
						const sdc = prescriptionData.substituting_doctor_card
						const spc = prescriptionData.substituting_provider_card
						printValues["substituting_doctor"] =
							(prescriptionData.sending_doctor_card ? "<br/>" : "") +
							`<br/> Zastupovanie <br/>
          ${sdc.personnel_code} <br />
          ${spc ? spc.provider_code : ""}`
					}

					//Zákaz vydania náhrady
					if (prescriptionData.prohibited_drugs && prescriptionData.prohibited_drugs.length > 0) {
						printValues["prohibited_drugs"] = `<br/>Zakázané lieky:<br/>${prescriptionData.prohibited_drugs
							.map((pd) => `${prescriptionData.product_prefix}${pd.code_ext} ${pd.name_ext}<br/>`)
							.join("")}`
					}
				}

				//Kód
				if (prescriptionData.product_printable_code) {
					prescriptionData.product_printable_code
						.toString()
						.split("")
						.map((char, index) => {
							printValues["sukl" + index] = char
						})
				}

				const recipeType = prescriptionData.recipe_type.toUpperCase()

				//Individuálne pripravované lieky majú ešte pokyny k príprave
				if (recipeType === "RINDIVID") {
					const ifp = prescriptionData.instructions_for_preparation
					const ifu = prescriptionData.instructions_for_use
					printValues.drug = `${prescriptionData.product_printable_name}`
					printValues.drug += `${ifp ? `${ifp}` : ""}`
					printValues.drug += `${ifu ? `${ifu}` : ""}`
					printValues.drug = htmlToPlain(printValues.drug)
					printValues.drug += `${prescriptionData.transcription ? "<br/>D.S.: " + prescriptionData.transcription : ""}`
				}

				//Tlačivo rozlišujeme podľa typu receptu
				//MEDEQUIP - Pomôcky
				//RDIETETIC - Recept na dietetickú potravinu
				//RINDIVID - Individuálne pripravované lieky
				if (recipeType === "MEDEQUIP" || recipeType === "RDIETETIC") {
					const recipeTypeLC = prescriptionData.recipe_type.toLowerCase()

					if (directPrint) {
						printReport("prescription_" + recipeTypeLC, printValues)
					} else {
						return {template: "prescription_" + recipeTypeLC, values: printValues, multiplePages}
					}
				} else {
					if (directPrint) {
						printReport("prescription", printValues)
					} else {
						return {template: "prescription", values: printValues, multiplePages}
					}
				}
			})
	}

	@action represcribe(id) {
		this.represcribeID = id
		this.represcribeLoaded = false
	}

	//zneplatnit
	@action deactivate(id) {
		api
			.deactivatePrescription(id)
			.call()
			.then((response) => {
				//Zrušíme označenie záznamu EHR
				DataStore.removeFromCheckedEHR({record_id: id})

				const xmlB64Data = [
					{
						RecordId: response._id,
						XmlData:
							isSafe(response.ixs_signer_data) &&
							isSafe(response.ixs_signer_data.payload) &&
							isSafe(response.ixs_signer_data.payload.text)
								? response.ixs_signer_data.payload.text
								: ""
					}
				]
				const selectedOrgUnit = getSelectedOrgUnit()
				const user = getUser()
				const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
				const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
				const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
				const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
				const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

				const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
					? DataStore.patientDTO.get("patient").validInfo.client
					: null
				const JRUZIDObj = isSafe(patientClientInfo.identifiers)
					? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
					: null

				GWApiCall({
					method: "POST",
					uri: `${config.GW_BASE_URL}/SignXmlData`,
					body: JSON.stringify({
						XmlDataItems: xmlB64Data,
						AmbeePatientId: DataStore.patientDTO.get("patientID"),
						PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
						PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
						AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
						AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
						AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
						AmbeePersonnelId: getUserPersonnelID(),
						OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
						PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
						PersonnelExpertiseJRUZVersion: specVer,
						PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
					})
				})
					.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
					.then((data) => {
						GlobalStore.checkEHGWError(data.ErrorCode, data.ErrorMessage, data.IsEhealthException)
						const key = `prescription#${id};`
						UIStore.commMsgQueue.delete(key)

						let singnatureData = []
						if (isSafe(data) && isSafe(data.XmlDataItems) && data.XmlDataItems.length) {
							Object.keys(data.XmlDataItems).forEach((obj) => {
								if (isSafe(data.XmlDataItems[obj]) && isSafe(data.XmlDataItems)) {
									singnatureData.push({
										record_id: data.XmlDataItems[obj].RecordId,
										signature: data.XmlDataItems[obj].XmlData
									})
								}
							})
						} else {
							Object.keys(response).forEach((obj) => {
								singnatureData.push({record_id: response[obj].record_id, signature: ""})
							})
						}

						api
							.invalidateMultipleRecordSignature(singnatureData)
							.call()
							.then((response) => {
								if (
									isSafe(response) &&
									isSafe(response.warning) &&
									isSafe(response.warning.text) &&
									response.warning.confirm
								) {
									StornoPrescriptionDialogStore.open(response.warning.text, true, id, null, true)
									const providerID = getSelectedOrgUnitID()
									DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) =>
										DataStore.setPatientEHR(overview)
									)
								} else if (
									(isSafe(response) && isNotSafe(response.warning)) ||
									(isSafe(response) && isSafe(response.warning) && !response.warning.confirm)
								) {
									StornoPrescriptionDialogStore.open(response.warning.text, false, null, null, true)
									const providerID = getSelectedOrgUnitID()
									DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) =>
										DataStore.setPatientEHR(overview)
									)
								} else {
									api
										.resetRecordAfterDelete({_id: id, _type: "EHR.Data.Medication", active: true})
										.call()
										.then(() => {
											const providerID = getSelectedOrgUnitID()
											DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) =>
												DataStore.setPatientEHR(overview)
											)
										})
								}
							})
							.catch(() => {
								api
									.resetRecordAfterDelete({_id: id, _type: "EHR.Data.Medication", active: true})
									.call()
									.then(() => {
										const providerID = getSelectedOrgUnitID()
										DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) =>
											DataStore.setPatientEHR(overview)
										)
									})
							})
					})
					.catch(() => {
						const key = `prescription#${id};`
						UIStore.commMsgQueue.delete(key)
						api
							.invalidateRecordSignature(id, "")
							.call()
							.then((response) => {
								if (
									isSafe(response) &&
									isSafe(response.warning) &&
									isSafe(response.warning.text) &&
									response.warning.confirm
								) {
									StornoPrescriptionDialogStore.open(response.warning.text, true, id, null, true)
									const providerID = getSelectedOrgUnitID()
									DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) =>
										DataStore.setPatientEHR(overview)
									)
								} else if (
									(isSafe(response) && isNotSafe(response.warning)) ||
									(isSafe(response) && isSafe(response.warning) && !response.warning.confirm)
								) {
									StornoPrescriptionDialogStore.open(response.warning.text, false, null, null, true)
									const providerID = getSelectedOrgUnitID()
									DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) =>
										DataStore.setPatientEHR(overview)
									)
								} else {
									api
										.resetRecordAfterDelete({_id: id, _type: "EHR.Data.Medication", active: true})
										.call()
										.then(() => {
											const providerID = getSelectedOrgUnitID()
											DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) =>
												DataStore.setPatientEHR(overview)
											)
										})
								}
							})
							.catch(() => {
								api
									.resetRecordAfterDelete({_id: id, _type: "EHR.Data.Medication", active: true})
									.call()
									.then(() => {
										const providerID = getSelectedOrgUnitID()
										DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) =>
											DataStore.setPatientEHR(overview)
										)
									})
							})
					})
			})
	}

	@action cancel(id) {
		api
			.deleteRecord(id)
			.call()
			.then((response) => {
				//Zrušíme označenie záznamu EHR
				DataStore.removeFromCheckedEHR({record_id: id})

				const xmlB64Data = [
					{
						RecordId: response._id,
						XmlData:
							isSafe(response.ixs_signer_data) &&
							isSafe(response.ixs_signer_data.payload) &&
							isSafe(response.ixs_signer_data.payload.text)
								? response.ixs_signer_data.payload.text
								: ""
					}
				]
				const selectedOrgUnit = getSelectedOrgUnit()
				const user = getUser()
				const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
				const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
				const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
				const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
				const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

				const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
					? DataStore.patientDTO.get("patient").validInfo.client
					: null
				const JRUZIDObj = isSafe(patientClientInfo.identifiers)
					? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
					: null

				GWApiCall({
					method: "POST",
					uri: `${config.GW_BASE_URL}/SignXmlData`,
					body: JSON.stringify({
						XmlDataItems: xmlB64Data,
						AmbeePatientId: DataStore.patientDTO.get("patientID"),
						PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
						PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
						AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
						AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
						AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
						AmbeePersonnelId: getUserPersonnelID(),
						OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
						PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
						PersonnelExpertiseJRUZVersion: specVer,
						PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
					})
				})
					.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
					.then((data) => {
						GlobalStore.checkEHGWError(data.ErrorCode, data.ErrorMessage, data.IsEhealthException)
						const key = `prescription#${id};`
						UIStore.commMsgQueue.delete(key)

						let singnatureData = []
						if (isSafe(data) && isSafe(data.XmlDataItems) && data.XmlDataItems.length) {
							Object.keys(data.XmlDataItems).forEach((obj) => {
								if (isSafe(data.XmlDataItems[obj]) && isSafe(data.XmlDataItems)) {
									singnatureData.push({
										record_id: data.XmlDataItems[obj].RecordId,
										signature: data.XmlDataItems[obj].XmlData
									})
								}
							})
						} else {
							Object.keys(response).forEach((obj) => {
								singnatureData.push({record_id: response[obj].record_id, signature: ""})
							})
						}

						api
							.deleteSignatureMultiplePrescriptions(singnatureData)
							.call()
							.then(async (response) => {
								let text = ""
								let confirm = false
								let ids = []
								Object.keys(response).forEach((obj, index) => {
									if (isSafe(response[obj])) {
										if (isSafe(response[obj].warning)) {
											text +=
												response[obj].warning.text +
												(Object.keys(response).length != index || response[obj].warning.text != "" ? "" : "<br/>")
											if (response[obj].warning.confirm) {
												if (!confirm) {
													confirm = response[obj].warning.confirm
												}
												ids.push(response[obj].record_id)
											}
										}
									}
								})
								if (!confirm) {
									if (isNotEmpty(text)) {
										StornoPrescriptionDialogStore.open(text, true, id)
										const providerID = getSelectedOrgUnitID()
										DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) =>
											DataStore.setPatientEHR(overview)
										)
									} else if (isEmpty(text)) {
										StornoPrescriptionDialogStore.open(text, false)
										const providerID = getSelectedOrgUnitID()
										DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) =>
											DataStore.setPatientEHR(overview)
										)
									}
								} else {
									for (let id in ids) {
										await api.resetRecordAfterDelete({_id: id, _type: "EHR.Data.Medication", active: true}).call()
									}
									const providerID = getSelectedOrgUnitID()
									DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) =>
										DataStore.setPatientEHR(overview)
									)
								}
							})
							.catch(() => {
								api
									.resetRecordAfterDelete({_id: id, _type: "EHR.Data.Medication", active: true})
									.call()
									.then(() => {
										const providerID = getSelectedOrgUnitID()
										DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) =>
											DataStore.setPatientEHR(overview)
										)
									})
							})
					})
					.catch(() => {
						const key = `prescription#${id};`
						UIStore.commMsgQueue.delete(key)
						let singnatureData = [{record_id: id, signature: ""}]
						api
							.deleteSignatureMultiplePrescriptions(singnatureData)
							.call()
							.then(async (response) => {
								let text = ""
								let confirm = false
								let ids = []
								Object.keys(response).forEach((obj, index) => {
									if (isSafe(response[obj])) {
										if (isSafe(response[obj].warning)) {
											text +=
												response[obj].warning.text +
												(Object.keys(response).length != index || response[obj].warning.text != "" ? "" : "<br/>")
											if (response[obj].warning.confirm) {
												if (!confirm) {
													confirm = response[obj].warning.confirm
												}
												ids.push(response[obj].record_id)
											}
										}
									}
								})
								if (!confirm) {
									if (isNotEmpty(text)) {
										StornoPrescriptionDialogStore.open(text, true, id)
										const providerID = getSelectedOrgUnitID()
										DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) =>
											DataStore.setPatientEHR(overview)
										)
									} else if (isEmpty(text)) {
										StornoPrescriptionDialogStore.open(text, false)
										const providerID = getSelectedOrgUnitID()
										DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) =>
											DataStore.setPatientEHR(overview)
										)
									}
								} else {
									for (let id in ids) {
										await api.resetRecordAfterDelete({_id: id, _type: "EHR.Data.Medication", active: true}).call()
									}
									const providerID = getSelectedOrgUnitID()
									DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) =>
										DataStore.setPatientEHR(overview)
									)
								}
							})
							.catch(() => {
								api
									.resetRecordAfterDelete({_id: id, _type: "EHR.Data.Medication", active: true})
									.call()
									.then(() => {
										const providerID = getSelectedOrgUnitID()
										DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) =>
											DataStore.setPatientEHR(overview)
										)
									})
							})
					})
			})
	}

	@action findDrugs(patientId, providerId, productCode) {
		api.najdilieky(patientId, providerId, productCode).call()
	}

	@action async loadIPLChipText(form) {
		let iplData = JSON.parse(form.$("ingredientsChipsForm").value)
		if (isSafe(iplData)) {
			let idsArr = []
			iplData.forEach((item) => {
				idsArr.push(item.col1)
			})

			if (idsArr.length) {
				let filters = {filters: [{associated_column: "code", predicate: "=", values: []}]}
				idsArr.forEach((i) => {
					filters.filters[0].values.push({id_value: i})
				})
				let res = await api.loadIngrediens(filters).call()
				iplData.forEach((i) => {
					let dataRow = res.rows.find((row) => row.code == i.col1)
					i.col1NameExt = dataRow.name_ext
				})
				form.$("ingredientsChipsForm").value = JSON.stringify(iplData)
			}
		}
	}

	@action loadProhibitedText(key) {
		if (isSafe(this.prohibitedChipsForm) && isSafe(this.prohibitedChipsForm[key])) {
			let data = this.prohibitedChipsForm[key].getData()
			let dataArr = []
			let idsArr = []

			Object.keys(data).forEach((field) => {
				dataArr[+field] = data[field]
			})
			dataArr.forEach((item) => {
				idsArr.push(item.col1)
			})

			if (idsArr.length) {
				let filters = {filters: [{associated_column: "code", predicate: "=", values: []}]}
				idsArr.forEach((i) => {
					filters.filters[0].values.push({id_value: i})
				})
				api
					.loadDrugs(filters)
					.call()
					.then((res) => {
						dataArr.forEach((i) => {
							let obj = res.rows.find((row) => row.code == i.col1)
							if (isSafe(obj)) {
								i.col1NameExt =
									obj.code_ext +
									" | " +
									obj.name_ext +
									" | " +
									obj.supplement +
									" | " +
									(obj.patient_payment ? obj.patient_payment + " | " : "") +
									(obj.insurer_payment ? obj.insurer_payment + " | " : "") +
									obj.atc +
									" | " +
									obj.active_ingredient
							}
						})
						this.prohibitedChipsForm[key].bindData(dataArr)
					})
			}
		}
	}

	@action async loadDefaultDiagnosis(form) {
		// const providerId = getSelectedOrgUnitID();
		// const patientId = DataStore.patientDTO.get("patientID");
		// const filter = {
		//   "filters": [
		//     {
		//       "associated_column": "provider_id",
		//       "values": [
		//         {
		//           "id_value": providerId,
		//         }
		//       ]
		//     },
		//     {
		//       "associated_column": "patient_id",
		//       "values": [
		//         {
		//           "id_value": patientId,
		//         }
		//       ]
		//     },
		//     {
		//       "associated_column": "type_id",
		//       "values": [
		//         {
		//           "id_value": "EHR.Data.Ambulance",
		//         }
		//       ]
		//     }
		//   ],
		//   "time_from": moment.utc().startOf("day").format(),
		//   "time_to": moment.utc().endOf("day").format()
		// }

		// api.loadEHROverview(filter).call().then(response => {
		//   if (isSafe(response) && isSafe(response.rows) && isSafe(response.rows[0]) && isSafe(response.rows[0].diagnosis)) {
		//     form.$("diagnosis").value = response.rows[0].diagnosis
		//   }
		// })

		//Ak je posledný dekurz z dnešného dňa tak predplníme z neho diagnózu
		if (DataStore.patientDTO) {
			const lastRecord = DataStore.patientDTO.get("lastRecord")
			if (lastRecord && moment(lastRecord.created_at).isSame(moment(), "day")) {
				form.$("diagnosis").value = lastRecord.diagnosis
				if (isSafe(lastRecord.substituting_doctor)) {
					form.$("substitutingPersonnelExpertiseId").value = lastRecord.substituting_doctor.personnel_expertise_id
					const searchRequest = getFilters([
						`personnel_expertise_id=${lastRecord.substituting_doctor.personnel_expertise_id}`
					])
					let response = await api.loadProviders(searchRequest).call()
					if (isSafe(response) && isSafe(response.rows)) {
						let oupzs = isNotEmpty(response.rows[0].provider_id) ? response.rows[0].provider_id : ""
						if (isNotEmpty(oupzs)) {
							// setTimeout(function() {
							// 	GlobalStore.handleClickFocus("patientPrescriptionForm")
							// }, 1000)
							form.$("substitutingDoctorOUPZS").value = oupzs
						}
					}
				}
			}
		}
	}

	deleteDraft = (forms, deleteFromStorage) => {
		UIStore.isFormSaving = true
		let draftData = []
		Object.keys(forms).forEach((name) => {
			draftData.push({
				_id: forms[name].$("recordId").value,
				_type: "EHR.Data.Record"
			})
		})
		api
			.deletePrescriptions(draftData)
			.call()
			.then(() => {
				const providerID = getSelectedOrgUnitID()
				DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
					DataStore.setPatientEHR(overview)
					// PatientPrescriptionContainerStore.clearPrescriptions()
					UIStore.isFormSaving = false
					// UIStore.patientCardTab = ""
					deleteFromStorage()

					let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
					if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
						DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
					}
				})
			})
		GlobalStore.setNotificationMessage("Common.label.prescriptionDraftDeleted")
	}

	deletePrescriptionsOnCancel = (forms, deleteFromStorage) => {
		UIStore.isFormSaving = true
		let deleteData = []
		Object.keys(forms).forEach((name) => {
			let data = forms[name].get("value")
			if (data.version == "final" && isNotEmpty(data.recordId)) {
				deleteData.push({
					_id: data.recordId,
					_type: "EHR.Data.Record"
				})
			}
		})
		api
			.deletePrescriptions(deleteData)
			.call()
			.then((response) => {
				UIStore.isFormSavingMessage = "Čakám na zdravotnú poisťovňu"
				let xmlB64Data = []
				let cardNotInserted = false
				let singnatureData = []
				Object.keys(response).forEach((obj) => {
					let res = response[obj]
					xmlB64Data.push({
						RecordId: res._id,
						XmlData:
							isSafe(res.ixs_signer_data) &&
							isSafe(res.ixs_signer_data.payload) &&
							isSafe(res.ixs_signer_data.payload.text)
								? res.ixs_signer_data.payload.text
								: ""
					})
				})
				// const providerID = getSelectedOrgUnitID();
				const selectedOrgUnit = getSelectedOrgUnit()
				const user = getUser()
				const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
				const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
				const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
				const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
				const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

				const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
					? DataStore.patientDTO.get("patient").validInfo.client
					: null
				const JRUZIDObj = isSafe(patientClientInfo.identifiers)
					? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
					: null

				GWApiCall({
					method: "POST",
					uri: `${config.GW_BASE_URL}/SignXmlData`,
					body: JSON.stringify({
						XmlDataItems: xmlB64Data,
						AmbeePatientId: DataStore.patientDTO.get("patientID"),
						PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
						PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
						AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
						AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
						AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
						AmbeePersonnelId: getUserPersonnelID(),
						OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
						PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
						PersonnelExpertiseJRUZVersion: specVer,
						PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
					})
				})
					.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
					.then((data) => {
						GlobalStore.checkEHGWError(data.ErrorCode, data.ErrorMessage, data.IsEhealthException)
						if (isSafe(data) && isSafe(data.XmlDataItems) && data.XmlDataItems.length) {
							Object.keys(data.XmlDataItems).forEach((obj) => {
								if (isSafe(data.XmlDataItems[obj]) && isSafe(data.XmlDataItems)) {
									singnatureData.push({
										record_id: data.XmlDataItems[obj].RecordId,
										signature: data.XmlDataItems[obj].XmlData
									})
								}
							})
						} else {
							cardNotInserted = true
							Object.keys(response).forEach((obj) => {
								singnatureData.push({record_id: response[obj].record_id, signature: ""})
							})
						}

						api
							.signatureMultiplePrescriptions(singnatureData)
							.call()
							.then((response) => {
								UIStore.isFormSavingMessage = ""
								let text = ""
								let confirm = false
								let ids = []
								Object.keys(response).forEach((obj, index) => {
									if (isSafe(response[obj])) {
										if (isSafe(response[obj].warning)) {
											text +=
												response[obj].warning.text +
												(Object.keys(response).length != index || response[obj].warning.text != "" ? "" : "<br/>")
											if (!confirm) confirm = response[obj].warning.confirm
										}
										ids.push(response[obj].record_id)
									}
								})
								if (cardNotInserted) {
									if (isNotEmpty(text)) {
										text += "<br/>"
									}
									text +=
										"Záznam nebolo možné podpísať, skontrolujte, či máte vloženú kartu v čítačke. Chcete napriek tomu pokračovať?"
									confirm = true
								}

								if (isNotEmpty(text)) {
									StornoPrescriptionDialogStore.open(text, true)
								}
								if (isEmpty(text)) {
									StornoPrescriptionDialogStore.open("Operácia prebehla úspešne", false)
								}

								const providerID = getSelectedOrgUnitID()
								DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
									DataStore.setPatientEHR(overview)
									PatientPrescriptionContainerStore.clearPrescriptions()
									UIStore.isFormSaving = false
									UIStore.isFormSavingMessage = ""
									deleteFromStorage()
									// UIStore.patientCardTab = ""
								})
							})
					})
					.catch(() => {
						let text = ""
						let confirm = false
						let ids = []
						Object.keys(response).forEach((obj, index) => {
							if (isSafe(response[obj])) {
								if (isSafe(response[obj].warning)) {
									text +=
										response[obj].warning.text +
										(Object.keys(response).length != index || response[obj].warning.text != "" ? "" : "<br/>")
									if (!confirm) confirm = response[obj].warning.confirm
								}
								ids.push(response[obj].record_id)
							}
						})
						if (cardNotInserted) {
							if (isNotEmpty(text)) {
								text += "<br/>"
							}
							text +=
								"Záznam nebolo možné podpísať, skontrolujte, či máte vloženú kartu v čítačke. Chcete napriek tomu pokračovať?"
							confirm = true
						}
						api
							.signatureMultiplePrescriptions(singnatureData)
							.call()
							.then((response) => {
								let text = ""
								let confirm = false
								let ids = []
								Object.keys(response).forEach((obj, index) => {
									if (isSafe(response[obj])) {
										if (isSafe(response[obj].warning)) {
											text +=
												response[obj].warning.text +
												(Object.keys(response).length != index || response[obj].warning.text != "" ? "" : "<br/>")
											if (!confirm) confirm = response[obj].warning.confirm
										}
										ids.push(response[obj].record_id)
									}
								})
								if (cardNotInserted) {
									if (isNotEmpty(text)) {
										text += "<br/>"
									}
									text +=
										"Záznam nebolo možné podpísať, skontrolujte, či máte vloženú kartu v čítačke. Chcete napriek tomu pokračovať?"
									confirm = true
								}

								if (isNotEmpty(text)) {
									StornoPrescriptionDialogStore.open(response.warning.text, true)
								}
								if (isEmpty(text)) {
									StornoPrescriptionDialogStore.open("Operácia prebehla úspešne", false)
								}
								const providerID = getSelectedOrgUnitID()
								DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
									DataStore.setPatientEHR(overview)
									PatientPrescriptionContainerStore.clearPrescriptions()
									UIStore.isFormSaving = false
									UIStore.patientCardTab = ""
								})
							})
					})
			})
	}

	checkDrugsHistory(productId) {
		api
			.loadPatientOverview(
				getSelectedOrgUnitID(),
				DataStore.patientDTO.get("patientID"),
				[],
				moment()
					.startOf("day")
					.utc()
					.format(),
				moment()
					.endOf("day")
					.utc()
					.format()
			)
			.call()
			.then((response) => {
				let data = response.rows
				if (isSafe(data)) {
					let filteredData = data.filter((i) => i.type_code == "RX" && i.version === "final")
					if (isSafe(filteredData.find((i) => i.product_id == productId))) {
						GlobalStore.confirmationDialogOpened = "xsCheckDrugs"
					}
				}
			})
	}

	loadPrescriptionLimitations(id, formKey) {
		const searchRequest = {
			filters: [
				{
					associated_column: "product_id",
					predicate: "=",
					values: [
						{
							id_value: id
						}
					]
				}
			]
			// "light": true
		}

		api
			.loadlimitations(searchRequest)
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.rows)) {
					const allTexts = response.rows.map((data) => data.name_ext)
					this.prescriptionLimitations[formKey] = [...new Set(allTexts)]
				}
			})
	}

	checkValidationRules = async (form) => {
		form.$("firstRadioValidator").set("rules", "required")
		if (form.$("repeatType").value == "0") {
			form.$("repeatTypeSecondary").set("rules", "required")
			form.$("maxRepeats").set("rules", "required|integer|min:2")
		} else {
			form.$("repeatTypeSecondary").set("rules", "")
			form.$("maxRepeats").set("rules", "")
		}
		form.$("drugs").set("rules", "")
		form.$("medEquip").set("rules", "")
		form.$("medFood").set("rules", "")
		switch (form.$("prescriptionType").value) {
			case "RBULK":
				form.$("drugs").set("rules", "required")
				break
			case "MEDEQUIP":
				form.$("medEquip").set("rules", "required")
				break
			case "RDIETETIC":
				form.$("medFood").set("rules", "required")
				break
		}

		if (form.$("quantization").value === "FIX") {
			form.$("medicationRoute").set("rules", "required")
			if (form.$("prescriptionType").value == "RBULK" || form.$("prescriptionType").value == "RDIETETIC") {
				form.$("unit").set("rules", "required")
			} else {
				form.$("unit").set("rules", "")
			}
			form.$("repeatingMoment").set("rules", "required")
		} else {
			form.$("medicationRoute").set("rules", "")
			form.$("repeatingMoment").set("rules", "")
			form.$("unit").set("rules", "")
		}

		if (form.$("quantization").value === "FIX" && form.$("quantizationRadio").value == "first") {
			if (+form.$("morning").value + +form.$("lunch").value + +form.$("evening").value + +form.$("sleep").value <= 0) {
				form.$("firstRadioValidator").set("rules", "required")
			} else {
				form.$("firstRadioValidator").set("rules", "")
			}
		} else {
			form.$("firstRadioValidator").set("rules", "")
		}
		if (form.$("quantization").value === "FIX" && form.$("quantizationRadio").value == "second") {
			if (
				+form.$("dosage").value + +form.$("everyHour").value <= 0 ||
				+form.$("dosage").value <= 0 ||
				+form.$("everyHour").value <= 0
			) {
				form.$("dosage").set("rules", "required")
				form.$("everyHour").set("rules", "required|min:1")
			}
		} else if (form.$("quantization").value !== "FIX") {
			if (form.$("quantization").value == "VAR") {
				form.$("dosage").set("rules", "required|min:3")
				form.$("everyHour").set("rules", "")
			} else {
				form.$("dosage").set("rules", "required")
				form.$("everyHour").set("rules", "")
			}
		} else {
			form.$("dosage").set("rules", "")
			form.$("everyHour").set("rules", "")
		}
		if (
			isNotEmpty(form.$("sendingDoctorPersonnelExpertiseId").value) ||
			isNotEmpty(form.$("doctorOUPZS").value) ||
			isNotEmpty(form.$("doctorDate").value)
		) {
			form.$("sendingDoctorPersonnelExpertiseId").set("rules", "required")
			form.$("doctorOUPZS").set("rules", "required")
			form.$("doctorDate").set("rules", "required")
		} else {
			form.$("sendingDoctorPersonnelExpertiseId").set("rules", "")
			form.$("doctorOUPZS").set("rules", "")
			form.$("doctorDate").set("rules", "")
		}
		if (
			isNotEmpty(form.$("substitutingPersonnelExpertiseId").value) ||
			isNotEmpty(form.$("substitutingDoctorOUPZS").value)
		) {
			form.$("substitutingPersonnelExpertiseId").set("rules", "required")
			form.$("substitutingDoctorOUPZS").set("rules", "required")
		} else {
			form.$("substitutingPersonnelExpertiseId").set("rules", "")
			form.$("substitutingDoctorOUPZS").set("rules", "")
		}
		if (form.$("forcedSave").value) {
			form.$("forcedSaveReason").set("rules", "required")
		} else {
			form.$("forcedSaveReason").set("rules", "")
		}

		if (form.$("prescriptionType").value == "MEDEQUIP") {
			if (isSafe(form.$("medEquipIndividual").value) && form.$("medEquipIndividual").value === true) {
				form.$("medDevDescription").set("rules", "required")
				form.$("prescriptionReason").set("rules", "required")
				form.$("showDetails").value = true
			} else if (form.$("permanentDisability").value) {
				form.$("prescriptionReason").set("rules", "required")
				form.$("showDetails").value = true
			} else {
				form.$("medDevDescription").set("rules", "")
				form.$("prescriptionReason").set("rules", "")
			}
		} else {
			if (form.$("permanentDisability").value) {
				form.$("prescriptionReason").set("rules", "required")
			} else {
				form.$("prescriptionReason").set("rules", "")
			}
			form.$("medDevDescription").set("rules", "")
		}
	}

	@action async loadLastRedcordSendingDoctor(form) {
		let response = await api
			.loadPatientOverview(
				getSelectedOrgUnitID(),
				DataStore.patientDTO.get("patientID"),
				[],
				moment()
					.startOf("day")
					.utc()
					.format(),
				moment()
					.endOf("day")
					.utc()
					.format()
			)
			.call()
		let data = response.rows
		if (isSafe(data)) {
			let filteredData = data.find((i) => i.type_code == "DEK" && isSafe(i.substituting_doctor_card))
			if (isSafe(filteredData) && isSafe(filteredData.substituting_doctor_card)) {
				form.$("substitutingPersonnelExpertiseId").value = filteredData.substituting_doctor_card.personnel_expertise_id
				form.$("substitutingDoctorOUPZS").value = filteredData.substituting_provider_id
			}
		}
	}

	loadFormWithDefs = async (form, props) => {
		await this.loadDefaultDiagnosis(form)
		await this.loadLastRedcordSendingDoctor(form)
		if (
			form.$("quantization").value === "FIX" ||
			form.$("quantization").value === "XYZW" ||
			form.$("quantization").value === "AE"
		) {
			form.$("quantization").value = "FIX"
			form.$("dosage").set(
				"label",
				props.intl.formatMessage({
					id: "Patient.form.patientPrescription.numberOfTablets"
				})
			)

			if (form.$("quantization").value === "AE") {
				form.$("quantizationRadio").value = "second"
			} else if (form.$("quantization").value === "XYZW") {
				form.$("quantizationRadio").value = "first"
			}
			// this.setState({showArea: false})!!!!!!!!!!!!!!!!!

			form.$("showArea").value = false

			// if (!initLoad) {
			// 	form.$("repeatingMoment").value = "D1"
			// 	form.$("drugs").options.forceRefresh()
			// }
		} else {
			form.$("dosage").set("label", "")
			// if (fromConstructor) {
			// 	this.state.showArea = true
			// } else { !!!!!!!!!!!!!!
			// 	this.setState({showArea: true})
			// }
			form.$("showArea").value = true
			form.$("unit").value = ""
			form.$("medicationRoute").value = ""
			form.$("repeatingMoment").value = ""
			form.$("days").value = ""
		}

		if (
			isNotEmpty(form.$("sendingDoctor").value) ||
			isNotEmpty(form.$("doctorOUPZS").value) ||
			isNotEmpty(form.$("doctorDate").value) ||
			isNotEmpty(form.$("substitutingDoctor").value) ||
			isNotEmpty(form.$("substitutingDoctorOUPZS").value) ||
			isNotEmpty(form.$("pacientPaysReason").value) ||
			form.$("urgent").value ||
			form.$("opiate").value ||
			form.$("replacementProhibited").value ||
			form.$("exeptionCheck").value ||
			form.$("forcedSave").value ||
			form.$("newBornOnParentIdentifier").value ||
			form.$("permanentDisability").value ||
			isNotEmpty(form.$("note").value) ||
			isNotEmpty(form.$("prescriptionReason").value) ||
			isNotEmpty(form.$("prescriptionNote").value) ||
			isNotEmpty(form.$("hospitalizationDischargeDate").value) ||
			isNotEmpty(form.$("medDevDescription").value)
		) {
			// this.state.showDetails = true
			form.$("showDetails").value = true
			if (form.$("replacementProhibited").value) {
				form.$("showReplacementProhibited").value = true
			}
		}

		this.checkValidationRules(form)
	}

	genererateValidity = (form, props) => {
		//dni_na_pridanie=(periodaOpakovania*pocetOpakovani)+7+pocetOpakovani
		//Platnost=datumVystaveniaReceptu+dni_na_pridanie
		if (
			isNotEmpty(form.$("repeatTypeSecondary").value) &&
			form.$("repeatTypeSecondary").value != "OTH" &&
			isNotEmpty(form.$("maxRepeats").value) &&
			isSafe(GlobalStore.CL["medicationRepeats"])
		) {
			let duration = GlobalStore.CL["medicationRepeats"].find((row) => row.code == form.$("repeatTypeSecondary").value)
				.duration
			duration = duration / 86400
			//MEDEQUIP RBULK
			let daysToAdd =
				duration * +form.$("maxRepeats").value +
				(form.$("prescriptionType").value == "MEDEQUIP" ? (form.$("medEquipIndividual").value == true ? 90 : 31) : 7) +
				+form.$("maxRepeats").value
			let date = moment()
				.add(daysToAdd, "days")
				.endOf("day")
			form.$("validity").value = date
			let prescriptionDate = moment()
				.add(daysToAdd, "days")
				.endOf("day")
			let datePlusYear = moment().add(1, "years")
			if (prescriptionDate.isSameOrAfter(datePlusYear)) {
				WarningStore.open(props.intl.formatMessage({id: "Common.label.prescriptionDurationWarning"}))
			}
		} else if (isNotEmpty(form.$("repeatPlain").value)) {
			let daysToAdd =
				form.$("repeatPlain").value * +form.$("maxRepeats").value +
				(form.$("prescriptionType").value == "MEDEQUIP" ? (form.$("medEquipIndividual").value == true ? 90 : 31) : 7) +
				+form.$("maxRepeats").value
			form.$("validity").value = moment()
				.add(daysToAdd, "days")
				.endOf("day")
			let prescriptionDate = moment().add(daysToAdd, "days")
			let datePlusYear = moment().add(1, "years")
			if (prescriptionDate.isSameOrAfter(datePlusYear)) {
				WarningStore.open(props.intl.formatMessage({id: "Common.label.prescriptionDurationWarning"}))
			}
		}
	}

	@action async loadBindingCallback(form, props, data) {
		if (data.version == "draft") {
			form.$("recordId").set("value", data.record_id)
			if (isSafe(data.relateds)) {
				form.$("relatedEvents").set("value", JSON.stringify(data.relateds))
			}
		}
		form.$("onlyCategorisedDrugs").value = isSafe(data.prouct_full) ? data.prouct_full.categorized : false
		form.$("isDrugCategorized").value = isSafe(data.prouct_full) ? data.prouct_full.categorized : false
		form.$("drugBaseDoseAmount").value = isSafe(data.prouct_full) ? data.prouct_full.base_dose_amount : ""
		form.$("version").value = data.version
		form.$("disableType").value = true
		form.$("prescriptionBySupplement").value = data.prescription_by_supplement
		// if (isSafe(props.checkSaveButtons) && typeof props.checkSaveButtons === "function") {
		// 	props.checkSaveButtons()
		// }
		if (
			isSafe(form.$("dosage").value) &&
			(form.$("dosage_type").value === "FIX" || form.$("dosage_type").value === "XYZW")
		) {
			const dsplit =
				isNotEmpty(form.$("dosage").value) && typeof form.$("dosage").value === "string"
					? form.$("dosage").value.split("-")
					: []
			if (dsplit.length === 4) {
				form.$("morning").value = +dsplit[0] == 0 ? null : +dsplit[0]
				form.$("lunch").value = +dsplit[1] == 0 ? null : +dsplit[1]
				form.$("evening").value = +dsplit[2] == 0 ? null : +dsplit[2]
				form.$("sleep").value = +dsplit[3] == 0 ? null : +dsplit[3]
			}
		}
		if (isSafe(data.duration_mins)) {
			let number = Number(data.duration_mins) / 24 / 60
			form.$("days").value = number > 0 ? number : ""
		}
		if (isSafe(form.$("flags").value)) {
			const flags = form.$("flags").value
			form.$("urgent").set("value", flags.includes("N"))
			form.$("pacientPays").set("value", flags.includes("P"))
			form.$("opiate").set("value", flags.includes("O"))
		}
		if (form.$("repeatType").value != 1) {
			form.$("repeatTypeSecondary").value = form.$("repeatType").value
			form.$("repeatType").set("value", "0")
		}
		form.$("drugs").value = data.product_id
		if (form.$("prescriptionType").value == "RBULK" && isSafe(data.prouct_full) && isSafe(data.prouct_full.atc)) {
			form.$("disableRepeat").value = data.prouct_full.atc.antibiotic || data.prouct_full.atc.opioid
			form.$("disableOpiateCheckbox").value = data.prouct_full.atc.opioid
		}
		if (form.$("prescriptionType").value == "MEDEQUIP") {
			form.$("medEquip").value = data.product_id
			if (isSafe(data.prouct_full)) {
				form.$("medEquipIndividual").value = data.prouct_full.individual
			}
			form.$("drugs").set("value", "")
		}
		if (form.$("prescriptionType").value == "RDIETETIC") {
			form.$("medFood").value = data.product_id
			form.$("drugs").set("value", "")
		}
		form.$("quantization").set("value", data.dosage_type)
		// handleChangeQuantization(data.dosage_type, true)
		if (form.$("dosage_type").value === "AE") {
			form.$("quantizationRadio").value = "second"
		} else if (form.$("dosage_type").value === "XYZW") {
			form.$("quantizationRadio").value = "first"
		}
		if (
			isSafe(data.sending_provider_card) &&
			isSafe(data.sending_provider_card.company) &&
			isSafe(data.sending_provider_card.company_id)
		) {
			form.$("doctorOUPZS").value = data.sending_provider_card.org_unit_id
		}
		form.$("doctorDate").value =
			isSafe(form.$("doctorDate").value) && isNotEmpty(form.$("doctorDate").value)
				? moment(form.$("doctorDate").value)
				: ""
		form.$("recommendationDateValidity").value =
			isSafe(form.$("recommendationDateValidity").value) && isNotEmpty(form.$("recommendationDateValidity").value)
				? moment(form.$("recommendationDateValidity").value)
				: ""
		// handleChangeQuantization(form.$("quantization").value, true)
		this.ingredientsChips = {}
		if (isSafe(data.components) && data.components.length) {
			this.ingredientsChips[props.keyVal] = data.components.map((i) => {
				return {
					col1: i.component_id._id,
					col2: +i.quantity,
					col3: i.unit_id
				}
			})
			if (isSafe(this.ingredientsChips[props.keyVal])) {
				form.$("ingredientsChipsForm").value = JSON.stringify(this.ingredientsChips[props.keyVal])
				await this.loadIPLChipText(form)
			}
		}
		if (isSafe(data.prohibited_drugs) && data.prohibited_drugs.length) {
			this.prohibitedChips[props.keyVal] = data.prohibited_drugs.map((i) => {
				let col1NameExt = ""
				col1NameExt = isNotEmpty(i.labelText)
					? i.labelText
					: i.code_ext +
					  " | " +
					  i.name_ext +
					  " | " +
					  i.supplement +
					  " | " +
					  (i.patient_payment ? i.patient_payment + " | " : "") +
					  (i.insurer_payment ? i.insurer_payment + " | " : "") +
					  i.atc +
					  " | " +
					  i.active_ingredient
				return {
					col1: i._id,
					col1NameExt: col1NameExt
				}
			})
		}

		if (form.$("isDrugCategorized").value == false && form.$("prescriptionType").value == "RBULK") {
			form.$("pacientPaysReason").value = "JASAM"
		}

		if (
			isNotEmpty(form.$("sendingDoctor").value) ||
			isNotEmpty(form.$("doctorOUPZS").value) ||
			isNotEmpty(form.$("doctorDate").value) ||
			isNotEmpty(form.$("substitutingDoctor").value) ||
			isNotEmpty(form.$("substitutingDoctorOUPZS").value) ||
			isNotEmpty(form.$("pacientPaysReason").value) ||
			form.$("urgent").value ||
			form.$("opiate").value ||
			form.$("replacementProhibited").value ||
			form.$("exeptionCheck").value ||
			form.$("forcedSave").value ||
			form.$("newBornOnParentIdentifier").value ||
			form.$("permanentDisability").value ||
			isNotEmpty(form.$("note").value) ||
			isNotEmpty(form.$("prescriptionReason").value) ||
			isNotEmpty(form.$("prescriptionNote").value) ||
			isNotEmpty(form.$("hospitalizationDischargeDate").value) ||
			isNotEmpty(form.$("medDevDescription").value)
		) {
			form.$("showDetails").value = true
			if (form.$("replacementProhibited").value) {
				form.$("showReplacementProhibited").value = true
				if (isSafe(this.prohibitedChips[props.keyVal])) {
					form.$("prohibitedChipsForm").value = JSON.stringify(this.prohibitedChips[props.keyVal])
				}
			}
		}
		if (isNotEmpty(data.repeats_type_plain)) {
			form.$("repeatPlain").value = data.repeats_type_plain / 86400
		}

		form.$("disableRepeat").value = !(
			form.$("prescriptionType").value == "RBULK" || form.$("prescriptionType").value == "MEDEQUIP"
		)
		if (isEmpty(form.$("days").value)) {
			this.generateDrugDays(form)
		}
		if (
			form.$("dosage_type").value === "FIX" ||
			form.$("dosage_type").value === "XYZW" ||
			form.$("dosage_type").value === "AE"
		) {
			form.$("quantization").value = "FIX"
			form.$("dosage").set("label", props.intl.formatMessage({id: "Patient.form.patientPrescription.numberOfTablets"}))
			if (form.$("dosage_type").value === "FIX" || form.$("dosage_type").value === "XYZW") {
				form.$("dosage").value = ""
			}
			if (form.$("dosage_type").value === "AE") {
				form.$("quantizationRadio").value = "second"
			} else if (form.$("dosage_type").value === "XYZW") {
				form.$("quantizationRadio").value = "first"
			}
			// setState({showArea: false})
			form.$("showArea").value = false
		} else {
			form.$("dosage").set("label", "")
			form.$("showArea").value = true
			form.$("unit").value = ""
			form.$("medicationRoute").value = ""
			form.$("repeatingMoment").value = ""
			form.$("days").value = ""
		}

		this.genererateValidity(form, props)
		this.checkValidationRules(form)

		// this.represcribeFlag = true
		// actionPatientInfo.saveFormToStorage(null, form, "PatientPrescriptionForm", 0, null)
	}

	@action async loadFormData(form, id, props) {
		// this.represcribeLoaded = true
		form.reset()
		form.$("onlyCategorisedDrugs").value = false
		await loadDataToFormBySchemeSync(form, dataBindings, {prescriptionId: id}, async (data) => {
			if (isSafe(UIStore.patientCardDivRef)) {
				UIStore.patientCardDivRef.scrollTo(0, 0)
			}
			await this.loadBindingCallback(form, props, data)
		})
	}

	@action async loadFormDataFromTemplate(form, dataToinsert, props) {
		form.reset()
		form.$("onlyCategorisedDrugs").value = false
		await loadDataToFormBySchemeSyncWithUri(form, dataToinsert, dataBindings, null, async (data) => {
			await this.loadBindingCallback(form, props, data)
		})
	}

	@action setDrugPrice = (keyVal, patient_payment, fullPrice) => {
		this.drugPrices.set(keyVal, patient_payment)
		this.drugPricesFull.set(keyVal, fullPrice)
	}

	// @action checkRepetitur = (drug, message) => {
	// 	let patientData = DataStore.patientDTO.get("patientEHR").rows
	// 	if (isSafe(patientData)) {
	// 		patientData = patientData.filter(
	// 			(row) =>
	// 				row.type_code == "RX" &&
	// 				isSafe(row.prescription) &&
	// 				+row.prescription.repeats_number > 0 &&
	// 				row.prescription.validity.now &&
	// 				row.prescription.product_code == drug.code_ext
	// 		)
	// 		if (patientData.length) {
	// 			WarningStore.open(message)
	// 		}
	// 	}
	// }

	@action checkRepetitur = async (forms, intl) => {
		let patientData = DataStore.patientDTO.get("patientEHR").rows
		let drugData = []
		let medEquipData = []
		let repeatingDrugsCodes = []
		let repeatingMedEquipCodes = []

		let repeatingFormsKeys = Object.keys(forms).filter((formKey) => {
			let form = forms[formKey]
			return isEmpty(form.$("recordId").value) && form.$("repeatType").value == "0"
		})

		if (isSafe(repeatingFormsKeys)) {
			repeatingFormsKeys.forEach((key) => {
				repeatingDrugsCodes.push(forms[key].$("drugs").value)
				repeatingMedEquipCodes.push(forms[key].$("medEquip").value)
			})

			repeatingDrugsCodes = repeatingDrugsCodes.filter((x) => isNotEmpty(x))
			repeatingMedEquipCodes = repeatingMedEquipCodes.filter((x) => isNotEmpty(x))
		}

		let drugResponse = null
		if (isSafe(repeatingDrugsCodes) && Array.isArray(repeatingDrugsCodes) && repeatingDrugsCodes.length > 0) {
			let searchRequest = getFilters(repeatingDrugsCodes.map((code) => `${"code"}=${code}`))
			let response = await api.loadDrugs(searchRequest).call()
			drugResponse = response
			if (isSafe(patientData) && isSafe(response) && isSafe(response.rows) && response.rows.length) {
				drugData = patientData.filter(
					(row) =>
						row.type_code == "RX" &&
						isSafe(row.prescription) &&
						+row.prescription.repeats_number > 1 &&
						row.prescription.validity.now &&
						isSafe(response.rows.find((drug) => drug.code_ext == row.prescription.product_code))
				)
			}
		}

		let medEquipResponse
		if (isSafe(repeatingMedEquipCodes) && Array.isArray(repeatingMedEquipCodes) && repeatingMedEquipCodes.length > 0) {
			let searchRequest = getFilters(repeatingMedEquipCodes.map((code) => `${"code"}=${code}`))
			let response = await api.loadMedDev(searchRequest).call()
			medEquipResponse = response
			if (isSafe(patientData) && isSafe(response) && isSafe(response.rows) && response.rows.length) {
				medEquipData = patientData.filter(
					(row) =>
						row.type_code == "RX" &&
						isSafe(row.prescription) &&
						+row.prescription.repeats_number > 1 &&
						row.prescription.validity.now &&
						isSafe(response.rows.find((drug) => drug.code_ext == row.prescription.product_code))
				)
			}
		}

		let warning = ""

		if (medEquipData.length) {
			warning += intl.formatMessage(
				{id: "Common.label.repetiturMedEquipWarning"},
				{
					drug: `${medEquipData.map((row) => {
						let drug = medEquipResponse.rows.find((drug) => drug.code_ext == row.prescription.product_code)
						if (isSafe(drug)) {
							return `${drug.name_ext}`
						} else {
							return ""
						}
					})}`
				}
			)
		}

		if (drugData.length) {
			warning += intl.formatMessage(
				{id: "Common.label.repetiturDrugWarning"},
				{
					drug: drugData.map((row) => {
						let drug = drugResponse.rows.find((drug) => drug.code_ext == row.prescription.product_code)
						if (isSafe(drug)) {
							return `${drug.name_ext} ${drug.supplement}`
						} else {
							return ""
						}
					})
				}
			)
		}

		if (isNotEmpty(warning)) {
			// intl.formatMessage({id: "Common.label.repetiturDrugWarning"}, {drug: `${warning}`})
			WarningStore.open(warning)
		}
	}

	@action async checkIfNonPayer(form) {
		let patientCheck
		try {
			patientCheck = await api.overpoistenca(DataStore.patientDTO.get("patientID"), getSelectedOrgUnitID(), true).call()
		} catch (err) {
			// form.$("pacientPaysReason").value = "JASAM"
			// form.$("showDetails").value = true
		}
		if (
			(isSafe(patientCheck) && patientCheck.ma_narok_na_odkladnu_zs == false) ||
			(isSafe(DataStore.patientDTO.get("insurer")) && isSafe(DataStore.patientDTO.get("insurer").match(/^40 -\W/g)))
		) {
			form.$("pacientPaysReason").value = "JASAM"
			form.$("showDetails").value = true
		}
	}

	@action generateDrugDays = (form) => {
		if (form.$("prescriptionType").value == "RBULK") {
			const drugAmount = +form.$("drugBaseDoseAmount").value
			const packs = +form.$("packs").value
			let dailyDose

			if (isNotEmpty(drugAmount)) {
				if (form.$("quantizationRadio").value == "first") {
					dailyDose =
						+form.$("morning").value + +form.$("lunch").value + +form.$("evening").value + +form.$("sleep").value
				} else {
					if (isNotEmpty(form.$("everyHour").value) && isNotEmpty(form.$("dosage").value)) {
						dailyDose = (24 / +form.$("everyHour").value) * +form.$("dosage").value
					} else {
						dailyDose = 0
					}
				}

				let result = (drugAmount * packs) / dailyDose
				if (result < 1) {
					result = 0
				}
				result = Math.round(result)
				form.$("days").value = result != Infinity && result > 0 ? result : ""
			}
		}
	}

	@action checkInsurerInvoice = (form) => {
		const insurers = getUserMedInsurers()
		const insurerId = DataStore.patientDTO.get("insurerId")
		if (isSafe(insurers) && isNotEmpty(insurerId)) {
			const invoiceObj = insurers.find((row) => row.insurer_id == insurerId)
			if (isSafe(invoiceObj) && invoiceObj.covered === 0) {
				form.$("pacientPaysReason").value = "JASAM"
				form.$("showDetails").value = true
			}
		}
	}
}

var singleton = new PatientPrescriptionStore()
export default singleton
