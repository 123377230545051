"use strict"

import {observable, action} from "mobx"

class AgreementPartDepartmentsStore {
	@observable isOpen = false

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}
}

var singleton = new AgreementPartDepartmentsStore()
export default singleton
