import React from "react"
import {observer} from "mobx-react"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import {getFieldIdentifier} from "../../helpers/actions"

export default observer(({field, onChange, radioButtons}) => {
	const handleOnChange = (field) => (e) => {
		field.set(e.target.value)
		if (typeof onChange == "function") onChange(e.target.value)
	}
	// must be here binding mechanism to UI scheme
	//field.set("bindings", "MaterialTextField")

	let classIdentifier = getFieldIdentifier(field, "xs-radio-button-group")

	return (
		<div>
			<RadioGroup className={classIdentifier} {...field.bind({onChange: handleOnChange(field)})}>
				{radioButtons !== undefined &&
					radioButtons.map((option, index) => (
						<FormControlLabel
							key={index}
							value={option.value}
							label={option.label}
							style={option.style}
							control={
								<Radio
									key={index}
									disabled={option.disabled}
									className={option.className}
									value={option.value}
									label={option.label}
									style={{
										width: "auto"
									}}
								/>
							}
						/>
					))}
			</RadioGroup>
		</div>
	)
})
