import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import {Grid, Tooltip} from "@material-ui/core"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
// import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
// import api from "../../../actions/api"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
// import RouterStore from "../../../../../global/store/RouterStore"
import HospicomReportStore from "../../../stores/HospicomReportStore"
import UIStore from "../../../stores/UIStore"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import GlobalStore from "../../../../../global/store/GlobalStore"
import TableStore from "../../../stores/TableStore"
// import ExportDraftReportDialog from "./exportDraftReportDialog"
import moment from "moment"
import RegistersCacheStore from "../../../../../global/store/RegistersCacheStore"
import HospicomDialog from "../../patientDetail/patientCard/hospicom/hospicomDialog"
import HospicomDialogStore from "../../../stores/HospicomDialogStore"
// import {getSelectedOrgUnitID} from "../../../../../global/helpers/actions"

@injectIntl
@observer
export default class HospicomReportList extends React.Component {
	constructor(props) {
		super(props)
		UIStore.isFormSaving = false
		HospicomReportStore.hospicomList = []

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				if (
					TableStore.tables.hasOwnProperty("HospicomReportList") &&
					TableStore.tables["HospicomReportList"].hasOwnProperty("index")
				) {
					delete TableStore.tables["HospicomReportList"]["index"]
				}

				HospicomReportStore.loadHospicomReport(form, props.intl.formatMessage({id: "Common.label.dateFilterRequired"}))
			},
			onError() {}
		}

		if (isEmpty(HospicomReportStore.formRef)) {
			HospicomReportStore.formRef = new MobxReactForm(
				{
					fields: {
						date: {
							label: <FormattedMessage id="Common.label.date" />,
							type: "date"
						},
						datefrom: {
							label: <FormattedMessage id="Common.label.createdFrom" />,
							type: "date",
							value: moment().startOf("month")
						},
						dateto: {
							label: <FormattedMessage id="Common.label.createdTo" />,
							type: "date",
							value: moment().endOf("month")
						},
						insurer: {
							label: <FormattedMessage id="Common.label.insurer" />,
							type: "string"
						},
						healthCareStatus: {
							label: <FormattedMessage id="Common.label.state" />
						},
						personIdentifier: {
							label: <FormattedMessage id="Patient.labels.IDNumber" />
						}
					}
				},
				{plugins: {dvr: validatorjs}, hooks, bindings}
			)
		}

		this.form = HospicomReportStore.formRef
		HospicomReportStore.checkedRecordIds = []

		HospicomReportStore.loadHospicomReport(this.form, props.intl.formatMessage({id: "Common.label.dateFilterRequired"}))
	}

	onHandleRowClick = (dataRow) => {
		if (dataRow.record_id) {
			HospicomDialogStore.load(dataRow)
		}
	}

	routeToDetail = (dataRow) => {
		if (dataRow.personId) {
			const currOrgunit = GlobalStore.orgunits.find((x) => x.code_ext == dataRow.providerCodeExt)

			if (isSafe(currOrgunit)) {
				GlobalStore.changeOrgUnitAndRouteToPatientDetail(currOrgunit._id, dataRow.personId)
			}
		}
	}

	render() {
		HospicomReportStore.isChangedFilter(this.form.values())

		const dateFormat = this.props.intl.formatMessage({id: "Application.moment.dateformat"})

		const exportBatchDisabled =
			isEmpty(this.form.$("healthCareStatus").value) ||
			this.form.$("healthCareStatus").value == "A" ||
			this.form.$("healthCareStatus").value == "D" ||
			this.form.$("healthCareStatus").value == "S" ||
			this.form.$("healthCareStatus").value == "ES" ||
			isEmpty(this.form.$("date").value) ||
			isEmpty(this.form.$("insurer").value) ||
			!HospicomReportStore.checkedRecordIds.length

		const dateDisabled =
			isEmpty(this.form.$("healthCareStatus").value) ||
			this.form.$("healthCareStatus").value == "A" ||
			this.form.$("healthCareStatus").value == "D" ||
			this.form.$("healthCareStatus").value == "S" ||
			this.form.$("healthCareStatus").value == "ES"

		return (
			<Grid container className="xs-draftReport-list">
				<Grid item xs={12}>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={1}>
							<Tooltip title={"Pre stav N,P,X - dátum navrh zadaný. Pre stav V - dátum skut. nástupu"}>
								<XsDateTimePicker
									white
									field={this.form.$("date")}
									disabled={dateDisabled}
									showTimeSelect={false}
									onChange={() => {
										HospicomReportStore.checkedRecordIds = []
									}}
								/>
							</Tooltip>
						</Grid>
						<Grid item xs={2}>
							<XsSearchSelect
								white
								field={this.form.$("healthCareStatus")}
								items={GlobalStore.CL.healthCareStatus.sort((a, b) => {
									return a.item_order - b.item_order
								})}
								onChange={(val) => {
									if (isEmpty(val) || (val != "N" && val != "E" && val != "P" && val != "C")) {
										this.form.$("date").value = null
									}
									HospicomReportStore.checkedRecordIds = []
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsSearchSelect
								white
								field={this.form.$("insurer")}
								items={RegistersCacheStore.insurersRegister}
								onChange={() => {
									HospicomReportStore.checkedRecordIds = []
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsInput
								white
								field={this.form.$("personIdentifier")}
								onChange={() => {
									HospicomReportStore.checkedRecordIds = []
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsDateTimePicker
								white
								field={this.form.$("datefrom")}
								showTimeSelect={false}
								onChange={() => {
									HospicomReportStore.checkedRecordIds = []
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsDateTimePicker
								white
								field={this.form.$("dateto")}
								showTimeSelect={false}
								onChange={() => {
									HospicomReportStore.checkedRecordIds = []
								}}
							/>
						</Grid>
						<Grid item xs={1} className="pb-0">
							<XsIconButton
								rect
								className="xs-default xs-outline"
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								onClick={this.form.onSubmit}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
						<Grid item xs={3}>
							<Grid container justify="flex-end" spacing={8}>
								<Grid item>
									<XsButton
										className={HospicomReportStore.changedFilters ? "xs-default xs-outline" : "xs-primary"}
										disabled={HospicomReportStore.changedFilters}
										text={<FormattedMessage id="Common.label.export" />}
										icon={<i className="fal fa-file-excel fa-lg" />}
										onClick={() => {
											HospicomReportStore.loadHospicomReport(this.form, null, true)
										}}
									/>
								</Grid>
								<Grid item>
									<XsButton
										disabled={exportBatchDisabled}
										className={exportBatchDisabled ? "xs-default xs-outline" : "xs-primary"}
										text={<FormattedMessage id="Capitation.form.ExportBatch" />}
										icon={<i className="fal fa-file-excel fa-lg" />}
										onClick={() => {
											HospicomReportStore.export(
												this.form,
												this.props.intl.formatMessage({id: "Common.label.dateFilterRequired"}),
												HospicomReportStore.checkedRecordIds
											)
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{UIStore.isFormSaving ? (
						<XsLoading />
					) : HospicomReportStore.changedFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i className="far fa-search fa-lg mr-3 pointer" onClick={this.form.onSubmit}></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTable
							setRef={(table) => (HospicomReportStore.tableRef = table)}
							config={{
								columnDefs: {
									status_code_ext: {
										title: <FormattedMessage id="Common.label.state" />,
										type: "string",
										design: {
											width: "50px",
											body: {
												renderer: (props) => {
													return (
														<div>
															<Tooltip title={props.value.status_name_ext}>
																<span>{props.value.status_code_ext}</span>
															</Tooltip>
														</div>
													)
												}
											}
										},
										sortable: true
									},
									identifier: {
										title: <FormattedMessage id="Patient.labels.IDNumber" />,
										type: "string",
										design: {
											width: "120px"
										}
									},
									full_name: {
										title: <FormattedMessage id="Common.label.fullName" />,
										type: "action",
										design: {
											width: "120px",
											body: {
												className: "blueDark xs-bold",
												renderer: (props) => {
													return (
														<div className="pointer" onClick={() => this.routeToDetail(props.value)}>
															{props.value.fullName}
														</div>
													)
												}
											}
										}
									},
									insurer_code_ext: {
										title: <FormattedMessage id="Common.label.insurer" />,
										type: "string",
										design: {
											width: "100px"
										},
										sortable: true
									},
									diagnosis: {
										title: <FormattedMessage id="Patient.labels.Diagnosis" />,
										type: "string",
										design: {
											width: "100px"
										}
									},
									provider: {
										title: <FormattedMessage id="Common.label.provider" />,
										type: "string",
										design: {
											width: "120px"
										}
									},
									created_at: {
										title: <FormattedMessage id="Common.label.proposal" />,
										type: "datetime",
										design: {
											width: "110px",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format(dateFormat) : ""
												}
											}
										},
										sortable: true
									},
									planned_at: {
										title: <FormattedMessage id="Common.label.proposalDateZS" />,
										type: "datetime",
										design: {
											width: "120px",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format(dateFormat) : ""
												}
											}
										},
										sortable: true
									},
									deliveries: {
										title: <FormattedMessage id="Patient.form.patientrecord.deliveries" />,
										type: "string",
										design: {
											width: "120px"
										}
									},
									internal_note: {
										title: <FormattedMessage id="Common.label.internalNote" />,
										type: "string"
									},
									type_code_ext: {
										title: <FormattedMessage id="Common.label.plannedZSType" />,
										type: "string",
										design: {
											width: "100px"
										},
										sortable: true
									}
								},
								options: {
									name: "HospicomReportList",
									showCursor: true,
									// selectRow: true,
									checkboxes: true,
									checkboxColumn: "record_id",
									checkAllDataColumn: "record_id",
									onClickCheckbox: (data) => {
										HospicomReportStore.checkedRecordIds = data
									},
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										return {
											status_code_ext: {
												status_code_ext: dataRow.status_code_ext,
												status_name_ext: dataRow.status_name_ext
											},
											identifier: dataRow.identifier,
											full_name: {
												fullName: dataRow.full_name,
												personId: dataRow.person_id,
												providerCodeExt: dataRow.provider_code_ext
											},
											insurer_code_ext: dataRow.insurer_code_ext,
											diagnosis:
												isSafe(dataRow.diagnose_code_ext) && Array.isArray(dataRow.diagnose_code_ext)
													? dataRow.diagnose_code_ext.join(", ")
													: "",
											provider: `${dataRow.provider_code_ext} ${dataRow.provider_name_ext} ${dataRow.provider_code}`,
											deliveries:
												isSafe(dataRow.delivery_code_ext) && Array.isArray(dataRow.delivery_code_ext)
													? dataRow.delivery_code_ext.join(", ")
													: "",
											internal_note: dataRow.internal_note,
											created_at: dataRow.created_at,
											planned_at: dataRow.planned_at,
											type_code_ext: dataRow.type_code_ext,
											record_id: dataRow.record_id,
											patient_id: dataRow.patient_id
										}
									}
								},
								dataSource: HospicomReportStore.hospicomList
							}}
						/>
					)}
				</Grid>
				<HospicomDialog />
			</Grid>
		)
	}
}
