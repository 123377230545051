import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"

import AgreementList from "./agreementList/agreementList"

import UIStore from "../../stores/UIStore"
import AgreementStore from "./../../stores/AgreementsStore"

@observer
export default class CapitationContainer extends React.Component {
	constructor(props) {
		super(props)
		UIStore.mainMenuTab = "agreements"
		AgreementStore.loadInsurences()
		AgreementStore.loadAgreements()
		// AgreementStore.loadParts()

		// GlobalStore.refreshCodeLists(["attributeType"])
	}

	//Faktúry
	// getAgreementContainer = () => {
	//   return {
	//     label: <FormattedMessage id="Agreement.Container.Agreement" />,
	//     value: "Agreements",
	//     content:
	//       (
	//         <AgreementList />
	//       )
	//   }
	// }

	render() {
		return (
			<Grid container direction="column" className="xs-agreements-container">
				<Paper className="xs-header" square={true} elevation={1}>
					<div className="xs-title">
						<FormattedMessage id="Common.menu.agreementspage" />
					</div>
				</Paper>
				<AgreementList />
			</Grid>
		)
	}
}
