import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"
import fields from "./transportTemplateFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsTextArea from "../../../../../../global/ui/xsTextArea/xsTextArea"
import XsCheckbox from "../../../../../../global/ui/xsCheckbox/xsCheckbox"
import SearchSelect from "../../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsAutocompleteLocal from "../../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegisterCacheStore from "../../../../../../global/store/RegistersCacheStore"

@injectIntl
@observer
export default class TransportTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.reasons = [
			// {_id: "0", name_ext: ""},
			{_id: "1", name_ext: this.props.intl.formatMessage({id: "PrintTemplate.Transport.Dovod1"})},
			{_id: "2", name_ext: this.props.intl.formatMessage({id: "PrintTemplate.Transport.Dovod2"})},
			{_id: "3", name_ext: this.props.intl.formatMessage({id: "PrintTemplate.Transport.Dovod3"})}
		]

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}
	}

	onReasonChange = () => {
		this.form.$("zDovodu").value = this.reasons.find((x) => x._id === this.form.$("preddefinovanyDovod").value).name_ext
	}

	render() {
		const labelStyle = {}

		return (
			<div style={{padding: "20px"}}>
				<Grid container direction="row" align="left">
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.form.$("variableDate")} showTimeSelect={true} />
						</Grid>
						<Grid item xs={9}>
							<XsInput maxLength={80} field={this.form.$("casMiestoPristaveniaVozidla")} labelStyle={labelStyle} />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left">
						<Grid item xs={8}>
							<XsAutocompleteLocal
								field={this.form.$("kodID")}
								minLengthForSearch="1"
								data={RegisterCacheStore.diagnosisRegister}
								inputRenderer={(obj) => {
									this.form.$("kod").value = obj.code_ext
									return obj.code_ext + " " + obj.name_ext
								}}
								saveValue={(obj) => obj.code}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
					</Grid>

					<Grid container direction="row" align="left">
						<Grid item xs={2}>
							<div style={{paddingTop: "15px"}}>
								<FormattedMessage id="PrintTemplate.Transport.FormLabel1" />
							</div>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("osobaChodiacaInv")} label={"Osoba je chodiaca* /"} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("osobaSediacaInv")} label={"sediaca* /"} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("osobaLeziacaInv")} label={"ležiaca* /"} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("osobaSoSprievodcomInv")} label={"so sprievodcom* /"} />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left">
						<Grid item xs={12}>
							<XsTextArea field={this.form.$("odovodnenie")} hideToolbar={true} className="xs-height-200" />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left">
						<Grid item xs={8}>
							<XsInput field={this.form.$("menoSprievodcu")} labelStyle={labelStyle} />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsInput field={this.form.$("dopravaZ")} labelStyle={labelStyle} />
						</Grid>
						<Grid item xs={4}>
							<XsInput field={this.form.$("dopravaDo")} labelStyle={labelStyle} />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left">
						<Grid item xs={2}>
							<div style={{paddingTop: "15px"}}>
								<FormattedMessage id="PrintTemplate.Transport.FormLabel2" />
							</div>
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("vozidloRLPInv")} label={"RLP* /"} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("vozidloRZPInv")} label={"RZP* /"} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("vozidloDZSInv")} label={"DZS* /"} />
						</Grid>
						<Grid item xs={2}>
							<XsCheckbox field={this.form.$("vozidloInfekcneInv")} label={"infekčné* /"} />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left">
						<Grid item xs={6}>
							<SearchSelect
								field={this.form.$("preddefinovanyDovod")}
								items={this.reasons}
								onChange={() => {
									this.onReasonChange()
								}}
							/>
						</Grid>
					</Grid>

					<Grid container direction="row" align="left">
						<Grid item xs={12}>
							<XsTextArea field={this.form.$("zDovodu")} hideToolbar={true} className="xs-height-200" />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsDateTimePicker field={this.form.$("datum")} showTimeSelect={false} />
						</Grid>

						<Grid item xs={2} />
						<Grid item xs={2}>
							<div style={{paddingTop: "25px", textAlign: "right"}}>
								<FormattedMessage id="PrintTemplate.Transport.opakovatKazdy" />
							</div>
						</Grid>
						<Grid item xs={1}>
							<XsInput field={this.form.$("opakovatKazdy")} type={"number"} min={"1"} labelStyle={{display: "none"}} />
						</Grid>
						<Grid item xs={1}>
							<div style={{paddingTop: "25px"}}>
								<FormattedMessage id="PrintTemplate.Transport.opakovatDen" />
							</div>
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker
								field={this.form.$("opakovatDo")}
								showTimeSelect={false}
								labelStyle={{display: "none"}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</div>
		)
	}
}
