import React from "react"
// import moment from "moment"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import fields from "./enumListFields"

import Grid from "@material-ui/core/Grid"

import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../../global/ui/xsButton/xsButton"
import XsTable from "../../../../../../global/ui/xsTable/xsTable"

import EnumDetailDialog from "./enumDetailDialog"

import EnumDetailDialogStore from "../../../../stores/EnumDetailDialogStore"
import EnumeratorListStore from "../../../../stores/EnumeratorListStore"

@observer
export default class EnumeratorList extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		EnumeratorListStore.formEnumFilterRef = this.form
	}

	applyFilters = () => {
		delayedCallback(500, () => EnumeratorListStore.loadCodeList(this.form))
	}

	render() {
		return (
			<React.Fragment>
				<Grid container direction="column">
					{EnumeratorListStore.enumId && (
						<Grid item xs={12}>
							<XsButton
								className="xs-back-btn pa-0 mb-1"
								icon={<i className="fal fa-chevron-left fa-lg" />}
								text={
									<h5>
										<span className="xs-bold">{EnumeratorListStore.enumId}</span>
										{isNotEmpty(EnumeratorListStore.description) && ` (${EnumeratorListStore.description})`}
									</h5>
								}
								onClick={() => {
									EnumeratorListStore.isOpen = false
								}}
							/>
						</Grid>
					)}
					<Grid item container spacing={8}>
						<Grid item xs={2}>
							<XsInput field={this.form.$("search_column")} onChange={() => this.applyFilters()} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("code_ext")} onChange={() => this.applyFilters()} />
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<XsTable
							config={{
								columnDefs: {
									code_ext: {
										title: <FormattedMessage id="Common.label.code" />,
										design: {
											width: "20%"
										},
										type: "string"
									},
									name_ext: {
										title: <FormattedMessage id="Common.label.name" />,
										design: {
											width: "80%"
										},
										type: "string"
									}
								},
								options: {
									showCursor: true,
									selectRow: true,
									onRowClick: (dataRow) => {
										EnumDetailDialogStore.open(dataRow.dataRow)
									},
									mapper: (dataRow) => {
										return {
											_id: dataRow._id,
											code_ext: dataRow.code_ext,
											name_ext: dataRow.name_ext,
											dataRow: dataRow
										}
									}
								},
								dataSource: EnumeratorListStore.enumData
							}}
						/>
					</Grid>
				</Grid>
				<EnumDetailDialog />
			</React.Fragment>
		)
	}
}
