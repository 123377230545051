import React from "react"
import {observer} from "mobx-react"
import Chip from "@material-ui/core/Chip"
import Tooltip from "@material-ui/core/Tooltip"

import "./xsChips.less"

export default observer(({label, tooltip, max, style, circleStyle, className = "", onClick, deleteIcon, onDelete}) => {
	let newLabel = isSafe(max)
		? label.length > max
			? label.trim().slice(0, max - 3) + "..."
			: label
		: label.length > 30
		? label.trim().slice(0, 30) + "..."
		: label

	if (isSafe(circleStyle)) {
		return (
			<div className="xs-chip">
				<Tooltip title={isNotEmpty(tooltip) ? tooltip : label}>
					<Chip
						className={className}
						style={style}
						label={
							<div className="xs-chip-label">
								<div className="xs-chip-circle" style={circleStyle}></div>
								{newLabel}
							</div>
						}
						onDelete={(e) => onDelete(e)}
						deleteIcon={<span className="xs-chip-delete-icon">{deleteIcon}</span>}
						onClick={(e) => onClick(e)}
					/>
				</Tooltip>
			</div>
		)
	} else {
		return (
			<div className="xs-chip">
				<Tooltip title={isNotEmpty(tooltip) ? tooltip : label}>
					<Chip
						className={className}
						style={style}
						label={newLabel}
						onDelete={(e) => onDelete(e)}
						deleteIcon={<span className="xs-chip-delete-icon">{deleteIcon}</span>}
						onClick={(e) => onClick(e)}
					/>
				</Tooltip>
			</div>
		)
	}
})
