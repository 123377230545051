"use strict"

import React from "react"
import classnames from "classnames"
import {FormattedMessage, injectIntl} from "react-intl"
import moment from "moment"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {withStyles} from "@material-ui/core/styles"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import fields from "./deliveryPerPatientFilterFields"

import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

import {Grid} from "@material-ui/core"
import {getUserPersonnelOrgUnits} from "../../../../../global/helpers/actions"

import DeliveryPerPatientStore from "../../../stores/DeliveryPerPatientStore"
import RegistersCacheStore from "../../../../../global/store/RegistersCacheStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

const styles = () => ({
	row: {
		borderBottom: "1px solid rgba(224, 224, 224, 1)"
	},
	headCell: {
		fontSize: "13px"
	},
	cell: {
		padding: "10px 5px",
		color: "#60606F",
		wordBreak: "break-word"
	}
})

@withStyles(styles)
@injectIntl
@observer
export default class DeliveryPerPatientList extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		if (isEmpty(DeliveryPerPatientStore.filterRef)) {
			DeliveryPerPatientStore.filterRef = new MobxReactForm(fields.load(), {
				plugins: {dvr: validatorjs},
				hooks,
				bindings
			})
		}

		this.form = DeliveryPerPatientStore.filterRef

		DeliveryPerPatientStore.currFilters = this.form.values()
	}

	componentDidMount() {
		DeliveryPerPatientStore.getFilters()
	}

	onRowClick = (row) => {
		if (isNotEmpty(row.person_id)) {
			let orgunit_id = ""

			if (isSafe(row.deliveries) && row.deliveries.length) {
				if (isSafe(row.deliveries[0]) && isNotEmpty(row.deliveries[0].provider_id)) {
					orgunit_id = row.deliveries[0].provider_id
				}
			}

			GlobalStore.changeOrgUnitAndRouteToPatientDetail(orgunit_id, row.person_id)
		}
	}

	render() {
		DeliveryPerPatientStore.isChangedFilter(this.form.values())

		const pagerText = this.props.intl.formatMessage(
			{id: "Common.label.overiewNextRecords"},
			{
				count: Math.min(
					DeliveryPerPatientStore.rowPerPage,
					DeliveryPerPatientStore.deliveryPerPatientData.length - DeliveryPerPatientStore.showData_count
				)
			}
		)

		return (
			<React.Fragment>
				<Grid container spacing={8} justify="flex-end">
					<Grid item>
						<XsButton
							className={DeliveryPerPatientStore.changedFilters ? "xs-default xs-outline" : "xs-primary"}
							disabled={DeliveryPerPatientStore.changedFilters}
							text={<FormattedMessage id="Common.label.export" />}
							icon={<i className="fal fa-file-excel fa-lg" />}
							onClick={() => {
								DeliveryPerPatientStore.getFilters(true)
							}}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={8} alignItems="center">
					<XsIconButton
						rect
						style={{borderColor: "rgb(192, 197, 207)"}}
						tooltip={this.props.intl.formatMessage({id: "Common.label.previousDay"})}
						className="xs-default xs-outline mt-1"
						onClick={() => {
							const previous_day = moment(this.form.$("time_from").value)
								.clone()
								.add(-1, "day")
							this.form.$("time_from").value = previous_day
							this.form.$("time_to").value = previous_day
						}}
						icon={<i className="far fa-chevron-left fa-lg xs-greyDefault"></i>}
					/>
					<Grid item xs={1}>
						<XsDateTimePicker white field={this.form.$("time_from")} showTimeSelect={false} />
					</Grid>
					<Grid item xs={1}>
						<XsDateTimePicker white field={this.form.$("time_to")} showTimeSelect={false} />
					</Grid>
					<XsIconButton
						rect
						style={{borderColor: "rgb(192, 197, 207)", marginLeft: "2px"}}
						tooltip={this.props.intl.formatMessage({id: "Common.label.nextDay"})}
						className="xs-default xs-outline mt-1"
						onClick={() => {
							const next_day = moment(this.form.$("time_from").value)
								.clone()
								.add(1, "day")
							this.form.$("time_from").value = next_day
							this.form.$("time_to").value = next_day
						}}
						icon={<i className="far fa-chevron-right fa-lg xs-greyDefault"></i>}
					/>
					<Grid item xs={2} className="ml-2">
						<XsSearchSelect white field={this.form.$("orgunit")} items={getUserPersonnelOrgUnits()} />
					</Grid>
					<Grid item xs={2}>
						<XsSearchSelect white field={this.form.$("insurer")} items={RegistersCacheStore.insurersRegister} />
					</Grid>
					<Grid item xs={2}>
						<XsInput white field={this.form.$("identifier")} />
					</Grid>
					<Grid item xs={2}>
						<XsSearchSelect white field={this.form.$("insurence_type")} items={GlobalStore.CL["insuranceTypes"]} />
					</Grid>
					<XsIconButton
						rect
						tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
						className="xs-default xs-outline mt-1 ml-1"
						onClick={() => {
							DeliveryPerPatientStore.getFilters()
						}}
						icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
					/>
				</Grid>
				<Grid container>
					<Grid item xs={1} className={classnames(this.props.classes.cell, this.props.classes.headCell)}>
						<FormattedMessage id="Patient.labels.IDNumber" />
					</Grid>
					<Grid item xs={1} className={classnames(this.props.classes.cell, this.props.classes.headCell)}>
						<FormattedMessage id="Patient.labels.patient" />
					</Grid>
					<Grid item container xs={10}>
						<Grid item xs className={classnames(this.props.classes.cell, this.props.classes.headCell)}>
							<FormattedMessage id="Delivery.List.Diagnosis" />
						</Grid>
						<Grid item xs className={classnames(this.props.classes.cell, this.props.classes.headCell)}>
							<FormattedMessage id="Common.label.delivery" />
						</Grid>
						<Grid item xs className={classnames(this.props.classes.cell, this.props.classes.headCell)}>
							<FormattedMessage id="Common.label.date" />
						</Grid>
						<Grid item xs className={classnames(this.props.classes.cell, this.props.classes.headCell)}>
							<FormattedMessage id="Delivery.List.Count" />
						</Grid>
						<Grid item xs className={classnames(this.props.classes.cell, this.props.classes.headCell)}>
							<FormattedMessage id="Delivery.List.Points" />
						</Grid>
						<Grid item xs className={classnames(this.props.classes.cell, this.props.classes.headCell)}>
							<FormattedMessage id="Delivery.List.Price" />
						</Grid>
						<Grid item xs className={classnames(this.props.classes.cell, this.props.classes.headCell)}>
							<FormattedMessage id="Delivery.List.PP" />
						</Grid>
						<Grid item xs className={classnames(this.props.classes.cell, this.props.classes.headCell)}>
							ZP
						</Grid>
						<Grid item xs className={classnames(this.props.classes.cell, this.props.classes.headCell)}>
							Typ poi.
						</Grid>
						<Grid item xs className={classnames(this.props.classes.cell, this.props.classes.headCell)}>
							<FormattedMessage id="Delivery.List.RefundType" />
						</Grid>
						<Grid item xs className={classnames(this.props.classes.cell, this.props.classes.headCell)}>
							<FormattedMessage id="Delivery.List.MovementShort" />
						</Grid>
						<Grid item xs className={classnames(this.props.classes.cell, this.props.classes.headCell)}>
							Typ odos.
						</Grid>
					</Grid>
				</Grid>
				{DeliveryPerPatientStore.changedFilters ? (
					<div className="xs-table-no-data pa-3 borderRadius-1 mt-4">
						<i className="far fa-search fa-lg mr-3 pointer" onClick={() => DeliveryPerPatientStore.getFilters()}></i>
						<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
					</div>
				) : (
					<React.Fragment>
						{DeliveryPerPatientStore.isFetching ? (
							<XsLoading />
						) : isSafe(DeliveryPerPatientStore.deliveryPerPatientData) &&
						  DeliveryPerPatientStore.deliveryPerPatientData.length > 0 ? (
							DeliveryPerPatientStore.deliveryPerPatientData
								.slice(0, DeliveryPerPatientStore.showData_count)
								.map((row, key) => {
									return (
										<Grid container key={key} className={this.props.classes.row}>
											<Grid item xs={1} className={this.props.classes.cell}>
												{row.person_identifier}
											</Grid>
											<Grid
												item
												xs={1}
												className={classnames(this.props.classes.cell, "blueDark", "xs-bold", "pointer")}
												onClick={() => this.onRowClick(row)}
											>
												{row.person_name}
											</Grid>
											<Grid item container xs={10}>
												{isSafe(row.deliveries) &&
													row.deliveries.length > 0 &&
													row.deliveries.map((delivery, idx) => {
														return (
															<Grid item container key={`${key}_${idx}`}>
																<Grid item xs className={this.props.classes.cell}>
																	{isSafe(delivery.diagnosis_code_ext) ? delivery.diagnosis_code_ext : ""}
																</Grid>
																<Grid item xs className={this.props.classes.cell} style={{fontWeight: "700"}}>
																	{isSafe(delivery.item_code_ext) ? delivery.item_code_ext : ""}
																</Grid>
																<Grid item xs className={this.props.classes.cell}>
																	{isNotEmpty(delivery.delivery_date_time)
																		? moment(delivery.delivery_date_time).format("DD.MM.YYYY")
																		: ""}
																</Grid>
																<Grid item xs className={this.props.classes.cell}>
																	{isSafe(delivery.count) ? delivery.count : ""}
																</Grid>
																<Grid item xs className={this.props.classes.cell}>
																	{isSafe(delivery.price_in_points) ? delivery.price_in_points : ""}
																</Grid>
																<Grid item xs className={(this.props.classes.cell, "textRight")}>
																	{isNotEmpty(delivery.price_in_currency)
																		? delivery.price_in_currency.toString().replace(".", ",")
																		: ""}
																</Grid>
																<Grid item xs className={this.props.classes.cell}>
																	{isSafe(delivery.sub_items_serialized) ? delivery.sub_items_serialized : ""}
																</Grid>
																<Grid item xs className={this.props.classes.cell}>
																	{isSafe(delivery.insurer_code_ext) ? delivery.insurer_code_ext : ""}
																</Grid>
																{/* <Grid item xs className={this.props.classes.cell}>
																	{isSafe(delivery.payer_type_code_ext) ? delivery.payer_type_code_ext : ""}
																</Grid> */}
																<Grid item xs className={this.props.classes.cell}>
																	{isSafe(delivery.insuree_type_code_ext) ? delivery.insuree_type_code_ext : ""}
																</Grid>
																<Grid item xs className={this.props.classes.cell}>
																	{isSafe(delivery.refund_type_code_ext) ? delivery.refund_type_code_ext : ""}
																</Grid>
																<Grid item xs className={this.props.classes.cell}>
																	{isSafe(delivery.movement) ? delivery.movement : ""}
																</Grid>
																<Grid item xs className={this.props.classes.cell}>
																	{isSafe(delivery.sender_type_code_ext) ? delivery.sender_type_code_ext : ""}
																</Grid>
															</Grid>
														)
													})}
											</Grid>
										</Grid>
									)
								})
						) : (
							<Grid container className="mt-2" justify="center">
								<Grid item className="xs-bold pt-2 pb-2">
									<FormattedMessage id="Table.NoData" />
								</Grid>
							</Grid>
						)}

						{!DeliveryPerPatientStore.isFetching &&
							DeliveryPerPatientStore.showData_count < DeliveryPerPatientStore.deliveryPerPatientData.length && (
								<Grid container justify="center" className="mt-2">
									<Grid
										item
										className="pointer blueDark pt-2 pb-2"
										onClick={() =>
											(DeliveryPerPatientStore.showData_count =
												DeliveryPerPatientStore.showData_count + DeliveryPerPatientStore.rowPerPage)
										}
									>
										{`${pagerText} z ${DeliveryPerPatientStore.deliveryPerPatientData.length}`}
									</Grid>
								</Grid>
							)}
					</React.Fragment>
				)}
			</React.Fragment>
		)
	}
}
