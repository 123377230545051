import {FormattedMessage} from "react-intl"
import React from "react"
import {getSelectedOrgUnit} from "../../../../../global/helpers/actions"

export default {
	load() {
		return {
			fields: {
				orgunit: {
					label: <FormattedMessage id="Patient.form.patientrequest.orgunit" />,
					rules: "string",
					value: getSelectedOrgUnit()._id
				},
				datefrom: {
					label: <FormattedMessage id="Capitation.List.From" />,
					type: "date"
				},
				dateto: {
					label: <FormattedMessage id="Capitation.List.To" />,
					type: "date"
				},
				identifier: {
					label: <FormattedMessage id="Patient.labels.IDNumber" />,
					type: "string"
				},
				country: {
					label: <FormattedMessage id="Patient.form.insuranceForm.country" />,
					rules: "string"
				}
			}
		}
	}
}
