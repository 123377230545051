//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import moment from "moment"

import {Grid, Tooltip} from "@material-ui/core"
import XsIconButton from "../../../../global/ui/xsButton/xsIconButton"
import {getUser} from "../../../../global/helpers/actions"

import XsConfirmationDialog from "../../../../global/ui/xsDialog/xsConfirmationDialog"
import EditEventDialogStore from "../../stores/EditEventDialogStore"
import CalendarStore from "../../stores/CalendarStore"
import GlobalStore from "../../../../global/store/GlobalStore"

import api from "../../actions/api"

@injectIntl
@observer
export default class EditEventOrders extends React.Component {
	constructor(props) {
		super(props)
	}

	user = getUser()
	managerId =
		isSafe(this.user) && isSafe(this.user.user_settings) && isNotEmpty(this.user.user_settings.QueueManagerEmployeeId)
			? this.user.user_settings.QueueManagerEmployeeId
			: null

	getOpenHours = () => {
		let from = 0
		let to = 23
		let hours = []
		for (let i = from; i <= to; i++) {
			hours.push(i)
		}

		return hours
	}

	getQueueStatus = (capacity, waiting) => {
		if (capacity > waiting) {
			return <i className="fas fa-circle greenSuccess" />
		} else if (capacity == waiting) {
			return <i className="fas fa-circle amberDark" />
		} else {
			return <i className="fas fa-circle redDanger" />
		}
	}

	removeItemFromQueue = () => {
		api
			.cancelEventFromQueue(GlobalStore.confirmationDialogParams.queueItemId)
			.call()
			.then(() => {
				EditEventDialogStore.getQueue()
				EditEventDialogStore.getQueueIdsWhereIsEvent()
				GlobalStore.setNotificationMessage("Pacient odstránený z fronty")
			})
	}

	increaseQueue = (queueId, eventId) => {
		const req = {
			_id: "",
			_ref: false,
			_type: "EZdravie.Data.MeZ.WorkQueue.Event",
			event: {
				_type: "Service.Data.Event" /* vazba na event z kalendara */,
				_id: eventId
			}
		}

		api
			.increaseQueue(queueId, req)
			.call()
			.then(() => {
				EditEventDialogStore.getQueue()
				EditEventDialogStore.getQueueIdsWhereIsEvent()
				GlobalStore.setNotificationMessage("Pacient priradený do fronty")
			})
	}

	render() {
		return (
			<div className="xs-patient-list-cover">
				{isSafe(this.managerId) ? (
					<React.Fragment>
						<Grid container justify="space-between" alignItems="center" className="pt-4 pb-2 pr-4 pl-4">
							<Grid item className="greyDark xs-bold">
								Kliknutím na riadok priradíte pacienta do fronty:
							</Grid>
						</Grid>
						<div className="xs-patient-list-scrollbar">
							<div className="xs-patient-list">
								<Grid container alignItems="center">
									{EditEventDialogStore.queueData.length > 0 ? (
										EditEventDialogStore.queueData.map((queue, idx) => {
											return (
												<React.Fragment key={idx}>
													<Grid
														item
														style={{
															borderBottom: "1px solid #e5e7ea"
														}}
														alignItems="center"
														container
													>
														<Grid
															item
															xs={1}
															onClick={() => this.increaseQueue(queue._id, EditEventDialogStore.event.event_id)}
															className="pointer pt-2 pb-2"
														>
															{this.getQueueStatus(queue.capacity, queue.waiting)}
														</Grid>
														<Grid
															item
															xs={8}
															onClick={() => this.increaseQueue(queue._id, EditEventDialogStore.event.event_id)}
															className="pointer pt-2 pb-2"
														>
															{queue.name}
														</Grid>
														<Grid
															item
															xs={2}
															container
															justify="center"
															onClick={() => this.increaseQueue(queue._id, EditEventDialogStore.event.event_id)}
															className="pointer pt-2 pb-2"
														>
															<Grid item>
																{queue.capacity}/{queue.waiting}
															</Grid>
														</Grid>
														{EditEventDialogStore.queueRemoveIds.includes(queue._id) && (
															<Grid item container xs={1} justify="flex-end">
																<Grid
																	item
																	onClick={() =>
																		GlobalStore.openConfirmationDialog("xsRemoveItemFromQueueDialog", {
																			queueItemId: EditEventDialogStore.queueRemoveJSON[queue._id]
																		})
																	}
																	className="pointer pt-2 pb-2"
																>
																	<Tooltip
																		title={this.props.intl.formatMessage({
																			id: "Common.label.removeFromQueue"
																		})}
																		placement="left"
																	>
																		<i className="fas fa-eraser redDanger"></i>
																	</Tooltip>
																</Grid>
															</Grid>
														)}
													</Grid>
												</React.Fragment>
											)
										})
									) : (
										<Grid item>Žiadne voľné miesta</Grid>
									)}
								</Grid>
							</div>
						</div>
					</React.Fragment>
				) : (
					<React.Fragment>
						<Grid container justify="space-between" alignItems="center" className="pt-2 pb-2 pr-4 pl-4">
							<Grid item className="greyDark xs-bold">
								<FormattedMessage id="Common.label.patientAlreadyHasTheseOrders" />
							</Grid>
							<Grid item>
								<XsIconButton
									tooltip={this.props.intl.formatMessage({id: "Common.label.scheduledAllDeadlines"})}
									onClick={() => (EditEventDialogStore.showPatientEvents = false)}
									className="xs-default"
									style={{height: "28px", minHeight: "28px", minWidth: "28px"}}
									rect
									icon={<i className="fal fa-list-ul"></i>}
								/>
							</Grid>
						</Grid>
						<div className="xs-patient-list-scrollbar">
							<div className="xs-patient-list">
								{EditEventDialogStore.patientEventsData.map((event, idx) => {
									let bgColor = "#808080"
									let eventDuration = ""
									let fontColor = undefined

									if (isSafe(event)) {
										if (isSafe(event.services) && isSafe(event.services[0])) {
											if (isNotEmpty(event.services[0].color)) {
												bgColor = event.services[0].color
											}

											if (isNotEmpty(event.services[0].fore_color)) {
												fontColor = event.services[0].fore_color
											}

											if (isNotEmpty(event.services[0].duration && event.services[0].duration > 0)) {
												eventDuration = ` (${Math.round(event.services[0].duration / 60)} min.)`
											}
										}
									}
									let colors = CalendarStore.invertColor(bgColor, fontColor)
									let lastName = isNotEmpty(event.last_name) ? event.last_name.toUpperCase() : ""

									return (
										<div key={idx} className="xs-plane-event xs-row pt-1 pb-1">
											<div className="xs-hour xs-row xs-align">
												{moment(event.interval.from).format("DD.MM.YYYY HH:mm")}
											</div>
											<div className="xs-events">
												<div
													className="xs-event"
													style={{backgroundColor: colors.backgroundColor, color: colors.color}}
												>
													<div className="xs-name">
														{isEmpty(event.last_name) && isEmpty(event.first_name)
															? event.client_text
															: lastName + " " + event.first_name}
													</div>
													{isSafe(event.service_names) && (
														<div className="xs-description">
															{event.service_names.length <= 15
																? event.service_names + eventDuration
																: event.service_names.substring(0, 15) + "..." + eventDuration}
														</div>
													)}
												</div>
											</div>
										</div>
									)
								})}
							</div>
						</div>
					</React.Fragment>
				)}
				<XsConfirmationDialog
					name="xsRemoveItemFromQueueDialog"
					text={<FormattedMessage id="Common.label.removePatientFromQueue" />}
					type="danger"
					headerConfig={{
						text: "Common.label.removeFromQueue",
						icon: <i className="fas fa-eraser fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.label.keep"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.label.removeFromQueue"
					}}
					onConfirmation={() => this.removeItemFromQueue()}
				/>
			</div>
		)
	}
}
