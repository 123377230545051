import React from "react"
import {observer} from "mobx-react"
import classnames from "classnames"

import {Button, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, Grid} from "@material-ui/core"

import "./xsButton.less"

@observer
export default class XsButtonGroup extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			open: false
		}
	}

	handleToggle = () => {
		this.setState((state) => ({open: !state.open}))
	}

	handleClose = (event) => {
		if (this.anchorEl.contains(event.target)) {
			return
		}

		this.setState({open: false})
	}

	render() {
		const {open} = this.state
		const {items, value, bgClass, textClass} = this.props
		// const {id, className, text, onClick, icon = "", size = "medium", disabled, tabindex} = this.props
		return (
			isSafe(items) &&
			items.length > 0 && (
				<React.Fragment>
					{items.length > 1 ? (
						<React.Fragment>
							<Grid
								container
								wrap="nowrap"
								alignItems="center"
								className={classnames("buttonGroup", {
									[textClass]: isNotEmpty(textClass),
									[bgClass]: isNotEmpty(bgClass)
								})}
							>
								<Grid item className="selectText">
									{value}
								</Grid>
								<Grid item>
									<Button
										className="selectBtn"
										buttonRef={(node) => {
											this.anchorEl = node
										}}
										aria-owns={open ? "menu-list-grow" : null}
										onClick={() => this.handleToggle()}
									>
										<i
											className={classnames("fas fa-sort-down", {
												[textClass]: isNotEmpty(textClass),
												greyDark: isEmpty(textClass)
											})}
										></i>
									</Button>
								</Grid>
							</Grid>
							<Popper open={open} anchorEl={this.anchorEl} transition placement="bottom-end">
								{({TransitionProps}) => (
									<Grow
										{...TransitionProps}
										in={this.state.open}
										style={{width: "200px"}}
										// {...TransitionProps}
										// id="menu-list-grow"
										// style={{transformOrigin: placement === "bottom" ? "center bottom" : "center bottom"}}
									>
										<Paper>
											<ClickAwayListener onClickAway={this.handleClose}>
												<MenuList>
													{items.map((item, key) => {
														if (item.label != value) {
															return (
																<MenuItem
																	key={key}
																	onClick={() => {
																		item.onClick(), this.setState({open: false})
																	}}
																	// className={classnames({bgGreyLight: value == item.label})}
																>
																	<div className="menuItem">{item.label}</div>
																</MenuItem>
															)
														}
													})}
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</React.Fragment>
					) : (
						<Grid
							container
							wrap="nowrap"
							alignItems="center"
							className={classnames("buttonGroup", {
								[textClass]: isNotEmpty(textClass),
								[bgClass]: isNotEmpty(bgClass)
							})}
						>
							<Grid item className="selectText">
								{value}
							</Grid>
						</Grid>
					)}
				</React.Fragment>
			)
		)
	}
}
