import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "lab_Request"
				},
				paperTemplateId: {
					value: null // nastavi sa po otvoreni
				},

				lab: {
					label: "Laboratórium",
					rules: "required",
					value: "alphamedical"
				},

				doctorName: {},

				orgunitname: {},
				orgunitaddress: {},
				companyIdentifier: {},

				///

				identifier: {},
				identifierForm: {
					label: <FormattedMessage id="Patient.labels.IDNumber" />
				},
				birthdate: {
					type: "date"
				},
				birthdateForm: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.birthdate" />,
					type: "date",
					value: ""
				},

				first_name: {},
				first_nameForm: {
					label: <FormattedMessage id="Common.label.firstName" />
				},
				last_name: {},
				last_nameForm: {
					label: <FormattedMessage id="Common.label.lastName" />
				},
				name_medirex: {},

				street: {},
				house_number: {},
				city: {},
				postcode: {},

				aplha_street: {
					label: "Ulica, číslo domu*"
				},
				aplha_city: {
					label: "Mesto/obec*"
				},
				aplha_zipcode: {
					label: "PSČ*"
				},

				sex: {},
				genderMan: {
					label: "Muž",
					type: "checkbox",
					value: false
				},
				genderFemale: {
					label: "Žena",
					type: "checkbox",
					value: false
				},

				gravidity: {
					label: "Gravidita",
					type: "checkbox",
					value: false
				},

				insurerCode: {},
				insurerCodeForm: {
					label: "Kód poisťovne"
				},
				payer: {
					label: "Platiteľ (kód ZP, samoplatca, PZS)"
				},

				pay_doctor: {
					label: "Lekár (zdravot. zariadenie)",
					type: "checkbox",
					value: false
				},
				pay_patient: {
					label: "Pacient",
					type: "checkbox",
					value: false
				},
				self_pay: {
					label: "Samoplatca",
					type: "checkbox",
					value: false
				},
				fee_free: {
					label: "Oslob. od DPH",
					type: "checkbox",
					value: false
				},
				fee_freeInv: {
					type: "checkbox",
					value: true
				},

				idHospPrip: {
					label: "ID hospitalizačného prípadu"
				},

				insurerEU: {
					label: "EU poistenec",
					type: "checkbox",
					value: false
				},
				countryForm: {
					label: "Kód krajiny EU"
				},
				countryForm0: {},
				countryForm1: {},

				typZs: {
					label: "Typ ZS"
				},
				patientId: {
					label: "ID pacienta"
				},

				bpb_note: {
					label: "Poznámka"
				},

				// DIAGNOZY
				diagHl: {
					label: "Dg. MKCH - hlavná"
				},
				diagHlKod: {value: ""},
				diagHlKod0: {},
				diagHlKod1: {},
				diagHlKod2: {},
				diagHlKod3: {},
				diagHlKod4: {},

				diagPridr: {
					label: "Dg. MKCH - pridružená"
				},
				diagPridrKod: {value: ""},
				diagPridr0: {},
				diagPridr1: {},
				diagPridr2: {},
				diagPridr3: {},
				diagPridr4: {},

				diagVedl: {
					label: "Dg. MKCH - vedľajšia"
				},
				diagVedlKod: {value: ""},
				diagVedlA0: {},
				diagVedlA1: {},
				diagVedlA2: {},
				diagVedlA3: {},
				diagVedlA4: {},

				diagVedl2: {
					label: "Dg. MKCH - vedľajšia"
				},
				diagVedl2Kod: {value: ""},
				diagVedlB0: {},
				diagVedlB1: {},
				diagVedlB2: {},
				diagVedlB3: {},
				diagVedlB4: {},

				dateBlood: {
					label: "Dátum a čas odberu",
					value: moment(),
					type: "dateTime"
				},
				dateBlood0: {},
				dateBlood1: {},
				dateBlood2: {},
				dateBlood3: {},
				dateBlood4: {},
				dateBlood5: {},
				dateBlood6: {},
				dateBlood7: {},
				dateBlood8: {},
				dateBlood9: {},

				date: {
					label: "Dátum vystavenia žiadanky",
					value: moment(),
					type: "date"
				},
				date0: {},
				date1: {},
				date2: {},
				date3: {},
				date4: {},
				date5: {},

				dateMenstr: {
					label: "Dátum poslednej menštruácie",
					value: "",
					type: "date"
				},
				dateMenstr0: {},
				dateMenstr1: {},
				dateMenstr2: {},
				dateMenstr3: {},
				dateMenstr4: {},
				dateMenstr5: {},

				sendingDoctorPersonnelExpertiseId: {
					label: <FormattedMessage id="Patient.form.patientPrescription.sendingDoctor" />
				},
				doctorOUPZS: {
					label: <FormattedMessage id="Patient.form.patientPrescription.sendingOUPZS" />
				},
				sendingDoctorFullName: {},
				sendingDoctorACode: {},
				sendingDoctorPCode: {},
				doctorOUPZSId: {},
				sendingDoctor: {},

				substitutingPersonnelExpertiseId: {
					label: "Zastupovaný lekár"
				},
				substitutingDoctorOUPZS: {
					label: <FormattedMessage id="Patient.form.patientPrescription.substitutingDoctorOUPZS" />
				},
				substitutingFullName: {},
				substitutingACode: {},
				substitutingPCode: {},
				substitutingDoctorOUPZSId: {},
				substitutingDoctor: {},

				//===

				// APLHA MEDICAL
				aplha_pills: {
					label: "Užívané lieky"
				},
				aplha_importantNote: {
					label: "Dôležitá poznámka"
				},
				aplha_height: {
					label: "Výška (cm)"
				},
				aplha_weight: {
					label: "Váha (kg)"
				},
				aplha_mc: {
					label: "MC"
				},
				aplha_diureza: {
					label: "Diuréza (ml)"
				},
				aplha_diurezaTime: {
					label: "Diuréza ( /hod.)"
				},
				aplha_hebd: {
					label: "Hebd. (t.)"
				},

				// MEDIREX
				medirex_email: {
					label: "E-mail pacienta"
				},

				// SYNLAB
				synlab_studia: {
					label: "Štúdia",
					type: "checkbox",
					value: false
				},
				synlab_studiaDetail: {
					label: "Štúdia"
				},
				synlab_department: {
					label: "Oddelenie"
				},
				synlab_telShort: {
					label: "Tel., klapka"
				},

				insurerCodeForm0: {},
				insurerCodeForm1: {},
				insurerCodeForm2: {},
				insurerCodeForm3: {},

				identifierForm0: {},
				identifierForm1: {},
				identifierForm2: {},
				identifierForm3: {},
				identifierForm4: {},
				identifierForm5: {},
				identifierForm6: {},
				identifierForm7: {},
				identifierForm8: {},
				identifierForm9: {},
				identifierForm10: {},

				// MEDIREX policka
				first_nameForm0: {},
				first_nameForm1: {},
				first_nameForm2: {},
				first_nameForm3: {},
				first_nameForm4: {},
				first_nameForm5: {},
				first_nameForm6: {},
				first_nameForm7: {},
				first_nameForm8: {},
				first_nameForm9: {},
				first_nameForm10: {},
				first_nameForm11: {},
				first_nameForm12: {},
				first_nameForm13: {},
				first_nameForm14: {},
				first_nameForm15: {},
				first_nameForm16: {},
				first_nameForm17: {},
				first_nameForm18: {},
				first_nameForm19: {},
				first_nameForm20: {},
				first_nameForm21: {},

				last_nameForm0: {},
				last_nameForm1: {},
				last_nameForm2: {},
				last_nameForm3: {},
				last_nameForm4: {},
				last_nameForm5: {},
				last_nameForm6: {},
				last_nameForm7: {},
				last_nameForm8: {},
				last_nameForm9: {},
				last_nameForm10: {},
				last_nameForm11: {},
				last_nameForm12: {},
				last_nameForm13: {},
				last_nameForm14: {},
				last_nameForm15: {},
				last_nameForm16: {},
				last_nameForm17: {},
				last_nameForm18: {},
				last_nameForm19: {},
				last_nameForm20: {},
				last_nameForm21: {},

				idHospPrip0: {},
				idHospPrip1: {},
				idHospPrip2: {},
				idHospPrip3: {},
				idHospPrip4: {},
				idHospPrip5: {},
				idHospPrip6: {},
				idHospPrip7: {},
				idHospPrip8: {},
				idHospPrip9: {},
				idHospPrip10: {},
				idHospPrip11: {},

				patientId0: {},
				patientId1: {},
				patientId2: {},
				patientId3: {},
				patientId4: {},
				patientId5: {},
				patientId6: {},
				patientId7: {},
				patientId8: {},
				patientId9: {},
				patientId10: {},
				patientId11: {},
				patientId12: {},
				patientId13: {},
				patientId14: {},
				patientId15: {},
				patientId16: {},
				patientId17: {},
				patientId18: {},
				patientId19: {},
				patientId20: {},
				patientId21: {},

				sendingDoctorACode0: {},
				sendingDoctorACode1: {},
				sendingDoctorACode2: {},
				sendingDoctorACode3: {},
				sendingDoctorACode4: {},
				sendingDoctorACode5: {},
				sendingDoctorACode6: {},
				sendingDoctorACode7: {},
				sendingDoctorACode8: {},
				sendingDoctorACode9: {},

				sendingDoctorPCode0: {},
				sendingDoctorPCode1: {},
				sendingDoctorPCode2: {},
				sendingDoctorPCode3: {},
				sendingDoctorPCode4: {},
				sendingDoctorPCode5: {},
				sendingDoctorPCode6: {},
				sendingDoctorPCode7: {},
				sendingDoctorPCode8: {},
				sendingDoctorPCode9: {},
				sendingDoctorPCode10: {},
				sendingDoctorPCode11: {},

				substitutingACode0: {},
				substitutingACode1: {},
				substitutingACode2: {},
				substitutingACode3: {},
				substitutingACode4: {},
				substitutingACode5: {},
				substitutingACode6: {},
				substitutingACode7: {},
				substitutingACode8: {},
				substitutingACode9: {},

				substitutingPCode0: {},
				substitutingPCode1: {},
				substitutingPCode2: {},
				substitutingPCode3: {},
				substitutingPCode4: {},
				substitutingPCode5: {},
				substitutingPCode6: {},
				substitutingPCode7: {},
				substitutingPCode8: {},
				substitutingPCode9: {},
				substitutingPCode10: {},
				substitutingPCode11: {},

				// BPB_MED policka
				bpb_phone: {
					label: "Tel. číslo"
				},

				bpb_phone0: {},
				bpb_phone1: {},
				bpb_phone2: {},
				bpb_phone3: {},
				bpb_phone4: {},
				bpb_phone5: {},
				bpb_phone6: {},
				bpb_phone7: {},
				bpb_phone8: {},
				bpb_phone9: {},
				bpb_phone10: {},
				bpb_phone11: {},
				bpb_phone12: {},
				bpb_phone13: {}
			}
		}
	}
}
