import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import MoveRecordDialogStore from "../../../stores/MoveRecordDialogStore"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import GlobalStore from "../../../../../global/store/GlobalStore"
import DataStore from "../../../stores/DataStore"
import InformationStore from "../../../../../global/store/InformationStore"

@injectIntl
@observer
export default class MoveRecordDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	clearCheckedEHR() {
		DataStore.checkedEHR = []
	}

	render() {
		const {reloadPatientOverview, intl} = this.props

		const searchItems =
			GlobalStore.orgunits.length > 0 && isSafe(GlobalStore.orgunitsId)
				? GlobalStore.orgunits
						.filter((x) => x._id != GlobalStore.orgunitsId)
						.map((x) => {
							return {
								value: x._id,
								label: x.name_ext
							}
						})
				: []

		const numberTransfered = intl.formatMessage({id: "Patient.form.patientrecord.numberTransfered"})

		return (
			<Dialog
				id="xsMoveRecordDialog"
				open={MoveRecordDialogStore.isOpen}
				onClose={() => MoveRecordDialogStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-random fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Patient.form.patientrecord.moveRecordDialogTitle" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<SearchSelect
						items={searchItems}
						label={<FormattedMessage id="Patient.form.patientrecord.selectDestinationOrgUnit" />}
						onChange={(value) => MoveRecordDialogStore.onHandleChange(value)}
						required
					/>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.btn.not.moveRecord" />}
						onClick={() => MoveRecordDialogStore.close()}
					/>
					<XsButton
						className="xs-info"
						icon={<i className="fal fa-random" />}
						text={<FormattedMessage id="Common.btn.modal.moveRecord" />}
						onClick={() => {
							MoveRecordDialogStore.onConfirm(
								GlobalStore.orgunitsId,
								DataStore.patientDTO.get("patientID"),
								reloadPatientOverview,
								this.clearCheckedEHR,
								InformationStore.open.bind(InformationStore),
								numberTransfered
							)
						}}
						disabled={isEmpty(MoveRecordDialogStore.selectedOrgUnit)}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
