"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"

class DoctorOUPZwarningDialogStore {
	@observable isOpen = false
	@observable warning = ""
	@observable confirm = false

	@action open(warning) {
		this.isOpen = true
		this.warning = warning
	}

	@action close() {
		this.isOpen = false
		this.warning = ""
	}

	@action checkDoctor(patientId, providerId, doctorExpertiseId, checkedProviderId) {
		if (isEmpty(patientId) || isEmpty(providerId) || isEmpty(doctorExpertiseId) || isEmpty(checkedProviderId)) {
			return
		}
		api
			.checkDoctorAndOUPZS(patientId, providerId, doctorExpertiseId, checkedProviderId)
			.call()
			.then((response) => {
				if (
					isSafe(response) &&
					isSafe(response.warning) &&
					isSafe(response.warning.text) &&
					isNotEmpty(response.warning.text)
				) {
					this.open(response.warning.text)
				}
			})
	}
}

var singleton = new DoctorOUPZwarningDialogStore()
export default singleton
