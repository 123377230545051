import React from "react"
import {FormattedMessage} from "react-intl"

export default {
	load() {
		return {
			fields: {
				exportType: {
					label: <FormattedMessage id="Common.label.TypeOfExport" />,
					rules: "required",
					value: "all"
				}
			}
		}
	}
}
