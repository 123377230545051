export default {
	load: {
		storeKey: "PatientContact",
		params: ["contactId"],
		type: "Entity.Data.Contact"
	},
	scheme: {
		contact_type: {
			codeList: {_id: "type"}
		},
		email: {
			field: "email"
		},
		mobile: {
			field: "mobile"
		},
		phone: {
			field: "phone"
		},
		description: {
			field: "description"
		},
		primary: {
			field: "check"
		},
		specific_rel: {
			relation: {
				_type: "Entity.Data.Employee",
				_id: "#clientId"
			}
		}
	}
}
