/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"
import {getSelectedOrgUnit, getUserDoctorInfo} from "../../../../../global/helpers/actions"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				orgunit: {
					label: <FormattedMessage id="Patient.form.patientrequest.orgunit" />,
					rules: "required",
					value: getSelectedOrgUnit()._id
				},
				datefrom: {
					label: <FormattedMessage id="Capitation.List.From" />,
					type: "date",
					rules: "required",
					value: moment().startOf("day")
				},
				dateto: {
					label: <FormattedMessage id="Capitation.List.To" />,
					rules: "required",
					type: "date",
					value: moment().endOf("day")
				},
				insurer: {
					label: <FormattedMessage id="Common.label.insurer" />,
					type: "string"
				},
				recordTypes: {
					label: <FormattedMessage id="Common.label.recordTypeName" />,
					type: "string"
				},
				doctor: {
					label: <FormattedMessage id="Common.label.doctor" />,
					value: getUserDoctorInfo().id
				},
				personnel_id: {}
			}
		}
	}
}
