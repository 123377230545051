import React from "react"
import {observer} from "mobx-react"
import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"

export default observer(
	({id, className, disabled, icon = "", onClick, style, tooltip, tabIndex, rect, placement, delay}) => {
		let classNames = "xs-icon"

		if (isNotEmpty(className)) {
			classNames += " " + className
		}

		if (!rect) {
			classNames += " xs-circle"
		}

		if (isSafe(tooltip)) {
			return (
				<Tooltip title={tooltip} enterDelay={delay} placement={placement}>
					<Button
						id={id}
						className={classNames}
						onClick={onClick}
						disabled={disabled}
						style={style}
						tabIndex={tabIndex}
					>
						{icon}
					</Button>
				</Tooltip>
			)
		} else {
			return (
				<Button id={id} className={classNames} onClick={onClick} disabled={disabled} style={style} tabIndex={tabIndex}>
					{icon}
				</Button>
			)
		}
	}
)
