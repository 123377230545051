// import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "anamnestic_questions"
				},
				datetime_vaccination: {
					label: "Dátum a čas očkovania",
					type: "dateTime",
					value: moment()
				},
				question_1_yes: {
					label: "Áno",
					type: "checkbox",
					value: false
				},
				question_2_yes: {
					label: "Áno",
					type: "checkbox",
					value: false
				},
				question_3_yes: {
					label: "Áno",
					type: "checkbox",
					value: false
				},
				question_4_yes: {
					label: "Áno",
					type: "checkbox",
					value: false
				},
				question_5_yes: {
					label: "Áno",
					type: "checkbox",
					value: false
				},
				question_6_yes: {
					label: "Áno",
					type: "checkbox",
					value: false
				},
				question_7_yes: {
					label: "Áno",
					type: "checkbox",
					value: false
				},
				question_8_yes: {
					label: "Áno",
					type: "checkbox",
					value: false
				},
				question_9_yes: {
					label: "Áno",
					type: "checkbox",
					value: false
				},
				question_1_no: {
					label: "Nie",
					type: "checkbox",
					value: true
				},
				question_2_no: {
					label: "Nie",
					type: "checkbox",
					value: true
				},
				question_3_no: {
					label: "Nie",
					type: "checkbox",
					value: true
				},
				question_4_no: {
					label: "Nie",
					type: "checkbox",
					value: true
				},
				question_5_no: {
					label: "Nie",
					type: "checkbox",
					value: true
				},
				question_6_no: {
					label: "Nie",
					type: "checkbox",
					value: true
				},
				question_7_no: {
					label: "Nie",
					type: "checkbox",
					value: true
				},
				question_8_no: {
					label: "Nie",
					type: "checkbox",
					value: true
				},
				question_9_no: {
					label: "Nie",
					type: "checkbox",
					value: true
				},
				isMan: {
					type: "checkbox",
					value: false
				}
			}
		}
	}
}
