import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import CommMsgQueueDialogStore from "./stores/CommMsgQueueDialogStore"
import UIStore from "./stores/UIStore"
import {Grid} from "@material-ui/core"
import renderHTML from "react-render-html"
import XsButton from "../../global/ui/xsButton/xsButton"
import XsIconButton from "../../global/ui/xsButton/xsIconButton"
import moment from "moment"
import RouterStore from "../../global/store/RouterStore"
import {toJS} from "mobx"
// import GWUpdatingDialogStore from "./stores/GWUpdatingDialogStore"
// import XsLoading from "../../global/ui/xsLoading/xsLoading"

/*
Ocakavany objekt:{
	timestamp: Date.now(),
	text: "Podpisujem recept(y) a posielame ich do poistovne",
	state: 0, //0 - progress 1-success 2-error
	needApprove: true,
	errorCode: null,
	stateText: null,
	navigationIdentifier: {},
	identifier: {}
}
*/
@injectIntl
@observer
export default class CommMsgQueueDialog extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			openedMessageKey: null
		}

		this.stateText = {
			0: (
				<span>
					<i className="fal fa-cogs fa-2x darkBlue"></i>
				</span>
			),
			1: (
				<span>
					<i className="fal fa-check fa-2x xs-greenSuccess"></i>
				</span>
			),
			2: (
				<span>
					<i className="fal fa-exclamation-triangle fa-2x xs-error-text"></i>
				</span>
			)
		}
	}

	onIconClick = (key) => {
		this.setState({
			openedMessageKey: key == this.state.openedMessageKey ? null : key
		})
	}

	onTextClick = (value) => {
		if (
			isSafe(value) &&
			isSafe(value.identifier) &&
			value.identifier._type == "EHR.Data.Medication" &&
			isSafe(value.navigationIdentifier) &&
			isNotEmpty(value.navigationIdentifier.patientId)
		) {
			RouterStore.push(`/patient?id=${value.navigationIdentifier.patientId}`)
			CommMsgQueueDialogStore.close()
		}
	}

	onDeleteClick = (key) => {
		UIStore.commMsgQueue.delete(key)
		if (UIStore.commMsgQueue.size == 0) {
			CommMsgQueueDialogStore.close()
		}
	}
	componentDidMount() {
		CommMsgQueueDialogStore.checkMsgs()
		setInterval(CommMsgQueueDialogStore.checkMsgs, 30000)
	}

	render() {
		let messages = []
		let messagesKeys = Object.keys(toJS(UIStore.commMsgQueue))
		messagesKeys.sort((a, b) => UIStore.commMsgQueue.get(b).timestamp - UIStore.commMsgQueue.get(a).timestamp)
		messagesKeys.forEach((valueKey, key) => {
			let value = UIStore.commMsgQueue.get(valueKey)
			messages.push(
				<div key={key} className={"message"} onClick={() => {}}>
					<Grid container direction="row">
						<Grid item xs={2}>
							{moment(value.timestamp).format("DD.MM.YYYY HH:mm")}
						</Grid>
						<Grid
							item
							xs={8}
							onClick={() => {
								this.onTextClick(value, key)
							}}
						>
							<b className={"messageLink"}>{value.text}</b>
						</Grid>
						{/* <Grid item xs={2}></Grid> */}
						<Grid item xs={1}>
							<span className="pullRight iconSpan" onClick={() => this.onIconClick(key)}>
								{this.stateText[value.state]}
							</span>
						</Grid>
						<Grid item xs={1}>
							<span className="pullRight iconSpan">
								<XsIconButton
									icon={<i className="far fa-trash-alt fa-2x xs-error-text"></i>}
									onClick={() => this.onDeleteClick(valueKey)}
								/>
							</span>
						</Grid>
					</Grid>
					{/* <div className="bottomDiv">
						<XsIconButton
							icon={<i className="far fa-trash-alt fa-2x xs-error-text"></i>}
							onClick={() => this.onDeleteClick(key)}
						/>
					</div> */}
					<Grid container direction="row">
						{this.state.openedMessageKey == key && (
							<Grid item xs={12} className="stateText">
								{isNotEmpty(value.stateText) ? renderHTML(value.stateText) : ""}
							</Grid>
						)}
					</Grid>
				</div>
			)
		})
		return (
			<Dialog
				open={CommMsgQueueDialogStore.isOpen}
				disableBackdropClick={true}
				maxWidth="md"
				id="CommMsgQueueDialog"
				onClose={() => {
					CommMsgQueueDialogStore.close()
					this.setState({openedMessageKey: null})
				}}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-900"
				}}
			>
				<DialogTitle>
					<div className="xs-eHealthProgress-header">
						<div className="xs-eHealthProgress-title">
							<FormattedMessage id="Common.label.tasksInBackground" />
							{/* {this.props.intl
								.formatMessage({id: "Common.label.EHGWUpdating"})
								.replace("{0}", GWUpdatingDialogStore.updatingVersion)``} */}
						</div>
					</div>
				</DialogTitle>
				<DialogContent>
					<div className="xs-body">{messages}</div>
				</DialogContent>
				<DialogActions className="xs-footer">
					<XsButton
						className="xs-info"
						text={<FormattedMessage id="Common.form.close" />}
						onClick={() => {
							this.setState({openedMessageKey: null})
							CommMsgQueueDialogStore.close()
						}}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
