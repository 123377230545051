import React from "react"
import {FormattedMessage} from "react-intl"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				patientId: {
					label: <FormattedMessage id="Patient.labels.patient" />,
					rules: "required",
					type: "string"
				},
				insurer_id: {},
				delivery: {
					label: <FormattedMessage id="Common.label.delivery" />,
					rules: "required",
					type: "string"
				},
				diagnosis: {
					label: <FormattedMessage id="Patient.form.patientrecord.diagnosis" />,
					rules: "required"
				},
				number: {
					label: <FormattedMessage id="Delivery.List.Count" />,
					rules: "required",
					type: "number",
					value: 1
				},
				payer_type: {
					label: <FormattedMessage id="Common.label.payerType" />,
					rules: "required"
				},
				delivery_extension: {
					label: <FormattedMessage id="Patient.form.patientrecord.deliveryTable.deliveryExtension" />
				},
				insuree_type: {
					label: <FormattedMessage id="Delivery.List.InsureeType" />
				},
				sender_type: {
					label: <FormattedMessage id="Delivery.List.SenderTypyShort" />
				},
				refund_type: {
					label: <FormattedMessage id="Delivery.List.RefundType" />
				},
				movement: {
					label: <FormattedMessage id="Delivery.List.MovementShort" />
				},
				date: {
					label: <FormattedMessage id="Common.label.date" />,
					rules: "required",
					value: moment()
				}
			}
		}
	}
}
