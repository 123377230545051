export const portfolioBinding = {
	load: {
		//storeKey: "PortfolioInfo",
		//params: ["personId"],
		type: "Entity.Data.SpecificRelRelationship"
		//flags: "CD"
	},
	scheme: {
		rel_type: {
			object: {
				descriptor: {
					_type: "Entity.CL.RelationshipType",
					_id: "General"
				}
			}
		},
		specific_rel_1: {
			relation: {
				_type: "Entity.Data.OrgUnit",
				_id: "#providerId"
			}
		},
		specific_rel_2: {
			relation: {
				_type: "Entity.Data.Client",
				_id: "#clientId"
			}
		}
	}
}

export const patientBinding = {
	load: {
		storeKey: "PatientInfo",
		params: ["personId"],
		type: "Entity.Data.Person",
		flags: "CD"
	},
	scheme: {
		identifier: {
			field: "identifier"
		},
		title_before: {
			codeList: {_id: "title_before"}
		},
		first_name: {
			field: "firstname"
		},
		last_name: {
			field: "lastname"
		},
		birth_name: {
			field: "birth_name"
		},
		title_after: {
			codeList: {_id: "title_after"}
		},
		birth_date: {
			field: "dateOfBirth"
		},
		gender: {
			codeList: {_id: "gender"}
		},
		death_date: {
			field: "deathDate"
		},
		citizenships: {
			array: {
				load: (item) =>
					isSafe(item.type) /*&& item.type.code === "nat"*/ && isSafe(item.validity) && item.validity.to === null,
				descriptor: {
					_type: "Entity.Data.Citizenship",
					_ref: false
				},
				scheme: {
					country: {
						codeList: {_id: "country"}
					}
				}
			}
		},
		specific_rels: {
			array: {
				load: (item) => item._type === "Entity.Data.Client" && item.active,
				descriptor: {
					_type: "Entity.Data.Client",

					_ref: false
				},
				scheme: {
					addresses: {
						array: {
							load: (item) => item.active,
							descriptor: {
								_type: "Entity.Data.Address",
								_ref: false,
								type: "PA"
							},
							scheme: {
								city_plain: {
									field: "cityText"
								},
								obj_city: {
									// relation: "@city"
									codeList: {_id: "city"}
								},
								region: {
									// relation: "@region"
									codeList: {_id: "region"}
								},
								county: {
									// relation: "@county"
									codeList: {_id: "county"}
								},
								obj_zip: {
									// relation: "@zip"
									codeList: {_id: "zip"}
								},
								zip_plain: {
									field: "zipText"
								},
								street_plain: {
									field: "street"
								},
								house_number: {
									field: "houseNumber"
								}
							}
						}
					},
					insurances: {
						array: {
							load: (item) => isSafe(item.validity) && item.validity.now && item.active,
							descriptor: {
								_type: "EHR.Data.Patient.Insurance",
								_ref: false
							},
							scheme: {
								insurer: {
									object: {
										scheme: {
											_id: {
												field: "insurer"
											},
											// name_ext: {
											// 	field: "insurer"
											// },
											code_ext: {
												field: "insurerCode"
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

export const patientDialogBinding = {
	load: {
		storeKey: "PatientInfo",
		params: ["personId"],
		type: "Entity.Data.Person",
		flags: "CD"
	},
	scheme: {
		identifier: {
			field: "identifier"
		},
		title_before: {
			codeList: {_id: "title_before"}
		},
		first_name: {
			field: "firstname"
		},
		last_name: {
			field: "lastname"
		},
		birth_name: {
			field: "birth_name"
		},
		title_after: {
			codeList: {_id: "title_after"}
		},
		birth_date: {
			field: "dateOfBirth"
		},
		gender: {
			codeList: {_id: "gender"}
		},
		citizenships: {
			array: {
				load: (item) =>
					isSafe(item.type) /*&& item.type.code === "nat"*/ && isSafe(item.validity) && item.validity.to === null,
				descriptor: {
					_type: "Entity.Data.Citizenship",
					_ref: false
				},
				scheme: {
					country: {
						codeList: {_id: "country"}
					}
				}
			}
		},
		specific_rels: {
			array: {
				load: (item) => item._type === "Entity.Data.Client" && item.active,
				descriptor: {
					_type: "Entity.Data.Client",

					_ref: false
				},
				scheme: {
					addresses: {
						array: {
							load: (item) => item.active,
							descriptor: {
								_type: "Entity.Data.Address",
								_ref: false,
								type: "PA"
							},
							scheme: {
								city_plain: {
									field: "cityText"
								},
								obj_city: {
									// relation: "@city"
									codeList: {_id: "city"}
								},
								region: {
									// relation: "@region"
									codeList: {_id: "region"}
								},
								county: {
									// relation: "@county"
									codeList: {_id: "county"}
								},
								obj_zip: {
									// relation: "@zip"
									codeList: {_id: "zip"}
								},
								zip_plain: {
									field: "zipText"
								},
								street_plain: {
									field: "street"
								},
								house_number: {
									field: "houseNumber"
								}
							}
						}
					},
					insurances: {
						array: {
							load: (item) => isSafe(item.validity) && item.validity.now && item.active,
							descriptor: {
								_type: "EHR.Data.Patient.Insurance",
								_ref: false
							},
							scheme: {
								insurer: {
									object: {
										scheme: {
											_id: {
												field: "insurer"
											},
											// name_ext: {
											// 	field: "insurer"
											// },
											code_ext: {
												field: "insurerCode"
											}
										}
									}
								},
								validity: {
									object: {
										scheme: {
											from: {
												field: "from"
											}
										}
									}
								},
								country: {
									object: {
										descriptor: {
											_id: "SK"
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// export const patientPutBinding = {
// 	load: {
// 		storeKey: "PatientInfo",
// 		params: ["personId"],
// 		type: "Entity.Data.Person",
// 		flags: "CD"
// 	},
// 	scheme: {
// 		identifier: {
// 			field: "identifier"
// 		},
// 		title_before: {
// 			codeList: {_id: "title_before"}
// 		},
// 		first_name: {
// 			field: "firstname"
// 		},
// 		last_name: {
// 			field: "lastname"
// 		},
// 		title_after: {
// 			codeList: {_id: "title_after"}
// 		},
// 		birth_date: {
// 			field: "dateOfBirth"
// 		},
// 		gender: {
// 			codeList: {_id: "gender"}
// 		},
// 		citizenships: {
// 			array: {
// 				load: (item) =>
// 					isSafe(item.type) /*&& item.type.code === "nat"*/ && isSafe(item.validity) && item.validity.to === null,
// 				descriptor: {
// 					_type: "Entity.Data.Citizenship",
// 					_ref: false
// 				},
// 				scheme: {
// 					country: {
// 						codeList: {_id: "country"}
// 					}
// 				}
// 			}
// 		},
// 		specific_rels: {
// 			array: {
// 				load: (item) => item._type === "Entity.Data.Client" && item.active,
// 				descriptor: {
// 					_type: "Entity.Data.Client",
// 					type: "PA",
// 					_ref: false
// 				},
// 				scheme: {
// 					addresses: {
// 						array: {
// 							load: (item) => item.active,
// 							descriptor: {
// 								_type: "Entity.Data.Address",
// 								_ref: false
// 							},
// 							scheme: {
// 								city_plain: {
// 									field: "cityText"
// 								},
// 								obj_city: {
// 									object: {
// 										scheme: {
// 											_id: {
// 												field: "city"
// 											}
// 										}
// 									}
// 								},
// 								obj_zip: {
// 									object: {
// 										scheme: {
// 											_id: {
// 												field: "zip"
// 											}
// 										}
// 									}
// 								},
// 								zip_plain: {
// 									field: "zipText"
// 								},
// 								street_plain: {
// 									field: "street"
// 								},
// 								house_number: {
// 									field: "houseNumber"
// 								}
// 							}
// 						}
// 					},
// 					insurances: {
// 						array: {
// 							load: (item) => isSafe(item.validity) && item.validity.to === null && item.active,
// 							descriptor: {
// 								_type: "EHR.Data.Patient.Insurance",
// 								_ref: false
// 							},
// 							scheme: {
// 								insurer: {
// 									object: {
// 										scheme: {
// 											_id: {
// 												field: "insurer"
// 											},
// 											// name: {
// 											//   field: "insurer"
// 											// },
// 											code_ext: {
// 												field: "insurerCode"
// 											}
// 										}
// 									}
// 								}
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// export const patientSearchBinding = {
// 	load: {
// 		storeKey: "PatientInfo",
// 		params: ["personId"],
// 		type: "Entity.Data.Person",
// 		flags: "CD"
// 	},
// 	scheme: {
// 		title_before: {
// 			codeList: {_id: "title_before"}
// 		},
// 		first_name: {
// 			field: "firstname"
// 		},
// 		last_name: {
// 			field: "lastname"
// 		},
// 		title_after: {
// 			codeList: {_id: "title_after"}
// 		},
// 		birth_date: {
// 			field: "dateOfBirth"
// 		},
// 		gender: {
// 			codeList: {_id: "gender"}
// 		},
// 		citizenships: {
// 			array: {
// 				load: (item) =>
// 					isSafe(item.type) /*&& item.type.code === "nat"*/ && isSafe(item.validity) && item.validity.to === null,
// 				descriptor: {
// 					_type: "Entity.Data.Citizenship",
// 					_ref: false
// 				},
// 				scheme: {
// 					country: {
// 						codeList: {_id: "country"}
// 					}
// 				}
// 			}
// 		},
// 		specific_rels: {
// 			array: {
// 				load: (item) => item._type === "Entity.Data.Client" && item.active,
// 				descriptor: {
// 					_type: "Entity.Data.Client",
// 					type: "PA",
// 					_ref: false
// 				},
// 				scheme: {
// 					addresses: {
// 						array: {
// 							load: (item) => item.active,
// 							descriptor: {
// 								_type: "Entity.Data.Address",
// 								_ref: false
// 							},
// 							scheme: {
// 								city_plain: {
// 									field: "cityText"
// 								},
// 								obj_city: {
// 									field: "city"
// 								},
// 								obj_zip: {
// 									field: "zip"
// 								},
// 								zip_plain: {
// 									field: "zipText"
// 								},
// 								street_plain: {
// 									field: "street"
// 								},
// 								house_number: {
// 									field: "houseNumber"
// 								}
// 							}
// 						}
// 					},
// 					insurances: {
// 						array: {
// 							load: (item) => isSafe(item.validity) && item.validity.to === null && item.active,
// 							descriptor: {
// 								_type: "EHR.Data.Patient.Insurance",
// 								_ref: false
// 							},
// 							scheme: {
// 								insurer: {
// 									object: {
// 										scheme: {
// 											_id: {
// 											  field: "insurer"
// 											},
// 											// name: {
// 											// 	field: "insurer"
// 											// },
// 											code_ext: {
// 												field: "insurerCode"
// 											}
// 										}
// 									}
// 								}
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

export const patientContactPersonBinding = {
	load: {
		storeKey: "ContactPerson",
		params: ["contactPersonID"],
		type: "Entity.Data.Person",
		flags: "CDL3"
	},
	scheme: {
		identifier: {
			field: "identifier"
		},
		title_before: {
			codeList: {_id: "title_before"}
		},
		first_name: {
			field: "firstname"
		},
		last_name: {
			field: "lastname"
		},
		// birth_name: {
		// 	field: "birth_name"
		// },
		title_after: {
			codeList: {_id: "title_after"}
		},
		birth_date: {
			field: "dateOfBirth"
		},
		gender: {
			codeList: {_id: "gender"}
		},
		specific_rels: {
			array: {
				load: (item) => item._type === "Entity.Data.SpecificRelRelated" && item.active,
				descriptor: {
					_type: "Entity.Data.SpecificRelRelated",
					_ref: false
				},
				scheme: {
					addresses: {
						array: {
							load: (item) => item.active,
							descriptor: {
								_type: "Entity.Data.Address",
								_ref: false
							},
							scheme: {
								city_plain: {
									field: "city"
								},
								zip_plain: {
									field: "zip"
								},
								street_plain: {
									field: "street"
								},
								house_number: {
									field: "houseNumber"
								}
							}
						}
					},
					contacts: {
						array: {
							load: (item) => item.active && item.primary,
							descriptor: {
								_type: "Entity.Data.Contact",
								_ref: false
							},
							scheme: {
								mobile: {
									field: "mobile"
								},
								phone: {
									field: "phone"
								},
								email: {
									field: "email"
								},
								description: {
									field: "description"
								}
							}
						}
					}
				}
			}
		}
	}
}

export const contactPersonPortfolioBinding = {
	load: {
		type: "Entity.Data.SpecificRelRelationship"
	},
	scheme: {
		rel_type: {
			object: {
				descriptor: {
					_type: "Entity.CL.RelationshipType",
					_id: "#typeID"
				}
			}
		},
		specific_rel_1: {
			relation: {
				_type: "Entity.Data.Client",
				_id: "#patientID"
			}
		},
		specific_rel_2: {
			relation: {
				_type: "#specRelType",
				_id: "#contactPersonID"
			}
		}
	}
}

export const contactPersonPortfolioDeleteBinding = {
	load: {
		type: "Entity.Data.SpecificRelRelationship"
	}
}

export const contactPersonPPhyAddressBinding = {
	load: {
		storeKey: "Personnel",
		// params: ["personnelID"],
		type: "EHR.Data.Entity.Personnel"
	},
	scheme: {
		_id: {
			relation: "#personnelID"
		},
		addresses: {
			array: {
				load: (item) => item.active && item.type._id === "CA",
				descriptor: {
					_type: "Entity.Data.Address",
					_ref: false,
					type: "CA"
				},
				scheme: {
					city_plain: {
						field: "city"
					},
					zip_plain: {
						field: "zip"
					},
					street_plain: {
						field: "street"
					},
					house_number: {
						field: "houseNumber"
					}
				}
			}
		}
	}
}
