"use strict"

import React from "react"
import {observer} from "mobx-react"
import ReactTooltip from "react-tooltip"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
// special import for Material-UI binding
import api from "../../../actions/api"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import dataBindings from "./contactAddressBindings"
import {
	loadDataToFormByScheme,
	insertFormDataByScheme,
	deleteDataByScheme,
	resetForm
} from "../../../../../global/helpers/bindings"
import {FormattedMessage} from "react-intl"
// import UI controls here
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import Grid from "@material-ui/core/Grid"
import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"
// definitions of form fields
import fields from "./contactAddressFields"
// actions
import GlobalStore from "../../../../../global/store/GlobalStore"
import WarningStore from "../../../../../global/store/WarningStore"
import DataStore from "../../../stores/DataStore"
import ContactAddressFormStore from "../../../stores/ContactAddressFormStore"
import {injectIntl} from "react-intl"
import config from "../../../../../global/config/settings"
import {GWApiCall} from "../../../../../global/helpers/api"
import {getUserPersonnelID, getSelectedOrgUnit, getUser} from "../../../../../global/helpers/actions"

@injectIntl
@observer
export default class ContactAddressTableEx extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			refreshComponent: false
		}

		const hooks = {
			onSubmit(form) {
				if (
					isEmpty(form.$("city").value) &&
					isEmpty(form.$("cityText").value) // ||
					// (isEmpty(form.$("zip").value) && isEmpty(form.$("zipText").value))
				) {
					WarningStore.open(props.intl.formatMessage({id: "Patient.form.contactAddress.warningMessage"}))
					form.invalidate()
				}

				if (
					form.$("check").value &&
					isSafe(DataStore.patientDTO.get("addresses")) &&
					DataStore.patientDTO.get("addresses").length > 0
				) {
					let result = false
					if (isNotEmpty(form.$("addressId").value)) {
						result = DataStore.patientDTO
							.get("addresses")
							.some((address) => address.primary === true && address._id != form.$("addressId").value)
					} else {
						result = DataStore.patientDTO.get("addresses").some((address) => address.primary === true && address._id)
					}

					if (result) {
						WarningStore.open(props.intl.formatMessage({id: "Patient.form.contactAddress.warningMessage.primary"}))
						form.invalidate()
					}
				}
			},
			onSuccess(form) {
				form.$("city").value = form.$("city").value === "" ? null : form.$("city").value
				form.$("zip").value = form.$("zip").value === "" ? null : form.$("zip").value
				insertFormDataByScheme(
					form,
					dataBindings,
					{
						addressId: isNotEmpty(form.$("addressId").get("value")) ? form.$("addressId").get("value") : null,
						specrel: {
							_type: "Entity.Data.Client",
							_id: props.clientId
						},
						city: isSafe(form.$("city").value) ? {_id: form.$("city").value} : null,
						zip: isSafe(form.$("zip").value) ? {_id: form.$("zip").value} : null
					},
					() => {
						DataStore.getPatientAddresses(props.id)
						ContactAddressFormStore.editable = false
					},
					false,
					"Patient.form.contactAddress.saveMessage"
				)
				form.reset()
				ContactAddressFormStore.isAdd = false
			},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		this.initForm()
	}

	initForm() {
		resetForm(this.form, dataBindings)
	}

	form

	onHandleRowClick = (dataRow) => {
		loadDataToFormByScheme(this.form, dataBindings, {contactAddressId: dataRow.delete}, (data) => {
			this.form.$("addressId").set(data._id)
			if (isSafe(data.obj_city)) {
				this.form.$("city").value = data.obj_city._id
			} else if (isNotEmpty(data.city_plain)) {
				this.refreshAutocomplete()
				this.form.$("cityText").value = data.city_plain
			}
			if (isSafe(data.obj_zip)) {
				this.form.$("zip").value = data.obj_zip._id
			} else if (isNotEmpty(data.zip_plain)) {
				this.form.$("zipText").set(data.zip_plain)
				this.refreshAutocomplete()
			}
		})

		ContactAddressFormStore.editable = true
		ContactAddressFormStore.isAdd = true
	}

	deleteContactAddress = () => {
		let identifier = null
		let personData = DataStore.patientDTO
			.get("addresses")
			.find((obj) => obj._id == GlobalStore.confirmationDialogParams.contactAddressId)
		if (isSafe(personData)) {
			identifier = personData.identifiers.find((ident) => ident.type == "NCZIRECIDENT")
		}

		deleteDataByScheme(
			dataBindings,
			GlobalStore.confirmationDialogParams.contactAddressId,
			(res) => {
				if (isSafe(identifier) && isNotEmpty(identifier.value)) {
					const selectedOrgUnit = getSelectedOrgUnit()
					const user = getUser()
					const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
					const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
					const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
					const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
					const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

					const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
						? DataStore.patientDTO.get("patient").validInfo.client
						: null
					const JRUZIDObj = isSafe(patientClientInfo.identifiers)
						? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
						: null

					GWApiCall({
						method: "POST",
						uri: `${config.GW_BASE_URL}/CancelPatientSummaryContactInformation`,
						body: JSON.stringify({
							ClassName: "ZrusKontaktneUdajePacientskehoSumaru_v3",
							ExtRecordIds: ["" + identifier.value],
							AmbeePatientId: DataStore.patientDTO.get("patientID"),
							PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
							PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
							AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
							AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
							AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
							AmbeePersonnelId: getUserPersonnelID(),
							OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
							PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
							PersonnelExpertiseJRUZVersion: specVer,
							PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
							RecordSignerData: isSafe(res.record_signer_data)
								? res.record_signer_data.map((x) => {
										return {
											Ref: x._ref,
											Type: x._type,
											RecordClass: x.record_class,
											RecordExternalId: x.record_external_id,
											RecordId: x.record_id
										}
								  })
								: null
						})
					})
						.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
						.then((data) => {
							if (isSafe(data) && isNotEmpty(data.ErrorMessage)) {
								GlobalStore.checkEHGWError(data.ErrorCode, data.ErrorMessage, data.IsEhealthException)
								// WarningStore.generateErrorMessage(data.IsEhealthException, data.ErrorMessage)
							}
						})
						.catch()
				}
				DataStore.getPatientAddresses(this.props.id)
			},
			"Patient.form.contactAddress.deleteMessage"
		)
		ContactAddressFormStore.editable = false
		this.initForm()
	}

	refreshAutocomplete() {
		this.setState({refreshComponent: !this.state.refreshComponent})
	}

	render() {
		return (
			<div className="xs-contact-address-table-ex">
				<div className="xs-table">
					<div className="xs-bold mb-1">{`Adresu trvalého pobytu vyplníte v karte "osobné údaje" (viď vyššie).`}</div>
					<XsTable
						config={{
							columnDefs: {
								region: {
									title: <FormattedMessage id="Common.label.region" />,
									type: "string",
									design: {
										width: "175px"
									}
								},
								county: {
									title: <FormattedMessage id="Common.label.county" />,
									type: "string",
									design: {
										width: "150px"
									}
								},
								street: {
									title: <FormattedMessage id="Common.label.street" />,
									type: "string"
									// design: {
									//   width: "175px",
									// },
								},
								house_number: {
									title: <FormattedMessage id="Common.label.number" />,
									type: "string",
									design: {
										width: "100px"
									},
									sortable: true
								},
								city: {
									title: <FormattedMessage id="Common.label.city" />,
									type: "string",
									design: {
										width: "300px",
										body: {
											className: (row) => (row.primary ? "xs-bold" : null)
										}
									}
								},
								zip: {
									title: <FormattedMessage id="Common.label.zip" />,
									type: "string",
									design: {
										width: "125px",
										body: {
											className: (row) => (row.primary ? "xs-bold" : null)
										}
									}
								},
								delete: {
									title: "",
									type: "action",
									design: {
										width: "42px",
										body: {
											renderer: (props) => {
												return (
													<XsIconButton
														className="action-delete"
														icon={
															<span>
																<i
																	className="fal fa-trash-alt fa-lg xs-greyDefault"
																	data-tip
																	data-for="removeAddress"
																/>
																<ReactTooltip id="removeAddress" effect="solid">
																	<FormattedMessage id="Common.label.removeAddress" />
																</ReactTooltip>
															</span>
														}
														onClick={() =>
															GlobalStore.openConfirmationDialog("xsDeletePatientContactAddress", {
																contactAddressId: props.value
															})
														}
													/>
												)
											}
										}
									}
								}
							},
							options: {
								// hideHeader: true,
								// selectRow: true,
								hidePager: true,
								showCursor: true,
								onRowClick: (dataRow) => {
									this.onHandleRowClick(dataRow)
								},
								mapper: (dataRow) => {
									return {
										region:
											isNotEmpty(dataRow.region) && isNotEmpty(dataRow.region.name_ext) ? dataRow.region.name_ext : "",
										county:
											isNotEmpty(dataRow.county) && isNotEmpty(dataRow.county.name_ext) ? dataRow.county.name_ext : "",
										street: isNotEmpty(dataRow.street) ? dataRow.street : "",
										house_number: isNotEmpty(dataRow.house_number) ? dataRow.house_number : "",
										city:
											isNotEmpty(dataRow.obj_city) && isNotEmpty(dataRow.obj_city.name_ext)
												? dataRow.obj_city.name_ext
												: isNotEmpty(dataRow.city_plain)
												? dataRow.city_plain
												: "",
										zip:
											isNotEmpty(dataRow.obj_zip) && isNotEmpty(dataRow.obj_zip.name_ext)
												? dataRow.obj_zip.name_ext
												: isNotEmpty(dataRow.zip_plain)
												? dataRow.zip_plain
												: "",
										delete: dataRow._id,
										primary: dataRow.primary
										// "primary": dataRow.primary
									}
								}
							},
							dataSource: DataStore.patientDTO.get("addresses")
						}}
					/>
				</div>
				<div className="xs-form">
					{ContactAddressFormStore.isAdd ? (
						<form>
							<Grid container direction="row" spacing={8}>
								{/* <Grid item xs={2}><SearchSelect field={this.form.$("region")} items={GlobalStore.CL["contactTypes"]} required /></Grid> */}
								<Grid item xs={2}>
									<SearchSelect
										field={this.form.$("region")}
										items={GlobalStore.CL["addressRegion"]}
										menuPlacement="top"
									/>
								</Grid>
								<Grid item xs={2}>
									<SearchSelect
										field={this.form.$("county")}
										items={GlobalStore.CL["addressCounty"]}
										menuPlacement="top"
									/>
								</Grid>
								<Grid item xs={2}>
									<XsInput white={true} field={this.form.$("street")} />
								</Grid>
								<Grid item xs={1}>
									<XsInput white={true} field={this.form.$("house_number")} />
								</Grid>
								{/* <Grid item xs={1}><XsInput field={this.form.$("zip")} /></Grid> */}
								<Grid item xs={1}>
									<XsAutocomplete
										field={this.form.$("zip")}
										minLengthForSearch="3"
										api={api.loadEntityZip}
										inputRenderer={(obj) => obj.name_ext}
										saveValue={(obj) => obj.code}
										freeText={true}
										freeTextField={this.form.$("zipText")}
										postAction={(obj) => {
											this.form.$("zipText").value = obj.name_ext
										}}
										onClear={() => {
											this.form.$("zipText").value = ""
										}}
										refreshComponent={this.state.refreshComponent}
										white={true}
									/>
								</Grid>
								<Grid item xs={2}>
									<XsAutocomplete
										field={this.form.$("city")}
										minLengthForSearch="3"
										api={api.loadEntityCity}
										inputRenderer={(obj) => obj.name_ext}
										saveValue={(obj) => obj.code}
										freeText={true}
										freeTextField={this.form.$("cityText")}
										postAction={(obj) => {
											this.form.$("cityText").set("value", obj.name_ext)
										}}
										onClear={() => {
											this.form.$("cityText").value = ""
										}}
										refreshComponent={this.state.refreshComponent}
										white={true}
									/>
									{/* <XsInput field={this.form.$("city")} /> */}
								</Grid>
								<Grid item xs={2} className="xs-contact-checkbox">
									<XsCheckbox field={this.form.$("check")} />
								</Grid>
								{/* <Grid item xs={1}><XsInput field={this.form.$("type")} /></Grid> */}
								<Grid item xs={12} className="xs-form-actions-newline">
									{ContactAddressFormStore.editable ? (
										<XsButton
											className="xs-success mr-3"
											type="submit"
											onClick={this.form.onSubmit}
											text={<FormattedMessage id="Common.label.save" />}
											icon={<SaveIcon />}
										/>
									) : (
										<XsButton
											className="xs-success mr-3"
											type="submit"
											onClick={this.form.onSubmit}
											text={<FormattedMessage id="Common.label.add" />}
											icon={<SaveIcon />}
										/>
									)}
									<XsButton
										className="xs-default xs-outline"
										onClick={() => {
											// this.initForm()
											this.form.reset()
											ContactAddressFormStore.editable = false
											ContactAddressFormStore.isAdd = false
											this.refreshAutocomplete()
										}}
										text={<FormattedMessage id="Common.label.cancel" />}
										icon={<CancelIcon />}
									/>
								</Grid>
							</Grid>
						</form>
					) : (
						<XsButton
							className="xs-default xs-outline xs-addAnother"
							icon={<i className="fal fa-plus-circle" />}
							text={<FormattedMessage id="Common.button.addAnother" />}
							onClick={() => {
								ContactAddressFormStore.isAdd = true
								ContactAddressFormStore.editable = false
								this.initForm()
							}}
						/>
					)}
				</div>
				<XsConfirmationDialog
					type="danger"
					name="xsDeletePatientContactAddress"
					text={<FormattedMessage id="Patient.form.addressContactForm.confirmationMessage" />}
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.btn.contactAddress.delete"
					}}
					onConfirmation={() => this.deleteContactAddress()}
				/>
			</div>
		)
	}
}
