import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
// import ReactTooltip from "react-tooltip"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
// import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import VaccinationListStore from "../../../stores/VaccinationListStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import ExportVaccinatedListToXlsDialogStore from "../../../stores/ExportVaccinatedListToXlsDialogStore"
import fields from "./vaccinatedFields"
import {getUserPersonnelOrgUnits} from "../../../../../global/helpers/actions"
import TableStore from "../../../stores/TableStore"
import XsNumberInput from "../../../../../global/ui/xsInput/xsNumberInput"
import XsInput from "../../../../../global/ui/xsInput/xsInput"

@injectIntl
@observer
export default class VaccinatedFilter extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				if (
					TableStore.tables.hasOwnProperty("vaccinationList") &&
					TableStore.tables["vaccinationList"].hasOwnProperty("index")
				) {
					delete TableStore.tables["vaccinationList"]["index"]
				}

				VaccinationListStore.getFiltersVaccinated(form)
			},
			onError() {}
		}

		if (isEmpty(VaccinationListStore.vaccinatedFormRef)) {
			VaccinationListStore.vaccinatedFormRef = new MobxReactForm(fields.load(), {
				plugins: {dvr: validatorjs},
				hooks,
				bindings
			})
		}
		this.form = VaccinationListStore.vaccinatedFormRef

		VaccinationListStore.getFiltersVaccinated(this.form)

		GlobalStore.refreshCodeLists(["medicationSubTypes"])
	}

	render() {
		VaccinationListStore.isChangedFilterVaccinated(this.form.values())

		return (
			<Grid container spacing={8} alignItems="center">
				<Grid item xs={1}>
					<XsDateTimePicker white field={this.form.$("date_from")} showTimeSelect={false} />
				</Grid>
				<Grid item xs={1}>
					<XsDateTimePicker white field={this.form.$("date_to")} showTimeSelect={false} />
				</Grid>
				<Grid item xs={2}>
					<XsNumberInput white field={this.form.$("doseNumber")} min={"1"} step={"1"} />
				</Grid>
				<Grid item xs={2}>
					<SearchSelect white field={this.form.$("org_unit")} items={getUserPersonnelOrgUnits()} />
				</Grid>
				<Grid item xs={2}>
					<XsInput white field={this.form.$("user")}/>
				</Grid>
				<Grid item xs={1} className="pb-0">
					<XsIconButton
						rect
						className="xs-default xs-outline"
						tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
						onClick={this.form.onSubmit}
						icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
					/>
				</Grid>
				<Grid item xs={3}>
					<Grid container justify="flex-end" spacing={8}>
						<Grid item>
							<XsButton
								className="xs-primary"
								text={<FormattedMessage id="Common.label.export" />}
								icon={<i className="fal fa-file-excel fa-lg" />}
								onClick={() => {
									ExportVaccinatedListToXlsDialogStore.open()
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		)
	}
}
