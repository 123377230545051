import React from "react"
import ReactTooltip from "react-tooltip"
import {FormattedMessage} from "react-intl"
import {observer} from "mobx-react"
import moment from "moment"
import CalendarStore from "../../stores/CalendarStore"
import GlobalStore from "../../../../global/store/GlobalStore"
import UIStore from "../../stores/UIStore"

@observer
export default class WeeklyView extends React.Component {
	constructor(props) {
		super(props)

		this.weeklyRow = {}
	}

	getDayClass = (day, hour, arrLength, dayIndex) => {
		let className = moment()
			.local()
			.isSame(moment(day), "date")
			? "xs-active xs-day"
			: "xs-day"
		if (dayIndex === arrLength - 1) {
			className += " xs-last"
		}

		if (
			moment(day)
				.set("hour", hour)
				.isBefore(moment())
		) {
			className += " xs-dayInPast"
		}

		if ((moment(day).day() === 0 || moment(day).day() === 6) && this.props.highlightWeekend) {
			className += " xs-weekend"
		}

		let dayNum = moment(day).day()

		if (isSafe(GlobalStore.openingHour) && isSafe(GlobalStore.openingHour[dayNum])) {
			let from = GlobalStore.openingHour[dayNum].from
			let to = GlobalStore.openingHour[dayNum].to

			className += hour < +from || hour > +to ? " xs-outOfOpeningHour" : ""
		}

		return className
	}

	getHourLabelClass = (hour) => {
		return moment()
			.local()
			.get("hours") == hour
			? "xs-weekly-hour xs-hour-active"
			: "xs-weekly-hour"
	}

	isActiveHour = (hour) => {
		return (
			moment()
				.local()
				.get("hours") == hour
		)
	}

	getLocale = () => {
		return this.context.intl.formatMessage({
			id: "Application.moment.locale",
			defaultMessage: "sk-SK"
		})
	}

	getDatesForHeader = () => {
		const days = []

		let dayCount = 4

		if (UIStore.weekPlusWeekend) {
			dayCount = 6
		}

		for (let i = 0; i <= dayCount; i++) {
			const startOfTheWeek = moment(CalendarStore.activeWeek)
			const day = startOfTheWeek.add(i, "day")
			days.push(day)
		}

		return days
	}

	getOpenHours = () => {
		let from = 0
		let to = 23
		let hours = []
		for (let i = from; i <= to; i++) {
			hours.push(i)
		}

		return hours
	}

	componentDidUpdate() {
		this.setPos()
	}

	setPos = () => {
		if (isSafe(this.weeklyViewRef) && !CalendarStore.calendarToggledFull) {
			let hour = moment().hours()

			let scrollVal = this.weeklyRow[hour].offsetTop - 160
			this.weeklyViewRef.scrollTop = scrollVal
		}
	}

	render() {
		const expandTooltip = CalendarStore.calendarToggledFull ? "hideCalendar" : "showAllCalendar"

		const {hideFullToggle, highlightWeekend, renderEvents} = this.props

		return (
			<div className="xs-weekly-view">
				<div className={`xs-week-header ${CalendarStore.calendarToggledFull || hideFullToggle ? "" : "xs-fix"}`}>
					<div className="xs-arrows" />
					{this.getDatesForHeader().map((day, key) => {
						const isWeekend = moment(day).day() === 0 || moment(day).day() === 6 ? true : false
						let className = moment(day).isSame(new Date(), "date") ? "xs-weekDay xs-active" : "xs-weekDay"
						className += isWeekend && highlightWeekend ? " xs-weekend" : ""
						return (
							<div key={key} className={className}>
								{moment(day).format("ddd D. MMM")}
							</div>
						)
					})}
				</div>
				<div className="xs-weekly-body-scrollbar" ref>
					<div
						ref={(div) => (this.weeklyViewRef = div)}
						className={`xs-weekly-body ${CalendarStore.calendarToggledFull || hideFullToggle ? "xs-unfix" : "xs-fix"}`}
					>
						{this.getOpenHours().map((hour, i) => {
							return (
								<div key={i} ref={(row) => (this.weeklyRow[hour] = row)} className="xs-weekly-row">
									<div className={this.getHourLabelClass(hour)}>{hour}:00</div>
									{this.getDatesForHeader().map((date, idx) => {
										const dayClass = this.getDayClass(date, hour, this.getOpenHours().length, idx)
										if (renderEvents) {
											return renderEvents(hour, i, date, idx, dayClass)
										}
									})}
								</div>
							)
						})}
					</div>
				</div>
				{/* {!hideFullToggle && ( */}
				<div
					data-tip
					data-for={expandTooltip}
					className="xs-full-screen"
					onClick={() => (CalendarStore.calendarToggledFull = !CalendarStore.calendarToggledFull)}
				>
					<i className={`fal fa-chevron-${CalendarStore.calendarToggledFull ? "up" : "down"} fa-lg"`} />
				</div>
				{/* )} */}
				<ReactTooltip id={expandTooltip}>
					<FormattedMessage id={`Common.label.${expandTooltip}`} />
				</ReactTooltip>
			</div>
		)
	}
}
