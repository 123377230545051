"use strict"

import {observable, action} from "mobx"
// import PrintTemplatesStore from "./PrintTemplatesStore"

class PrescriptionTemplateDialogStore {
	@observable isOpen = false
	@observable templates = []
	@observable showSaveForm = false

	formKey = null
	saveCallback = null

	@action open(templates, key, saveCallback) {
		this.isOpen = true
		this.templates = templates
		this.formKey = key

		if (isSafe(saveCallback)) {
			this.saveCallback = saveCallback
			this.showSaveForm = true
		}
	}

	@action close() {
		this.isOpen = false
		setTimeout(() => {
			this.showSaveForm = false
			this.saveCallback = null
			this.templates = []
			this.formKey = null
		}, 0)
	}
}

var singleton = new PrescriptionTemplateDialogStore()
export default singleton
