import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import UsersList from "./usersList"
// import XsTabsIcon from "../../../../../global/ui/xsTabs/xsTabsIcon"
import UIStore from "../../../stores/UIStore"
import RolesContainer from "../roles/rolesContainer"
import ResourcesContainer from "../resources/resourcesContainer"
// import UsersStore from "../../../stores/UsersStore"
import {Grid, List, Divider, Drawer, ListItem, ListItemText, ListItemIcon, Tooltip} from "@material-ui/core"
import {withStyles} from "@material-ui/core/styles"
import classNames from "classnames"

const drawerWidth = 250

const styles = (theme) => ({
	drawer: {
		height: "100%"
	},
	drawerPaper: {
		position: "relative",
		overflow: "hidden",
		whiteSpace: "nowrap",
		height: "100%",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerPaperClose: {
		overflowX: "hidden",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		width: theme.spacing.unit * 7,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing.unit * 9
		}
	},
	drawerIcon: {
		fontSize: "1.6rem !important",
		width: "15px"
	}
})

@withStyles(styles)
@injectIntl
@observer
export default class UsersContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			open: false
		}
		UIStore.usersSettings = "users"
		UIStore.usersSettingsName = <FormattedMessage id="Admin.usersList.users" />
	}

	handleDrawerOpen = () => {
		this.setState({open: true})
	}

	handleDrawerClose = () => {
		this.setState({open: false})
	}

	handleChange = (event, newValue) => {
		UIStore.usersSettings = newValue
	}

	drawerData = [
		{
			label: this.props.intl.formatMessage({id: "Admin.usersList.users"}),
			icon: <i className={classNames("fal fa-users", this.props.classes.drawerIcon)} />,
			id: "users",
			content: <UsersList />
		},
		{
			label: this.props.intl.formatMessage({id: "Admin.usersList.roles"}),
			icon: <i className={classNames("fal fa-users-cog", this.props.classes.drawerIcon)} />,
			id: "roles",
			content: <RolesContainer />
		},
		{
			label: this.props.intl.formatMessage({id: "Admin.usersList.resources"}),
			icon: <i className={classNames("fal fa-cogs", this.props.classes.drawerIcon)} />,
			id: "resources",
			content: <ResourcesContainer />
		}
	]

	render() {
		return (
			<Grid container className="maxHeight">
				<Grid item className="maxHeight">
					<Drawer
						variant="permanent"
						className={this.props.classes.drawer}
						classes={{
							paper: classNames(this.props.classes.drawerPaper, !this.state.open && this.props.classes.drawerPaperClose)
						}}
						open={this.state.open}
					>
						<Divider />
						<List>
							<ListItem button onClick={() => (this.state.open ? this.handleDrawerClose() : this.handleDrawerOpen())}>
								{this.state.open ? (
									<ListItemText className="textRight">
										<i className={classNames("fal fa-chevron-left", this.props.classes.drawerIcon)} />
									</ListItemText>
								) : (
									<ListItemText className="textRight">
										<i className={classNames("fal fa-chevron-right", this.props.classes.drawerIcon)} />
									</ListItemText>
								)}
							</ListItem>
						</List>
						<Divider />
						<List className="overflowY overflowXHidden">
							{this.drawerData.map((data, key) => {
								return (
									<ListItem
										key={key}
										button
										onClick={() => {
											UIStore.usersSettings = data.id
											UIStore.usersSettingsName = data.label
										}}
									>
										<Tooltip title={data.label} placement="right">
											<ListItemIcon>{data.icon}</ListItemIcon>
										</Tooltip>
										<ListItemText>{data.label}</ListItemText>
									</ListItem>
								)
							})}
						</List>
					</Drawer>
				</Grid>
				<Grid item className="xs-flex pt-4 pb-4 pr-8 pl-8 maxHeight overflowY">
					<Grid container direction="column">
						<h2 className="pb-6">{UIStore.usersSettingsName}</h2>
						{UIStore.usersSettings === "users" && <UsersList />}
						{UIStore.usersSettings === "roles" && <RolesContainer />}
						{UIStore.usersSettings === "resources" && <ResourcesContainer />}
					</Grid>
				</Grid>
			</Grid>
		)
	}
}
