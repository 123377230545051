import React from "react"
import {observer} from "mobx-react"
import moment from "moment"
import Grid from "@material-ui/core/Grid"
import Problem from "./problem"
import ProblemsStore from "../../../stores/ProblemsStore"
import PatientProblemsDialogStore from "../../../stores/PatientProblemsDialogStore"

@observer
export default class ProblemsContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			problemForm: false
		}
	}

	closeProblemForm = () => {
		this.setState({problemForm: false})
	}

	render() {
		return (
			isSafe(ProblemsStore.problemsDTO) &&
			ProblemsStore.problemsDTO.length > 0 && (
				<Grid container direction="column" id="xsProblemsContainer">
					<Grid container direction="row">
						<Grid item xs={12}>
							{ProblemsStore.problemsDTO.map((problem, idx) => {
								const firstProblem = ProblemsStore.problemsDTO[0]
								const lastProblem = ProblemsStore.problemsDTO[ProblemsStore.problemsDTO.length - 1]
								const lastProblemAgeTo = lastProblem.age_to
									? lastProblem.age_to
									: moment(new Date()).diff(moment(lastProblem.birth_date), "years")
								const difference = lastProblemAgeTo - firstProblem.age_from
								return (
									<Problem
										key={idx + 1}
										problem={problem}
										index={idx + 1}
										totalLength={difference}
										firstAge={firstProblem.age_from}
										onClick={() => {
											PatientProblemsDialogStore.open(problem)
										}}
										isDeletable={false}
									/>
								)
							})}
						</Grid>
					</Grid>
				</Grid>
			)
		)
	}
}
