"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"
import {getFilters} from "../../../global/helpers/api"
import {
	getSelectedOrgUnitID,
	/*getSelectedOrgUnitResourceID,*/ getUserCompanyInfo,
	getSelectedOrgUnit
} from "../../../global/helpers/actions"
// import api from "../actions/api"
import UIStore from "./UIStore"
// import {getSelectedOrgUnitID} from "../../../global/helpers/actions"
// import actionCard from "../actions/patientCard"
import DataStore from "./DataStore"
import GlobalStore from "../../../global/store/GlobalStore"

class DeletePatientDialogStore {
	@observable isOpen = false
	@observable searchValue = ""
	@observable patientList = []
	@observable checked
	// @observable dropedFiles = []
	// @observable recordId = null

	@action open(patientId) {
		this.isOpen = true
		this.patientId = patientId
	}

	@action close() {
		this.isOpen = false
		this.searchValue = ""
		this.patientList = []
		this.checked = null
		this.patientId = null

		GlobalStore.closeConfirmationDialog("xsDeletePatientConfirmation")
	}

	@action searchPatient() {
		const providerID = getSelectedOrgUnitID()
		let searchRequest = getFilters([`search_column=${this.searchValue}`, "exited=0"])

		if (this.orgUnit_addEventFilter) {
			searchRequest = getFilters([`search_column=${this.searchValue}`, `provider_id=${providerID}`, "exited=0"])
		}

		delayedCallback(500, () => {
			if (this.searchValue.length >= 3) {
				api
					.loadCompanyPatients(getUserCompanyInfo().id, searchRequest)
					.call()
					.then((response) => {
						if (isSafe(response) && isSafe(response.rows) && response.rows.length) {
							this.patientList = response.rows
								.filter((x) => x.client_id != this.patientId)
								.map((dataRow) => {
									return {
										profile: {
											person_id: dataRow.person_id,
											avatar_id: dataRow.avatar_id,
											first_name: dataRow.first_name,
											last_name: dataRow.last_name,
											gender_id: dataRow.gender_id,
											gender_name: dataRow.gender,
											age: dataRow.age
										},
										client_text: dataRow.client_text,
										insuredFrom: {
											insured_from: dataRow.insured_from,
											insurer_name: dataRow.insurer_name
										},
										identifier: dataRow.identifier,
										insurer_short_name: dataRow.insurer_short_name,
										contact: {
											email: dataRow.email,
											contact: dataRow.contact,
											primary_address: dataRow.primary_address,
											last_visit: dataRow.last_visit,
											next_event_from: dataRow.next_event_from
										},
										events: {
											last_visit: dataRow.last_visit,
											next_event_from: dataRow.next_event_from,
											person_id: dataRow.person_id,
											next_event_id: dataRow.next_event_id,
											next_event_service_name: dataRow.next_event_service_name
										},
										prevention: {
											last_preventive_examination: dataRow.last_preventive_examination,
											person_id: dataRow.person_id
										},
										gender_id: dataRow.gender_id,
										person_id: dataRow.person_id,
										client_id: dataRow.client_id,
										delete: dataRow.client_id,
										primary_address: dataRow.primary_address
									}
								})
						} else {
							this.patientList = []
						}
					})
			} else {
				this.patientList = []
				this.checked = null
			}
		})
	}

	@action deletePatient() {
		api
			.deletePatient(getSelectedOrgUnitID(), this.patientId)
			.call()
			.then(() => {
				DataStore.patientListTableRef.refreshData()
				UIStore.isFormSaving = false
				this.close()
			})
	}

	@action checkAndDelete() {
		const providerID = getSelectedOrgUnitID()
		api
			.loadPatientOverview(providerID, this.patientId, null, null, true)
			.call()
			.then((response) => {
				if (isSafe(response.rows) && response.rows.length > 0) {
					UIStore.isFormSaving = false
					GlobalStore.openConfirmationDialog("xsDeletePatientConfirmation")
				} else {
					this.deletePatient()
				}
			})
	}

	@action movePatient() {
		let selectedPatient = this.patientList[this.checked]
		let req = {
			source_personnel_expertise_id: getSelectedOrgUnit().personnel_expertise_id,
			target_patient_id: selectedPatient.client_id,
			target_org_unit_id: getSelectedOrgUnitID(),
			target_personnel_expertise_id: getSelectedOrgUnit().personnel_expertise_id
		}

		api
			.movePatientData(getSelectedOrgUnitID(), this.patientId, req)
			.call()
			.then(() => {
				const providerID = getSelectedOrgUnitID()
				api
					.loadPatientOverview(providerID, this.patientId, null, null, true)
					.call()
					.then((response) => {
						if (isSafe(response.rows) && response.rows.length > 0) {
							UIStore.isFormSaving = false
							GlobalStore.openConfirmationDialog("xsDeletePatientConfirmation")
						} else {
							this.deletePatient()
						}
					})
			})
	}
}

var singleton = new DeletePatientDialogStore()
export default singleton
