import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import InvoiceListStore from "../../../stores/InvoiceListStore"
import GenerateInvoiceFromServiceDialogStore from "../../../stores/GenerateInvoiceFromServiceDialogStore"
import AddIcon from "@material-ui/icons/Add"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import ExportInvoicesDialogStore from "../../../stores/ExportInvoicesDialogStore"
import ExportInvoicesPDFDialogStore from "../../../stores/ExportInvoicesPDFDialogStore"
import InvoiceFormStore from "../../../stores/InvoiceFormStore"
import PrintIcon from "@material-ui/icons/Print"

@injectIntl
@observer
export default class InvoiceListAction extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<React.Fragment>
				<Grid item container justify="space-between" alignItems="center">
					<Grid item xs container spacing={8} justify="flex-end">
						<Grid item>
							<XsButton
								className={InvoiceListStore.checkedInvoces.length == 0 ? "xs-default xs-outline" : "xs-primary"}
								disabled={InvoiceListStore.checkedInvoces.length == 0}
								text={<FormattedMessage id="Common.lable.print" />}
								icon={<PrintIcon />}
								onClick={() => {
									InvoiceFormStore.multiPrint(InvoiceListStore.checkedInvoces)
								}}
							/>
						</Grid>
						<Grid item>
							<XsButton
								className={InvoiceListStore.checkedInvoces.length == 0 ? "xs-default xs-outline" : "xs-primary"}
								disabled={InvoiceListStore.checkedInvoces.length == 0}
								text={<FormattedMessage id="Common.label.exportToPDF" />}
								icon={<i className="fal fa-file-export fa-lg" />}
								onClick={() => {
									ExportInvoicesPDFDialogStore.open()
								}}
							/>
						</Grid>
						<Grid item>
							<XsButton
								className="xs-primary"
								text={<FormattedMessage id="Common.label.exportInvoices" />}
								icon={<i className="fal fa-file-export fa-lg"></i>}
								onClick={() => {
									ExportInvoicesDialogStore.open()
								}}
							/>
						</Grid>
						<Grid item>
							<XsButton
								className="xs-primary"
								text={<FormattedMessage id="Invoice.form.generateFromService" />}
								icon={<i className="fal fa-save fa-lg" />}
								onClick={() => {
									GenerateInvoiceFromServiceDialogStore.open()
								}}
							/>
						</Grid>
						<Grid item>
							<XsButton
								className="xs-success"
								text={<FormattedMessage id="Invoice.form.new" />}
								icon={<AddIcon />}
								onClick={() => InvoiceListStore.set("showForm", true)}
							/>
						</Grid>
					</Grid>
				</Grid>
			</React.Fragment>
		)
	}
}
