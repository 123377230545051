//@flow
import React from "react"
import moment from "moment"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import {Dialog, DialogActions, DialogTitle, DialogContent} from "@material-ui/core"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import PatientCheckInsurerDialogStore from "../../../stores/PatientCheckInsurerDialogStore"

@observer
export default class PatientCheckInsurerDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			isSafe(PatientCheckInsurerDialogStore.checkInsurerData) && (
				<Dialog
					id="xsPatientCheckInsurerDialog"
					open={PatientCheckInsurerDialogStore.isOpen}
					onClose={() => PatientCheckInsurerDialogStore.close()}
					// onEscapeKeyDown={() => PatientCheckInsurerDialogStore.close()}
					disableBackdropClick={true}
					maxWidth="md"
					className="xs-base-dialog"
				>
					<DialogTitle className="xs-info">
						<div className="xs-header">
							<div className="xs-header-icon">
								<i className="fal fa-check fa-2x"></i>
							</div>
							<div className="xs-header-title">
								<FormattedMessage id="Patient.checkInsurer.dialog.headerMessage" />
							</div>
						</div>
					</DialogTitle>
					<DialogContent className="xs-content">
						<div className="xs-insurer-item">
							<div className="xs-insurer-label">
								<FormattedMessage id="Patient.checkInsurer.dialog.insurancePolicy" />
							</div>
							<div className="xs-insurer-text">
								{PatientCheckInsurerDialogStore.checkInsurerData.poistny_vztah_poistenca == "Je poistencom e u" ? (
									<FormattedMessage id="Common.label.isInsurerEU" />
								) : (
									PatientCheckInsurerDialogStore.checkInsurerData.poistny_vztah_poistenca
								)}
							</div>
						</div>
						<div className="xs-insurer-item">
							<div className="xs-insurer-label">
								<FormattedMessage id="Patient.checkInsurer.dialog.nonPayer" />
							</div>
							<div className="xs-insurer-text">{PatientCheckInsurerDialogStore.checkInsurerData.je_neplatic}</div>
						</div>
						<div className="xs-insurer-item">
							<div className="xs-insurer-label">
								<FormattedMessage id="Patient.checkInsurer.dialog.hasClaimOnPostponingCare" />
							</div>
							<div className="xs-insurer-text">
								{PatientCheckInsurerDialogStore.checkInsurerData.ma_narok_na_odkladnu_zs}
							</div>
						</div>
						<div className="xs-insurer-item">
							<div className="xs-insurer-label">
								<FormattedMessage id="Patient.checkInsurer.dialog.startEUInsurance" />
							</div>
							<div className="xs-insurer-text">
								{isNotEmpty(PatientCheckInsurerDialogStore.checkInsurerData.zaciatok_eu_poistenia) &&
								PatientCheckInsurerDialogStore.checkInsurerData.zaciatok_eu_poistenia.length > 5
									? moment(PatientCheckInsurerDialogStore.checkInsurerData.zaciatok_eu_poistenia).format("DD.MM.YYYY")
									: PatientCheckInsurerDialogStore.checkInsurerData.zaciatok_eu_poistenia}
							</div>
						</div>
					</DialogContent>
					<DialogActions className="xs-footer">
						<XsButton
							className="xs-info"
							icon={<i className="fal fa-times"></i>}
							text={<FormattedMessage id="Common.label.ok" />}
							onClick={() => PatientCheckInsurerDialogStore.close()}
						/>
					</DialogActions>
				</Dialog>
			)
		)
	}
}
