//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import moment from "moment"

import {Grid} from "@material-ui/core"

import XsIconButton from "../../../../global/ui/xsButton/xsIconButton"
import AddEventDialogStore from "../../stores/AddEventDialogStore"
import CalendarStore from "../../stores/CalendarStore"

@injectIntl
@observer
export default class AddEventOrders extends React.Component {
	constructor(props) {
		super(props)
	}

	getOpenHours = () => {
		let from = 0
		let to = 23
		let hours = []
		for (let i = from; i <= to; i++) {
			hours.push(i)
		}

		return hours
	}

	render() {
		return (
			<div className="xs-patient-list-cover">
				{AddEventDialogStore.showPatientEvents ? (
					<React.Fragment>
						<Grid container justify="space-between" alignItems="center" className="pt-2 pb-2 pr-4 pl-4">
							<Grid item className="greyDark xs-bold">
								<FormattedMessage id="Common.label.patientAlreadyHasTheseOrders" />
							</Grid>
							<Grid item>
								<XsIconButton
									tooltip={this.props.intl.formatMessage({id: "Common.label.scheduledAllDeadlines"})}
									onClick={() => (AddEventDialogStore.showPatientEvents = false)}
									className="xs-default"
									style={{height: "28px", minHeight: "28px", minWidth: "28px"}}
									rect
									icon={<i className="fal fa-list-ul"></i>}
								/>
							</Grid>
						</Grid>
						<div className="xs-patient-list-scrollbar">
							<div className="xs-patient-list">
								{AddEventDialogStore.patientEventsData.map((event, idx) => {
									let bgColor = "#808080"
									let eventDuration = ""
									let fontColor = undefined

									if (isSafe(event)) {
										if (isSafe(event.services) && isSafe(event.services[0])) {
											if (isNotEmpty(event.services[0].color)) {
												bgColor = event.services[0].color
											}

											if (isNotEmpty(event.services[0].fore_color)) {
												fontColor = event.services[0].fore_color
											}

											if (isNotEmpty(event.services[0].duration && event.services[0].duration > 0)) {
												eventDuration = ` (${Math.round(event.services[0].duration / 60)} min.)`
											}
										}
									}
									let colors = CalendarStore.invertColor(bgColor, fontColor)
									let lastName = isNotEmpty(event.last_name) ? event.last_name.toUpperCase() : ""

									return (
										<div key={idx} className="xs-plane-event xs-row pt-1 pb-1">
											<div className="xs-hour xs-row xs-align">
												{moment(event.interval.from).format("DD.MM.YYYY HH:mm")}
											</div>
											<div className="xs-events">
												<div
													className="xs-event"
													style={{backgroundColor: colors.backgroundColor, color: colors.color}}
												>
													<div className="xs-name">
														{isEmpty(event.last_name) && isEmpty(event.first_name)
															? event.client_text
															: lastName + " " + event.first_name}
													</div>
													{isSafe(event.service_names) && (
														<div className="xs-description">
															{event.service_names.length <= 15
																? event.service_names + eventDuration
																: event.service_names.substring(0, 15) + "..." + eventDuration}
														</div>
													)}
												</div>
											</div>
										</div>
									)
								})}
							</div>
						</div>
					</React.Fragment>
				) : (
					<React.Fragment>
						<Grid container justify="space-between" alignItems="center" className="pt-2 pb-2 pr-4 pl-4">
							<Grid item className="greyDark xs-bold pt-1 pb-1">
								<FormattedMessage id="Calendar.addEvent.dialog.orderSubtitle" />
							</Grid>
							{isEmpty(AddEventDialogStore.unknowPatientName) && (
								<Grid item className="patientOrdersTour">
									<XsIconButton
										tooltip={this.props.intl.formatMessage({id: "Common.label.scheduledPatientDeadlines"})}
										onClick={() => AddEventDialogStore.patientEvents()}
										className="xs-default xs-outline"
										style={{height: "28px", minHeight: "28px", minWidth: "28px"}}
										rect
										icon={<i className="fal fa-list-ul"></i>}
									/>
								</Grid>
							)}
						</Grid>
						<div className="xs-patient-list-scrollbar">
							<div className="xs-patient-list">
								{this.getOpenHours().map((hour, i) => {
									return (
										<div key={i} className="xs-daily-event">
											<div className="xs-hour">{hour}:00</div>
											<div className="xs-events">
												{isSafe(CalendarStore.orderedPatientsData[hour]) &&
													Array.isArray(CalendarStore.orderedPatientsData[hour]) &&
													CalendarStore.orderedPatientsData[hour].map((event, idx) => {
														let isEventEnd = event.event_status_id === "F" ? true : false
														if (!isEventEnd) {
															let bgColor = "#808080"
															let eventDuration = ""
															let fontColor = undefined
															let intervalFrom = ""

															if (isSafe(event)) {
																if (isSafe(event.services) && isSafe(event.services[0])) {
																	if (isNotEmpty(event.services[0].color)) {
																		bgColor = event.services[0].color
																	}

																	if (isNotEmpty(event.services[0].fore_color)) {
																		fontColor = event.services[0].fore_color
																	}

																	if (isNotEmpty(event.services[0].duration && event.services[0].duration > 0)) {
																		eventDuration = ` (${Math.round(event.services[0].duration / 60)} min.)`
																	}
																}

																if (isSafe(event.interval) && isNotEmpty(event.interval.from)) {
																	intervalFrom = ` (${moment(event.interval.from).format("HH:mm")})`
																}
															}
															let colors = CalendarStore.invertColor(bgColor, fontColor, isEventEnd)

															let lastName = isNotEmpty(event.last_name) ? event.last_name.toUpperCase() : ""

															return (
																<div
																	key={`${i}_${idx}`}
																	className="xs-event"
																	style={{backgroundColor: colors.backgroundColor, color: colors.color}}
																>
																	<div className="xs-name">
																		{isEmpty(event.last_name) && isEmpty(event.first_name)
																			? event.client_text + intervalFrom
																			: lastName + " " + event.first_name + intervalFrom}
																	</div>
																	{isSafe(event.service_names) && (
																		<div className="xs-description">
																			{event.service_names.length <= 15
																				? event.service_names + eventDuration
																				: event.service_names.substring(0, 15) + "..." + eventDuration}
																		</div>
																	)}
																</div>
															)
														}
													})}
											</div>
										</div>
									)
								})}
							</div>
						</div>
					</React.Fragment>
				)}
			</div>
		)
	}
}
