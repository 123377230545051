"use strict"

import {observable, action} from "mobx"
import {handleInsertHelpersIntoTextArea} from "../../../global/helpers/actions"

class RindividHelpDialogStore {
	@observable isOpen = false
	@observable searchName = ""
	@observable searchContent = ""
	textAreaCursors = {}
	form = undefined
	formFieldName = ""

	@action open(form, formFieldName) {
		this.isOpen = true
		if (isSafe(form) && isNotEmpty(formFieldName)) {
			this.form = form
			this.formFieldName = formFieldName
		}
	}

	@action close() {
		this.isOpen = false

		this.searchContent = ""
		this.searchName = ""
		this.textAreaCursors = {}
		this.formFieldName = ""
	}

	@action appendToArea(content) {
		if (isNotEmpty(this.form.$(this.formFieldName).value) && this.textAreaCursors[this.formFieldName]) {
			handleInsertHelpersIntoTextArea(
				this.form,
				this.formFieldName,
				this.textAreaCursors[this.formFieldName].cursor,
				content
			)
		} else {
			this.form.$(this.formFieldName).value += content
		}
		this.close()
	}
}

var singleton = new RindividHelpDialogStore()
export default singleton
