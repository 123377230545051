"use strict"

import {observable} from "mobx"

class OrgunitDetailAddressesStore {
	@observable editable = false
	@observable isAdd = false
}

var singleton = new OrgunitDetailAddressesStore()
export default singleton
