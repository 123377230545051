import React from "react"
import {observer} from "mobx-react"
import "./xsGroupValidator.less"

// @observer
// export default class XsGroupValidator extends React.Component {
//   constructor(props) {
//     super(props)
//   }

//   render(){
//     return (
//       <div className={isSafe(this.props.field.get("rules")) && this.props.field.get("rules").includes("required") ? "xs-group-validatorRed" : "xs-group-validatorNone"} style={this.props.style}>
//         {this.props.content}
//       </div>
//     )
//   }

// }
export default observer(({field, content, style}) => {
	return (
		<div
			className={
				isSafe(field.get("rules")) && field.get("rules").includes("required")
					? "xs-group-validatorRed"
					: "xs-group-validatorNone"
			}
			style={style}
		>
			{content}
		</div>
	)
})
