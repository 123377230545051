"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"
import UIStore from "./UIStore"
import {getSelectedOrgUnitID} from "../../../global/helpers/actions"
import actionCard from "../actions/patientCard"
import DataStore from "./DataStore"

class PatientRecordDropzoneModalStore {
	@observable isOpen = false
	@observable dropedFiles = []
	@observable recordId = null

	@action open(id) {
		this.isOpen = true
		this.recordId = id
	}

	@action close() {
		this.isOpen = false
		this.dropedFiles = []
		this.recordId = null
	}

	@action save() {
		if (isSafe(this.dropedFiles) && this.dropedFiles.length) {
			let req = []
			let files = this.dropedFiles
			let binaryDataArr = []
			const recordId = this.recordId
			const close = this.close.bind(this)
			let getdata = function(index) {
				let data = files[index]
				let binaryData
				var reader = new FileReader()
				reader.readAsBinaryString(data)
				reader.onloadend = function() {
					binaryData = reader.result
					binaryDataArr.push(binaryData)
					req.push({
						_id: isNotEmpty(data._id) ? data._id : null,
						_ref: false,
						_type: "IXS.Data.Attachment",
						active: true,
						rel_class_name: "EHR.Data.Ambulance",
						rel_object_id: recordId,
						content_type: isNotEmpty(data.type) ? data.type : "application/octet-stream",
						// "content": binaryData.split(",")[1],
						type: "General",
						file_name: data.name,
						size: data.size
					})
					if (files.length > index + 1) getdata(index + 1)
					else {
						api
							.saveAttachment(req)
							.call()
							.then((res) => {
								Object.keys(res).forEach((key) => {
									api.saveAttachmentContent(res[key]._id, binaryDataArr[+key]).call()
								})
								const providerID = getSelectedOrgUnitID()
								actionCard
									.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID"), null, null, true)
									.then((overview) => {
										DataStore.setPatientEHR(overview)
										UIStore.isFormSaving = false
										close()
									})
							})
							.catch(() => {
								UIStore.isFormSaving = false
							})
					}
				}
			}
			getdata(0)
		} else {
			UIStore.isFormSaving = false
		}
	}
}

var singleton = new PatientRecordDropzoneModalStore()
export default singleton
