/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"
// import moment from "moment"

export default {
	load() {
		return {
			fields: {
				printOperation: {
					type: "checkbox",
					value: false
				},
				recordId: {},
				examdate: {
					label: <FormattedMessage id="Patient.form.patientrecord.dateOfExam" />,
					type: "date"
				},
				operationStartDate: {
					label: <FormattedMessage id="Common.label.operationStartDate" />,
					type: "date"
				},
				operationEndDate: {
					label: <FormattedMessage id="Common.label.operationEndDate" />,
					type: "date"
				},
				operationRoomEntry: {
					label: <FormattedMessage id="Common.label.operationRoomEntry" />,
					type: "date"
				},
				operationRoomLeave: {
					label: <FormattedMessage id="Common.label.operationRoomLeave" />,
					type: "date"
				},
				diagnosisChipsForm: {
					value: "[]"
				},
				operating_theater: {
					label: <FormattedMessage id="Common.label.operatingRoom" />
				},
				anesthesia_duration: {
					label: <FormattedMessage id="Common.label.anesthesiaTime" />
				},
				mechanical_ventilation_duration: {
					label: <FormattedMessage id="Common.label.duringUPV" />
				},
				surgeon_preparation_duration: {
					label: <FormattedMessage id="Common.label.operator" />
				},
				instrumentalist_preparation_duration: {
					label: <FormattedMessage id="Common.label.instrumentalist" />
				},
				anesthesiologist_preparation_duration: {
					label: <FormattedMessage id="Common.label.anesthesiologist" />
				},
				anesthesia_type: {
					label: <FormattedMessage id="Common.label.typeOfAnesthesia" />
				},
				surgery_type: {
					label: <FormattedMessage id="Common.label.kindOfOperation" />
				},
				surgery_schedule_type: {
					label: <FormattedMessage id="Common.label.typeOfOperation" />
				},
				surgery_perop_complications: {
					label: <FormattedMessage id="Common.label.peropComplication" />
				},
				surgery_associated_complications: {
					label: <FormattedMessage id="Common.label.associatedComplication" />
				},
				operator: {
					label: <FormattedMessage id="Common.label.operator" />,
					rules: "required"
				},
				operator_cl_expertise_id: {},
				operator_personnel_id: {},
				instrumentalist: {
					label: <FormattedMessage id="Common.label.instrumentalist" />
				},
				instrumentalist_cl_expertise_id: {},
				anesthesiologist: {
					label: <FormattedMessage id="Common.label.anesthesiologist" />
				},
				anesthesiologist_cl_expertise_id: {},
				anesthesiologist_personnel_id: {},
				surgery_procedure: {
					label: <FormattedMessage id="Common.label.codeOperationNCZI" />,
					rules: "required"
				},
				note: {
					label: <FormattedMessage id="Common.label.note" />
				},
				noteId: {},
				anamnesis: {
					label: " "
				},
				id: {},
				deliveriesChipsForm: {value: "[]"},
				deliveriesFormAddItems: {
					value: "[]"
				},
				hasDispensaryDiagnosis: {}
				// closeForm: {}
			}
		}
	}
}
