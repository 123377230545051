"use strict"

import {observable, action} from "mobx"
import moment from "moment"

class PatientImplantDialogStore {
	@observable isOpen = false
	@observable isEditable = false
	@observable implantObj = undefined
	@observable nameOfDeleteImplant = ""

	implantId = undefined

	@action open(implant = undefined) {
		this.isOpen = true
		this.implantObj = implant
	}

	@action insertFormData(form) {
		let implant = this.implantObj

		this.isEditable = true
		this.implantId = implant._id

		if (isNotEmpty(implant.description)) {
			form.$("implantText").set("value", implant.description)
		} else {
			if (isSafe(implant.group) && isSafe(implant.group._id)) {
				form.$("implantCode").set("value", implant.group._id)
			}
		}

		if (isNotEmpty(implant.implantation_date)) {
			form.$("implantation_date").set("value", moment(implant.implantation_date))
		}

		if (isSafe(implant.validity)) {
			form.$("validFrom").set("value", isNotEmpty(implant.validity.from) ? moment(implant.validity.from) : null)
			form.$("validTo").set("value", isNotEmpty(implant.validity.to) ? moment(implant.validity.to) : null)
		}

		if (isSafe(implant.medical_device) && isNotEmpty(implant.medical_device._id)) {
			form.$("medEquip").value = implant.medical_device._id
		}

		this.implantObj = undefined
	}

	@action close() {
		this.isOpen = false
		this.implantId = undefined
		this.nameOfDeleteImplant = ""
	}
}

var singleton = new PatientImplantDialogStore()
export default singleton
