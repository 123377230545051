import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import moment from "moment"

import {Grid} from "@material-ui/core"

import "./printTemplatesStyle.less"

import fields from "./requestForMagneticResonanceFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsSimpleTextArea from "../../../../../../global/ui/xsTextArea/xsSimpleTextArea"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsAutocompleteLocal from "../../../../../../global/ui/xsInput/xsAutocompleteLocal"
import XsAutocomplete from "../../../../../../global/ui/xsInput/xsAutocomplete"
import XsCheckbox from "../../../../../../global/ui/xsCheckbox/xsCheckbox"
import RegisterCacheStore from "../../../../../../global/store/RegistersCacheStore"
import {getSelectedOrgUnitID, getUserDoctorInfo} from "../../../../../../global/helpers/actions"
import TemplateForm from "../../patientRecordForm/templateForm"
import DataStore from "../../../../stores/DataStore"
import UIStore from "../../../../stores/UIStore"

import api from "../../../../actions/api"

@injectIntl
@observer
export default class RequestForMagneticResonance extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		if (isEmpty(this.form.$("addressForm").value) && isNotEmpty(this.form.$("address").value)) {
			this.form.$("addressForm").value = this.form.$("address").value
		}

		if (isEmpty(this.form.$("nameForm").value) && isNotEmpty(this.form.$("name").value)) {
			this.form.$("nameForm").value = this.form.$("name").value
		}

		if (isEmpty(this.form.$("insurerForm").value) && isNotEmpty(this.form.$("insurerCode").value)) {
			this.form.$("insurerForm").value = this.form.$("insurerCode").value
		}

		if (isEmpty(this.form.$("identifierForm").value) && isNotEmpty(this.form.$("identifier").value)) {
			this.form.$("identifierForm").value = this.form.$("identifier").value
		}

		if (isEmpty(this.form.$("phoneForm").value) && isNotEmpty(this.form.$("phone").value)) {
			this.form.$("phoneForm").value = this.form.$("phone").value
		}

		if (isEmpty(this.form.$("orgunitcontactForm").value) && isNotEmpty(this.form.$("orgunitcontact").value)) {
			this.form.$("orgunitcontactForm").value = this.form.$("orgunitcontact").value
		}
	}

	componentDidMount() {
		// const orgunit = getSelectedOrgUnit()

		// if (isNotEmpty(this.props.values) && isSafe(orgunit)) {
		// 	this.form.$("orgunitCode").set("value", isSafe(orgunit.provider_code) ? orgunit.provider_code : "")
		// }

		const doctorInfo = getUserDoctorInfo()

		if (isNotEmpty(this.props.values) && isEmpty(this.props.values.sendingDoctor) && isSafe(doctorInfo)) {
			this.form.$("sendingDoctor").set("value", isSafe(doctorInfo.expertise) ? doctorInfo.expertise.id : "")
			this.form.$("doctorCode").set("value", doctorInfo.code)
			this.form.$("doctorName").set("value", doctorInfo.name)
		}

		if (isNotEmpty(this.props.values) && isEmpty(this.props.values.clinicalDiagnosis)) {
			UIStore.isFormSaving = true
			api
				.loadPatientOverview(
					getSelectedOrgUnitID(),
					DataStore.patientDTO.get("patientID"),
					null,
					moment()
						.startOf("day")
						.utc()
						.format(),
					moment()
						.endOf("day")
						.utc()
						.format()
				)
				.call()
				.then((res) => {
					if (isSafe(res.rows)) {
						let dekurz = res.rows.find((i) => i.type_code == "DEK")

						if (isSafe(dekurz)) {
							if (isNotEmpty(dekurz.diagnosis_code_ext)) {
								this.form.$("clinicalDiagnosis").set("value", dekurz.diagnosis_code_ext)
								this.form.$("diagnosisNameExt").set("value", dekurz.diagnosis_name_ext)
								this.form.$("diagnosisCodeExt").set("value", dekurz.diagnosis_code_ext)
							}

							if (isSafe(dekurz.data) && dekurz.data) {
								const subTazkosti = dekurz.data.find((x) => x.type._id == "OF")
								const objNalez = dekurz.data.find((x) => x.type._id == "AMB")
								const zobrazovacieVysetrenia = dekurz.data.find((x) => x.type._id == "IEXAM")

								let sucasnaAna = ""

								if (isSafe(subTazkosti) && isNotEmpty(subTazkosti.content)) {
									sucasnaAna = subTazkosti.content
								}

								if (isSafe(objNalez) && isNotEmpty(objNalez.content)) {
									sucasnaAna += objNalez.content
								}

								if (isSafe(zobrazovacieVysetrenia) && isNotEmpty(zobrazovacieVysetrenia.content)) {
									sucasnaAna += zobrazovacieVysetrenia.content
								}

								this.form.$("anamnesisNow").set("value", htmlToPlain(sucasnaAna))
							}
						}
					}
					UIStore.isFormSaving = false
				})
		}
	}

	render() {
		return (
			<React.Fragment>
				<Grid container spacing={8}>
					<Grid item xs={6}>
						<XsInput field={this.form.$("nameForm")} />
					</Grid>
					<Grid item xs={3}>
						<XsInput field={this.form.$("identifierForm")} />
					</Grid>
					<Grid item xs={3}>
						<XsInput field={this.form.$("insurerForm")} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={9}>
						<XsInput field={this.form.$("addressForm")} />
					</Grid>
					<Grid item xs={3}>
						<XsInput field={this.form.$("phoneForm")} />
					</Grid>
				</Grid>
				<Grid container spacing={8} alignItems="center">
					<Grid item xs={9}>
						<XsAutocompleteLocal
							field={this.form.$("clinicalDiagnosis")}
							minLengthForSearch="3"
							filterValue="code_ext"
							clearText
							data={RegisterCacheStore.diagnosisRegister}
							inputRenderer={(obj) => {
								return obj.code_ext + " " + obj.name_ext
							}}
							saveValue={(obj) => obj.code_ext}
							postAction={(obj) => {
								this.form.$("diagnosisNameExt").value = obj.name_ext
								this.form.$("diagnosisCodeExt").value = obj.code_ext
							}}
						/>
					</Grid>
					<Grid item xs={3} className="mt-1">
						<XsCheckbox
							field={this.form.$("pacientMobile")}
							onChange={(target) => {
								if (!target.value) {
									this.form.$("pacientMobile_inv").value = true
								} else {
									this.form.$("pacientMobile_inv").value = false
								}
							}}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={9}>
						<XsAutocomplete
							field={this.form.$("sendingDoctor")}
							minLengthForSearch="3"
							api={api.loadPersonnel}
							clearText
							filterValue="personnel_expertise_id"
							inputRenderer={(obj, menuItem) =>
								(isNotEmpty(obj.preferred_provider_code) && obj.preferred_provider_code.charAt(9) == "2" && menuItem
									? "<b style=color:#00A787>"
									: "") +
								(obj.identifier ? obj.identifier + " / " : "") +
								obj.full_name +
								" / " +
								(obj.expertise_code ? obj.expertise_code + " " : "") +
								obj.expertise +
								(isNotEmpty(obj.preferred_provider_code) ? " / " + obj.preferred_provider_code : "") +
								(isNotEmpty(obj.preferred_provider_code) && obj.preferred_provider_code.charAt(9) == "2" && menuItem
									? "</b>"
									: "")
							}
							saveValue={(obj) => obj.personnel_expertise_id}
							postAction={(obj) => {
								this.form.$("doctorCode").value = obj.identifier
								this.form.$("doctorName").value = obj.full_name
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						<XsInput field={this.form.$("orgunitcontactForm")} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={3}>
						<XsDateTimePicker
							field={this.form.$("datetimeMRExamination")}
							onSelect={(val) => {
								if (isSafe(val)) {
									this.form.$("dateMRExamination").value = moment(val).format("DD.MM.YYYY")
									this.form.$("hourMRExamination").value = moment(val).format("HH:mm")
								} else {
									this.form.$("dateMRExamination").value = ""
									this.form.$("hourMRExamination").value = ""
								}
							}}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsSimpleTextArea field={this.form.$("whichOrganHaveBeenExamination")} rows={2} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsSimpleTextArea field={this.form.$("question")} rows={2} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsSimpleTextArea field={this.form.$("alergyAnamnesis")} rows={2} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsSimpleTextArea field={this.form.$("oblickoveParameters")} rows={2} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsSimpleTextArea field={this.form.$("anamnesisNow")} rows={2} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsSimpleTextArea field={this.form.$("kontraindikacie")} rows={2} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<XsSimpleTextArea field={this.form.$("implants")} rows={2} />
					</Grid>
				</Grid>
				<Grid container spacing={8}>
					<Grid item xs={3}>
						<XsDateTimePicker
							field={this.form.$("datetime")}
							showTimeSelect={false}
							onSelect={(val) =>
								isNotEmpty(val)
									? (this.form.$("datetimeFormat").value = moment(val).format("DD.MM.YYYY"))
									: (this.form.$("datetimeFormat").value = "")
							}
						/>
					</Grid>
				</Grid>
				<TemplateForm type={this.templateName} />
			</React.Fragment>
		)
	}
}
