import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import renderHTML from "react-render-html"

import "./printTemplatesRow.less"
// import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsExpansionPanel from "../../../../../../global/ui/xsExpansionPanel/xsExpansionPanel"
import Barcode from "../../../../../../global/ui/xsBarcode/xsBarcode"
import UIStore from "../../../../stores/UIStore"

const PrintTemplatesRow = observer(({item}) => {
	// let templateName = ""
	let content
	let contentJSON

	try {
		content = base64DecodeUnicode(item.data[0].content)
	} catch (e) {
		logger("PrintTemplatesRow base64DecodeUnicode content error", e)
	}

	try {
		contentJSON = JSON.parse(content)
		// templateName = PrintTemplatesStore.getTemplateName(contentJSON.templateId)
	} catch (e) {
		logger("PrintTemplatesRow parsing content error", e)
	}

	if (isSafe(contentJSON) && contentJSON.templateId == "header" && isSafe(contentJSON.dekurz)) {
		return (
			<XsExpansionPanel maxHeight={20} glOpen={UIStore.isOpenAllRecordsInEHROverview}>
				<div className="printTemplatesRow">
					{renderHTML(contentJSON.dekurz)}
					{/* <div>
						<span className="subtitle">{templateName}</span>
					</div> */}
				</div>
			</XsExpansionPanel>
		)
	} else if (isSafe(contentJSON) && contentJSON.templateId == "corona" && isSafe(contentJSON.covidPass)) {
		return (
			<XsExpansionPanel maxHeight={20} glOpen={UIStore.isOpenAllRecordsInEHROverview}>
				<div className="printTemplatesRow">
					<div className="pr-barcode">
						<Barcode value={contentJSON.covidPass} format="CODE128" ean128={true} displayValue={true} height={50} />
					</div>
				</div>
			</XsExpansionPanel>
		)
	} else {
		return null
	}
})

export default injectIntl(PrintTemplatesRow)
