export default {
	load: {
		storeKey: "medWorkExpContainerForm",
		// params: ["personnelId"],
		type: "EHR.Data.Entity.PersonnelExpertise",
		flags: "CD"
	},
	descriptor: {
		_ref: false
	},
	scheme: {
		expertise: {
			object: {
				descriptor: {
					scope: "Pe",
					group: "EXPERTISE"
				},
				scheme: {
					_id: {
						field: "expertiseId"
					}
				}
			}
		},
		employment_contract: {
			object: {
				descriptor: {
					_type: "Entity.Data.EmploymentContract",
					_ref: true
				},
				scheme: {
					_id: {
						field: "contract"
					}
				}
			}
		},
		validity: {
			object: {
				scheme: {
					from: {
						field: "from"
					},
					to: {
						field: "to"
					}
				}
			}
		},
		identifiers: {
			relation: "@identifiers"
		},
		personnel: {
			object: {
				descriptor: {
					_ref: false,
					_type: "EHR.Data.Entity.Personnel"
				},
				scheme: {
					_id: {
						relation: "#personnelId"
					}
				}
			}
		},
		_id: {
			field: "personnelExpertisesId"
		},
		assesing_physician: {
			object: {
				scheme: {
					_id: {
						field: "assesing_physician"
					}
				}
			}
		}
	}
}
