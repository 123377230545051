//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import TextField from "@material-ui/core/TextField"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import OverPrescriptionDialogStore from "../../../stores/OverPrescriptionDialogStore"
import {pascalCaseToSentance} from "../../../../../global/helpers/functions"

@observer
export default class ConfirmInteracationDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		let data = OverPrescriptionDialogStore.data

		return (
			<Dialog
				id="xsOverPrescriptionDialog"
				open={OverPrescriptionDialogStore.isOpen}
				onClose={() => OverPrescriptionDialogStore.close()}
				//onEscapeKeyDown={() => DrugInteractionsDialogStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-base">
					<div className="xs-header">
						<div className="xs-header-icon">
							{!OverPrescriptionDialogStore.isCheck ? (
								<i className="fal fa-check-circle fa-2x"></i>
							) : (
								<i className="fal fa-info-circle fa-2x"></i>
							)}
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Calendar.overPrescription.dialog.headerMessage" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					{!OverPrescriptionDialogStore.isCheck && (
						<TextField
							label={<FormattedMessage id="Calendar.overPrescription.dialog.bardcode" />}
							value={OverPrescriptionDialogStore.barcode}
							onChange={(e) => OverPrescriptionDialogStore.onHandleChange(e.target.value)}
							InputLabelProps={{
								shrink: true
							}}
							fullWidth
						/>
					)}
					{OverPrescriptionDialogStore.isCheck && isSafe(data) && (
						<div>
							<div className="xs-insurer-item">
								<div className="xs-insurer-label">
									<FormattedMessage id="Calendar.overPrescription.dialog.signature" />:
								</div>
								<div className="xs-insurer-text">{pascalCaseToSentance(data.stav_overenia_podpisu)}</div>
							</div>
							{isSafe(data.ciarovy_kod) && (
								<div className="xs-insurer-item">
									<div className="xs-insurer-label">
										<FormattedMessage id="Calendar.overPrescription.dialog.bardcode" />:
									</div>
									<div className="xs-insurer-text">{data.ciarovy_kod}</div>
								</div>
							)}
							{isSafe(data.kod_lekara) && (
								<div className="xs-insurer-item">
									<div className="xs-insurer-label">
										<FormattedMessage id="Calendar.overPrescription.dialog.doctorCode" />:
									</div>
									<div className="xs-insurer-text">{data.kod_lekara}</div>
								</div>
							)}
							{isSafe(data.kod_oupzs) && (
								<div className="xs-insurer-item">
									<div className="xs-insurer-label">
										<FormattedMessage id="Calendar.overPrescription.dialog.oupzsCode" />:
									</div>
									<div className="xs-insurer-text">{data.kod_oupzs}</div>
								</div>
							)}
							{isSafe(data.rc) && (
								<div className="xs-insurer-item">
									<div className="xs-insurer-label">
										<FormattedMessage id="Calendar.overPrescription.dialog.rc" />:
									</div>
									<div className="xs-insurer-text">{data.rc}</div>
								</div>
							)}
							{isSafe(data.kod_produktu) && (
								<div className="xs-insurer-item">
									<div className="xs-insurer-label">
										<FormattedMessage id="Calendar.overPrescription.dialog.productCode" />:
									</div>
									<div className="xs-insurer-text">{data.kod_produktu}</div>
								</div>
							)}
							{isSafe(data.nazov_produktu) && (
								<div className="xs-insurer-item">
									<div className="xs-insurer-label">
										<FormattedMessage id="Calendar.overPrescription.dialog.productName" />:
									</div>
									<div className="xs-insurer-text">{data.nazov_produktu}</div>
								</div>
							)}
							{isSafe(data.prepis_davkovania) && (
								<div className="xs-insurer-item">
									<div className="xs-insurer-label">
										<FormattedMessage id="Calendar.overPrescription.dialog.dosage" />:
									</div>
									<div className="xs-insurer-text">{data.prepis_davkovania}</div>
								</div>
							)}
							{data.udaje_vynimky && (
								<div>
									{isSafe(data.exclusion_insurer_id) && (
										<div className="xs-insurer-item">
											<div className="xs-insurer-label">
												<FormattedMessage id="Calendar.overPrescription.dialog.exlusionInsurerId" />:
											</div>
											<div className="xs-insurer-text">{data.exclusion_insurer_id}</div>
										</div>
									)}
									{isSafe(data.exclusion_number) && (
										<div className="xs-insurer-item">
											<div className="xs-insurer-label">
												<FormattedMessage id="Calendar.overPrescription.dialog.exlusionNumber" />:
											</div>
											<div className="xs-insurer-text">{data.exclusion_number}</div>
										</div>
									)}
									{isSafe(data.validity) && isSafe(data.validity.from) && (
										<div className="xs-insurer-item">
											<div className="xs-insurer-label">
												<FormattedMessage id="Calendar.overPrescription.dialog.validityFrom" />:
											</div>
											<div className="xs-insurer-text">{data.validity.from}</div>
										</div>
									)}
									{isSafe(data.validity) && isSafe(data.validity.to) && (
										<div className="xs-insurer-item">
											<div className="xs-insurer-label">
												<FormattedMessage id="Calendar.overPrescription.dialog.validityTo" />:
											</div>
											<div className="xs-insurer-text">{data.validity.to}</div>
										</div>
									)}
									{isSafe(data.approved_quantity) && (
										<div className="xs-insurer-item">
											<div className="xs-insurer-label">
												<FormattedMessage id="Calendar.overPrescription.dialog.approvedQuantity" />:
											</div>
											<div className="xs-insurer-text">{data.approved_quantity}</div>
										</div>
									)}
									{isSafe(data.unspent_quantity) && (
										<div className="xs-insurer-item">
											<div className="xs-insurer-label">
												<FormattedMessage id="Calendar.overPrescription.dialog.unspentQuantity" />:
											</div>
											<div className="xs-insurer-text">{data.unspent_quantity}</div>
										</div>
									)}
									{isSafe(data.price) && (
										<div className="xs-insurer-item">
											<div className="xs-insurer-label">
												<FormattedMessage id="Calendar.overPrescription.dialog.price" />:
											</div>
											<div className="xs-insurer-text">{data.price}</div>
										</div>
									)}
								</div>
							)}
						</div>
					)}
				</DialogContent>
				{!OverPrescriptionDialogStore.isCheck && (
					<DialogActions className="xs-footer xs-space-between">
						<XsButton
							className="xs-default xs-outline"
							icon={<i className="fal fa-times"></i>}
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => OverPrescriptionDialogStore.close()}
						/>
						<XsButton
							className="xs-success"
							icon={<i className="fal fa-check-circle"></i>}
							text={<FormattedMessage id="Common.form.check" />}
							onClick={() => OverPrescriptionDialogStore.check()}
						/>
					</DialogActions>
				)}
				{OverPrescriptionDialogStore.isCheck && (
					<DialogActions className="xs-footer">
						<XsButton
							className="xs-default xs-outline"
							icon={<i className="fal fa-times"></i>}
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => OverPrescriptionDialogStore.close()}
						/>
					</DialogActions>
				)}
			</Dialog>
		)
	}
}
