"use strict"

import {observable, action} from "mobx"
import {getUserCompanyInfo, getUser} from "../../../global/helpers/actions"
import api from "../actions/api"
import UIStore from "../stores/UIStore"
import WarningStore from "../../../global/store/WarningStore"

class NcziA01ReportStore {
	@observable reportList = []
	@observable changedFilters = false
	formRef = null

	@action isChangedFilter(formValues) {
		if (isNotEmpty(formValues.year)) {
			if (this.currFilters.year != formValues.year) {
				this.changedFilters = true
			} else {
				this.changedFilters = false
			}
		}
	}

	@action loadNcziA01Report(form, warningText, exportXML = false) {
		this.currFilters = form.values()

		UIStore.isFormSaving = true
		let filters = {
			filters: [
				{
					associated_column: "company_id",
					values: [
						{
							id_value: getUserCompanyInfo().id
						}
					]
				},
				{
					associated_column: "user_person_id",
					values: [
						{
							id_value: getUser().person._id
						}
					]
				}
			]
		}

		if (isNotEmpty(form.$("year").value)) {
			filters.filters.push({
				associated_column: "year",
				values: [
					{
						id_value: form.$("year").value
					}
				]
			})
		} else {
			UIStore.isFormSaving = false
			WarningStore.open(warningText)
			return
		}

		if (exportXML) {
			api
				.exportNcziA01Report(filters)
				.call()
				.then((response) => {
					UIStore.isFormSaving = false
					if (response.ok == true) {
						let fileName = isSafe(response.headers.get("content-disposition"))
							? response.headers.get("content-disposition").split("filename=")[1]
							: "export"
						response.blob().then((blob) => {
							let FileSaver = require("file-saver") // eslint-disable-line no-undef
							FileSaver.saveAs(blob, fileName)
						})
					}
				})
		} else {
			api
				.loadNcziA01Report(filters)
				.call()
				.then((response) => {
					this.reportList = response
					UIStore.isFormSaving = false
				})
				.catch(() => {
					UIStore.isFormSaving = false
				})
		}
	}
}

var singleton = new NcziA01ReportStore()
export default singleton
