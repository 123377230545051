import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import {Grid} from "@material-ui/core"

import "./printTemplatesStyle.less"

import fields from "./anamnesticQuestionsFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import DataStore from "../../../../stores/DataStore"
import XsCheckbox from "../../../../../../global/ui/xsCheckbox/xsCheckbox"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import TemplateForm from "../../patientRecordForm/templateForm"

@injectIntl
@observer
export default class RequestForMagneticResonance extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		// if (isEmpty(this.form.$("nameForm").value) && isNotEmpty(this.form.$("name").value)) {
		// 	this.form.$("nameForm").value = this.form.$("name").value
		// }

		// if (isEmpty(this.form.$("identifierForm").value) && isNotEmpty(this.form.$("identifier").value)) {
		// 	this.form.$("identifierForm").value = this.form.$("identifier").value
		// }
	}

	render() {
		let pacInfo = DataStore.patientDTO.get("patient")

		const isMan = isSafe(pacInfo.gender) && pacInfo.gender._id == "M" ? true : false

		this.form.$("isMan").value = !isMan

		return (
			<React.Fragment>
				<Grid container spacing={8} className="pl-8 pr-4" alignItems="baseline">
					<Grid item container xs={12}>
						<Grid item xs={4}>
							<XsDateTimePicker field={this.form.$("datetime_vaccination")} showTimeSelect />
						</Grid>
					</Grid>
					<Grid item xs={8}>
						Boli ste už očkovaný/á proti ochoreniu COVID-19?
					</Grid>
					<Grid item xs={2} className="pl-8">
						<XsCheckbox
							field={this.form.$("question_9_yes")}
							onChange={() => {
								this.form.$("question_9_no").value = false
								this.form.$("question_9_yes").value = true
							}}
						/>
					</Grid>
					<Grid item xs={2}>
						<XsCheckbox
							field={this.form.$("question_9_no")}
							onChange={() => {
								this.form.$("question_9_yes").value = false
								this.form.$("question_9_no").value = true
							}}
						/>
					</Grid>
					<Grid item xs={8}>
						Máte príznaky akútneho ochorenia (teplota nad 37 °C, kašeľ, bolesť hrdla, slabosť, malátnosť, bolesť kĺbov,
						herpes...)?
					</Grid>
					<Grid item xs={2} className="pl-8">
						<XsCheckbox
							field={this.form.$("question_1_yes")}
							onChange={() => {
								this.form.$("question_1_no").value = false
								this.form.$("question_1_yes").value = true
							}}
						/>
					</Grid>
					<Grid item xs={2}>
						<XsCheckbox
							field={this.form.$("question_1_no")}
							onChange={() => {
								this.form.$("question_1_yes").value = false
								this.form.$("question_1_no").value = true
							}}
						/>
					</Grid>
					<Grid item xs={8}>
						Trpíte závažným ochorením, ktoré bolo v minulosti posudzované ako prekážka očkovania?
					</Grid>
					<Grid item xs={2} className="pl-8">
						<XsCheckbox
							field={this.form.$("question_2_yes")}
							onChange={() => {
								this.form.$("question_2_no").value = false
								this.form.$("question_2_yes").value = true
							}}
						/>
					</Grid>
					<Grid item xs={2}>
						<XsCheckbox
							field={this.form.$("question_2_no")}
							onChange={() => {
								this.form.$("question_2_yes").value = false
								this.form.$("question_2_no").value = true
							}}
						/>
					</Grid>
					<Grid item xs={8}>
						Mali ste v minulosti závažnú alergickú reakciu – napr. anafylaktický šok?
					</Grid>
					<Grid item xs={2} className="pl-8">
						<XsCheckbox
							field={this.form.$("question_3_yes")}
							onChange={() => {
								this.form.$("question_3_no").value = false
								this.form.$("question_3_yes").value = true
							}}
						/>
					</Grid>
					<Grid item xs={2}>
						<XsCheckbox
							field={this.form.$("question_3_no")}
							onChange={() => {
								this.form.$("question_3_yes").value = false
								this.form.$("question_3_no").value = true
							}}
						/>
					</Grid>
					<Grid item xs={8}>
						Mali ste niekedy v minulosti závažné vedľajšie účinky po podaní vakcíny?
					</Grid>
					<Grid item xs={2} className="pl-8">
						<XsCheckbox
							field={this.form.$("question_4_yes")}
							onChange={() => {
								this.form.$("question_4_no").value = false
								this.form.$("question_4_yes").value = true
							}}
						/>
					</Grid>
					<Grid item xs={2}>
						<XsCheckbox
							field={this.form.$("question_4_no")}
							onChange={() => {
								this.form.$("question_4_yes").value = false
								this.form.$("question_4_no").value = true
							}}
						/>
					</Grid>
					<Grid item xs={8}>
						Máte vážnu poruchu zrážanlivosti krvi alebo beriete lieky na riedenie krvi?
					</Grid>
					<Grid item xs={2} className="pl-8">
						<XsCheckbox
							field={this.form.$("question_5_yes")}
							onChange={() => {
								this.form.$("question_5_no").value = false
								this.form.$("question_5_yes").value = true
							}}
						/>
					</Grid>
					<Grid item xs={2}>
						<XsCheckbox
							field={this.form.$("question_5_no")}
							onChange={() => {
								this.form.$("question_5_yes").value = false
								this.form.$("question_5_no").value = true
							}}
						/>
					</Grid>
					<Grid item xs={8}>
						Máte závažnú poruchu imunity?
					</Grid>
					<Grid item xs={2} className="pl-8">
						<XsCheckbox
							field={this.form.$("question_6_yes")}
							onChange={() => {
								this.form.$("question_6_no").value = false
								this.form.$("question_6_yes").value = true
							}}
						/>
					</Grid>
					<Grid item xs={2}>
						<XsCheckbox
							field={this.form.$("question_6_no")}
							onChange={() => {
								this.form.$("question_6_yes").value = false
								this.form.$("question_6_no").value = true
							}}
						/>
					</Grid>
					<Grid item xs={8}>
						Absolvovali ste v posledných dvoch týždňoch nejaké iné očkovanie?
					</Grid>
					<Grid item xs={2} className="pl-8">
						<XsCheckbox
							field={this.form.$("question_8_yes")}
							onChange={() => {
								this.form.$("question_8_no").value = false
								this.form.$("question_8_yes").value = true
							}}
						/>
					</Grid>
					<Grid item xs={2}>
						<XsCheckbox
							field={this.form.$("question_8_no")}
							onChange={() => {
								this.form.$("question_8_yes").value = false
								this.form.$("question_8_no").value = true
							}}
						/>
					</Grid>
					{!isMan && (
						<React.Fragment>
							<Grid item xs={8}>
								Ste tehotná alebo dojčíte?
							</Grid>
							<Grid item xs={2} className="pl-8">
								<XsCheckbox
									field={this.form.$("question_7_yes")}
									onChange={() => {
										this.form.$("question_7_no").value = false
										this.form.$("question_7_yes").value = true
									}}
								/>
							</Grid>
							<Grid item xs={2}>
								<XsCheckbox
									field={this.form.$("question_7_no")}
									onChange={() => {
										this.form.$("question_7_yes").value = false
										this.form.$("question_7_no").value = true
									}}
								/>
							</Grid>
						</React.Fragment>
					)}
				</Grid>
				<TemplateForm type={this.templateName} />
			</React.Fragment>
		)
	}
}
