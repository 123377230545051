export default {
	load: {
		storeKey: "userLogin",
		params: ["userId"],
		type: "IXS.Security.Data.User"
	},
	descriptor: {
		_ref: false,
		active: true
	},
	scheme: {
		_id: {
			relation: "@userId"
		},
		confirmed: {
			field: "confirmed"
		},
		email: {
			field: "email"
		},
		enabled: {
			field: "enabled"
		},
		login: {
			field: "login"
		},
		password_plain: {
			field: "password"
		}
	}
}
