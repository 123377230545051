import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsDropdownList from "../../../../../global/ui/xsDropdown/xsDropdownList"
import XsInputSearchFilter from "../../../../../global/ui/xsInput/xsInputSearchFilter"

import {TableHead, Table, TableBody, TableCell, TableRow, TextField} from "@material-ui/core"

import PricePerPointByGroupDeliveryForm from "./pricePerPointByGroupDeliveryForm"

import AgreementAttributeStore from "../../../stores/AgreementAttributeStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

import api from "../../../actions/api"

@injectIntl
@observer
export default class PricePerPointByGroupDeliveryGrid extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			order: false,
			orderBy: null,
			sortType: ""
		}
	}

	tableBodyRows
	tableBody() {
		this.tableBodyRows = []

		if (
			isSafe(AgreementAttributeStore.attributeDataJSON) &&
			AgreementAttributeStore.attributeDataJSON.hasOwnProperty(AgreementAttributeStore.typeFilter) &&
			Object.keys(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]).length > 0
		) {
			// dataSource.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).forEach((currRow, idx) => {
			Object.keys(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]).forEach(
				(rowId, idx) => {
					if (AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].active) {
						this.tableBodyRows.push(
							<TableRow key={idx}>
								<TableCell>
									{AgreementAttributeStore.isEditAttributeGrid ? (
										<XsDropdownList
											emptyValue
											className="xs-dropdown"
											items={GlobalStore.CL["deliveryGroup"]}
											renderer={(item) => {
												return item.name_ext
											}}
											column="_id"
											value={
												AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
													.delivery_group._id
											}
											chooseItem={(item) => {
												AgreementAttributeStore.changedAttribute(
													AgreementAttributeStore.typeFilter,
													rowId,
													"delivery_group",
													item._id
												)
											}}
										/>
									) : (
										AgreementAttributeStore.deliveryGroupGridJSON[rowId]
									)}
								</TableCell>
								<TableCell>
									{AgreementAttributeStore.isEditAttributeGrid ? (
										<XsInputSearchFilter
											api={api.loadExpertisePr}
											minLengthForSearch={1}
											// isValid={isEmpty(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].deliveries)}
											onButtonClick={(value) =>
												delayedCallback(500, () => AgreementAttributeStore.searchExpertise(value, rowId))
											}
											data={AgreementAttributeStore.clExpertiseGrid[rowId]}
											onSearchClose={() => (AgreementAttributeStore.clExpertiseGrid[rowId] = [])}
											defaultValue={AgreementAttributeStore.clExpertiseGridJSON[rowId]}
											className="xs-input"
											inputRenderer={(dataRow) => {
												return dataRow.code_ext + " " + dataRow.name_ext
											}}
											valueRenderer={(dataRow) => {
												return dataRow.code_ext + " " + dataRow.name_ext
											}}
											chooseItem={(value) =>
												AgreementAttributeStore.changedAttribute(
													AgreementAttributeStore.typeFilter,
													rowId,
													"cl_expertise",
													value._id
												)
											}
											onClear={() =>
												AgreementAttributeStore.changedAttribute(
													AgreementAttributeStore.typeFilter,
													rowId,
													"cl_expertise",
													""
												)
											}
											modalTitle={<FormattedMessage id="Patient.form.patientrequest.expertise" />}
											modalConfig={(textValue, clickHandler) => {
												return {
													columnDefs: {
														code_ext: {
															title: <FormattedMessage id="Agreement.Container.Detail.Attribute.DeliveryCode" />,
															type: "string",
															dbName: "code_ext",
															design: {
																width: "150px"
															},
															filter: {
																gridWidth: 3,
																defaultValue: "",
																renderElement: "input"
															}
														},
														name_ext: {
															title: <FormattedMessage id="Agreement.Container.Detail.Attribute.DeliveryName" />,
															type: "string",
															dbName: "search_column",
															filter: {
																gridWidth: 9,
																defaultValue: textValue,
																renderElement: "input"
															}
															// sortable: true
														}
													},
													options: {
														// selectRow: true,
														showCursor: true,
														onRowClick: (dataRow) => {
															clickHandler(dataRow)
														}
													}
												}
											}}
										/>
									) : (
										AgreementAttributeStore.clExpertiseGridJSON[rowId]
									)}
								</TableCell>
								<TableCell className="xs-text-right xs-width-150">
									{AgreementAttributeStore.isEditAttributeGrid ? (
										<TextField
											// defaultValue={currRow.price_in_currency}
											value={
												AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
													.price_in_currency
											}
											// onChange={(e) => AgreementAttributeStore.updateAttribute(rowId, "price_in_currency", e.target.value)}
											onChange={(e) =>
												AgreementAttributeStore.changedAttribute(
													AgreementAttributeStore.typeFilter,
													rowId,
													"price_in_currency",
													e.target.value
												)
											}
											InputProps={{
												className: "xs-input",
												classes: {
													root: !isPrice(
														AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
															.price_in_currency
													)
														? "xs-input-error"
														: ""
												}
											}}
											// /> : AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].price_in_currency
										/>
									) : (
										priceFormat(
											AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
												.price_in_currency
										)
									)}
								</TableCell>
								{AgreementAttributeStore.isEditAttributeGrid && (
									<TableCell className="xs-text-center xs-delete-action">
										<XsIconButton
											icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
											onClick={
												() =>
													AgreementAttributeStore.changedAttribute(
														AgreementAttributeStore.typeFilter,
														rowId,
														"active",
														false
													) /*GlobalStore.openConfirmationDialog("xsDeleteAgreementAttribute", { attributeId: AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].id })*/
											}
										/>
									</TableCell>
								)}
							</TableRow>
						)
					}
				}
			)
		} else {
			this.tableBodyRows.push(
				<TableRow key={1}>
					<TableCell className="xs-table-no-data" colSpan={2}>
						<FormattedMessage id="Table.NoData" />
					</TableCell>
				</TableRow>
			)
		}
	}

	render() {
		this.tableBody()

		return (
			<div className="xs-table-ex">
				<div className="xs-table">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<FormattedMessage id="Common.label.deliveryGroup" />
								</TableCell>
								<TableCell>
									<FormattedMessage id="Patient.form.patientrequest.expertise" />
								</TableCell>
								<TableCell className="xs-text-right">
									<FormattedMessage id="Agreement.Container.Detail.Attribute.Price" />
								</TableCell>
								{AgreementAttributeStore.isEditAttributeGrid && <TableCell />}
							</TableRow>
						</TableHead>
						<TableBody>{this.tableBodyRows}</TableBody>
					</Table>
				</div>
				<div className="xs-attribute-form">
					<PricePerPointByGroupDeliveryForm />
				</div>
			</div>
		)
	}
}
