import React from "react"

export default class MonthDropdownOptions extends React.Component {
	renderOptions = () => {
		return this.props.monthNames.map((month, i) => (
			<div
				className={
					this.props.month === i ? "react-datepicker__month-option --selected_month" : "react-datepicker__month-option"
				}
				key={month}
				ref={month}
				onClick={this.onChange}
			>
				{this.props.month === i ? <span className="react-datepicker__month-option--selected">✓</span> : ""}
				{month}
			</div>
		))
	}

	onChange = (month) => this.props.onChange(month)

	handleClickOutside = () => this.props.onCancel()

	render() {
		return <div className="react-datepicker__month-dropdown">{this.renderOptions()}</div>
	}
}
