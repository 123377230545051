import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				login: {
					label: <FormattedMessage id="Employee.form.user.login" />,
					rules: "required"
				},
				password: {
					label: <FormattedMessage id="Employee.form.user.password" />,
					rules: "required"
				},
				password2: {
					label: <FormattedMessage id="Employee.form.user.password2" />,
					rules: "required"
				},
				email: {
					label: <FormattedMessage id="Common.label.email" />,
					rules: "required"
				},
				enabled: {
					label: <FormattedMessage id="Employee.form.user.enabled" />,
					type: "checkbox",
					value: true
				},
				confirmed: {
					label: <FormattedMessage id="Employee.form.user.confirmed" />,
					type: "checkbox",
					value: true
				}
			}
		}
	}
}
