"use strict"
import api from "../actions/api"
import ambulanceApi from "../../ambulance/actions/api"
import {observable, action} from "mobx"
import {getFilters} from "../../../global/helpers/api"
import {getUserCompanyInfo} from "../../../global/helpers/actions"
import RouterStore from "../../../global/store/RouterStore"
import GlobalStore from "../../../global/store/GlobalStore"

class OrgunitDetailStore {
	@observable orgunitData = []
	@observable orgunitDetailEmpl = []
	@observable companyOrgunits = []
	@observable expertiseData = []
	@observable orgParamsList = []
	@observable orgParamsShowForm = false
	@observable banks = []

	@observable editOrgunit = false
	@observable fullName = ""

	@observable orgunitDTO = observable.map({
		contacts: [],
		addresses: [],
		banks: []
	})

	@action loadOrgunitData(id) {
		if (isEmpty(id)) return
		api
			.loadOrgUnitDetail(id)
			.call()
			.then((response) => {
				if (isSafe(response)) {
					this.orgunitData = response

					if (isSafe(response.addresses)) {
						this.orgunitDTO.set("addresses", response.addresses)
					}

					if (isSafe(response.contacts)) {
						this.orgunitDTO.set("contacts", response.contacts)
					}
					this.loadOrgunitBankAcc(id)
				}
			})
	}

	@action loadOrgunitBankAcc(id) {
		api
			.loadOrgunitBankAcc(id)
			.call()
			.then((response) => {
				this.orgunitDTO.set("banks", response)
			})
	}

	@action loadOrgunitEmpl(id) {
		api
			.loadOrgunitDetailEmpl(id)
			.call()
			.then((response) => {
				this.orgunitDetailEmpl = response.rows
			})
	}

	@action loadFormData(form) {
		form.$("code").set(this.orgunitData.code)
		form.$("name").set(this.orgunitData.name_ext)
		form.$("superiorOJ").set(this.orgunitData.parent)
		form
			.$("company")
			.set(isSafe(this.orgunitData.entity) && isSafe(this.orgunitData.entity._id) ? this.orgunitData.entity._id : "")
		form
			.$("expertise")
			.set(
				isSafe(this.orgunitData.expertises) &&
					isSafe(this.orgunitData.expertises[0]) &&
					isSafe(this.orgunitData.expertises[0].expertise)
					? this.orgunitData.expertises[0].expertise._id
					: ""
			)
		// form.$("expertise").set(isSafe(this.orgunitData.expertises) && isSafe(this.orgunitData.expertises[0]) && isSafe(this.orgunitData.expertises[0].expertise) ? this.orgunitData.expertises[0].expertise.code_ext + " " + this.orgunitData.expertises[0].expertise.name_ext : "")
		form
			.$("OUPZScode")
			.set(
				isSafe(this.orgunitData.identifiers) &&
					isSafe(this.orgunitData.identifiers.find((i) => i.type === "PROVIDERCODE"))
					? this.orgunitData.identifiers.find((i) => i.type === "PROVIDERCODE").value
					: ""
			)
		form
			.$("JRUZid")
			.set(
				isSafe(this.orgunitData.identifiers) && isSafe(this.orgunitData.identifiers.find((i) => i.type === "JRUZID"))
					? this.orgunitData.identifiers.find((i) => i.type === "JRUZID").value
					: ""
			)
	}

	@action clearData(name) {
		this[name] = {}
	}

	@action loadSuperiorOrgunits(id) {
		let company = getUserCompanyInfo()
		const searchRequest = getFilters([`company_id=${company.id}`])
		api
			.loadOrgUnits(searchRequest)
			.call()
			.then((response) => {
				this.companyOrgunits = response.rows
					.filter((i) => i.org_unit_id != id)
					.map((i) => {
						return {
							code_ext: i.code_ext,
							_id: i.org_unit_id,
							name_ext: i.name_ext
						}
					})
			})
	}

	@action searchExpertiseOrg(value) {
		const searchRequest = getFilters([`search_column=${value}`])
		ambulanceApi
			.loadExpertise(searchRequest, "PR")
			.call()
			.then((response) => {
				this.expertiseData = response.rows
			})
	}

	@action loadOrgUnitParams(orgunitId) {
		const searchRequest = getFilters([`org_unit=${orgunitId}`])
		api
			.loadOrgunitsParams(searchRequest)
			.call()
			.then((response) => {
				this.orgParamsList = response.rows
			})
	}

	@action deleteParam(paramId, orgunitId) {
		let data = {
			_id: paramId,
			active: false,
			_ref: false,
			_type: "Entity.Data.OrgUnitParam"
		}
		api
			.saveOrgunitParam(data)
			.call()
			.then(() => {
				this.loadOrgUnitParams(orgunitId)
			})
	}

	@action deleteOrgunit() {
		const req = {
			_id: GlobalStore.confirmationDialogParams.orgUnitId,
			_ref: false,
			_type: "Entity.Data.OrgUnit",
			active: false
		}

		ambulanceApi
			.saveSpecificRel(req)
			.call()
			.then(() => {
				this.editOrgunit = false
				this.fullName = ""
				RouterStore.push(`/employees`)
			})
	}
}
var singleton = new OrgunitDetailStore()
export default singleton
