import React from "react"
import classnames from "classnames"
import Week from "./Week"
import * as utils from "./helpers"

const FIXED_HEIGHT_STANDARD_WEEK_COUNT = 6

export default class Month extends React.Component {
	handleDayClick = (day, event) => {
		if (this.props.onDayClick) {
			this.props.onDayClick(day, event)
		}
	}

	handleDayMouseEnter = (day) => {
		if (this.props.onDayMouseEnter) {
			this.props.onDayMouseEnter(day)
		}
	}

	handleMouseLeave = () => {
		if (this.props.onMouseLeave) {
			this.props.onMouseLeave()
		}
	}

	isWeekInMonth = (startOfWeek) => {
		const day = this.props.day
		const endOfWeek = utils.addDays(utils.cloneDate(startOfWeek), 6)
		return utils.isSameMonth(startOfWeek, day) || utils.isSameMonth(endOfWeek, day)
	}

	renderWeeks = () => {
		const weeks = []
		var isFixedHeight = this.props.fixedHeight
		let currentWeekStart = utils.getStartOfWeek(utils.getStartOfMonth(utils.cloneDate(this.props.day)))
		let i = 0
		let breakAfterNextPush = false

		// eslint-disable-line
		while (true) {
			weeks.push(
				<Week
					key={i}
					day={currentWeekStart}
					month={utils.getMonth(this.props.day)}
					onDayClick={this.handleDayClick}
					onDayMouseEnter={this.handleDayMouseEnter}
					onWeekSelect={this.props.onWeekSelect}
					formatWeekNumber={this.props.formatWeekNumber}
					minDate={this.props.minDate}
					maxDate={this.props.maxDate}
					excludeDates={this.props.excludeDates}
					includeDates={this.props.includeDates}
					inline={this.props.inline}
					highlightDates={this.props.highlightDates}
					selectingDate={this.props.selectingDate}
					filterDate={this.props.filterDate}
					preSelection={this.props.preSelection}
					selected={this.props.selected}
					selectsStart={this.props.selectsStart}
					selectsEnd={this.props.selectsEnd}
					showWeekNumber={this.props.showWeekNumbers}
					startDate={this.props.startDate}
					endDate={this.props.endDate}
					dayClassName={this.props.dayClassName}
					utcOffset={this.props.utcOffset}
				/>
			)

			if (breakAfterNextPush) {
				break
			}

			i++
			currentWeekStart = utils.addWeeks(utils.cloneDate(currentWeekStart), 1)

			// If one of these conditions is true, we will either break on this week
			// or break on the next week
			const isFixedAndFinalWeek = isFixedHeight && i >= FIXED_HEIGHT_STANDARD_WEEK_COUNT
			const isNonFixedAndOutOfMonth = !isFixedHeight && !this.isWeekInMonth(currentWeekStart)

			if (isFixedAndFinalWeek || isNonFixedAndOutOfMonth) {
				if (this.props.peekNextMonth) {
					breakAfterNextPush = true
				} else {
					break
				}
			}
		}

		return weeks
	}

	getClassNames = () => {
		const {selectingDate, selectsStart, selectsEnd} = this.props
		return classnames("react-datepicker__month", {
			"react-datepicker__month--selecting-range": selectingDate && (selectsStart || selectsEnd)
		})
	}

	render() {
		return (
			<div className={this.getClassNames()} onMouseLeave={this.handleMouseLeave} role="listbox">
				{this.renderWeeks()}
			</div>
		)
	}
}
