import React from "react"
import {observer} from "mobx-react"
import moment from "moment"
import ReactTooltip from "react-tooltip"
import {Tooltip /*, Popover, Grid*/} from "@material-ui/core"
import classnames from "classnames"
import CircularProgress from "@material-ui/core/CircularProgress"

import EditEventDialogStore from "../../stores/EditEventDialogStore"
import CalendarStore from "../../stores/CalendarStore"
// import DataStore from "../../stores/DataStore"
import AddEventDialogStore from "../../stores/AddEventDialogStore"
import {FormattedMessage, injectIntl} from "react-intl"
// import XsConfirmationDialog from "../../../../global/ui/xsDialog/xsConfirmationDialog"

// import {getUser} from "../../../../global/helpers/actions"
// import {getFilters} from "../../../../global/helpers/api"
// import api from "../../actions/api"

// import GlobalStore from "../../../../global/store/GlobalStore"

@injectIntl
@observer
export default class DailyView extends React.Component {
	constructor(props) {
		super(props)

		// this.state = {
		// 	anchorEl: null,
		// 	popoverId: "",
		// 	listOfQueue: []
		// }

		CalendarStore.dailyViewRef = this
	}

	getHourLabelClass = (hour) => {
		return moment()
			.local()
			.get("hours") == hour
			? "xs-daily-hour xs-hour-active"
			: "xs-daily-hour"
	}

	componentDidUpdate() {
		// const minutes = moment().local().get("minutes");
		// const percentage = (minutes / 60) * 100;
		// const timeline = Math.round(percentage);
		// this.setPos()
	}

	componentDidMount() {
		// this.refreshInterval = setInterval(() => {
		// 	if (!AddEventDialogStore.isOpen && moment(CalendarStore.selectedDay).isSame(moment(), "day")) {
		// 		CalendarStore.setCalendarRange("today")
		// 	}
		// }, 30000)
		this.scrollInterval = setInterval(() => {
			if (!AddEventDialogStore.isOpen && moment(CalendarStore.selectedDay).isSame(moment(), "day")) {
				this.setPos()
			}
		}, 1800000)
	}

	componentWillUnmount() {
		clearInterval(this.refreshInterval)
		clearInterval(this.scrollInterval)
	}

	setPos = () => {
		// const progressb = this.timeline
		if (isSafe(this.inRow) && !CalendarStore.calendarToggledFull) {
			let scrollY = this.inRow <= 3 ? 0 : this.inRow * 55 - 175

			if (isSafe(this.dailyViewRef) && scrollY > 0) {
				this.dailyViewRef.scrollTop = scrollY
			}
		}
		return 0
	}

	openAddEventDialog = (hourFrom, hourTo) => {
		if (EditEventDialogStore.isClickedEdit) {
			EditEventDialogStore.isClickedEdit = false
		} else {
			if (CalendarStore.selectedDay.isBefore(hourFrom)) {
				CalendarStore.selectedDay = moment(CalendarStore.selectedDay)
					.set("hour", hourFrom.hours())
					.set("minute", hourFrom.minutes())
					.set("second", 0)
				CalendarStore.inputDateTimeIsValid = moment(CalendarStore.selectedDay)
					.set("hour", hourFrom.hours())
					.set("minute", hourFrom.minutes())
					.set("second", 0)
					.format("DD.MM.YYYY HH:mm")
			} else if (CalendarStore.selectedDay.isAfter(hourTo)) {
				CalendarStore.selectedDay = moment(CalendarStore.selectedDay)
					.set("hour", hourTo.hours())
					.set("minute", hourTo.minutes())
					.set("second", 0)
				CalendarStore.inputDateTimeIsValid = moment(CalendarStore.selectedDay)
					.set("hour", hourTo.hours())
					.set("minute", hourTo.minutes())
					.set("second", 0)
					.format("DD.MM.YYYY HH:mm")
			} else {
				CalendarStore.selectedDay = moment(CalendarStore.selectedDay).set(
					"minute",
					CalendarStore.getRoundMinutes(moment(CalendarStore.selectedDay))
				)
				CalendarStore.inputDateTimeIsValid = moment(CalendarStore.selectedDay)
					.set("minute", CalendarStore.getRoundMinutes(moment(CalendarStore.selectedDay)))
					.format("DD.MM.YYYY HH:mm")
			}

			AddEventDialogStore.open()
		}
	}

	getEventClass = (originalTime, duration) => {
		const time = JSON.parse(JSON.stringify(originalTime))
		if (
			moment()
				.local()
				.get("hours") ===
			moment(time)
				.local()
				.get("hours")
		) {
			if (
				moment(time)
					.local()
					.get("minutes") <=
					moment()
						.local()
						.get("minutes") &&
				moment()
					.local()
					.get("minutes") <=
					moment(time)
						.local()
						.get("minutes") +
						duration / 60
			) {
				return true
			}
		}

		return false
	}

	getInsurerStatus = (status) => {
		switch (status) {
			case 0:
				return (
					<Tooltip
						title={this.props.intl.formatMessage({
							id: "Common.label.patientNotExistUDZS"
						})}
						placement="left"
					>
						<i className="fas fa-check-circle fa-lg redDanger" />
					</Tooltip>
				)
			case 1:
				return (
					<Tooltip
						title={this.props.intl.formatMessage({
							id: "Common.label.patientHasInsurerUDZS"
						})}
						placement="left"
					>
						<i className="fas fa-check-circle fa-lg greenSuccess" />
					</Tooltip>
				)
			case 2:
				return (
					<Tooltip
						title={this.props.intl.formatMessage({
							id: "Common.label.patientNotHaveCorrectInsurerUDZS"
						})}
						placement="left"
					>
						<i className="fas fa-check-circle fa-lg amberWarning" />
					</Tooltip>
				)
		}
	}

	getBillStatus = (isBill) => {
		if (isBill) {
			return (
				<Tooltip
					title={this.props.intl.formatMessage({
						id: "Common.label.billed"
					})}
					placement="left"
				>
					<i className="fas fa-euro-sign fa-lg greenSuccess" />
				</Tooltip>
			)
		}

		return (
			<Tooltip
				title={this.props.intl.formatMessage({
					id: "Common.label.unbilled"
				})}
				placement="left"
			>
				<i className="fas fa-euro-sign fa-lg amberWarning" />
			</Tooltip>
		)
	}

	getEventStatus = (orderTime, status) => {
		if (status == "F") {
			return (
				<Tooltip
					title={this.props.intl.formatMessage({
						id: "Common.label.eventEnd"
					})}
					placement="left"
				>
					<i className="fas fa-user-check fa-lg greenSuccess" />
				</Tooltip>
			)
		} else {
			if (status == "P") {
				return (
					<Tooltip
						title={this.props.intl.formatMessage({
							id: "Common.label.inProgress"
						})}
						placement="left"
					>
						<i className="fas fa-user-cog fa-lg amberWarning" />
					</Tooltip>
				)
			} else {
				if (moment(orderTime).isBefore(moment())) {
					return (
						<Tooltip
							title={this.props.intl.formatMessage({
								id: "Common.label.notEventEnd"
							})}
							placement="left"
						>
							<i className="fas fa-user fa-lg greyDefault" />
						</Tooltip>
					)
				}
			}
		}
	}

	// user = getUser()
	// managerId =
	// 	isSafe(this.user) && isSafe(this.user.user_settings) && isNotEmpty(this.user.user_settings.QueueManagerEmployeeId)
	// 		? this.user.user_settings.QueueManagerEmployeeId
	// 		: null

	// handleClick = (event, eventId) => {
	// 	this.setState({anchorEl: event.currentTarget, popoverId: eventId})
	// 	//preto bez helpera, lebo sa to robi pre vacc, platame

	// 	const req = getFilters([`specific_rel=${this.managerId}`])
	// 	api
	// 		.getQueueByManager(req)
	// 		.call()
	// 		.then((res) => {
	// 			if (isSafe(res) && isSafe(res.rows) && res.rows.length) {
	// 				this.setState({listOfQueue: res.rows})
	// 			} else {
	// 				this.setState({listOfQueue: []})
	// 			}
	// 		})
	// }

	// handleClose = () => {
	// 	this.setState({anchorEl: null, listOfQueue: [], popoverId: ""})
	// }

	// increaseQueue = (queueId, eventId) => {
	// 	const req = {
	// 		_id: "",
	// 		_ref: false,
	// 		_type: "EZdravie.Data.MeZ.WorkQueue.Event",
	// 		event: {
	// 			_type: "Service.Data.Event" /* vazba na event z kalendara */,
	// 			_id: eventId
	// 		}
	// 	}

	// 	api
	// 		.increaseQueue(queueId, req)
	// 		.call()
	// 		.then(() => {
	// 			this.handleClose()
	// 		})
	// }

	// getQueueStatus = (capacity, waiting) => {
	// 	if (capacity > waiting) {
	// 		return <i className="fas fa-circle greenSuccess" />
	// 	} else if (capacity == waiting) {
	// 		return <i className="fas fa-circle amberDark" />
	// 	} else {
	// 		return <i className="fas fa-circle redDanger" />
	// 	}
	// }

	// removeFromQueue = (eventId) => {
	// 	//preto bez helpera, lebo sa to robi pre vacc, platame
	// 	// const req = getFilters([`specific_rel=${this.managerId}`])

	// 	api
	// 		.loadQueueByEvent(eventId, req)
	// 		.call()
	// 		.then((res) => {
	// 			if (isSafe(res) && isSafe(res.rows) && res.rows.length) {
	// 				GlobalStore.openConfirmationDialog("xsRemoveItemFromQueue", {queueItemId: res.rows[0]._id})
	// 			} else {
	// 				GlobalStore.setNotificationMessage("Pacient sa nenachádza v žiadnej fronte")
	// 			}
	// 		})
	// }

	// removeItemFromQueue = () => {
	// 	api
	// 		.cancelEventFromQueue(GlobalStore.confirmationDialogParams.queueItemId)
	// 		.call()
	// 		.then(() => {
	// 			GlobalStore.setNotificationMessage("Pacient odstránený z fronty")
	// 		})
	// }

	render() {
		const expandTooltip = CalendarStore.calendarToggledFull ? "hideCalendar" : "showAllCalendar"

		const loadingStyle = {
			position: "absolute",
			minHeight: "50px",
			top: 0,
			bottom: 0,
			right: 0,
			display: "flex",
			left: 0,
			alignItems: "center",
			justifyContent: "center",
			backgroundColor: "rgba(0,0,0,0.3)"
		}

		return (
			<div className="xs-daily-view" style={{position: "relative"}}>
				<div className="xs-orders-scrollbar">
					<div
						className={classnames("xs-orders", {
							"pb-3": CalendarStore.eventsData.length <= 5,
							"xs-fix": !CalendarStore.calendarToggledFull && CalendarStore.eventsData.length > 5
						})}
						style={{minHeight: "50px"}}
						ref={(div) => (this.dailyViewRef = div)}
					>
						{isSafe(CalendarStore.eventsData) &&
							CalendarStore.eventsData.length > 0 &&
							CalendarStore.eventsData.map((order, i) => {
								if (isSafe(order) && isSafe(order.event)) {
									const {client, status, event_note_text, services, bill_status, insurer_status} = order.event

									const service = isSafe(services) && services.length > 0 && isSafe(services[0]) ? services[0] : null
									const isBill = isSafe(bill_status) && bill_status._id == "B" ? true : false
									let bgColor = isSafe(service) && isNotEmpty(service.back_color) ? service.back_color : "#808080"
									let fontColor = isSafe(service) && isNotEmpty(service.fore_color) ? service.fore_color : undefined
									let isEventEnd = isSafe(status) && status._id === "F" ? true : false
									let actualEvent = isSafe(service) ? this.getEventClass(order.time, service.duration) : false
									// let inThePast = isSafe(service) ? isInThePast(order.time, service.duration) : false
									// let colors = CalendarStore.invertColor(bgColor, fontColor, isEventEnd, inThePast, true)
									let colors = CalendarStore.invertColor(bgColor, fontColor, isEventEnd, false, true)

									let lastName = isNotEmpty(client.last_name) ? client.last_name.toUpperCase() : ""

									let eventNote = isNotEmpty(event_note_text) ? " (" + event_note_text + ")" : ""

									let subInfo = isNotEmpty(client.identifier)
										? client.identifier + ", " + service.service_name_ext + eventNote
										: isNotEmpty(eventNote)
										? service.service_name_ext + eventNote
										: service.service_name_ext

									let inThePastClass = moment().isAfter(moment(order.time)) ? " xs-in-the-past" : ""

									if (actualEvent) {
										this.inRow = i + 1
									}

									return (
										<div
											key={i}
											className={`${actualEvent ? "xs-event xs-active pointer" : "xs-event pointer"} ${inThePastClass}`}
											style={{cursor: ""}}
											onClick={() => {
												EditEventDialogStore.isClickedEdit = true
												EditEventDialogStore.open(order.event)
											}}
										>
											<div className="xs-time">{moment(order.time).format("HH:mm")}</div>
											<div
												// className="pointer"
												// onClick={() => {
												// 	EditEventDialogStore.isClickedEdit = true
												// 	EditEventDialogStore.open(order.event)
												// }}
												style={{height: "48px", display: "flex", alignItems: "center"}}
											>
												<div
													// className={`xs-service-info ${inThePastClass}`}
													className="xs-service-info"
													style={{backgroundColor: colors.backgroundColor, color: colors.color}}
												>
													{isSafe(service) && isNotEmpty(service.duration) ? Math.ceil(+service.duration / 60) : 0}
												</div>
											</div>
											<div
												// onClick={() => {
												// 	EditEventDialogStore.isClickedEdit = true
												// 	EditEventDialogStore.open(order.event)
												// }}
												className="xs-patient-info"
											>
												<div className={`xs-patient-name ${inThePastClass}`}>
													{isEmpty(client.last_name) && isEmpty(client.first_name) ? (
														client.client_text
													) : (
														<span>
															<span className="xs-lastname">{lastName}</span>
															{" " + client.first_name}
															{isNotEmpty(client.insurer_short_name) && ` (${client.insurer_short_name})`}
															{isNotEmpty(client.phone) && ", " + client.phone}
														</span>
													)}
												</div>
												<div className="xs-patient-service">{subInfo}</div>
											</div>
											<div className="xs-patient-actions">
												<div className="xs-insurer-icon">{this.getInsurerStatus(insurer_status)}</div>
												<div className="xs-bill-icon ml-1">{this.getBillStatus(isBill)}</div>
												<div className="xs-state-icon ml-1">{this.getEventStatus(order.time, status._id)}</div>
												{/* {isNotEmpty(this.managerId) && (
													<React.Fragment>
														<div
															className="xs-front-icon ml-2 pointer"
															onClick={(e) => this.handleClick(e, order.event.event_id)}
														>
															<Tooltip
																title={this.props.intl.formatMessage({
																	id: "Common.label.listOfQueue"
																})}
																placement="left"
															>
																<i className="fas fa-list" style={{color: "#39b0dd"}}></i>
															</Tooltip>
														</div>
														<Popover
															open={this.state.popoverId == order.event.event_id}
															anchorEl={this.state.anchorEl}
															anchorOrigin={{
																vertical: "bottom",
																horizontal: "right"
															}}
															transformOrigin={{
																vertical: "top",
																horizontal: "right"
															}}
															onClose={this.handleClose}
														>
															<Grid
																container
																className="pt-3 pb-3 pl-2 pr-2"
																alignItems="center"
																style={{width: "300px", maxHeight: "300px", overflowY: "auto"}}
															>
																{this.state.listOfQueue.length > 0 ? (
																	<React.Fragment>
																		<Grid item container className="pb-1 pl-1 pr-1">
																			<Grid item>Kliknutím na riadok priradíte pacienta do fronty:</Grid>
																		</Grid>
																		{this.state.listOfQueue.map((queue, idx) => {
																			return (
																				<Grid
																					item
																					className="pointer queueHover pl-1 pr-1"
																					style={{
																						borderBottom: "1px solid #e5e7ea",
																						paddingTop: "8px",
																						paddingBottom: "8px"
																					}}
																					alignItems="center"
																					container
																					key={idx}
																					onClick={() => this.increaseQueue(queue._id, order.event.event_id)}
																				>
																					<Grid item xs={1}>
																						{this.getQueueStatus(queue.capacity, queue.waiting)}
																					</Grid>
																					<Grid item xs={9}>
																						{queue.name}
																					</Grid>
																					<Grid item xs={2} container justify="center">
																						<Grid item>
																							{queue.capacity}/{queue.waiting}
																						</Grid>
																					</Grid>
																				</Grid>
																			)
																		})}
																	</React.Fragment>
																) : (
																	<Grid item className=" pl-1 pr-1">
																		Žiadne voľné miesta
																	</Grid>
																)}
															</Grid>
														</Popover>

														<div
															className="xs-front-icon ml-1 pointer"
															onClick={() => this.removeFromQueue(order.event.event_id)}
														>
															<Tooltip
																title={this.props.intl.formatMessage({
																	id: "Common.label.removeFromQueue"
																})}
																placement="left"
															>
																<i className="fas fa-eraser redDanger"></i>
															</Tooltip>
														</div>
													</React.Fragment>
												)} */}
											</div>
										</div>
									)
								} else {
									if (isSafe(order) && isSafe(order.timeFrom) && isSafe(order.timeTo)) {
										let currHour = moment().hours()
										let currMinutes = moment().minutes()
										let fromHour = order.timeFrom.hours()
										let fromMinutes = order.timeFrom.minutes()
										let toHour = order.timeTo.hours()
										let toMinutes = order.timeTo.minutes()

										if (
											((fromHour < currHour || (fromHour == currHour && fromMinutes < currMinutes)) &&
												currHour < toHour) ||
											(currHour == toHour && currMinutes < toMinutes)
										) {
											this.inRow = i + 1
										}

										return (
											<div
												key={i}
												className={`xs-free-time ${moment(order.timeTo).isBefore(moment()) ? "" : "in-the-future"}`}
												onClick={() => this.openAddEventDialog(order.timeFrom, order.timeTo)}
											>
												<div className="xs-time-text">
													<div className="xs-time">
														{moment(order.timeFrom).format("HH:mm")} - {moment(order.timeTo).format("HH:mm")}
													</div>
													<div className="xs-text">
														<FormattedMessage id="Calendar.dailyView.freeTime" /> {order.freeTime}{" "}
														<FormattedMessage id="Calendar.dailyView.minute" />
													</div>
												</div>
												<div className="xs-add-event">
													<i className="xs-thumb-btn fas fa-thumbtack" />
													<FormattedMessage id="Calendar.dailyView.addEvent" />
												</div>
											</div>
										)
									}
								}
							})}
					</div>
					{CalendarStore.loadingEvents && (
						<div style={loadingStyle}>
							<div className="progressMessage">
								<CircularProgress size={50} />
							</div>
						</div>
					)}
				</div>
				{isSafe(CalendarStore.eventsData) && CalendarStore.eventsData.length > 5 && (
					<div
						data-tip
						data-for={expandTooltip}
						className="xs-full-screen"
						onClick={() => (CalendarStore.calendarToggledFull = !CalendarStore.calendarToggledFull)}
					>
						<i className={`fal fa-chevron-${CalendarStore.calendarToggledFull ? "up" : "down"} fa-lg"`} />
						<ReactTooltip id={expandTooltip}>
							<FormattedMessage id={`Common.label.${expandTooltip}`} />
						</ReactTooltip>
					</div>
				)}
			</div>
		)
	}
}
