"use strict"

import React from "react"
import {observer} from "mobx-react"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import {FormattedMessage} from "react-intl"

// import UI controls here
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import Grid from "@material-ui/core/Grid"

// definitions of form fields
import fields from "./OrgunitBankFields"

// actions
import GlobalStore from "../../../../../global/store/GlobalStore"
import OrgunitDetailStore from "../../../stores/OrgunitDetailStore"
import OrgunitBankStore from "../../../stores/OrgunitBankStore"
import {injectIntl} from "react-intl"

@injectIntl
@observer
export default class OrgunitBankContainer extends React.Component {
	constructor(props) {
		super(props)

		GlobalStore.refreshCodeLists(["bankAccountType"])

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				OrgunitBankStore.save(
					form,
					props.orgunitId,
					props.intl.formatMessage({id: "Patient.form.contactForm.saveMessage"})
				)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		OrgunitBankStore.loadBankTypes()
	}

	form

	onHandleRowClick = (dataRow) => {
		this.form.$("bank").value = dataRow.bankCode
		this.form.$("type").value = dataRow.typeCode
		this.form.$("prefix").value = dataRow.prefix
		this.form.$("number").value = dataRow.number
		this.form.$("check").value = dataRow.primary
		this.form.$("bankAccId").value = dataRow.delete

		OrgunitBankStore.editable = true
		OrgunitBankStore.isAdd = true
	}

	render() {
		return (
			<React.Fragment>
				<Grid container className="pa-8">
					<Grid item xs={12}>
						<XsTable
							config={{
								columnDefs: {
									bankName: {
										title: <FormattedMessage id="Common.label.bank" />,
										type: "string",
										design: {
											width: "15%",
											body: {
												className: (row) => (row.primary ? "xs-primary-contact" : null)
											}
										}
									},
									type: {
										title: <FormattedMessage id="Common.label.type" />,
										type: "string",
										design: {
											width: "15%"
										}
									},
									prefix: {
										title: <FormattedMessage id="Common.label.prefix" />,
										type: "string",
										design: {
											width: "15%"
										},
										sortable: true
									},
									number: {
										title: <FormattedMessage id="Common.label.number" />,
										design: {
											width: "15%"
										},
										type: "string"
									},
									delete: {
										title: "",
										type: "action",
										design: {
											width: "5%",
											body: {
												renderer: (props) => {
													return (
														<XsIconButton
															className="action-delete"
															icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
															onClick={() =>
																GlobalStore.openConfirmationDialog("xsDeleteOrgunitBankAcc", {bankAccId: props.value})
															}
														/>
													)
												}
											}
										}
									}
								},
								options: {
									reverseColor: true,
									hidePager: true,
									showCursor: true,
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										return {
											bankName: dataRow.treasurer.entity.name,
											bankCode: dataRow.treasurer._id,
											type: dataRow.type.name_ext,
											typeCode: dataRow.type.code,
											prefix: dataRow.prefix,
											number: dataRow.number,
											primary: dataRow.primary,
											delete: dataRow._id
										}
									}
								},
								dataSource: OrgunitDetailStore.orgunitDTO.get("banks")
							}}
						/>
					</Grid>
					<Grid item xs={12} className="pt-6">
						{OrgunitBankStore.isAdd ? (
							<form>
								<Grid container direction="row" align="center" spacing={8}>
									<Grid item xs={2}>
										<SearchSelect field={this.form.$("bank")} items={OrgunitBankStore.banks} required />
									</Grid>
									<Grid item xs={2}>
										<SearchSelect field={this.form.$("type")} items={GlobalStore.CL["bankAccountType"]} required />
									</Grid>
									<Grid item xs={2}>
										<XsInput field={this.form.$("prefix")} />
									</Grid>
									<Grid item xs={2}>
										<XsInput field={this.form.$("number")} />
									</Grid>
									<Grid item xs={1} className="mt-2">
										<XsCheckbox field={this.form.$("check")} />
									</Grid>
									<Grid item container justify="flex-end" alignItems="center" xs={3} spacing={8} className="mt-0">
										{OrgunitBankStore.editable ? (
											<Grid item>
												<XsButton
													className="xs-success"
													type="submit"
													onClick={this.form.onSubmit}
													text={<FormattedMessage id="Common.label.save" />}
													icon={<i className="fal fa-check fa-lg" />}
												/>
											</Grid>
										) : (
											<Grid item>
												<XsButton
													className="xs-success"
													type="submit"
													onClick={this.form.onSubmit}
													text={<FormattedMessage id="Common.label.add" />}
													icon={<i className="fal fa-check fa-lg" />}
												/>
											</Grid>
										)}
										<Grid item>
											<XsButton
												className="xs-danger xs-outline mr-2"
												onClick={() => {
													this.form.reset()
													OrgunitBankStore.editable = false
													OrgunitBankStore.isAdd = false
												}}
												text={<FormattedMessage id="Common.label.cancel" />}
												icon={<i className="fal fa-times fa-lg" />}
											/>
										</Grid>
									</Grid>
								</Grid>
							</form>
						) : (
							<Grid item className="pb-2 pt-2">
								<XsButton
									className="xs-primary xs-outline"
									icon={<i className="fal fa-plus fa-lg" />}
									text={<FormattedMessage id="Common.button.addAnother" />}
									onClick={() => {
										OrgunitBankStore.isAdd = true
										OrgunitBankStore.editable = false
									}}
								/>
							</Grid>
						)}
					</Grid>
				</Grid>
				<XsConfirmationDialog
					name="xsDeleteOrgunitBankAcc"
					text={<FormattedMessage id="Common.label.deleteBankAccMessage" />}
					type="danger"
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.label.deleteBankAcc"
					}}
					onConfirmation={() =>
						OrgunitBankStore.delete(GlobalStore.confirmationDialogParams.bankAccId, this.props.orgunitId)
					}
				/>
			</React.Fragment>
		)
	}
}
