/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				bank: {
					label: <FormattedMessage id="Common.label.bank" />,
					rules: "required|string"
				},
				type: {
					label: <FormattedMessage id="Common.label.type" />,
					rules: "required|string"
				},
				prefix: {
					label: <FormattedMessage id="Common.label.prefix" />
					// rules: "string"
				},
				number: {
					label: <FormattedMessage id="Common.label.number" />,
					rules: "required"
				},
				check: {
					type: "checkbox",
					label: <FormattedMessage id="Patient.form.contactForm.primary" />
				},
				bankAccId:{}
			}
		}
	}
}
