export default {
	load: {
		storeKey: "PatientPrescription",
		params: ["prescriptionId"],
		type: "EHR.API.JSON.Proxy.Recipe",
		flags: "CDL3"
	},
	scheme: {
		type_code: {
			field: "type_code"
		},
		recipe_type: {
			field: "prescriptionType"
		},
		repeats_type: {
			field: "repeatType"
		},
		product_id: {
			field: "productCode"
			//IPLdescription
			//drugProductCode
		},
		instructions_for_preparation: {
			field: "production"
		},
		instructions_for_use: {
			field: "useGuide"
		},
		supplement: {
			field: "supplement"
			//IPLdescription
			//drugSupplement
		},
		dose_every: {
			field: "everyHour"
		},
		dosage_type: {
			field: "dosage_type"
		},
		amount: {
			field: "packs"
		},
		dosage: {
			field: "dosage"
		},
		dose_unit: {
			field: "unit"
		},
		route: {
			field: "medicationRoute"
		},
		dose_periodicity: {
			field: "repeatingMoment"
		},
		duration_mins: {
			relation: "@days"
		},
		note: {
			field: "note"
		},
		transcription: {
			field: "ds"
		},
		flags: {
			field: "flags"
		},
		patient_id: {
			relation: {
				_type: "Entity.Data.SpecificRel",
				_id: "#patientId"
			}
		},
		doctor_id: {
			field: "doctor_id"
		},
		personnel_id: {
			field: "personnel_id"
		},
		provider_id: {
			field: "provider_id"
		},
		components: {
			relation: "@components"
		},
		dose_every_unit: {
			relation: "@doseEveryUnit"
		},
		record_diagnoses: {
			array: {
				load: (item) => item.active,
				descriptor: {
					_ref: false,
					_type: "EHR.Data.Record.Diagnosis",
					active: true,
					item_order: 1,
					life_threatening: false,
					type: "dgn"
				},
				scheme: {
					diagnosis_type: {
						object: {
							descriptor: {
								_id: "PRIMARY",
								_type: "EHR.CL.Record.DiagnosisType"
							}
						}
					},
					item: {
						object: {
							descriptor: {
								_type: "EHR.CL.Record.Diagnosis"
							},
							scheme: {
								_id: {
									field: "diagnosis"
								}
							}
						}
					}
				}
			}
		},
		repeats_number: {
			field: "maxRepeats"
		},
		sending_doctor_id: {
			field: "sendingDoctor"
		},
		sending_doctor_expertise_id: {
			field: "sendingDoctorExpertiseId"
		},
		sending_personnel_expertise_id: {
			field: "sendingDoctorPersonnelExpertiseId"
		},
		substituting_personnel_expertise_id: {
			field: "substitutingPersonnelExpertiseId"
		},
		sent_at: {
			field: "doctorDate"
		},
		sent_end_at: {
			field: "recommendationDateValidity"
		},
		hospitalization_discharge_date: {
			field: "hospitalizationDischargeDate"
		},
		permanent_disability: {
			field: "permanentDisability"
		},
		base_dose_unit: {
			field: "doseUnitId"
		},
		sending_provider_id: {
			field: "doctorOUPZS"
		},
		substituting_doctor_id: {
			field: "substitutingDoctor"
		},
		substituting_doctor_expertise_id: {
			field: "substitutingExpertiseId"
		},
		substituting_provider_id: {
			field: "substitutingDoctorOUPZS"
		},
		forced_save: {
			field: "forcedSave"
		},
		forced_save_reason: {
			field: "forcedSaveReason"
		},
		prohibited_drugs: {
			relation: "@prohibitedDrugs"
		},
		replacement_prohibited: {
			field: "replacementProhibited"
		},
		record_id: {
			relation: "@receptId"
		},
		on_exclusion: {
			field: "exeptionCheck"
		},
		patient_payment_reason: {
			field: "pacientPaysReason"
		},
		medical_device_description: {
			field: "medDevDescription"
		},
		new_born_on_parent_identifier: {
			field: "newBornOnParentIdentifier"
		},
		prescription_reason: {
			field: "prescriptionReason"
		},
		prescription_note: {
			field: "prescriptionNote"
		}
		// exclusion:{
		//   object:{
		//     descriptor:{
		//       _ref:false,
		//       _type: "EHR.Data.Medication.Exclusion",
		//     },
		//     scheme:{
		//       exclusion_insurer_id:{
		//         field:"exeptionId"
		//       },
		//       exclusion_number:{
		//         field:"exeptionNumber"
		//       },
		//       validity:{
		//         object:{
		//           // descriptor:{
		//           //   _ref:false,
		//           //   _type: "IXS.Entity.Data.Validity"
		//           // },
		//           scheme:{
		//             from:{
		//               field:"exeptionValidityFrom"
		//             },
		//             to:{
		//               field:"exeptionValidityTo"
		//             }
		//           }
		//         }
		//       },
		//       // validity_from:{
		//       //   field:""
		//       // },
		//       // validity_to:{
		//       //   field:""
		//       // },
		//       approved_quantity:{
		//         field:"exeptionAprovedAmount"
		//       },
		//       unspent_quantity:{
		//         field:"exeptionUnspentAmount"
		//       },
		//       price:{
		//         field:"exeptionPrice"
		//       },
		//     }
		//   }
		// }
	}
}
