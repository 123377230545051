//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import DeleteIcon from "@material-ui/icons/Clear"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"

import SignInLabDialogStore from "../../../stores/SignInLabDialogStore"

@observer
export default class SignInLabDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsSignInLab"
				open={SignInLabDialogStore.isOpen}
				onClose={() => SignInLabDialogStore.close()}
				onEscapeKeyDown={() => SignInLabDialogStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle>
					<div className="xs-base-header">
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.labs" />
						</div>
						<XsIconButton
							className="xs-dialog-header"
							icon={<DeleteIcon onClick={() => SignInLabDialogStore.close()} />}
						/>
					</div>
				</DialogTitle>
				<DialogContent>
					<div className="xs-base-dialog-body xs-signIn-lab-body">
						<p>
							<a href="https://alphalab.sk" rel="noopener noreferrer" target="_blank">
								Alpha Medical
							</a>
						</p>
						<p>
							<a href="https://www.laboratornadiagnostika.sk/klientska-zona" rel="noopener noreferrer" target="_blank">
								Medirex group
							</a>
						</p>
						<p>
							<a href="https://www.synlab.sk" rel="noopener noreferrer" target="_blank">
								SYNLAB
							</a>
						</p>
					</div>
				</DialogContent>
				<DialogActions>
					<div className="xs-dialog-btn-center">
						<XsButton
							className="xs-default xs-outline"
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => SignInLabDialogStore.close()}
						/>
					</div>
				</DialogActions>
			</Dialog>
		)
	}
}
