import React from "react"
import {observer} from "mobx-react"
import ReactTooltip from "react-tooltip"
import moment from "moment"
import {FormattedMessage, injectIntl} from "react-intl"
import GlobalStore from "../../../../../global/store/GlobalStore"
import PatientProblemsDialogStore from "../../../stores/PatientProblemsDialogStore"

@injectIntl
@observer
export default class Problem extends React.Component {
	constructor(props) {
		super(props)

		GlobalStore.refreshCodeLists(["severities"])

		this.state = {
			editProblem: false
		}
	}

	renderYears = (problemLengthYears) => {
		if (problemLengthYears === 1) {
			return <FormattedMessage id="PatientDetail.problems.length.oneYear" />
		} else if ([2, 3, 4].includes(problemLengthYears)) {
			return <FormattedMessage id="PatientDetail.problems.length.fewYears" />
		} else {
			return <FormattedMessage id="Common.label.years" />
		}
	}

	// updateDimensions = () => {
	//   ProblemsStore.setSymptomsRef(window.innerWidth)
	// }

	// componentDidUpdate = () => {
	//   window.addEventListener("resize", this.updateDimensions)
	// }

	renderMonths = (problemLengthMonths) => {
		if (problemLengthMonths === 1) {
			return <FormattedMessage id="PatientDetail.problems.length.oneMonth" />
		} else if ([2, 3, 4].includes(problemLengthMonths)) {
			return <FormattedMessage id="PatientDetail.problems.length.fewMonths" />
		} else {
			return <FormattedMessage id="PatientDetail.problems.length.otherMonths" />
		}
	}

	closeProblemForm = () => {
		this.setState({editProblem: false})
	}

	chipsColor = {
		Unknown: {
			chip: {backgroundColor: "white", border: "1px solid #64b5f6", fontSize: " 15px", fontWeight: 400},
			circle: {backgroundColor: "#64b5f6"}
		},
		Low: {
			chip: {backgroundColor: "white", border: "1px solid #0071ff", fontSize: " 15px", fontWeight: 400},
			circle: {backgroundColor: "#0071ff"}
		},
		Medium: {
			chip: {backgroundColor: "white", border: "1px solid #ffce00", fontSize: " 15px", fontWeight: 400},
			circle: {backgroundColor: "#ffce00"}
		},
		High: {
			chip: {backgroundColor: "white", border: "1px solid #ff9700", fontSize: " 15px", fontWeight: 400},
			circle: {backgroundColor: "#ff9700"}
		},
		Critical: {
			chip: {backgroundColor: "white", border: "1px solid #f44336", fontSize: " 15px", fontWeight: 400},
			circle: {backgroundColor: "#f44336"}
		}
	}

	render() {
		const {problem, index, totalLength, firstAge} = this.props
		const isGoingProblem = problem.age_to === null
		const intervalTo = problem.interval_to === null ? new Date() : problem.interval_to
		const problemTotalLenghtInMonths = moment(intervalTo).diff(problem.interval_from, "month")
		const problemLengthYears = Math.floor(problemTotalLenghtInMonths / 12)
		const problemLengthMonths = problemTotalLenghtInMonths - 12 * problemLengthYears
		const diffFromFirstProblem = problem.age_from - firstAge
		const leftBufferWidth = (diffFromFirstProblem / totalLength) * 100 + "%"
		const problemWidth = isGoingProblem ? "100%" : (problemLengthYears / totalLength) * 100 + "%"

		return (
			<div className="xs-problem-cover">
				<div className="xs-problem">
					<div className="xs-problem-info">
						<div className="xs-index">
							<div className={isGoingProblem ? "xs-circle xs-ongoing" : "xs-circle"}>{index}</div>
						</div>
						<div
							className="xs-symptom" /*ref={symptoms => ProblemsStore.setSymptomsRef(symptoms)}*/
							onClick={() =>
								isSafe(this.props.onClick) && typeof this.props.onClick === "function" ? this.props.onClick() : ""
							}
						>
							<div className="xs-problem-name">{problem.name}</div>
							<div className="xs-symptoms">
								{problem.symptoms.map((prob, idx) => {
									return <span key={idx}>{`${prob.description}${idx == problem.symptoms.length - 1 ? "" : ", "}`}</span>
								})}
							</div>
							{/* <XsChipForm
								ref={(chipForm) => (ProblemsStore.symptomChipsForm[index].form = chipForm)}
								configuration={{
									header: {
										chipWidth: 100,
										headerWidth: ((window.innerWidth - 350) / 100) * 27,
										chipsRenderer: (key, dataRow, clickHandler, width) => (
											<XsChip
												key={key}
												label={dataRow["description"]["value"]}
												style={{width: `${width}px`, overflow: "hidden", justifyContent: "left"}}
												onClick={(e) => clickHandler(false, e, key)}
											/>
										),
										addButtonRenderer: (clickHandler, count) => (
											<XsIconButton className="xs-default xs-outline" icon={count} onClick={clickHandler} />
										)
									},
									columns: [
										{
											name: "description",
											label: this.props.intl.formatMessage({id: "Patient.form.problemForm.symptom"}),
											width: 4,
											defaultFocus: true,
											validationRule: "required|string",
											renderer: (field) => <XsInput field={field} />
										},
										{
											name: "code",
											label: "Závažnosť",
											width: 3,
											defaultValue: "Unknown",
											validationRule: "required|string",
											renderer: (field) => <SearchSelect field={field} items={GlobalStore.CL["severities"]} required />
										}
									],
									options: {
										disabled: false,
										isFormMode: false,
										isModalMode: true,
										onFormOpen: () => {},
										onFormClose: () => {},
										onFormSave: (values) => {
											ProblemsStore.saveSymptoms(values, problem.record_id)
										}
									},
									data: ProblemsStore.symptomChipsForm[index].formData
								}}
							/> */}
						</div>
					</div>

					<div
						className="xs-problem-length"
						onClick={() =>
							isSafe(this.props.onClick) && typeof this.props.onClick === "function" ? this.props.onClick() : ""
						}
					>
						<div className="xs-left-buffer" style={{width: leftBufferWidth}} />
						<div className={isGoingProblem ? "xs-length xs-ongoing" : "xs-length"} style={{width: problemWidth}}>
							<div className="xs-left-label" data-tip data-for={`startProblemDuration_${index}`}>
								{problem.age_from}
							</div>
							<div className="xs-right-label">
								{isGoingProblem ? (
									<FormattedMessage id="PatientDetail.problems.length.onGoing" />
								) : (
									<span data-tip data-for={`endProblemDuration_${index}`}>
										{problem.age_to}
									</span>
								)}
							</div>
							<ReactTooltip id={`startProblemDuration_${index}`} place="bottom" effect="solid">
								{this.props.intl.formatMessage({id: "Common.label.startProblemDuration"}, {from: problem.age_from})}
							</ReactTooltip>
							<ReactTooltip id={`endProblemDuration_${index}`} place="bottom" effect="solid">
								{this.props.intl.formatMessage({id: "Common.label.endProblemDuration"}, {to: problem.age_to})}
							</ReactTooltip>
						</div>
						{isGoingProblem && <div className="xs-arrow" />}
					</div>

					<div
						className="xs-interval"
						onClick={() =>
							isSafe(this.props.onClick) && typeof this.props.onClick === "function" ? this.props.onClick() : ""
						}
					>
						{problemLengthMonths > 0 || problemLengthYears > 0 ? (
							<div>
								<span>
									<b>{problemLengthYears}</b> {this.renderYears(problemLengthYears)}
									<FormattedMessage id="PatientDetail.problems.length.and" />
								</span>
								<span>
									<b>{problemLengthMonths}</b> {this.renderMonths(problemLengthMonths)}
								</span>
							</div>
						) : (
							<div>
								<span>
									&lt; <FormattedMessage id="PatientDetail.problems.length.oneMonth" />
								</span>
							</div>
						)}
						<div className="xs-desc">
							<FormattedMessage id="PatientDetail.problems.length.label" />
						</div>
						<div className="xs-timestamp" />
					</div>

					{this.props.isDeletable && (
						<div className="xs-trash">
							<i
								className="fal fa-trash-alt fa-lg"
								onClick={() => (
									this.props.form.$("_id").set(problem.record_id),
									GlobalStore.openConfirmationDialog("xsDeletePatientProblem", {problem: this.props.problem}),
									(PatientProblemsDialogStore.nameOfDeleteProblem = problem.name)
								)}
							/>
						</div>
					)}
				</div>
			</div>
		)
	}
}
