//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import DeleteIcon from "@material-ui/icons/Clear"
import SmsNotificationStore from "../../../stores/SmsNotificationStore"
import renderHTML from "react-render-html"

@observer
export default class HealthCardDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			isNotEmpty(SmsNotificationStore.htmlContent) && (
				<Dialog
					id="xsMedicineCard"
					open={SmsNotificationStore.isOpenHealthCard}
					onClose={() => SmsNotificationStore.closeHealthCard()}
					disableBackdropClick={true}
					maxWidth={false}
				>
					<DialogTitle>
						<div className="nx-insurer-header">
							<div className="nx-header-title">
								<FormattedMessage id="PatientDetail.cards.patientInsuranceCard" />
							</div>
							<XsIconButton
								className="xs-dialog-header"
								icon={<DeleteIcon onClick={() => SmsNotificationStore.closeHealthCard()} />}
							/>
						</div>
					</DialogTitle>
					<DialogContent>
						<div className="nx-body">
							<div className="xs-html-content">
								{isEmpty(SmsNotificationStore.htmlContent) ? "" : renderHTML(SmsNotificationStore.htmlContent)}
							</div>
						</div>
					</DialogContent>
					<DialogActions>
						<div className="xs-button">
							<XsButton
								className="xs-default xs-outline"
								text={<FormattedMessage id="Common.label.ok" />}
								onClick={() => SmsNotificationStore.closeHealthCard()}
							/>
						</div>
					</DialogActions>
				</Dialog>
			)
		)
	}
}
