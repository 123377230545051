/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"

import moment from "moment"

export default {
	load() {
		return {
			fields: {
				validity_from: {
					value: moment()
						.startOf("month")
						.format("YYYY-MM-DD")
				},
				validity_to: {
					value: moment()
						.endOf("month")
						.format("YYYY-MM-DD")
				},
				identifier: {
					label: <FormattedMessage id="Patient.labels.IDNumber" />,
					rules: "string"
				},
				insurer: {
					label: <FormattedMessage id="Common.label.insurer" />
				}
			}
		}
	}
}
