//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import {Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import DrugInteractionsDialogStore from "../../../stores/DrugInteractionsDialogStore"
import renderHTML from "react-render-html"

@observer
export default class DrugInteractionsDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			isSafe(DrugInteractionsDialogStore.htmlContent) && (
				<Dialog
					id="xsDrugInteractions"
					open={DrugInteractionsDialogStore.isOpen}
					onClose={() => DrugInteractionsDialogStore.close()}
					//onEscapeKeyDown={() => DrugInteractionsDialogStore.close()}
					disableBackdropClick={true}
					maxWidth={false}
					className="xs-base-dialog"
				>
					<DialogTitle className="xs-info">
						<div className="xs-header">
							<div className="xs-header-icon">
								<i className="fal fa-check fa-2x"></i>
							</div>
							<div className="xs-header-title">
								<FormattedMessage id="Patient.drugInteraction.dialog.headerMessage" />
							</div>
						</div>
					</DialogTitle>
					<DialogContent className="xs-content">
						{isEmpty(DrugInteractionsDialogStore.htmlContent)
							? ""
							: renderHTML(DrugInteractionsDialogStore.htmlContent)}
					</DialogContent>
					<DialogActions className="xs-footer xs-space-between">
						<XsButton
							className="xs-default xs-outline action-cancel"
							icon={<i className="fal fa-times fa-lg"></i>}
							text={<FormattedMessage id="Common.form.canelSave" />}
							onClick={() => DrugInteractionsDialogStore.cancel()}
						/>
						<XsButton
							className="xs-info"
							icon={<i className="fal fa-check fa-lg action-ok"></i>}
							text={<FormattedMessage id="Common.label.ok" />}
							onClick={() => DrugInteractionsDialogStore.close()}
						/>
					</DialogActions>
				</Dialog>
			)
		)
	}
}
