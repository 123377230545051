import React from "react"
import {observer} from "mobx-react"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import UIStore from "../../../stores/UIStore"
import {FormattedMessage} from "react-intl"
// import XsTabs from "../../../../../global/ui/xsTabs/xsTabs"
import ProvidedServicesList from "./providedServicesList/providedServicesList"
import OpeningHoursForm from "./openingHoursForm/openingHoursForm"
// import OpeningHoursCalendar from "./openingHoursCalendar/openingHoursCalendar"
import CompaniesContainer from "./companies/companiesContainer"
import DrugsContainer from "./drugs/drugsContainer"
import DiagnosesContainer from "./diagnoses/diagnosesContainer"
import EnumeratorList from "./enumerators/enumeratorList"

import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"

@observer
export default class CodelistContainer extends React.Component {
	constructor(props) {
		super(props)
		UIStore.mainMenuTab = "admin"
		UIStore.adminclDetailTab = "ProvidedServices"
	}

	//Poskytované služby
	// getProvidedServices = () => {
	// 	return {
	// 		label: <FormattedMessage id="Admin.codelist.providedservices" />,
	// 		value: "ProvidedServices",
	// 		content: <ProvidedServicesList />
	// 	}
	// }

	// //Ordinačné hodiny
	// getOpeningHours = () => {
	// 	return {
	// 		label: <FormattedMessage id="Admin.codelist.openinghours" />,
	// 		value: "OpeningHours",
	// 		content: <OpeningHoursForm />
	// 	}
	// }

	// //Ordinačné hodiny - kalendár
	// getOpeningHoursCalendar = () => {
	// 	return {
	// 		label: <FormattedMessage id="Admin.codelist.openinghoursCalendar" />,
	// 		value: "OpeningHoursCalendar",
	// 		content: <OpeningHoursCalendar />
	// 	}
	// }

	// getCompanies = () => {
	// 	return {
	// 		label: <FormattedMessage id="Common.label.companies" />,
	// 		value: "companies",
	// 		content: <CompaniesContainer />
	// 	}
	// }

	// getDrugs = () => {
	// 	return {
	// 		label: <FormattedMessage id="Common.label.drugs" />,
	// 		value: "drugs",
	// 		content: <DrugsContainer />
	// 	}
	// }

	// getDiagnoses = () => {
	// 	return {
	// 		label: <FormattedMessage id="Common.label.diagnosis" />,
	// 		value: "diagnoses",
	// 		content: <DiagnosesContainer />
	// 	}
	// }

	// getEnumerators = () => {
	// 	return {
	// 		label: <FormattedMessage id="Common.menu.admin-cl" />,
	// 		value: "enumerators",
	// 		content: <EnumeratorList />
	// 	}
	// }

	handleChange = (event, newValue) => {
		UIStore.adminclDetailTab = newValue
	}

	render() {
		return (
			<Grid container direction="column" className="xs-codelist-container">
				<Paper className="xs-header" square={true} elevation={1}>
					<div className="xs-title">
						<FormattedMessage id="Common.menu.admin-cl" />
					</div>
				</Paper>
				<Tabs className="xs-codelist-tabs" value={false} onChange={this.handleChange} scrollable scrollButtons="on">
					<Tab
						value="ProvidedServices"
						className={UIStore.adminclDetailTab == "ProvidedServices" ? "xs-tab xs-active" : "xs-tab"}
						label={
							<span className="pr-3 pl-3">
								<FormattedMessage id="Admin.codelist.providedservices" />
							</span>
						}
					/>
					<Tab
						value="OpeningHours"
						className={UIStore.adminclDetailTab == "OpeningHours" ? "xs-tab xs-active" : "xs-tab"}
						label={
							<span className="pr-3 pl-3">
								<FormattedMessage id="Admin.codelist.openinghours" />
							</span>
						}
					/>
					{/* <Tab
						value="OpeningHoursCalendar"
						className={UIStore.adminclDetailTab == "OpeningHoursCalendar" ? "xs-tab xs-active" : "xs-tab"}
						label={
							<span className="pr-3 pl-3">
								<FormattedMessage id="Admin.codelist.openinghoursCalendar" />
							</span>
						}
					/> */}
					<Tab
						value="Companies"
						className={UIStore.adminclDetailTab == "Companies" ? "xs-tab xs-active" : "xs-tab"}
						label={
							<span className="pr-3 pl-3">
								<FormattedMessage id="Common.label.companies" />
							</span>
						}
					/>
					<Tab
						value="Drugs"
						className={UIStore.adminclDetailTab == "Drugs" ? "xs-tab xs-active" : "xs-tab"}
						label={
							<span className="pr-3 pl-3">
								<FormattedMessage id="Common.label.drugs" />
							</span>
						}
					/>
					<Tab
						value="Diagnoses"
						className={UIStore.adminclDetailTab == "Diagnoses" ? "xs-tab xs-active" : "xs-tab"}
						label={
							<span className="pr-3 pl-3">
								<FormattedMessage id="Common.label.diagnosis" />
							</span>
						}
					/>
					<Tab
						value="Enumerator"
						className={UIStore.adminclDetailTab == "Enumerator" ? "xs-tab xs-active" : "xs-tab"}
						label={
							<span className="pr-3 pl-3">
								<FormattedMessage id="Common.menu.admin-cl" />
							</span>
						}
					/>
				</Tabs>
				{UIStore.adminclDetailTab === "ProvidedServices" && <ProvidedServicesList />}
				{UIStore.adminclDetailTab === "OpeningHours" && <OpeningHoursForm />}
				{/* {UIStore.adminclDetailTab === "OpeningHoursCalendar" && <OpeningHoursCalendar />} */}
				{UIStore.adminclDetailTab === "Companies" && <CompaniesContainer />}
				{UIStore.adminclDetailTab === "Drugs" && <DrugsContainer />}
				{UIStore.adminclDetailTab === "Diagnoses" && <DiagnosesContainer />}
				{UIStore.adminclDetailTab === "Enumerator" && <EnumeratorList />}
				{/* {
					<XsTabs
						background="#f0f3f8"
						headerClassName="filled"
						scrollButtons={true}
						value={UIStore.adminclDetailTab}
						onChangeTab={(value) => (UIStore.adminclDetailTab = value)}
						ended={true}
						tabs={[
							this.getProvidedServices(),
							this.getOpeningHours(),
							this.getCompanies(),
							this.getDrugs(),
							this.getDiagnoses(),
							this.getEnumerators()
						]}
					/>
				} */}
			</Grid>
		)
	}
}
