"use strict"
import api from "../actions/api"
import {observable, action} from "mobx"
import {getSelectedOrgUnitID, printReport, getUserCompanyInfo} from "../../../global/helpers/actions"
import GlobalStore from "../../../global/store/GlobalStore"
import UIStore from "../stores/UIStore"
import InvoiceListStore from "./InvoiceListStore"

import moment from "moment"
import settings from "../../../global/config/settings"
import {getFilters} from "../../../global/helpers/api"

class InvoiceFormStore {
	@observable invoiceNumber = null
	@observable itemsChipsError = null
	@observable itemsChips = []
	@observable customers = []
	itemsChipsForm
	invoiceLoaded = false
	invoiceNewLoaded = false
	newInvoiceData = null
	onUnmount = null
	customerTemplate = null

	@observable filters = []

	@observable changedFilters = false
	formRef = null
	currFilters = {}

	@action isChangedFilter(formValues) {
		if (
			this.currFilters.customer != formValues.customer ||
			this.currFilters.invoice_number != formValues.invoice_number ||
			this.currFilters.actualOrgUnit != formValues.actualOrgUnit
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action getInvoiceFilters(form) {
		let formValues = form.values()
		this.currFilters = form.values()

		let filters = [
			{associated_column: "invoice_number", values: [{id_value: formValues.invoice_number}]},
			{associated_column: "customer", values: [{id_value: formValues.customer}]}
		]

		//Ak je zaškrtnuté posiela sa supplier id (aktuálne zvolené oddelenie)
		//Ak nie je zaškrtnuté posiela sa customer id
		if (formValues.actualOrgUnit) {
			filters.push({associated_column: "supplier", values: [{id_value: getSelectedOrgUnitID()}]})
		} else {
			filters.push({associated_column: "company_id", values: [{id_value: getUserCompanyInfo().id}]})
		}

		this.filters = filters
	}

	@action set(name, value) {
		this[name] = value
	}

	@action reset(resetForAnotherInvoice = false) {
		this.invoiceNumber = null
		this.itemsChipsForm = null
		this.itemsChipsError = null
		this.itemsChips = []
		this.customers = []
		this.invoice = null
		this.invoiceNewLoaded = false
		this.onUnmount = null
		this.customerTemplate = null
		if (!resetForAnotherInvoice) {
			this.newInvoiceData = null
			this.invoiceLoaded = false
		}
	}

	/**
  * Prednastaví polia faktúry + položky
  * Na vstupe objekt s jednotlivými poliami Form Fields 
  * + optional callBack a príznak či má triggernúť onChange na odberateľovi (vykoná príp. načítanie šablóny)
    {
      _triggerCustomOnChange: true,
      customer: 92,
      invoice_number: 123456789,
      items: [{
        itemName: "Nazov",
        itemUnitPrice: 23.45,
        itemQuantity: 1
        itemPrice: 23.45
      }]
    }
  */
	@action setNewInvoiceData(data, onUnmount) {
		UIStore.reportingZSDetailTab = "Invoices"
		UIStore.reportZSName = "Faktúry"
		InvoiceListStore.set("showForm", true)
		this.set("newInvoiceData", data)
		if (onUnmount) {
			this.onUnmount = onUnmount
		}
	}

	@action getInvoiceNumber(onSuccess) {
		const invRequest = {org_unit: getSelectedOrgUnitID()}
		api
			.getInvoiceNumber(invRequest)
			.call()
			.then((response) => {
				this.invoiceNumber = response.invoice_number
				if (onSuccess) {
					onSuccess()
				}
			})
	}

	@action loadCustomers() {
		const searchRequest = getFilters([`object_owner=${getUserCompanyInfo().object_owner}`])
		api
			.loadPartners(searchRequest)
			.call()
			.then((response) => {
				if (response.rows) {
					this.customers = response.rows.map((p) => p.org_unit)
				}
			})
	}

	@action loadInvoiceTemplate(selectedCustomer) {
		const filters = getFilters([`org_unit=${getSelectedOrgUnitID()}`, `customer=${selectedCustomer._id}`])
		api
			.getInvoicesTemplate(filters)
			.call()
			.then((response) => {
				if (response && response.rows.length > 0) {
					this.customerTemplate = response.rows.find((r) => r.active)
					GlobalStore.openConfirmationDialog("xsLoadTemplate")
				}
			})
	}

	@action saveForm(data, onSuccess) {
		let items = data.values()
		const providerID = getSelectedOrgUnitID()

		let saveRequest = {
			_ref: false,
			_type: "Fin.Data.Invoice",
			active: true,
			type: {
				_id: items.type,
				_type: "Fin.CL.InvoiceType"
			},
			currency_unit: {
				_id: items.currency_unit,
				_type: "IXS.CL.Data.CurrencyUnit"
			},
			delivery_date: moment.isMoment(items.delivery_date)
				? items.delivery_date.format(settings.DB_DATE_FORMAT)
				: items.delivery_date,
			description: items.description,
			due_date: moment.isMoment(items.due_date) ? items.due_date.format(settings.DB_DATE_FORMAT) : items.due_date,
			invoice_number: items.invoice_number,
			issue_date: moment.isMoment(items.issue_date)
				? items.issue_date.format(settings.DB_DATE_FORMAT)
				: items.issue_date,
			variable_symbol: items.variable_symbol,
			specific_symbol: items.specific_symbol,
			constant_symbol: items.constant_symbol,
			period: items.period,
			supplier: {
				_id: providerID,
				_type: "Entity.Data.OrgUnit"
			},
			total_cost: items.total_cost
		}

		if (
			isEmpty(items._id) && //nie je to update
			isNotEmpty(InvoiceListStore.selectedInvoiceID) &&
			(items.type == "debitnote" || items.type == "corrinvoice" || items.type == "creditnote")
		) {
			saveRequest["master_invoices"] = [
				{
					_id: "",
					_ref: false,
					_type: "Fin.Data.Invoice.InvoiceRelationship",
					active: true,
					master: {
						_id: InvoiceListStore.selectedInvoiceID,
						_type: "Fin.Data.Invoice"
					},
					type: "RELATED"
				}
			]
		}

		if (items.customer) {
			saveRequest["customer"] = {
				_id: items.customer,
				_type: "Entity.Data.OrgUnit"
			}
		} else {
			saveRequest["customer"] = null
		}

		//Customer data údaje
		saveRequest["customer_data"] = {
			name: items.customer_dataName,
			identifier: items.customer_dataIdentifier,
			tax_identification_number: items.customer_dataTax_identification_number,
			bank_account_plain: items.customer_dataBank_account_plain,
			company_description: items.customer_dataCompany_description,
			vat_identification_number: items.customer_dataVat_identification_number
		}

		//Customer address údaje
		saveRequest["customer_address"] = {
			city_plain: items.customer_addressCity_plain,
			zip_plain: items.customer_addressZip_plain,
			street_plain: items.customer_addressStreet_plain,
			house_number: items.customer_addressHouse_number
		}

		//Supplier data údaje
		saveRequest["supplier_data"] = {
			name: items.supplier_dataName,
			identifier: items.supplier_dataIdentifier,
			tax_identification_number: items.supplier_dataTax_identification_number,
			bank_account_plain: items.supplier_dataBank_account_plain,
			company_description: items.supplier_dataCompany_description,
			vat_identification_number: items.supplier_dataVat_identification_number
		}

		//Supplier address údaje
		saveRequest["supplier_address"] = {
			city_plain: items.supplier_addressCity_plain,
			zip_plain: items.supplier_addressZip_plain,
			street_plain: items.supplier_addressStreet_plain,
			house_number: items.supplier_addressHouse_number
		}

		//Konečný príjemca address údaje
		saveRequest["customer_branch_address"] = {
			description: items.endCustomer_dataName,
			city_plain: items.endCustomer_addressCity_plain,
			zip_plain: items.endCustomer_addressZip_plain,
			street_plain: items.endCustomer_addressStreet_plain,
			house_number: items.endCustomer_addressHouse_number
		}

		//Ak máme IDčko tak robíme update
		if (items._id) {
			saveRequest["_id"] = items._id
		}

		//Položky faktúry
		let itemsRequest = []
		const invItems = this.itemsChipsForm.getData()
		Object.keys(invItems).forEach((key, index) => {
			const item = invItems[key]
			itemsRequest.push({
				_ref: false,
				_type: "Fin.Data.InvoiceItem",
				active: true,
				description: item.itemName,
				quantity: item.itemQuantity,
				unit: {
					_id: "pc",
					_type: "IXS.CL.Data.Unit"
				},
				unit_price: item.itemUnitPrice,
				item_order: index,
				price: item.itemPrice
			})
		})

		saveRequest["items"] = itemsRequest

		api
			.saveInvoice(saveRequest)
			.call()
			.then((response) => {
				if (items.print) {
					this.printInvoice(response._id)
				}

				//Uloženie PDF faktúry do attachments
				if (response._id) {
					this.printInvoice(response._id, (content) => {
						this.savePrintPDFToAttachment(content, response._id, items)
					})
				}

				if (response._id && onSuccess) {
					onSuccess()
				}

				//Resetovanie
				UIStore.isFormSaving = false
				GlobalStore.setNotificationMessage("Invoice.form.savesuccess")
				InvoiceListStore.set("showForm", false)
				InvoiceListStore.set("selectedInvoiceID", null)
			})
	}

	savePrintPDFToAttachment = (content, invoiceId, items) => {
		const reader = new FileReader()
		reader.onload = async function() {
			if (this.result) {
				const content = this.result.split(",")
				if (content.length > 0) {
					if (isNotEmpty(items.attachmentIds)) {
						const ids = JSON.parse(items.attachmentIds)
						for (let id in ids) {
							let data = {
								_id: ids[id],
								active: false
							}
							await api.saveAttachment(data).call()
						}
					}

					const attRequest = {
						_ref: false,
						_type: "IXS.Data.Attachment",
						active: true,
						rel_class_name: "Fin.Data.Invoice",
						rel_object_id: invoiceId,
						content_type: "application/pdf",
						content: content[1],
						type: "pdf"
					}
					api.saveAttachment(attRequest).call()
				}
			}
		}
		reader.readAsDataURL(content)
	}

	//Upraví cenu podľa nasledujúcich pravidiel
	//1. Pridá oddelovač tisícov
	//2. Vymení oddelovač desatinných miest
	//3. Zobrazí zadaný počet desatinných miest
	getLocalizedPrice(price, numberOfdecimals = 2) {
		return parseFloat(price).toLocaleString(undefined, {
			minimumFractionDigits: numberOfdecimals,
			maximumFractionDigits: numberOfdecimals
		})
	}

	@action multiPrint(invoiceIds) {
		invoiceIds.forEach(async (id) => {
			await api
				.loadInvoice(id)
				.call()
				.then((invoiceData) => {
					const customer = invoiceData.customer
					const customerData = invoiceData.customer_data
					const supplierData = invoiceData.supplier_data
					const customerAddress = invoiceData.customer_address
					const supplierAddress = invoiceData.supplier_address
					const supplier = invoiceData.supplier
					const cba = invoiceData.customer_branch_address

					const primaryCusContact = customer && customer.contacts ? customer.contacts.find((c) => c.primary) : null
					const primarySupContact = supplier && supplier.contacts ? supplier.contacts.find((c) => c.primary) : null

					const curr = invoiceData.currency_unit ? invoiceData.currency_unit.name_ext : null

					let printValues = {
						due_date: invoiceData.due_date ? moment(invoiceData.due_date).format("DD.MM.YYYY") : null,
						delivery_date: invoiceData.delivery_date ? moment(invoiceData.delivery_date).format("DD.MM.YYYY") : null,
						issue_date: invoiceData.issue_date ? moment(invoiceData.issue_date).format("DD.MM.YYYY") : null,
						invoice_number: invoiceData.invoice_number,
						invoice_type: invoiceData.type ? invoiceData.type.name_ext : "",
						variable_symbol: invoiceData.variable_symbol,
						specific_symbol: invoiceData.specific_symbol ? invoiceData.specific_symbol : "",
						constant_symbol: invoiceData.constant_symbol ? invoiceData.constant_symbol : "",
						total_cost: this.getLocalizedPrice(invoiceData.total_cost),
						invoice_title: invoiceData.description ? invoiceData.description : "",
						curr: curr,
						items: "",
						cus_name: customerData.name,
						cus_ico: customerData.identifier,
						cus_dic: customerData.tax_identification_number,
						cus_icdph: customerData.vat_identification_number,
						cus_iban: customerData.bank_account_plain,
						cus_description: customerData.company_description,
						sup_name: supplierData.name,
						sup_ico: supplierData.identifier,
						sup_dic: supplierData.tax_identification_number,
						sup_icdph: supplierData.vat_identification_number,
						sup_iban: supplierData.bank_account_plain,
						sup_description: supplierData.company_description,
						customer_branch_address: cba
							? `<br/>Konečný príjemca/Fakturačná adresa:<br/>${cba.description}<br/>${cba.street_plain} ${cba.house_number}<br/>${cba.city_plain} ${cba.zip_plain}`
							: ""
					}

					//Kontakt odberateľ - Poisťovňa
					if (primaryCusContact) {
						printValues["cus_phone"] =
							primaryCusContact.phone || primaryCusContact.mobile
								? `Tel: ${primaryCusContact.phone ? primaryCusContact.phone : primaryCusContact.mobile}`
								: null
						printValues["cus_email"] = primaryCusContact.email ? `Email: ${primaryCusContact.email}` : null
					}

					//Adresa odberateľ - Poisťovňa
					if (customerAddress) {
						printValues["cus_street"] = `${customerAddress.street_plain} ${customerAddress.house_number}`
						printValues["cus_city"] = customerAddress.city_plain
						printValues["cus_zipcode"] = customerAddress.zip_plain
					}

					//Kontakt dodávateľ - OrgUnit
					if (primarySupContact) {
						printValues["sup_phone"] =
							primarySupContact.phone || primarySupContact.mobile
								? `Tel: ${primarySupContact.phone ? primarySupContact.phone : primarySupContact.mobile}`
								: null
						printValues["sup_email"] = primarySupContact.email ? `Email: ${primarySupContact.email}` : null
					}

					//Adresa dodávateľ - OrgUnit
					if (supplierAddress) {
						printValues["sup_street"] = `${supplierAddress.street_plain} ${supplierAddress.house_number}`
						printValues["sup_city"] = supplierAddress.city_plain
						printValues["sup_zipcode"] = supplierAddress.zip_plain
					}

					//Obdobie
					if (invoiceData.period) {
						printValues["period"] = `${invoiceData.period}`
					}

					//Položky faktúry
					if (invoiceData.items && invoiceData.items.length > 0) {
						invoiceData.items
							.sort((a, b) => a.item_order - b.item_order)
							.map((i) => {
								const unit_total_price = i.quantity * i.unit_price
								printValues.items += `<tr class="tr">
        <td class="tab" style="text-align:left;"><strong>${i.description}</strong></td>
        <td class="tab" style="text-align:right;">${i.quantity ? this.getLocalizedPrice(i.quantity, 4) : ""}</td>
        <td class="tab number">${i.unit_price ? this.getLocalizedPrice(i.unit_price, 4) : ""}</td>
        <td class="tab" style="text-align:right;white-space:nowrap;">${
					i.price
						? this.getLocalizedPrice(i.price, 2)
						: unit_total_price
						? this.getLocalizedPrice(unit_total_price, 2)
						: ""
				}</td>
        </tr>`
							})
					}

					printReport("invoice", printValues, null, null)
				})
		})

		InvoiceListStore.tableRef.state.checkboxValues = []
		InvoiceListStore.tableRef.refreshData()
		InvoiceListStore.checkedInvoces = []
	}

	@action printInvoice(invoiceID, streamCallback = null) {
		const dateFormat = "DD.MM.YYYY"
		api
			.loadInvoice(invoiceID)
			.call()
			.then((invoiceData) => {
				const customer = invoiceData.customer
				const customerData = invoiceData.customer_data
				const supplierData = invoiceData.supplier_data
				const customerAddress = invoiceData.customer_address
				const supplierAddress = invoiceData.supplier_address
				const supplier = invoiceData.supplier
				const cba = invoiceData.customer_branch_address

				const primaryCusContact = customer && customer.contacts ? customer.contacts.find((c) => c.primary) : null
				const primarySupContact = supplier && supplier.contacts ? supplier.contacts.find((c) => c.primary) : null

				const curr = invoiceData.currency_unit ? invoiceData.currency_unit.name_ext : null

				let printValues = {
					due_date: invoiceData.due_date ? moment(invoiceData.due_date).format(dateFormat) : null,
					delivery_date: invoiceData.delivery_date ? moment(invoiceData.delivery_date).format(dateFormat) : null,
					issue_date: invoiceData.issue_date ? moment(invoiceData.issue_date).format(dateFormat) : null,
					invoice_number: invoiceData.invoice_number,
					invoice_type: invoiceData.type ? invoiceData.type.name_ext : "",
					variable_symbol: invoiceData.variable_symbol,
					specific_symbol: invoiceData.specific_symbol ? invoiceData.specific_symbol : "",
					constant_symbol: invoiceData.constant_symbol ? invoiceData.constant_symbol : "",
					total_cost: this.getLocalizedPrice(invoiceData.total_cost),
					invoice_title: invoiceData.description ? invoiceData.description : "",
					curr: curr,
					items: "",
					cus_name: customerData.name,
					cus_ico: customerData.identifier,
					cus_dic: customerData.tax_identification_number,
					cus_icdph: customerData.vat_identification_number,
					cus_iban: customerData.bank_account_plain,
					cus_description: customerData.company_description,
					sup_name: supplierData.name,
					sup_ico: supplierData.identifier,
					sup_dic: supplierData.tax_identification_number,
					sup_icdph: supplierData.vat_identification_number,
					sup_iban: supplierData.bank_account_plain,
					sup_description: supplierData.company_description,
					customer_branch_address: cba
						? `<br/>Konečný príjemca/Fakturačná adresa:<br/>${cba.description}<br/>${cba.street_plain} ${cba.house_number}<br/>${cba.city_plain} ${cba.zip_plain}`
						: ""
				}

				//Kontakt odberateľ - Poisťovňa
				if (primaryCusContact) {
					printValues["cus_phone"] =
						primaryCusContact.phone || primaryCusContact.mobile
							? `Tel: ${primaryCusContact.phone ? primaryCusContact.phone : primaryCusContact.mobile}`
							: null
					printValues["cus_email"] = primaryCusContact.email ? `Email: ${primaryCusContact.email}` : null
				}

				//Adresa odberateľ - Poisťovňa
				if (customerAddress) {
					printValues["cus_street"] = `${customerAddress.street_plain} ${customerAddress.house_number}`
					printValues["cus_city"] = customerAddress.city_plain
					printValues["cus_zipcode"] = customerAddress.zip_plain
				}

				//Kontakt dodávateľ - OrgUnit
				if (primarySupContact) {
					printValues["sup_phone"] =
						primarySupContact.phone || primarySupContact.mobile
							? `Tel: ${primarySupContact.phone ? primarySupContact.phone : primarySupContact.mobile}`
							: null
					printValues["sup_email"] = primarySupContact.email ? `Email: ${primarySupContact.email}` : null
				}

				//Adresa dodávateľ - OrgUnit
				if (supplierAddress) {
					printValues["sup_street"] = `${supplierAddress.street_plain} ${supplierAddress.house_number}`
					printValues["sup_city"] = supplierAddress.city_plain
					printValues["sup_zipcode"] = supplierAddress.zip_plain
				}

				//Obdobie
				if (invoiceData.period) {
					printValues["period"] = `${invoiceData.period}`
				}

				//Položky faktúry
				if (invoiceData.items && invoiceData.items.length > 0) {
					invoiceData.items
						.sort((a, b) => a.item_order - b.item_order)
						.map((i) => {
							const unit_total_price = i.quantity * i.unit_price
							printValues.items += `<tr class="tr">
        <td class="tab" style="text-align:left;"><strong>${i.description}</strong></td>
        <td class="tab" style="text-align:right;">${i.quantity ? this.getLocalizedPrice(i.quantity, 4) : ""}</td>
        <td class="tab number">${i.unit_price ? this.getLocalizedPrice(i.unit_price, 4) : ""}</td>
        <td class="tab" style="text-align:right;white-space:nowrap;">${
					i.price
						? this.getLocalizedPrice(i.price, 2)
						: unit_total_price
						? this.getLocalizedPrice(unit_total_price, 2)
						: ""
				}</td>
        </tr>`
						})
				}

				printReport("invoice", printValues, null, streamCallback)
			})
	}

	@action saveCustomer(form) {
		const formVals = form.values()

		let req = {
			_ref: false,
			_type: "Fin.API.JSON.Proxy.InvoicingPartner",
			company_id: getUserCompanyInfo().id,
			description: formVals.customer_dataCompany_description,
			identifier: formVals.customer_dataIdentifier,
			name: formVals.customer_dataName,
			partner_id: null,
			tax_identification_number: formVals.customer_dataTax_identification_number,
			vat_identification_number: formVals.customer_dataVat_identification_number
		}

		if (
			isNotEmpty(formVals.customer_addressCity_plain) ||
			isNotEmpty(formVals.customer_addressHouse_number) ||
			isNotEmpty(formVals.customer_addressStreet_plain) ||
			isNotEmpty(formVals.customer_addressZip_plain)
		) {
			req["address"] = {
				_ref: false,
				_type: "Entity.Data.Address",
				active: true,
				city_plain: formVals.customer_addressCity_plain,
				house_number: formVals.customer_addressHouse_number,
				obj_city: null,
				obj_zip: null,
				primary: true,
				street_plain: formVals.customer_addressStreet_plain,
				zip_plain: formVals.customer_addressZip_plain
			}
		}
		if (isNotEmpty(formVals.customer_dataBank_account_plain)) {
			req["account_number"] = formVals.customer_dataBank_account_plain
		}

		api
			.saveInvoicePartner(req)
			.call()
			.then(() => {
				GlobalStore.setNotificationMessage("Invoice.form.savePartnerSucc")
			})
	}
}
var singleton = new InvoiceFormStore()
export default singleton
