import {observable, action} from "mobx"

class StornoDialogStore {
	@observable isOpen = false
	@observable recordId = null
	@observable reason = ""
	title = ""
	onConfirm = null

	@action open(id, title, onConfirm) {
		this.isOpen = true
		this.recordId = id
		this.title = title
		this.onConfirm = onConfirm
	}

	@action onHandleChange(value) {
		this.reason = value
	}

	@action close() {
		this.isOpen = false
		this.recordId = null
		this.reason = ""
	}
}

var singleton = new StornoDialogStore()
export default singleton
