import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "requests_additional_healthcare"
				},
				nazovSidloLiecebnehoZariadenia: {
					label: <FormattedMessage id="PrintTemplate.RequestsAdditionalHealthcare.nazovSidloLiecebnehoZariadenia" />,
					value: ""
				},
				anamneza: {
					label: <FormattedMessage id="Common.label.anamnes" />,
					value: ""
				},
				objektivneVysetrenie: {
					label: <FormattedMessage id="PrintTemplate.RequestsAdditionalHealthcare.objektivneVysetrenie" />,
					value: ""
				},
				zaveryOdbornychVysetreni: {
					label: <FormattedMessage id="PrintTemplate.RequestsAdditionalHealthcare.zaveryOdbornychVysetreni" />,
					value: ""
				},
				epikriza: {
					label: <FormattedMessage id="PrintTemplate.RequestsAdditionalHealthcare.epikriza" />,
					value: ""
				},
				diagnostickyZaver: {
					label: <FormattedMessage id="PrintTemplate.RequestsAdditionalHealthcare.diagnostickyZaver" />,
					value: ""
				},
				kod: {
					value: ""
				},
				kodID: {
					label: <FormattedMessage id="PrintTemplate.RequestsAdditionalHealthcare.kodID" />,
					value: ""
				},
				doterajsiaLiecba: {
					label: <FormattedMessage id="PrintTemplate.RequestsAdditionalHealthcare.doterajsiaLiecba" />,
					value: ""
				},
				udajOFajceni: {
					label: <FormattedMessage id="PrintTemplate.RequestsAdditionalHealthcare.udajOFajceni" />,
					value: ""
				},
				poistenecChodiaci: {
					label: <FormattedMessage id="PrintTemplate.RequestsAdditionalHealthcare.poistenecChodiaci" />,
					value: ""
				},
				predpokladaneObdobieLiecby: {
					label: <FormattedMessage id="PrintTemplate.RequestsAdditionalHealthcare.predpokladaneObdobieLiecby" />,
					value: ""
				},
				prilohy: {
					label: <FormattedMessage id="PrintTemplate.RequestsAdditionalHealthcare.prilohy" />,
					value: ""
				},
				datum: {
					label: <FormattedMessage id="PrintTemplate.RequestsAdditionalHealthcare.datum" />,
					value: moment(),
					type: "date"
				}
			}
		}
	}
}
