import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"

import fields from "./protocolEnteralNutritionFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsAutocomplete from "../../../../../../global/ui/xsInput/xsAutocomplete"
import XsAutocompleteLocal from "../../../../../../global/ui/xsInput/xsAutocompleteLocal"
import XsCheckbox from "../../../../../../global/ui/xsCheckbox/xsCheckbox"

import RegisterCacheStore from "../../../../../../global/store/RegistersCacheStore"
import api from "../../../../actions/api"

@injectIntl
@observer
export default class ProtocolEnteralNutritionTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		if (isNotEmpty(this.form.$("diagnosisWord").value) && this.form.$("diagnosisWord").value.length > 32) {
			this.form.$("diagnosisWord").value = this.form.$("diagnosisWord").value.slice(0, 30) + "..."
		}
	}

	render() {
		return (
			<div className="pa-4">
				<Grid container direction="column" align="top">
					<div style={{marginBottom: "20px", fontWeight: "bold", fontSize: "17px"}}>
						I. POTVRDENIE RIZIKA A VÝVOJA MALNUTRÍCIE
					</div>
					<div style={{marginTop: "20px", fontWeight: "bold"}}>Anamnéza: Skore - body (vhodné vyznačte)</div>

					<div style={{marginBottom: "10px", marginTop: "20px"}}>
						Nechcený pokles hmotnosti a) za mesiac o 5% (seniori o 3%) alebo pokles hmotnosti za 6 mesiacov o 10%
					</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("ana_weight_yes")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("ana_weight_no")} />
						</Grid>
					</Grid>

					<div style={{marginBottom: "10px", marginTop: "20px"}}>Znížený príjem o 35% z plnej dávky</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("ana_income_yes")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("ana_income_no")} />
						</Grid>
					</Grid>

					<div style={{marginTop: "20px", fontWeight: "bold"}}>Antropometrické vyšetrenie</div>

					<div style={{marginBottom: "10px", marginTop: "20px"}}>{`Chodiaci pacienti - muži (BMI < 19)`}</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("antro_walk_man_yes")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("antro_walk_man_no")} />
						</Grid>
					</Grid>

					<div style={{marginBottom: "10px", marginTop: "10px"}}>{`Chodiaci pacienti - ženy (BMI < 19)`}</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("antro_walk_woman_yes")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("antro_walk_woman_no")} />
						</Grid>
					</Grid>

					<div
						style={{marginBottom: "10px", marginTop: "10px"}}
					>{`Ležiaci pacienti - muži (Obvod dominantného ramena v cm < 26)`}</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("antro_lying_man_yes")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("antro_lying_man_no")} />
						</Grid>
					</Grid>

					<div
						style={{marginBottom: "10px", marginTop: "10px"}}
					>{`Ležiaci pacienti - ženy (Obvod dominantného ramena v cm < 24)`}</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("antro_lying_woman_yes")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("antro_lying_woman_no")} />
						</Grid>
					</Grid>

					<div
						style={{marginBottom: "10px", marginTop: "10px"}}
					>{`Dialyzovaní pacienti - muži (BCM - FTI a/alebo LTI pod pásmom optima)`}</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("antro_dial_man_yes")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("antro_dial_man_no")} />
						</Grid>
					</Grid>

					<div
						style={{marginBottom: "10px", marginTop: "10px"}}
					>{`Dialyzovaní pacienti - ženy (BCM - FTI a/alebo LTI pod pásmom optima)`}</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("antro_dial_woman_yes")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("antro_dial_woman_no")} />
						</Grid>
					</Grid>

					<div style={{marginBottom: "20px", marginTop: "20px", fontWeight: "bold"}}>
						{`Ak je skóre >= 1 pokračujte v časti II.`}
					</div>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={11}>
							<XsInput
								field={this.form.$("clinicalEvaluationState")}
								multiple
								rows={3}
								onChange={(val) => {
									if (val.length > 200) {
										this.form.$("clinicalEvaluationState").value = val.slice(0, 200)
									}
								}}
							/>
						</Grid>
						<Grid item xs={1} className="customCounter">
							{`${this.form.$("clinicalEvaluationState").value.length}/200`}
						</Grid>
					</Grid>

					<Grid item xs={12} className="xs-autocomplete-element">
						<XsAutocompleteLocal
							field={this.form.$("diagnosis")}
							minLengthForSearch="1"
							data={RegisterCacheStore.diagnosisRegister}
							inputRenderer={(obj) => {
								this.form.$("diagnosisCode").value = obj.code_ext
								const diagWord = obj.name_ext
									? obj.name_ext.length > 32
										? obj.name_ext.slice(0, 30) + "..."
										: obj.name_ext
									: ""
								this.form.$("diagnosisWord").value = diagWord
								return obj.code_ext + " " + obj.name_ext
							}}
							saveValue={(obj) => obj.code}
							modalConfig={(textValue, clickHandler) => {
								return {
									columnDefs: {
										code_ext: {
											title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
											type: "string",
											dbName: "code_ext",
											design: {
												width: "150px"
											},
											filter: {
												gridWidth: 3,
												defaultValue: "",
												renderElement: "input"
											}
										},
										name_ext: {
											title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
											type: "string",
											dbName: "search_column",
											filter: {
												gridWidth: 9,
												defaultValue: textValue,
												renderElement: "input"
											}
										}
									},
									options: {
										showCursor: true,
										onRowClick: (dataRow) => {
											clickHandler(dataRow)
										}
									}
								}
							}}
						/>
					</Grid>

					<div style={{marginBottom: "20px", marginTop: "30px", fontWeight: "bold", fontSize: "17px"}}>
						II. INDIKÁCIE AMBULANTNEJ ENTERÁLNEJ VÝŽIVY PRI POTVRDENÍ RIZIKA A VÝVOJA MALNUTRÍCIE
					</div>

					<div style={{marginBottom: "10px", marginTop: "20px"}}>1. Znížený príjem potravy</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("amb1_yes")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("amb1_no")} />
						</Grid>
					</Grid>

					<div style={{marginBottom: "10px", marginTop: "20px"}}>2. Poruchy pasáže</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("amb2_yes")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("amb2_no")} />
						</Grid>
					</Grid>

					<div style={{marginBottom: "10px", marginTop: "20px"}}>3. Zvýšené nutričné požiadavky</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("amb3_yes")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("amb3_no")} />
						</Grid>
					</Grid>

					<div style={{marginBottom: "10px", marginTop: "20px"}}>4. Zvýšené straty proteínov</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("amb4_yes")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("amb4_no")} />
						</Grid>
					</Grid>

					<div style={{marginBottom: "10px", marginTop: "20px"}}>5. Poruchy trávenia a vstrebávania</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("amb5_yes")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("amb5_no")} />
						</Grid>
					</Grid>

					<div style={{marginBottom: "10px", marginTop: "20px"}}>6. Poruchy metabolizmu</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("amb6_yes")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("amb6_no")} />
						</Grid>
					</Grid>

					<div style={{marginTop: "30px", fontWeight: "bold", fontSize: "17px"}}>FORMA ENTERÁLNEJ VÝŽIVY</div>

					<div style={{marginBottom: "10px", marginTop: "20px"}}>
						Nutričná podpora (je hradená maximálne do 675 kcal/deň)
					</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("nutritionSupport_yes")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("nutritionSupport_no")} />
						</Grid>
					</Grid>

					<div style={{marginBottom: "10px", marginTop: "20px"}}>
						Plná výživa nasogastrickou sondou alebo perkutánnou endoskopickou chirurgickou gastronómiou alebo jej
						unostómiou (je hradená maximálne do 2000 kcal/deň)
					</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("fullNutrition_yes")} />
						</Grid>
						<Grid item xs={3}>
							<XsCheckbox field={this.form.$("fullNutrition_no")} />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" className="mt-4">
						<Grid item xs={12} className="xs-autocomplete-element">
							<XsAutocomplete
								field={this.form.$("foodCodeInput")}
								minLengthForSearch="3"
								api={api.loadMedFood}
								clearText
								inputRenderer={(obj, menuItem) =>
									(menuItem && obj.has_limitation
										? "<span style=color:#FFB900>" + obj.code_ext + "</span>"
										: obj.code_ext) +
									" | " +
									(menuItem && obj.has_limitation
										? "<span style=color:#FFB900>" + obj.name_ext + "</span>"
										: obj.name_ext) +
									" | " +
									(obj.supplement ? obj.supplement : "")
								}
								saveValue={(obj) => obj.code}
								postAction={(value) => {
									this.form.$("foodCode").value = value.code_ext
									this.form.$("foodName").value = value.name_ext
								}}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.code_ext" />,
												type: "string",
												dbName: "code_ext"
											},
											name_ext: {
												title: <FormattedMessage id="Common.label.medFood" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 3,
													defaultValue: textValue,
													renderElement: "input"
												},
												sortable: true,
												design: {
													body: {
														className: (row) => (row.has_limitation ? "xs-amberWarning" : null)
													}
												}
											},
											supplement: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />,
												type: "string",
												dbName: "supplement"
											}
											// patient_payment: {
											// 	title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.patient_payment" />,
											// 	type: "string",
											// 	dbName: "patient_payment"
											// },
											// insurer_payment: {
											// 	title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.insurer_payment" />,
											// 	type: "string",
											// 	dbName: "insurer_payment"
											// }
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											},
											renderHeaderAsFirstRow: true
										}
									}
								}}
								onClear={() => {
									this.form.$("foodCode").value = ""
									this.form.$("foodName").value = ""
								}}
							/>
						</Grid>
					</Grid>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={10}>
							<XsInput field={this.form.$("dailyDosage")} />
						</Grid>
						<Grid item xs={2}>
							<XsInput field={this.form.$("packageNumber")} />
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8} style={{marginTop: "20px"}}>
						<Grid item xs={4}>
							<XsDateTimePicker field={this.form.$("date")} showTimeSelect={false} />
						</Grid>
					</Grid>
				</Grid>
			</div>
		)
	}
}
