//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import {Dialog, DialogContent, Grid} from "@material-ui/core"

// import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"

import ConsentFormStore from "../../../stores/ConsentFormStore"
import PrintTemplatesStore from "../../../stores/PrintTemplatesStore"

@observer
export default class ConsentTemplateDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsConsentTemplateDialog"
				open={ConsentFormStore.isOpenConsentTemplate}
				onClose={() => ConsentFormStore.closeConsentTemplate()}
				onEscapeKeyDown={() => ConsentFormStore.closeConsentTemplate()}
				// disableBackdropClick={true}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-600"
				}}
				maxWidth="md"
			>
				<DialogContent className="overflowYInherit pr-4 pl-4 pb-4 pt-8" style={{position: "relative"}}>
					{/* <Grid container justify="flex-end" className="mb-1">
						<Grid item>
							<XsIconButton
								icon={<i className="fal fa-times" />}
								onClick={() => ConsentFormStore.closeConsentTemplate()}
							/>
						</Grid>
					</Grid> */}
					<i
						className="fal fa-times fa-lg pointer"
						style={{position: "absolute", right: "15px", top: "10px"}}
						onClick={() => ConsentFormStore.closeConsentTemplate()}
					/>
					<Grid container direction="column" /*className="pb-2 pr-4 pl-3"*/>
						<Grid
							item
							className="pa-2 borderRadius-1 borderGreyLight pointer"
							onClick={() => {
								PrintTemplatesStore.open("agreement_health_care", PrintTemplatesStore.getCommonValues())
								ConsentFormStore.closeConsentTemplate()
							}}
						>
							<FormattedMessage id="PrintTemplate.AgreementHealthCare.Title" />
						</Grid>
						<Grid
							item
							className="mt-2 pa-2 borderRadius-1 borderGreyLight pointer"
							onClick={() => {
								ConsentFormStore.printConsent(ConsentFormStore.consentId)
								ConsentFormStore.closeConsentTemplate()
							}}
						>
							<FormattedMessage id="Common.label.agreementProvisionOfHealthCare" />
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		)
	}
}
