export default {
	load() {
		return {
			fields: {
				capitationsChecboxes: {},
				deliveriesChecboxes: {}
			}
		}
	}
}
