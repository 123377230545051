import React from "react"
import {FormattedMessage} from "react-intl"

export default {
	load() {
		return {
			fields: {
				orgUnitCheckboxes: {},
				invoiceNumber: {
					label: <FormattedMessage id="Invoice.form.number" />,
					rules: ""
				},
				allToDelivery: {
					label: <FormattedMessage id="Invoice.form.allToDelivery" />,
					type: "checkbox",
					value: true
				}
			}
		}
	}
}
