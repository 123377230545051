/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"
// import moment from "moment"

export default {
	load() {
		return {
			fields: {
				ico: {
					label: <FormattedMessage id="Common.label.ico" />,
					rules: "required|string"
				},
				name: {
					label: <FormattedMessage id="Common.label.name" />,
					rules: "string"
				},
				description: {
					label: <FormattedMessage id="Common.label.description" />,
					rules: "string"
				},
				short_name: {
					label: <FormattedMessage id="Common.label.shortName" />,
					rules: "string"
				},
				DIC: {
					label: <FormattedMessage id="Common.label.dic" />,
					rules: "string"
				},
				ICDPH: {
					label: <FormattedMessage id="Common.label.icdph" />,
					rules: "string"
				},
				companyId: {},
				code: {
					label: <FormattedMessage id="Orgunits.orgunit.form.code" />,
					rules: "required"
				},
				org_name: {
					label: <FormattedMessage id="Common.label.name" />,
					rules: "required"
				},
				superiorOJ: {
					label: <FormattedMessage id="Orgunits.orgunit.form.superiorOJ" />
				},
				OUPZScode: {
					label: <FormattedMessage id="Orgunits.orgunit.form.OUPZScode" />
				},
				JRUZid: {
					label: <FormattedMessage id="Orgunits.orgunit.form.JRUZid" />
				},
				expertise: {
					label: <FormattedMessage id="Orgunits.orgunit.form.expertise" />,
					rules: "required"
				},
				expertiseId: {}
			}
		}
	}
}
