//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import moment from "moment"

import {Grid} from "@material-ui/core"
import AddEventDialogStore from "../../stores/AddEventDialogStore"
import CalendarStore from "../../stores/CalendarStore"

@injectIntl
@observer
export default class AddEventSlots extends React.Component {
	constructor(props) {
		super(props)
	}

	onHandleClickSlot = (selectedDateTime) => {
		CalendarStore.selectedDay = moment(selectedDateTime)
		CalendarStore.inputDateTimeIsValid = moment(selectedDateTime).format("DD.MM.YYYY HH:mm")
		CalendarStore.selectedSlotDateTime = selectedDateTime

		delayedCallback(500, () => AddEventDialogStore.setSlots())
		// CalendarStore.loadPatientsOrder(
		// 	moment(selectedDateTime)
		// 		.startOf("day")
		// 		.utc()
		// 		.format(),
		// 	moment(selectedDateTime)
		// 		.endOf("day")
		// 		.utc()
		// 		.format()
		// )
	}

	render() {
		return (
			<div className="xs-free-slot">
				<Grid container className="pt-3 pb-3 pr-4 pl-4">
					<Grid item className="greyDark xs-bold">
						<FormattedMessage id="Calendar.addEvent.dialog.slotSubtitle" />
					</Grid>
				</Grid>
				<div className="xs-slots-scrollbar">
					{isSafe(AddEventDialogStore.slotsData) && (
						<div className="xs-slots">
							{AddEventDialogStore.slotsData.map((slot, idx) => {
								const minuteDiff = moment(slot.interval_to).diff(slot.interval_from, "minutes")
								return (
									<div
										key={idx}
										data-interval-duration={minuteDiff}
										className={`xs-slot ${
											CalendarStore.selectedSlotDateTime === slot.interval_from ? "xs-active" : ""
										}`}
										onClick={() => this.onHandleClickSlot(slot.interval_from)}
									>
										<div className="xs-day">{moment(slot.interval_from).format("dddd, DD.MMMM")}</div>
										{/* <div className="xs-slot-date">{moment(slot.interval_from).format("")}</div> */}
										<div className="xs-hour">
											{moment(slot.interval_from).format("HH:mm")} - {moment(slot.interval_to).format("HH:mm")}
										</div>
									</div>
								)
							})}
						</div>
					)}
				</div>
			</div>
		)
	}
}
