import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				tagType: {
					label: <FormattedMessage id="Patient.Header.Tags.Tag" />,
					rules: "required|string"
				}
			}
		}
	}
}
