"use strict"

import React from "react"
import {observer} from "mobx-react"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import dataBindings from "./orgunitDetailContactsBindings"
import {
	loadDataToFormByScheme,
	saveFormDataByScheme,
	deleteDataByScheme,
	insertFormDataByScheme,
	resetForm
} from "../../../../../global/helpers/bindings"
import {FormattedMessage} from "react-intl"

// import UI controls here
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import Grid from "@material-ui/core/Grid"

// definitions of form fields
import fields from "./orgunitDetailContactsFields"
import {isEmailValid, isPhoneNumberValid} from "../../../../../global/helpers/functions"

// actions
import GlobalStore from "../../../../../global/store/GlobalStore"
// import DataStore from "../../../stores/DataStore"
import OrgunitDetailStore from "../../../stores/OrgunitDetailStore"
import OrgunitDetailContactsStore from "../../../stores/OrgunitDetailContactsStore"
import {injectIntl} from "react-intl"

@injectIntl
@observer
export default class OrgunitDetailContact extends React.Component {
	constructor(props) {
		super(props)

		GlobalStore.refreshCodeLists(["contactTypes"])

		const hooks = {
			onSubmit(form) {
				if (isEmpty(form.$("phone").value) && isEmpty(form.$("mobile").value) && isEmpty(form.$("email").value)) {
					GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Patient.form.contactForm.emptyFormat"}))
					form.invalidate()
				} else {
					if (isNotEmpty(form.$("phone").value) && !isPhoneNumberValid(form.$("phone").value)) {
						GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Patient.form.contactForm.phoneFormat"}))
						form.invalidate()
					} else if (isNotEmpty(form.$("mobile").value) && !isPhoneNumberValid(form.$("mobile").value)) {
						GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Patient.form.contactForm.mobileFormat"}))
						form.invalidate()
					} else if (isNotEmpty(form.$("email").value) && !isEmailValid(form.$("email").value)) {
						GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Patient.form.contactForm.emailFormat"}))
						form.invalidate()
					}
				}
			},
			onSuccess(form) {
				if (OrgunitDetailContactsStore.editable) {
					saveFormDataByScheme(
						form,
						dataBindings,
						{
							specrel: {
								_type: "Entity.Data.OrgUnit",
								_id: props.orgunitId
							}
						},
						(res) => {
							if (isSafe(res)) {
								form.reset()
							}
							OrgunitDetailStore.loadOrgunitData(props.orgunitId)
						},
						false,
						"Patient.form.contactForm.updateMessage"
					)
					OrgunitDetailContactsStore.editable = false
				} else {
					insertFormDataByScheme(
						form,
						dataBindings,
						{
							specrel: {
								_type: "Entity.Data.OrgUnit",
								_id: props.orgunitId
							}
						},
						(res) => {
							if (isSafe(res)) {
								form.reset()
							}
							OrgunitDetailStore.loadOrgunitData(props.orgunitId)
						},
						false,
						"Patient.form.contactForm.saveMessage"
					)
				}
			},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		this.initForm()
	}

	initForm() {
		resetForm(this.form, dataBindings)
	}

	form

	onHandleRowClick = (dataRow) => {
		loadDataToFormByScheme(this.form, dataBindings, {contactId: dataRow.delete})

		OrgunitDetailContactsStore.editable = true
		OrgunitDetailContactsStore.isAdd = true
	}

	deleteContact = () => {
		deleteDataByScheme(
			dataBindings,
			GlobalStore.confirmationDialogParams.contactId,
			() => OrgunitDetailStore.loadOrgunitData(this.props.orgunitId),
			"Patient.form.contactForm.deleteMessage"
		)
		OrgunitDetailContactsStore.editable = false
		this.initForm()
	}

	render() {
		return (
			<React.Fragment>
				<Grid container className="pa-8">
					<Grid item xs={12}>
						<XsTable
							config={{
								columnDefs: {
									contact_type: {
										title: <FormattedMessage id="Patient.form.contactForm.typ" />,
										type: "string",
										design: {
											width: "150px",
											body: {
												className: (row) => (row.primary ? "xs-primary-contact" : null)
											}
										}
									},
									mobile: {
										title: <FormattedMessage id="Patient.form.contactForm.mobile" />,
										type: "string",
										design: {
											width: "175px"
										}
									},
									email: {
										title: <FormattedMessage id="Common.label.email" />,
										type: "string",
										design: {
											width: "300px"
										},
										sortable: true
									},
									phone: {
										title: <FormattedMessage id="Patient.form.contactForm.phone" />,
										design: {
											width: "175px"
										},
										type: "string"
									},
									web_page: {
										title: <FormattedMessage id="Common.label.web" />,
										design: {
											width: "150px"
										},
										type: "string"
									},
									description: {
										title: <FormattedMessage id="Common.label.description" />,
										type: "string"
									},
									delete: {
										title: "",
										type: "action",
										design: {
											width: "42px",
											body: {
												renderer: (props) => {
													return (
														<XsIconButton
															className="action-delete"
															icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
															onClick={() =>
																GlobalStore.openConfirmationDialog("xsDeletePatientContact", {contactId: props.value})
															}
														/>
													)
												}
											}
										}
									}
								},
								options: {
									reverseColor: true,
									hidePager: true,
									showCursor: true,
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										return {
											contact_type: isSafe(dataRow.contact_type) ? dataRow.contact_type.description : "",
											// "contact_type_code": isSafe(dataRow.contact_type) ? dataRow.contact_type.code : "",
											mobile: dataRow.mobile,
											description: dataRow.description,
											email: dataRow.email,
											phone: dataRow.phone,
											web_page: dataRow.web_page,
											delete: dataRow._id,
											primary: dataRow.primary
										}
									}
								},
								dataSource: OrgunitDetailStore.orgunitDTO.get("contacts")
							}}
						/>
					</Grid>
					<Grid item xs={12} className="pt-6">
						{OrgunitDetailContactsStore.isAdd ? (
							<form>
								<Grid container direction="row" align="center" spacing={8}>
									<Grid item xs={2}>
										<SearchSelect field={this.form.$("type")} items={GlobalStore.CL["contactTypes"]} required />
									</Grid>
									<Grid item xs={2}>
										<XsInput field={this.form.$("mobile")} />
									</Grid>
									<Grid item xs={2}>
										<XsInput field={this.form.$("email")} />
									</Grid>
									<Grid item xs={1}>
										<XsInput field={this.form.$("phone")} />
									</Grid>
									<Grid item xs={1}>
										<XsInput field={this.form.$("web_page")} />
									</Grid>
									<Grid item xs={1}>
										<XsInput field={this.form.$("description")} />
									</Grid>
									<Grid item xs={1} className="mt-2">
										<XsCheckbox field={this.form.$("check")} />
									</Grid>
									<Grid item container justify="flex-end" alignItems="center" xs={2} spacing={8} className="mt-0">
										{OrgunitDetailContactsStore.editable ? (
											<Grid item>
												<XsButton
													className="xs-success"
													type="submit"
													onClick={this.form.onSubmit}
													text={<FormattedMessage id="Common.label.save" />}
													icon={<i className="fal fa-check fa-lg" />}
												/>
											</Grid>
										) : (
											<Grid item>
												<XsButton
													className="xs-success"
													type="submit"
													onClick={this.form.onSubmit}
													text={<FormattedMessage id="Common.label.add" />}
													icon={<i className="fal fa-check fa-lg" />}
												/>
											</Grid>
										)}
										<Grid item>
											<XsButton
												className="xs-danger xs-outline"
												onClick={() => {
													this.initForm()
													OrgunitDetailContactsStore.editable = false
													OrgunitDetailContactsStore.isAdd = false
												}}
												text={<FormattedMessage id="Common.label.cancel" />}
												icon={<i className="fal fa-times fa-lg" />}
											/>
										</Grid>
									</Grid>
								</Grid>
							</form>
						) : (
							<Grid item className="pb-2 pt-2">
								<XsButton
									className="xs-primary xs-outline"
									icon={<i className="fal fa-plus fa-lg" />}
									text={<FormattedMessage id="Common.button.addAnother" />}
									onClick={() => {
										OrgunitDetailContactsStore.isAdd = true
										OrgunitDetailContactsStore.editable = false
									}}
								/>
							</Grid>
						)}
					</Grid>
					<XsConfirmationDialog
						name="xsDeletePatientContact"
						text={<FormattedMessage id="Patient.form.contactForm.confirmationMessage" />}
						type="danger"
						headerConfig={{
							text: "Common.label.warning",
							icon: <i className="fal fa-trash-alt fa-2x" />
						}}
						cancelBtn={{
							icon: <i className="fal fa-times fa-lg" />,
							text: "Common.btn.not.delete"
						}}
						confirmBtn={{
							icon: <i className="fal fa-trash-alt fa-lg" />,
							text: "Common.btn.contact.delete"
						}}
						onConfirmation={() => this.deleteContact()}
					/>
				</Grid>
			</React.Fragment>
		)
	}
}
