"use strict"
import api from "../../modules/ambulance/actions/api"
import codeListApi from "../../global/config/api"
import {observable, action} from "mobx"
import {get, set} from "idb-keyval"
// import {getUser} from "../helpers/actions"
import {codeListsConfig} from "../../global/config/codeLists"
import GlobaStore from "../../global/store/GlobalStore"
import {getFilters} from "../../global/helpers/api"
import {toJS} from "mobx"
import {getSelectedOrgUnitID} from "../helpers/actions"

class RegistersCacheStore {
	@observable registersLoaded = true
	registersLoading = false
	diagnosisRegister = []
	deliveriesRegister = []
	insurersRegister = []
	measureSetRegister = []

	@action async loadDiagnoses() {
		const diagnosisFromStorage = await get("diagnosis")
		let versions = await get("versions")
		this.diagnosisRegister = diagnosisFromStorage
		let reqFilters = {row_count_show: 1000000, row_offset: 1, row_count_full: 1000000}
		let diagnosisResponse = null

		if (isSafe(diagnosisFromStorage)) {
			if (isSafe(versions) && isNotEmpty(versions.diagnosisVersion)) {
				reqFilters.version = versions.diagnosisVersion
			}
		}

		try {
			diagnosisResponse = await api.loadDiagnosis(reqFilters).call()
		} catch (error) {
			diagnosisResponse = null
		}

		if (
			isSafe(diagnosisResponse) &&
			isSafe(diagnosisResponse.rows) &&
			Array.isArray(diagnosisResponse.rows) &&
			diagnosisResponse.rows.length
		) {
			this.diagnosisRegister = diagnosisResponse.rows
			versions.diagnosisVersion = diagnosisResponse.version
			await set("diagnosis", diagnosisResponse.rows)
			await set("versions", versions)
		} else if (isSafe(diagnosisFromStorage)) {
			this.diagnosisRegister = diagnosisFromStorage
		}
	}

	@action async loadDeliveries() {
		const val = await get("deliveries")
		this.deliveriesRegister = val
		if (isNotSafe(this.deliveriesRegister)) {
			let deliveriesRegister = null
			try {
				deliveriesRegister = await api
					.loadSpecificDelivery({row_count_show: 1000000, row_offset: 1, row_count_full: 1000000})
					.call()
			} catch (error) {
				deliveriesRegister = null
			}
			if (isSafe(deliveriesRegister) && isSafe(deliveriesRegister.rows)) {
				this.deliveriesRegister = deliveriesRegister.rows
				await set("deliveries", deliveriesRegister.rows)
			}
		}
		// this.registersLoaded = true
		// this.loadingRegisters = false
		// this.logLength()
	}

	@action async loadInsurers() {
		// const val = await get("insurers")
		// this.insurersRegister = val
		// if (isNotSafe(this.insurersRegister)) {
		let insurersRegister = null
		try {
			insurersRegister = await api
				.loadInsurers({row_count_show: 1000000, row_offset: 1, row_count_full: 1000000})
				.call()
		} catch (error) {
			insurersRegister = null
		}
		if (isSafe(insurersRegister) && isSafe(insurersRegister.rows)) {
			this.insurersRegister = insurersRegister.rows
			await set("insurers", insurersRegister.rows)
		}
		// }
	}

	@action async loadCodelists() {
		const codelistsFromStorage = await get("codeLists")
		let versions = await get("versions")

		if (isSafe(codelistsFromStorage) && isSafe(versions) && isSafe(versions.codelist)) {
			let versionsObj = {}
			const codelistVersions = versions.codelist
			GlobaStore.CL = codelistsFromStorage
			for (let clIndex in codeListsConfig) {
				let cl = codeListsConfig[clIndex]
				let codelistResponse = await codeListApi.loadCodeList(cl.uri, "POST", {version: codelistVersions[cl.name]})
				if (isSafe(codelistResponse) && isSafe(codelistResponse.rows)) {
					if (cl.name == "allergens") {
						GlobaStore.CL[cl.name] = codelistResponse.rows.sort((a, b) =>
							(isSafe(a.name_ext) ? a.name_ext : "").localeCompare(isSafe(b.name_ext) ? b.name_ext : "")
						)
					} else {
						GlobaStore.CL[cl.name] = codelistResponse.rows
					}
					versionsObj[cl.name] = codelistResponse.version
				} else {
					versionsObj[cl.name] = codelistVersions[cl.name]
				}
			}

			if (isSafe(versions)) {
				versions.codelist = versionsObj
			} else {
				versions = {codelist: versionsObj}
			}

			await set("codeLists", toJS(GlobaStore.CL))
			await set("versions", versions)
		} else {
			let versionsObj = {}
			for (let clIndex in codeListsConfig) {
				let cl = codeListsConfig[clIndex]
				let val = await codeListApi.loadCodeList(cl.uri)
				if (cl.name == "allergens") {
					GlobaStore.CL[cl.name] = val.rows.sort((a, b) =>
						(isSafe(a.name_ext) ? a.name_ext : "").localeCompare(isSafe(b.name_ext) ? b.name_ext : "")
					)
				} else {
					GlobaStore.CL[cl.name] = val.rows
				}
				versionsObj[cl.name] = val.version
			}

			if (isSafe(versions)) {
				versions.codelist = versionsObj
			} else {
				versions = {codelist: versionsObj}
			}

			await set("codeLists", toJS(GlobaStore.CL))
			await set("versions", versions)
		}
	}

	// Funkcia obsahuje getSelectedOrgUnitID cize sa musi volat po kazdej zmene orgunity
	@action async loadMeasuresSet() {
		// const val = await get("measureSet")
		// this.measureSetRegister = val
		// if (isNotSafe(this.measureSetRegister) || forceReload) {
		let measureSetRegister = null
		let filters = getFilters([`specific_rel=${getSelectedOrgUnitID()}`])
		filters = Object.assign({}, filters, {
			row_count_show: 1000000,
			row_offset: 1,
			row_count_full: 1000000
		})
		try {
			measureSetRegister = await api.loadMeasuresSet(filters).call()
		} catch (error) {
			measureSetRegister = null
		}
		if (isSafe(measureSetRegister) && isSafe(measureSetRegister.rows)) {
			this.measureSetRegister = measureSetRegister.rows
			await set("measureSet", measureSetRegister.rows)
		}
		// }
	}

	@action async checkRegistersTest() {
		let promise = new Promise((resolve) => {
			setTimeout(() => resolve(true), 2000)
		})
		let result = await promise
		return result
	}

	//Kontrola ci su loadnute v store data, v pripade ze je uzivatel na login obrazovke vymaze sa obsah IndexedDB a po logine zbehne kontrola a loadnu sa data z BE
	@action async checkRegisters(route) {
		if (route != "/" && route != "/login") {
			if (
				!this.registersLoading &&
				(Object.keys(GlobaStore.CL).length == 0 ||
					this.diagnosisRegister.length == 0 ||
					this.insurersRegister.length == 0)
			) {
				this.registersLoading = true
				this.registersLoaded = false

				if (Object.keys(GlobaStore.CL).length == 0) {
					//Load vsetkych CL do IndexedDB
					await this.loadCodelists()
				}
				if (
					(isSafe(this.diagnosisRegister) && this.diagnosisRegister.length == 0) ||
					isNotSafe(this.diagnosisRegister)
				) {
					//Load diagnoz
					await this.loadDiagnoses()
				}
				if ((isSafe(this.insurersRegister) && this.insurersRegister.length == 0) || isNotSafe(this.insurersRegister)) {
					//load poistovni
					await this.loadInsurers()
				}

				if (
					(isSafe(this.measureSetRegister) && this.measureSetRegister.length == 0) ||
					isNotSafe(this.insurersRegister)
				) {
					//load setu klinickych hodnot
					await this.loadMeasuresSet()
				}

				// if (this.deliveriesRegister.length == 0) {
				// 	//Load vykonov
				// 	await this.loadDeliveries()
				// }

				this.registersLoading = false
				this.registersLoaded = true
			}
		}
	}

	//Len na kontrolu pri debugovani
	@action logLength() {
		setTimeout(() => {
			logger("LENGHTS", this.diagnosisRegister.length)
		}, 100)
	}

	//Po zmene orgunity je potrebne refrshut nasldujuce cisleniky
	refreshCacheAfterOrgunitChange = () => {
		this.loadMeasuresSet(true)
	}

	//Vymaze ciselniky ktore nemaju verzie a teda musia byt resetovane po kazdom odhlaseni
	@action async clearRegisters() {
		this.registersLoading = true
		this.insurersRegister = []
		await set("insurers", null)
		this.measureSetRegister = []
		await set("measureSet", null)
		this.registersLoading = false
	}
}
var singleton = new RegistersCacheStore()
export default singleton
