import React from "react"
import {observer} from "mobx-react"
import ReactQuill from "react-quill"
import ReactTooltip from "react-tooltip"
import Select from "@material-ui/core/Select"
import Tooltip from "@material-ui/core/Tooltip"
import MenuItem from "@material-ui/core/MenuItem"
import StorageEx from "../../storage/storageEx"
import * as types from "../../config/constants"

import "react-quill/dist/quill.snow.css"
import "./xsTextArea.less"

// const textState = observable({
//   text: "",
//   onChangeText(value) {
//     textState.text = value;
//   }
// })
// export default observer(({
//   field,
//   // value = textState.text,
//   // onChange = textState.onChangeText(),

// }) => {

//   field.set("bindings", "QuillTextArea")
//   return (
//     <div>
//       <div className={"customLabel"}>{field.label}</div>
//       <ReactQuill theme="snow"
//         {...field.bind()}//value, onChange
//         value={field.value}
//       />
//     </div>
//   )
// });

@observer
export default class xsTextArea extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isValid: false,
			isFocused: false,
			rows: isNotEmpty(props.rows) ? +props.rows : 10
		}

		this.hideToolbar = props.hideToolbar || props.shrinkMode
		this.prevFocusElement = null
	}

	handleValidation = () => {
		if (
			isSafe(this.props.field) &&
			isNotEmpty(this.props.field.get("rules")) &&
			this.props.field.get("rules").includes("required")
		) {
			let minVal = 0
			let rules = this.props.field.get("rules")
			//Validacia pre rules napriklad "required|min:3" teda pole je validne az po 3 znakoch
			if (rules.includes("min")) {
				minVal = +rules.charAt(rules.search("min") + 4)
				minVal -= 1
			}
			let isValid = htmlToPlain(this.props.field.value).length > minVal ? true : false
			if (this.state.isValid !== isValid) {
				this.setState({isValid: isValid})
			}
		}
	}

	handleChange = (value, delta, source, editor) => {
		const {onChange, field, maxLength} = this.props
		let editorRef = isSafe(this.reactQuillRef) ? this.reactQuillRef.getEditor() : {}

		//Kontrola v prípade ak je prázdny riadok stripneme HTML
		if (this.htmlToPlain(value) === "") {
			value = ""
		}

		if (editor.getLength() - 1 <= +maxLength) {
			field.set("value", value)
			this.handleValidation()
			if (onChange) {
				onChange(value)
			}
			this.forceUpdate()
		} else {
			if (isSafe(editorRef.deleteText)) {
				editorRef.deleteText(+maxLength, 9e9)
			}
			this.forceUpdate()
		}
	}

	handleFocus = (arg) => {
		if (this.props.shrinkMode) {
			this.hideToolbar = false
			if (!this.state.isFocused) {
				this.setState({isFocused: true})
			}
		}

		if (isSafe(this.props.onFocus) && typeof this.props.onFocus === "function") {
			if (arg == "div" && this.prevFocusElement == "quill") {
				const savedRange = this.reactQuillRef.getEditor().selection.savedRange
				const cursor = savedRange.index + savedRange.length
				this.props.onFocus({
					cursor: cursor
				})
			} else {
				// if (arg == "quill") {
				// 	const savedRange = this.reactQuillRef.getEditor().selection.savedRange
				// 	const cursor = savedRange.index + savedRange.length
				// 	this.props.onFocus({
				// 		cursor: cursor
				// 	})
				// } else {
				this.props.onFocus()
				// }
			}
		}
		this.prevFocusElement = arg

		// if (arg == "div") {
		// 	this.reactQuillRef.blur()
		// }
	}

	handleBlur = () => {
		if (this.props.shrinkMode) {
			this.hideToolbar = true
			if (this.state.isFocused) {
				this.setState({isFocused: false})
			}
		}

		if (isSafe(this.props.onBlur) && typeof this.props.onBlur === "function") {
			const savedRange = this.reactQuillRef.getEditor().selection.savedRange
			const cursor = savedRange.index + savedRange.length
			this.props.onBlur({
				cursor: cursor
			})
		}
	}

	toggleShrinkMode = (open) => {
		this.hideToolbar = !open
		this.setState({isFocused: open})
	}

	reactQuillRef

	focus = () => {
		this.reactQuillRef.focus()
	}

	htmlToPlain = (text) => {
		return text
			? String(text)
					.replace(/&nbsp;/gm, " ")
					.replace(/<p/gm, "\n<p")
					.replace(/<\/p/gm, "\n</p")
					.replace(/<div/gm, "\n<div")
					.replace(/<\/div/gm, "\n</div")
					.replace(/<br/gm, "\n<br")
					.replace(/<[^>]+>/gm, "")
					.replace(/\n\n/gm, "\n")
					.trim()
			: ""
	}
	// handleBlur = (previousRange, source, editor) => {
	//   const { onBlur } = this.props;

	//   if (onBlur) {
	//     onBlur(previousRange, source, editor, this.reactQuillRef.getEditor());
	//   }
	//   this.props.field.focus = false
	// };

	generateColorList = (className) => {
		return (
			<select className={className}>
				<option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)" />
				<option value="rgb(230, 0, 0)" label="rgb(230, 0, 0)" />
				<option value="rgb(255, 153, 0)" label="rgb(255, 153, 0)" />
				<option value="rgb(255, 255, 0)" label="rgb(255, 255, 0)" />
				<option value="rgb(0, 138, 0)" label="rgb(0, 138, 0)" />
				<option value="rgb(0, 102, 204)" label="rgb(0, 102, 204)" />
				<option value="rgb(153, 51, 255)" label="rgb(153, 51, 255)" />
				<option value="rgb(255, 255, 255)" label="rgb(255, 255, 255)" />
				<option value="rgb(250, 204, 204)" label="rgb(250, 204, 204)" />
				<option value="rgb(255, 235, 204)" label="rgb(255, 235, 204)" />
				<option value="rgb(255, 255, 204)" label="rgb(255, 255, 204)" />
				<option value="rgb(204, 232, 204)" label="rgb(204, 232, 204)" />
				<option value="rgb(204, 224, 245)" label="rgb(204, 224, 245)" />
				<option value="rgb(235, 214, 255)" label="rgb(235, 214, 255)" />
				<option value="rgb(187, 187, 187)" label="rgb(187, 187, 187)" />
				<option value="rgb(240, 102, 102)" label="rgb(240, 102, 102)" />
				<option value="rgb(255, 194, 102)" label="rgb(255, 194, 102)" />
				<option value="rgb(255, 255, 102)" label="rgb(255, 255, 102)" />
				<option value="rgb(102, 185, 102)" label="rgb(102, 185, 102)" />
				<option value="rgb(102, 163, 224)" label="rgb(102, 163, 224)" />
				<option value="rgb(194, 133, 255)" label="rgb(194, 133, 255)" />
				<option value="rgb(136, 136, 136)" label="rgb(136, 136, 136)" />
				<option value="rgb(161, 0, 0)" label="rgb(161, 0, 0)" />
				<option value="rgb(178, 107, 0)" label="rgb(178, 107, 0)" />
				<option value="rgb(178, 178, 0)" label="rgb(178, 178, 0)" />
				<option value="rgb(0, 97, 0)" label="rgb(0, 97, 0)" />
				<option value="rgb(0, 71, 178)" label="rgb(0, 71, 178)" />
				<option value="rgb(107, 36, 178)" label="rgb(107, 36, 178)" />
				<option value="rgb(68, 68, 68)" label="rgb(68, 68, 68)" />
				<option value="rgb(92, 0, 0)" label="rgb(92, 0, 0)" />
				<option value="rgb(102, 61, 0)" label="rgb(102, 61, 0)" />
				<option value="rgb(102, 102, 0)" label="rgb(102, 102, 0)" />
				<option value="rgb(0, 55, 0)" label="rgb(0, 55, 0)" />
				<option value="rgb(0, 41, 102)" label="rgb(0, 41, 102)" />
				<option value="rgb(61, 20, 102)" label="rgb(61, 20, 102)" />
			</select>
		)
	}

	renderToolbar = () => {
		const {field, customToolbar, customToolbarBmi, customToolbarHelpers} = this.props

		const identifier = field.name + (this.props.keyVal ? this.props.keyVal : "")
		const headingText = [
			// this.context.intl.formatMessage({ id: "Application.textarea.headings.small" }),
			// this.context.intl.formatMessage({ id: "Application.textarea.headings.normal" }),
			// this.context.intl.formatMessage({ id: "Application.textarea.headings.large" }),
			// this.context.intl.formatMessage({ id: "Application.textarea.headings.huge" })
			"small",
			"normal",
			"large",
			"huge"
		]
		//Warning: Use the `defaultValue` or `value` props on <select> instead of setting `selected` on <option>.
		//Zmena  <option selected="selected"></option> na value a defaultValue props
		return (
			<div
				className={"ql-text-area-custom-toolbar" + (identifier ? "-" + identifier : "")}
				style={this.hideToolbar ? {display: "none"} : null}
			>
				<select className="ql-size" defaultValue={""} onBlur={(e) => e.persist()}>
					<option value="small">{headingText[0]}</option>
					<option value="" />
					<option value="large">{headingText[2]}</option>
					<option value="huge">{headingText[3]}</option>
				</select>
				<select className="ql-align" defaultValue={""}>
					<option value="" />
					<option value="center" />
					<option value="right" />
					<option value="justify" />
				</select>
				<button className="ql-bold" />
				<button className="ql-italic" />
				<button className="ql-underline" />
				<button className="ql-strike" />
				<button className="ql-list" value="ordered" />
				<button className="ql-list" value="bullet" />
				<button className="ql-indent" value="-1" />
				<button className="ql-indent" value="+1" />
				{this.generateColorList("ql-background")}
				{this.generateColorList("ql-color")}

				{isNotEmpty(this.props.rowsId) && (
					<React.Fragment>
						<Tooltip title={"Nastavenie veľkosti"}>
							<Select
								value={this.state.rows.toString()}
								onChange={(e) => {
									const val = e.target.value.toString()
									this.changeDefaultSizeInStorage(val)
									this.setState({rows: val})
								}}
								className="xs-select-rows"
							>
								{Array.from(Array(21).keys()).map((val) => {
									return (
										<MenuItem key={val.toString()} value={val.toString()}>
											{val.toString()}
										</MenuItem>
									)
								})}
							</Select>
						</Tooltip>
					</React.Fragment>
				)}
				<React.Fragment>
					{customToolbarBmi}
					<div onClick={() => this.handleFocus("div")}>{customToolbarHelpers}</div>
					{customToolbar}
				</React.Fragment>
				<div style={{marginLeft: "auto"}}>
					{(isSafe(this.reactQuillRef) ? +this.reactQuillRef.getEditor().getLength() - 1 : "0") +
						"/" +
						this.props.maxLength}
				</div>
			</div>
		)
	}

	componentDidMount() {
		this.handleValidation()
		//Nastavenie tabIndexu pretoze prop tabIndex nefunguje https://github.com/zenoamaro/react-quill/issues/442
		if (
			isNotEmpty(this.props.tabindex) &&
			isSafe(this.reactQuillRef) &&
			isSafe(this.reactQuillRef.getEditor()) &&
			isSafe(this.reactQuillRef.getEditor().root)
		) {
			this.reactQuillRef.getEditor().root.tabIndex = this.props.tabindex
			delete this.reactQuillRef.getEditor().keyboard.bindings["9"]
		}

		if (isNotEmpty(this.props.rowsId)) {
			let storageObj = StorageEx.getByKey(types.TEXTAREASIZES) || "{}"
			storageObj = JSON.parse(storageObj)
			if (isNotEmpty(storageObj[this.props.rowsId])) {
				this.setState({rows: storageObj[this.props.rowsId]})
			}
		}
	}

	changeDefaultSizeInStorage = (value) => {
		if (isNotEmpty(this.props.rowsId)) {
			let storageObj = StorageEx.getByKey(types.TEXTAREASIZES) || "{}"
			storageObj = JSON.parse(storageObj)
			storageObj[this.props.rowsId] = value
			StorageEx.updateByKey(types.TEXTAREASIZES, storageObj)
		}
	}

	componentDidUpdate() {
		this.handleValidation()
		if (this.props.hideToolbar) {
			if (this.state.rows != 10) {
				this.setState({rows: 10})
			}
		} else {
			if (isNotEmpty(this.props.rowsId)) {
				let storageObj = StorageEx.getByKey(types.TEXTAREASIZES) || "{}"
				storageObj = JSON.parse(storageObj)
				if (isNotEmpty(storageObj[this.props.rowsId]) && storageObj[this.props.rowsId] != this.state.rows) {
					this.setState({rows: storageObj[this.props.rowsId]})
				}
			}
		}
	}

	render() {
		const {
			disabled,
			field,
			autofocus = false,
			style,
			showNoLabel = false,
			className,
			onChangeSelection,
			hideToolbar,
			shrinkMode
		} = this.props
		this.hideToolbar = hideToolbar || shrinkMode
		let requiredClass =
			isSafe(field.get("rules")) && field.get("rules").includes("required") && !this.state.isValid
				? "xs-textArea-red"
				: ""
		const errorText = false

		const formats = [
			"size",
			"align",
			"bold",
			"italic",
			"underline",
			"strike",
			"blockquote",
			"list",
			"bullet",
			"indent",
			"link",
			"image",
			"color",
			"background"
		]
		const modules = {
			toolbar: {
				container:
					".ql-text-area-custom-toolbar" +
					(this.props.field.name ? "-" + this.props.field.name + (this.props.keyVal ? this.props.keyVal : "") : "")
			}
		}

		const bindedField = field.bind()
		const labelJSX =
			isSafe(field.get("rules")) && field.get("rules").includes("required") && !showNoLabel ? (
				<span className="xs-input-label xs-required">{bindedField.label} *</span>
			) : (
				<span className="xs-optional">{bindedField.label}</span>
			)

		if (autofocus && isSafe(this.reactQuillRef) && htmlToPlain(field.get("value")) === "") {
			this.reactQuillRef.focus()
		}

		let classes = this.props.shrinkMode
			? this.state.isFocused
				? `${requiredClass} ${className} xs-height-${this.state.rows}-rows`
				: `${requiredClass} ${isNotEmpty(this.props.shrinkModeClass) ? this.props.shrinkModeClass : "xs-height-50"}`
			: `${requiredClass} ${className} xs-height-${this.state.rows}-rows`
		return (
			<div id={this.props.field.name} className={this.props.white ? "xs-textarea-white" : ""}>
				<div className={"xs-textarea-label"}>{labelJSX} </div>
				{/* className={"customLabel"} */}
				{/* this.props.field.bind().label */}
				{this.renderToolbar()}
				<div data-tip data-for={"xsTextArea" + field.id} data-multiline={true}>
					<ReactQuill
						ref={(el) => (this.reactQuillRef = el)}
						{...this.props.field.bind({
							onFocus: (e) => {
								this.props.field.onFocus(e)
								this.handleFocus("quill")
							},
							onBlur: (e) => {
								this.props.field.onBlur(e)
								this.handleBlur()
							}
						})}
						// value={this.props.field.value}

						// onChangeSelection={(range, source, editor) => {
						//   if (onSelectedText) {
						//     const selection = editor.getSelection();
						//     const selectedContent = editor.getContents(selection.index, selection.length);
						//     const tempContainer = document.createElement("div");
						//     const tempQuill = new Quill(tempContainer);
						//     tempQuill.setContents(selectedContent);
						//     onSelectedText(tempContainer.querySelector(".ql-editor").innerHTML);
						//   }
						// }}
						readOnly={disabled}
						onChange={(value, delta, source, editor) => {
							this.handleChange(value, delta, source, editor)
						}}
						onChangeSelection={
							onChangeSelection && typeof onChangeSelection == "function"
								? (range, source, editor) => onChangeSelection(range, source, editor)
								: undefined
						}
						modules={modules}
						formats={formats}
						style={style}
						// className={`${requiredClass} ${className}`}
						className={classes}
					/>
				</div>
				{errorText && (
					<div className="errorText" style={{paddingTop: "5px"}}>
						{errorText}
					</div>
				)}
				{isNotEmpty(this.props.tooltip) && (
					<ReactTooltip data-multiline={true} id={"xsTextArea" + field.id} effect="solid" place="left">
						{this.props.tooltip}
					</ReactTooltip>
				)}
			</div>
		)
	}
}

xsTextArea.defaultProps = {
	maxLength: 1000
}

// TextArea.contextTypes = {
//   intl: React.PropTypes.object.isRequired
// };
