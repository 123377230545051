import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import {/*Grid,*/ Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import RecordRePrintDialogStore from "../../../stores/RecordRePrintDialogStore"
import Reprint from "../../../../../global/ui/xsReprint/xsReprint"
import ambulanceApi from "../../../../ambulance/actions/api"
import {printMPDF} from "../../../../../global/helpers/actions"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import GlobalStore from "../../../../../global/store/GlobalStore"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import validatorjs from "validatorjs"
import MobxReactForm from "mobx-react-form"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

@injectIntl
@observer
export default class RecordRePrintDialog extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			showContent: false
		}

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					printAnamnesis: {
						label: <FormattedMessage id="Common.label.printWithAnamnesis" />,
						type: "checkbox",
						value: true
					},
					printHeader: {
						label: <FormattedMessage id="Common.label.printWithHeader" />,
						type: "checkbox",
						value: true
					},
					printWithDeliveries: {
						label: <FormattedMessage id="Common.label.printWithDeliveries" />,
						type: "checkbox",
						value: false
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)
	}

	componentDidMount() {
		const cfg = RecordRePrintDialogStore.config
		//Zmazanie anamnezy
		if (this.form.$("printAnamnesis").value == false) {
			cfg.requestData["anamnesis"] = ""
		}
		//Tlac hlavicky
		if (this.form.$("printHeader").value == true) {
			cfg.requestData["printHeader"] = "initial"
		} else {
			cfg.requestData["printHeader"] = "none"
		}
		//Tlac vykonov
		if (this.form.$("printWithDeliveries").value == true) {
			cfg.requestData["printWithDeliveries"] = "initial"
		} else {
			cfg.requestData["printWithDeliveries"] = "none"
		}

		if (
			isSafe(cfg) &&
			isSafe(cfg.requestData) &&
			isSafe(cfg.requestData.values) &&
			isNotEmpty(cfg.requestData.values["medication"])
		) {
			this.printRecord()
		} else {
			GlobalStore.openConfirmationDialog("printWithoutMedication")
		}
	}

	printRecord() {
		const cfg = RecordRePrintDialogStore.config
		printMPDF(cfg.streamCallback, cfg.requestData)
		this.setState({showContent: false})
		RecordRePrintDialogStore.close()
		this.form.reset()
	}

	render() {
		const cfg = RecordRePrintDialogStore.config

		return (
			<React.Fragment>
				<Dialog
					id="xsRecordRePrintDialog"
					//Dialog sa uz neotvara len v didmounte sa zavola print s default hodnotami
					open={RecordRePrintDialogStore.isOpen && 1 > 2}
					onClose={() => {
						this.setState({showContent: false})
						RecordRePrintDialogStore.close()
						this.form.reset()
					}}
					className="xs-base-dialog dialog-patientbloodtype"
					classes={{
						paper: "xs-paper-dialog xs-width-paper-450"
					}}
					disableBackdropClick={true}
					maxWidth="md"
				>
					<DialogTitle className="xs-info">
						<div className="xs-header">
							<div
								className="xs-absolute xs-close-btn"
								onClick={() => {
									this.setState({showContent: false})
									RecordRePrintDialogStore.close()
									this.form.reset()
								}}
							>
								<i className="fal fa-times fa-lg" />
							</div>
							<div className="xs-header-icon">
								<i className="fal fa-print fa-2x" />
							</div>
							<div className="xs-header-title">
								<FormattedMessage id="Common.lable.recordPrint" />
							</div>
							<div className="xs-header-subtitle">
								{/* <FormattedMessage id="Patient.sidebar.bloodType.subtitle" /> */}
							</div>
						</div>
					</DialogTitle>
					<DialogContent className="xs-overflow-visible">
						{this.state.showContent && (
							<div>
								<Reprint
									paper={{width: 210, height: 297, margins: {left: 10, top: 10}}}
									maxPrintHeight={250}
									suggestPosition={cfg.requestData["_paddingTop"]}
									width={500}
									height={400}
									onChange={(val) => (cfg.requestData["_paddingTop"] = val)}
								/>
							</div>
						)}
						<div style={{paddingLeft: "20px", paddingRight: "20px"}}>
							<XsCheckbox field={this.form.$("printAnamnesis")} />
							<XsCheckbox field={this.form.$("printHeader")} />
							<XsCheckbox field={this.form.$("printWithDeliveries")} />
						</div>
					</DialogContent>
					<DialogActions className="xs-footer xs-space-between">
						<XsButton
							className="xs-success mr-3"
							text={<FormattedMessage id="Common.lable.recordRePrint" />}
							onClick={() => {
								if (!this.state.showContent) {
									cfg.printIdentifier["template"] += "_plain"
									ambulanceApi
										.getPrintPosition(cfg.printIdentifier)
										.call()
										.then((data) => {
											cfg.requestData["_reprint"] = "T"
											//cfg.requestData["_withHeader"] = isNotSafe((data || {}).print_position_approved) ? "T" : "F"
											cfg.requestData["_paddingTop"] = (data || {}).print_position_approved || 0
											cfg.requestData["_template"] += "_plain"

											this.setState({showContent: true})

											this.form.$("printAnamnesis").value = false
											this.form.$("printWithDeliveries").value = false
										})
								} else {
									//Zmazanie anamnezy
									if (this.form.$("printAnamnesis").value == false) {
										cfg.requestData["anamnesis"] = ""
									}
									//Tlac hlavicky
									if (this.form.$("printHeader").value == true) {
										cfg.requestData["printHeader"] = "initial"
									} else {
										cfg.requestData["printHeader"] = "none"
									}
									//Tlac vykonov
									if (this.form.$("printWithDeliveries").value == true) {
										cfg.requestData["printWithDeliveries"] = "initial"
									} else {
										cfg.requestData["printWithDeliveries"] = "none"
									}

									logger("DATA", cfg.requestData)

									printMPDF(cfg.streamCallback, cfg.requestData)
									this.setState({showContent: false})
									RecordRePrintDialogStore.close()
									this.form.reset()
									GlobalStore.openConfirmationDialog("reprintSuccessConfirmation")
								}
							}}
						/>
						{!this.state.showContent && (
							<XsButton
								className="xs-success mr-3"
								text={<FormattedMessage id="Common.lable.print" />}
								onClick={() => {
									//Zmazanie anamnezy
									if (this.form.$("printAnamnesis").value == false) {
										cfg.requestData["anamnesis"] = ""
									}
									//Tlac hlavicky
									if (this.form.$("printHeader").value == true) {
										cfg.requestData["printHeader"] = "initial"
									} else {
										cfg.requestData["printHeader"] = "none"
									}
									//Tlac vykonov
									if (this.form.$("printWithDeliveries").value == true) {
										cfg.requestData["printWithDeliveries"] = "initial"
									} else {
										cfg.requestData["printWithDeliveries"] = "none"
									}
									printMPDF(cfg.streamCallback, cfg.requestData)
									this.setState({showContent: false})
									RecordRePrintDialogStore.close()
									this.form.reset()
								}}
							/>
						)}
					</DialogActions>
				</Dialog>
				<XsConfirmationDialog
					name={"reprintSuccessConfirmation"}
					text={<FormattedMessage id={"Common.lable.printSuccQ"} />}
					onConfirmation={() => ambulanceApi.aprovePrintPosition(cfg.printIdentifier).call()}
				/>
				<XsConfirmationDialog
					name={"printWithoutMedication"}
					type="warning"
					headerConfig={{
						icon: <i className="fal fa-exclamation-triangle fa-2x"></i>,
						text: "Najprv uložte pacientovi očkovací zaznam!",
						type: "warning"
					}}
					text={"Chcete pokračovať v tlači bez očkovacieho záznamu?"}
					onConfirmation={() => {
						RecordRePrintDialogStore.close()
					}}
					onCancel={() => {
						this.printRecord()
					}}
					//POZOR tlacidla su prehodene!!
					cancelBtn={{ //ANO
						text: "Common.label.yes",
						type: "warning"
					}}
					confirmBtn={{ // NIE
						text: "Common.label.no",
						type: "success"
					}}
				/>
			</React.Fragment>
		)
	}
}
