//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
//import moment from "moment"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import PrintTemplatesStore from "../../../stores/PrintTemplatesStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import StornoTemplateDialogStore from "../../../stores/StornoTemplateDialogStore"
import CovidResultStore from "../../../stores/CovidResultStore"
// import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"

import "./printTemplatesDialog.less"

@injectIntl
@observer
export default class PrintTemplatesDialog extends React.Component {
	constructor(props) {
		super(props)

		GlobalStore.refreshCodeLists(["indicativeGroup"])
	}

	render() {
		const templateObj = isSafe(PrintTemplatesStore.templateId)
			? PrintTemplatesStore.printTemplates.find((x) => x.id === PrintTemplatesStore.templateId)
			: null

		const isPrintablePaper = isSafe(templateObj) ? templateObj.isPrintable : false

		const hidePrint = isSafe(templateObj) ? templateObj.hidePrint : false
		const isMoreVersions = isSafe(templateObj) ? templateObj.isMoreVersions : false

		return (
			<Dialog
				id="xsPatientPrintTemplates"
				open={PrintTemplatesStore.isOpen}
				onClose={() => PrintTemplatesStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
				fullWidth
				className="xs-base-dialog"
			>
				<DialogTitle>
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-file-alt fa-2x" />
						</div>
						<div className="xs-header-title">{PrintTemplatesStore.title}</div>
					</div>
				</DialogTitle>

				<DialogContent className="xs-content">{PrintTemplatesStore.templateContent}</DialogContent>

				<DialogActions className="xs-footer xs-space-between">
					<div>
						<XsButton
							className="xs-default xs-outline"
							icon={<i className="fal fa-times fa-lg" />}
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => PrintTemplatesStore.close()}
						/>
						{PrintTemplatesStore.templateId == "corona" && isNotEmpty(PrintTemplatesStore.editedtemplateId) && (
							<XsButton
								className="xs-danger xs-outline ml-2"
								icon={<i className="fal fa-trash fa-lg" />}
								text={<FormattedMessage id="Common.label.storno" />}
								onClick={() => {
									PrintTemplatesStore.close()
									StornoTemplateDialogStore.open(PrintTemplatesStore.editedtemplateId)
									//this.onSave()
								}}
							/>
						)}
						{PrintTemplatesStore.templateId == "corona" && isNotEmpty(PrintTemplatesStore.editedtemplateId) && (
							<XsButton
								className="xs-default xs-outline ml-2"
								icon={<i className="fal fa-file-medical fa-lg" />}
								text={<FormattedMessage id="Common.label.covidResult" />}
								onClick={() => {
									CovidResultStore.open()
								}}
							/>
						)}
					</div>
					<div>
						{/* {(PrintTemplatesStore.templateId != "corona" || !isSafe(PrintTemplatesStore.editedtemplateId)) && ( */}
						<XsButton
							className="xs-success xs-outline"
							icon={<i className="fal fa-save fa-lg" />}
							text={<FormattedMessage id="Common.label.save" />}
							onClick={() => {
								PrintTemplatesStore.onSave(
									this.props.intl.formatMessage({id: "Application.moment.datetimeformat"}),
									this.props.intl.formatMessage({id: "Common.warning.message.emptyform"})
								)
							}}
						/>
						{/* )} */}
						{!hidePrint && (
							<XsButton
								className="xs-success ml-3"
								icon={<i className="fal fa-save fa-lg" />}
								text={<FormattedMessage id="Common.dialog.SaveAndPrint" />}
								onClick={() => {
									PrintTemplatesStore.onSaveAndPrint(
										this.props.intl.formatMessage({id: "Application.moment.datetimeformat"}),
										null,
										isMoreVersions,
										this.props.intl.formatMessage({id: "Common.warning.message.emptyform"})
									)
								}}
							/>
						)}
						{isPrintablePaper && (
							<XsButton
								className="xs-success ml-3"
								icon={<i className="fal fa-save fa-lg" />}
								text={<FormattedMessage id="Common.dialog.SaveAndPrintToTemplate" />}
								onClick={() => {
									PrintTemplatesStore.onSaveAndPrint(
										this.props.intl.formatMessage({id: "Application.moment.datetimeformat"}),
										true,
										null,
										this.props.intl.formatMessage({id: "Common.warning.message.emptyform"})
									)
								}}
							/>
						)}
					</div>
				</DialogActions>
			</Dialog>
		)
	}
}
