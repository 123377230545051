import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "request_for_magnetic_resonance_examination"
				},
				name: {},
				nameForm: {
					label: <FormattedMessage id="Common.label.fullName" />,
					rules: "required"
				},
				identifierForm: {
					label: <FormattedMessage id="Patient.labels.IDNumber" />,
					rules: "required"
				},
				identifier: {},
				address: {},
				addressForm: {
					label: "Adresa",
					rules: "required"
				},
				phoneForm: {
					label: "Tel. pacienta"
				},
				phone: {},
				insurerForm: {
					label: "Z.P.",
					rules: "required"
				},
				insurerCode: {},
				sendingMedicalDevice: {
					label: "Odosielajúce zdravotnícke zariadenie"
				},
				sendingDoctor: {
					label: "Odosielajúci lekár"
				},
				doctorCode: {},
				doctorName: {},
				orgunitCode: {
					label: "Kód oddelenia (ambulancie)"
				},
				orgunitaddress: {},
				orgunitcontact: {},
				orgunitcontactForm: {
					label: "Tel. kontakt"
				},
				clinicalDiagnosis: {
					label: "Klinická diagnóza"
				},
				diagnosisNameExt: {},
				diagnosisCodeExt: {},
				whichOrganHaveBeenExamination: {
					label: "Ktorý orgán (oblasť) má byť vyšetrený(á)"
				},
				question: {
					label: "Otázka, ktorá má byť MR vyšetrením zodpovedaná"
				},
				alergyAnamnesis: {
					label: "Alergická anamnéza"
				},
				oblickoveParameters: {
					label: "Obličkové parametre (funkčné, renálna insuficiencia, hladina sérového kreatinínu, močoviny)"
				},
				anamnesisNow: {
					label: "Anamnéza. terajšie ochorenie - epikríza, záver vyšetrení: RTG, USG, CT, MR"
				},
				pacientMobile: {
					label: "Pacient je imobilný",
					type: "checkbox",
					value: false
				},
				pacientMobile_inv: {type: "checkbox"},
				implants: {
					label: "Potvrďte MR kompatibilitu prípadných implantátov"
				},
				kontraindikacie: {
					label:
						"Uveďte prípadné kontraindikácie MR vyšetrenia: prítomnosť kovových implantátov a úlomkov. cievnych svoriek, umelých chlopní, elektród, graviditu pacientky a pod."
				},
				datetimeMRExamination: {
					label: "Termín MR vyšetrenia"
				},
				dateMRExamination: {},
				hourMRExamination: {},
				datetime: {
					label: "Dátum",
					value: moment()
				},
				datetimeFormat: {
					value: moment().format("DD.MM.YYYY")
				}
			}
		}
	}
}
