import {observable, action} from "mobx"
import {ApiCall} from "../../../global/helpers/api"

import EnumeratorListStore from "./EnumeratorListStore"
import GlobalStore from "../../../global/store/GlobalStore"

class EnumDetailDialogStore {
	@observable isOpen = false

	formDialogRef = undefined

	@observable data = {}

	@action open(dataRow) {
		this.isOpen = true
		this.data = dataRow

		this.formDialogRef.set({
			code: dataRow.code,
			code_ext: dataRow.code_ext,
			name_ext: dataRow.name_ext,
			description: dataRow.description
		})
	}

	@action save(type, message) {
		let formValues = this.formDialogRef.values()

		this.data.code = formValues.code
		this.data.code_ext = formValues.code_ext
		this.data.description = formValues.description
		this.data.name_ext = formValues.name_ext
		this.data["_type"] = type

		let putEnum = new ApiCall(`/cl/${type}`, "PUT", this.data)
		putEnum.call().then((res) => {
			if (isSafe(res)) {
				EnumeratorListStore.formEnumFilterRef.reset()
				EnumeratorListStore.loadCodeList()
				this.close()
				GlobalStore.setNotificationMessage(message)
			}
		})
	}

	@action close() {
		this.isOpen = false
		this.formDialogRef.reset()
	}
}
var singleton = new EnumDetailDialogStore()
export default singleton
