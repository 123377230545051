"use strict"

import api from "../../../modules/ambulance/actions/api"
import {action} from "mobx"
import moment from "moment"
import config from "../../../global/config/settings"
import actionCard from "../actions/patientCard"
import DataStore from "../stores/DataStore"
import UIStore from "../stores/UIStore"
import GlobalStore from "../../../global/store/GlobalStore"
import PatientOperationStore from "./PatientOperationStore"
import {getSelectedOrgUnitID, getSelectedOrgUnit, getUserDoctorInfo, printReport} from "../../../global/helpers/actions"
import {ixsIsEmpty} from "../../../global/helpers/functions"

class PatientAnamnesisStore {
	anamnesisForm_textAreaCursors = {}
	operationForm_textAreaCursors = {}

	textAreaAnamnesisRef = undefined
	saveAndCloseBtnRef = null

	// formdata = {}

	@action saveForm(data, patientID, providerID, personnelID, type, summaryType, onSuccess, draftOnly = false) {
		UIStore.isFormSaving = true
		let items = data.values()

		let customRecordTime = undefined
		if (type == "EHR.Data.OperatingProtocol" && isNotEmpty(items.examdate)) {
			customRecordTime = items.examdate
		}

		let anamnesisRequest = {
			_ref: false,
			_type: type,
			confidentiality_level: "STANDARD",
			version: {
				_type: "EHR.CL.Record.Version",
				_id: draftOnly ? "draft" : "final"
			},
			specific_rels: [
				{
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: patientID,
						_type: "Entity.Data.Client"
					},
					type: "pat"
				},
				{
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: personnelID,
						_type: "EHR.Data.Entity.Personnel"
					},
					type: "phy"
				},
				{
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: providerID,
						_type: "Entity.Data.OrgUnit"
					},
					type: "ou"
				}
			],
			summaries: [
				{
					_ref: false,
					_type: "EHR.Data.Record.Summary",
					content: items.anamnesis,
					type: summaryType
				}
			],
			recorded_events: [
				{
					_ref: false,
					_type: "EHR.Data.Record.Event",
					active: true,
					recorded_at: moment(customRecordTime).format(config.DB_DATETIME_FORMAT),
					type: {
						_id: "WRT",
						_type: "EHR.CL.Record.EventType"
					}
				}
			]
		}

		if (isNotEmpty(items.socialAnamnesis)) {
			anamnesisRequest.summaries.push({
				_ref: false,
				_type: "EHR.Data.Record.Summary",
				content: items.socialAnamnesis,
				type: "ANASOCIAL"
			})
		}

		if (isNotEmpty(items.deliveryAnamnesis)) {
			anamnesisRequest.summaries.push({
				_ref: false,
				_type: "EHR.Data.Record.Summary",
				content: items.deliveryAnamnesis,
				type: "ANAOBSTETRIC"
			})
		}

		if (isNotEmpty(items.deliveryDate)) {
			anamnesisRequest.supposed_delivery_date = moment(items.deliveryDate).format(config.DB_DATE_FORMAT)
		} else {
			anamnesisRequest.supposed_delivery_date = ""
		}

		if (isNotEmpty(items.id)) {
			anamnesisRequest["_id"] = items.id
		}

		if (isNotEmpty(items.recordId)) {
			anamnesisRequest["_id"] = items.recordId
		}

		if (isNotEmpty(items.wrt_recorded_event)) {
			const wrt = anamnesisRequest.recorded_events[0]
			wrt["_id"] = items.wrt_recorded_event
		}

		if (isNotEmpty(items.operationStartDate)) {
			anamnesisRequest.recorded_events.push({
				_ref: false,
				_type: "EHR.Data.Record.Event",
				active: true,
				recorded_at: moment(items.operationStartDate).format(config.DB_DATETIME_FORMAT),
				type: {
					_id: "SRGFROM",
					_type: "EHR.CL.Record.EventType"
				}
			})
		}

		if (isNotEmpty(items.operationEndDate)) {
			anamnesisRequest.recorded_events.push({
				_ref: false,
				_type: "EHR.Data.Record.Event",
				active: true,
				recorded_at: moment(items.operationEndDate).format(config.DB_DATETIME_FORMAT),
				type: {
					_id: "SRGTO",
					_type: "EHR.CL.Record.EventType"
				}
			})
		}

		if (isNotEmpty(items.operationRoomEntry)) {
			anamnesisRequest.recorded_events.push({
				_ref: false,
				_type: "EHR.Data.Record.Event",
				active: true,
				recorded_at: moment(items.operationRoomEntry).format(config.DB_DATETIME_FORMAT),
				type: {
					_id: "OROOMENTER",
					_type: "EHR.CL.Record.EventType"
				}
			})
		}

		if (isNotEmpty(items.operationRoomLeave)) {
			anamnesisRequest.recorded_events.push({
				_ref: false,
				_type: "EHR.Data.Record.Event",
				active: true,
				recorded_at: moment(items.operationRoomLeave).format(config.DB_DATETIME_FORMAT),
				type: {
					_id: "OROOMLEAVE",
					_type: "EHR.CL.Record.EventType"
				}
			})
		}

		anamnesisRequest["general_items"] = []

		//-----------Op. protokol----------
		if (isNotEmpty(items.diagnosisChipsForm)) {
			const diagnosis = JSON.parse(items.diagnosisChipsForm)
			diagnosis.map((dg, index) => {
				if (isSafe(dg) && dg.col1) {
					const newDg = {
						_ref: false,
						_type: "EHR.Data.Record.Diagnosis",
						item: {
							_id: dg.col1,
							_type: "EHR.CL.Record.Diagnosis"
						},
						type: "dgn",
						description: dg.col3,
						diagnosis_type: dg.col2,
						life_threatening: isSafe(dg.col4) ? dg.col4 : false,
						item_order: index
					}
					anamnesisRequest["general_items"].push(newDg)
				}
			})
		}
		if (isNotEmpty(items.operator_cl_expertise_id)) {
			anamnesisRequest["general_items"].push({
				item: {
					_id: items.operator_cl_expertise_id,
					_type: "EHR.CL.Expertise"
				},
				type: "phyexp",
				_ref: false,
				_type: "EHR.Data.Record.Expertise"
			})
		}
		if (isNotEmpty(items.anesthesiologist_cl_expertise_id)) {
			anamnesisRequest["general_items"].push({
				item: {
					_id: items.anesthesiologist_cl_expertise_id,
					_type: "EHR.CL.Expertise"
				},
				type: "anesthphyexp",
				_ref: false,
				_type: "EHR.Data.Record.Expertise"
			})
		}
		if (isNotEmpty(items.operating_theater)) {
			anamnesisRequest["operating_theater"] = {
				_id: items.operating_theater,
				_type: "EHR.Data.Entity.OperatingTheater"
			}
		}
		if (isNotEmpty(items.mechanical_ventilation_duration)) {
			anamnesisRequest["mechanical_ventilation_duration"] = items.mechanical_ventilation_duration
		}
		if (isNotEmpty(items.anesthesia_duration)) {
			anamnesisRequest["anesthesia_duration"] = items.anesthesia_duration
		}
		if (isNotEmpty(items.surgeon_preparation_duration)) {
			anamnesisRequest["surgeon_preparation_duration"] = items.surgeon_preparation_duration
		}
		if (isNotEmpty(items.instrumentalist_preparation_duration)) {
			anamnesisRequest["instrumentalist_preparation_duration"] = items.instrumentalist_preparation_duration
		}
		if (isNotEmpty(items.anesthesiologist_preparation_duration)) {
			anamnesisRequest["anesthesiologist_preparation_duration"] = items.anesthesiologist_preparation_duration
		}
		if (isNotEmpty(items.anesthesia_type)) {
			anamnesisRequest["anesthesia_type"] = items.anesthesia_type
		}
		if (isNotEmpty(items.surgery_type)) {
			anamnesisRequest["surgery_type"] = items.surgery_type
		}
		if (isNotEmpty(items.surgery_schedule_type)) {
			anamnesisRequest["surgery_schedule_type"] = items.surgery_schedule_type
		}
		if (isNotEmpty(items.surgery_perop_complications) || isNotEmpty(items.surgery_associated_complications)) {
			anamnesisRequest["surgery_complications"] = []

			if (isNotEmpty(items.surgery_perop_complications)) {
				anamnesisRequest.surgery_complications.push({
					_ref: false,
					_type: "EHR.Data.Record.SurgeryComplication",
					type: {_type: "EHR.CL.Record.SurgeryComplication", _id: items.surgery_perop_complications},
					scope: "Pe"
				})
			}
			if (isNotEmpty(items.surgery_associated_complications)) {
				anamnesisRequest.surgery_complications.push({
					_ref: false,
					_type: "EHR.Data.Record.SurgeryComplication",
					type: {_type: "EHR.CL.Record.SurgeryComplication", _id: items.surgery_associated_complications},
					scope: "As"
				})
			}
		}

		if (isNotEmpty(items.operator_personnel_id)) {
			anamnesisRequest.specific_rels.push({
				_ref: false,
				_type: "EHR.Data.Record.SpecificRel",
				specific_rel: {
					_id: items.operator_personnel_id,
					_type: "EHR.Data.Entity.Personnel"
				},
				type: "srgphy"
			})
		}
		if (isNotEmpty(items.instrumentalist)) {
			anamnesisRequest.specific_rels.push({
				_ref: false,
				_type: "EHR.Data.Record.SpecificRel",
				specific_rel: {
					_id: items.instrumentalist,
					_type: "EHR.Data.Entity.Personnel"
				},
				type: "srginstr"
			})
		}
		if (isNotEmpty(items.anesthesiologist_personnel_id)) {
			anamnesisRequest.specific_rels.push({
				_ref: false,
				_type: "EHR.Data.Record.SpecificRel",
				specific_rel: {
					_id: items.anesthesiologist_personnel_id,
					_type: "EHR.Data.Entity.Personnel"
				},
				type: "srganesth"
			})
		}
		if (isNotEmpty(items.surgery_procedure)) {
			anamnesisRequest["surgery_procedure"] = items.surgery_procedure
		}
		if (isNotEmpty(items.note)) {
			anamnesisRequest["_notes"] = {
				_id: isNotEmpty(items.noteId) ? items.noteId : "",
				_ref: false,
				_type: "IXS.Data.Note",
				type: "General",
				value: items.note
			}
		}

		api
			.saveAnamnesis(anamnesisRequest, patientID)
			.call()
			.then(async (response) => {
				data.$("id").value = response._id
				//Save vykonov
				if (summaryType == "OP") {
					await PatientOperationStore.handleSaveDeliveries(items, response._id)
				}
				// if (items.closeForm) {
				// 	data.reset()
				// }
				if (summaryType == "ANA") GlobalStore.setNotificationMessage("Patient.form.patientanamnesis.savesuccess")
				if (summaryType == "OP") GlobalStore.setNotificationMessage("Patient.form.patientOP.savesuccess")
				// if (items.closeForm) {
				// 	UIStore.patientCardTab = ""
				// }
				if (items.print) {
					this.printAnamnesis(items, DataStore.patientDTO.get("patient"), true)
				}
				if (items.printOperation) {
					PatientOperationStore.printOperation(response._id)
				}

				if (response._id && onSuccess) {
					setTimeout(() => {
						onSuccess()
					}, 200)
				}

				actionCard
					.loadPatientOverview(providerID, patientID, null, null, true)
					.then((overview) => {
						DataStore.setPatientEHR(overview)
						UIStore.isFormSaving = false
					})
					.catch(() => {
						UIStore.isFormSaving = false
					})
				window.scrollTo(0, 0)
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}

	@action async loadAnamData(form) {
		if (isNotEmpty(getSelectedOrgUnitID()) && isNotEmpty(DataStore.patientDTO.get("patientID"))) {
			const anamnesis = await api.loadAnnamnesis(getSelectedOrgUnitID(), DataStore.patientDTO.get("patientID")).call()
			// Ak neexistuje ziadna anamneza pre pacienta BE vrati prazdny response takze awiat tam vlozi header typu Response a vtedy sa nic nema loadovat
			if (!(anamnesis instanceof Response)) {
				DataStore.patientDTO.set("anamnesis", JSON.stringify(anamnesis))
				this.insertFormData(form, anamnesis)
			}
		}
	}

	@action insertFormData(form, responseData) {
		form
			.$("anamnesis")
			.set(
				"value",
				isNotEmpty(responseData) && isNotEmpty(responseData.data.find((i) => i.type._id == "ANA"))
					? responseData.data.find((i) => i.type._id == "ANA").content
					: ""
			)
		form
			.$("socialAnamnesis")
			.set(
				"value",
				isNotEmpty(responseData) && isNotEmpty(responseData.data.find((i) => i.type._id == "ANASOCIAL"))
					? responseData.data.find((i) => i.type._id == "ANASOCIAL").content
					: ""
			)
		form
			.$("deliveryAnamnesis")
			.set(
				"value",
				isNotEmpty(responseData) && isNotEmpty(responseData.data.find((i) => i.type._id == "ANAOBSTETRIC"))
					? responseData.data.find((i) => i.type._id == "ANAOBSTETRIC").content
					: ""
			)
		form.$("id").set("value", responseData.record_id)
		form.$("deliveryDate").set("value", responseData.supposed_delivery_date)
		form.$("wrt_recorded_event").set("value", responseData.wrt_recorded_event._id)
	}

	@action printAnamnesis(anamnesis, pacInfo, closeAndPrint = false) {
		UIStore.isFormSaving = true

		if (isNotEmpty(anamnesis)) {
			const doctorInfo = getUserDoctorInfo()
			const orgUnit = getSelectedOrgUnit()

			const pacAddress = pacInfo.validInfo.address
			let printAddress = ""
			if (pacInfo.validInfo && pacAddress) {
				printAddress += `${pacAddress.city ? `${pacAddress.city}, ` : ""}`
				printAddress += `${
					pacAddress.street
						? `${pacAddress.street}${pacAddress.house_number ? ` ${pacAddress.house_number}` : ""},`
						: ""
				}`
				printAddress += ` ${pacAddress.zip}`
			}

			let printValues = {
				name: `${pacInfo.full_name}`,
				identifier: pacInfo.identifier,
				address: pacInfo.validInfo && pacAddress ? printAddress : "",
				doctorcode: doctorInfo ? doctorInfo.code : "",
				doctorname: doctorInfo ? doctorInfo.name : ""
			}

			//OrgUnit
			if (orgUnit) {
				const primaryAddress = doctorInfo.address
				const businessContact = doctorInfo.contact
				const providerCode = orgUnit.identifiers.find((i) => i.type === "PROVIDERCODE")
				printValues["orgunitname"] = orgUnit.name_ext
				printValues["orgunitaddress"] = primaryAddress ? primaryAddress.full_address : ""
				if (businessContact) {
					printValues["orgunitemail"] = businessContact.email ? businessContact.email : ""
					printValues["orgunitcontact"] = `${businessContact.mobile ? businessContact.mobile : ""} ${
						businessContact.phone ? businessContact.phone : ""
					}`
				}
				printValues["orgunitcode"] = providerCode ? providerCode.value : ""
			}

			if (pacInfo.validInfo && pacInfo.validInfo.insurance) {
				printValues[
					"insurer"
				] = `${pacInfo.validInfo.insurance.insurer.code_ext} - ${pacInfo.validInfo.insurance.insurer.name_ext}`
			}

			if (closeAndPrint) {
				printValues["personAna"] = anamnesis.anamnesis ? `<b>Osobná anamnéza:</b><p>${anamnesis.anamnesis}</p>` : ""
				printValues["socialAna"] = anamnesis.socialAnamnesis
					? `<b>Sociálna anamnéza:</b><p>${anamnesis.socialAnamnesis}</p>`
					: ""
				printValues["obstetricAna"] = anamnesis.deliveryAnamnesis
					? `<b>Pôrodnícka anamnéza:</b><p>${anamnesis.deliveryAnamnesis}</p>`
					: ""
				printValues["obstetricDate"] = anamnesis.deliveryDate
					? `<b>Predpokladaný dátum pôrodu:</b><span>${moment(anamnesis.deliveryDate).format("DD.MM.YYYY")}</span>`
					: ""
			} else {
				if (isNotEmpty(anamnesis.data) && anamnesis.data.length > 0) {
					const tempPersonAna = anamnesis.data.find((i) => i.type._id == "ANA")
					const tempSocialAna = anamnesis.data.find((i) => i.type._id == "ANASOCIAL")
					const tempObstetricAna = anamnesis.data.find((i) => i.type._id == "ANAOBSTETRIC")

					const personAnaText =
						tempPersonAna && tempPersonAna.content && !ixsIsEmpty(tempPersonAna.content)
							? `${tempPersonAna.content}`
							: null
					const socialAnaText =
						tempSocialAna && tempSocialAna.content && !ixsIsEmpty(tempSocialAna.content)
							? `${tempSocialAna.content}`
							: null
					const obstetricAnaText =
						tempObstetricAna && tempObstetricAna.content && !ixsIsEmpty(tempObstetricAna.content)
							? `${tempObstetricAna.content}`
							: null

					const obstetricDate = anamnesis.supposed_delivery_date
						? moment(anamnesis.supposed_delivery_date).format("DD.MM.YYYY")
						: null

					printValues["personAna"] = personAnaText ? `<b>Osobná anamnéza:</b><p>${personAnaText}</p>` : ""
					printValues["socialAna"] = socialAnaText ? `<b>Sociálna anamnéza:</b><p>${socialAnaText}</p>` : ""
					printValues["obstetricAna"] = obstetricAnaText ? `<b>Pôrodnícka anamnéza:</b><p>${obstetricAnaText}</p>` : ""
					printValues["obstetricDate"] = obstetricDate
						? `<b>Predpokladaný dátum pôrodu:</b><span> ${obstetricDate}</span>`
						: ""
				}
			}

			UIStore.isFormSaving = false
			printReport("anamnesis", printValues)
		}
	}
}

var singleton = new PatientAnamnesisStore()
export default singleton
