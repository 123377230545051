//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import ReactTooltip from "react-tooltip"
import bindings from "../../../../global/ui/globalUISchemeBindings"

import {IconButton, InputAdornment, Grid} from "@material-ui/core"
import {getUser} from "../../../../global/helpers/actions"

import DeleteIcon from "@material-ui/icons/Clear"

// import XsDateTimePicker from "../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsSearchSelect from "../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsCheckbox from "../../../../global/ui/xsCheckbox/xsCheckbox"
import XsInput from "../../../../global/ui/xsInput/xsInput"
import XsIconButton from "../../../../global/ui/xsButton/xsIconButton"
import AddEventDialogStore from "../../stores/AddEventDialogStore"
import {StorageBase} from "../../../../global/storage/storageEx"
import {RESOURCES} from "../../../../global/config/constants"
import TourStore from "../../../../global/store/TourStore"
import UIStore from "../../stores/UIStore"

@injectIntl
@observer
export default class AddSearchPatient extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			isSaving: false
		}

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		const resourcesObj = StorageBase.getObjectByKey(RESOURCES)
		this.resources =
			isSafe(resourcesObj) && isSafe(resourcesObj[getUser().user._id]) ? resourcesObj[getUser().user._id] : null

		this.form = new MobxReactForm(
			{
				fields: {
					provider: {
						label: " ",
						value: isSafe(this.resources) && this.resources.length > 0 ? this.resources[0]._id : null
					},
					currOrgunit: {
						label: <FormattedMessage id="Patient.PatientList.Filter.Orgunit" />,
						type: "checkbox",
						value: true
					},
					search: {
						label: " ",
						placeholder: props.intl.formatMessage({id: "Calendar.addEvent.dialog.searchPlaceholder"})
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)

		AddEventDialogStore.searchFormRef = this.form
	}

	render() {
		return (
			<div className="xs-dialog-content xs-first-step xs-column">
				<div className="xs-header">
					<i className="xs-plus-icon fal fa-calendar-plus" />
					<XsIconButton className="xs-close-btn" icon={<DeleteIcon onClick={() => AddEventDialogStore.close()} />} />
				</div>
				<div className="xs-title">
					<FormattedMessage id="Calendar.addEvent.dialog.title" />
				</div>
				<div className="xs-subtitle">
					<FormattedMessage id="Calendar.addEvent.dialog.subtitle" />
				</div>
				<div className="xs-curr-orgunit xs-fullWidth">
					<Grid container alignContent="center" justify="center">
						{UIStore.calendarTimePeriod === "resources" && AddEventDialogStore.isOpenOverTheAddButton && (
							<Grid item xs>
								<XsSearchSelect
									white
									required
									hideLabel={true}
									field={this.form.$("provider")}
									items={isSafe(this.resources) ? this.resources : []}
									onChange={() => AddEventDialogStore.searchPatient()}
								/>
							</Grid>
						)}
						<Grid item xs className="pl-4 pr-4 textCenter">
							<XsCheckbox field={this.form.$("currOrgunit")} onChange={() => AddEventDialogStore.searchPatient()} />
						</Grid>
					</Grid>
				</div>
				<div className="xs-search">
					<XsInput
						field={this.form.$("search")}
						autoFocus={true}
						onChange={() => {
							AddEventDialogStore.searchPatient()
						}}
						InputProps={{
							className: "xs-search-input tourSearchPatient",
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										disabled={isEmpty(this.form.$("search").value) ? true : false}
										onClick={() => {
											AddEventDialogStore.onHandleUnknowPatient()
											this.setState({isSaving: false})
											if (TourStore.runSaveNewEvent_tour) {
												TourStore.handleNextSaveNewEventTour()
											}
										}}
									>
										<i className="xs-plus-btn fal fa-plus-square tourSearchPlus" />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
				</div>
				{isSafe(AddEventDialogStore.patientList) && AddEventDialogStore.patientList.length > 0 && (
					<div className="xs-patient-list-scrollbar">
						<div className="xs-patient-list">
							{AddEventDialogStore.patientList.map((patient, idx) => {
								let lastName = isNotEmpty(patient.last_name) ? patient.last_name.toUpperCase() : ""

								return (
									<div
										key={idx}
										className={`xs-patient tourPatient${idx}`}
										onClick={() => {
											AddEventDialogStore.onHandleSelectPatient(patient)
											this.setState({isSaving: false})
											if (TourStore.runSaveEvent_tour) {
												TourStore.runSaveEvent_tour = false
												TourStore.handleNextSaveEventTour()
											}
										}}
									>
										<div className="xs-patient-info">
											<div className="xs-full-name">
												{isEmpty(patient.last_name) && isEmpty(patient.first_name)
													? patient.client_text
													: lastName + " " + patient.first_name}
												{patient.in_capitation && (
													<React.Fragment>
														<span className="ml-1 xs-capitation-badge bgBlueDark " data-tip data-for="capitation">
															K
														</span>
														<ReactTooltip id="capitation">
															<FormattedMessage id="Patient.header.tooltip.patientCapitation" />
														</ReactTooltip>
													</React.Fragment>
												)}
											</div>
											<div className="xs-gender">
												{patient.gender}, {patient.age} <FormattedMessage id="Common.label.years" />
											</div>
										</div>
										<div className="xs-insurer">
											<div className="xs-identifier">{patient.identifier}</div>
											<div className="xs-insurer-name">{patient.insurer_short_name}</div>
											{/* <div className="xs-insurer-name">{patient.insurer_name}</div> */}
										</div>
									</div>
								)
							})}
						</div>
					</div>
				)}
			</div>
		)
	}
}
