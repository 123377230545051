import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
// import renderHTML from "react-render-html"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"

import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"

import DataStore from "../../../stores/DataStore"
import DialogStore from "../../../stores/RindividHelpDialogStore"
import Diacritics from "../../../../../global/helpers/diacritics"

@observer
export default class RindividHelpDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	onHandleRowClick(dataRow) {
		DialogStore.appendToArea(htmlToPlain(dataRow.edit.template_stream))
	}

	onHandleSearchName(value) {
		DialogStore.searchName = value
	}

	onHandleSearchContent(value) {
		DialogStore.searchContent = value
	}

	handleFilterData(data) {
		let filteredData = data

		if (isNotEmpty(DialogStore.searchName)) {
			filteredData = filteredData.filter(
				(x) =>
					Diacritics.remove(x.name)
						.toLowerCase()
						.indexOf(Diacritics.remove(DialogStore.searchName).toLowerCase()) !== -1
			)
		}
		if (isNotEmpty(DialogStore.searchContent)) {
			filteredData = filteredData.filter(
				(x) =>
					Diacritics.remove(x.content)
						.toLowerCase()
						.indexOf(Diacritics.remove(DialogStore.searchContent).toLowerCase()) !== -1
			)
		}

		return filteredData
	}

	render() {
		const getData = () => {
			let data = []
			if (
				isSafe(DataStore.templatesAll) &&
				isSafe(DataStore.templatesAll["RINDIVID"]) &&
				isSafe(DataStore.templatesAll["RINDIVID"].records)
			) {
				DataStore.templatesAll["RINDIVID"].records.forEach((rec) => {
					data.push({
						name: rec.name,
						content: htmlToPlain(rec.template_stream), // renderHTML(rec.template_stream), //
						edit: rec
					})
				})
			} else {
				return data
			}
			return this.handleFilterData(data)
		}

		return (
			isSafe(DataStore.templatesAll) &&
			isSafe(DataStore.templatesAll["RINDIVID"]) &&
			isSafe(DataStore.templatesAll["RINDIVID"].records) &&
			DataStore.templatesAll["RINDIVID"].records.length > 0 && (
				<Dialog
					id="xsRindividHelpDialog"
					open={DialogStore.isOpen}
					onClose={() => DialogStore.close()}
					onEscapeKeyDown={() => DialogStore.close()}
					disableBackdropClick={true}
					maxWidth="md"
					className="xs-base-dialog"
				>
					<DialogTitle>
						<div className="xs-header">
							<div className="xs-header-icon">
								<i className="fal fa-map-marker-question fa-2x"></i>
							</div>
							<div className="xs-header-title">
								<FormattedMessage id="Patient.form.template.templates" />
							</div>
						</div>
					</DialogTitle>
					<DialogContent className="xs-content">
						<div className="xs-content-filters">
							<Grid container direction="row" align="center" spacing={8}>
								<Grid item xs={6}>
									<TextField
										InputLabelProps={{shrink: true}}
										defaultValue=""
										autoComplete="off"
										onChange={(e) => this.onHandleSearchName(e.target.value)}
										label={<FormattedMessage id="Common.label.findByName" />}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										InputLabelProps={{shrink: true}}
										defaultValue=""
										autoComplete="off"
										onChange={(e) => this.onHandleSearchContent(e.target.value)}
										label={<FormattedMessage id="Common.label.findByContent" />}
									/>
								</Grid>
							</Grid>
						</div>
						<XsTable
							config={{
								columnDefs: {
									name: {
										title: <FormattedMessage id="Common.label.name" />,
										type: "string",
										design: {
											width: "150px"
										},
										sortable: true
									},
									content: {
										title: <FormattedMessage id="Common.label.content" />,
										type: "string",
										design: {
											width: "650px"
										}
									},
									edit: {
										title: "",
										type: "action",
										design: {
											width: "20px",
											body: {
												renderer: (props) => {
													return (
														<XsIconButton
															icon={<i className="fal fa-pencil-alt"></i>}
															onClick={(e) => DataStore.editTemplate(e.target, props.value, null, this.props.formKey)}
														/>
													)
												}
											}
										}
									}
								},
								options: {
									showCursor: true,
									defaultSort: {columnName: "name", sortDirection: "asc"},
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									}
								},
								dataSource: getData()
							}}
						/>
					</DialogContent>
					<DialogActions className="xs-footer">
						<XsButton
							className="xs-default xs-outline"
							icon={<i className="fal fa-times"></i>}
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => DialogStore.close()}
						/>
					</DialogActions>
				</Dialog>
			)
		)
	}
}
