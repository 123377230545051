import React from "react"
import {observer} from "mobx-react"
import DeliveryList from "./deliveryList"
import DeliveryPerPatientList from "./deliveryPerPatientList"
import XsTabsIcon from "../../../../../global/ui/xsTabs/xsTabsIcon"
import DeliveryStore from "../../../stores/DeliveryStore"
import {injectIntl} from "react-intl"
import {Grid} from "@material-ui/core"

function deliveryContainer(props) {
	return (
		<Grid container className="xs-drugReport-list">
			<Grid item xs={12}>
				<XsTabsIcon
					value={DeliveryStore.tabCard}
					onChangeTab={(value) => {
						DeliveryStore.tabCard = value
					}}
					mainClass="xs-patient-card-tab"
					background="white"
					tabs={[
						{
							label: props.intl.formatMessage({id: "Patient.form.patientrecord.deliveries"}),
							value: "delivery",
							icon:
								DeliveryStore.tabCard === "drug" ? (
									<i className="fas   fa-pills fa-2x" />
								) : (
									<i className="fal   fa-pills  fa-2x" />
								),
							content: <DeliveryList />
						},
						{
							label: props.intl.formatMessage({id: "Common.label.deliveriesByPatient"}),
							value: "patient",
							icon:
								DeliveryStore.tabCard === "medEquip" ? (
									<i className="fas fa-first-aid fa-2x" />
								) : (
									<i className="fal fa-first-aid fa-2x" />
								),
							content: <DeliveryPerPatientList />
						}
					]}
				/>
			</Grid>
		</Grid>
	)
}

export default injectIntl(observer(deliveryContainer))
