import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
// import moment from "moment"

import {Grid, Checkbox, FormControlLabel, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import AgreementPartDepartmentsStore from "../../../stores/AgreementPartDepartmentsStore"
import AgreementAttributeStore from "../../../stores/AgreementAttributeStore"

@observer
export default class AgreementPartDepartmentsDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsAgreementPartDepartmentsDialog"
				open={AgreementPartDepartmentsStore.isOpen}
				onClose={() => AgreementPartDepartmentsStore.close()}
				className="xs-base-dialog"
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle>
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-clipboard-check fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Agreement.SelectDepartments.dialog.headerMessage" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<Grid container direction="column">
						{isSafe(AgreementAttributeStore.providers) &&
							AgreementAttributeStore.providers.length > 0 &&
							AgreementAttributeStore.providers.map((provider, idx) => {
								return (
									<Grid key={idx} item xs={12}>
										<Grid container direction="row" spacing={8} alignItems="center">
											<Grid item xs={1}>
												<FormControlLabel
													control={
														<Checkbox
															checked={AgreementAttributeStore.providersChecked[provider.provider_id]}
															onChange={(e) =>
																AgreementAttributeStore.handleCheckbox(
																	`${provider.provider_id}`,
																	e.target.checked,
																	provider
																)
															}
														/>
													}
													// label={<FormattedMessage id="Agreement.Container.Detail.Sufficient" />}
												/>
											</Grid>
											<Grid item xs={5}>
												{provider.expertise_name_ext}
											</Grid>
											<Grid item xs={6}>
												{provider.name_ext}
											</Grid>
										</Grid>
									</Grid>
								)
							})}
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times fa-lg" />}
						text={<FormattedMessage id="Common.form.close" />}
						onClick={() => AgreementPartDepartmentsStore.close()}
					/>
					<XsButton
						className="xs-success"
						icon={<i className="fal fa-clipboard-check fa-lg" />}
						text={<FormattedMessage id="Common.btn.agreement.selectDepartment" />}
						onClick={() => AgreementPartDepartmentsStore.close()}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
