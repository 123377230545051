import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "medical_examination_driver_printable"
				},
				paperTemplateId: {
					value: "medical_examination_driver_printable_Sevt"
				},

				doctorName: {},

				orgunitname: {},
				orgunitaddress: {},
				companyIdentifier: {},

				///

				paperNumber: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.paperNumber" />
				},

				name: {},
				nameForm: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.name" />
				},
				birthdate: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.birthdate" />,
					type: "date"
				},

				//drzitel
				has_AM: {label: "AM", type: "checkbox", value: false},
				has_AMx: {type: "checkbox", value: true},

				has_A1: {label: "A1", type: "checkbox", value: false},
				has_A1x: {type: "checkbox", value: true},

				has_A2: {label: "A2", type: "checkbox", value: false},
				has_A2x: {type: "checkbox", value: true},

				has_A: {label: "A", type: "checkbox", value: false},
				has_Ax: {type: "checkbox", value: true},

				has_B1: {label: "B1", type: "checkbox", value: false},
				has_B1x: {type: "checkbox", value: true},

				has_B: {label: "B", type: "checkbox", value: false},
				has_Bx: {type: "checkbox", value: true},

				has_BE: {label: "BE", type: "checkbox", value: false},
				has_BEx: {type: "checkbox", value: true},

				has_C1: {label: "C1", type: "checkbox", value: false},
				has_C1x: {type: "checkbox", value: true},

				has_C1E: {label: "C1E", type: "checkbox", value: false},
				has_C1Ex: {type: "checkbox", value: true},

				has_C: {label: "C", type: "checkbox", value: false},
				has_Cx: {type: "checkbox", value: true},

				has_CE: {label: "CE", type: "checkbox", value: false},
				has_CEx: {type: "checkbox", value: true},

				has_D1: {label: "D1", type: "checkbox", value: false},
				has_D1x: {type: "checkbox", value: true},

				has_D1E: {label: "D1E", type: "checkbox", value: false},
				has_D1Ex: {type: "checkbox", value: true},

				has_D: {label: "D", type: "checkbox", value: false},
				has_Dx: {type: "checkbox", value: true},

				has_DE: {label: "DE", type: "checkbox", value: false},
				has_DEx: {type: "checkbox", value: true},

				has_T: {label: "T", type: "checkbox", value: false},
				has_Tx: {type: "checkbox", value: true},

				// ziada
				req_AM: {label: "AM", type: "checkbox", value: false},
				req_AMx: {type: "checkbox", value: true},

				req_A1: {label: "A1", type: "checkbox", value: false},
				req_A1x: {type: "checkbox", value: true},

				req_A2: {label: "A2", type: "checkbox", value: false},
				req_A2x: {type: "checkbox", value: true},

				req_A: {label: "A", type: "checkbox", value: false},
				req_Ax: {type: "checkbox", value: true},

				req_B1: {label: "B1", type: "checkbox", value: false},
				req_B1x: {type: "checkbox", value: true},

				req_B: {label: "B", type: "checkbox", value: false},
				req_Bx: {type: "checkbox", value: true},

				req_BE: {label: "BE", type: "checkbox", value: false},
				req_BEx: {type: "checkbox", value: true},

				req_C1: {label: "C1", type: "checkbox", value: false},
				req_C1x: {type: "checkbox", value: true},

				req_C1E: {label: "C1E", type: "checkbox", value: false},
				req_C1Ex: {type: "checkbox", value: true},

				req_C: {label: "C", type: "checkbox", value: false},
				req_Cx: {type: "checkbox", value: true},

				req_CE: {label: "CE", type: "checkbox", value: false},
				req_CEx: {type: "checkbox", value: true},

				req_D1: {label: "D1", type: "checkbox", value: false},
				req_D1x: {type: "checkbox", value: true},

				req_D1E: {label: "D1E", type: "checkbox", value: false},
				req_D1Ex: {type: "checkbox", value: true},

				req_D: {label: "D", type: "checkbox", value: false},
				req_Dx: {type: "checkbox", value: true},

				req_DE: {label: "DE", type: "checkbox", value: false},
				req_DEx: {type: "checkbox", value: true},

				req_T: {label: "T", type: "checkbox", value: false},
				req_Tx: {type: "checkbox", value: true},

				//
				opinion_driver1Inv: {
					label: <FormattedMessage id="PrintTemplate.MedicalExaminationDriver.opinion_driver1" />,
					type: "checkbox",
					value: false
				},
				opinion_driver2Inv: {
					label: <FormattedMessage id="PrintTemplate.MedicalExaminationDriver.opinion_driver2" />,
					type: "checkbox",
					value: false
				},

				result1_cat: {
					label: <FormattedMessage id="PrintTemplate.MedicalExaminationDriver.result1_cat" />
				},

				result2_cat: {
					label: <FormattedMessage id="PrintTemplate.MedicalExaminationDriver.result2_cat" />
				},

				result3_cat: {
					label: <FormattedMessage id="PrintTemplate.MedicalExaminationDriver.result3_cat" />
				},

				//

				validateTo: {
					label: <FormattedMessage id="Common.label.date" />,
					type: "date"
				},

				in: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.in" />
				},

				date: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.date" />,
					value: moment(),
					type: "date"
				}
			}
		}
	}
}
