import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				implantCode: {
					label: <FormattedMessage id="Patient.sidebar.implant" />,
					rules: "required"
				},
				medEquip: {
					label: <FormattedMessage id="Patient.form.patientPrescription.medDev" />
				},
				implantation_date: {
					label: <FormattedMessage id="Common.implantationDate" />,
					type: "date"
				},
				validFrom: {
					label: <FormattedMessage id="Common.Date.ValidFrom" />,
					type: "date",
					rules: "required",
					value: moment()
				},
				validTo: {
					label: <FormattedMessage id="Common.Date.ValidTo" />,
					type: "date"
				},
				implantText: {}
			}
		}
	}
}
