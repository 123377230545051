import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import fields from "./employeeUserExternalCredentialsFields"

import Grid from "@material-ui/core/Grid"
import XsTable from "../../../../../../global/ui/xsTable/xsTable"
import SearchSelect from "../../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import {FormattedMessage, injectIntl} from "react-intl"

import EmployeesDetailStore from "../../../../stores/EmployeesDetailStore"
// import XsDropdown from "../../../../../../global/ui/xsDropdown/xsDropdown"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../../global/ui/xsButton/xsButton"
import GlobalStore from "../../../../../../global/store/GlobalStore"
import {getUserPersonnelOrgUnits} from "../../../../../../global/helpers/actions"
import XsConfirmationDialog from "../../../../../../global/ui/xsDialog/xsConfirmationDialog"
import XsIconButton from "../../../../../../global/ui/xsButton/xsIconButton"

@injectIntl
@observer
export default class EmployeeUserExternalCredentials extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit(form) {
				if (form.$("password").value != form.$("password2").value) {
					form.$("password").invalidate()
					GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Admin.users.wrongPass"}))
				}
			},
			onSuccess(form) {
				EmployeesDetailStore.saveExternalCredentials(form, EmployeesDetailStore.externalCredentialsEditing)
				EmployeesDetailStore.externalCredentialsEditing = false
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}
		GlobalStore.refreshCodeLists(["externalSystems"])
		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks})
	}

	onHandleRowClick = (dataRow) => {
		this.form.$("login").value = dataRow.username
		this.form.$("system").value = dataRow.system
		this.form.$("sub_identifier").value = isNotEmpty(dataRow.sub_identifierId)
			? dataRow.sub_identifierId.substring(2)
			: ""
		EmployeesDetailStore.updatedSystem = dataRow.system
		EmployeesDetailStore.updatedSubIdentifier = isNotEmpty(dataRow.sub_identifierId)
			? dataRow.sub_identifierId.substring(2)
			: ""
		EmployeesDetailStore.externalCredentialsEditing = true
		EmployeesDetailStore.externalCredentialsAdd = true
	}

	render() {
		return (
			<Grid container direction="column">
				<Grid item className="pt-6 pr-6 pl-6">
					<XsTable
						config={{
							columnDefs: {
								username: {
									title: <FormattedMessage id="Employee.form.user.login" />,
									type: "string",
									design: {
										width: "25%"
									},
									sortable: true
								},
								system: {
									title: <FormattedMessage id="EmployeeDetail.employeeUser.system" />,
									type: "string",
									design: {
										width: "25%"
									},
									sortable: true
								},
								user_id: {
									title: "Id",
									type: "string",
									design: {
										width: "25%"
									},
									sortable: true
								},
								sub_identifier: {
									title: <FormattedMessage id="Common.label.orgunit" />,
									type: "string",
									design: {
										width: "25%"
									},
									sortable: true
								},
								delete: {
									title: "",
									type: "action",
									design: {
										width: "10%",
										body: {
											renderer: (props) => {
												return (
													<XsIconButton
														className="action-delete"
														icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
														onClick={() =>
															GlobalStore.openConfirmationDialog("xsDeleteUSerExternalCredentials", {
																credentials: props.value
															})
														}
													/>
												)
											}
										}
									}
								}
							},
							options: {
								hidePager: true,
								showCursor: true,
								onRowClick: (dataRow) => {
									this.onHandleRowClick(dataRow)
								},
								mapper: (dataRow) => {
									return {
										password: dataRow.password,
										system: dataRow.system,
										user_id: dataRow.user_id,
										username: dataRow.username,
										sub_identifier:
											isNotEmpty(dataRow.sub_identifier) &&
											isSafe(getUserPersonnelOrgUnits()) &&
											isSafe(getUserPersonnelOrgUnits().find((a) => a._id == dataRow.sub_identifier.substring(2)))
												? getUserPersonnelOrgUnits().find((a) => a._id == dataRow.sub_identifier.substring(2)).code_ext
												: "",
										sub_identifierId: dataRow.sub_identifier,
										delete: JSON.stringify(dataRow)
									}
								}
							},
							dataSource: EmployeesDetailStore.externalCredentials
						}}
					/>
				</Grid>
				<Grid item className="fomBorderTop pt-4 pb-2 pl-6 pr-6">
					{EmployeesDetailStore.externalCredentialsAdd ? (
						<Grid container direction="row" align="center" spacing={8}>
							<Grid item xs={2}>
								<XsInput white={true} field={this.form.$("login")} />
							</Grid>
							<Grid item xs={2}>
								<SearchSelect
									field={this.form.$("system")}
									items={GlobalStore.CL["externalSystems"]}
									white={true}
									required
								/>
							</Grid>
							<Grid item xs={2}>
								<SearchSelect field={this.form.$("sub_identifier")} items={getUserPersonnelOrgUnits()} white={true} />
							</Grid>
							<Grid item xs={2}>
								<XsInput white={true} field={this.form.$("password")} type="password" />
							</Grid>
							<Grid item xs={2}>
								<XsInput white={true} field={this.form.$("password2")} type="password" />
							</Grid>
							<Grid item container justify="flex-end" alignItems="center" xs={2} spacing={8} className="mt-0">
								<Grid item>
									<XsButton
										className="xs-success"
										text={
											<FormattedMessage
												id={EmployeesDetailStore.externalCredentialsEditing ? "Common.label.edit" : "Common.label.save"}
											/>
										}
										type="submit"
										onClick={this.form.onSubmit}
										icon={<i className="fal fa-check fa-lg" />}
									/>
								</Grid>
								<Grid item>
									<XsButton
										className="xs-danger xs-outline"
										text={<FormattedMessage id="Common.label.cancel" />}
										onClick={() => {
											this.form.reset()
											EmployeesDetailStore.externalCredentialsEditing = false
											EmployeesDetailStore.externalCredentialsAdd = false
										}}
										icon={<i className="fal fa-times fa-lg"></i>}
									/>
								</Grid>
							</Grid>
						</Grid>
					) : (
						<Grid item className="pt-2 pb-2">
							<XsButton
								className="xs-primary xs-outline"
								icon={<i className="fal fa-plus fa-lg" />}
								text={<FormattedMessage id="Common.button.addAnother" />}
								onClick={() => {
									EmployeesDetailStore.externalCredentialsAdd = true
									EmployeesDetailStore.externalCredentialsEditing = false
								}}
							/>
						</Grid>
					)}
				</Grid>
				<XsConfirmationDialog
					type="danger"
					name="xsDeleteUSerExternalCredentials"
					text={<FormattedMessage id="Common.label.deleteCredentialsConfirmationMessage" />}
					headerConfig={{
						text: "Common.label.warning",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.label.deleteCredentials"
					}}
					onConfirmation={() =>
						EmployeesDetailStore.deleteExternalCredentials(JSON.parse(GlobalStore.confirmationDialogParams.credentials))
					}
				/>
			</Grid>
		)
	}
}
