import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import bindings from "../../../../../global/ui/globalUISchemeBindings"

import Grid from "@material-ui/core/Grid"
import CancelIcon from "@material-ui/icons/Clear"

import UIStore from "../../../stores/UIStore"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import ScoringDialog from "./scoringDialog"
import ShowDocumentsDialog from "./showDocumentsDialog"
import ScoringStore from "../../../stores/ScoringStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

@observer
export default class ScoringList extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					view: {label: " ", value: "scoring", rules: "required"}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)

		ScoringStore.loadList()
	}

	onRowClick = (item) => {
		ScoringStore.open(item)
	}

	onCDSRowClick = (item) => {
		ScoringStore.getCDSById(item.id)
	}

	render() {
		return (
			<section className="xs-patient-card-form xs-column">
				{/* {this.renderAutoSaveConfirmDialog()} */}
				<div className="xs-patient-form xs-row">
					<div className="xs-patient-form-left xs-flex">
						<div className="padding">
							<Grid container>
								<Grid item xs={3}>
									<XsSearchSelect
										hideLabel
										field={this.form.$("view")}
										items={[
											{_id: "cds", code_ext: "cds", name_ext: "CDS"},
											{_id: "scoring", code_ext: "scoring", name_ext: "Scoring"}
										]}
									/>
								</Grid>
								{this.form.$("view").value == "scoring" ? (
									<Grid item xs={12}>
										<XsTable
											config={{
												columnDefs: {
													name_ext: {type: "string"}
												},
												options: {
													showCursor: true,
													selectRow: true,
													hideHeader: true,
													paging: {
														rowsPerPage: 25
													},
													onRowClick: this.onRowClick,
													mapper: (dataRow) => {
														return {
															name_ext: isSafe(dataRow.name_ext) ? dataRow.name_ext : "",
															id: isSafe(dataRow._id) ? dataRow._id : ""
														}
													}
												},
												dataSource: ScoringStore.scoringList
											}}
										/>
									</Grid>
								) : (
									<Grid item xs={12}>
										<XsTable
											config={{
												columnDefs: {
													name_ext: {type: "string"}
												},
												options: {
													showCursor: true,
													hideHeader: true,
													paging: {
														rowsPerPage: 25
													},
													onRowClick: this.onCDSRowClick,
													mapper: (dataRow) => {
														return {
															name_ext: isSafe(dataRow.name_ext) ? dataRow.name_ext : "",
															id: isSafe(dataRow._id) ? dataRow._id : ""
														}
													}
												},
												dataSource: GlobalStore.CL["cds"]
											}}
										/>
									</Grid>
								)}
							</Grid>
						</div>
					</div>
				</div>
				<div className="xs-patient-form-actions">
					<XsButton
						className="xs-danger xs-outline"
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => {
							UIStore.patientCardTab = ""
						}}
						icon={<CancelIcon />}
					/>
				</div>
				{ScoringStore.isOpen && (
					<ScoringDialog
						options={{
							insertScoringToRecord: this.props.options.insertScoringToRecord
						}}
					/>
				)}
				{ScoringStore.isOpenShowDocument && <ShowDocumentsDialog />}
			</section>
		)
	}
}
