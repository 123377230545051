import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				dateFrom: {
					label: <FormattedMessage id="Common.label.from" />,
					rules: "required",
					type: "date",
					value: moment().subtract(6, "month")
				},
				dateTo: {
					label: <FormattedMessage id="Common.label.to" />,
					rules: "required",
					type: "date",
					value: moment()
				},
				professionalFocus: {
					label: <FormattedMessage id="Patient.form.patientrecord.professionalFocus" />,
					rules: "string"
				},
				technicalDepartment: {
					label: <FormattedMessage id="Patient.form.patientrecord.OUPZS" />,
					rules: "string"
				}
			}
		}
	}
}
