import {FormattedMessage} from "react-intl"
import React from "react"
// import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "medical_examination_driver"
				},

				doctorName: {},

				///

				name: {},
				nameForm: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.name" />
				},
				birthdate: {
					type: "date"
				},
				birthdateForm: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.birthdate" />,
					type: "date"
				},

				own: {
					label: "Menovaný je držiteľom skupín, podskupín:"
				},
				opinion: {
					label: "Menovaný bol posudzovaný ako vodič skupiny:"
				},

				result1_cat: {
					label: <FormattedMessage id="PrintTemplate.MedicalExaminationDriver.result1_cat" />
				},

				result2_cat: {
					label: <FormattedMessage id="PrintTemplate.MedicalExaminationDriver.result2_cat" />
				},

				result3_cat: {
					label: <FormattedMessage id="PrintTemplate.MedicalExaminationDriver.result3_cat" />
				}

				// date: {
				// 	label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.date" />,
				// 	value: moment(),
				// 	type: "date"
				// }
			}
		}
	}
}
