import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import DeleteIcon from "@material-ui/icons/Clear"

import SmsNotificationStore from "../../../stores/SmsNotificationStore"
import {TextField} from "@material-ui/core"

@observer
export default class SmsNotificationDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsSmsNotification"
				open={SmsNotificationStore.isOpen}
				onClose={() => SmsNotificationStore.close()}
				//onEscapeKeyDown={() => DrugInteractionsDialogStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle>
					<div className="xs-base-header">
						<div className="xs-header-title">
							<FormattedMessage id="Calendar.smsNotification.dialog.headerMessage" />
						</div>
						<XsIconButton
							className="xs-dialog-header"
							icon={<DeleteIcon onClick={() => SmsNotificationStore.close()} />}
						/>
					</div>
				</DialogTitle>
				<DialogContent>
					<div className="xs-base-dialog-body xs-sms-notification-body">
						<TextField onChange={(e) => SmsNotificationStore.handleInputChange(e.target.value)} />
					</div>
				</DialogContent>
				<DialogActions>
					<div className="xs-dialog-btn-center">
						<XsButton
							className="xs-success"
							text={<FormattedMessage id="Common.form.continue" />}
							onClick={() => SmsNotificationStore.getHealthCard()}
						/>
						<XsButton
							className="xs-default xs-outline"
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => SmsNotificationStore.close()}
						/>
					</div>
				</DialogActions>
			</Dialog>
		)
	}
}
