import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import renderHTML from "react-render-html"
import "../../xsDialog/xsDialog.less"

import XsButton from "../../xsButton/xsButton"

import importVaccStore from "./importVaccStore"

@observer
export default class CustomImportWarningDialog extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			showDetails: false
		}
	}

	render() {
		return (
			<Dialog
				aria-describedby="alert-dialog-description"
				open={importVaccStore.customWarningisOpen}
				onEscapeKeyDown={() => importVaccStore.customWarningClose()}
				maxWidth="md"
				className="xs-warning-dialog"
			>
				<DialogTitle className={importVaccStore.isError ? "xs-danger" : "xs-warning"}>
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-exclamation-triangle fa-2x"></i>
						</div>
						<div className="xs-header-title">{<FormattedMessage id="Common.label.warning" />}</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					{isNotEmpty(importVaccStore.customWarningSuccessRowCount)
						? `Úspešne naimportovaných riadkov: ${importVaccStore.customWarningSuccessRowCount}`
						: ""}
					{renderHTML(importVaccStore.customWarningErrorText)}
					{isNotEmpty(importVaccStore.customWarningWarningText) && (
						<div className="greyDefault">
							<div
								onClick={() => {
									this.setState({showDetails: !this.state.showDetails})
								}}
								className="pointer"
							>
								Upozornenia <i className={`fal fa-chevron-${this.state.showDetails ? "up" : "down"}`}></i>
							</div>
							{this.state.showDetails && <div>{renderHTML(importVaccStore.customWarningWarningText)}</div>}
						</div>
					)}
				</DialogContent>
				<DialogActions className="xs-footer">
					<XsButton
						className={importVaccStore.isError ? "xs-danger" : "xs-warning"}
						text={<FormattedMessage id="Common.label.ok" />}
						onClick={() => importVaccStore.customWarningClose()}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
