import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import VaccByRegionReportStore from "../../../stores/VaccByRegionReportStore"

// import ExportMedicationToXlsDialogStore from "../../../stores/ExportMedicationToXlsDialogStore"

import fields from "./vaccByRegionFields"

import TableStore from "../../../stores/TableStore"

@injectIntl
@observer
export default class VaccByRegionFilters extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				if (
					TableStore.tables.hasOwnProperty("vaccByRegionList") &&
					TableStore.tables["vaccByRegionList"].hasOwnProperty("index")
				) {
					delete TableStore.tables["vaccByRegionList"]["index"]
				}

				VaccByRegionReportStore.getFilters(form)
			},
			onError() {}
		}

		if (isEmpty(VaccByRegionReportStore.formRef)) {
			VaccByRegionReportStore.formRef = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		}
		this.form = VaccByRegionReportStore.formRef

		VaccByRegionReportStore.getFilters(this.form)
	}

	render() {
		VaccByRegionReportStore.isChangedFilter(this.form.values())

		return (
			<Grid container spacing={8} alignItems="center">
				<Grid item xs={2}>
					<XsDateTimePicker white field={this.form.$("date_from")} showTimeSelect={true} />
				</Grid>
				<Grid item xs={2}>
					<XsDateTimePicker white field={this.form.$("date_to")} showTimeSelect={true} />
				</Grid>
				<Grid item xs={2}>
					<XsInput white field={this.form.$("place_ids")} />
				</Grid>
				<Grid item xs={1} className="pb-0">
					<XsIconButton
						rect
						className="xs-default xs-outline"
						tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
						onClick={this.form.onSubmit}
						icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
					/>
				</Grid>
				<Grid item xs={5}>
					<Grid container justify="flex-end" spacing={8}>
						<Grid item>
							<XsButton
								className={VaccByRegionReportStore.changedFilters ? "xs-outline xs-default" : "xs-primary"}
								disabled={VaccByRegionReportStore.changedFilters}
								text={<FormattedMessage id="Common.label.export" />}
								icon={<i className="fal fa-file-excel fa-lg" />}
								onClick={() => {
									VaccByRegionReportStore.exportToExcel()
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		)
	}
}
