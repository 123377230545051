import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				category: {
					label: <FormattedMessage id="Employee.form.medicalWorker.category" />,
					rules: "required"
				},
				from: {
					label: <FormattedMessage id="Employee.form.medicalWorker.from" />,
					rules: "required",
					type: "date"
				},
				to: {
					label: <FormattedMessage id="Employee.form.medicalWorker.to" />,
					type: "date"
				},
				JRUZid: {
					label: <FormattedMessage id="Employee.form.medicalWorker.JRUZid" />
				}
			}
		}
	}
}
