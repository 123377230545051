import React from "react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import QueueContainerStore from "../../../stores/QueueContainerStore"
import UIStore from "../../../stores/UIStore"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import XsNumberInput from "../../../../../global/ui/xsInput/xsNumberInput"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import GlobalStore from "../../../../../global/store/GlobalStore"

@observer
export default class EditQueueDialog extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			showTable: false
		}

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				QueueContainerStore.editQueue(form)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		const fields = {
			name: {
				label: "Názov",
				rules: "required"
			},
			count: {
				label: "Počet objektov vo fronte",
				rules: "required",
				value: 0
			},
			capacity: {
				label: "Kapacita",
				rules: "required"
			},
			employee: {
				label: "Manažér fronty",
				rules: "required"
			}
		}
		this.form = new MobxReactForm({fields: fields}, {plugins: {dvr: validatorjs}, hooks})

		if (isNotEmpty(QueueContainerStore.queueId)) {
			QueueContainerStore.loadEditForm(this.form)
		}
	}

	render() {
		return (
			<Dialog
				id="xsEditQueueDialog"
				open={QueueContainerStore.editDialogOpen}
				onClose={() => {
					QueueContainerStore.closeEditDialog(this.form)
				}}
				disableBackdropClick={true}
				maxWidth={"lg"}
				classes={{
					paper: "xs-paper-dialog xs-width-paper-600"
				}}
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className={`fal ${isNotEmpty(QueueContainerStore.queueId) ? "fa-edit" : "fa-poll-people"}  fa-2x`} />
						</div>
						<div className="xs-header-title">
							{isNotEmpty(QueueContainerStore.queueId) ? "Úprava fronty" : "Nová fronta"}
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content" style={{overflow: "visible"}}>
					{UIStore.isFormSaving ? (
						<XsLoading />
					) : (
						<Grid container spacing={8}>
							<Grid item xs={12}>
								<XsInput field={this.form.$("name")} />
							</Grid>
							<Grid item xs={12}>
								<XsSearchSelect field={this.form.$("employee")} items={QueueContainerStore.employeesList} />
							</Grid>
							<Grid item xs={12}>
								<XsNumberInput field={this.form.$("capacity")} min={"0"} step={"1"} />
							</Grid>
							<Grid item xs={12}>
								<XsNumberInput field={this.form.$("count")} min={"0"} step={"1"} />
							</Grid>
						</Grid>
					)}
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.form.close" />}
						onClick={() => QueueContainerStore.closeEditDialog(this.form)}
					/>
					<XsButton
						className="xs-success"
						icon={<i className="fal fa-check" />}
						text={<FormattedMessage id="Common.label.save" />}
						onClick={(e) => this.form.onSubmit(e)}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
