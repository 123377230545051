import React from "react"
import {FormattedMessage} from "react-intl"

export default {
	load() {
		return {
			fields: {
				batchType: {
					label: <FormattedMessage id="Delivery.List.BatchType" />,
					rules: "required",
					value: "751a"
				},
				disposition: {
					label: <FormattedMessage id="Delivery.List.BatchType" />,
					rules: "required",
					value: "N"
				}
				// rows: {}
			}
		}
	}
}
