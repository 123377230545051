"use strict"

import {observable, action} from "mobx"

import {getUserCompanyInfo} from "../../../global/helpers/actions"

import api from "../actions/api"

class ContactFormStore {
	@observable editable = false
	@observable isAdd = false

	@observable isOpenSendSMSDialog = false
	@observable resData = undefined

	smsFormRef = null

	@action openSendSMSDialog(phoneNumber) {
		this.smsFormRef.$("recipient").value = phoneNumber

		this.isOpenSendSMSDialog = true
	}

	@action closeSendSMSDialog() {
		this.isOpenSendSMSDialog = false
		this.resData = undefined
	}

	@action sendSMS(form) {
		const formValues = form.values()

		const req = {
			company_id: getUserCompanyInfo().id,
			sender: {
				text: formValues.sender
			},
			recipients: [
				{
					phonenr: formValues.recipient
				}
			],
			message: formValues.message
		}

		api
			.sendSMS(req)
			.call()
			.then((res) => {
				if (isSafe(res) && (isSafe(res.to_success) || isSafe(res.to_failed))) {
					this.resData = res
				} else {
					this.resData = undefined
				}
			})
	}
}

var singleton = new ContactFormStore()
export default singleton
