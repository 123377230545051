"use strict"

import {observable, action} from "mobx"
import PatientRecordStore from "./PatientRecordStore"

class ShowSelectedDekurzDialogStore {
	@observable isOpen = false

	id = undefined
	form = null

	@action async open(recId) {
		this.form.$("recordId").value = recId
		await PatientRecordStore.insertFormData(this.form)
		await PatientRecordStore.loadRecordData(recId, true)
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
		PatientRecordStore.recordID = null
		PatientRecordStore.recordLoaded = false
		PatientRecordStore.readonlyform = false
	}
}

var singleton = new ShowSelectedDekurzDialogStore()
export default singleton
