import {ApiCall} from "../../../global/helpers/api"

export default {
	loadAttendees() {
		return new ApiCall("/attend/attendance/timestamps", "GET")
	},
	loadEmployees(companyId) {
		return new ApiCall(`/entity/entities/${companyId}/employees`, "GET")
	},
	loadOrgUnits(filter, level = "") {
		return new ApiCall(`/entity/orgunits?level_flag=CD${level}`, "POST", filter)
	},
	loadEmployeeContrats(filter) {
		return new ApiCall("/entity/contracts", "POST", filter)
	},
	loadOrgUnitDetail(id) {
		return new ApiCall(`/entity/orgunits/${id}?level_flag=CD`, "GET")
	},
	loadOrgunitDetailEmpl(id) {
		return new ApiCall(`/entity/orgunits/${id}/employees`, "GET")
	},
	loadWorkerCard(id, level = "") {
		return new ApiCall(`/entity/specificrels/${id}?level_flag=CD${level}`, "GET")
	},
	loadPersonnelExpertise(id) {
		return new ApiCall(`/amb/entity/personnel/${id}/expertises?level_flag=CDL5`, "GET")
	},
	loadPersonnelExpertiseSpecific(personnel, expertise, level = "") {
		return new ApiCall(`/amb/entity/personnel/${personnel}/expertises/${expertise}?level_flag=CD${level}`, "GET")
	},
	deleteEmpWorkExpertise(personnelId, expertiseId) {
		return new ApiCall(`/amb/entity/personnel/${personnelId}/expertises/${expertiseId}`, "DELETE")
	},
	deleteEmpContract(id) {
		return new ApiCall(`/entity/contracts/${id}`, "DELETE")
	},

	loadRegions() {
		return new ApiCall("/cl/Entity.CL.Address.Region", "GET")
	},

	loadCounty() {
		return new ApiCall("/cl/Entity.CL.Address.County", "GET")
	},

	loadEntityCity(filter) {
		return new ApiCall(`/cl/Entity.CL.Address.City`, "POST", filter)
	},

	loadEntityZip(filter) {
		return new ApiCall(`/cl/Entity.CL.Address.Zip`, "POST", filter)
	},

	getSpecificEmployee(companyId, filter) {
		return new ApiCall(`/entity/entities/${companyId}/employees`, "POST", filter)
	},

	loadOrgunitsParams(filter) {
		return new ApiCall(`/entity/orgunit-params?level_flag=CDL1`, "POST", filter)
	},

	saveOrgunitParam(data) {
		return new ApiCall(`/entity/orgunit-params`, "PUT", data)
	},
	loadEntityOrgUnits(filter) {
		return new ApiCall("/amb/entity/orgunits?level_flag=CD", "POST", filter)
	},
	loadRoles(filter) {
		return new ApiCall("/session/sec-role", "POST", filter)
	},
	loadResources(filter) {
		return new ApiCall("/session/sec-resource", "POST", filter)
	},
	assignToRole(req) {
		return new ApiCall("/session/assign", "POST", req)
	},
	saveRole(filter) {
		return new ApiCall("/session/sec-role", "PUT", filter)
	},

	loadBankTypes() {
		return new ApiCall("/fin/banks", "GET")
	},

	loadOrgunitBankAcc(id) {
		return new ApiCall(`/entity/specificrels/${id}/bankaccounts?level_flag=CD`, "GET")
	},

	saveBankAcc(id, data) {
		return new ApiCall(`/entity/specificrels/${id}/bankaccounts`, "PUT", data)
	},

	loadUserRoles(id) {
		return new ApiCall(`/session/access/${id}`, "GET")
	},

	setUserRoles(data, id) {
		return new ApiCall(`/session/access/${id}`, "POST", data)
	}
}
