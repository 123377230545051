"use strict"

import {observable} from "mobx"

class OrgunitDetailParametersStore {
	@observable editable = false
	@observable isAdd = false
}

var singleton = new OrgunitDetailParametersStore()
export default singleton
