import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import Grid from "@material-ui/core/Grid"

import "./printTemplatesStyle.less"

import fields from "./medicalOpinionWeaponFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsCheckbox from "../../../../../../global/ui/xsCheckbox/xsCheckbox"

@injectIntl
@observer
export default class MedicalOpinionWeaponTemplate extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		if (isEmpty(this.form.$("addressForm").value) && isNotEmpty(this.form.$("address").value)) {
			this.form.$("addressForm").value = this.form.$("address").value
		}
		if (isEmpty(this.form.$("birthdateForm").value) && isNotEmpty(this.form.$("birthdate").value)) {
			this.form.$("birthdateForm").value = this.form.$("birthdate").value
		}
		if (isEmpty(this.form.$("nameForm").value) && isNotEmpty(this.form.$("name").value)) {
			this.form.$("nameForm").value = this.form.$("name").value
		}
	}

	render() {
		return (
			<div className="pa-4">
				<Grid container direction="column" align="top">
					<Grid item xs={3}>
						<XsInput field={this.form.$("paperNumber")} maxLength={40} />
					</Grid>

					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsInput field={this.form.$("nameForm")} />
						</Grid>
						<Grid item xs={4}>
							<XsDateTimePicker field={this.form.$("birthdateForm")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={4}>
							<XsInput field={this.form.$("addressForm")} />
						</Grid>
					</Grid>

					<div style={{marginBottom: "20px", marginTop: "20px"}}>
						<FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.FormLabel1" />
					</div>
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsCheckbox
								field={this.form.$("competencyTrueInv")}
								onChange={(field) => {
									if (field.value == false && this.form.$("competencyTrueInv").value == false) {
										this.form.$("competencyTrueInv").value = true
									} else if (field.value) {
										this.form.$("competencyFalseInv").value = false
									}
								}}
							/>
						</Grid>
						<Grid item xs={8}>
							<XsCheckbox
								field={this.form.$("competencyFalseInv")}
								onChange={(field) => {
									if (field.value == false && this.form.$("competencyFalseInv").value == false) {
										this.form.$("competencyFalseInv").value = true
									} else if (field.value) {
										this.form.$("competencyTrueInv").value = false
									}
								}}
							/>
						</Grid>
					</Grid>

					<Grid container direction="row" align="left" spacing={8} style={{marginTop: "20px"}}>
						<Grid item xs={4}>
							<XsInput field={this.form.$("in")} />
						</Grid>
						<Grid item xs={4}>
							<XsDateTimePicker field={this.form.$("date")} showTimeSelect={false} />
						</Grid>
					</Grid>

					<div style={{marginTop: "20px"}}>
						<FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.FormLabel2" />
					</div>
				</Grid>
			</div>
		)
	}
}
