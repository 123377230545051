/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				invoice_number: {
					label: <FormattedMessage id="Invoice.form.number" />,
					rules: "string"
				},
				customer: {
					label: <FormattedMessage id="Invoice.form.customer" />,
					rules: "string"
				},
				actualOrgUnit: {
					label: <FormattedMessage id="Common.label.currentDepartment" />,
					type: "checkbox"
				}
			}
		}
	}
}
