import React from "react"
import {FormattedMessage} from "react-intl"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "release_report"
				},
				name: {},
				nameForm: {
					label: <FormattedMessage id="Common.label.fullName" />
				},
				company_name: {},
				orgunit_address: {},
				identifier: {},
				identifierForm: {
					label: "Rodné číslo"
				},
				address: {},
				addressForm: {
					label: <FormattedMessage id="Patient.form.contactForm.address" />
				},
				phone: {
					label: "Mobil"
				},
				insurerCode: {},
				insurerCodeForm: {
					label: "Poisť."
				},
				email: {
					label: "E-mail"
				},
				patientRecievedTime: {
					label: "Pacient vyšetrený pred OP o"
				},
				patientRecieved: {
					label: " "
				},
				diagnosisWhenRecieved: {
					label: "Diagnóza pri prijatí"
				},
				diagnosisWhenRecieved_name: {},
				operationProtocolDoneBy: {
					label: "Predoperačné vyšetrenie vykonal bez kontraindikácií k CA"
				},
				operationProtocolDoneBy_name: {},
				operationDeliveryTime: {
					label: "Operačný výkon o"
				},
				operationDelivery: {
					label: " "
				},
				operator: {
					label: "Operatér"
				},
				operator_name: {},
				anestesis: {
					label: "Anestézia",
					value: "tumescencia"
				},
				operationDeliveryText: {
					label: "Operačný výkon"
				},
				complicationsDuringOperation: {
					label: "Komplikácie počas operácie"
				},
				materialForHisto: {
					label: "Materiál na histologické vyšetrenie"
				},
				medicamentTreatment: {
					label: "Medikamentózna liečba"
				},
				bleedingAfterOperation: {
					label: "Pooperačné krvácanie"
				},
				// otherComplications: {
				// 	label: "Iné komplikácie"
				// },
				otherComplications: {
					label: "Iné komplikácie"
				},
				vitalSings: {
					label: "Vitálne funkcie"
				},
				poRecieved: {
					label: "P.O. príjem"
				},
				releasedWithDiagnosis: {
					label: "Prepustený s diagnózou"
				},
				releasedWithDiagnosis_name: {},
				recommendation: {
					label: "Odporúčanie"
				},
				releaseDate: {
					label: "Dátum",
					value: moment(),
					type: "date"
				},
				doctorname: {
					label: "Prepustil"
				}
			}
		}
	}
}
