"use strict"

import {observable, action} from "mobx"
import DataStore from "./DataStore"
import {getSelectedOrgUnitID} from "../../../global/helpers/actions"
import api from "../actions/api"

import moment from "moment"
import {StorageBase} from "../../../global/storage/storageEx"
import UIStore from "../stores/UIStore"
import GlobalStore from "../../../global/store/GlobalStore"

import {DZPDATE} from "../../../global/config/constants"

class MedicineCardDialogStore {
	@observable isOpen = false
	@observable setName = false
	@observable htmlContent = ""

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
		this.htmlContent = ""
	}

	@action drugBook() {
		if (DataStore.patientDTO.get("insurerShortName") == "DZP") {
			StorageBase.updateByKey(DZPDATE, moment().format("YYYY-MM-DD"))
		}

		UIStore.isFormSaving = true

		api
			.loadDrugBook(getSelectedOrgUnitID(), DataStore.patientDTO.get("patientID"))
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.html)) {
					if (isEmpty(response.html)) {
						UIStore.isFormSaving = false
						this.htmlContent = ""
						GlobalStore.setNotificationMessage("Common.label.emptyPrescriptionCard")
					} else {
						UIStore.isFormSaving = false
						this.htmlContent = b64DecodeUnicode(response.html)
						this.setName = false
						this.open()
					}
				} else {
					UIStore.isFormSaving = false
					this.htmlContent = ""
					GlobalStore.setNotificationMessage("Common.label.failedToLoadPrescriptionCardFromInsurance")
				}
			})
			.catch(() => {
				UIStore.isFormSaving = false
				this.htmlContent = ""
			})
	}

	//drugBook duplicitna metoda kvoli loadingu...
	@action drugBookEHR() {
		if (DataStore.patientDTO.get("insurerShortName") == "DZP") {
			StorageBase.updateByKey(DZPDATE, moment().format("YYYY-MM-DD"))
		}

		UIStore.isFormSaving = true

		api
			.loadDrugBook(getSelectedOrgUnitID(), DataStore.patientDTO.get("patientID"))
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.html)) {
					if (isEmpty(response.html)) {
						UIStore.isFormSaving = false
						this.htmlContent = ""
						GlobalStore.setNotificationMessage("Common.label.emptyPrescriptionCard")
					} else {
						UIStore.isFormSaving = false
						this.htmlContent = b64DecodeUnicode(response.html)
						this.setName = false
						this.open()
					}
				} else {
					UIStore.isFormSaving = false
					this.htmlContent = ""
					GlobalStore.setNotificationMessage("Common.label.failedToLoadPrescriptionCardFromInsurance")
				}
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}

	@action pacientInteractions() {
		UIStore.isFormSaving = true
		api
			.loadPacientInteractions(getSelectedOrgUnitID(), DataStore.patientDTO.get("patientID"))
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.html)) {
					UIStore.isFormSaving = false
					this.htmlContent = b64DecodeUnicode(response.html)
					this.setName = true
					this.open()
				} else {
					UIStore.isFormSaving = false
					this.htmlContent = ""
				}
			})
			.catch(() => {
				UIStore.isFormSaving = false
				this.htmlContent = ""
			})
	}
}

var singleton = new MedicineCardDialogStore()
export default singleton
