import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "control_examination_adults"
				},
				paperTemplateId: {
					value: "control_examination_adults_without_template"
				},

				socInsOffice: {
					label: "Pobočka Sociálnej poisťovne (nevypĺňať, pokiaľ ide o predtlačené tlačivo)"
				},

				identifier: {},
				socInsDosageDate: {
					label: "dňa",
					type: "date",
					value: moment()
				},
				name: {},
				birthdate: {},
				insurerCode: {},

				address: {},
				addressForm: {
					label: "Adresa trvalého (prechodného) pobytu"
				},

				employer: {
					label: "Zamestnávateľ"
				},
				job: {
					label: "Druh zárobkovej činnosti"
				},

				pnFromDate: {
					label: "Od",
					type: "date"
				},
				pnToDate: {
					label: "Do",
					type: "date"
				},

				pnReasonSickness: {
					label: "pre chorobu",
					type: "checkbox",
					value: false
				},
				pnReasonSicknessInv: {
					label: "pre chorobu",
					type: "checkbox",
					value: true,
					options: {
						html: "visibility"
					}
				},

				pnReasonInjury: {
					label: "úraz",
					type: "checkbox",
					value: false
				},
				pnReasonInjuryInv: {
					label: "úraz",
					type: "checkbox",
					value: true,
					options: {
						html: "visibility"
					}
				},

				pnReasonWorkInjury: {
					label: "pracovný úraz",
					type: "checkbox",
					value: false
				},
				pnReasonWorkInjuryInv: {
					label: "pracovný úraz",
					type: "checkbox",
					value: true,
					options: {
						html: "visibility"
					}
				},

				pnReasonWorkSickness: {
					label: "chorobu z povolania",
					type: "checkbox",
					value: false
				},
				pnReasonWorkSicknessInv: {
					label: "chorobu z povolania",
					type: "checkbox",
					value: true,
					options: {
						html: "visibility"
					}
				},

				dosageType: {
					label: "Poberateľ dávky sociálneho poistenia, druh dávky"
				},

				anamnesisWorkSocial: {
					label: "pracovná a sociálna"
				},
				anamnesisFamily: {
					label: "rodinná"
				},
				anamnesisPersonal: {
					label: "osobná"
				},
				currentHealth: {
					label: "súčasné zdravotné ťažkosti"
				},
				currentTherapy: {
					label: "súčasná liečba"
				},

				objectiveFound: {
					label: "Objektívny nález"
				},

				height: {
					label: "výška"
				},
				weight: {
					label: "hmotnosť"
				},
				bp: {
					label: "krvný tlak"
				},
				pulse: {
					label: "pulz"
				},

				///

				funcExamResults: {
					label: "Výsledky funkčných vyšetrení"
				},
				disabilityDiagnosis: {
					label: "Druh zdravotného postihnutia, rozhodujúca diagnóza"
				},
				otherDiagnosis: {
					label: "Ostatné zdravotné postihnutie, ostatné diagnózy"
				},

				// competencyTrueInv: {
				// 	label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.competencyTrue" />,
				// 	type: "checkbox",
				// 	value: true
				// },

				// competencyFalseInv: {
				// 	label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.competencyFalse" />,
				// 	type: "checkbox",
				// 	value: false
				// },

				date: {
					label: <FormattedMessage id="PrintTemplate.MedicalOpinionWeapon.date" />,
					value: moment(),
					type: "date"
				}
			}
		}
	}
}
