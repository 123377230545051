import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				title_before: {
					label: <FormattedMessage id="Common.label.titleBefore" />
				},
				firstname: {
					label: <FormattedMessage id="Common.label.firstName" />,
					rules: "required"
				},
				lastname: {
					label: <FormattedMessage id="Common.label.lastName" />,
					rules: "required"
				},
				title_after: {
					label: <FormattedMessage id="Common.label.titleAfter" />
				},
				birthname: {
					label: <FormattedMessage id="Common.label.birthName" />
				},
				identifier: {
					//definujeme len staticke props ako su label, placeholder, ktore sa nemenia za behu...
					label: <FormattedMessage id="Patient.form.patientinfo.identifier" />,
					rules: "required|between:9,10"
				},
				dateOfBirth: {
					label: <FormattedMessage id="Common.label.dateOfBirth" />,
					type: "date"
				},
				gender: {
					label: <FormattedMessage id="Common.label.gender" />,
					rules: "required"
				},
				employeeCategory: {
					label: <FormattedMessage id="Employee.form.employeeinfo.employeecategory" />,
					rules: "required"
				},
				personalNumber: {
					label: <FormattedMessage id="Employee.form.employeeinfo.personalnumber" />
				},
				cardNumber: {
					label: <FormattedMessage id="Employee.form.employeeinfo.cardnumber" />,
					rules: "required"
				},
				arrivalDate: {
					label: <FormattedMessage id="Employee.form.employeeinfo.arrivaldate" />,
					type: "date"
				},
				departureDate: {
					label: <FormattedMessage id="Employee.form.employeeinfo.departuredate" />,
					type: "date"
				},
				entityId: {}
			}
		}
	}
}
