import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import ReactTooltip from "react-tooltip"
// import moment from "moment"

import {Grid, Dialog, DialogContent, DialogTitle} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

import fields from "./patientTagsFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import DataStore from "../../../stores/DataStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import PatientTagsDialogStore from "../../../stores/PatientTagsDialogStore"

import api from "../../../actions/api"

@injectIntl
@observer
export default class PatientTagsDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				let request = []
				let tempTags = []

				if (PatientTagsDialogStore.isEditable && isSafe(PatientTagsDialogStore.tagId)) {
					tempTags = DataStore.patientDTO.get("tagsChips").filter((tag) => tag._id !== PatientTagsDialogStore.tagId)
				} else {
					if (isSafe(DataStore.patientDTO.get("tagsChips"))) {
						tempTags = isSafe(DataStore.patientDTO.get("tagsChips")) ? DataStore.patientDTO.get("tagsChips") : []
					}
				}

				tempTags.forEach((tag) => {
					request.push({
						type: {_id: tag.tagType, _type: "IXS.CL.Data.TagType"},
						_ref: false,
						_type: "IXS.Data.Tag"
					})
				})

				request.push({
					type: {_id: form.values().tagType, _type: "IXS.CL.Data.TagType"},
					_ref: false,
					_type: "IXS.Data.Tag"
				})

				api
					.savePatientTags(request, DataStore.patientDTO.get("patientID"))
					.call()
					.then(() => {
						api
							.loadTags("Entity.Data.Client", DataStore.patientDTO.get("patientID"), "Patient")
							.call()
							.then((tags) => {
								let tagRecords = []

								tags.forEach((rec) => {
									tagRecords.push({
										_id: rec._id,
										tagType: isSafe(rec.type) ? rec.type._id : "",
										tagName: isSafe(rec.type) ? rec.type.name_ext : "",
										newTagType: "",
										deleteTagType: ""
									})
								})

								DataStore.patientDTO.set("tagsChips", tagRecords)
							})

						form.reset()
						PatientTagsDialogStore.isEditable = false
					})
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	form

	onRowClick = (tag) => {
		PatientTagsDialogStore.isEditable = true
		PatientTagsDialogStore.tagId = tag._id

		this.form.$("tagType").set("value", tag.tagType)
	}

	deletePatientTag = () => {
		if (isSafe(DataStore.patientDTO.get("tagsChips"))) {
			let tagsRecords = DataStore.patientDTO
				.get("tagsChips")
				.filter((tag) => tag._id != GlobalStore.confirmationDialogParams.tagId)

			let request = []

			tagsRecords.forEach((tag) => {
				request.push({
					type: {_id: tag.tagType, _type: "IXS.CL.Data.TagType"},
					_ref: false,
					_type: "IXS.Data.Tag"
				})
			})

			api
				.savePatientTags(request, DataStore.patientDTO.get("patientID"))
				.call()
				.then(() => {
					api
						.loadTags("Entity.Data.Client", DataStore.patientDTO.get("patientID"), "Patient")
						.call()
						.then((tags) => {
							let tagRecords = []

							tags.forEach((rec) => {
								tagRecords.push({
									_id: rec._id,
									tagType: isSafe(rec.type) ? rec.type._id : "",
									tagName: isSafe(rec.type) ? rec.type.name_ext : "",
									newTagType: "",
									deleteTagType: ""
								})
							})

							DataStore.patientDTO.set("tagsChips", tagRecords)
						})

					this.form.reset()
					PatientTagsDialogStore.isEditable = false
				})
		}
	}

	UNSAFE_componentWillUpdate() {
		if (isSafe(PatientTagsDialogStore.tagObj)) {
			PatientTagsDialogStore.insertFormData(this.form)
		}
	}

	render() {
		return (
			<Dialog
				id="xsPatientTagsDialog"
				open={PatientTagsDialogStore.isOpen}
				onClose={() => {
					PatientTagsDialogStore.close()
					PatientTagsDialogStore.isEditable = false
					this.form.reset()
				}}
				className="xs-base-dialog dialog-patienttags"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-450"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								PatientTagsDialogStore.close()
								PatientTagsDialogStore.isEditable = false
								this.form.reset()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-tags fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Patient.sidebar.tags.title" />
						</div>
						<div className="xs-header-subtitle">
							<FormattedMessage id="Patient.sidebar.tags.subtitle" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-overflow-visible">
					<Grid container direction="column">
						<Grid item xs={12} className="xs-form">
							<SearchSelect field={this.form.$("tagType")} items={GlobalStore.CL["patientTagTypes"]} required />
						</Grid>
						{PatientTagsDialogStore.isEditable ? (
							<Grid item xs={12} className="xs-action xs-row xs-justify-between">
								<XsButton
									className="xs-success"
									type="submit"
									onClick={this.form.onSubmit}
									text={<FormattedMessage id="Patient.sidebar.tags.saveTag" />}
									icon={<i className="fal fa-plus fa-lg" />}
								/>
								<XsButton
									className="xs-danger xs-outline"
									onClick={() => {
										PatientTagsDialogStore.isEditable = false
										this.form.reset()
									}}
									text={<FormattedMessage id="Common.label.cancel" />}
									icon={<i className="fal fa-times fa-lg" />}
								/>
							</Grid>
						) : (
							<Grid item xs={12} className="xs-action">
								<XsButton
									className="xs-success"
									type="submit"
									onClick={this.form.onSubmit}
									text={<FormattedMessage id="Patient.sidebar.tags.addTag" />}
									icon={<i className="far fa-plus fa-lg" />}
								/>
							</Grid>
						)}
						<Grid item xs={12} className="xs-items-scrollbar">
							<div className="xs-bold xs-items-title xs-row">
								<FormattedMessage id="Patient.sidebar.tags.allTags" />
								<div className="xs-count-items">
									{isSafe(DataStore.patientDTO.get("tagsChips")) && DataStore.patientDTO.get("tagsChips").length > 0
										? DataStore.patientDTO.get("tagsChips").length
										: 0}
								</div>
							</div>
							<div className="xs-items">
								{isSafe(DataStore.patientDTO.get("tagsChips")) &&
									DataStore.patientDTO.get("tagsChips").map((tag, idx) => {
										return (
											<div key={idx} className="xs-item xs-row xs-flex xs-align">
												<div className="xs-flex xs-bold xs-blueInfo" onClick={() => this.onRowClick(tag)}>
													{tag.tagName}
												</div>
												<div
													className="xs-trash"
													data-tip
													data-for="deleteTag"
													onClick={() => {
														GlobalStore.openConfirmationDialog("xsDeletePatientTag", {tagId: tag._id})
														PatientTagsDialogStore.nameOfDeleteTag = tag.tagName
													}}
												>
													<i className="fal fa-trash-alt fa-lg" />
												</div>
												<ReactTooltip id="deleteTag">
													<FormattedMessage id="Common.label.deleteTag" />
												</ReactTooltip>
											</div>
										)
									})}
							</div>
						</Grid>
						<XsConfirmationDialog
							type="danger"
							name="xsDeletePatientTag"
							text={
								<span className="xs-greyDefault fontSize13">
									<FormattedMessage id="Delete.tag.text" />
								</span>
							}
							subtext={<span className="xs-bold fontSize15">{PatientTagsDialogStore.nameOfDeleteTag}</span>}
							headerConfig={{
								text: "Delete.tag.header.text",
								subtext: "Delete.tag.header.subtext",
								icon: <i className="fal fa-trash-alt fa-2x" />
							}}
							cancelBtn={{
								icon: <i className="fal fa-times fa-lg" />,
								text: "Common.btn.not.delete"
							}}
							confirmBtn={{
								icon: <i className="fal fa-trash-alt fa-lg" />,
								text: "Common.label.deleteTag"
							}}
							onConfirmation={() => this.deletePatientTag()}
						/>
					</Grid>
				</DialogContent>
			</Dialog>
		)
	}
}
