"use strict"

import {observable, action} from "mobx"
// import moment from "moment"
import api from "../actions/api"
// import RouterStore from "../../../global/store/RouterStore"
import {getFilters} from "../../../global/helpers/api"
import {getSelectedOrgUnitResourceID} from "../../../global/helpers/actions"
import Diacritics from "../../../global/helpers/diacritics"
// import GlobalStore from "../../../global/store/GlobalStore"
// import AgreementAttributeStore from "./AgreementAttributeStore"
import UIStore from "../stores/UIStore"
// import settings from "../../../global/config/settings"

class CopyServiceDialogStore {
	@observable isOpen = false
	@observable checkedResourcesIds = []
	@observable checkedOrgunitIds = []
	@observable orgunits = []
	allOrgunits = []
	source_resource_id = null

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@action loadOrgunits() {
		UIStore.isFormSaving = true
		const searchRequest = getFilters([`class_name=Entity.Data.OrgUnit`])
		api
			.loadServiceResources(searchRequest)
			.call()
			.then((response) => {
				this.orgunits = response.rows.map((row) => {
					row.specific_rel["resource_id"] = row.resource._id
					return row.specific_rel
				})

				this.allOrgunits = this.orgunits
				UIStore.isFormSaving = false
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}

	@action copyAgreement(form) {
		let req = {
			source_resource_id: getSelectedOrgUnitResourceID(),
			services: this.checkedResourcesIds,
			target_resources: this.checkedOrgunitIds
		}

		if (isNotEmpty(form.$("endDate").value)) {
			req["invalidate_date"] = form.$("endDate").value
		}

		api
			.copyProvidedServices(req)
			.call()
			.then(() => {
				form.reset()
				this.checkedOrgunitIds = []
				this.checkedResourcesIds = []
				this.close()
			})
	}

	@action agreementFilter(searchValue) {
		delayedCallback(500, () => {
			if (isNotEmpty(searchValue) && searchValue.length > 2) {
				this.orgunits = this.allOrgunits.filter(
					(x) =>
						isSafe(x.name_ext) &&
						Diacritics.remove(x.name_ext)
							.toLowerCase()
							.includes(Diacritics.remove(searchValue).toLowerCase())
				)
			} else {
				this.orgunits = this.allOrgunits
			}
		})
	}
}

var singleton = new CopyServiceDialogStore()
export default singleton
