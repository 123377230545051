import React from "react"

export default class DoveraIcon extends React.Component {
	render() {
		return (
			<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
				<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<path
						d="M12.0003024,24 C5.37271009,24 0,18.6272899 0,11.9996976 C0,5.37271009 5.37271009,7.56728014e-13 12.0003024,7.56728014e-13 L24,7.56728014e-13 L24,11.9996976 C24,18.6272899 18.6272899,24 12.0003024,24"
						id="Fill-1"
						fill="#50A028"
					></path>
					<rect fill="#FFFFFF" x="10" y="5" width="4" height="14"></rect>
					<rect
						fill="#FFFFFF"
						transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
						x="10"
						y="5"
						width="4"
						height="14"
					></rect>
				</g>
			</svg>
		)
	}
}
