import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import renderHTML from "react-render-html"

import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	FormControl,
	Radio,
	RadioGroup,
	// FormLabel,
	FormControlLabel
} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import GetPatientSummaryStore from "../../../stores/GetPatientSummaryStore"

@observer
export default class GetPatientSummaryDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsGetPatientSummaryDialog"
				open={GetPatientSummaryStore.isOpen}
				onClose={() => GetPatientSummaryStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
				className="xs-base-dialog"
			>
				<DialogTitle className={isNotEmpty(GetPatientSummaryStore.errorMessage) ? "xs-danger" : ""}>
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-reply fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Patient.dialog.summary.title" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					{GetPatientSummaryStore.step === 1 ? (
						<div className="xs-row xs-justify">
							<FormControl component="fieldset">
								{/* <FormLabel component="legend">labelPlacement</FormLabel> */}
								<RadioGroup
									aria-label="position"
									className="xs-column"
									name="position"
									value={GetPatientSummaryStore.selectedValue}
									onChange={(e) => GetPatientSummaryStore.handleChange(e)}
									row
								>
									<FormControlLabel
										value="ZdravotneProblemyZavazneDiagnozy"
										control={<Radio color="primary" />}
										label={<FormattedMessage id="Common.label.seriousDiagnoses" />}
									/>
									<FormControlLabel
										value="ZdravProblemZdravotnePomocky"
										control={<Radio color="primary" />}
										label={<FormattedMessage id="Common.label.medicalAis" />}
									/>
									<FormControlLabel
										value="LiekovaAnamneza"
										control={<Radio color="primary" />}
										label={<FormattedMessage id="Common.label.medicalHistory" />}
									/>
									<FormControlLabel
										value="VarovaniaAlergie"
										control={<Radio color="primary" />}
										label={<FormattedMessage id="Common.label.alertAllergy" />}
									/>
									<FormControlLabel
										value="VarovaniaNeziaduceUcinkyOckovani"
										control={<Radio color="primary" />}
										label={<FormattedMessage id="Common.label.adverseEffectsVaccination" />}
									/>
									<FormControlLabel
										value="VysledkyVysetreniKrvnaSkupina"
										control={<Radio color="primary" />}
										label={<FormattedMessage id="Common.label.bloodGroup" />}
									/>
									<FormControlLabel
										value="VysledkyVysetreniKrvnyTlak"
										control={<Radio color="primary" />}
										label={<FormattedMessage id="Common.label.bloodPressure" />}
									/>
									<FormControlLabel
										value="VysledkyVysetreniVitalneHodnoty"
										control={<Radio color="primary" />}
										label={<FormattedMessage id="Common.label.vitalValue" />}
									/>
									<FormControlLabel
										value="SocialnaAnamnezaAbuzus"
										control={<Radio color="primary" />}
										label={<FormattedMessage id="Common.label.anamnesisAbuses" />}
									/>
									<FormControlLabel
										value="OsobnaAnamnezaOckovaciZaznam"
										control={<Radio color="primary" />}
										label={<FormattedMessage id="Common.label.vaccinationRecord" />}
									/>
									<FormControlLabel
										value="OsobnaAnamnezaZdravotneObmedzenia"
										control={<Radio color="primary" />}
										label={<FormattedMessage id="Common.label.healthRestriction" />}
									/>
									<FormControlLabel
										value="OsobnaAnamnezaChirurgickeVykony"
										control={<Radio color="primary" />}
										label={<FormattedMessage id="Common.label.surgicalProcedures" />}
									/>
									<FormControlLabel
										value="OsobnaAnamnezaTerapeutickeOdporucanie"
										control={<Radio color="primary" />}
										label={<FormattedMessage id="Common.label.terapeuticRecommendations" />}
									/>
								</RadioGroup>
							</FormControl>
						</div>
					) : GetPatientSummaryStore.isFetching ? (
						<XsLoading />
					) : (
						<div>
							{isNotEmpty(GetPatientSummaryStore.errorMessage) ? (
								GetPatientSummaryStore.errorMessage
							) : GetPatientSummaryStore.notResponse ? (
								<div>
									<FormattedMessage id="Common.not.find.response" />
								</div>
							) : (
								<div>
									{isNotEmpty(GetPatientSummaryStore.htmlData) ? renderHTML(GetPatientSummaryStore.htmlData) : ""}
								</div>
							)}
						</div>
					)}
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className={isNotEmpty(GetPatientSummaryStore.errorMessage) ? "xs-danger" : "xs-default xs-outline"}
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => GetPatientSummaryStore.close()}
					/>
					{isEmpty(GetPatientSummaryStore.errorMessage) &&
						!GetPatientSummaryStore.nextButton &&
						GetPatientSummaryStore.step === 1 && (
							<XsButton
								className="xs-success"
								icon={<i className="fal fa-reply" />}
								text={<FormattedMessage id="Common.label.ok" />}
								onClick={() => GetPatientSummaryStore.search()}
							/>
						)}
					{isEmpty(GetPatientSummaryStore.errorMessage) && GetPatientSummaryStore.step === 2 && (
						<XsButton
							className="xs-success"
							disabled={!GetPatientSummaryStore.nextButton}
							icon={<i className="fal fa-arrow-right" />}
							text={<FormattedMessage id="Common.label.next" />}
							onClick={() => GetPatientSummaryStore.search()}
						/>
					)}
				</DialogActions>
			</Dialog>
		)
	}
}
