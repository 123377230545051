import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import moment from "moment"
import ReactTooltip from "react-tooltip"

import {Grid, Dialog, DialogContent, DialogTitle} from "@material-ui/core"

import fields from "./patientImplantFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import {
	getUserCompanyInfo,
	getUserPersonnelID,
	getSelectedOrgUnitID,
	getUser,
	getSelectedOrgUnit
} from "../../../../../global/helpers/actions"

// import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
// import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"

import DataStore from "../../../stores/DataStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import PatientImplantDialogStore from "../../../stores/PatientImplantDialogStore"
// import WarningStore from "../../../../../global/store/WarningStore"
import config from "../../../../../global/config/settings"
import api from "../../../actions/api"
import {GWApiCall} from "../../../../../global/helpers/api"
// import settings from "../../../../../global/config/settings"

@injectIntl
@observer
export default class PatientAllergiesDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit(form) {
				if (isEmpty(form.$("implantCode").value) && isEmpty(form.$("implantText").value)) {
					// GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Patient.dialog.implantat.warningMessage"}))
					form.invalidate()
				}
			},
			onSuccess(form) {
				let request = {
					_id: isNotEmpty(PatientImplantDialogStore.implantId) ? PatientImplantDialogStore.implantId : "",
					_ref: false,
					_type: "EHR.Data.Implant",
					implantation_date: isNotEmpty(form.$("implantation_date").value) ? form.$("implantation_date").value : null,
					type: {
						_id: "General",
						_type: "EHR.CL.Report.Type"
					},
					active: true,
					recorded_events: [
						{
							_ref: false,
							_type: "EHR.Data.Record.Event",
							active: true,
							recorded_at: moment().utc(),
							type: {
								_id: "WRT",
								_type: "EHR.CL.Record.EventType"
							}
						}
					],
					specific_rels: [
						{
							_ref: false,
							_type: "EHR.Data.Record.SpecificRel",
							specific_rel: {
								_id: DataStore.patientDTO.get("patientID"),
								_type: "Entity.Data.SpecificRel"
							},
							type: "pat"
						},
						{
							_ref: false,
							_type: "EHR.Data.Record.SpecificRel",
							specific_rel: {
								_id: getUserPersonnelID(),
								_type: "EHR.Data.Entity.Personnel"
							},
							type: "phy"
						},
						{
							_ref: false,
							_type: "EHR.Data.Record.SpecificRel",
							specific_rel: {
								_id: getSelectedOrgUnitID(),
								_type: "Entity.Data.OrgUnit"
							},
							type: "ou"
						}
					],
					validity: {
						from: isSafe(form.$("validFrom").value) ? form.$("validFrom").value.utc() : null,
						to: isSafe(form.$("validTo").value) ? form.$("validTo").value.utc() : null
					}
				}

				if (isNotEmpty(form.$("implantCode").value)) {
					request["group"] = form.$("implantCode").value
					request["description"] = null
				} else {
					request["description"] = form.$("implantText").value
					request["group"] = null
				}

				if (isNotEmpty(form.$("medEquip").value)) {
					request.medical_device = {
						_id: form.$("medEquip").value
					}
				} else {
					request.medical_device = null
				}

				api
					.saveRecord(request, DataStore.patientDTO.get("patientID"))
					.call()
					.then((res) => {
						if (isSafe(res)) {
							if (
								isSafe(res.ixs_signer_data) &&
								isNotEmpty(res.ixs_signer_data.ext_id) &&
								isSafe(res.ixs_signer_data.payload) &&
								isNotEmpty(res.ixs_signer_data.payload.text)
							) {
								const selectedOrgUnit = getSelectedOrgUnit()
								const user = getUser()
								const OUPZS = isSafe(selectedOrgUnit)
									? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID")
									: null
								const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
								const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
								const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
								const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

								const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
									? DataStore.patientDTO.get("patient").validInfo.client
									: null
								const JRUZIDObj = isSafe(patientClientInfo.identifiers)
									? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
									: null

								GWApiCall({
									method: "POST",
									uri: `${config.GW_BASE_URL}/${
										res.ixs_signer_data.operation === "invalidate" ? "InvalidateEHRExtract" : "WriteEHRExtract"
									}`,
									body: JSON.stringify({
										ClassName: res.ixs_signer_data.eh_class,
										ExtId: res.ixs_signer_data.ext_id,
										ArchetypeId: res.ixs_signer_data.archetype_id,
										PreviousExtId: res.ixs_signer_data.previous_ext_id,
										EHRExtract: res.ixs_signer_data.payload.text,
										TimeCommitted: res.actual_time,
										RcIdOID: res.ixs_signer_data.rcid_oid,
										AmbeePatientId: DataStore.patientDTO.get("patientID"),
										PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
										PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
										AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
										AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
										AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
										AmbeePersonnelId: getUserPersonnelID(),
										OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
										PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
										PersonnelExpertiseJRUZVersion: specVer,
										PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
										RecordSignerData: isSafe(res.record_signer_data)
											? res.record_signer_data.map((x) => {
													return {
														Ref: x._ref,
														Type: x._type,
														RecordClass: x.record_class,
														RecordExternalId: x.record_external_id,
														RecordId: x.record_id,
														QueueId: x.queue_id
													}
											  })
											: null
									})
								})
									.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
									.then((data) => {
										// api.setSent(res.ixs_signer_data.ext_id).call()
										if (isSafe(data) && isNotEmpty(data.ErrorMessage)) {
											GlobalStore.checkEHGWError(data.ErrorCode, data.ErrorMessage, data.IsEhealthException)
											// WarningStore.generateErrorMessage(data.IsEhealthException, data.ErrorMessage)
										}
									})
									.catch()
							}

							api
								.loadImplant(getUserCompanyInfo()._id, DataStore.patientDTO.get("patientID"))
								.call()
								.then((implants) => {
									if (isSafe(implants) && isSafe(implants.records)) {
										DataStore.patientDTO.set("implants", implants.records)
									}
								})
						}

						PatientImplantDialogStore.implantId = undefined
						PatientImplantDialogStore.isEditable = false
						form.reset()
					})
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	form

	onRowClick = (implant) => {
		if (isSafe(implant)) {
			PatientImplantDialogStore.implantId = implant._id

			if (isNotEmpty(implant.description)) {
				this.form.$("implantText").set("value", implant.description)
			} else {
				if (isSafe(implant.group) && isSafe(implant.group._id)) {
					this.form.$("implantCode").set("value", implant.group._id)
				}
			}

			if (isNotEmpty(implant.implantation_date)) {
				this.form.$("implantation_date").set("value", moment(implant.implantation_date))
			}

			if (isSafe(implant.validity)) {
				this.form.$("validFrom").set("value", isNotEmpty(implant.validity.from) ? moment(implant.validity.from) : null)
				this.form.$("validTo").set("value", isNotEmpty(implant.validity.to) ? moment(implant.validity.to) : null)
			}

			if (isSafe(implant.medical_device) && isNotEmpty(implant.medical_device._id)) {
				this.form.$("medEquip").value = implant.medical_device._id
			}

			PatientImplantDialogStore.isEditable = true
		}
	}

	deletePatientImplant = () => {
		const req = {
			reason: "STORNO"
		}

		api
			.deleteRecord(GlobalStore.confirmationDialogParams.implantId, req)
			.call()
			.then((res) => {
				if (isSafe(res)) {
					if (isSafe(res.ixs_signer_data)) {
						const selectedOrgUnit = getSelectedOrgUnit()
						const user = getUser()
						const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
						const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
						const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
						const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
						const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

						const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
							? DataStore.patientDTO.get("patient").validInfo.client
							: null
						const JRUZIDObj = isSafe(patientClientInfo.identifiers)
							? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
							: null

						GWApiCall({
							method: "POST",
							uri: `${config.GW_BASE_URL}/CancelEHRExtract`,
							body: JSON.stringify({
								ClassName: "ZrusZapisZPacientskehoSumaru_v2",
								ExtId: res.ixs_signer_data.ext_id,
								PreviousExtId: res.ixs_signer_data.previous_ext_id,
								ArchetypeId: res.ixs_signer_data.archetype_id,
								RcIdOID: res.ixs_signer_data.rcid_oid,
								AmbeePatientId: DataStore.patientDTO.get("patientID"),
								PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
								PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
								AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
								AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
								AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
								AmbeePersonnelId: getUserPersonnelID(),
								OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
								PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
								PersonnelExpertiseJRUZVersion: specVer,
								PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
								RecordSignerData: isSafe(res.record_signer_data)
									? res.record_signer_data.map((x) => {
											return {
												Ref: x._ref,
												Type: x._type,
												RecordClass: x.record_class,
												RecordExternalId: x.record_external_id,
												RecordId: x.record_id,
												QueueId: x.queue_id
											}
									  })
									: null
							})
						})
							.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
							.then((data) => {
								// api.setSent(res.ixs_signer_data.ext_id).call()
								if (isSafe(data) && isNotEmpty(data.ErrorMessage)) {
									GlobalStore.checkEHGWError(data.ErrorCode, data.ErrorMessage, data.IsEhealthException)
									// WarningStore.generateErrorMessage(data.IsEhealthException, data.ErrorMessage)
								}
							})
							.catch()
					}
					api
						.loadImplant(getUserCompanyInfo()._id, DataStore.patientDTO.get("patientID"))
						.call()
						.then((implants) => {
							if (isSafe(implants) && isSafe(implants.records)) {
								DataStore.patientDTO.set("implants", implants.records)
							}
						})
				}

				PatientImplantDialogStore.implantId = undefined
				PatientImplantDialogStore.isEditable = false
				this.form.reset()
			})
	}

	UNSAFE_componentWillUpdate() {
		if (isSafe(PatientImplantDialogStore.implantObj)) {
			PatientImplantDialogStore.insertFormData(this.form)
		}
	}

	render() {
		return (
			<Dialog
				id="xsPatientImplantDialog"
				open={PatientImplantDialogStore.isOpen}
				onClose={() => {
					PatientImplantDialogStore.close()
					PatientImplantDialogStore.isEditable = false
					this.form.reset()
				}}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-600"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								PatientImplantDialogStore.close()
								PatientImplantDialogStore.isEditable = false
								this.form.reset()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-scalpel-path fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Patient.sidebar.implants" />
						</div>
						<div className="xs-header-subtitle">
							<FormattedMessage id="Patient.sidebar.implant.subtitle" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-overflow-visible">
					<Grid container direction="column">
						<Grid item xs={12} className="xs-form">
							{/* <SearchSelect field={this.form.$("implantCode")} items={GlobalStore.CL["implantGroup"]} /> */}
							<XsAutocomplete
								field={this.form.$("implantCode")}
								minLengthForSearch="3"
								api={api.loadImplantGroup}
								inputRenderer={(obj) => obj.name_ext}
								saveValue={(obj) => obj.code}
								freeText={true}
								freeTextField={this.form.$("implantText")}
								postAction={(obj) => {
									this.form.$("implantText").value = obj.name_ext
								}}
								onClear={() => {
									this.form.$("implantText").value = ""
									this.form.$("implantCode").value = ""
								}}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: (
													<FormattedMessage id="Patient.form.patientrequest.expertise.autoCompleteModal.CodeColumn" />
												),
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px",
													header: {
														className: "xs-autoCompleteModalColumnHeader"
													}
												}
											},
											name_ext: {
												title: <FormattedMessage id="Common.label.name" />,
												type: "string",
												dbName: "search_column",
												design: {
													header: {
														className: "xs-autoCompleteModalColumnHeader"
													}
												},
												filter: {
													gridWidth: 3,
													defaultValue: textValue,
													renderElement: "input"
												},
												sortable: true
											}
										},
										options: {
											// selectRow: true,
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
							<Grid container spacing={8}>
								<Grid item xs={6}>
									<XsAutocomplete
										field={this.form.$("medEquip")}
										minLengthForSearch="3"
										api={api.loadMedDev}
										inputRenderer={(obj) => obj.code_ext + " | " + obj.name_ext + " | " + obj.supplement}
										saveValue={(obj) => obj._id}
										// postAction={(value) => {}}
										// onClear={() => {}}
										modalConfig={(textValue, clickHandler) => {
											return {
												columnDefs: {
													code_ext: {
														title: (
															<FormattedMessage id="Patient.form.patientrequest.expertise.autoCompleteModal.CodeColumn" />
														),
														type: "string",
														dbName: "code_ext",
														design: {
															width: "150px",
															header: {
																className: "xs-autoCompleteModalColumnHeader"
															}
														}
													},
													name_ext: {
														title: <FormattedMessage id="Common.label.name" />,
														type: "string",
														dbName: "search_column",
														design: {
															header: {
																className: "xs-autoCompleteModalColumnHeader"
															}
														},
														filter: {
															gridWidth: 3,
															defaultValue: textValue,
															renderElement: "input"
														},
														sortable: true
													}
												},
												options: {
													// selectRow: true,
													showCursor: true,
													onRowClick: (dataRow) => {
														clickHandler(dataRow)
													}
												}
											}
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<XsDateTimePicker field={this.form.$("implantation_date")} showTimeSelect={false} />
								</Grid>
							</Grid>

							<Grid container spacing={8}>
								<Grid item xs={6}>
									<XsDateTimePicker field={this.form.$("validFrom")} showTimeSelect={false} />
								</Grid>
								<Grid item xs={6}>
									<XsDateTimePicker field={this.form.$("validTo")} showTimeSelect={false} />
								</Grid>
							</Grid>
						</Grid>
						{PatientImplantDialogStore.isEditable ? (
							<Grid item xs={12} className="xs-action xs-row xs-justify-between">
								<XsButton
									className="xs-success"
									type="submit"
									onClick={(e) => {
										PatientImplantDialogStore.implantId = ""
										this.form.onSubmit(e)
									}}
									text={<FormattedMessage id="Patient.sidebar.implant.saveImplant" />}
									icon={<i className="fal fa-plus fa-lg" />}
								/>
								<XsButton
									className="xs-danger xs-outline"
									onClick={() => {
										PatientImplantDialogStore.isEditable = false
										this.form.reset()
									}}
									text={<FormattedMessage id="Common.label.cancel" />}
									icon={<i className="fal fa-times fa-lg" />}
								/>
							</Grid>
						) : (
							<Grid item xs={12} className="xs-action">
								<XsButton
									className="xs-success"
									type="submit"
									onClick={this.form.onSubmit}
									text={<FormattedMessage id="Patient.sidebar.implant.addImplant" />}
									icon={<i className="fal fa-plus fa-lg" />}
								/>
							</Grid>
						)}
						<Grid item xs={12} className="xs-items-scrollbar">
							<div className="xs-bold xs-items-title xs-row">
								{this.props.intl.formatMessage({id: "Patient.sidebar.implant.allImplants"}).toUpperCase()}
								<div className="xs-count-items">
									{isSafe(DataStore.patientDTO.get("implants")) && DataStore.patientDTO.get("implants").length > 0
										? DataStore.patientDTO.get("implants").length
										: 0}
								</div>
							</div>
							<div className="xs-items">
								{isSafe(DataStore.patientDTO.get("implants")) &&
									DataStore.patientDTO.get("implants").map((implant, idx) => {
										let implantNameExt =
											isSafe(implant) && isSafe(implant.group) && isSafe(implant.group.name_ext)
												? implant.group.name_ext
												: ""
										return (
											<div key={idx} className="xs-item xs-row xs-flex">
												<div className="xs-item-content" onClick={() => this.onRowClick(implant)}>
													<div className="xs-column xs-flex xs-justify">
														<div className="xs-primary-info xs-blueInfo">
															{isNotEmpty(implantNameExt)
																? implantNameExt.shortTo(45)
																: isNotEmpty(implant.description)
																? implant.description.shortTo(45)
																: ""}
														</div>
													</div>
												</div>
												<div
													data-tip
													data-for="deleteImplant"
													className="xs-trash"
													onClick={() => {
														GlobalStore.openConfirmationDialog("xsDeletePatientImplant", {implantId: implant._id}),
															(PatientImplantDialogStore.nameOfDeleteImplant = isNotEmpty(implantNameExt)
																? implantNameExt.shortTo(45)
																: isNotEmpty(implant.description)
																? implant.description.shortTo(45)
																: "")
													}}
												>
													<i className="fal fa-trash-alt fa-lg" />
												</div>
												<ReactTooltip id="deleteImplant">
													<FormattedMessage id="Common.label.deleteImplant" />
												</ReactTooltip>
											</div>
										)
									})}
							</div>
						</Grid>
						<XsConfirmationDialog
							type="danger"
							name="xsDeletePatientImplant"
							text={
								<span className="xs-greyDefault fontSize13">
									<FormattedMessage id="Title.implant.text" />
								</span>
							}
							subtext={<span className="xs-bold fontSize15">{PatientImplantDialogStore.nameOfDeleteImplant}</span>}
							headerConfig={{
								text: "Delete.implant.header.text",
								subtext: "Delete.implant.header.subtext",
								icon: <i className="fal fa-trash-alt fa-2x" />
							}}
							cancelBtn={{
								icon: <i className="fal fa-times fa-lg" />,
								text: "Common.btn.not.delete"
							}}
							confirmBtn={{
								icon: <i className="fal fa-trash-alt fa-lg" />,
								text: "Common.label.deleteImplant"
							}}
							onConfirmation={() => this.deletePatientImplant()}
						/>
					</Grid>
				</DialogContent>
			</Dialog>
		)
	}
}
