import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import {Document, Page} from "react-pdf"

import {Dialog, DialogContent, DialogTitle, DialogActions, Grid} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

import CovidCertificatesDialogStore from "../../../stores/CovidCertificatesDialogStore"

@injectIntl
@observer
export default class ShowCovidCertificatesDialog extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			numPages: null,
			pageNumber: 1
		}
	}

	onDocumentLoadSuccess = ({numPages}) => {
		this.setState({numPages})
	}

	render() {
		return (
			<Dialog
				id="xsShowCovidCertificatesDialog"
				open={CovidCertificatesDialogStore.isOpenPDF}
				onClose={() => CovidCertificatesDialogStore.closePDF()}
				disableBackdropClick={true}
				maxWidth="md"
				className="xs-base-dialog"
				classes={{
					paper: "overflowSearch xs-width-paper-1050"
				}}
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-book-alt fa-2x" />
						</div>
						<div className="xs-header-title">Certificate</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<Grid container direction="column">
						{CovidCertificatesDialogStore.loadingPDF ? (
							<XsLoading />
						) : (
							<Document
								file={`data:application/pdf;base64,${CovidCertificatesDialogStore.covidCertPDF}`}
								onLoadSuccess={this.onDocumentLoadSuccess}
							>
								<Page width={990} pageNumber={this.state.pageNumber} />
							</Document>
						)}
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => CovidCertificatesDialogStore.closePDF()}
					/>
					<XsButton
						className="xs-primary xs-outline"
						icon={<i className="fal fa-download" />}
						text={<FormattedMessage id="Common.label.downloadPDF" />}
						onClick={() => {
							const byteCharacters = atob(CovidCertificatesDialogStore.covidCertPDF)
							const byteNumbers = new Array(byteCharacters.length)
							for (let i = 0; i < byteCharacters.length; i++) {
								byteNumbers[i] = byteCharacters.charCodeAt(i)
							}
							const byteArray = new Uint8Array(byteNumbers)
							const blob = new Blob([byteArray], {type: "application/pdf"})

							var FileSaver = require("file-saver") // eslint-disable-line no-undef
							FileSaver.saveAs(blob, "certificate")
						}}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
