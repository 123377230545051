import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import XsTable from "../../../../global/ui/xsTable/xsTable"
import XsIconButton from "../../../../global/ui/xsButton/xsIconButton"
import XsButton from "../../../../global/ui/xsButton/xsButton"
import XsConfirmationDialog from "../../../../global/ui/xsDialog/xsConfirmationDialog"

import GlobalStore from "../../../../global/store/GlobalStore"
import OrgunitDetailStore from "../../stores/OrgunitDetailStore"
import EmployeesListStore from "../../stores/EmployeesListStore"
import RouterStore from "../../../../global/store/RouterStore"
import AddOrgunitDialog from "./addOrgunitDialog"
import AddOrgunitDialogStore from "../../stores/AddOrgunitDialogStore"

@observer
export default class OrgunitsList extends React.Component {
	constructor(props) {
		super(props)

		if (isNotEmpty(props.companyId)) {
			EmployeesListStore.loadEntityOrgunits(props.companyId)
		} else {
			EmployeesListStore.loadOrgunits(props.companyId)
		}
	}

	onHandleRowClick = (dataRow) => {
		RouterStore.push(`/orgunit?id=${dataRow.org_unit_id}`)
	}

	handleAddClick = () => {
		if (isNotEmpty(this.props.companyId)) {
			RouterStore.push(`/orgunit?id=new&companyId=${this.props.companyId}`)
		} else {
			RouterStore.push(`/orgunit?id=new`)
		}
	}

	render() {
		return (
			<Grid container className="xs-orgunit-grid">
				<Grid item container justify="flex-end" xs={12}>
					<Grid item>
						<XsButton
							icon={<i className="fal fa-hospital fa-lg"></i>}
							className="xs-primary"
							text={<FormattedMessage id="Orgunits.orgunit.addOrgunit" />}
							onClick={() => AddOrgunitDialogStore.open()}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<XsTable
						config={{
							columnDefs: {
								code: {
									title: <FormattedMessage id="Orgunits.orgunit.form.code" />,
									type: "string",
									design: {
										width: "20%"
									},
									sortable: true
								},
								name: {
									title: <FormattedMessage id="Common.label.name" />,
									type: "string",
									design: {
										width: "20%"
									},
									sortable: true
								},
								OUPZS_code: {
									title: <FormattedMessage id="Orgunits.orgunit.form.OUPZScode" />,
									type: "string",
									design: {
										width: "20%"
									},
									sortable: true
								},
								expertise: {
									title: <FormattedMessage id="Orgunits.orgunit.form.expertise" />,
									type: "string",
									design: {
										width: "20%"
									},
									sortable: true
								},
								JRUZ_id: {
									title: <FormattedMessage id="Orgunits.orgunit.form.JRUZid" />,
									type: "string",
									design: {
										width: "20%"
									},
									sortable: true
								},
								delete: {
									title: "",
									type: "action",
									design: {
										width: "50px",
										body: {
											renderer: (props) => {
												return (
													<XsIconButton
														className="action-delete"
														icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault"></i>}
														onClick={() => {
															OrgunitDetailStore.fullName = props.value.fullName
															GlobalStore.openConfirmationDialog("xsDeleteOrgunits", {
																orgUnitId: props.value.org_unit_id
															})
														}}
													/>
												)
											}
										}
									}
								}
							},
							options: {
								// hideHeader: true,
								showCursor: true,
								// selectRow: true,
								onRowClick: (dataRow) => {
									this.onHandleRowClick(dataRow)
								},
								mapper: (dataRow) => {
									return {
										name: isSafe(dataRow.name_ext) ? dataRow.name_ext : "",
										code: isSafe(dataRow.code_ext) ? dataRow.code_ext : "",
										OUPZS_code:
											isSafe(dataRow.identifiers) && isSafe(dataRow.identifiers.find((i) => i.type === "PROVIDERCODE"))
												? dataRow.identifiers.find((i) => i.type === "PROVIDERCODE").value
												: "",
										expertise: isSafe(dataRow.cl_expertise)
											? dataRow.cl_expertise.code_ext + " " + dataRow.cl_expertise.name_ext
											: "",
										JRUZ_id:
											isSafe(dataRow.identifiers) && isSafe(dataRow.identifiers.find((i) => i.type === "JRUZID"))
												? dataRow.identifiers.find((i) => i.type === "JRUZID").value
												: "",
										org_unit_id: isNotEmpty(dataRow.org_unit_id) ? dataRow.org_unit_id : dataRow.provider_id,
										delete: {
											org_unit_id: isNotEmpty(dataRow.org_unit_id) ? dataRow.org_unit_id : dataRow.provider_id,
											fullName: isSafe(dataRow.name_ext) ? dataRow.name_ext : ""
										}
									}
								}
							},
							dataSource: EmployeesListStore.orgunits
						}}
					/>
				</Grid>
				{AddOrgunitDialogStore.isOpen && <AddOrgunitDialog />}
				<XsConfirmationDialog
					type="danger"
					name="xsDeleteOrgunits"
					text={
						<span className="xs-greyDefault fontSize13">
							<FormattedMessage id="Delete.orgunit.text" />
						</span>
					}
					subtext={<span className="xs-bold fontSize15">{OrgunitDetailStore.fullName}</span>}
					headerConfig={{
						text: "Delete.orgunit.header.text",
						subtext: "Delete.orgunit.header.subtext",
						icon: <i className="fal fa-trash-alt fa-2x" />
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.not.delete"
					}}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.label.delete"
					}}
					onConfirmation={() => OrgunitDetailStore.deleteOrgunit()}
				/>
			</Grid>
		)
	}
}
