import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsSearchdropdownCache from "../../../../../global/ui/xsSearchdropdown/xsSearchdropdownCache"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import DeliveriesByCodeStore from "../../../stores/DeliveriesByCodeStore"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import TableStore from "../../../stores/TableStore"
import RegistersCacheStore from "../../../../../global/store/RegistersCacheStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

import {getUserPersonnelOrgUnits} from "../../../../../global/helpers/actions"

// definitions of form fields
import fields from "./deliveriesByCodeFilterFields"

import moment from "moment"

@injectIntl
@observer
export default class DeliveriesByCodeList extends React.Component {
	constructor(props) {
		super(props)
		DeliveriesByCodeStore.isFetching = false

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				if (
					TableStore.tables.hasOwnProperty("deliveriesByCodeList") &&
					TableStore.tables["deliveriesByCodeList"].hasOwnProperty("index")
				) {
					delete TableStore.tables["deliveriesByCodeList"]["index"]
				}

				DeliveriesByCodeStore.loadDeliveries(form)
			},
			onError() {}
		}

		if (isEmpty(DeliveriesByCodeStore.formRef)) {
			DeliveriesByCodeStore.formRef = new MobxReactForm(fields.load(), {
				plugins: {dvr: validatorjs},
				hooks,
				bindings
			})
		}

		this.form = DeliveriesByCodeStore.formRef

		DeliveriesByCodeStore.loadDeliveries(this.form)
	}

	onHandleRowClick = (dataRow) => {
		if (dataRow.person_id) {
			GlobalStore.changeOrgUnitAndRouteToPatientDetail(dataRow.provider_id, dataRow.person_id)
		}
	}

	componentWillUnmount() {
		DeliveriesByCodeStore.deliveriesData = []
	}

	render() {
		DeliveriesByCodeStore.isChangedFilter(this.form.values())

		return (
			<Grid container className="xs-medicalRecordReport-list">
				<Grid item xs={12}>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}>
							<XsSearchSelect white field={this.form.$("orgunit")} items={getUserPersonnelOrgUnits()} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker white field={this.form.$("date_from")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker white field={this.form.$("date_to")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={2} className="mt-1">
							<XsSearchdropdownCache
								selectedItems={
									isSafe(this.form.$("dgn").value) && this.form.$("dgn").value.length ? this.form.$("dgn").value : []
								}
								sourceItems={RegistersCacheStore.diagnosisRegister}
								multiSelect={true}
								id="patientListContainer_diagnoses"
								label={<FormattedMessage id="Common.label.diagnosis" />}
								onClose={(ids) => {
									if (ids.length == 0) {
										this.form.$("dgn").value = []
									} else {
										this.form.$("dgn").value = ids
									}
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsInput white field={this.form.$("deliveries")} />
						</Grid>
						<Grid item xs={1} className="pb-0">
							<XsIconButton
								rect
								className="xs-default xs-outline"
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								onClick={this.form.onSubmit}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
					</Grid>
					{DeliveriesByCodeStore.isFetching ? (
						<XsLoading />
					) : DeliveriesByCodeStore.changedFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i className="far fa-search fa-lg mr-3 pointer" onClick={this.form.onSubmit}></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTable
							config={{
								columnDefs: {
									provider_code: {
										title: <FormattedMessage id="Common.label.provider" />,
										type: "string",
										design: {
											width: "175px"
										}
									},
									identifier: {
										title: <FormattedMessage id="Patient.form.patientinfo.identifier" />,
										type: "string",
										design: {
											width: "150px"
										}
									},
									full_name: {
										title: <FormattedMessage id="Patient.labels.patient" />,
										type: "string",
										design: {
											width: "175px",
											body: {
												className: "blueDark xs-bold"
											}
										},
										sortable: true
									},
									insurances: {
										title: <FormattedMessage id="Common.label.insurer" />,
										type: "string",
										design: {
											width: "120px"
										}
									},
									delivery_date: {
										title: "Dátum výkonu",
										type: "string",
										design: {
											width: "100px"
										}
									},
									delivery: {
										title: "Výkon",
										type: "string"
									},
									deliveryCount: {
										title: "Počet",
										type: "string",
										design: {
											width: "75px"
										}
									},
									diagnosis: {
										title: <FormattedMessage id="Common.label.diagnose" />,
										type: "string",
										design: {
											width: "200px"
										}
									}
								},
								options: {
									name: "deliveriesByCodeList",
									showCursor: true,
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										const dgn_code = isSafe(dataRow.diagnosis_code_ext) ? dataRow.diagnosis_code_ext : ""
										const dgn_name = isSafe(dataRow.diagnosis_name_ext) ? dataRow.diagnosis_name_ext : ""
										const insurer_code = isSafe(dataRow.insurer_code_ext) ? dataRow.insurer_code_ext : ""
										const insurer_name = isSafe(dataRow.insurer_name_ext) ? dataRow.insurer_name_ext : ""
										const delivery_code = isSafe(dataRow.delivery_code_ext) ? dataRow.delivery_code_ext : ""
										const delivery_name = isSafe(dataRow.delivery_name_ext) ? dataRow.delivery_name_ext : ""

										return {
											provider_code: isSafe(dataRow.provider_code) ? dataRow.provider_code : "",
											provider_id: isSafe(dataRow.provider_id) ? dataRow.provider_id : "",
											full_name: isSafe(dataRow.full_name) ? dataRow.full_name : "",
											identifier: isSafe(dataRow.identifier) ? dataRow.identifier : "",
											diagnosis: `${dgn_code} - ${dgn_name}`,
											insurances: `${insurer_code} - ${insurer_name}`,
											delivery_date: isNotEmpty(dataRow.delivery_date_time)
												? moment(dataRow.delivery_date_time).format("DD.MM.YYYY")
												: "",
											delivery: `${delivery_code} - ${delivery_name}`,
											deliveryCount: isSafe(dataRow.count) ? dataRow.count : "",
											person_id: isSafe(dataRow.person_id) ? dataRow.person_id : ""
										}
									}
								},
								dataSource: DeliveriesByCodeStore.deliveriesData
							}}
						/>
					)}
				</Grid>
				{/* {UIStore.isFormSaving && <XsLoading overlay={true} />} */}
			</Grid>
		)
	}
}
