"use strict"

// import {observable, action} from "mobx"
import moment from "moment"
import api from "../actions/api"
import DataStore from "../stores/DataStore"
import {getSelectedOrgUnitID, getUserPersonnelID} from "../../../global/helpers/actions"

class PatientCardContainerStore {
	loadAllDraftsBtnRef = null

	loadTodaysDrafts = async () => {
		const filter = {
			filters: [
				{
					associated_column: "patient_id",
					values: [
						{
							id_value: DataStore.patientDTO.get("patientID")
						}
					]
				},
				{
					associated_column: "provider_id",
					values: [
						{
							id_value: getSelectedOrgUnitID()
						}
					]
				},
				{
					associated_column: "personnel_id",
					values: [
						{
							id_value: getUserPersonnelID()
						}
					]
				}
			],
			time_from: moment().startOf("day"),
			time_to: moment().endOf("day")
		}
		let drafts = await api.loadAllDrafts(filter).call()
		return drafts
	}
}

var singleton = new PatientCardContainerStore()
export default singleton
