import React from "react"
import {observer} from "mobx-react"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import ReactTooltip from "react-tooltip"
import {FormattedMessage} from "react-intl"

import "./xsCheckbox.less"
import {getFieldIdentifier} from "../../helpers/actions"

@observer
export default class XsCheckbox extends React.Component {
	constructor(props) {
		super(props)
	}

	handleChange = (field) => (e) => {
		const {onChange} = this.props
		field.set("value", e.target.value == "true" ? false : true)
		if (typeof onChange === "function") {
			onChange(field)
		}
	}

	render() {
		const {field} = this.props
		let classIdentifier = getFieldIdentifier(field, "xs-checkbox")

		return (
			<div>
				<FormControlLabel
					data-tip
					data-for={isNotEmpty(this.props.tooltipId) ? `checkboxTooltip${field.id}` : ""}
					control={
						<Checkbox
							{...field.bind({
								checked: field.get("value"),
								value: "" + field.get("value"),
								onChange: this.handleChange(field)
							})}
							disabled={this.props.disabled}
							className={classIdentifier}
						/>
					}
					label={field.label}
					classes={{
						label: `${this.props.shrinkLabel ? "shrinkLabel" : ""} ${this.props.warningLabel ? "xs-amberWarning" : ""}`
					}}
				/>
				{isSafe(this.props.tooltipId) && (
					<ReactTooltip id={`checkboxTooltip${field.id}`} place="bottom" effect="solid">
						<FormattedMessage id={this.props.tooltipId} />
					</ReactTooltip>
				)}
			</div>
		)
	}
}
