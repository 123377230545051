import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import moment from "moment"

import bindings from "../../../../../global/ui/globalUISchemeBindings"
import {FormattedMessage, injectIntl} from "react-intl"

import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"

import Grid from "@material-ui/core/Grid"

import fields from "./employeeContractFormFields"
import saveBindings from "./employeeContractFormBidings"
import {insertFormDataByScheme} from "../../../../../global/helpers/bindings"

import EmployeesDetailStore from "../../../stores/EmployeesDetailStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import settings from "../../../../../global/config/settings"

@injectIntl
@observer
export default class EmployeeContractForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			editable: false,
			isAdd: false
		}

		EmployeesDetailStore.loadEmployeeContrats(props.employeeId)
		EmployeesDetailStore.loadEmployeeOrgunits()

		const hooks = {
			onSubmit(/*form*/) {},
			onSuccess(form) {
				form.$("capacityTransformed").value = +form.$("capacity").value / 100
				insertFormDataByScheme(
					form,
					saveBindings,
					{employeeId: props.employeeId, employmentContractId: EmployeesDetailStore.employeeContratseditingId},
					() => (
						EmployeesDetailStore.loadEmployeeContrats(props.employeeId),
						form.reset(),
						(EmployeesDetailStore.employeeContratseditingId = "")
					),
					false,
					"EmployeeDetail.employeeContracts.contractSaved"
				)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}
		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	onHandleRowClick = (dataRow) => {
		this.form.$("org_unit").set(dataRow.org_unitId)
		this.form.$("capacity").set(isNotEmpty(dataRow.capacity) ? parseInt(dataRow.capacity.replace("%", "")) : "")
		this.form
			.$("validFrom")
			.set(isNotEmpty(dataRow.validFrom) ? moment(dataRow.validFrom, "DD.MM.YYYY").format(settings.DB_DATE_FORMAT) : "")
		this.form
			.$("validTo")
			.set(isNotEmpty(dataRow.validTo) ? moment(dataRow.validTo, "DD.MM.YYYY").format(settings.DB_DATE_FORMAT) : "")
		EmployeesDetailStore.employeeContratseditingId = dataRow.employment_contract_id
		this.setState({
			editable: true,
			isAdd: true
		})
	}

	deleteContract = () => {
		EmployeesDetailStore.deleteEmpContract(GlobalStore.confirmationDialogParams.contractId, () =>
			EmployeesDetailStore.loadEmployeeContrats(this.props.employeeId)
		)
	}

	render() {
		return (
			<div className="pa-8">
				<Grid container direction="column" className="bgWhite borderRadius-2 borderGreyLight">
					<Grid item className="pa-6">
						<XsTable
							config={{
								columnDefs: {
									org_unit: {
										title: <FormattedMessage id="EmployeeDetail.employeeContracts.org_unit" />,
										type: "string",
										design: {
											width: "30%"
										},
										sortable: true
									},
									capacity: {
										title: <FormattedMessage id="EmployeeDetail.employeeContracts.capacity" />,
										type: "string",
										design: {
											width: "30%"
										},
										sortable: true
									},
									validFrom: {
										title: <FormattedMessage id="EmployeeDetail.employeeContracts.validFrom" />,
										type: "string",
										design: {
											width: "20%"
										},
										sortable: true
									},
									validTo: {
										title: <FormattedMessage id="EmployeeDetail.employeeContracts.validTo" />,
										type: "string",
										design: {
											width: "20%"
										}
									},
									delete: {
										title: "",
										type: "action",
										design: {
											body: {
												renderer: (props) => {
													return (
														<XsIconButton
															className="action-delete"
															icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
															onClick={() =>
																GlobalStore.openConfirmationDialog("xsDeletePatientContract", {contractId: props.value})
															}
														/>
													)
												}
											}
										}
									}
								},
								options: {
									hidePager: true,
									showCursor: true,
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										return {
											org_unit: isSafe(dataRow.org_unit_name_ext) ? dataRow.org_unit_name_ext : "",
											org_unitId: dataRow.org_unit_id,
											employment_contract_id: dataRow.employment_contract_id,
											capacity: isSafe(dataRow.capacity) ? +dataRow.capacity * 100 + "%" : "",
											validFrom:
												isSafe(dataRow.validity) && isNotEmpty(dataRow.validity.from)
													? moment(dataRow.validity.from, settings.DB_DATE_FORMAT).format("DD.MM.YYYY")
													: "",
											validTo:
												isSafe(dataRow.validity) && isNotEmpty(dataRow.validity.to)
													? moment(dataRow.validity.to, settings.DB_DATE_FORMAT).format("DD.MM.YYYY")
													: "",
											delete: dataRow.employment_contract_id
										}
									}
								},
								dataSource: EmployeesDetailStore.employeeContrats
							}}
						/>
					</Grid>
					<Grid item className="fomBorderTop pt-4 pb-2 pl-6 pr-6">
						{this.state.isAdd ? (
							<form>
								<Grid container direction="row" align="center" spacing={8}>
									<Grid item xs={2}>
										<SearchSelect field={this.form.$("org_unit")} items={EmployeesDetailStore.orgunits} required />
									</Grid>
									<Grid item xs={2}>
										<XsInput field={this.form.$("capacity")} />
									</Grid>
									<Grid item xs={1}>
										<XsDateTimePicker field={this.form.$("validFrom")} />
									</Grid>
									<Grid item xs={1}>
										<XsDateTimePicker field={this.form.$("validTo")} />
									</Grid>
									<Grid item container justify="flex-end" alignItems="center" xs={6} spacing={8} className="mt-0">
										{this.state.editable ? (
											<Grid item>
												<XsButton
													className="xs-success"
													type="submit"
													onClick={(e) => {
														this.form.onSubmit(e)
														this.setState({
															editable: false
														})
													}}
													text={<FormattedMessage id="Common.label.save" />}
													icon={<i className="fal fa-check fa-lg" />}
												/>
											</Grid>
										) : (
											<Grid item>
												<XsButton
													className="xs-success"
													type="submit"
													onClick={this.form.onSubmit}
													text={<FormattedMessage id="Common.label.add" />}
													icon={<i className="fal fa-check fa-lg" />}
												/>
											</Grid>
										)}
										<Grid item>
											<XsButton
												className="xs-danger xs-outline mr-3"
												text={<FormattedMessage id="Common.label.cancel" />}
												onClick={() => {
													this.form.reset()
													this.setState({
														editable: false,
														isAdd: false
													})
												}}
												icon={<i className="fal fa-times fa-lg"></i>}
											/>
										</Grid>
									</Grid>
								</Grid>
							</form>
						) : (
							<Grid item className="pb-2 pt-2">
								<XsButton
									className="xs-primary xs-outline"
									icon={<i className="fal fa-plus fa-lg" />}
									text={<FormattedMessage id="Common.button.addAnother" />}
									onClick={() => {
										this.setState({
											isAdd: true,
											editable: false
										})
									}}
								/>
							</Grid>
						)}
					</Grid>
					<XsConfirmationDialog
						type="danger"
						name="xsDeletePatientContract"
						text={<FormattedMessage id="Patient.form.contractForm.confirmationMessage" />}
						headerConfig={{
							text: "Common.label.warning",
							icon: <i className="fal fa-trash-alt fa-2x" />
						}}
						cancelBtn={{
							icon: <i className="fal fa-times fa-lg" />,
							text: "Common.btn.not.delete"
						}}
						confirmBtn={{
							icon: <i className="fal fa-trash-alt fa-lg" />,
							text: "Common.btn.contract.delete"
						}}
						onConfirmation={() => this.deleteContract()}
					/>
				</Grid>
			</div>
		)
	}
}
