import api from "../actions/api"
import {observable, action} from "mobx"
import {exportToExcel} from "../../../global/helpers/actions"
import moment from "moment"
import {getFilters} from "../../../global/helpers/api"
import settings from "../../../global/config/settings"

class EUPatientsReportStore {
	@observable patientsOverview = []
	@observable loadingData = false
	@observable isLoading = false
	formRef = undefined

	@observable isOpen = false

	euPatientReportListRef = null

	@observable changedFilters = false
	currFilters = {}
	formRef = null

	@action isChangedFilter(formValues) {
		let isChangeDateFrom = compareMomentDate(this.currFilters.datefrom, formValues.datefrom)
		let isChangeDateTo = compareMomentDate(this.currFilters.dateto, formValues.dateto)

		if (
			isChangeDateFrom ||
			isChangeDateTo ||
			this.currFilters.country != formValues.country ||
			this.currFilters.identifier != formValues.identifier ||
			this.currFilters.orgunit != formValues.orgunit
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action set(name, value) {
		this[name] = value
	}

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@action loadPatientsOverview(form) {
		this.currFilters = form.values()
		this.formRef = form

		let formValues = form.values()

		let filters = [`provider_id=${formValues.orgunit}`]

		filters.push("insurance_type_id=EU")

		if (isNotEmpty(formValues.datefrom)) {
			filters.push(
				`validity_from=${moment(formValues.datefrom)
					.startOf("day")
					.format(settings.DB_DATETIME_FORMAT)}`
			)
		}
		if (isNotEmpty(formValues.dateto)) {
			filters.push(
				`validity_to=${moment(formValues.dateto)
					.endOf("day")
					.format(settings.DB_DATETIME_FORMAT)}`
			)
		}
		if (isNotEmpty(formValues.identifier)) {
			filters.push(`identifier=${formValues.identifier}`)
		}
		if (isNotEmpty(formValues.country)) {
			filters.push(`country_id=${formValues.country}`)
		}

		const searchRequest = getFilters(filters)

		this.isLoading = true
		api
			.loadEUPatients(searchRequest)
			.call()
			.then((response) => {
				this.isLoading = false
				this.patientsOverview = response.rows
			})
			.catch(() => {
				this.isLoading = false
			})
	}

	@action ExportToXls(form) {
		let filters = [
			{
				associated_column: "insurance_type_id",
				values: [
					{
						id_value: "EU"
					}
				]
			}
		]

		if (isNotEmpty(form.values().exportType)) {
			filters.push({
				associated_column: "exportType",
				values: [
					{
						id_value: form.values().exportType
					}
				]
			})
		}

		if (isSafe(this.formRef && isSafe(this.formRef.values()))) {
			let refFormValues = this.formRef.values()

			if (isNotEmpty(refFormValues.orgunit)) {
				filters.push({
					associated_column: "provider_id",
					values: [{id_value: refFormValues.orgunit}]
				})
			}

			if (isNotEmpty(refFormValues.datefrom)) {
				filters.push({
					associated_column: "validity_from",
					values: [
						{
							id_value: moment(refFormValues.datefrom)
								.startOf("day")
								.format("YYYY-MM-DD")
						}
					]
				})
			}

			if (isNotEmpty(refFormValues.dateto)) {
				filters.push({
					associated_column: "validity_to",
					values: [
						{
							id_value: moment(refFormValues.dateto)
								.startOf("day")
								.format("YYYY-MM-DD")
						}
					]
				})
			}

			if (isNotEmpty(refFormValues.identifier)) {
				filters.push({
					associated_column: "identifier",
					values: [{id_value: refFormValues.identifier}]
				})
			}

			if (isNotEmpty(refFormValues.country)) {
				filters.push({
					associated_column: "country_id",
					values: [{id_value: refFormValues.country}]
				})
			}
		}

		let req = {
			filters: filters,
			uri: api.loadEUPatients().getPath()
		}

		if (form.values().exportType == "now") {
			if (isSafe(this.euPatientReportListRef)) {
				req.row_count_show = this.euPatientReportListRef.state.rowsPerPage
				// req.row_count_full = this.euPatientReportListRef.state.rowsPerPage
				req.row_offset = this.euPatientReportListRef.state.page * this.euPatientReportListRef.state.rowsPerPage + 1
			} else {
				req.row_offset = 1
				req.row_count_show = 10
				// req.row_count_full = 10
			}
		}

		exportToExcel(req, () => {
			this.close(), form.reset()
		})
	}
}
var singleton = new EUPatientsReportStore()
export default singleton
