/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				org_unit: {
					label: <FormattedMessage id="EmployeeDetail.employeeContracts.org_unit" />,
					rules: "required"
				},
				capacity: {
					label: <FormattedMessage id="EmployeeDetail.employeeContracts.capacity" />,
					rules: "required"
				},
				capacityTransformed: {},
				validFrom: {
					label: <FormattedMessage id="EmployeeDetail.employeeContracts.validFrom" />,
					rules: "required",
					type: "date"
				},
				validTo: {
					label: <FormattedMessage id="EmployeeDetail.employeeContracts.validTo" />,
					type: "date"
				}
			}
		}
	}
}
