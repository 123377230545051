import React, {Component} from "react"
import Select from "react-select"
import {FormattedMessage} from "react-intl"
import {observer} from "mobx-react"

import {Paper, Grid} from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"

import classnames from "classnames"

import "./xsDropDownAction.less"
import {getFieldIdentifier} from "../../helpers/actions"
import {Tooltip} from "@material-ui/core"
import ReactTooltip from "react-tooltip"

function Menu(props) {
	return (
		<Paper
			square
			className={`xs-searchSelectAction-paper${props.selectProps.menuPlacement === "top" ? " menuOnTop" : ""}`}
			{...props.innerProps}
		>
			{props.children}
		</Paper>
	)
}

function NoOptionsMessage() {
	return (
		<div className="xs-searchSelectAction-noOption">
			<FormattedMessage id="Common.searchSelect.noOccurence" />
		</div>
	)
}

function SingleValue(props) {
	let code = ""
	let id = ""

	if (props.data) {
		id = props.data._id
		code = props.data.code
	}

	return (
		<div className="xs-searchSelectAction-singleValue" data-code={code} data-id={id}>
			{props.children}
		</div>
	)
}

function ValueContainer(props) {
	return (
		<div
			className={`xs-searchSelectAction-container ${props.selectProps.isClearable ? "clearable" : "no-clearable"}${
				props.selectProps.isMulti ? " isMulti" : ""
			}`}
		>
			{props.children}
		</div>
	)
}

function Placeholder(props) {
	return <div className="xs-searchSelectAction-placeholder">{props.children}</div>
}

// function DropdownIndicator(props) {
// 	return (
// 		<components.DropdownIndicator {...props}>
// 			<svg className="xs-searchSelectAction-dropdown" focusable="false" viewBox="0 0 24 24">
// 				<path d="M7 10l5 5 5-5z"></path>
// 			</svg>
// 		</components.DropdownIndicator>
// 	)
// }

function inputComponent({inputRef, ...props}) {
	return <div className="xs-searchSelectAction-inputComponent" ref={inputRef} {...props} />
}

function Control(props) {
	let inputClassName = "xs-searchSelectAction-input pointer"

	if (isSafe(props.selectProps.white)) {
		inputClassName += " bgSnowWhite"
	}

	return (
		<TextField
			data-tip
			data-for={isSafe(props.selectProps) && isSafe(props.selectProps.fieldId) ? props.selectProps.fieldId : null}
			data-multiline={true}
			disabled={props.selectProps.isDisabled}
			fullWidth
			InputLabelProps={{shrink: true}}
			InputProps={{
				inputComponent,
				className: `xs-input-root${props.selectProps.isMulti ? " isMulti" : ""}`,
				classes: {
					root: props.selectProps.isValid ? "" : "xs-input-error"
				},
				inputProps: {
					className: inputClassName,
					inputRef: props.innerRef,
					children: props.children,
					...props.innerProps
				}
			}}
			label={props.selectProps.hideLabel ? undefined : props.selectProps.labelJSX}
			{...props.selectProps.textFieldProps}
		/>
	)
}

function Option(props) {
	const option = getOption(props)
	if (props.selectProps && props.selectProps.tooltipProp && props.data && props.data[props.selectProps.tooltipProp]) {
		return <Tooltip title={props.data[props.selectProps.tooltipProp]}>{option}</Tooltip>
	}
	return option
}

function getOption(props) {
	return (
		<MenuItem className={`xs-searchSelectAction-menuItem item-${props.value}`}>
			<Grid container style={{padding: "0px"}}>
				<Grid
					xs={11}
					item
					buttonRef={props.innerRef}
					selected={props.isFocused}
					component="div"
					style={{
						fontWeight: 300,
						padding: 0
					}}
					{...props.innerProps}
				>
					{props.children}
				</Grid>
				<Grid item xs={1}>
					<i
						className={"fal fa-pencil-alt"}
						onClick={(e) => {
							e.preventDefault()
							props.data.action(e)
						}}
					/>
				</Grid>
			</Grid>
		</MenuItem>
	)
	/*
	<MenuItem value={idx + 1} key={idx + 1} onClick={null}>
								<div
									className="xs-menuItemAction"
									onClick={() => {
										if (isSafe(item.itemData) && isSafe(onSelect) && typeof onSelect == "function") {
											onSelect(item.itemData)
										}
										this.canClose = true
									}}
								>
									{item.label}
								</div>
								<i
									className={actionIconClassName}
									onClick={(e) => {
										this.canClose = false
										item.action(e)
									}}
								/>
							</MenuItem>
							*/
}

const mycomponents = {
	Control,
	Menu,
	NoOptionsMessage,
	Option,
	Placeholder,
	SingleValue,
	ValueContainer
}

/**
 * - Wrapped 'react-select' by Maros
 * - ak pouzijete komponentu do formulara s pouzitim mobx-react-form, staci poslat propu field a pripadne required
 * - pre obycajne pouzitie neposielajte field, object: items => {label: "", value: "", ...},
 * 		alebo pouzite getOptionLabel a getOptionValue pre nastavenie z vlastneho objektu
 * - pre renderovanie moznosti nad input nastavit menuPlacement="top"
 * - mozno sa zide aj konfigurovatelny min-width na paper (DDL), teraz je nastaveny cez CSS
 */

@observer
export default class SearchSelect extends Component {
	constructor(props) {
		super(props)

		this._isMounted = false

		this.state = {
			isValid: true
		}

		this.selectRef = null
	}

	componentDidMount() {
		this._isMounted = true
		if (isSafe(this.props.field) && this.props.disabled !== true) {
			this.handleValidation(this.props.field)
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	componentDidUpdate() {
		if (isSafe(this.props.field) && this.props.disabled !== true) {
			this.handleValidation(this.props.field)
		}
	}

	handleValidation = (field) => {
		field.validate().then(({isValid}) => {
			if (this.state.isValid !== isValid) {
				if (this._isMounted) {
					this.setState({
						isValid: isValid
					})
				}
			}
		})
	}

	focus = () => {
		if (isSafe(this.selectRef)) {
			this.selectRef.focus()
		}
	}

	render() {
		const {
			field,
			items,
			label,
			value,
			onChange,
			className,
			required,
			disabled,
			hideLabel,
			placeholder,
			isMulti,
			menuPlacement,
			tooltipProp,
			white,
			onClear
		} = this.props

		const isField = isSafe(field)
		const bindedField = isField ? field.bind() : undefined

		const labelJSX = isField ? (
			required === true || (isSafe(field.get("rules")) && field.get("rules").includes("required")) ? (
				<span className="xs-searchSelectAction-label xs-searchSelectAction-required">{bindedField.label} *</span>
			) : (
				<span className="xs-searchSelectAction-optional">{bindedField.label}</span>
			)
		) : required === true ? (
			<span className="xs-searchSelectAction-label xs-searchSelectAction-required">{label} *</span>
		) : (
			<span className="xs-searchSelectAction-optional">{label}</span>
		)

		const allItems = isSafe(items) ? items : []

		const handleOnChange = (formField) => (e, triggeredAction) => {
			if (isSafe(triggeredAction) && triggeredAction.action == "clear" && typeof onClear == "function") {
				onClear()
			}

			if (isNotSafe(e)) {
				formField.set("")
				formField.onChange("")
				this.handleValidation(formField)
				return
			}

			let val
			if (isSafe(e._id)) {
				val = e._id
			} else {
				val = e.code
			}

			if (formField.get("value") != val) {
				formField.set("value", val)
				formField.onChange(val)
			} else {
				formField.reset()
				formField.set("value", val)
			}
			if (typeof onChange == "function") {
				onChange(val)
			}
			this.handleValidation(formField)

			//Po tom ako sa vykonaju vsetky zmeny zavolam forceUpdate na rerender tooltipu
			setTimeout(() => {
				this.forceUpdate()
			}, 0)
		}

		const handleOnMultiChange = (formField) => (e, triggeredAction) => {
			if (isSafe(triggeredAction) && triggeredAction.action == "clear" && typeof onClear == "function") {
				onClear()
			}

			if (isNotSafe(e)) {
				formField.set([])
				formField.onChange([])
				this.handleValidation(formField)
				return
			}

			let vals = e.map((val) => {
				if (isSafe(val._id)) {
					return val._id
				} else {
					return val.code
				}
			})

			formField.set("value", vals)
			formField.onChange(vals)

			if (typeof onChange == "function") {
				onChange(vals)
			}

			this.handleValidation(formField)
		}

		const isMultiVal = isField ? field.options.isMulti === true : isMulti === true

		let defVal = null
		if (isField) {
			if (isMultiVal) {
				defVal = isNotEmpty(bindedField.value) ? allItems.filter((x) => bindedField.value.includes(x._id)) : []
			} else {
				defVal = isNotEmpty(bindedField.value) ? allItems.filter((x) => x._id === bindedField.value)[0] : ""
			}
		} else {
			defVal = value
		}

		const isDisabled = isField ? bindedField.disabled || disabled : disabled
		const additionalClasses = classnames(className, {disabled: isDisabled})

		let classIdentifier = getFieldIdentifier(field, additionalClasses)

		return (
			<React.Fragment>
				<Select
					ref={(input) => (this.selectRef = input)}
					className={`xs-searchSelectAction ${isSafe(classIdentifier) ? classIdentifier : ""}`}
					options={allItems}
					components={mycomponents}
					value={defVal}
					//Identifikator pre tooltip pouzity v Control
					fieldId={isSafe(field) ? "xsSearchSelect" + field.id : null}
					onChange={
						isField
							? field.options.isMulti === true
								? handleOnMultiChange(field)
								: handleOnChange(field)
							: (e) => onChange(e)
					}
					tabIndex={isSafe(this.props.tabindex) ? this.props.tabindex : null}
					getOptionLabel={
						isField
							? (opt) =>
									isSafe(this.props.getOptionLabel) && typeof this.props.getOptionLabel === "function"
										? this.props.getOptionLabel(opt)
										: opt.name_ext
							: isSafe(this.props.getOptionLabel) && typeof this.props.getOptionLabel === "function"
							? (opt) => this.props.getOptionLabel(opt)
							: undefined
					}
					getOptionValue={
						isField
							? (opt) => opt._id
							: isSafe(this.props.getOptionValue) && typeof this.props.getOptionValue === "function"
							? (opt) => this.props.getOptionValue(opt)
							: undefined
					}
					styles={{
						indicatorSeparator: () => {},
						dropdownIndicator: (provided, state) => {
							const padding = state.selectProps.isClearable ? "6px 4px 6px 2px" : "6px 4px 6px 4px"
							return {...provided, padding}
						},
						clearIndicator: (provided) => {
							const padding = "6px 2px 6px 4px"
							return {...provided, padding}
						}
					}}
					isClearable={
						isField
							? !(required === true || (isSafe(field.get("rules")) && field.get("rules").includes("required")))
							: required !== true
					}
					isMulti={isMultiVal}
					tooltipProp={tooltipProp}
					labelJSX={labelJSX}
					white={white}
					isDisabled={isDisabled}
					tabSelectsValue={true}
					isValid={this.state.isValid}
					hideLabel={hideLabel === true}
					placeholder={placeholder ? placeholder : null}
					//presunute na cely select
					// inputProps={
					// 	{
					// 		tabIndex: isSafe(this.props.tabindex) ? this.props.tabindex : ""
					// 	}
					// }
					menuPlacement={isSafe(menuPlacement) ? menuPlacement : "auto"} // auto | bottom | top // zatial funguje len top
				/>
				{isSafe(field) &&
					isSafe(this.selectRef) &&
					isSafe(this.selectRef.props) &&
					isSafe(this.selectRef.props.value) &&
					isSafe(this.selectRef.props.value.name_ext) && (
						<ReactTooltip data-multiline={true} id={"xsSearchSelect" + field.id}>
							{isSafe(this.props.getOptionLabel) && typeof this.props.getOptionLabel === "function"
								? this.props.getOptionLabel(this.selectRef.props.value)
								: this.selectRef.props.value.name_ext}
						</ReactTooltip>
					)}
			</React.Fragment>
		)
	}
}
