"use strict"

import {action} from "mobx"

import api from "../actions/api"
import UIStore from "./UIStore"
import GlobalStore from "../../../global/store/GlobalStore"

class SettingStore {
	formRef = null

	@action loadMessiging(form) {
		api
			.getSettings("NickName")
			.call()
			.then((setting) => {
				if (isSafe(setting) && isNotEmpty(setting.value)) {
					form.$("nick_name").value = setting.value
				}
			})

		api
			.getSettings("MessagingEnabled")
			.call()
			.then((setting) => {
				if (isSafe(setting) && isNotEmpty(setting.value)) {
					form.$("messaging_enable").value = setting.value == 1 ? true : false
				}
			})
	}

	@action setSetting(name, value) {
		UIStore.isFormSaving = true

		const req = {
			_type: "IXS.Security.Data.User.Setting",
			_id: "",
			_ref: false,
			name: name,
			value: value
		}

		api
			.setSettings(req)
			.call()
			.then((res) => {
				if (isSafe(res)) {
					GlobalStore.setNotificationMessage("Common.label.changeSetting")
				}
				UIStore.isFormSaving = false
			})
	}
}

var singleton = new SettingStore()
export default singleton
