"use strict"
import api from "../actions/api"
import {observable, action} from "mobx"
import {exportToExcel, getUserCompanyInfo} from "../../../global/helpers/actions"
import moment from "moment"
import settings from "../../../global/config/settings"

class VaccinationCountByDeliveryListStore {
	@observable patients = []
	@observable isFetching = false
	@observable isOpen = false

	formRef = undefined

	vaccinationCountByDeliveryRef = null

	@observable changedFilters = false
	currFilters = {}
	currDiagnosis = []

	@action isChangedFilter(formValues) {
		let isChangeDateFrom = compareMomentDate(this.currFilters.date_from, formValues.date_from)
		let isChangeDateTo = compareMomentDate(this.currFilters.date_to, formValues.date_to)

		if (
			this.currFilters.orgunit != formValues.orgunit ||
			this.currFilters.delivery != formValues.delivery ||
			this.currFilters.productFilter != formValues.productFilter ||
			this.currFilters.doseNumber != formValues.doseNumber ||
			isChangeDateFrom ||
			isChangeDateTo
		) {
			this.changedFilters = true
		} else {
			this.changedFilters = false
		}
	}

	@action set(name, value) {
		this[name] = value
	}

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	getFilters(form) {
		let formValues = form.values()

		// this.isChangedFilter(form.values())
		let filters = [{associated_column: "company_id", values: [{id_value: getUserCompanyInfo().id}]}]

		let searchRequest = {
			filters: filters
		}

		if (isNotEmpty(formValues.date_from)) {
			searchRequest["time_from"] = moment(form.$("date_from").value).format(settings.DB_DATETIME_FORMAT)
			// filters.push({
			// 	associated_column: "vaccination_from",
			// 	values: moment(form.$("date_from").value).format("YYYY-MM-DD")
			// })
		}

		if (isNotEmpty(formValues.date_to)) {
			searchRequest["time_to"] = moment(form.$("date_to").value).format(settings.DB_DATETIME_FORMAT)
			// filters.push({associated_column: "vaccination_to", values: moment(form.$("date_to").value).format("YYYY-MM-DD")})
		}

		if (isNotEmpty(formValues.orgunit)) {
			filters.push({associated_column: "provider_id", values: [{id_value: formValues.orgunit}]})
		}

		if (isNotEmpty(formValues.delivery)) {
			filters.push({associated_column: "delivery_id", values: [{id_value: formValues.delivery}]})
		}

		if (isNotEmpty(formValues.productFilter)) {
			filters.push({associated_column: "vaccine_id", values: [{id_value: formValues.productFilter}]})
		}

		if (isNotEmpty(formValues.doseNumber)) {
			filters.push({associated_column: "order_num", values: [{id_value: formValues.doseNumber}]})
		}

		searchRequest["filters"] = filters

		return searchRequest
	}

	@action loadPatients(form) {
		this.currFilters = form.values()
		this.currDiagnosis = this.diagnosisFilter

		const searchRequest = this.getFilters(form)

		this.isFetching = true
		api
			.loadVaccdeliveryreport(searchRequest)
			.call()
			.then((response) => {
				this.patients = response.rows
				this.isFetching = false
			})
			.catch(() => {
				this.isFetching = false
			})
	}

	@action exportToExcel(form) {
		let req = this.getFilters(form)

		req["uri"] = api.loadVaccdeliveryreport().getPath()

		exportToExcel(req)
	}
}
var singleton = new VaccinationCountByDeliveryListStore()
export default singleton
