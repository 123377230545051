import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				examination: {
					label: <FormattedMessage id="Common.label.nameSampleType" />
				},
				vzorka: {
					label: <FormattedMessage id="Common.label.nameSampleType" />
				}
			}
		}
	}
}
