"use strict"

import React, {useState, useEffect} from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
import Dropzone from "react-dropzone"
import moment from "moment"
import XsButton from "../../xsButton/xsButton"
import ImportVaccStore from "./importVaccStore"
import GlobalStore from "../../../store/GlobalStore"
import XsConfirmationDialog from "../../xsDialog/xsConfirmationDialog"
import XsDateTimePicker from "../../xsDateTimePicker/xsDateTimePicker"
import {
	getSelectedOrgUnit,
	getSelectedOrgUnitID,
	getUserPersonnelOrgUnits,
	getUserCompanyInfo,
	getUser
} from "../../../helpers/actions"
import XsSearchSelect from "../../xsSearchSelect/xsSearchSelect"
import UIStore from "../../../../modules/ambulance/stores/UIStore"
import XsLoading from "../../xsLoading/xsLoading"
import XsInput from "../../xsInput/xsInput"
import XsCheckbox from "../../xsCheckbox/xsCheckbox"
import XsAutocomplete from "../../xsInput/xsAutocomplete"
import api from "../../../../modules/ambulance/actions/api"
// import DataStore from "../../../"

function ImportVaccDialog() {
	const orgunit = getSelectedOrgUnit()
	const company = getUserCompanyInfo()
	const user = getUser()

	let addressString = ""
	let addressStringDescription = ""
	if (isSafe(orgunit.addresses)) {
		const contactAddress = orgunit.addresses.find((row) => isSafe(row.type) && row.type.code == "CA")
		if (isSafe(contactAddress)) {
			addressString = `${contactAddress.street} ${contactAddress.house_number}, ${contactAddress.city}`
			addressStringDescription = contactAddress.description
		}
	}

	const fields = {
		dateFrom: {
			label: <FormattedMessage id="Common.label.from" />,
			rules: "required",
			value: moment()
		},
		dateTo: {
			label: <FormattedMessage id="Common.label.to" />,
			rules: "required",
			value: moment()
		},
		orgunit: {
			label: <FormattedMessage id="Common.label.orgunit" />,
			rules: "required",
			value: getSelectedOrgUnitID()
		},
		vaccPlace: {
			label: "ID očkovacieho miesta",
			rules: "required"
		},
		sendSMS: {
			label: "Odoslať SMS",
			type: "checkbox",
			value: false
		},
		forceSendSMS: {
			label: "Poslať SMS, aj keď bol pacient už naplánovaný",
			type: "checkbox",
			value: false
		},
		sendEmail: {
			label: "Odoslať Email",
			type: "checkbox",
			value: false
		},
		checkUdzs: {
			label: "Kontrola voči UDZS",
			type: "checkbox",
			value: false
		},
		vaccName: {
			label: "Názov vakcíny"
		},
		vaccPlaceName: {
			label: "Názov očk. miesta",
			value: addressStringDescription
		},
		vaccPlaceAdress: {
			label: "Adresa očk. miesta",
			value: addressString
		},
		vaccSender: {
			label: "Odosielateľ SMS",
			value: company.short_name
		},
		drugs: {
			label: "Vakcína na očkovanie",
			rules: "required"
		},
		delivery: {
			label: "Výkon",
			rules: "required"
		},
		forceSaveDrug: {
			label: "Prepísať kód vakcíny v už naimportovaných záznamoch na daný deň zadanou vakcínou",
			type: "checkbox",
			value: false
		},
		insertCustomPlaceId: {
			label: "Zadať ID očk. miesta ručne",
			type: "checkbox",
			value: false
		},
		productCode: {}
	}

	const hooks = {
		onSubmit() {},
		onSuccess() {},
		onError() {}
	}

	const [form] = useState(new MobxReactForm({fields: fields}, {plugins: {dvr: validatorjs}, hooks}))

	const handleDrop = (files) => {
		ImportVaccStore.dropedFiles = [...files]
	}

	const handleDropzoneDelete = (index) => {
		if (isSafe(ImportVaccStore.dropedFiles[index]._id)) {
			ImportVaccStore.deleteLoadedAttachemnt(ImportVaccStore.dropedFiles[index]._id, index)
		} else {
			ImportVaccStore.dropedFiles.splice(index, 1)
		}
	}

	const handleDropzonePreview = (index) => {
		var FileSaver = require("file-saver") // eslint-disable-line no-undef
		FileSaver.saveAs(ImportVaccStore.dropedFiles[index], ImportVaccStore.dropedFiles[index].name)
	}

	const handleValidation = () => {
		if (form.$("sendSMS").value == true || form.$("sendEmail").value == true) {
			form.$("vaccPlaceName").set("rules", "required")
			form.$("vaccPlaceAdress").set("rules", "required")
			form.$("vaccSender").set("rules", "required")
		} else {
			form.$("vaccPlaceName").set("rules", "")
			form.$("vaccPlaceAdress").set("rules", "")
			form.$("vaccSender").set("rules", "")
		}
	}

	const handleCustomPlaceIdInput = () => {
		if (form.$("insertCustomPlaceId").value == true) {
			form.$("vaccPlace").value = ""
			ImportVaccStore.vaccPlaces = []
		} else {
			form.$("vaccPlace").value = ""
			ImportVaccStore.loadVaccPlaces(form)
		}
	}

	useEffect(() => {
		ImportVaccStore.loadVaccPlaces(form)
		ImportVaccStore.loadDefaultDelivery(form)
	}, [])

	return (
		<div>
			<Dialog
				id="xsImportVaccDialog"
				open={ImportVaccStore.isOpen}
				onClose={() => {
					ImportVaccStore.close()
				}}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-600"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								ImportVaccStore.close()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-info-circle fa-2x" onClick={() => ImportVaccStore.open()}></i>
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.importExportVaccCSV" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-overflow-visible xs-content" style={{maxHeight: "450px", overflowY: "auto"}}>
					{UIStore.isFormSaving ? (
						<XsLoading />
					) : (
						<Grid container direction="row" spacing={8}>
							<Grid item xs={12}>
								<h4>Import CSV súboru</h4>
							</Grid>
							<Grid item xs={12}>
								<Dropzone onDrop={handleDrop} id="dropzone" maxFiles={1}>
									{isSafe(ImportVaccStore.dropedFiles) && ImportVaccStore.dropedFiles.length ? (
										ImportVaccStore.dropedFiles.map((file, index) => (
											<div key={index} className={"dropedFile"}>
												<i
													className="fal fa-file icon"
													onClick={(e) => {
														e.stopPropagation()
														handleDropzonePreview(index)
													}}
												/>
												<i
													className="far fa-trash-alt deleteIcon"
													onClick={(e) => {
														e.stopPropagation()
														GlobalStore.openConfirmationDialog("xsDeleteAttachmentFromDropzone", {fileIndex: index})
													}}
												/>
												<br />
												<div
													className={"mac_ellipsis"}
													data-content-start={file.name.substr(0, Math.floor(file.name.length / 2))}
													data-content-end={"\u200E" + file.name.substr(Math.floor(file.name.length / 2)) + "\u200E"}
												/>
											</div>
										))
									) : (
										<div className={"dropzonePlaceholder"}>
											<FormattedMessage id="Common.form.attachment" />
										</div>
									)}
								</Dropzone>
							</Grid>
							<Grid item xs={6}>
								<XsInput field={form.$("vaccName")} />
							</Grid>
							<Grid item xs={6}>
								<XsInput field={form.$("vaccPlaceName")} />
							</Grid>
							<Grid item xs={12}>
								<XsAutocomplete
									field={form.$("drugs")}
									minLengthForSearch="3"
									clearText
									api={api.loadDrugs}
									autoFocus={true}
									ref={(input) => (GlobalStore.defaultFocusFields["patientMedicationForm"] = input)}
									inputRenderer={(obj) =>
										obj.code_ext +
										" | " +
										obj.name_ext +
										" | " +
										obj.supplement +
										" | " +
										(obj.patient_payment ? obj.patient_payment + " | " : "") +
										(obj.insurer_payment ? obj.insurer_payment + " | " : "") +
										obj.atc +
										" | " +
										obj.active_ingredient
									}
									saveValue={(obj) => obj.code}
									postAction={(value) => {
										form.$("productCode").value = "" + value.code
									}}
									modalConfig={(textValue, clickHandler) => {
										return {
											columnDefs: {
												code_ext: {
													title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.code_ext" />,
													type: "string",
													dbName: "code_ext"
												},
												name_ext: {
													title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.name_ext" />,
													type: "string",
													dbName: "search_column",
													filter: {
														gridWidth: 3,
														defaultValue: textValue,
														renderElement: "input"
													},
													sortable: true
												},
												supplement: {
													title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />,
													type: "string",
													dbName: "supplement"
												},
												patient_payment: {
													title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.patient_payment" />,
													type: "string",
													dbName: "patient_payment"
												},
												insurer_payment: {
													title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.insurer_payment" />,
													type: "string",
													dbName: "insurer_payment"
												},
												atc: {
													title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.ATC" />,
													type: "string",
													dbName: "atc"
												},
												active_ingredient: {
													title: (
														<FormattedMessage id="Patient.form.patientPrescription.drugsTable.active_ingredient" />
													),
													type: "string",
													dbName: "active_ingredient"
												}
											},
											options: {
												showCursor: true,
												onRowClick: (dataRow) => {
													clickHandler(dataRow)
												},
												renderHeaderAsFirstRow: true,
												findSameATC: true,
												paging: {
													rowsPerPage: 100,
													rowsPerPageOptions: [5, 10, 25, 50, 100]
												}
											}
										}
									}}
									onClear={() => {
										form.$("productCode").value = ""
									}}
									// addSpecialFilter={() => {
									// 	return [
									// 		{
									// 			associated_column: "insurer_id",
									// 			predicate: "=",
									// 			values: [
									// 				{
									// 					id_value: DataStore.patientDTO.get("insurerId")
									// 				}
									// 			]
									// 		}
									// 	]
									// }}
								/>
							</Grid>
							<Grid item xs={12}>
								<XsAutocomplete
									field={form.$("delivery")}
									minLengthForSearch="1"
									clearText
									api={api.loadDeliveries}
									searchFilter={"code_ext"}
									loadApi={api.loadSpecificDelivery}
									renderFooterInModal={true}
									inputRenderer={(obj) => obj.code_ext + "-" + obj.name_ext}
									saveValue={(obj) => obj._id}
									modalConfig={(textValue, clickHandler) => {
										return {
											columnDefs: {
												code_ext: {
													title: <FormattedMessage id="Patient.form.patientrecord.deliveryTable.code_ext" />,
													design: {
														width: "10%"
													},
													type: "string",
													dbName: "code_ext",
													filter: {
														gridWidth: 3,
														defaultValue: "",
														renderElement: "input"
													}
												},
												name_ext: {
													title: <FormattedMessage id="Patient.form.patientrecord.deliveryTable.name_ext" />,
													type: "string",
													dbName: "search_column",
													design: {
														width: "90%"
													},
													filter: {
														gridWidth: 3,
														defaultValue: textValue,
														renderElement: "input"
													}
												}
											},
											options: {
												showCursor: true,
												onRowClick: (dataRow) => {
													clickHandler(dataRow)
												}
											}
										}
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<XsInput field={form.$("vaccPlaceAdress")} />
							</Grid>
							<Grid item xs={6}>
								<XsInput field={form.$("vaccSender")} />
							</Grid>
							<Grid item xs={6}>
								<XsCheckbox field={form.$("sendSMS")} onChange={handleValidation} />
							</Grid>
							<Grid item xs={6}>
								<XsCheckbox
									field={form.$("forceSendSMS")}
									onChange={handleValidation}
									tooltipId={"Common.label.forcetextSMSTooltip"}
									warningLabel
								/>
							</Grid>
							{isSafe(user) && isSafe(user.user_settings) && user.user_settings.vacc_send_email_enabled == 1 && (
								<Grid item xs={6}>
									<XsCheckbox field={form.$("sendEmail")} onChange={handleValidation} />
								</Grid>
							)}
							<Grid item xs={6}>
								<XsCheckbox field={form.$("checkUdzs")} onChange={handleValidation} />
							</Grid>
							<Grid item xs={12}>
								<XsCheckbox field={form.$("forceSaveDrug")} />
							</Grid>
							<Grid item xs={12} container justify="flex-end">
								<XsButton
									className="xs-success"
									onClick={() => {
										ImportVaccStore.importData(form)
									}}
									disabled={
										(isSafe(ImportVaccStore.dropedFiles) && ImportVaccStore.dropedFiles.length == 0) ||
										isNotSafe(ImportVaccStore.dropedFiles) ||
										isEmpty(form.$("drugs").value) ||
										isEmpty(form.$("delivery").value) ||
										((form.$("sendSMS").value == true || form.$("sendEmail").value == true) &&
											(isEmpty(form.$("vaccPlaceName").value) ||
												isEmpty(form.$("vaccPlaceAdress").value) ||
												isEmpty(form.$("vaccSender").value)))
									}
									text={"Import"}
									icon={<i className="fal fa-file-import fa-lg" />}
								/>
							</Grid>

							<div style={{height: "1px", width: "100%", borderTop: "1px solid black"}} className="ma-1"></div>
							<Grid item xs={12}>
								<h4>Export CSV pre plánovanie očkovania</h4>
							</Grid>
							<Grid item xs={6}>
								<XsDateTimePicker
									field={form.$("dateFrom")}
									onSelect={() => {
										setTimeout(() => {
											ImportVaccStore.loadVaccPlaces(form)
										}, 0)
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<XsDateTimePicker
									field={form.$("dateTo")}
									onSelect={() => {
										setTimeout(() => {
											ImportVaccStore.loadVaccPlaces(form)
										}, 0)
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<XsSearchSelect
									field={form.$("orgunit")}
									items={getUserPersonnelOrgUnits()}
									onChange={() => {
										ImportVaccStore.loadVaccPlaces(form)
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								{form.$("insertCustomPlaceId").value == true ? (
									<XsInput field={form.$("vaccPlace")} />
								) : (
									<XsSearchSelect field={form.$("vaccPlace")} items={ImportVaccStore.vaccPlaces} />
								)}
							</Grid>
							<Grid item xs={6}>
								<XsCheckbox field={form.$("insertCustomPlaceId")} onChange={handleCustomPlaceIdInput} />
							</Grid>
							<Grid item xs={12} container justify="flex-end">
								<XsButton
									className="xs-success"
									onClick={() => {
										ImportVaccStore.exportData(form)
									}}
									text={"Export"}
									icon={<i className="fal fa-file-download fa-lg" />}
									disabled={
										isEmpty(form.$("dateFrom").value) ||
										isEmpty(form.$("dateTo").value) ||
										isEmpty(form.$("orgunit").value) ||
										isEmpty(form.$("vaccPlace").value)
									}
								/>
							</Grid>
						</Grid>
					)}
				</DialogContent>
				<DialogActions className="xs-footer">
					<Grid container justify="flex-end">
						<Grid item>
							<XsButton
								className="xs-danger xs-outline"
								onClick={() => {
									ImportVaccStore.close()
								}}
								text={<FormattedMessage id="Common.form.close" />}
								icon={<i className="fal fa-times fa-lg" />}
							/>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
			<XsConfirmationDialog
				name="xsDeleteAttachmentFromDropzone"
				title={<FormattedMessage id="Common.label.warning" />}
				text={<FormattedMessage id="Patient.form.patientrecord.deleteAttachment" />}
				onConfirmation={() => handleDropzoneDelete(GlobalStore.confirmationDialogParams.fileIndex)}
			/>
		</div>
	)
}

export default injectIntl(observer(ImportVaccDialog))
