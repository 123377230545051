import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import TextField from "@material-ui/core/TextField"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import EHealthGetRequestsDialogStore from "../../../stores/EHealthGetRequestsDialogStore"
import {Grid} from "@material-ui/core"

@injectIntl
@observer
export default class eHealthGetRequestsDialog extends React.Component {
	constructor(props) {
		super(props)

		EHealthGetRequestsDialogStore.warningText = this.props.intl.formatMessage({id: "Common.label.infoNoCriteria"})
	}

	render() {
		return (
			<Dialog
				id="xsEHealthGetRequestsDialog"
				open={EHealthGetRequestsDialogStore.isOpen}
				onClose={() => EHealthGetRequestsDialogStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-base">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-search fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="PatientDetail.overview.searchIneHealth" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<Grid container>
						<Grid item xs={12}>
							<TextField
								value={EHealthGetRequestsDialogStore.numberRequestId}
								onChange={(e) => (EHealthGetRequestsDialogStore.numberRequestId = e.target.value)}
								label={<FormattedMessage id="PatientDetail.overview.numberRequestId" />}
								InputLabelProps={{
									shrink: true
								}}
								onKeyPress={(event) => {
									if (event.which === 13 || event.keyCode === 13) {
										EHealthGetRequestsDialogStore.search()
									}
								}}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => EHealthGetRequestsDialogStore.close()}
					/>
					<XsButton
						className="xs-success"
						icon={<i className="fal fa-search" />}
						text={<FormattedMessage id="Common.list.search" />}
						onClick={() => EHealthGetRequestsDialogStore.search() /*EHealthSearchDialogStore.search()*/}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
