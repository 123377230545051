"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"
import {getPlaceholderFunctions} from "../../../global/helpers/universalTemplatesFunctions"
import GlobalStore from "../../../global/store/GlobalStore"
import UIStore from "./UIStore"

class UniversalTemplateStore {
	@observable isDialogOpen = false
	@observable templates = []
	@observable templatesForSearch = []
	@observable mappings = []
	@observable showFullText = false
	form = null
	generalForm = null

	@action openTemplateDialog(id) {
		this.isDialogOpen = true

		if (isNotEmpty(id)) {
			this.loadForm(id, this.form)
		}
	}

	@action closeTemplateDialog() {
		this.isDialogOpen = false
		this.mappings = []
		this.showFullText = false
		if (isSafe(this.form)) {
			this.form.reset()
		}
	}

	@action saveDialog(form) {
		let formVals = form.values()
		let req = {
			// "_id": "",
			_ref: false,
			_type: "IXS.Template.Data.HTML",
			// "object_owner_id":"IXS",
			active: true,
			content_type: "text/html",
			charset: "utf-8",
			mappings: [
				// {
				// 	_id: "",
				// 	_ref: false,
				// 	_type: "IXS.Template.Data.Mapping",
				// 	active: true,
				// 	placeholder: "patientInfo",
				// 	value: {
				// 		_ref: false,
				// 		_type: "IXS.Template.Data.FunctionValue",
				// 		active: true,
				// 		name: "patientInfo"
				// 	}
				// }
				// {
				// 	_id: "",
				// 	_ref: false,
				// 	_type: "IXS.Template.Data.Mapping",
				// 	active: true,
				// 	placeholder: "PODNADPIS",
				// 	value: {
				// 		_id: "",
				// 		_ref: false,
				// 		_type: "IXS.Template.Data.FunctionValue",
				// 		active: true,
				// 		name: "Java.getUnderNadpis()"
				// 	}
				// }
			],
			name: formVals.name,
			place_holder: "##",
			scope: formVals.private ? "Pr" : "Pu",
			template_stream: formVals.templateText
		}

		this.mappings.forEach((placeholder) => {
			const placeholderObj = getPlaceholderFunctions().find((row) => row._id == placeholder)
			req.mappings.push({
				// _id: "",
				_ref: false,
				_type: "IXS.Template.Data.Mapping",
				active: true,
				placeholder: placeholder,
				value: {
					_ref: false,
					_type: "IXS.Template.Data.FunctionValue",
					active: true,
					name: placeholderObj.function.name
				}
			})
		})

		if (isNotEmpty(formVals.id)) {
			req._id = formVals.id
		}
		api
			.saveUniversalTemplate(req)
			.call()
			.then(() => {
				this.closeTemplateDialog()
				this.getList()
				GlobalStore.setNotificationMessage("Common.label.SaveUniversalTemplateSucc")
			})
	}

	@action getList() {
		api
			.loadUniversalTemplates()
			.call()
			.then((res) => {
				this.templates = res.rows
			})
	}

	@action getListForSearch() {
		api
			.loadUniversalTemplates()
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows) && res.rows) {
					this.templatesForSearch = res.rows.map((temp) => {
						return {_id: temp._id, name_ext: temp.name, code: temp._id}
					})
				} else {
					this.templatesForSearch = []
				}
			})
	}

	@action delete(form) {
		let req = {
			_id: form.$("id").value,
			active: false,
			_ref: false,
			_type: "IXS.Template.Data.HTML"
		}
		api
			.saveUniversalTemplate(req)
			.call()
			.then(() => {
				this.closeTemplateDialog()
				this.getList()
				GlobalStore.setNotificationMessage("Common.label.deleteUniversalTemplateSucc")
			})
	}

	@action loadForm(id, form) {
		api
			.loadUniversalTemplate(id)
			.call()
			.then((temp) => {
				let data = {
					id: temp._id,
					name: temp.name,
					private: temp.scope == "Pr",
					templateText: temp.template_stream
				}

				if (isSafe(temp.mappings)) {
					this.mappings = temp.mappings.map((placeholder) => {
						return placeholder.placeholder
					})
				}

				form.set(data)
			})
	}

	@action loadGeneralForm(id) {
		UIStore.isFormSaving = true
		api
			.loadUniversalTemplate(id)
			.call()
			.then((temp) => {
				if (isSafe(temp.mappings)) {
					this.mappings = temp.mappings.map((placeholder) => {
						return placeholder.placeholder
					})
				}

				let data = {
					id: temp._id,
					name: temp.name,
					templateText: this.replacePlaceholders(temp.template_stream)
				}

				this.generalForm.set(data)
				UIStore.isFormSaving = false
			})
	}

	@action replacePlaceholders(templateText) {
		let generateText = templateText
		const functions = getPlaceholderFunctions()

		this.mappings.forEach((placeholder) => {
			const placeholderObj = functions.find((row) => row._id == placeholder)
			if (isSafe(placeholderObj)) {
				generateText = generateText.replace(placeholderObj._id, placeholderObj.function())
			}
		})

		return generateText
	}
}

var singleton = new UniversalTemplateStore()
export default singleton
