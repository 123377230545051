"use strict"

import React from "react"
import {observer} from "mobx-react"
import {withStyles} from "@material-ui/core/styles"
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from "recharts"
import PatientClinicalValuesTooltip from "./patientClinicalValuesTooltip"
import moment from "moment"
import settings from "../../../../../global/config/settings"
import {injectIntl} from "react-intl"
import XsButton from "../../../../../global/ui/xsButton/xsButton"

const styles = () => ({
	cvchartContainer: {
		marginBottom: "10px",
		width: "100%",
		height: "200px",
		backgroundColor: "#fff"
	},
	cvchartTitle: {
		textAlign: "center",
		fontSize: "24px",
		fontWeight: 300,
		color: "#60606F",
		marginTop: "10px",
		marginBottom: "20px"
	},
	cvchartButtons: {
		marginBottom: "20px"
	}
})

@observer
export class PatientClinicalValuesChart extends React.Component {
	cvdata = []

	constructor(props) {
		super(props)

		//Prejdeme všetky dáta a premapujeme si ich pre potreby Recharts
		//Ukladáme si len povolené typy s numeric=true
		if (props.data) {
			props.data.map((d) => {
				return d.clinical_values.map((cv) => {
					if (cv.numeric) {
						this.cvdata.push({
							...cv,
							measured_unixtimestamp: moment(cv.measured_at, settings.DB_DATETIME_FORMAT).unix() * 1000
						})
					}
				})
			})
			this.cvdata.sort((a, b) => new Date(a.measured_at).getTime() - new Date(b.measured_at).getTime())
		}

		//Získame prvú hodnotu z cvdata
		this.state = {
			selectedCV: this.cvdata.length ? this.cvdata[0].measure_id : ""
		}
	}

	getFilteredData = (measureID) => {
		return this.cvdata.filter((cv) => cv.measure_id === measureID)
	}

	getCVDetail = (selected) => {
		return this.cvdata.find((cv) => cv.measure_id === selected)
	}

	renderButtons = () => {
		const unique = (value, index, self) => {
			return self.indexOf(value) === index
		}
		const uniqueCV = this.cvdata.map((cvd) => cvd.measure_id).filter(unique)

		return (
			<div className={this.props.classes.cvchartButtons}>
				{uniqueCV.map((acv, ix) => {
					const active = this.state.selectedCV === acv ? "" : " xs-outline"
					const cvDetail = this.getCVDetail(acv)
					if (cvDetail) {
						return (
							<XsButton
								key={ix}
								className={"xs-primary" + active}
								text={cvDetail.measure_name_ext}
								onClick={() => this.changeSelectedCV(acv)}
							/>
						)
					}
				})}
			</div>
		)
	}

	changeSelectedCV = (selected) => {
		this.setState({
			selectedCV: selected
		})
	}

	render() {
		const data = this.getFilteredData(this.state.selectedCV)
		const dateFormat = this.props.intl.formatMessage({id: "Application.moment.dateformat"})
		const selectedDetail = this.getCVDetail(this.state.selectedCV)

		return (
			<div>
				{data && selectedDetail && (
					<div>
						<div className={this.props.classes.cvchartTitle}>{selectedDetail.measure_name_ext}</div>
						<div className={this.props.classes.cvchartContainer}>
							<ResponsiveContainer>
								<LineChart width={600} height={300} data={data} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
									<XAxis
										dataKey="measured_unixtimestamp"
										domain={["dataMin-1", "dataMax+1"]}
										type="category"
										tickFormatter={(unixTime) => moment(unixTime).format("DD.MM.YYYY")}
									/>
									<YAxis
										dataKey="value"
										type="number"
										tickFormatter={(value) => Math.round(value * 1000) / 1000}
										domain={["dataMin-1", "dataMax+1"]}
									/>
									<CartesianGrid strokeDasharray="3 3" />
									<Tooltip content={<PatientClinicalValuesTooltip dateFormat={dateFormat} />} />
									<Line type="monotone" dataKey="value" stroke="#8884d8" isAnimationActive={false} activeDot={{r: 8}} />
								</LineChart>
							</ResponsiveContainer>
						</div>
						{this.renderButtons()}
					</div>
				)}
			</div>
		)
	}
}

export default injectIntl(withStyles(styles)(PatientClinicalValuesChart))
