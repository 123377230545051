import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import moment from "moment"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import RequestsSVLZInsurerStore from "../../../stores/RequestsSVLZInsurerStore"

@injectIntl
@observer
export default class RequestsSVLZDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const req = RequestsSVLZInsurerStore.svlzRequest

		return (
			<Dialog
				id="xsRequestsSVLZDialog"
				open={RequestsSVLZInsurerStore.isOpenSVLZRequest}
				onClose={() => RequestsSVLZInsurerStore.closeSVLZRequest()}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-1050"
				}}
				disableBackdropClick={true}
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-absolute xs-close-btn" onClick={() => RequestsSVLZInsurerStore.closeSVLZRequest()}>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-book-alt fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.requestSVLZInsurer" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="content pt-4 overflowXHidden">
					<Grid container spacing={24} className="pl-8 pr-8 pb-4 pt-2">
						<Grid item container className="fontSize18">
							<Grid item className="xs-bold">
								{`${this.props.intl.formatMessage({id: "Common.label.request"})} - ${
									isNotEmpty(req.datum_vystavenia_ziadanky)
										? moment(req.datum_vystavenia_ziadanky).format("DD.MM.YYYY")
										: ""
								} (${isNotEmpty(req.typ_svlz_vysetrenia) ? req.typ_svlz_vysetrenia : "-"})`}
							</Grid>
						</Grid>
						<Grid item container>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.examinationSVLZType" />
								</Grid>
								<Grid item>{isNotEmpty(req.typ_svlz_vysetrenia) ? req.typ_svlz_vysetrenia : "-"}</Grid>
							</Grid>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.urgent" />
								</Grid>
								<Grid item>
									{isNotEmpty(req.datum_vystavenia_ziadanky) && req.datum_vystavenia_ziadanky ? "Áno" : "Nie"}
								</Grid>
							</Grid>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.requestState" />
								</Grid>
								<Grid item>{isNotEmpty(req.stav_ziadanky) ? req.stav_ziadanky : "-"}</Grid>
							</Grid>
						</Grid>
						<Grid item container>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.dateOfExposedRequest" />
								</Grid>
								<Grid item>
									{isNotEmpty(req.datum_vystavenia_ziadanky)
										? moment(req.datum_vystavenia_ziadanky).format("DD.MM.YYYY")
										: "-"}
								</Grid>
							</Grid>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.dateOfExposedRequest" />
								</Grid>
								<Grid item>
									{isNotEmpty(req.datum_cas_evidencie) ? moment(req.datum_cas_evidencie).format("DD.MM.YYYY") : "-"}
								</Grid>
							</Grid>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.dateLastModification" />
								</Grid>
								<Grid item>
									{isNotEmpty(req.datum_poslednej_modifikacie)
										? moment(req.datum_poslednej_modifikacie).format("DD.MM.YYYY")
										: "-"}
								</Grid>
							</Grid>
						</Grid>
						<Grid item container>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.resultAvailable" />
								</Grid>
								<Grid item>{isNotEmpty(req.dostupne_vysledky) && req.dostupne_vysledky ? "Áno" : "Nie"}</Grid>
							</Grid>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.resultAvailableInNZIS" />
								</Grid>
								<Grid item>{isNotEmpty(req.dostupne_vysledky_nzis) && req.dostupne_vysledky_nzis ? "Áno" : "Nie"}</Grid>
							</Grid>
						</Grid>
						<Grid item container>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.icoSendingPZS" />
								</Grid>
								<Grid item>{isNotEmpty(req.ico_odosielajuci_pzs) ? req.ico_odosielajuci_pzs : "-"}</Grid>
							</Grid>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.icoExaminationPZS" />
								</Grid>
								<Grid item>{isNotEmpty(req.ico_vysetrujuci_pzs) ? req.ico_vysetrujuci_pzs : "-"}</Grid>
							</Grid>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.nameExaminationPZS" />
								</Grid>
								<Grid item>{isNotEmpty(req.nazov_vysetrujuci_pzs) ? req.nazov_vysetrujuci_pzs : "-"}</Grid>
							</Grid>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.codeSendingNZ" />
								</Grid>
								<Grid item>{isNotEmpty(req.kod_odosielajuca_nz) ? req.kod_odosielajuca_nz : "-"}</Grid>
							</Grid>
						</Grid>
						<Grid item container>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.codeSendingDoctor" />
								</Grid>
								<Grid item>{isNotEmpty(req.kod_odosielajuci_lekar) ? req.kod_odosielajuci_lekar : "-"}</Grid>
							</Grid>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.nameSendingDoctor" />
								</Grid>
								<Grid item>{isNotEmpty(req.meno_odosielajuci_lekar) ? req.meno_odosielajuci_lekar : "-"}</Grid>
							</Grid>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.emailSendingDoctor" />
								</Grid>
								<Grid item>{isNotEmpty(req.odosielajuci_lekar_email) ? req.odosielajuci_lekar_email : "-"}</Grid>
							</Grid>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.phoneSendingDoctor" />
								</Grid>
								<Grid item>{isNotEmpty(req.odosielajuci_lekar_mobil) ? req.odosielajuci_lekar_mobil : "-"}</Grid>
							</Grid>
						</Grid>
						<Grid item container>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.codeRecomendedNZ" />
								</Grid>
								<Grid item>{isNotEmpty(req.kod_odporucajuca_nz) ? req.kod_odporucajuca_nz : "-"}</Grid>
							</Grid>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.codeRecommendedDoctor" />
								</Grid>
								<Grid item>{isNotEmpty(req.kod_odporucajuci_lekar) ? req.kod_odporucajuci_lekar : "-"}</Grid>
							</Grid>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.nameRecommendedDoctor" />
								</Grid>
								<Grid item>{isNotEmpty(req.meno_odporucajuci_lekar) ? req.meno_odporucajuci_lekar : "-"}</Grid>
							</Grid>
						</Grid>
						<Grid item container>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.diagnosis" />
								</Grid>
								<Grid item>{isNotEmpty(req.kody_diagnoz) ? req.kody_diagnoz : "-"}</Grid>
							</Grid>
						</Grid>
						<Grid item container>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.urgentZS" />
								</Grid>
								<Grid item>{isNotEmpty(req.neodkladna_zs) && req.neodkladna_zs ? "Áno" : "Nie"}</Grid>
							</Grid>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.nonpayer" />
								</Grid>
								<Grid item>{isNotEmpty(req.je_neplatic) && req.je_neplatic ? "Áno" : "Nie"}</Grid>
							</Grid>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.paperResults" />
								</Grid>
								<Grid item>{isNotEmpty(req.papierove_vysledky) && req.papierove_vysledky ? "Áno" : "Nie"}</Grid>
							</Grid>
							<Grid item xs={3} container direction="column">
								<Grid item className="fontSize13 greyDark xs-bold">
									<FormattedMessage id="Common.label.attachmentResults" />
								</Grid>
								<Grid item>{isNotEmpty(req.prilohy_vysledkov) && req.prilohy_vysledkov ? "Áno" : "Nie"}</Grid>
							</Grid>
						</Grid>
					</Grid>
					{isSafe(req.vzorky) &&
						req.vzorky.length > 0 &&
						req.vzorky.map((vzor, key) => {
							return (
								<Grid key={key} container className="pa-4 bgWhite">
									<Grid item container className="pb-3">
										<Grid item className="xs-bold">
											{`${this.props.intl.formatMessage({id: "Common.label.samples"})} ${
												isNotEmpty(vzor.nazov_typu_vzorky) ? "- " + vzor.nazov_typu_vzorky : ""
											}`}
										</Grid>
									</Grid>
									<Grid item container className="mb-2">
										<Grid item xs={3} container direction="column">
											<Grid item className="fontSize13 greyDark xs-bold">
												<FormattedMessage id="Common.label.codeSampleType" />
											</Grid>
											<Grid item>{isNotEmpty(vzor.kod_typ_vzorky) ? vzor.kod_typ_vzorky : "-"}</Grid>
										</Grid>
										<Grid item xs={3} container direction="column">
											<Grid item className="fontSize13 greyDark xs-bold">
												<FormattedMessage id="Common.label.nameSampleType" />
											</Grid>
											<Grid item>{isNotEmpty(vzor.nazov_typu_vzorky) ? vzor.nazov_typu_vzorky : "-"}</Grid>
										</Grid>
										<Grid item xs={3} container direction="column">
											<Grid item className="fontSize13 greyDark xs-bold">
												<FormattedMessage id="Common.label.dateOfSampling" />
											</Grid>
											<Grid item>
												{isNotEmpty(vzor.datum_odobratia_vzorky)
													? moment(vzor.datum_odobratia_vzorky).format("DD.MM.YYYY")
													: "-"}
											</Grid>
										</Grid>
										{isSafe(vzor.vysetrenia) &&
											vzor.vysetrenia.length > 0 &&
											vzor.vysetrenia.map((vys, id) => {
												return (
													<Grid
														item
														container
														key={`${key}_${id}`}
														spacing={16}
														className="pa-4 ma-2 bgSnowWhite"
														style={{borderRadius: "5px"}}
													>
														<Grid item container>
															<Grid item className="xs-bold">
																<FormattedMessage id="Common.label.examination" />
															</Grid>
														</Grid>
														<Grid item container>
															<Grid item xs={3} container direction="column">
																<Grid item className="fontSize13 greyDark xs-bold">
																	<FormattedMessage id="Common.label.examinationCode" />
																</Grid>
																<Grid item>{isNotEmpty(vys.kod_vysetrenia) ? vys.kod_vysetrenia : "-"}</Grid>
															</Grid>
															<Grid item xs={3} container direction="column">
																<Grid item className="fontSize13 greyDark xs-bold">
																	<FormattedMessage id="Common.label.examinationName" />
																</Grid>
																<Grid item>{isNotEmpty(vys.nazov_vysetrenia) ? vys.nazov_vysetrenia : "-"}</Grid>
															</Grid>
														</Grid>
														<Grid item container>
															<Grid item xs={3} container direction="column">
																<Grid item className="fontSize13 greyDark xs-bold">
																	<FormattedMessage id="Common.label.codeExaminerDoctor" />
																</Grid>
																<Grid item>
																	{isNotEmpty(vys.kod_vysetrujuci_lekar) ? vys.kod_vysetrujuci_lekar : "-"}
																</Grid>
															</Grid>
															<Grid item xs={3} container direction="column">
																<Grid item className="fontSize13 greyDark xs-bold">
																	<FormattedMessage id="Common.label.nameExaminerDoctor" />
																</Grid>
																<Grid item>
																	{isNotEmpty(vys.meno_vysetrujuci_lekar) ? vys.meno_vysetrujuci_lekar : "-"}
																</Grid>
															</Grid>
															<Grid item xs={3} container direction="column">
																<Grid item className="fontSize13 greyDark xs-bold">
																	<FormattedMessage id="Common.label.codeExaminerNZ" />
																</Grid>
																<Grid item>{isNotEmpty(vys.kod_vysetrujuca_nz) ? vys.kod_vysetrujuca_nz : "-"}</Grid>
															</Grid>
															<Grid item xs={3} container direction="column">
																<Grid item className="fontSize13 greyDark xs-bold">
																	<FormattedMessage id="Common.label.nameExaminerNZ" />
																</Grid>
																<Grid item>
																	{isNotEmpty(vys.nazov_vysetrujuca_nz) ? vys.nazov_vysetrujuca_nz : "-"}
																</Grid>
															</Grid>
														</Grid>
														<Grid item container>
															<Grid item xs={3} container direction="column">
																<Grid item className="fontSize13 greyDark xs-bold">
																	<FormattedMessage id="Common.label.dateExamination" />
																</Grid>
																<Grid item>
																	{isNotEmpty(vys.datum_vysetrenia)
																		? moment(vys.datum_vysetrenia).format("DD.MM.YYYY")
																		: "-"}
																</Grid>
															</Grid>
															<Grid item xs={3} container direction="column">
																<Grid item className="fontSize13 greyDark xs-bold">
																	<FormattedMessage id="Common.label.datePlanExamination" />
																</Grid>
																<Grid item>
																	{isNotEmpty(vys.planovany_datum_vysetrenia)
																		? moment(vys.planovany_datum_vysetrenia).format("DD.MM.YYYY")
																		: "-"}
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												)
											})}
									</Grid>
								</Grid>
							)
						})}
				</DialogContent>
				<DialogActions className="xs-footer xs-flex-end">
					<XsButton
						className="xs-danger xs-outline"
						onClick={() => RequestsSVLZInsurerStore.closeSVLZRequest()}
						text={<FormattedMessage id="Common.label.cancel" />}
						icon={<i className="fal fa-times fa-lg" />}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
