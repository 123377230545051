"use strict"

import {observable, action} from "mobx"
import PrintTemplatesStore from "./PrintTemplatesStore"

class PatientTemplatesDialogStore {
	@observable isOpen = false

	@action open() {
		this.isOpen = true

		PrintTemplatesStore.filterValue = ""
		PrintTemplatesStore.filteredTemplates = PrintTemplatesStore.printTemplates
	}

	@action close() {
		this.isOpen = false
	}
}

var singleton = new PatientTemplatesDialogStore()
export default singleton
