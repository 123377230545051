//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import renderHTML from "react-render-html"
import ConfirmStornoPrescriptionDialogStore from "../../../stores/ConfirmStornoPrescriptionDialogStore"

@observer
export default class ConfirmStornoPrescriptionDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsConfirmInteraction"
				className="dialog-cancel-prescription"
				open={ConfirmStornoPrescriptionDialogStore.isOpen}
				onClose={() => ConfirmStornoPrescriptionDialogStore.close()}
				//onEscapeKeyDown={() => DrugInteractionsDialogStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className={ConfirmStornoPrescriptionDialogStore.isWarning ? "xs-warning-header" : ""}>
					<div className="nx-generika-header">
						<div className="nx-header-title">
							<FormattedMessage id="Calendar.stornoPrescription.dialog.headerMessage" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent>
					<div className="nx-body">
						{renderHTML(ConfirmStornoPrescriptionDialogStore.warning)}
						{ConfirmStornoPrescriptionDialogStore.isWarning ? (
							<span>
								<br />
								<FormattedMessage id="Patient.stronoPrescription.areUsure" />{" "}
							</span>
						) : ConfirmStornoPrescriptionDialogStore.isStornoError ? (
							<span className="dialog-cancel-success-message">
								{" "}
								{ConfirmStornoPrescriptionDialogStore.warning && <br />}
							</span>
						) : (
							<span className="dialog-cancel-success-message">
								{" "}
								{ConfirmStornoPrescriptionDialogStore.warning && <br />}Storno prebehlo úspešne
							</span>
						)}
					</div>
				</DialogContent>
				<DialogActions>
					{!ConfirmStornoPrescriptionDialogStore.isWarning && (
						<div className="xs-button">
							<XsButton
								className="xs-success xs-outline action-ok"
								text={<FormattedMessage id="Common.label.ok" />}
								onClick={() => ConfirmStornoPrescriptionDialogStore.close()}
							/>
						</div>
					)}
					{ConfirmStornoPrescriptionDialogStore.isWarning && (
						<div className="xs-button">
							<XsButton
								className="xs-success xs-outline action-decline"
								text={<FormattedMessage id="Common.dialog.No" />}
								onClick={() => ConfirmStornoPrescriptionDialogStore.revertDelete()}
							/>
							<XsButton
								className="xs-danger xs-outline action-proceed"
								text={<FormattedMessage id="Common.dialog.Yes" />}
								onClick={() => ConfirmStornoPrescriptionDialogStore.continue()}
							/>
						</div>
					)}
				</DialogActions>
			</Dialog>
		)
	}
}
