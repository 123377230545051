/*
  Unified Fields Props Definition
*/
import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				name: {
					label: <FormattedMessage id="Common.label.name" />,
					rules: "required|string"
				},
				scope: {
					type: "checkbox",
					label: <FormattedMessage id="Patient.form.template.private" />
				},
				templateStream: {
					label: " ",
					rules: "required"
				},
				validityFrom: {},
				validityTo: {}
			}
		}
	}
}
