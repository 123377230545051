import React from "react"
import {FormattedMessage} from "react-intl"

export default {
	load() {
		return {
			fields: {
				from: {
					label: <FormattedMessage id="Common.label.from" />,
					rules: "required",
					type: "date"
				},
				to: {
					label: <FormattedMessage id="Common.label.to" />,
					rules: "required",
					type: "date"
				},
				provided_service: {
					label: <FormattedMessage id="Common.label.providedServices" />,
					type: "string"
				},
				status_id: {
					label: <FormattedMessage id="Common.label.state" />,
					type: "string",
					value: "F"
				}
			}
		}
	}
}
