import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import {Dialog, DialogContent, DialogTitle, DialogActions, Grid, Typography} from "@material-ui/core"
import moment from "moment"
import classnames from "classnames"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsBarcode from "../../../../../global/ui/xsBarcode/xsBarcode"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

import CovidResultStore from "../../../stores/CovidResultStore"

@observer
export default class CovidResultDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const covidRes = CovidResultStore.covidResult
		return (
			<Dialog
				id="xsCovidResultDialog"
				open={CovidResultStore.isOpen}
				onClose={() => CovidResultStore.close()}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-900"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-reply fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.covidResult" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="pa-4 overflowY overflowXHidden">
					{CovidResultStore.loading ? (
						<XsLoading />
					) : (
						<React.Fragment>
							{isSafe(covidRes) ? (
								<Grid container justify="center">
									<Grid item>
										<XsBarcode
											value={covidRes.vCovid19Pass}
											text={covidRes.vCovid19Pass}
											format="CODE128"
											ean128={true}
											displayValue={true}
											height={50}
										/>
									</Grid>
									{isSafe(covidRes.Results) &&
										covidRes.Results.length > 0 &&
										covidRes.Results.map((res, idx) => (
											<Grid
												item
												container
												key={idx}
												className={classnames({"pt-2": idx > 0, "pb-2": idx + 1 < covidRes.Results.length})}
												style={idx > 0 ? {borderTop: "1px solid #e0e5ef"} : {}}
												spacing={8}
											>
												<Grid item container spacing={8}>
													<Grid item xs={3}>
														<Typography align="right">
															<FormattedMessage id="Common.label.type" />:
														</Typography>
													</Grid>
													<Grid item xs={9}>
														{res.vMicrobiologyScreeningType}
													</Grid>
												</Grid>
												<Grid item container spacing={8}>
													<Grid item xs={3}>
														<Typography align="right">
															<FormattedMessage id="Common.label.result" />:
														</Typography>
													</Grid>
													<Grid item xs={9}>
														{res.vScreeningFinalResult}
													</Grid>
												</Grid>
												<Grid item container spacing={8}>
													<Grid item xs={3}>
														<Typography align="right">
															<FormattedMessage id="Common.label.day" />:
														</Typography>
													</Grid>
													<Grid item xs={9}>
														{isNotEmpty(res.vScreeningEndedAt)
															? moment(res.vScreeningEndedAt).format("DD.MM.YYYY HH:mm")
															: ""}
													</Grid>
												</Grid>
												<Grid item container spacing={8}>
													<Grid item xs={3}>
														<Typography align="right">
															<FormattedMessage id="Common.label.icoLaboratory" />:
														</Typography>
													</Grid>
													<Grid item xs={9}>
														{res.vTestedByCareProviderCin}
													</Grid>
												</Grid>
												<Grid item container spacing={8}>
													<Grid item xs={3}>
														<Typography align="right">
															<FormattedMessage id="Common.label.pzsCodeLaboratory" />:
														</Typography>
													</Grid>
													<Grid item xs={9}>
														{res.vTestedByCareProviderCode}
													</Grid>
												</Grid>
												<Grid item container spacing={8}>
													<Grid item xs={3}>
														<Typography align="right">
															<FormattedMessage id="Common.label.laboratoryName" />:
														</Typography>
													</Grid>
													<Grid item xs={9}>
														{res.vTestedByCareProviderName}
													</Grid>
												</Grid>
											</Grid>
										))}
								</Grid>
							) : (
								<Grid container justify="center">
									<Grid item>Žiadne dostupné výsledky</Grid>
								</Grid>
							)}
						</React.Fragment>
					)}
				</DialogContent>
				<DialogActions className="xs-footer">
					<XsButton
						className="xs-outline xs-default"
						icon={<i className="fal fa-check" />}
						text={<FormattedMessage id="Common.label.ok" />}
						onClick={() => CovidResultStore.close()}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
