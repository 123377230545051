import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import moment from "moment"
import GlobalStore from "../../../../global/store/GlobalStore"

import {Grid} from "@material-ui/core"

import {getHour, getMinute, getDate, getMonth, getYear} from "../../../../global/ui/xsDateTimePicker/helpers"
import AddEventDialogStore from "../../stores/AddEventDialogStore"
import CalendarStore from "../../stores/CalendarStore"
import {XsDateTimePickerDirect} from "../../../../global/ui/xsDateTimePickerDirect/xsDateTimePickerDirect"

@injectIntl
@observer
export default class AddEventCalendar extends React.Component {
	constructor(props) {
		super(props)
	}

	handleDayClick = (selectedDate) => {
		let date = getDate(selectedDate)
		let month = getMonth(selectedDate)
		let year = getYear(selectedDate)
		let hour = getHour(moment())
		let minute = getMinute(selectedDate)
		let currDate = getDate(moment())
		let currMonth = getMonth(moment())
		let currYear = getYear(moment())

		let datetime = null

		if (currDate != date || currMonth != month || currYear != year) {
			let hour =
				isSafe(GlobalStore.openingHour) && isSafe(GlobalStore.openingHour[selectedDate.day()])
					? GlobalStore.openingHour[selectedDate.day()].from
					: 0

			datetime = moment().set({date, month, year, hour, minute})
		} else {
			minute = CalendarStore.getRoundTime(moment())
			datetime = moment().set({date, month, year, hour, minute})
		}

		CalendarStore.selectedDay = datetime
		delayedCallback(500, () => AddEventDialogStore.setSlots())
		CalendarStore.inputDateTimeIsValid = datetime.format("DD.MM.YYYY HH:mm")

		// CalendarStore.loadPatientsOrder(
		// 	moment(datetime)
		// 		.startOf("day")
		// 		.utc()
		// 		.format(),
		// 	moment(datetime)
		// 		.endOf("day")
		// 		.utc()
		// 		.format()
		// )
	}

	handleTimeClick = (selectedDateTime, hourList) => {
		let date = getDate(CalendarStore.selectedDay)
		let month = getMonth(selectedDateTime)
		let year = getYear(selectedDateTime)
		let hour = hourList ? getHour(selectedDateTime) : getHour(CalendarStore.selectedDay)
		let minute = hourList ? getMinute(CalendarStore.selectedDay) : getMinute(selectedDateTime)

		const datetime = moment().set({date, month, year, hour, minute})

		CalendarStore.selectedDay = datetime
		delayedCallback(500, () => AddEventDialogStore.setSlots())
		CalendarStore.inputDateTimeIsValid = datetime.format("DD.MM.YYYY HH:mm")

		// CalendarStore.loadPatientsOrder(
		// 	moment(datetime)
		// 		.startOf("day")
		// 		.utc()
		// 		.format(),
		// 	moment(datetime)
		// 		.endOf("day")
		// 		.utc()
		// 		.format()
		// )
	}

	render() {
		return (
			<Grid item className="xs-datetime-picker">
				<XsDateTimePickerDirect
					onMonthChange={(sDateTime) => CalendarStore.handleEventsByDay(sDateTime)}
					onYearChange={(sDateTime) => CalendarStore.handleEventsByDay(sDateTime)}
					onDayClick={(sDateTime) => this.handleDayClick(sDateTime)}
					onTimeClick={(sDateTime, hourList) => this.handleTimeClick(sDateTime, hourList)}
					highlightDates={CalendarStore.eventsByDay}
					selected={CalendarStore.selectedDay}
					showTimeSelect={true}
					minDate={
						isSafe(this.props.setScheduleToPastInMins)
							? moment().add(-this.props.setScheduleToPastInMins, "minutes")
							: moment()
					}
					inline={true}
				/>
			</Grid>
		)
	}
}
