import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {FormattedMessage, injectIntl} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
// import TextField from "@material-ui/core/TextField"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
// import fields from "./eHealthSearchDialogFormFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import EhealthConsentDialogStore from "../../../stores/EhealthConsentDialogStore"
import {Grid, FormControlLabel, Checkbox, RadioGroup, Radio} from "@material-ui/core"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
// import {XsDateTimePicker} from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
// import SearchDropdownNoForm from "../../../../../global/ui/xsSearchdropdown/xsSearchdropdownNoForm"
// import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
// import api from "../../../actions/api"

@injectIntl
@observer
export default class EHealthConsentDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		const fields = {
			auth: {
				label: <FormattedMessage id="PatientDetail.overview.eHealth.authType" />,
				rules: "required",
				value: "PinRequired"
			}
		}

		this.form = new MobxReactForm({fields: fields}, {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	// handleSearchClick() {
	// 	Object.entries(this.form.values()).forEach((obj) => {
	// 		EhealthConsentDialogStore.handleChange(obj[0], obj[1])
	// 	})

	// 	EhealthConsentDialogStore.search()
	// 	this.form.reset()
	// }

	render() {
		const confimType = [
			{
				name_ext: "Potvrdenie",
				_id: "ChallengeResponse"
			},
			{
				name_ext: "Pin",
				_id: "PinRequired"
			},
			{
				name_ext: "Bez overenia",
				_id: "NoPinRequired"
			}
		]

		// const checkedTypes =
		// 	isSafe(EhealthConsentDialogStore.examinationTypes) && EhealthConsentDialogStore.examinationTypes.length > 0
		// 		? EhealthConsentDialogStore.examinationTypes.map((x) => x)
		// 		: null

		// const examinationTypeValues = examinationType.map((et, idx) => {
		// 	return {
		// 		id: idx,
		// 		search_string: et,
		// 		// primaryText: et,
		// 		secondaryText: et,
		// 		checkValue: isSafe(checkedTypes) && checkedTypes.includes(et)
		// 	}
		// })

		return (
			<Dialog
				id="xsEHealthConsentDialog"
				open={EhealthConsentDialogStore.isOpen}
				onClose={() => EhealthConsentDialogStore.close()}
				disableBackdropClick={true}
				classes={{
					paper: "xs-paper-dialog"
				}}
				maxWidth="md"
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-base">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-search fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="PatientDetail.overview.eHealth.searching" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent>
					<Grid container direction="column">
						<Grid item xs={12} className="pa-4">
							<Grid container direction="row" spacing={8}>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Checkbox
												value="hasAgreement"
												checked={EhealthConsentDialogStore.hasAgreement}
												onChange={(e) => EhealthConsentDialogStore.handleChange("hasAgreement", e.target.checked)}
											/>
										}
										label={<FormattedMessage id="PatientDetail.overview.eHealth.hasAgreement" />}
									/>
								</Grid>
							</Grid>
							<Grid container direction="row">
								<Grid item xs={12}>
									<RadioGroup
										className="xs-radio-buttons-sensitivity"
										onChange={(e) => EhealthConsentDialogStore.handleChange("sensitivity", e.target.value)}
										value={EhealthConsentDialogStore.sensitivity}
									>
										<FormControlLabel
											className="xs-control"
											value="3"
											control={<Radio />}
											label={<FormattedMessage id="PatientDetail.overview.eHealth.sensitivityMedium" />}
										/>
										<FormControlLabel
											className="xs-control"
											value="5"
											control={<Radio />}
											label={<FormattedMessage id="PatientDetail.overview.eHealth.sensitivityHigh" />}
										/>
									</RadioGroup>
								</Grid>
							</Grid>
							<Grid container direction="row">
								<Grid item xs={12}>
									<SearchSelect field={this.form.$("auth")} items={confimType} required />
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Checkbox
												value="oau"
												checked={EhealthConsentDialogStore.oau}
												onChange={(e) => EhealthConsentDialogStore.handleChange("oau", e.target.checked)}
											/>
										}
										label={<FormattedMessage id="PatientDetail.overview.eHealth.oau" />}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Checkbox
												value="oup"
												checked={EhealthConsentDialogStore.oup}
												onChange={(e) => EhealthConsentDialogStore.handleChange("oup", e.target.checked)}
											/>
										}
										label={<FormattedMessage id="PatientDetail.overview.eHealth.oup" />}
									/>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Checkbox
												value="phr"
												checked={EhealthConsentDialogStore.phr}
												onChange={(e) => EhealthConsentDialogStore.handleChange("phr", e.target.checked)}
											/>
										}
										label={<FormattedMessage id="PatientDetail.overview.eHealth.phr" />}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Checkbox
												value="liek"
												checked={EhealthConsentDialogStore.liek}
												onChange={(e) => EhealthConsentDialogStore.handleChange("liek", e.target.checked)}
											/>
										}
										label={<FormattedMessage id="PatientDetail.overview.eHealth.liek" />}
									/>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Checkbox
												value="rec"
												checked={EhealthConsentDialogStore.rec}
												onChange={(e) => EhealthConsentDialogStore.handleChange("rec", e.target.checked)}
											/>
										}
										label={<FormattedMessage id="PatientDetail.overview.eHealth.rec" />}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Checkbox
												value="disp"
												checked={EhealthConsentDialogStore.disp}
												onChange={(e) => EhealthConsentDialogStore.handleChange("disp", e.target.checked)}
											/>
										}
										label={<FormattedMessage id="PatientDetail.overview.eHealth.disp" />}
									/>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Checkbox
												value="psu"
												checked={EhealthConsentDialogStore.psu}
												onChange={(e) => EhealthConsentDialogStore.handleChange("psu", e.target.checked)}
											/>
										}
										label={<FormattedMessage id="PatientDetail.overview.eHealth.psu" />}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Checkbox
												value="dps"
												checked={EhealthConsentDialogStore.dps}
												onChange={(e) => EhealthConsentDialogStore.handleChange("dps", e.target.checked)}
											/>
										}
										label={<FormattedMessage id="PatientDetail.overview.eHealth.dps" />}
									/>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Checkbox
												value="lbz"
												checked={EhealthConsentDialogStore.lbz}
												onChange={(e) => EhealthConsentDialogStore.handleChange("lbz", e.target.checked)}
											/>
										}
										label={<FormattedMessage id="PatientDetail.overview.eHealth.lbz" />}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Checkbox
												value="lbv"
												checked={EhealthConsentDialogStore.lbv}
												onChange={(e) => EhealthConsentDialogStore.handleChange("lbv", e.target.checked)}
											/>
										}
										label={<FormattedMessage id="PatientDetail.overview.eHealth.lbv" />}
									/>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Checkbox
												value="vvp"
												checked={EhealthConsentDialogStore.vvp}
												onChange={(e) => EhealthConsentDialogStore.handleChange("vvp", e.target.checked)}
											/>
										}
										label={<FormattedMessage id="PatientDetail.overview.eHealth.vvp" />}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Checkbox
												value="vla"
												checked={EhealthConsentDialogStore.vla}
												onChange={(e) => EhealthConsentDialogStore.handleChange("vla", e.target.checked)}
											/>
										}
										label={<FormattedMessage id="PatientDetail.overview.eHealth.vla" />}
									/>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={8}>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Checkbox
												value="vlu"
												checked={EhealthConsentDialogStore.vlu}
												onChange={(e) => EhealthConsentDialogStore.handleChange("vlu", e.target.checked)}
											/>
										}
										label={<FormattedMessage id="PatientDetail.overview.eHealth.vlu" />}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => (EhealthConsentDialogStore.close(), this.form.reset())}
					/>
					<XsButton
						disabled={!this.form.isValid}
						className={!this.form.isValid ? "xs-default" : "xs-success"}
						text={<FormattedMessage id="Common.btn.write" />}
						onClick={() => EhealthConsentDialogStore.requestConsent(this.form)}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
