import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import moment from "moment"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import DeleteIcon from "@material-ui/icons/Clear"

import GlobalStore from "../../../../../global/store/GlobalStore"
import PatientPayDebtDialogStore from "../../../stores/PatientPayDebtDialogStore"
import {TextField, Grid} from "@material-ui/core"

@observer
export default class PatientPayDebtDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsPatientPayDebt"
				open={PatientPayDebtDialogStore.isOpen}
				onClose={() => PatientPayDebtDialogStore.close()}
				//onEscapeKeyDown={() => DrugInteractionsDialogStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle>
					<div className="xs-base-header">
						<div className="xs-header-title">
							<FormattedMessage id="Patient.payDept.dialog.header" />
						</div>
						<XsIconButton
							className="xs-dialog-header"
							icon={<DeleteIcon onClick={() => PatientPayDebtDialogStore.close()} />}
						/>
					</div>
				</DialogTitle>
				<DialogContent>
					<div className="xs-base-dialog-body xs-patient-pay-debt">
						{PatientPayDebtDialogStore.steps === 1 && (
							<div className="xs-column xs-align">
								{isNotEmpty(PatientPayDebtDialogStore.dlhCelkom) && (
									<div className="xs-base-item">
										<div className="xs-base-label" style={{textAlign: "right"}}>
											<FormattedMessage id="Patient.payDept.dialog.amountOfDebt" />:
										</div>
										<div className="xs-base-text" style={{whiteSpace: "nowrap"}}>
											{PatientPayDebtDialogStore.dlhCelkom} €
										</div>
									</div>
								)}
								<div style={{paddingTop: "15px"}}>
									<FormattedMessage id="Patient.payDept.dialog.countinuePayment" />
								</div>
							</div>
						)}
						{PatientPayDebtDialogStore.steps === 2 && (
							<div className="xs-column xs-align" style={{paddingTop: "15px"}}>
								{isNotEmpty(PatientPayDebtDialogStore.dlhCelkom) && (
									<div className="xs-base-item">
										<div className="xs-base-label" style={{textAlign: "right"}}>
											<FormattedMessage id="Patient.payDept.dialog.amountOfDebt" />:
										</div>
										<div className="xs-base-text" style={{whiteSpace: "nowrap"}}>
											{PatientPayDebtDialogStore.dlhCelkom} €
										</div>
									</div>
								)}
								<div style={{paddingTop: "15px"}}>
									<FormattedMessage id="Patient.payDept.dialog.printMessage" />
								</div>
								<div style={{paddingTop: "15px"}}>
									<FormattedMessage id="Patient.payDept.dialog.signConsent" />
								</div>
							</div>
						)}
						{PatientPayDebtDialogStore.steps === 3 && (
							<div className="xs-column">
								{isSafe(PatientPayDebtDialogStore.symbols) && (
									<div style={{paddingBottom: "20px"}}>
										<div className="xs-base-item" style={{justifyContent: "center"}}>
											<div className="xs-base-text">
												<FormattedMessage id="Patient.payDebt.dialog.symbolsForPayment" />
											</div>
										</div>
										{isSafe(PatientPayDebtDialogStore.symbols.id) && (
											<div className="xs-base-item" style={{paddingTop: "15px"}}>
												<div className="xs-base-label" style={{textAlign: "right"}}>
													<FormattedMessage id="Patient.payDept.dialog.id" />:
												</div>
												<div className="xs-base-text">{PatientPayDebtDialogStore.symbols.id}</div>
											</div>
										)}
										{isSafe(PatientPayDebtDialogStore.symbols.vs) && (
											<div className="xs-base-item">
												<div className="xs-base-label" style={{textAlign: "right"}}>
													<FormattedMessage id="Patient.payDept.dialog.vs" />:
												</div>
												<div className="xs-base-text">{PatientPayDebtDialogStore.symbols.vs}</div>
											</div>
										)}
										{isSafe(PatientPayDebtDialogStore.symbols.ss) && (
											<div className="xs-base-item">
												<div className="xs-base-label" style={{textAlign: "right"}}>
													<FormattedMessage id="Patient.payDept.dialog.ss" />:
												</div>
												<div className="xs-base-text">{PatientPayDebtDialogStore.symbols.ss}</div>
											</div>
										)}
										{isSafe(PatientPayDebtDialogStore.symbols.ks) && (
											<div className="xs-base-item">
												<div className="xs-base-label" style={{textAlign: "right"}}>
													<FormattedMessage id="Patient.payDept.dialog.ks" />:
												</div>
												<div className="xs-base-text">{PatientPayDebtDialogStore.symbols.ks}</div>
											</div>
										)}
										{isSafe(PatientPayDebtDialogStore.symbols.suma_na_uhradu) && (
											<div className="xs-base-item">
												<div className="xs-base-label" style={{textAlign: "right"}}>
													<FormattedMessage id="Patient.payDept.dialog.amount" />:
												</div>
												<div className="xs-base-text">{PatientPayDebtDialogStore.symbols.suma_na_uhradu} €</div>
											</div>
										)}
										{isSafe(PatientPayDebtDialogStore.symbols.datum_uhrady) && (
											<div className="xs-base-item">
												<div className="xs-base-label" style={{textAlign: "right"}}>
													<FormattedMessage id="Patient.payDept.dialog.dateOfPayment" />:
												</div>
												<div className="xs-base-text">
													{moment(PatientPayDebtDialogStore.symbols.datum_uhrady).format("DD.MM.YYYY HH:mm")}
												</div>
											</div>
										)}
										<div style={{display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "15px"}}>
											<div>
												<FormattedMessage id="Patient.payDebt.dialog.contactInfoMessageFirst" />
											</div>
											<div style={{color: "#a1a4a9"}}>
												<FormattedMessage id="Patient.payDebt.dialog.contactInfoMessageSecond" />
											</div>
										</div>
									</div>
								)}
								<Grid container direction="row" spacing={8}>
									<Grid item xs={6}>
										<TextField
											label={<FormattedMessage id="Common.label.email" />}
											defaultValue={PatientPayDebtDialogStore.email}
											onChange={(e) => (PatientPayDebtDialogStore.email = e.target.value)}
											InputLabelProps={{
												shrink: true
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField
											label={<FormattedMessage id="Patient.payDept.dialog.mobile" />}
											defaultValue={PatientPayDebtDialogStore.phone}
											onChange={(e) => (PatientPayDebtDialogStore.phone = e.target.value)}
											InputLabelProps={{
												shrink: true
											}}
										/>
									</Grid>
								</Grid>
							</div>
						)}
						{PatientPayDebtDialogStore.steps === 4 && isNotEmpty(PatientPayDebtDialogStore.message) && (
							<div>
								<FormattedMessage id={`${PatientPayDebtDialogStore.message}`} />
							</div>
						)}
					</div>
				</DialogContent>
				<DialogActions>
					<div className="xs-dialog-btn-center">
						{PatientPayDebtDialogStore.steps === 1 && (
							<XsButton
								className="xs-success"
								text={<FormattedMessage id="Patient.payDept.dialog.continueRepay" />}
								onClick={() => PatientPayDebtDialogStore.testuj()}
							/>
						)}
						{PatientPayDebtDialogStore.steps === 2 && (
							<XsButton
								className="xs-success"
								text={<FormattedMessage id="Patient.payDept.dialog.continueRepay" />}
								onClick={() => PatientPayDebtDialogStore.dajsymbolypreuhradu()}
							/>
						)}
						{/* {
              PatientPayDebtDialogStore.steps === 2 &&
              <XsButton
                className="xs-success xs-outline"
                text={<FormattedMessage id="Patient.payDept.dialog.print" />}
                onClick={() => PatientPayDebtDialogStore.printReport()} />
            } */}
						{(PatientPayDebtDialogStore.steps === 1 || PatientPayDebtDialogStore.steps === 2) && (
							<XsButton
								className="xs-default xs-outline"
								text={<FormattedMessage id="Common.label.cancel" />}
								onClick={() => PatientPayDebtDialogStore.close()}
							/>
						)}
						{PatientPayDebtDialogStore.steps === 3 && (
							<XsButton
								className="xs-success"
								text={<FormattedMessage id="Patient.payDept.dialog.patientPaid" />}
								onClick={() => GlobalStore.openConfirmationDialog("xsContinuePaymentConfirmation")}
							/>
						)}
						{PatientPayDebtDialogStore.steps === 3 && (
							<XsButton
								className="xs-default xs-outline"
								text={<FormattedMessage id="Common.label.cancel" />}
								onClick={() => PatientPayDebtDialogStore.stornoSymbolovPreUhradu()}
							/>
						)}
						{PatientPayDebtDialogStore.steps === 4 && (
							<XsButton
								className="xs-default xs-outline"
								text={<FormattedMessage id="Common.label.ok" />}
								onClick={() => PatientPayDebtDialogStore.close()}
							/>
						)}
					</div>
				</DialogActions>
				<XsConfirmationDialog
					name="xsContinuePaymentConfirmation"
					title={<FormattedMessage id="Common.label.warning" />}
					text={<FormattedMessage id="Patient.payDebt.dialog.Confirmation.ContinuePayment" />}
					onConfirmation={() => PatientPayDebtDialogStore.zaevidujUhradu()}
				/>
			</Dialog>
		)
	}
}
