import {FormattedMessage} from "react-intl"
import React from "react"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				date_from: {
					label: <FormattedMessage id="Common.lable.datetimeFrom" />,
					value: moment().startOf("day")
				},
				date_to: {
					label: <FormattedMessage id="Common.lable.datetimeTo" />,
					value: moment().endOf("day")
				},
				place_ids: {
					label: "ID očkovacích miest",
					placeholder: "1221, 233",
					type: "string"
				}
			}
		}
	}
}
