"use strict"

import UIStore from "../../../stores/UIStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import PatientAnamnesisStore from "../../../stores/PatientAnamnesisStore"
import DataStore from "../../../stores/DataStore"
import {getSelectedOrgUnitID, getUserPersonnelID} from "../../../../../global/helpers/actions"
// import StorageEx from "../../../../../global/storage/storageEx"

export default {
	load(props, onSave) {
		return {
			onSubmit(form) {
				// special validation here
				logger("SUBMIT", JSON.parse(form.$("diagnosisChipsForm").value))
				JSON.parse(form.$("diagnosisChipsForm").value).forEach((i) => {
					if (isSafe(i) && (isEmpty(i.col1) || isEmpty(i.col2))) {
						form.$("operator").invalidate()
					}
				})
			},
			onSuccess(form) {
				// form.$("closeForm").value = true
				PatientAnamnesisStore.saveForm(
					form,
					DataStore.patientDTO.get("patientID"),
					getSelectedOrgUnitID(),
					getUserPersonnelID(),
					"EHR.Data.OperatingProtocol",
					"OP",
					() => onSave("OPERATION")
					// deleteFromSession
				)
				// UIStore.clinicalValuesFormExtended = false
				UIStore.overviewTab = "overview" // "operation_protocol" - presmerovanie na prehlad
			},
			onError() {
				// special error notification here
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}
	}
}
