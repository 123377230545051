import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {FormattedMessage, injectIntl} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import fields from "./writeSpaRequestDialogFormFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import WriteSpaRequestDialogStore from "../../../stores/WriteSpaRequestDialogStore"
import XsAutocompleteLocal from "../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegisterCacheStore from "../../../../../global/store/RegistersCacheStore"

@injectIntl
@observer
export default class WriteSpaRequestDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	render() {
		WriteSpaRequestDialogStore.errorWriteMessage = this.props.intl.formatMessage({
			id: "Common.label.writeSpaRequestsError"
		})

		return (
			<Dialog
				id="xsWriteSpaRequestDialog"
				open={WriteSpaRequestDialogStore.isOpen}
				onClose={() => {
					WriteSpaRequestDialogStore.close()
					this.form.reset()
				}}
				disableBackdropClick={true}
				maxWidth="md"
				className="xs-base-dialog"
				classes={{
					paper: "overflowSearch"
				}}
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-pencil fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.writeSpaRequest" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content overflowYInherit">
					<XsAutocompleteLocal
						field={this.form.$("diagnosis")}
						minLengthForSearch="3"
						data={RegisterCacheStore.diagnosisRegister}
						inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
						saveValue={(obj) => obj.code}
						modalConfig={(textValue, clickHandler) => {
							return {
								columnDefs: {
									code_ext: {
										title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
										type: "string",
										dbName: "code_ext",
										design: {
											width: "150px"
										},
										filter: {
											gridWidth: 3,
											defaultValue: "",
											renderElement: "input"
										}
									},
									name_ext: {
										title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
										type: "string",
										dbName: "search_column",
										filter: {
											gridWidth: 9,
											defaultValue: textValue,
											renderElement: "input"
										}
									}
								},
								options: {
									showCursor: true,
									onRowClick: (dataRow) => {
										clickHandler(dataRow)
									}
								}
							}
						}}
						onChange={(value) => WriteSpaRequestDialogStore.onHandleChange(value)}
					/>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.btn.not.write" />}
						onClick={() => (WriteSpaRequestDialogStore.close(), this.form.reset())}
					/>
					<XsButton
						className="xs-info"
						icon={<i className="fal fa-pencil" />}
						text={<FormattedMessage id="Common.btn.write" />}
						onClick={() => {
							WriteSpaRequestDialogStore.onWrite()
							this.form.reset()
						}}
						disabled={isEmpty(WriteSpaRequestDialogStore.diagnose)}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
