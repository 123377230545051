import React, {useEffect} from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import ReactTooltip from "react-tooltip"
import moment from "moment"
// import renderHTML from "react-render-html"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import {Grid} from "@material-ui/core"

import CovidCertificatesDialogStore from "../../../stores/CovidCertificatesDialogStore"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

function CovidCertificatesDialog() {
	useEffect(() => {
		CovidCertificatesDialogStore.searchCovidCertificates()
	}, [])

	return (
		<Dialog
			id="xsCovidCertificatesDialog"
			open={CovidCertificatesDialogStore.isOpen}
			onClose={() => CovidCertificatesDialogStore.close()}
			disableBackdropClick={true}
			maxWidth="md"
			classes={{
				paper: "overflowSearch xs-width-paper-900"
			}}
			className="xs-base-dialog"
		>
			<DialogTitle>
				<div className="xs-header">
					<div className="xs-header-icon">
						<i className="fal fa-file-import fa-2x" />
					</div>
					<div className="xs-header-title">
						<FormattedMessage id="Common.label.covidCertifications" />
					</div>
				</div>
			</DialogTitle>
			<DialogContent className="xs-content">
				{CovidCertificatesDialogStore.loading ? (
					<XsLoading />
				) : (
					<React.Fragment>
						<Grid container>
							<Grid item xs={4}>
								<XsSearchSelect
									value={CovidCertificatesDialogStore.filterVal}
									label={"Typ"}
									items={[
										// {label: "", code: "Ockovanie", _id: "Ockovanie"},
										{label: "Ockovanie", code: "Ockovanie", _id: "Ockovanie"},
										{label: "TestCovid", code: "TestCovid", _id: "TestCovid"},
										{label: "PrekonaneOchorenie", code: "PrekonaneOchorenie", _id: "PrekonaneOchorenie"}
									]}
									onChange={(val) => {
										logger("VALUE", val)
										CovidCertificatesDialogStore.filterVal = val
										setTimeout(() => {
											CovidCertificatesDialogStore.searchCovidCertificates()
										}, 0)
									}}
								/>
							</Grid>
						</Grid>
						<XsTable
							config={{
								columnDefs: {
									certificate_type: {
										title: "Typ",
										type: "string",
										design: {
											width: "200px"
										}
									},
									created_at: {
										title: "Vytvorené",
										type: "datetime",
										design: {
											width: "150px",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format("DD.MM.YYYY HH:mm") : ""
												}
											}
										},
										sortable: true
									},
									valid_from: {
										title: "Platnosť od",
										type: "datetime",
										design: {
											width: "150px",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format("DD.MM.YYYY HH:mm") : ""
												}
											}
										},
										sortable: true
									},
									valid_to: {
										title: "Platnosť do",
										type: "datetime",
										design: {
											width: "150px",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format("DD.MM.YYYY HH:mm") : ""
												}
											}
										},
										sortable: true
									},
									download: {
										title: "",
										type: "action",
										design: {
											width: "50px",
											body: {
												renderer: (props) => {
													return (
														<XsIconButton
															icon={
																<span>
																	<i className="fas fa-download fa-lg" data-tip data-for="downloadCert"></i>
																	<ReactTooltip id="downloadCert" effect="solid">
																		Stiahnuť a vytlačiť digitálny certifikát pacienta
																	</ReactTooltip>
																</span>
															}
															onClick={() =>
																CovidCertificatesDialogStore.getCovidCertificate(
																	props.value.certId,
																	props.value.certType
																)
															}
														/>
													)
												}
											}
										}
									}
								},
								options: {
									hidePager: true,
									showCursor: true,
									// onRowClick: (dataRow) => {
									// 	this.onHandleRowClick(dataRow)
									// },
									mapper: (dataRow) => {
										return {
											certificate_type: dataRow.certificate_type,
											created_at: dataRow.created_at,
											valid_from: dataRow.valid_from,
											valid_to: dataRow.valid_to,
											download: {certId: dataRow.certificate_id, certType: dataRow.certificate_type}
										}
									}
								},
								dataSource: CovidCertificatesDialogStore.certificates
							}}
						/>
					</React.Fragment>
				)}
			</DialogContent>
			<DialogActions className="xs-footer">
				<XsButton
					className="xs-default xs-outline"
					icon={<i className="fal fa-times" />}
					text={<FormattedMessage id="Common.label.cancel" />}
					onClick={() => CovidCertificatesDialogStore.close()}
				/>
			</DialogActions>
		</Dialog>
	)
}

export default observer(CovidCertificatesDialog)
