import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import ExportSicknessAbsToXlsDialog from "./exportSicknessAbsToXlsDialog"
import RouterStore from "../../../../../global/store/RouterStore"
import UIStore from "../../../stores/UIStore"
import SicknessAbsReportListStore from "../../../stores/SicknessAbsReportListStore"
import WarningStore from "../../../../../global/store/WarningStore"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import TableStore from "../../../stores/TableStore"

// definitions of form fields
import fields from "./sicknessAbsReportListFields"

import moment from "moment"

@injectIntl
@observer
export default class SicknessAbsReportList extends React.Component {
	constructor(props) {
		super(props)
		SicknessAbsReportListStore.isFetching = false
		UIStore.isFormSaving = false

		SicknessAbsReportListStore.patientsOverview = []

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				if (form.values().datefrom || form.values().dateto) {
					if (
						TableStore.tables.hasOwnProperty("sicknessAbsReportList") &&
						TableStore.tables["sicknessAbsReportList"].hasOwnProperty("index")
					) {
						delete TableStore.tables["sicknessAbsReportList"]["index"]
					}

					SicknessAbsReportListStore.loadPatientsOverview(form)
				} else {
					WarningStore.open(props.intl.formatMessage({id: "Common.label.warningDateFromDateTo"}))
				}
			},
			onError() {}
		}

		if (isEmpty(SicknessAbsReportListStore.formRef)) {
			SicknessAbsReportListStore.formRef = new MobxReactForm(fields.load(), {
				plugins: {dvr: validatorjs},
				hooks,
				bindings
			})
		}

		this.form = SicknessAbsReportListStore.formRef

		if (this.form.$("datefrom").value || this.form.$("dateto").value) {
			SicknessAbsReportListStore.loadPatientsOverview(this.form)
		}
	}

	onHandleRowClick = (dataRow) => {
		if (dataRow.person_id) {
			RouterStore.push(`/patient?id=${dataRow.person_id}`)
		}
	}

	render() {
		SicknessAbsReportListStore.isChangedFilter(this.form.values())

		const dateFormat = this.props.intl.formatMessage({id: "Application.moment.dateformat"})
		return (
			<Grid container className="xs-sicknessAbsReport-list">
				<Grid item xs={12}>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}>
							<XsDateTimePicker white field={this.form.$("datefrom")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker white field={this.form.$("dateto")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={2}>
							<XsInput white field={this.form.$("number")} />
						</Grid>
						<Grid item xs={1} className="pb-0">
							<XsIconButton
								rect
								className="xs-default xs-outline"
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								onClick={this.form.onSubmit}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
						<Grid item xs={5}>
							<Grid container justify="flex-end" spacing={8}>
								<Grid item>
									<XsButton
										className="xs-primary"
										text={<FormattedMessage id="Common.label.export" />}
										icon={<i className="fal fa-file-excel fa-lg" />}
										onClick={() => {
											SicknessAbsReportListStore.open()
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{SicknessAbsReportListStore.isFetching ? (
						<XsLoading />
					) : SicknessAbsReportListStore.changedFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i className="far fa-search fa-lg mr-3 pointer" onClick={this.form.onSubmit}></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTable
							setRef={(table) => (SicknessAbsReportListStore.sicknessAbsReportListRef = table)}
							config={{
								columnDefs: {
									valid_from: {
										title: <FormattedMessage id="Capitation.List.From" />,
										type: "datetime",
										design: {
											width: "100px",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format(dateFormat) : ""
												}
											}
										},
										sortable: true
									},
									valid_to: {
										title: <FormattedMessage id="Capitation.List.To" />,
										type: "datetime",
										design: {
											width: "100px",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format(dateFormat) : ""
												}
											}
										},
										sortable: true
									},
									patient: {
										title: <FormattedMessage id="Capitation.List.Patient" />,
										type: "string",
										design: {
											width: "250px",
											body: {
												className: "blueDark xs-bold"
											}
										},
										sortable: true
									},
									identifier: {
										title: <FormattedMessage id="Patient.form.patientinfo.identifier" />,
										type: "string",
										design: {
											width: "120px"
										},
										sortable: true
									},
									dg: {
										title: <FormattedMessage id="Patient.form.patientrecord.diagnosis" />,
										type: "string",
										sortable: true
									},
									type: {
										title: <FormattedMessage id="Patient.form.contactForm.typ" />,
										type: "string",
										design: {
											width: "170px"
										}
									},
									number: {
										title: <FormattedMessage id="Common.label.number" />,
										type: "string",
										design: {
											width: "150px"
										}
									}
								},
								options: {
									name: "sicknessAbsReportList",
									showCursor: true,
									// selectRow: true,
									defaultSort: {columnName: "stamp", sortDirection: "asc"},
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									},
									mapper: (dataRow) => {
										// const lastInsurance =
										// 	isSafe(dataRow.full_patient) &&
										// 	isSafe(dataRow.full_patient.insurances) &&
										// 	dataRow.full_patient.insurances.length > 0
										// 		? dataRow.full_patient.insurances.find((i) => i.last)
										// 		: null
										return {
											person_id:
												isSafe(dataRow.full_patient) && dataRow.full_patient.entity
													? dataRow.full_patient.entity._id
													: null,
											valid_from: isSafe(dataRow.validity) && dataRow.validity.from ? dataRow.validity.from : "",
											valid_to: isSafe(dataRow.validity) && dataRow.validity.to ? dataRow.validity.to : "",
											patient:
												isSafe(dataRow.full_patient) && dataRow.full_patient.entity
													? dataRow.full_patient.entity.full_name
													: "",
											identifier:
												isSafe(dataRow.full_patient) && dataRow.full_patient.entity
													? dataRow.full_patient.entity.identifier
													: "",
											// insurer: isSafe(lastInsurance)
											// 	? `${lastInsurance.insurer.code_ext} - ${lastInsurance.insurer.name_ext}`
											// 	: "",
											dg: isSafe(dataRow.diagnosis)
												? `${dataRow.diagnosis_code_ext} - ${dataRow.diagnosis_name_ext}`
												: "",
											type: isSafe(dataRow.sub_type) ? dataRow.sub_type.name_ext : "",
											number: isSafe(dataRow.number) ? dataRow.number : ""
										}
									}
								},
								dataSource: SicknessAbsReportListStore.patientsOverview
							}}
						/>
					)}
				</Grid>
				<ExportSicknessAbsToXlsDialog />
				{UIStore.isFormSaving && <XsLoading overlay={true} />}
			</Grid>
		)
	}
}
