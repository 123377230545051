import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import {FormattedMessage, injectIntl} from "react-intl"
import validatorjs from "validatorjs"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import "./exportCapitations.less"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsSearchdropdownNoForm from "../../../../../global/ui/xsSearchdropdown/xsSearchdropdownNoForm"
import fields from "./exportCapitationFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import ExportCapitationDialogStore from "../../../stores/ExportCapitationDialogStore"
import {Grid} from "@material-ui/core"

@injectIntl
@observer
export default class ExportCapitationDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		this.form.$("report_type").value = [
			{
				secondaryText: "#",
				primaryText: `${this.props.intl.formatMessage({id: "Capitation.form.ReportOfCount"})}`,
				search_string: `# (${this.props.intl.formatMessage({id: "Capitation.form.ReportOfCount"})})`,
				id: "#",
				checkValue: true
			},
			{
				secondaryText: "+",
				primaryText: `${this.props.intl.formatMessage({id: "Capitation.form.ReportOfIncrement"})}`,
				search_string: `# (${this.props.intl.formatMessage({id: "Capitation.form.ReportOfIncrement"})})`,
				id: "+",
				checkValue: true
			},
			{
				secondaryText: "-",
				primaryText: `${this.props.intl.formatMessage({id: "Capitation.form.ReportOfLoss"})}`,
				search_string: `# (${this.props.intl.formatMessage({id: "Capitation.form.ReportOfLoss"})})`,
				id: "-",
				checkValue: true
			}
		]
		ExportCapitationDialogStore.insurers = []
	}

	onSubmitClick = () => {
		// Object.entries(this.form.values()).forEach(obj => {
		//   ExportCapitationDialogStore.handleChange(obj[0], obj[1])
		// })

		// ExportCapitationDialogStore.getSummaryCapitations()
		ExportCapitationDialogStore.downloadMultipleCapitations(this.form)
	}

	getInsurersForDropdown = () => {
		if (isSafe(this.props.insurers) && !ExportCapitationDialogStore.insurers.length) {
			this.props.insurers.forEach((row) => {
				ExportCapitationDialogStore.insurers.push({
					id: row._id,
					secondaryText: isNotEmpty(row.company_short_name) ? row.company_short_name : "  ",
					primaryText: row.description,
					search_string: row.search_column,
					checkValue: true
				})
			})
			this.form.$("insurer").set(ExportCapitationDialogStore.insurers)
		}

		return ExportCapitationDialogStore.insurers
	}

	render() {
		return (
			<Dialog
				id="xsExportDeliveries"
				open={ExportCapitationDialogStore.isOpen}
				onClose={() => ExportCapitationDialogStore.close()}
				disableBackdropClick={true}
				classes={{
					paper: "xs-paper-dialog"
				}}
				maxWidth="md"
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-base">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-save fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Capitation.form.ExportBatch" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<Grid container direction="column">
						<Grid item xs={12}>
							<Grid container direction="row">
								<Grid item xs={12}>
									<div className="searchDropdownDiv">
										<div className="searchDropdownLabel">{<FormattedMessage id="Capitation.form.insurers" />}</div>
										<XsSearchdropdownNoForm
											// label={<FormattedMessage id="Capitation.form.insurers" />}
											id="insurers"
											field={this.form.$("insurer")}
											items={this.getInsurersForDropdown()}
											multiSelect={true}
											onClose={(data) => {
												this.form.$("insurer").set(data)
												ExportCapitationDialogStore.insurers = data
											}}
										/>
									</div>
								</Grid>
							</Grid>
							<Grid container direction="row">
								<Grid item xs={12}>
									{/* <XsCheckbox field={this.form.$("reportOfCount")} />
                  <XsCheckbox field={this.form.$("reportOfIncrement")} />
                  <XsCheckbox field={this.form.$("reportOfLoss")} /> */}
									<div className="searchDropdownDiv">
										<div className="searchDropdownLabel">{<FormattedMessage id="Capitation.form.ReportType" />}</div>
										<XsSearchdropdownNoForm
											id="ReportType"
											// label={<FormattedMessage id="Common.label.diagnosis" />}
											field={this.form.$("report_type")}
											items={[
												{
													secondaryText: "#",
													primaryText: `${this.props.intl.formatMessage({id: "Capitation.form.ReportOfCount"})}`,
													search_string: `# (${this.props.intl.formatMessage({id: "Capitation.form.ReportOfCount"})})`,
													id: "#",
													checkValue: true
												},
												{
													secondaryText: "+",
													primaryText: `${this.props.intl.formatMessage({id: "Capitation.form.ReportOfIncrement"})}`,
													search_string: `# (${this.props.intl.formatMessage({
														id: "Capitation.form.ReportOfIncrement"
													})})`,
													id: "+",
													checkValue: true
												},
												{
													secondaryText: "-",
													primaryText: `${this.props.intl.formatMessage({id: "Capitation.form.ReportOfLoss"})}`,
													search_string: `# (${this.props.intl.formatMessage({id: "Capitation.form.ReportOfLoss"})})`,
													id: "-",
													checkValue: true
												}
											]}
											multiSelect={true}
											onClose={(data) => {
												this.form.$("report_type").set(data)
											}}
										/>
									</div>
								</Grid>
								<Grid />
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => (ExportCapitationDialogStore.close(), this.form.reset())}
					/>
					<XsButton
						className={"xs-success"}
						icon={<i className="fal fa-save" />}
						text={<FormattedMessage id="Capitation.form.ExportBatch" />}
						// disabled={!this.form.isValid}
						onClick={() => this.onSubmitClick()}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
