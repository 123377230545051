import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import {FormattedMessage, injectIntl} from "react-intl"
import validatorjs from "validatorjs"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"

import fields from "./exportSocInsDeliveriesFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import ExportSocInsDeliveryDialogStore from "../../../stores/ExportSocInsDeliveryDialogStore"
import {Grid} from "@material-ui/core"
import GlobalStore from "../../../../../global/store/GlobalStore"
import {getSelectedOrgUnitID} from "../../../../../global/helpers/actions"

@injectIntl
@observer
export default class ExportSocInsDeliveryDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	onSubmitClick = () => {
		// Object.entries(this.form.values()).forEach((obj) => {
		// 	ExportSocInsDeliveryDialogStore.handleChange(obj[0], obj[1])
		// })
		let formVals = this.form.values()
		ExportSocInsDeliveryDialogStore.invoiceNumber = formVals.invoiceNumber
		ExportSocInsDeliveryDialogStore.allToDelivery = formVals.allToDelivery
		//Vyberiem len zachecknute orgUnits
		let selectedOrg = GlobalStore.orgunits.filter((obj, idx) => formVals.orgUnitCheckboxes[idx])
		if (isSafe(selectedOrg)) {
			ExportSocInsDeliveryDialogStore.selectedOrgUnitsIds = selectedOrg.map((obj) => obj._id)
			ExportSocInsDeliveryDialogStore.getSocSummaryDeliveries(
				selectedOrg.map((obj) => obj._id),
				ExportSocInsDeliveryDialogStore.selectedOrgUnitsIds.length
			)
		}
	}

	componentDidMount() {
		GlobalStore.orgunits.forEach((org, idx) => {
			this.form.$("orgUnitCheckboxes").add(
				{
					key: idx,
					label: org.provider_code + " (" + org.name_ext + ")",
					type: "checkbox"
				},
				true
			)
		})
	}

	render() {
		return (
			<Dialog
				id="xsExportSocInsDeliveries"
				open={ExportSocInsDeliveryDialogStore.isOpen}
				onClose={() => ExportSocInsDeliveryDialogStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
				className="xs-base-dialog"
				onEnter={() => {
					this.form.reset()
					let selectedOrg = getSelectedOrgUnitID()
					let orgIndex = GlobalStore.orgunits.map((org) => org._id).indexOf(selectedOrg)
					//Predplnenie podla prave selectnutej orgunit
					this.form
						.$("orgUnitCheckboxes")
						.fields.get(`${orgIndex}`)
						.set("value", true)
				}}
			>
				<DialogTitle className="xs-base">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-save fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="ExportSocInsDeliveries.dialog.title" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<Grid container direction="column">
						<Grid item xs={12}>
							<Grid container direction="row">
								<Grid item xs={12}>
									<b className="xs-content-subtitle">
										<FormattedMessage id="ExportSocInsDeliveries.dialog.subTitle" />
									</b>
								</Grid>
								<Grid item xs={12}>
									{GlobalStore.orgunits.map((org, idx) => {
										return (
											<div key={idx}>
												<div className="form">
													<XsCheckbox field={this.form.$("orgUnitCheckboxes").fields.get(idx.toString())} />
												</div>
											</div>
										)
									})}
								</Grid>
								<Grid item xs={12}>
									<XsInput
										field={this.form.$("invoiceNumber")}
										placeholder={this.props.intl.formatMessage({id: "Invoice.form.optionalInvoiceNumber"})}
									/>
								</Grid>
								<Grid item xs={12}>
									<XsCheckbox field={this.form.$("allToDelivery")} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default xs-outline"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.cancel" />}
						onClick={() => (ExportSocInsDeliveryDialogStore.close(), this.form.reset())}
					/>
					<XsButton
						className={!this.form.isValid ? "xs-default" : "xs-success"}
						icon={<i className="fal fa-save" />}
						text={<FormattedMessage id="Capitation.form.ExportBatch" />}
						disabled={!this.form.isValid}
						onClick={() => this.onSubmitClick()}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
