import React from "react"
import ReactTooltip from "react-tooltip"
import {FormattedMessage, injectIntl} from "react-intl"
import {observer} from "mobx-react"
import moment from "moment"
import classnames from "classnames"

import CalendarStore from "../../stores/CalendarStore"
import GlobalStore from "../../../../global/store/GlobalStore"
import EditEventDialogStore from "../../stores/EditEventDialogStore"
import AddEventDialogStore from "../../stores/AddEventDialogStore"
import ResourcePlusViewHeader from "./resourcePlusViewHeader"

import {Grid, Tooltip} from "@material-ui/core"

@injectIntl
@observer
export default class ResourcePlusView extends React.Component {
	constructor(props) {
		super(props)

		this.weeklyRow = {}
	}

	getHourLabelClass = (hour) => {
		return moment()
			.local()
			.get("hours") == hour
			? "xs-weekly-hour xs-hour-active"
			: "xs-weekly-hour"
	}

	isActiveHour = (hour) => {
		return (
			moment()
				.local()
				.get("hours") == hour
		)
	}

	getOpenHours = () => {
		let from = 0
		let to = 23
		let hours = []
		for (let i = from; i <= to; i++) {
			hours.push(i)
		}

		return hours
	}

	componentDidUpdate() {
		this.setPos()
	}

	setPos = () => {
		if (isSafe(this.weeklyViewRef) && !CalendarStore.calendarToggledFull) {
			let hour = moment().hours()

			let scrollVal = this.weeklyRow[hour].offsetTop - 160
			this.weeklyViewRef.scrollTop = scrollVal
		}
	}

	getInsurerStatus = (status) => {
		switch (status) {
			case 0:
				return (
					<Tooltip
						title={this.props.intl.formatMessage({
							id: "Common.label.patientNotExistUDZS"
						})}
						placement="left"
					>
						<i className="fas fa-check-circle fa-lg redDanger" />
					</Tooltip>
				)
			case 1:
				return (
					<Tooltip
						title={this.props.intl.formatMessage({
							id: "Common.label.patientHasInsurerUDZS"
						})}
						placement="left"
					>
						<i className="fas fa-check-circle fa-lg greenSuccess" />
					</Tooltip>
				)
			case 2:
				return (
					<Tooltip
						title={this.props.intl.formatMessage({
							id: "Common.label.patientNotHaveCorrectInsurerUDZS"
						})}
						placement="left"
					>
						<i className="fas fa-check-circle fa-lg amberWarning" />
					</Tooltip>
				)
		}
	}

	getEventStatus = (orderTime, status) => {
		if (status == "F") {
			return (
				<div className="xs-state-icon">
					<Tooltip
						title={this.props.intl.formatMessage({
							id: "Common.label.eventEnd"
						})}
						placement="left"
					>
						<i className="fas fa-user-check fa-lg greenSuccess" />
					</Tooltip>
				</div>
			)
		} else {
			if (status == "P") {
				return (
					<div className="xs-state-icon">
						<Tooltip
							title={this.props.intl.formatMessage({
								id: "Common.label.inProgress"
							})}
							placement="left"
						>
							<i className="fas fa-user-cog fa-lg amberWarning" />
						</Tooltip>
					</div>
				)
			} else {
				if (moment(orderTime).isBefore(moment())) {
					return (
						<div className="xs-state-icon">
							<Tooltip
								title={this.props.intl.formatMessage({
									id: "Common.label.notEventEnd"
								})}
								placement="left"
							>
								<i className="fas fa-user fa-lg greyDefault" />
							</Tooltip>
						</div>
					)
				}
			}
		}
	}

	openAddEventDialog = (hour, resourceId, orgunitId) => {
		if (EditEventDialogStore.isClickedEdit) {
			EditEventDialogStore.isClickedEdit = false
		} else {
			if (
				moment(CalendarStore.selectedDay)
					.set("hour", hour)
					.set("minute", 0)
					.set("second", 0)
					.isAfter(moment())
			) {
				CalendarStore.selectedDay = moment(CalendarStore.selectedDay)
					.set("hour", hour)
					.set("minute", 0)
					.set("second", 0)
				CalendarStore.inputDateTimeIsValid = moment(CalendarStore.selectedDay)
					.set("hour", hour)
					.set("minute", 0)
					.set("second", 0)
					.format("DD.MM.YYYY HH:mm")

				AddEventDialogStore.open(false, {_id: resourceId, provider_id: orgunitId})
			}
		}
	}

	tempElemDrag = null
	dragEvent = null

	drop = (e, hour, resourceId, providerId) => {
		e.preventDefault()
		EditEventDialogStore.moveEvent(this.dragEvent, providerId, resourceId, hour)
	}

	dragOverDrop = (e) => {
		e.preventDefault()
	}

	dragStart = (e, event) => {
		const target = e.target

		e.dataTransfer.setData("card_id", target.id)

		this.tempElemDrag = target
		this.dragEvent = event
		// setTimeout(() => {
		// 	target.style.display = "none"
		// }, 0)
	}

	dragOver = (e) => {
		e.stopPropagation()
	}

	renderEvent = (event, index) => {
		const service = isSafe(event.services) && event.services.length > 0 ? event.services[0] : null
		const client = isSafe(event.client) ? event.client : null

		const duration = isSafe(service) && isSafe(service.duration) ? Math.ceil(+service.duration / 60) : 0
		let fullName =
			isSafe(client) && isEmpty(client.last_name) && isEmpty(client.first_name)
				? client.client_text
				: `${client.last_name} ${client.first_name}`

		if (isNotEmpty(client.insurer_short_name)) {
			fullName += ` (${client.insurer_short_name})`
		}

		if (isNotEmpty(client.phone)) {
			fullName += `, ${client.phone}`
		}

		let subInfo = isSafe(client) && isNotEmpty(client.identifier) ? client.identifier : ""

		if (isNotEmpty(subInfo)) {
			if (isSafe(service) && isNotEmpty(service.service_name_ext)) {
				subInfo += `, ${service.service_name_ext}`
			}
		} else {
			if (isSafe(service) && isNotEmpty(service.service_name_ext)) {
				subInfo = service.service_name_ext
			}
		}

		if (isNotEmpty(event.event_note_text)) {
			subInfo += ` (${event.event_note_text})`
		}

		return (
			<Grid
				item
				key={index}
				id={event.event_id}
				draggable="true"
				onDragStart={(e) => this.dragStart(e, event)}
				onDragOver={this.dragOver}
				container
				className="bgWhite borderRadius-1 border-greyLight pt-1 pb-1 pl-2 pr-2 xs-resource-item pointer"
				alignItems="center"
				// style={{height: "45px"}}
				onClick={() => {
					EditEventDialogStore.isClickedEdit = true
					EditEventDialogStore.open(event, true)
				}}
			>
				<Grid item>{moment(event.interval_from).format("HH:mm")}</Grid>
				<Grid
					item
					className="pa-1 ml-2 mr-2 borderRadius-1 textCenter"
					style={{backgroundColor: service.back_color, color: service.fore_color, width: "40px"}}
				>
					{duration}
				</Grid>
				<Grid item xs container direction="column">
					<Grid item className="blueDark">
						{fullName}
					</Grid>
					<Grid item className="fontSize13 greyDark">
						{subInfo}
					</Grid>
				</Grid>
				<Grid item className="ml-1">
					{this.getInsurerStatus(event.insurer_status)}
				</Grid>
				<Grid item className="ml-1">
					{this.getEventStatus(event.interval_from, event.status._id)}
				</Grid>
			</Grid>
		)
	}

	inTheFuture = (hour) => {
		return moment(CalendarStore.selectedDay)
			.set("hour", hour)
			.set("minute", 0)
			.set("second", 0)
			.isAfter(moment())
	}

	render() {
		const expandTooltip = CalendarStore.calendarToggledFull ? "hideCalendar" : "showAllCalendar"

		const {hideFullToggle} = this.props

		return (
			<div className="xs-weekly-view resource-view">
				<ResourcePlusViewHeader />
				<div className="xs-weekly-body-scrollbar" ref>
					<div
						ref={(div) => (this.weeklyViewRef = div)}
						className={`xs-weekly-body ${CalendarStore.calendarToggledFull || hideFullToggle ? "xs-unfix" : "xs-fix"}`}
					>
						{this.getOpenHours().map((hour, i) => {
							return (
								<div key={i} ref={(row) => (this.weeklyRow[hour] = row)} className="xs-weekly-row">
									<div className={this.getHourLabelClass(hour)}>{hour}:00</div>
									{CalendarStore.storageResources.map((orgunit, idx) => {
										const inTheFuture = this.inTheFuture(hour)
										return (
											<Grid
												container
												key={`${i}_${idx}`}
												id={`${i}_${idx}`}
												onDrop={(e) => (inTheFuture ? this.drop(e, hour, orgunit.resource_id, orgunit._id) : null)}
												onDragOver={inTheFuture ? this.dragOverDrop : null}
												className={classnames("xs-flex borderWhite pa-1", {
													pointer: inTheFuture && isNotEmpty(orgunit._id)
												})}
												onClick={() =>
													isNotEmpty(orgunit._id)
														? this.openAddEventDialog(hour, orgunit.resource_id, orgunit._id)
														: GlobalStore.setNotificationMessage("Common.label.firstSelectOrgunit", "", "error")
												}
											>
												{isSafe(CalendarStore.resourceData[orgunit.resource_id]) &&
													isSafe(CalendarStore.resourceData[orgunit.resource_id][hour]) &&
													CalendarStore.resourceData[orgunit.resource_id][hour].length > 0 &&
													CalendarStore.resourceData[orgunit.resource_id][hour].map((event, index) =>
														this.renderEvent(event, index)
													)}
											</Grid>
										)
									})}
								</div>
							)
						})}
					</div>
				</div>
				<div
					data-tip
					data-for={expandTooltip}
					className="xs-full-screen"
					onClick={() => (CalendarStore.calendarToggledFull = !CalendarStore.calendarToggledFull)}
				>
					<i className={`fal fa-chevron-${CalendarStore.calendarToggledFull ? "up" : "down"} fa-lg"`} />
				</div>
				{/* )} */}
				<ReactTooltip id={expandTooltip}>
					<FormattedMessage id={`Common.label.${expandTooltip}`} />
				</ReactTooltip>
			</div>
		)
	}
}
