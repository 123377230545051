import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import Grid from "@material-ui/core/Grid"

// special import for Material-UI binding
import api from "../../../actions/api"
import XsTableServer from "../../../../../global/ui/xsTableServer/xsTableServer"
import VaccByRegionReportStore from "../../../stores/VaccByRegionReportStore"

import VaccByRegionFilters from "./vaccByRegionFilters"
// import ExportMedicationToXlsDialog from "./exportMedicationToXlsDialog"
import UIStore from "../../../stores/UIStore"
import TableStore from "../../../stores/TableStore"

@injectIntl
@observer
export default class VaccByRegionReport extends React.Component {
	constructor(props) {
		super(props)
		UIStore.isFormSaving = false
	}

	// onHandleRowClick = (dataRow) => {
	// 	if (isSafe(dataRow.person_id)) {
	// 		GlobalStore.changeOrgUnitAndRouteToPatientDetail(
	// 			VaccByRegionReportStore.formRef.$("org_unit").value,
	// 			dataRow.person_id
	// 		)
	// 	}
	// }

	render() {
		const applyFilters = VaccByRegionReportStore.filters

		return (
			<Grid container className="xs-vaccination-list">
				<VaccByRegionFilters />
				<Grid item xs={12}>
					{VaccByRegionReportStore.changedFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i
								className="far fa-search fa-lg mr-3 pointer"
								onClick={() => {
									if (
										TableStore.tables.hasOwnProperty("vaccinationList") &&
										TableStore.tables["vaccinationList"].hasOwnProperty("index")
									) {
										delete TableStore.tables["vaccinationList"]["index"]
									}

									VaccByRegionReportStore.getFilters(VaccByRegionReportStore.formRef)
								}}
							></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTableServer
							setRef={(table) => (VaccByRegionReportStore.vaccListRef = table)}
							uri={api.loadVaccReport().getPath()}
							filterValue={applyFilters}
							config={{
								columnDefs: {
									addr_zip: {
										title: <FormattedMessage id="Common.label.zip" />,
										type: "string",
										design: {
											width: "125px"
										}
									},
									addr_city: {
										title: <FormattedMessage id="Common.label.city" />,
										type: "string"
									},
									age: {
										title: <FormattedMessage id="Delivery.List.Age" />,
										type: "string",
										design: {
											width: "125px"
										}
									},
									gender: {
										title: <FormattedMessage id="Common.label.gender" />,
										type: "string",
										design: {
											width: "125px"
										}
									},
									insurer_id: {
										title: <FormattedMessage id="Common.label.insurer" />,
										type: "string",
										design: {
											width: "125px"
										}
									},
									isVaccinated: {
										title: <FormattedMessage id="Common.label.vaccinated" />,
										type: "string",
										design: {
											width: "125px",
											body: {
												renderer: (props) => {
													return isNotEmpty(props) && props ? "Áno" : "Nie"
												}
											}
										}
									}
								},
								options: {
									name: "vaccByRegionList",
									showCursor: true
									// mapper: (dataRow) => {
									// 	return {
									// 		created_at: isSafe(dataRow.created_at) ? dataRow.created_at : "",
									// 		code_ext: isSafe(dataRow.code_ext) ? dataRow.code_ext : "",
									// 		name_ext: isSafe(dataRow.name_ext) ? dataRow.name_ext : "",
									// 		manufacturer_ext: isSafe(dataRow.manufacturer_ext) ? dataRow.manufacturer_ext : "",
									// 		identifier: isSafe(dataRow.identifier) ? dataRow.identifier : "",
									// 		person_id: isSafe(dataRow.person_id) ? dataRow.person_id : "",
									// 		expiration_date: isSafe(dataRow.expiration_date) ? dataRow.expiration_date : "",
									// 		administered_at: isSafe(dataRow.administered_at) ? dataRow.administered_at : "",
									// 		batch_number: isSafe(dataRow.batch_number) ? dataRow.batch_number : ""
									// 	}
									// }
									// onRowClick: (dataRow) => {
									// 	this.onHandleRowClick(dataRow)
									// }
								}
							}}
						/>
					)}
				</Grid>
				{/* <ExportMedicationToXlsDialog /> */}
			</Grid>
		)
	}
}
