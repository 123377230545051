import {FormattedMessage} from "react-intl"
import React from "react"
// import {getSelectedOrgUnit} from "../../../../../global/helpers/actions"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				orgunit: {
					label: <FormattedMessage id="Patient.form.patientrequest.orgunit" />,
					rules: "string"
				},
				date_from: {
					label: <FormattedMessage id="Capitation.List.From" />,
					type: "date",
					rules: "required",
					value: moment().startOf("year")
				},
				date_to: {
					label: <FormattedMessage id="Capitation.List.To" />,
					type: "date",
					rules: "required",
					value: moment().endOf("year")
				},
				deliveries: {
					label: "Výkony",
					placeholder: "napr. C10,1"
				},
				dgn: {
					label: "Diagnózy"
				}
			}
		}
	}
}
