//@flow
import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import CancelIcon from "@material-ui/icons/Clear"

// import XsDateTimePicker from "../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsButton from "../../../../global/ui/xsButton/xsButton"

import OtherDayDialogStore from "../../stores/OtherDayDialogStore"
import CalendarStore from "../../stores/CalendarStore"
import {XsDateTimePickerDirect} from "../../../../global/ui/xsDateTimePickerDirect/xsDateTimePickerDirect"

@observer
export default class OtherDayDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsOtherDayDialog"
				open={OtherDayDialogStore.isOpen}
				onClose={() => OtherDayDialogStore.close()}
				onEscapeKeyDown={() => OtherDayDialogStore.close()}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<div className="xs-header">
					<div className="xs-year">{CalendarStore.selectedDay.format("YYYY")}</div>
					<div className="xs-date-format">{CalendarStore.selectedDay.format("ddd, MMM DD")}</div>
				</div>
				<DialogContent>
					<div className="nx-body">
						<div className="xs-datetime-picker xs-datetime-picker-right-arrow">
							<XsDateTimePickerDirect
								onChange={(sDateTime) => OtherDayDialogStore.handleDateTimePickerChange(sDateTime)}
								onMonthChange={(sDateTime) => CalendarStore.handleEventsByDay(sDateTime)}
								onYearChange={(sDateTime) => CalendarStore.handleEventsByDay(sDateTime)}
								selected={CalendarStore.selectedDay}
								highlightDates={CalendarStore.eventsByDay}
								inline={true}
							/>
						</div>
						<div className="xs-button">
							<XsButton
								className="xs-danger xs-outline"
								icon={<CancelIcon />}
								text={<FormattedMessage id="Common.label.cancel" />}
								onClick={() => OtherDayDialogStore.close()}
							/>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		)
	}
}
