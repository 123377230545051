import React from "react"
import {FormattedMessage} from "react-intl"

export default {
	load() {
		return {
			fields: {
				invoice: {
					label: "faktura"
				},
				insurer: {
					label: <FormattedMessage id="Common.label.insurer" />
				},
				orgunit: {
					label: <FormattedMessage id="Patient.form.patientrequest.orgunit" />,
					rules: "string"
				},
				partner: {
					label: <FormattedMessage id="Invoice.form.customer" />
				},
				capitation: {
					label: "KAP",
					type: "checkbox"
				},
				bezd: {
					label: "BEZD",
					type: "checkbox"
				},
				cudz: {
					label: "CUDZ",
					type: "checkbox"
				},
				eu: {
					label: "EU",
					type: "checkbox"
				},
				ine: {
					label: "INE",
					type: "checkbox"
				},
				vzp: {
					label: "VZP",
					type: "checkbox"
				}
			}
		}
	}
}
