"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"

import {getUser, getSelectedOrgUnit, getSelectedOrgUnitID} from "../../../global/helpers/actions"

import UIStore from "../stores/UIStore"
import GlobalStore from "../../../global/store/GlobalStore"
import DataStore from "../stores/DataStore"
import PrintTemplatesStore from "../stores/PrintTemplatesStore"

class RequestsSVLZInsurerStore {
	@observable isOpen = false
	@observable isOpenViewer = false
	@observable isOpenAcceptOfTheTerm = false
	@observable acceptOfTheTermData = null
	@observable confirmTerm = false
	@observable confirmRegistration = false

	acceptFormRef = null

	@observable showSMSField = false

	@observable isOpenShowPDF = false
	@observable vysledkySVLZPoistencaPDF = null
	@observable titlePDF = "Common.label.resultSVLZ"
	fileName = ""

	requestsSVLZAll = []
	@observable requestsSVLZ = []
	@observable isOpenRequests = {}

	@observable isOpenSVLZRequest = false
	@observable svlzRequest = {}

	@observable openFromTemplateId = ""

	tempFormValues = null
	tagsCode = []
	tagsCodeActual = []

	icoLab = ""
	endpointSVLZ = ""
	overovaciTokenDZP = ""
	personnelExpertiseId = ""

	@action open() {
		this.checkSMSCodeNeeded()
		this.isOpen = true
	}

	@action checkSMSCodeNeeded() {
		api
			.getDZPELabSMSCodeNeeded()
			.call()
			.then((res) => {
				if (isSafe(res)) {
					this.showSMSField = true

					const request = {
						patient_id: DataStore.patientDTO.get("patientID"),
						provider_id: getSelectedOrgUnitID()
					}

					api
						.dajkodprezdravotnukartu(request)
						.call()
						.then((response) => {
							if (isSafe(response) && isSafe(response.odoslana) && response.odoslana) {
								GlobalStore.setNotificationMessage("SMS odoslaná")
							} else {
								if (isSafe(response.warning) && isNotEmpty(response.warning.text)) {
									GlobalStore.setNotificationMessage(response.warning.text)
								} else {
									GlobalStore.setNotificationMessage("Nepodarilo sa odoslat SMS")
								}
							}
						})
				} else {
					this.showSMSField = false
				}
			})
	}

	@action openShowPDF(base64, title) {
		this.vysledkySVLZPoistencaPDF = base64
		this.titlePDF = title
		this.isOpenShowPDF = true
	}

	@action closeShowPDF() {
		this.isOpenShowPDF = false
		this.vysledkySVLZPoistencaPDF = null
		this.titlePDF = "Common.label.resultSVLZ"
	}

	@action openRequestDetail(idx) {
		this.isOpenRequests[idx] = true
	}

	@action closeRequestDetail(idx) {
		this.isOpenRequests[idx] = false
	}

	@action openAcceptOfTheTerm(response) {
		this.acceptOfTheTermData = response

		let userData = getUser()

		if (isSafe(userData)) {
			if (isSafe(userData.orgunits) && userData.orgunits.length > 0) {
				const orgunits = userData.orgunits.filter((org) => org._id == getSelectedOrgUnitID())

				if (isSafe(orgunits) && orgunits.length > 0) {
					this.acceptFormRef.$("codeNZ").set("value", orgunits[0].provider_code)
					this.acceptFormRef.$("doctorCode").set("value", orgunits[0].doctor_code)
					if (isSafe(orgunits[0].addresses) && orgunits[0].addresses.length > 0) {
						this.acceptFormRef.$("address").set("value", orgunits[0].addresses[0].full_address)
					}
					if (isSafe(orgunits[0].contacts) && orgunits[0].contacts.length > 0) {
						this.acceptFormRef.$("mobile").set("value", orgunits[0].contacts[0].mobile)
						this.acceptFormRef.$("email").set("value", orgunits[0].contacts[0].email)
					}

					this.personnelExpertiseId = orgunits[0].personnel_expertise_id
				}
			}

			if (isSafe(userData.person) && isNotEmpty(userData.person.full_name)) {
				this.acceptFormRef.$("doctorName").set("value", userData.person.full_name)
			}

			if (isSafe(userData.company)) {
				if (isNotEmpty(userData.company.name)) {
					this.acceptFormRef.$("name").set("value", userData.company.name)
				}

				if (isNotEmpty(userData.company.identifier)) {
					this.acceptFormRef.$("ico").set("value", userData.company.identifier)
				}
			}
		}

		this.isOpenAcceptOfTheTerm = true
	}

	@action closeAcceptOfTheTerm() {
		this.isOpenAcceptOfTheTerm = false
		this.confirmTerm = false
		this.acceptOfTheTermData = null
		this.tempFormValues = null
	}

	@action filterDajZiadankySVLZPoistenca(form, patientId) {
		let formValues = form.values()

		UIStore.isFormSaving = true

		let request = {
			patient_id: patientId,
			provider_id: getSelectedOrgUnitID()
		}

		if (isNotEmpty(formValues.sms_code)) {
			request["sms_kod"] = formValues.sms_code
		}

		if (isNotEmpty(formValues.period_from)) {
			request["obdobie_od"] = formValues.period_from
		}

		if (isNotEmpty(formValues.period_to)) {
			request["obdobie_do"] = formValues.period_to
		}

		if (isNotEmpty(formValues.period_type)) {
			request["obdobie_typ"] = formValues.period_type
		}

		if (isNotEmpty(formValues.examination_type)) {
			request["typ_svlz_vysetrenia"] = formValues.examination_type
		}

		if (isNotEmpty(formValues.examination_tag) && formValues.examination_tag.length > 0) {
			request["kody_tagov_vysetrenia"] = formValues.examination_tag.map((x) => x.code)
			this.tagsCodeActual = formValues.examination_tag.map((x) => x.code)
		} else {
			this.tagsCodeActual = []
		}

		if (isNotEmpty(formValues.diagnosis)) {
			request["kod_diagnozy"] = formValues.diagnosis
		}

		if (isNotEmpty(formValues.search)) {
			request["vyhladavaci_retazec"] = formValues.search
		}

		api
			.dajZiadankySVLZPoistenca(request)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res.ziadanky_svlz)) {
					this.requestsSVLZ = res.ziadanky_svlz
					this.requestsSVLZAll = res.ziadanky_svlz

					let reqTempIdx = {}

					for (let i = 0; i < res.ziadanky_svlz.length; i++) {
						reqTempIdx[i] = false
					}

					this.isOpenRequests = reqTempIdx

					this.openViewer()
					this.close()
				} else {
					if (isNotEmpty(res.chyba_spracovania)) {
						GlobalStore.setNotificationMessage(res.chyba_spracovania)
					} else {
						GlobalStore.setNotificationMessage("Nepodarilo sa načítať SVLZ žiadanky")
					}

					this.requestsSVLZ = []
					this.requestsSVLZAll = []
					this.isOpenRequests = {}
				}

				UIStore.isFormSaving = false
			})
	}

	@action getExamTagCodes() {
		const selectedOrgUnit = getSelectedOrgUnit()
		const personnelExpertiseCode = isNotEmpty(selectedOrgUnit.personnel_expertise_cl_code_ext)
			? selectedOrgUnit.personnel_expertise_cl_code_ext
			: null

		api
			.getExpertiseExamTag(personnelExpertiseCode)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res.exam_tag_codes)) {
					const expTagsCode = res.exam_tag_codes.includes(";")
						? res.exam_tag_codes.split(";")
						: [`${res.exam_tag_codes}`]

					this.tagsCode = expTagsCode
					this.tagsCodeActual = expTagsCode
				}
			})
	}

	@action dajZiadankySVLZPoistenca(patientId, openFromTemplateId = "") {
		// let formValues = form.values()
		this.openFromTemplateId = openFromTemplateId
		UIStore.isFormSaving = true

		let request = {
			patient_id: patientId,
			provider_id: getSelectedOrgUnitID(),
			obdobie_typ: "P",
			typ_svlz_vysetrenia: "LAB",
			kody_tagov_vysetrenia: this.tagsCode
		}

		this.tagsCodeActual = this.tagsCode

		api
			.dajZiadankySVLZPoistenca(request)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res.ziadanky_svlz)) {
					this.requestsSVLZ = res.ziadanky_svlz
					this.requestsSVLZAll = res.ziadanky_svlz

					let reqTempIdx = {}

					for (let i = 0; i < res.ziadanky_svlz.length; i++) {
						reqTempIdx[i] = false
					}

					this.isOpenRequests = reqTempIdx

					this.openViewer()
					// this.close()
				} else {
					if (isNotEmpty(res.chyba_spracovania)) {
						GlobalStore.setNotificationMessage(res.chyba_spracovania)
					} else {
						GlobalStore.setNotificationMessage("Nepodarilo sa načítať SVLZ žiadanky")
					}

					if (isNotEmpty(openFromTemplateId)) {
						let printValues = PrintTemplatesStore.getCommonValues()

						PrintTemplatesStore.open(this.openFromTemplateId, printValues)
					}

					this.requestsSVLZ = []
					this.requestsSVLZAll = []
					this.isOpenRequests = {}
				}

				UIStore.isFormSaving = false
			})
			.catch(() => {
				if (isNotEmpty(openFromTemplateId)) {
					let printValues = PrintTemplatesStore.getCommonValues()

					PrintTemplatesStore.open(this.openFromTemplateId, printValues)
				}
			})
	}

	@action checkLabExternalCredentials(patientId, values) {
		UIStore.isFormSaving = true
		this.tempFormValues = values

		let request = {
			system: "DZPELAB",
			sub_identifier: `ICO${values.ico_vysetrujuci_pzs}`
		}

		api
			.checkLabExternalCredentials(getUser().user._id, request)
			.call()
			.then((res) => {
				if (isSafe(res) && res.length > 0 && isNotEmpty(res[0].user_id)) {
					this.dajVysledkySVLZPoistenca(patientId, values)
				} else {
					this.dajOverovaciTokenLab(values)
				}
			})
	}

	@action dajPrihlasovacieUdaje(patientId, form) {
		const formValues = form.values()

		let request = {
			provider_id: getSelectedOrgUnitID(),
			doctor_expertise_id: getSelectedOrgUnit().personnel_expertise_id,
			overovaci_token_dzp: this.overovaciTokenDZP,
			suhlas: this.confirmTerm,
			ico_lab: this.icoLab,
			endpoint_svlz: this.endpointSVLZ,
			mobil: formValues.mobile,
			email: formValues.email
		}

		api
			.dajPrihlasovacieUdaje(request)
			.call()
			.then((response) => {
				if (isSafe(response) && isNotEmpty(response.chyba_spracovania)) {
					GlobalStore.setNotificationMessage(response.chyba_spracovania)
				} else {
					this.dajVysledkySVLZPoistenca(patientId)
					this.closeAcceptOfTheTerm()
				}
			})
	}

	@action dajVysledkySVLZPoistenca(patientId) {
		UIStore.isFormSaving = true

		let request = {
			patient_id: patientId,
			provider_id: getSelectedOrgUnitID()
		}

		if (isNotEmpty(this.tempFormValues.ico_vysetrujuci_pzs)) {
			request["ico_lab"] = this.tempFormValues.ico_vysetrujuci_pzs
		}

		const selectedOrgUnit = getSelectedOrgUnit()
		const doctor_expertise_id = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_id : null

		if (isNotEmpty(doctor_expertise_id)) {
			request["doctor_expertise_id"] = doctor_expertise_id
		}

		if (isNotEmpty(this.tempFormValues.id_ziadanky_svlz)) {
			request["id_ziadanky_svlz"] = this.tempFormValues.id_ziadanky_svlz
		}

		api
			.dajVysledkySVLZPoistenca(request)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.vysledky_pdf) && res.vysledky_pdf.length > 0) {
					if (isEmpty(res.vysledky_pdf[0].obsah)) {
						GlobalStore.setNotificationMessage("Výsledky SVLZ sú prázdne")
					} else {
						this.fileName = isNotEmpty(res.vysledky_pdf[0].nazov_dokumentu)
							? res.vysledky_pdf[0].nazov_dokumentu
							: "neznamy"
						this.openShowPDF(res.vysledky_pdf[0].obsah, "Common.label.resultSVLZ") //base64EncodeUnicode(res.vysledky_pdf[0].obsah)
					}

					UIStore.isFormSaving = false
				} else {
					GlobalStore.setNotificationMessage("Nepodarilo sa ziskat vysledky SVLZ")
				}
			})
	}

	@action dajOverovaciTokenLab() {
		let request = {
			provider_id: getSelectedOrgUnitID()
		}

		const selectedOrgUnit = getSelectedOrgUnit()
		const doctor_expertise_id = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_id : null

		if (isNotEmpty(doctor_expertise_id)) {
			request["doctor_expertise_id"] = doctor_expertise_id
		}

		if (isNotEmpty(this.tempFormValues.ico_vysetrujuci_pzs)) {
			request["ico_lab"] = this.tempFormValues.ico_vysetrujuci_pzs
			this.icoLab = this.tempFormValues.ico_vysetrujuci_pzs
		} else {
			this.icoLab = ""
		}

		api
			.dajOverovaciTokenLab(request)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res.podmienky)) {
					this.openAcceptOfTheTerm(res)
					UIStore.isFormSaving = false
					this.endpointSVLZ = res.endpoint_svlz
					this.overovaciTokenDZP = res.overovaci_token_dzp
				} else {
					GlobalStore.setNotificationMessage("Nepodarilo sa ziskat podmienky pre overenie")
					this.endpointSVLZ = ""
					this.overovaciTokenDZP = ""
				}
			})
	}

	@action close(closeFromModal = false) {
		if (isNotEmpty(this.openFromTemplateId) && closeFromModal) {
			let printValues = PrintTemplatesStore.getCommonValues()

			PrintTemplatesStore.open(this.openFromTemplateId, printValues)
		}

		this.isOpen = false
	}

	@action openViewer() {
		this.isOpenViewer = true
	}

	@action closeViewer() {
		if (isNotEmpty(this.openFromTemplateId)) {
			let printValues = PrintTemplatesStore.getCommonValues()

			PrintTemplatesStore.open(this.openFromTemplateId, printValues)
		}

		this.isOpenViewer = false
	}

	@action openSVLZRequest(svlzRequest) {
		this.svlzRequest = svlzRequest
		this.isOpenSVLZRequest = true
	}

	@action closeSVLZRequest() {
		this.svlzRequest = {}
		this.isOpenSVLZRequest = false
	}
}

var singleton = new RequestsSVLZInsurerStore()
export default singleton
