import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {withStyles} from "@material-ui/core/styles"

// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import VaccinationCountByDeliveryListStore from "../../../stores/VaccinationCountByDeliveryListStore"
import TableStore from "../../../stores/TableStore"
import moment from "moment"
import {getUserPersonnelOrgUnits} from "../../../../../global/helpers/actions"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import {XsDateTimePicker} from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import api from "../../../actions/api"
import XsNumberInput from "../../../../../global/ui/xsInput/xsNumberInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"

const styles = () => ({
	searchIcon: {
		marginTop: "14px"
	}
})

@withStyles(styles)
@injectIntl
@observer
export default class VaccinationCountByDeliveryList extends React.Component {
	constructor(props) {
		super(props)
		VaccinationCountByDeliveryListStore.isFetching = false

		const fields = {
			fields: {
				orgunit: {
					label: <FormattedMessage id="Patient.form.patientrequest.orgunit" />
					// rules: "required",
					// value: getSelectedOrgUnit()._id
				},
				date_from: {
					label: <FormattedMessage id="Capitation.List.From" />,
					type: "date",
					rules: "required",
					value: moment().startOf("day")
				},
				date_to: {
					label: <FormattedMessage id="Capitation.List.To" />,
					type: "date",
					rules: "required",
					value: moment().endOf("day")
				},
				delivery: {
					label: <FormattedMessage id="Common.label.delivery" />
				},
				productFilter: {
					label: <FormattedMessage id="Common.label.drugs" />
				},
				doseNumber: {
					label: <FormattedMessage id="Common.label.ordinanceOrder" />
				}
			}
		}

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				if (
					TableStore.tables.hasOwnProperty("vaccinationCountByDeliveryList") &&
					TableStore.tables["vaccinationCountByDeliveryList"].hasOwnProperty("index")
				) {
					delete TableStore.tables["vaccinationCountByDeliveryList"]["index"]
				}

				VaccinationCountByDeliveryListStore.loadPatients(form)
			},
			onError() {}
		}

		if (isEmpty(VaccinationCountByDeliveryListStore.formRef)) {
			VaccinationCountByDeliveryListStore.formRef = new MobxReactForm(fields, {
				plugins: {dvr: validatorjs},
				hooks,
				bindings
			})
		}

		this.form = VaccinationCountByDeliveryListStore.formRef
		VaccinationCountByDeliveryListStore.loadPatients(this.form)
	}

	componentWillUnmount() {
		VaccinationCountByDeliveryListStore.patientsOverview = []
	}

	onHandleRowClick = () => {}

	render() {
		VaccinationCountByDeliveryListStore.isChangedFilter(this.form.values())
		const dateTimeFormat = this.props.intl.formatMessage({id: "Application.moment.dateformat"})

		return (
			<Grid container className="xs-dailyReport-list">
				<Grid item xs={12}>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={1}>
							<XsDateTimePicker white field={this.form.$("date_from")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={1}>
							<XsDateTimePicker white field={this.form.$("date_to")} showTimeSelect={false} />
						</Grid>
						<Grid item xs={1}>
							<SearchSelect white field={this.form.$("orgunit")} items={getUserPersonnelOrgUnits()} />
						</Grid>
						<Grid item xs={2}>
							<XsAutocomplete
								field={this.form.$("productFilter")}
								white
								minLengthForSearch="3"
								api={api.loadDrugs}
								removePunctuation={true}
								clearText
								inputRenderer={(obj, menuItem) =>
									(menuItem && obj.has_limitation
										? "<span style=color:#FFB900>" + obj.code_ext + "</span>"
										: obj.code_ext) +
									" | " +
									(menuItem && obj.has_limitation
										? "<span style=color:#FFB900>" + obj.name_ext + "</span>"
										: obj.name_ext) +
									" | " +
									obj.supplement +
									" | " +
									(obj.patient_payment
										? (menuItem ? "<span style=color:#01579b>" : "") +
										  obj.patient_payment +
										  (menuItem ? "</span>" : "") +
										  " | "
										: "") +
									(obj.insurer_payment
										? (menuItem ? "<span style=color:#00A787>" : "") +
										  obj.insurer_payment +
										  (menuItem ? "</span>" : "") +
										  " | "
										: "") +
									(obj.price ? (menuItem ? "<b>" : "") + obj.price + (menuItem ? "</b>" : "") + " | " : "") +
									obj.atc +
									" | " +
									obj.active_ingredient +
									" | " +
									obj.registrator_name
								}
								saveValue={(obj) => obj.code}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.code_ext" />,
												type: "string",
												dbName: "code_ext"
											},
											name_ext: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.name_ext" />,
												type: "string",
												dbName: "search_column",
												filterLabel: <FormattedMessage id="Common.label.search" />,
												filter: {
													gridWidth: 3,
													defaultValue: textValue,
													renderElement: "input"
												}
												// sortable: true
											},
											//Virtulany stlpec ktory sa nezobrazuje sluzi len na naviazanie filtra
											name_extFilter: {
												title: <FormattedMessage id="Delivery.list.modal.drugName" />,
												type: "string",
												dbName: "name_ext",
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												},
												design: {
													width: "0px",
													body: {
														className: "hide"
													},
													header: {
														className: "hide"
													}
												}
											},
											supplement: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.supplement" />,
												type: "string",
												dbName: "supplement"
											},
											patient_payment: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.patient_payment" />,
												type: "string",
												dbName: "patient_payment"
											},
											insurer_payment: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.insurer_payment" />,
												type: "string",
												dbName: "insurer_payment"
											},
											atc: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.ATC" />,
												type: "string",
												dbName: "atc"
											},
											active_ingredient: {
												title: <FormattedMessage id="Patient.form.patientPrescription.drugsTable.active_ingredient" />,
												type: "string",
												dbName: "active_ingredient"
											},
											registrator_name: {
												title: <FormattedMessage id="Common.label.registratorName" />,
												type: "string",
												dbName: "registrator_name"
											}
										},
										options: {
											// selectRow: true,
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											},
											renderHeaderAsFirstRow: true,
											row_count_full: 100
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsAutocomplete
								white
								field={this.form.$("delivery")}
								minLengthForSearch="1"
								clearText
								searchFilter={"code_ext"}
								exactMatch={true}
								api={api.loadSpecificDelivery}
								inputRenderer={(obj) => obj.code_ext + "-" + obj.name_ext}
								saveValue={(obj) => obj.code}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Patient.form.patientrecord.deliveryTable.code_ext" />,
												design: {
													width: "10%"
												},
												type: "string",
												dbName: "code_ext",
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Patient.form.patientrecord.deliveryTable.name_ext" />,
												type: "string",
												dbName: "search_column",
												design: {
													width: "90%"
												},
												filter: {
													gridWidth: 3,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsNumberInput white field={this.form.$("doseNumber")} min="1" step="1" />
						</Grid>
						<Grid item xs={1} className="pb-0">
							<XsIconButton
								rect
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								className="xs-default xs-outline"
								onClick={this.form.onSubmit}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
						<Grid item xs={3} container justify="flex-end">
							<Grid item>
								<XsButton
									disabled={VaccinationCountByDeliveryListStore.changedFilters}
									className={
										VaccinationCountByDeliveryListStore.changedFilters ? "xs-default xs-outline" : "xs-primary"
									}
									icon={<i className="fal fa-file-excel fa-lg" />}
									text={<FormattedMessage id="Common.label.export" />}
									onClick={() => VaccinationCountByDeliveryListStore.exportToExcel(this.form)}
								/>
							</Grid>
						</Grid>
					</Grid>
					{VaccinationCountByDeliveryListStore.isFetching ? (
						<XsLoading />
					) : VaccinationCountByDeliveryListStore.changedFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i className="far fa-search fa-lg mr-3 pointer" onClick={this.form.onSubmit}></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTable
							setRef={(table) => (VaccinationCountByDeliveryListStore.vaccinationCountByDeliveryRef = table)}
							config={{
								columnDefs: {
									vacc_date: {
										title: <FormattedMessage id="Common.label.vaccDate" />,
										type: "datetime",
										design: {
											width: "100px",
											body: {
												formatter: (props) => {
													return isSafe(props) && isNotEmpty(props) ? moment(props).format(dateTimeFormat) : ""
												}
											}
										},
										sortable: true
									},
									vacc_order: {
										title: <FormattedMessage id="Common.label.ordinanceOrder" />,
										type: "number",
										design: {
											width: "100px"
										},
										sortable: true
									},
									delivery_code_ext: {
										title: <FormattedMessage id="Common.label.delivery" />,
										type: "string",
										design: {
											width: "100px"
										},
										sortable: true
									},
									patient_count: {
										title: <FormattedMessage id="Common.label.numberOfPatients" />,
										type: "number",
										design: {
											width: "100px"
										},
										sortable: true
									}
								},
								options: {
									// showCursor: true,
									name: "vaccinationCountByDeliveryList",
									// selectRow: true,
									// defaultSort: {columnName: "stamp", sortDirection: "asc"},
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									}
									// mapper: (dataRow) => {
									// 	return {
									// 		dataRow
									// 	}
									// }
								},
								dataSource: VaccinationCountByDeliveryListStore.patients
							}}
						/>
					)}
				</Grid>
			</Grid>
		)
	}
}
