"use strict"

import {observable} from "mobx"

class OrgunitDetailContactsStore {
	@observable editable = false
	@observable isAdd = false
}

var singleton = new OrgunitDetailContactsStore()
export default singleton
