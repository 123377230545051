import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import bindings from "../../../../../global/ui/globalUISchemeBindings"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions, InputAdornment} from "@material-ui/core"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsInput from "../../../../../global/ui/xsInput/xsInput"

import AgreementsStore from "../../../stores/AgreementsStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

@injectIntl
@observer
export default class CopyAgreementDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				AgreementsStore.copyAgreement(form)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					endDate: {
						label: <FormattedMessage id="Agreement.CopyPart.dialog.dateFrom" />,
						rules: "required",
						value: isSafe(AgreementsStore.currAgreement) ? AgreementsStore.currAgreement.validity.from : null
					},
					search_column: {label: <FormattedMessage id="Common.label.orgunit" />},
					close_contracts: {
						label: <FormattedMessage id="Common.label.endCurrAgreement" />,
						type: "checkbox",
						value: true
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)

		AgreementsStore.loadOrgunits()
	}

	render() {
		return (
			<Dialog
				id="xsCopyAgreementDialog"
				open={AgreementsStore.isOpenCopyAgreement}
				onClose={() => {
					this.form.reset()
					AgreementsStore.closeCopyAgreement()
				}}
				className="xs-base-dialog dialog-newpatient"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-1050"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								this.form.reset()
								AgreementsStore.closeCopyAgreement()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-copy fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.copyAgreement" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent>
					<Grid container>
						<Grid item container xs={12} className="pa-4" justify="space-between">
							<Grid item xs={8} container alignItems="center">
								<Grid item xs={3}>
									<XsDateTimePicker field={this.form.$("endDate")} />
								</Grid>
								<Grid item xs={6} className="pl-4 pt-1">
									<XsCheckbox field={this.form.$("close_contracts")} />
								</Grid>
							</Grid>
							<Grid item xs={4}>
								<XsInput
									white
									clearText={() => AgreementsStore.agreementFilter("")}
									field={this.form.$("search_column")}
									placeholder={"Vyhľadávanie..."}
									onChange={(val) => AgreementsStore.agreementFilter(val)}
									startAdornment={
										<InputAdornment position="start" className="xs-search-adornment">
											<i className="fal fa-search pt-1 pl-2" />
										</InputAdornment>
									}
								/>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<XsTable
								loading={AgreementsStore.loadingOrgunits}
								config={{
									columnDefs: {
										orgunit_code: {
											title: <FormattedMessage id="Common.label.orgUnitCode" />,
											type: "string",
											design: {
												width: "20%"
											}
										},
										orgunit_name: {
											title: <FormattedMessage id="Common.label.orgunit" />,
											type: "string",
											design: {
												width: "40%"
											}
										},
										company_name: {
											title: <FormattedMessage id="Common.label.company" />,
											type: "string",
											design: {
												width: "40%"
											}
										}
									},
									options: {
										showCursor: true,
										checkboxes: true,
										checkboxColumn: "orgunit_id",
										checkAllDataColumn: "orgunit_id",
										onClickCheckbox: (data) => {
											AgreementsStore.checkedOrgunitIds = data
										}
									},
									dataSource: AgreementsStore.orgunits
								}}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer">
					<Grid container justify="space-between">
						<Grid item>
							<XsButton
								className="xs-default xs-outline"
								icon={<i className="fal fa-times" />}
								text={<FormattedMessage id="Common.form.close" />}
								onClick={() => {
									this.form.reset()
									AgreementsStore.closeCopyAgreement()
								}}
							/>
						</Grid>
						<Grid>
							<XsButton
								className={"xs-success ml-3"}
								// disabled={AgreementsStore.checkedOrgunitIds.length == 0}
								icon={<i className="fal fa-copy fa-lg" />}
								text={<FormattedMessage id="Common.label.copy" />}
								onClick={this.form.onSubmit}
							/>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}
