"use strict"

import {observable, action} from "mobx"
import {getSelectedOrgUnitID} from "../../../global/helpers/actions"
import DDaction from "../actions/patientCard"
import DataStore from "./DataStore"
import api from "../actions/api"

class StornoOpProtocolDialogStore {
	@observable isOpen = false
	@observable recordId = null
	@observable reason = ""

	@action open(id) {
		this.isOpen = true
		this.recordId = id
	}

	@action onHandleChange(value) {
		this.reason = value
	}

	@action storno() {
		let request = {
			_id: this.recordId,
			active: false,
			reason: this.reason,
			_type: "EHR.Data.OperatingProtocol"
		}

		api
			.stornoRecords(request)
			.call()
			.then(() => {
				this.close()
				const providerID = getSelectedOrgUnitID()
				DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
					DataStore.setPatientEHR(overview)

					let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
					if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
						DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
					}
				})
			})
	}

	@action close() {
		this.isOpen = false
		this.recordId = null
		this.reason = ""
	}
}

var singleton = new StornoOpProtocolDialogStore()
export default singleton
