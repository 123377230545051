import {observable, action} from "mobx"
import api from "../actions/api"

class MoveRecordDialogStore {
	@observable isOpen = false
	@observable selectedOrgUnit = null
	recordIds = []

	@action open(recordId, checkedEHR) {
		this.isOpen = true
		this.recordIds = [...new Set(checkedEHR).add(`${recordId}`)]
	}

	@action onHandleChange(orgUnit) {
		this.selectedOrgUnit = orgUnit.value
	}

	@action onConfirm(
		sourceOrgUnitId,
		patientId,
		reloadPatientOverview,
		clearCheckedEHR,
		infoStoreOpen,
		numberTransferedString
	) {
		const request = {
			patient: {
				_id: +patientId,
				_type: "Entity.Data.Client",
				_ref: true
			},
			target_unit: {
				_id: +this.selectedOrgUnit,
				_type: "Entity.Data.OrgUnit",
				_ref: true
			},
			record_ids: this.recordIds.map((x) => +x)
		}

		api
			.moveRecords(request, +sourceOrgUnitId)
			.call()
			.then((data) => {
				infoStoreOpen(`${numberTransferedString}: ${data.affected_rows}`)

				reloadPatientOverview()
				clearCheckedEHR()
			})

		this.close()
	}

	@action close() {
		this.isOpen = false
		this.selectedOrgUnit = null
		this.recordIds = []
	}
}

var singleton = new MoveRecordDialogStore()
export default singleton
