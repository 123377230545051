"use strict"

import {observable, action} from "mobx"
import DDaction from "../actions/patientCard"
import {getSelectedOrgUnitID} from "../../../global/helpers/actions"
import DataStore from "../stores/DataStore"
import api from "../../../modules/ambulance/actions/api"
import UIStore from "./UIStore"
import StornoPrescriptionDialogStore from "./StornoPrescriptionDialogStore"

class ConfirmStornoPrescriptionDialogStore {
	@observable isOpen = false
	@observable warning = ""
	@observable isWarning = false
	prescriptionIds = null
	signatureData = null
	@observable isStornoError = false

	@action open(warning, isWarning, ids, signatureData, isStornoError) {
		this.isOpen = true
		this.warning = warning
		this.isWarning = isWarning
		this.prescriptionIds = ids
		this.signatureData = signatureData
		this.isStornoError = isStornoError
	}

	@action close() {
		UIStore.isFormSaving = false
		this.isOpen = false
		this.prescriptionIds = null
		this.signatureData = null
		const providerID = getSelectedOrgUnitID()
		DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
			DataStore.setPatientEHR(overview)

			let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
			if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
				DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
			}
		})
		// DrugInteractionDialogStore.cancel()
	}

	@action async revertDelete() {
		UIStore.isFormSaving = true
		// for (let i = 0; i < this.prescriptionIds.length; i++) {
		// 	const id = this.prescriptionIds[i]
		// 	await api.resetRecordAfterDelete({_id: id, _type: "EHR.Data.Medication", active: true}).call()
		// }
		this.close()
	}

	@action continue() {
		UIStore.isFormSaving = true

		api
			.deleteSignatureMultiplePrescriptions(this.signatureData)
			.call()
			.then(async (response) => {
				UIStore.isFormSavingMessage = ""
				let text = ""
				let confirm = false
				let ids = []
				Object.keys(response).forEach((obj, index) => {
					if (isSafe(response[obj])) {
						if (isSafe(response[obj].warning)) {
							text +=
								response[obj].warning.text +
								(Object.keys(response).length != index || response[obj].warning.text != "" ? "" : "<br/>")
							if (!confirm) confirm = response[obj].warning.confirm
						}
						if (isSafe(response[obj].warning) && response[obj].warning.confirm == false) {
							ids.push(response[obj].record_id)
						}
					}
				})

				let request = []
				ids.forEach((rec) => {
					request.push({
						_id: rec,
						active: false,
						reason: this.reason,
						_type: "EHR.Data.Medication"
					})
				})

				api
					.stornoRecords(request)
					.call()
					.then(() => {
						if (!confirm) {
							if (isNotEmpty(text)) {
								// warning storna
								ConfirmStornoPrescriptionDialogStore.open(text, false, null, null, true)
							} else {
								StornoPrescriptionDialogStore.open("Operácia prebehla úspešne", false)
							}
						} else {
							ConfirmStornoPrescriptionDialogStore.open(text, false, null, null, true)
						}

						const providerID = getSelectedOrgUnitID()
						DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
							DataStore.setPatientEHR(overview)
							UIStore.isFormSaving = false
							UIStore.isFormSavingMessage = ""

							let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
							if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
								DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
							}
						})
					})
					.catch(() => {
						const providerID = getSelectedOrgUnitID()
						DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
							DataStore.setPatientEHR(overview)
							UIStore.isFormSaving = false
							UIStore.isFormSavingMessage = ""

							let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
							if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
								DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
							}
						})
					})
			})
			.catch(async () => {
				const providerID = getSelectedOrgUnitID()
				DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) =>
					DataStore.setPatientEHR(overview)
				)
			})

		this.close()
	}
}

var singleton = new ConfirmStornoPrescriptionDialogStore()
export default singleton
