import React from "react"
import {observer} from "mobx-react"

import renderHTML from "react-render-html"

import UIStore from "../../../../stores/UIStore"

import "./anamnesisRow.less"
import XsExpansionPanel from "../../../../../../global/ui/xsExpansionPanel/xsExpansionPanel"

const AnamnesisRow = observer(({item}) => {
	return (
		<XsExpansionPanel glOpen={UIStore.isOpenAllRecordsInEHROverview}>
			{isSafe(item) &&
				isSafe(item.data) &&
				item.data.length > 0 &&
				item.data.map((data, idx) => {
					if (isEmpty(data.content)) {
						return (
							<div className="anamnesisRow" key={idx}>
								<span></span>
							</div>
						)
					} else {
						return (
							<div className="anamnesisRow" key={idx}>
								{renderHTML(data.content)}
							</div>
						)
					}
				})}
		</XsExpansionPanel>
	)
})

export default AnamnesisRow
