// @flow
import React from "react"
import {withStyles} from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"
import Modal from "@material-ui/core/Modal"

import "./loading.less"

const styles = (theme) => ({
	progress: {
		margin: theme.spacing.unit * 2
	}
})

const renderLoading = (size, message, classes, darkText) => {
	return (
		<div className="loading">
			<div className="progressMessage">
				<CircularProgress className={classes.progress} size={size || 50} />
				{message && <div className={"message" + darkText ? " dark" : ""}>{message}</div>}
			</div>
		</div>
	)
}

const xsLoading = ({size, message, classes, overlay}) => {
	if (overlay) {
		return <Modal open={true}>{renderLoading(size, message, classes)}</Modal>
	} else {
		return renderLoading(size, message, classes)
	}
}

export default withStyles(styles)(xsLoading)
