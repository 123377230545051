//@flow
import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"

import {Dialog, DialogContent} from "@material-ui/core"
import AddEventDialogStore from "../../stores/AddEventDialogStore"

import AddSearchPatient from "./addSearchPatient"
import AddEvent from "./addEvent"
import AddEventSelectOrgunitDialog from "./addEventSelectOrgunitDialog"

@injectIntl
@observer
export default class AddEventDialogContainer extends React.Component {
	constructor(props) {
		super(props)

		AddEventDialogStore.notServiceTxt = props.intl.formatMessage({id: "Common.label.setOfficeHour"})
	}

	render() {
		return (
			<Dialog
				id="xsAddEventDialog"
				open={AddEventDialogStore.isOpen}
				onClose={() => AddEventDialogStore.close()}
				onEscapeKeyDown={() => AddEventDialogStore.close()}
				disableBackdropClick={true}
				scroll="body"
				PaperProps={{
					className: "xs-dialog-container"
				}}
				aria-labelledby="scroll-dialog-title"
			>
				<DialogContent className="xs-dialog-body">
					{AddEventDialogStore.step === 1 && <AddSearchPatient />}
					{AddEventDialogStore.step === 2 && <AddEvent />}
				</DialogContent>
				{AddEventDialogStore.isOpenSelectOrgunit && <AddEventSelectOrgunitDialog />}
			</Dialog>
		)
	}
}
