import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import moment from "moment"
import ReactTooltip from "react-tooltip"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {toLatin} from "../../../../../global/helpers/functions.js"

import fields from "./requestsSVLZInsurerViewerFilterFields"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
// import XsInput from "../../../../../global/ui/xsInput/xsInput"

import RequestsSVLZInsurerStore from "../../../stores/RequestsSVLZInsurerStore"
import DataStore from "../../../stores/DataStore"

@injectIntl
@observer
export default class RequestsSVLZInsurerViewerDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		RequestsSVLZInsurerStore.getExamTagCodes()

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	handleFilter = () => {
		let filteredData = RequestsSVLZInsurerStore.requestsSVLZAll

		if (
			isSafe(this.form.$("vzorka")) &&
			isSafe(this.form.$("vzorka").value) &&
			this.form.$("vzorka").value.length > 2
		) {
			filteredData = RequestsSVLZInsurerStore.requestsSVLZAll.filter(
				(x) =>
					isSafe(x.vzorky) &&
					x.vzorky.length > 0 &&
					x.vzorky.some((y) =>
						toLatin(y.nazov_typu_vzorky.toLowerCase()).includes(toLatin(this.form.$("vzorka").value))
					)
			)
		}

		RequestsSVLZInsurerStore.requestsSVLZ = filteredData
	}

	requisitionStates = {
		O: "Objednaná",
		E: "Evidovaná u SVLZ",
		N: "Neriešená",
		C: "Čiastočne spracovaná",
		S: "Spracovaná"
	}

	render() {
		return (
			<Dialog
				id="xsRequestsSVLZInsurerViewerDialog"
				open={RequestsSVLZInsurerStore.isOpenViewer}
				onClose={() => {
					RequestsSVLZInsurerStore.closeViewer()
					this.form.reset()
				}}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog"
				}}
				disableBackdropClick={true}
				fullScreen
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								RequestsSVLZInsurerStore.closeViewer()
								this.form.reset()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-book-alt fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.requestsSVLZInsurer" />
						</div>
						<div className="xs-header-title">
							{`${DataStore.patientDTO.get("patient").full_name} (${
								DataStore.patientDTO.get("patient").identifier
							}), ${DataStore.patientDTO.get("insurer")}`}
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="pt-3 pb-2 pl-8 pr-8 hidden bgWhite">
					<div className="xs-column maxHeight">
						<Grid container spacing={8}>
							{/* <Grid item xs={2}>
								<XsInput field={this.form.$("examination")} onChange={() => this.handleFilter()} />
							</Grid> */}
							{/* <Grid item xs={2}>
								<XsInput
									white
									field={this.form.$("vzorka")}
									onChange={() => delayedCallback(500, () => this.handleFilter())}
								/>
							</Grid> */}
							<Grid item>
								<XsButton
									className="xs-primary xs-outline"
									onClick={() => {
										RequestsSVLZInsurerStore.open()
									}}
									text="Zadať / zmeniť filtre pre výber žiadaniek"
									icon={<i className="fal fa-filter fa-lg" />}
								/>
							</Grid>
						</Grid>
						<div className="overflowY maxHeight">
							<XsTable
								config={{
									columnDefs: {
										datum_vystavenia_ziadanky: {
											title: <FormattedMessage id="Common.label.dateOfExposed" />,
											type: "datetime",
											design: {
												width: "10%",
												body: {
													formatter: (props) => {
														return isNotEmpty(props) ? moment(props).format("DD.MM.YYYY") : "-"
													}
												}
											},
											sortable: true
										},
										typ_svlz_vysetrenia: {
											title: <FormattedMessage id="Common.label.type" />,
											type: "string",
											design: {
												width: "10%"
											}
										},
										stav_ziadanky: {
											title: <FormattedMessage id="Common.label.state" />,
											type: "string",
											design: {
												width: "10%",
												body: {
													renderer: (props) => {
														if (isSafe(this.requisitionStates[props.value])) {
															return (
																<Tooltip title={this.requisitionStates[props.value]}>
																	<span>{props.value}</span>
																</Tooltip>
															)
														} else {
															return <span>{props.value}</span>
														}
													}
												}
											}
										},
										dostupne_vysledky: {
											title: <FormattedMessage id="Common.label.resultAvailable" />,
											type: "string",
											design: {
												width: "10%"
											}
										},
										kody_diagnoz: {
											title: "DGN",
											type: "string",
											design: {
												width: "25%"
											}
										},
										ico_odosielajuci_pzs: {
											title: <FormattedMessage id="Common.label.icoOdos" />,
											type: "string",
											design: {
												width: "15%"
											}
										},
										nazov_vysetrujuci_pzs: {
											title: <FormattedMessage id="Common.label.nameExamPZS" />,
											type: "string",
											design: {
												width: "15%"
											}
										},
										daj_vysledok: {
											title: "",
											type: "action",
											design: {
												width: "5%",
												body: {
													renderer: (props) => {
														return (
															<XsIconButton
																icon={
																	<React.Fragment>
																		<i className="fal fa-download fa-lg xs-greyDefault" data-tip data-for="getResult" />
																		<ReactTooltip id="getResult" effect="solid">
																			<FormattedMessage id="Common.label.getResult" />
																		</ReactTooltip>
																	</React.Fragment>
																}
																onClick={() =>
																	// RequestsSVLZInsurerStore.dajVysledkySVLZPoistenca(this.props.patientId, props.value)
																	RequestsSVLZInsurerStore.checkLabExternalCredentials(
																		this.props.patientId,
																		props.value
																	)
																}
															/>
														)
													}
												}
											}
										}
									},
									options: {
										// hidePager: true,
										onRowClick: (dataRow) => {
											RequestsSVLZInsurerStore.openSVLZRequest(dataRow.daj_vysledok)
										},
										mapper: (dataRow) => {
											return {
												datum_vystavenia_ziadanky: dataRow.datum_vystavenia_ziadanky,
												typ_svlz_vysetrenia: isNotEmpty(dataRow.typ_svlz_vysetrenia)
													? dataRow.typ_svlz_vysetrenia
													: "-",
												stav_ziadanky: isNotEmpty(dataRow.stav_ziadanky) ? dataRow.stav_ziadanky : "-",
												dostupne_vysledky:
													isSafe(dataRow.dostupne_vysledky) && dataRow.dostupne_vysledky ? "Áno" : "Nie",
												kody_diagnoz:
													isSafe(dataRow.kody_diagnoz) && dataRow.kody_diagnoz.length > 0
														? dataRow.kody_diagnoz.join(", ")
														: "-",
												ico_odosielajuci_pzs: isNotEmpty(dataRow.ico_odosielajuci_pzs)
													? dataRow.ico_odosielajuci_pzs
													: "-",
												nazov_vysetrujuci_pzs: isNotEmpty(dataRow.nazov_vysetrujuci_pzs)
													? dataRow.nazov_vysetrujuci_pzs
													: "-",
												daj_vysledok: dataRow
											}
										}
									},
									dataSource: RequestsSVLZInsurerStore.requestsSVLZ
								}}
							/>
						</div>
					</div>
				</DialogContent>
				<DialogActions className="xs-footer xs-flex-end bgSnowWhite">
					<XsButton
						className="xs-danger xs-outline"
						onClick={() => {
							RequestsSVLZInsurerStore.closeViewer()
							this.form.reset()
						}}
						text={<FormattedMessage id="Common.label.cancel" />}
						icon={<i className="fal fa-times fa-lg" />}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
