import React from "react"
import {injectIntl} from "react-intl"
import {observer} from "mobx-react"
import moment from "moment"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../global/ui/globalUISchemeBindings"
import {RESOURCES} from "../../../../global/config/constants"
import classnames from "classnames"

import {getUserPersonnelOrgUnits, getUser} from "../../../../global/helpers/actions"
import XsSearchSelect from "../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsIconButton from "../../../../global/ui/xsButton/xsIconButton"
import XsDateTimePicker from "../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import CalendarStore from "../../stores/CalendarStore"
// import DataStore from "../../stores/DataStore"
import GlobalStore from "../../../../global/store/GlobalStore"
import {StorageBase} from "../../../../global/storage/storageEx"

import {Grid} from "@material-ui/core"

@injectIntl
@observer
export default class ResourcePlusViewHeader extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					providerId_0: {
						label: " "
					},
					providerId_1: {
						label: " "
					},
					providerId_2: {
						label: " "
					},
					providerId_3: {
						label: " "
					},
					providerId_4: {
						label: " "
					},
					datetime_0: {
						label: " ",
						type: "date"
					},
					datetime_1: {
						label: " ",
						type: "date"
					},
					datetime_2: {
						label: " ",
						type: "date"
					},
					datetime_3: {
						label: " ",
						type: "date"
					},
					datetime_4: {
						label: " ",
						type: "date"
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)

		const resourcesObj = StorageBase.getObjectByKey(RESOURCES)

		CalendarStore.storageResources =
			isSafe(resourcesObj) && isSafe(resourcesObj[getUser().user._id]) ? resourcesObj[getUser().user._id] : [{}]

		CalendarStore.storageResources.map((orgunit, key) => {
			if (isSafe(orgunit._id)) {
				this.form.$(`providerId_${key}`).value = orgunit._id
				this.form.$(`datetime_${key}`).value = isNotEmpty(orgunit.datetime) ? moment(orgunit.datetime) : ""
			} else {
				this.form.$(`providerId_${key}`).value = ""
				this.form.$(`datetime_${key}`).value = ""
			}
		})

		CalendarStore.disabledMultiBtn = isEmpty(CalendarStore.storageResources[0]._id) ? true : false
	}

	changeResources = () => {
		const formValues = this.form.values()
		let newResources = []

		for (let i = 0; i < 5; i++) {
			if (isNotEmpty(formValues[`providerId_${i}`])) {
				const orgunit = GlobalStore.orgunits.find((x) => x._id == formValues[`providerId_${i}`])
				const date = formValues[`datetime_${i}`]

				if (isSafe(orgunit)) {
					newResources.push({
						_id: orgunit._id,
						resource_id: orgunit.resource_id,
						datetime: date,
						code: orgunit.code,
						code_ext: orgunit.code_ext,
						name: orgunit.name,
						name_ext: orgunit.name_ext
					})
				}
			}
		}

		CalendarStore.disabledMultiBtn = false

		let resourcesObj = isSafe(StorageBase.getObjectByKey(RESOURCES)) ? StorageBase.getObjectByKey(RESOURCES) : {}
		resourcesObj[getUser().user._id] = newResources
		StorageBase.updateByKey(RESOURCES, resourcesObj)
		CalendarStore.storageResources = newResources
		CalendarStore.getEventsByDay()
	}

	changeDate = (key, val) => {
		this.form.$(`datetime_${key}`).value = val

		const formValues = this.form.values()

		let newResources = []

		for (let i = 0; i < 5; i++) {
			if (isNotEmpty(formValues[`providerId_${i}`])) {
				const orgunit = GlobalStore.orgunits.find((x) => x._id == formValues[`providerId_${i}`])
				const date = formValues[`datetime_${i}`]

				if (isSafe(orgunit)) {
					newResources.push({
						_id: orgunit._id,
						resource_id: orgunit.resource_id,
						datetime: date,
						code: orgunit.code,
						code_ext: orgunit.code_ext,
						name: orgunit.name,
						name_ext: orgunit.name_ext
					})
				}
			}
		}

		CalendarStore.disabledMultiBtn = false

		let resourcesObj = isSafe(StorageBase.getObjectByKey(RESOURCES)) ? StorageBase.getObjectByKey(RESOURCES) : {}
		resourcesObj[getUser().user._id] = newResources
		StorageBase.updateByKey(RESOURCES, resourcesObj)
		CalendarStore.storageResources = newResources
		CalendarStore.getEventsByDay()
	}

	removeResource = (orgunitId) => {
		let resourcesObj = isSafe(StorageBase.getObjectByKey(RESOURCES)) ? StorageBase.getObjectByKey(RESOURCES) : {}
		const resources =
			isSafe(resourcesObj) && isSafe(resourcesObj[getUser().user._id]) ? resourcesObj[getUser().user._id] : null

		let newResources = resources.filter((x) => x._id != orgunitId)
		//odstranenie posledneho fieldu
		this.form.$(`providerId_${newResources.length}`).value = ""

		if (isSafe(newResources) && newResources.length > 0) {
			resourcesObj[getUser().user._id] = newResources
			StorageBase.updateByKey(RESOURCES, resourcesObj)
			CalendarStore.storageResources = newResources
		} else {
			resourcesObj[getUser().user._id] = [{}]
			StorageBase.updateByKey(RESOURCES, resourcesObj)
			CalendarStore.storageResources = [{}]
			CalendarStore.disabledMultiBtn = true
		}

		CalendarStore.getEventsByDay()
	}

	addResource = () => {
		const resourcesObj = StorageBase.getObjectByKey(RESOURCES)
		const resources =
			isSafe(resourcesObj) && isSafe(resourcesObj[getUser().user._id]) ? resourcesObj[getUser().user._id] : null

		let newResources = isSafe(resources) && resources.length > 0 ? resources : []

		newResources.push({})

		resourcesObj[getUser().user._id] = newResources

		StorageBase.updateByKey(RESOURCES, resourcesObj)
		CalendarStore.storageResources = newResources
	}

	getOrgunit = (key) => {
		const formValues = this.form.values()

		let orgUnit = getUserPersonnelOrgUnits()

		switch (+key) {
			case 0:
				orgUnit = orgUnit.filter(
					(x) =>
						x._id != formValues.providerId_1 &&
						x._id != formValues.providerId_2 &&
						x._id != formValues.providerId_3 &&
						x._id != formValues.providerId_4
				)
				break
			case 1:
				orgUnit = orgUnit.filter(
					(x) =>
						x._id != formValues.providerId_0 &&
						x._id != formValues.providerId_2 &&
						x._id != formValues.providerId_3 &&
						x._id != formValues.providerId_4
				)
				break
			case 2:
				orgUnit = orgUnit.filter(
					(x) =>
						x._id != formValues.providerId_0 &&
						x._id != formValues.providerId_1 &&
						x._id != formValues.providerId_3 &&
						x._id != formValues.providerId_4
				)
				break
			case 3:
				orgUnit = orgUnit.filter(
					(x) =>
						x._id != formValues.providerId_0 &&
						x._id != formValues.providerId_1 &&
						x._id != formValues.providerId_2 &&
						x._id != formValues.providerId_4
				)
				break
			case 4:
				orgUnit = orgUnit.filter(
					(x) =>
						x._id != formValues.providerId_0 &&
						x._id != formValues.providerId_1 &&
						x._id != formValues.providerId_2 &&
						x._id != formValues.providerId_3
				)
				break
		}

		return orgUnit
	}

	render() {
		const {hideFullToggle} = this.props

		return (
			<div
				className={classnames("xs-week-header resource-plus mb-2", {
					"xs-fi": CalendarStore.calendarToggledFull || hideFullToggle
				})}
			>
				<div className="xs-arrows xs-justify">
					<XsIconButton
						rect
						className="xs-outline xs-primary"
						tooltip={this.props.intl.formatMessage({
							id: "Common.label.addAnotherOrgUnit"
						})}
						icon={<i className="fal fa-plus"></i>}
						onClick={() =>
							CalendarStore.storageResources.length < getUserPersonnelOrgUnits().length &&
							CalendarStore.storageResources.length < 5
								? this.addResource()
								: null
						}
					/>
				</div>
				{CalendarStore.storageResources.map((orgunit, key) => {
					return (
						<Grid container key={key} className="xs-flex pt-1 pb-1 pl-2 pr-2" spacing={8}>
							<Grid item xs>
								<XsSearchSelect
									white
									required
									hideLabel={true}
									field={this.form.$(`providerId_${key}`)}
									// items={getUserPersonnelOrgUnits()}
									items={this.getOrgunit(key)}
									onChange={() => this.changeResources()}
									// onClear={() => this.changeResources()}
								/>
							</Grid>
							<Grid item>
								<XsIconButton
									rect
									className="xs-outline xs-default"
									tooltip={this.props.intl.formatMessage({
										id: "Common.label.removeCalendarOrgUnit"
									})}
									icon={<i className="fal fa-times"></i>}
									onClick={() => this.removeResource(orgunit._id)}
								/>
							</Grid>
							<Grid item xs={12}>
								<XsDateTimePicker
									field={this.form.$(`datetime_${key}`)}
									className="xs-datetimepicker"
									white
									onSelect={(val) => {
										if (moment.isMoment(val)) {
											this.changeDate(key, val)
										} else {
											this.changeDate(key, null)
										}
									}}
									showTimeSelect={false}
								/>
							</Grid>
						</Grid>
					)
				})}
			</div>
		)
	}
}
