import Calendar from "./Calendar"
import React from "react"
import classnames from "classnames"
import {
	newDate,
	getYear,
	now,
	cloneDate,
	getDTPickerValue,
	isMoment,
	isDate,
	isBefore,
	isAfter,
	setTime,
	getSecond,
	formatDate,
	getMinute,
	getHour,
	getMonth,
	addDays,
	addMonths,
	addWeeks,
	addYears,
	subtractDays,
	subtractMonths,
	subtractWeeks,
	subtractYears,
	isSameDay,
	isDayDisabled,
	isDayInRange,
	getEffectiveMinDate,
	getEffectiveMaxDate,
	parseDate,
	safeDateFormat
} from "./helpers"
//import Popover from "@material-ui/core/Popover";
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Popper from "@material-ui/core/Popper"
import Input from "@material-ui/core/TextField"
import {injectIntl} from "react-intl"

import "./datetimepicker.less"
import {ixsIsNull, ixsIsEmpty} from "../../helpers/functions"
import settings from "../../config/settings"
import {getFieldIdentifier} from "../../helpers/actions"

const outsideClickIgnoreClass = "react-datepicker-ignore-onclickoutside"

@injectIntl // FIXME!!! treba to vyriesit, lebo to pada na pouziti this.props.intl.formatMessage
export class XsDateTimePickerDirect extends React.Component {
	anchorEl = null
	open = false
	preSelection = null
	//inputValue = null;
	preventFocus = false

	static get defaultProps() {
		return {
			allowSameDay: true,
			dateFormatCalendar: "MMMM YYYY",
			onChange() {},
			disabledKeyboardNavigation: true,
			dropdownMode: "select",
			freeText: false,
			onFocus() {},
			onBlur() {},
			onDayClick() {},
			onTimeClick() {},
			onKeyDown() {},
			onSelect() {},
			onClickOutside() {},
			onMonthChange() {},
			preventOpenOnFocus: false,
			onYearChange() {},
			monthsShown: 1,
			withPortal: false,
			popoverAnimated: true,
			shouldCloseOnSelect: true,
			showTimeSelect: false,
			showMonthDropdown: true,
			showYearDropdown: true,
			showSeconds: false,
			targetOrigin: {horizontal: "middle", vertical: "bottom"},
			timeIntervals: 5,
			timeCaption: "Time",
			timeFormat: "HH:mm"
		}
	}

	constructor(props) {
		super(props)
		this.selectedValue = this.props.field ? this.getFieldValue(this.props.field.get("value")) : this.props.selected

		const initState = this.calcInitialState()

		const isValid = this.checkIsValid()

		this.open = initState.open
		this.preSelection = initState.preSelection
		this.preventFocus = initState.preventFocus
		this.state = {
			isValid: isValid,
			inputValue: this.selectedValue
		}
	}

	shouldComponentUpdate(nextProps) {
		const currentMonth = this.selectedValue && getMonth(newDate(this.selectedValue))
		const nextMonth = nextProps.selected && getMonth(nextProps.selected)
		const currentYear = this.selectedValue && getYear(newDate(this.selectedValue))
		const nextYear = nextProps.selected && getYear(nextProps.selected)
		this.selectedValue = nextProps.field ? this.getFieldValue(nextProps.field.get("value")) : nextProps.selected
		const isNowValid = this.checkIsValid()
		if (this.state.isValid !== isNowValid) {
			this.setState({isValid: isNowValid})
		}
		//const selected = isMoment(this.selectedValue) ? this.selectedValue : newDate(this.selectedValue);
		/*if (this.state.inputValue != nextState.inputValue) {
	    this.setState({inputValue: this.selectedValue});
	  }*/

		if (this.props.inline && (currentMonth !== nextMonth || currentYear !== nextYear)) {
			this.setPreSelection(nextProps.selected)
		}

		return true
	}

	// componentDidUpdate(prevProps) {
	//   const currentMonth = this.props.selected && getMonth(newDate(this.props.selected));
	//   const nextMonth = prevProps.selected && getMonth(prevProps.selected);
	//   const currentYear = this.props.selected && getYear(newDate(this.props.selected));
	//   const nextYear = prevProps.selected && getYear(prevProps.selected);
	//   this.selectedValue = this.props.field ? this.getFieldValue(this.props.field.get("value")) : this.props.selected;

	//   // this.selectedValue = this.props.field ? this.getFieldValue(this.props.field.get("value")) : this.props.selected;
	//   //const selected = isMoment(this.selectedValue) ? this.selectedValue : newDate(this.selectedValue);

	//   if (this.props.inline && (currentMonth !== nextMonth || currentYear !== nextYear)) {
	//     this.setPreSelection(this.props.selected);
	//   }

	// }

	componentWillUnmount() {
		this.clearPreventFocusTimeout()
	}

	locale = ""
	dateFormat = ""
	selectedValue = ""

	checkIsValid = () => {
		const {field, required} = this.props
		return !this.selectedValue && (required || (field && field.get("rules") && field.get("rules").includes("required")))
			? false
			: true
	}

	getDateFormat = () => {
		const {showTimeSelect, showSeconds, dateFormat} = this.props
		let format = null
		if (dateFormat) {
			return dateFormat
		}
		if (showTimeSelect) {
			if (showSeconds) {
				format = this.props.intl.formatMessage({
					id: "Application.moment.datetimeformat.seconds",
					defaultMessage: "DD.MM.YYYY HH:mm:ss"
				})
			} else {
				format = this.props.intl.formatMessage({
					id: "Application.moment.datetimeformat",
					defaultMessage: "DD.MM.YYYY HH:mm"
				})
			}
		} else {
			format = this.props.intl.formatMessage({
				id: "Application.moment.dateformat",
				defaultMessage: "DD.MM.YYYY"
			})
		}

		let nextformat = format.replace("DD", "D")
		nextformat = nextformat.replace("MM", "M")
		// nextformat = nextformat.replace("HH", "h");

		return [format, nextformat]
	}

	getLocale = () => {
		const {locale} = this.props
		return locale
			? locale
			: this.props.intl.formatMessage({
					id: "Application.moment.locale",
					defaultMessage: "sk-SK"
			  })
	}

	getPreSelection = () => {
		const {openToDate, selectsEnd, startDate, endDate, selectsStart, utcOffset} = this.props
		const sd = isMoment(startDate) ? startDate : newDate(startDate)
		return openToDate
			? newDate(openToDate)
			: selectsEnd && sd
			? newDate(sd)
			: selectsStart && endDate
			? newDate(endDate)
			: now(utcOffset)
	}

	calcInitialState = () => {
		const defaultPreSelection = this.getPreSelection()
		const minDate = getEffectiveMinDate(this.props)
		const maxDate = getEffectiveMaxDate(this.props)
		const boundedPreSelection =
			minDate && isBefore(defaultPreSelection, minDate)
				? minDate
				: maxDate && isAfter(defaultPreSelection, maxDate)
				? maxDate
				: defaultPreSelection
		return {
			open: this.props.startOpen || false,
			preventFocus: false,
			preSelection: this.selectedValue ? newDate(this.selectedValue) : boundedPreSelection
		}
	}

	clearPreventFocusTimeout = () => {
		if (this.preventFocusTimeout) {
			clearTimeout(this.preventFocusTimeout)
		}
	}

	setFocus = () => {
		if (this.input && this.input.focus) {
			this.input.focus()
		}
	}

	setOpen = (open, event) => {
		const anchorEl = event ? event.currentTarget : null
		this.anchorEl = anchorEl
		this.open = open
		this.preSelection = open && this.open ? this.preSelection : this.calcInitialState().preSelection
		this.setState({open: this.open})
	}

	/*handleFocus = event => {
    if (!this.preventFocus) {
      this.props.onFocus(event);
      if (!this.props.preventOpenOnFocus) {
        this.setOpen(true, event);
      }
    }
  };*/

	cancelFocusInput = () => {
		clearTimeout(this.inputFocusTimeout)
		this.inputFocusTimeout = null
	}

	deferFocusInput = () => {
		this.cancelFocusInput()
		this.inputFocusTimeout = setTimeout(() => this.setFocus(), 1)
	}

	handleDropdownFocus = () => {
		this.cancelFocusInput()
	}

	handleBlur = (event) => {
		//Kontrola dátumu
		if (event && event.currentTarget && event.currentTarget.value && !this.props.freeText) {
			let value = event.currentTarget.value

			//Kontrola na + a -
			let setNewValue = false
			let isValidDateSelection = true
			if (value.length > 0) {
				const sign = value[0]
				const inputval = parseInt(value.substring(1, value.length))
				if (sign === "+") {
					value = addDays(newDate(), inputval)
					setNewValue = value ? true : false
				}
				if (sign === "-") {
					value = subtractDays(newDate(), inputval)
					setNewValue = value ? true : false
				}

				const checkedDate = parseDate(value, {dateFormat: this.dateFormat, locale: this.locale})

				//Kontrola či vôbec môže byť takýto dátum v rozmedzí minDate-maxDate
				if (checkedDate !== null && isDayDisabled(checkedDate, this.props)) {
					this.onClearClick(event)
					return
				}

				if (checkedDate) {
					const isDateRangePresent =
						typeof this.props.minDate !== "undefined" && typeof this.props.maxDate !== "undefined"
					isValidDateSelection =
						isDateRangePresent && checkedDate ? isDayInRange(checkedDate, this.props.minDate, this.props.maxDate) : true
					setNewValue = true
				}
				if (checkedDate === null || !isValidDateSelection) {
					this.setState({inputValue: null})
					this.setFieldValue(null)
					this.selectedValue = null
					this.preSelection = null
					//this.inputValue = null;
				} else if (setNewValue) {
					this.setSelected(checkedDate, event)
				}
			}
		}
		if (this.open && event.target != this.input) {
			this.deferFocusInput()
		} else {
			this.props.onBlur(event)
		}
	}

	handleCalendarClickOutside = (event) => {
		if (!this.props.inline) {
			//Ak neklikáme na UL/LI položky v zozname pre rýchly výber mesiaca alebo roka, len vtedy zavrieme
			if (
				event.target &&
				event.target != this.input &&
				event.target.nodeName.toUpperCase() !== "UL" &&
				event.target.nodeName.toUpperCase() !== "LI"
			) {
				this.setOpen(false)
			}
		}
		this.props.onClickOutside(event)
		if (this.props.withPortal) {
			event.preventDefault()
		}
	}

	roundMinutes = (min) => {
		const nextNumber = Math.ceil(min / this.props.timeIntervals) * this.props.timeIntervals
		return nextNumber >= 60 ? 60 - this.props.timeIntervals : nextNumber
	}

	//Pri zmene hodnoty v INPUTE
	handleChange = (...allArgs) => {
		let event = allArgs[0]
		if (this.props.onChangeRaw) {
			this.props.onChangeRaw.apply(this, allArgs)
			if (typeof event.isDefaultPrevented !== "function" || event.isDefaultPrevented()) {
				return
			}
		}

		const tValue = event.target.value
		this.setState({inputValue: tValue})
		//this.inputValue = tValue;
		let date = parseDate(tValue, {
			dateFormat: Array.isArray(this.dateFormat) ? this.dateFormat[0] : this.dateFormat,
			locale: this.locale
		})

		if (ixsIsNull(date) && !ixsIsEmpty(tValue)) {
			date = tValue
		}

		if (date || !tValue) {
			if (isMoment(date) && this.props.timeIntervals) {
				date.minutes(this.roundMinutes(date.minutes()))
			}
			this.setSelected(date, event, true)
		}

		if (this.props.field) {
			this.handleValidation(this.props.field)
		}
	}

	handleValidation = (field) => {
		field.validate().then(({isValid}) => {
			if (this.state.isValid !== isValid) {
				this.setState({isValid: isValid})
			}
		})
	}

	checkValidation = () => {
		if (isSafe(this.props.field)) {
			this.handleValidation(this.props.field)
		}
	}

	handleSelect = (date, event) => {
		// Preventing onFocus event to fix issue
		// https://github.com/Hacker0x01/react-datepicker/issues/628
		/*this.setState({preventFocus: true}, () => {
      this.preventFocusTimeout = setTimeout(() => this.setState({preventFocus: false}), 50);
      return this.preventFocusTimeout;
    });*/

		this.preventFocus = false
		this.setSelected(date, event)

		if (!this.props.shouldCloseOnSelect || this.props.showTimeSelect) {
			this.setPreSelection(date)
		} else if (!this.props.inline) {
			this.setOpen(false)
		}
		this.setState({open: false})

		if (this.props.field) {
			this.handleValidation(this.props.field)
		}
	}

	setSelected = (date, event, keepInput) => {
		let changedDate = date

		//Ak bol input prázdny a vyberieme dátum, predplníme aktuálny čas
		if (!this.selectedValue && this.props.showTimeSelect) {
			let now = newDate()
			if (isMoment(changedDate)) {
				changedDate = setTime(changedDate, {
					hour: getHour(now),
					minute: this.props.timeIntervals ? this.roundMinutes(getMinute(now)) : getMinute(now),
					second: getSecond(now)
				})
			}
		}

		if (isMoment(changedDate)) {
			let selected = isMoment(this.selectedValue) ? this.selectedValue : newDate(this.selectedValue)

			if (!isSameDay(selected, changedDate) || this.props.allowSameDay) {
				if (changedDate !== null) {
					if (this.selectedValue) {
						//let selected = isMoment(this.selectedValue) ? this.selectedValue : newDate(this.selectedValue);
						if (keepInput) {
							selected = newDate(changedDate)
						}
						changedDate = setTime(newDate(changedDate), {
							hour: getHour(selected),
							minute: getMinute(selected),
							second: getSecond(selected)
						})
					}
					/*this.setState({
            preSelection: changedDate
          });*/
					this.preSelection = changedDate
				}
				this.props.onChange(changedDate, event)
			}
		}

		this.props.onSelect(changedDate, event)
		this.setFieldValue(changedDate)
		// FIXME taka funkcia neexistuje: setFieldValue
		//this.setFieldValue(changedDate);
		this.selectedValue = this.props.field ? this.getFieldValue(this.props.field.get("value")) : this.props.selected
		if (!keepInput) {
			this.setState({inputValue: null})
			//this.inputValue = null;
		}
	}

	setFieldValue = (newValue) => {
		if (this.props.field) {
			this.props.field.set(newValue)
		}
	}

	getFieldValue = (value) => {
		if (isMoment(value)) return value
		if (value !== undefined && value !== null && value !== "" && typeof value === "string") {
			return getDTPickerValue(value)
		}
		return value
	}

	setPreSelection = (date) => {
		const isDateRangePresent = typeof this.props.minDate !== "undefined" && typeof this.props.maxDate !== "undefined"
		const isValidDateSelection =
			isDateRangePresent && date ? isDayInRange(date, this.props.minDate, this.props.maxDate) : true
		if (isValidDateSelection) {
			/*this.setState({
        preSelection: date
      });*/
			this.preSelection = date
		}
	}

	handleTimeChange = (time, setHours = true) => {
		let selected = this.selectedValue ? this.selectedValue : this.getPreSelection()
		selected = isMoment(selected) ? selected : newDate(selected)
		let newTime = null
		if (setHours) {
			newTime = {hour: getHour(time), minute: getMinute(selected)}
		} else {
			newTime = {hour: getHour(selected), minute: getMinute(time)}
		}

		let changedDate = setTime(cloneDate(selected), newTime)
		this.preSelection = changedDate

		//this.inputValue = changedDate.format(this.dateFormat[0]);
		this.setState({inputValue: changedDate.format(this.dateFormat[0])})
		this.setFieldValue(changedDate)
		this.props.onChange(changedDate)

		this.selectedValue = this.props.field ? this.getFieldValue(this.props.field.get("value")) : this.props.selected
	}

	onInputClick = (event) => {
		if (!this.props.disabled) {
			this.setOpen(true, event)
		}
	}

	onInputKeyDown = (event) => {
		this.props.onKeyDown(event)
		const eventKey = event.key
		if (!this.open && !this.props.inline && !this.props.preventOpenOnFocus) {
			if (eventKey !== "Enter" && eventKey !== "Escape" && eventKey !== "Tab") {
				this.onInputClick(event)
			}
			return
		}
		const copy = newDate(this.preSelection)
		if (eventKey === "Enter") {
			event.preventDefault()
			if (isMoment(this.preSelection) || isDate(this.preSelection)) {
				this.handleSelect(copy, event)
				!this.props.shouldCloseOnSelect && this.setPreSelection(copy)
			} else {
				this.setOpen(false)
			}
		} else if (eventKey === "Escape") {
			event.preventDefault()
			this.setOpen(false)
		} else if (eventKey === "Tab") {
			this.setOpen(false)
		} else if (!this.props.disabledKeyboardNavigation) {
			let newSelection
			switch (eventKey) {
				case "ArrowLeft":
					event.preventDefault()
					newSelection = subtractDays(copy, 1)
					break
				case "ArrowRight":
					event.preventDefault()
					newSelection = addDays(copy, 1)
					break
				case "ArrowUp":
					event.preventDefault()
					newSelection = subtractWeeks(copy, 1)
					break
				case "ArrowDown":
					event.preventDefault()
					newSelection = addWeeks(copy, 1)
					break
				case "PageUp":
					event.preventDefault()
					newSelection = subtractMonths(copy, 1)
					break
				case "PageDown":
					event.preventDefault()
					newSelection = addMonths(copy, 1)
					break
				case "Home":
					event.preventDefault()
					newSelection = subtractYears(copy, 1)
					break
				case "End":
					event.preventDefault()
					newSelection = addYears(copy, 1)
					break
			}
			if (this.props.adjustDateOnChange) {
				this.setSelected(newSelection)
			}
			this.setPreSelection(newSelection)
		}
	}

	onClearClick = (event) => {
		if (event) {
			if (event.preventDefault) {
				event.preventDefault()
			}
		}
		this.setFieldValue(null)
		this.props.onChange(null, event)
		this.setState({inputValue: null})
		//this.inputValue = null;
	}

	clear = () => {
		this.onClearClick()
	}

	renderCalendar = () => {
		if (!this.props.inline && (!this.open || this.props.disabled)) {
			return null
		}

		const selected = this.selectedValue
			? isMoment(this.selectedValue)
				? this.selectedValue
				: newDate(this.selectedValue)
			: newDate()

		return (
			<div>
				<Calendar
					ref={(elem) => {
						this.calendar = elem
					}}
					locale={this.locale}
					adjustDateOnChange={this.props.adjustDateOnChange}
					setOpen={this.setOpen}
					dateFormat={this.dateFormat}
					dateFormatCalendar={this.props.dateFormatCalendar}
					useWeekdaysShort={this.props.useWeekdaysShort}
					dropdownMode={this.props.dropdownMode}
					selected={selected}
					preSelection={this.preSelection}
					onSelect={this.handleSelect}
					onWeekSelect={this.props.onWeekSelect}
					openToDate={this.props.openToDate}
					minDate={this.props.minDate}
					maxDate={this.props.maxDate}
					selectsStart={this.props.selectsStart}
					selectsEnd={this.props.selectsEnd}
					startDate={this.props.startDate}
					endDate={this.props.endDate}
					excludeDates={this.props.excludeDates}
					filterDate={this.props.filterDate}
					onClickOutside={this.handleCalendarClickOutside}
					formatWeekNumber={this.props.formatWeekNumber}
					highlightDates={this.props.highlightDates}
					includeDates={this.props.includeDates}
					includeTimes={this.props.includeTimes}
					inline={this.props.inline}
					peekNextMonth={this.props.peekNextMonth}
					showMonthDropdown={this.props.showMonthDropdown}
					useShortMonthInDropdown={this.props.useShortMonthInDropdown}
					showMonthYearDropdown={this.props.showMonthYearDropdown}
					showWeekNumbers={this.props.showWeekNumbers}
					showYearDropdown={this.props.showYearDropdown}
					withPortal={this.props.withPortal}
					forceShowMonthNavigation={this.props.forceShowMonthNavigation}
					showDisabledMonthNavigation={this.props.showDisabledMonthNavigation}
					scrollableYearDropdown={this.props.scrollableYearDropdown}
					scrollableMonthYearDropdown={this.props.scrollableMonthYearDropdown}
					todayButton={this.props.todayButton}
					weekLabel={this.props.weekLabel}
					utcOffset={this.props.utcOffset}
					outsideClickIgnoreClass={outsideClickIgnoreClass}
					fixedHeight={this.props.fixedHeight}
					monthsShown={this.props.monthsShown}
					onDropdownFocus={this.handleDropdownFocus}
					onMonthChange={this.props.onMonthChange}
					onYearChange={this.props.onYearChange}
					dayClassName={this.props.dayClassName}
					showTimeSelect={this.props.showTimeSelect}
					onTimeChange={this.handleTimeChange}
					timeFormat={this.props.timeFormat}
					timeIntervals={this.props.timeIntervals}
					minTime={this.props.minTime}
					maxTime={this.props.maxTime}
					excludeTimes={this.props.excludeTimes}
					timeCaption={this.props.timeCaption}
					className={this.props.calendarClassName}
					yearDropdownItemNumber={this.props.yearDropdownItemNumber}
					onDayClick={this.props.onDayClick}
					onTimeClick={this.props.onTimeClick}
				>
					{this.props.children}
				</Calendar>
			</div>
		)
	}

	renderDateInputLabel = () => {
		const {label, required, field} = this.props
		if ((field && field.get("rules") && field.get("rules").includes("required")) || required) {
			return <span className="xs-input-label xs-required">{field ? field.label : label}*</span>
		}
		return <span className="xs-input-label">{field ? field.label : label}</span>
	}

	renderDateInput = () => {
		const {required, field} = this.props
		var className = classnames(this.props.className, {
			[outsideClickIgnoreClass]: this.open
		})

		className = getFieldIdentifier(field, className)

		//const customInputRef = this.props.customInputRef || "ref";
		const sd = this.selectedValue
			? isMoment(this.selectedValue)
				? this.selectedValue
				: newDate(this.selectedValue)
			: ""

		let inputValue =
			typeof this.props.value === "string"
				? this.props.value
				: typeof this.state.inputValue === "string"
				? this.state.inputValue
				: safeDateFormat(sd, {dateFormat: this.dateFormat, locale: this.locale})

		//Ak máme field, berieme hodnotu z neho
		if (this.props.field) {
			const fvalue = this.props.field.get("value")
			if (typeof fvalue == "undefined" || fvalue == "" || fvalue == null) {
				inputValue = ""
			} else {
				if (isMoment(fvalue)) {
					inputValue = safeDateFormat(fvalue, {dateFormat: this.dateFormat, locale: this.locale})
				} else {
					//Testujeme všetky formáty čo máme (display, iba dátum DB, dátum a čas DB)
					const testFormats = [
						Array.isArray(this.dateFormat) ? this.dateFormat[0] : this.dateFormat,
						settings.DB_DATE_FORMAT,
						...this.dateTimeFormats
					]
					const displayFormat = Array.isArray(this.dateFormat) ? this.dateFormat[0] : this.dateFormat
					inputValue = formatDate(fvalue, testFormats, displayFormat)
				}
			}
		}

		let inputClassName =
			(!isSafe(inputValue) || ixsIsEmpty(inputValue)) &&
			(required || (field && field.get("rules") && field.get("rules").includes("required")))
				? "xs-input-error"
				: ""

		if (this.props.white) {
			inputClassName += " bgSnowWhite"
		}

		const customInput = this.props.customInput || (
			<Input
				InputLabelProps={{
					shrink: true
				}}
				InputProps={{
					classes: {
						root: inputClassName
					},
					tabIndex: isSafe(this.props.tabindex) ? this.props.tabindex : ""
				}}
				inputRef={(input) => (this.input = input)}
				label={this.renderDateInputLabel()}
				//hintStyle={{textAlign: "left"}}
				//floatingLabelFixed={true}
				disabled={this.props.disabled}
				//errorText={this.props.form && this.props.field && this.props.form.touched[this.props.field.name] && this.props.form.errors[this.props.field.name]}
			/>
		)

		return React.cloneElement(customInput, {
			value: inputValue,
			onBlur: this.handleBlur,
			onChange: this.handleChange,
			onClick: this.onInputClick,
			onFocus: this.handleFocus,
			onKeyDown: this.onInputKeyDown,
			id: this.props.id,
			name: this.props.name,
			autoFocus: this.props.autoFocus,
			placeholder: this.props.placeholderText,
			disabled: this.props.disabled,
			autoComplete: this.props.autoComplete,
			className: className,
			title: this.props.title,
			readOnly: this.props.readOnly,
			required: this.props.required,
			tabIndex: this.props.tabIndex
		})
	}

	renderClearButton = () => {
		if (this.props.isClearable && this.selectedValue != null) {
			return <button className="react-datepicker__close-icon" href="javascript:void(0)" onClick={this.onClearClick} />
		} else {
			return null
		}
	}

	render() {
		const {popoverClassName, inline, withPortal, disabled} = this.props
		const {open, anchorEl} = this
		this.locale = this.locale ? this.locale : this.getLocale()
		this.dateFormat = this.dateFormat ? this.dateFormat : this.getDateFormat()
		this.dateTimeFormats = [
			settings.DB_DATETIME_FORMAT,
			settings.DB_DATETIME_FORMAT.replace(".SSS", ".SS"),
			settings.DB_DATETIME_FORMAT.replace(".SSS", ".S"),
			settings.DB_DATETIME_FORMAT.replace(".SSS", "")
		]

		if (this.props.field) {
			// this.handleValidation(this.props.field)
		}

		if (this.locale && this.dateFormat) {
			const calendar = this.renderCalendar()

			if (inline && !withPortal) {
				return <div className="react-datepicker__inline">{calendar}</div>
			}

			if (withPortal) {
				return (
					<div>
						{!inline ? (
							<div className="react-datepicker__input-container">
								{this.renderDateInput()}
								{this.renderClearButton()}
							</div>
						) : null}
						{open || inline ? <div className="react-datepicker__portal">{calendar}</div> : null}
					</div>
				)
			}

			return (
				<div>
					<div className="react-datepicker__input-container">
						{this.renderDateInput()}
						{this.renderClearButton()}
					</div>
					{open && (
						// <Popover
						//   className={popoverClassName}
						//   open={open && !disabled}
						//   anchorEl={anchorEl}
						//   anchorOrigin={{horizontal: "center", vertical: "top"}}
						//   onClose={this.handleCalendarClickOutside}
						// >
						//   <div className="react-datepicker__input-popover">{calendar}</div>
						// </Popover>

						<Popper
							className={"react-datepicker__popper" + (popoverClassName ? " " + popoverClassName : "")}
							open={open && !disabled}
							anchorEl={anchorEl}
						>
							<ClickAwayListener onClickAway={this.handleCalendarClickOutside} mouseEvent="onMouseDown">
								<div className="react-datepicker__input-popover">{calendar}</div>
							</ClickAwayListener>
						</Popper>
					)}
				</div>
			)
		}
		return null
	}
}

export default injectIntl(XsDateTimePickerDirect, {
	withRef: true
})
