"use strict"

import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"
import XsButton from "../xsButton/xsButton"
import TourStore from "../../store/TourStore"
// import api from "../../../actions/api"

function TourDialog() {
	return (
		<div>
			<Dialog
				id="xsTourDialog"
				open={TourStore.isOpen}
				onClose={() => {
					TourStore.close()
				}}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-600"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div
							className="xs-absolute xs-close-btn"
							onClick={() => {
								TourStore.close()
							}}
						>
							<i className="fal fa-times fa-lg" />
						</div>
						<div className="xs-header-icon">
							<i className="fal fa-info-circle fa-2x" onClick={() => TourStore.open()}></i>
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.tourGuide" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-overflow-visible xs-content">
					<Grid container direction="column" spacing={8}>
						{TourStore.tourList.map((tour, idx) => (
							<Grid key={idx} item onClick={() => tour.openTour()} className="pointer bgWhite pa-2 borderRadius-1 mb-2">
								{tour.label}
							</Grid>
						))}
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer">
					<Grid container justify="flex-end">
						<Grid item>
							<XsButton
								className="xs-danger xs-outline"
								onClick={() => {
									TourStore.close()
								}}
								text={<FormattedMessage id="Common.form.close" />}
								icon={<i className="fal fa-times fa-lg" />}
							/>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default injectIntl(observer(TourDialog))
