import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import MobxReactForm from "mobx-react-form"
import Grid from "@material-ui/core/Grid"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import UsersStore from "../../../stores/UsersStore"
import validatorjs from "validatorjs"
import UIStore from "../../../../ambulance/stores/UIStore.js"
import fields from "./rolesDetailFields"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"
import AddIcon from "@material-ui/icons/Add"
import ResourcesForRoleContainer from "./resourcesForRoleContainer"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

@injectIntl
@observer
export default class RolesContainer extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				UsersStore.saveRole(form)
			},
			onError() {
				UIStore.isFormSaving = false
			}
		}

		UsersStore.showRoleDetail = false
		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks})

		const filterHooks = {
			onSubmit() {},
			onSuccess(form) {
				UsersStore.loadRoles(form)
			},
			onError() {}
		}

		this.filterForm = new MobxReactForm(
			{
				fields: {
					name: {
						label: <FormattedMessage id="Common.label.name" />
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks: filterHooks, bindings}
		)

		UsersStore.loadRoles(this.filterForm)
	}

	onHandleRowClick = (dataRow) => {
		UsersStore.showRoleDetail = true
		UsersStore.loadResourcesForRole(dataRow.roleId)
		this.form.$("name").set(dataRow.name_ext)
		this.form.$("description").set(dataRow.description)
		this.form.$("roleId").set(dataRow.roleId)
	}

	render() {
		UsersStore.isChangedRolesFilter(this.filterForm.values())

		return (
			<React.Fragment>
				{!UsersStore.showRoleDetail && (
					<Grid container>
						<Grid item container alignItems="center" spacing={8}>
							<Grid item xs={2}>
								<XsInput white field={this.filterForm.$("name")} />
							</Grid>
							<Grid item xs className="pb-0">
								<XsIconButton
									rect
									className="xs-default xs-outline"
									tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
									onClick={(e) => {
										this.filterForm.onSubmit(e)
									}}
									icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
								/>
							</Grid>
							<Grid item>
								<XsButton
									className="xs-success"
									text={<FormattedMessage id="Common.label.addRole" />}
									icon={<AddIcon />}
									onClick={() => {
										UsersStore.showRoleDetail = true
									}}
								/>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							{UIStore.isFormSaving ? (
								<XsLoading />
							) : UsersStore.changedRolesFilters ? (
								<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
									<i className="far fa-search fa-lg mr-3 pointer" onClick={this.filterForm.onSubmit}></i>
									<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
								</div>
							) : (
								<XsTable
									config={{
										columnDefs: {
											name_ext: {
												title: <FormattedMessage id="Common.label.name" />,
												type: "string",
												sortable: true
											},
											description: {
												title: <FormattedMessage id="Common.label.description" />,
												type: "string",
												sortable: true
											}
										},
										options: {
											showCursor: true,
											// selectRow: true,
											onRowClick: (dataRow) => {
												this.onHandleRowClick(dataRow)
											},
											mapper: (dataRow) => {
												return {
													name_ext: dataRow.display_name,
													description: dataRow.description,
													roleId: dataRow.role_id
												}
											}
										},
										dataSource: UsersStore.rolesList
									}}
								/>
							)}
						</Grid>
					</Grid>
				)}
				{UsersStore.showRoleDetail && (
					<React.Fragment>
						<Grid container /*className="pr-8 pl-8"*/>
							<Grid container direction="column" className="bgSnowWhite borderRadius-2 mb-6">
								<Grid item container spacing={8} className="pt-4 pr-4 pl-4">
									<Grid item xs={3}>
										<XsInput field={this.form.$("name")} />
									</Grid>
									<Grid item xs={3}>
										<XsInput field={this.form.$("description")} />
									</Grid>
								</Grid>
								<Grid item container className="pt-2 pr-4 pb-2 pl-4 xsformFooter">
									<Grid container spacing={8}>
										<React.Fragment>
											<Grid item>
												<XsButton
													className="xs-success pullRight"
													text={<FormattedMessage id="Common.label.save" />}
													icon={<SaveIcon />}
													onClick={(e) => {
														UIStore.isFormSaving = true
														this.form.onSubmit(e)
													}}
												/>
											</Grid>
											<Grid item>
												<XsButton
													className="xs-danger pullRight mr-2"
													text={<FormattedMessage id="Common.label.cancel" />}
													icon={<CancelIcon />}
													onClick={() => {
														UsersStore.showRoleDetail = false
														this.form.reset()
													}}
												/>
											</Grid>
										</React.Fragment>
									</Grid>
								</Grid>
								{/* <Grid item xs={6}>
									<XsButton
										className="xs-success pullRight"
										text={<FormattedMessage id="Common.label.save" />}
										icon={<SaveIcon />}
										onClick={(e) => {
											UIStore.isFormSaving = true
											this.form.onSubmit(e)
										}}
									/>
									<XsButton
										className="xs-danger pullRight mr-2"
										text={<FormattedMessage id="Common.label.cancel" />}
										icon={<CancelIcon />}
										onClick={() => {
											UsersStore.showRoleDetail = false
											this.form.reset()
										}}
									/>
								</Grid> */}
							</Grid>
						</Grid>
						<Grid container>
							{isNotEmpty(this.form.$("roleId").value) && (
								<ResourcesForRoleContainer roleId={this.form.$("roleId").value} />
							)}
						</Grid>
					</React.Fragment>
				)}
			</React.Fragment>
		)
	}
}
