"use strict"

import React from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"

import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import {FormattedMessage} from "react-intl"

import XsButton from "../../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../../global/ui/xsButton/xsIconButton"
import Grid from "@material-ui/core/Grid"

import fields from "./addCodelistFormFields"

import UIStore from "../../../../stores/UIStore"

import CancelIcon from "@material-ui/icons/Clear"
import AddIcon from "@material-ui/icons/Add"
import SaveIcon from "@material-ui/icons/Done"
import {Popover} from "@material-ui/core"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import api from "../../../../actions/api"

import WarningStore from "../../../../../../global/store/WarningStore"
import CodeListStore from "../../../../stores/CodeListStore"

import "./addCodeList.less"

@observer
// @injectIntl
export default class AddCodelistForm extends React.Component {
	// Formulár na pridanie číselníkovej hodnoty

	// PROPS
	// codelist - názov číselníka napr. Fin.CL.InvoiceType
	// apicall - celá URI API volania, ak ide o custom číselník napr. /service/cl/services
	// type - typ dátovej triedy, ktorá sa ukladaná
	// method - HTTP metóda na volanie API [default: POST]
	// afterSave - callback po uložení nového záznamu, vracia response z volania
	// customStyle - custom CSS štýl pre addCodelistForm
	// isDisabled - nevykreslí sa tlačidlo +

	constructor(props) {
		super(props)

		const hooks = {
			onSubmit(form) {
				if (isEmpty(form.$("name").value) || isEmpty(form.$("code").value)) {
					WarningStore.open(CodeListStore.warningMessage)
					form.invalidate()
				}
			},
			onSuccess(form) {
				const items = form.values()
				const type = props.codelist ? props.codelist : props.type
				const saveRequest = {
					active: true,
					_ref: false,
					_type: type,
					code: items.code,
					code_ext: items.code,
					name: items.name,
					name_ext: items.name,
					item_order: items.itemorder,
					description: items.description
				}

				api
					.saveCodelistValue(
						props.codelist ? `/cl/${props.codelist}` : props.apicall,
						props.method ? props.method : "POST",
						saveRequest
					)
					.call()
					.then((response) => {
						UIStore.isFormSaving = false
						form.$("showForm").set("value", false)
						form.reset()
						if (props.afterSave) {
							props.afterSave(response)
						}
					})
			},
			onError() {
				UIStore.isFormSaving = false
			}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
	}

	handleClick = (e) => {
		const anchorEl = e ? e.currentTarget : null
		this.form.$("showForm").set("value", !this.form.$("showForm").get("value"))
		this.form.$("anchorEl").set("value", anchorEl)
	}

	render() {
		const isDisabled = this.props.disabled ? true : false

		let headerAddButtonJSX = (
			<XsIconButton className="xs-default xs-outline" icon={<AddIcon />} onClick={this.handleClick} />
		)

		return (
			<div style={{marginTop: "14px", ...this.props.customStyle}}>
				{!isDisabled && (
					<React.Fragment>
						<Grid container direction="row">
							{headerAddButtonJSX}
						</Grid>
						<Popover
							open={this.form.$("showForm").get("value")}
							anchorEl={this.form.$("anchorEl").get("value")}
							anchorOrigin={{vertical: "bottom", horizontal: "center"}}
							transformOrigin={{vertical: "top", horizontal: "center"}}
							onClose={this.handleClick}
						>
							<div className="xs-add-codelist-form">
								<Grid container direction="row" spacing={8}>
									<Grid item xs={2}>
										<XsInput field={this.form.$("itemorder")} type={"number"} />
									</Grid>
									<Grid item xs={5}>
										<XsInput field={this.form.$("code")} />
									</Grid>
									<Grid item xs={5}>
										<XsInput field={this.form.$("name")} />
									</Grid>
									<Grid item xs={12}>
										<XsInput field={this.form.$("description")} />
									</Grid>
								</Grid>
								<Grid container direction="row" spacing={8}>
									<Grid item xs={12} className="xs-actions">
										<XsButton
											className="xs-success mr-3"
											text={<FormattedMessage id="Common.label.save" />}
											type="submit"
											onClick={(e) => this.form.onSubmit(e)}
											icon={<SaveIcon />}
										/>
										<XsButton
											className="xs-danger xs-outline"
											text={<FormattedMessage id="Common.label.cancel" />}
											onClick={this.handleClick}
											icon={<CancelIcon />}
										/>
									</Grid>
								</Grid>
							</div>
						</Popover>
					</React.Fragment>
				)}
			</div>
		)
	}
}
