import React from "react"
import {injectIntl, FormattedMessage} from "react-intl"
import {observer} from "mobx-react"
import {Grid} from "@material-ui/core"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import fields from "./nonCapitationFilterFields"

import {getUserPersonnelOrgUnits} from "../../../../../global/helpers/actions"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsPeriodPicker from "../../../../../global/ui/xsPeriodPicker/xsPeriodPicker"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import ExportToXlsDialog from "../../exportToXlsDialog/exportToXlsDialog"

import NonCapitationStore from "../../../stores/NonCapitationStore"
import ExportToXlsDialogStore from "../../../stores/ExportToXlsDialogStore"
import TableStore from "../../../stores/TableStore"

@injectIntl
@observer
export default class NonCapitationFilter extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				if (
					isNotEmpty("nonCapitationList") &&
					TableStore.tables.hasOwnProperty("nonCapitationList") &&
					TableStore.tables["nonCapitationList"].hasOwnProperty("index")
				) {
					delete TableStore.tables["nonCapitationList"]["index"]
				}

				NonCapitationStore.getFilters(form)
			},
			onError() {}
		}

		if (isEmpty(NonCapitationStore.formRef)) {
			NonCapitationStore.formRef = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		}
		this.form = NonCapitationStore.formRef

		NonCapitationStore.currFilters = this.form.values()
		NonCapitationStore.getFilters(this.form)
	}

	render() {
		NonCapitationStore.isChangedFilter(this.form.values())

		return (
			<Grid container>
				<Grid item xs={10}>
					<Grid container alignItems="center" spacing={8}>
						<Grid item xs={3}>
							<XsPeriodPicker
								white
								defaultValue={this.form.$("validity_from").value}
								onChange={(val) => {
									this.form.$("validity_from").value = val.from
									this.form.$("validity_to").value = val.to
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsInput white field={this.form.$("identifier")} />
						</Grid>
						<Grid item xs={2}>
							<XsSearchSelect white field={this.form.$("provider_id")} items={getUserPersonnelOrgUnits()} />
						</Grid>
						<Grid item className="pb-0">
							<XsIconButton
								rect
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								className="xs-default xs-outline"
								onClick={this.form.onSubmit}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={2} container justify="flex-end" alignItems="center">
					<Grid item>
						<XsButton
							className="xs-primary"
							text={<FormattedMessage id="Common.label.export" />}
							icon={<i className="fal fa-file-excel fa-lg" />}
							onClick={() => {
								ExportToXlsDialogStore.open()
							}}
						/>
					</Grid>
				</Grid>
				<ExportToXlsDialog
					onSuccess={(type) => {
						NonCapitationStore.exportToXls(type, this.form)
					}}
				/>
			</Grid>
		)
	}
}
