import {FormattedMessage} from "react-intl"
import React from "react"
// import {getSelectedOrgUnit} from "../../../../../global/helpers/actions"
import moment from "moment"

export default {
	load() {
		return {
			fields: {
				org_unit: {
					label: <FormattedMessage id="Patient.form.patientrequest.orgunit" />,
					rules: "string"
					// value: getSelectedOrgUnit()._id
				},
				date_from: {
					label: <FormattedMessage id="Capitation.List.From" />,
					type: "date",
					value: moment().startOf("day")
					// .subtract(70, "days")
				},
				date_to: {
					label: <FormattedMessage id="Capitation.List.To" />,
					type: "date",
					value: moment().endOf("day")
					// .subtract(30, "days")
				},
				doseNumber: {
					label: "Poradie dávky"
				},
				user: {
					label: "Používateľ"
				}
			}
		}
	}
}
